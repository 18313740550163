/* eslint-disable no-undef */
import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import firebase from '../../config/firebase';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Container,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import { Link } from 'react-router-dom';
import {
  inviteAccept,
  inviteEdit,
  inviteReject,
  inviteMaybe,
} from '../../sagas/invitationSaga/reducer';
// Images
import redLogo from '../../images/heartbig.png';
import { white } from 'material-ui/styles/colors';

const query = require('array-query');
const _ = require('lodash');

class InviteFull extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {}

  getImage(type, locationStart) {
    let location = locationStart;
    let path = '';
    let className = '';

    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getLocationArray(locations, alllocations) {
    if (locations.length !== 0) {
      // const locationsArray = [];
      // locations.forEach((location) => {
      //   const thislocation = query('locationId')
      //     .is(location.locationId)
      //     .on(this.props.alllocations);
      //   locationsArray.push(thislocation[0]);
      // });
      return (
        <div>
          {locations.map((location) => (
            <div className="location-info fullwidth">
              <Row>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="no-padding thumbnailCol"
                >
                  {/* <Link
                    to={{
                      pathname: '/location',
                      state: {
                        location: { location },
                        // packageId: { packageId },
                        type: 6,
                      },
                    }}
                  > */}
                  {this.getImage('thumb', location, alllocations)}
                  {/* </Link> */}
                </Col>
                <Col xs={8} md={8} sm={8} lg={8}>
                  {/* <Link
                    to={{
                      pathname: '/location',
                      state: {
                        location: { location },
                        packageId: '',
                        type: 6,
                      },
                    }}
                  > */}
                  <div>
                    <span>{location.locationTitle}</span>

                    <p className="invite-font">
                      {location.address.street}
                      <br />
                      {location.address.city} {location.address.state}
                      , {location.address.zip}
                    </p>
                  </div>
                  {/* </Link> */}
                </Col>
              </Row>
            </div>
          ))}
        </div>
      );
    } else {
      return <div>{/* <p>No Locations</p> */}</div>;
    }
  }

  getInviteHeader(invite) {
    if (invite.type !== 4) {
      return (
        <div className="text-center">
          <h3 className="clear inviteTitle">
            You've been invited to make plans with{' '}
            <b>{invite.senderName}</b>!
          </h3>
          {/* <div
            style={{
              padding: '10px',
              maxWidth: '800px',
              margin: '0pc auto',
              marginBottom: '20px',
            }}
          >
            <span
              style={{
                fontSize: '18px',
              }}
              className="mobileHide"
            >
              If you want to start making plans with{' '}
              {invite.senderName} just hit "Accept". If not, hit
              "Reject". Or, if you want to think about it just hit
              maybe and you can find this invite later in your inbox.
            </span>
            <span
              style={{
                fontSize: '16px',
              }}
              className="mobileShow"
            >
              If you want to start making plans with{' '}
              {invite.senderName} just hit "Accept". If not, hit
              "Reject". Or, if you want to think about it just hit
              maybe and you can find this invite later in your inbox.
            </span>
          </div> */}
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <h2 className="clear inviteTitle">You've been invited!</h2>
        </div>
      );
    }
  }

  acceptInvite(profile, notification, event, connections) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      event,
      senderLastName,
      senderName,
      connections,
    };
    this.props.inviteAccept(bundle);
  }

  rejectInvite(profile, notification, event) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;

    if (eventId === null) {
      eventId = event.eventId;
    }

    const bundle = {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      senderLastName,
      senderName,
      event,
    };
    this.props.inviteReject(bundle);
  }

  maybeInvite(profile, notification, event) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      eventId: event.eventId,
      senderId,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      senderLastName,
      senderName,
      event,
    };
    this.props.inviteMaybe(bundle);
  }

  acceptConnection(profile, notification, event, connections) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      eventId: event.eventId,
      senderId,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      senderLastName,
      senderName,
      event,
      connections,
    };
    this.props.inviteEdit(bundle);
  }

  editInvite(profile, notification, event, connections) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      eventId: event.eventId,
      senderId,
      senderLastName,
      senderName,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      event,
      connections,
    };
    this.props.inviteEdit(bundle);
  }

  getPostDate(scheduledTime) {
    let atsign = '';
    const dayText = moment
      .utc(scheduledTime.timestamp)
      .format('dddd');
    const dateText = moment.utc(scheduledTime.timestamp).format('Do');
    const monthText = moment
      .utc(scheduledTime.timestamp)
      .format('MMM');
    if (scheduledTime.time !== '') {
      atsign = '@';
    }
    if (scheduledTime.date === '') {
      return 'This event is not scheduled yet.';
    } else {
      return (
        <span>
          {dayText} {monthText} {dateText} {atsign}{' '}
          {scheduledTime.time}
        </span>
      );
    }
  }

  getEditButton(notification, event, connections) {
    if (notification.editable === true) {
      return (
        <center>
          <span
            onClick={() =>
              this.editInvite(
                this.props.profile,
                notification,
                event,
                connections,
              )
            }
            className="white"
          >
            {/* <img
              src={redLogo}
              className="bottomAcceptLogo"
              alt="logo"
            /> */}
            <MaterialIcon icon="check" size={35} color="white" />
          </span>
          <span className="icon-title">Connect</span>
        </center>
      );
    } else {
      return (
        <center>
          <span
            onClick={() =>
              this.acceptInvite(
                this.props.profile,
                notification,
                event,
                connections,
              )
            }
            className="white"
          >
            {/* <img
              src={redLogo}
              className="bottomAcceptLogo"
              alt="logo"
            /> */}
            <MaterialIcon icon="check" size={35} color="#f21d59" />
          </span>
          <span className="icon-title">Connect</span>
        </center>
      );
    }
  }

  getActions(notification, date, event) {
    if (notification.status === 0) {
      // if (notification.type !== 4) {
      return (
        <div>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              <center>
                <span
                  onClick={() =>
                    this.rejectInvite(
                      this.props.profile,
                      notification,
                      event,
                    )
                  }
                  className="white"
                >
                  <MaterialIcon
                    icon="not_interested"
                    size={35}
                    color="white"
                  />
                </span>
                <span className="icon-title">Reject</span>
              </center>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              {this.getEditButton(
                notification,
                event,
                this.props.connections,
              )}
            </Col>
            <Col
              xs={4}
              md={4}
              sm={4}
              lg={4}
              className="no-padding text-center"
            >
              {/* <div>
                  {this.getEditButton(
                    notification,
                    event,
                    this.props.connections
                  )}
                </div> */}

              <span
                onClick={() =>
                  this.maybeInvite(
                    this.props.profile,
                    notification,
                    event,
                  )
                }
                className="white"
              >
                <MaterialIcon
                  icon="arrow_forward"
                  size={35}
                  color="white"
                />
              </span>
              <span className="icon-title">Maybe</span>
            </Col>
          </Row>
        </div>
      );
      // } else {
      //   return (
      //     <Row>
      //       <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
      //         <span
      //           onClick={() =>
      //             this.acceptConnection(
      //               this.props.profile,
      //               notification,
      //               event,
      //               this.props.connections,
      //             )
      //           }
      //           className="white"
      //         >
      //           Accept
      //         </span>
      //       </Col>
      //       <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
      //         <span
      //           onClick={() =>
      //             this.rejectInvite(
      //               this.props.profile,
      //               notification,
      //               event,
      //             )
      //           }
      //           className="white"
      //         >
      //           Reject
      //         </span>
      //       </Col>
      //     </Row>
      //   );
      // }
    } else {
      return (
        <div>
          <span className="right">
            Started
            <br />
            {date}
          </span>
        </div>
      );
    }
  }

  getMessage(message) {
    if (message !== '') {
      return (
        <div className="section text-center pushten">
          <span>"{message}"</span>
        </div>
      );
    }
  }

  getTitle(title) {
    if (title !== '') {
      return <h4 style={{ fontSize: '19px' }}>{title}</h4>;
    }
  }

  getDescription(description) {
    if (description !== '') {
      return <p className="description">{description}</p>;
    }
  }

  getGuestList(users, profile) {
    let guests = [];
    Object.keys(users).forEach(function (key) {
      if (key !== profile.userId) {
        guests.push(users[key]);
      }
    });
    return guests;
  }

  render() {
    let event = this.props.eventsArray[0];
    let invite = event.invitations[this.props.profile.userId];
    const date = this.getPostDate(event.scheduledTime);
    let senderName = invite.senderName + ' ' + invite.senderLastName;
    if (this.state.loading === true) {
      return null;
    }
    if (this.props.alllocations === undefined) {
      return null;
    }
    let guests = this.getGuestList(event.users, this.props.profile);

    return (
      <div id="inviteFull">
        <Container
          style={{
            margin: '0px auto',
            maxWidth: '1000px',
            background: 'white',

            padding: '20px',
            height: '100%',
          }}
        >
          {/* <div className="section">
                <Link
                  to={{
                    pathname: "/events/planning"
                  }}
                  className="left"
                >
                  <MaterialIcon
                    icon="keyboard_backspace"
                    size={25}
                    color="white"
                  />
                </Link>
              </div> */}

          <div>
            {this.getInviteHeader(invite)}
            {/* {this.getMessage(invite.inviteMessage)} */}
          </div>

          <div
            style={{
              borderTop: '1px solid grey',
              paddingTop: '30px',
              padding: '20px',
              paddingBottom: '100px',
            }}
          >
            <div style={{ marginBottom: '25px' }}>
              <center>
                <h4 className="clear" style={{ fontSize: '20px' }}>
                  Heres the plan so far.
                </h4>
                <h4
                  className="clear"
                  style={{
                    fontSize: '18px',
                    fontWeight: '300',
                    color: '#f21d59',
                  }}
                >
                  Tip: You can make edits after you Connect.
                </h4>
              </center>
            </div>

            <div style={{ marginBottom: '20px' }}>
              {this.getTitle(event.title)}
              {this.getDescription(event.description)}
              <div>{date}</div>
            </div>

            <div style={{ marginBottom: '15px' }}>
              {this.getLocationArray(
                event.packageDetails.locations,
                this.props.alllocations,
              )}
            </div>
            <div>
              <span
                className="title"
                style={{ display: 'block', marginBottom: '15px' }}
              >
                Current Guest List
              </span>
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="postAvatar no-padding"
                >
                  {guests.map((guest, index) => (
                    <div>
                      <UserAvatar
                        size="50"
                        name={guest.profile.fullName}
                        src={guest.profile.profilePhotoPath}
                        style={{ margin: 'none', float: 'left' }}
                        className="mobileShow"
                      />
                      <UserAvatar
                        size="50"
                        name={guest.profile.fullName}
                        src={guest.profile.profilePhotoPath}
                        style={{ margin: 'none', float: 'left' }}
                        className="mobileHide"
                      />
                      <div style={{ float: 'left' }}>
                        <center>
                          <span>{guest.profile.fullName}</span>
                          <span className="postName">
                            {guest.profile.userName}
                          </span>
                        </center>
                      </div>
                    </div>
                  ))}
                </Col>
              </Row>
            </div>
          </div>
        </Container>
        <div className="bottomActions">
          <Container
            style={{
              margin: '0px auto',
              maxWidth: '1200px',
            }}
          >
            {this.getActions(invite, date, event)}
          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    events: state.events.events,
    alllocations: state.locations.locations,
    connections: state.connections,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    inviteAccept: (bundle) => {
      dispatch(inviteAccept(bundle));
    },
    inviteReject: (bundle) => {
      dispatch(inviteReject(bundle));
    },
    inviteMaybe: (bundle) => {
      dispatch(inviteMaybe(bundle));
    },
    inviteEdit: (bundle) => {
      dispatch(inviteEdit(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteFull);
