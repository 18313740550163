import { call, put, takeLatest } from 'redux-saga/effects';
import { getUsers } from '../../services/AdminUserServices';
import * as actions from './actions';

let defaultState = {
  events: {},
};

function* getAdminUserAction(action) {
  let users = yield call(getUsers);
  yield put({
    type: actions.SET_ADMINUSERS,
    payload: {
      ...defaultState,
      users,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_ADMINUSERS, getAdminUserAction);
}
