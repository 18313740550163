import { call, put, takeLatest } from 'redux-saga/effects';
import { getTimedEvents } from '../../services/TimedEventServices';

import * as actions from './actions';

let defaultState = {
  events: [],
};

function* getTimedEventsAction(zoneId) {
  if (zoneId !== undefined) {
    let events = yield call(getTimedEvents, zoneId.zoneId);

    yield put({
      type: actions.SET_TIMEDEVENTS,
      payload: {
        ...defaultState,
        events,
      },
    });
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_TIMEDEVENTS, getTimedEventsAction);
}
