import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const config = {
  apiKey: 'AIzaSyBofMoa_Qe4SZ9QHbQSM9HplQaGDIKpZ8E',
  // authDomain: "go-together-6b9cc.firebaseapp.com",
  authDomain: 'www.trevel.com',
  databaseURL: 'https://go-together-6b9cc.firebaseio.com',
  projectId: 'go-together-6b9cc',
  storageBucket: 'go-together-6b9cc.appspot.com',
  appId: '1:334895369930:ios:6efde61c8ddeebe5',
  messagingSenderId: '334895369930',
};

firebase.initializeApp(config);

export default firebase;
