import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class AdminMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  UNSAFE_componentWillMount() {
    if (this.props.history) {
      this.setState({
        currentUrl: this.props.history.location.pathname,
        loading: false,
      });
    }
  }

  displayLink(menuItem) {
    return (
      <li>
        <MaterialIcon
          icon={menuItem.icon}
          size={20}
          color="#f21d59"
        />
        <NavLink
          to={{
            pathname: menuItem.url,
          }}
          activeClassName="activeMemuItem"
          onClick={() => {
            this.props.click();
          }}
        >
          {menuItem.label}
        </NavLink>
      </li>
    );
  }

  render() {
    const mainLinks = [
      {
        url: '/admin/home',
        label: 'Dashboard',
        icon: 'dashboard',
      },

      {
        url: '/admin/users',
        label: 'Users',
        icon: 'supervised_user_circle',
      },

      {
        url: '/admin/locations',
        label: 'Locations',
        icon: 'location_on',
      },
      {
        url: '/admin/customlocations',
        label: 'Custom Locations',
        icon: 'person_pin_circle',
      },
      {
        url: '/admin/packages',
        label: 'Packages',
        icon: 'layers',
      },
      {
        url: '/admin/imports',
        label: 'Import Tool',
        icon: 'layers',
      },
    ];

    const secondaryLinks = [
      {
        url: '/admin/vendors',
        label: 'Vendors',
        icon: 'business',
      },
      {
        url: '/admin/events',
        label: 'Events',
        icon: 'date_range',
      },
      {
        url: '/admin/promos',
        label: 'Promotions',
        icon: 'local_activity',
      },
    ];

    const bottomLinks = [
      {
        url: '/admin/articles',
        label: 'Articles',
        icon: 'local_library',
      },
    ];

    return (
      <div id="right-filter">
        <ul className="drawerMenuList">
          {mainLinks.map((menuItem, index) => (
            <div key={'key' + index}>
              {this.displayLink(menuItem, this.state.currentUrl)}
            </div>
          ))}
        </ul>
        <ul className="drawerMenuList">
          {secondaryLinks.map((menuItem, index) => (
            <div key={'key' + index}>
              {this.displayLink(menuItem, this.state.currentUrl)}
            </div>
          ))}
        </ul>
        <ul className="drawerMenuList">
          {bottomLinks.map((menuItem, index) => (
            <div key={'key' + index}>
              {this.displayLink(menuItem, this.state.currentUrl)}
            </div>
          ))}
        </ul>
      </div>
    );
  }
}

export default AdminMenu;
