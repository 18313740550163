import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React, { Component } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
// Reducers
import { scheduleEvent } from '../../sagas/userEventsSaga/reducer';

const format = 'h:mm a';
const now = moment().hour(0).minute(0);
const query = require('array-query');

class NewEventScheduleModal extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      screen: 1,
      selectedDay: null,
      selectedTime: null,
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
  }

  UNSAFE_componentWillMount() {}

  changeScreen(screen) {
    this.setState({
      screen,
    });
  }

  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  }

  onTimeChange = (time) => {
    this.setState({ selectedTime: time });
  };

  getButton() {
    if (this.state.selectedDay !== null) {
      return (
        <button
          className="redbutton fullwidth"
          onClick={() => {
            this.props.setSchedule(
              this.state.selectedDay,
              this.state.selectedTime,
            );
          }}
        >
          All Set!
        </button>
      );
    } else {
      return (
        <button
          className="redbutton fullwidth"
          onClick={() => {
            this.props.skipSchedule();
          }}
        >
          Skip!
        </button>
      );
    }
  }

  render() {
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
      },
    };
    return (
      <div>
        <div className="section">
          <Container>
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <div>
                  <div className="text-center">
                    <span className="inputLabel">Time</span>
                    <TimePicker
                      showSecond={false}
                      // defaultValue={this.state.selectedTime}
                      className="xxx"
                      onChange={this.onTimeChange}
                      format={format}
                      use12Hours
                      inputReadOnly
                    />
                  </div>
                  <div className="text-center">
                    {/* {this.state.selectedDay ? (
              <p>You clicked {this.state.selectedDay.toLocaleDateString()}</p>
            ) : (
              <p>Please select a day.</p>
            )} */}
                    <DayPicker
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiersStyles={modifiersStyles}
                    />
                    <p>
                      {this.state.selectedDay
                        ? this.state.selectedDay.toLocaleDateString()
                        : 'Please select a day'}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">{this.getButton()}</Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    packages: state.packages.packages,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    scheduleEvent: (bundle) => {
      dispatch(scheduleEvent(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewEventScheduleModal);
