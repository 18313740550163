import { call, put, takeLatest } from 'redux-saga/effects';
import { getEntertainment } from '../../services/EntertainmentServices';

import * as actions from './actions';

let defaultState = {
  entertainment: [],
};

function* getEntertainmentAction(zoneId) {
  if (zoneId !== undefined) {
    let entertainment = yield call(getEntertainment, zoneId.zoneId);

    yield put({
      type: actions.SET_ENTERTAINMENT,
      payload: {
        ...defaultState,
        entertainment,
      },
    });
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_ENTERTAINMENT, getEntertainmentAction);
}
