import { ErrorMessage, Form, Formik } from 'formik';
import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../config/firebase';
// Components
// Reducers
import { getProfile } from '../sagas/getProfileSaga/reducer';

export class ProfileTag extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      mode: 1,
      love: {
        beverages: [],
        cuisine: [],
        dateType: [],
        setting: [],
        experienceType: [],
        genre: [],
        dietary: [],
        eventType: [],
        beverages: [],
        cuisine: [],
        dateType: [],
        setting: [],
        experienceType: [],
        genre: [],
        dietary: [],
        eventType: [],
      },
      hate: {
        beverages: [],
        cuisine: [],
        dateType: [],
        setting: [],
        experienceType: [],
        genre: [],
        dietary: [],
        eventType: [],
        beverages: [],
        cuisine: [],
        dateType: [],
        setting: [],
        experienceType: [],
        genre: [],
        dietary: [],
        eventType: [],
      },
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
  }

  componentDidMount() {
    document.title = 'Preferences';

    this.setCurrentValues();
  }

  setCurrentValues() {
    const beverageTagsHate = [];
    const settingTagsHate = [];
    const cuisineTagsHate = [];
    const dateTypeTagsHate = [];
    const beverageTagsLove = [];
    const cuisineTagsLove = [];
    const dateTypeTagsLove = [];
    const settingTagsLove = [];
    const experienceTypeTagsLove = [];
    const experienceTypeTagsHate = [];
    const genreTagsLove = [];
    const genreTagsHate = [];
    const dietaryTagsLove = [];
    const dietaryTagsHate = [];
    const eventTypeTagsLove = [];
    const eventTypeTagsHate = [];

    if (this.props.profile.tags !== undefined) {
      const userTags = this.props.profile.tags;

      if (
        userTags.hate.tagsGenre !== undefined &&
        userTags.hate.tagsGenre !== 0
      ) {
        userTags.hate.tagsGenre.forEach((tag) => {
          genreTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (
        userTags.hate.tagsDietary !== undefined &&
        userTags.hate.tagsDietary !== 0
      ) {
        userTags.hate.tagsDietary.forEach((tag) => {
          dietaryTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (
        userTags.hate.tagsEventType !== undefined &&
        userTags.hate.tagsEventType !== 0
      ) {
        userTags.hate.tagsEventType.forEach((tag) => {
          eventTypeTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (
        userTags.hate.tagsExperienceType !== 0 &&
        userTags.hate.tagsExperienceType !== undefined
      ) {
        userTags.hate.tagsExperienceType.forEach((tag) => {
          experienceTypeTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.hate.tagsBeverages !== 0) {
        userTags.hate.tagsBeverages.forEach((tag) => {
          beverageTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.hate.tagsSetting.length !== 0) {
        userTags.hate.tagsSetting.forEach((tag) => {
          settingTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.hate.tagsCuisine.length !== 0) {
        userTags.hate.tagsCuisine.forEach((tag) => {
          cuisineTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.hate.tagsDateType.length !== 0) {
        userTags.hate.tagsDateType.forEach((tag) => {
          dateTypeTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (
        userTags.love.tagsEventType !== undefined &&
        userTags.love.tagsEventType !== 0
      ) {
        userTags.love.tagsEventType.forEach((tag) => {
          eventTypeTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (
        userTags.love.tagsDietary !== undefined &&
        userTags.love.tagsDietary !== 0
      ) {
        userTags.love.tagsDietary.forEach((tag) => {
          dietaryTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (
        userTags.love.tagsGenre !== undefined &&
        userTags.love.tagsGenre !== 0
      ) {
        userTags.love.tagsGenre.forEach((tag) => {
          genreTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (
        userTags.love.tagsExperienceType !== 0 &&
        userTags.love.tagsExperienceType !== undefined
      ) {
        userTags.love.tagsExperienceType.forEach((tag) => {
          experienceTypeTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.love.tagsBeverages.length !== 0) {
        userTags.love.tagsBeverages.forEach((tag) => {
          beverageTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.love.tagsSetting.length !== 0) {
        userTags.love.tagsSetting.forEach((tag) => {
          settingTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.love.tagsCuisine.length !== 0) {
        userTags.love.tagsCuisine.forEach((tag) => {
          cuisineTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.love.tagsDateType.length !== 0) {
        userTags.love.tagsDateType.forEach((tag) => {
          dateTypeTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }
    }

    let count =
      beverageTagsLove.length +
      cuisineTagsLove.length +
      dateTypeTagsLove.length +
      settingTagsLove.length +
      experienceTypeTagsLove.length +
      genreTagsLove.length +
      dietaryTagsLove.length +
      eventTypeTagsLove.length +
      beverageTagsHate.length +
      cuisineTagsHate.length +
      dateTypeTagsHate.length +
      settingTagsHate.length +
      experienceTypeTagsHate.length +
      genreTagsHate.length +
      dietaryTagsHate.length +
      eventTypeTagsHate.length;

    this.setState({
      love: {
        beverages: beverageTagsLove,
        cuisine: cuisineTagsLove,
        dateType: dateTypeTagsLove,
        setting: settingTagsLove,
        experienceType: experienceTypeTagsLove,
        genre: genreTagsLove,
        dietary: dietaryTagsLove,
        eventType: eventTypeTagsLove,
      },
      hate: {
        beverages: beverageTagsHate,
        cuisine: cuisineTagsHate,
        dateType: dateTypeTagsHate,
        setting: settingTagsHate,
        experienceType: experienceTypeTagsHate,
        genre: genreTagsHate,
        dietary: dietaryTagsHate,
        eventType: eventTypeTagsHate,
      },
      loading: false,
      startCount: count,
    });
  }

  saveTags(userId) {
    const db = firebase.firestore();
    const docToUpdate = db.collection('users').doc(userId);

    const tagsExperienceTypeLove = [];
    this.state.love.experienceType.forEach((doc) => {
      tagsExperienceTypeLove.push(doc.value);
    });

    const tagsEventTypeLove = [];
    this.state.love.eventType.forEach((doc) => {
      tagsEventTypeLove.push(doc.value);
    });

    const tagsGenreLove = [];
    this.state.love.genre.forEach((doc) => {
      tagsGenreLove.push(doc.value);
    });

    const tagsDietaryLove = [];
    this.state.love.dietary.forEach((doc) => {
      tagsDietaryLove.push(doc.value);
    });

    const tagsBeveragesLove = [];
    this.state.love.beverages.forEach((doc) => {
      tagsBeveragesLove.push(doc.value);
    });

    const tagsCuisineLove = [];
    this.state.love.cuisine.forEach((doc) => {
      tagsCuisineLove.push(doc.value);
    });

    const tagsDateTypeLove = [];
    this.state.love.dateType.forEach((doc) => {
      tagsDateTypeLove.push(doc.value);
    });

    const tagsSettingLove = [];
    this.state.love.setting.forEach((doc) => {
      tagsSettingLove.push(doc.value);
    });

    const tagsDietaryHate = [];
    this.state.love.dietary.forEach((doc) => {
      tagsDietaryHate.push(doc.value);
    });

    const tagsGenreHate = [];
    this.state.hate.genre.forEach((doc) => {
      tagsGenreHate.push(doc.value);
    });

    const tagsEventTypeHate = [];
    this.state.hate.eventType.forEach((doc) => {
      tagsEventTypeHate.push(doc.value);
    });

    const tagsExperienceTypeHate = [];
    this.state.hate.experienceType.forEach((doc) => {
      tagsExperienceTypeHate.push(doc.value);
    });

    const tagsBeveragesHate = [];
    this.state.hate.beverages.forEach((doc) => {
      tagsBeveragesHate.push(doc.value);
    });

    const tagsCuisineHate = [];
    this.state.hate.cuisine.forEach((doc) => {
      tagsCuisineHate.push(doc.value);
    });

    const tagsDateTypeHate = [];
    this.state.hate.dateType.forEach((doc) => {
      tagsDateTypeHate.push(doc.value);
    });

    const tagsSettingHate = [];
    this.state.hate.setting.forEach((doc) => {
      tagsSettingHate.push(doc.value);
    });

    const tagsArray = {
      love: {
        tagsDateType: tagsDateTypeLove,
        tagsCuisine: tagsCuisineLove,
        tagsBeverages: tagsBeveragesLove,
        tagsSetting: tagsSettingLove,
        tagsExperienceType: tagsExperienceTypeLove,
        tagsGenre: tagsGenreLove,
        tagsDietary: tagsDietaryLove,
        tagsEventType: tagsEventTypeLove,
      },
      hate: {
        tagsDateType: tagsDateTypeHate,
        tagsCuisine: tagsCuisineHate,
        tagsBeverages: tagsBeveragesHate,
        tagsSetting: tagsSettingHate,
        tagsExperienceType: tagsExperienceTypeHate,
        tagsGenre: tagsGenreHate,
        tagsDietary: tagsDietaryHate,
        tagsEventType: tagsEventTypeHate,
      },
    };

    const updateDoc = docToUpdate
      .update({
        tags: tagsArray,
      })
      .then(() => {
        this.props.getProfile(this.props.profile.userId);
        this.props.history.push('/');
      });
  }

  getContent(mode, users, userId) {
    if (mode === 1) {
      return this.loveTab();
    } else if (mode === 2) {
      return this.hateTab();
    }
  }

  getTab(tabMode, tabLable, mode) {
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tabLable}
          </div>
        </a>
      );
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getCategoryFields(tags) {
    const cuisineOptions = [];
    const beverageOptions = [];
    const settingOptions = [];
    const experienceTypeOptions = [];
    const genreOptions = [];
    const dietaryOptions = [];
    const eventTypeOptions = [];
    tags.forEach((tag) => {
      if (tag.sid === 'cuisineType') {
        tag.tags.forEach((tag) => {
          cuisineOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (tag.sid === 'beverages') {
        tag.tags.forEach((tag) => {
          beverageOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (tag.sid === 'setting') {
        tag.tags.forEach((tag) => {
          settingOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (tag.sid === 'experienceType') {
        tag.tags.forEach((tag) => {
          experienceTypeOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (tag.sid === 'genre') {
        tag.tags.forEach((tag) => {
          genreOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (tag.sid === 'dietary') {
        tag.tags.forEach((tag) => {
          dietaryOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (tag.sid === 'eventType') {
        tag.tags.forEach((tag) => {
          eventTypeOptions.push({
            value: tag.tag,
            label: tag.plural,
          });
        });
      }
    });
    if (this.state.mode === 1) {
      return (
        <div className="signup-questions">
          <div
            style={{ margin: '20px 0 20px 0', textAlign: 'center' }}
          >
            <span style={{ fontSize: '17px', fontWeight: '500' }}>
              Entertainment
            </span>
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">
              Experiences I Love
            </span>
            {/* <Select
              value={this.state.love.experienceType}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChange('experienceType', value, 'love');
              }}
              options={experienceTypeOptions}
              isMulti={true}
            /> */}
            <Select
              value={this.state.love.eventType}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChange('eventType', value, 'love');
              }}
              options={eventTypeOptions}
              isMulti={true}
            />
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">
              Music Genres
            </span>
            <Select
              value={this.state.love.genre}
              menuPlacement="auto"
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChange('genre', value, 'love');
              }}
              options={genreOptions}
              isMulti={true}
            />
          </div>
          <div
            style={{ margin: '20px 0 20px 0', textAlign: 'center' }}
          >
            <span className="title">Dining Preferences</span>
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">
              Dietary Must Haves
            </span>
            <Select
              value={this.state.love.dietary}
              closeMenuOnSelect={false}
              menuPlacement="auto"
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChange('dietary', value, 'love');
              }}
              options={dietaryOptions}
              isMulti={true}
            />
          </div>

          <div className="signup-question">
            <span className="signup-tag-lable textleft">Cuisine</span>
            <Select
              value={this.state.love.cuisine}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              menuPlacement="auto"
              onChange={(value) => {
                this.handleOnChange('cuisine', value, 'love');
              }}
              options={cuisineOptions}
              isMulti={true}
            />
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">Drinks</span>
            <Select
              value={this.state.love.beverages}
              closeMenuOnSelect={false}
              menuPlacement="auto"
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChange('beverages', value, 'love');
              }}
              options={beverageOptions}
              isMulti={true}
            />
          </div>
          {/* <div className="signup-question">
            <span className="signup-tag-lable textleft">Setting</span>
            <Select
              value={this.state.love.setting}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChange('setting', value, 'love');
              }}
              options={settingOptions}
              isMulti={true}
            />
          </div> */}
        </div>
      );
    } else {
      return (
        <div className="signup-questions">
          <div
            style={{ margin: '20px 0 20px 0', textAlign: 'center' }}
          >
            <span style={{ fontSize: '17px', fontWeight: '500' }}>
              Entertainment
            </span>
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">
              Experiences I Hate
            </span>
            <Select
              value={this.state.hate.eventType}
              closeMenuOnSelect={false}
              menuPlacement="auto"
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChange('eventType', value, 'hate');
              }}
              options={eventTypeOptions}
              isMulti={true}
            />
            {/* <Select
              value={this.state.hate.experienceType}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChange('experienceType', value, 'hate');
              }}
              options={experienceTypeOptions}
              isMulti={true}
            /> */}
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">
              Music Genres
            </span>
            <Select
              value={this.state.hate.genre}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              menuPlacement="auto"
              onChange={(value) => {
                this.handleOnChange('genre', value, 'hate');
              }}
              options={genreOptions}
              isMulti={true}
            />
          </div>
          <div
            style={{ margin: '20px 0 20px 0', textAlign: 'center' }}
          >
            <span className="title">Dining Preferences</span>
          </div>
          {/* <div className="signup-question">
            <span className="signup-tag-lable textleft">
              Dietary Must Haves
            </span>
            <Select
              value={this.state.love.dietary}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChange('dietary', value, 'love');
              }}
              options={dietaryOptions}
              isMulti={true}
            />
          </div> */}
          <div className="signup-question">
            <span className="signup-tag-lable textleft">Cuisine</span>
            <Select
              value={this.state.hate.cuisine}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              menuPlacement="auto"
              onChange={(value) => {
                this.handleOnChange('cuisine', value, 'hate');
              }}
              options={cuisineOptions}
              isMulti={true}
            />
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">Drinks</span>
            <Select
              value={this.state.hate.beverages}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              menuPlacement="auto"
              onChange={(value) => {
                this.handleOnChange('beverages', value, 'hate');
              }}
              options={beverageOptions}
              isMulti={true}
            />
          </div>
          {/* <div className="signup-question">
            <span className="signup-tag-lable textleft">Setting</span>
            <Select
              value={this.state.hate.setting}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChange('setting', value, 'hate');
              }}
              options={settingOptions}
              isMulti={true}
            />
          </div> */}
        </div>
      );
    }
  }

  getTitleBar(count) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {this.state.startCount !== 0 && (
                <Link
                  to={{
                    pathname: '/',
                  }}
                  className="left"
                >
                  <MaterialIcon
                    icon="close"
                    size={25}
                    color="white"
                  />
                </Link>
              )}
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Your Preferences</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {count !== 0 ? (
                <a
                  onClick={(value) => {
                    this.saveTags(this.props.user.id);
                  }}
                  className="right"
                >
                  <MaterialIcon
                    icon="check"
                    size={25}
                    color="white"
                  />
                </a>
              ) : (
                <span className="right">
                  <MaterialIcon icon="check" size={25} color="grey" />
                </span>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  loveTab() {
    return (
      <Formik
        enableReinitialize
        onSubmit={(values) => {
          this.saveTags(values, this.props.user.id);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Container>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="left no-padding"
                >
                  <div className="section no-padding">
                    <div>
                      {this.getCategoryFields(this.props.tags)}
                    </div>
                    <ErrorMessage name="categories" component="div" />
                  </div>
                </Col>
              </Row>
            </Container>
            {/* <button
              type="submit"
              // className="redbutton"
              disabled={isSubmitting}
            >
              Get Started!
            </button> */}
          </Form>
        )}
      </Formik>
    );
  }

  hateTab() {
    return (
      <Formik
        enableReinitialize
        onSubmit={(values) => {
          this.saveTags(values, this.props.user.id);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Container>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="left no-padding"
                >
                  <div className="section no-padding">
                    <div>
                      {this.getCategoryFields(this.props.tags)}
                    </div>
                    <ErrorMessage name="categories" component="div" />
                  </div>
                </Col>
              </Row>
            </Container>
            {/* <button
              type="submit"
              // className="redbutton"
              disabled={isSubmitting}
            >
              Get Started!
            </button> */}
          </Form>
        )}
      </Formik>
    );
  }

  handleOnChange = (fieldName, value, cat) => {
    let loveTags = this.state.love;
    let hateTags = this.state.hate;
    if (cat === 'love') {
      loveTags[fieldName] = value;
      this.setState({ love: loveTags });
    }
    if (cat === 'hate') {
      hateTags[fieldName] = value;
      this.setState({ hate: hateTags });
    }
  };

  render() {
    let count =
      this.state.love.beverages.length +
      this.state.love.cuisine.length +
      this.state.love.dateType.length +
      this.state.love.setting.length +
      this.state.love.experienceType.length +
      this.state.love.genre.length +
      this.state.love.dietary.length +
      this.state.love.eventType.length +
      this.state.love.beverages.length +
      this.state.hate.cuisine.length +
      this.state.hate.dateType.length +
      this.state.hate.setting.length +
      this.state.hate.experienceType.length +
      this.state.hate.genre.length +
      this.state.hate.dietary.length +
      this.state.hate.eventType.length;

    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div id="profile">
        {this.getTitleBar(count)}
        <div id="headersTopSecondary" className="full-width">
          <Row>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(1, 'Things I Love', this.state.mode)}
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(2, 'Things I Hate', this.state.mode)}
            </Col>
          </Row>
        </div>
        <Container className="fortyPush">
          <div className="section">
            {/* <h3>Set Some Preferences</h3>
            <p>You can set these later as well!</p> */}
            {count === 0 && (
              <div
                style={{
                  border: '2px solid #ed0259',
                  padding: '10px',
                  background: 'white',
                  textAlign: 'center',
                }}
              >
                Your preferences help us find the best experiences
                possible for you. Please select at least one thing you
                love or hate.
              </div>
            )}
            <div>
              {this.getContent(this.state.mode)}

              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
                }}
              />
            </div>
          </div>
        </Container>

        {/* <style
          dangerouslySetInnerHTML={{
            __html:
              "#authleft {display:none } #mobile-nav{display:none !important} .maincontent-push{top:0px !important; height:100%} #authright {padding-left:0px !important} header { display: none } .maincontent { top: 0px; margin-top: 0px;} .footer-container{display:none} .full-height{height:100% "
          }}
        /> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (userId) => {
      dispatch(getProfile(userId));
    },
  };
};

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    user: state.user,
    tags: state.tags.tags,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileTag);
