import React from 'react';
import { connect } from 'react-redux';
import EventHomeBody from '../components/EventHomeBody';
import PromotedHero from '../components/PromotedHero';

export class EventHome extends React.Component {
  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
  }
  render() {
    return (
      <div id="home" className="section">
        <PromotedHero />
        <h1>Plan Your Event</h1>
        <EventHomeBody />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    articles: state.articles.articles,
    user: state.user,
  };
}

export default connect(mapStateToProps)(EventHome);
