import { call, put, takeLatest } from 'redux-saga/effects';
import { getPromos } from '../../services/PromoServices';

import * as actions from './actions';

let defaultState = {
  promos: [],
};

function* getPromosAction(zoneId) {
  if (zoneId !== undefined) {
    let promos = yield call(getPromos, zoneId.zoneId);
    yield put({
      type: actions.SET_PROMOS,
      payload: {
        promos,
      },
    });
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_PROMOS, getPromosAction);
}
