import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import firebase from '../../config/firebase';
import FileUploader from 'react-firebase-file-uploader';
import Select from 'react-select';
import MaterialIcon from 'material-icons-react';
import ReactCrop from 'react-image-crop';
import UserAvatar from 'react-user-avatar';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../images/TrevelUnderlined.svg';
// Reducers
// import { setLocations } from '../../sagas/locationsSaga/reducer';

import { getVendor } from '../../sagas/vendorSaga/reducer';
import { getTimedEvents } from '../../sagas/timedEventsSaga/reducer';
import { getEntertainment } from '../../sagas/entertainmentSaga/reducer';

const format = 'h:mm a';
const now = moment().hour(0).minute(0);

const INITIAL_STATE = {
  mode: 0,
  editorChange: false,
  editorState: '',
  title: '',
  categories: '',
  locationHero: '',
  locationId: '',
  locationThumb: '',
  eventDescriptionLong: '',
  website: '',
  twitter: '',
  twitch: '',
  tiktok: '',
  facebook: '',
  patreon: '',
  bandcamp: '',
  instagram: '',
  youtube: '',
  slug: '',
  menuLink: '',
  zoneId: '',
  error: null,
  loading: true,
  isUploading: false,
  progress: 0,
  progressThumb: 0,
  heroURL: '',
  hero: '',
  selectedOption: null,
  contactEmail: null,
  contactName: null,
  contactPhone: null,
  step: 1,
  feedbackSubmitstatus: 0,
  feedback: '',
  selectedTime: '',
  from: undefined,
  to: undefined,
  tagFilters: [],
  entertainment: [],
  entertainmentType: [],
  genre: [],
  local: false,

  crop: {
    unit: '%',
    width: 30,
    aspect: 800 / 259,
  },
  cropThumb: {
    unit: '%',
    width: 30,
    aspect: 146 / 122,
  },
  cropping: false,
  croppingThumb: false,
  thumbPath: '',
  heroPath: '',
};

export class VendorEntertainmentAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getCategoryFields = this.getCategoryFields.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
  }

  UNSAFE_componentWillMount() {
    const editorState = EditorState.createEmpty();
    const editorStateLong = EditorState.createEmpty();
    this.setState({
      editorStateLong,
      editorState,
    });
    this.setInitialSlug();
  }

  componentDidMount() {
    this.setCurrentValues();
    // Get New Location ID to use later
    const db = firebase.firestore();
    const newLocationRef = db.collection('vendor_events').doc();
    const dbref = newLocationRef.id;
    this.setState({
      dbref: dbref,
      title: this.props.vendor.businessName,
    });
    // this.updateFields();
  }

  getCategoryFields(cattag) {
    const options = [];
    if (
      cattag.sid === 'entertainmentType' ||
      cattag.sid === 'genre'
    ) {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div>
          <span className="inputLabel">{cattag.parent}</span>
          <Select
            value={this.state[cattag.sid]}
            onChange={(value) => {
              this.handleOnChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  handleOnChange = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  setCurrentValues() {
    const entertainmentTypeTags = [];
    const genreTags = [];

    this.setState({
      genreTags,
      entertainmentTypeTags,
    });
  }

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorChange: true,
      editorState,
    });
  };

  onEditorStateChangeLong: Function = (editorStateLong) => {
    this.setState({
      editorStateLong,
    });
  };

  saveLocation(editorState, values) {
    const db = firebase.firestore();
    // const articleToUpdate = db.collection('locations').doc(id);
    const timestamp = new Date();

    const tagsEntertainmentType = [];
    this.state.entertainmentType.forEach((doc) => {
      tagsEntertainmentType.push(doc.value);
    });

    const tagsGenre = [];
    this.state.genre.forEach((doc) => {
      tagsGenre.push(doc.value);
    });

    // Convert Body from Draft.js to HTML
    const rawContentState = convertToRaw(
      editorState.getCurrentContent(),
    );
    const markup = draftToHtml(rawContentState);

    // const rawContentStateLong = convertToRaw(
    //   this.state.editorStateLong.getCurrentContent(),
    // );
    // const markupLong = draftToHtml(rawContentStateLong);

    let verifiedStatus = false;
    if (this.props.vendor.verified === true) {
      verifiedStatus = true;
    }

    let finalObject = {
      active: verifiedStatus,
      entertainmentDescription: markup,
      lastUpdated: timestamp,
      entertainmentTitle: values.title,
      slug: values.slug,
      entertainmentId: this.state.dbref,
      entertainmentThumb: this.state.thumbPath,
      entertainmentHero: this.state.heroPath,
      website: values.website,
      social: {
        twitter: values.twitter,
        twitch: values.twitch,
        tiktok: values.tiktok,
        facebook: values.facebook,
        patreon: values.patreon,
        bandcamp: values.bandcamp,
        instagram: values.instagram,
        youtube: values.youtube,
      },
      settings: {
        public: true,
        published: true,
        tdStatus: 0,
        customImages: true,
        claimed: true,
        local: this.state.local,
      },
      creationDetails: {
        creatorId: this.props.profile.userId,
        createdDate: timestamp,
        vendorId: this.props.vendor.vendorId,
      },
      zoneId: this.props.profile.zoneId,
      account: {
        vendorId: this.props.vendor.vendorId,
      },
      tagsEntertainmentType,
      tagsGenre,
    };

    // Update Vendor Record
    let vendorUpdate = this.props.vendor;
    let vendorEntertainment = [];
    if (this.props.vendor.entertainment !== undefined) {
      vendorEntertainment = this.props.vendor.entertainment;
    }
    vendorEntertainment.push(this.state.dbref);
    vendorUpdate.entertainment = vendorEntertainment;

    let gotoEvents = false;
    if (this.props.vendorTimedEvents.length === 0) {
      gotoEvents = true;
    }
    let bundle = {
      zoneId: this.props.profile.zoneId,
      vendorId: this.props.profile.vendorAccount.id,
      gotoEvents,
      claimed: false,
      userId: this.props.profile.vendorAccount.id,
    };

    const setDoc = db
      .collection('entertainment')
      .doc(this.state.dbref)
      .set(finalObject)
      .then((ref) => {
        const update = db
          .collection('vendors')
          .doc(this.props.profile.vendorAccount.id);
        const setDoc = update.update(vendorUpdate).then((ref) => {
          this.props.getEntertainment(this.props.profile.zoneId);
          this.props.getTimedEvents(this.props.profile.zoneId);
          this.props.getVendor(bundle);
        });
      });
  }

  // Banner Crop
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      cropping: true,
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'heroImage.jpeg',
      );
      this.setState({ heroPath: croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getBannerCropper(bannerCropping) {
    const { crop, croppedImageUrl, src } = this.state;
    if (bannerCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="Bar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropper();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropper();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </div>
        </div>
      );
    }
  }
  // End Banner Crop
  // Thumb Crop
  onSelectFileThumb = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      croppingThumb: true,
    });
  };

  onImageLoadedThumb = (image) => {
    this.imageRef = image;
  };

  onCropCompleteThumb = (crop) => {
    this.makeClientCropThumb(crop);
  };

  onCropChangeThumb = (cropThumb, percentCrop) => {
    this.setState({ cropThumb });
  };

  async makeClientCropThumb(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImgThumb(
        this.imageRef,
        crop,
        'thumbnail.jpeg',
      );
      this.setState({
        thumbPath: croppedImageUrl,
      });
    }
  }

  getCroppedImgThumb(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getThumbnailCropper(thumbnailCropping) {
    const { cropThumb, croppedImageUrl, src } = this.state;
    if (thumbnailCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropperThumb();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropperThumb();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={cropThumb}
                onImageLoaded={this.onImageLoadedThumb}
                onComplete={this.onCropCompleteThumb}
                onChange={this.onCropChangeThumb}
              />
            )}
          </div>
        </div>
      );
    }
  }
  //End Thumb Crop
  cancelCropper() {
    this.setState({
      cropping: false,
    });
  }

  saveCropper() {
    this.uploadImage(this.state.blob, 'hero');
    this.setState({
      cropping: false,
    });
  }

  cancelCropperThumb() {
    this.setState({
      croppingThumb: false,
    });
  }

  saveCropperThumb() {
    this.uploadImage(this.state.blob, 'thumb');
    this.setState({
      croppingThumb: false,
    });
  }

  uploadImage = (image, type) => {
    const uploadUrl =
      'vendorEntertainment_images/' +
      this.state.dbref +
      '/' +
      image.name;
    // const uploadUrl =
    //   'event_images/' + this.state.dbref + '/' + image.name;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadUrl);
    imageRef.put(image).then((snapshot) => {
      // const path = snapshot.ref.location.path_;
      if (type === 'hero') {
        let heroImagePath =
          'vendorEntertainment_images%2F' +
          this.state.dbref +
          '%2FheroImage.jpeg';
        let heroPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          heroImagePath +
          '?alt=media';
        this.setState({
          heroPath,
          customHero: true,
        });
      } else {
        let thumbnailImagePath =
          'vendorEntertainment_images%2F' +
          this.state.dbref +
          '%2Fthumbnail.jpeg';
        let thumbPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          thumbnailImagePath +
          '?alt=media';
        this.setState({
          thumbPath,
          customThumb: true,
        });
      }
    });
  };

  // Crop Functions
  getHeroImage() {
    if (this.state.heroPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '100%' }}
          src={this.state.heroPath}
        />
      );
    }
  }

  getThumbnail() {
    if (this.state.thumbPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '100%' }}
          src={this.state.thumbPath}
        />
      );
    }
  }

  onChangeActionLocal = (value) => {
    this.setState({
      local: value.target.checked,
    });
  };

  getCity() {
    let city = '';
    if (this.props.profile.zoneId === 11) {
      city = 'St. Louis';
    } else if (this.props.profile.zoneId === 10) {
      city = 'Kansas City';
    }
    return city;
  }

  setInitialSlug() {
    let preSlug = this.props.vendor.businessName;
    var slugSpaces = preSlug.replace(/ +/g, '-');
    var slugNoA = slugSpaces.replace("'", '');
    var slug = slugNoA.toLowerCase();

    this.setState({
      slug: slug,
    });
  }

  titleChange = (event) => {
    let preSlug = event.target.value;
    var slugSpaces = preSlug.replace(/ +/g, '-');
    var slugNoA = slugSpaces.replace("'", '');
    var slug = slugNoA.toLowerCase();

    this.setState({
      title: event.target.value,
      slug: slug,
    });
  };

  getStep(step) {
    const { editorState, editorStateLong } = this.state;
    if (step === 0) {
      return (
        <div id="vendorAdmin">
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                {this.getId(this.props.mode)}
                <div
                  className="section no-padding"
                  style={{ marginTop: '20px' }}
                >
                  <span
                    className="inputLabel"
                    style={{ display: 'inline' }}
                  >
                    Name
                  </span>
                  <ErrorMessage
                    name="title"
                    component="div"
                    style={{ float: 'right', color: 'red' }}
                  />
                  <Field
                    type="title"
                    name="title"
                    placeholder={this.state.title}
                    onChange={this.titleChange}
                    style={{ marginBottom: '20px' }}
                  />

                  <span
                    className="inputLabel"
                    style={{ display: 'inline' }}
                  >
                    Description
                  </span>
                  <ErrorMessage
                    name="description"
                    component="div"
                    style={{
                      float: 'right',
                      color: 'red',
                      fontSize: '15px',
                    }}
                  />

                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      // inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      options: [
                        'inline',
                        'blockType',
                        'list',
                        'colorPicker',
                        'link',
                        'remove',
                        'history',
                      ],
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div
                  style={{
                    paddingBottom: '10px',
                    display: 'inline-block',
                  }}
                >
                  <span className="inputLabel">
                    Are you local to the {this.getCity()} area?
                  </span>

                  <input
                    type="checkbox"
                    checked={this.state.local}
                    onChange={this.onChangeActionLocal}
                    className="closedCheckBox"
                    name="local"
                    style={{ transform: 'scale(2)', float: 'left' }}
                  />
                  <span
                    style={{
                      float: 'left',
                      position: 'relative',
                      top: '9px',
                    }}
                  >
                    Yes
                  </span>
                  <ErrorMessage name="local" component="div" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  {/* <span className="inputLabel">Phone</span>
                  <Field
                    type="phone"
                    name="phone"
                    placeholder="Phone Number"
                  />
                  <ErrorMessage name="phone" component="div" /> */}
                  <span className="inputLabel">
                    Website Link (Optional)
                  </span>
                  <Field
                    type="phone"
                    name="website"
                    placeholder="Website URL"
                  />
                  <ErrorMessage name="website" component="div" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">
                    Your Trevel Home Page URL
                  </span>
                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      fontSize: '13px',
                      marginBottom: '10px',
                    }}
                  >
                    http://trevel.com/{this.state.slug}
                  </span>
                  <Field
                    type="slug"
                    name="slug"
                    placeholder={this.state.slug}
                  />
                  <ErrorMessage
                    name="slug"
                    component="div"
                    style={{
                      float: 'right',
                      color: 'red',
                      fontSize: '15px',
                    }}
                  />
                  {/* <span className="inputLabel">Main Contact Name</span>
                      <Field
                        type="text"
                        name="contactName"
                        placeholder="Name"
                      />
                      <ErrorMessage name="contactName" component="div" />
                      <span className="inputLabel">Main Contact Phone</span>
                      <Field
                        type="text"
                        name="contactPhone"
                        placeholder="Phone Number"
                      />
                      <ErrorMessage name="contactPhone" component="div" />
                      <span className="inputLabel">Main Contact Email</span>
                      <Field
                        type="text"
                        name="contactEmail"
                        placeholder="Email Address"
                      />
                      <ErrorMessage name="contactEmail" component="div" /> */}
                </div>
              </Col>
            </Row>

            <Row style={{ paddingTop: '20px' }}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <span>Social Media Links (Optional)</span>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">Twitter</span>
                  <Field type="text" name="twitter" placeholder="" />
                  <ErrorMessage name="twitter" component="div" />
                </div>
                <div>
                  <span className="inputLabel">Twitch</span>
                  <Field type="text" name="twitch" placeholder="" />
                  <ErrorMessage name="twitch" component="div" />
                </div>
                <div>
                  <span className="inputLabel">TicTok</span>
                  <Field type="text" name="tiktok" placeholder="" />
                  <ErrorMessage name="ticktok" component="div" />
                </div>

                <div>
                  <span className="inputLabel">YouTube</span>
                  <Field type="text" name="youtube" placeholder="" />
                  <ErrorMessage name="youtube" component="div" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">Facebook</span>
                  <Field type="text" name="facebook" placeholder="" />
                  <ErrorMessage name="facebook" component="div" />
                </div>
                <div>
                  <span className="inputLabel">Instagram</span>
                  <Field
                    type="text"
                    name="instagram"
                    placeholder=""
                  />
                  <ErrorMessage name="instagram" component="div" />
                </div>
                <div>
                  <span className="inputLabel">Patreon</span>
                  <Field type="text" name="patreon" placeholder="" />
                  <ErrorMessage name="patreon" component="div" />
                </div>
                <div>
                  <span className="inputLabel">Band Camp</span>
                  <Field type="text" name="bandcamp" placeholder="" />
                  <ErrorMessage name="bandcamp" component="div" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  {/* <span className="inputLabel">Link to Menu</span>
                  <Field
                    type="text"
                    name="menuLink"
                    placeholder="URL"
                  />
                  <ErrorMessage name="website" component="div" /> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (step === 1) {
      return (
        <Container id="imageUpload">
          <Row>
            <Col
              lg={12}
              md={6}
              sm={12}
              xs={12}
              className="left"
              style={{ marginTop: '10px' }}
            >
              <span className="title">Images</span>
              <p>
                You can skip uploading images for now, but you will
                need to add them before your page can be published.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <span className="inputLabel">Banner Image</span>
              {this.getHeroImage()}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <div>
                <input type="file" onChange={this.onSelectFile} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <span className="inputLabel">Thumbnail Image</span>
              {this.getThumbnail()}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <div>
                <input
                  type="file"
                  onChange={this.onSelectFileThumb}
                />
              </div>
            </Col>
          </Row>
        </Container>
      );
    } else if (step === 2) {
      return (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {/* <div className="section no-padding">
              <span className="section-header">Categories</span>
            </div> */}
            <div className="section no-padding">
              {this.props.tags.map((cattag, index) => (
                <div>{this.getCategoryFields(cattag)}</div>
              ))}
              <ErrorMessage name="categories" component="div" />
            </div>
          </Col>
        </Row>
      );
    }
  }

  handleChange = (event) => {
    this.setState({ feedback: event.target.value });
  };

  submitFeedback(feedback) {
    const userName =
      this.props.profile.firstName + this.props.profile.lastName;
    const userId = this.props.profile.userId;
    const db = firebase.firestore();
    const timestamp = new Date();
    db.collection('feedback').add({
      created: timestamp,
      userName,
      userId,
      feedback: feedback,
      topic: 'tags',
    });
    this.setState({ feedbackSubmitstatus: 1, feedback: '' });
  }

  getActionButton(step, title) {
    if (this.state.step === step) {
      return (
        <div
          className="text-center borderRight actionButtonActive"
          onClick={() => {
            this.setStep(step);
          }}
        >
          {title}
        </div>
      );
    } else {
      return (
        <div
          className="text-center borderRight actionButton"
          onClick={() => {
            this.setStep(step);
          }}
        >
          {title}
        </div>
      );
    }
  }

  getId(mode) {
    if (mode === 1) {
      return (
        <div className="border-box greyback">
          <span>
            <span className="inputLabel">
              ID: {this.state.locationId}
            </span>
          </span>
        </div>
      );
    }
  }

  setStep(step) {
    this.setState({
      step,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  getTitleBar(title) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              {/* <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span> */}
              <span className="vendorAppTitle">
                {title}
                <span
                  className="vendorAppTitle"
                  style={{ color: 'grey' }}
                >
                  {/* {name} */}
                </span>
              </span>
              {/* </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding"> */}
              {this.props.vendor.entertainment.length !== 0 ? (
                <span className="helpButton mobileHide">
                  <Link
                    to={{
                      pathname: '/vendor/support',
                    }}
                  >
                    <MaterialIcon
                      icon="help_outline"
                      size={35}
                      color="#353535"
                    />
                  </Link>
                  {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
                </span>
              ) : (
                <span className="helpButton" />
              )}

              {/* <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span> */}
              <div
                className={
                  this.props.vendor.entertainment.length !== 0
                    ? 'saveButtons'
                    : 'right'
                }
              >
                <button className="saveButton" type="submit">
                  Save
                </button>

                {this.props.vendor.entertainment.length !== 0 ? (
                  <Link
                    to={{
                      pathname: '/vendor/admin/entertainment',
                    }}
                  >
                    <span className="cancelButton">Cancel</span>
                  </Link>
                ) : (
                  <span />
                )}
              </div>
            </Col>
          </Row>
        </div>
        <Row className="no-margin">
          <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
            <div className="mobileShow">{this.getMobileMenu()}</div>
          </Col>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'body{height: 100%} @media screen and (max-width: 768px) { .maincontent{margin-top: 120px !important; top:0px !important}}',
            }}
          />
        </Row>
      </div>
    );
  }

  getTab(tabMode, tabLable, mode, last) {
    if (tabMode === mode) {
      if (last === true) {
        return (
          <div className="noBorderRight actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      } else {
        return (
          <div className="actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      }
    } else {
      if (last === true) {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton noBorderRight  actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      }
    }
  }

  onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getLeftMenu() {
    return (
      <div className="vendorLeftNavButtons">
        {this.getTab(0, 'Details', this.state.mode, false)}
        {this.getTab(1, 'Images', this.state.mode, false)}
        {this.getTab(2, 'Tags', this.state.mode, false)}
      </div>
    );
  }

  getMobileNext(mode, mobileMenu) {
    let totalCount = mobileMenu.length;
    let currentMenu = mobileMenu[mode];
    if (currentMenu.last !== true) {
      let arrayNum = mode + 1;
      let nextMenu = mobileMenu[arrayNum];
      return (
        <div
          style={{
            float: 'right',
            position: 'relative',
            top: '8px',
          }}
        >
          <a
            onClick={() => {
              this.setMode(nextMenu.mode);
            }}
          >
            <span style={{ fontWeight: '600', color: 'white' }}>
              {/* {nextMenu.label} */}
              Next
            </span>
            <span
              className="right"
              style={{ position: 'relative', right: '-5px' }}
            >
              <MaterialIcon
                icon="arrow_forward_ios"
                size={25}
                color="white"
              />
            </span>
          </a>
        </div>
      );
    }
  }

  getMobilePrev(mode, mobileMenu) {
    let currentMenu = mobileMenu[mode];
    if (currentMenu.first !== true) {
      let arrayNum = mode - 1;
      let nextMenu = mobileMenu[arrayNum];
      return (
        <div
          style={{ float: 'left', position: 'relative', top: '8px' }}
        >
          <a
            onClick={() => {
              this.setMode(nextMenu.mode);
            }}
          >
            <span style={{ fontWeight: '600', color: 'white' }}>
              Back
            </span>
            <span className="left">
              <MaterialIcon
                icon="arrow_back_ios"
                size={25}
                color="white"
              />
            </span>
          </a>
        </div>
      );
    }
  }

  getMobileCurrent(mode, mobileMenu) {
    let total = mobileMenu.length;
    let currentMenu = mobileMenu[mode];
    return (
      <div>
        <span style={{ fontWeight: '600', color: 'white' }}>
          {currentMenu.label}
        </span>
      </div>
    );
  }

  getMobileMenu() {
    let mobileMenu = [
      {
        label: 'Details',
        mode: 0,
        last: false,
        first: true,
      },
      {
        label: 'Images',
        mode: 1,
        last: false,
        first: false,
      },
      {
        label: 'Tags',
        mode: 2,
        last: true,
        first: false,
      },
    ];

    return (
      <div
        style={{
          background: '#353535',
          paddingTop: '5px',
          paddingBottom: '5px',
          // position: 'fixed',
          // bottom: '0px',
          width: '100%',
        }}
      >
        <Row>
          <Col xs={4} md={4} sm={4} lg={4}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobilePrev(this.state.mode, mobileMenu)}
            </div>
          </Col>
          <Col xs={4} md={4} sm={4} lg={4}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobileCurrent(this.state.mode, mobileMenu)}
              <div className="paginationInner">
                {mobileMenu.map((menu, index) => (
                  <div
                    className={
                      menu.mode === this.state.mode
                        ? 'dot active'
                        : 'dot'
                    }
                  />
                ))}
              </div>
            </div>
          </Col>
          <Col xs={4} md={4} sm={4} lg={4}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobileNext(this.state.mode, mobileMenu)}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const {
      title,
      categories,
      slug,
      error,
      website,
      twitter,
      twitch,
      tiktok,
      facebook,
      patreon,
      bandcamp,
      instagram,
      youtube,
      locationHero,
      locationId,
      locationThumb,
      phone,
      zoneId,
    } = this.state;
    const isInvalid =
      categories === '' ||
      title === '' ||
      locationHero === '' ||
      locationId === '' ||
      locationThumb === '' ||
      phone === '' ||
      website === '' ||
      twitter === '' ||
      twitch === '' ||
      tiktok === '' ||
      facebook === '' ||
      patreon === '' ||
      bandcamp === '' ||
      instagram === '' ||
      youtube === '' ||
      slug === '' ||
      zoneId === '';

    return (
      <div style={{ height: '100%', display: 'block' }} id="vendor">
        <div>{this.getBannerCropper(this.state.cropping)}</div>
        <div>
          {this.getThumbnailCropper(this.state.croppingThumb)}
        </div>

        <Formik
          enableReinitialize
          initialValues={{
            title: this.state.title,
            categories: '',
            zoneId: this.state.zoneId,
            locationHero: '',
            locationId: '',
            slug: this.state.slug,
            locationThumb: '',
            packages: '',
            description: this.state.description,
            longDescription: this.state.longDescription,
            website: this.state.website,
            twitch: this.state.twitch,
            tiktok: this.state.tiktok,
            twitter: this.state.twitter,
            facebook: this.state.facebook,
            patreon: this.state.patreon,
            bandcamp: this.state.bandcamp,
            instagram: this.state.instagram,
            youtube: this.state.youtube,
          }}
          validate={(values) => {
            let errors = {};
            if (!values.title) {
              errors.title = 'Required';
            }
            if (this.state.editorChange === false) {
              errors.description = 'Required';
            }
            if (!values.slug) {
              errors.slug = 'Required';
            }
            return errors;
          }}
          onSubmit={(values) => {
            this.saveLocation(this.state.editorState, values);
          }}
        >
          {({ isSubmitting }) => (
            <Form
              style={{ height: '100%', display: 'block' }}
              onKeyDown={this.onKeyDown}
            >
              {this.getTitleBar('Setup Your Page')}

              {this.props.vendor.entertainment.length === 0 && (
                <div className="topTextBox mobileHide">
                  Welcome {this.props.profile.firstName}! {'  '}Lets
                  setup your public page.
                </div>
              )}
              <div className="vendorLeftNav mobileHide">
                <div style={{ padding: '10px' }}>
                  <span style={{ fontWeight: '600', color: 'white' }}>
                    SETUP
                  </span>
                </div>

                {this.getLeftMenu()}
              </div>

              <Container>{this.getStep(this.state.mode)}</Container>
            </Form>
          )}
        </Formik>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    tags: state.tags.tags,
    mode: state.mode.mode,
    profile: state.profile.profile,
    vendor: state.vendor.vendor,
    vendorTimedEvents: state.vendorTimedEvents.events,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setLocations: (location) => {
    //   dispatch(setLocations(location));
    // },
    getVendor: (bundle) => {
      dispatch(getVendor(bundle));
    },
    getEntertainment: (zoneId) => {
      dispatch(getEntertainment(zoneId));
    },
    getTimedEvents: (zoneId) => {
      dispatch(getTimedEvents(zoneId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorEntertainmentAdd);
