import React from 'react';
import { connect } from 'react-redux';

export class TopicAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div id="helpTopic">
        <div className="wizard-section-border noTopPadding">
          <h6>Account Settings</h6>
          <p>
            If you would like to delete your account, this is where
            you will find that option.
          </p>
          <p>
            We also think that it is more important now than ever to
            be transparent with user data. We do not sell or disclose
            user data with any third-party. We do give statistics to
            our vendors to help them understand who their customer is.
            So, for example, we might share that 40% of their
            customers in the platform enjoy wine, while only 5% enjoy
            beer.
          </p>
          <p>
            In the future, if users are okay with it, we might create
            channels to help vendors thank users for stopping by,
            however, that is not a feature at the moment.
          </p>
          {/* <Card>
            <a id='inviteNow' className='red helpTopic'>
              Invite Someone Now
            </a>
            <UncontrolledCollapse toggler='#inviteNow'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card>
          <Card>
            <a id='inviteLater' className='red helpTopic'>
              Invite Someone Later
            </a>
            <UncontrolledCollapse toggler='#inviteLater'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {};
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
    user: state.user,
    events: state.events.events,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicAccount);
