import React from 'react';
import { connect } from 'react-redux';

export class TopicYourContacts extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div id="helpTopic">
        <div className="wizard-section-border noTopPadding">
          <h6>Your Contacts</h6>
          <p>
            Add, edit, and delete contacts so that you can easily add
            guests to your Socials.
          </p>
          <p>
            You can add contacts from your phone and send invite links
            so that your friends or a significant other can create an
            account.
          </p>
          <p>
            This is important when you create a new Social because if
            an invite has selected preferences, our recommendation
            engine will help you find locations that your guests are
            sure to love.
          </p>
          {/* <Card>
            <a id='inviteNow' className='red helpTopic'>
              Invite Someone Now
            </a>
            <UncontrolledCollapse toggler='#inviteNow'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card>
          <Card>
            <a id='inviteLater' className='red helpTopic'>
              Invite Someone Later
            </a>
            <UncontrolledCollapse toggler='#inviteLater'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {};
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
    user: state.user,
    events: state.events.events,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicYourContacts);
