import React from 'react';
import { connect } from 'react-redux';
// Components
import LocationListItemEdit from '../../../../components/LocationListItemEdit';

export class NewCustomLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    // this.props.getAllLocations(1);
    this.filterCustom(this.props.alllocations);
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  filterCustom(locations) {
    const filteredArray = [];
    const theselocations = locations;
    theselocations.forEach((location) => {
      if (location.custom === true) {
        filteredArray.push(location);
      }
    });
    this.setState({
      filteredArray,
      loading: false,
    });
  }

  sortByDate(stuff) {
    stuff.sort(function compare(a, b) {
      let dateA = '';
      let dateB = '';
      if (
        a.created !== undefined &&
        a.created.seconds !== undefined
      ) {
        dateA = new Date(a.created.seconds * 1000);
      }
      if (
        b.created !== undefined &&
        b.created.seconds !== undefined
      ) {
        dateB = new Date(b.created.seconds * 1000);
      }
      return dateB - dateA;
    });
    return stuff;
  }

  render() {
    if (this.state.loading) {
    }
    // let sorted = this.sortByDate()
    const topUsers = this.sortByDate(this.props.alllocations);
    return (
      <div className="widget">
        <div className="section">
          <span className="widgetHeader">New Custom Locations</span>
        </div>

        <div className="section">
          <LocationListItemEdit
            locations={this.state.filteredArray}
            edit={true}
            editPath="/admin/customlocation/edit/"
            backPath="/admin/customlocations"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
  };
}

export default connect(mapStateToProps)(NewCustomLocations);
