import React from "react";
import { Col, Container, Row } from "reactstrap";

export default class WorkWithUs extends React.Component {
  render() {
    return (
      <div>
        <div id="contactForm" className="section">
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h1>Work With Us</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <p>
                  We know that we probably missed some great locations when we
                  were creating our service. If you are interested in working
                  with us, please reach out to us at{" "}
                  <a href="mailto:mike@trevel.com">mike@trevel.com</a>.
                </p>
              </Col>
            </Row>
          </Container>

          {/* <Link to={`/packages`}>Packages</Link> */}
        </div>
      </div>
    );
  }
}
