import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import './LocationDetails.css';
import moment from 'moment';

const query = require('array-query');

const AnyReactComponent = ({ text }) => (
  <div>
    <center>
      <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgoicon.png?alt=media&token=44afb950-2e1a-4e8a-b7e2-a0178a49fd4f" />
    </center>
  </div>
);

class LocationDetailsAdd extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  static defaultProps = {
    zoom: 15,
  };

  UNSAFE_componentWillMount() {}

  setHappyHourArray(happyHours) {
    const happyHourArray = [];
    if (happyHours.length !== 0) {
      happyHours.forEach((happyHour) => {
        // if (happyHour.happyHourType !== 2) {
        //   happyHourArray.push(happyHour);
        // }
        happyHourArray.push(happyHour);
      });
    }
    return happyHourArray;
  }

  setPromoArray(happyHours) {
    let promoArray = [];
    if (happyHours.length !== 0) {
      happyHours.forEach((happyHour) => {
        if (happyHour.happyHourType === 2) {
          promoArray.push(happyHour);
        }
      });
    }
    return promoArray;
  }

  happyHourSection(happyHours) {
    let happyHourArray = this.setHappyHourArray(happyHours);
    if (happyHourArray.length !== 0) {
      return (
        <div className="card-section">
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div className="happyHourListing">
                <span className="location-title">Happy Hours</span>
                <div className="happyHour-container">
                  {happyHourArray.map((happyHour) => (
                    <div className="happyHour">
                      <p>{happyHour.happyHourPromo}</p>
                      {/* <button className="purchase">Select HappyHour</button> */}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }

  // happyHourSection(happyHours) {
  //   const promoArray = this.setPromoArray(happyHours);
  //   if (promoArray.length === 0) {
  //     return <p>No Promos Available</p>;
  //   } else {
  //     return (
  //       <div className="happyHour-container">
  //         {promoArray.map((promo) => (
  //           <div className="happyHour">
  //             <p>{promo.happyHourPromo}</p>
  //             {/* <button className="purchase">Select HappyHour</button> */}
  //           </div>
  //         ))}
  //       </div>
  //     );
  //   }
  // }

  getPromos(locationId, promos) {
    let promoArray = [];
    if (promos !== null && promos !== undefined) {
      promos.forEach((promo) => {
        if (promo.locationId === locationId) {
          promoArray.push(promo);
        }
      });
    }
    if (promoArray.length !== 0) {
      return (
        <div className="card-section">
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div className="happyHourListing">
                <div className="happyHour-container">
                  <span className="location-title">Promotions</span>
                  {promoArray.map((promo) => (
                    <div className="happyHour">
                      <span>{promo.title}</span>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }

  getHappyHoursAndPromos(location) {
    if (location.happyHours !== undefined) {
      return (
        <div>
          {this.getPromos(location.locationId, this.props.promos)}
          {this.happyHourSection(location.happyHours)}
        </div>
      );
    }
  }

  getFooterButton() {
    if (this.props.type === 1) {
      return (
        <a
          onClick={() => {
            this.props.addLocation(
              this.props.customEvent,
              this.props.location,
            );
          }}
        >
          <span className="center solidredbutton">
            Add This Location
          </span>
        </a>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.props.changeLocation(
              this.props.customEvent,
              this.props.location,
              this.props.index,
              0,
            );
          }}
        >
          <span className="center solidredbutton">
            Add This Location
          </span>
        </a>
      );
    }
  }

  getFooter(event, profile) {
    return (
      <div id="startBar">
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <center>{this.getFooterButton()}</center>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  getWebsite(location) {
    if (location.website !== undefined && location.website !== null) {
      return (
        <Row className="no-margin">
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            <div>
              <a
                href={location.website}
                target="_blank"
                rel="noopener noreferrer"
                className="red"
              >
                <span className="left website">Visit Website</span>
              </a>
            </div>
          </Col>
        </Row>
      );
    }
  }

  getPhone(location) {
    if (location.phone !== undefined && location.phone !== null) {
      let path = 'tel:' + location.phone;
      return (
        <a href={path} className="red">
          <span className="right phone">{location.phone}</span>
        </a>
      );
    }
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  checkTag(main, tag) {
    if (main !== tag) {
      return <span className="tag-user">{tag}</span>;
    }
  }

  getTags(location) {
    return (
      <div style={{ paddingTop: '20px' }}>
        <span className="tag-user">{location.tagsMain}</span>

        {location.tagsBeverages.map((tag, index) => (
          <span>{this.checkTag(location.tagsMain, tag)}</span>
        ))}
        {location.tagsCuisine !== undefined &&
          location.tagsCuisine.map((tag, index) => (
            <span>{this.checkTag(location.tagsMain, tag)}</span>
          ))}
        {location.tagsDateType.map((tag, index) => (
          <span>{this.checkTag(location.tagsMain, tag)}</span>
        ))}
        {location.tagsSetting !== undefined && (
          <div style={{ display: 'inline-block' }}>
            {location.tagsSetting.map((tag, index) => (
              <span>{this.checkTag(location.tagsMain, tag)} </span>
            ))}
          </div>
        )}
      </div>
    );
  }

  getHours(hours) {
    var date = moment();
    var dayString = date.format('dddd');
    return (
      <div
        className="no-margin"
        style={{ paddingBottom: '50px', display: 'inline-block' }}
      >
        <div style={{ display: 'block', clear: 'both' }}>
          <span className="title">Hours</span>
        </div>
        <div style={{ display: 'block', clear: 'both' }}>
          <div
            style={{
              float: 'left',
              minWidth: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Sunday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Sunday</span>
            <div>
              {hours.sunday.sundayOpen === false ? (
                <div
                  style={{ float: 'left' }}
                  className={
                    hours.sunday.sundayTwo === true && 'secondSet'
                  }
                >
                  {hours.sunday.sundayFromHour}:
                  {hours.sunday.sundayFromMin > 10
                    ? hours.sunday.sundayFromMin
                    : '0' + hours.sunday.sundayFromMin}{' '}
                  {hours.sunday.sundayFromAMPM}
                  <br />
                  {hours.sunday.sundayToHour}:
                  {hours.sunday.sundayToMin > 10
                    ? hours.sunday.sundayToMin
                    : '0' + hours.sunday.sundayToMin}{' '}
                  {hours.sunday.sundayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
              {hours.sunday.sundayTwo === true && (
                <div style={{ float: 'left', marginLeft: '10px' }}>
                  {hours.sunday.sundayFromHourTwo}:
                  {hours.sunday.sundayFromMinTwo > 10
                    ? hours.sunday.sundayFromMinTwo
                    : '0' + hours.sunday.sundayFromMinTwo}{' '}
                  {hours.sunday.sundayFromAMPMTwo}
                  <br />
                  {hours.sunday.sundayToHourTwo}:
                  {hours.sunday.sundayToMinTwo > 10
                    ? hours.sunday.sundayToMinTwo
                    : '0' + hours.sunday.sundayToMinTwo}{' '}
                  {hours.sunday.sundayToAMPMTwo}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Monday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Monday</span>
            <div>
              {hours.monday.mondayOpen === false ? (
                <div>
                  {hours.monday.mondayFromHour}:
                  {hours.monday.mondayFromMin > 10
                    ? hours.monday.mondayFromMin
                    : '0' + hours.monday.mondayFromMin}{' '}
                  {hours.monday.mondayFromAMPM} <br />
                  {hours.monday.mondayToHour}:
                  {hours.monday.mondayToMin > 10
                    ? hours.monday.mondayToMin
                    : '0' + hours.monday.mondayToMin}{' '}
                  {hours.monday.mondayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Tuesday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Tuesday</span>
            <div>
              {hours.tuesday.tuesdayOpen === false ? (
                <div>
                  {hours.tuesday.tuesdayFromHour}:
                  {hours.tuesday.tuesdayFromMin > 10
                    ? hours.tuesday.tuesdayFromMin
                    : '0' + hours.tuesday.tuesdayFromMin}{' '}
                  {hours.tuesday.tuesdayFromAMPM} <br />
                  {hours.tuesday.tuesdayToHour}:
                  {hours.tuesday.tuesdayToMin > 10
                    ? hours.tuesday.tuesdayToMin
                    : '0' + hours.tuesday.tuesdayToMin}{' '}
                  {hours.tuesday.tuesdayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Wednesday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Wednesday</span>
            <div>
              {hours.wednesday.wednesdayOpen === false ? (
                <div>
                  {hours.wednesday.wednesdayFromHour}:
                  {hours.wednesday.wednesdayFromMin > 10
                    ? hours.wednesday.wednesdayFromMin
                    : '0' + hours.wednesday.wednesdayFromMin}{' '}
                  {hours.wednesday.wednesdayFromAMPM} <br />
                  {hours.wednesday.wednesdayToHour}:
                  {hours.wednesday.wednesdayToMin > 10
                    ? hours.wednesday.wednesdayToMin
                    : '0' + hours.wednesday.wednesdayToMin}{' '}
                  {hours.wednesday.wednesdayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Thursday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Thursday</span>
            <div>
              {hours.thursday.thursdayOpen === false ? (
                <div>
                  {hours.thursday.thursdayFromHour}:
                  {hours.thursday.thursdayFromMin > 10
                    ? hours.thursday.thursdayFromMin
                    : '0' + hours.thursday.thursdayFromMin}{' '}
                  {hours.thursday.thursdayFromAMPM} <br />
                  {hours.thursday.thursdayToHour}:
                  {hours.thursday.thursdayToMin > 10
                    ? hours.thursday.thursdayToMin
                    : '0' + hours.thursday.thursdayToMin}{' '}
                  {hours.thursday.thursdayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Friday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Friday</span>
            <div>
              {hours.friday.fridayOpen === false ? (
                <div>
                  {hours.friday.fridayFromHour}:
                  {hours.friday.fridayFromMin > 10
                    ? hours.friday.fridayFromMin
                    : '0' + hours.friday.fridayFromMin}{' '}
                  {hours.friday.fridayFromAMPM} <br />
                  {hours.friday.fridayToHour}:
                  {hours.friday.fridayToMin > 10
                    ? hours.friday.fridayToMin
                    : '0' + hours.friday.fridayToMin}{' '}
                  {hours.friday.fridayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Saturday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Saturday</span>
            <div>
              {hours.saterday.saterdayOpen === false ? (
                <div>
                  {hours.saterday.saterdayFromHour}:
                  {hours.saterday.saterdayFromMin > 10
                    ? hours.saterday.saterdayFromMin
                    : '0' + hours.saterday.saterdayFromMin}{' '}
                  {hours.saterday.saterdayFromAMPM} <br />
                  {hours.saterday.saterdayToHour}:
                  {hours.saterday.saterdayToMin > 10
                    ? hours.saterday.saterdayToMin
                    : '0' + hours.saterday.saterdayToMin}{' '}
                  {hours.saterday.saterdayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let path = this.getImage('hero', this.props.location);
    return (
      <div style={{ height: '100%' }}>
        <Container style={{ height: '100%' }}>
          <Row className="no-margin" style={{ height: '100%' }}>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div>
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={path}
                    alt="articleHero"
                  />
                  <CardBody>
                    <CardTitle>
                      {this.props.location.locationTitle}
                    </CardTitle>
                    <CardText>
                      <div className="card-section">
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              this.props.location.locationDescription,
                          }}
                          className="editoOverRide"
                        />
                        <div>
                          {/* {this.getMainTag(this.props.location)} */}
                          {/* {this.props.user.authenticated === true &&
                            this.getMatches(this.props.location)} */}
                          {/* {this.props.user.authenticated === false &&
                            this.getTags(this.props.location)} */}

                          {this.getTags(this.props.location)}
                        </div>
                      </div>
                      {this.props.location.locationDescriptionLong !==
                        undefined &&
                        this.props.location.locationDescriptionLong
                          .length > 12 && (
                          <div className="card-section">
                            <h5>Our Story</h5>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.props.location
                                    .locationDescriptionLong,
                              }}
                              className="editoOverRide"
                            />
                          </div>
                        )}
                      <div className="card-section">
                        <Row className="no-margin">
                          <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={6}
                            className="no-padding"
                          >
                            <span className="address bold">
                              {this.props.location.address.street}
                            </span>
                            <span className="address">
                              {this.props.location.address.city}{' '}
                              {this.props.location.address.state},{' '}
                              {this.props.location.address.zip}
                            </span>
                          </Col>
                          <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={6}
                            className="no-padding"
                          >
                            {this.getPhone(this.props.location)}
                          </Col>
                        </Row>
                        {this.getWebsite(this.props.location)}
                      </div>
                      {this.props.location.hours !== undefined && (
                        <div className="card-section">
                          {this.getHours(this.props.location.hours)}
                        </div>
                      )}
                      {this.getHappyHoursAndPromos(
                        this.props.location,
                      )}

                      {/* <div className="card-section">
                        <div
                          style={{ height: '200px', width: '100%' }}
                        >
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key:
                                'AIzaSyBGGWTnJQdshIr_HSsNYJREN_vWIlxZUX8',
                            }}
                            defaultCenter={[
                              this.props.location.address.longitude,
                              this.props.location.address.latitude,
                            ]}
                            defaultZoom={this.props.zoom}
                          >
                            <AnyReactComponent
                              lat={
                                this.props.location.address.longitude
                              }
                              lng={
                                this.props.location.address.latitude
                              }
                              text={this.props.location.locationTitle}
                            />
                          </GoogleMapReact>
                        </div>
                      </div> */}
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
        {this.getFooter(this.state.activeLocation)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    promos: state.vendorPromos.promos,
  };
}

export default connect(mapStateToProps)(LocationDetailsAdd);

// Location Details Available
// **************************
// this.props.location.locationTitle
// this.props.location.categories
// this.props.location.locationDescription
// this.props.location.locationHero
// this.props.location.locationThumb
// this.props.location.locationId
// this.props.location.locationType
// ** HappyHours and Packages as well
