import React from 'react';
import { connect } from 'react-redux';
import { Card, UncontrolledCollapse } from 'reactstrap';

export class TopicCreateEvent extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div id="helpTopic">
        <div className="wizard-section-border noTopPadding">
          <h6>Invite Someone</h6>
          <p>
            The first step to creating an event is choosing whether
            you want to invite someone now or plan first and invite
            someone later.{' '}
          </p>
          <Card>
            <a id="inviteNow" className="red helpTopic">
              Invite Someone Now
            </a>
            <UncontrolledCollapse toggler="#inviteNow">
              <div className="section full-width noTopPadding">
                <div className="card-section">
                  Inviting a guest before looking at locations is
                  beneficial because we might be able to make some
                  recommendations. By connecting to a guest first, our
                  recommendation engine (Trevel Agent) will take into
                  account your guest's preferences while you are
                  planning your Social. Make sure they are already a
                  user and have selected their preferences for this
                  feature.
                </div>
                <div className="card-section">
                  This is helpful if you are on a first date, in a new
                  city, or just looking for a new spot to try out.
                </div>
              </div>
            </UncontrolledCollapse>
          </Card>
          <Card>
            <a id="inviteLater" className="red helpTopic">
              Invite Someone Later
            </a>
            <UncontrolledCollapse toggler="#inviteLater">
              <div className="section full-width noTopPadding">
                <div className="card-section">
                  If getting guest preferences isn't necessary, go
                  ahead and start the planning process if you'd like.
                  Our Trevel Agent will still give recommendations
                  based on your preferences.
                </div>
              </div>
            </UncontrolledCollapse>
          </Card>
        </div>
        <div className="wizard-section-border">
          <h6>Plan Your Itinerary</h6>
          <p>
            We create Socials with the idea that you might get drinks
            for happy hour and then move on to dinner (or more
            drinks). All locations that make up a Social are part of
            the Itinerary.
          </p>
          <Card>
            <a id="rec" className="red helpTopic">
              Our Recommendations
            </a>
            <UncontrolledCollapse toggler="#rec">
              <div className="section full-width noTopPadding">
                <div className="card-section">
                  These Socials are packaged together with 2 or 3
                  locations that are close in distance to each other.
                  We also take into consideration what beverage
                  programs are offered at locations as well as happy
                  hours to help save you money.
                </div>
                <div className="card-section">
                  The goal with our Itineraries is to create a
                  cohesive social experience that is easy to navigate
                  and will get you the most bang for your buck.
                </div>
              </div>
            </UncontrolledCollapse>
          </Card>
          <Card>
            <a id="createOwn" className="red helpTopic">
              Create Your Own
            </a>
            <UncontrolledCollapse toggler="#createOwn">
              <div className="section full-width noTopPadding">
                <div className="card-section">
                  We are not the most perfect app in the world and we
                  will be the first to admit it. If you have a better
                  idea for a social event, create your own Itinerary
                  using our favorite locations, or by searching for
                  your own.
                </div>
              </div>
            </UncontrolledCollapse>
          </Card>
        </div>
        <div className="wizard-section-border">
          <h6>Make Plans Together!</h6>
          <p>
            When you are making plans with someone else it takes
            everyone's approval to save/start an event.
          </p>
          <Card>
            <a id="collab" className="red helpTopic">
              Accept, Reject, Edit
            </a>
            <UncontrolledCollapse toggler="#collab">
              <div className="section full-width noTopPadding">
                <div className="card-section">
                  Your guests will have the ability to edit a Social
                  and modify it to their liking. Once everyone is
                  comfortable with the event, hit the accept button
                  and the Social will move from the "Planning" section
                  to the "Upcoming" section in your social calendar.
                </div>
              </div>
            </UncontrolledCollapse>
          </Card>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {};
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
    user: state.user,
    events: state.events.events,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicCreateEvent);
