import { connect } from "react-redux";
import AppComponent from "./App";
import { hydrateApp } from "./sagas/hydrateSaga/reducer";
import { authhydrateApp } from "./sagas/authhydrateSaga/reducer";

const mapStatetoProps = state => {
  return {
    user: state.user,
    profile: state.profile.profile,
    hydrated: state.hydrated,
    authhydrated: state.authhydrated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hydrateApp: () => {
      dispatch(hydrateApp());
    },
    authhydrateApp: () => {
      dispatch(authhydrateApp());
    }
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(AppComponent);
