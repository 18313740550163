import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
// Components
import RelatedArticles from '../components/RelatedArticles';
import TitleBar from '../components/TitleBar';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
// Reducers
import { init } from '../sagas/packagesSaga/reducer';

const query = require('array-query');

export class Article extends React.Component {
  constructor() {
    super();
    this.state = {
      articleDetails: null,
      loading: true,
      articles: undefined,
    };
  }

  UNSAFE_componentWillMount() {}

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.articles !== prevState.articles) {
      return {
        articles: nextProps.articles,
        loading: false,
      };
    }
    return null;
  }

  findRelatedArticles(articles) {
    const relatedArticles = [];
    articles.forEach((article) => {
      if (article.slug !== this.props.match.params.title) {
        relatedArticles.push(article);
      }
    });
    relatedArticles.sort(function compare(a, b) {
      var dateA = new Date(a.postdate);
      var dateB = new Date(b.postdate);
      return dateB - dateA;
    });
    return relatedArticles;
  }

  componentDidMount() {
    const articleDetails = query('slug')
      .is(this.props.match.params.title)
      .on(this.state.articles);
    document.title = articleDetails[0].title;
    if (this.state.articles === undefined) {
      this.props.init();
    }
  }

  getDetails() {
    const articleDetails = query('slug')
      .is(this.props.match.params.title)
      .on(this.state.articles);
    return articleDetails[0];
  }

  getRelated() {
    const relatedArticles = this.findRelatedArticles(
      this.state.articles,
    );
    return relatedArticles;
  }

  setReadMore(articleDetails) {
    if (articleDetails.readMore !== undefined) {
      return (
        <a
          href={articleDetails.readMore.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="see-more">
            {articleDetails.readMore.lable}
          </button>
        </a>
      );
    } else {
      return <br />;
    }
  }

  getTitleBar() {
    let back = '/articles';
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.back !== undefined
    ) {
      back = this.props.location.state.back;
    }
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: back,
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Things to Read</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <a
                onClick={value => {
                  this.saveTags(this.props.user.id);
                }}
                className="right"
              >
                <MaterialIcon icon="check" size={25} color="white" />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.articles === undefined) {
      return null; // or render a loading icon
    } else {
      const articleDetails = this.getDetails();
      const relatedArticles = this.getRelated();
      const readmore = this.setReadMore(articleDetails);
      return (
        <div id="article">
          {this.getTitleBar()}
          <div className="contentbox">
            <Container>
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  <div className="articleArea">
                    <img src={articleDetails.hero} className="hero" />
                    <div className="section">
                      <h1>{articleDetails.title}</h1>
                      <p className="author">
                        By {articleDetails.author}
                      </p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: articleDetails.body,
                        }}
                      />
                    </div>
                    {readmore}
                  </div>
                  <br />
                  <div id="relatedArticles">
                    <div>
                      <center>
                        <h2>More Fun Things To Read</h2>
                      </center>
                      <br />
                      <RelatedArticles articles={relatedArticles} />
                    </div>
                  </div>
                </Col>
                {/* <Col xs={3} md={3} sm={3} lg={3} className="twocolRight">
                <div id="right-filter">
                  <h2>Side</h2>
                </div>
              </Col> */}
              </Row>
            </Container>
          </div>
          {this.props.user.authenticated === true && (
            <style
              dangerouslySetInnerHTML={{
                __html: '#mobile-nav { display: none }',
              }}
            />
          )}
          {this.props.user.authenticated === false && (
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '@media (max-width: 800px) {.maincontent{top:80px !important}} .backbar{display: none}',
              }}
            />
          )}
          <style
            dangerouslySetInnerHTML={{
              __html:
                'html{display:grid} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;}  #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    articles: state.articles.articles,
    profile: state.profile.profile,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(init());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);
