import { call, put, takeLatest } from 'redux-saga/effects';
import { getVendorEvents } from '../../services/VendorEventsServices';

import * as actions from './actions';

let defaultState = {
  events: [],
};

function* getVendorEventsAction(vendorId) {
  if (vendorId !== undefined) {
    let events = yield call(getVendorEvents, vendorId.vendorId);
    yield put({
      type: actions.SET_VENDOREVENTS,
      payload: {
        ...defaultState,
        events,
      },
    });
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_VENDOREVENTS, getVendorEventsAction);
}
