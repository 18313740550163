import { call, put, takeLatest } from "redux-saga/effects";
import { getVendorRewards } from "../../services/VendorRewardServices";

import * as actions from "./actions";



function* getVendorPromosAction(vendorId) {
  if (vendorId !== undefined) {
    let rewards = yield call(getVendorRewards, vendorId.vendorId);
    yield put({
      type: actions.SET_VENDORREWARDS,
      payload: {
        rewards,
      },
    });
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_VENDORREWARDS, getVendorPromosAction);
}
