import MaterialIcon from 'material-icons-react';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../images/TrevelUnderlined.svg';
import * as auth from '../../config/auth';
import { clearProfile } from '../../sagas/getProfileSaga/reducer';
import { withRouter } from 'react-router';

class VendorCommunityMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.logOut = this.logOut.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.history) {
      this.setState({
        currentUrl: this.props.history.location.pathname,
        loading: false,
      });
    }
  }

  logOut() {
    const user = {
      id: null,
      userName: null,
      email: null,
      authenticated: false,
    };
    auth.doSignOut();
    this.props.clearProfile();
  }

  render() {
    return (
      <div
        className={
          this.props.popupMenu === true
            ? 'appPickerDrawer'
            : 'appCommunityMenu'
        }
      >
        {this.props.popupMenu === true && (
          <div
            className="sidebar-logo"
            style={{ height: '80px', margin: '0px 0px 25px 0px' }}
          >
            <div className=" left">
              <center>
                <img
                  src={logo}
                  style={{
                    maxWidth: '110px',
                    margin: '0px 0px 6px 0px',
                  }}
                />
                <h5>Experiences</h5>
              </center>
            </div>
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="rgb(238 1 88)"
                onClick={() => {
                  this.props.showPicker(false);
                }}
              />
            </span>
          </div>
        )}

        <div
          style={{
            padding:
              this.props.popupMenu === true
                ? '0px'
                : '0px 0px 0px 0px',
          }}
        >
          <NavLink
            to={{
              pathname: '/vendor/community',
            }}
            activeClassName="activeMemuItem"
          >
            <span className="appTitle">Trevel Hub</span>
          </NavLink>
          {/* <div className="appList"> */}
        </div>
        <div
          style={{
            margin:
              this.props.popupMenu === true
                ? '20px 0px 0px 0px'
                : '20px 0px 0px 0px',
          }}
        >
          <span className="appListCategory">Directories</span>
          <NavLink
            to={{
              pathname: '/vendor/directory-destinations',
            }}
            activeClassName="activeMemuItem"
          >
            <span className="appTitle">Destinations</span>
          </NavLink>
          <NavLink
            to={{
              pathname: '/vendor/directory-entertainers',
            }}
            activeClassName="activeMemuItem"
          >
            <span className="appTitle">Entertainers</span>
          </NavLink>

          {/* <div style={{ marginTop: '40px' }}>
            <NavLink
              to={{
                pathname: '/vendor/support',
              }}
              activeClassName="activeMemuItem"
            >
              <span className="appTitle">Help</span>
            </NavLink>
          </div> */}
          <div
            style={{
              display: 'block',
              marginTop: '40px',
              width: '100%',
            }}
          >
            <NavLink
              to={{
                pathname: '/vendor/home',
              }}
              activeClassName="activeMemuItem"
            >
              <span className="appTitle">Return</span>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearProfile: user => {
      dispatch(clearProfile(user));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: state.profile.profile,
    vendor: state.vendor.vendor,
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(VendorCommunityMenu),
);
