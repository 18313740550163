import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import firebase from "../../config/firebase";
import FileUploader from "react-firebase-file-uploader";
// Reducers
import { getArticles } from "../../sagas/articleSaga/reducer";
// Styles
import "../rich-editor.css";

const INITIAL_STATE = {
  title: "",
  teaser: "",
  body: "",
  author: "",
  heroImage: "",
  postdate: "",
  originator: "",
  lable: "",
  url: "",
  slug: "",
  error: null,
  imageURL: "",
};

export class AdminPackageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if (this.props.new === false) {
      this.setCurrentValues();
    }
  }

  setCurrentValues() {
    const blocksFromHtml = htmlToDraft(this.props.editableDoc.body);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    this.setState({
      editorState,
      title: this.props.editableDoc.title,
      body: this.props.editableDoc.body,
      author: this.props.editableDoc.author,
      heroImage: "",
      postdate: "",
      lable: this.props.editableDoc.readMore.lable,
      originator: this.props.editableDoc.readMore.originator,
      url: this.props.editableDoc.readMore.url,
      slug: this.props.editableDoc.slug,
      teaser: this.props.editableDoc.teaser,
      id: this.props.editableDoc.id,
      imageURL: this.props.editableDoc.hero,
    });
  }

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };

  saveArticle(id, editorState, values) {
    const db = firebase.firestore();
    const timestamp = new Date();
    // Convert Body from Draft.js to HTML
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    if (this.props.new === false) {
      const articleToUpdate = db.collection("articles").doc(id);
      const updateDoc = articleToUpdate
        .update({
          body: markup,
          lastUpdated: timestamp,
          title: values.title,
          slug: values.slug,
          author: values.author,
          teaser: values.teaser,
          readMore: {
            url: values.url,
            originator: values.originator,
            lable: values.lable,
          },
          hero: this.state.imageURL,
        })
        .then(() => {
          this.props.history.push("/admin/articles");
        });
    } else {
      db.collection("articles")
        .add({
          body: markup,
          postDate: timestamp,
          title: values.title,
          slug: values.slug,
          author: values.author,
          teaser: values.teaser,
          readMore: {
            url: values.url,
            originator: values.originator,
            lable: values.lable,
          },
          hero: this.state.imageURL,
        })
        .then(() => {
          this.props.history.push("/admin/articles");
        });
    }
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = (filename) => {
    this.setState({
      articleImage: filename,
      progress: 100,
      isUploading: false,
    });
    firebase
      .storage()
      .ref("article_images")
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        this.setState({ imageURL: url });
      })
      .then(() => {
        if (this.props.new === false) {
          const db = firebase.firestore();
          const docToUpdate = db
            .collection("articles")
            .doc(this.props.editableDoc.id);
          const updateDoc = docToUpdate.update({
            hero: this.state.imageURL,
          });
        } else {
        }
      })
      .then(() => {
        // this.props.getUpdatedProfile(this.props.profile.userId);
      });
  };

  render() {
    const { editorState } = this.state;
    return (
      <div id="signup">
        <div>
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                title: this.state.title,
                body: this.state.body,
                author: this.state.author,
                heroImage: "",
                postdate: "",
                lable: this.state.lable,
                originator: this.state.originator,
                url: this.state.url,
                slug: this.state.slug,
                teaser: this.state.teaser,
              }}
              validate={(values) => {
                let errors = {};
                if (!values.teaser) {
                  errors.teaser = "Required";
                }
                if (!values.title) {
                  errors.title = "Required";
                }
                if (!this.state.editorState) {
                  errors.body = "Required";
                }
                return errors;
              }}
              onSubmit={(values) => {
                this.saveArticle(this.state.id, this.state.editorState, values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col xs={12} md={12} sm={12} lg={12} className="left">
                        <div className="border-box greyback">
                          <span>
                            <span className="inputLabel">
                              ID: {this.state.id}
                            </span>
                          </span>
                        </div>
                        <span className="inputLabel">Title</span>
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error-message"
                        />
                        <Field
                          type="textfield"
                          name="title"
                          placeholder="Article Title"
                        />
                        <span className="inputLabel">Body</span>
                        <ErrorMessage
                          name="body"
                          component="div"
                          className="error-message"
                        />
                        <Editor
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={this.onEditorStateChange}
                        />
                        <span className="inputLabel">Teaser</span>
                        <ErrorMessage
                          name="teaser"
                          component="div"
                          className="error-message"
                        />
                        <Field
                          type="text"
                          component="textarea"
                          name="teaser"
                          placeholder="Teaser"
                        />
                        <span className="inputLabel">Author</span>
                        <Field
                          type="author"
                          name="author"
                          placeholder="Author"
                        />
                        <ErrorMessage name="Author" component="div" />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} lg={12} className="left">
                        <span className="inputLabel">Hero Image</span>
                        <img
                          src={this.state.imageURL}
                          className="hero-preview"
                        />
                      </Col>
                      <Col xs={12} md={12} sm={12} lg={12} className="left">
                        {this.state.isUploading && (
                          <p>Progress: {this.state.progress}</p>
                        )}
                        <FileUploader
                          accept="image/*"
                          name="hero"
                          // randomizeFilename
                          // filename={file => file}
                          storageRef={firebase.storage().ref("article_images")}
                          onUploadStart={this.handleUploadStart}
                          onUploadError={this.handleUploadError}
                          onUploadSuccess={this.handleUploadSuccess}
                          onProgress={this.handleProgress}
                          width="400"
                        />
                        <ErrorMessage name="heroImage" component="div" />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} lg={12} className="left">
                        <span className="inputLabel">Article Originator</span>
                        <Field
                          type="textfield"
                          name="originator"
                          placeholder="Originally Posted By"
                        />
                        <ErrorMessage name="originator" component="div" />
                        <span className="inputLabel">Button URL</span>
                        <Field
                          type="textfield"
                          name="url"
                          placeholder="URL To Original Version"
                        />
                        <ErrorMessage name="url" component="div" />
                        <span className="inputLabel">Button Text</span>
                        <Field
                          type="textfield"
                          name="lable"
                          placeholder="Button Text"
                        />
                        <ErrorMessage name="lable" component="div" />
                        <span className="inputLabel">Slug</span>
                        <Field
                          type="textfield"
                          name="slug"
                          placeholder="Article Slug"
                        />
                        <ErrorMessage name="slug" component="div" />
                      </Col>
                      <Col xs={12} md={6} sm={12} lg={6} />
                    </Row>
                  </Container>

                  <button
                    type="submit"
                    className="redbutton right"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    article: state.article,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getArticles: (article) => {
      dispatch(getArticles(article));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPackageForm);
