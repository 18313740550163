import { call, put, takeLatest } from 'redux-saga/effects';
import { getVendorObject } from '../../services/VendorServices';
import * as actions from './actions';
import { push } from 'connected-react-router';
import { getVendorPromos } from '../vendorPromoSaga/reducer';
import { getVendorEvents } from '../vendorEventsSaga/reducer';
import { getVendorTimedEvents } from '../vendorTimedEventsSaga/reducer';
import { getVendorEntertainment } from '../vendorEntertainmentSaga/reducer';
import { getVendorAwarded } from '../vendorAwardedSaga/reducer';
import { getVendorRewards } from '../vendorRewardsSaga/reducer';
import { getVendorUserEvents } from '../vendorUserEventsSaga/reducer';

let defaultState = {
  events: {},
};

function* getVendorAction(action) {
  let vendor = yield call(getVendorObject, action.payload.userId);

  // yield put(getVendorPromos(action.payload.userId));

  // yield put(getVendorAwarded(action.payload.userId));

  // yield put(getVendorRewards(action.payload.userId));

  // yield put(getVendorEvents(action.payload.userId));

  // yield put(getVendorTimedEvents(action.payload.userId));

  // yield put(getVendorUserEvents(action.payload.userId));

  // yield put(getVendorEntertainment(action.payload.userId));

  yield put({
    type: actions.SET_VENDOR,
    payload: {
      ...defaultState,
      vendor,
    },
  });

  let stuffToCheck = [];
  let stuffCount = 0;
  if (
    action.payload.vendorType !== undefined &&
    action.payload.vendorType === 1
  ) {
    stuffToCheck === vendor.locations;
  } else if (
    action.payload.vendorType !== undefined &&
    action.payload.vendorType === 2
  ) {
    stuffToCheck === vendor.entertainment;
  }
  stuffCount = stuffToCheck.length;

  // This means user created account after following a claim link and we are taking them to the locations screen to see that new location

  if (action.payload.claim === true && vendor.vendorTypes[0] !== 2) {
    yield put(
      push('/vendor/location/edit/' + action.payload.locationId),
    );
  }

  // This means its a vendor that didnt claim a site but needs to be taken to either create entertainment or add location
  else if (
    action.payload.path !== undefined &&
    action.payload.claim === false
  ) {
    if (
      stuffCount === 0 &&
      action.payload.createEvent !== true &&
      action.payload.path !== '/'
    ) {
      if (action.payload.path !== '/vendor/home') {
        console.log('/path', action.payload.path);
        yield put(push(action.payload.path));
      }
    }
  }

  // This means a new entertainer vendor has made a new account, created entertainer vendor account, and are going to add first event
  else if (
    action.payload.gotoEvents !== undefined &&
    action.payload.gotoEvents === true
  ) {
    yield put(push('/vendor/timedevents'));
  }
  // who know
  else {
    if (action.payload.path === 'nope') {
      yield put(push('/vendor/locations'));
    } else {
      // yield put(push('/vendor/home'));
    }
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_VENDOR, getVendorAction);
}
