import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as auth from '../../config/auth';
import './SideDrawer.css';
// Reducers
import { setUser } from '../../sagas/loginSaga/reducer';
import { clearUser } from '../../sagas/loginSaga/reducer';
import { clearProfile } from '../../sagas/getProfileSaga/reducer';
import { getMode } from '../../sagas/setModeSaga/reducer';
import UserMenu from '../UserMenu';
import { Link } from 'react-router-dom';

class AuthSideDrawer extends Component {
  constructor() {
    super();
    this.logOut = this.logOut.bind(this);
  }
  logOut() {
    const user = {
      id: null,
      userName: null,
      email: null,
      authenticated: false,
    };
    auth.doSignOut();
    this.props.clearProfile();
    // this.props.clearUser();
  }

  adminLinks(mode, type) {
    if (mode === 0) {
      return (
        <UserMenu
          logOut={this.logOut}
          history={this.props.history}
          click={this.props.click}
        />
      );
    } else if (mode === 1) {
      if (type === 1) {
        return (
          <div>
            <UserMenu
              logOut={this.logOut}
              history={this.props.history}
              click={this.props.click}
            />
          </div>
        );
      }
    } else if (mode === 2) {
      return (
        <div>
          <UserMenu
            logOut={this.logOut}
            history={this.props.history}
            click={this.props.click}
          />
        </div>
      );
    }
  }

  setMode(mode) {
    this.props.getMode(mode);
    if (mode === 1) {
      this.props.history.push('/admin/home');
    } else if (mode === 0) {
      this.props.history.push('/');
    } else if (mode === 2) {
      this.props.history.push('/vendor/home');
    }
  }

  getModeLinks(mode, type) {
    if (type === 1 || type === 3 || type === 4) {
      return (
        <div>
          <a
            href="/vendor/home"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="modelink">Trevel Experiences</span>
          </a>
        </div>
      );
    }
    // else if (mode === 2) {
    //   return (
    //     <div>
    //       <span
    //         onClick={() => {
    //           this.setMode(0);
    //         }}
    //         className="modelink"
    //       >
    //         Exit
    //       </span>
    //     </div>
    //   );
    // } else if (mode === 1) {
    //   return (
    //     <div>
    //       <span
    //         onClick={() => {
    //           this.setMode(0);
    //         }}
    //         className="modelink"
    //       >
    //         Exit
    //       </span>
    //     </div>
    //   );
    // }
  }

  render() {
    let drawerClasses = 'side-drawer';
    if (this.props.show) {
      drawerClasses = 'side-drawer open';
    }
    return (
      <div className={drawerClasses}>
        <div className="section">
          <div className="sidebar-logo">
            <center>
              {/* <img src={beta} /> */}
              <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelUnderlined.svg?alt=media&token=507aa809-2e2c-4529-8b5d-9877e8ed8b38" />
            </center>
          </div>
          {this.getModeLinks(
            this.props.mode,
            this.props.profile.userType,
          )}
          {this.adminLinks(
            this.props.mode,
            this.props.profile.userType,
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: state.profile.profile,
    state: state,
    mode: state.mode.mode,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
    clearProfile: (user) => {
      dispatch(clearProfile(user));
    },
    clearUser: (user) => {
      dispatch(clearUser(user));
    },
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthSideDrawer);
