import { call, put, takeLatest } from "redux-saga/effects";
import { getUserVouchers } from "../../services/UserVoucherServices";
import * as actions from "./actions";

let defaultState = {
  vouchers: {
    status: 0,
  },
};

function* getVouchersAction(action) {
  let vouchers = yield call(getUserVouchers, action.payload);
  yield put({
    type: actions.SET_VOUCHERS,
    payload: {
      ...defaultState,
      vouchers,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_VOUCHERS, getVouchersAction);
}
