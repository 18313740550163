import * as action from './actions';

export const getInvite = (token) => {
  return {
    type: action.GET_INVITE,
    payload: {},
    invite: token,
  };
};

export const setInvite = (token) => {
  return {
    type: action.SET_INVITE,
    payload: token,
  };
};

export const sendInvite = (invite) => {
  return {
    type: action.SEND_INVITE,
    payload: invite,
  };
};

export const clearInvite = (invite) => {
  let defaultState = {
    event: {
      title: null,
    },
  };
  return {
    type: action.CLEAR_INVITE,
    payload: defaultState,
  };
};

const ACTION_HANDLERS = {
  [action.SET_INVITE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_INVITE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SEND_INVITE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.CLEAR_INVITE]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  event: {
    title: null,
  },
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
