import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import MaterialIcon from "material-icons-react";
// Styles
import "./LocationListItem.css";
// Services
import { deleteDocument } from "../../services/AdminServices";

class AdminVendorListItem extends Component {
  constructor() {
    super();
    this.state = {
      loading: false
    };
  }

  getEdit(id) {
    const url = "/admin/vendor/edit/" + id;
    return (
      <div>
        <a href={url} className="right">
          <MaterialIcon icon="edit" size={25} color="#f21d59" />
        </a>{" "}
        <span className="right">
          <MaterialIcon
            icon="delete"
            size={25}
            onClick={() => {
              this.confirmDelete(id);
            }}
          />
        </span>
      </div>
    );
  }

  getPostDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, "x")
      .subtract(10, "days")
      .calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getLocationCount(locations) {
    return Object.keys(locations).length;
  }

  confirmDelete(id) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this vendor?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteDocument("vendors", id, "/admin/vendors")
        },
        {
          label: "No",
          onClick: () => console.log("closed")
        }
      ]
    });
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={6} md={6} sm={6} lg={6} className="left">
                  <span className="label">Business Name</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label"># Locations</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Created</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left"></Col>
              </div>
            </li>
            {this.props.vendors.map((vendor, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col xs={6} md={6} sm={6} lg={6} key={"key" + index}>
                    {vendor.businessName}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {this.getLocationCount(vendor.locations)}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {this.getPostDate(vendor.created)}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {this.getEdit(vendor.id)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminVendorListItem);
