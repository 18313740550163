import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import VendorMenu from '../../components/VendorMenu';
// Components
import TitleBar from '../../components/TitleBar';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';

export class VendorSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
    };
    this.showPicker = this.showPicker.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.title = 'Promote Your Business';
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  getAppContent(filteredLocations) {
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuNew mobileHide">
          <VendorMenu popupMenu={false} />
        </div>
        <div id="vendorScreenBody">
          <div style={{ padding: '10px', margin: '0px auto' }}>
            <div>
              <div className="section">
                <Container>
                  <Row>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <h2>Promote Your Business</h2>
                      <p>
                        What type of promotion would you like to
                        create?
                      </p>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <Link
                        to={{
                          pathname: '/vendor/timedevent/add/promo',
                        }}
                      >
                        <div
                          style={{
                            border: '1px solid #222222',
                            padding: '10px',
                          }}
                        >
                          <center>
                            <h3>Product Promotions</h3>

                            <p>
                              Are you currently running any
                              promotions, like discounts on Food or
                              Beverages? I can help make sure the
                              right people know about it.
                            </p>
                          </center>
                          {/* <div
                            style={{
                              borderTop: '1px solid grey',
                              paddingTop: '10px',
                            }}
                          >
                            <center>
                              <span
                                style={{
                                  fontSize: '13px',
                                  color: 'grey',
                                }}
                              >
                                Examples
                              </span>
                            </center>
                            <Row className="no-padding">
                              <Col
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                className="no-padding"
                              >
                                <ul>
                                  <li>Live Music</li>
                                  <li>Comedy</li>
                                </ul>
                              </Col>
                              <Col
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                className="no-padding"
                              >
                                <ul>
                                  <li>Wine Tasting</li>
                                  <li>Trivia Night</li>
                                </ul>
                              </Col>
                            </Row>
                          </div> */}
                        </div>
                      </Link>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <Link
                        to={{
                          pathname: '/vendor/timedevent/add/',
                        }}
                      >
                        <div
                          style={{
                            border: '1px solid #222222',
                            padding: '10px',
                          }}
                        >
                          <center>
                            <h3>Live Event</h3>
                            <p>
                              Promote your upcoming events like Live
                              Music, Wine Tastings, Charity Events, or
                              anything people would attend.
                            </p>
                          </center>
                          {/* <div
                            style={{
                              borderTop: '1px solid grey',
                              paddingTop: '10px',
                            }}
                          >
                            <center>
                              <span
                                style={{
                                  fontSize: '13px',
                                  color: 'grey',
                                }}
                              >
                                Examples
                              </span>
                            </center>
                            <Row className="no-padding">
                              <Col
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                className="no-padding"
                              >
                                <ul>
                                  <li>Live Music</li>
                                  <li>Comedy</li>
                                </ul>
                              </Col>
                              <Col
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                className="no-padding"
                              >
                                <ul>
                                  <li>Wine Tasting</li>
                                  <li>Trivia Night</li>
                                </ul>
                              </Col>
                            </Row>
                          </div> */}
                        </div>
                      </Link>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <Link
                        to={{
                          pathname:
                            '/vendor/timedevent/add/happyhour',
                        }}
                      >
                        <div
                          style={{
                            border: '1px solid #222222',
                            padding: '10px',
                          }}
                        >
                          <center>
                            <h3>Happy Hour</h3>

                            <p>
                              Do you have a regular Happy Hour? I
                              can't wait to tell people! This is a
                              great event to get started with.
                            </p>
                          </center>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">{title}</span>

              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.vendor === undefined) {
      return null; // or render a loading icon
    }
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Help')}
        <div className="vendorContent">{this.getAppContent()}</div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorSupport);
