import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class TitleBar extends Component {
  getContent() {
    let backlink = '';
    if (this.props.backlink !== '') {
      backlink = this.props.backlink;
      return (
        <Row className="no-margin">
          <Col lg={2} md={2} sm={2} xs={2}>
            <Link
              to={{
                pathname: backlink,
              }}
            >
              Back
            </Link>
          </Col>
          <Col lg={8} md={8} sm={8} xs={8}>
            <center>
              <span className="page-header">{this.props.title}</span>
            </center>
          </Col>
          <Col lg={2} md={2} sm={2} xs={2} />
        </Row>
      );
    } else {
      return (
        <Row className="no-margin">
          <Col lg={12} md={12} sm={12} xs={12}>
            <center>
              <span className="page-header">{this.props.title}</span>
            </center>
          </Col>
        </Row>
      );
    }
  }

  render() {
    return (
      <div id="headers">
        <div className="backbar">
          <Container className="no-padding">
            {this.getContent()}
          </Container>
        </div>
      </div>
    );
  }
}

export default TitleBar;
