import firebase from '../config/firebase';

export function getVendorRewards(vendorId) {
  const rewardArray = [];
  const db = firebase.firestore();
  const rewards = db.collection('vendor_rewards');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = rewards
        .where('vendorId', '==', vendorId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                title,
                details,
                description,
                restrictions,
                creatorInfo,
                locations,
              } = doc.data();
              rewardArray.push({
                id: doc.id,
                title,
                details,
                description,
                restrictions,
                creatorInfo,
                locations,
              });
            }
          });
          return rewardArray;
        })
        .then((result) => {
          resolve(rewardArray);
        });
    });
  };
  return getP();
}
