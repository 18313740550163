import React from 'react';
import { connect } from 'react-redux';
// Components
import VendorProfileForm from '../components/VendorProfileForm';

export class VendorSignUpProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === true) {
      let status = false;
      if (
        this.props.vendor !== undefined &&
        this.props.vendor.vendorTypes !== undefined &&
        this.props.vendor.vendorTypes[0] === 1
      ) {
        if (
          this.props.vendor !== undefined &&
          this.props.vendor.locations !== undefined
        ) {
          status = this.props.vendor.locations.includes(
            this.props.unauth.claimId,
          );
        }
        if (status === true) {
          // this.props.history.push('/vendor/locations');
        }
      } else {
        if (
          this.props.vendor !== undefined &&
          this.props.vendor.entertainment !== undefined
        ) {
          status = this.props.vendor.entertainment.includes(
            this.props.unauth.claimId,
          );
        }
        if (status === true) {
          // this.props.history.push('/vendor/home');
        }
      }
    }
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    let locationId = null;
    if (this.props.match.params.id !== undefined) {
      locationId = this.props.match.params.id;
    } else if (this.props.unauth.claimId !== undefined) {
      locationId = this.props.unauth.claimId;
    }
    return (
      <div id="login">
        <div className="signup-box-container">
          <div className="signup-box">
            <div className="profile-box-title-area">
              <h3>
                {this.props.unauth.claimLink === true
                  ? 'Claim Your Site'
                  : 'Create Your Account'}
              </h3>
              <p className="signup-box-info">
                <span>Step 2 of 2: Your Profile</span>
              </p>
            </div>
            <div id="profileform">
              <VendorProfileForm locationId={locationId} />
            </div>
          </div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'header { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer-container{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    user: state.user,
    vendor: state.vendor.vendor,
    unauth: state.unauth,
  };
}

export default connect(mapStateToProps)(VendorSignUpProfile);
