/* eslint-disable no-undef */
import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Container,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import SearchInput, { createFilter } from 'react-search-input';

const query = require('array-query');
const _ = require('lodash');
const KEYS_TO_FILTERS = ['tag'];

class FilterModal extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      searchTerm: '',
    };
  }

  UNSAFE_componentWillMount() {
    let tagFilters = [];
    if (
      this.props.tagFilters !== null &&
      this.props.tagFilters !== undefined
    ) {
      this.props.tagFilters.forEach((tagcat) => {
        tagFilters.push(tagcat);
      });
    }
    this.setState({
      tagFilters,
    });
  }

  componentDidMount() {}

  getButton(tag) {
    let style = 'tagFilterBtnNone';
    if (tag.status === 1) {
      style = 'tagFilterBtnUser';
    } else if (tag.status === 2) {
      style = 'tagFilterBtnOther';
    } else if (tag.status === 3) {
      style = 'tagFilterBtnMatched';
    }
    if (tag.checked === false) {
      return (
        <span
          className={style}
          onClick={() => {
            this.toggleTag(tag, true);
          }}
        >
          {tag.tag}
        </span>
      );
    } else {
      return (
        <span
          className="tagFilterBtnActive"
          onClick={() => {
            this.toggleTag(tag, false);
          }}
        >
          {tag.tag}
        </span>
      );
    }
  }

  filterTags(tagFilters, locations) {
    let updatedTagFilters = [
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
      {
        id: '5vhCJSC7VgBE1MFNctgY',
        parent: 'Location Type',
        sid: 'locationType',
        tags: [],
      },
      {
        id: '7ayttBEiZgiM5TZ2iYsM',
        parent: 'Cuisine Type',
        sid: 'cuisineType',
        tags: [],
      },
      {
        id: '7kJeYaz9m5q434zO28Pg',
        parent: 'Setting Type',
        sid: 'setting',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Experience Type',
        sid: 'experienceType',
        tags: [],
      },
      {
        id: 'BW3qPhXbN7C4XMXWh4tv',
        parent: 'Date Type',
        sid: 'dateType',
        tags: [],
      },
      {
        id: 'CSKtI08vSBtyqKr10Nef',
        parent: ' Dietary Must Haves',
        sid: 'dietary',
        tags: [],
      },
      {
        id: 'F7R2nsOyZTiqKbW6AhFQ',
        parent: 'Hours',
        sid: 'hours',
        tags: [],
      },
      {
        id: 'xeytdS8Xwjlou67MVbVY',
        parent: 'Beverages',
        sid: 'beverages',
        tags: [],
      },
      {
        id: 'rxsnb1c2XX8c4MpugeEP',
        parent: 'Genre',
        sid: 'genre',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Entertainment Type',
        sid: 'entertainmentType',
        tags: [],
      },
      {
        id: 'eAVFUFVivBObckEaKiRR',
        parent: 'Event Type',
        sid: 'eventType',
        tags: [],
      },
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
    ];
    let allTags = [];
    locations.forEach((location) => {
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.stagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsMain !== undefined) {
        let status = allTags.includes(location.tagsMain);
        if (status === false) {
          allTags.push(location.tagsMain);
        }
      }
    });
    if (tagFilters != undefined) {
      tagFilters.forEach((tagx, indexA) => {
        if (tagx.tags !== undefined) {
          tagx.tags.forEach((tag, indexB) => {
            let status = allTags.includes(tag.tag);
            if (status === true) {
              updatedTagFilters[indexA].tags.push(tag);
            }
          });
        }
      });
    }
    return updatedTagFilters;

    // this.setState({
    //   tagFilters: updatedTagFilters,
    // });
    // this.setTagFilters(updatedTagFilters);
  }

  setInitialTags(tags, locations) {
    let filteredTags = this.filterTags(tags, locations);
    let updatedTags = [];
    filteredTags.forEach((tagcat) => {
      updatedTags.push(tagcat);
    });
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = 1;
        if (this.props.user.authenticated === true) {
          status = this.getTagStatus(tag.tag);
        }
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let drinks = {
    //   checked: false,
    //   parentindex: 6,
    //   status: 1,
    //   tag: 'Drinks',
    //   tagindex: updatedTags[6].tags.length,
    // };

    let food = {
      checked: false,
      parentindex: 1,
      status: 1,
      tag: 'Food',
      tagindex: updatedTags[1].tags.length,
    };

    // updatedTags[6].tags.push(drinks);

    let status = updatedTags[1].tags.includes('Food');
    if (status === false) {
      updatedTags[1].tags.push(food);
    }

    updatedTags.forEach((tagcat, x) => {
      updatedTags[x].tags = updatedTags[x].tags.sort((a, b) =>
        a.tag.localeCompare(b.tag),
      );
      updatedTags[x].tags = updatedTags[x].tags.reverse();
      updatedTags[x].tags.map((tag, y) => {
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let sortedTags = []
    // updatedTags.forEach((tagcat, x) => {
    //   sortedTags = _.sortBy(updatedTags[x].tags, o => o.status);
    // }

    let origionalTags = [];
    updatedTags.forEach((tagcat) => {
      origionalTags.push(tagcat);
    });

    return origionalTags;
  }

  toggleTag(tag, status) {
    let updatedTags = this.props.tagFilters;
    // let origionalTags = this.setInitialTags(
    //   this.props.tags,
    //   this.props.locations,
    // );
    // origionalTags.forEach((tagcat) => {
    //   updatedTags.push(tagcat);
    // });

    updatedTags[tag.parentindex].tags[tag.tagindex].checked = status;
    // console.log('updatedTags', origionalTags);
    this.setState({
      tagFilters: updatedTags,
    });
    this.props.setTagFilters(updatedTags);
  }

  getTagStatus(tag) {
    let userTagsLove = this.props.profile.tags.love;
    let userTagsHate = this.props.profile.tags.hate;
    let otherUserTagsLove = this.props.otherUserTagsLove;
    let otherUserTagsHate = this.props.otherUserTagsHate;

    let status = 0;

    Object.keys(userTagsLove).forEach((key) => {
      userTagsLove[key].forEach((userTag) => {
        if (tag === userTag) {
          status = 1;
          if (
            otherUserTagsLove !== undefined &&
            otherUserTagsLove !== null
          ) {
            Object.keys(otherUserTagsLove).forEach((key) => {
              otherUserTagsLove[key].forEach((otherUserTag) => {
                if (tag === otherUserTag) {
                  status = 3;
                }
              });
            });
          }
        }
      });
    });

    if (
      otherUserTagsLove !== undefined &&
      otherUserTagsLove !== null &&
      status === 0
    ) {
      Object.keys(otherUserTagsLove).forEach((key) => {
        otherUserTagsLove[key].forEach((otherUserTag) => {
          if (tag === otherUserTag) {
            status = 2;
          }
        });
      });
    }

    return status;
  }

  setTags(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = 1;
        if (this.props.user.authenticated === true) {
          status = this.getTagStatus(tag.tag);
        }
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    // let sortedTags = []
    // updatedTags.forEach((tagcat, x) => {
    //   sortedTags = _.sortBy(updatedTags[x].tags, o => o.status);
    // }

    this.setState({
      tagFilters: updatedTags,
    });
    this.props.setTagFilters(updatedTags);
  }

  getSortedFilters(tags) {
    let sortedTags = _.sortBy(tags, (o) => o.status).reverse();
    return sortedTags.map((tag, index) => (
      <Col
        xs={6}
        md={6}
        sm={6}
        lg={6}
        className="no-padding"
        key={tag}
      >
        {this.getButton(tag)}
      </Col>
    ));
  }

  getTagGroup(tagcat) {
    let min = 1;
    let max = 200;
    const x = Math.random() * (max - min + 1) + min;
    let status = false;
    tagcat.tags.forEach((tag, x) => {
      if (tag.checked === true) {
        status = true;
      } else if (this.state.searchTerm.length !== 0) {
        status = true;
      }
    });

    if (tagcat.tags.length !== 0) {
      return (
        <div>
          <a id={tagcat.sid} className="red">
            <Row>
              <Col
                xs={10}
                md={10}
                sm={10}
                lg={10}
                className="no-padding"
              >
                <h5 className="red">{tagcat.parent}</h5>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
                <span className="right">
                  <MaterialIcon
                    icon="keyboard_arrow_down"
                    size={25}
                    color="#f21d59"
                  />
                </span>
              </Col>
            </Row>
          </a>
          <Row className="no-padding">
            <UncontrolledCollapse
              toggler={tagcat.sid}
              defaultOpen={status}
              key={x}
            >
              {this.getSortedFilters(tagcat.tags)}
            </UncontrolledCollapse>
          </Row>
        </div>
      );
    }
  }

  searchedTags(term, tags) {
    if (term === '') {
    }
    let updatedTags = [];

    if (tags !== undefined) {
      tags.forEach((tagcat) => {
        let newTagCat = {};
        let newTags = [];
        tagcat.tags.forEach((tag) => {
          newTags.push(tag);
        });
        newTagCat = {
          id: tagcat.id,
          parent: tagcat.parent,
          sid: tagcat.sid,
          tags: newTags,
        };
        updatedTags.push(newTagCat);
      });

      updatedTags.forEach((tagcat, x) => {
        const filteredList = tagcat.tags.filter(
          createFilter(term, KEYS_TO_FILTERS),
        );

        updatedTags[x].tags = filteredList;
      });
    }

    if (term === '') {
      return tags;
    } else {
      return updatedTags;
    }
  }

  getTags(tags) {
    const tagSections = [];
    tags.forEach((tagcat) => {
      if (tagcat.parent !== 'Hours') {
        tagSections.push(
          <div
            className={tagcat.tags.length !== 0 && 'filterSection'}
            key={tagcat.sid}
          >
            {this.getTagGroup(tagcat)}
          </div>,
        );
      }

      //   tagcat.tags.forEach(tag => {
      //     tagArray.push({ name: tag.tag });
      //   });

      //   for (var i = tagArray.length - 1; i >= 0; i--) {
      //     if (i % 2 === 0) {
      //       evenTags.unshift(tagArray.splice(i, 1)[0]);
      //     } else {
      //       oddTags.unshift(tagArray.splice(i, 1)[0]);
      //     }
      //   }

      //   const test = [
      //     {
      //       name: 'dog',
      //       checked: true
      //     },
      //     {
      //       name: 'cat',
      //       checked: false
      //     }
      //   ];
    });
    return tagSections;
  }

  // getKey() {
  //   if (this.props.otherUser.length !== 0) {
  //     return (
  //       <p>
  //         <b>Tag Colors: </b>
  //         <span className="tag-user">
  //           {/* {this.props.profile.firstName} */}
  //           You
  //         </span>
  //         <span className="tag-otherUser">
  //           {this.props.otherUser.firstName}
  //         </span>
  //         <span className="tag-match">Match!</span>
  //       </p>
  //     );
  //   } else {
  //     return (
  //       <p>
  //         <b>Tag Colors: </b>
  //         <span className="tag-user">You</span>
  //       </p>
  //     );
  //   }
  // }
  searchUpdated = (term) => {
    this.setState({
      searchTerm: term,
    });
  };

  updateOtherUser = (documentSnapshot) => {
    const eventsObject = {};
    eventsObject.events = [];
    const user = documentSnapshot.data();

    let tags = undefined;
    if (user.tags !== undefined) {
      tags = user.tags;
    }

    if (tags !== undefined && tags !== null) {
      this.setState({
        otherUserTagsLove: tags.love,
        otherUserTagsHate: tags.hate,
      });
    } else {
      this.setState({
        otherUserTagsLove: undefined,
        otherUserTagsHate: undefined,
      });
    }
  };

  render() {
    if (
      this.props.tagFilters === null ||
      this.props.tagFilters === undefined ||
      this.props.tagFilters.length === 0
    ) {
      this.setTags(this.props.tags);
      return null;
    }
    let tagFilters = this.props.tagFilters;
    let searchedTags = this.searchedTags(
      this.state.searchTerm,
      tagFilters,
    );

    return (
      <div>
        <SearchInput
          className="search-input"
          onChange={this.searchUpdated}
          value={this.state.searchTerm}
          placeholder="Search Options"
        />
        {/* {this.getKey()} */}
        <Container>
          <Row className="no-margin">
            {this.getTags(searchedTags)}
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tags: state.tags.tags,
    profile: state.profile.profile,
    user: state.user,
  };
}

export default connect(mapStateToProps)(FilterModal);
