import * as action from './actions';

export const getSpotlight = (id) => {
  return {
    type: action.GET_SPOTLIGHT,
    payload: id,
  };
};

export const setSpotlight = (mode) => {
  return {
    type: action.SET_SPOTLIGHT,
    payload: mode,
  };
};

const ACTION_HANDLERS = {
  [action.GET_SPOTLIGHT]: (state, action) => {
    return { ...state };
  },
  [action.SET_SPOTLIGHT]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = { dailySpotlight: false };

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
