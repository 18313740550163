import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SideDrawer.css';
// Images
import logo from '../../images/TrevelUnderlined.svg';
import beta from '../../images/beta.png';

class sideDrawer extends Component {
  authenticatedLinks() {
    if (this.props.user.authenticated === false) {
    } else {
      return (
        <div>
          <ul className="drawerMenuList">
            <li>
              <a href="/events/upcoming" onClick={this.props.click}>
                Upcoming Events
              </a>
            </li>
            <li>
              <a href="/profile" onClick={this.props.click}>
                Profile
              </a>
            </li>
          </ul>
        </div>
      );
    }
  }

  adminLinks() {
    if (this.props.profile.userType === 0) {
    } else {
      return (
        <div>
          <ul className="drawerMenuList">
            <li>
              <a href="/admin/home" onClick={this.props.click}>
                Admin
              </a>
            </li>
          </ul>
        </div>
      );
    }
  }

  authLinks() {
    if (this.props.user.authenticated === true) {
      // <div>
      //   <a href="/admin" onClick={this.props.click}>
      //     Log Out
      //   </a>
      // </div>
    } else {
      return (
        <div>
          <ul className="drawerMenuList">
            <li>
              <a href="/login" onClick={this.props.click}>
                Login
              </a>
            </li>
            <li>
              <a href="/" onClick={this.props.click}>
                Sign Up
              </a>
            </li>
          </ul>
        </div>
      );
    }
  }

  render() {
    let drawerClasses = 'side-drawer';
    if (this.props.show) {
      drawerClasses = 'side-drawer open';
    }
    return (
      <div className={drawerClasses}>
        <div className="section">
          <div className="sidebar-logo">
            <center>
              {/* <img src={beta} /> */}
              <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelUnderlined.svg?alt=media&token=507aa809-2e2c-4529-8b5d-9877e8ed8b38" />
            </center>
          </div>
          <ul className="drawerMenuList">
            <li>
              <a href="/" onClick={this.props.click}>
                Home
              </a>
            </li>
            <li>
              <a href="/articles" onClick={this.props.click}>
                Articles
              </a>
            </li>
            {/* <li>
              <a href="/ourservices">Services</a>
            </li> */}
            {/* <li>
              <a href="/aboutus">About</a>
            </li> */}
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
          </ul>
          {this.authenticatedLinks()}
          {this.authLinks()}
          {this.adminLinks()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: state.profile.profile,
    state: state,
  };
}

export default connect(mapStateToProps)(sideDrawer);
