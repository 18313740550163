import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import { getSpotlight } from '../../sagas/setDailySpotlightSaga/reducer';
import { Link } from 'react-router-dom';
import moment from 'moment';

const query = require('array-query');

class DailyEntertainerSpotlight extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      dailySpotlight: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      loading: false,
    });
  }

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    return to;
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.eventHero;
      } else {
        if (location !== undefined && location.event !== undefined) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.event +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.eventThumb;
      } else {
        if (
          location !== undefined &&
          location.eventThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.eventThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = '';
    if (str !== undefined) {
      result = str.replace(regex, '');
    }

    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  getSpotlight(dailySpotlight) {
    let backgroundStyle = {
      backgroundImage:
        'linear-gradient(45deg,rgba(242, 29, 89, 1),rgba(245, 246, 252, 0)), url(' +
        dailySpotlight.entertainmentHero +
        ')',
      backgroundSize: 'cover',
      minHeight: '400px',
      backgroundPosition: 'center',
      position: 'relative',
    };

    let backgroundStyleMobile = {
      backgroundImage:
        'linear-gradient(45deg,rgba(242, 29, 89, 1),rgba(245, 246, 252, 0)), url(' +
        dailySpotlight.entertainmentHero +
        ')',
      backgroundSize: 'cover',
      minHeight: '200px',
      backgroundPosition: 'center',
      position: 'relative',
    };

    return (
      <Link
        to={{
          pathname: '/entertainment/' + dailySpotlight.slug,
        }}
      >
        <div style={backgroundStyle} className="mobileHide">
          <div className="spotlightText">
            <span className="spotlightTitle white">
              {dailySpotlight.entertainmentTitle}
            </span>

            <span className="white">
              {this.truncate(dailySpotlight.entertainmentDescription)}
            </span>
          </div>
        </div>
        <div style={backgroundStyleMobile} className="mobileShow">
          <div className="spotlightText">
            <span className="spotlightTitle white">
              {dailySpotlight.entertainmentTitle}
            </span>

            <span className="white">
              {this.truncate(dailySpotlight.entertainmentDescription)}
            </span>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    if (this.props.loading) {
      return null; // or render a loading icon
    }
    return <div>{this.getSpotlight(this.props.location)}</div>;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSpotlight: spotlight => {
      dispatch(getSpotlight(spotlight));
    },
  };
};

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    dailySpotlight: state.dailySpotlight,
    connectionsTagsAndFavs: state.tagsAndFavs.connectionsTagsAndFavs,
    timedEvents: state.timedEvents.events,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyEntertainerSpotlight);
