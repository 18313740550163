import * as action from "./actions";

export const getCheckout = checkoutBundle => {
  return {
    type: action.GET_CHECKOUT,
    payload: checkoutBundle
  };
};

export const setCheckout = checkoutBundle => {
  return {
    type: action.SET_CHECKOUT,
    payload: checkoutBundle
  };
};

export const getCustomCheckout = checkoutBundle => {
  return {
    type: action.GET_CUSTOMCHECKOUT,
    payload: checkoutBundle
  };
};

export const setCustomCheckout = checkoutBundle => {
  return {
    type: action.SET_CUSTOMCHECKOUT,
    payload: checkoutBundle
  };
};

const ACTION_HANDLERS = {
  [action.GET_CHECKOUT]: (state, action) => {
    return { ...state };
  },
  [action.SET_CHECKOUT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_CUSTOMCHECKOUT]: (state, action) => {
    return { ...state };
  },
  [action.SET_CUSTOMCHECKOUT]: (state, action) => {
    return { ...state, ...action.payload };
  }
};

let defaultState = {
  checkoutBundle: {
    status: 0,
    selectedVouchers: []
  }
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
