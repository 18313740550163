import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import { RadialChart } from 'react-vis';
import { Col, Row } from 'reactstrap';
import '../../../node_modules/react-vis/dist/style.css';
import '../../../node_modules/react-vis/dist/styles/radial-chart.scss';

const KEYS_TO_FILTERS = ['owner.firstName', 'owner.lastName'];

const query = require('array-query');

export class VendorRewardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      reward: {},
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.getRewardDetails();
  }

  getRewardDetails() {
    const rewardDetails = query('id')
      .is(this.props.match.params.id)
      .on(this.props.vendorRewards);
    let reward = rewardDetails[0];

    let awardArray = [];

    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === reward.id) {
        awardArray.push(award);
      }
    });

    this.setState({
      awards: awardArray,
      reward,
      loading: false,
    });
  }

  getStatus(status) {
    if (status === true) {
      return <span>Redeemed</span>;
    } else {
      return <span className="redeemed">Active</span>;
    }
  }

  getActiveStatus(status) {
    if (status === true) {
      return <span className="right loudStatus">ACTIVE</span>;
    } else {
      return <span className="right loudStatus">INACTIVE</span>;
    }
  }

  getAwardActiveStatus(status) {
    if (status === true) {
      return <span className="right">Active</span>;
    } else {
      return <span className="right">Inactive</span>;
    }
  }

  getEarnedStatus(earnedStatus, redeemedStatus, activeStatus) {
    if (redeemedStatus === true) {
      return <span className="">Redeemed</span>;
    } else if (activeStatus === false) {
      return <span className="">Expired</span>;
    } else if (earnedStatus === true) {
      return <span className="">Earned</span>;
    } else {
      return <span className="">In Progress</span>;
    }
  }

  getEarnedStatusLoud(earnedStatus, redeemedStatus, activeStatus) {
    if (redeemedStatus === true) {
      return <span className="right loudStatus">Redeemed</span>;
    } else if (activeStatus === false) {
      return <span className="right loudStatus">Expired</span>;
    } else if (earnedStatus === true) {
      return <span className="right loudStatus">Earned</span>;
    } else {
      return <span className="right loudStatus">In Progress</span>;
    }
  }

  getHistoryValidationStatus(status) {
    if (status === true) {
      return <span className="green">Approved</span>;
    } else {
      return <span className="red">Rejected</span>;
    }
  }

  getAwardCreatedDate(date) {
    if (date !== null) {
      let timestamp = this.getDate(date);
      return <span>{timestamp}</span>;
    } else {
      return <span className="text-center">-</span>;
    }
  }

  getCount(rewardId, starting) {
    let awardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === rewardId) {
        awardArray.push(award);
      }
    });
    let count = awardArray.length;
    // let remaining = starting - count;
    return <span>{count}</span>;
  }

  getRemaining(rewardId, starting) {
    console.log('');
    let awardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === rewardId) {
        awardArray.push(award);
      }
    });
    let count = awardArray.length;
    let remaining = starting - count;
    return <span>{remaining}</span>;
  }

  getRedeemed(rewardId) {
    let rewardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === rewardId) {
        if (award.redemption.redeemed === true) {
          rewardArray.push(award);
        }
      }
    });
    let count = rewardArray.length;
    return <span>{count}</span>;
  }

  getDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getUnused(reward) {
    // Get Awarded
    let awardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === reward.id) {
        awardArray.push(award);
      }
    });
    let awarded = awardArray.length;
    // Get Redeemed
    let redeemedArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === reward.id) {
        if (award.redemption.redeemed === true) {
          redeemedArray.push(award);
        }
      }
    });
    let redeemed = redeemedArray.length;
    // Get Unused
    let unused = awarded - redeemed;
    return <span>{unused}</span>;
  }

  getRedeemer(award) {
    if (award.redemption.date !== null) {
      return <span>{award.redemption.redeemerFullName}</span>;
    } else {
      return <span className="text-center">-</span>;
    }
  }

  getPromo(reward) {
    const promoDetails = query('id')
      .is(reward.promo.id)
      .on(this.props.vendorPromos);
    let promo = promoDetails[0];
    return <span>{promo.title}</span>;
  }

  getAwards(rewards) {
    return (
      <Row className="no-margin">
        <div className="fullwidth">
          <div className="reportHeader">
            <span className="reportHeaderText">User Rewards</span>
          </div>
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Owner</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Earned</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Created</span>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Promo</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="right">
                  <span className="label">History</span>
                </Col>
              </div>
            </li>
            {rewards.map((award, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {award.owner.firstName} {award.owner.lastName}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {/* <span>{award.criteria[0].validationHistory[0].fullName}</span> */}
                    {this.getEarnedStatus(
                      award.earned,
                      award.redemption.redeemed,
                      award.active,
                    )}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {this.getAwardCreatedDate(
                      award.creationDetails.startDate,
                    )}
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {/* <a
                      onClick={() => {
                        this.showRewardDetails(award);
                      }}
                    >
                      <span>Show History</span>
                    </a> */}
                    <span>{this.getPromo(award)}</span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    <a
                      onClick={() => {
                        this.showRewardDetails(award);
                      }}
                      // className="right"
                    >
                      <span>Show History</span>
                    </a>
                    {/* {this.getRedeemer(award)} */}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  getValidationHistory(validationHistory) {
    return (
      <Row className="no-margin">
        <div className="fullwidth">
          {/* <div className="reportHeader">
            <span className="reportHeaderText">Validation History</span>
          </div> */}
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={6} md={6} sm={6} lg={6} className="left">
                  <span className="label">Validator Name</span>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Status</span>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Date</span>
                </Col>
              </div>
            </li>
            {validationHistory.map((validation, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col xs={6} md={6} sm={6} lg={6} className="left">
                    {validation.fullName}
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {this.getHistoryValidationStatus(
                      validation.validated,
                    )}
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {this.getAwardCreatedDate(validation.date)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  getAwardHistory(reward) {
    const promoDetails = query('id')
      .is(reward.promo.id)
      .on(this.props.vendorPromos);
    let promo = promoDetails[0];

    return (
      <div>
        <div className="wizard-section-border-ten section">
          {this.getEarnedStatusLoud(
            reward.earned,
            reward.redemption.redeemed,
            reward.active,
          )}
          <b>
            <span>Owner:</span>
          </b>{' '}
          <span>
            {reward.owner.firstName} {reward.owner.lastName}
          </span>
          <div>
            <span>Promo: {promo.title}</span>
          </div>
          <div className="clear">
            {/* <span><b>Total Actions</b></span><span>{this.getTotalActions(reward.owner.id)}</span> */}
            {/* <span><b>Total Actions</b></span><span>3</span> */}
          </div>
        </div>
        {reward.criteria.map((criteria, index) => (
          <div className="card section">
            <b>Criteria</b>
            <span>{criteria.text}</span>
            <br />
            {this.getValidationHistory(criteria.validationHistory)}
          </div>
        ))}
      </div>
    );
  }

  searchUpdated(term) {
    console.log('term', this.state.awards);
    this.setState({ searchTerm: term });
  }

  showRewardDetails(reward) {
    this.setState({ showRewardDetails: true, activeReward: reward });
  }

  closeRewardDetails() {
    this.setState({ showRewardDetails: false, activeReward: null });
  }

  getModalTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <a
                onClick={() => {
                  this.closeRewardDetails();
                }}
              >
                <span className="left">
                  <MaterialIcon
                    icon="close"
                    size={25}
                    color="white"
                  />
                </span>
              </a>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Reward Validation History
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/rewards/',
                }}
              >
                <span className="left">
                  <MaterialIcon
                    icon="keyboard_backspace"
                    size={25}
                    color="white"
                  />
                </span>
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">{title}</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/reward/edit/' + id,
                }}
              >
                <span className="right">
                  <MaterialIcon icon="edit" size={25} color="white" />
                </span>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getRewards(reward, index) {
    return (
      <div className="card section">
        <div className="wizard-section-border-ten">
          <span>
            <b>Level {index + 1}:</b>
          </span>{' '}
          <span className="rewardTitle">{reward.title}</span>
        </div>
        {/* <div className="wizard-section-ten">
            <b>Offer Criteria</b> */}
        {/* <p>{promo.criteria.text}</p> */}
        {/* </div> */}
        <Row>
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <div className="wizard-section-ten">
              <Row>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="left no-padding"
                >
                  Awarded:
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="text-right"
                >
                  {this.getCount(reward.id, reward.quantity)}
                </Col>
              </Row>
              <Row>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="left no-padding"
                >
                  Redeemed:
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="text-right"
                >
                  {this.getRedeemed(reward.id)}
                </Col>
              </Row>

              <Row>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="left no-padding"
                >
                  Unused:
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="text-right"
                >
                  {this.getUnused(reward)}
                </Col>
              </Row>

              <Row>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="left no-padding"
                >
                  Available:
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="text-right"
                >
                  {this.getRemaining(reward.id, reward.quantity)}
                </Col>
              </Row>

              <br />

              <Row>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="left no-padding"
                >
                  Max Quantity:
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="text-right"
                >
                  <span>{reward.quantity}</span>
                </Col>
              </Row>
            </div>
          </Col>

          <Col
            xs={6}
            md={6}
            sm={6}
            lg={6}
            className="right no-padding text-center"
          >
            <span className="chart-title">Awards Remaining</span>
            <div id="promoChart">{this.getChart(reward)}</div>
          </Col>
        </Row>
      </div>
    );
  }

  getChart(promo) {
    // Get Starting Quantity
    let starting = promo.quantity;
    // Get Awarded
    let awardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.promo.id === promo.id) {
        awardArray.push(award);
      }
    });
    let awarded = awardArray.length;
    // Get Available
    let available = starting - awarded;
    // Get Redeemed
    let redeemedArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.promo.id === promo.id) {
        if (award.redemption.redeemed === true) {
          redeemedArray.push(award);
        }
      }
    });
    let redeemed = redeemedArray.length;
    // Get Unused
    let unused = awarded - redeemed;

    let unusedPercent = (unused / starting) * 100;
    let availablePercent = (available / starting) * 100;
    let redeemedPercent = (redeemed / starting) * 100;
    console.log('unusedPercent - unusedPercent', unusedPercent);
    console.log('unusedPercent - availablePercent', availablePercent);
    console.log('unusedPercent - redeemedPercent', redeemedPercent);
    const myData = [
      { angle: unusedPercent, label: 'Unused' },
      { angle: redeemedPercent, label: 'Redeemed' },
      { angle: availablePercent, label: 'Available' },
    ];
    return (
      <div>
        {/* <RadialChart data={myData} width={150} height={150} showLabels={true} /> */}
        <RadialChart
          data={myData}
          width={150}
          height={150}
          colorRange={['#b90b3b', '#f21d59']}
          // showLabels={true}
          // labelsRadiusMultiplier={1.34}
          // labelsAboveChildren={true}
        />
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    if (this.state.showRewardDetails === true) {
      let reward = this.state.activeReward;
      return (
        <div id="admin">
          {this.getModalTitleBar()}
          {this.getAwardHistory(reward)}
          <style
            dangerouslySetInnerHTML={{
              __html:
                'body{height: 100%} #bottomNav { display: none } ',
            }}
          />
        </div>
      );
    }

    let rewards = this.state.awards;
    const filteredAwards = rewards.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );

    let reward = this.state.reward;
    return (
      <div id="admin">
        {/* <TitleBar title={promo.title} backlink="/vendor/promos" /> */}
        {this.getTitleBar(reward.title, reward.id)}
        {/* <div className="section promoGrey"> */}
        <div className="section">
          <div className="wizard-section-border-ten">
            <Row className="no-padding">
              <Col
                xs={6}
                md={6}
                sm={6}
                lg={6}
                className="left no-padding"
              >
                {/* <p>
                  Created By:{' '}
                  <b>{promo.creationDetails.creator.fullName}</b>
                </p> */}
                <p>
                  Created:{' '}
                  <b>{this.getDate(reward.creatorInfo.created)}</b>
                </p>
                <p>
                  <b>{reward.details}</b>
                </p>
              </Col>
              <Col
                xs={6}
                md={6}
                sm={6}
                lg={6}
                className="right no-padding"
              >
                {/* {this.getActiveStatus(promo.active)} */}
              </Col>
            </Row>
          </div>
          {/* <div className="section">
            <Row className="no-padding">
              {this.state.promo.rewards.map((reward, index) => (
                <Col xs={12} md={6} sm={12} lg={6}>
                  {this.getRewards(reward, index)}
                </Col>
              ))}
            </Row>
          </div> */}
          <div className="header-container">
            <div className="details-search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
          </div>
        </div>
        <div>{this.getAwards(filteredAwards)}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} #bottomNav { display: none } ',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vendorPromos: state.vendorPromos.promos,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    vendorRewards: state.vendorRewards.rewards,
  };
}

export default connect(mapStateToProps)(VendorRewardDetails);
