import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import banner3 from '../../images/banner3.png';
import banner6 from '../../images/banner6.png';
import buildown from '../../images/buildown.png';
import collab from '../../images/collab.png';
import connected from '../../images/connected.png';
import heartbig from '../../images/heartbig.png';
import rec from '../../images/rec.png';
import travel from '../../images/travel.png';

class Features extends Component {
  render() {
    return (
      <div className="features">
        <div className="feature padding-bottom">
          <div className="wrapper">
            <Row className="no-margin">
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div style={{ padding: '10px 0px 0px 0px' }}>
                  <center>
                    <h3>
                      It{"'"}s Time to{' '}
                      <span
                        style={{
                          fontWeight: '600',
                          fontSize: '36px',
                        }}
                      >
                        Revel in Our Experiences
                      </span>{' '}
                      Again,{' '}
                      <span
                        style={{
                          fontWeight: '600',
                          fontSize: '36px',
                        }}
                      >
                        Together
                      </span>
                    </h3>
                    {/* <h4 className="inline-block">
                      Sign Up - Connect - Live
                    </h4> */}
                  </center>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* <Row className="no-margin">
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="align-middle"
          >
            <div style={{ paddingBottom: '20px' }}>
              <center>
                <video
                  width="100%"
                  max-width="500px"
                  controls
                  controlsList="nodownload"
                  style={{ border: '1px solid #131313' }}
                >
                  <source
                    src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/video%2FTrevel1.mp4?alt=media&token=672a063d-31d3-47eb-b5fb-2ebfeef6dda7"
                    type="video/mp4"
                  />
                </video>
              </center>
            </div>
          </Col>
        </Row> */}
        <div className="feature padding-top padding-bottom">
          <Container>
            <div className="wrapper">
              <Row>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="align-middle"
                >
                  <h4 className="inline-block">
                    Lets Experience Life Together
                  </h4>
                  <p className="subline">
                    Whether you are making plans for a first date, a
                    night out with your spouse, or grabbing drinks
                    with a group of friends, Trevel helps you craft an
                    experience that everyone attending will love.
                  </p>
                  <p className="subline">
                    When you connect with others to create an event
                    their preferences, history, favorite locations and
                    more are combined with yours to find the perfect
                    experience.
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <center>
                    <img
                      height="650"
                      src={connected}
                      // width="609"
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* <div className="feature">
            <Container>
              <div className="wrapper">
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12} className="align-middle">
                    <h3 className="inline-block">Save Your Money</h3>
                    <p className="subline">
                      Many of our vendors create vouchers and rewards and promote
                      them through the app.
                    </p>
                    <p className="subline">
                      Some are limited in number, so don’t miss your chance to
                      save money at some of the best restaurants and bars in town.
                    </p>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <center>
                      <img src={banner6} className="maxed-image" width="auto" />
                    </center>
                  </Col>
                </Row>
              </div>
            </Container>
          </div> */}

        <div className="feature padding-top padding-bottom">
          <Container>
            <div className="wrapper">
              <Row className="no-margin">
                <div className="reverse">
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="align-middle"
                  >
                    <div>
                      <h4 className="inline-block">
                        Make Plans WITH Others
                      </h4>
                      <p className="subline">
                        With Trevel you can make plans on your own, or
                        you can connect with others and make plans
                        together. Our recommendation engine takes into
                        account the group’s likes and dislikes to
                        provide the most fitting options for the
                        group.
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <center>
                      <img
                        src={collab}
                        className="maxed-image"
                        width="auto"
                      />
                    </center>
                  </Col>
                </div>
              </Row>
            </div>
          </Container>
        </div>

        {/* <div className="feature padding-top padding-bottom">
          <Container>
            <div className="wrapper">
              <Row>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="align-middle"
                >
                  <h3 className="inline-block">
                    Plan The Perfect Social Event
                  </h3>
                  <p className="subline">
                    Finding a place to go that <b>everyone</b>{' '}
                    attending will love is key to our mission at
                    Trevel.
                  </p>
                  <p className="subline">
                    When you connect with others to create an event
                    their preferences, history and locations are
                    combined with yours to drive our recommendation
                    engine.
                  </p>
                  <p className="subline">
                    Trevel helps ensure you find a place everyone will
                    love!
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <center>
                    <img
                      height="450"
                      src={connected}
                      // width="609"
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <div className="feature">
          <Container>
            <div className="wrapper">
              <Row className="no-margin">
                <div className="reverse">
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="align-middle"
                  >
                    <div>
                      <h3 className="inline-block">
                        Consierge Service
                      </h3>
                      <p className="subline">
                        We landed on the idea for Trevel because we
                        can{"'"}t always keep up with the food and
                        beverage scene. Whether traveling for work or
                        looking for a new date spot, it can be a
                        little intimidating to plan a night out with
                        others.{' '}
                      </p>
                      <p className="subline">
                        If you{"'"}re like us and want to go out but
                        aren't even sure where to begin, we have
                        already done the thinking for you.{' '}
                      </p>

                      <p className="subline">
                        Select from one of our pre-made itineraries.
                        It{"'"}s super easy and sure to be a great time
                        out at the best local restaurants and bars.{' '}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <center>
                      <img
                        src={rec}
                        className="maxed-image"
                        width="auto"
                      />
                    </center>
                  </Col>
                </div>
              </Row>
            </div>
          </Container>
        </div>

        <div className="feature padding-top padding-bottom">
          <Container>
            <div className="wrapper">
              <Row>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="align-middle"
                >
                  <h3 className="inline-block">
                    Customized Your Social Events
                  </h3>
                  <p className="subline">
                    We call them Socials. If you want to assemble your
                    own itinerary, be our guest. Use our recommended
                    locations or search for a location you might
                    already have in mind if it{"'"}s not in our database
                    (no we don{"'"}t claim to be perfect).
                  </p>

                  <p className="subline">
                    Just search, add, and invite.
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <center>
                    <img
                      src={buildown}
                      className="maxed-image"
                      width="auto"
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <div className="feature">
          <Container>
            <div className="wrapper">
              <Row>
                <div className="reverse">
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="align-middle"
                  >
                    <h3 className="inline-block">Manage Events</h3>
                    <p className="subline">
                      Once an event is created it{"'"}s we make it easy
                      to:
                    </p>
                    <ul>
                      <li>
                        <p className="subline">
                          Invite Trevel users or send a text to anyone
                          you want
                        </p>
                      </li>
                      <li>
                        <p className="subline">
                          Schedule your event date and times
                        </p>
                      </li>
                      <li>
                        <p className="subline">
                          Communicate with everyone attending
                        </p>
                      </li>
                      <li>
                        <p className="subline">
                          Share memories from the event by uploading
                          photos
                        </p>
                      </li>
                    </ul>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <center>
                      <img
                        src={banner3}
                        className="maxed-image"
                        width="auto"
                      />
                    </center>
                  </Col>
                </div>
              </Row>
            </div>
          </Container>
        </div> */}

        <div className="feature padding-top padding-bottom">
          <Container>
            <div className="wrapper">
              <Row>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="align-middle"
                >
                  <h3 className="inline-block">Get There Safely</h3>
                  <p className="subline">
                    Trevel makes it easier then ever to get where you
                    are going for your event.{' '}
                  </p>
                  <p className="subline">
                    Once an event is started you can quickly pull up
                    driving directions for each stop on your
                    itinerary. Or call a ride.
                  </p>

                  <p className="subline">
                    This is our favorite- with one click of a button,
                    use the built in integration with Uber and Lyft
                    let you call a ride with a simple tap. Great for
                    evenings where you might partake in some adult
                    beverages.
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <center>
                    <img
                      height="450"
                      src={travel}
                      // width="609"
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* <div className="feature ">
          <Container>
            <div className="wrapper">
              <Row className="no-margin">
                <Col lg={6} md={6} sm={12} xs={12}>
                  <h3 className="inline-block">
                    Special Built-In Features
                  </h3>
                  <p className="subline">
                    Trevel makes it easier then ever to get where you
                    are going for your event.{' '}
                  </p>
                  <p className="subline">
                    Once an event is started you can quickly pull up
                    driving directions for each stop on your
                    itinerary.{' '}
                  </p>

                  <p className="subline">
                    This is our favorite- with one click of a button,
                    use the built in integration with Uber and Lyft
                    let you call a ride with a simple tap. Great for
                    evenings where you might partake in some adult
                    beverages.
                  </p>
                  <p className="subline">
                    We are constantly looking to add new features to
                    not only help make your evenings easy and fun, but
                    also more safe. More features are soon to come!
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <img
                    width="auto"
                    src={travel}
                    // width="609"
                    className="breakleft desktop"
                  />
                  <center>
                    <img
                      width="auto"
                      src={travel}
                      // width="609"
                      className="mobile maxed-image"
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </Container>
        </div> */}

        {/* <div className="feature padding-top padding-bottom">
          <Container>
            <div className="wrapper">
              <Row>
                <div className="reverse">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <h3 className="inline-block">Date Consultations</h3>
                    <p className="subline">
                      Looking to put together something custom? We can do that.
                      Just reach out to us and we will create something that is
                      exactly what you are looking for.
                    </p>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <center>
                      <img src={banner7} className="maxed-image" />
                    </center>
                  </Col>
                </div>
              </Row>
            </div>
          </Container>
        </div> */}
      </div>
    );
  }
}

export default Features;
