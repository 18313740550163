import React from "react";
import { Container, Row, Col } from "reactstrap";
import TitleBar from "../components/TitleBar";
import ContactUsForm from "../components/ContactUsForm";

export default class Feeback extends React.Component {
  render() {
    return (
      <div id="feedback">
        <TitleBar title="Provide Feedback" backlink="" />
        <div id="contactForm" className="section">
          <Container>
            <Row>
              <Col lg={5} md={5} sm={12} xs={12}>
                <p>
                  Have a favorite location that we missed?{" "}
                  <b>Send it our way.</b>
                </p>
                <p>
                  Have a comment about how we are doing? <b>Let us know.</b>
                </p>
                <p>
                  Have an idea on how we can improve? <b>Please reach out.</b>
                </p>
                <br />
                {/* <CompanyInfo /> */}
              </Col>
              <Col lg={7} md={7} sm={12} xs={12}>
                <center>
                  <ContactUsForm />
                </center>
              </Col>
            </Row>
          </Container>

          {/* <Link to={`/packages`}>Packages</Link> */}
        </div>
      </div>
    );
  }
}
