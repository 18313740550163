import { call, put, takeLatest } from 'redux-saga/effects';
import {
  setFavorites,
  setEntertainmentFavorites,
} from '../../services/FavoritesServices';
import * as actions from './actions';
import { getUpdatedProfileFavorites } from '../getProfileSaga/reducer';

let defaultState = {
  events: {},
};

function* setFavoritesAction(action) {
  if (action.payload.type === 1) {
    let favoritesSet = yield call(setFavorites, action);
    yield put(getUpdatedProfileFavorites(action.payload.userId));
  } else if (action.payload.type === 2) {
    let favoriteEntertainmentSet = yield call(
      setEntertainmentFavorites,
      action,
    );
    yield put(getUpdatedProfileFavorites(action.payload.userId));
  }
}

export default function* sagas() {
  yield takeLatest(actions.SET_FAVORITES, setFavoritesAction);
}
