import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import { Link } from 'react-router-dom';
// Topics
import TopicAccount from '../components/HelpTopics/TopicAccount';
import TopicCreateEvent from '../components/HelpTopics/TopicCreateEvent';
import TopicProfile from '../components/HelpTopics/TopicProfile';
import TopicPromotions from '../components/HelpTopics/TopicPromotions';
import TopicScrapbook from '../components/HelpTopics/TopicScrapbook';
import TopicStartEvent from '../components/HelpTopics/TopicStartEvent';
import TopicUpcomingEvents from '../components/HelpTopics/TopicUpcomingEvents';
import TopicYourContacts from '../components/HelpTopics/TopicYourContacts';
import TopicYourPreferences from '../components/HelpTopics/TopicYourPreferences';

export class Support extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      step: 1,
      showHelp: false,
    };
  }

  UNSAFE_componentWillMount() {
    // if (this.props.user.authenticated === false) {
    //   window.location.replace('http://trevel.com/login');
    // }
  }

  componentDidMount() {
    document.title = 'Help';

    if (this.props.match.params.topic !== undefined) {
      let topic = parseInt(this.props.match.params.topic, 10);
      this.showHelp(topic, '');
    }
  }

  showHelp(topic, topicTitle) {
    this.setState({ topic, topicTitle, showHelp: true });
  }

  hideHelp() {
    this.setState({ showHelp: false });
  }

  getHelpTopic(topic) {
    if (topic === 1) {
      return (
        <div>
          <TopicCreateEvent />
        </div>
      );
    } else if (topic === 2) {
      return (
        <div>
          <TopicUpcomingEvents />
        </div>
      );
    } else if (topic === 3) {
      return (
        <div>
          <TopicYourPreferences />
        </div>
      );
    } else if (topic === 4) {
      return (
        <div>
          <TopicScrapbook />
        </div>
      );
    } else if (topic === 5) {
      return (
        <div>
          <TopicYourContacts />
        </div>
      );
    } else if (topic === 6) {
      return (
        <div>
          <TopicStartEvent />
        </div>
      );
    } else if (topic === 7) {
      return (
        <div>
          <TopicProfile />
        </div>
      );
    } else if (topic === 8) {
      return (
        <div>
          <TopicAccount />
        </div>
      );
    } else if (topic === 9) {
      return (
        <div>
          <TopicPromotions />
        </div>
      );
    }
  }

  getTitle() {
    if (this.state.showHelp === false) {
      return (
        <center>
          <span className="page-header">Need Some Help?</span>
        </center>
      );
    } else {
      return (
        <center>
          <span className="page-header">{this.state.topicTitle}</span>
        </center>
      );
    }
  }

  getBackButton() {
    let returnPath = '/';
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.returnPath !== undefined
    ) {
      returnPath = this.props.location.state.returnPath;
    }

    if (this.state.showHelp === true) {
      return (
        <span className="left">
          <MaterialIcon
            icon="keyboard_backspace"
            size={25}
            color="white"
            onClick={() => {
              this.hideHelp();
            }}
          />
        </span>
      );
    } else {
      return (
        <Link
          to={{
            pathname: returnPath,
          }}
          className="left"
        >
          <MaterialIcon
            icon="keyboard_backspace"
            size={25}
            color="white"
          />
        </Link>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          {/* <Row className="center" style={{ maxWidth: '1200px' }}> */}
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {this.getBackButton()}
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Trevel Knowledge Base
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <a
                onClick={value => {
                  this.saveTags(this.props.user.id);
                }}
                className="right"
              >
                <MaterialIcon icon="check" size={25} color="white" />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.showHelp === true) {
      return (
        <div id="support">
          {this.getTitleBar()}
          <div className="section">
            <Container>
              <Row className="wizard-section">
                <Col className="no-padding">
                  {this.getHelpTopic(this.state.topic)}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
    return (
      <div id="support">
        {this.getTitleBar()}
        <div className="section">
          <Container>
            {/* <Row className='wizard-section-border-ten'>
              <Col className='no-padding'>

              </Col>
            </Row> */}
            <Row className="wizard-section">
              <Col className="no-padding">
                <div className="help-topic">
                  <a
                    onClick={() => {
                      this.showHelp(1, 'Create an Event');
                    }}
                  >
                    <span>Create New Experience</span>
                  </a>
                </div>
                <div className="help-topic">
                  <a
                    onClick={() => {
                      this.showHelp(2, 'Upcoming Events');
                    }}
                  >
                    <span>Upcoming Events</span>
                  </a>
                </div>
                <div className="help-topic">
                  <a
                    onClick={() => {
                      this.showHelp(6, 'Starting Events');
                    }}
                  >
                    <span>Starting Events</span>
                  </a>
                </div>
                <div className="help-topic">
                  <a
                    onClick={() => {
                      this.showHelp(3, 'Your Preferences');
                    }}
                  >
                    <span>Your Preferences</span>
                  </a>
                </div>
                <div className="help-topic">
                  <a
                    onClick={() => {
                      this.showHelp(4, 'Scrapbook');
                    }}
                  >
                    <span>Scrapbook</span>
                  </a>
                </div>
                <div className="help-topic">
                  <a
                    onClick={() => {
                      this.showHelp(9, 'Promotions');
                    }}
                  >
                    <span>Promotions</span>
                  </a>
                </div>

                <div className="help-topic">
                  <a
                    onClick={() => {
                      this.showHelp(5, 'Your Contacts');
                    }}
                  >
                    <span>Your Contacts</span>
                  </a>
                </div>

                <div className="help-topic">
                  <a
                    onClick={() => {
                      this.showHelp(7, 'Your Profile');
                    }}
                  >
                    <span>Your Profile</span>
                  </a>
                </div>
                <div className="help-topic">
                  <a
                    onClick={() => {
                      this.showHelp(8, 'Account Settings');
                    }}
                  >
                    <span>Account Settings</span>
                  </a>
                </div>

                {/* <div className='help-topic'>
                  <a
                    onClick={() => {
                      this.showHelp(2, 'Edit an Event');
                    }}
                  >
                    <span>Edit an Event</span>{' '}
                  </a>
                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    tags: state.tags.tags,
    user: state.user,
  };
}

export default connect(mapStateToProps)(Support);
