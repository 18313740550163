import React from "react";
import { Col, Container, Row } from "reactstrap";
import RedCallToAction from "../components/RedCallToAction";
// Images
import heartbig from "../images/heartbig.png";

export default class AboutUs extends React.Component {
  render() {
    return (
      <div>
        <div id="aboutUs">
          <div className="padding-top-fifteen">
            <div className="padding-top-fifteen padding-bottom">
              <Container>
                {/* <div className="wrapper"> */}
                <Row>
                  <Col lg={8} md={8} sm={12} xs={12} className="no-padding">
                    <h1>Welcome to Trevel</h1>
                    <p>
                      We found that creating a memorable evening out is a lot
                      more difficult than it should be and we wanted to provide
                      a solution. Thus, Trevel was born.{" "}
                    </p>
                    <p>
                      Everyone has their go-to spots for social gatherings and
                      dates, but sometimes you want to try something new or in
                      some instances, you need plans on the fly. That’s why we
                      exist. We want to make it easier for you to plan your
                      evenings with the ones you care about, and we want to make
                      sure you get to your destinations and back home safely
                      (mostly because our itineraries involve having a drink or
                      two).{" "}
                      {/* We thank you for trusting us with planning your
                    evening out, we know that it’s those times with friends and
                    loved ones that shape our memories and our lives. */}
                    </p>
                    <h3>Our Mission</h3>
                    <p>
                      Encourage people to experience the world with others in
                      order to bring us all closer together.
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <center>
                      <img
                        src={heartbig}
                        className="maxed-image"
                        width="auto"
                        alt="heart-logo"
                      />
                    </center>
                  </Col>
                </Row>
                <hr />
                {/* </div> */}
              </Container>
            </div>
          </div>
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <center>
                  <h2>Mike & Mark</h2>
                  <p>Coming Soon</p>
                </center>
              </Col>
            </Row>
            {/* <Row>
              <AboutUsBoxProfile
                name="Mike Levine"
                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id sapien eleifend, imperdiet diam in, dignissim dui. Nullam ac justo quis dui sodales rhoncus eget sed metus. Aenean non lacus nec odio bibendum laoreet. "
                favdrink="Old Fashion"
                image={heartbig}
              />
              <AboutUsBoxProfile
                name="Mark Errante"
                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id sapien eleifend, imperdiet diam in, dignissim dui. Nullam ac justo quis dui sodales rhoncus eget sed metus. Aenean non lacus nec odio bibendum laoreet. "
                favdrink="Old Fashion"
                image="mike"
              />
            </Row> */}
          </Container>

          <RedCallToAction />
        </div>
      </div>
    );
  }
}
