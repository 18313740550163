import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
// Styles
import './PackageItinerary.css';

const query = require('array-query');

class PackageItinerary extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      locationsArray: [],
    };
  }
  static defaultProps = {
    zoom: 15,
  };

  componentDidMount() {
    if (this.props.user.authenticated === true) {
      if (this.props.demo !== undefined && this.props.demo === true) {
        const locationsArray = [];
        this.props.locations.forEach((location) => {
          const thislocation = query('locationId')
            .is(location.locationId)
            .on(this.props.demoLocations);
          if (thislocation[0] !== undefined) {
            locationsArray.push(thislocation[0]);
          }
        });
        this.setState({
          locationsArray: locationsArray,
          loading: false,
        });
      } else {
        const locationsArray = [];
        this.props.locations.forEach((location) => {
          const thislocation = query('locationId')
            .is(location.locationId)
            .on(this.props.alllocations);
          if (thislocation[0] !== undefined) {
            locationsArray.push(thislocation[0]);
          }
        });
        this.setState({
          locationsArray: locationsArray,
          loading: false,
        });
      }
    } else {
      if (this.props.nonauthLocations !== undefined) {
        this.setState({
          locationsArray: this.props.nonauthLocations,
          loading: false,
        });
      } else if (
        this.props.demo !== undefined &&
        this.props.demo === true
      ) {
        const locationsArray = [];
        this.props.locations.forEach((location) => {
          const thislocation = query('locationId')
            .is(location.locationId)
            .on(this.props.demoLocations);
          if (thislocation[0] !== undefined) {
            locationsArray.push(thislocation[0]);
          }
        });
        this.setState({
          locationsArray: locationsArray,
          loading: false,
        });
      } else {
        const locationsArray = [];
        this.props.locations.forEach((location) => {
          const thislocation = query('locationId')
            .is(location.locationId)
            .on(this.props.alllocations[0]);
          if (thislocation[0] !== undefined) {
            locationsArray.push(thislocation[0]);
          }
        });
        this.setState({
          locationsArray,
          loading: false,
        });
      }
    }
  }

  setHappyHourArray(happyHours) {
    const happyHourArray = [];
    if (happyHours.length !== 0) {
      happyHours.forEach((happyHour) => {
        if (happyHour.happyHourType !== 2) {
          happyHourArray.push(happyHour);
        }
      });
    }
    return happyHourArray;
  }

  setPromoArray(happyHours) {
    let promoArray = [];
    if (happyHours.length !== 0) {
      happyHours.forEach((happyHour) => {
        if (happyHour.happyHourType === 2) {
          promoArray.push(happyHour);
        }
      });
    }
    return promoArray;
  }

  happyHourSection(happyHours) {
    let happyHourArray = this.setHappyHourArray(happyHours);
    if (happyHourArray.length === 0) {
      return <p>No HappyHours Available</p>;
    } else {
      return (
        <div className="happyHour-container">
          {happyHourArray.map((happyHour) => (
            <div className="happyHour">
              <p>{happyHour.happyHourPromo}</p>
              {/* <button className="purchase">Select HappyHour</button> */}
            </div>
          ))}
        </div>
      );
    }
  }

  getImage(type, locationStart) {
    let location = '';

    if (this.props.user.authenticated === true) {
      if (this.props.demo === true) {
        let locations = this.props.demoLocations;
        const locationDetails = query('locationId')
          .is(locationStart.locationId)
          .on(locations);
        location = locationDetails[0];
      } else {
        let locations = this.props.alllocations;
        const locationDetails = query('locationId')
          .is(locationStart.locationId)
          .on(locations);
        location = locationDetails[0];
      }
    } else {
      location = locationStart;
      // this.props.nonauthLocations
    }

    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  promoSection(happyHours) {
    const promoArray = this.setPromoArray(happyHours);
    if (promoArray.length === 0) {
      return <p>No Promos Available</p>;
    } else {
      return (
        <div className="happyHour-container">
          {promoArray.map((promo) => (
            <div className="happyHour">
              <p>{promo.happyHourPromo}</p>
              {/* <button className="purchase">Select HappyHour</button> */}
            </div>
          ))}
        </div>
      );
    }
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return <span className="mainTag">{location.tagsMain}</span>;
    }
  }

  getMatches(location) {
    let userLoveTags = [];
    let otherUserLoveTags = [];
    let matchLoveTags = [];
    let userHateTags = [];
    let otherUserHateTags = [];
    let matchHateTags = [];
    let matchesUserHate = [];
    if (location.matchesUserHate !== undefined) {
      matchesUserHate = location.matchesUserHate;
    }
    let matchesUserLove = [];
    if (location.matchesUserLove !== undefined) {
      matchesUserLove = location.matchesUserLove;
    }
    let matchesOtherUserHate = [];
    if (location.matchesOtherUserHate !== undefined) {
      matchesOtherUserHate = location.matchesOtherUserHate;
    }
    let matchesOtherUserLove = [];
    if (location.matchesOtherUserLove !== undefined) {
      matchesOtherUserLove = location.matchesOtherUserLove;
    }
    matchesUserHate.forEach((userMatch) => {
      if (matchesOtherUserHate.length !== 0) {
        matchesOtherUserHate.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            matchHateTags.push(userMatch);
          } else {
            userHateTags.push(userMatch);
          }
        });
      }
    });

    matchesUserLove.forEach((userMatch) => {
      let matched = [];
      if (matchesOtherUserLove.length !== 0) {
        matchesOtherUserLove.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            matched.push(userMatch);
          }
        });
      }
      if (matched.length !== 0) {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          matchLoveTags.push(userMatch);
        }
      } else {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          userLoveTags.push(userMatch);
        }
      }
    });

    matchesOtherUserLove.forEach((userMatch) => {
      let matched = [];
      if (matchesUserLove.length !== 0) {
        matchesUserLove.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            if (matchLoveTags.indexOf(userMatch) === 0) {
              matched.push(userMatch);
            }
          }
        });
      }
      if (matched.length !== 0) {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          matchLoveTags.push(userMatch);
        }
      } else {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          otherUserLoveTags.push(userMatch);
        }
      }
    });

    matchesOtherUserHate.forEach((userMatch) => {
      if (matchesUserHate.length !== 0) {
        matchesUserHate.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            if (matchHateTags.indexOf(userMatch) === 0) {
              matchHateTags.push(userMatch);
            }
          } else {
            if (matchHateTags.indexOf(userMatch) === 0) {
              otherUserHateTags.push(userMatch);
            }
          }
        });
      }
    });

    return (
      <div>
        {userLoveTags.map((tag, index) => (
          <span className="tag-user">{tag}</span>
        ))}
        {otherUserLoveTags.map((tag, index) => (
          <span className="tag-otherUser">{tag}</span>
        ))}
        {matchLoveTags.map((tag, index) => (
          <span className="tag-match">{tag}</span>
        ))}
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    const type = this.props.type;
    return (
      <div>
        <div className="itinerary">
          {this.state.locationsArray.map((location, index) => (
            <div className="location-info" key={'key' + index}>
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                  key={'key' + index}
                >
                  <Row>
                    <Col
                      xs={4}
                      md={4}
                      sm={4}
                      lg={4}
                      className="no-padding thumbnailColSmall"
                    >
                      <Link
                        to={{
                          pathname:
                            '/location/' + location.locationId,
                          state: {
                            location: { location },
                            packageId: this.props.packageId,
                            type: this.props.type,
                            eventId: this.props.eventId,
                          },
                        }}
                      >
                        {this.getImage('thumb', location)}
                      </Link>
                    </Col>
                    <Col xs={8} md={8} sm={8} lg={8}>
                      <Link
                        to={{
                          pathname:
                            '/location/' + location.locationId,
                          state: {
                            location: { location },
                            packageId: this.props.packageId,
                            type: this.props.type,
                            eventId: this.props.eventId,
                          },
                        }}
                      >
                        <div>
                          <span className="location-title">
                            {location.locationTitle}
                          </span>
                          {/* <span className="address">
                           
                          </span> */}
                          <span className="address">
                            {location.address.street},{' '}
                            {location.address.city}{' '}
                            {location.address.state},{' '}
                            {location.address.zip}
                          </span>
                        </div>
                        {this.getMainTag(location)}
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                    ></Col>
                  </Row>
                  {/* <Row className="no-margin">
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                    >
                      <div className="happyHourListing">
                        <span className="location-title">Promotions</span> 
                        {this.happyHourSection(location.happyHours)}
                      </div>
                      <div className="card-section">
                        <div className="happyHourListing">
                          <span className="location-title">
                            Happy Hours
                          </span>
                          {this.promoSection(location.happyHours)}
                        </div>
                      </div>
                    </Col>
                  </Row> */}
                </Col>
              </Row>
            </div>
          ))}
        </div>
        <br />
        {/* <button className="checkout">Checkout</button> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    user: state.user,
  };
}

export default connect(mapStateToProps)(PackageItinerary);
