import { call, put, takeLatest } from "redux-saga/effects";
import { getNotifications } from "../../services/NotificationServices";
import { push } from "connected-react-router";
import * as actions from "./actions";

let defaultState = {
  notifications: [],
};

function* getNotificationsAction(action) {
  let notifications = yield call(getNotifications, action.payload);
  yield put({
    type: actions.SET_NOTIFICATIONS,
    payload: {
      ...defaultState,
      notifications,
    },
  });
}

function* refreshNotificationsAction(action) {
  const notifications = action.payload;
  yield put({
    type: actions.SET_NOTIFICATIONS,
    payload: {
      ...defaultState,
      notifications,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_NOTIFICATIONS, getNotificationsAction);
  yield takeLatest(actions.REFRESH_NOTIFICATIONS, refreshNotificationsAction);
}
