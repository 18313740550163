import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';

import VendorMenu from '../../components/VendorMenu';
import VendorProfileFormNew from '../../components/VendorProfileFormNew';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';

export class VendorTrevelExperiencesSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
      vendorType: null,
      step: 1,
    };
    this.showPicker = this.showPicker.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  UNSAFE_componentWillMount() {
    // if (this.props.user.authenticated === true) {
    //   if (this.props.profile.vendorAccount !== null) {
    //     this.props.history.push('/vendor/home');
    //   }
    // }
    if (this.props.unauth.vendorType !== undefined) {
      this.setState({
        vendorType: this.props.unauth.vendorType,
        step: 2,
      });
    }
  }

  componentDidMount() {
    document.title = 'Trevel Experiences - Signup';
  }

  setStep(step) {
    this.setState({
      step,
    });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  setVendorType(mode) {
    this.setState({
      vendorType: mode,
    });
  }

  getTitleBar(title) {
    // const authLink = this.authLink();
    return (
      <div id="headersTop">
        <div className="toolbarSignup mobileHide">
          <div className="toolbar__navigation " id="full-navSignup">
            <Row className="no-margin">
              <Col lg={8} md={8} sm={8} xs={8} className="no-padding">
                <div className="toolbar__logo">
                  <Link
                    to={{
                      pathname: '/experiences',
                    }}
                  >
                    <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880" />
                  </Link>
                </div>
              </Col>

              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                <div className="toolbar_navigation_authlinks">
                  {/* {this.authLink()} */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="toolbarSignup mobileShow">
          <div className="toolbar__navigation ">
            <Container>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="no-padding"
                >
                  <center>
                    <div className="toolbar__logo">
                      <Link
                        to={{
                          pathname: '/experiences',
                        }}
                      >
                        <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880" />
                      </Link>
                    </div>
                  </center>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="no-padding"
                >
                  <center>
                    <h4 style={{ marginTop: '10px' }}>
                      Create Your Account
                    </h4>
                  </center>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  getAppContent() {
    if (this.state.vendorType === null) {
      return (
        <div
          style={{
            height: '100%',
          }}
          id="vendor"
        >
          <div style={{ margin: '5% auto', maxWidth: '1000px' }}>
            <div
              style={{
                maxWidth: '500px',
                width: '90%',
                margin: '0px auto',
                paddingBotton: '40px',
              }}
            >
              <img
                style={{
                  width: '100%',
                }}
                src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880"
              />
            </div>
            <center>
              <h2
                style={{
                  margin: '40px 0px 70px 0px',
                }}
                className="mobileHide"
              >
                {this.state.step === 1
                  ? 'Account Information'
                  : 'Setup Your Account'}
              </h2>
              <h4
                style={{
                  margin: '10px 0px 20px 0px',
                }}
                className="mobileShow"
              >
                {this.state.step === 1
                  ? 'Account Information'
                  : 'Setup Your Account'}
              </h4>
            </center>
          </div>
          <div
            style={{
              maxWidth: '800px',
              margin: '0px auto',
            }}
          >
            <Row>
              <Col
                xs={12}
                md={6}
                sm={12}
                lg={6}
                style={{ height: '100%' }}
              >
                <a
                  onClick={() => {
                    this.setVendorType(1);
                  }}
                >
                  <div className="homebaseApp">
                    <center>
                      <span
                        style={{
                          fontSize: '30px',
                          fontWeight: '100',
                          color: '#f21d59',
                        }}
                      >
                        Destination
                      </span>

                      <span
                        style={{
                          fontSize: '18px',
                          fontWeight: '100',
                          clear: 'both',
                          display: 'block',
                          margin: '10px 0px 0px 0px',
                          color: 'dimgrey',
                          maxWidth: '350px',
                        }}
                      >
                        You represent a location, like a resteraunt,
                        bar or venue.
                      </span>
                    </center>
                  </div>
                </a>
              </Col>
              <Col
                xs={12}
                md={6}
                sm={12}
                lg={6}
                style={{ height: '100%' }}
              >
                <a
                  onClick={() => {
                    this.setVendorType(2);
                  }}
                >
                  <div className="homebaseApp">
                    <center>
                      <span
                        style={{
                          fontSize: '30px',
                          fontWeight: '100',
                          color: '#f21d59',
                        }}
                      >
                        Entertainment
                      </span>

                      <span
                        style={{
                          fontSize: '18px',
                          fontWeight: '100',
                          clear: 'both',
                          display: 'block',
                          margin: '10px 0px 0px 0px',
                          color: 'dimgrey',
                          maxWidth: '350px',
                        }}
                      >
                        You provide entertainment for people, like a
                        musician.
                      </span>
                    </center>
                  </div>
                </a>
              </Col>
            </Row>
          </div>

          <div
            style={{
              padding: '30px',
              border: '1px solid lightgrey',
              margin: '0px auto',
              width: '90%',
              marginTop: '100px',
              background: 'white',
              display: 'flex',
            }}
          >
            <Col xs={1} md={1} sm={1} lg={1}>
              <div style={{ float: 'left' }}>
                <MaterialIcon
                  icon="help_outline"
                  size={35}
                  color="#353535"
                />
              </div>
            </Col>

            <Col xs={11} md={11} sm={11} lg={11}>
              <h4 style={{ fontSize: '18px', marginBottom: '10px' }}>
                Join the Community
              </h4>
              <p style={{ padding: '0px', margin: '0px' }}>
                Trevel helps local businesses and entertainers connect
                to create Experiences together.
              </p>
            </Col>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'body{height: 100%} header { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer-container{display:none} .full-height{height:100%}',
            }}
          />
        </div>
      );
    } else if (this.state.vendorType === 1) {
      return (
        <div
          style={{
            height: '100%',
          }}
          id="vendor"
        >
          <div>
            <div style={{ margin: '5% auto', maxWidth: '1000px' }}>
              <div
                style={{
                  maxWidth: '500px',
                  width: '90%',
                  margin: '0px auto',
                  paddingBotton: '40px',
                }}
              >
                <img
                  style={{
                    width: '100%',
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880"
                />
              </div>
              <center>
                <h2
                  style={{
                    margin: '40px 0px 70px 0px',
                  }}
                  className="mobileHide"
                >
                  {this.state.step === 1
                    ? 'Account Information'
                    : 'Setup Your Account'}
                </h2>
                <h4
                  style={{
                    margin: '10px 0px 20px 0px',
                  }}
                  className="mobileShow"
                >
                  {this.state.step === 1
                    ? 'Account Information'
                    : 'Setup Your Account'}
                </h4>
              </center>
              <Container>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ height: '100%' }}
                  >
                    <VendorProfileFormNew
                      vendorType={this.state.vendorType}
                      setStep={this.setStep}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ height: '100%' }}
                    className="mobileHide"
                  >
                    <div>
                      <center>
                        <h4>Up Next!</h4>
                        <h5>Setup Your Location Page</h5>
                      </center>
                      <center>
                        <img
                          style={{
                            width: '90%',
                            border: '1px solid black',
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FprofileDestination.png?alt=media&token=68b24220-20a5-4fa1-9696-dfad6aa639b3"
                        />
                      </center>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      );
    } else if (this.state.vendorType === 2) {
      return (
        <div
          style={{
            height: '100%',
          }}
          id="vendor"
        >
          <div>
            <div style={{ margin: '5% auto', maxWidth: '1000px' }}>
              <div
                style={{
                  maxWidth: '500px',
                  width: '90%',
                  margin: '0px auto',
                  paddingBotton: '40px',
                }}
              >
                <img
                  style={{
                    width: '100%',
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880"
                />
              </div>
              <center>
                <h2
                  style={{
                    margin: '40px 0px 70px 0px',
                  }}
                  className="mobileHide"
                >
                  {this.state.step === 1
                    ? 'Account Information'
                    : 'Setup Your Account'}
                </h2>
                <h4
                  style={{
                    margin: '10px 0px 20px 0px',
                  }}
                  className="mobileShow"
                >
                  {this.state.step === 1
                    ? 'Account Information'
                    : 'Setup Your Account'}
                </h4>
              </center>
              <Container>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ height: '100%' }}
                  >
                    <div
                      className="mobileHide"
                      style={{ height: '10px' }}
                    />
                    <VendorProfileFormNew
                      vendorType={this.state.vendorType}
                      setStep={this.setStep}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ height: '100%' }}
                    className="mobileHide"
                  >
                    <div>
                      <center>
                        <h4>Up Next!</h4>
                        <h5>
                          Create Your Home Page & Add Your Events
                        </h5>
                      </center>
                      <center>
                        <img
                          style={{
                            width: '90%',
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FprofileEntertainment.png?alt=media&token=173ec085-b3c2-466a-8b67-6ea35b55bec6"
                        />
                      </center>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {/* {this.props.user.authenticated === true &&
          this.getTitleBar('Trevel Experiences')} */}

        <div className="vendorContent">{this.getAppContent()}</div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              ' .hideThis { display: none } .feature{padding: 10px 0px 20px 0px} .toolbar{display:none}',
          }}
        />
        <style
          dangerouslySetInnerHTML={{
            __html:
              ' #mobile-nav{display: none} #analytics{height: 100%} .maincontent{display: grid} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
    user: state.user,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    vendorPromos: state.vendorPromos.promos,
    unauth: state.unauth,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorTrevelExperiencesSignUp);
