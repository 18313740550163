import { call, put, takeLatest } from 'redux-saga/effects';
import { getVendor } from '../vendorSaga/reducer';
import { getLocations } from '../locationsSaga/reducer';
import * as actions from './actions';
import { push } from 'connected-react-router';

let defaultState = {
  locations: [],
};

function* getVendorLocationsAction(payload) {
  let zoneId = payload.payload.zoneId;
  let url = '/vendor/locations';
  let bundle = {
    claim: false,
    userId: payload.payload.vendorId,
  };
  yield put(getLocations(zoneId));
  yield put(getVendor(bundle));
  yield put({
    type: actions.SET_VENDORLOCATIONS,
    payload: {
      status: true,
    },
  });
  yield put(push(url));
}

export default function* sagas() {
  yield takeLatest(
    actions.GET_VENDORLOCATIONS,
    getVendorLocationsAction,
  );
}
