import { call, put, takeLatest } from 'redux-saga/effects';
import { getAllUserEvents } from '../../services/UserEventServices';

import * as actions from './actions';

let defaultState = {
  events: [],
};

function* getEventsAction() {
  let events = yield call(getAllUserEvents);
  yield put({
    type: actions.SET_ALLEVENTS,
    payload: {
      ...defaultState,
      events,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GETALL_EVENTS, getEventsAction);
}
