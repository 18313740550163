import firebase from '../config/firebase';

export function getVendorTimedEvents(vendorId) {
  console.log('vendorTimedEventsxx servicce', vendorId);
  const eventArray = [];
  const db = firebase.firestore();
  const events = db.collection('vendor_events');
  const getP = function () {
    return new Promise((resolve, reject) => {
      if (vendorId === 'KZry0625YQCjZV99ZZh1') {
        const query = events
          .where('account.vendorId', '==', vendorId)
          .where('active', '==', true)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              if (doc && doc.exists) {
                const {
                  active,
                  eventDescription,
                  lastUpdated,
                  entertainment,
                  eventTitle,
                  eventId,
                  eventThumb,
                  eventHero,
                  address,
                  // tagsDateType: tagsDateType,
                  zoneId,
                  website,
                  settings,
                  creationDetails,
                  scheduledTime,
                  tagsGenre,
                  tagsEventType,
                  ticketInfo,
                } = doc.data();
                eventArray.push({
                  active,
                  id: doc.id,
                  eventDescription,
                  entertainment,
                  lastUpdated,
                  eventTitle,
                  eventId,
                  eventThumb,
                  eventHero,
                  address,
                  // tagsDateType: tagsDateType,
                  zoneId,
                  website,
                  settings,
                  creationDetails,
                  scheduledTime,
                  tagsGenre,
                  tagsEventType,
                  ticketInfo,
                });
              }
            });
            return eventArray;
          })
          .then((result) => {
            resolve(eventArray);
          });
      } else {
        const query = events
          .where('account.vendorId', '==', vendorId)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              if (doc && doc.exists) {
                const {
                  active,
                  eventDescription,
                  lastUpdated,
                  entertainment,
                  eventTitle,
                  eventId,
                  eventThumb,
                  eventHero,
                  address,
                  // tagsDateType: tagsDateType,
                  zoneId,
                  website,
                  settings,
                  creationDetails,
                  scheduledTime,
                  tagsGenre,
                  tagsEventType,
                  ticketInfo,
                } = doc.data();
                eventArray.push({
                  active,
                  id: doc.id,
                  eventDescription,
                  entertainment,
                  lastUpdated,
                  eventTitle,
                  eventId,
                  eventThumb,
                  eventHero,
                  address,
                  // tagsDateType: tagsDateType,
                  zoneId,
                  website,
                  settings,
                  creationDetails,
                  scheduledTime,
                  tagsGenre,
                  tagsEventType,
                  ticketInfo,
                });
              }
            });
            return eventArray;
          })
          .then((result) => {
            resolve(eventArray);
          });
      }
    });
  };
  return getP();
}
