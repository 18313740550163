import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  UNSAFE_componentWillMount() {
    if (this.props.history) {
      this.setState({
        currentUrl: this.props.history.location.pathname,
        loading: false,
      });
    }
  }

  displayLink(menuItem) {
    return (
      <li>
        <MaterialIcon
          icon={menuItem.icon}
          size={20}
          color="#f21d59"
        />
        <NavLink
          to={{
            pathname: menuItem.url,
          }}
          activeClassName="activeMemuItem"
          onClick={() => {
            this.props.click();
          }}
        >
          {menuItem.label}
        </NavLink>
      </li>
    );
  }

  render() {
    const mainLinks = [
      // {
      //   url: '/rewards',
      //   label: 'Your Rewards',
      //   icon: 'local_activity',
      // },
      // {
      //   url: '/stats',
      //   label: 'Point & Rankings',
      //   icon: 'ballot',
      // },
      {
        url: '/events/plan/1',
        label: 'Talk to Your Trevel Agent',
        icon: 'search',
      },
    ];

    const secondaryLinks = [
      {
        url: '/profile/tag',
        label: 'My Preferences',
        icon: 'thumbs_up_down',
      },
      {
        url: '/places',
        label: 'My Favorites',
        icon: 'favorite',
      },

      // {
      //   url: '/scrapbook',
      //   label: 'Scrap Book',
      //   icon: 'import_contacts',
      // },
    ];

    const bottomLinks = [
      {
        url: '/profile/edit',
        label: 'Profile',
        icon: 'person',
      },
      {
        url: '/connections',
        label: 'Contacts',
        icon: 'mail',
      },

      {
        url: '/account',
        label: 'Account',
        icon: 'account_circle',
      },

      // {
      //   url: "/feedback",
      //   label: "Provide Feedback",
      //   icon: "feedback"
      // }
    ];

    // const extraLinks = [
    //   {
    //     url: '/articles',
    //     label: 'Suggested Reading',
    //     icon: 'local_library',
    //   },
    // ];

    const signOutLinks = [
      {
        url: '/support',
        label: 'Help',
        icon: 'help',
      },
    ];

    if (this.state.loading) {
      return null;
    }
    return (
      <div id="right-filter">
        <ul className="drawerMenuList">
          {mainLinks.map((menuItem, index) => (
            <div key={'key' + index}>
              {this.displayLink(menuItem, this.state.currentUrl)}
            </div>
          ))}
        </ul>
        <span className="appListCategory">Customize The Guide</span>
        <ul className="drawerMenuList">
          {secondaryLinks.map((menuItem, index) => (
            <div key={'key' + index}>
              {this.displayLink(menuItem, this.state.currentUrl)}
            </div>
          ))}
        </ul>
        <span className="appListCategory">MANAGE</span>
        <ul className="drawerMenuList">
          {bottomLinks.map((menuItem, index) => (
            <div key={'key' + index}>
              {this.displayLink(menuItem, this.state.currentUrl)}
            </div>
          ))}
        </ul>

        <div
          style={{
            display: 'block',
            bottom: '0px',
            position: 'absolute',
            width: '100%',
          }}
        >
          <ul className="drawerMenuList">
            {signOutLinks.map((menuItem, index) => (
              <div key={'key' + index}>
                {this.displayLink(menuItem, this.state.currentUrl)}
              </div>
            ))}
            <li>
              <MaterialIcon
                icon="exit_to_app"
                size={20}
                color="#f21d59"
              />
              <a
                onClick={() => {
                  this.props.logOut();
                }}
              >
                Sign out
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default UserMenu;
