import React from 'react';
import { connect } from 'react-redux';
// Components
import PasswordResetForm from '../components/PasswordResetForm';
import firebase from '../config/firebase';
// Reducers
import { setNewUser, setUser } from '../sagas/loginSaga/reducer';

export class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loadForm: false,
    };
  }

  UNSAFE_componentWillMount = () => {
    document.title = 'Trevel';

    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        // const token = result.credential.accessToken;
        if (result.credential) {
          const user = result.user;
          if (result.additionalUserInfo.isNewUser === true) {
            const user = {
              id: result.user.uid,
              userName: result.user.displayName,
              email: result.user.email,
              authenticated: true,
              newuser: true,
            };
            this.props.setNewUser(user);
          } else {
            const user = {
              id: result.user.uid,
              userName: result.user.displayName,
              email: result.user.email,
              authenticated: true,
              newuser: false,
            };
            this.props.setUser(user);
          }
        } else {
          this.setState({ loadForm: true });
        }
      })
      .catch(function(error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        // ...
      });
  };

  loadForm() {
    if (this.state.loadForm === true) {
      return (
        <div className="signup-box">
          <PasswordResetForm />
        </div>
      );
    }
  }

  render() {
    return (
      <div id="login">
        <div className="signup-box-container">{this.loadForm()}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'header { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer-container{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    invite: state.invite,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user));
    },
    setNewUser: user => {
      dispatch(setNewUser(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);
