import * as action from './actions';

export const getVendorPromos = (vendorId) => {
  return {
    type: action.GET_VENDORPROMOS,
    payload: {},
    vendorId: vendorId,
  };
};

export const refreshVendorPromos = (promos) => {
  return {
    type: action.REFRESH_VENDORPROMOS,
    payload: promos,
  };
};

export const approveVendorPromos = (promos) => {
  return {
    type: action.APPROVE_VENDORPROMOS,
    payload: promos,
  };
};
export const rejectVendorPromos = (promos) => {
  return {
    type: action.REJECT_VENDORPROMOS,
    payload: promos,
  };
};
export const editVendorPromos = (promos) => {
  return {
    type: action.EDIT_VENDORPROMOS,
    payload: promos,
  };
};

const ACTION_HANDLERS = {
  [action.SET_VENDORPROMOS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_VENDORPROMOS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.REFRESH_VENDORPROMOS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.REJECT_VENDORPROMOS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.APPROVE_VENDORPROMOS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.EDIT_VENDORPROMOS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
