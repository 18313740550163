import React from 'react';
import { connect } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
import { NavLink, Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import firebase from '../../config/firebase';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import VendorMenu from '../../components/VendorMenu';
// Components
import TitleBar from '../../components/TitleBar';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';

const KEYS_TO_FILTERS = ['owner.firstName', 'owner.lastName'];

const query = require('array-query');

export class VendorValidator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: false,
      vendorLocations: [],
      awards: [],
      showPicker: false,
      showHelp: false,
    };
    this.showPicker = this.showPicker.bind(this);
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    document.title = 'Manage';
  }

  getRewards(vendorAwarded) {
    const awardArray = [];
    vendorAwarded.forEach((award) => {
      if (award.validatorMode === true) {
        awardArray.push(award);
      }
    });
    return awardArray;
  }

  // Validate Award Criteria
  validateCriteria(award, profile) {
    const localDate = new Date();
    let newAward = award;
    // Add Validation History to Active Criteria
    award.criteria.forEach((criteria, index) => {
      if (
        criteria.activeCriteria !== undefined &&
        criteria.activeCriteria === true
      ) {
        const newHistory = {
          date: localDate,
          role: 0, // need this?
          fullName: profile.firstName + ' ' + profile.lastName,
          validatorId: profile.userId,
          validated: true,
        };
        const updatedHistory = criteria.validationHistory;
        updatedHistory.push(newHistory);
        newAward.criteria[index].validationHistory = updatedHistory;
        newAward.criteria[index].activeCriteria = false;

        let validationCount = 0;
        newAward.criteria[index].validationHistory.forEach(
          (validation, index) => {
            if (validation.validated === true) {
              validationCount = validationCount + 1;
            }
          },
        );
        if (validationCount === newAward.criteria[index].numTimes) {
          let satisfiedAll = true;
          newAward.criteria[index].satisfied = true;
          newAward.criteria.forEach((criteria, index) => {
            if (criteria.satisfied === false) {
              satisfiedAll = false;
            }
          });
          if (satisfiedAll === true) {
            newAward.earned = true;
            newAward.creationDetails.earnedDate = localDate;
          }
        }
      }
    });
    console.log('newAward', newAward);
    // Update History
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(award.awardId);
    const setDoc = update
      .update({
        criteria: newAward.criteria,
        earned: newAward.earned,
        validatorMode: false,
        validationType: 0,
        creationDetails: newAward.creationDetails,
      })
      .then((ref) => {
        // this.setState({
        //   showConfirm: false,
        //   validate: false,
        //   redeem: false,
        // });
      });
  }

  // Reject Award Criteria
  rejectCriteria(award, profile) {
    const localDate = new Date();
    let newAward = award;
    // Add Validation History to Active Criteria
    award.criteria.forEach((criteria, index) => {
      if (
        criteria.activeCriteria !== undefined &&
        criteria.activeCriteria === true
      ) {
        const newHistory = {
          date: localDate,
          role: 0, // need this?
          fullName: profile.firstName + ' ' + profile.lastName,
          validatorId: profile.userId,
          validated: false,
        };
        const updatedHistory = criteria.validationHistory;
        updatedHistory.push(newHistory);
        newAward.criteria[index].validationHistory = updatedHistory;
      }
    });
    // Update History
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(award.awardId);
    const setDoc = update
      .update({
        criteria: newAward.criteria,
        validatorMode: false,
      })
      .then((ref) => {
        // this.setState({
        //   showConfirm: false,
        //   validate: false,
        //   redeem: false,
        // });
      });
  }

  // WORKING ON REDEPMTION!!!!!!

  // Redeem Award
  redeemAward(award, profile) {
    const localDate = new Date();
    let updatedRedemption = {};
    let fullName = profile.firstName + ' ' + profile.lastName;
    // Set Redemption Record
    updatedRedemption.date = localDate;
    updatedRedemption.redeemerFullName = fullName;
    updatedRedemption.redeemerId = profile.userId;
    updatedRedemption.redeemed = true;
    // Update Award
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(award.awardId);
    const setDoc = update
      .update({
        validatorMode: false,
        validationType: 0,
        redemption: updatedRedemption,
        active: false,
      })
      .then((ref) => {
        // this.setState({
        //   showConfirm: false,
        //   validate: false,
        //   redeem: false,
        // });
      });
  }

  // Reject Award Redemption
  rejectRedemption(award, profile) {
    const localDate = new Date();
    let newAward = award;
    // Add Validation History to Active Criteria
    award.criteria.forEach((criteria, index) => {
      if (
        criteria.activeCriteria !== undefined &&
        criteria.activeCriteria === true
      ) {
        const newHistory = {
          date: localDate,
          role: 0, // need this?
          fullName: profile.firstName + ' ' + profile.lastName,
          validatorId: profile.userId,
          validated: false,
        };
        const updatedHistory = criteria.validationHistory;
        updatedHistory.push(newHistory);
        newAward.criteria[index].validationHistory = updatedHistory;
      }
    });
    // Update History
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(award.awardId);
    const setDoc = update
      .update({
        criteria: newAward.criteria,
        validatorMode: false,
      })
      .then((ref) => {
        // this.setState({
        //   showConfirm: false,
        //   validate: false,
        //   redeem: false,
        // });
      });
  }

  getAward(award, index) {
    console.log('award', award);

    let title = '';
    award.rewards.forEach((rewardx, x) => {
      if (x === 0) {
        title = rewardx.title;
      } else {
        title = title + ' or ' + rewardx.title;
      }
    });

    let fullName = award.owner.firstName + ' ' + award.owner.lastName;

    if (award.validationType === 1) {
      return (
        <li className="adminlistitem">
          <div className="fullwidth">
            <Col xs={3} md={3} sm={3} lg={3} key={'key' + index}>
              {fullName}
            </Col>
            <Col xs={6} md={6} sm={6} lg={6} className="left">
              <b>{title}</b>
              {this.getCriteria(award)}
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="left text-center"
            >
              <span className="validationType">Validate</span>
              <a
                onClick={() => {
                  this.validateCriteria(award, this.props.profile);
                }}
              >
                <MaterialIcon
                  icon="check_circle"
                  size={30}
                  color="#f21d59"
                />
              </a>
              <a
                onClick={() => {
                  this.rejectCriteria(award, this.props.profile);
                }}
              >
                <MaterialIcon
                  icon="cancel"
                  size={30}
                  color="#f21d59"
                />
              </a>
            </Col>
          </div>
        </li>
      );
    } else if (award.validationType === 2) {
      return (
        <li className="adminlistitem">
          <div className="fullwidth">
            <Col xs={3} md={3} sm={3} lg={3} key={'key' + index}>
              {fullName}
            </Col>
            <Col xs={6} md={6} sm={6} lg={6} className="left">
              <b>{award.reward.title}</b>
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="left text-center"
            >
              <span className="validationType">Redeem</span>
              <a
                onClick={() => {
                  this.redeemAward(award, this.props.profile);
                }}
              >
                <MaterialIcon
                  icon="check_circle"
                  size={30}
                  color="#f21d59"
                />
              </a>
              <a
                onClick={() => {
                  this.rejectRedemption(award, this.props.profile);
                }}
              >
                <MaterialIcon
                  icon="cancel"
                  size={30}
                  color="#f21d59"
                />
              </a>
            </Col>
          </div>
        </li>
      );
    }
  }

  getCriteria(award) {
    let activeCriteria = {};
    award.criteria.forEach((criteria) => {
      if (
        criteria.activeCriteria !== undefined &&
        criteria.activeCriteria === true
      ) {
        activeCriteria = criteria;
      }
    });
    return <div>{activeCriteria.text}</div>;
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getTitleBar(title, rewards) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={10}
              md={10}
              sm={10}
              xs={10}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">
                <span style={{ color: '#f21d59', fontSize: '22px' }}>
                  Manage{' '}
                </span>
                Experiences
              </span>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
              {rewards.length > 0 ? (
                <span
                  style={{
                    padding: '0px 10px 0px 0px',
                    float: 'right',
                    top: '-2px',
                    display: 'block',
                    position: 'relative',
                  }}
                >
                  <MaterialIcon
                    icon="notifications_active"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  />
                </span>
              ) : (
                <div />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getAppContent(filteredLocations) {
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuNew  mobileHide">
          <VendorMenu popupMenu={false} />
        </div>
        <div
          style={{
            float: 'left',
            width: 'calc(100% - 120px)',
            padding: '0px',
          }}
          className="mobileHide"
        >
          <div
            style={{
              width: '100%',
              padding: '5px 10px 5px 10px',
              borderBottom: '1px solid rgb(142 12 60)',
              margin: '0px auto',

              display: 'flex',
              background: '#ffebf3',
            }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <center>
                <span>
                  You are not currently subscribed to Trevel
                  Experiences. You can explore and create but you
                  cannot publish live experiences.{'  '}
                  <Link
                    to={{
                      pathname: '/vendor/pilot',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>
                      Learn More
                    </span>
                  </Link>
                </span>
              </center>
            </Col>
            {/* <Col lg={2} md={4} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/pilot',
                }}
                className="right"
              >
                <span style={{ float: 'right' }}>Learn More</span>
              </Link>
            </Col> */}
          </div>
          <div style={{ maxWidth: '1000px', margin: '0px auto' }}>
            <Row className="no-margin">
              <div className="fullwidth">
                {this.props.vendorPromos.length !== 0 && (
                  <div>
                    <div className="section">
                      <div className="header-container">
                        <div className="search-area">
                          <SearchInput
                            onChange={this.searchUpdated}
                          />
                        </div>
                      </div>
                    </div>
                    <ul className="no-padding">
                      <li className="adminlistitem adminlistheader ">
                        <div>
                          <Col
                            xs={3}
                            md={3}
                            sm={3}
                            lg={3}
                            className="left"
                          >
                            <span className="label">Name</span>
                          </Col>

                          <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={6}
                            className="left"
                          >
                            <span className="label">
                              Award & Criteria
                            </span>
                          </Col>
                          <Col
                            xs={3}
                            md={3}
                            sm={3}
                            lg={3}
                            className="left"
                          >
                            {/* <span className="label">Actions</span> */}
                          </Col>
                        </div>
                      </li>
                      {filteredLocations.map((award, index) => (
                        <div>{this.getAward(award, index)}</div>
                      ))}
                    </ul>
                  </div>
                )}
                {this.props.vendorPromos.length === 0 && (
                  <div
                    style={{
                      padding: '30px',
                      border: '1px solid lightgrey',
                      margin: '0px auto',
                      width: '100%',
                      marginTop: '100px',
                      background: 'white',
                      display: 'flex',
                    }}
                  >
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <div style={{ float: 'left' }}>
                        <MaterialIcon
                          icon="help_outline"
                          size={45}
                          color="#353535"
                        />
                      </div>
                    </Col>
                    <Col xs={11} md={11} sm={11} lg={11}>
                      <h4
                        style={{
                          fontSize: '18px',
                          marginBottom: '10px',
                        }}
                      >
                        Manage Experiences
                      </h4>
                      <p style={{ padding: '0px', margin: '0px' }}>
                        This is the tool you will use to{' '}
                        <span style={{ fontWeight: '600' }}>
                          validate
                        </span>{' '}
                        user's actions and{' '}
                        <span style={{ fontWeight: '600' }}>
                          redeem
                        </span>{' '}
                        user's Rewards once they have earned them.
                      </p>
                    </Col>
                  </div>
                )}
              </div>
            </Row>
          </div>
        </div>
        <div
          style={{
            float: 'left',
            width: '100%',
            padding: '0px',
          }}
          className="mobileShow"
        >
          <div
            style={{
              width: '100%',
              padding: '5px 10px 5px 10px',
              borderBottom: '1px solid rgb(142 12 60)',
              margin: '0px auto',

              display: 'flex',
              background: '#ffebf3',
            }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <center>
                <span>
                  You are not currently subscribed to Trevel
                  Experiences. You can explore and create but you
                  cannot publish live experiences.{'  '}
                  <Link
                    to={{
                      pathname: '/vendor/pilot',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>
                      Learn More
                    </span>
                  </Link>
                </span>
              </center>
            </Col>
            {/* <Col lg={2} md={4} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/pilot',
                }}
                className="right"
              >
                <span style={{ float: 'right' }}>Learn More</span>
              </Link>
            </Col> */}
          </div>
          <div style={{ maxWidth: '1000px', margin: '0px auto' }}>
            <Row className="no-margin">
              <div className="fullwidth">
                {this.props.vendorPromos.length !== 0 && (
                  <div>
                    <div className="section">
                      <div className="header-container">
                        <div className="search-area">
                          <SearchInput
                            onChange={this.searchUpdated}
                          />
                        </div>
                      </div>
                    </div>
                    <ul className="no-padding">
                      <li className="adminlistitem adminlistheader ">
                        <div>
                          <Col
                            xs={3}
                            md={3}
                            sm={3}
                            lg={3}
                            className="left"
                          >
                            <span className="label">Name</span>
                          </Col>

                          <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={6}
                            className="left"
                          >
                            <span className="label">
                              Award & Criteria
                            </span>
                          </Col>
                          <Col
                            xs={3}
                            md={3}
                            sm={3}
                            lg={3}
                            className="left"
                          >
                            {/* <span className="label">Actions</span> */}
                          </Col>
                        </div>
                      </li>
                      {filteredLocations.map((award, index) => (
                        <div>{this.getAward(award, index)}</div>
                      ))}
                    </ul>
                  </div>
                )}
                {this.props.vendorPromos.length === 0 && (
                  <div
                    style={{
                      padding: '30px',
                      border: '1px solid lightgrey',
                      margin: '0px auto',
                      width: '100%',
                      marginTop: '100px',
                      background: 'white',
                      display: 'flex',
                    }}
                  >
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <div style={{ float: 'left' }}>
                        <MaterialIcon
                          icon="help_outline"
                          size={45}
                          color="#353535"
                        />
                      </div>
                    </Col>
                    <Col xs={11} md={11} sm={11} lg={11}>
                      <h4
                        style={{
                          fontSize: '18px',
                          marginBottom: '10px',
                        }}
                      >
                        Manage Experiences
                      </h4>
                      <p style={{ padding: '0px', margin: '0px' }}>
                        This is the tool you will use to{' '}
                        <span style={{ fontWeight: '600' }}>
                          validate
                        </span>{' '}
                        user's actions and{' '}
                        <span style={{ fontWeight: '600' }}>
                          redeem
                        </span>{' '}
                        user's Rewards once they have earned them.
                      </p>
                    </Col>
                  </div>
                )}
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
    }
    let rewards = this.getRewards(this.props.vendorAwarded);
    const filteredLocations = rewards.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Manage Experiences', rewards)}
        <div className="vendorContent">
          {this.getAppContent(filteredLocations)}
        </div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .col-12:nth-child(even){padding-left: 15px} .col-12:nth-child(even){padding-right: 15px}  .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    profile: state.profile.profile,
    vendorPromos: state.vendorPromos.promos,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorValidator);
