import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';

import VendorMenu from '../../components/VendorMenu';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';

export class VendorHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
    };
    this.showPicker = this.showPicker.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    }

    if (this.props.user.authenticated === true) {
      let locationStatus = false;
      let entertainmentStatus = false;

      if (this.props.unauth.claimLink === true) {
        this.props.history.push(
          '/vendor/location/edit/' + this.props.unauth.claimId,
        );
      }

      if (
        this.props.vendor !== undefined &&
        this.props.vendor.vendorTypes !== undefined
      ) {
        locationStatus = this.props.vendor.vendorTypes.includes(1);
        entertainmentStatus =
          this.props.vendor.vendorTypes.includes(2);
      }

      if (locationStatus === true) {
        if (
          this.props.vendor.locations !== undefined &&
          this.props.vendor.locations.length === 0
        ) {
          this.props.history.push('/vendor/locations/add');
        }
      } else if (entertainmentStatus === true) {
        if (
          this.props.vendor.entertainment !== undefined &&
          this.props.vendor.entertainment.length === 0
        ) {
          this.props.history.push('/vendor/entertainment/add');
        }
      }
    }

    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }

  componentDidMount() {
    document.title = 'Trevel Experiences';
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getTitleBar(title, rewards) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={10}
              md={10}
              sm={10}
              xs={10}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">
                <span style={{ color: '#f21d59', fontSize: '22px' }}>
                  Trevel{' '}
                </span>
                Experiences
              </span>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right mobileHide">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                  className
                />
              </span>
              {rewards.length > 0 ? (
                <Link
                  to={{
                    pathname: '/vendor/validator',
                  }}
                >
                  <span
                    style={{
                      padding: '0px 10px 0px 0px',
                      float: 'right',
                      top: '-2px',
                      display: 'block',
                      position: 'relative',
                    }}
                  >
                    <MaterialIcon
                      icon="notifications_active"
                      size={35}
                      color="rgb(242 29 89)"
                    />
                  </span>
                </Link>
              ) : (
                <div />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getAppContent() {
    let entertainment = '';
    if (this.props.vendor.entertainment[0] !== undefined) {
      entertainment = this.props.vendor.entertainment[0];
    }
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuNew mobileHide">
          <VendorMenu popupMenu={false} />
        </div>
        <div id="vendorScreenBody">
          <div style={{ padding: '10px', margin: '0px auto' }}>
            <div
              style={{
                maxWidth: '500px',
                width: '90%',
                margin: '0px auto',
                paddingBotton: '40px',
              }}
            >
              <div
                className="mobileHide"
                style={{ height: '60px' }}
              />
              <div
                className="mobileSho2"
                style={{ height: '20px' }}
              />
              <img
                style={{
                  width: '100%',
                }}
                src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880"
                className="mobileHide"
              />
              <img
                style={{
                  width: '100%',
                  maxWidth: '300px',
                  margin: '0px auto',
                }}
                src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880"
                className="mobileShow"
              />
            </div>
            <center>
              <h2
                style={{
                  fontWeight: '200',
                  color: 'black !important',
                  fontSize: '20px',
                  marginBottom: '40px',
                }}
                className="mobileShow"
              >
                Welcome{' '}
                {this.props.vendorPromos !== undefined &&
                  this.props.vendorPromos.length !== 0 &&
                  'Back'}{' '}
                {this.props.profile.firstName}!
              </h2>
              <h2
                style={{
                  fontWeight: '100',
                  color: 'black !important',
                }}
                className="mobileHide"
              >
                Welcome{' '}
                {this.props.vendorPromos !== undefined &&
                  this.props.vendorPromos.length !== 0 &&
                  'Back'}{' '}
                {this.props.profile.firstName}!
              </h2>
              {/* <h5
                style={{
                  color: 'dimgrey',
                  margin: '20px 0px 80px 0px',
                }}
                className="mobileHide"
              >
                Ready to build something new?
              </h5> */}
            </center>

            {/* <Row className="mobileHide">
              <Col
                xs={4}
                md={4}
                sm={4}
                lg={4}
                style={{ height: '100%' }}
              >
                <Link
                  to={{
                    pathname: '/vendor/experiences',
                  }}
                >
                  <div
                    className="homebaseApp"
                    style={{ borderRight: '1px solid black' }}
                  >
                    <center> */}

            {/* <span
                    style={{
                      fontSize: '16px',
                      fontWeight: '100',
                      color: '#383838',
                      display: 'block',
                      clear: 'both',
                      textTransform: 'uppercase',
                      margin: '0px 0px 10px 0px',
                    }}
                  >
                    Create
                  </span> */}

            {/* <span
                        style={{
                          fontSize: '30px',
                          fontWeight: '100',
                          color: '#f21d59',
                        }}
                      >
                        Create
                      </span>

                      <span
                        style={{
                          fontSize: '18px',
                          fontWeight: '100',
                          clear: 'both',
                          display: 'block',
                          margin: '10px 0px 0px 0px',
                          color: 'dimgrey',
                        }}
                      >
                        Build Customer Experiences to drive business.
                      </span>
                    </center>
                  </div>
                </Link>
              </Col>
              <Col
                xs={4}
                md={4}
                sm={4}
                lg={4}
                style={{ height: '100%' }}
              >
                <Link
                  to={{
                    pathname: '/vendor/validator',
                  }}
                >
                  <div
                    className="homebaseApp"
                    style={{ borderRight: '1px solid black' }}
                  >
                    <center> */}
            {/* <span
                    style={{
                      fontSize: '16px',
                      fontWeight: '100',
                      color: '#383838',
                      display: 'block',
                      clear: 'both',
                      textTransform: 'uppercase',
                      margin: '0px 0px 10px 0px',
                    }}
                  >
                    Manage
                  </span> */}
            {/* <span
                        style={{
                          fontSize: '30px',
                          fontWeight: '100',
                          color: '#f21d59',
                        }}
                      >
                        Manage
                      </span>

                      <span
                        style={{
                          fontSize: '18px',
                          fontWeight: '100',
                          clear: 'both',
                          display: 'block',
                          margin: '10px 0px 0px 0px',
                          color: 'dimgrey',
                        }}
                      >
                        Validate player's criteria and redeem their
                        earned Rewards
                      </span>
                    </center>
                  </div>
                </Link>
              </Col>
              <Col
                xs={4}
                md={4}
                sm={4}
                lg={4}
                style={{ height: '100%' }}
              >
                <Link
                  to={{
                    pathname: '/vendor/insights',
                  }}
                >
                  <div className="homebaseApp">
                    <center> */}
            {/* <span
                    style={{
                      fontSize: '16px',
                      fontWeight: '100',
                      color: '#383838',
                      display: 'block',
                      clear: 'both',
                      textTransform: 'uppercase',
                      margin: '0px 0px 10px 0px',
                    }}
                  >
                    Analyse
                  </span> */}
            {/* <span
                        style={{
                          fontSize: '30px',
                          fontWeight: '100',
                          color: '#f21d59',
                        }}
                      >
                        Analyze
                      </span>

                      <span
                        style={{
                          fontSize: '18px',
                          fontWeight: '100',
                          clear: 'both',
                          display: 'block',
                          margin: '10px 0px 0px 0px',
                          color: 'dimgrey',
                        }}
                      >
                        Gain Insights from your Experiences and
                        Customers
                      </span>
                    </center>
                  </div>
                </Link>
              </Col>
            </Row> */}

            <div
              style={{
                padding: '30px',
                border: '1px solid #600000',
                margin: '0px auto',
                width: '90%',
                marginTop: '50px',
                background: '#ffeeee',
                display: 'flex',
                textAlign: 'center',
              }}
            >
              <center>
                <span>
                  <span style={{ fontWeight: '600' }}>
                    Need Help?{' '}
                  </span>
                  Call Mike Levin at{' '}
                  <span style={{ fontWeight: '600' }}>
                    (913) 384-7377
                  </span>
                </span>
              </center>
            </div>

            <div className="mobileShow">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ height: '100%' }}
                >
                  {this.props.profile.userType !== 1 &&
                    this.props.vendor.vendorTypes[0] === 1 && (
                      <Link
                        to={{
                          pathname: '/vendor/locations/',
                        }}
                        className="mobileMenuButton"
                      >
                        Manage Your Locations
                      </Link>
                    )}

                  {this.props.profile.userType !== 1 &&
                    this.props.vendor.vendorTypes[0] === 2 && (
                      <Link
                        to={{
                          pathname:
                            '/vendor/entertainment/edit/' +
                            entertainment,
                        }}
                        className="mobileMenuButton"
                      >
                        Edit Your Page
                      </Link>
                    )}

                  <Link
                    to={{
                      pathname: '/vendor/timedevents',
                    }}
                    className="mobileMenuButton"
                  >
                    Manage Events
                  </Link>
                </Col>
              </Row>
            </div>

            {this.props.vendor.vendorTypes.includes(1) === true && (
              <div
                style={{
                  padding: '30px',
                  border: '1px solid lightgrey',
                  margin: '0px auto',
                  width: '90%',
                  marginTop: '40px',
                  background: 'white',
                  display: 'flex',
                }}
                className="mobileHide"
              >
                <Col xs={1} md={1} sm={1} lg={1}>
                  <div style={{ float: 'left' }}>
                    <MaterialIcon
                      icon="help_outline"
                      size={35}
                      color="#353535"
                    />
                  </div>
                </Col>
                <Col xs={11} md={11} sm={11} lg={11}>
                  <h4
                    style={{ fontSize: '18px', marginBottom: '10px' }}
                  >
                    Edit Your Locations
                  </h4>
                  <p style={{ padding: '0px', margin: '0px' }}>
                    Click on{' '}
                    <span style={{ fontWeight: '600' }}>
                      Locations
                    </span>{' '}
                    in the menu to manage your locations info and
                    images, or to add new ones.
                  </p>{' '}
                </Col>
              </div>
            )}

            {this.props.vendor.vendorTypes.includes(1) === true && (
              <div
                style={{
                  padding: '30px',
                  border: '1px solid lightgrey',
                  margin: '0px auto',
                  width: '90%',
                  marginTop: '20px',
                  background: 'white',
                  display: 'flex',
                }}
                className="mobileHide"
              >
                <Col xs={1} md={1} sm={1} lg={1}>
                  <div style={{ float: 'left' }}>
                    <MaterialIcon
                      icon="help_outline"
                      size={35}
                      color="#353535"
                    />
                  </div>
                </Col>
                <Col xs={11} md={11} sm={11} lg={11}>
                  <h4
                    style={{ fontSize: '18px', marginBottom: '10px' }}
                  >
                    Manage Your Promotions
                  </h4>
                  <p style={{ padding: '0px', margin: '0px' }}>
                    Click on{' '}
                    <span style={{ fontWeight: '600' }}>Promote</span>{' '}
                    in the menu to manage your Promotions, and add new
                    ones.
                  </p>{' '}
                </Col>
              </div>
            )}
            {this.props.vendor.vendorTypes.includes(2) === true && (
              <div
                style={{
                  padding: '30px',
                  border: '1px solid lightgrey',
                  margin: '0px auto',
                  width: '90%',
                  marginTop: '100px',
                  background: 'white',
                  display: 'flex',
                }}
                className="mobileHide"
              >
                <Col xs={1} md={1} sm={1} lg={1}>
                  <div style={{ float: 'left' }}>
                    <MaterialIcon
                      icon="help_outline"
                      size={35}
                      color="#353535"
                    />
                  </div>
                </Col>
                <Col xs={11} md={11} sm={11} lg={11}>
                  <h4
                    style={{
                      fontSize: '18px',
                      marginBottom: '10px',
                    }}
                  >
                    Add Your Upcoming Events
                  </h4>
                  <p style={{ padding: '0px', margin: '0px' }}>
                    Click on{' '}
                    <span style={{ fontWeight: '600' }}>Promote</span>{' '}
                    in the menu to manage your upcoming events and to
                    add new ones!
                  </p>
                </Col>
              </div>
            )}
            {this.props.vendor.vendorTypes.includes(3) === true && (
              <div
                style={{
                  padding: '30px',
                  border: '1px solid lightgrey',
                  margin: '0px auto',
                  width: '90%',
                  marginTop: '20px',
                  background: 'white',
                  display: 'flex',
                }}
                className="mobileHide"
              >
                <Col xs={1} md={1} sm={1} lg={1}>
                  <div style={{ float: 'left' }}>
                    <MaterialIcon
                      icon="help_outline"
                      size={35}
                      color="#353535"
                    />
                  </div>
                </Col>
                <Col xs={11} md={11} sm={11} lg={11}>
                  <h4
                    style={{
                      fontSize: '18px',
                      marginBottom: '10px',
                    }}
                  >
                    Add Your Upcoming Events
                  </h4>
                  <p style={{ padding: '0px', margin: '0px' }}>
                    Click on{' '}
                    <span style={{ fontWeight: '600' }}>Promote</span>{' '}
                    in the menu to manage your upcoming events and to
                    add new ones!
                  </p>{' '}
                </Col>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  getRewards(vendorAwarded) {
    const awardArray = [];
    if (vendorAwarded !== undefined) {
      vendorAwarded.forEach((award) => {
        if (award.validatorMode === true) {
          awardArray.push(award);
        }
      });
    }
    return awardArray;
  }

  render() {
    if (this.props.vendor === undefined) {
      return null; // or render a loading icon
    }
    let rewards = this.getRewards(this.props.vendorAwarded);
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Trevel Experiences', rewards)}
        <div className="vendorContent">{this.getAppContent()}</div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
    user: state.user,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    vendorPromos: state.vendorPromos.promos,
    unauth: state.unauth,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorHome);
