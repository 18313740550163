import { call, put, takeLatest } from 'redux-saga/effects';
import { getProfile } from '../getProfileSaga/reducer';
import { getLocation } from '../locationsSaga/reducer';
import { getDemos } from '../demoSaga/reducer';
import { getArticles } from '../articleSaga/reducer';
import { getGuideTemplates } from '../guideTemplatesSaga/reducer';
import { getRegions } from '../regionsSaga/reducer';
import { getTags } from '../tagsSaga/reducer';
import { init as getPackages } from '../packagesSaga/reducer';
import { getUsers } from '../usersSaga/reducer';
import { getVendors } from '../vendorsSaga/reducer';
import { push } from 'connected-react-router';

import * as actions from './actions';

function* hydrateAction() {
  yield put(getArticles());
  yield put(getGuideTemplates());
  // yield put(getRegions());
  // yield put(getUsers());
  yield put(getTags());
  yield put(getDemos());
  // yield put(getAllLocations(1));

  // yield put(getAllLocations(1));
  yield put({
    type: actions.HYDRATE_SUCCESS,
    payload: {
      hydrated: true,
    },
  });
  // yield put(push("/"));
}

function* vendorHydrateAction(bundle) {
  // yield put(getVendors());
  yield put(getLocation(bundle.payload.locationId));

  let url = '/vendor/claimready/' + bundle.payload.locationId;
  if (bundle.payload.autoSignup === true) {
    url =
      '/vendor/claimready/' +
      bundle.payload.locationId +
      '?source=qr';
  }

  yield put(push(url));
}

export default function* sagas() {
  yield takeLatest(actions.HYDRATE_APP, hydrateAction);
  yield takeLatest(actions.VENDORHYDRATE_APP, vendorHydrateAction);
}
