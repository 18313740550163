import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
} from 'reactstrap';
import moment from 'moment';
import geolib from 'geolib';
import SearchInput, { createFilter } from 'react-search-input';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import GuideEntertainerCategory from '../../components/GuideEntertainerCategory';
import DailyEntertainerSpotlight from '../../components/DailyEntertainerSpotlight';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';

const _ = require('lodash');
const KEYS_TO_FILTERS = ['locationTitle', 'locations.locationTitle'];

class GuideEntertainersAll extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      width: 0,
      height: 0,
      tagFilters: [],
      promos: [],
    };
  }

  componentDidMount() {
    this.searchUpdated('');
    if (this.state.tagFilters.length === 0) {
      this.setTags(this.props.tags, this.props.entertainment);
    }
  }

  findPromotedLocations(locations, category, tagCat) {
    let setTag = undefined;

    let tagsEventType = {
      parent: 'Event Type',
      sid: 'eventType',
      tags: [],
    };

    // Combine Tags

    function getPromoted(locations) {
      let promotedLocations = [];
      let promotedLocationIds = [];

      // if (locations !== undefined) {
      //   locations.forEach((location) => {
      //     if (tagCat === 'tagsEventType') {
      //       if (
      //         category === 'entertainment' ||
      //         category === 'music'
      //       ) {
      //         var todayDate = moment(new Date()).format('MM/DD/YYYY');
      //         var pastDate = moment(
      //           location.scheduledTime.dates.startDate,
      //         );
      //         var dDiff = pastDate.diff(todayDate);
      //         if (dDiff > 0 && dDiff !== 0) {
      //           promotedLocations.push(location);
      //         } else if (dDiff === 0) {
      //           promotedLocations.push(location);
      //         }
      //       } else {
      //         if (
      //           location.tagsEventType !== undefined &&
      //           location.tagsEventType.length !== 0
      //         ) {
      //           location.tagsEventType.forEach((tagx) => {
      //             if (tagx === category) {
      //               const status = promotedLocationIds.includes(
      //                 location.eventId,
      //               );
      //               if (status === false) {
      //                 promotedLocationIds.push(location.eventId);
      //                 var todayDate = moment(new Date()).format(
      //                   'MM/DD/YYYY',
      //                 );
      //                 var pastDate = moment(
      //                   location.scheduledTime.dates.startDate,
      //                 );
      //                 var dDiff = pastDate.diff(todayDate);
      //                 if (dDiff > 0 && dDiff !== 0) {
      //                   promotedLocations.push(location);
      //                 } else if (dDiff === 0) {
      //                   promotedLocations.push(location);
      //                 }
      //               }
      //             }
      //           });
      //         }
      //       }
      //     }
      //   });
      // }

      return locations;
      // return promotedLocations;
    }

    let promotedLocations = [];
    let i = 0;

    while (promotedLocations.length === 0 && i !== 10) {
      promotedLocations = getPromoted(locations);
      i++;
    }

    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
    }
    let shuffledLocations = shuffleArray(promotedLocations);
    let topThree = [];
    let topThreeIds = [];
    shuffledLocations.forEach((location) => {
      if (topThree.length !== 100) {
        const status = topThreeIds.includes(location.entertainmentId);
        if (status === false) {
          topThreeIds.push(location.entertainmentId);
          topThree.push(location);
        }
      }
    });
    let seeMore = false;
    if (shuffledLocations.length > 10) {
      seeMore = true;
    }

    let bundle = {
      promotedLocations: topThree,
      setTag,
      seeMore,
    };
    return bundle;
  }

  setTags(tags, locations) {
    let filteredTags = this.filterTags(tags, locations);
    let updatedTags = filteredTags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = 1;
        if (this.props.user.authenticated === true) {
          // status = this.getTagStatus(tag.tag);
        }
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let drinks = {
    //   checked: false,
    //   parentindex: 6,
    //   status: 1,
    //   tag: 'Drinks',
    //   tagindex: updatedTags[6].tags.length,
    // };

    let food = {
      checked: false,
      parentindex: 1,
      status: 1,
      tag: 'Food',
      tagindex: updatedTags[1].tags.length,
    };

    // updatedTags[6].tags.push(drinks);
    updatedTags[1].tags.push(food);
    updatedTags.forEach((tagcat, x) => {
      updatedTags[x].tags = updatedTags[x].tags.sort((a, b) =>
        a.tag.localeCompare(b.tag),
      );
      updatedTags[x].tags = updatedTags[x].tags.reverse();
      updatedTags[x].tags.map((tag, y) => {
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let sortedTags = []
    // updatedTags.forEach((tagcat, x) => {
    //   sortedTags = _.sortBy(updatedTags[x].tags, o => o.status);
    // }

    let origionalTags = [];
    updatedTags.forEach((tagcat) => {
      origionalTags.push(tagcat);
    });

    this.setState({
      tagFilters: updatedTags,
      origionalTags,
    });
    this.setTagFilters(updatedTags);
  }

  filterTags(tagFilters, locations) {
    let updatedTagFilters = [
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
      {
        id: '5vhCJSC7VgBE1MFNctgY',
        parent: 'Location Type',
        sid: 'locationType',
        tags: [],
      },
      {
        id: '7ayttBEiZgiM5TZ2iYsM',
        parent: 'Cuisine Type',
        sid: 'cuisineType',
        tags: [],
      },
      {
        id: '7kJeYaz9m5q434zO28Pg',
        parent: 'Setting Type',
        sid: 'setting',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Experience Type',
        sid: 'experienceType',
        tags: [],
      },
      {
        id: 'BW3qPhXbN7C4XMXWh4tv',
        parent: 'Date Type',
        sid: 'dateType',
        tags: [],
      },
      {
        id: 'CSKtI08vSBtyqKr10Nef',
        parent: ' Dietary Must Haves',
        sid: 'dietary',
        tags: [],
      },
      {
        id: 'F7R2nsOyZTiqKbW6AhFQ',
        parent: 'Hours',
        sid: 'hours',
        tags: [],
      },
      {
        id: 'xeytdS8Xwjlou67MVbVY',
        parent: 'Beverages',
        sid: 'beverages',
        tags: [],
      },
      {
        id: 'rxsnb1c2XX8c4MpugeEP',
        parent: 'Genre',
        sid: 'genre',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Entertainment Type',
        sid: 'entertainmentType',
        tags: [],
      },
      {
        id: 'eAVFUFVivBObckEaKiRR',
        parent: 'Event Type',
        sid: 'eventType',
        tags: [],
      },
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
    ];
    let allTags = [];

    locations.forEach((location) => {
      if (location.tagsEntertainmentType !== undefined) {
        location.tagsEntertainmentType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }
    });
    if (tagFilters != undefined) {
      tagFilters.forEach((tagx, indexA) => {
        if (tagx.tags !== undefined) {
          tagx.tags.forEach((tag, indexB) => {
            let status = allTags.includes(tag.tag);
            if (status === true) {
              updatedTagFilters[indexA].tags.push(tag);
            }
          });
        }
      });
    }
    return updatedTagFilters;
  }

  clearTagFilters(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    this.setState({
      tagFilters: updatedTags,
    });
    window.scrollTo(0, 0);
    this.searchUpdated('');
  }

  getSelectOptions() {
    let events = this.props.entertainment;

    let allTags = [];

    events.forEach((event) => {
      if (event.tagsEntertainmentType !== undefined) {
        event.tagsEntertainmentType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }
    });

    let selectOptions = [];

    this.state.tagFilters.forEach((tagx, indexA) => {
      if (tagx.sid === 'genre') {
        tagx.tags.forEach((tag, indexB) => {
          let status = allTags.includes(tag.tag);
          if (status === true) {
            selectOptions.push({
              value: tag,
              label: tag.tag,
            });
          }
        });
      }
    });

    let reversedOptions = selectOptions.reverse();
    // this.setTagFilters(updatedTagFilters);
    return reversedOptions;
  }

  getCategories(cattag) {
    const categories = [];

    if (
      this.props.category === 'Music' ||
      this.props.category === 'music'
    ) {
      this.props.tags.forEach((cattag) => {
        if (
          // cattag.sid === 'entertainmentType' ||
          cattag.sid === 'entertainmentType'
        ) {
          const tags = cattag.tags;
          tags.forEach((tag) => {
            let tagBundle = {};
            if (tag.plural !== undefined) {
              tagBundle.category = tag.plural;
            } else {
              tagBundle.category = tag.tag;
            }
            tagBundle.tag = tag.tag;
            categories.push(tagBundle);
          });
        }
      });
    } else if (
      this.props.category === 'Entertainment' ||
      this.props.category === 'entertainment'
    ) {
      this.props.tags.forEach((cattag) => {
        if (
          cattag.sid === 'eventType'
          // cattag.sid === 'genre'
        ) {
          const tags = cattag.tags;
          tags.forEach((tag) => {
            let tagBundle = {};
            if (tag.plural !== undefined) {
              tagBundle.category = tag.plural;
            } else {
              tagBundle.category = tag.tag;
            }
            tagBundle.tag = tag.tag;
            categories.push(tagBundle);
          });
        }
      });
    } else if (
      this.props.category === 'food' ||
      this.props.category === 'Food'
    ) {
      this.props.tags.forEach((cattag) => {
        if (
          cattag.sid === 'cuisineType'
          // cattag.sid === 'genre'
        ) {
          const tags = cattag.tags;
          tags.forEach((tag) => {
            let tagBundle = {};
            if (tag.plural !== undefined) {
              tagBundle.category = tag.plural;
            } else {
              tagBundle.category = tag.tag;
            }
            tagBundle.tag = tag.tag;
            categories.push(tagBundle);
          });
        }
      });
    }

    return categories;
  }

  // Filter Functions
  openFilterModal(user) {
    this.setState({ filterModalVisible: true });
  }

  closeFilterModal = () => {
    this.searchUpdated('');
    window.scrollTo(0, 0);
    this.setState({ filterModalVisible: false });
  };

  setTagFilters = (tagFilters) => {
    this.setState({ tagFilters });
  };

  setDistanceFilter = (value) => {
    this.setState({
      distanceFilterState: value,
    });
  };

  setMoodModalState(status) {
    this.setState({
      moodSet: status,
    });
    this.searchUpdated('');
  }

  setFirstFilter(tag) {
    let updatedTags = this.state.tagFilters;
    updatedTags[tag.parentindex].tags[tag.tagindex].checked = true;
    this.setState({
      tagFilters: updatedTags,
    });
    this.setTagFilters(updatedTags);
    this.searchUpdated(this.state.searchTerm);
    this.clearLanding(false);
    this.updateUauth(updatedTags);
  }

  getGeoLocation = (locationsx) => {
    let self = this;
    let currentLongitude = '';
    let currentLatitude = '';
    this.getPosition()
      .then((position) => {
        if (position.coords) {
          self.setState({
            currentLongitude: position.coords.longitude,
            currentLatitude: position.coords.latitude,
            error: null,
          });
          currentLatitude = position.coords.latitude;
          currentLongitude = position.coords.longitude;
          this.addDistance(
            this.state.locations,
            currentLongitude,
            currentLatitude,
          );
          this.searchUpdated('', locationsx);
        } else {
          alert('Geolocation is not supported by this browser.');
        }
      })
      .catch((err) => {
        console.error('something wrongg', err.message);
        self.setState({
          locationServices: false,
        });
      });
  };

  getPosition() {
    let options = {
      timeout: 50000,
      maximumAge: 10000,
      enableHighAccuracy: true,
    };
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          options,
        ),
      );
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }

  addDistance(locations, currentLongitude, currentLatitude) {
    const locationsArray = [];
    locations.forEach((location) => {
      const locLat = location.address.latitude;
      const locLong = location.address.longitude;
      const distance = geolib.getDistance(
        { latitude: locLong, longitude: locLat },
        { latitude: currentLatitude, longitude: currentLongitude },
      );
      // console.log("geolibtard", distance);
      const miles = geolib.convertUnit('mi', distance, 2); // -> 14,21
      location.address.distance = miles;

      locationsArray.push(location);
    });
    const sortedLocations = this.sortAndWeight(
      locations,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );

    this.updateUauthGuide(locationsArray, sortedLocations);

    this.setState({
      locationsWithDistance: locationsArray,
      filteredArray: sortedLocations,
      loading: false,
      locationServices: true,
    });
  }

  sortAndWeight(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    const sortedLocations = this.sortLocations(
      locations,
      distanceFilterState,
      currentLongitude,
      currentLatitude,
      sortState,
    );
    // const weightedLocations = this.getWeightedLocations(
    //   sortedLocations,
    //   this.props.profile,
    // );

    return sortedLocations;
  }

  sortLocations(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    if (sortState === 1) {
      const locationsArray = [];
      locations.forEach((location) => {
        const miles = location.address.distance;
        if (location.type !== 1) {
          if (distanceFilterState === 0) {
            if (miles <= 50) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 1) {
            if (miles <= 10) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 2) {
            locationsArray.push(location);
          }
        }
      });
      return _.sortBy(locationsArray, (o) => o.address.distance);
    } else {
      return _.sortBy(locations, (o) => o.locationTitle);
    }
  }

  searchUpdated(term, locationsx) {
    // let locationsWithDistance = this.state.locationsWithDistance;
    // if (this.props.uauth.guide.locationsWithDistance.length !== 0) {
    //   locationsWithDistance =
    //     this.props.uauth.guide.locationsWithDistance;
    // }
    // if (locationsx !== undefined) {
    //   this.props.setLocations(locationsx);
    // }
    // const filteredList = locationsWithDistance.filter(
    //   createFilter(term, KEYS_TO_FILTERS),
    // );
    // const filteredPromos = this.state.promos.filter(
    //   createFilter(term, KEYS_TO_FILTERS),
    // );
    // let filteredLocations = [];
    // const sortedLocations = this.sortAndWeight(
    //   filteredList,
    //   this.state.distanceFilterState,
    //   this.state.currentLongitude,
    //   this.state.currentLatitude,
    //   this.state.sortState,
    // );
    // let selectedTags = [];
    // let tagFilters = this.state.tagFilters;
    // if (this.props.uauth.guide.tagFilters.length !== 0) {
    //   tagFilters = this.props.uauth.guide.tagFilters;
    // }
    // tagFilters.forEach((tagCat) => {
    //   tagCat.tags.forEach((tag) => {
    //     if (tag.checked === true) {
    //       selectedTags.push(tag.tag);
    //     }
    //   });
    // });
    // if (selectedTags.length > 0) {
    //   filteredLocations = this.updateLocations(
    //     tagFilters,
    //     sortedLocations,
    //   );
    // } else {
    //   filteredLocations = sortedLocations;
    // }
    // if (filteredLocations.length === 0) {
    //   this.setDistanceFilter(2);
    //   const sortedLocationsx = this.sortAndWeight(
    //     filteredList,
    //     2,
    //     this.state.currentLongitude,
    //     this.state.currentLatitude,
    //     this.state.sortState,
    //   );
    //   if (selectedTags.length > 0) {
    //     filteredLocations = this.updateLocations(
    //       tagFilters,
    //       sortedLocationsx,
    //     );
    //   } else {
    //     filteredLocations = sortedLocationsx;
    //   }
    // }
    // this.setState({
    //   searchTerm: term,
    //   filteredArray: filteredLocations,
    //   filteredPromos: filteredPromos,
    // });
  }

  updateLocations(tags, locations) {
    // This will have every location but with its tags put into a single list
    const locationsReady = [];
    // For each location we push all of its tags into the allTags array
    locations.forEach((location) => {
      const newLocation = location;
      const allTags = [];
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      newLocation.tags = allTags;

      locationsReady.push(newLocation);
    });
    // const dif = _.differenceWith(cuisine, beverages, _.isEqual);
    // const filtered = _.filter(coll, item => _.some(filter, key => _.includes(item.tags, key)));

    // Filter Locations Based On Selected Categories
    function filterByMultipleFields(coll, filter) {
      // Organize Tags into their categories
      const tagsBeverages = [];
      const tagsSettings = [];
      const tagsCuisine = [];
      const tagsHours = [];
      const tagsDateType = [];
      const tagsLocationType = [];

      let beverages = [];
      let locationType = [];
      let setting = [];
      let type = [];
      let cuisine = [];
      let hours = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Hours') {
            tagsHours.push(tag.tag);
          } else if (tagcat.parent === 'Beverages') {
            tagsBeverages.push(tag.tag);
          } else if (tagcat.parent === 'Date Type') {
            tagsDateType.push(tag.tag);
          } else if (tagcat.parent === 'Cuisine Type') {
            tagsCuisine.push(tag.tag);
          } else if (tagcat.parent === 'Setting Type') {
            tagsSettings.push(tag.tag);
          } else if (tagcat.parent === 'Location Type') {
            tagsLocationType.push(tag.tag);
          }
        });
      });

      filter.forEach((tag) => {
        if (tagsBeverages.includes(tag) === true) {
          beverages = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsBeverages, key),
            ),
          );
        } else if (tagsDateType.includes(tag) === true) {
          type = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsDateType, key),
            ),
          );
        } else if (tagsLocationType.includes(tag) === true) {
          locationType = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsLocationType, key),
            ),
          );
        } else if (tagsSettings.includes(tag) === true) {
          setting = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsSettings, key),
            ),
          );
        } else if (tagsHours.includes(tag) === true) {
          hours = _.filter(locationsReady, (item) =>
            _.some(filter, (key) => _.includes(item.tagsHours, key)),
          );
        } else if (tagsCuisine.includes(tag) === true) {
          cuisine = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsCuisine, key),
            ),
          );
        }
      });
      const filtered = _.filter(coll, (item) =>
        _.some(filter, (key) => _.includes(item.tags, key)),
      );
      const arrays = [
        cuisine,
        beverages,
        type,
        setting,
        hours,
        locationType,
      ];
      const result = _.spread(_.union)(arrays);
      return result;
    }
    const filter = this.selectedTags(tags);
    const result = filterByMultipleFields(locationsReady, filter);

    return result;
    // if (this.props.selectedCategories.length > 0) {
    //   this.setState({
    //     filteredLocations: result,
    //   });
    // } else {
    //   this.setState({
    //     filteredLocations: locations,
    //   });
    // }
  }

  selectedTags(tagFilters) {
    let selectedTags = [];
    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });
    return selectedTags;
  }

  getMoodLinks() {
    let upcomingEvents = this.props.entertainment;

    let allTags = [];

    upcomingEvents.forEach((event) => {
      if (
        event.tagsEntertainmentType !== undefined &&
        event.tagsEntertainmentType.length !== 0
      ) {
        if (event.tagsEntertainmentType !== undefined) {
          event.tagsEntertainmentType.forEach((tag) => {
            let status = allTags.includes(tag);
            if (status === false) {
              allTags.push(tag);
            }
          });
        }
      }
    });

    return allTags;
  }

  getCurrentDistance(filter) {
    let text = '';
    if (filter === 0) {
      text = '50';
    } else if (filter === 1) {
      text = '10';
    } else {
      text = '100+';
    }
    return text;
  }

  selectChange = (values) => {
    // let updatedTags = this.state.tagFilters;
    // updatedTags[values.value.parentindex].tags[
    //   values.value.tagindex
    // ].checked = true;
    // this.setState({
    //   tagFilters: updatedTags,
    // });
    // this.setTagFilters(updatedTags);
    // this.searchUpdated(this.state.searchTerm);
    // this.clearLanding(false);
    // this.updateUauth(updatedTags);

    this.props.history.push('/guide/category/music/' + values.label);
  };

  render() {
    let links = this.getMoodLinks();
    let count = this.props.count;
    let selectOptions = this.getSelectOptions();
    let categories = this.getCategories();

    const bundle = this.findPromotedLocations(
      this.props.entertainment,
      this.props.category,
      this.props.tagCat,
    );

    let promotedLocations = bundle.promotedLocations;
    return (
      <div>
        <div>
          <DailyEntertainerSpotlight
            location={promotedLocations[0]}
          />
        </div>
        <div className="homeMoodBar">
          <Row style={{ margin: '0px 0px 0px 0px' }}>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              // className="no-padding"
              style={{ padding: '5px 5px 5px 5px' }}
            >
              <div
                style={{
                  width: '80%',
                  maxWidth: '400px',
                  margin: '0px auto',
                }}
              >
                <Select
                  value={this.state.selectInput}
                  closeMenuOnSelect={true}
                  blurInputOnSelect={false}
                  onChange={(e) => this.selectChange(e, 0, 'one')}
                  options={selectOptions}
                  isMulti={false}
                  placeholder={'What are you in the mood for?'}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ background: 'whitesmoke', marginTop: '5px' }}>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              // className="no-padding"
              style={{ padding: '5px 5px 5px 5px' }}
            >
              {/* <SearchInput
                  className="search-input guide-search"
                  onChange={this.searchUpdated}
                  placeholder="All Locations"
                  value={searchTerm}
                />
                <a
                  onClick={() => {
                    this.clearLanding(false);
                  }}
                  className="pointer"
                >
                  <MaterialIcon
                    icon="search"
                    size={45}
                    color="#ffffff"
                  />
                </a> */}
              <div
                style={{
                  textAlign: 'center',
                }}
                id="menu"
              >
                <ul>
                  {links.map((link, index) => (
                    <Link
                      to={{
                        pathname: '/guide/entertainers/' + link,
                      }}

                      // className="white"
                    >
                      <li>
                        <span>{link}</span>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div
          style={{
            padding: '10px',
            background: '#f21b59',
            borderBottom: '1px solid black',
            borderTop: '0px solid black',
          }}
        >
          <center>
            <Link
              to={{
                pathname: '/guide/category/entertainment',
              }}
            >
              <span style={{ color: 'white' }}>
                Search By Events{' '}
                <span style={{ color: 'white' }}>|</span>
              </span>
            </Link>
            <Link
              to={{
                pathname: '/guide/category/Entertainment%20Venue',
              }}
            >
              <span style={{ paddingLeft: '5px', color: 'white' }}>
                Search By Venue
              </span>
            </Link>
          </center>
        </div>
        <div className="homeSectionAll">
          <div>
            {categories.map((category, index) => (
              <GuideEntertainerCategory
                category={category.tag}
                tagCat={'tagsEntertainmentType'}
                entertainment={promotedLocations}
                count={this.props.count}
                catTitle={category.category}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    locations: state.locations.locations,
    tags: state.tags.tags,
    uauth: state.unauth,
    user: state.user,
    timedEvents: state.timedEvents.events,
  };
}

export default withRouter(
  connect(mapStateToProps)(GuideEntertainersAll),
);
