import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { push } from 'connected-react-router';
import { savePackage } from '../../services/PackagesServices';
import { init as getPackages } from '../packagesSaga/reducer';
import { getAllLocations } from '../allLocationsSaga/reducer';

function* updatePackageAction(action) {
  // yield put(getEvents(action.payload.event.creationDetails.plannerId));
  // yield call(saveVouchers, action.payload.userVouchers);
  // yield put(getUserVouchers(action.payload.profile.userId));
  // yield put({
  //   type: actions.SET_CE,
  //   payload: {
  //     ...defaultState,
  //     defaultState
  //   }
  // });
  yield put(push('/admin/event/create/itinerary'));
}

function* savePackageAction(action) {
  const defaultState = {
    step: 1,
    containsCustom: false,
    newEventRef: null,
    started: 0,
    package: {
      created: null,
      title: '',
      id: null,
      category: {
        Custom: true,
      },
      locations: [],
      description: null,
      heroImage: null,
      thumbnail: null,
      zoneId: null,
      creatorInfo: {
        creatorImage: null,
        creatorName: null,
        creatorId: null,
      },
    },
  };
  yield call(savePackage, action.payload);
  yield put(getPackages(1));
  yield put(getAllLocations(1));
  yield put({
    type: actions.SET_NEWPACKAGE,
    payload: {
      ...defaultState,
      defaultState,
    },
  });
}

function* setNewPackageAction(action) {
  yield put(push('/vendor/events/'));
}

function* addPackageAction(action) {
  const packageBundle = action.payload;
  yield put({
    type: actions.SET_NEWPACKAGE,
    payload: {
      step: packageBundle.step,
      containsCustom: packageBundle.containsCustom,
      newEventRef: packageBundle.newEventRef,
      started: packageBundle.started,
      package: packageBundle.package,
    },
  });
  yield put(push('/admin/event/create/add/'));
}

function* resetPackageAction(action) {
  const defaultState = {
    step: 1,
    containsCustom: false,
    newEventRef: null,
    started: 0,
    package: {
      created: null,
      title: '',
      id: null,
      category: {
        Custom: true,
      },
      locations: [],
      description: null,
      heroImage: null,
      thumbnail: null,
      zoneId: null,
      creatorInfo: {
        creatorImage: null,
        creatorName: null,
        creatorId: null,
      },
    },
  };
  yield put({
    type: actions.SET_NEWPACKAGE,
    payload: {
      ...defaultState,
      defaultState,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.UPDATE_PACKAGE, updatePackageAction);
  yield takeLatest(actions.SAVE_PACKAGE, savePackageAction);
  yield takeLatest(actions.ADD_PACKAGE, addPackageAction);
  yield takeLatest(actions.RESET_PACKAGE, resetPackageAction);
  yield takeLatest(actions.SET_NEWPACKAGE, setNewPackageAction);
}
