import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap';
import firebase from '../config/firebase';
import moment from 'moment';
// Components
import LocationDetails from '../components/LocationDetails';
import VendorFeatures from '../components/VendorFeatures';
// Reducers
import { resetCe, getCe } from '../sagas/customEventSaga/reducer';
import {
  getNewEvent,
  resetNewEvent,
} from '../sagas/createEventSaga/reducer';
import { getUauth } from '../sagas/unauthenticatedSaga/reducer';
import { setPackages } from '../sagas/packagesSaga/reducer';
import { setLocations } from '../sagas/locationsSaga/reducer';
import { getTimedEvents } from '../sagas/timedEventsSaga/reducer';
import { getTaRequest } from '../sagas/taRequestSaga/reducer';

const query = require('array-query');

export class Location extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      promos: [],
      claim: false,
      claimCode: '',
    };
    this.setClaim = this.setClaim.bind(this);
    this.addLocation = this.addLocation.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setUp();
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setUp();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setUp() {
    // Get Packages if they are not there already

    // Get this specific locations Details and Promos if the user is NOT signed in
    let location = [];
    let locations = [];
    let packages = [];
    let promos = [];
    const locationsArray = [];
    if (
      this.props.user.authenticated === false &&
      this.props.ulocations !== undefined &&
      this.props.ulocations.length === 0
    ) {
      const db = firebase.firestore();
      db.collection('locations')
        .where('locationId', '==', this.props.match.params.id)
        .get()
        .then((querySnapshot) => {
          //Notice the arrow funtion which bind `this` automatically.
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            location.push(doc.data());
          });
          if (this.props.packages === undefined) {
            db.collection('packages')
              .where('zoneId', '==', location[0].zoneId)
              .get()
              .then((querySnapshot) => {
                //Notice the arrow funtion which bind `this` automatically.
                querySnapshot.forEach(function (doc) {
                  // doc.data() is never undefined for query doc snapshots

                  let packagex = doc.data();
                  packagex.packageId = doc.id;
                  packages.push(packagex);
                });
                let packagesxxx = {
                  packages: packages,
                };
                this.setState({
                  packages: packagesxxx,
                });
                this.props.setPackages(packagesxxx);
              });
          } else {
            this.setState({
              packages: this.props.packages,
            });
          }
          this.props.getTimedEvents(location[0].zoneId);
          db.collection('locations')
            .where('zoneId', '==', location[0].zoneId)
            .where('settings.published', '==', true)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach(function (doc) {
                locations.push(doc.data());
              });
              let locationsx = [];
              locationsx.push(locations);

              let locationsReady = [];
              locations.forEach((location) => {
                const newLocation = location;
                const allTags = [];
                if (location.tagsBeverages !== undefined) {
                  location.tagsBeverages.forEach((tag) => {
                    allTags.push(tag);
                    let status =
                      newLocation.tagsBeverages.includes('Drinks');
                    if (status === false) {
                      allTags.push('Drinks');
                      newLocation.tagsBeverages.push('Drinks');
                    }
                  });
                }

                if (location.tagsSettings !== undefined) {
                  location.tagsSettings.forEach((tag) => {
                    allTags.push(tag);
                  });
                }

                if (location.tagsCuisine !== undefined) {
                  location.tagsCuisine.forEach((tag) => {
                    allTags.push(tag);
                    let status =
                      newLocation.tagsCuisine.includes('Food');
                    if (status === false) {
                      allTags.push('Food');
                      newLocation.tagsCuisine.push('Food');
                    }
                  });
                }

                if (location.tagsHours !== undefined) {
                  location.tagsHours.forEach((tag) => {
                    allTags.push(tag);
                  });
                }

                if (location.tagsDateType !== undefined) {
                  location.tagsDateType.forEach((tag) => {
                    allTags.push(tag);
                  });
                }

                if (location.tagsLocationType !== undefined) {
                  location.tagsLocationType.forEach((tag) => {
                    allTags.push(tag);
                  });
                }

                newLocation.tags = allTags;

                locationsReady.push(newLocation);
              });
              let locationBundle = [];
              locationBundle.push(locationsReady);

              this.props.setLocations(locationBundle);
              this.setState({
                location: location[0],
                path: '/home',
                loading: false,
              });
            });
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
        });
      db.collection('promos')
        .where(
          'locationIds',
          'array-contains',
          this.props.match.params.id,
        )
        .get()
        .then((querySnapshot) => {
          //Notice the arrow funtion which bind `this` automatically.
          querySnapshot.forEach(function (doc) {
            let promo = doc.data();
            promo.id = doc.id;
            promos.push(promo);
          });

          this.setState({
            promos,
          });
        });
    } else {
      if (
        this.props.location !== undefined &&
        this.props.location.state !== undefined &&
        this.props.location.state.type !== undefined &&
        this.props.location.state.type.type !== undefined
      ) {
        if (this.props.location.state.type.type === 2) {
          const path =
            '/invite/' + this.props.location.state.token.token;
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type.type === 75) {
          const path =
            '/event/' + this.props.location.state.eventId.eventId;
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        }
      } else if (
        this.props.location !== undefined &&
        this.props.location.state !== undefined &&
        this.props.location.state.type !== undefined &&
        this.props.location.state.type.type === undefined
      ) {
        if (this.props.location.state.type === 3) {
          const path =
            '/package/' + this.props.location.state.packageId;
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type === 4) {
          const path = '/admin/packages/';
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type === 5) {
          const path = '/event/create/add/';
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type === 6) {
          const path = '/events/upcoming';
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type === 7) {
          const path =
            '/event/edit/' + this.props.location.state.eventId + '/1';
          this.setState({
            path: path,
            loading: false,
            location:
              this.props.location.state.location.locationReady,
          });
        } else if (this.props.location.state.type === 12) {
          const path = '/vendor/locations/';
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type === 11) {
          const path = '/vendor/events/';
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type === 13) {
          const path = '/admin/locations/';
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type === 14) {
          const path = '/vevent/' + this.props.location.state.eventId;
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type === 35) {
          const path = '/event/create/home/1';
          this.setState({
            path: path,
            loading: false,
            location:
              this.props.location.state.location.locationReady,
          });
        } else if (this.props.location.state.type === 57) {
          const path = '/guide/search/';
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type === 73) {
          const path = '/places';
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        } else if (this.props.location.state.type === 500) {
          let currentUrl =
            this.props.history.location.state.currentUrl;
          let backArray = [];
          if (
            this.props.history.location.state.backArray !== undefined
          ) {
            this.props.history.location.state.backArray.forEach(
              (url) => {
                backArray.push(url);
              },
            );
          }
          backArray.push(currentUrl);
          let path = '/homes';
          if (currentUrl !== undefined) {
            path = currentUrl;
          }
          if (this.props.newEvent.clear === false) {
            path = '/event/create/add/';
          }
          let locationDetails = [];
          if (this.props.user.authenticated === true) {
            locationDetails = query('locationId')
              .is(this.props.match.params.id)
              .on(this.props.locations);
          } else {
            locationDetails = query('locationId')
              .is(this.props.match.params.id)
              .on(this.props.ulocations);
          }
          let location = locationDetails[0];
          this.setState({
            location,
            backArray,
            path,
            loading: false,
          });
        } else if (this.props.location.state.type === 600) {
          const path = '/home';
          const locationDetails = query('locationId')
            .is(this.props.match.params.id)
            .on(this.props.ulocations);
          let location = locationDetails[0];
          this.setState({
            location,
            path,
            loading: false,
          });
        } else if (this.props.location.state.type === 650) {
          const path = '/home';
          const locationDetails = query('locationId')
            .is(this.props.match.params.id)
            .on(this.props.locations);
          let location = locationDetails[0];
          this.setState({
            location,
            path,
            loading: false,
          });
        } else {
          const path =
            '/event/' + this.props.location.state.eventId.eventId;
          this.setState({
            path: path,
            loading: false,
            location: this.props.location.state.location.location,
          });
        }
      } else {
        const locationDetails = query('locationId')
          .is(this.props.match.params.id)
          .on(this.props.locations);
        let location = locationDetails[0];
        this.setState({
          location: location,
          path: '/home',
          loading: false,
        });
      }
    }
  }

  quickEvent(location) {
    const newEvent = {
      connections: [],
      planFirst: false,
      connectFirst: false,
      clear: false,
      step: 2,
      screen: 1,
      fromVEvent: false,
      fromLocation: true,
      fromPackage: false,
      vEvent: {},
      singleLocation: location,
      weightedLocations: [],
    };
    this.props.getNewEvent(newEvent);
  }

  loggedOutQuickEvent(location) {
    const newEvent = {
      connections: [],
      planFirst: false,
      connectFirst: false,
      clear: false,
      step: 10,
      screen: 1,
      fromVEvent: false,
      fromLocation: true,
      fromPackage: false,
      vEvent: {},
      singleLocation: location,
      weightedLocations: [],
    };
    this.props.getNewEvent(newEvent);
  }

  // addLocation(customEvent, location) {
  //   console.log('hellox');
  //   let customImages = false;
  //   if (location.settings.customImages !== undefined) {
  //     customImages = location.settings.customImages;
  //   }

  //   if (customEvent.event.locations !== null) {
  //     const newLocations = [];
  //     customEvent.event.locations.forEach((location) => {
  //       newLocations.push(location);
  //     });
  //     if (location.type === 1) {
  //       newLocations.push({ location: location, type: 1 });
  //     } else {
  //       newLocations.push({
  //         type: 0,
  //         locationId: location.locationId,
  //         locationTitle: location.locationTitle,
  //         locationThumb: location.locationThumb,
  //         address: location.address,
  //         hours: location.hours,
  //         settings: {
  //           customImages,
  //         },
  //       });
  //     }
  //     customEvent.event.locations = newLocations;
  //     customEvent.step = 1;
  //     this.props.getCe(customEvent);
  //   } else {
  //     const newLocations = [];
  //     if (location.type === 1) {
  //       newLocations.push({ location: location, type: 1 });
  //     } else {
  //       newLocations.push({
  //         locationId: location.locationId,
  //         type: 0,
  //       });
  //     }
  //     customEvent.step = 1;
  //     customEvent.event.locations = newLocations;
  //     this.props.getCe(customEvent);
  //     this.props.history.push('/event/create/home/1');
  //   }
  // }

  addLocation(customEvent, location) {
    let customImages = false;
    if (location.settings.customImages !== undefined) {
      customImages = location.settings.customImages;
    }

    if (customEvent.event.locations !== null) {
      const newLocations = [];
      customEvent.event.locations.forEach((location) => {
        newLocations.push(location);
      });
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          type: 0,
          locationId: location.locationId,
          locationTitle: location.locationTitle,
          locationThumb: location.locationThumb,
          address: location.address,
          hours: location.hours,
          settings: {
            customImages,
          },
        });
      }
      customEvent.event.locations = newLocations;
      customEvent.step = 1;

      let newTagFilters = this.props.taRequest.tagFilters;
      if (
        this.props.taRequest.eventCats.eat === true &&
        this.props.taRequest.eventCats.watch === true
      ) {
        this.setState({
          guideMode: 2,
        });
        newTagFilters[8].tags[1].checked = true;
        newTagFilters[8].tags[2].checked = false;
      }
      let startingCat = 1;
      if (this.props.taRequest.eventCats.startingCat !== undefined) {
        if (this.props.taRequest.eventCats.startingCat === 1) {
          startingCat = 2;
        } else {
          startingCat = 1;
        }
      }
      // Update TaRequest
      let bundle = {
        tagFilters: newTagFilters,
        distance: this.props.taRequest.distance,
        startLocation: 'lastLocation',
        eventCats: {
          eat: this.props.taRequest.eventCats.eat,
          watch: this.props.taRequest.eventCats.watch,
          do: this.props.taRequest.eventCats.do,
          startingCat,
          endCat: this.props.taRequest.eventCats.endCat,
        },
        currentLatitude: this.props.taRequest.currentLatitude,
        currentLongitude: this.props.taRequest.currentLongitude,
        closestRegion: this.props.taRequest.closestRegion,
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
        reroll: this.props.taRequest.reroll,
      };
      this.props.getTaRequest(bundle);

      customEvent.activeEventCats = {
        eat: false,
        watch: this.props.taRequest.eventCats.watch,
        do: this.props.taRequest.eventCats.do,
        startingCat: this.props.taRequest.eventCats.startingCat,
        endCat: this.props.taRequest.eventCats.endCat,
      };
      // this.props.clearTaRequest(this.props.taRequest);
      this.props.getCe(customEvent);

      if (
        this.props.taRequest.eventCats.startingCat ===
        this.props.taRequest.eventCats.endCat
      ) {
        this.props.clearTaRequest(this.props.taRequest);
        if (this.props.customEvent.editMode === true) {
          this.props.history.push(
            '/event/edit/' +
              this.props.customEvent.event.eventId +
              '/1',
          );
        } else {
          this.props.history.push('/event/create/home/1');
        }
      }
    } else {
      const newLocations = [];
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          locationId: location.locationId,
          type: 0,
        });
      }
      customEvent.step = 1;
      customEvent.event.locations = newLocations;
      this.props.getCe(customEvent);

      this.props.clearTaRequest(this.props.taRequest);
      if (this.props.customEvent.editMode === true) {
        this.props.history.push(
          '/event/edit/' +
            this.props.customEvent.event.eventId +
            '/1',
        );
      } else {
        this.props.history.push('/event/create/home/1');
      }
    }
  }

  setTitleBar() {
    //  let back = this.props.history.location.state.back.back;
    // let path = this.props.history.location.state.back.back;
    let path = this.state.path;
    0;
    if (this.props.profile.profilePhotoPath !== undefined) {
      return (
        <div id="headersTop">
          <div className="backbar">
            <Row className="no-margin">
              <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
                <Link
                  to={{
                    pathname: path,
                    state: {
                      back: {
                        back: window.location.pathname,
                      },
                    },
                  }}
                  className="left"
                >
                  <MaterialIcon
                    icon="keyboard_backspace"
                    size={25}
                    color="white"
                  />
                </Link>
              </Col>
              <Col lg={8} md={8} sm={8} xs={8}>
                <center>
                  <span className="page-header">
                    Location Details
                  </span>
                </center>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
                {this.props.profile.userType === 1 && (
                  <Link
                    to={{
                      pathname:
                        '/vendor/location/edit/' +
                        this.state.location.locationId,
                    }}
                    className="right"
                  >
                    <MaterialIcon
                      icon="edit"
                      size={25}
                      color="white"
                    />
                  </Link>
                )}
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }

  getOpenStatus(location) {
    let status = {
      status: false,
      day: '',
    };
    if (
      this.props.customEvent.event.scheduledTime.date !== null &&
      this.props.customEvent.event.scheduledTime.date !== ''
    ) {
      let eventDate = this.props.customEvent.event.scheduledTime.date;
      let eventDay = moment(eventDate).format('dddd');
      status.day = eventDay;
      let lowercase = eventDay.toLowerCase();
      if (lowercase === 'saturday') {
        lowercase = 'saterday';
      }
      let openString = lowercase + 'Open';
      let fromAMPMString = lowercase + 'FromAMPM';
      let from = lowercase + 'FromHour';
      let toAMPMString = lowercase + 'ToAMPM';
      let to = lowercase + 'ToHour';

      let eventTime = null;
      let hour = null;
      let eventAMPM = null;
      let hourInt = null;

      if (
        this.props.customEvent.event.scheduledTime.time !== undefined
      ) {
        eventTime = this.props.customEvent.event.scheduledTime.time;
        hour = eventTime.split(':')[0];
      }

      // Check if Location is open on dates and time

      let openTime = true;
      let closeTime = undefined;
      if (location.hours !== undefined) {
        let openStatus = location.hours[lowercase][openString];

        openTime = location.hours[lowercase][from];
        let openTimeInt = parseInt(openTime);
        let fromAMPM = location.hours[lowercase][fromAMPMString];
        closeTime = location.hours[lowercase][to];
        let closeTimeInt = parseInt(closeTime);
        let toAMPM = location.hours[lowercase][toAMPMString];
        if (fromAMPM === 'pm') {
          openTimeInt = openTimeInt + 12;
        }
        if (toAMPM === 'pm') {
          closeTimeInt = closeTimeInt + 12;
        }
        if (
          this.props.customEvent.event.scheduledTime.time !==
          undefined
        ) {
          eventAMPM =
            this.props.customEvent.event.scheduledTime.time.includes(
              'P.M',
            );
          hourInt = parseInt(hour);
        }
        if (eventAMPM === true) {
          hourInt = hourInt + 12;
        }
        if (openStatus === false) {
          status.status = true;
          if (hourInt >= openTimeInt && hourInt < closeTimeInt) {
          }
        }
      }
    } else {
      status.status = true;
    }

    return status;
  }

  getFooter(mobile) {
    // if (
    //   this.props.location.state !== undefined &&
    //   this.props.location.state.type !== 35
    // ) {

    let openStatus = this.getOpenStatus(this.state.location);

    return (
      <Container className={mobile === false ? 'getGoing' : ''}>
        <Row className="no-margin">
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            <center>
              {this.props.user.authenticated === true &&
                (this.props.newEvent.clear === true ? (
                  <a
                    onClick={() => {
                      this.quickEvent(this.state.location);
                    }}
                  >
                    <span className="center solidredbutton">
                      Add To Itinerary
                    </span>
                  </a>
                ) : (
                  <div>
                    {openStatus.status === true ? (
                      <a
                        onClick={() => {
                          this.addLocation(
                            this.props.customEvent,
                            this.state.location,
                          );
                        }}
                      >
                        <span className="center solidredbutton">
                          Add To Itinerary
                        </span>
                      </a>
                    ) : (
                      <span className="center solidgreybutton">
                        This Location is Closed on {openStatus.day}s
                      </span>
                    )}
                  </div>
                ))}

              {this.props.user.authenticated === false && (
                <a
                  onClick={() => {
                    this.loggedOutQuickEvent(this.state.location);
                  }}
                >
                  <span className="center solidredbutton">
                    Add To Itinerary
                  </span>
                </a>
              )}
            </center>
          </Col>
        </Row>
      </Container>
    );
    // }
  }

  setClaim(mode) {
    this.setState({ claim: mode });
  }

  onChange = (event) => {
    this.setState({ claimCode: event.target.value });
  };

  claimLocation(code) {
    const db = firebase.firestore();
    let location = [];
    db.collection('vendorCodes')
      .where('locationId', '==', this.props.match.params.id)
      .get()
      .then((querySnapshot) => {
        //Notice the arrow funtion which bind `this` automatically.
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          location.push(doc.data());
        });
        if (code === location[0].vendorCode) {
          let newUauth = {
            claimLink: true,
            claimId: this.props.match.params.id,
            vendorId: null,
            vendorType: 1,
          };
          this.props.getUauth(newUauth);
          let path = '';
          if (this.props.user.authenticated === true) {
            path =
              '/vendor/signupprofile/' + this.props.match.params.id;
          } else {
            path = '/vendor/signup/';
          }

          this.props.history.push(path);
        }

        // this.setState({
        //   location: location[0],
        //   path: '/home',
        //   loading: false,
        // });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          {' '}
          <style
            dangerouslySetInnerHTML={{
              __html:
                ' #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer-container{display:none}  .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    if (this.state.claim === true) {
      return (
        <div>
          {this.setTitleBar()}
          <div
            style={{
              width: '300px',
              margin: '0px auto',
              marginBottom: '80px',
            }}
          >
            <center>
              <h4>Enter Claim Number</h4>
              <div style={{ width: '200px', margin: '0px auto' }}>
                <input
                  name="code"
                  value={this.state.claimCode}
                  onChange={this.onChange}
                  type="text"
                  placeholder=""
                  maxLength="6"
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                <center>
                  <span>
                    Don{"'"}t have your claim code? No Problem!
                    <br /> Just call us at (913) 384-7377
                  </span>
                </center>
              </div>
              {this.state.claimCode.length < 6 ? (
                <span
                  className="cancelButton"
                  style={{ float: 'inherit' }}
                >
                  Claim
                </span>
              ) : (
                <a
                  onClick={() => {
                    this.claimLocation(this.state.claimCode);
                  }}
                >
                  <span
                    className="saveButton"
                    style={{ float: 'inherit' }}
                  >
                    Claim
                  </span>
                </a>
              )}
              <a
                onClick={() => {
                  this.setClaim(false);
                }}
              >
                <span
                  className="saveButton"
                  style={{ float: 'inherit' }}
                >
                  Cancel
                </span>
              </a>
            </center>
          </div>
          <VendorFeatures persona={'1'} />

          {this.props.user.authenticated === true && (
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#mobile-nav { display: none }  .maincontent { top: 60px !important; margin-top: 0px; height: 100%} ',
              }}
            />
          )}
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;}.footer-container{display:none} .App{background-color: #fafafa;}  #authleft {display:none;} #bottomNav { display: none } .maincontent { top: 50px; height: 100%}.footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;} p{max-width: 100% !important}',
            }}
          />
        </div>
      );
    }
    let type = null;
    if (this.props.location.state !== undefined) {
      type = this.props.location.state.type;
    }
    return (
      <div id="location" style={{ height: '100%' }}>
        {this.setTitleBar()}
        <div style={{ height: '100%' }}>
          <LocationDetails
            location={this.state.location}
            locationPromos={this.state.promos}
            setClaim={this.setClaim}
            packages={this.state.packages}
            type={type}
          />
        </div>

        <div
          id={type !== 35 && 'startBar'}
          style={{ padding: '0px' }}
          className="mobileHide"
        >
          {this.getFooter(false)}
        </div>
        <div id={type !== 35 && 'startBar'} className="mobileShow">
          {this.getFooter(true)}
        </div>
        {this.props.user.authenticated === true && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                'html{display:grid} #mobile-nav { display: none }  .maincontent { top: 39px !important; margin-top: 0px; height: 100%} .App{background-color: #fafafa;}',
            }}
          />
        )}
        {this.props.user.authenticated === false && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                '@media (max-width: 800px){.maincontent { top: 80px !important;}}  ',
            }}
          />
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '@media (max-width: 800px){.maincontent { top: 39px}} html{display:grid} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;}.footer-container{display:none} .App{background-color: #fafafa;}  #authleft {display:none;} #bottomNav { display: none } .maincontent { top: 0px; height: 100%}.footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;} p{max-width: 100% !important}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    profile: state.profile.profile,
    locations: state.locations.locations,
    user: state.user,
    ulocations: state.locations.locations,
    customEvent: state.customEvent.customEvent,
    profile: state.profile.profile,
    newEvent: state.newEvent,
    taRequest: state.taRequest,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    resetNewEvent: (newEvent) => {
      dispatch(resetNewEvent(newEvent));
    },
    getUauth: (bundle) => {
      dispatch(getUauth(bundle));
    },
    setPackages: (packages) => {
      dispatch(setPackages(packages));
    },
    setLocations: (locations) => {
      dispatch(setLocations(locations));
    },
    getTimedEvents: (zoneId) => {
      dispatch(getTimedEvents(zoneId));
    },
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
    getTaRequest: (request) => {
      dispatch(getTaRequest(request));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
