import { Formik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { getNewEvent } from '../../sagas/createEventSaga/reducer';

const query = require('array-query');

class WriteMessage extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      message: '',
    };
  }

  sendInvite(user, message) {
    const eventUpdate = this.props.event;

    if (
      this.props.event.newEvent !== undefined &&
      this.props.event.newEvent === true
    ) {
      let newEventUpdate = this.props.newEvent;

      if (user.type === undefined) {
        const fullname = `${user.firstName} ${user.lastName}`;
        const timestamp = new Date();
        // Update Users
        eventUpdate.users[user.userId] = {
          access: true,
          approved: false,
          editor: false,
          attending: false,
          rejected: false,
          inviteAction: false,
          profile: {
            fullName: fullname,
            profilePhotoPath: user.profilePhotoPath,
            userName: user.userName,
          },
        };
        newEventUpdate.connections = eventUpdate.users;
        // this.props.getNewEvent(newEventUpdate);
        // this.props.changeScreen(1);
      } else {
        const timestamp = new Date();
        eventUpdate.users[user.userId] = {
          newUser: true,
          access: true,
          approved: false,
          editor: false,
          attending: false,
          rejected: false,
          inviteAction: false,
          profile: {
            fullName: this.props.fullName,
            profilePhotoPath: '',
            userName: '',
          },
        };
        eventUpdate.invitations[user.userId] = {
          dateSent: timestamp,
          senderName: this.props.profile.firstName,
          senderLastName: this.props.profile.lastName,
          profilePhotoPath: this.props.profile.profilePhotoPath,
          senderId: this.props.profile.userId,
          // userPurchaseId: eventUpdate.packageDetails.packageId,
          eventId: eventUpdate.eventId,
          // locations: eventUpdate.packageDetails.locations,
          receiverName: user.fullName,
          userName: '',
          userId: user.userId,
          receiverPhotoPath: '',
          type: 1,
          status: 0,
          message,
          editable: true,
        };
        newEventUpdate.connections = eventUpdate.users;
        // this.props.getNewEvent(newEventUpdate);
        // this.props.changeScreen(1);
      }
    } else {
      if (user.type === undefined) {
        const fullname = `${user.firstName} ${user.lastName}`;
        const timestamp = new Date();
        // Update Users
        eventUpdate.users[user.userId] = {
          access: true,
          approved: false,
          editor: false,
          attending: false,
          rejected: false,
          inviteAction: false,
          profile: {
            fullName: fullname,
            profilePhotoPath: user.profilePhotoPath,
            userName: user.userName,
          },
        };
        eventUpdate.invitations[user.userId] = {
          dateSent: timestamp,
          senderName: this.props.profile.firstName,
          senderLastName: this.props.profile.lastName,
          profilePhotoPath: this.props.profile.profilePhotoPath,
          senderId: this.props.profile.userId,
          userPurchaseId: eventUpdate.packageDetails.packageId,
          eventId: eventUpdate.eventId,
          locations: eventUpdate.packageDetails.locations,
          receiverName: fullname,
          userName: user.userName,
          userId: user.userId,
          receiverPhotoPath: user.profilePhotoPath,
          type: 1,
          status: 0,
          message,
          editable: true,
        };
        // this.props.sendInvite(eventUpdate);
        // this.props.changeScreen(1);
      } else {
        const timestamp = new Date();
        eventUpdate.users[user.userId] = {
          newUser: true,
          access: true,
          approved: false,
          editor: false,
          attending: false,
          rejected: false,
          inviteAction: false,
          profile: {
            fullName: this.props.fullName,
            profilePhotoPath: '',
            userName: '',
          },
        };
        eventUpdate.invitations[user.userId] = {
          dateSent: timestamp,
          senderName: this.props.profile.firstName,
          senderLastName: this.props.profile.lastName,
          profilePhotoPath: this.props.profile.profilePhotoPath,
          senderId: this.props.profile.userId,
          userPurchaseId: eventUpdate.packageDetails.packageId,
          eventId: eventUpdate.eventId,
          locations: eventUpdate.packageDetails.locations,
          receiverName: user.fullName,
          userName: '',
          userId: user.userId,
          receiverPhotoPath: '',
          type: 1,
          status: 0,
          message,
          editable: true,
        };
        // this.props.sendInvite(eventUpdate);
        // this.props.changeScreen(4);
      }
    }
  }

  // get event?

  // const packageDetails = query("packageId")
  //   .is(this.props.event.packageDetails.packageId)
  //   .on(this.props.packages);

  // const locations = packageDetails[0].locations;

  // const invitation = {
  //   dateSent: timestamp,
  //   senderName: this.props.profile.firstName,
  //   senderLastName: this.props.profile.lastName,
  //   receiverName: fullname,
  //   userName: user.userName,
  //   senderId: this.props.profile.userId,
  //   locations,
  //   userId: user.userId,
  //   profilePhotoPath: this.props.profile.profilePhotoPath,
  //   receiverPhotoPath: user.profilePhotoPath,
  //   type: 1,
  //   status: 0,
  //   userPurchaseId: this.props.event.packageDetails.packageId,
  //   eventId: this.props.event.eventId,
  //   message,
  //   scheduledTime: this.props.event.scheduledTime.timestamp,
  //   editable: false
  // };
  // this.props.sendinvitation(invitation, user.userId);
  // this.props.changeScreen(1);

  getSubmitButton(value) {
    if (value.values.message !== '') {
      return (
        <div>
          <center>
            <a
              onClick={() => {
                this.sendInvite(
                  this.props.invitedUser,
                  value.values.message,
                );
              }}
              className="redbutton white display-block"
            >
              <span> Send Invite!</span>
            </a>
          </center>
        </div>
      );
    } else {
      return (
        <div>
          <center>
            <a
              onClick={() => {
                this.sendInvite(
                  this.props.invitedUser,
                  value.values.message,
                );
              }}
              className="redbutton white display-block"
            >
              <span>Skip</span>
            </a>
          </center>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <Container>
          <div className="secondary-title-bar">
            <span>Invite Message</span>
          </div>
          <div id="message-form">
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <Formik
                  initialValues={{ message: '' }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      alert(JSON.stringify(values, null, 2));
                      setSubmitting(false);
                    }, 500);
                  }}
                  // validationSchema={Yup.object().shape({
                  //   email: Yup.string()
                  //     .email()
                  //     .required('Required'),
                  // })}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit} id="fullName">
                        {/* <label htmlFor="fullName" style={{ display: "block" }}>
                    Email
                  </label> */}
                        <textarea
                          id="message"
                          placeholder="This message is included on the invite."
                          type="text"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? 'text-input error'
                              : 'text-input'
                          }
                        />
                        {/* {errors.email && touched.email && (
              <div className="input-feedback">{errors.email}</div>
            )} */}
                        {/* 
                  <button
                    type="button"
                    className="outline"
                    onClick={handleReset}
                    disabled={!dirty || isSubmitting}
                  >
                    Reset
                  </button>
                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button> */}
                        {this.getSubmitButton(props)}
                      </form>
                    );
                  }}
                </Formik>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    newEvent: state.newEvent,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WriteMessage);
