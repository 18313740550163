import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import * as auth from '../../config/auth';
import { connect } from 'react-redux';
import firebase from '../../config/firebase';
import { Link } from 'react-router-dom';
import logo from '../../images/TrevelLogoText.svg';
import axios from 'axios';
// ReCaptcha
import ReCAPTCHA from 'react-google-recaptcha';
// import logo from '../../images/TrevelUnderlined.svg';
// import logo from '../../images/beta.png';
// Reducers
import { setNewUser, setUser } from '../../sagas/loginSaga/reducer';

const INITIAL_STATE = {
  email: '',
  password: '',
  confirmpassword: '',
  error: null,
  isReCaptchaChecked: false,
};

const SignupSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .required()
    .test(
      'regex',
      'Password must contain at least 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character',
      (val) => {
        let regExp = new RegExp(
          '^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$',
        );
        // console.log(regExp.test(val), regExp, val);
        return regExp.test(val);
      },
    ),
  confirmpassword: yup.string().when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf(
        [yup.ref('password')],
        'Both password need to be the same',
      ),
  }),
});

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.reCAPTCHA = React.createRef();
    this.state = { ...INITIAL_STATE };
  }

  signUp(email, password) {
    auth
      .doCreateUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        const user = {
          id: authUser.user.uid,
          userName: null,
          email: authUser.user.email,
          authenticated: true,
          newuser: true,
          destination: this.state.destination,
        };
        this.props.setNewUser(user);
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState(byPropKey('error', error));
      });
  }

  twitterSignUp = () => {
    var provider = new firebase.auth.TwitterAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  };

  facebookSignUp = () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  };

  googleSignUp = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  };

  getErrorMessage(error) {
    if (error !== null) {
      return error.message;
    }
  }

  handleSubmit = async (values) => {
    // verify reCaptcha
    // const captcha_validation = await axios.get(
    //   'https://us-central1-go-together-6b9cc.cloudfunctions.net/recaptcha',
    //   { params: { token: this.reCAPTCHA.current.getValue() } },
    // );

    // if (captcha_validation.data.success === true) {
    //   this.signUp(values.email, values.password);
    // } else {
    //   console.log('Captcha Error during registration');
    // }
    this.signUp(values.email, values.password);
  };

  onReCAPTCHAChange = (value) => {
    this.setState({
      isReCaptchaChecked: true,
    });
  };

  onReCAPTCHAExpire = () => {
    this.setState({
      isReCaptchaChecked: false,
    });
  };

  render() {
    const { email, password, confirmpassword, error } = this.state;
    const isInvalid =
      password === '' || email === '' || confirmpassword === '';

    return (
      <div id="signup">
        <div>
          <div className="title-area">
            <div
              style={{
                maxWidth: '200px',
                margin: '0px auto',
                marginBottom: '20px',
                position: 'relative',
                left: '-10px',
              }}
              className="mobileHide"
            >
              <img src={logo} />
            </div>

            <h3>Create a New Account</h3>
            <p className="signup-box-info">
              <span>Or </span>
              <Link
                to={{
                  pathname: '/login',
                }}
              >
                login to your account
              </Link>
              <span>.</span>
            </p>
          </div>
          <Formik
            initialValues={{
              email: '',
              password: '',
              confirmpassword: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <Container>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="left"
                    >
                      <Field
                        type="email"
                        name="email"
                        placeholder="Please enter your email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                      <span className="error-message">
                        {this.getErrorMessage(this.state.error)}
                      </span>

                      <Field
                        type="password"
                        name="password"
                        placeholder="Choose a password"
                      />
                      {/* <ErrorMessage name="password" component="div" /> */}
                      {errors.password ? (
                        <div className="error-message">
                          {errors.password}
                        </div>
                      ) : null}
                      <Field
                        type="password"
                        name="confirmpassword"
                        placeholder="Confirm password"
                      />
                      {/* <ErrorMessage name="password" component="div" /> */}
                      {errors.confirmpassword ? (
                        <div className="error-message">
                          {errors.confirmpassword}
                        </div>
                      ) : null}
                      <div
                        style={{
                          display: 'block',
                          margin: '0px auto',
                          width: '300px',
                          marginBottom: '10px',
                        }}
                      >
                        <ReCAPTCHA
                          ref={this.reCAPTCHA}
                          sitekey="6LeXS4sdAAAAAImlBJe-5gfH_v9-YTgDMcNTgAQN"
                          onChange={this.onReCAPTCHAChange}
                          onExpired={this.onReCAPTCHAExpire}
                        />
                      </div>

                      <button
                        type="submit"
                        disabled={!this.state.isReCaptchaChecked}
                      >
                        Register
                      </button>

                      <span
                        className="social-button"
                        onClick={() => {
                          this.googleSignUp();
                        }}
                      >
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgoogle-logo.png?alt=media&token=d0484856-780c-429a-9f95-fa774dc5652b"
                          height="20px"
                        />{' '}
                        Register with Google
                      </span>
                    </Col>
                    {/* <Col xs={12} md={6} sm={12} lg={6}>
                      <span
                        className="social-button"
                        onClick={() => {
                          this.googleSignUp();
                        }}
                      >
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgoogle-logo.png?alt=media&token=d0484856-780c-429a-9f95-fa774dc5652b"
                          height="20px"
                        />{' '}
                        Register with Google
                      </span>
                      <span
                        className="social-button"
                        onClick={() => {
                          this.facebookSignUp();
                        }}
                      >
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ffacebook-logo.png?alt=media&token=b5a3311f-4911-4cdf-861b-6c80ab4e29e9"
                          height="20px"
                        />{' '}
                        Register with Facebook
                      </span>
                      <span
                        className="social-button"
                        onClick={() => {
                          this.twitterSignUp();
                        }}
                      >
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ftwitter-logo.png?alt=media&token=acc72dae-60ec-4fa8-a94b-194e0a26c952"
                          height="20px"
                        />{' '}
                        Register with Twitter
                      </span>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <div className="disclaimer">
                        <p>
                          By registering for a Trevel account, you
                          agree to our Privacy policy and Terms of
                          service.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
          <style
            dangerouslySetInnerHTML={{
              __html: '.nav-signup { display: none }',
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    destination: state.destination.destination,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNewUser: (user) => {
      dispatch(setNewUser(user));
    },
    setUser: (user) => {
      dispatch(setUser(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpForm);
