import React from 'react';
import { connect } from 'react-redux';
// Components
import SignUpBanner from '../components/SignUpBanner';
import { Col, Row, Container } from 'reactstrap';
import Features from '../components/Features';
import RedCallToAction from '../components/RedCallToAction';
import { clearUauth } from '../sagas/unauthenticatedSaga/reducer';
import { Link } from 'react-router-dom';
// Reducers
import { getArticles } from '../sagas/articleSaga/reducer';

export class ArchGrant extends React.Component {
  UNSAFE_componentWillMount() {
    document.title = 'Trevel';
    if (this.props.user.authenticated === true) {
      this.props.history.push('/home');
    }
  }
  render() {
    return (
      <div id="home">
        <Row className="no-margin">
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="align-middle"
          >
            <div
              style={{
                paddingBottom: '20px',
                marginTop: '30px',
                maxWidth: '1000px',
              }}
            >
              <center>
                <video
                  width="100%"
                  max-width="500px"
                  controls
                  controlsList="nodownload"
                  style={{ border: '1px solid #131313' }}
                >
                  <source
                    src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/video%2FTrevelGrant.mp4?alt=media&token=672a063d-31d3-47eb-b5fb-2ebfeef6dda7"
                    type="video/mp4"
                  />
                </video>
              </center>
            </div>
            <div style={{ paddingBottom: '20px', marginTop: '30px' }}>
              <center>
                <h2 style={{ color: '#f21d59' }}>
                  Thank you for your time!
                </h2>
                <h4>
                  If you any questions please contact us at (913)
                  384-7377.
                </h4>
              </center>
            </div>
          </Col>
        </Row>
        <style
          dangerouslySetInnerHTML={{
            __html: '.footer-container{display: none}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearUauth: () => {
      dispatch(clearUauth());
    },
    getArticles: article => {
      dispatch(getArticles(article));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArchGrant);
