import { template } from 'lodash';
import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import SearchInput, { createFilter } from 'react-search-input';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
import firebase from '../../config/firebase';
import { getAllRegions } from '../../sagas/regionsSaga/reducer';
// Components
import TitleBar from '../../components/TitleBar';

const KEYS_TO_FILTERS = ['title'];

export class AdminArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    }
    if (
      this.props.user.authenticated === true &&
      this.props.profile.userType !== 1
    ) {
      this.props.history.push('/');
    }
  }

  componentDidMount() {
    this.props.getAllRegions();
    // if (
    //   this.props.profile.userType !== undefined &&
    //   this.props.profile.userType === 1
    // ) {
    //   this.props.getGuideTemplates();
    //   if (this.props.alllocations === undefined) {
    //     // this.props.getAllLocations();
    //   }
    // }

    if (
      this.props.profile.userType !== undefined &&
      this.props.profile.userType === 1
    ) {
      // this.props.getTags();
      this.setState({
        loading: false,
      });
    } else {
      this.props.history.push('/login');
    }
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  updateTemplates() {
    if (
      this.props.profile.userType !== undefined &&
      this.props.profile.userType === 1
    ) {
      this.props.getAllRegions();
    }
  }

  getDate(timestamp) {
    let myDate = timestamp * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();
    return dayText;
  }

  getEdit(id) {
    const url = '/admin/region/edit/' + id;
    return (
      <div>
        <Link
          to={{
            pathname: url,
            state: {},
          }}
          className="right"
        >
          <MaterialIcon icon="edit" size={25} color="#f21d59" />
        </Link>
        {/* <span className="right">
          <MaterialIcon
            icon="delete"
            size={25}
            onClick={() => {
              this.confirmDelete(id);
            }}
          />
        </span> */}
      </div>
    );
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/home',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Manage Regions</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}></Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
    }
    const filteredTemplates = this.props.regions.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="admin">
        {this.getTitleBar()}
        <div className="section">
          <div className="header-container">
            <div className="right">
              {/* <a href="/admin/templates/new" className="redbutton">
                Add New Template
              </a> */}
            </div>
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
            <div>
              <a
                onClick={() => {
                  this.updateTemplates();
                }}
              >
                Refresh Regions
              </a>
            </div>
          </div>
        </div>
        <div>
          <Row className=" no-margin no-padding">
            <div className="fullwidth">
              <ul className="no-padding">
                <li className="adminlistitem adminlistheader ">
                  <div>
                    <Col xs={7} md={7} sm={7} lg={7} className="left">
                      <span className="label">Region</span>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3} className="left">
                      <span className="label">Zone</span>
                    </Col>
                    <Col
                      xs={2}
                      md={2}
                      sm={2}
                      lg={2}
                      className="left"
                    />
                  </div>
                </li>
                {filteredTemplates.map((template, index) => (
                  <li className="adminlistitem">
                    <div className="fullwidth">
                      <Col
                        xs={7}
                        md={7}
                        sm={7}
                        lg={7}
                        key={'key' + index}
                      >
                        <span>{template.name}</span>
                      </Col>
                      <Col
                        xs={3}
                        md={3}
                        sm={3}
                        lg={3}
                        className="left"
                      >
                        <span
                          className="label"
                          style={{ color: 'black' }}
                        >
                          <span>{template.zoneId}</span>
                          {/* {this.getDate(
                            template.editHistory.lastUpdated.seconds,
                          )}
                          {' - '}
                          {template.editHistory.lastUpdater} */}
                        </span>
                      </Col>
                      <Col xs={2} md={2} sm={2} lg={2}>
                        {this.getEdit(template.id)}
                      </Col>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Row>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllRegions: () => {
      dispatch(getAllRegions());
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: state.profile.profile,
    regions: state.regions.regions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminArticles);
