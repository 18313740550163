import { call, put, takeLatest } from "redux-saga/effects";
import { getEvents } from "../../services/EventServices";

import * as actions from "./actions";

let defaultState = {
  events: [],
};

function* getEventsAction(zoneId) {
  if (zoneId !== undefined) {
    let events = yield call(getEvents, zoneId.zoneId);
    yield put({
      type: actions.SET_PROMOEVENTS,
      payload: {
        ...defaultState,
        events,
      },
    });
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_PROMOEVENTS, getEventsAction);
}
