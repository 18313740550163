import * as action from './actions';

export const setUser = (user) => {
  return {
    type: action.SET_USER,
    payload: user,
  };
};

export const setNewUser = (user) => {
  return {
    type: action.SET_NEWUSER,
    payload: user,
  };
};

export const setVendorLogin = (user) => {
  return {
    type: action.SET_VENDORLOGIN,
    payload: user,
  };
};

export const setNewVendor = (user) => {
  return {
    type: action.SET_NEWVENDOR,
    payload: user,
  };
};

export const clearUser = (user) => {
  const clearedUser = {
    authenticated: false,
    email: '',
    id: '',
    newuser: '',
    userName: '',
  };
  return {
    type: action.CLEAR_USER,
    payload: clearedUser,
  };
};

const ACTION_HANDLERS = {
  [action.SET_USER]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.CLEAR_USER]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SET_NEWUSER]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SET_VENDORLOGIN]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SET_NEWVENDOR]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = { authenticated: false };

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
