import React from 'react';
import { connect } from 'react-redux';
import firebase from '../config/firebase';
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MaterialIcon from 'material-icons-react';
// Components

// Reducers
import { getAp } from '../sagas/setActivePackageSaga/reducer';
import { getNewEvent } from '../sagas/createEventSaga/reducer';
import { getCe } from '../sagas/customEventSaga/reducer';
import { getTaRequest } from '../sagas/taRequestSaga/reducer';
import { clearTaRequest } from '../sagas/taRequestSaga/reducer';
import { is } from 'redux-saga/utils';

const query = require('array-query');
const _ = require('lodash');

export class TimedEvent extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      claim: false,
      entertainment: [],
      events: [],
    };
    this.setUpStuff = this.setUpStuff.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setUpStuff();
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.timedEvents.events === undefined) {
      this.setUpStuff();
    } else {
      let packageDetails = [];
      packageDetails = query('eventId')
        .is(this.props.match.params.id)
        .on(this.props.timedEvents.events);
      if (
        packageDetails[0] === undefined &&
        this.props.user.vendorAccount !== null
      ) {
        packageDetails = query('eventId')
          .is(this.props.match.params.id)
          .on(this.props.vendorTimedEvents);
      }
      console.log('packageDetails[0]', packageDetails[0]);
      let finalPackage = [];
      if (packageDetails[0] !== undefined) {
        finalPackage = packageDetails[0];
      }

      let headliners = [];
      let additional = [];

      if (packageDetails[0] !== undefined) {
        packageDetails[0].entertainment.headliners.forEach(
          (entertainer, i) => {
            const entertainmentDetails = query('entertainmentId')
              .is(entertainer.entertainmentId)
              .on(this.props.entertainment);
            headliners.push(entertainmentDetails[0]);
          },
        );
        packageDetails[0].entertainment.additional.forEach(
          (entertainer, i) => {
            const entertainmentDetails = query('entertainmentId')
              .is(entertainer.entertainmentId)
              .on(this.props.entertainment);
            additional.push(entertainmentDetails[0]);
          },
        );
      }

      this.setState({
        packageDetails: finalPackage,
        loading: false,
        headliners,
        additional,
      });
    }
  }

  setUpStuff = () => {
    // Get Packages if they are not there already

    // Get this specific locations Details and Promos if the user is NOT signed in

    let entertainment = [];
    let timedEvent = [];
    let entertainments = [];
    let entertainmentArray = [];
    var that = this;
    if (this.props.user.authenticated === false) {
      const db = firebase.firestore();
      db.collection('vendor_events')
        .where('eventId', '==', this.props.match.params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            timedEvent.push(doc.data());

            db.collection('entertainment')
              .where(
                'entertainmentId',
                '==',
                timedEvent[0].entertainmentId,
              )
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                  entertainment.push(doc.data());
                });

                that.setState({
                  packageDetails: timedEvent[0],
                  entertainmentDetails: entertainment[0],
                  loading: false,
                });
              });
          });
        });
    } else {
      //   const entertainmentDetails = query('entertainmentId')
      //     .is(this.props.match.params.id)
      //     .on(this.props.entertainment);
      //   let entertainment = entertainmentDetails[0];
      //   let eventsArray = [];
      //   this.props.timedEvents.forEach((event) => {
      //     if (
      //       event.entertainmentId ===
      //       entertainmentDetails[0].entertainmentId
      //     ) {
      //       eventsArray.push(event);
      //     }
      //   });
      //   this.setState({
      //     events: eventsArray,
      //     entertainment: entertainment,
      //     path: '/home',
      //     loading: false,
      //   });
      // }
    }
  };

  getTitleBar() {
    let back = '/';
    if (
      this.props.location !== undefined &&
      this.props.location.state !== undefined &&
      this.props.location.state.back !== undefined
    ) {
      back = this.props.location.state.back.back;
    }

    // let back = this.props.history.location.state.back.back;
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: back,
                  state: {
                    back: {
                      back: window.location.pathname,
                    },
                  },
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Experience Details
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {this.props.profile.userType === 1 && (
                <Link
                  to={{
                    pathname:
                      '/vendor/timedevent/edit/' +
                      this.props.match.params.id,
                  }}
                  className="right"
                >
                  <MaterialIcon icon="edit" size={25} color="white" />
                </Link>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  addEntertainment = (customEvent, event, timedEvents) => {
    let location = {};
    let customImages = false;

    if (event.address.tdId !== null) {
      const locationDetails = query('locationId')
        .is(event.address.tdId)
        .on(this.props.locations);
      location = locationDetails[0];
      location.locationThumb = event.eventThumb;
      location.locationHero = event.eventHero;
      if (location.settings.customImages !== undefined) {
        customImages = location.settings.customImages;
      }
    } else {
      customImages = true;
      location = {
        locationId: null,
        locationTitle: event.address.locationName,
        locationThumb: event.eventThumb,
        zoneId: event.zoneId,
        locationHero: event.eventHero,
        address: {
          street: event.address.street,
          state: event.address.state,
          city: event.address.city,
          zip: event.address.zip,
          latitude: event.address.latitude,
          longitude: event.address.longitude,
          region: event.address.region,
        },
      };
    }

    if (customEvent.event.locations !== null) {
      const newLocations = [];
      customEvent.event.locations.forEach((location) => {
        newLocations.push(location);
      });
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          type: 0,
          locationId: location.locationId,
          hours: location.hours,
          locationTitle: location.locationTitle,
          locationThumb: location.locationThumb,
          locationHero: location.locationHero,
          zoneId: location.zoneId,
          address: location.address,
          settings: {
            customImages,
          },
          locationType: 3,
          event: true,
          eventId: event.eventId,
        });
      }
      customEvent.event.locations = newLocations;
      customEvent.step = 1;

      let newTagFilters = this.props.taRequest.tagFilters;

      if (
        this.props.taRequest.eventCats.eat === true &&
        this.props.taRequest.eventCats.watch === true
      ) {
        this.setState({
          guideMode: 1,
        });
        newTagFilters[8].tags[1].checked = false;
        newTagFilters[8].tags[0].checked = false;
        newTagFilters[8].tags[2].checked = true;
      }

      let startingCat = 1;
      if (this.props.taRequest.eventCats.startingCat !== undefined) {
        startingCat = this.props.taRequest.eventCats.startingCat;

        if (
          this.props.taRequest.eventCats.startingCat !=
          this.props.taRequest.eventCats.endCat
        ) {
          if (this.props.taRequest.eventCats.startingCat === 1) {
            startingCat = 2;
          } else {
            startingCat = 1;
          }
        }
      }
      // Update TaRequest
      let bundle = {
        tagFilters: this.props.taRequest.tagFilters,
        distance: this.props.taRequest.distance,
        startLocation: this.props.taRequest.startLocation,
        eventCats: {
          eat: this.props.taRequest.eventCats.eat,
          watch: this.props.taRequest.eventCats.watch,
          do: this.props.taRequest.eventCats.do,
          startingCat,
          endCat: this.props.taRequest.eventCats.endCat,
        },
        currentLatitude: this.props.taRequest.currentLatitude,
        currentLongitude: this.props.taRequest.currentLongitude,
        closestRegion: this.props.taRequest.closestRegion,
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
        reroll: this.props.taRequest.reroll,
      };
      this.props.getTaRequest(bundle);
      if (customEvent.event.title === '') {
        customEvent.event.title = event.eventTitle;
      }

      customEvent.activeEventCats = {
        eat: this.props.taRequest.eventCats.eat,
        watch: false,
        do: this.props.taRequest.eventCats.do,
        startingCat: this.props.taRequest.eventCats.startingCat,
        endCat: this.props.taRequest.eventCats.endCat,
      };

      // this.props.clearTaRequest(this.props.taRequest);
      this.props.getCe(customEvent);

      if (
        this.props.taRequest.eventCats.startingCat ===
        this.props.taRequest.eventCats.endCat
      ) {
        this.props.clearTaRequest(this.props.taRequest);
        if (this.props.customEvent.editMode === true) {
          this.props.history.push(
            '/event/edit/' +
              this.props.customEvent.event.eventId +
              '/1',
          );
        } else {
          // this.props.history.push('/event/create/home/1');
          // If Event Incluced with Date Set That Date and Lock Calendar
          let eventX = null;
          if (
            customEvent.event.locations !== undefined &&
            customEvent.event.locations.length > 0
          ) {
            customEvent.event.locations.forEach((location, i) => {
              if (location.event === true) {
                const eventDetails = query('id')
                  .is(location.eventId)
                  .on(timedEvents.events);
                eventX = eventDetails[0];
              }
            });

            if (eventX !== null) {
              if (
                (eventX.scheduledTime.dates.repeat !== undefined &&
                  eventX.scheduledTime.dates.repeat === true) ||
                (eventX.scheduledTime.dates.multiDates !==
                  undefined &&
                  eventX.scheduledTime.dates.multiDates.length > 1)
              ) {
                if (customEvent.event.scheduledTime.date === null) {
                  this.props.history.push('/event/create/home/2');
                }
              } else {
                this.props.history.push('/event/create/home/1');
              }
            }
          }
        }
      }
    } else {
      const newLocations = [];
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          locationId: location.locationId,
          type: 0,
        });
      }
      customEvent.step = 1;
      customEvent.event.locations = newLocations;
      if (customEvent.event.title === '') {
        customEvent.event.title = event.eventTitle;
      }

      this.props.getCe(customEvent);
      this.props.clearTaRequest(this.props.taRequest);
      if (this.props.customEvent.editMode === true) {
        this.props.history.push(
          '/event/edit/' +
            this.props.customEvent.event.eventId +
            '/1',
        );
      } else {
        this.props.history.push('/event/create/home/1');
      }
    }
  };

  getFooter(event, mobile) {
    return (
      <Container className={mobile === false ? 'getGoing' : ''}>
        <Row className="no-margin">
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            <center>
              {this.props.user.authenticated === true && (
                <div>
                  {this.props.newEvent.clear === true ? (
                    <a
                      onClick={() => {
                        this.quickEvent(event);
                      }}
                    >
                      <span
                        className="center solidredbutton"
                        style={{ margin: '0px auto' }}
                      >
                        Add To Itinerary
                      </span>
                    </a>
                  ) : (
                    <a
                      onClick={() => {
                        this.addEntertainment(
                          this.props.customEvent,
                          event,
                          this.props.timedEvents,
                        );
                      }}
                    >
                      <span
                        className="center solidredbutton"
                        style={{ margin: '0px auto' }}
                      >
                        Add To Itinerary
                      </span>
                    </a>
                  )}
                </div>
              )}
              {this.props.user.authenticated === false && (
                <a
                  onClick={() => {
                    this.loggedOutQuickEvent(event);
                  }}
                >
                  <span className="center solidredbutton">
                    Add To Itinerary
                  </span>
                </a>
              )}
            </center>
          </Col>
        </Row>
      </Container>
    );
  }

  quickEvent(event) {
    const locationDetails = query('locationId')
      .is(event.address.locationId)
      .on(this.props.locations);
    let location = locationDetails[0];

    const newEvent = {
      connections: [],
      planFirst: false,
      connectFirst: false,
      clear: false,
      step: 2,
      screen: 1,
      fromVEvent: false,
      fromPackage: false,
      vEvent: {},
      fromEntertainment: true,
      entertainmentEvent: event,
      singleLocation: location,
    };
    this.props.getNewEvent(newEvent);
  }

  loggedOutQuickEvent(event) {
    const locationDetails = query('locationId')
      .is(event.address.tdId)
      .on(this.props.unauthlocations);
    let location = locationDetails[0];

    const newEvent = {
      connections: [],
      planFirst: false,
      connectFirst: false,
      clear: false,
      step: 10,
      screen: 1,
      fromVEvent: false,
      fromEntertainment: true,
      fromPackage: false,
      vEvent: {},
      singleLocation: location,
      entertainmentEvent: event,
    };
    this.props.getNewEvent(newEvent);
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true &&
        location.eventHero !== ''
      ) {
        path = location.eventHero;
      } else if (
        location !== undefined &&
        location.eventHero !== undefined &&
        location.eventHero !== ''
      ) {
        path =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
          location.eventHero +
          '?alt=media';
      } else if (
        location !== undefined &&
        location.eventHero === ''
      ) {
        if (location.tagsEventType[0] === 'Happy Hour') {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2Feat_hero.jpg?alt=media';
        } else {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgenericmusic.png?alt=media&token=86ae93a5-3d7b-4dea-ab01-b31f84bdaa39';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.eventThumb;
      } else {
        if (
          location !== undefined &&
          location.eventThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.eventThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  getWhen(event) {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 'tu' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];

    let days = [];

    if (event.scheduledTime.dates.repeatDays !== undefined) {
      event.scheduledTime.dates.repeatDays.forEach((day) => {
        let eventDetailsx = query('short').is(day).on(dayArray);
        let eventx = eventDetailsx[0];
        days.push(eventx.full);
      });
    }

    let dayText = '';
    days.forEach((day, index) => {
      if (days.length === 1) {
        dayText = day;
      } else if (days.length === 2) {
        if (index === 0) {
          dayText = day;
        } else {
          dayText = dayText + ' & ' + day;
        }
      } else if (
        days.length > 2 &&
        event.scheduledTime.dates.timesSelected > 1
      ) {
        if (index === 0) {
          dayText = day + 's';
        } else if (days.length - 1 === index) {
          dayText = dayText + ', & ' + day + 's';
        } else {
          dayText = dayText + ',  ' + day + 's';
        }
      } else if (
        days.length > 2 &&
        event.scheduledTime.dates.timesSelected === 1
      ) {
        if (index === 0) {
          dayText = day;
        } else if (days.length - 1 === index) {
          dayText = dayText + ', & ' + day;
        } else {
          dayText = dayText + ',  ' + day;
        }
      }
    });

    let weekly = '';
    if (event.scheduledTime.dates.timesSelected === 1) {
      weekly = 'week';
    } else {
      weekly = event.scheduledTime.dates.timesSelected + ' weeks ';
    }
    let statement = '';
    if (event.scheduledTime.dates.timesSelected === 1) {
      if (event.scheduledTime.dates.repeatType === 'Weekly') {
        statement = 'Every ' + dayText;
      }
    } else {
      if (event.scheduledTime.dates.repeatType === 'Weekly') {
        statement = 'Every ' + weekly + ' on ' + dayText;
      }
    }

    return statement;
  }

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    let t = to.substr(0, to.lastIndexOf('/'));
    return t;
  }

  getWebsite(location) {
    if (
      location.website !== undefined &&
      location.website !== null &&
      location.website !== ''
    ) {
      return (
        <div style={{ clear: 'both' }}>
          <a
            href={location.website}
            target="_blank"
            rel="noopener noreferrer"
            className="red"
          >
            <span className="right">Visit Website</span>
          </a>
        </div>
      );
    }
  }

  getMultiDates(event) {
    let dateArray = [];
    let count = 0;
    if (event.scheduledTime.dates.multiDates !== undefined) {
      event.scheduledTime.dates.multiDates.forEach((date, index) => {
        let todayDate = moment(date.date).format('MM/DD');
        let today = moment();
        let status = moment(date.date).isBefore(today);
        if (status === false) {
          if (count !== 4) {
            count++;
            dateArray.push(todayDate);
          }
        }
      });
    }
    if (dateArray.length !== 0) {
      return (
        <div style={{ margin: '10px 0px 10px 0px ' }}>
          <h5>Upcoming Dates</h5>
          {dateArray.map((date, index) => (
            <span style={{ fontWeight: '500' }}>
              {date}
              {index !== dateArray.length - 1 && ', '}
            </span>
          ))}
        </div>
      );
    }
  }

  getSlug(entertainer) {
    if (entertainer.slug !== undefined) {
      return entertainer.slug;
    } else {
      return entertainer.entertainmentId;
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                ' #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer-container{display:none}  .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    if (this.state.packageDetails.length === 0) {
      return (
        <div style={{ height: '100%' }}>
          {this.props.user.authenticated === true &&
            this.getTitleBar()}
          <Container style={{ height: '100%' }}>
            <Row className="no-margin" style={{ height: '100%' }}>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <div
                  style={{
                    padding: '100px 10px 30px 10px',
                    textAlign: 'center',
                  }}
                >
                  <h4
                    style={{ fontSize: '30px', marginBottom: '20px' }}
                  >
                    Sorry!
                  </h4>
                  <h4 style={{ fontSize: '20px' }}>
                    It looks like this event might be over.
                  </h4>
                  <div style={{ marginTop: '15px' }}>
                    <Link
                      to={{
                        pathname: '/',
                        state: {},
                      }}
                    >
                      <span>Return to Guide</span>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div
            id={
              this.props.user.authenticated === true
                ? 'startBar'
                : 'startBar'
            }
            style={{ padding: '0px' }}
            className="mobileHide"
          >
            {this.getFooter(this.state.packageDetails, false)}
          </div>
          <div
            id={
              this.props.user.authenticated === true
                ? 'startBar'
                : 'startBar'
            }
            className="mobileShow"
          >
            {this.getFooter(this.state.packageDetails, true)}
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '@media (max-width: 800px){.maincontent { top: 39px}}',
            }}
          />
        </div>
      );
    }
    if (this.state.packageDetails.length !== 0) {
      return (
        <div id="location" style={{ height: '100%' }}>
          {/* {this.props.user.authenticated === true &&
            this.getTitleBar()} */}
          {this.getTitleBar()}
          <Container style={{ height: '100%' }}>
            <Row className="no-margin" style={{ height: '100%' }}>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <div style={{ height: '100%' }}>
                  <Card style={{ height: '100%' }}>
                    <CardImg
                      top
                      width="100%"
                      src={this.getImage(
                        'hero',
                        this.state.packageDetails,
                      )}
                      alt="articleHero"
                    />
                    <CardBody>
                      {this.state.packageDetails.scheduledTime.dates
                        .repeat !== undefined &&
                        this.state.packageDetails.scheduledTime.dates
                          .repeat !== true && (
                          <h2 className="right eventDate">
                            {this.getDate(this.state.packageDetails)}
                          </h2>
                        )}
                      {this.state.packageDetails.scheduledTime.dates
                        .repeat === undefined && (
                        <h2 className="right eventDate">
                          {this.getDate(this.state.packageDetails)}
                        </h2>
                      )}

                      <h2 style={{ textTransform: 'capitalize' }}>
                        {this.state.packageDetails.eventTitle}
                      </h2>

                      {this.state.packageDetails.scheduledTime.dates
                        .repeat !== undefined &&
                        this.state.packageDetails.scheduledTime.dates
                          .repeat === true && (
                          <Link
                            to={{
                              pathname:
                                '/location/' +
                                this.state.packageDetails.address
                                  .tdId,
                              state: {
                                location: {},
                                // eventId: { eventId },
                                // token: { token },
                                type:
                                  this.props.user.authenticated ===
                                  false
                                    ? 600
                                    : 650,
                              },
                            }}
                          >
                            <span
                              style={{
                                display: 'block',
                                margin: '0px',
                                paddingTop: '5px',
                              }}
                            >
                              {
                                this.state.packageDetails.address
                                  .locationName
                              }
                            </span>
                          </Link>
                        )}

                      {this.state.packageDetails.entertainment
                        .entertainmentIds.length !== 0 && (
                        <div>
                          {' '}
                          <div
                            style={{
                              display: 'inline-block',
                              width: '100%',
                            }}
                          >
                            {this.state.headliners.map(
                              (entertainer, index) => (
                                <div
                                  style={{
                                    float: 'left',
                                  }}
                                >
                                  <Link
                                    to={{
                                      pathname:
                                        '/entertainment/' +
                                        entertainer.slug,
                                    }}
                                  >
                                    <span
                                      className="red"
                                      style={{
                                        fontSize: '25px',
                                        paddingTop: '0px',
                                        float: 'left',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      {entertainer.entertainmentTitle}
                                    </span>
                                  </Link>
                                  {index + 1 !==
                                    this.state.headliners.length && (
                                    <span
                                      style={{
                                        fontSize: '25px',
                                      }}
                                    >
                                      &nbsp;&&nbsp;
                                    </span>
                                  )}
                                </div>
                              ),
                            )}
                          </div>
                          <div style={{ display: 'inline-block' }}>
                            {this.state.additional.length !== 0 && (
                              <span
                                style={{
                                  float: 'left',
                                  fontSize: '18px',
                                }}
                              >
                                With&nbsp;
                              </span>
                            )}
                            {this.state.additional.map(
                              (entertainer, index) => (
                                <div style={{ float: 'left' }}>
                                  <Link
                                    to={{
                                      pathname:
                                        '/entertainment/' +
                                        this.getSlug(entertainer),
                                    }}
                                  >
                                    <span
                                      className="red"
                                      style={{
                                        fontSize: '18px',
                                        paddingTop: '0px',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      {entertainer.entertainmentTitle}
                                      &nbsp;
                                    </span>
                                  </Link>
                                  {index + 1 !==
                                    this.state.additional.length && (
                                    <span>&nbsp;and&nbsp;</span>
                                  )}
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      )}

                      <div style={{ marginTop: '15px' }}>
                        <CardText>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.packageDetails
                                  .eventDescription,
                            }}
                          />
                          {this.state.packageDetails.scheduledTime
                            .dates.repeatType === 'Weekly' && (
                            <div style={{ paddingBottom: '10px' }}>
                              <span
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                When:{'  '}
                              </span>
                              {this.getWhen(
                                this.state.packageDetails,
                              )}
                            </div>
                          )}
                          {this.state.packageDetails.scheduledTime
                            .dates.repeatType !== 'Weekly' && (
                            <div style={{ paddingBottom: '10px' }}>
                              <span
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                When:{'  '}
                              </span>
                              {
                                this.state.packageDetails
                                  .scheduledTime.times.startTime
                              }{' '}
                              -{' '}
                              {
                                this.state.packageDetails
                                  .scheduledTime.times.endTime
                              }
                            </div>
                          )}

                          <div>
                            {this.getMultiDates(
                              this.state.packageDetails,
                            )}
                          </div>

                          <div className="card-section">
                            <Row className="no-margin">
                              <Col
                                xs={6}
                                md={6}
                                sm={6}
                                lg={6}
                                className="no-padding"
                              >
                                <Link
                                  to={{
                                    pathname:
                                      '/location/' +
                                      this.state.packageDetails
                                        .address.tdId,
                                    state: {
                                      location: {},
                                      back: {
                                        back: window.location
                                          .pathname,
                                      },
                                      // eventId: { eventId },
                                      // token: { token },
                                      type:
                                        this.props.user
                                          .authenticated === false
                                          ? 600
                                          : 650,
                                    },
                                  }}
                                >
                                  <span className="title bold red">
                                    {
                                      this.state.packageDetails
                                        .address.locationName
                                    }
                                  </span>
                                </Link>
                                <span className="address bold">
                                  {
                                    this.state.packageDetails.address
                                      .street
                                  }
                                </span>
                                <span className="address">
                                  {
                                    this.state.packageDetails.address
                                      .city
                                  }{' '}
                                  {
                                    this.state.packageDetails.address
                                      .state
                                  }
                                  ,{' '}
                                  {
                                    this.state.packageDetails.address
                                      .zip
                                  }
                                </span>
                              </Col>
                              <Col
                                xs={6}
                                md={6}
                                sm={6}
                                lg={6}
                                className="no-padding"
                              >
                                {this.getWebsite(
                                  this.state.packageDetails,
                                )}
                              </Col>
                            </Row>
                          </div>
                        </CardText>
                      </div>

                      {/* <div className="card-section">
                      <p>
                        This package was created by{' '}
                        {
                          this.state.packageDetails.creatorInfo
                            .creatorName
                        }
                      </p>
                    </div> */}
                      <div
                        className="card-section"
                        id="package-itinerary"
                        style={{ paddingBottom: '80px' }}
                      >
                        {this.state.packageDetails.ticketInfo !==
                          undefined &&
                          this.state.packageDetails.ticketInfo
                            .ticketsRequired &&
                          this.state.packageDetails.ticketInfo
                            .ticketUrl !== '' && (
                            <div
                              style={{
                                marginBottom: '10px',
                              }}
                            >
                              <a
                                href={
                                  this.state.packageDetails.ticketInfo
                                    .ticketUrl
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="red"
                              >
                                <span
                                  className="right"
                                  style={{
                                    backgroundColor: '#f21d59',
                                    padding: '5px 10px 5px 10px',
                                    marginTop: '0px',
                                    borderRadius: '4px',
                                    color: 'white',
                                  }}
                                >
                                  Buy Tickets
                                </span>
                              </a>
                              <span
                                style={{
                                  fontWeight: '600',
                                  fontSize: '18px',
                                  display: 'block',

                                  marginBottom: '5px',
                                }}
                              >
                                Don{"'"}t Forget Your Tickets
                              </span>
                              {this.state.packageDetails
                                .ticketInfo !== undefined &&
                                this.state.packageDetails.ticketInfo
                                  .ticketUrl === true && (
                                  <a
                                    href={
                                      this.state.packageDetails
                                        .ticketInfo.ticketUrl
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="red"
                                  >
                                    <span className="right ">
                                      Buy Tickets
                                    </span>
                                  </a>
                                )}

                              <span style={{ fontWeight: '500' }}>
                                They{' '}
                                <span
                                  style={{
                                    textDecoration: 'underline',
                                    fontWeight: '500',
                                  }}
                                >
                                  Are
                                </span>{' '}
                                Required
                              </span>
                            </div>
                          )}
                        {this.state.packageDetails.ticketInfo !==
                          undefined &&
                          this.state.packageDetails.ticketInfo
                            .ticketsRequired === false &&
                          this.state.packageDetails
                            .tagsEventType[0] !== 'Happy Hour' && (
                            <div style={{ marginBottom: '10px' }}>
                              <span style={{ fontWeight: '600' }}>
                                Tickets are Not Required
                              </span>
                            </div>
                          )}
                        {this.state.packageDetails.ticketInfo !==
                          undefined &&
                          this.state.packageDetails.ticketInfo
                            .atDoor &&
                          this.state.packageDetails.ticketInfo
                            .mustPurchase !== true && (
                            <div>
                              <span>
                                You can buy them at the door.
                              </span>
                            </div>
                          )}
                        {this.state.packageDetails.ticketInfo !==
                          undefined &&
                          this.state.packageDetails.ticketInfo
                            .mustPurchase === true && (
                            <div>
                              <span>
                                You must buy them ahead of time, and
                                they could sell out.
                              </span>
                            </div>
                          )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
          <div
            id={
              this.props.user.authenticated === true
                ? 'startBar'
                : 'startBar'
            }
            style={{ padding: '0px' }}
            className="mobileHide"
          >
            {this.getFooter(this.state.packageDetails, false)}
          </div>
          <div
            id={
              this.props.user.authenticated === true
                ? 'startBar'
                : 'startBar'
            }
            className="mobileShow"
          >
            {this.getFooter(this.state.packageDetails, true)}
          </div>

          <style
            dangerouslySetInnerHTML={{
              __html:
                'html{display:grid} #mobile-nav { display: none }  .maincontent { top: 39px !important; margin-top: 0px; height: 100%} .App{background-color: #fafafa;}',
            }}
          />

          {/* {this.props.user.authenticated === false && (
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '@media (max-width: 800px){.maincontent { top: 80px !important;}}  ',
              }}
            />
          )} */}
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.toolbar { display: none } @media (max-width: 800px){.maincontent { top: 39px}} html{display:grid} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;}.footer-container{display:none} .App{background-color: #fafafa;}  #authleft {display:none;} #bottomNav { display: none } .maincontent { top: 0px; height: 100%}.footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;} p{max-width: 100% !important}',
            }}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    user: state.user,
    timedEvents: state.timedEvents,
    entertainment: state.entertainment.entertainment,
    locations: state.locations.locations,
    unauthlocations: state.locations.locations,
    vendorTimedEvents: state.vendorTimedEvents.events,
    newEvent: state.newEvent,
    customEvent: state.customEvent.customEvent,
    profile: state.profile.profile,
    taRequest: state.taRequest,
    regions: state.regions.regions,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAp: (ap) => {
      dispatch(getAp(ap));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
    getTaRequest: (request) => {
      dispatch(getTaRequest(request));
    },
    clearTaRequest: (user) => {
      dispatch(clearTaRequest(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimedEvent);
