import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as auth from '../../config/auth';
import UserAvatar from 'react-user-avatar';
import { Link } from 'react-router-dom';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';
import { clearProfile } from '../../sagas/getProfileSaga/reducer';
import AdminMenu from '../AdminMenu';
import UserMenu from '../UserMenu';
// Styles
import './SideDrawer.css';

class AuthSideMenu extends Component {
  constructor() {
    super();
    this.logOut = this.logOut.bind(this);
  }

  UNSAFE_componentWillMount() {}

  logOut() {
    const user = {
      id: null,
      userName: null,
      email: null,
      authenticated: false,
    };
    auth.doSignOut();
    this.props.clearProfile();
    // this.props.clearUser();
  }
  // test

  adminLinks(mode, type) {
    if (mode === 0) {
      return (
        <UserMenu
          logOut={this.logOut}
          history={this.props.history}
          click={this.props.click}
        />
      );
    } else if (mode === 1) {
      if (type === 1) {
        return (
          <div>
            <AdminMenu
              click={this.props.click}
              history={this.props.history}
            />
          </div>
        );
      }
    } else if (mode === 2) {
      return (
        <div>
          <UserMenu
            logOut={this.logOut}
            history={this.props.history}
            click={this.props.click}
          />
        </div>
      );
    }
  }

  setMode(mode) {
    this.props.getMode(mode);
    if (mode === 1) {
      this.props.history.push('/admin/home');
    } else if (mode === 0) {
      this.props.history.push('/home');
    } else if (mode === 2) {
      this.props.history.push('/vendor/home');
    }
  }

  getModeLinks(mode, type) {
    if (type === 1) {
      if (mode === 0) {
        return (
          <div>
            <a
              href="http://trevel.com/vendor/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="modelink">Trevel Experiencess</span>
            </a>
            <span
              onClick={() => {
                this.setMode(1);
              }}
              className="modelink"
            >
              Admin
            </span>
          </div>
        );
      } else if (mode === 2) {
        return (
          <div>
            <a
              href="http://trevel.com/vendor/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                // onClick={() => {
                //   this.setMode(2);
                // }}
                className="modelink"
              >
                Trevel Experiences
              </span>
            </a>
            <span
              onClick={() => {
                this.setMode(1);
              }}
              className="modelink"
            >
              Admin
            </span>
          </div>
        );
      } else if (mode === 1) {
        return (
          <div>
            <span
              onClick={() => {
                this.setMode(0);
              }}
              className="modelink"
            >
              Exit
            </span>
          </div>
        );
      }
    } else if (type === 3) {
      if (mode === 0) {
        return (
          <div>
            <a
              href="http://trevel.com/vendor/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                // onClick={() => {
                //   this.setMode(2);
                // }}
                className="modelink"
              >
                Trevel Experiences
              </span>
            </a>
          </div>
        );
      } else if (mode === 2) {
        return (
          <div>
            <a
              href="http://trevel.com/vendor/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                // onClick={() => {
                //   this.setMode(2);
                // }}
                className="modelink"
              >
                Trevel Experiences
              </span>
            </a>
          </div>
        );
      } else if (mode === 1) {
        return (
          <div>
            <span
              onClick={() => {
                this.setMode(0);
              }}
              className="modelink"
            >
              Exit
            </span>
          </div>
        );
      }
    }
  }

  render() {
    return (
      <div id="authsidemenu">
        <div className="section">
          <div className="authtoolbar__logo">
            <Link
              to={{
                pathname: '/',
              }}
            >
              {/* <img src={beta} /> */}
              <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelUnderlined.svg?alt=media&token=507aa809-2e2c-4529-8b5d-9877e8ed8b38" />
            </Link>
          </div>
          <div id="profile-block">
            <Link
              to={{
                pathname: '/profile',
              }}
            >
              <UserAvatar
                size="60"
                name={
                  this.props.profile.firstName +
                  this.props.profile.lastName
                }
                src={this.props.profile.profilePhotoPath}
              />
            </Link>
          </div>
          {this.getModeLinks(
            this.props.mode,
            this.props.profile.userType,
          )}
          {this.adminLinks(
            this.props.mode,
            this.props.profile.userType,
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearProfile: (user) => {
      dispatch(clearProfile(user));
    },
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: state.profile.profile,
    state: state,
    mode: state.mode.mode,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthSideMenu);
