import React from 'react';
import { connect } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import firebase from '../../config/firebase';
import { getGuideTemplates } from '../../sagas/guideTemplatesSaga/reducer';
import {
  UncontrolledCollapse,
  Col,
  Row,
  Container,
} from 'reactstrap';
// Components
import TitleBar from '../../components/TitleBar';

const query = require('array-query');

export class AdminTemplateEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      title: '',
      numbers: ['One', 'Two', 'Three', 'Four', 'Five', 'Six'],
      loading: true,
      zoneFilter: null,
    };
  }

  componentDidMount() {
    this.setCurrentValues();
  }

  onChangeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleOnChange = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  getCategoryFields(tags, catId) {
    const options = [];
    const locationTypeOptions = [];
    const locationTypesSettingsOptions = [
      {
        value: 1,
        label: 'Include',
      },
      {
        value: 0,
        label: 'Exclude',
      },
    ];
    tags.forEach((tagCat) => {
      if (
        tagCat.sid !== 'entertainmentType' &&
        tagCat.sid !== 'eventType' &&
        tagCat.sid !== 'genre' &&
        tagCat.sid !== 'locationType'
      ) {
        tagCat.tags.forEach((tag) => {
          options.push({
            value: tag.tag + ',' + tagCat.sid,
            label: tag.tag,
          });
        });
      }
      if (tagCat.sid === 'locationType') {
        tagCat.tags.forEach((tag) => {
          locationTypeOptions.push({
            value: tag.tag + ',' + tagCat.sid,
            label: tag.tag,
          });
        });
      }
    });

    return (
      <div
        style={{
          borderTop: '1px solid silver',
          padding: '10px 0px 10px 0px',
        }}
      >
        <span
          style={{
            fontWeight: '500',
            marginBottom: '5px',
            display: 'block',
            position: 'relative',
            fontSize: '12px',
            color: '#a3a3a3',
          }}
        >
          LOCATION SETTINGS
        </span>
        <Row>
          <Col
            lg={2}
            md={2}
            sm={3}
            xs={4}
            style={{ padding: '0px 5px 0px 0px' }}
          >
            <span
              style={{
                fontWeight: '500',
                marginBottom: '5px',
                display: 'block',
                position: 'relative',
              }}
            >
              Types
            </span>
            <Select
              value={this.state[catId + 'LocationTypeSetting']}
              onChange={(value) => {
                this.handleOnChange(
                  catId + 'LocationTypeSetting',
                  value,
                );
              }}
              options={locationTypesSettingsOptions}
              isMulti={false}
            />
          </Col>
          <Col lg={10} md={10} sm={9} xs={8} className="no-padding">
            <span
              style={{
                fontWeight: '500',
                marginBottom: '5px',
                display: 'block',
                position: 'relative',
                color: 'white',
              }}
            >
              .
            </span>

            <Select
              value={this.state[catId + 'LocationTypeTags']}
              onChange={(value) => {
                this.handleOnChange(
                  catId + 'LocationTypeTags',
                  value,
                );
              }}
              options={locationTypeOptions}
              isMulti={true}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
            <span>
              <span style={{ float: 'left', fontWeight: '500' }}>
                Tags Based On User Favorites:
              </span>
              <span style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state[catId + 'LocationsCreatorFavs']}
                  onChange={(value) => {
                    this.onChangeDate(
                      catId,
                      'LocationsCreatorFavs',
                      value,
                    );
                  }}
                  className="closedCheckBox"
                  style={{ top: '6px' }}
                />
                <span>Creator</span>
              </span>
              <span style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state[catId + 'LocationsGuestFavs']}
                  onChange={(value) => {
                    this.onChangeDate(
                      catId,
                      'LocationsGuestFavs',
                      value,
                    );
                  }}
                  className="closedCheckBox"
                  style={{ top: '6px' }}
                />
                <span>Guests</span>
              </span>
            </span>
          </Col>
        </Row>
        {this.state[catId + 'LocationsGuestFavs'] === true ||
        this.state[catId + 'LocationsCreatorFavs'] === true ? (
          <div></div>
        ) : (
          <Row style={{ marginTop: '10px' }}>
            <Col
              lg={11}
              md={11}
              sm={10}
              xs={10}
              className="no-padding"
            >
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                }}
              >
                Tags
              </span>
              <Select
                value={this.state[catId + 'Tags']}
                onChange={(value) => {
                  this.handleOnChange(catId + 'Tags', value);
                }}
                options={options}
                isMulti={true}
              />
            </Col>
            <Col lg={1} md={1} sm={2} xs={2}>
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                  color: 'white',
                }}
              >
                .
              </span>
              <span className="inputLabel" style={{ float: 'left' }}>
                Strict
              </span>
              <input
                type="checkbox"
                checked={this.state[catId + 'LocationStrict']}
                onChange={(value) => {
                  this.onChangeDate(catId, 'LocationStrict', value);
                }}
                className="closedCheckBox"
                style={{ float: 'none' }}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }

  getEventsCategoryFields(tags, catId) {
    const options = [];
    const genreOptions = [];
    const eventTagSettingsOptions = [
      {
        value: 1,
        label: 'Include',
      },
      {
        value: 0,
        label: 'Exclude',
      },
    ];
    tags.forEach((tagCat) => {
      if (tagCat.sid === 'eventType') {
        tagCat.tags.forEach((tag) => {
          options.push({
            value: tag.tag + ',' + tagCat.sid,
            label: tag.tag,
          });
        });
      } else if (tagCat.sid === 'genre') {
        tagCat.tags.forEach((tag) => {
          genreOptions.push({
            value: tag.tag + ',' + tagCat.sid,
            label: tag.tag,
          });
        });
      }
    });
    console.log('optionss', options);
    return (
      <div style={{ marginBottom: '5px' }}>
        <span
          style={{
            fontWeight: '500',
            marginBottom: '5px',
            display: 'block',
            position: 'relative',
            fontSize: '12px',
            color: '#a3a3a3',
          }}
        >
          EVENT SETTINGS
        </span>
        <Row>
          <Col
            lg={2}
            md={2}
            sm={3}
            xs={4}
            style={{ padding: '0px 5px 0px 0px' }}
          >
            <span
              style={{
                fontWeight: '500',
                marginBottom: '5px',
                display: 'block',
                position: 'relative',
              }}
            >
              Types
            </span>
            <Select
              value={this.state[catId + 'EventTypeSetting']}
              onChange={(value) => {
                this.handleOnChange(
                  catId + 'EventTypeSetting',
                  value,
                );
              }}
              options={eventTagSettingsOptions}
              isMulti={false}
            />
          </Col>
          <Col lg={10} md={10} sm={9} xs={8} className="no-padding">
            <span
              style={{
                fontWeight: '500',
                marginBottom: '5px',
                display: 'block',
                position: 'relative',
                color: 'white',
              }}
            >
              .
            </span>

            <Select
              value={this.state[catId + 'EventTypeTags']}
              onChange={(value) => {
                this.handleOnChange(catId + 'EventTypeTags', value);
              }}
              options={options}
              isMulti={true}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
            <span>
              <span style={{ float: 'left', fontWeight: '500' }}>
                Tags Based On User Favorites:
              </span>
              <span style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state[catId + 'EventsCreatorFavs']}
                  onChange={(value) => {
                    this.onChangeDate(
                      catId,
                      'EventsCreatorFavs',
                      value,
                    );
                  }}
                  className="closedCheckBox"
                  style={{ top: '6px' }}
                />
                <span>Creator</span>
              </span>
              <span style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state[catId + 'EventsGuestFavs']}
                  onChange={(value) => {
                    this.onChangeDate(
                      catId,
                      'EventsGuestFavs',
                      value,
                    );
                  }}
                  className="closedCheckBox"
                  style={{ top: '6px' }}
                />
                <span>Guests</span>
              </span>
            </span>
          </Col>
        </Row>
        {this.state[catId + 'EventsGuestFavs'] === true ||
        this.state[catId + 'EventsCreatorFavs'] === true ? (
          <div></div>
        ) : (
          <Row style={{ marginTop: '10px' }}>
            <Col
              lg={11}
              md={11}
              sm={10}
              xs={10}
              className="no-padding"
            >
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                }}
              >
                Tags
              </span>
              <Select
                value={this.state[catId + 'EventSettingsTags']}
                onChange={(value) => {
                  this.handleOnChange(
                    catId + 'EventSettingsTags',
                    value,
                  );
                }}
                options={genreOptions}
                isMulti={true}
              />
            </Col>
            <Col lg={1} md={1} sm={2} xs={2}>
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                  color: 'white',
                }}
              >
                .
              </span>
              <span className="inputLabel" style={{ float: 'left' }}>
                Strict
              </span>
              <input
                type="checkbox"
                checked={this.state[catId + 'EventStrict']}
                onChange={(value) => {
                  this.onChangeDate(catId, 'EventStrict', value);
                }}
                className="closedCheckBox"
                style={{ float: 'none' }}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }

  getEntertainersCategoryFields(tags, catId) {
    const options = [];
    const genreOptions = [];
    const eventTagSettingsOptions = [
      {
        value: 1,
        label: 'Include',
      },
      {
        value: 0,
        label: 'Exclude',
      },
    ];
    tags.forEach((tagCat) => {
      if (tagCat.sid === 'entertainmentType') {
        tagCat.tags.forEach((tag) => {
          options.push({
            value: tag.tag + ',' + tagCat.sid,
            label: tag.tag,
          });
        });
      } else if (tagCat.sid === 'genre') {
        tagCat.tags.forEach((tag) => {
          genreOptions.push({
            value: tag.tag + ',' + tagCat.sid,
            label: tag.tag,
          });
        });
      }
    });

    return (
      <div
        style={{
          borderTop: '1px solid silver',
          padding: '10px 0px 10px 0px',
        }}
      >
        <span
          style={{
            fontWeight: '500',
            marginBottom: '5px',
            display: 'block',
            position: 'relative',
            fontSize: '12px',
            color: '#a3a3a3',
          }}
        >
          ENTERTAINERS SETTINGS
        </span>
        <Row>
          <Col
            lg={2}
            md={2}
            sm={3}
            xs={4}
            style={{ padding: '0px 5px 0px 0px' }}
          >
            <span
              style={{
                fontWeight: '500',
                marginBottom: '5px',
                display: 'block',
                position: 'relative',
              }}
            >
              Types
            </span>
            <Select
              value={this.state[catId + 'EntertainerTypeSetting']}
              onChange={(value) => {
                this.handleOnChange(
                  catId + 'EntertainerTypeSetting',
                  value,
                );
              }}
              options={eventTagSettingsOptions}
              isMulti={false}
            />
          </Col>
          <Col lg={10} md={10} sm={9} xs={8} className="no-padding">
            <span
              style={{
                fontWeight: '500',
                marginBottom: '5px',
                display: 'block',
                position: 'relative',
                color: 'white',
              }}
            >
              .
            </span>

            <Select
              value={this.state[catId + 'EntertainerTypeTags']}
              onChange={(value) => {
                this.handleOnChange(
                  catId + 'EntertainerTypeTags',
                  value,
                );
              }}
              options={options}
              isMulti={true}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
            <span>
              <span style={{ float: 'left', fontWeight: '500' }}>
                Tags Based On User Favorites:
              </span>
              <span style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={
                    this.state[catId + 'EntertainersCreatorFavs']
                  }
                  onChange={(value) => {
                    this.onChangeDate(
                      catId,
                      'EntertainersCreatorFavs',
                      value,
                    );
                  }}
                  className="closedCheckBox"
                  style={{ top: '6px' }}
                />
                <span>Creator</span>
              </span>
              <span style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={
                    this.state[catId + 'EntertainersGuestFavs']
                  }
                  onChange={(value) => {
                    this.onChangeDate(
                      catId,
                      'EntertainersGuestFavs',
                      value,
                    );
                  }}
                  className="closedCheckBox"
                  style={{ top: '6px' }}
                />
                <span>Guests</span>
              </span>
            </span>
          </Col>
        </Row>
        {this.state[catId + 'EntertainersGuestFavs'] === true ||
        this.state[catId + 'EntertainersCreatorFavs'] === true ? (
          <div></div>
        ) : (
          <Row style={{ marginTop: '10px' }}>
            <Col
              lg={11}
              md={11}
              sm={10}
              xs={10}
              className="no-padding"
            >
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                }}
              >
                Tags
              </span>
              <Select
                value={this.state[catId + 'EntertainerGenre']}
                onChange={(value) => {
                  this.handleOnChange(
                    catId + 'EntertainerGenre',
                    value,
                  );
                }}
                options={genreOptions}
                isMulti={true}
              />
            </Col>
            <Col lg={1} md={1} sm={2} xs={2}>
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                  color: 'white',
                }}
              >
                .
              </span>
              <span className="inputLabel" style={{ float: 'left' }}>
                Strict
              </span>
              <input
                type="checkbox"
                checked={this.state[catId + 'EntertainerStrict']}
                onChange={(value) => {
                  this.onChangeDate(
                    catId,
                    'EntertainerStrict',
                    value,
                  );
                }}
                className="closedCheckBox"
                style={{ float: 'none' }}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }

  setCurrentValues() {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 't' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];
    if (this.props.match.params.id !== undefined) {
      const doc = this.getContent(
        this.props.match.params.id,
        this.props.guideTemplates,
      );
      this.setState({
        title: doc.title,
        categories: doc.categories,
        loading: false,
        templateId: this.props.match.params.id,
      });

      doc.categories.sectionOne.forEach((category, index) => {
        // let catId = 'cat' + this.state.numbers[category.position - 1];
        const zones = category.filters.zones.join(',');
        const zips = category.filters.zips.join(',');
        const cities = category.filters.cities.join(',');

        let includeEvents = false;
        let includeEntertainers = false;
        let includeUserRequest = false;
        let includeLocations = false;
        if (
          category.types !== undefined &&
          category.types.length !== 0
        ) {
          category.types.forEach((type, index) => {
            if (type === 'Locations') {
              includeLocations = true;
            }
            if (type === 'Events') {
              includeEvents = true;
            }
            if (type === 'Entertainers') {
              includeEntertainers = true;
            }
            if (type === 'User Request') {
              includeUserRequest = true;
            }
          });
        } else {
          if (category.type === 'Locations') {
            includeLocations = true;
          }
          if (category.type === 'Events') {
            includeEvents = true;
          }
          if (category.type === 'Entertainers') {
            includeEntertainers = true;
          }
        }

        const type = category.type;
        const visibility = category.visibility;
        const tags = category.filters.tags;

        let boosters = [];
        if (category.filters.boosters !== undefined) {
          boosters = category.filters.boosters.tags;
        }
        let reducers = [];
        if (category.filters.reducers !== undefined) {
          reducers = category.filters.reducers.tags;
        }
        let required = [];
        if (category.filters.required !== undefined) {
          required = category.filters.required.tags;
        }

        if (category.time !== undefined) {
          if (category.time.toTime !== undefined) {
            this.setState({
              [category.categoryId + 'ToTime']: category.time.toTime,
              [category.categoryId + 'showTimes']: true,
            });
          }
        }
        if (category.time !== undefined) {
          if (category.time.fromTime !== undefined) {
            this.setState({
              [category.categoryId + 'FromTime']:
                category.time.fromTime,
              [category.categoryId + 'showTimes']: true,
            });
          }
        }
        if (category.time !== undefined) {
          if (category.time.activeDays !== undefined) {
            category.time.activeDays.forEach((day, index) => {
              dayArray.forEach((dayx, index) => {
                if (dayx.short === day) {
                  this.setState({
                    [category.categoryId + dayx.full]: true,
                    [category.categoryId + 'showTimes']: true,
                  });
                }
              });
            });
          }
        }
        const section = category.section;
        let tagArray = [];
        let boosterArray = [];
        let reducerArray = [];
        let requiredArray = [];
        let genreTagArray = [];
        let eventTypeTagArray = [];
        let locationTypeTagArray = [];

        boosters.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            boosterArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        reducers.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            reducerArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        required.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            requiredArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });

        tags.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            if (tag.sid === 'genre') {
              genreTagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            } else if (tag.sid === 'eventType') {
              eventTypeTagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            } else if (tag.sid === 'locationType') {
              locationTypeTagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            } else {
              tagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            }
          });
        });
        let ignore = category.filters.ignoreFilters;
        let includeNearbyRegions =
          category.filters.includeNearbyRegions;
        let ignoreRegionFilters =
          category.filters.ignoreRegionFilters;
        if (category.filters.tagSettings !== undefined) {
          this.setState({
            [category.categoryId + 'Title']: category.title,
            [category.categoryId + 'LocationStrict']:
              category.filters.tagSettings.location.strict,
            [category.categoryId + 'EventStrict']:
              category.filters.tagSettings.event.strict,
            [category.categoryId + 'EntertainerStrict']:
              category.filters.tagSettings.entertainer.strict,
            [category.categoryId + 'Visibility']: visibility,
            [category.categoryId + 'ZipCodes']: zips,
            [category.categoryId + 'Zones']: zones,
            [category.categoryId + 'Boosters']: boosterArray,
            [category.categoryId + 'Reducers']: reducerArray,
            [category.categoryId + 'Required']: requiredArray,
            [category.categoryId + 'Cities']: cities,
            [category.categoryId + 'Type']: type,
            [category.categoryId + 'Tags']: tagArray,
            [category.categoryId + 'EventTags']: genreTagArray,
            [category.categoryId + 'EventTypeTags']:
              eventTypeTagArray,
            [category.categoryId + 'Ignore']: ignore,
            [category.categoryId + 'IncludeNearbyRegions']:
              includeNearbyRegions,
            [category.categoryId + 'IgnoreRegionFilters']:
              ignoreRegionFilters,
            [category.categoryId + 'LocationTypeTags']:
              locationTypeTagArray,
            [category.categoryId + 'Section']: section,
            [category.categoryId + 'IncludeLocations']:
              includeLocations,
            [category.categoryId + 'IncludeEvents']: includeEvents,
            [category.categoryId + 'IncludeEntertainers']:
              includeEntertainers,
            [category.categoryId + 'IncludeUserRequest']:
              includeUserRequest,
          });
        } else {
          this.setState({
            [category.categoryId + 'Title']: category.title,

            [category.categoryId + 'Visibility']: visibility,
            [category.categoryId + 'ZipCodes']: zips,
            [category.categoryId + 'Zones']: zones,
            [category.categoryId + 'Boosters']: boosterArray,
            [category.categoryId + 'Reducers']: reducerArray,
            [category.categoryId + 'Required']: requiredArray,
            [category.categoryId + 'Cities']: cities,
            [category.categoryId + 'Type']: type,
            [category.categoryId + 'Tags']: tagArray,
            [category.categoryId + 'EventTags']: genreTagArray,
            [category.categoryId + 'EventTypeTags']:
              eventTypeTagArray,
            [category.categoryId + 'Ignore']: ignore,
            [category.categoryId + 'IncludeNearbyRegions']:
              includeNearbyRegions,
            [category.categoryId + 'IgnoreRegionFilters']:
              ignoreRegionFilters,
            [category.categoryId + 'LocationTypeTags']:
              locationTypeTagArray,
            [category.categoryId + 'Section']: section,
            [category.categoryId + 'IncludeLocations']:
              includeLocations,
            [category.categoryId + 'IncludeEvents']: includeEvents,
            [category.categoryId + 'IncludeEntertainers']:
              includeEntertainers,
            [category.categoryId + 'IncludeUserRequest']:
              includeUserRequest,
          });
        }
      });
      doc.categories.sectionTwo.forEach((category, index) => {
        // let catId = 'cat' + this.state.numbers[category.position - 1];
        const zones = category.filters.zones.join(',');
        const zips = category.filters.zips.join(',');
        const cities = category.filters.cities.join(',');
        let includeEvents = false;
        let includeEntertainers = false;
        let includeUserRequest = false;
        let includeLocations = false;
        if (
          category.types !== undefined &&
          category.types.length !== 0
        ) {
          category.types.forEach((type, index) => {
            if (type === 'Locations') {
              includeLocations = true;
            }
            if (type === 'Events') {
              includeEvents = true;
            }
            if (type === 'Entertainers') {
              includeEntertainers = true;
            }
            if (type === 'User Request') {
              includeUserRequest = true;
            }
          });
        } else {
          if (category.type === 'Locations') {
            includeLocations = true;
          }
          if (category.type === 'Events') {
            includeEvents = true;
          }
          if (category.type === 'Entertainers') {
            includeEntertainers = true;
          }
        }

        const type = category.type;
        const visibility = category.visibility;
        const tags = category.filters.tags;
        let boosters = [];
        if (category.filters.boosters !== undefined) {
          boosters = category.filters.boosters.tags;
        }
        let reducers = [];
        if (category.filters.reducers !== undefined) {
          reducers = category.filters.reducers.tags;
        }
        let required = [];
        if (category.filters.required !== undefined) {
          required = category.filters.required.tags;
        }
        const section = category.section;
        let tagArray = [];
        let boosterArray = [];
        let reducerArray = [];
        let requiredArray = [];
        let genreTagArray = [];
        let eventTypeTagArray = [];
        let locationTypeTagArray = [];
        boosters.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            boosterArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        reducers.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            reducerArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        required.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            requiredArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        tags.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            if (tag.sid === 'genre') {
              genreTagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            } else if (tag.sid === 'eventType') {
              eventTypeTagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            } else if (tag.sid === 'locationType') {
              locationTypeTagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            } else {
              tagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            }
          });
        });
        let ignore = category.filters.ignoreFilters;
        let includeNearbyRegions =
          category.filters.includeNearbyRegions;
        let ignoreRegionFilters =
          category.filters.ignoreRegionFilters;
        if (category.filters.tagSettings !== undefined) {
          this.setState({
            [category.categoryId + 'Title']: category.title,
            [category.categoryId + 'LocationStrict']:
              category.filters.tagSettings.location.strict,
            [category.categoryId + 'EventStrict']:
              category.filters.tagSettings.event.strict,
            [category.categoryId + 'EntertainerStrict']:
              category.filters.tagSettings.entertainer.strict,
            [category.categoryId + 'Visibility']: visibility,
            [category.categoryId + 'ZipCodes']: zips,
            [category.categoryId + 'Zones']: zones,
            [category.categoryId + 'Boosters']: boosterArray,
            [category.categoryId + 'Reducers']: reducerArray,
            [category.categoryId + 'Required']: requiredArray,
            [category.categoryId + 'Cities']: cities,
            [category.categoryId + 'Type']: type,
            [category.categoryId + 'Tags']: tagArray,
            [category.categoryId + 'EventTags']: genreTagArray,
            [category.categoryId + 'EventTypeTags']:
              eventTypeTagArray,
            [category.categoryId + 'Ignore']: ignore,
            [category.categoryId + 'IncludeNearbyRegions']:
              includeNearbyRegions,
            [category.categoryId + 'IgnoreRegionFilters']:
              ignoreRegionFilters,
            [category.categoryId + 'LocationTypeTags']:
              locationTypeTagArray,
            [category.categoryId + 'Section']: section,
            [category.categoryId + 'IncludeLocations']:
              includeLocations,
            [category.categoryId + 'IncludeEvents']: includeEvents,
            [category.categoryId + 'IncludeEntertainers']:
              includeEntertainers,
            [category.categoryId + 'IncludeUserRequest']:
              includeUserRequest,
          });
        } else {
          this.setState({
            [category.categoryId + 'Title']: category.title,

            [category.categoryId + 'Visibility']: visibility,
            [category.categoryId + 'ZipCodes']: zips,
            [category.categoryId + 'Zones']: zones,
            [category.categoryId + 'Boosters']: boosterArray,
            [category.categoryId + 'Reducers']: reducerArray,
            [category.categoryId + 'Required']: requiredArray,
            [category.categoryId + 'Cities']: cities,
            [category.categoryId + 'Type']: type,
            [category.categoryId + 'Tags']: tagArray,
            [category.categoryId + 'EventTags']: genreTagArray,
            [category.categoryId + 'EventTypeTags']:
              eventTypeTagArray,
            [category.categoryId + 'Ignore']: ignore,
            [category.categoryId + 'IncludeNearbyRegions']:
              includeNearbyRegions,
            [category.categoryId + 'IgnoreRegionFilters']:
              ignoreRegionFilters,
            [category.categoryId + 'LocationTypeTags']:
              locationTypeTagArray,
            [category.categoryId + 'Section']: section,
            [category.categoryId + 'IncludeLocations']:
              includeLocations,
            [category.categoryId + 'IncludeEvents']: includeEvents,
            [category.categoryId + 'IncludeEntertainers']:
              includeEntertainers,
            [category.categoryId + 'IncludeUserRequest']:
              includeUserRequest,
          });
        }
      });
      doc.categories.sectionThree.forEach((category, index) => {
        // let catId = 'cat' + this.state.numbers[category.position - 1];
        const zones = category.filters.zones.join(',');
        const zips = category.filters.zips.join(',');
        const cities = category.filters.cities.join(',');

        let includeEvents = false;
        let includeEntertainers = false;
        let includeUserRequest = false;
        let includeLocations = false;
        if (
          category.types !== undefined &&
          category.types.length !== 0
        ) {
          category.types.forEach((type, index) => {
            if (type === 'Locations') {
              includeLocations = true;
            }
            if (type === 'Events') {
              includeEvents = true;
            }
            if (type === 'Entertainers') {
              includeEntertainers = true;
            }
            if (type === 'User Request') {
              includeUserRequest = true;
            }
          });
        } else {
          if (category.type === 'Locations') {
            includeLocations = true;
          }
          if (category.type === 'Events') {
            includeEvents = true;
          }
          if (category.type === 'Entertainers') {
            includeEntertainers = true;
          }
        }

        const type = category.type;
        let boosters = [];
        if (category.filters.boosters !== undefined) {
          boosters = category.filters.boosters.tags;
        }
        let reducers = [];
        if (category.filters.reducers !== undefined) {
          reducers = category.filters.reducers.tags;
        }
        let required = [];
        if (category.filters.required !== undefined) {
          required = category.filters.required.tags;
        }
        const visibility = category.visibility;
        const tags = category.filters.tags;
        const section = category.section;
        let tagArray = [];
        let boosterArray = [];
        let reducerArray = [];
        let requiredArray = [];
        let genreTagArray = [];
        let eventTypeTagArray = [];
        let locationTypeTagArray = [];
        boosters.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            boosterArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        reducers.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            reducerArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        required.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            requiredArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        tags.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            if (tag.sid === 'genre') {
              genreTagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            } else if (tag.sid === 'eventType') {
              eventTypeTagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            } else if (tag.sid === 'locationType') {
              locationTypeTagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            } else {
              tagArray.push({
                value: tagx + ',' + tag.sid,
                label: tagx,
              });
            }
          });
        });
        let ignore = category.filters.ignoreFilters;
        let includeNearbyRegions =
          category.filters.includeNearbyRegions;
        let ignoreRegionFilters =
          category.filters.ignoreRegionFilters;
        if (category.filters.tagSettings !== undefined) {
          this.setState({
            [category.categoryId + 'Title']: category.title,
            [category.categoryId + 'LocationStrict']:
              category.filters.tagSettings.location.strict,
            [category.categoryId + 'EventStrict']:
              category.filters.tagSettings.event.strict,
            [category.categoryId + 'EntertainerStrict']:
              category.filters.tagSettings.entertainer.strict,
            [category.categoryId + 'Visibility']: visibility,
            [category.categoryId + 'ZipCodes']: zips,
            [category.categoryId + 'Zones']: zones,
            [category.categoryId + 'Boosters']: boosterArray,
            [category.categoryId + 'Reducers']: reducerArray,
            [category.categoryId + 'Required']: requiredArray,
            [category.categoryId + 'Cities']: cities,
            [category.categoryId + 'Type']: type,
            [category.categoryId + 'Tags']: tagArray,
            [category.categoryId + 'EventTags']: genreTagArray,
            [category.categoryId + 'EventTypeTags']:
              eventTypeTagArray,
            [category.categoryId + 'Ignore']: ignore,
            [category.categoryId + 'IncludeNearbyRegions']:
              includeNearbyRegions,
            [category.categoryId + 'IgnoreRegionFilters']:
              ignoreRegionFilters,
            [category.categoryId + 'LocationTypeTags']:
              locationTypeTagArray,
            [category.categoryId + 'Section']: section,
            [category.categoryId + 'IncludeLocations']:
              includeLocations,
            [category.categoryId + 'IncludeEvents']: includeEvents,
            [category.categoryId + 'IncludeEntertainers']:
              includeEntertainers,
            [category.categoryId + 'IncludeUserRequest']:
              includeUserRequest,
          });
        } else {
          this.setState({
            [category.categoryId + 'Title']: category.title,

            [category.categoryId + 'Visibility']: visibility,
            [category.categoryId + 'ZipCodes']: zips,
            [category.categoryId + 'Zones']: zones,
            [category.categoryId + 'Boosters']: boosterArray,
            [category.categoryId + 'Reducers']: reducerArray,
            [category.categoryId + 'Required']: requiredArray,
            [category.categoryId + 'Cities']: cities,
            [category.categoryId + 'Type']: type,
            [category.categoryId + 'Tags']: tagArray,
            [category.categoryId + 'EventTags']: genreTagArray,
            [category.categoryId + 'EventTypeTags']:
              eventTypeTagArray,
            [category.categoryId + 'Ignore']: ignore,
            [category.categoryId + 'IncludeNearbyRegions']:
              includeNearbyRegions,
            [category.categoryId + 'IgnoreRegionFilters']:
              ignoreRegionFilters,
            [category.categoryId + 'LocationTypeTags']:
              locationTypeTagArray,
            [category.categoryId + 'Section']: section,
            [category.categoryId + 'IncludeLocations']:
              includeLocations,
            [category.categoryId + 'IncludeEvents']: includeEvents,
            [category.categoryId + 'IncludeEntertainers']:
              includeEntertainers,
            [category.categoryId + 'IncludeUserRequest']:
              includeUserRequest,
          });
        }
      });
    }
  }

  getContent(id, array) {
    if (id !== undefined) {
      const contentDetails = query('id').is(id).on(array);
      return contentDetails[0];
    } else {
      return false;
    }
  }

  saveTemplate = (id, values) => {
    let templateTitle = values.title;

    let tempArray = [];
    tempArray.push(this.state.categories.sectionOne);
    tempArray.push(this.state.categories.sectionTwo);
    tempArray.push(this.state.categories.sectionThree);

    let categories = {
      sectionOne: [],
      sectionTwo: [],
      sectionThree: [],
    };

    tempArray.forEach((sections, index) => {
      sections.forEach((category, index) => {
        let types = [];

        let eventsInclude =
          this.state[category.categoryId + 'IncludeEvents'];
        if (eventsInclude === true) {
          types.push('Events');
        }

        let locationsInclude =
          this.state[category.categoryId + 'IncludeLocations'];
        if (locationsInclude === true) {
          types.push('Locations');
        }

        let entertainersInclude =
          this.state[category.categoryId + 'IncludeEntertainers'];
        if (entertainersInclude === true) {
          types.push('Entertainers');
        }

        let usersInclude =
          this.state[category.categoryId + 'IncludeUserRequest'];
        if (usersInclude === true) {
          types.push('User Request');
        }

        let type = 'Locations';
        if (this.state[category.categoryId + 'Type'] != undefined) {
          type = this.state[category.categoryId + 'Type'];
        }
        let newCategory = {
          filters: {
            tags: [],
            zips: [],
            cities: [],
            zones: [],
            ignoreFilters: false,
            ignoreRegionFilters: false,
            includeNearbyRegions: false,
            boosters: { tags: [] },
            reducers: { tags: [] },
            required: { tags: [] },
          },
          time: {},
          title: this.state[category.categoryId + 'Title'],
          categoryId: category.categoryId,
          position: category.position,
          type: type,
          types,
          visibility: this.state[category.categoryId + 'Visibility'],
          section: this.state[category.categoryId + 'Section'],
        };

        if (
          this.state[category.categoryId + 'IncludeNearbyRegions'] !==
          undefined
        ) {
          newCategory.filters.includeNearbyRegions =
            this.state[category.categoryId + 'IncludeNearbyRegions'];
        }

        if (
          this.state[category.categoryId + 'Ignore'] !== undefined
        ) {
          newCategory.filters.ignoreFilters =
            this.state[category.categoryId + 'Ignore'];
        }

        if (
          this.state[category.categoryId + 'IgnoreRegionFilters'] !==
          undefined
        ) {
          newCategory.filters.ignoreRegionFilters =
            this.state[category.categoryId + 'IgnoreRegionFilters'];
        }

        if (
          this.state[category.categoryId + 'FromTime'] !== undefined
        ) {
          newCategory.time.fromTime =
            this.state[category.categoryId + 'FromTime'];
        }
        if (
          this.state[category.categoryId + 'ToTime'] !== undefined
        ) {
          newCategory.time.toTime =
            this.state[category.categoryId + 'ToTime'];
        }

        let dayArray = [
          { full: 'Sunday', short: 'su' },
          { full: 'Monday', short: 'm' },
          { full: 'Tuesday', short: 't' },
          { full: 'Wednesday', short: 'w' },
          { full: 'Thursday', short: 'th' },
          { full: 'Friday', short: 'f' },
          { full: 'Saturday', short: 'sa' },
        ];
        let selectedDayArray = [];
        dayArray.forEach((day, index) => {
          if (this.state[category.categoryId + day.full] === true) {
            selectedDayArray.push(day.short);
          }
        });
        newCategory.time.activeDays = selectedDayArray;

        // Tag Settings
        newCategory.filters.tagSettings = {
          location: {
            setting: 1,
            tagsFavs: 0,
            include: false,
            strict: false,
          },
          entertainer: {
            setting: 1,
            tagsFavs: 0,
            include: false,
            strict: false,
          },
          event: {
            setting: 1,
            tagsFavs: 0,
            include: false,
            strict: false,
          },
        };

        let LocationsCreatorFavs =
          this.state[category.categoryId + 'LocationsCreatorFavs'];
        let EventsCreatorFavs =
          this.state[category.categoryId + 'EventsCreatorFavs'];
        let EntertainersCreatorFavs =
          this.state[category.categoryId + 'EntertainersCreatorFavs'];
        let LocationsGuestFavs =
          this.state[category.categoryId + 'LocationsGuestFavs'];
        let EventsGuestFavs =
          this.state[category.categoryId + 'EventsGuestFavs'];
        let EntertainersGuestFavs =
          this.state[category.categoryId + 'EntertainersGuestFavs'];

        let LocationStrict =
          this.state[category.categoryId + 'LocationStrict'];
        let EventStrict =
          this.state[category.categoryId + 'EventStrict'];
        let EntertainerStrict =
          this.state[category.categoryId + 'EntertainerStrict'];

        let IncludeEntertainers =
          this.state[category.categoryId + 'IncludeEntertainers'];

        let IncludeLocations =
          this.state[category.categoryId + 'IncludeLocations'];

        let IncludeEvents =
          this.state[category.categoryId + 'IncludeEvents'];

        let IncludeUserRequest =
          this.state[category.categoryId + 'IncludeUserRequest'];

        if (IncludeEntertainers !== undefined) {
          console.log('IncludeEntertainers', IncludeEntertainers);
          newCategory.filters.tagSettings.entertainer.include =
            IncludeEntertainers;
        }

        if (IncludeEvents !== undefined && IncludeEvents === true) {
          newCategory.filters.tagSettings.event.include =
            IncludeEvents;
        }

        if (
          IncludeLocations !== undefined &&
          IncludeLocations === true
        ) {
          newCategory.filters.tagSettings.location.include =
            IncludeLocations;
        }

        if (
          IncludeUserRequest !== undefined &&
          IncludeUserRequest === true
        ) {
          newCategory.filters.tagSettings.location.include =
            IncludeUserRequest;
          newCategory.filters.tagSettings.event.include =
            IncludeUserRequest;
          newCategory.filters.tagSettings.entertainer.include =
            IncludeUserRequest;
        }

        if (EntertainerStrict !== undefined) {
          newCategory.filters.tagSettings.entertainer.strict =
            EntertainerStrict;
        }
        if (LocationStrict !== undefined) {
          newCategory.filters.tagSettings.location.strict =
            LocationStrict;
        }
        if (EventStrict !== undefined) {
          newCategory.filters.tagSettings.event.strict = EventStrict;
        }

        // Location TagsAndFavs Settings
        if (LocationsCreatorFavs !== undefined) {
          if (LocationsCreatorFavs === true) {
            if (
              LocationsGuestFavs === undefined ||
              LocationsGuestFavs !== true
            ) {
              newCategory.filters.tagSettings.location.tagsFavs = 1;
            }
          }
        }
        if (LocationsGuestFavs !== undefined) {
          if (LocationsGuestFavs === true) {
            if (
              LocationsCreatorFavs === undefined ||
              LocationsCreatorFavs !== true
            ) {
              newCategory.filters.tagSettings.location.tagsFavs = 2;
            }
          }
        }
        if (
          LocationsGuestFavs !== undefined &&
          LocationsGuestFavs !== undefined
        ) {
          if (LocationsGuestFavs === true && LocationsCreatorFavs) {
            newCategory.filters.tagSettings.location.tagsFavs = 3;
          }
        }

        // Event TagsAndFavs Settings
        if (EventsCreatorFavs !== undefined) {
          if (EventsCreatorFavs === true) {
            if (
              EventsGuestFavs === undefined ||
              EventsGuestFavs !== true
            ) {
              newCategory.filters.tagSettings.event.tagsFavs = 1;
            }
          }
        }
        if (EventsGuestFavs !== undefined) {
          if (EventsGuestFavs === true) {
            if (
              EventsCreatorFavs === undefined ||
              EventsCreatorFavs !== true
            ) {
              newCategory.filters.tagSettings.event.tagsFavs = 2;
            }
          }
        }
        if (
          EventsCreatorFavs !== undefined &&
          EventsGuestFavs !== undefined
        ) {
          if (EventsGuestFavs === true && EventsCreatorFavs) {
            newCategory.filters.tagSettings.event.tagsFavs = 3;
          }
        }

        // Entertainer TagsAndFavs Settings
        if (EntertainersCreatorFavs !== undefined) {
          if (EntertainersCreatorFavs === true) {
            if (
              EntertainersGuestFavs === undefined ||
              EntertainersGuestFavs !== true
            ) {
              newCategory.filters.tagSettings.entertainer.tagsFavs = 1;
            }
          }
        }
        if (EntertainersGuestFavs !== undefined) {
          if (EntertainersGuestFavs === true) {
            if (
              EntertainersCreatorFavs === undefined ||
              EntertainersCreatorFavs !== true
            ) {
              newCategory.filters.tagSettings.entertainer.tagsFavs = 2;
            }
          }
        }
        if (
          EntertainersGuestFavs !== undefined &&
          EntertainersGuestFavs !== undefined
        ) {
          if (
            EntertainersGuestFavs === true &&
            EntertainersCreatorFavs
          ) {
            newCategory.filters.tagSettings.entertainer.tagsFavs = 3;
          }
        }

        let LocationTypeSetting =
          this.state[category.categoryId + 'LocationTypeSetting'];
        let EntertainerTypeSetting =
          this.state[category.categoryId + 'EntertainerTypeSetting'];
        let EventTagSetting =
          this.state[category.categoryId + 'EventTagSetting'];
        if (EntertainerTypeSetting !== undefined) {
          newCategory.filters.tagSettings.entertainer.setting =
            EntertainerTypeSetting.value;
        }
        if (LocationTypeSetting !== undefined) {
          newCategory.filters.tagSettings.location.setting =
            LocationTypeSetting.value;
        }
        if (EventTagSetting !== undefined) {
          newCategory.filters.tagSettings.event.setting =
            EventTagSetting.value;
        }

        let tagFilters = {};

        let boosterFilters = {};
        let Boosters = this.state[category.categoryId + 'Boosters'];

        let reducerFilters = {};
        let Reducers = this.state[category.categoryId + 'Reducers'];

        let requiredFilters = {};
        let Required = this.state[category.categoryId + 'Required'];

        let EntertainerTypeTags =
          this.state[category.categoryId + 'EntertainerTypeTags'];

        let EntertainerGenre =
          this.state[category.categoryId + 'EntertainerGenre'];

        let EventTypeTags =
          this.state[category.categoryId + 'EventTypeTags'];

        let EventSettingsTags =
          this.state[category.categoryId + 'EventSettingsTags'];

        let tags = this.state[category.categoryId + 'Tags'];

        let LocationTypeTags =
          this.state[category.categoryId + 'LocationTypeTags'];

        if (Reducers !== undefined) {
          Reducers.forEach((tagGroup, index) => {
            let broken = tagGroup.value
              .split(',')
              .filter((string) => Boolean(string));
            let tagx = broken[0];
            let sid = broken[1];
            let tagType = '';
            if (sid === 'activityType') {
              tagType = 'tagsActivity';
            } else if (sid === 'beverages') {
              tagType = 'tagsBeverages';
            } else if (sid === 'cuisineType') {
              tagType = 'tagsCuisine';
            } else if (sid === 'locationType') {
              tagType = 'tagsLocationType';
            } else if (sid === 'setting') {
              tagType = 'tagsSetting';
            } else if (sid === 'dateType') {
              tagType = 'tagsDateType';
            } else if (sid === 'experienceType') {
              tagType = 'tagsExperienceType';
            } else if (sid === 'eventType') {
              tagType = 'tagsEventType';
            } else if (sid === 'genre') {
              tagType = 'tagsGenre';
            } else if (sid === 'entertainmentType') {
              tagType = 'tagsEntertainmentType';
            } else if (sid === 'dietary') {
              tagType = 'tagsDietary';
            }
            if (reducerFilters[sid] === undefined) {
              reducerFilters[sid] = {
                tags: [tagx],
                sid: sid,
                tagType: tagType,
              };
            } else {
              reducerFilters[sid].tags.push(tagx);
            }
          });
        }
        if (Boosters !== undefined) {
          Boosters.forEach((tagGroup, index) => {
            let broken = tagGroup.value
              .split(',')
              .filter((string) => Boolean(string));
            let tagx = broken[0];
            let sid = broken[1];
            let tagType = '';
            if (sid === 'activityType') {
              tagType = 'tagsActivity';
            } else if (sid === 'beverages') {
              tagType = 'tagsBeverages';
            } else if (sid === 'cuisineType') {
              tagType = 'tagsCuisine';
            } else if (sid === 'locationType') {
              tagType = 'tagsLocationType';
            } else if (sid === 'setting') {
              tagType = 'tagsSetting';
            } else if (sid === 'dateType') {
              tagType = 'tagsDateType';
            } else if (sid === 'experienceType') {
              tagType = 'tagsExperienceType';
            } else if (sid === 'eventType') {
              tagType = 'tagsEventType';
            } else if (sid === 'genre') {
              tagType = 'tagsGenre';
            } else if (sid === 'entertainmentType') {
              tagType = 'tagsEntertainmentType';
            } else if (sid === 'dietary') {
              tagType = 'tagsDietary';
            }
            if (boosterFilters[sid] === undefined) {
              boosterFilters[sid] = {
                tags: [tagx],
                sid: sid,
                tagType: tagType,
              };
            } else {
              boosterFilters[sid].tags.push(tagx);
            }
          });
        }
        if (Required !== undefined) {
          Required.forEach((tagGroup, index) => {
            let broken = tagGroup.value
              .split(',')
              .filter((string) => Boolean(string));
            let tagx = broken[0];
            let sid = broken[1];
            let tagType = '';
            if (sid === 'activityType') {
              tagType = 'tagsActivity';
            } else if (sid === 'beverages') {
              tagType = 'tagsBeverages';
            } else if (sid === 'cuisineType') {
              tagType = 'tagsCuisine';
            } else if (sid === 'locationType') {
              tagType = 'tagsLocationType';
            } else if (sid === 'setting') {
              tagType = 'tagsSetting';
            } else if (sid === 'dateType') {
              tagType = 'tagsDateType';
            } else if (sid === 'experienceType') {
              tagType = 'tagsExperienceType';
            } else if (sid === 'eventType') {
              tagType = 'tagsEventType';
            } else if (sid === 'genre') {
              tagType = 'tagsGenre';
            } else if (sid === 'entertainmentType') {
              tagType = 'tagsEntertainmentType';
            } else if (sid === 'dietary') {
              tagType = 'tagsDietary';
            }
            if (requiredFilters[sid] === undefined) {
              requiredFilters[sid] = {
                tags: [tagx],
                sid: sid,
                tagType: tagType,
              };
            } else {
              requiredFilters[sid].tags.push(tagx);
            }
          });
        }
        if (EntertainerGenre !== undefined) {
          EntertainerGenre.forEach((tagGroup, index) => {
            let broken = tagGroup.value
              .split(',')
              .filter((string) => Boolean(string));
            let tagx = broken[0];
            let sid = broken[1];
            let tagType = '';
            if (sid === 'activityType') {
              tagType = 'tagsActivity';
            } else if (sid === 'beverages') {
              tagType = 'tagsBeverages';
            } else if (sid === 'cuisineType') {
              tagType = 'tagsCuisine';
            } else if (sid === 'locationType') {
              tagType = 'tagsLocationType';
            } else if (sid === 'setting') {
              tagType = 'tagsSetting';
            } else if (sid === 'dateType') {
              tagType = 'tagsDateType';
            } else if (sid === 'experienceType') {
              tagType = 'tagsExperienceType';
            } else if (sid === 'eventType') {
              tagType = 'tagsEventType';
            } else if (sid === 'genre') {
              tagType = 'tagsGenre';
            } else if (sid === 'entertainmentType') {
              tagType = 'tagsEntertainmentType';
            } else if (sid === 'dietary') {
              tagType = 'tagsDietary';
            }
            if (tagFilters[sid] === undefined) {
              tagFilters[sid] = {
                tags: [tagx],
                sid: sid,
                tagType: tagType,
              };
            } else {
              tagFilters[sid].tags.push(tagx);
            }
          });
        }

        if (EntertainerTypeTags !== undefined) {
          EntertainerTypeTags.forEach((tagGroup, index) => {
            let broken = tagGroup.value
              .split(',')
              .filter((string) => Boolean(string));
            let tagx = broken[0];
            let sid = broken[1];
            let tagType = '';
            if (sid === 'activityType') {
              tagType = 'tagsActivity';
            } else if (sid === 'beverages') {
              tagType = 'tagsBeverages';
            } else if (sid === 'cuisineType') {
              tagType = 'tagsCuisine';
            } else if (sid === 'locationType') {
              tagType = 'tagsLocationType';
            } else if (sid === 'setting') {
              tagType = 'tagsSetting';
            } else if (sid === 'dateType') {
              tagType = 'tagsDateType';
            } else if (sid === 'experienceType') {
              tagType = 'tagsExperienceType';
            } else if (sid === 'eventType') {
              tagType = 'tagsEventType';
            } else if (sid === 'genre') {
              tagType = 'tagsGenre';
            } else if (sid === 'entertainmentType') {
              tagType = 'tagsEntertainmentType';
            } else if (sid === 'dietary') {
              tagType = 'tagsDietary';
            }
            if (tagFilters[sid] === undefined) {
              tagFilters[sid] = {
                tags: [tagx],
                sid: sid,
                tagType: tagType,
              };
            } else {
              tagFilters[sid].tags.push(tagx);
            }
          });
        }

        if (LocationTypeTags !== undefined) {
          LocationTypeTags.forEach((tagGroup, index) => {
            let broken = tagGroup.value
              .split(',')
              .filter((string) => Boolean(string));
            let tagx = broken[0];
            let sid = broken[1];
            let tagType = '';
            if (sid === 'activityType') {
              tagType = 'tagsActivity';
            } else if (sid === 'beverages') {
              tagType = 'tagsBeverages';
            } else if (sid === 'cuisineType') {
              tagType = 'tagsCuisine';
            } else if (sid === 'locationType') {
              tagType = 'tagsLocationType';
            } else if (sid === 'setting') {
              tagType = 'tagsSetting';
            } else if (sid === 'dateType') {
              tagType = 'tagsDateType';
            } else if (sid === 'experienceType') {
              tagType = 'tagsExperienceType';
            } else if (sid === 'eventType') {
              tagType = 'tagsEventType';
            } else if (sid === 'genre') {
              tagType = 'tagsGenre';
            } else if (sid === 'entertainmentType') {
              tagType = 'tagsEntertainmentType';
            } else if (sid === 'dietary') {
              tagType = 'tagsDietary';
            }
            if (tagFilters[sid] === undefined) {
              tagFilters[sid] = {
                tags: [tagx],
                sid: sid,
                tagType: tagType,
              };
            } else {
              tagFilters[sid].tags.push(tagx);
            }
          });
        }

        if (EventTypeTags !== undefined) {
          EventTypeTags.forEach((tagGroup, index) => {
            let broken = tagGroup.value
              .split(',')
              .filter((string) => Boolean(string));
            let tagx = broken[0];
            let sid = broken[1];
            let tagType = '';
            if (sid === 'activityType') {
              tagType = 'tagsActivity';
            } else if (sid === 'beverages') {
              tagType = 'tagsBeverages';
            } else if (sid === 'cuisineType') {
              tagType = 'tagsCuisine';
            } else if (sid === 'locationType') {
              tagType = 'tagsLocationType';
            } else if (sid === 'setting') {
              tagType = 'tagsSetting';
            } else if (sid === 'dateType') {
              tagType = 'tagsDateType';
            } else if (sid === 'experienceType') {
              tagType = 'tagsExperienceType';
            } else if (sid === 'eventType') {
              tagType = 'tagsEventType';
            } else if (sid === 'genre') {
              tagType = 'tagsGenre';
            } else if (sid === 'entertainmentType') {
              tagType = 'tagsEntertainmentType';
            } else if (sid === 'dietary') {
              tagType = 'tagsDietary';
            }
            if (tagFilters[sid] === undefined) {
              tagFilters[sid] = {
                tags: [tagx],
                sid: sid,
                tagType: tagType,
              };
            } else {
              tagFilters[sid].tags.push(tagx);
            }
          });
        }

        if (EventSettingsTags !== undefined) {
          EventSettingsTags.forEach((tagGroup, index) => {
            let broken = tagGroup.value
              .split(',')
              .filter((string) => Boolean(string));
            let tagx = broken[0];
            let sid = broken[1];
            let tagType = '';
            if (sid === 'activityType') {
              tagType = 'tagsActivity';
            } else if (sid === 'beverages') {
              tagType = 'tagsBeverages';
            } else if (sid === 'cuisineType') {
              tagType = 'tagsCuisine';
            } else if (sid === 'locationType') {
              tagType = 'tagsLocationType';
            } else if (sid === 'setting') {
              tagType = 'tagsSetting';
            } else if (sid === 'dateType') {
              tagType = 'tagsDateType';
            } else if (sid === 'experienceType') {
              tagType = 'tagsExperienceType';
            } else if (sid === 'eventType') {
              tagType = 'tagsEventType';
            } else if (sid === 'genre') {
              tagType = 'tagsGenre';
            } else if (sid === 'entertainmentType') {
              tagType = 'tagsEntertainmentType';
            } else if (sid === 'dietary') {
              tagType = 'tagsDietary';
            }
            if (tagFilters[sid] === undefined) {
              tagFilters[sid] = {
                tags: [tagx],
                sid: sid,
                tagType: tagType,
              };
            } else {
              tagFilters[sid].tags.push(tagx);
            }
          });
        }

        if (tags !== undefined) {
          tags.forEach((tagGroup, index) => {
            let broken = tagGroup.value
              .split(',')
              .filter((string) => Boolean(string));
            let tagx = broken[0];
            let sid = broken[1];
            let tagType = '';
            if (sid === 'activityType') {
              tagType = 'tagsActivity';
            } else if (sid === 'beverages') {
              tagType = 'tagsBeverages';
            } else if (sid === 'cuisineType') {
              tagType = 'tagsCuisine';
            } else if (sid === 'locationType') {
              tagType = 'tagsLocationType';
            } else if (sid === 'setting') {
              tagType = 'tagsSetting';
            } else if (sid === 'dateType') {
              tagType = 'tagsDateType';
            } else if (sid === 'experienceType') {
              tagType = 'tagsExperienceType';
            } else if (sid === 'eventType') {
              tagType = 'tagsEventType';
            } else if (sid === 'genre') {
              tagType = 'tagsGenre';
            } else if (sid === 'entertainmentType') {
              tagType = 'tagsEntertainmentType';
            } else if (sid === 'dietary') {
              tagType = 'tagsDietary';
            }
            if (tagFilters[sid] === undefined) {
              tagFilters[sid] = {
                tags: [tagx],
                sid: sid,
                tagType: tagType,
              };
            } else {
              tagFilters[sid].tags.push(tagx);
            }
          });
        }

        if (
          this.state[category.categoryId + 'ZipCodes'] !== undefined
        ) {
          var result = this.state[category.categoryId + 'ZipCodes']
            .split(',')
            .map(function (value) {
              return value.trim();
            });
          result.forEach((zipCode, index) => {
            newCategory.filters.zips.push(zipCode);
          });
        }
        if (this.state[category.categoryId + 'Zones'] !== undefined) {
          var result = this.state[category.categoryId + 'Zones']
            .split(',')
            .map(function (value) {
              return value.trim();
            });
          result.forEach((zoneId, index) => {
            newCategory.filters.zones.push(zoneId);
          });
        }
        if (
          this.state[category.categoryId + 'Cities'] !== undefined
        ) {
          var result = this.state[category.categoryId + 'Cities']
            .split(',')
            .map(function (value) {
              return value.trim();
            });
          result.forEach((city, index) => {
            newCategory.filters.cities.push(city);
          });
        }

        Object.keys(tagFilters).map(function (key) {
          newCategory.filters.tags.push(tagFilters[key]);
        });

        Object.keys(boosterFilters).map(function (key) {
          newCategory.filters.boosters.tags.push(boosterFilters[key]);
        });

        Object.keys(reducerFilters).map(function (key) {
          newCategory.filters.reducers.tags.push(reducerFilters[key]);
        });

        Object.keys(requiredFilters).map(function (key) {
          newCategory.filters.required.tags.push(
            requiredFilters[key],
          );
        });

        if (this.state[category.categoryId + 'Section'] === 1) {
          categories.sectionOne.push(newCategory);
        } else if (
          this.state[category.categoryId + 'Section'] === 2
        ) {
          categories.sectionTwo.push(newCategory);
        } else if (
          this.state[category.categoryId + 'Section'] === 3
        ) {
          categories.sectionThree.push(newCategory);
        }
      });
    });

    let template = {
      title: templateTitle,
      categories: categories,
    };

    const db = firebase.firestore();
    const timestamp = new Date();
    const templateToUpdate = db
      .collection('guide_templates')
      .doc(this.state.templateId);
    const updateDoc = templateToUpdate
      .update({
        editHistory: {
          lastUpdated: timestamp,
          lastUpdater: this.props.profile.firstName,
        },
        title: templateTitle,
        categories: categories,
      })
      .then(() => {
        this.props.getGuideTemplates();
        this.props.history.push('/admin/templates');
      });
  };

  changeText = (e) => {
    // const index = this.state.categories.findIndex((object) => {
    //   return object.categoryId === e.target.name;
    // });

    let x = e.target.id.split('/');
    let catId = x[0];
    let target = x[1];
    let toChange = x[2];

    // console.log('dodo', x);
    // let newCategories = [];
    // let section = '';
    // if (target === 1) {
    //   section = 'sectionOne';
    // } else if (target === 2) {
    //   section = 'sectionTwo';
    // } else if (target === 3) {
    //   section = 'sectionThree';
    // }

    // let oldCategory = this.state.categories[section][x];

    // this.state.categories.forEach((category, i) => {
    //   let categoryTemp = {};
    //   if (i === index) {
    //     categoryTemp = {
    //       title: e.target.value,
    //       position: category.position,
    //       filters: category.filters,
    //       categoryId: category.categoryId,
    //     };
    //   } else {
    //     categoryTemp = category;
    //   }
    //   newCategories.push(categoryTemp);
    // });
    // this.setState({
    //   categories: newCategories,
    // });

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  changeVisibility(category, visibility) {
    this.setState({
      [category.categoryId + 'Visibility']: visibility,
    });
  }

  addCategory = (e) => {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * charactersLength),
      );
    }
    let categories = this.state.categories;
    let newCategory = {
      categoryId: result,
      filters: {
        tags: [],
        zones: [],
        zips: [],
        cities: [],
      },
      position: this.state.categories.sectionThree.length + 1,
      title: 'New Category',
      section: 3,
    };
    categories.sectionThree.push(newCategory);
    this.setState({
      categories,
      [result + 'Title']: 'New Category',
      [result + 'Visibility']: true,
      [result + 'Section']: 3,
    });
  };

  getDeleteButton(category, index, section) {
    return (
      <div style={{ width: '100%', display: 'inline-block' }}>
        <div style={{ float: 'right' }}>
          <MaterialIcon
            icon="delete"
            size={20}
            color="rgb(242, 29, 89)"
            onClick={() => {
              this.confirmDelete(category, index, section);
            }}
          />
        </div>
      </div>
    );
  }

  deleteCategory(category, index, onClose, section) {
    let newCategories = this.state.categories;
    newCategories[section].splice(index, 1);
    this.setState({ categories: newCategories });
    onClose();
  }

  confirmDelete(category, index, section) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div style={{ textAlign: 'center' }}>
              <span
                style={{
                  fontWeight: '600',
                  marginBottom: '15px',
                  display: 'block',
                  fontSize: '20px',
                }}
              >
                Are you sure you want to delete this category?
              </span>
              <span
                className="title"
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  fontSize: '18px',
                }}
              >
                "{category.title}"
              </span>
              <div style={{ marginBottom: '20px' }}>
                <a
                  className="red"
                  onClick={() => {
                    this.deleteCategory(
                      category,
                      index,
                      onClose,
                      section,
                    );
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Delete Category
                </a>
              </div>
            </div>

            <div>
              <div>
                <button onClick={onClose} className="redbutton">
                  Nevermind
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  getVisibleButton(category) {
    if (this.state[category.categoryId + 'Visibility'] == true) {
      return (
        <div style={{ width: '100%', display: 'inline-block' }}>
          <div style={{ float: 'right' }}>
            <MaterialIcon
              icon="visibility"
              size={20}
              color="rgb(242, 29, 89)"
              key={category.categoryId + 'Visibility'}
              onClick={() => {
                this.changeVisibility(category, false);
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: '100%', display: 'inline-block' }}>
          <div style={{ float: 'right' }}>
            <MaterialIcon
              icon="visibility_off"
              size={20}
              color="grey"
              key={category.categoryId + 'VisibilityOff'}
              onClick={() => {
                this.changeVisibility(category, true);
              }}
            />
          </div>
        </div>
      );
    }
  }

  getMoveButton(index, categories, category, section) {
    if (this.state.categories[section].length === 1) {
      if (section === 'sectionOne') {
        return (
          <div style={{ width: '100%', display: 'inline-block' }}>
            <div style={{ float: 'right' }}>
              <MaterialIcon
                icon="arrow_upward"
                size={20}
                color="#d0d0d0"
              />
              <a
                onClick={() => {
                  this.changeSection(
                    categories,
                    'sectionOne',
                    'sectionTwo',
                    index,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_downward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconDown'}
                />
              </a>
            </div>
          </div>
        );
      }
      if (section === 'sectionTwo') {
        return (
          <div style={{ width: '100%', display: 'inline-block' }}>
            <div style={{ float: 'right' }}>
              <a
                onClick={() => {
                  this.changeSection(
                    categories,
                    'sectionTwo',
                    'sectionOne',
                    index,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_upward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconUp'}
                />
              </a>
              <a
                onClick={() => {
                  this.changeSection(
                    categories,
                    'sectionOne',
                    'sectionTwo',
                    index,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_downward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconDown'}
                />
              </a>
            </div>
          </div>
        );
      }
      if (section === 'sectionThree') {
        return (
          <div style={{ width: '100%', display: 'inline-block' }}>
            <div style={{ float: 'right' }}>
              <a
                onClick={() => {
                  this.changeSection(
                    categories,
                    'sectionThree',
                    'sectionTwo',
                    index,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_upward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconUp'}
                />
              </a>
              <MaterialIcon
                icon="arrow_downward"
                size={20}
                color="#d0d0d0"
              />
            </div>
          </div>
        );
      }
    } else {
      if (index === 0) {
        if (section === 'sectionOne') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <MaterialIcon
                  icon="arrow_upward"
                  size={20}
                  color="#d0d0d0"
                />
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'down',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_downward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconDown'}
                  />
                </a>
              </div>
            </div>
          );
        } else if (section === 'sectionTwo') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <a
                  onClick={() => {
                    this.changeSection(
                      categories,
                      'sectionTwo',
                      'sectionOne',
                      index,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_upward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconUp'}
                  />
                </a>
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'down',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_downward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconDown'}
                  />
                </a>
              </div>
            </div>
          );
        } else if (section === 'sectionThree') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <a
                  onClick={() => {
                    this.changeSection(
                      categories,
                      'sectionThree',
                      'sectionTwo',
                      index,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_upward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconUp'}
                  />
                </a>
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'down',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_downward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconDown'}
                  />
                </a>
              </div>
            </div>
          );
        }
      } else if (index > 0 && index !== categories.length - 1) {
        return (
          <div
            style={{ width: '100%', display: 'inline-block' }}
            key={categories[index].categoryId + 'icons'}
          >
            <div style={{ float: 'right' }}>
              <a
                onClick={() => {
                  this.changeOrder(
                    categories,
                    categories[index].position - 1,
                    'up',
                    section,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_upward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconUp'}
                />
              </a>
              <a
                onClick={() => {
                  this.changeOrder(
                    categories,
                    categories[index].position - 1,
                    'down',
                    section,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_downward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconDown'}
                />
              </a>
            </div>
          </div>
        );
      } else if (index === categories.length - 1) {
        if (section === 'sectionOne') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'up',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_upward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconUp'}
                  />
                </a>
                <a
                  onClick={() => {
                    this.changeSection(
                      categories,
                      'sectionOne',
                      'sectionTwo',
                      index,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_downward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconDown'}
                  />
                </a>
              </div>
            </div>
          );
        } else if (section === 'sectionTwo') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'up',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_upward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconUp'}
                  />
                </a>
                <a
                  onClick={() => {
                    this.changeSection(
                      categories,
                      'sectionTwo',
                      'sectionThree',
                      index,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_downward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconDown'}
                  />
                </a>
              </div>
            </div>
          );
        } else if (section === 'sectionThree') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'up',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_upward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconUp'}
                  />
                </a>
                <MaterialIcon
                  icon="arrow_downward"
                  size={20}
                  color="#d0d0d0"
                />
              </div>
            </div>
          );
        }
      } else {
        return (
          <div
            style={{ width: '100%', display: 'inline-block' }}
            key={categories[index].categoryId + 'icons'}
          >
            <div style={{ float: 'right' }}>
              <a
                onClick={() => {
                  this.changeOrder(
                    categories,
                    categories[index].position - 1,
                    'up',
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_upward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconUp'}
                />
              </a>
            </div>
          </div>
        );
      }
    }
  }

  changeSection(categories, oldSection, newSection, index) {
    let newCategories = this.state.categories;
    let oldCategories = this.state.categories;
    let section = 1;
    if (newSection === 'sectionTwo' && oldSection === 'sectionOne') {
      newCategories[newSection].unshift(
        newCategories[oldSection][index],
      );
      section = 2;
      newCategories[oldSection][index].section = 2;
    } else if (
      newSection === 'sectionTwo' &&
      oldSection === 'sectionThree'
    ) {
      newCategories[newSection].push(
        newCategories[oldSection][index],
      );
      section = 2;
      newCategories[oldSection][index].section = 2;
    } else if (newSection === 'sectionThree') {
      newCategories[newSection].unshift(
        newCategories[oldSection][index],
      );
      section = 3;
      newCategories[oldSection][index].section = 3;
    } else if (newSection === 'sectionOne') {
      newCategories[newSection].push(
        newCategories[oldSection][index],
      );
      section = 1;
      newCategories[oldSection][index].section = 1;
    }
    newCategories[newSection].forEach((category, i) => {
      newCategories[newSection][i].position = i + 1;
    });
    let categoryId = oldCategories[oldSection][index].categoryId;
    newCategories[oldSection].splice(index, 1);
    this.setState({
      [categoryId + 'Section']: section,
      categories: newCategories,
    });
  }

  selectRegionChange = (value, categoryId) => {
    this.setState({ [categoryId + 'SelectRegions']: value });
  };

  changeOrder(categories, from, direction, section) {
    let newCategories = this.state.categories;
    let to = 0;
    if (direction === 'up') {
      to = from - 1;
    }
    if (direction === 'down') {
      to = from + 1;
    }
    let categoriesX = [];
    let tempGroup = [];

    let cleanPositions = [];
    if (newCategories[section][0].position !== 0) {
      newCategories[section].forEach((category, i) => {
        let newCategory = category;
        newCategory.position = i + 1;
        cleanPositions.push(newCategory);
      });
    } else {
      cleanPositions = newCategories[section];
    }

    cleanPositions.forEach((category, i) => {
      categoriesX.push(category);
    });
    var f = categoriesX.splice(from, 1)[0];

    categoriesX.splice(to, 0, f);
    categoriesX.forEach((category, i) => {
      let categoryTemp = {
        title: category.title,
        position: i + 1,
        filters: category.filters,
        categoryId: category.categoryId,
        section: category.section,
      };
      tempGroup.push(categoryTemp);
      newCategories[section] = tempGroup;
    });
    this.setState({
      categories: newCategories,
    });
  }

  getRegions(regions) {
    let selectOptions = [];
    if (regions !== undefined) {
      regions.forEach((region) => {
        selectOptions.push({
          value: region.name,
          label: region.name,
        });
      });
    }
    let sorted = selectOptions.sort((a, b) =>
      a.value.localeCompare(b.value),
    );
    return sorted;
  }

  getCategoryBlock(category, index, section) {
    let regions = this.getRegions(this.props.regions);

    let options = [];
    this.props.tags.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        options.push({
          value: tag.tag + ',' + tagCat.sid,
          label: tag.tag,
        });
      });
    });

    let catId = category.categoryId;
    let tags = category.filters.tags;
    let showTimes = false;
    if (this.state[catId + 'showTimes'] !== undefined) {
      showTimes = this.state[catId + 'showTimes'];
    }

    return (
      <div
        id={catId}
        key={catId + 'block'}
        style={{
          borderBottom: '1px solid #dddddd',
          marginBottom: '10px',
        }}
      >
        <div style={{ width: '100%', height: '30px', clear: 'both' }}>
          <span style={{ fontWeight: '600' }}>
            {this.state[category.categoryId + 'Title']}
          </span>
          <div style={{ float: 'right', marginRight: '10px' }}>
            {this.getDeleteButton(category, index, section)}
          </div>
          <div style={{ float: 'right', marginRight: '10px' }}>
            {this.getVisibleButton(category)}
          </div>
          <div
            id={catId + 'Toggle'}
            key={catId + 'Toggle'}
            className={
              this.state[catId + 'Toggler'] === true
                ? 'collapsed'
                : ''
            }
            data-toggle="collapse"
            style={{ float: 'right', marginRight: '10px' }}
          >
            <MaterialIcon icon="edit" size={25} color="#f21d59" />
          </div>
          <div style={{ float: 'right', marginRight: '10px' }}>
            {this.getMoveButton(
              index,
              this.state.categories[section],
              category,
              section,
            )}
          </div>
        </div>

        <UncontrolledCollapse
          toggler={catId + 'Toggle'}
          defaultOpen={false}
          key={catId + 'collapse'}
          style={{ width: '100%' }}
        >
          <div
            className="criteria"
            style={{
              padding: '10px',
              border: '1px solid',
            }}
            key={catId + 'innerdiv'}
          >
            <div key={catId + 'button'}>
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                }}
              >
                Category Title
              </span>
              <input
                name={
                  this.state.categories[section][index].categoryId +
                  'Title'
                }
                value={
                  this.state[
                    this.state.categories[section][index].categoryId +
                      'Title'
                  ]
                }
                onChange={this.changeText}
                type="text"
                placeholder=""
                key={catId + 'input'}
              />
              {/* {tags.map((tag, index) => (
                <div>{tag.tag}</div>
              ))} */}
              {/* {this.props.tags.map((cattag, index) => (
          <div>{this.getMainCuisineFields(cattag)}</div>
        ))} */}
            </div>
            {/* <div
              onChange={this.onChangeValue}
              style={{ padding: '10px' }}
            >
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                Content
              </span>
              <input
                type="radio"
                value="Locations"
                name={category.categoryId + 'Type'}
                style={{
                  width: 'auto',
                  minWidth: 'auto',
                }}
                checked={
                  this.state[category.categoryId + 'Type'] ===
                  'Locations'
                    ? true
                    : false
                }
              />
              <span
                style={{
                  marginRight: '10px',
                  marginLeft: '5px',
                }}
              >
                Locations
              </span>
              <input
                type="radio"
                value="Entertainers"
                checked={
                  this.state[category.categoryId + 'Type'] ===
                  'Entertainers'
                    ? true
                    : false
                }
                name={category.categoryId + 'Type'}
                style={{
                  width: 'auto',
                  minWidth: 'auto',
                  marginRight: '5px',
                }}
              />
              <span
                style={{
                  marginRight: '10px',
                }}
              >
                Entertainers
              </span>
              <input
                type="radio"
                value="Events"
                name={category.categoryId + 'Type'}
                style={{
                  width: 'auto',
                  minWidth: 'auto',
                }}
                checked={
                  this.state[category.categoryId + 'Type'] ===
                  'Events'
                    ? true
                    : false
                }
              />
              <span
                style={{
                  marginRight: '10px',
                  marginLeft: '5px',
                }}
              >
                Events
              </span>
            </div> */}

            <div style={{ padding: '10px', display: 'inline-block' }}>
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                Content
              </span>

              <span style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state[catId + 'IncludeLocations']}
                  onChange={(value) => {
                    this.onChangeDate(
                      catId,
                      'IncludeLocations',
                      value,
                    );
                  }}
                  className="closedCheckBox"
                  style={{ top: '5px', marginRight: '5px' }}
                />
                <span>Locations</span>
              </span>

              <span style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state[catId + 'IncludeEvents']}
                  onChange={(value) => {
                    this.onChangeDate(catId, 'IncludeEvents', value);
                  }}
                  className="closedCheckBox"
                  style={{ top: '5px', marginRight: '5px' }}
                />
                <span>Events</span>
              </span>
              <span style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state[catId + 'IncludeEntertainers']}
                  onChange={(value) => {
                    this.onChangeDate(
                      catId,
                      'IncludeEntertainers',
                      value,
                    );
                  }}
                  className="closedCheckBox"
                  style={{ top: '5px', marginRight: '5px' }}
                />
                <span>Entertainers</span>
              </span>
              <span style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state[catId + 'IncludeUserRequest']}
                  onChange={(value) => {
                    this.onChangeDate(
                      catId,
                      'IncludeUserRequest',
                      value,
                    );
                  }}
                  className="closedCheckBox"
                  style={{ top: '5px', marginRight: '5px' }}
                />
                <span>User Request</span>
              </span>
            </div>

            <div style={{ padding: '10px' }}>
              {/* {this.state[category.categoryId + 'Type'] ===
                'Events' &&
                this.getEventsCategoryFields(this.props.tags, catId)}

              {this.state[category.categoryId + 'Type'] ===
                'Locations' &&
                this.getCategoryFields(this.props.tags, catId)}

              {this.state[category.categoryId + 'Type'] ===
                'Entertainers' &&
                this.getEntertainersCategoryFields(
                  this.props.tags,
                  catId,
                )} */}

              {this.state[
                category.categoryId + 'IncludeLocations'
              ] === true &&
                this.getCategoryFields(this.props.tags, catId)}

              {this.state[category.categoryId + 'IncludeEvents'] ===
                true &&
                this.getEventsCategoryFields(this.props.tags, catId)}

              {this.state[
                category.categoryId + 'IncludeEntertainers'
              ] === true &&
                this.getEntertainersCategoryFields(
                  this.props.tags,
                  catId,
                )}

              {this.state[
                category.categoryId + 'IncludeUserRequest'
              ] === true && (
                <div>
                  {this.getCategoryFields(this.props.tags, catId)}
                  {this.getEventsCategoryFields(
                    this.props.tags,
                    catId,
                  )}
                  {this.getEntertainersCategoryFields(
                    this.props.tags,
                    catId,
                  )}
                </div>
              )}

              <div
                style={{
                  borderTop: '1px solid silver',
                  padding: '10px 0px 0px 0px',
                }}
              >
                <span
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                    position: 'relative',
                    fontSize: '12px',
                    color: '#a3a3a3',
                  }}
                >
                  DISTANCE SETTINGS
                </span>

                <div
                  style={{
                    clear: 'both',
                    diplay: 'block',
                    minWidth: '300px',
                    marginBottom: '10px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '500',
                      marginBottom: '5px',
                      display: 'block',
                      position: 'relative',
                    }}
                  >
                    Regions
                  </span>
                  <Select
                    value={
                      this.state[
                        category.categoryId + 'SelectedRegions'
                      ]
                    }
                    // closeMenuOnSelect={true}
                    blurInputOnSelect={false}
                    onChange={(e) =>
                      this.selectRegionChange(e, category.categoryId)
                    }
                    options={regions}
                    // onInputChange={(e) =>
                    //   this.moodTextChange(e, 0, 'one')
                    // }
                    isMulti={true}
                    placeholder={'Select Regions'}
                  />
                </div>

                <span
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                    position: 'relative',
                  }}
                >
                  City Names
                </span>
                <input
                  id={
                    this.state[category.categoryId + 'Section'] +
                    '/' +
                    index +
                    '/' +
                    'cities'
                  }
                  name={catId + 'Cities'}
                  value={this.state[catId + 'Cities']}
                  onChange={this.changeText}
                  type="text"
                  placeholder=""
                />
                <span
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                    position: 'relative',
                  }}
                >
                  Zip Codes
                </span>
                <input
                  id={catId + '/ZipCodes'}
                  name={catId + 'ZipCodes'}
                  value={this.state[catId + 'ZipCodes']}
                  onChange={this.changeText}
                  type="text"
                  placeholder=""
                />

                <span
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                    position: 'relative',
                  }}
                >
                  Zones
                </span>
                <input
                  id={catId + '/Zones'}
                  name={catId + 'Zones'}
                  value={this.state[catId + 'Zones']}
                  onChange={this.changeText}
                  type="text"
                  placeholder=""
                />
              </div>

              <div
                style={{
                  borderTop: '1px solid silver',
                  padding: '10px 0px 10px 0px',
                  borderBottom: '1px solid silver',
                }}
              >
                <span
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                    position: 'relative',
                    fontSize: '12px',
                    color: '#a3a3a3',
                  }}
                >
                  FILTER SETTINGS
                </span>
                <Row>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="no-padding"
                  >
                    <span style={{ float: 'left' }}>
                      <input
                        type="checkbox"
                        checked={this.state[catId + 'Ignore']}
                        onChange={(value) => {
                          this.onChangeDate(catId, 'Ignore', value);
                        }}
                        className="closedCheckBox"
                        style={{ top: '5px' }}
                      />
                      <span>Ignore User Filters</span>
                    </span>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="no-padding"
                  >
                    <span style={{ float: 'left' }}>
                      <input
                        type="checkbox"
                        checked={
                          this.state[catId + 'IgnoreRegionFilters']
                        }
                        onChange={(value) => {
                          this.onChangeDate(
                            catId,
                            'IgnoreRegionFilters',
                            value,
                          );
                        }}
                        className="closedCheckBox"
                        style={{ top: '5px' }}
                      />
                      <span>Ignore User Regions</span>
                    </span>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="no-padding"
                  >
                    <span style={{ float: 'left' }}>
                      <input
                        type="checkbox"
                        checked={
                          this.state[catId + 'IncludeNearbyRegions']
                        }
                        onChange={(value) => {
                          this.onChangeDate(
                            catId,
                            'IncludeNearbyRegions',
                            value,
                          );
                        }}
                        className="closedCheckBox"
                        style={{ top: '5px' }}
                      />
                      <span>Include Nearby Regions</span>
                    </span>
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  padding: '10px 0px 10px 0px',
                }}
              >
                {showTimes === false && (
                  <div>
                    <span
                      onClick={() => {
                        this.showTimes(catId);
                      }}
                      style={{ color: 'rgb(242, 29, 89)' }}
                    >
                      Show Time Options
                    </span>
                  </div>
                )}
                {showTimes === true && (
                  <div
                    style={{
                      padding: '10px',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '500',
                        marginBottom: '5px',
                        display: 'block',
                        position: 'relative',
                      }}
                    >
                      Time Options
                    </span>
                    <Container>
                      <Row>
                        <Col
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="no-padding"
                        >
                          <span
                            className="inputLabel"
                            style={{ float: 'left' }}
                          >
                            Sun
                          </span>
                          <input
                            type="checkbox"
                            checked={this.state[catId + 'Sunday']}
                            onChange={(value) => {
                              this.onChangeDate(
                                catId,
                                'Sunday',
                                value,
                              );
                            }}
                            className="closedCheckBox"
                            style={{ float: 'none' }}
                          />
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="no-padding"
                        >
                          <span
                            className="inputLabel"
                            style={{ float: 'left' }}
                          >
                            Mon
                          </span>
                          <input
                            type="checkbox"
                            checked={this.state[catId + 'Monday']}
                            onChange={(value) => {
                              this.onChangeDate(
                                catId,
                                'Monday',
                                value,
                              );
                            }}
                            className="closedCheckBox"
                            style={{ float: 'none' }}
                          />
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="no-padding"
                        >
                          <span
                            className="inputLabel"
                            style={{ float: 'left' }}
                          >
                            Tues
                          </span>
                          <input
                            type="checkbox"
                            checked={this.state[catId + 'Tuesday']}
                            onChange={(value) => {
                              this.onChangeDate(
                                catId,
                                'Tuesday',
                                value,
                              );
                            }}
                            className="closedCheckBox"
                            style={{ float: 'none' }}
                          />
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="no-padding"
                        >
                          <span
                            className="inputLabel"
                            style={{ float: 'left' }}
                          >
                            Wed
                          </span>
                          <input
                            type="checkbox"
                            checked={this.state[catId + 'Wednesday']}
                            onChange={(value) => {
                              this.onChangeDate(
                                catId,
                                'Wednesday',
                                value,
                              );
                            }}
                            className="closedCheckBox"
                            style={{ float: 'none' }}
                          />
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="no-padding"
                        >
                          <span
                            className="inputLabel"
                            style={{ float: 'left' }}
                          >
                            Thurs
                          </span>
                          <input
                            type="checkbox"
                            checked={this.state[catId + 'Thursday']}
                            onChange={(value) => {
                              this.onChangeDate(
                                catId,
                                'Thursday',
                                value,
                              );
                            }}
                            className="closedCheckBox"
                            style={{ float: 'none' }}
                          />
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="no-padding"
                        >
                          <span
                            className="inputLabel"
                            style={{ float: 'left' }}
                          >
                            Fri
                          </span>
                          <input
                            type="checkbox"
                            checked={this.state[catId + 'Friday']}
                            onChange={(value) => {
                              this.onChangeDate(
                                catId,
                                'Friday',
                                value,
                              );
                            }}
                            className="closedCheckBox"
                            style={{ float: 'none' }}
                          />
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="no-padding"
                        >
                          <span
                            className="inputLabel"
                            style={{ float: 'left' }}
                          >
                            Sat
                          </span>
                          <input
                            type="checkbox"
                            checked={this.state[catId + 'Saturday']}
                            onChange={(value) => {
                              this.onChangeDate(
                                catId,
                                'Saturday',
                                value,
                              );
                            }}
                            className="closedCheckBox"
                            style={{ float: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <div>
                          <span
                            className="inputLabel"
                            style={{
                              clear: 'both',
                              display: 'block',
                            }}
                          >
                            From
                          </span>
                          <input
                            name={catId + 'FromTime'}
                            value={this.state[catId + 'FromTime']}
                            onChange={(value) => {
                              this.fromTimeChange(
                                catId,
                                'FromTime',
                                value,
                              );
                            }}
                            type="time"
                            placeholder=""
                            // min="09:00"
                            // max="18:00"
                            style={{
                              float: 'left',
                              minWidth: '40px',
                            }}
                          />
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                          <span
                            className="inputLabel"
                            style={{
                              clear: 'both',
                              display: 'block',
                            }}
                          >
                            To
                          </span>
                          <input
                            name={catId + 'ToTime'}
                            value={this.state[catId + 'ToTime']}
                            onChange={(value) => {
                              this.fromTimeChange(
                                catId,
                                'ToTime',
                                value,
                              );
                            }}
                            type="time"
                            placeholder=""
                            // min="09:00"
                            // max="18:00"
                            style={{
                              float: 'left',
                              minWidth: '40px',
                            }}
                          />
                        </div>
                      </Row>
                    </Container>
                  </div>
                )}
              </div>

              <div>
                <span
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                    position: 'relative',
                  }}
                >
                  Boosters
                </span>
                <Select
                  value={this.state[catId + 'Boosters']}
                  onChange={(value) => {
                    this.handleOnChange(catId + 'Boosters', value);
                  }}
                  options={options}
                  isMulti={true}
                />
              </div>

              <div>
                <span
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                    position: 'relative',
                  }}
                >
                  Reducers
                </span>
                <Select
                  value={this.state[catId + 'Reducers']}
                  onChange={(value) => {
                    this.handleOnChange(catId + 'Reducers', value);
                  }}
                  options={options}
                  isMulti={true}
                />
              </div>

              <div>
                <span
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                    position: 'relative',
                  }}
                >
                  Required
                </span>
                <Select
                  value={this.state[catId + 'Required']}
                  onChange={(value) => {
                    this.handleOnChange(catId + 'Required', value);
                  }}
                  options={options}
                  isMulti={true}
                />
              </div>
            </div>
          </div>
        </UncontrolledCollapse>
      </div>
    );
  }

  onChangeDate = (catId, day, value) => {
    let combo = catId + day;
    this.setState({
      [combo]: value.target.checked,
    });
  };

  fromTimeChange = (catId, day, value) => {
    let combo = catId + day;
    this.setState({
      [combo]: value.target.value,
    });
  };

  // getZoneIds(locations) {
  //   let zoneIds = [];
  //   let selectOptions = [];
  //   locations.forEach((location, indexA) => {
  //     let status = zoneIds.includes(location.zoneId);
  //     if (status === false) {
  //       selectOptions.push({
  //         value: location.zoneId,
  //         label: location.zoneId + ' - ' + location.address.state,
  //       });
  //       zoneIds.push(location.zoneId);
  //     }
  //   });
  //   let sorted = selectOptions.sort(function (a, b) {
  //     return a.value - b.value;
  //   });
  //   return sorted;
  // }

  getZoneIds() {
    let selectOptions = [
      {
        value: 1,
        label: 1 + ' - NE',
      },
      {
        value: 2,
        label: 2 + ' - CO',
      },
      {
        value: 3,
        label: 3 + ' - LA',
      },
      {
        value: 4,
        label: 4 + ' - PA',
      },
      {
        value: 5,
        label: 5 + ' - CA',
      },
      {
        value: 6,
        label: 6 + ' - OK',
      },
      {
        value: 9,
        label: 9 + ' - DC',
      },
      {
        value: 10,
        label: 10 + ' - KC',
      },
      {
        value: 11,
        label: 11 + ' - MO',
      },
      {
        value: 27,
        label: 27 + ' - MI',
      },
    ];

    let sorted = selectOptions.sort(function (a, b) {
      return a.value - b.value;
    });
    return sorted;
  }

  selectChange = (values) => {
    this.setState({
      zoneFilter: values.value,
      selectInput: {
        label: values.label,
        value: values.value,
      },
    });
  };

  clearZone = () => {
    this.setState({
      zoneFilter: null,
      selectInput: {
        label: '',
        value: '',
      },
    });
  };

  showTimes = (catId) => {
    console.log('guard', catId);
    this.setState({
      [catId + 'showTimes']: true,
    });
  };

  getForm(sectionOne, sectionTwo, sectionThree) {
    let sectionOneCleanPositions = [];
    let sectionTwoCleanPositions = [];
    let sectionThreeCleanPositions = [];

    if (sectionOne[0].position !== 0) {
      sectionOne.forEach((category, i) => {
        let newCategory = category;
        newCategory.position = i + 1;
        sectionOneCleanPositions.push(newCategory);
      });
    } else {
      sectionOneCleanPositions = sectionOne;
    }

    if (sectionTwo[0].position !== 0) {
      sectionTwo.forEach((category, i) => {
        let newCategory = category;
        newCategory.position = i + 1;
        sectionTwoCleanPositions.push(newCategory);
      });
    } else {
      sectionTwoCleanPositions = sectionOne;
    }

    if (sectionThree[0].position !== 0) {
      sectionThree.forEach((category, i) => {
        let newCategory = category;
        newCategory.position = i + 1;
        sectionThreeCleanPositions.push(newCategory);
      });
    } else {
      sectionThreeCleanPositions = sectionOne;
    }

    let filterSectionOne = [];
    let filterSectionTwo = [];
    let filterSectionThree = [];

    sectionOneCleanPositions.forEach((category) => {
      if (
        category.filters.zones.length !== 0 &&
        category.filters.zones[0] !== '' &&
        this.state.zoneFilter !== null
      ) {
        category.filters.zones.forEach((zone) => {
          var b = parseInt(zone);

          if (b === this.state.zoneFilter) {
            filterSectionOne.push(category);
          }
        });
      } else {
        // console.log('bya');
        filterSectionOne.push(category);
      }
    });
    sectionTwoCleanPositions.forEach((category) => {
      if (
        category.filters.zones.length !== 0 &&
        category.filters.zones[0] !== '' &&
        this.state.zoneFilter !== null
      ) {
        category.filters.zones.forEach((zone) => {
          var b = parseInt(zone);
          if (b === this.state.zoneFilter) {
            filterSectionTwo.push(category);
          }
        });
      } else {
        filterSectionTwo.push(category);
      }
    });
    sectionThreeCleanPositions.forEach((category, index) => {
      console.log(
        'sectionThreeCleanPositions',
        sectionThreeCleanPositions,
      );
      if (
        category.filters.zones.length !== 0 &&
        category.filters.zones[0] !== '' &&
        this.state.zoneFilter !== null
      ) {
        category.filters.zones.forEach((zone) => {
          var b = parseInt(zone);
          if (b === this.state.zoneFilter) {
            filterSectionThree.push(category);
          }
        });
      } else {
        filterSectionThree.push(category);
      }
    });

    let selectOptions = this.getZoneIds(this.props.alllocations);
    return (
      <div key="form">
        <Formik
          enableReinitialize
          initialValues={{
            title: this.state.title,
          }}
          validate={(values) => {
            let errors = {};
            if (!values.title) {
              errors.title = 'Required';
            }
            return errors;
          }}
          onSubmit={(values) => {
            this.saveTemplate(this.state.id, values);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Container>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="left"
                  >
                    <span className="inputLabel">Title</span>
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="error-message"
                    />
                    <Field
                      type="textfield"
                      name="title"
                      placeholder="Template Title"
                    />
                    <div
                      style={{
                        width: '250px',
                        float: 'left',
                        marginRight: '20px',
                      }}
                    >
                      <span
                        style={{
                          float: 'left',
                          marginRight: '10px',
                          display: 'block',
                          top: '10px',
                          fontWeight: '500',
                          position: 'relative',
                        }}
                      >
                        Zone Id
                      </span>
                      <Select
                        value={this.state.selectInput}
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectChange(e, 0, 'one')
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'Filter By ZoneId'}
                      />
                    </div>
                    <a
                      onClick={(e) => this.clearZone()}
                      style={{
                        float: 'left',
                        position: 'relative',
                        top: '10px',
                      }}
                    >
                      <span>Clear</span>
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="left"
                    style={{ marginTop: '30px' }}
                  >
                    <h6 style={{ color: '#ed0259' }}>Section 1</h6>
                    {filterSectionOne.map((category, index) => (
                      <div key={category.categoryId + 'wrapper'}>
                        {this.getCategoryBlock(
                          category,
                          index,
                          'sectionOne',
                        )}
                      </div>
                    ))}
                    <h6
                      style={{ marginTop: '40px', color: '#ed0259' }}
                    >
                      Section 2
                    </h6>
                    {filterSectionTwo.map((category, index) => (
                      <div key={category.categoryId + 'wrapper'}>
                        {this.getCategoryBlock(
                          category,
                          index,
                          'sectionTwo',
                        )}
                      </div>
                    ))}
                    <h6
                      style={{ marginTop: '40px', color: '#ed0259' }}
                    >
                      Section 3
                    </h6>
                    {filterSectionThree.map((category, index) => (
                      <div key={category.categoryId + 'wrapper'}>
                        {this.getCategoryBlock(
                          category,
                          index,
                          'sectionThree',
                        )}
                      </div>
                    ))}
                  </Col>
                </Row>
              </Container>

              <button
                type="submit"
                className="redbutton right"
                // disabled={isSubmitting}
                style={{ marginLeft: '10px' }}
              >
                Save
              </button>

              <span
                className="redbutton right"
                onClick={() => {
                  this.addCategory();
                }}
              >
                Add Category
              </span>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  render() {
    let categories = this.state.categories;
    if (this.state.loading) {
      return null;
    }
    return (
      <div>
        <TitleBar title="Edit Template" backlink="/admin/templates" />
        <div className="section">
          <Container>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12}>
                {this.getForm(
                  categories.sectionOne,
                  categories.sectionTwo,
                  categories.sectionThree,
                )}
              </Col>
            </Row>
          </Container>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.backbar{background: #313131 !important} [data-toggle="collapse"]:after{display: none} .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGuideTemplates: () => {
      dispatch(getGuideTemplates());
    },
  };
};

function mapStateToProps(state) {
  return {
    guideTemplates: state.guideTemplates.templates,
    tags: state.tags.tags,
    alllocations: state.alllocations.locations,
    user: state.user,
    profile: state.profile.profile,
    regions: state.regions.regions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminTemplateEdit);
