import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

class GuideEntertainerCategory extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {}

  findPromotedLocations(locations, category, tagCat) {
    let setTag = undefined;

    // Combine Tags

    function getPromoted(locations) {
      let promotedLocations = [];
      let promotedLocationIds = [];

      if (locations !== undefined) {
        locations.forEach((location) => {
          if (tagCat === 'tagsEntertainmentType') {
            if (
              location.tagsEntertainmentType !== undefined &&
              location.tagsEntertainmentType.length !== 0
            ) {
              location.tagsEntertainmentType.forEach((tagx) => {
                if (tagx === category) {
                  const status = promotedLocationIds.includes(
                    location.entertainmentId,
                  );
                  if (status === false) {
                    promotedLocations.push(location);
                  }
                }
              });
            }
          }
        });
      }

      return promotedLocations;
    }

    let promotedLocations = [];
    let i = 0;

    while (promotedLocations.length === 0 && i !== 10) {
      promotedLocations = getPromoted(locations);
      i++;
    }

    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }

    let shuffledLocations = shuffleArray(promotedLocations);

    let topThree = [];
    let topThreeIds = [];

    shuffledLocations.forEach((location) => {
      if (topThree.length !== 10) {
        if (tagCat === 'tagsEntertainmentType') {
          const status = topThreeIds.includes(
            location.entertainmentId,
          );
          if (status === false) {
            topThreeIds.push(location.entertainmentId);
            topThree.push(location);
          }
        }
      }
    });
    let seeMore = false;
    if (shuffledLocations.length > 10) {
      seeMore = true;
    }
    let bundle = {
      promotedLocations: topThree,
      setTag,
      seeMore,
    };
    return bundle;
  }

  getTagCount(userLove) {
    let userTags = [];
    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };
    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };
    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateType',
      tags: [],
    };
    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisineType',
      tags: [],
    };
    let allTags = {
      tagsBeverages: [],
      tagsDateType: [],
      tagsSetting: [],
      tagsCuisine: [],
    };
    // Combine Tags

    userLove.tagsBeverages.forEach((tagcat, x) => {
      let n = allTags.tagsBeverages.includes(tagcat);
      if (n === false) {
        allTags.tagsBeverages.push(tagcat);
      }
    });

    userLove.tagsDateType.forEach((tagcat, x) => {
      let n = allTags.tagsDateType.includes(tagcat);
      if (n === false) {
        allTags.tagsDateType.push(tagcat);
      }
    });

    userLove.tagsSetting.forEach((tagcat, x) => {
      let n = allTags.tagsSetting.includes(tagcat);
      if (n === false) {
        allTags.tagsSetting.push(tagcat);
      }
    });

    userLove.tagsCuisine.forEach((tagcat, x) => {
      let n = allTags.tagsCuisine.includes(tagcat);
      if (n === false) {
        allTags.tagsCuisine.push(tagcat);
      }
    });

    let tagCount =
      allTags.tagsCuisine.length +
      allTags.tagsSetting.length +
      allTags.tagsDateType.length +
      allTags.tagsBeverages.length;
    allTags.tagsSetting;

    return tagCount;
  }

  getImage(type, location) {
    let hero = '';
    let thumb = '';
    if (
      this.props.tagCat === 'tagsGenre' ||
      this.props.tagCat === 'tagsEventType'
    ) {
      hero = location.eventHero;
      thumb = location.eventThumb;
    } else {
      hero = location.locationHero;
      thumb = location.locationThumb;
    }
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = hero;
      } else {
        if (location !== undefined && hero !== undefined) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            hero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = thumb;
      } else {
        if (location !== undefined && thumb !== undefined) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            thumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  getSingle(promotedLocations) {
    let location = promotedLocations[0];
    if (promotedLocations.length === 1) {
      return (
        <div>
          <Link
            to={{
              pathname: '/entertainment/' + location.slug,
              state: {
                location: { location },
                // eventId: { eventId },
                // token: { token },
                type: 500,
              },
            }}
          >
            <div>
              <img
                src={location.entertainmentHero}
                style={{ width: '100%' }}
              />
              <div className="section">
                <span className="title">
                  {location.entertainmentTitle}
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: location.entertainmentDescription,
                  }}
                  className="editoOverRide"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    return to;
  }

  getSlideSections(promotedLocations, count) {
    let sections = [];
    let sectionOne = [];
    let sectionTwo = [];
    let sectionThree = [];
    let sectionFour = [];
    let sectionFive = [];
    let sectionSix = [];
    let sectionSeven = [];
    let sectionEight = [];
    let sectionNine = [];
    let sectionTen = [];

    if (count === 4) {
      promotedLocations.forEach((location, index) => {
        if (index < 3 || index === 3) {
          sectionOne.push(location);
        }
        if (index > 3 && index < 8) {
          sectionTwo.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
    }

    if (count === 3) {
      promotedLocations.forEach((location, index) => {
        if (index < 2 || index === 2) {
          sectionOne.push(location);
        }
        if (index > 2 && index < 6) {
          sectionTwo.push(location);
        }
        if (index > 6 && index < 9) {
          sectionThree.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
    }

    if (count === 2) {
      promotedLocations.forEach((location, index) => {
        if (index === 0) {
          sectionOne.push(location);
        }
        if (index === 1) {
          sectionTwo.push(location);
        }
        if (index === 2) {
          sectionThree.push(location);
        }
        if (index === 3) {
          sectionFour.push(location);
        }
        if (index === 4) {
          sectionFive.push(location);
        }
        if (index === 5) {
          sectionSix.push(location);
        }
        if (index === 6) {
          sectionSeven.push(location);
        }
        if (index === 7) {
          sectionEight.push(location);
        }
        if (index === 8) {
          sectionNine.push(location);
        }
        if (index === 9) {
          sectionTen.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
      if (sectionFour.length !== 0) {
        sections.push(sectionFour);
      }
      if (sectionFive.length !== 0) {
        sections.push(sectionFive);
      }
      if (sectionSix.length !== 0) {
        sections.push(sectionSix);
      }
      if (sectionEight.length !== 0) {
        sections.push(sectionEight);
      }
      if (sectionSeven.length !== 0) {
        sections.push(sectionSeven);
      }
      if (sectionNine.length !== 0) {
        sections.push(sectionNine);
      }
      if (sectionTen.length !== 0) {
        sections.push(sectionTen);
      }
    }
    if (
      this.props.tagCat !== 'tagsGenre' &&
      this.props.tagCat !== 'tagsEventType'
    ) {
      return sections.map((section, index) => {
        return (
          <div style={{ height: '100%' }}>
            {section.map((location, index) => (
              <div
                style={{
                  border: '0px solid rgba(0, 0, 0, 0.125)',
                  background: 'white !important',
                }}
                className={count === 2 ? 'singleSlide' : 'multiSlide'}
              >
                <Link
                  to={{
                    pathname: '/entertainment/' + location.slug,
                    state: {
                      location: { location },
                      // eventId: { eventId },
                      // token: { token },
                      type: 500,
                    },
                  }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={
                      location.entertainmentHero !== ''
                        ? location.entertainmentHero
                        : 'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgenericmusichero.png?alt=media&token=7462811e-567a-4585-9c48-97882b9270e2'
                    }
                    alt="articleHero"
                  />
                  <CardBody style={{ textAlign: 'left' }}>
                    <div
                      style={{ paddingTop: '10px', border: '0px' }}
                    >
                      <span className="title">
                        {location.entertainmentTitle}
                      </span>
                      {this.getMainTag(location)}
                      <CardText>
                        <span className="editoOverRide">
                          {this.truncate(
                            location.entertainmentDescription,
                          )}
                        </span>
                      </CardText>
                      <CardText />
                    </div>
                  </CardBody>
                </Link>
              </div>
            ))}
          </div>
        );
      });
    } else {
      return sections.map((section, index) => {
        return (
          <div>
            {section.map((location, index) => (
              <div
                style={{
                  border: '0px solid rgba(0, 0, 0, 0.125)',
                  background: 'white !important',
                }}
                className={count === 2 ? 'singleSlide' : 'multiSlide'}
              >
                <Link
                  to={{
                    pathname:
                      '/timedevent/' + location.entertainmentId,
                    state: {
                      location: { location },
                      // eventId: { eventId },
                      // token: { token },
                      type: 500,
                    },
                  }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={
                      location.entertainmentHero !== ''
                        ? location.entertainmentHero
                        : 'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgenericmusichero.png?alt=media&token=7462811e-567a-4585-9c48-97882b9270e2'
                    }
                    alt="articleHero"
                  />
                  <CardBody style={{ textAlign: 'left' }}>
                    <div
                      style={{ paddingTop: '10px', border: '0px' }}
                    >
                      <span className="title">
                        {location.entertainmentTitle}
                      </span>
                      {/* <span className="address">
                        {location.bandName} - @{' '}
                        {location.address.locationName}
                      </span>

                      <span className="address">
                        {this.getDate(location)}
                      </span> */}
                      {/* {this.getMainTag(location)} */}
                      {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                      <CardText>
                        <span className="editoOverRide">
                          {this.truncate(
                            location.entertainmentDescription,
                          )}
                        </span>
                      </CardText>
                      <CardText />
                    </div>
                  </CardBody>
                </Link>
              </div>
            ))}
          </div>
        );
      });
    }
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span className="mainTagClear">{location.tagsMain}</span>
      );
    }
  }

  truncate(str) {
    if (str !== undefined) {
      let regex = /(<([^>]+)>)/gi;
      let result = str.replace(regex, '');
      let final = result.replace(/&amp;/g, '&');
      return final.length > 100
        ? final.substring(0, 100) + '...'
        : final;
    } else {
      return '';
    }
  }

  render() {
    let bundle = [];

    let entertainment = [];
    if (this.props.entertainment !== undefined) {
      entertainment = this.props.entertainment;
    }

    bundle = this.findPromotedLocations(
      entertainment,
      this.props.category,
      this.props.tagCat,
    );

    let promotedLocations = bundle.promotedLocations;

    let slug = '';
    if (this.props.category !== undefined) {
      slug = this.props.category.replace(/\s/g, '-');
    }

    let count = this.props.count;

    if (promotedLocations.length > 0) {
      return (
        // <div className="homeSection" id="homeSectionGrey">
        <div className="homeSection">
          <div
            style={{
              height:
                count > 2 || bundle.seeMore === false
                  ? '40px'
                  : '80px',
              marginBottom:
                count > 2 || bundle.seeMore === false
                  ? '10px'
                  : '10px',
            }}
          >
            <Row
              className="no-margin"
              style={{
                padding: count > 2 ? '0x' : '10px',
              }}
            >
              <Col
                xs={12}
                sm={12}
                md={10}
                lg={10}
                className="no-padding"
              >
                <div className="sectiontitle text-center">
                  <span
                    className="homeCat "
                    style={{
                      float: count > 2 ? 'left' : 'left',
                      marginBottom: count > 2 ? '10px' : '',
                      textAlign: count > 2 ? 'left' : 'left',
                    }}
                  >
                    {this.props.catTitle}
                  </span>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                className="no-padding"
              >
                {bundle.seeMore === true && (
                  <div
                    style={{
                      zindex: '99999',
                      textAlign: count > 2 ? 'left' : 'left',
                    }}
                  >
                    <Link
                      to={{
                        pathname: '/guide/category/' + slug,
                      }}
                      className={count > 2 ? 'right' : 'seeMoreLeft'}
                    >
                      See More
                    </Link>
                  </div>
                )}
              </Col>
            </Row>
          </div>
          {promotedLocations.length > 0 && (
            <Carousel
              showThumbs={false}
              // centerMode={true}
              // centerSlidePercentage={80}
              // selectedItem={this.getCount(promotedLocations)}
              showIndicators={false}
              showStatus={false}
              // showArrows={true}
              infiniteLoop={false}
              centerMode={count === 2 ? true : false}
              swipeScrollTolerance={50}
              preventMovementUntilSwipeScrollTolerance={true}
            >
              {this.getSlideSections(promotedLocations, count)}
            </Carousel>
          )}
        </div>
      );
    }
    return null; // or render a loading icon
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
  };
}

export default connect(mapStateToProps)(GuideEntertainerCategory);
