import React, { Component } from 'react';
import MaterialIcon from 'material-icons-react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getConnectionsTagsAndFavs } from '../../sagas/connectionsTagsAndFavsSaga/reducer';

class DailyContactLocations extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  UNSAFE_componentWillMount() {
    const promotedLocations = this.findPromotedLocations(
      this.props.locations,
    );
    this.setState({
      promotedLocations: promotedLocations,
      loading: false,
    });

    let connections = [];
    if (Object.keys(this.props.connections).length !== 0) {
      Object.keys(this.props.connections).forEach((key) => {
        if (
          this.props.connections[key].favorite !== undefined &&
          this.props.connections[key].favorite === true
        ) {
          connections.push(this.props.connections[key]);
        }
      });
    }

    if (connections.length !== 0) {
      this.getConnectionTagsandFavs(connections[0].userId);
    }
  }

  getConnectionCount(userConnections) {
    let connections = [];
    if (Object.keys(userConnections).length !== 0) {
      Object.keys(userConnections).forEach((key) => {
        if (
          userConnections[key].favorite !== undefined &&
          userConnections[key].favorite === true
        ) {
          connections.push(userConnections[key]);
        }
      });
    }

    return connections.length;
  }

  getConnectionTagsandFavs(userId) {
    let bundle = {
      inviteId: userId,
      locations: this.props.locations,
      homePage: true,
    };

    this.props.getConnectionsTagsAndFavs(bundle);
  }

  findPromotedLocations(locations) {
    const promotedLocations = [];
    let userLove = {};
    userLove.tagsBeverages = [];
    userLove.tagsDateType = [];
    userLove.tagsCuisine = [];
    userLove.tagsSetting = [];
    if (this.props.connectionsTagsAndFavs[0] !== undefined) {
      userLove = this.props.connectionsTagsAndFavs[0].tags.love;
    }

    let userTags = [];
    let connectionsTags = [];

    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };

    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };

    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };

    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };

    let allTags = {
      tagsBeverages: [],
      tagsDateType: [],
      tagsSetting: [],
      tagsCuisine: [],
    };
    // Combine Tags

    userLove.tagsBeverages.forEach((tagcat, x) => {
      let n = allTags.tagsBeverages.includes(tagcat);
      if (n === false) {
        allTags.tagsBeverages.push(tagcat);
      }
    });

    userLove.tagsDateType.forEach((tagcat, x) => {
      let n = allTags.tagsDateType.includes(tagcat);
      if (n === false) {
        allTags.tagsDateType.push(tagcat);
      }
    });

    userLove.tagsSetting.forEach((tagcat, x) => {
      let n = allTags.tagsSetting.includes(tagcat);
      if (n === false) {
        allTags.tagsSetting.push(tagcat);
      }
    });

    userLove.tagsCuisine.forEach((tagcat, x) => {
      let n = allTags.tagsCuisine.includes(tagcat);
      if (n === false) {
        allTags.tagsCuisine.push(tagcat);
      }
    });

    let randomNum = Math.floor(Math.random() * 2);

    if (randomNum === 1) {
      if (allTags.tagsBeverages.length !== 0) {
        let randomTagNum = Math.floor(
          Math.random() * allTags.tagsBeverages.length,
        );
        this.setState({
          setTag: allTags.tagsBeverages[randomTagNum],
        });

        allTags.tagsBeverages.forEach((tag) => {
          locations.forEach((location) => {
            if (location.tagsBeverages.length !== 0) {
              location.tagsBeverages.forEach((tagx) => {
                if (tagx === allTags.tagsBeverages[randomTagNum]) {
                  promotedLocations.push(location);
                }
              });
            }
          });
        });
      }
    } else if (randomNum === 0) {
      if (allTags.tagsCuisine.length !== 0) {
        let randomTagNum = Math.floor(
          Math.random() * allTags.tagsCuisine.length,
        );
        this.setState({
          setTag: allTags.tagsCuisine[randomTagNum],
        });
        let promotedLocationIds = [];
        allTags.tagsCuisine.forEach((tag) => {
          locations.forEach((location) => {
            if (
              location.tagsCuisine !== undefined &&
              location.tagsCuisine.length !== 0
            ) {
              location.tagsCuisine.forEach((tagx) => {
                if (tagx === allTags.tagsCuisine[randomTagNum]) {
                  const status = promotedLocationIds.includes(
                    location.locationId,
                  );
                  if (status === false) {
                    promotedLocationIds.push(location.locationId);
                    promotedLocations.push(location);
                  }
                }
              });
            }
          });
        });
      }
    }

    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
    let shuffledLocations = shuffleArray(promotedLocations);
    let topThree = [];
    let topThreeIds = [];
    shuffledLocations.forEach((location) => {
      if (topThree.length !== 10) {
        const status = topThreeIds.includes(location.locationId);
        if (status === false) {
          topThreeIds.push(location.locationId);
          topThree.push(location);
        }
      }
    });

    return topThree;
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getSingle(promotedLocations) {
    let location = promotedLocations[0];
    if (promotedLocations.length === 1) {
      return (
        <div>
          <Link
            to={{
              pathname: '/location/' + location.locationId,
              state: {
                location: { location },
                // eventId: { eventId },
                // token: { token },
                type: 500,
              },
            }}
          >
            <div>
              <img
                src={this.getImage(
                  'hero',
                  this.state.promotedLocations[0],
                )}
                style={{ width: '100%' }}
              />
              <div className="section">
                <span className="title">
                  {this.state.promotedLocations[0].locationTitle}
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.promotedLocations[0]
                        .locationDescription,
                  }}
                  className="editoOverRide"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getSlideSections(promotedLocations, count) {
    let sections = [];
    let sectionOne = [];
    let sectionTwo = [];
    let sectionThree = [];
    let sectionFour = [];
    let sectionFive = [];
    let sectionSix = [];
    let sectionSeven = [];
    let sectionEight = [];
    let sectionNine = [];
    let sectionTen = [];

    if (count === 4) {
      promotedLocations.forEach((location, index) => {
        if (index < 3 || index === 3) {
          sectionOne.push(location);
        }
        if (index > 3 && index < 8) {
          sectionTwo.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
    }

    if (count === 3) {
      promotedLocations.forEach((location, index) => {
        if (index < 2 || index === 2) {
          sectionOne.push(location);
        }
        if (index > 2 && index < 6) {
          sectionTwo.push(location);
        }
        if (index > 6 && index < 9) {
          sectionThree.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
    }

    if (count === 2) {
      promotedLocations.forEach((location, index) => {
        if (index === 0) {
          sectionOne.push(location);
        }
        if (index === 1) {
          sectionTwo.push(location);
        }
        if (index === 2) {
          sectionThree.push(location);
        }
        if (index === 3) {
          sectionFour.push(location);
        }
        if (index === 4) {
          sectionFive.push(location);
        }
        if (index === 5) {
          sectionSix.push(location);
        }
        if (index === 6) {
          sectionSeven.push(location);
        }
        if (index === 7) {
          sectionEight.push(location);
        }
        if (index === 8) {
          sectionNine.push(location);
        }
        if (index === 9) {
          sectionTen.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
      if (sectionFour.length !== 0) {
        sections.push(sectionFour);
      }
      if (sectionFive.length !== 0) {
        sections.push(sectionFive);
      }
      if (sectionSix.length !== 0) {
        sections.push(sectionSix);
      }
      if (sectionEight.length !== 0) {
        sections.push(sectionEight);
      }
      if (sectionSeven.length !== 0) {
        sections.push(sectionSeven);
      }
      if (sectionNine.length !== 0) {
        sections.push(sectionNine);
      }
      if (sectionTen.length !== 0) {
        sections.push(sectionTen);
      }
    }
    return sections.map((section, index) => {
      return (
        <div style={{ height: '100%' }}>
          {section.map((location, index) => (
            <div
              style={{
                border: '0px solid rgba(0, 0, 0, 0.125)',
                background: 'white !important',
              }}
              className={count === 2 ? 'singleSlide' : 'multiSlide'}
            >
              <Link
                to={{
                  pathname: '/location/' + location.locationId,
                  state: {
                    location: { location },
                    // eventId: { eventId },
                    // token: { token },
                    type: 500,
                  },
                }}
              >
                <CardImg
                  top
                  width="100%"
                  src={this.getImage('hero', location)}
                  alt="articleHero"
                />
                <CardBody style={{ textAlign: 'left' }}>
                  <div style={{ paddingTop: '10px', border: '0px' }}>
                    <span className="title">
                      {location.locationTitle}
                    </span>
                    {this.getMainTag(location)}
                    {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                    <CardText>
                      <span className="editoOverRide">
                        {this.truncate(location.locationDescription)}
                      </span>
                    </CardText>
                    <CardText />
                  </div>
                </CardBody>
              </Link>
            </div>
          ))}
        </div>
      );
    });
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span className="mainTagClear">{location.tagsMain}</span>
      );
    }
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  render() {
    let count = this.props.count;

    let connectionCount = this.getConnectionCount(
      this.props.connections,
    );
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    if (connectionCount === 0) {
      return (
        // <div className="homeSection">
        //   <div
        //     className="sectiontitle text-center"
        //     style={{
        //       borderTop: '1px solid #313131',
        //     }}
        //   >
        //     <CardBody>
        //       <span className="homeCatCenter">
        //         Customize Your Guide
        //       </span>
        //       <span>
        //         Favorite a contact to see places they will love!
        //       </span>
        //       <Link
        //         to={{
        //           pathname: '/connections',
        //           state: {},
        //         }}
        //       >
        //         <span
        //           className="solidredbutton"
        //           style={{ maxWidth: '150px', marginTop: '10px' }}
        //         >
        //           Connections
        //         </span>
        //       </Link>
        //     </CardBody>
        //   </div>
        // </div>
        <div>
          <div
            className="announcementBox mobileHide"
            style={{ marginTop: '30px' }}
          >
            <Link
              to={{
                pathname: '/connections',
              }}
              style={{ color: '#303030' }}
            >
              <h4 style={{ fontSize: '20px' }}>
                Tip: Select a{' '}
                <span
                  style={{
                    marginTop: '0px',
                    top: '5px',
                    display: 'inline-block',
                    position: 'relative',
                  }}
                >
                  <MaterialIcon
                    icon="favorite"
                    size={25}
                    color="grey"
                  />
                </span>{' '}
                next to a friend in your Contacts to always see
                Destinations & Entertainment they will love on your
                guide!
              </h4>
            </Link>
          </div>
          <div
            className="announcementBox mobileShow"
            style={{ marginTop: '30px' }}
          >
            <Link
              to={{
                pathname: '/connections',
              }}
              style={{ color: '#303030' }}
            >
              <h4
                style={{
                  fontSize: '18px',
                  lineHeight: '25px',
                  fontWeight: '500',
                  paddingBottom: '10px',
                }}
              >
                Tip: Select a{' '}
                <span
                  style={{
                    marginTop: '0px',
                    top: '5px',
                    display: 'inline-block',
                    position: 'relative',
                  }}
                >
                  <MaterialIcon
                    icon="favorite"
                    size={25}
                    color="grey"
                  />
                </span>{' '}
                next to a friend in your Contacts to always see
                Destinations & Entertainment they will love on your
                guide!
              </h4>
            </Link>
          </div>
        </div>
      );
    }
    if (
      this.props.connectionsTagsAndFavs[0] !== undefined &&
      this.state.promotedLocations.length > 0
    ) {
      return (
        <div className="homeSection">
          <div
            style={{
              height: count > 2 ? '40px' : '40px',
              marginBottom: count > 2 ? '10px' : '10px',
            }}
          >
            <Row
              className="no-margin"
              style={{
                padding: count > 2 ? '0x' : '10px',
              }}
            >
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="no-padding"
              >
                <div className="sectiontitle">
                  <span
                    className="homeCat "
                    style={{
                      float: count > 2 ? 'left' : 'left',
                      marginBottom: count > 2 ? '10px' : '',
                      textAlign: count > 2 ? 'left' : 'left',
                    }}
                  >
                    Places{' '}
                    {this.props.connectionsTagsAndFavs[0].firstName}{' '}
                    Will Love!
                  </span>
                </div>
              </Col>
            </Row>
          </div>

          {this.state.promotedLocations.length > 0 && (
            <Carousel
              showThumbs={false}
              // centerMode={true}
              // centerSlidePercentage={80}
              // selectedItem={this.getCount(promotedLocations)}
              showIndicators={false}
              showStatus={false}
              infiniteLoop={false}
              centerMode={count === 2 ? true : false}
            >
              {this.getSlideSections(
                this.state.promotedLocations,
                count,
              )}
            </Carousel>
          )}
          {/* {this.getSingle(this.state.promotedLocations)} */}
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    connections: state.connections.connections.connections,
    connectionsTagsAndFavs: state.tagsAndFavs.connectionsTagsAndFavs,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConnectionsTagsAndFavs: (userId) => {
      dispatch(getConnectionsTagsAndFavs(userId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyContactLocations);
