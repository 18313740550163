import React from "react";
import { Col, Container, Row } from "reactstrap";

export default class Affiliates extends React.Component {
  render() {
    return (
      <div>
        <div id="contactForm" className="section">
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h1>Affiliate Programs</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <p>
                  As a small business, we love helping the growth of other
                  businesses. If you have a product or article that you would
                  like for us to feature, please send us an email at
                  <a href="mailto:mike@trevel.com"> mike@trevel.com</a>.
                </p>

                <br />
              </Col>
              {/*
              <Col lg={7} md={7} sm={12} xs={12}>
                <center>
                  <ContactUsForm />
                </center>
              </Col>*/}
            </Row>
          </Container>

          {/* <Link to={`/packages`}>Packages</Link> */}
        </div>
      </div>
    );
  }
}
