import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import firebase from '../../config/firebase';
import {
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import LocationListItem from '../LocationListItem';
// Components
import './EventInvite.css';
// Reducers
import { setUser } from '../../sagas/loginSaga/reducer';
import { getEvents } from '../../sagas/userEventsSaga/reducer';

const query = require('array-query');

class EventDetails extends Component {
  constructor() {
    super();
    this.state = {
      // loading: true,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    // this.props.init();

    // this.props.getAllLocations(1);

    if (this.props.event[0] !== undefined) {
      // this.setState({ loading: false });
    } else {
      this.props.getInvite(this.props.token);
    }
  }

  getSchedule() {
    if (this.props.event[0].scheduledTime.date === '') {
      return 'This event is not scheduled yet.';
    }
    return this.getPostDate(this.props.event[0].scheduledTime);
  }

  getPostDate(scheduledTime) {
    let atsign = '';
    const dayText = moment
      .utc(scheduledTime.timestamp)
      .format('dddd');
    const dateText = moment.utc(scheduledTime.timestamp).format('Do');
    const monthText = moment
      .utc(scheduledTime.timestamp)
      .format('MMM');
    if (scheduledTime.timestamp !== '') {
      atsign = '@';
    }
    if (scheduledTime.date === '') {
      return 'This event is not scheduled yet.';
    } else {
      return (
        <span>
          {dayText} {monthText} {dateText} {atsign}{' '}
          {scheduledTime.time}
        </span>
      );
    }
  }

  // getLocationDetails(packageId) {
  //   const locations = getLocationDetailsService(packageId);
  //   console.log("locations", locations);
  // }

  // getHero() {
  //   console.log("this.props.event", this.props.event);
  //   // const packageDetails = query("packageId")
  //   //   .is(this.props.event.packageDetails.packageId)
  //   //   .on(this.props.packages);
  //   // console.log("packageDetails", packageDetails[0]);
  //   const storage = firebase
  //     .app()
  //     .storage("gs://go-together-6b9cc.appspot.com");
  //   const storageRef = firebase.storage().ref();
  //   return new Promise((resolve, reject) => {
  //     const tempPath = `location_images/${this.props.event[0].heroImage}`;
  //     storageRef
  //       .child(tempPath)
  //       .getDownloadURL()
  //       .then(
  //         url => url,
  //         error => {
  //           console.log(error);
  //         }
  //       )
  //       .then(result => {
  //         console.log("result", result);
  //         this.setState({
  //           imageurl: result,
  //           locations: this.props.event[0].locations,
  //           loading: false
  //         });
  //       });
  //   });
  // }

  getInviteMessage() {
    if (
      this.props.event[0].invitations[this.props.token].type !== 4
    ) {
      return <h1>You{"'"}re Invited!</h1>;
    } else {
      return <h1>You{"'"}re Invited To Make Plans!</h1>;
    }
  }

  getMessage() {
    if (
      this.props.event[0].invitations[this.props.token]
        .inviteMessage !== undefined &&
      this.props.event[0].invitations[this.props.token]
        .inviteMessage !== null &&
      this.props.event[0].invitations[this.props.token]
        .inviteMessage !== ''
    ) {
      return (
        <div className="card-section">
          <div className="sender-message">
            <span className="invite-label">Message</span>
            <p>
              {
                this.props.event[0].invitations[this.props.token]
                  .inviteMessage
              }
            </p>
          </div>
        </div>
      );
    }
  }

  getDescription() {
    if (this.props.event[0].description !== '') {
      return (
        <div className="card-section">
          <span className="title">{this.props.event[0].title}</span>
          {/* <span className="invite-label">Event Description</span> */}
          <p>{this.props.event[0].description}</p>
        </div>
      );
    }
  }

  getItinerary() {
    if (this.props.event[0].packageDetails.locations.length !== 0) {
      //   let readyLocations = [];
      // this.props.event.packageDetails.locations.forEach((location) => {
      //   const locationDetails = query('locationId')
      //     .is(location.locationId)
      //     .on(this.props.locations);
      //   readyLocations.push({
      //     type: 0,
      //     locationId: locationDetails[0].locationId,
      //     locationTitle: locationDetails[0].locationTitle,
      //     locationThumb: locationDetails[0].locationThumb,
      //     locationHero: locationDetails[0].locationHero,
      //     address: locationDetails[0].address,
      //     locationDescription: locationDetails[0].locationDescription,
      //     phone: locationDetails[0].phone,
      //     website: locationDetails[0].website,
      //     happyHours: locationDetails[0].happyHours,
      //     settings: locationDetails[0].settings,
      //   });
      // });

      return (
        <div className="card-section">
          <CardSubtitle>Event Itinerary</CardSubtitle>
          <LocationListItem
            locations={this.props.event[0].packageDetails.locations}
            token={this.props.token}
            type={2}
            links={false}
          />
        </div>
      );
    }
  }

  acceptInvite() {
    let user = {
      id: this.props.user.id,
      userName: this.props.profile.userName,
      email: this.props.user.email,
      authenticated: true,
      newuser: true,
      invited: null,
      eventId: null,
    };

    // Check if this user was sent an invite to join
    if (this.props.invite.event.title !== null) {
      user.invited = true;
      user.eventId = this.props.invite.event[0].eventId;
      user.inviteType =
        this.props.invite.event[0].invitations[
          this.props.invite.event[0].token
        ].type;
    }

    // We will want to seperate this from setUser
    if (this.props.invite.event.title !== null) {
      this.updateEvent(
        this.props.invite.event[0],
        this.props.profile.firstName,
        this.props.profile.lastName,
        this.props.profile.userName,
        this.props.invite.event[0].creationDetails.plannerId,
        this.props.user.id,
        this.props.invite,
      );
    }
  }

  updateEvent(
    event,
    firstName,
    lastName,
    userName,
    senderId,
    userId,
    invite,
  ) {
    const db = firebase.firestore();
    const eventId = event.eventId;
    const userEvent = db.collection('user_events').doc(eventId);
    const timestamp = new Date();

    // const userConnectionObject = {
    //   connected: true,
    //   created: timestamp,
    //   userId: senderId,
    // };

    const senderConnectionObject = {
      connected: true,
      created: timestamp,
      userId: userId,
    };

    const usersObject = event.users;
    const invitationObject = event.invitations;
    const fullname = firstName + ' ' + lastName;
    let updatedUser = {};

    // Add User With New UID
    if (invite.event[0].eventSettings.eventApproved === true) {
      updatedUser = {
        access: true,
        approved: true,
        editor: false,
        attending: true,
        rejected: false,
        inviteAction: true,
        profile: {
          fullName: fullname,
          profilePhotoPath: '',
          userName: userName,
        },
      };
    } else {
      // is planning invite
      updatedUser = {
        access: true,
        approved: false,
        editor: true,
        attending: true,
        rejected: false,
        inviteAction: true,
        profile: {
          fullName: fullname,
          profilePhotoPath: '',
          userName: userName,
        },
      };
    }

    usersObject[userId] = updatedUser;
    // Delete temp id
    delete usersObject[event.token];
    // Update Invitations
    invitationObject[userId] = event.invitations[event.token];
    invitationObject[userId].userId = userId;
    // Delete Old Invite
    delete invitationObject[event.token];
    // Make New Event
    // let updatedEvent = event;
    // updatedEvent.users = usersObject;
    // updatedEvent.invitations = invitationObject;
    // Delete Token Property
    // delete updatedEvent.token;
    // console.log("updatedEvent", updatedEvent);

    const setDoc = userEvent
      .update({
        users: usersObject,
        invitations: invitationObject,
        // status: 2
      })
      .then(() => {
        const setDoc = db
          .collection('connections')
          .doc(userId)
          .set({
            connections: {
              [senderId]: {
                connected: true,
                created: timestamp,
                userId: senderId,
              },
            },
          })
          .then(() => {
            const senderConnections = db
              .collection('connections')
              .doc(senderId);
            const setDoc3 = senderConnections
              .update({
                ['connections.' + userId]: senderConnectionObject,
              })
              .then(() => {
                this.props.getEvents(userId);
                this.props.history.push('/event/' + eventId);
              });
          });
      });
  }

  render() {
    // if (this.props.event[0].title !== undefined) {
    //   return null; // or render a loading icon
    // } else
    if (this.state.loading) {
      return null;
    }
    if (this.props.event[0] === undefined) {
      return null;
    }
    const fullName =
      this.props.event[0].creationDetails.plannerFirstName +
      ' ' +
      this.props.event[0].creationDetails.plannerLastName;
    const schedule = this.getSchedule();
    // const locations = this.getLocationDetails(
    //   this.props.event.packageDetails.packageId
    // );
    return (
      <div id="event-invite">
        {this.props.event[0].invitations[this.props.token].status ===
        0 ? (
          <div>
            <div id="spacer" />
            <Container>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="no-padding"
                >
                  <center>{this.getInviteMessage()}</center>
                  <Container className="package">
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding"
                      >
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              this.props.event[0].heroImage !== null
                                ? this.props.event[0].heroImage
                                : 'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/article_images%2FdowHero.jpg?alt=media&token=99acdb0d-b8de-4e16-9005-3cb3e5fc04c6'
                            }
                            alt="Card image cap"
                          />
                          <CardBody>
                            {/* {this.getMessage()} */}

                            {this.getDescription()}
                            <div className="card-section">
                              <span>
                                <span className="invite-label">
                                  When:
                                </span>{' '}
                                {schedule}
                              </span>
                            </div>
                            <div id="sender">
                              <UserAvatar
                                size="48"
                                name={fullName}
                                src={
                                  this.props.event[0].creationDetails
                                    .plannerPhoto
                                }
                                className="floatLeft"
                              />
                              <span className="sender-name">
                                {fullName}
                              </span>
                            </div>
                            {this.getItinerary()}
                            <center>
                              {this.props.user.authenticated ===
                              false ? (
                                <Link
                                  to={`/signup/${this.props.token}`}
                                  className="accept-button"
                                >
                                  Accept Invitation!
                                </Link>
                              ) : (
                                <div>
                                  <a
                                    onClick={() =>
                                      this.acceptInvite()
                                    }
                                    className="accept-button"
                                  >
                                    Accept Invitation!
                                  </a>
                                </div>
                              )}
                            </center>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="message-box">
            <h2>This Invite Has Been Used</h2>
            <Link
              to={{
                pathname: '/home',
              }}
            >
              Return Home
            </Link>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
    getEvents: (user) => {
      dispatch(getEvents(user));
    },
  };
};

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    invite: state.invite,
    user: state.user,
    profile: state.profile.profile,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventDetails);
