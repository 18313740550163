import * as action from './actions';

export const getAdminUsers = (id) => {
  return {
    type: action.GET_ADMINUSERS,
    payload: id,
  };
};

export const setAdminUsers = (users) => {
  return {
    type: action.SET_ADMINUSERS,
    payload: users,
  };
};

const ACTION_HANDLERS = {
  [action.GET_ADMINUSERS]: (state, action) => {
    return { ...state };
  },
  [action.SET_ADMINUSERS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
