import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getLocationDetailsService } from '../../services/LocationServices';
import { updateLocationsService } from '../../services/LocationServices';
import { getLocations } from '../../services/LocationServices';
import { getLocation } from '../../services/LocationServices';

import * as actions from './actions';

let defaultState = {
  locations: [],
};

// what was this?

// function* getLocationsAction(zoneId) {
//   console.log("locations saga function", zoneId);
//   let locations = yield call(getLocationDetailsService, packageId.packageId);
//   console.log("returned locations", locations);
//   yield put({
//     type: actions.SET_LOCATIONS,
//     payload: {
//       ...defaultState,
//       locations,
//     },
//   });
// }

function* getLocationAction(bundle) {
  let location = yield call(getLocation, bundle.locationId);
  yield put({
    type: actions.SET_LOCATIONS,
    payload: {
      ...defaultState,
      location,
    },
  });
}

function* getLocationsAction(bundle) {
  let locations = yield call(getLocations, bundle.zoneId);
  yield put({
    type: actions.SET_LOCATIONS,
    payload: {
      ...defaultState,
      locations,
    },
  });
}

function* updateLocationsAction(bundle) {
  let destination = bundle.bundle.destination;
  // console.log("bundlex", bundle.bundle.destination);
  let locations = yield call(updateLocationsService, bundle);
  yield put({
    type: actions.SET_LOCATIONS,
    payload: {
      ...defaultState,
      locations,
    },
  });

  // Coming from adding guest.
  if (destination === 1) {
    yield put(push('/event/create/home/1'));
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_LOCATIONS, getLocationsAction);
  yield takeLatest(actions.GET_LOCATION, getLocationAction);
  yield takeLatest(actions.UPDATE_LOCATIONS, updateLocationsAction);
}
