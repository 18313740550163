import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import firebase from '../../config/firebase';
import FileUploader from 'react-firebase-file-uploader';
import Select from 'react-select';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
// Reducers
import { setLocations } from '../../sagas/locationsSaga/reducer';
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';

const INITIAL_STATE = {
  title: '',
  categories: '',
  city: '',
  latitude: '',
  longitude: '',
  state: '',
  street: '',
  zip: '',
  locationHero: '',
  locationId: '',
  locationThumb: '',
  phone: '',
  voucher1Info: '',
  voucher1Cost: '',
  voucher1Id: '',
  voucher1Promo: '',
  voucher1Type: '',
  voucher2Info: '',
  voucher2Cost: '',
  voucher2Id: '',
  voucher2Promo: '',
  voucher2Type: '',
  voucher3Info: '',
  voucher3Cost: '',
  voucher3Id: '',
  voucher3Promo: '',
  voucher3Type: '',
  packages: '',
  zoneId: '',
  error: null,
  loading: true,
  isUploading: false,
  progress: 0,
  heroURL: '',
  hero: '',
  selectedOption: null,
  contactEmail: '',
  contactName: '',
  contactPhone: '',
  step: 1,
};

export class AdminLocationsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getCategoryFields = this.getCategoryFields.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.editlocation.locationDescription !== undefined) {
      const blocksFromHtml = htmlToDraft(
        this.props.editlocation.locationDescription,
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });
    } else {
      const editorState = EditorState.createEmpty();
      this.setState({
        editorState,
      });
    }
  }

  componentDidMount() {
    this.setCurrentValues();
    this.updateFields();
  }

  getCategoryFields(cattag) {
    const tags = cattag.tags;
    const options = [];
    tags.forEach(tag => {
      options.push({
        value: tag.tag,
        label: tag.tag,
      });
    });
    return (
      <div>
        <span className="inputLabel">{cattag.parent}</span>
        <Select
          value={this.state[cattag.sid]}
          onChange={value => {
            this.handleOnChange(cattag.sid, value);
          }}
          options={options}
          isMulti={true}
        />
      </div>
    );
  }

  handleOnChange = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  updateFields() {
    const heroURL =
      'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
      this.props.editlocation.locationHero +
      '?alt=media';

    const thumbURL =
      'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
      this.props.editlocation.locationThumb +
      '?alt=media';

    this.setState({
      title: this.props.editlocation.locationTitle,
      city: this.props.editlocation.address.city,
      latitude: this.props.editlocation.address.latitude,
      longitude: this.props.editlocation.address.longitude,
      state: this.props.editlocation.address.state,
      street: this.props.editlocation.address.street,
      website: this.props.editlocation.website,
      zip: this.props.editlocation.address.zip,
      description: this.props.editlocation.locationDescription,
      locationId: this.props.editlocation.locationId,
      zoneId: this.props.editlocation.zoneId,
      phone: this.props.editlocation.phone,
      heroURL,
      thumbURL,
    });
    if (this.props.editlocation.contact !== undefined) {
      this.setState({
        contactEmail: this.props.editlocation.contact.contactEmail,
        contactName: this.props.editlocation.contact.contactName,
        contactPhone: this.props.editlocation.contact.contactPhone,
        loading: false,
      });
    }
  }

  setCurrentValues() {
    const beverageTags = [];
    if (this.props.editlocation.tagsBeverages) {
      this.props.editlocation.tagsBeverages.forEach(tag => {
        beverageTags.push({
          value: tag,
          label: tag,
        });
      });
    }

    const settingTags = [];
    if (this.props.editlocation.tagsSetting) {
      this.props.editlocation.tagsSetting.forEach(tag => {
        settingTags.push({
          value: tag,
          label: tag,
        });
      });
    }

    const cuisineTags = [];
    if (this.props.editlocation.tagsCuisine) {
      this.props.editlocation.tagsCuisine.forEach(tag => {
        cuisineTags.push({
          value: tag,
          label: tag,
        });
      });
    }

    const dateTypeTags = [];
    if (this.props.editlocation.tagsDateType) {
      this.props.editlocation.tagsDateType.forEach(tag => {
        dateTypeTags.push({
          value: tag,
          label: tag,
        });
      });
    }

    const hoursTags = [];
    if (this.props.editlocation.tagsHours) {
      this.props.editlocation.tagsHours.forEach(tag => {
        hoursTags.push({
          value: tag,
          label: tag,
        });
      });
    }

    this.setState({
      beverages: beverageTags,
      cuisineType: cuisineTags,
      hours: hoursTags,
      dateType: dateTypeTags,
      setting: settingTags,
    });
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  }

  saveLocation(id, editorState, values) {
    const db = firebase.firestore();
    const articleToUpdate = db.collection('locations').doc(id);
    const timestamp = new Date();

    const tagsBeverages = [];
    this.state.beverages.forEach(doc => {
      tagsBeverages.push(doc.value);
    });

    const tagsCuisine = [];
    this.state.cuisineType.forEach(doc => {
      tagsCuisine.push(doc.value);
    });

    const tagsDateType = [];
    this.state.dateType.forEach(doc => {
      tagsDateType.push(doc.value);
    });

    const tagsHours = [];
    this.state.hours.forEach(doc => {
      tagsHours.push(doc.value);
    });

    const tagsSetting = [];
    this.state.setting.forEach(doc => {
      tagsSetting.push(doc.value);
    });

    // Convert Body from Draft.js to HTML
    const rawContentState = convertToRaw(
      editorState.getCurrentContent(),
    );
    const markup = draftToHtml(rawContentState);

    let update = {
      locationDescription: markup,
      lastUpdated: timestamp,
      locationTitle: values.title,
      address: {
        city: values.city,
        street: values.street,
        zip: values.zip,
        longitude: values.longitude,
        latitude: values.latitude,
        state: values.state,
      },
      contact: {
        contactName: values.contactName,
        contactPhone: values.contactPhone,
        contactEmail: values.contactEmail,
      },
      tagsDateType: tagsDateType,
      tagsCuisine: tagsCuisine,
      tagsHours: tagsHours,
      tagsBeverages: tagsBeverages,
      tagsSetting: tagsSetting,
      zoneId: values.zoneId,
      phone: values.phone,
      website: values.website,
    };

    const updateDoc = articleToUpdate
      .update({
        locationDescription: markup,
        lastUpdated: timestamp,
        locationTitle: values.title,
        address: {
          city: values.city,
          street: values.street,
          zip: values.zip,
          longitude: values.longitude,
          latitude: values.latitude,
          state: values.state,
        },
        contact: {
          contactName: values.contactName,
          contactPhone: values.contactPhone,
          contactEmail: values.contactEmail,
        },
        tagsDateType: tagsDateType,
        tagsCuisine: tagsCuisine,
        tagsHours: tagsHours,
        tagsBeverages: tagsBeverages,
        tagsSetting: tagsSetting,
        zoneId: values.zoneId,
        phone: values.phone,
        website: values.website,
      })
      .then(() => {
        this.props.getAllLocations(this.props.profile.zoneId);
        this.props.history.push('/admin/locations');
      });
  }

  handleUploadStart = () =>
    this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = filename => {
    this.setState({
      hero: filename,
      progress: 100,
      isUploading: false,
    });
    firebase
      .storage()
      .ref('test')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ heroURL: url });
      })
      .then(() => {
        const db = firebase.firestore();
        const imageToUpdate = db
          .collection('locations')
          .doc(this.props.editlocation.locationId);
        const updateDoc = imageToUpdate.update({
          locationHeroURL: this.state.heroURL,
        });
      });
  };

  handleUploadStartThumb = () =>
    this.setState({ isUploading: true, progress: 0 });
  handleProgressThumb = progress => this.setState({ progress });
  handleUploadErrorThumb = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccessThumb = filename => {
    this.setState({
      hero: filename,
      progress: 100,
      isUploading: false,
    });
    firebase
      .storage()
      .ref('test')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ thumbURL: url });
      })
      .then(() => {
        const db = firebase.firestore();
        const imageToUpdate = db
          .collection('locations')
          .doc(this.props.editlocation.locationId);
        const updateDoc = imageToUpdate.update({
          locationThumbURL: this.state.thumbURL,
        });
      });
  };

  getStep(step) {
    const { editorState } = this.state;
    if (step === 1) {
      return (
        <div>
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                {this.getId(this.props.mode)}
                <div className="section no-padding">
                  <span className="inputLabel">Business Name</span>
                  <Field
                    type="title"
                    name="title"
                    placeholder={this.state.title}
                  />
                  <ErrorMessage name="title" component="div" />
                  <span className="inputLabel">Description</span>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="section no-padding">
                  <span className="inputLabel">Street</span>
                  <Field
                    type="street"
                    name="street"
                    placeholder={this.state.street}
                  />
                  <ErrorMessage name="street" component="div" />
                  <span className="inputLabel">City</span>
                  <Field
                    type="city"
                    name="city"
                    placeholder={this.state.city}
                  />
                  <ErrorMessage name="city" component="div" />
                  <span className="inputLabel">State</span>
                  <Field
                    type="state"
                    name="state"
                    placeholder={this.state.state}
                  />
                  <ErrorMessage name="state" component="div" />
                  <span className="inputLabel">Zip Code</span>
                  <Field
                    type="zip"
                    name="zip"
                    placeholder={this.state.zip}
                  />

                  <ErrorMessage name="zip" component="div" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="section no-padding">
                  <span className="inputLabel">Latitude</span>
                  <Field
                    type="latitude"
                    name="latitude"
                    placeholder={this.state.latitude}
                  />
                  <ErrorMessage name="latitude" component="div" />
                  <span className="inputLabel">Longitude</span>
                  <Field
                    type="longitude"
                    name="longitude"
                    placeholder={this.state.longitude}
                  />
                  <ErrorMessage name="longitude" component="div" />
                  <span className="inputLabel">Zone</span>
                  <Field
                    type="zoneId"
                    name="zoneId"
                    placeholder="Zone ID"
                    default={this.state.zoneId}
                  />
                  <ErrorMessage name="zoneId" component="div" />
                </div>
              </Col>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <hr />
                </Col>
              </Row>
            </Row>
            <Row className="wizard-section-border-ten">
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="section no-padding">
                  <span className="inputLabel">Phone</span>
                  <Field
                    type="phone"
                    name="phone"
                    placeholder="Phone Number"
                  />
                  <ErrorMessage name="phone" component="div" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <span className="inputLabel">Website</span>
                <Field
                  type="phone"
                  name="website"
                  placeholder="Website URL"
                />
                <ErrorMessage name="website" component="div" />
              </Col>
            </Row>
            <Row className="wizard-section-ten">
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="section no-padding">
                  <span className="inputLabel">
                    Main Contact Name
                  </span>
                  <Field
                    type="text"
                    name="contactName"
                    placeholder="Name"
                  />
                  <ErrorMessage name="contactName" component="div" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <span className="inputLabel">Main Contact Phone</span>
                <Field
                  type="text"
                  name="contactPhone"
                  placeholder="Phone Number"
                />
                <ErrorMessage name="contactPhone" component="div" />
                <span className="inputLabel">Main Contact Email</span>
                <Field
                  type="text"
                  name="contactEmail"
                  placeholder="Email Address"
                />
                <ErrorMessage name="contactEmail" component="div" />
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (step === 2) {
      return (
        <Container id="imageUpload">
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              {/* <div className="section no-padding">
              <span className="section-header">Primary Images</span>
            </div> */}

              <span className="inputLabel">Banner Image</span>
              {this.state.isUploading && (
                <p>Progress: {this.state.progress}</p>
              )}
              {this.state.heroURL && (
                <img
                  src={this.state.heroURL}
                  className="heroInputImage"
                />
              )}
              <FileUploader
                accept="image/*"
                name="hero"
                randomizeFilename
                storageRef={firebase.storage().ref('test')}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
                className="imageUpload"
              />

              <ErrorMessage name="locationHero" component="div" />
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12} className="left">
              <span className="inputLabel">Thumbnail Image</span>
              {this.state.isUploading && (
                <p>Progress: {this.state.progressThumb}</p>
              )}
              {this.state.heroURL && (
                <img src={this.state.thumbURL} />
              )}
              <FileUploader
                accept="image/*"
                name="thumb"
                randomizeFilename
                storageRef={firebase.storage().ref('test')}
                onUploadStart={this.handleUploadStartThumb}
                onUploadError={this.handleUploadErrorThumb}
                onUploadSuccess={this.handleUploadSuccessThumb}
                onProgress={this.handleProgressThumb}
                className="imageUpload"
              />
              <ErrorMessage name="locationThumb" component="div" />
            </Col>
          </Row>
        </Container>
      );
    } else if (step === 3) {
      return (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="left">
            {/* <div className="section no-padding">
              <span className="section-header">Categories</span>
            </div> */}
            <div className="section no-padding">
              {this.props.tags.map((cattag, index) => (
                <div>{this.getCategoryFields(cattag)}</div>
              ))}
              <ErrorMessage name="categories" component="div" />
            </div>
          </Col>
        </Row>
      );
    }
  }

  getActionButton(step, title) {
    if (this.state.step === step) {
      return (
        <div
          className="text-center borderRight actionButtonActive"
          onClick={() => {
            this.setStep(step);
          }}
        >
          {title}
        </div>
      );
    } else {
      return (
        <div
          className="text-center borderRight actionButton"
          onClick={() => {
            this.setStep(step);
          }}
        >
          {title}
        </div>
      );
    }
  }

  getId(mode) {
    if (mode === 1) {
      return (
        <div className="border-box greyback">
          <span>
            <span className="inputLabel">
              ID: {this.state.locationId}
            </span>
          </span>
        </div>
      );
    }
  }

  setStep(step) {
    this.setState({
      step,
    });
  }

  render() {
    const {
      title,
      categories,
      error,
      city,
      latitude,
      longitude,
      state,
      street,
      zip,
      locationHero,
      locationId,
      locationThumb,
      phone,
      voucher1Info,
      voucher1Cost,
      voucher1Id,
      voucher1Promo,
      voucher1Type,
      voucher2Info,
      voucher2Cost,
      voucher2Id,
      voucher2Promo,
      voucher2Type,
      voucher3Info,
      voucher3Cost,
      voucher3Id,
      voucher3Promo,
      voucher3Type,
      packages,
      zoneId,
    } = this.state;
    const isInvalid =
      categories === '' ||
      title === '' ||
      city === '' ||
      latitude === '' ||
      longitude === '' ||
      state === '' ||
      street === '' ||
      zip === '' ||
      locationHero === '' ||
      locationId === '' ||
      locationThumb === '' ||
      phone === '' ||
      voucher1Info === '' ||
      voucher1Cost === '' ||
      voucher1Id === '' ||
      voucher1Promo === '' ||
      voucher1Type === '' ||
      voucher2Info === '' ||
      voucher2Cost === '' ||
      voucher2Id === '' ||
      voucher2Promo === '' ||
      voucher2Type === '' ||
      voucher3Info === '' ||
      voucher3Cost === '' ||
      voucher3Id === '' ||
      voucher3Promo === '' ||
      voucher3Type === '' ||
      packages === '' ||
      zoneId === '';

    return (
      <div id="admin" className="actionBarPush">
        <div className="adminActionBar">
          <Row>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getActionButton(1, 'General')}
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getActionButton(2, 'Images')}
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getActionButton(3, 'Tagging')}
            </Col>
          </Row>
        </div>
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              title: this.state.title,
              categories: '',
              city: this.state.city,
              latitude: this.state.latitude,
              longitude: this.state.longitude,
              state: this.state.state,
              street: this.state.street,
              zip: this.state.zip,
              zoneId: this.state.zoneId,
              phone: this.state.phone,
              contactEmail: this.state.contactEmail,
              contactName: this.state.contactName,
              contactPhone: this.state.contactPhone,
              locationHero: '',
              locationId: '',
              locationThumb: '',
              voucher1Info: '',
              voucher1Cost: '',
              voucher1Id: '',
              voucher1Promo: '',
              voucher1Type: '',
              voucher2Info: '',
              voucher2Cost: '',
              voucher2Id: '',
              voucher2Promo: '',
              voucher2Type: '',
              voucher3Info: '',
              voucher3Cost: '',
              voucher3Id: '',
              voucher3Promo: '',
              voucher3Type: '',
              packages: '',
              description: this.state.description,
              website: this.state.website,
            }}
            // validate={values => {
            //   let errors = {};
            //   if (!values.title) {
            //     errors.title = "Required";
            //   } else if (
            //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            //   ) {
            //     errors.title = "Required";
            //   }
            //   return errors;
            // }}
            onSubmit={values => {
              this.saveLocation(
                this.props.editlocation.locationId,
                this.state.editorState,
                values,
              );
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div id="headersTop">
                  <div className="backbar">
                    <Row className="no-margin">
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="no-padding"
                      >
                        <Link
                          to={{
                            pathname: '/vendor/locations/',
                          }}
                        >
                          <MaterialIcon
                            // icon="keyboard_backspace"
                            icon="close"
                            size={25}
                            color="white"
                          />
                        </Link>
                      </Col>
                      <Col lg={8} md={8} sm={8} xs={8}>
                        <center>
                          <span className="page-header">
                            Edit Location
                          </span>
                        </center>
                      </Col>

                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="no-padding"
                      >
                        <span className="right">
                          <button
                            className="footer-link right white"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            <MaterialIcon
                              icon="check"
                              size={25}
                              color="white"
                            />
                          </button>
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div id="admin" className="actionBarPush">
                  <div className="adminActionBar">
                    <Row>
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="no-padding"
                      >
                        {this.getActionButton(1, 'General')}
                      </Col>
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="no-padding"
                      >
                        {this.getActionButton(2, 'Images')}
                      </Col>
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="no-padding"
                      >
                        {this.getActionButton(3, 'Tagging')}
                      </Col>
                    </Row>
                  </div>
                </div>
                <Container>{this.getStep(this.state.step)}</Container>
                {/* <div id="breakoutFooter">
                  <Container className="no-padding no-margin">
                    <button
                      className="footer-link right white"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                    <a
                      href="/vendor/locations/"
                      className="left footer-link white"
                    >
                      Cancel
                    </a>
                  </Container>
                </div> */}
              </Form>
            )}
          </Formik>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.col-12:nth-child(even){padding-left: 15px} .col-12:nth-child(even){padding-right: 15px}  .nav-signup { display: none }  .maincontent-push{top:40px !important} .rdw-editor-main{padding: 10px; background-color: white;}',
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    tags: state.tags.tags,
    mode: state.mode.mode,
    profile: state.profile.profile,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setLocations: location => {
      dispatch(setLocations(location));
    },
    getAllLocations: zoneId => {
      dispatch(getAllLocations(zoneId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminLocationsForm);
