import React from 'react';
import MaterialIcon from 'material-icons-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import moment from 'moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledCollapse,
  Collapse,
} from 'reactstrap';
// Components
import EventListItem from '../../components/EventListItem';
import SearchInput, { createFilter } from 'react-search-input';
import TitleBar from '../../components/TitleBar';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';
import { resetCe } from '../../sagas/customEventSaga/reducer';
import { resetEvent } from '../../sagas/activeEventSaga/reducer';
import {
  getEvents,
  refreshEvents,
} from '../../sagas/userEventsSaga/reducer';
import {
  inviteAccept,
  inviteEdit,
  inviteReject,
} from '../../sagas/invitationSaga/reducer';
import { formValueSelector } from 'redux-form';

const KEYS_TO_FILTERS = [
  'title',
  'packageDetails.locations.locationTitle',
  'names',
];
const query = require('array-query');

export class EventsUpcoming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      searchTerm: '',
      showPlanning: false,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    let unplanned = [];
    this.props.events.forEach((event) => {
      event.mode = '0';
      unplanned.push(event);
    });
    if (unplanned.length < 3) {
      this.setState({ showPlanning: true });
    }

    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    if (this.props.customEvent.clear === false) {
      const create = false;
      this.props.resetCe(create);
    }
    this.props.resetEvent();
  }

  componentDidMount() {
    document.title = 'Upcoming Experiences';
  }

  setEvents(events, userId) {
    const planned = [];
    const unplanned = [];
    const finished = [];
    let setEvents = {};

    if (events !== undefined) {
      events.forEach((event) => {
        if (
          (event.users[userId].finished === true &&
            event.users[userId].delete !== true) ||
          (event.users[userId].canceled === true &&
            event.users[userId].delete !== true)
        ) {
          if (event.status !== 11) {
            event.mode = '6';
            finished.push(event);
          } else if (
            event.status === 11 &&
            event.users[this.props.user.id].editor === true
          ) {
            event.mode = '6';
            finished.push(event);
          }
        } else if (
          event.users[userId].finished !== true &&
          event.status !== 9
        ) {
          if (event.status === 0) {
            event.mode = '1';
            planned.push(event);
          } else if (
            event.status === 5 &&
            event.creationDetails.plannerId === this.props.user.id
          ) {
            event.mode = '5';
            planned.push(event);
          } else if (
            (event.users[this.props.user.id].editor === true &&
              event.status === 2) ||
            event.status === 3 ||
            event.status === 4 ||
            event.status === 11
          ) {
            event.mode = '0';
            unplanned.push(event);
          }
        }
      });
      let plannedCount = planned.length;
      let unplannedCount = unplanned.length;
      const activeCountArray = [];
      const activeInvitationsArray = [];
      events.forEach((event) => {
        if (event.users[this.props.user.id].inviteAction === false) {
          activeInvitationsArray.push(
            event.invitations[this.props.user.id],
          );
        }
      });
      activeInvitationsArray.forEach((event) => {
        if (event.status === 0 || event.status === 9) {
          activeCountArray.push(event);
        }
      });
      const activeCount = activeCountArray.length;

      const upcoming = [];
      planned.forEach((event) => {
        let names = [];
        Object.keys(event.users).forEach((key) => {
          names.push(event.users[key].profile.fullName);
        });
        event.names = names;
        upcoming.push(event);
      });
      unplanned.forEach((event) => {
        upcoming.push(event);
      });

      setEvents = {
        planned: planned,
        unplanned: unplanned,
        plannedCount,
        unplannedCount,
        inviteCount: activeCount,
        finished,
        upcoming,
      };
      return setEvents;
    }
  }

  setNotifications(events, userId) {
    const planned = [];
    const unplanned = [];
    const finished = [];
    let setEvents = {};

    if (events !== undefined) {
      events.forEach((event) => {
        if (
          (event.users[userId].finished === true &&
            event.users[userId].delete !== true) ||
          (event.users[userId].canceled === true &&
            event.users[userId].delete !== true)
        ) {
          if (event.status !== 11) {
            event.mode = '6';
            finished.push(event);
          } else if (
            event.status === 11 &&
            event.users[this.props.user.id].editor === true
          ) {
            event.mode = '6';
            finished.push(event);
          }
        } else if (
          event.users[userId].finished !== true &&
          event.status !== 9
        ) {
          if (event.status === 0) {
            event.mode = '1';
            planned.push(event);
          } else if (
            (event.users[this.props.user.id].editor === true &&
              event.status === 2) ||
            event.status === 3 ||
            event.status === 4 ||
            event.status === 11
          ) {
            event.mode = '0';
            unplanned.push(event);
          }
        }
      });
      let plannedCount = planned.length;
      let unplannedCount = unplanned.length;
      const activeCountArray = [];
      const activeInvitationsArray = [];
      events.forEach((event) => {
        if (
          event.users[this.props.user.id].inviteAction !==
            undefined &&
          event.users[this.props.user.id].inviteAction === false
        ) {
          activeInvitationsArray.push(event);
        }
      });
      activeInvitationsArray.forEach((event) => {
        if (
          event.invitations[this.props.user.id].status === 0 ||
          event.invitations[this.props.user.id].status === 9
        ) {
          activeCountArray.push(event);

          // let cstate = false;
          // if (this.state[event.eventId] !== undefined) {
          //   cstate = this.state[event.eventId];
          // }
          // const returnObj = {};
          // returnObj[event.eventId] = !cstate;
          // console.log('notificationIdsss', returnObj);
          // this.setState(returnObj);
        }
      });
      const activeCount = activeCountArray.length;

      setEvents = {
        plannedCount,
        unplannedCount,
        inviteCount: activeCount,
        invites: activeCountArray,
        finished,
      };

      return setEvents;
    }
  }

  changeMode(mode) {}

  getFooter() {
    return (
      <Container>
        <Row className="no-margin">
          <Col
            xs={12}
            md={12}
            sm={12}
            lg={12}
            className="no-padding text-center"
          >
            <Link
              to={{
                pathname: '/events/home',
              }}
            >
              <span className="footer-link-withborder">
                Upcoming Experience
              </span>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getContent(setEvents, searchTerm) {
    const planned = setEvents.planned;
    const unplanned = setEvents.unplanned;
    const upcoming = setEvents.upcoming;

    let filteredPlannedEvents = [];
    filteredPlannedEvents = planned.filter(
      createFilter(searchTerm, KEYS_TO_FILTERS),
    );

    let filteredUnPlannedEvents = [];
    filteredUnPlannedEvents = unplanned.filter(
      createFilter(searchTerm, KEYS_TO_FILTERS),
    );

    let notifications = this.setNotifications(
      this.props.events,
      this.props.user.id,
    );

    let yourturn = [];
    let waiting = [];
    filteredUnPlannedEvents.forEach((event) => {
      if (event.users[this.props.user.id].approved === false) {
        yourturn.push(event);
      } else {
        waiting.push(event);
      }
    });
    const sortedUnplanned = [...yourturn, ...waiting];

    let sortedUpcoming = [];
    let noDates = [];
    let hasDate = [];
    filteredPlannedEvents.forEach((event) => {
      if (event.scheduledTime.date === '') {
        noDates.push(event);
      } else {
        hasDate.push(event);
      }
    });
    sortedUpcoming = hasDate.sort(
      (a, b) =>
        moment(a.scheduledTime.date).format('YYYYMMDD') -
        moment(b.scheduledTime.date).format('YYYYMMDD'),
    );

    let eventGroups = {};
    let eventDates = [];

    sortedUpcoming.forEach((event) => {
      let status = false;
      status = eventDates.includes(event.scheduledTime.date);
      if (status === false) {
        eventGroups[event.scheduledTime.date] = {};
        eventGroups[event.scheduledTime.date].date =
          event.scheduledTime.date;
        eventGroups[event.scheduledTime.date].events = [];
        eventGroups[event.scheduledTime.date].events.push(event);
        eventDates.push(event);
      } else {
        eventGroups[event.scheduledTime.date].events.push(event);
      }
    });
    if (setEvents.plannedCount + setEvents.unplannedCount === 0) {
      return (
        <div>
          <div style={{ padding: '10px' }}>
            {notifications.invites.length !== 0 && (
              <h5 style={{ fontSize: '18px' }}>
                New Invitations{' '}
                <div className="counterGreyBig">
                  <span>{notifications.invites.length}</span>
                </div>
              </h5>
            )}
            {this.getNotifications(
              notifications.invites,
              this.state.mode,
              this.props.user,
            )}{' '}
          </div>

          <Container className="pushfifty">
            <Row className="text-center">
              <span>You do not have any upcoming Experiences.</span>

              <div
                style={{
                  padding: '15px 10px 10px 10px',
                  border: '1px solid lightgrey',
                  margin: '0px auto',
                  width: '100%',
                  marginTop: '55px',
                  background: 'white',
                  display: 'flex',
                  textAlign: 'left',
                }}
              >
                <Col xs={2} md={1} sm={2} lg={1}>
                  <div style={{ float: 'left' }}>
                    <MaterialIcon
                      icon="help_outline"
                      size={35}
                      color="rgb(238 1 88)"
                    />
                  </div>
                </Col>
                <Col xs={10} md={11} sm={10} lg={11}>
                  <h4
                    style={{ fontSize: '18px', marginBottom: '10px' }}
                  >
                    Upcoming Experiences
                  </h4>
                  <p
                    style={{
                      margin: '0px',
                      padding: '0px 0px 10px 0px',
                    }}
                  >
                    Here you will find all of your upcoming
                    Experiences, including ones you are still planning
                    with others.
                  </p>
                </Col>
              </div>
            </Row>
          </Container>
        </div>
      );
    } else {
      return (
        <div>
          <div id="eventList" style={{ height: '100%' }}>
            <Container>
              <div
                style={{
                  padding: '10px 0px 10px 0px',
                }}
              >
                <SearchInput
                  className="search-input centered-input"
                  onChange={this.searchUpdated}
                  placeholder="Search"
                  value={this.state.searchTerm}
                />
              </div>
              {notifications.invites.length !== 0 && (
                <h5 style={{ fontSize: '18px' }}>New Invitations</h5>
              )}
              {this.getNotifications(
                notifications.invites,
                this.state.mode,
                this.props.user,
              )}
              <div id="eventsBlack" style={{ paddingBottom: '80px' }}>
                {sortedUnplanned.length !== 0 && (
                  <h5
                    style={{ fontSize: '18px' }}
                    onClick={() => {
                      this.hidePlanning(!this.state.showPlanning);
                    }}
                  >
                    Plans In Progress{' '}
                    <span
                      style={{ float: 'right', color: '#ee0059' }}
                    >
                      {this.state.showPlanning === true
                        ? 'Hide'
                        : 'Show'}
                    </span>
                    <div className="counterGreyBig">
                      <span>{sortedUnplanned.length}</span>
                    </div>
                  </h5>
                )}

                {this.state.showPlanning === true && (
                  <EventListItem events={sortedUnplanned} mode="1" />
                )}
                {noDates.length == 0 &&
                  Object.keys(eventGroups).length === 0 && (
                    <h5 style={{ fontSize: '18px' }}>
                      Upcoming Experiences
                    </h5>
                  )}
                {noDates.length !== 0 && (
                  <div>
                    <span style={{ color: 'grey' }}>No Date</span>
                    <EventListItem events={noDates} mode="1" />
                  </div>
                )}
                {this.getSortedEvents(eventGroups)}
              </div>
            </Container>
          </div>
        </div>
      );
    }
  }

  convertDate(event) {
    let newDate = moment(event.date, 'YYYY-MM-DD').toDate();
    let finalDate = moment(newDate).format('MMM Do');
    return (
      <span
        style={{
          color: 'black',
          fontSize: '20px',
          fontWeight: '500',
        }}
      >
        {finalDate}
      </span>
    );
  }

  getSortedEvents(eventGroups) {
    let events = [];
    Object.keys(eventGroups).forEach(function (key) {
      events.push(eventGroups[key]);
    });
    return (
      <div>
        {events.map((event) => (
          <div>
            {this.convertDate(event)}

            <EventListItem events={event.events} mode="1" />
          </div>
        ))}
      </div>
    );
  }

  getNotifications(events, mode, user) {
    if (events.length === 0) {
      // return (
      //   <Container className="pushfifty">
      //     <Row className="text-center">
      //       <span>You do not have any new Invitations.</span>
      //       <div
      //         style={{
      //           padding: '15px 10px 10px 10px',
      //           border: '1px solid lightgrey',
      //           margin: '0px auto',
      //           width: '100%',
      //           marginTop: '100px',
      //           background: 'white',
      //           display: 'flex',
      //           textAlign: 'left',
      //         }}
      //       >
      //         <Col xs={2} md={1} sm={2} lg={1}>
      //           <div style={{ float: 'left' }}>
      //             <MaterialIcon
      //               icon="help_outline"
      //               size={35}
      //               color="rgb(238 1 88)"
      //             />
      //           </div>
      //         </Col>
      //         <Col xs={10} md={11} sm={10} lg={11}>
      //           <h4
      //             style={{ fontSize: '18px', marginBottom: '10px' }}
      //           >
      //             Your Experiences - Invitations
      //           </h4>
      //           <p
      //             style={{
      //               margin: '0px',
      //               padding: '0px 0px 10px 0px',
      //             }}
      //           >
      //             Here you will find any Invitations that you haven
      //             {"'"}t Accepted or Rejected.
      //           </p>
      //         </Col>
      //       </div>
      //     </Row>
      //   </Container>
      // );
    } else {
      return (
        <div>
          <div id="notifications">
            <Row className="no-margin no-padding">
              {events.map((event) => (
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  {this.displayNotification(
                    event.invitations[this.props.user.id],
                    mode,
                    user,
                    event,
                  )}
                </Col>
              ))}
            </Row>
          </div>
        </div>
      );
    }
  }

  getInviteHeader(notification) {
    if (notification.type !== 4) {
      return (
        <div>
          <span className="lightbold invite-font clear">
            You've been invited to an event!
          </span>
          <span className="invite-label invite-font clear">
            With {notification.senderName}{' '}
            {notification.senderLastName}
          </span>
        </div>
      );
    } else {
      return (
        <span className="lightbold invite-font clear">
          You've been invited to make plans with{' '}
          {notification.senderName} {notification.senderLastName}!
        </span>
      );
    }
  }

  getArrow(status, notificationId) {
    if (status === true) {
      return (
        <span className="rotateArrow right">
          <MaterialIcon
            icon="keyboard_arrow_up"
            size={30}
            color="#f21d59"
          />
        </span>
      );
    } else {
      return (
        <span className="right">
          <MaterialIcon
            icon="keyboard_arrow_up"
            size={30}
            color="#f21d59"
          />
        </span>
      );
    }
  }

  getLocationArray(locations) {
    if (locations.length !== 0) {
      const locationsArray = [];
      locations.forEach((location) => {
        const thislocation = query('locationId')
          .is(location.locationId)
          .on(this.props.alllocations);
        if (thislocation[0] !== undefined) {
          locationsArray.push(thislocation[0]);
        }
      });
      return (
        <div>
          {locationsArray.map((location) => (
            <div className="location-info fullwidth">
              <Row>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="no-padding thumbnailCol"
                >
                  <Link
                    to={{
                      pathname: '/location',
                      state: {
                        location: { location },
                        // packageId: { packageId },
                        type: 6,
                      },
                    }}
                  >
                    {this.getImage('thumb', location)}
                  </Link>
                </Col>
                <Col xs={8} md={8} sm={8} lg={8}>
                  <Link
                    to={{
                      pathname: '/location/' + location.locationId,
                      state: {
                        location: { location },
                        packageId: '',
                        type: 6,
                      },
                    }}
                  >
                    <div>
                      <span className="red">
                        {location.locationTitle}
                      </span>

                      <p className="invite-font">
                        {location.address.street}
                        <br />
                        {location.address.city}{' '}
                        {location.address.state},{' '}
                        {location.address.zip}
                      </p>
                    </div>
                  </Link>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      );
    } else {
      return <div>{/* <p>No Locations</p> */}</div>;
    }
  }

  getImage(type, locationStart) {
    let location = locationStart;
    let locations = this.props.alllocations;
    // const locationDetails = query('locationId')
    //   .is(locationStart.locationId)
    //   .on(locations);
    // location = locationDetails[0];
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={locationStart.locationTitle}
        className={className}
      />
    );
  }

  toggle(notificationId) {
    let cstate = false;
    if (this.state[notificationId] !== undefined) {
      cstate = this.state[notificationId];
    }
    const returnObj = {};
    returnObj[notificationId] = !cstate;
    this.setState(returnObj);
  }

  displayNotification(notification, mode, user, event) {
    const withNoDigits = event.eventId.replace(/[0-9]/g, '');
    const toggler = '#' + withNoDigits;
    const divId = withNoDigits;
    const { title, description, scheduledTime } = event;
    const date = this.getPostDate(scheduledTime);

    return (
      <Card className="inviteCard">
        <CardBody
          onClick={(e) => {
            this.toggle(notification.eventId);
          }}
        >
          <Container className="no-padding">
            <Row className="no-padding" id={divId}>
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="left no-padding"
              >
                <div className="left" style={{ maxWidth: '70px' }}>
                  <UserAvatar
                    size="40"
                    name={notification.senderName}
                    src={notification.profilePhotoPath}
                  />
                </div>
                <div className="left">
                  {this.getInviteHeader(notification)}
                </div>
              </Col>
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="left no-padding"
              >
                <span
                  className="red"
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  {this.getArrow(
                    this.state[notification.eventId],
                    notification.eventId,
                  )}
                </span>
              </Col>
            </Row>
          </Container>
        </CardBody>
        <Collapse isOpen={this.state[notification.eventId]}>
          <Row>
            <Col xs={12} md={12} sm={12} lg={12} className="left">
              {/* <div>
                  <div className="card-section">
                    <span className="invite-font">
                      {notification.inviteMessage}
                    </span>
                  </div>
                </div> */}

              <div className="card-section" style={{ border: '0px' }}>
                {title !== null && (
                  <h2 className="invite-title">{title}</h2>
                )}
                <p className="invite-font">{description}</p>
                <span className="invite-font">{date}</span>
              </div>

              {this.getLocationArray(event.packageDetails.locations)}
            </Col>
          </Row>
        </Collapse>
        <div
          className="card-section"
          style={{
            border: '0px',
            clear: 'both',
            marginBottom: '20px',
          }}
        >
          {this.getActions(notification, date, event)}
        </div>
      </Card>
    );
  }

  hidePlanning(state) {
    this.setState({ showPlanning: state });
  }

  getActionButton(mode, title, count) {
    if (this.state.mode === mode) {
      return (
        <div
          className="text-center actionButton actionButtonActive"
          onClick={() => {
            this.changeMode(mode);
          }}
        >
          {title}
          <div className="counterGrey">
            <span>{count}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="text-center  actionButton actionButtonInactive"
          onClick={() => {
            this.changeMode(mode);
          }}
        >
          {title}
          <div className="counterGrey">
            <span>{count}</span>
          </div>
        </div>
      );
    }
  }

  acceptInvite(profile, notification, event, connections) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      eventId: event.eventId,
      senderId,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      event,
      senderLastName,
      senderName,
      connections,
    };
    this.props.inviteAccept(bundle);
  }

  rejectInvite(profile, notification, event) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      eventId: event.eventId,
      senderId,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      senderLastName,
      senderName,
      event,
    };
    this.props.inviteReject(bundle);
  }

  acceptConnection(profile, notification, event, connections) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      notificationId,
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      notificationId,
      eventId: event.eventId,
      senderId,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      senderLastName,
      senderName,
      event,
      connections,
    };
    this.props.inviteEdit(bundle);
  }

  editInvite(profile, notification, event, connections) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      notificationId,
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      notificationId,
      eventId: event.eventId,
      senderId,
      senderLastName,
      senderName,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      event,
      connections,
    };
    this.props.inviteEdit(bundle);
  }

  getPostDate(scheduledTime) {
    let atsign = '';
    const dayText = moment
      .utc(scheduledTime.timestamp)
      .format('dddd');
    const dateText = moment.utc(scheduledTime.timestamp).format('Do');
    const monthText = moment
      .utc(scheduledTime.timestamp)
      .format('MMM');
    if (scheduledTime.timestamp !== '') {
      atsign = '@';
    }
    if (scheduledTime.date === '') {
      return 'This event is not scheduled yet.';
    } else {
      return (
        <span>
          {dayText} {monthText} {dateText} {atsign}{' '}
          {scheduledTime.time}
        </span>
      );
    }
  }

  getEditButton(notification, event, connections) {
    if (notification.editable === true) {
      return (
        <div>
          <center>
            <span
              onClick={() =>
                this.editInvite(
                  this.props.profile,
                  notification,
                  event,
                  connections,
                )
              }
              className="red hand"
            >
              Edit
            </span>
          </center>
        </div>
      );
    } else {
      return <div />;
    }
  }

  getActions(notification, date, event) {
    if (notification.status === 0 || notification.status === 9) {
      if (notification.type !== 4) {
        return (
          <Row>
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              <center>
                <span
                  onClick={() =>
                    this.acceptInvite(
                      this.props.profile,
                      notification,
                      event,
                      this.props.connections,
                    )
                  }
                  className="red hand"
                >
                  Accept
                </span>
              </center>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              <center>
                <span
                  onClick={() =>
                    this.rejectInvite(
                      this.props.profile,
                      notification,
                      event,
                    )
                  }
                  className="red hand"
                >
                  Reject
                </span>
              </center>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              <div>
                {this.getEditButton(
                  notification,
                  event,
                  this.props.connections,
                )}
              </div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
              <span
                onClick={() =>
                  this.acceptConnection(
                    this.props.profile,
                    notification,
                    event,
                    this.props.connections,
                  )
                }
                className="red hand"
              >
                Accept
              </span>
            </Col>
            <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
              <span
                onClick={() =>
                  this.rejectInvite(
                    this.props.profile,
                    notification,
                    event,
                  )
                }
                className="red hand"
              >
                Reject
              </span>
            </Col>
          </Row>
        );
      }
    } else {
      return (
        <div>
          <span className="right">
            Started
            <br />
            {date}
          </span>
        </div>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/home',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Upcoming Experiences
                </span>
              </center>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <Link
                to={{
                  pathname: '/past-events',
                }}
                className="right"
              >
                <span>
                  Completed{' '}
                  <MaterialIcon
                    icon="close"
                    size={25}
                    color="white"
                  />
                </span>
              </Link> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    let setEvents = this.setEvents(
      this.props.events,
      this.props.user.id,
    );

    return (
      <div id="events">
        {/* <TitleBar title="Social Calendar" backlink="" /> */}
        {this.getTitleBar()}
        {/* <div
          className="adminActionBar"
          style={{ top: '40px !important', width: '100%' }}
        >
          <Row>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <div className="text-center actionButtonActive actionButton">
                Upcoming
                <div className="counterGrey">
                  <span>
                    {setEvents.plannedCount +
                      setEvents.unplannedCount}
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <Link
                to={{
                  pathname: '/events/completed',
                }}
              >
                <div className="text-center actionButton actionButtonInactive">
                  Completed
                  <div className="counterGrey">
                    <span>{setEvents.finished.length}</span>
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <Link
                to={{
                  pathname: '/events/invitations',
                }}
              >
                <div className="text-center actionButton actionButtonInactive noBorderRight ">
                  Invitations
                  <div className="counterGrey">
                    <span>{setEvents.inviteCount}</span>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div> */}
        <div
          // className="actionBarPush"
          style={{ paddingBottom: '80px', height: '100%' }}
        >
          {/* <div> */}
          {this.getContent(setEvents, this.state.searchTerm)}
        </div>
        {/* <div className="footer">{this.getFooter()}</div> */}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#startBar{background-color: #ef0059;} .adminActionBar {top: 40px !important;} #events{position: relative;}  #headersTop {width: 100% !important;} #headersTopSecondary{width: 100%} #authright {padding-left: 0px !important;}  #mobile-nav { display: none } #authleft {display:none;} #events{position: relative; top: 40px}  #bottomNav{left:0px; width:100%} .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    events: state.events.events,
    alllocations: state.locations.locations,
    user: state.user,
    customEvent: state.customEvent.customEvent,
    profile: state.profile.profile,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
    getEvents: (userId) => {
      dispatch(getEvents(userId));
    },
    refreshEvents: (events) => {
      dispatch(refreshEvents(events));
    },
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    resetEvent: (event) => {
      dispatch(resetEvent(event));
    },
    inviteAccept: (bundle) => {
      dispatch(inviteAccept(bundle));
    },
    inviteReject: (bundle) => {
      dispatch(inviteReject(bundle));
    },
    inviteEdit: (bundle) => {
      dispatch(inviteEdit(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventsUpcoming);
