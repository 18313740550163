import * as action from './actions';

export const getCe = (id) => {
  return {
    type: action.GET_CE,
    payload: id,
  };
};

export const resetCe = (id) => {
  return {
    type: action.RESET_CE,
    payload: id,
  };
};

export const updateCe = (id) => {
  return {
    type: action.UPDATE_CE,
    payload: id,
  };
};

export const setCe = (ce) => {
  return {
    type: action.SET_CE,
    payload: ce,
  };
};

export const setDate = (ce) => {
  return {
    type: action.SET_DATE,
    payload: ce,
  };
};

export const saveCe = (ce) => {
  return {
    type: action.SAVE_CE,
    payload: ce,
  };
};

export const approveEvent = (ce) => {
  return {
    type: action.APPROVE_EVENT,
    payload: ce,
  };
};

export const rejectEvent = (ce) => {
  return {
    type: action.REJECT_EVENT,
    payload: ce,
  };
};

export const editMode = (ce) => {
  return {
    type: action.EDIT_MODE,
    payload: ce,
  };
};

export const saveCeP = (ce) => {
  return {
    type: action.SAVE_CEP,
    payload: ce,
  };
};

export const editCe = (ce) => {
  return {
    type: action.EDIT_CE,
    payload: ce,
  };
};

export const editCeP = (ce) => {
  return {
    type: action.EDIT_CEP,
    payload: ce,
  };
};

const ACTION_HANDLERS = {
  [action.GET_CE]: (state, action) => {
    return { ...state };
  },
  [action.SET_CE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SET_DATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SAVE_CE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SAVE_CEP]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.EDIT_CEP]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.EDIT_CE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.UPDATE_CE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.RESET_CE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.APPROVE_EVENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.REJECT_EVENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.EDIT_MODE]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  customEvent: {
    clear: true,
    step: 1,
    containsCustom: false,
    newEventRef: null,
    event: {
      description: null,
      title: null,
      status: 0,
      locations: [],
      creationDetails: {
        plannerId: null,
        plannerPhoto: null,
        plannerFirstName: null,
        plannerLastName: null,
        plannerUserName: null,
        creationDate: null,
      },
      scheduledTime: {
        date: null,
        timestamp: null,
        timeString: null,
        checkWeek: false,
        checkMonth: false,
      },
      usersAttending: null,
      eventSettings: {
        itinerarySent: false,
        scheduled: false,
      },
      packageDetails: {
        packageId: null,
      },
    },
  },
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
