import MaterialIcon from 'material-icons-react';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { createFilter } from 'react-search-input';
import UserAvatar from 'react-user-avatar';
import Select from 'react-select';
import VendorMenu from '../../components/VendorMenu';
import firebase from '../../config/firebase';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineSeries,
  LineMarkSeries,
  RadialChart,
  FlexibleWidthXYPlot,
  DiscreteColorLegend,
  ContinuousColorLegend,
} from 'react-vis';

import { Col, Row } from 'reactstrap';
import '../../../node_modules/react-vis/dist/style.css';
import '../../../node_modules/react-vis/dist/styles/radial-chart.scss';

const KEYS_TO_FILTERS = ['title'];

const query = require('array-query');

const Checkbox = (props) => <input type="checkbox" {...props} />;

const mainLinks = [
  // {
  //   report: 2,
  //   label: 'Users',
  //   icon: 'ballot',
  // },
];

const secondaryLinks = [
  {
    report: 3,
    label: 'Profile',
    icon: 'stars',
  },

  {
    report: 4,
    label: 'Subscription',
    icon: 'stars',
  },
  {
    report: 1,
    label: 'Community',
    icon: 'local_activity',
  },
];

export class VendorAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: false,
      showPicker: false,
      showHelp: false,
      report: 3,
      community: false,
    };
    this.showPicker = this.showPicker.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.title = 'Account';
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    }
  }

  componentDidMount() {
    if (this.props.vendor.subscription.community !== undefined) {
      this.setState({
        community: this.props.vendor.subscription.community,
      });
    }
  }

  onChangeActionCommunity = (value) => {
    let status = true;
    if (value.target !== null) {
      status = value.target.checked;
      this.setState({
        community: value.target.checked,
      });
    }

    let vendorId = this.props.vendor.vendorId;
    let subscription = this.props.vendor.subscription;
    subscription.community = value.target.checked;

    const db = firebase.firestore();
    const userToUpdate = db.collection('vendors').doc(vendorId);

    const updateDoc = userToUpdate
      .update({
        subscription,
      })
      .then(() => {
        this.setState({
          community: status,
        });
      });
  };

  getContent(report, filteredAwards) {
    if (report === 0) {
      return (
        <div>
          <div className="section">
            <h3>Dashboard</h3>
          </div>
        </div>
      );
    } else if (report === 1) {
      return (
        <div>
          <div className="section">
            <h3>Community Settings</h3>
          </div>
          <div className="section">
            <p>
              You can connect with other Entertainers and Destinations
              to create unique Trevel Experiences together.
            </p>
            <p>
              Accepting an invitation to collaborate is always up to
              you and you get to approve the new Trevel Experience
              before anything ever goes live.
            </p>
            <span
              className="title"
              style={{ display: 'block', clear: 'both' }}
            >
              Can other Trevel Experience members send you
              invititations to collaborate?
            </span>
            <input
              type="checkbox"
              checked={this.state.community}
              onChange={this.onChangeActionCommunity}
              className="closedCheckBox"
              name="local"
              style={{ transform: 'scale(2)', float: 'left' }}
            />
            <span
              style={{
                float: 'left',
                position: 'relative',
                top: '9px',
              }}
            >
              Yes
            </span>
          </div>
        </div>
      );
    } else if (report === 2) {
      return (
        <div>
          <div className="section">
            <h3>Player Activity Report</h3>
          </div>
        </div>
      );
    } else if (report === 3) {
      return (
        <div>
          <div className="section">
            <h3>Profile</h3>
          </div>
          <div className="section">
            <h5>Primary Business Name</h5>
            <span>{this.props.vendor.businessName}</span>
          </div>
          <div className="section">
            <h5>Address</h5>
            <span>
              {this.props.vendor.primaryAddress.businessAddress}
            </span>
            <br />
            <span>
              {this.props.vendor.primaryAddress.businessCity} MO,{' '}
              {this.props.vendor.primaryAddress.businessZip}
            </span>
          </div>
        </div>
      );
    } else if (report === 4) {
      let status = 'Inactive';
      if (this.props.vendor.subscription.active === true) {
        status = 'Active';
      }
      return (
        <div>
          <div className="section">
            <h3>Subscription</h3>
          </div>
          <div className="section">
            <h5>Subscription Status</h5>
            <span>{status}</span>
          </div>
        </div>
      );
    }
  }

  setReport(report) {
    this.setState({ report });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={10}
              md={10}
              sm={10}
              xs={10}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">{title}</span>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
              <div className="saveButtons">
                <Link
                  to={{
                    pathname: '/vendor/home',
                  }}
                >
                  <span className="cancelButton">Close</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  displayLink(menuItem, report) {
    if (menuItem.report === report) {
      return (
        <li className="activeReportLink">
          {/* <MaterialIcon
            icon={menuItem.icon}
            size={20}
            color="#f21d59"
          /> */}
          <span
            onClick={() => {
              this.setReport(menuItem.report);
            }}
            style={{
              padding: '10px 0px 5px 0px',
              color: 'white',
            }}
          >
            {menuItem.label}
          </span>
        </li>
      );
    } else {
      return (
        <li>
          {/* <MaterialIcon
            icon={menuItem.icon}
            size={20}
            color="#f21d59"
          /> */}

          <span
            onClick={() => {
              this.setReport(menuItem.report);
            }}
            style={{
              padding: '10px 0px 5px 0px',
              color: 'white',
            }}
          >
            {menuItem.label}
          </span>
        </li>
      );
    }
  }

  getAppContent(promo, filteredAwards) {
    return (
      <Row style={{ height: '100%' }}>
        <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
          <div className="section">
            <div>
              {/* <Row className="no-padding">
              <Col
                xs={6}
                md={6}
                sm={6}
                lg={6}
                className="left no-padding"
              >
                <p>
                  Created By:{' '}
                  <b>{promo.creationDetails.creator.fullName}</b>
                </p>
                <p>
                  Created:{' '}
                  <b>
                    {this.getDate(promo.creationDetails.createdDate)}
                  </b>
                </p>
              </Col>
              <Col
                xs={6}
                md={6}
                sm={6}
                lg={6}
                className="right no-padding"
              >
                {this.getActiveStatus(promo.active)}
              </Col>
            </Row
            > */}
            </div>
            {this.getContent(this.state.report)}
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Manage Account')}
        <div
          className="vendorLeftNav"
          style={{
            padding: '10px',
            position: 'fixed',
            width: '135px',
          }}
        >
          <div style={{ marginTop: '15px' }}>
            <div id="right-filter">
              <ul className="drawerMenuList">
                {secondaryLinks.map((menuItem, index) => (
                  <div key={'key' + index}>
                    {this.displayLink(menuItem, this.state.report)}
                  </div>
                ))}
              </ul>
            </div>
            <Link
              to={{
                pathname: '/vendor/home',
              }}
            >
              <span
                className="cancelButton"
                style={{ float: 'left', marginLeft: '0px' }}
              >
                Close
              </span>
            </Link>
            {/* <span className="clear" style={{ color: 'white' }}>
              MANAGE
            </span> */}
            <div id="right-filter">
              <ul className="drawerMenuList">
                {mainLinks.map((menuItem, index) => (
                  <div key={'key' + index}>
                    {this.displayLink(menuItem, this.state.report)}
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div
          className="vendorContent"
          style={{ float: 'left', width: '90%', left: '135px' }}
        >
          {this.getAppContent()}
        </div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} #analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vendorPromos: state.vendorPromos.promos,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    profile: state.profile.profile,
    vendor: state.vendor.vendor,
    user: state.user,
  };
}

export default connect(mapStateToProps)(VendorAccount);
