import * as action from "./actions";

export const saveEvent = eventBundle => {
  return {
    type: action.SAVE_EVENT,
    payload: eventBundle
  };
};

const ACTION_HANDLERS = {
  [action.SAVE_EVENT]: (state, action) => {
    return { ...state, ...action.payload };
  }
};

let defaultState = {
  step: 1,
  containsCustom: false,
  newEventRef: null,
  started: 0,
  package: {
    created: null,
    title: "",
    id: null,
    category: {
      Custom: true
    },
    locations: [],
    description: null,
    heroImage: null,
    thumbnail: null,
    zoneId: null,
    creatorInfo: {
      creatorImage: null,
      creatorName: null,
      creatorId: null
    }
  }
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
