import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

let defaultState = {
  ap: {
    packageId: null,
    title: null,
    description: null,
    thumbnail: null,
    heroImage: null,
    creatorId: null,
    creatorImage: null,
    creatorName: null,
    category: null,
  },
};

function* setActivePackageAction(action) {
  const ap = action.payload;
  yield put({
    type: actions.SET_AP,
    payload: {
      ...defaultState,
      ap,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_AP, setActivePackageAction);
}
