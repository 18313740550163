import { times } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ContinuousColorLegend } from 'react-vis';
// Components
import EventInvite from '../components/EventInvite';
import Features from '../components/Features';
import { getAllLocations } from '../sagas/allLocationsSaga/reducer';
// Reducers
import { getInvite } from '../sagas/inviteSaga/reducer';
import { clearInvite } from '../sagas/inviteSaga/reducer';
import { init } from '../sagas/packagesSaga/reducer';

export class Invite extends React.Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      invite: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.invite !== prevState.invite) {
      return {
        invite: nextProps.invite,
        checked: true,
      };
    } else {
      return {
        invite: prevState.invite,
        checked: false,
      };
    }
  }

  getInvite() {
    if (this.state.checked === true) {
      if (this.state.invite.length === 0) {
        return (
          <div className="message-box">
            <h2>Sorry, This Invite Has Been Used.</h2>
            <Link
              to={{
                pathname: '/home',
              }}
            >
              Return Home
            </Link>
          </div>
        );
      } else {
        return (
          <EventInvite
            event={this.state.invite}
            getInvite={this.props.getInvite}
            init={this.props.init}
            getAllLocations={this.props.getAllLocations}
            token={this.props.match.params.id}
            alllocations={this.props.alllocations}
            history={this.props.history}
          />
        );
      }
    } else {
      return (
        <EventInvite
          event={this.state.invite}
          getInvite={this.props.getInvite}
          init={this.props.init}
          getAllLocations={this.props.getAllLocations}
          token={this.props.match.params.id}
          alllocations={this.props.alllocations}
          history={this.props.history}
        />
      );
    }
  }

  render() {
    if (
      this.props.invite[0] !== undefined &&
      this.props.invite[0].token !== this.props.match.params.id
    ) {
      this.props.clearInvite();
    }
    return (
      <div id="invite">
        {this.getInvite()}
        {this.props.user.authenticated === false && (
          <div>
            <div className="red-back">
              <div className="section">
                {/* <h2>So what is Trevel?</h2>
            <p>
              When we started building this company we invisioned
              building something that would bring people together.
              From dates to casual meetups, we wanted to bring you
              something that was easy to use and made it easier for
              everyone to meet up. We are experiencing a time when
              everyone stays home and we believe that going out with
              friends and family is crucial to everyone's well-being.
            </p> */}
              </div>
            </div>
            <Features />
          </div>
        )}
        {this.props.user.authenticated === true && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                ' .maincontent { margin-top: 0px; height: 100%} ',
            }}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    invite: state.invite.event,
    state: state,
    alllocations: state.locations.locations,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInvite: (token) => {
      dispatch(getInvite(token));
    },
    clearInvite: (token) => {
      dispatch(clearInvite(token));
    },
    init: () => {
      dispatch(init());
    },
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
