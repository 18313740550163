import firebase from '../config/firebase';

export function getTimedEvents(zoneId) {
  const promoArray = [];
  const db = firebase.firestore();
  const promos = db.collection('vendor_events');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = promos
        .where('zoneId', '==', zoneId)
        .where('active', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                eventDescription,
                lastUpdated,
                eventTitle,
                eventId,
                eventThumb,
                entertainment,
                eventHero,
                address,
                // tagsDateType: tagsDateType,
                zoneId,
                website,
                settings,
                creationDetails,
                scheduledTime,
                tagsGenre,
                tagsEventType,
                tagsTrevelTags,
                ticketInfo,
              } = doc.data();
              promoArray.push({
                id: doc.id,
                eventDescription,
                lastUpdated,
                eventTitle,
                eventId,
                entertainment,
                eventThumb,
                eventHero,
                address,
                // tagsDateType: tagsDateType,
                zoneId,
                website,
                settings,
                creationDetails,
                scheduledTime,
                tagsGenre,
                tagsEventType,
                tagsTrevelTags,
                ticketInfo,
              });
            }
          });
          return promoArray;
        })
        .then((result) => {
          resolve(promoArray);
        });
    });
  };
  return getP();
}

// export function getTimedEvent(entertainerId) {
//   const promoArray = [];
//   const db = firebase.firestore();
//   const promos = db.collection('vendor_events');
//   const getP = function () {
//     return new Promise((resolve, reject) => {
//       const query = promos
//         .where('zoneId', '==', zoneId)
//         .where('active', '==', true)
//         .get()
//         .then((snapshot) => {
//           snapshot.forEach((doc) => {
//             if (doc && doc.exists) {
//               const {
//                 eventDescription,
//                 lastUpdated,
//                 eventTitle,
//                 eventId,
//                 eventThumb,
//                 entertainment,
//                 eventHero,
//                 address,
//                 // tagsDateType: tagsDateType,
//                 zoneId,
//                 website,
//                 settings,
//                 creationDetails,
//                 scheduledTime,
//                 tagsGenre,
//                 tagsEventType,
//                 ticketInfo,
//               } = doc.data();
//               promoArray.push({
//                 id: doc.id,
//                 eventDescription,
//                 lastUpdated,
//                 eventTitle,
//                 eventId,
//                 entertainment,
//                 eventThumb,
//                 eventHero,
//                 address,
//                 // tagsDateType: tagsDateType,
//                 zoneId,
//                 website,
//                 settings,
//                 creationDetails,
//                 scheduledTime,
//                 tagsGenre,
//                 tagsEventType,
//                 ticketInfo,
//               });
//             }
//           });
//           return promoArray;
//         })
//         .then((result) => {
//           resolve(promoArray);
//         });
//     });
//   };
//   return getP();
// }
