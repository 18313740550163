import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import firebase from '../../config/firebase';
// Reducers
import { setUser } from '../../sagas/loginSaga/reducer';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  verifiedStatus: false,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
export class AdminUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  UNSAFE_componentWillMount() {
    let verifiedStatus = false;
    if (this.props.editableUser.verified === true) {
      verifiedStatus = true;
    }
    this.setState({
      verifiedStatus,
    });
  }

  saveProfile(
    userId,
    firstName,
    lastName,
    userName,
    email,
    verifiedStatus,
  ) {
    const db = firebase.firestore();
    const userToUpdate = db.collection('users').doc(userId);
    const timestamp = new Date();
    const updateDoc = userToUpdate
      .update({
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        email: email,
        // sex: 0,
        // profilePhotoPath: "",
        lastUpdated: timestamp,
        verified: verifiedStatus,
      })
      .then(() => {
        this.props.history.push('/admin/users');
      });
  }

  handleChange = (value) => {
    this.setState({
      verifiedStatus: value.target.checked,
    });
  };

  render() {
    let email = '';
    if (
      this.props.editableUser !== undefined &&
      this.props.editableUser.email !== undefined
    ) {
      email = this.props.editableUser.email;
    }

    return (
      <div>
        <div
          style={{ display: 'block', clear: 'both', padding: '15px' }}
        >
          <div>
            <h4 className="hfour left">Verified</h4>
            <div style={{ width: '20px', float: 'left' }}>
              <input
                type="checkbox"
                checked={this.state.verifiedStatus}
                onChange={this.handleChange}
                className="left"
              />
            </div>
          </div>
        </div>

        <div style={{ display: 'block', clear: 'both' }}>
          <Formik
            initialValues={{
              email: email,
              lastName: this.props.editableUser.lastName,
              firstName: this.props.editableUser.firstName,
              userName: this.props.editableUser.userName,
            }}
            validate={(values) => {
              let errors = {};
              if (!values.firstName) {
                errors.firstName = 'Required';
              }
              return errors;
            }}
            onSubmit={(values) => {
              this.saveProfile(
                this.props.editableUser.userId,
                values.firstName,
                values.lastName,
                values.userName,
                values.email,
                this.state.verifiedStatus,
              );
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Container>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="left"
                    >
                      <br />
                      <div className="border-box greyback">
                        <span>
                          <span className="inputLabel">
                            ID: {this.props.editableUser.userId}
                          </span>
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} sm={6} lg={6} className="left">
                      <h4 className="hfour">Required</h4>

                      <Field
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                      />
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        default={this.props.user.lastName}
                      />
                      <ErrorMessage name="lastName" component="div" />
                      <Field
                        type="text"
                        name="userName"
                        placeholder="User Name"
                      />
                      <ErrorMessage name="userName" component="div" />
                      <Field
                        type="email"
                        name="email"
                        placeholder="Your Email"
                      />
                      <ErrorMessage name="email" component="div" />
                    </Col>
                    <Col xs={6} md={6} sm={6} lg={6}>
                      {/* <h4 className="hfour">Optional</h4>
                      <Field component="select" name="gender">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other?</option>
                      </Field>
                      <ErrorMessage name="firstName" component="div" /> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="left"
                    >
                      <br />
                      <div className="submitarea">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="redbutton"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.invite.event,
    invite: state.invite,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserForm);
