import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// Styles
import './ArticleListItem.css';

class ArticleListItem extends Component {
  getPostDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getTruncatedBody(article) {
    if (article.teaser !== undefined) {
      return article.teaser;
    } else {
      const length = 130;
      const truncatedString =
        article.body.substring(0, length) + '...';
      return truncatedString;
    }
  }

  render() {
    return (
      <div
        style={{
          // background: 'rgba(245, 246, 252, 01)',
          display: 'flex',
        }}
      >
        <Link
          to={{
            pathname: '/article/' + this.props.article.slug,
            state: {
              back: '/home',
            },
          }}
          className="left"
        >
          <CardImg
            top
            width="100%"
            src={this.props.article.hero}
            alt="articleHero"
          />
          <CardBody style={{ textAlign: 'left' }}>
            <div style={{ border: '0px' }}>
              <span className="title">
                {this.props.article.title}
              </span>
              {/* <small className="text-muted right">
                      {this.getPostDate(article.postdate)}
                    </small> */}
              <p className="author">
                {/* {this.props.article.author} -{' '}
              {this.getPostDate(this.props.article.postdate)} */}
                {this.props.article.author}
              </p>
              <CardText>
                <span className="editoOverRide">
                  {this.getTruncatedBody(this.props.article)}
                </span>
              </CardText>
              <CardText />
            </div>
          </CardBody>
        </Link>
      </div>
    );
  }
}

export default ArticleListItem;

// Article Fields Available
// ************************
// article.author
// article.authorId
// article.body
// article.hero
// article.id
// article.postdate
// article.readMore (This is the read more URL)
// article.slug (This is what is used for URL of article)
// article.title
