import { ErrorMessage } from 'formik';
import MaterialIcon from 'material-icons-react';
import React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import TitleBar from '../../components/TitleBar';
import firebase from '../../config/firebase';
import addbtn from '../../images/addbtn.png';
// Reducers
import {
  addPackage,
  resetPackage,
  savePackage,
  updatePackage,
} from '../../sagas/createPackageSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

export class VendorCreateEvent extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      title: '',
      description: '',
    };
  }
  componentDidMount() {
    this.setState({
      title: this.props.packageBundle.package.title,
      description: this.props.packageBundle.package.description,
      loading: false,
    });

    if (this.props.packageBundle.started === 0) {
      this.setState({
        locations: [
          {
            // locationId: this.props.vendor.locations[0].locationId
            locationId: this.props.vendor.locations[0],
          },
        ],
      });
    } else {
      this.setState({
        locations: this.props.packageBundle.package.locations,
      });
    }

    if (this.props.packageBundle.package.heroImage === null) {
      this.setState({
        heroURL: '',
      });
    } else {
      const heroURL =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/test%2F' +
        this.props.profile.vendorAccount.id +
        '%2F' +
        this.props.packageBundle.package.heroImage +
        '?alt=media';
      this.setState({
        heroURL: heroURL,
      });
    }

    if (this.props.packageBundle.package.thumbnail === null) {
      this.setState({
        thumbURL: '',
      });
    } else {
      const thumbURL =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/test%2F' +
        this.props.profile.vendorAccount.id +
        '%2F' +
        this.props.packageBundle.package.thumbnail +
        '?alt=media';

      this.setState({
        thumbURL: thumbURL,
      });
    }
  }

  handleUploadStart = () =>
    this.setState({ isUploading: true, progress: 0 });
  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = (filename) => {
    const uploadUrl = 'test/' + this.props.profile.vendorAccount.id;
    this.setState({
      hero: filename,
      progress: 100,
      isUploading: false,
    });
    firebase
      .storage()
      .ref(uploadUrl)
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        console.log('returned URL', url);
        this.setState({ heroURL: url });
      })
      .then(() => {
        let updatedPackageBundle = this.props.packageBundle;
        updatedPackageBundle.package.heroImage = filename;
        this.props.updatePackage(updatedPackageBundle);
      });
  };

  handleUploadStartThumb = () =>
    this.setState({ isUploading: true, progress: 0 });
  handleProgressThumb = (progress) => this.setState({ progress });
  handleUploadErrorThumb = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccessThumb = (filename) => {
    const uploadUrl = 'test/' + this.props.profile.vendorAccount.id;
    this.setState({
      hero: filename,
      progress: 100,
      isUploading: false,
    });
    firebase
      .storage()
      .ref(uploadUrl)
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        console.log('returned URL', url);
        this.setState({ thumbURL: url });
      })
      .then(() => {
        let updatedPackageBundle = this.props.packageBundle;
        updatedPackageBundle.package.thumbnail = filename;
        this.props.updatePackage(updatedPackageBundle);
      });
  };

  displayForm() {
    const uploadUrl = 'test/' + this.props.profile.vendorAccount.id;
    return (
      <div id="eventDetailsForm">
        <div>
          <div>
            <Container>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12} className="left">
                  <span className="inputLabel">Title</span>
                  <input
                    type="text"
                    onChange={this.titleOnChange.bind(this)}
                    value={this.state.title}
                    className="full-width"
                  />
                  <span className="inputLabel">Description</span>
                  <textarea
                    type="text"
                    onChange={this.descriptionOnChange.bind(this)}
                    value={this.state.description}
                    className="full-width"
                  />
                  <span className="inputLabel">Teaser</span>
                  <textarea
                    type="text"
                    onChange={this.teaserOnChange.bind(this)}
                    value={this.state.teaser}
                    className="full-width"
                  />
                </Col>
              </Row>
            </Container>
            <Container id="imageUpload">
              <Row>
                <Col lg={12} md={6} sm={12} xs={12} className="left">
                  {/* <div className="section no-padding">
              <span className="section-header">Primary Images</span>
            </div> */}

                  <span className="inputLabel">Banner Image</span>
                  {this.state.isUploading && (
                    <p>Progress: {this.state.progress}</p>
                  )}
                  {this.state.heroURL && (
                    <img
                      src={this.state.heroURL}
                      className="heroInputImage"
                    />
                  )}
                  <FileUploader
                    accept="image/*"
                    name="hero"
                    randomizeFilename
                    storageRef={firebase.storage().ref(uploadUrl)}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                    className="imageUpload"
                  />

                  <ErrorMessage name="locationHero" component="div" />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="left">
                  <span className="inputLabel">Thumbnail Image</span>
                  {this.state.isUploading && (
                    <p>Progress: {this.state.progressThumb}</p>
                  )}
                  {this.state.heroURL && (
                    <img
                      src={this.state.thumbURL}
                      className="heroInputImage"
                    />
                  )}
                  <FileUploader
                    accept="image/*"
                    name="thumb"
                    randomizeFilename
                    storageRef={firebase.storage().ref(uploadUrl)}
                    onUploadStart={this.handleUploadStartThumb}
                    onUploadError={this.handleUploadErrorThumb}
                    onUploadSuccess={this.handleUploadSuccessThumb}
                    onProgress={this.handleProgressThumb}
                    className="imageUpload"
                  />
                  <ErrorMessage
                    name="locationThumb"
                    component="div"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  createEvent() {
    // const timestamp = new Date();
    // let newEvent = {
    //   created: timestamp,
    //   title: packageBundle.package.title,
    //   id: dbref,
    //   category: {
    //     Custom: false
    //   },
    //   locations: locationArray,
    //   description: packageBundle.package.description,
    //   heroImage: packageBundle.package.heroImage,
    //   thumbnail: packageBundle.package.thumbnail,
    //   zoneId: 1,
    //   creatorInfo: {
    //     creatorImage: profile.profilePhotoPath,
    //     creatorName: fullname,
    //     creatorId: profile.userId,
    //     type: 3,
    //     vendorId: profile.vendorAccount.id,
    //     vendorBusinessName: vendor.businessName
    //   }
    // }
  }

  //   getLocations(activeEvent) {
  //     const packageDetails = query("packageId")
  //       .is(activeEvent.packageDetails.packageId)
  //       .on(this.props.packages);
  //     return packageDetails[0].locations;
  //   }

  getLocationDetails(location, locations, index, editMode) {
    const currentIndex = index;
    let locationReady = '';
    if (location.type === 1) {
      locationReady = location.location;
    } else {
      const locationDetails = query('locationId')
        .is(location.locationId)
        .on(locations);
      locationReady = locationDetails[0];
    }
    // console.log("locationReady", locationReady);
    // console.log("locationReady", location);
    // console.log("locationReady", locations);
    return (
      <div>
        <Row className="oddeven">
          <Col
            xs={10}
            md={10}
            sm={10}
            lg={10}
            key={'key' + index}
            className="no-padding"
          >
            <Link
              to={{
                pathname: '/location',
                state: {
                  location: { locationReady },
                  // eventId: { eventId },
                  // token: { token },
                  // type: { type }
                },
              }}
            >
              <div className="full-height left">
                <img
                  src={
                    'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
                    locationReady.locationThumb +
                    '?alt=media'
                  }
                  alt={locationReady.locationTitle}
                  className="thumbnail"
                />
              </div>
              <span className="invite-label">
                {locationReady.locationTitle}
              </span>
              <br />
              <span className="address">
                {locationReady.address.street}
              </span>
              <br />
              <span className="address">
                {locationReady.address.city}{' '}
                {locationReady.address.state},{' '}
                {locationReady.address.zip}
              </span>
            </Link>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2} key={'key' + index}>
            <div className="right text-right">
              {this.getRemoveButton(index, locationReady.locationId)}
            </div>
            {/* {this.getEditButton(editMode, currentIndex)} */}
          </Col>
        </Row>
      </div>
    );
  }

  getRemoveButton(index, locationId) {
    if (locationId === this.props.vendor.locations[0].locationId) {
      return;
    } else {
      return (
        <MaterialIcon
          icon="delete"
          size={20}
          color="#f21d59"
          onClick={() => {
            this.removeLocation(index, this.props.packageBundle);
          }}
        />
      );
    }
  }

  //   getEditButton(editMode, index) {
  //     const currentIndex = index;
  //     console.log("customidex1", index);
  //     if (editMode === true) {
  //       return (
  //         <div>
  //           <Link
  //             to={{
  //               pathname: "/event/create/edit/",
  //               state: {
  //                 index: currentIndex,
  //                 editMode: true
  //               }
  //             }}
  //           >
  //             <MaterialIcon icon="edit" size={20} color="#f21d59" />
  //           </Link>
  //         </div>
  //       );
  //     }
  //   }

  removeLocation(i, packageBundle) {
    const locations = packageBundle.package.locations;
    if (locations.length === 1) {
      // this.scaleAnimationDialog.show();
      // this.setState({
      //   loading: false
      // });
    } else {
      locations.splice(i, 1);
      packageBundle.package.locations = locations;
      this.props.updatePackage(packageBundle);
    }
  }

  getFooter(mode) {
    return (
      <Container>
        <Row className="no-margin">
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <div>
              <span
                className="footer-link left"
                onClick={() => {
                  this.resetPackage();
                }}
              >
                Cancel
              </span>
            </div>
          </Col>
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <span
              className="right footer-link"
              onClick={() => {
                this.createPackage(
                  this.props.profile,
                  this.props.packageBundle,
                  this.props.locations,
                  this.props.vendor,
                );
              }}
            >
              Save
            </span>
          </Col>
        </Row>
      </Container>
    );
  }

  updateAndAdd(packageBundle, placement) {
    let updatedPackageBundle = packageBundle;
    if (updatedPackageBundle.started === 0) {
      updatedPackageBundle.package.locations = this.state.locations;
    }
    updatedPackageBundle.package.title = this.state.title;
    updatedPackageBundle.package.description = this.state.description;
    updatedPackageBundle.started = 1;
    updatedPackageBundle.placement = placement;
    this.props.addPackage(updatedPackageBundle);
  }

  titleOnChange(e) {
    this.setState({ title: e.target.value });
  }

  descriptionOnChange(e) {
    this.setState({ description: e.target.value });
  }

  teaserOnChange(e) {
    this.setState({ teaser: e.target.value });
  }

  resetPackage() {
    this.props.resetPackage();
  }

  createPackage(profile, packageBundle, locations, vendor) {
    const db = firebase.firestore();
    const newEventRef = db.collection('packages').doc();
    const dbref = newEventRef.id;
    const fullname = profile.firstName + ' ' + profile.lastName;
    const timestamp = new Date();
    // Prepare Locations
    const locationArray = [];
    // Check if there are any new locations in the event and if so add to DB
    const eventLocations = packageBundle.package.locations;
    eventLocations.forEach((location, i) => {
      if (location.type === 1) {
        const db = firebase.firestore();
        const newLocationRef = db.collection('locations').doc();
        const dbref = newLocationRef.id;
        locationArray[i] = {
          locationId: dbref,
          locationTitle: location.location.locationTitle,
          locationType: 1,
        };
        this.uploadLocation(
          location.location,
          dbref,
          profile.vendorAccount.id,
          vendor.businessName,
        );
      } else {
        const locationDetails = query('locationId')
          .is(location.locationId)
          .on(locations);
        locationArray[i] = {
          locationId: locationDetails[0].locationId,
          locationTitle: locationDetails[0].locationTitle,
          locationType: 0,
        };
      }
    });
    // Create Package
    const newPackage = {
      created: timestamp,
      title: packageBundle.package.title,
      id: dbref,
      category: {
        Custom: false,
      },
      locations: locationArray,
      description: packageBundle.package.description,
      heroImage: packageBundle.package.heroImage,
      thumbnail: packageBundle.package.thumbnail,
      zoneId: 1,
      creatorInfo: {
        creatorImage: profile.profilePhotoPath,
        creatorName: fullname,
        creatorId: profile.userId,
        type: 3,
        vendorId: profile.vendorAccount.id,
        vendorBusinessName: vendor.businessName,
      },
    };
    this.props.savePackage(newPackage);
  }

  uploadLocation(location, id, vendorId, vendorBusinessName) {
    const newLocation = Object.assign({}, location, {
      locationId: id,
      custom: true,
      vendorReferral: true,
      vendorReferralInfo: {
        vendorId: vendorId,
        vendorBusinessName: vendorBusinessName,
      },
    });
    const db = firebase.firestore();
    const setDoc = db
      .collection('locations')
      .doc(id)
      .set(newLocation)
      .then((ref) => {
        console.log(ref);
      });
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div>
        <TitleBar
          title="Create Recommended Itinerary"
          backlink="/vendor/events/create"
        />
        <div id="events" className="section bottom-push grey-back">
          {/* <div className="header-container">
            <div className="header text-center">
              <h1>Edit Your Event</h1>
            </div>
          </div> */}
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              {this.displayForm()}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <span className="inputLabel">Event Itinerary</span>
              <div className="clear">
                <a
                  onClick={() => {
                    this.updateAndAdd(this.props.packageBundle, 0);
                  }}
                >
                  <img src={addbtn} className="left" />
                </a>
              </div>
              <div className="clear">
                {this.state.locations.map((location, index) => (
                  <div>
                    {this.getLocationDetails(
                      location,
                      this.props.locations,
                      index,
                      true,
                    )}
                  </div>
                ))}
              </div>
              <div className="clear">
                <a
                  onClick={() => {
                    this.updateAndAdd(this.props.packageBundle, 1);
                  }}
                >
                  <img src={addbtn} className="left" />
                </a>
              </div>
            </Col>
          </Row>
        </div>
        <div className="footer">{this.getFooter()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customEvent: state.customEvent.customEvent,
    locations: state.locations.locations,
    events: state.events.events,
    packages: state.packages.packages,
    profile: state.profile.profile,
    packageBundle: state.newPackage,
    vendor: state.vendor.vendor,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePackage: (newPackage) => {
      dispatch(updatePackage(newPackage));
    },
    savePackage: (newPackage) => {
      dispatch(savePackage(newPackage));
    },
    addPackage: (newPackage) => {
      dispatch(addPackage(newPackage));
    },
    resetPackage: (newPackage) => {
      dispatch(resetPackage(newPackage));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorCreateEvent);
