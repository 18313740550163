import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as actions from "./actions";

let defaultState = {
  checkoutBundle: {
    status: 1,
    selectedVouchers: [],
  },
};

function* setCheckoutBundle(action) {
  const checkoutBundle = action.payload;
  yield put({
    type: actions.SET_CHECKOUT,
    payload: {
      ...defaultState,
      checkoutBundle,
    },
  });
  yield put(push("/checkout/review"));
}

function* setCustomCheckoutBundle(action) {
  const checkoutBundle = action.payload;
  yield put({
    type: actions.SET_CUSTOMCHECKOUT,
    payload: {
      ...defaultState,
      checkoutBundle,
    },
  });
  yield put(push("/event/create/review"));
}

export default function* sagas() {
  yield takeLatest(actions.GET_CHECKOUT, setCheckoutBundle);
  yield takeLatest(actions.GET_CUSTOMCHECKOUT, setCustomCheckoutBundle);
}
