import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import { push } from "connected-react-router";
import { saveEvent } from "../../services/PackagesServices";
import { init as getPackages } from "../packagesSaga/reducer";
import { getAllLocations } from "../allLocationsSaga/reducer";
import { getVendorEvents } from "../vendorEventsSaga/reducer";

function* saveEventAction(action) {
  yield call(saveEvent, action.payload);
  yield put(getVendorEvents(action.payload.creatorInfo.vendorId));
  yield put(push("/vendor/events"));
}

export default function* sagas() {
  yield takeLatest(actions.SAVE_EVENT, saveEventAction);
}
