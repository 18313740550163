import React from 'react';
import { connect } from 'react-redux';
// Components
import SignUpBanner from '../components/SignUpBanner';
import { Col, Row, Container } from 'reactstrap';
import Features from '../components/Features';
import RedCallToAction from '../components/RedCallToAction';
import { clearUauth } from '../sagas/unauthenticatedSaga/reducer';
import { Link } from 'react-router-dom';
// Reducers
import { getArticles } from '../sagas/articleSaga/reducer';

export class About extends React.Component {
  UNSAFE_componentWillMount() {
    document.title = 'Trevel';
    if (this.props.user.authenticated === true) {
      this.props.history.push('/home');
    }
  }
  render() {
    return (
      <div id="home">
        <div className="intro" id="bannerIntro">
          <div className="wrapperShort wide top">
            <img
              className="intro-image mobileHide"
              height="527"
              src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fbanner1.png?alt=media&token=844aca0c-a108-4140-a566-6487a4f65344"
              width="609"
              alt="intro"
            />
            <div className="hero-wrapper mobileHide">
              <h2>
                <b>
                  Welcome! I am your personal consergire here to help
                  you revel in your life.
                </b>
              </h2>
              <h3 style={{ color: 'white', fontSize: '24px' }}>
                Here are some ways I can help:
              </h3>
              <ul>
                <li>
                  <span className="subline">
                    I can make sure those first dates at least have
                    great food you will love. No matter what mood you
                    are in.
                  </span>
                </li>
                <li>
                  <span className="subline">
                    I can elimante the 'What do you want to eat?'
                    experience from your life when you are making
                    plans with others.
                  </span>
                </li>
                <li>
                  <span className="subline">
                    I can help you turn dining and attending events
                    into excurions that you will love.{' '}
                  </span>
                </li>
              </ul>

              {/* <Link
                to={{
                  pathname: '/signup',
                }}
                className="button whiteButton"
              >
                Create Free Account
              </Link> */}
              {/* <Link
                to={{
                  pathname: "/beta",
                }}
                className="button outlined-white"
              >
                Download Trevel
              </Link> */}
            </div>
            <div className="hero-wrapperFull mobileShow">
              <h2>
                <b>
                  Welcome! I am your personal concierge, here to help
                  you revel in your life.
                </b>
              </h2>
              <h3 style={{ color: 'white', fontSize: '24px' }}>
                Here are some ways I can help:
              </h3>
              <ul>
                <li>
                  <span className="subline">
                    I can make sure those first dates at least have
                    great food you will love. No matter what mood you
                    are in.
                  </span>
                </li>
                <li>
                  <span className="subline">
                    I can elimante the 'What do you want to eat?'
                    experience from your life when you are making
                    plans with others.
                  </span>
                </li>
                <li>
                  <span className="subline">
                    I can help you turn dining and attending events
                    into excurions that you will love.{' '}
                  </span>
                </li>
              </ul>

              {/* <Link
                to={{
                  pathname: '/signup',
                }}
                className="button whiteButton"
              >
                Create Free Account
              </Link> */}
              {/* <Link
                to={{
                  pathname: "/beta",
                }}
                className="button outlined-white"
              >
                Download Trevel
              </Link> */}
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: '3px solid black',
            padding: '30px 10px, 30px 10px',
          }}
        >
          <div
            style={{
              display: 'block',
              padding: '10px',
              width: '95%',
              margin: '0px auto',
            }}
          >
            <Container style={{ maxWidth: '750px' }}>
              <Row>
                <Col>
                  <center>
                    <h4
                      style={{
                        marginBottom: '20px',
                        fontSize: '24px',
                        fontWeight: '500',
                        marginTop: '30px',
                      }}
                    >
                      Why are we only available in St. Louis and
                      Kansas City?
                    </h4>
                  </center>

                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginBottom: '10px',
                    }}
                  >
                    During the pandemic, we saw many of our favorite
                    local businesses and entertainers struggeling, so
                    we decided to take action and began building
                    Trevel as a way to help.
                  </span>

                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginBottom: '10px',
                    }}
                  >
                    St. Louis and Kansas City are our hometowns, and
                    while we do plan on expanding, we felt it was only
                    right to start in the towns where we grew up and
                    still live.
                  </span>
                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginBottom: '10px',
                    }}
                  >
                    We hope Trevel can help bring us all back together
                    again doing the things we all love doing.
                  </span>
                  <span
                    style={{
                      marginTop: '20px',
                      display: 'block',
                    }}
                  >
                    Let there be music!
                  </span>
                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginBottom: '30px',
                      marginTop: '10px',
                      fontWeight: '500',
                    }}
                  >
                    Mike & Mark
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Features />
        <RedCallToAction />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearUauth: () => {
      dispatch(clearUauth());
    },
    getArticles: (article) => {
      dispatch(getArticles(article));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
