import * as action from './actions';

export const setFavorites = (favorites) => {
  return {
    type: action.SET_FAVORITES,
    payload: favorites,
  };
};

const ACTION_HANDLERS = {
  [action.SET_FAVORITES]: (state, action) => {
    return { ...state };
  },
};

let defaultState = {};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
