import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import { confirmAlert } from 'react-confirm-alert';
import {
  Card,
  CardBody,
  CardImg,
  Col,
  Container,
  Row,
} from 'reactstrap';
// Reducers
import {
  startEvent,
  resetEvent,
} from '../../sagas/activeEventSaga/reducer';
import { getNewEvent } from '../../sagas/createEventSaga/reducer';
import {
  approveEvent,
  editMode,
  rejectEvent,
} from '../../sagas/customEventSaga/reducer';
import { getLocations } from '../../sagas/locationsSaga/reducer';
import ConversationModal from '../ConversationModal';
import GuestListModal from '../GuestListModal';
// Components
import LocationListItem from '../LocationListItem';
import ScheduleModal from '../ScheduleModal';
import { finishEvent } from '../../sagas/userEventsSaga/reducer';
// Styles
import './EventDetails.css';

const query = require('array-query');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
  },
};

const conversationModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    height: '100%',
    width: '100%',
  },
};

class EventDetails extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      guestListModalVisible: false,
      scheduleModalVisible: false,
      locations: [],
    };
    this.openGuestListModal = this.openGuestListModal.bind(this);
    this.closeGuestListModal = this.closeGuestListModal.bind(this);
    this.openScheduleModal = this.openScheduleModal.bind(this);
    this.closeScheduleModal = this.closeScheduleModal.bind(this);
    this.openConversationModal =
      this.openConversationModal.bind(this);
    this.closeConversationModal =
      this.closeConversationModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.resetEvent();
  }

  getSchedule() {
    console.log('yepp', this.props.event);
    let atsign = '';
    const dayText = moment
      .utc(this.props.event.scheduledTime.timestamp)
      .format('dddd');
    const dateText = moment
      .utc(this.props.event.scheduledTime.timestamp)
      .format('Do');
    const monthText = moment
      .utc(this.props.event.scheduledTime.timestamp)
      .format('MMM');
    if (
      this.props.event.scheduledTime.time !== '' &&
      this.props.event.scheduledTime.time !== null
    ) {
      atsign = '@';
    }

    if (this.props.event.status === 11) {
      return (
        <span style={{ fontWeight: '600' }}>
          This event has been canceled
        </span>
      );
    } else if (
      this.props.event.scheduledTime.date === '' ||
      this.props.event.scheduledTime.date === null
    ) {
      return 'This event is not scheduled yet.';
    } else {
      return (
        <span>
          {dayText} {monthText} {dateText} {atsign}{' '}
          {this.props.event.scheduledTime.time}
        </span>
      );
    }
  }

  getHero(event) {
    let heroURL = '';
    if (
      event.eventSettings.customHero === false ||
      event.eventSettings.customHero === ''
    ) {
      heroURL =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
        event.heroImage +
        '?alt=media';
    } else {
      heroURL =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
        event.heroImage +
        '?alt=media';
    }
    return heroURL;
  }

  openGuestListModal() {
    this.setState({ guestListModalVisible: true });
  }

  closeGuestListModal() {
    this.setState({ guestListModalVisible: false });
  }

  openScheduleModal() {
    this.setState({ scheduleModalVisible: true });
  }

  closeScheduleModal() {
    this.setState({ scheduleModalVisible: false });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  // Conversation Functions
  openConversationModal() {
    this.setState({ conversationModalVisible: true });
  }

  closeConversationModal() {
    this.setState({ conversationModalVisible: false });
  }

  recreate(event) {
    let readyLocations = [];

    this.props.event.packageDetails.locations.forEach((location) => {
      readyLocations.push({
        type: 0,
        locationId: location.locationId,
        locationTitle: location.locationTitle,
        locationThumb: location.locationThumb,
        locationHero: location.locationHero,
        address: location.address,
      });
    });

    let newEvent = {};
    const usersObject = {};
    // Setup newEvent
    newEvent = {
      connectFirst: false,
      clear: false,
      step: 4,
      recreate: true,
    };
    // Set Origionally Invites
    Object.keys(event.users).forEach((key) => {
      usersObject[key] = {
        access: true,
        approved: false,
        editor: true,
        attending: false,
        rejected: false,
        inviteAction: false,
        newUser: false,
        profile: event.users[key].profile,
      };
    });
    newEvent.connections = usersObject;
    // Create New Custom Event
    let customEvent = {
      clear: false,
      step: 1,
      containsCustom: false,
      newEventRef: null,
      editMode: false,
      event: {
        description: this.props.event.description,
        title: this.props.event.title,
        status: 0,
        locations: readyLocations,
        creationDetails: {
          plannerId: null,
          plannerPhoto: null,
          plannerFirstName: null,
          plannerLastName: null,
          plannerUserName: null,
          creationDate: null,
        },
        scheduledTime: {
          date: null,
          timestamp: null,
          timeString: null,
        },
        usersAttending: null,
        eventSettings: {
          itinerarySent: false,
          scheduled: false,
        },
        packageDetails: {
          packageId: null,
        },
      },
    };
    newEvent.customEvent = customEvent;
    this.props.getNewEvent(newEvent);
  }

  getLocations() {
    let readyLocations = [];
    this.props.event.packageDetails.locations.forEach((location) => {
      let locationReady = {};
      if (
        location.locationId !== 'PQszNY3Mpy8oJXTQMooB' &&
        location.locationId !== 'WkhbxzFvfPjnDTXCBVyr' &&
        location.locationId !== '-LKT3cWR6JqNdUTEST'
      ) {
        if (location.locationId !== null) {
          const locationDetails = query('locationId')
            .is(location.locationId)
            .on(this.props.locations);
          locationReady = locationDetails[0];
        } else {
          locationReady = location;
        }
      } else {
        locationDetails = query('locationId')
          .is(location.locationId)
          .on(this.props.demo.demoLocations);
        locationReady = locationDetails[0];
      }
      if (locationReady !== undefined) {
        readyLocations.push({
          type: 0,
          locationId: locationReady.locationId,
          locationTitle: locationReady.locationTitle,
          locationThumb: locationReady.locationThumb,
          locationHero: locationReady.locationHero,
          address: locationReady.address,
          locationDescription: locationReady.locationDescription,
          phone: locationReady.phone,
          website: locationReady.website,
          happyHours: locationReady.happyHours,
          settings: locationReady.settings,
          tagsBeverages: locationReady.tagsBeverages,
          tagsCuisine: locationReady.tagsCuisine,
          tagsDateType: locationReady.tagsDateType,
          tagsHours: locationReady.tagsHours,
          tagsMain: locationReady.tagsMain,
          tagsSetting: locationReady.tagsSetting,
        });
      }
    });
    // const packageDetails = query("packageId")
    //   .is(this.props.event.packageDetails.packageId)
    //   .on(this.props.packages);

    if (this.props.event.packageDetails.locations.length !== 0) {
      return (
        <div className="no-padding">
          <LocationListItem
            // locations={packageDetails[0].locations}
            locations={readyLocations}
            eventId={this.props.event.eventId}
            type={75}
          />
        </div>
      );
    } else {
      return (
        <div>
          <center>
            <span>
              You will need to add at least one location before you
              can start this event.
            </span>
          </center>
        </div>
      );
    }
  }

  startEvent(event) {
    this.props.startEvent(event);
  }

  approveEvent(event, profile) {
    const bundle = {};
    bundle.event = event;

    // Update History
    const updatedHistory = event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 2,
      fullName: profile.userName,
      userId: profile.userId,
    };
    updatedHistory.push(newHistory);
    bundle.event.editHistory = updatedHistory;

    // SET USERS
    const usersObject = event.users;

    usersObject[profile.userId].approved = true;
    usersObject[profile.userId].attending = true;

    // Object.keys(usersObject).forEach((key) => {
    //   newUsersObject[key] = {
    //     access: usersObject[key].access,
    //     editor: usersObject[key].editor,
    //     approved: true,
    //     attending: true,
    //     profile: {
    //       profilePhotoPath: usersObject[key].profile.profilePhotoPath,
    //       fullName: usersObject[key].profile.fullName,
    //       userName: usersObject[key].profile.userName,
    //     },
    //   };
    // });

    bundle.event.users = usersObject;
    this.props.approveEvent(bundle);
  }

  rejectEvent(event, profile) {
    const bundle = {};
    bundle.event = event;

    // Update History
    const updatedHistory = event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 3,
      fullName: profile.userName,
      userId: profile.userId,
    };
    updatedHistory.push(newHistory);
    bundle.event.editHistory = updatedHistory;

    // SET USERS
    const userId = profile.userId;
    const usersObject = event.users;
    const newUsersObject = {};
    Object.keys(usersObject).forEach((key) => {
      if (key === userId) {
        newUsersObject[key] = {
          access: false,
          editor: false,
          approved: false,
          attending: false,
          rejected: true,
          profile: {
            profilePhotoPath:
              usersObject[key].profile.profilePhotoPath,
            fullName: usersObject[key].profile.fullName,
            userName: usersObject[key].profile.userName,
          },
        };
      } else {
        newUsersObject[key] = {
          access: usersObject[key].access,
          editor: usersObject[key].editor,
          approved: usersObject[key].approved,
          attending: usersObject[key].attending,
          profile: {
            profilePhotoPath:
              usersObject[key].profile.profilePhotoPath,
            fullName: usersObject[key].profile.fullName,
            userName: usersObject[key].profile.userName,
          },
        };
      }
    });
    bundle.event.users = newUsersObject;

    this.props.rejectEvent(bundle);
  }

  getEditLink(event, userId, link) {
    return (
      <a
        onClick={() => {
          this.editMode(this.props.event);
        }}
      >
        <span className="center solidredbutton full-width">
          EDIT{' '}
        </span>
      </a>
    );
  }

  getAcceptLink(event, userId, link) {
    if (event.users !== undefined) {
      if (event.users[userId].approved === true) {
        return (
          // <a href={url} className="right marginLeftTen">
          //   <MaterialIcon icon="thumb_up" size={20} color="#00a927" />
          // </a>

          <span className="center solidgreybutton full-width">
            WAITING
          </span>
        );
      } else {
        if (link === true) {
          return (
            <a
              onClick={() => {
                this.approveEvent(event, this.props.profile);
              }}
            >
              <span className="center solidredbutton full-width">
                APPROVE
              </span>
            </a>
          );
        } else {
          if (event.users[userId].rejected !== true) {
            return <span className="right">WAITING</span>;
          }
        }
      }
    }
  }

  getRejectLink(event, userId, link) {
    if (event.users !== undefined) {
      const url = '/event/edit/' + userId;
      if (event.users[userId].rejected === true) {
        return <span className="right">REJECTED</span>;
      } else {
        if (event.users[userId].approved === true) {
          if (link === true) {
            return (
              <a
                onClick={() => {
                  this.rejectEvent(event, this.props.profile);
                }}
              >
                <span className="center solidredbutton full-width">
                  REJECT
                </span>
              </a>
            );
          } else {
            // return (
            //   <a href={url} className="right marginLeftTen">
            //     <MaterialIcon icon="thumb_down" size={20} color="#e0e0e0" />
            //   </a>
            // );
          }
        } else {
          if (link === true) {
            return (
              <a
                onClick={() => {
                  this.rejectEvent(event, this.props.profile);
                }}
              >
                <span className="center solidredbutton full-width">
                  REJECT
                </span>
              </a>
            );
          } else {
            // return (
            //   <a href={url} className="right marginLeftTen">
            //     <MaterialIcon icon="thumb_down" size={20} color="#e0e0e0" />
            //   </a>
            // );
          }
        }
      }
    }
  }

  getUserStatusBorder(planner) {
    let className = 'unapprovedBorder';
    if (planner.canceled === true) {
      className = 'rejectedBorder';
    } else if (planner.approved === true) {
      className = 'approvedBorder';
    } else if (
      planner.rejected !== undefined &&
      planner.rejected === true
    ) {
      className = 'rejectedBorder';
    }
    return className;
  }

  getUserStatusName(planner) {
    let className = 'unapprovedName';
    if (planner.canceled === true) {
      className = 'rejectedName';
    } else if (planner.approved === true) {
      className = 'approvedName';
    } else if (
      planner.rejected !== undefined &&
      planner.rejected === true
    ) {
      className = 'rejectedName';
    }
    return className;
  }

  getPlanners(planners, profile) {
    const userProfile = profile;
    const plannerArray = [];
    Object.keys(planners).forEach((key) => {
      if (key !== userProfile.userId) {
        let firstName = planners[key].profile.fullName.replace(
          / .*/,
          '',
        );
        let rejected = false;
        if (planners[key].access === true) {
          plannerArray.push({
            firstName,
            id: [key],
            fullName: planners[key].profile.fullName,
            profilePhotoPath: planners[key].profile.profilePhotoPath,
            approved: planners[key].approved,
            rejected: planners[key].rejected,
            canceled: planners[key].canceled,
            finished: planners[key].finished,
          });
        }
      }
    });
    if (plannerArray.length > 0 && plannerArray.length < 7) {
      return (
        <div>
          {plannerArray.map((planner, index) => (
            <div
              style={{
                float: 'left',
                margin: '0px 10px 7px 0px',
              }}
            >
              {/* <a
                  className="action-icon right"
                  onClick={() => {
                    this.openConversationModal();
                  }}
                >
                  <MaterialIcon icon="sms" size={15} color="white" />
                </a> */}
              {/* <Row>
                  <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
                    <span>You are planning this event with:</span>
                  </Col>
                </Row> */}

              <div
                // className={
                //   planner.approved === true
                //     ? 'approvedBorder'
                //     : 'unapprovedBorder'
                // }
                className={this.getUserStatusBorder(planner)}
              >
                <UserAvatar
                  size="35"
                  name={planner.fullName}
                  src={planner.profilePhotoPath}
                />
              </div>
              <span className={this.getUserStatusName(planner)}>
                {planner.firstName}
              </span>
              {planner.canceled && (
                <span style={{ fontWeight: '600' }}>CANCELED</span>
              )}
            </div>
          ))}
          <style
            dangerouslySetInnerHTML={{
              __html: '#event-body{top:60px; position: relative;} ',
            }}
          />
        </div>
      );
    } else if (plannerArray.length > 0 && plannerArray.length < 20) {
      return (
        <div>
          {plannerArray.map((planner, index) => (
            <div
              style={{
                float: 'left',
                margin: '0px 10px 7px 0px',
              }}
            >
              {/* <a
                  className="action-icon right"
                  onClick={() => {
                    this.openConversationModal();
                  }}
                >
                  <MaterialIcon icon="sms" size={15} color="white" />
                </a> */}
              {/* <Row>
              <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
                <span>You are planning this event with:</span>
              </Col>
            </Row> */}
              {/* <Row className="plannerName"> */}
              {/* <Col xs={4} md={4} sm={4} lg={4} className="no-padding"> */}
              <div className={this.getUserStatusBorder(planner)}>
                {/* <span
                      className="icon-title"
                      style={{
                        color:
                          planner.approved === false
                            ? 'red'
                            : 'green',
                      }}
                    >
                      {planner.firstName}
                    </span> */}
                <UserAvatar
                  size="35"
                  name={planner.fullName}
                  src={planner.profilePhotoPath}
                />
              </div>
              {/* </Col> */}
              {/* </Row> */}
            </div>
          ))}
          <style
            dangerouslySetInnerHTML={{
              __html: '#event-body{top:60px; position: relative;} ',
            }}
          />
        </div>
      );
    } else if (plannerArray.length > 0 && plannerArray.length > 20) {
      return (
        <div>
          {plannerArray.map((planner, index) => (
            <div className="planners section">
              <Container>
                {/* <a
                  className="action-icon right"
                  onClick={() => {
                    this.openConversationModal();
                  }}
                >
                  <MaterialIcon icon="sms" size={15} color="white" />
                </a> */}
                {/* <Row>
              <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
                <span>You are planning this event with:</span>
              </Col>
            </Row> */}
                <Row className="plannerName">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div>
                      <span>
                        <b>{plannerArray.length}</b> Guests
                      </span>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
          <style
            dangerouslySetInnerHTML={{
              __html: '#event-body{top:60px; position: relative;} ',
            }}
          />
        </div>
      );
    }
  }

  getStartNowButton() {
    if (this.props.event.packageDetails.locations.length !== 0) {
      return (
        <a
          onClick={() => {
            this.startEvent(this.props.event);
          }}
        >
          <span className="center solidredbutton">START NOW!</span>
        </a>
      );
    } else {
      return (
        <span className="center solidgreybuttonfull fullWidth">
          START NOW!
        </span>
      );
    }
  }

  getFooter(event, profile) {
    if (
      event.status === 0 &&
      event.users[this.props.profile.userId].finished !== true &&
      event.users[this.props.profile.userId].canceled !== true
    ) {
      return (
        <div id="startBar">
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <center>{this.getStartNowButton()}</center>
            </Col>
          </Row>
        </div>
      );
    } else if (event.status === 2) {
      return (
        <div id="startBar">
          <Container>
            <Row className="no-margin ">
              <div className="mobileHide" style={{ width: '100%' }}>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  style={{ padding: '0px 10px 0px 20px' }}
                >
                  <center>
                    {this.getAcceptLink(event, profile.userId, true)}
                  </center>
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  style={{ padding: '0px 10px 0px 10px' }}
                >
                  <center>
                    {this.getEditLink(event, profile.userId, true)}
                  </center>
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  style={{ padding: '0px 20px 0px 10px' }}
                >
                  <center>
                    {this.getRejectLink(event, profile.userId, true)}
                  </center>
                </Col>
              </div>
              <div className="mobileShow" style={{ width: '100%' }}>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  style={{ padding: '0px 5px 0px 0px' }}
                >
                  <center>
                    {this.getAcceptLink(event, profile.userId, true)}
                  </center>
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  style={{ padding: '0px 5px 0px 5px' }}
                >
                  <center>
                    {this.getEditLink(event, profile.userId, true)}
                  </center>
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  style={{ padding: '0px 0px 0px 5px' }}
                >
                  <center>
                    {this.getRejectLink(event, profile.userId, true)}
                  </center>
                </Col>
              </div>
            </Row>
          </Container>
        </div>
      );
    } else if (event.status === 3) {
      return (
        <div id="startBar">
          <Container>
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <center>
                  <a
                    onClick={() => {
                      this.confirmDelete(this.props.event);
                    }}
                  >
                    <span className="center solidredbutton">
                      DELETE REJECTED EVENT
                    </span>
                  </a>
                </center>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (event.status === 4) {
      return (
        <div id="startBar">
          <Container>
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <center>
                  <a
                  // onClick={() => {
                  //   this.startEvent(this.props.event);
                  // }}
                  >
                    <span className="center solidredbutton">
                      WAITING
                    </span>
                  </a>
                </center>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (event.status === 5) {
      return (
        <div id="startBar">
          <Container>
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <center>
                  <a
                    onClick={() => {
                      this.confirmDelete(this.props.event);
                    }}
                  >
                    <span className="center solidredbutton">
                      DELETE CANCELED EVENT
                    </span>
                  </a>
                </center>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (
      event.status === 9 ||
      event.users[this.props.profile.userId].finished === true
    ) {
      return (
        <div id="startBar">
          <Container>
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <center>
                  <a
                    onClick={() => {
                      this.recreate(this.props.event);
                    }}
                  >
                    <span className="center solidredbutton">
                      Recreate the Magic
                    </span>
                  </a>
                </center>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

  getTitleBar(event) {
    let backLink = '';
    if (event.status === 0) {
      backLink = '/events/upcoming';
    } else if (event.status === 9) {
      backLink = '/events/completed';
    } else {
      backLink = '/events/upcoming';
    }
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: backLink,
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Event Details</span>
              </center>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span
                className="right"
                style={{ marginLeft: '7px', cursor: 'pointer' }}
              >
                {this.props.event.status !== 2 &&
                  (this.props.profile.userId ===
                    this.props.event.creationDetails.plannerId) !==
                    true && (
                    <MaterialIcon
                      icon="delete"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.confirmDelete(this.props.event);
                      }}
                    />
                  )}
                {this.props.profile.userId ===
                  this.props.event.creationDetails.plannerId && (
                  <MaterialIcon
                    icon="delete"
                    size={25}
                    color="white"
                    onClick={() => {
                      this.confirmDelete(this.props.event);
                    }}
                  />
                )}
              </span>
              <span className="right" style={{ cursor: 'pointer' }}>
                {this.props.event.users[this.props.profile.userId]
                  .editor === true &&
                  this.props.event.users[this.props.profile.userId]
                    .finished !== true && (
                    <MaterialIcon
                      icon="edit"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.editMode(this.props.event);
                      }}
                    />
                  )}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  confirmDelete(event) {
    let planner = false;
    if (
      this.props.profile.userId === event.creationDetails.plannerId
    ) {
      planner = true;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h4
              style={{
                fontSize: '20px',
                textAlign: 'center',
                marginBottom: '30px',
              }}
            >
              {planner === true &&
              event.users[this.props.profile.userId].finished !== true
                ? 'As the creator you can:'
                : 'What would you like to do?'}
            </h4>
            {(event.users[this.props.profile.userId].finished ===
              true ||
              event.users[this.props.profile.userId].canceled ===
                true) && (
              <div>
                <span
                  clasName="title"
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                  }}
                >
                  Delete This Experience
                </span>
                <p>
                  Deleting an Experience is permenant and it will be
                  banished from your life forever.
                </p>
                <a
                  className="red"
                  onClick={() => {
                    this.guestDeleteEvent(
                      this.props.profile,
                      onClose,
                    );
                  }}
                >
                  Delete This Experience
                </a>
              </div>
            )}
            <div style={{ padding: '00px 0px 0px 0px' }}>
              {planner === false &&
                event.users[this.props.profile.userId].finished !==
                  true &&
                event.users[this.props.profile.userId].canceled !==
                  true &&
                Object.keys(event.users).length > 1 && (
                  <div>
                    <span
                      clasName="title"
                      style={{
                        fontWeight: '500',
                        marginBottom: '5px',
                        display: 'block',
                      }}
                    >
                      Regretfully Cancel
                    </span>
                    <p>
                      If you can no longer attend this experience you
                      can cancel here.
                    </p>
                    <a
                      className="red"
                      onClick={() => {
                        this.guestCancelEvent(
                          this.props.profile,
                          onClose,
                        );
                      }}
                    >
                      Regretfully Cancel
                    </a>
                  </div>
                )}
            </div>
            {planner === true &&
              event.users[this.props.profile.userId].finished !==
                true && (
                <div>
                  <span
                    clasName="title"
                    style={{
                      fontWeight: '500',
                      marginBottom: '5px',
                      display: 'block',
                    }}
                  >
                    Cancel This Experience
                  </span>
                  <p>
                    This will cancel the Experience for everyone
                    attending and they will no longer be able to edit
                    or start it.
                  </p>
                  <a
                    onClick={() => {
                      this.creatorCancelEvent(
                        this.props.profile,
                        onClose,
                      );
                    }}
                    className="red"
                  >
                    Cancel This Experience
                  </a>
                </div>
              )}
            <div style={{ padding: '20px 0px 0px 0px' }}>
              {planner === true &&
                event.users[this.props.profile.userId].finished !==
                  true && (
                  <div>
                    <span
                      clasName="title"
                      style={{
                        fontWeight: '500',
                        marginBottom: '5px',
                        display: 'block',
                      }}
                    >
                      End This Experience
                    </span>
                    <p>
                      If this Experience is over you can just end it.
                      This will mark the Experience as completed for
                      everyone attending and they will no longer be
                      able to edit or start it.
                    </p>
                    <a
                      className="red"
                      onClick={() => {
                        this.creatorEndEvent(
                          this.props.profile,
                          onClose,
                        );
                      }}
                    >
                      End This Experience
                    </a>
                  </div>
                )}
              {planner === false &&
                event.users[this.props.profile.userId].finished !==
                  true && (
                  <div>
                    <span
                      clasName="title"
                      style={{
                        fontWeight: '500',
                        marginBottom: '5px',
                        display: 'block',
                      }}
                    >
                      End This Experience
                    </span>
                    <p>
                      If this Experience is over you can just end it.
                      You can find it later in your Past Experiences
                      list if you want to re-create the magic.
                    </p>
                    <a
                      className="red"
                      onClick={() => {
                        this.guestFinishEvent(
                          this.props.profile,
                          onClose,
                        );
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      End This Experience
                    </a>
                  </div>
                )}
            </div>
            {/* <div style={{ padding: '20px 0px 40px 0px' }}>
              {planner === true &&
                event.users[this.props.profile.userId].finished !==
                  true &&
                Object.keys(event.users).length > 2 && (
                  <div>
                    <span
                      clasName="title"
                      style={{
                        fontWeight: '500',
                        marginBottom: '5px',
                        display: 'block',
                      }}
                    >
                      Leave This Experience Without Canceling
                    </span>
                    <p>
                      If you can no longer attend this experience but
                      want other guests to still have fun, use this
                      option.
                    </p>
                    <a
                      className="red"
                      //  onClick={onClose}
                    >
                      Leave This Experience
                    </a>
                  </div>
                )}
            </div> */}

            <div>
              <div>
                <button onClick={onClose} className="redbutton">
                  Nevermind
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  editMode(event) {
    const timestamp = new Date();
    const bundle = {};
    bundle.event = event;
    const editing = {
      editing: true,
      editorId: this.props.profile.userId,
      timestamp,
    };
    bundle.editing = editing;
    this.props.editMode(bundle);
  }

  getScheduleButton(users, userId) {
    if (
      users[userId].editor === true &&
      this.props.event.status !== 9
    ) {
      return (
        <Link
          to={{
            pathname:
              '/event/edit/' + this.props.event.eventId + '/2',
          }}
        >
          <div className="scheduleButton">
            <MaterialIcon
              icon="date_range"
              size={30}
              color="#ffffff"
            />
          </div>
        </Link>
      );
    }
  }

  // Guest  - Finish Event
  guestFinishEvent(profile, onClose) {
    // This users info
    const fullName = profile.firstName + ' ' + profile.lastName;
    let userId = profile.userId;
    // The event they are canceling
    let event = this.props.event;
    // Mark this user as finished
    event.users[userId].finished = true;
    // Check how many guests are finished.
    let finishedArray = [];
    let usersArray = [];
    Object.keys(event.users).forEach((key) => {
      usersArray.push(event.users[key]);
      if (
        event.users[key].finished !== undefined &&
        event.users[key].finished === true
      ) {
        finishedArray.push(event.users[key]);
      }
    });
    // Is Everyone Finshed?
    if (finishedArray.length === usersArray.length) {
      event.status = 9;
    }
    // Update History
    const updatedHistory = event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 8,
      fullName: fullName,
      userId: userId,
    };
    // Is Everyone Finshed?
    updatedHistory.push(newHistory);
    if (finishedArray.length === usersArray.length) {
      const closeHistory = {
        editDate: localDate,
        eventType: 9,
        fullName: fullName,
        userId: userId,
      };
      updatedHistory.push(closeHistory);
    }
    event.editHistory = updatedHistory;
    onClose();
    this.props.finishEvent(event);
  }

  // Guest  - Finish Event
  guestCancelEvent(profile, onClose) {
    // This users info
    const fullName = profile.firstName + ' ' + profile.lastName;
    let userId = profile.userId;
    // The event they are canceling
    let event = this.props.event;
    // Mark this user as finished
    event.users[userId].attending = false;
    event.users[userId].canceled = true;
    event.users[userId].editor = false;
    // Check how many guests are finished.
    let finishedArray = [];
    let usersArray = [];
    // Update History
    const updatedHistory = event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 5,
      fullName: fullName,
      userId: userId,
    };
    updatedHistory.push(newHistory);
    event.editHistory = updatedHistory;
    onClose();
    this.props.finishEvent(event);
  }

  // Guest  - Finish Event
  guestDeleteEvent(profile, onClose) {
    // This users info
    const fullName = profile.firstName + ' ' + profile.lastName;
    let userId = profile.userId;
    // The event they are canceling
    let event = this.props.event;
    // Mark this user as deleted
    event.users[userId].finished = true;
    event.users[userId].delete = true;
    // Check how many guests are finished.
    let finishedArray = [];
    let usersArray = [];
    // Update History
    const updatedHistory = event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 10,
      fullName: fullName,
      userId: userId,
    };
    updatedHistory.push(newHistory);
    event.editHistory = updatedHistory;
    onClose();
    this.props.finishEvent(event);
  }

  // Creator  - Cancel Event
  creatorCancelEvent(profile, onClose) {
    // This users info
    const fullName = profile.firstName + ' ' + profile.lastName;
    let userId = profile.userId;
    // The event they are canceling
    let event = this.props.event;
    // Mark everyone as finished
    Object.keys(event.users).forEach((key) => {
      event.users[key].finished = true;
      event.users[key].finished = true;
      if (key !== event.creationDetails.plannerId) {
        event.users[key].editor = false;
      }
    });
    //Mark Event as Completed
    event.status = 11;
    // Update History
    const updatedHistory = event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 11,
      fullName: fullName,
      userId: userId,
    };
    event.editHistory = updatedHistory;
    onClose();
    this.props.finishEvent(event);
  }

  // Creator  - Cancel Event
  creatorEndEvent(profile, onClose) {
    // This users info
    const fullName = profile.firstName + ' ' + profile.lastName;
    let userId = profile.userId;
    // The event they are canceling
    let event = this.props.event;
    // Mark everyone as finished
    Object.keys(event.users).forEach((key) => {
      event.users[key].finished = true;
      event.users[key].finished = true;
      if (key !== event.creationDetails.plannerId) {
        event.users[key].editor = false;
      }
    });
    //Mark Event as Completed
    event.status = 9;
    // Update History
    const updatedHistory = event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 12,
      fullName: fullName,
      userId: userId,
    };
    event.editHistory = updatedHistory;
    onClose();
    this.props.finishEvent(event);
  }

  // getEditButton(users, userId) {
  //   if (
  //     users[userId].editor === true &&
  //     this.props.event.status !== 9
  //   ) {
  //     return (
  //       <button
  //         class="solidredbutton"
  //         onClick={() => {
  //           this.editMode(this.props.event);
  //         }}
  //       >
  //         Edit Event
  //       </button>
  //     );
  //   }
  // }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div style={{ height: '100%' }}>
        {this.getTitleBar(this.props.event)}
        {/* <Container>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="no-padding"
            >
              {this.getPlanners(
                this.props.event.users,
                this.props.profile,
              )}
            </Col>
          </Row>
        </Container> */}
        <Modal
          isOpen={this.state.guestListModalVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeGuestListModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <GuestListModal
            event={this.props.event}
            closeGuestListModal={this.closeGuestListModal}
          />
        </Modal>
        <Modal
          isOpen={this.state.scheduleModalVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeScheduleModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <ScheduleModal
            event={this.props.event}
            closeScheduleModal={this.closeScheduleModal}
          />
        </Modal>
        <Modal
          isOpen={this.state.conversationModalVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeConversationModal}
          style={conversationModalStyles}
          contentLabel="Conversation Modal"
        >
          <div className="modalHeader">
            <a
              onClick={() => {
                this.closeConversationModal();
              }}
            >
              <div className="modalCloser text-center">
                <MaterialIcon
                  icon="keyboard_arrow_down"
                  size={25}
                  color="white"
                />
              </div>
            </a>
            <a
              onClick={() => {
                this.closeConversationModal();
              }}
            >
              <span className="modalHeader-text">Conversation</span>
            </a>
          </div>

          <ConversationModal
            eventId={this.props.event.eventId}
            className="conversationModal"
          />
        </Modal>
        {/* <div id="event-body"> */}
        <div style={{ height: '100%' }}>
          <Container style={{ height: '100%' }}>
            <Row className="no-margin" style={{ height: '100%' }}>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <Card
                  className="no-borders"
                  style={{ height: '100%' }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={
                      this.props.event.heroImage !== null
                        ? this.props.event.heroImage
                        : 'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/article_images%2FdowHero.jpg?alt=media&token=99acdb0d-b8de-4e16-9005-3cb3e5fc04c6'
                    }
                    alt="Card image cap"
                  />

                  <CardBody>
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding"
                      >
                        <h2 className="no-padding">
                          {this.props.event.title}
                        </h2>
                      </Col>
                      {/* <Col
                        xs={2}
                        md={2}
                        sm={2}
                        lg={2}
                        className="no-padding"
                      >
                        <div className="right text-right">
                          {this.getEditButton(
                            this.props.event.users,
                            this.props.profile.userId,
                          )}
                        </div>
                      </Col> */}
                    </Row>

                    <span className="description">
                      {this.props.event.description}
                    </span>

                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding"
                      >
                        <div
                          id="eventDate"
                          style={{ padding: '0px 0px 20px 0px' }}
                        >
                          {/* <span className="bold">When: </span> */}
                          {this.getSchedule()}
                        </div>
                      </Col>
                      {/* <Col
                          xs={1}
                          md={1}
                          sm={1}
                          lg={1}
                          className="no-padding"
                        >
                          <div className="right text-right">
                            {this.getScheduleButton(
                              this.props.event.users,
                              this.props.profile.userId,
                            )}
                          </div>
                        </Col> */}
                    </Row>
                    <Row className="no-padding">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="no-padding"
                      >
                        {this.getPlanners(
                          this.props.event.users,
                          this.props.profile,
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding"
                      >
                        <div className="card-section">
                          {this.getLocations()}
                        </div>
                      </Col>
                    </Row>
                    {/* <div className="card-section">
                      <Row>
                        <Col
                          xs={12}
                          md={12}
                          sm={12}
                          lg={12}
                          className="no-padding"
                        >
                          <div id="eventGuestList">
                            <span className="bold">Guest List</span>
                          </div>
                        </Col>
                        <Col
                          xs={2}
                          md={2}
                          sm={2}
                          lg={2}
                          className="no-padding"
                        >
                          <div className="right text-right addGuest">
                            <MaterialIcon
                              icon="person_add"
                              size={30}
                              color="#ffffff"
                              onClick={() => {
                                this.openGuestListModal();
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                    {/* <div className="card-section text-center">
                      <a
                        onClick={() => {
                          this.startEvent(this.props.event);
                        }}
                      >
                        <span className="center solidredbutton">
                          START NOW!
                        </span>
                      </a>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {this.getFooter(this.props.event, this.props.profile)}

        <style
          dangerouslySetInnerHTML={{
            __html:
              '#event{padding-bottom: 0px}.maincontent-push{top:0px !important} .maincontent{background: white !important} .adminlistitem:nth-child(even) { background: white;} ',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    invite: state.invite,
    locations: state.locations.locations,
    profile: state.profile.profile,
    demo: state.demo,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLocations: (packageId) => {
      dispatch(getLocations(packageId));
    },
    startEvent: (event) => {
      dispatch(startEvent(event));
    },
    resetEvent: (event) => {
      dispatch(resetEvent(event));
    },
    approveEvent: (bundle) => {
      dispatch(approveEvent(bundle));
    },
    rejectEvent: (bundle) => {
      dispatch(rejectEvent(bundle));
    },
    editMode: (bundle) => {
      dispatch(editMode(bundle));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    finishEvent: (newEvent) => {
      dispatch(finishEvent(newEvent));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventDetails);
