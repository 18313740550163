import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
// Images
import banner2 from '../../images/banner2.png';
import banner3 from '../../images/banner3.png';
import banner4mobile from '../../images/banner4-mobile.png';
import banner4 from '../../images/banner4.png';
import banner6 from '../../images/banner6.png';
import ex2 from '../../images/ex2.png';
import ex3 from '../../images/ex3.png';
import ex4 from '../../images/ex4.png';
import ex1 from '../../images/ex1.png';

class VendorFeatures extends Component {
  constructor() {
    super();
    this.state = {
      persona: 0,
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ persona: this.props.persona, loading: false });
  }
  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <div
        className="features"
        style={{
          borderTop: '4px solid #030303',
          paddingTop: '0px',
        }}
      >
        {/* <div id="signupbanner">
          <SignUpBanner />
        </div> */}

        <div className="feature" style={{ paddingTop: '0px' }}>
          <Container>
            {/* <div className="wrapper"> */}

            {this.state.persona === '1' ||
              (this.props.persona !== undefined &&
                this.props.persona === '1' && (
                  <Row
                    className="no-margin"
                    style={{
                      paddingBottom: '20px',
                      borderBottom: '1px solid grey',
                    }}
                  >
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <center>
                        <h4
                          style={{
                            fontSize: '40px',
                            lineHeight: '35px',
                            color: 'black',
                            marginBottom: '30px',
                          }}
                        >
                          Trevel Destinations
                        </h4>
                        <h3
                          style={{
                            fontSize: '35px',
                            lineHeight: '35px',
                            color: '#f21d59',
                            marginBottom: '50px',
                          }}
                        >
                          It’s time to get back to the business of
                          having fun!
                        </h3>
                      </center>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <h4
                        style={{
                          fontWeight: '100',
                          padding: '5px 0px 10px 0px',
                        }}
                      >
                        Let Trevel
                        <span
                          style={{
                            fontSize: '9px',
                            verticalAlign: 'super',
                          }}
                        >
                          ®
                        </span>{' '}
                        help you get back into the game.
                      </h4>
                      <p className="subline">
                        Let’s face it. Restaurants, bars and their
                        patrons have had a tough go of it in the last
                        few years. But now we’re all ready to get back
                        to having fun, and Trevel
                        <span
                          style={{
                            fontSize: '9px',
                            verticalAlign: 'super',
                          }}
                        >
                          ®
                        </span>{' '}
                        is here to help. We are the only technology
                        platform that gives your business an easy and
                        fun way to craft experiences, keep customers
                        coming back, and expand revenue and
                        profitably.
                      </p>
                      <ul>
                        <li>
                          <p className="subline">
                            Reach your unique revenue and margin goals
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            Incentivize customers for specific
                            services
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            Bring strategy and creativity to your
                            promotions
                          </p>
                        </li>

                        <li>
                          <p className="subline">
                            Offer no-cost rewards that drive customer
                            behavior
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            Unify efforts and cross-promote to
                            multiple locations
                          </p>
                        </li>
                      </ul>
                      <div className="talkBox">
                        <h4
                          style={{
                            fontWeight: '100',
                            padding: '15px 0px 10px 0px',
                            color: '#f21d59',
                          }}
                        >
                          Lets Talk!
                        </h4>
                        <span style={{ fontSize: '18px' }}>
                          <span style={{ fontWeight: '600' }}>
                            (913) 384-7377
                          </span>
                        </span>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <center>
                        <img
                          style={{
                            width: '90%',
                            border: '5px solid #0c0c0c',
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fcelebrate.jpeg?alt=media&token=5bb4485e-5c98-48b2-a025-1ef1e00ced30"
                        />
                      </center>
                    </Col>
                  </Row>
                ))}
            {this.state.persona === '2' ||
              (this.props.persona !== undefined &&
                this.props.persona === '2' && (
                  <Row
                    className="no-margin"
                    style={{
                      paddingBottom: '20px',
                      borderBottom: '1px solid grey',
                    }}
                  >
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <center>
                        <h4
                          style={{
                            fontSize: '40px',
                            lineHeight: '35px',
                            color: 'black',
                            marginBottom: '30px',
                          }}
                        >
                          Entertainers
                        </h4>
                        <h3
                          style={{
                            fontSize: '40px',
                            lineHeight: '35px',
                            color: '#f21d59',
                            marginBottom: '50px',
                          }}
                        >
                          It’s time to get back to the business of
                          having fun!
                        </h3>
                      </center>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <h4
                        style={{
                          fontWeight: '100',
                          padding: '5px 0px 10px 0px',
                        }}
                      >
                        Let Trevel
                        <span
                          style={{
                            fontSize: '9px',
                            verticalAlign: 'super',
                          }}
                        >
                          ®
                        </span>{' '}
                        help you get back into the game.
                      </h4>
                      <p className="subline">
                        The last couple of years have been tough to
                        draw crowds or find venues to play. But now
                        the world is ready to take back their
                        nightlife, and Trevel{' '}
                        <span
                          style={{
                            fontSize: '9px',
                            verticalAlign: 'super',
                          }}
                        >
                          ®
                        </span>{' '}
                        is here to help. We are the only technology
                        platform that gives entertainers an easy and
                        fun way to craft experiences, keep fans coming
                        back, and expand revenue and profitably.
                      </p>
                      <ul>
                        <li>
                          <p className="subline">
                            Broaden your fanbase
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            Reach your revenue goals
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            Incentivize fans to keep coming back
                          </p>
                        </li>

                        <li>
                          <p className="subline">
                            Bring strategy and creativity to
                            promotions
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            Offer no-cost rewards that drive customer
                            behavior
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            Unify efforts and cross-promote with
                            locations and other entertainers
                          </p>
                        </li>
                      </ul>
                      <div className="talkBox">
                        <h4
                          style={{
                            fontWeight: '100',
                            padding: '15px 0px 10px 0px',
                            color: '#f21d59',
                          }}
                        >
                          Lets Talk!
                        </h4>
                        <span style={{ fontSize: '18px' }}>
                          <span style={{ fontWeight: '600' }}>
                            (913) 384-7377
                          </span>
                        </span>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <center>
                        <img
                          style={{
                            width: '90%',
                            border: '5px solid #0c0c0c',
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fcelebrate.jpeg?alt=media&token=5bb4485e-5c98-48b2-a025-1ef1e00ced30"
                        />
                      </center>
                    </Col>
                  </Row>
                ))}
            <div className="feature" style={{ paddingTop: '60px' }}>
              <Container>
                {this.state.persona === '1' && (
                  <Row
                    className="no-margin"
                    style={{
                      paddingBottom: '20px',
                      borderBottom: '1px solid grey',
                    }}
                  >
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <center>
                        <h4
                          style={{
                            fontSize: '40px',
                            lineHeight: '35px',
                            color: 'black',
                            marginBottom: '20px',
                          }}
                        >
                          The Trevel Guide
                        </h4>
                        <h3
                          style={{
                            fontSize: '35px',
                            lineHeight: '35px',
                            color: '#f21d59',
                            marginBottom: '30px',
                          }}
                        >
                          Helping people find the things they love,
                          together.
                        </h3>
                      </center>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <center>
                        <img
                          style={{
                            width: '100%',
                            border: '1px solid #0c0c0c',
                            marginBottom: '30px',
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ftrevelguide2.png?alt=media&token=a365b206-5d26-4708-98ec-481c5ca9590e"
                        />
                      </center>
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={12}>
                      <h4
                        style={{
                          fontWeight: '100',
                          padding: '5px 0px 10px 0px',
                        }}
                      >
                        Take Complete Control of Your Listing For Free
                      </h4>
                      <p className="subline">
                        The Trevel
                        <span
                          style={{
                            fontSize: '9px',
                            verticalAlign: 'super',
                          }}
                        >
                          ®
                        </span>{' '}
                        Guide helps you stay connected with your fans
                        while helping you develop new ones along the
                        way.
                      </p>
                      <p className="subline">
                        By setting up your Free Account with Trevel
                        you can take control of your listing and start
                        engaging with your fans how you want.
                      </p>
                      <ul>
                        <li>
                          <p className="subline">
                            Our easy to use inteface makes managing
                            your Guide Listing quick and simple, even
                            from your phone!
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            When users favorite you on Trevel your
                            Channel with upcoming events will appear
                            on their Daily Trevel Guide.
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            By joining you will become part of our
                            powerful recommendations engine that
                            connects you with new fans.
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            By joining the{' '}
                            <b style={{ fontWeight: '600' }}>
                              Trevel Community
                            </b>{' '}
                            you can also connect with other
                            Destinations and Entertainers to create
                            Trevel Expereinces that help gamify your
                            fans.
                          </p>
                          <p className="subline">
                            You can learn more about that below!
                          </p>
                        </li>
                      </ul>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <center>
                        <img
                          style={{
                            width: '90%',
                            border: '1px solid #0c0c0c',
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ftrevelguide1.png?alt=media&token=aab4c3e9-fbad-46fb-b773-fe141221414c"
                        />
                      </center>
                    </Col>
                  </Row>
                )}
                {this.state.persona === '2' && (
                  <Row
                    className="no-margin"
                    style={{
                      paddingBottom: '20px',
                      borderBottom: '1px solid grey',
                    }}
                  >
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <center>
                        <h4
                          style={{
                            fontSize: '40px',
                            lineHeight: '35px',
                            color: 'black',
                            marginBottom: '20px',
                          }}
                        >
                          The Trevel Guide
                        </h4>
                        <h3
                          style={{
                            fontSize: '35px',
                            lineHeight: '35px',
                            color: '#f21d59',
                            marginBottom: '30px',
                          }}
                        >
                          Helping people find the things they love,
                          together.
                        </h3>
                      </center>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <center>
                        <img
                          style={{
                            width: '100%',
                            border: '1px solid #0c0c0c',
                            marginBottom: '30px',
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ftrevelguide2.png?alt=media&token=a365b206-5d26-4708-98ec-481c5ca9590e"
                        />
                      </center>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <h4
                        style={{
                          fontWeight: '100',
                          padding: '5px 0px 10px 0px',
                        }}
                      >
                        Take Complete Control of Your Listing For Free
                      </h4>
                      <p className="subline">
                        The Trevel
                        <span
                          style={{
                            fontSize: '9px',
                            verticalAlign: 'super',
                          }}
                        >
                          ®
                        </span>{' '}
                        Guide helps you stay connected with your fans
                        while helping you develop new ones along the
                        way.
                      </p>
                      <p className="subline">
                        By setting up your Free Account with Trevel
                        you can take control of your listing and start
                        engaging with your fans how you want.
                      </p>
                      <ul>
                        <li>
                          <p className="subline">
                            Our easy to use inteface makes it simple
                            to add in your upcoming events, even from
                            your phone.
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            When Fans favorite you on Trevel your
                            Channel with upcoming events will appear
                            on their Daily Trevel Guide.
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            By joining you will become part of our
                            powerful recommendations engine that
                            connects you with new fans.
                          </p>
                        </li>
                        <li>
                          <p className="subline">
                            By joining the{' '}
                            <b style={{ fontWeight: '600' }}>
                              Trevel Community
                            </b>{' '}
                            you can also connect with other
                            Destinations and Entertainers to create
                            Trevel Expereinces that help gamify your
                            fans.
                          </p>
                          <p className="subline">
                            You can learn more about that below!
                          </p>
                        </li>
                      </ul>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <center>
                        <img
                          style={{
                            width: '90%',
                            border: '1px solid #0c0c0c',
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ftrevelguide1.png?alt=media&token=aab4c3e9-fbad-46fb-b773-fe141221414c"
                        />
                      </center>
                    </Col>
                  </Row>
                )}
              </Container>
            </div>

            <Row className="no-margin">
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <img
                  style={{ maxWidth: '75%', paddingTop: '50px' }}
                  src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880"
                />
                <h3
                  style={{
                    fontSize: '30px',
                    lineHeight: '35px',
                    color: '#f21d59',
                    marginBottom: '40px',
                  }}
                >
                  {this.state.persona === '1'
                    ? 'Gamify Your Business'
                    : 'Build Your Fan Base'}
                </h3>
                {/* <center>
                  <p className="subline">
                    Gameify your business with Trevel<span style={{fontSize: '9px', verticalAlign: 'super'}}>®</span> Experiences and
                    .....
                  </p>
                </center> */}
              </Col>
            </Row>
            <Row className="no-margin">
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div style={{ paddingBottom: '20px' }}>
                  <center>
                    <video
                      width="100%"
                      max-width="500px"
                      controls
                      controlsList="nodownload"
                      style={{ border: '1px solid #131313' }}
                    >
                      <source
                        src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/video%2FTrevel-Experiences-V1.mp4?alt=media&token=672a063d-31d3-47eb-b5fb-2ebfeef6dda7"
                        type="video/mp4"
                      />
                    </video>
                  </center>
                </div>
              </Col>
            </Row>
            <Row className="no-margin">
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div>
                  <h3 className="inline-block">Create</h3>
                  <h4
                    style={{
                      fontWeight: '100',
                      padding: '5px 0px 10px 0px',
                    }}
                  >
                    Craft Customer Experiences that Drive More
                    Business
                  </h4>

                  <p className="subline">
                    Trevel
                    <span
                      style={{
                        fontSize: '9px',
                        verticalAlign: 'super',
                      }}
                    >
                      ®
                    </span>{' '}
                    {this.state.persona === '2' &&
                      'gives you the tools to create promotional experiences for your fans and deliver them directly to their mobile devices. Create custom experiences and rewards, then add criteria your fans must meet to earn those rewards, effectively gamifying your business. Rewards do not have to be tied to a monetary value, so be creative!'}
                    {this.state.persona === '1' &&
                      'gives you the tools to create promotional experiences for your customers and deliver them directly to their mobile devices. Create custom experiences and rewards you, then add criteria your customers must meet to earn those rewards, effectively gamifying your business. Rewards do not have to be tied to a monetary value, so be creative!'}
                  </p>

                  <p className="subline">
                    {this.state.persona === '2' &&
                      'If you are having an event you want to market to existing and prospective fans, create your event, and it will be displayed on the home page of Trevel® '}
                    {this.state.persona === '1' &&
                      'If you are having an event you want to market to existing and prospective customers, create your event, and it will be displayed on the home pageof Trevel'}
                    <span
                      style={{
                        fontSize: '9px',
                        verticalAlign: 'super',
                      }}
                    >
                      ®
                    </span>{' '}
                    Experiences which can be viewed by all in the
                    Trevel
                    <span
                      style={{
                        fontSize: '9px',
                        verticalAlign: 'super',
                      }}
                    >
                      ®
                    </span>{' '}
                    community.
                  </p>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <center>
                  <img
                    src={ex2}
                    className="maxed-image"
                    width="auto"
                  />
                </center>
              </Col>
            </Row>
            {/* </div> */}
          </Container>
        </div>

        <div className="feature padding-top padding-bottom">
          <Container>
            {/* <div className="wrapper"> */}
            <Row>
              <div className="reverse">
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="align-middle"
                >
                  <h3 className="inline-block">Manage</h3>
                  <h4
                    style={{
                      fontWeight: '100',
                      padding: '5px 0px 10px 0px',
                    }}
                  >
                    Validate a Player's Criteria & Redeem Their Earned
                    Rewards
                  </h4>
                  <p className="subline">
                    Instantly validate users as they navigate through
                    their experiences in our cloud-based application
                    (no hardware required). Your employees can easily
                    manage the process from any computer, tablet or
                    mobile device.
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <center>
                    <img
                      src={ex3}
                      className="maxed-image"
                      width="auto"
                    />
                  </center>
                </Col>
              </div>
            </Row>
            {/* </div> */}
          </Container>
        </div>

        <div className="feature">
          <Container>
            {/* <div className="wrapper"> */}
            <Row className="no-margin">
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div>
                  <h3 className="inline-block">Analyze</h3>
                  <h4
                    style={{
                      fontWeight: '100',
                      padding: '5px 0px 10px 0px',
                    }}
                  >
                    {this.state.persona === '2' &&
                      'Gain Insights from your Fans Experiences'}
                    {this.state.persona === '1' &&
                      'Gain Insights from your Customers Experiences'}
                  </h4>
                  <p className="subline">
                    Once an experience has been created, you can track
                    the performance with real-time data. Our built-in
                    reporting is insightful and actionable:
                  </p>
                  {this.state.persona === '2' && (
                    <ul>
                      <li>
                        <p>
                          Player analytics, including new vs.
                          returning fans
                        </p>
                      </li>
                      <li>
                        <p>
                          Performance of your promotions over a
                          specified period of time
                        </p>
                      </li>
                      <li>
                        <p>
                          Promotion analytics, including rewards
                          available, created and earned by fans
                        </p>
                      </li>
                      <li>
                        <p>
                          Complete customer validation historical data
                        </p>
                      </li>
                    </ul>
                  )}
                  {this.state.persona === '1' && (
                    <ul>
                      <li>
                        <p>
                          Player analytics, including new vs.
                          returning customers
                        </p>
                      </li>
                      <li>
                        <p>
                          Performance of your promotions over a
                          specified period of time
                        </p>
                      </li>
                      <li>
                        <p>
                          Promotion analytics, including rewards
                          available, created and earned by users
                        </p>
                      </li>
                      <li>
                        <p>
                          Complete customer validation historical data
                        </p>
                      </li>
                    </ul>
                  )}
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <center>
                  <img
                    src={ex4}
                    className="maxed-image"
                    width="auto"
                  />
                </center>
              </Col>
            </Row>
            {/* </div> */}
          </Container>
        </div>

        {/* <div className="feature">
          <Container>
            <div className="wrapper">
              <Row>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="align-middle"
                >
                  <h3 className="inline-block">Vendor Dashboard</h3>
                  <p className="subline">
                    Learn about your customers on our platform with
                    our built-in vendor dashboard. This page will
                    provide you with all of the reporting data
                    surrounding vouchers and rewards to see how your
                    promotions are helping your business. As we
                    continue to develop and grow, we are learning what
                    data is important to you and your business. If you
                    have suggestions, please let us know!
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <center>
                    <img
                      src={banner6}
                      className="maxed-image"
                      width="auto"
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </Container>
        </div> */}
        {/* 
        <div className="feature padding-top padding-bottom">
          <Container>
            <div className="wrapper">
              <Row>
                <div className="reverse">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <h3 className="inline-block">Date Consultations</h3>
                    <p className="subline">
                      Another service that we provide is creating custom dates
                      for our users if they should request it. It{"'"}s another
                      method we can use to help market your business to our
                      users. Word-of-mouth recommendations coming straight from
                      us to help our users enjoy their evening at your
                      establishment.
                    </p>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <center>
                      <img src={banner7} className="maxed-image" />
                    </center>
                  </Col>
                </div>
              </Row>
            </div>
          </Container>
        </div> */}

        <div className="feature red-back calltoaction">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="callSection"
            >
              <center>
                <h2 className="reallywhite">Lets Talk</h2>
                {/* <a href="/signup" className="button whiteButton"> */}
                <h3 className="reallywhite">(913) 384-7377</h3>
                {/* </a> */}
              </center>
            </Col>
          </Row>
        </div>

        {/* <div className="feature">
          <div className="wrapper">
            <Row className="no-margin">
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <h2
                  style={{
                    fontSize: '40px',
                    lineHeight: '35px',
                    color: '#f21d59',
                    marginBottom: '40px',
                  }}
                >
                  Pricing
                </h2> */}
        {/* <center>
                  <p className="subline">
                    Gamify your business with Trevel Experiences and
                    .....
                  </p>
                </center> */}
        {/* </Col>
            </Row>
            <Row className="no-margin">
              <Col lg={4} md={4} sm={4} xs={4}>
                <center></center>
              </Col>
              <Col lg={4} md={4} sm={4} xs={4}>
                <center>
                  <h4>Free</h4> */}
        {/* <a href="/signup" className="button whiteButton">
                    Reach out and Sign Up Today!
                  </a> */}
        {/* </center>
              </Col>
              <Col lg={4} md={4} sm={4} xs={4}>
                <center>
                  <h4>Subscriber</h4>
                  <h6>$200 Monthly</h6> */}
        {/* <a href="/signup" className="button whiteButton">
                    Reach out and Sign Up Today!
                  </a> */}
        {/* </center>
              </Col>
            </Row>

            <Row className="no-margin">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>Manage Your Location</span>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#efefef' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="#f21d59"
                  />
                </center>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#f21d59' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="white"
                  />
                </center>
              </Col>
            </Row> */}

        {/* <Row className="no-margin">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>Trevel<span style={{fontSize: '9px', verticalAlign: 'super'}}>®</span> Promotes Your Business</span>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#efefef' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="#f21d59"
                  />
                </center>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#f21d59' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="white"
                  />
                </center>
              </Col>
            </Row> */}
        {/* 
            <Row className="no-margin">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>Manage Your Location</span>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#efefef' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="#f21d59"
                  />
                </center>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#f21d59' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="white"
                  />
                </center>
              </Col>
            </Row>

            <Row className="no-margin">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>
                  Trevel Creates packages using your business
                </span>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#efefef' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="#f21d59"
                  />
                </center>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#f21d59' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="white"
                  />
                </center>
              </Col>
            </Row>

            <Row className="no-margin">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>COVID Relief Campaign</span>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#efefef' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="#f21d59"
                  />
                </center>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#f21d59' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="white"
                  />
                </center>
              </Col>
            </Row> */}

        {/* 
            ADD THIS BACK BELW BUT NOT ABOVE */}

        {/* <Row className="no-margin">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>Trevel Agent Support</span>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#efefef' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="#f21d59"
                  />
                </center>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#f21d59' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="white"
                  />
                </center>
              </Col>
            </Row>

            <Row className="no-margin">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>Campaign Creation</span>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#efefef' }}
              >
                <center></center>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#f21d59' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="white"
                  />
                </center>
              </Col>
            </Row>

            <Row className="no-margin">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>Data Insight Reports</span>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#efefef' }}
              >
                <center></center>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#f21d59' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="white"
                  />
                </center>
              </Col>
            </Row>

            <Row className="no-margin">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>Unique Trevel URL</span>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#efefef' }}
              >
                <center></center>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ background: '#f21d59' }}
              >
                <center>
                  <MaterialIcon
                    icon="check"
                    size={45}
                    color="white"
                  />
                </center>
              </Col>
            </Row>
          </div>
        </div> */}
      </div>
    );
  }
}

export default VendorFeatures;
