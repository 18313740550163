import copy from 'copy-to-clipboard';
import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

const query = require('array-query');

export class InviteLink extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      copied: false,
    };
  }

  componentDidMount() {}

  confirmCopy() {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);
  }

  getContent(token) {
    let link = 'http://www.trevel.com/invite/' + token;
    let backLink = '/event/' + this.props.match.params.eventId;
    return (
      <div>
        <div className="section centerText">
          <h2 className="guestListTitle">
            Your Invitation Is Ready!
          </h2>
          <span>
            Just copy your link and send it to your guest however you
            want!
          </span>
          <div className="section">
            <span>
              <b>{link}</b>
            </span>
          </div>
        </div>
        <div className="section">
          <center>
            <a
              onClick={() => {
                this.copyLink(token);
              }}
              className="redbutton fullwidth fullwidth white display-block"
            >
              <span>Copy Invite Link</span>
            </a>
          </center>
          <center>
            <Link
              to={{
                pathname: backLink,
              }}
              className="stackedRedButtonBlock fullwidth fullwidth white display-block"
            >
              <span>All Done!</span>
            </Link>
          </center>
        </div>
      </div>
    );
  }

  copyLink(token) {
    let textToCopy = 'http://www.trevel.com/invite/' + token;
    copy(textToCopy);
    // // 1) Add the text to the DOM (usually achieved with a hidden input field)
    // const input = document.createElement("input");
    // document.body.appendChild(input);
    // input.value = textToCopy;
    // // 2) Select the text
    // input.focus();
    // input.select();
    // // 3) Copy text to clipboard
    // const isSuccessful = document.execCommand("copy");
    // // 4) Catch errors
    // if (!isSuccessful) {
    //   console.error("Failed to copy text.");
    // }

    // let textToCopy = "http://trevel.com/invite/" + token;
    // var input = document.createElement("input");
    // document.body.appendChild(input);
    // input.setAttribute("value", textToCopy);

    // // var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);

    // // if (isiOSDevice) {
    // var editable = input.contentEditable;
    // var readOnly = input.readOnly;

    // input.contentEditable = true;
    // input.readOnly = false;

    // var range = document.createRange();
    // range.selectNodeContents(input);

    // var selection = window.getSelection();
    // selection.removeAllRanges();
    // selection.addRange(range);

    // input.setSelectionRange(0, 999999);
    // input.contentEditable = editable;
    // input.readOnly = readOnly;
    // // } else {
    // input.focus();
    // input.select();
    // // }

    // const isSuccessful = document.execCommand("copy");
    // if (!isSuccessful) {
    //   console.error("Failed to copy text.");
    // }
    // document.body.removeChild(input);

    this.setState({
      copied: true,
    });
  }

  getTitleBar() {
    let backLink = '/event/' + this.props.match.params.eventId;
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="no-padding"
            ></Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Copy Your Invite Link
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: backLink,
                }}
                className="right"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    const token = this.props.match.params.token;
    return (
      <div>
        <div
          id="snackbar"
          style={{
            visibility:
              this.state.copied === true ? 'visible' : 'hidden',
            opacity: this.state.copied === true ? '1' : '0',
          }}
        >
          Invite Link Copied!
        </div>
        {this.getTitleBar()}
        <div id="invite">{this.getContent(token)}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteLink);
