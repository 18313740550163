import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';
// Services
import { deleteDocument } from '../../services/AdminServices';
// Styles
import './LocationListItem.css';
import SocialLocationCity from 'material-ui/svg-icons/social/location-city';

const query = require('array-query');

class VendorEntertainmentListItemEdit extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  getEdit(location) {
    const copyLink =
      'https://trevel.com/vendor/claim/' + location.locationId;
    if (this.props.edit === true) {
      const url = this.props.editPath + location.entertainmentId;
      return (
        <div className="right">
          <a href={url}>
            <MaterialIcon icon="edit" size={25} color="#f21d59" />
          </a>{' '}
          {/* <MaterialIcon
            icon="delete"
            size={25}
            onClick={() => {
              this.confirmDelete(location.locationId);
            }}
          /> */}
        </div>
      );
    }
  }

  getTaggedStatus(location) {
    let tagsSetting = 0;
    let tagsBeverages = 0;
    let tagsCuisine = 0;
    let tagsHours = 0;
    let tagsDateType = 0;

    if (location.tagsSetting) {
      if (location.tagsSetting.length > 0) {
        tagsSetting = 20;
      }
    }
    if (location.tagsBeverages) {
      if (location.tagsBeverages.length > 0) {
        tagsBeverages = 20;
      }
    }
    if (location.tagsCuisine) {
      if (location.tagsCuisine.length > 0) {
        tagsCuisine = 20;
      }
    }
    if (location.tagsHours) {
      if (location.tagsHours.length > 0) {
        tagsHours = 20;
      }
    }
    if (location.tagsDateType) {
      if (location.tagsDateType.length > 0) {
        tagsDateType = 20;
      }
    }

    const count =
      tagsSetting +
      tagsBeverages +
      tagsCuisine +
      tagsHours +
      tagsDateType;

    if (count === 100) {
      return <span className="table-bold green">{count}%</span>;
    } else {
      return (
        <span className="table-bold error-message">{count}%</span>
      );
    }
  }

  getDescriptionStatus(location) {
    if (location.locationDescription !== undefined) {
      if (location.locationDescription !== '<p></p>') {
        return <span className="table-bold green">Yes</span>;
      }
    } else {
      return <span className="table-bold error-message">No</span>;
    }
  }

  getPhoneStatus(location) {
    if (location.phone !== undefined) {
      if (
        location.phone === '(555) 555-5555' ||
        location.phone === '(555)555-555' ||
        location.phone === '555-555-5555' ||
        location.phone === '555-555-5602' ||
        location.phone === ''
      ) {
        return (
          <span className="table-bold error-message">Needed</span>
        );
      } else {
        return <span className="">{location.phone}</span>;
      }
    } else {
      return <span className="table-bold error-message">Needed</span>;
    }
  }

  confirmDelete(id) {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            deleteDocument('location', id, '/admin/locations'),
        },
        {
          label: 'No',
          onClick: () => console.log('closed'),
        },
      ],
    });
  }

  getImage(type, event) {
    let path = '';
    if (
      event !== undefined &&
      event.settings !== undefined &&
      event.settings.customImages !== undefined &&
      event.settings.customImages === true
    ) {
      path = event.entertainmentThumb;
    }
    return <img src={path} alt="alt" className="thumbnail" />;
  }

  getEventCount(id) {
    let countArray = [];
    let countActiveArray = [];
    this.props.vendorTimedEvents.forEach((event) => {
      const status =
        event.entertainment.entertainmentIds.includes(id);
      if (status === true) {
        if (event.active === false) {
          countArray.push('event');
        } else {
          countActiveArray.push('event');
        }
      }
    });

    return (
      <div>
        <span>
          <span style={{ fontWeight: '800' }}>
            {countActiveArray.length}
            {'  '}
          </span>
          Active Events
        </span>
        <span
          style={{
            clear: 'both',
            display: 'block',
            marginTop: '10px',
          }}
        >
          <span style={{ fontWeight: '800' }}>
            {countArray.length}
            {'  '}
          </span>
          Deactivated
        </span>
      </div>
    );
  }

  getEntertainmentType(entertainment) {
    return entertainment.tagsEntertainmentType[0];
  }

  getLocation(zoneId) {
    if (zoneId === 11) {
      return 'St. Louis';
    } else {
      return 'Kansas City';
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    const eventId = this.props.eventId;
    const token = this.props.token;
    const type = this.props.type;
    // let subset = this.props.packages.slice(0, 10);
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={4} md={4} sm={4} lg={4} className="left">
                  <span className="label">Name</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Location</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Type</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Genre</span>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2} className="right">
                  <span className="label right">Actions</span>
                </Col>
              </div>
            </li>
            {this.props.entertainment.map((entertainment, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    key={'key' + index}
                  >
                    <a
                      href={'/entertainment/' + entertainment.slug}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Row>
                        <Col
                          xs={6}
                          md={6}
                          sm={6}
                          lg={6}
                          className="thumbnailCol left no-padding"
                        >
                          {this.getImage('thumb', entertainment)}
                        </Col>
                        <Col
                          xs={6}
                          md={6}
                          sm={6}
                          lg={6}
                          className="left"
                        >
                          <span
                            className="location-title  red"
                            style={{
                              marginTop: '0px',
                              fontSize: '18px',
                              fontWeight: '800',
                              lineHeight: '20px',
                            }}
                          >
                            {entertainment.entertainmentTitle}
                          </span>

                          <span
                            className="location-title"
                            style={{ marginTop: '10px' }}
                          >
                            {this.getEventCount(
                              entertainment.entertainmentId,
                            )}{' '}
                          </span>
                        </Col>
                      </Row>
                    </a>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    <span
                      style={{
                        fontWeight: '800',
                        color: 'black',
                      }}
                    >
                      {this.getLocation(entertainment.zoneId)}
                    </span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    <span
                      style={{
                        fontWeight: '800',
                        color: 'black',
                      }}
                    >
                      {this.getEntertainmentType(entertainment)}{' '}
                    </span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {entertainment.tagsGenre.length !== 0 &&
                      entertainment.tagsGenre.map((genre, index) => (
                        <span className="tag-user">{genre}</span>
                      ))}
                    {entertainment.tagsGenre.length === 0 && (
                      <span className="tag-user">none</span>
                    )}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {this.getEdit(entertainment)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    timedEvents: state.vendorTimedEvents.events,
    vendorTimedEvents: state.vendorTimedEvents.events,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorEntertainmentListItemEdit);

// Location Details Available
// **************************
// location.locationTitle
// location.categories
// location.locationDescription
// location.locationHero
// location.ocationThumb
// location.locationId
// location.locationType
// ** Vouchers and Packages as well
