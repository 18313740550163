import React from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import * as auth from "../../config/auth";
// Reducers
import { setNewUser, setUser } from "../../sagas/loginSaga/reducer";

const INITIAL_STATE = {
  error: {},
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  signIn(email, password) {
    auth
      .doSignInWithEmailAndPassword(email, password)
      .then((result) => {
        const user = {
          id: result.user.uid,
          userName: result.user.displayName,
          email: result.user.email,
          authenticated: true,
          newuser: false,
        };
        this.setState({ ...INITIAL_STATE });
        this.props.setUser(user);
      })
      .catch((error) => {
        this.setState(byPropKey("error", error));
      });
  }

  getErrorMessage(error) {
    if (error !== null) {
      console.log("error -", error);
      return error.message;
    }
  }

  onChange = (event) => {
    let label = [event.target.name] + "Error";
    let error = this.state.error;

    if (event.target.value === "") {
      error[label] = {
        message: "Required",
      };
    } else {
      error[label] = undefined;
    }

    console.log("error", error);
    this.setState({ [event.target.name]: event.target.value, error });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return (
      <div id="signup">
        <div>
          <div className="title-area">
            <h3>Reset Your Password</h3>
            {/* <p className="signup-box-info">
              <span>Or </span>
              <a href="/signup">create an account</a>
              <span> for free.</span>
            </p> */}
          </div>
          <form onSubmit={this.onSubmit}>
            <Container>
              <Row>
                <Col xs={12} md={6} sm={12} lg={6} className="left">
                  <input
                    name="passwordOne"
                    value={passwordOne}
                    onChange={this.onChange}
                    type="password"
                    placeholder="New Password"
                  />
                  {error && error.passwordOneError && (
                    <span className="error-message">
                      {error.passwordOneError.message}
                    </span>
                  )}
                  <input
                    name="passwordTwo"
                    value={passwordTwo}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Confirm New Password"
                  />
                  {error && error.passwordTwoError && (
                    <span className="error-message">
                      {error.passwordTwoError.message}
                    </span>
                  )}
                  <button disabled={isInvalid} type="submit">
                    Reset My Password
                  </button>
                </Col>
              </Row>
              <Row xs={12} md={12} sm={12} lg={12} className="disclaimer" />
            </Container>
          </form>

          <style
            dangerouslySetInnerHTML={{
              __html: ".nav-signin { display: none }",
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
    setNewUser: (user) => {
      dispatch(setNewUser(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm);
