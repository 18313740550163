import React from 'react';
import { connect } from 'react-redux';
// Components
import VendorLocationsForm from '../../components/VendorLocationsForm';

const query = require('array-query');

export class AdminLocationsEdit extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  getLocation(locationId, locations) {
    const locationDetails = query('locationId')
      .is(locationId)
      .on(locations);
    return locationDetails[0];
  }

  render() {
    const location = this.getLocation(
      this.props.match.params.id,
      this.props.alllocations,
    );

    return (
      <div id="vendor">
        <VendorLocationsForm
          editlocation={location}
          history={this.props.history}
          admin={true}
        />
        <style
          dangerouslySetInnerHTML={{
            __html:
              'html {display: grid} #analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.alllocations.locations,
  };
}

export default connect(mapStateToProps)(AdminLocationsEdit);
