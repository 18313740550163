import firebase from '../config/firebase';

export function getUserAwards(userId) {
  const awardsArray = [];
  const db = firebase.firestore();

  const getV = function () {
    return new Promise((resolve, reject) => {
      db.collection('user_awards')
        .where('owner.userId', '==', userId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                active,
                creationDetails,
                criteria,
                reward,
                rewards,
                rewardSettings,
                owner,
                redemption,
                restrictions,
                promo,
                earned,
                validatorMode,
                validationType,
              } = doc.data();
              // const expireDateString = expireDate.toDateString();
              // const purchaseDateString = purchaseDate.toDateString();
              awardsArray.push({
                awardId: doc.id,
                active,
                creationDetails,
                criteria,
                reward,
                owner,
                redemption,
                rewards,
                rewardSettings,
                restrictions,
                promo,
                earned,
                validatorMode,
                validationType,
              });
            }
          });
          return awardsArray;
        })
        .then((result) => {
          // const final_vouchers = result[0]
          resolve(awardsArray);
        });
    });
  };
  return getV();
}
