import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getEvent,
  sendInvitation,
} from '../../services/InvitationServices';
import { getEvents } from '../userEventsSaga/reducer';
import { push } from 'connected-react-router';

import * as actions from './actions';

let defaultState = {
  event: {
    title: 'hello',
  },
};

function* getInviteAction(token) {
  let event = yield call(getEvent, token.invite);
  yield put({
    type: actions.SET_INVITE,
    payload: {
      ...defaultState,
      event,
    },
  });
}

function* sendInviteAction(invite) {
  yield call(sendInvitation, invite.payload);
  // yield put(getEvents());
}

export default function* sagas() {
  yield takeLatest(actions.GET_INVITE, getInviteAction);
  yield takeLatest(actions.SEND_INVITE, sendInviteAction);
}
