import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./EventHomeBody.css";

class EventHomeBody extends Component {
  render() {
    return (
      <Col xs={12} md={12} sm={12} lg={12}>
        <Row>
          <div className="button-container">
            <a className="event-button-link" href="/packages">
              <div className="event-button">
                <p>Pre-Planned Dates</p>
              </div>
            </a>
          </div>
        </Row>
        <Row>
          <div className="button-container">
            <a className="event-button-link" href="/custompackagehome">
              <div className="event-button">
                <p>Create Your Own</p>
              </div>
            </a>
          </div>
        </Row>
      </Col>
    );
  }
}

export default EventHomeBody;
