import * as action from "./actions";

export const getVendorUserEvents = vendorId => {
  return {
    type: action.GET_VENDORUSEREVENTS,
    payload: {},
    vendorId: vendorId
  };
};

const ACTION_HANDLERS = {
  [action.SET_VENDORUSEREVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_VENDORUSEREVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  }
};

let defaultState = {};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
