import React from 'react';
import { connect } from 'react-redux';
import firebase from '../../config/firebase';
// Components
import { vendorHydrateApp } from '../../sagas/hydrateSaga/reducer';
import VendorFeatures from '../../components/VendorFeatures';

const query = require('array-query');

export class VendorClaim extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      locationId: '',
      aboutMode: 0,
      error: false,
    };
  }

  UNSAFE_componentWillMount() {
    let autoSignup = false;
    if (this.props.location.search === '?source=qr') {
      autoSignup = true;
    }
    if (this.props.match.params.id !== undefined) {
      const db = firebase.firestore();
      let location = [];
      db.collection('vendorCodes')
        .where('vendorCode', '==', this.props.match.params.id)
        .get()
        .then((querySnapshot) => {
          //Notice the arrow funtion which bind `this` automatically.
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            location.push(doc.data());
          });
          if (this.props.user.authenticated === false) {
            let bundle = {
              locationId: location[0].locationId,
              autoSignup,
            };
            this.props.vendorHydrateApp(bundle);
          } else {
            this.props.history.push(
              '/vendor/claimready/' +
                location[0].locationId +
                this.props.location.search,
            );
          }

          this.setState({
            location: location[0],
            path: '/home',
            loading: false,
          });
        })
        .catch((error) => {
          console.log(' error', error);
          this.setState({
            error: true,
          });
        });
    } else {
      this.setState({
        error: true,
      });
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    if (this.state.error === true) {
      return (
        <div style={{ position: 'relative', top: '50px' }}>
          <div style={{ padding: '20px' }}>
            <center>
              <h4>Invalid Claim Code</h4>
              <span>Need help? Just call us at (913) 384-7377</span>
            </center>
          </div>

          <VendorFeatures />
        </div>
      );
    }
    return <div id="vendorClaim" />;
  }
}

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    locations: state.locations.locations,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    vendorHydrateApp: (id) => {
      dispatch(vendorHydrateApp(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorClaim);
