import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import { Container, Row, Col } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import { getConnectionsTagsAndFavs } from '../sagas/connectionsTagsAndFavsSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

export class ContactProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      mode: 1,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    const userDetails = query('userId')
      .is(this.props.match.params.id)
      .on(this.props.users);
    const userProfile = userDetails[0];
    let bundle = {
      inviteId: this.props.match.params.id,
      locations: this.props.alllocations,
      contactProfile: true,
    };
    this.getConnectionTagsandFavs(bundle);
    this.setState({
      userProfile,
    });
  }

  check() {
    if (
      this.props.connectionsTagsAndFavs[0] !== undefined &&
      this.props.connectionsTagsAndFavs[0].userId ===
        this.props.match.params.id
    ) {
      this.setUsersTags(
        this.props.profile.tags.love,
        this.props.profile.tags.hate,
      );
    } else {
      setTimeout(() => {
        this.checkAgain();
      }, 0.05 * 1000);
    }
  }

  checkAgain() {
    if (
      this.props.connectionsTagsAndFavs[0] !== undefined &&
      this.props.connectionsTagsAndFavs[0].userId ===
        this.props.match.params.id
    ) {
      this.setUsersTags(
        this.props.profile.tags.love,
        this.props.profile.tags.hate,
      );
    } else {
      this.check();
    }
  }

  getConnectionTagsandFavs(userId) {
    this.props.getConnectionsTagsAndFavs(userId);
  }

  getNumbers() {
    // [400].forEach(function(i) {
    //   Array(i)
    //     .fill(i)
    //     .forEach(function() {
    //       const db = firebase.firestore();
    //       const newEventRef = db.collection("locations").doc();
    //       const dbref = newEventRef.id;
    //       console.log("dog", dbref);
    //     });
    // });
    //   const numbersArray = [];
    //   numbersArray.push(dbref);
  }

  componentDidMount() {
    this.check();

    // this.getNumbers();
    // const db = firebase.firestore();
    // packages.forEach((packageSingle) => {
    //   const setDoc = db
    //     .collection('packtest')
    //     .doc(packageSingle.id)
    //     .add(packageSingle)
    //     .then((ref) => {
    //       console.log(ref._documentPath._parts[1]);
    //     });
    // });
    // const db = firebase.firestore();
    // locations.forEach(location => {
    //   const setDoc = db
    //     .collection("locationTest")
    //     .doc(location.locationId)
    //     .set(location)
    //     .then(ref => {
    //       console.log(ref._documentPath._parts[1]);
    //     });
    // });
  }

  getEvents(events, userId) {
    const userEvents = [];
    events.forEach(event => {
      if (event.users[userId] !== undefined) {
        userEvents.push(event);
      }
    });
    return userEvents;
  }

  getEvent(event) {
    return (
      <Row>
        <Col className="no-padding">
          <Link
            to={{
              pathname: '/event/' + event.eventId,
            }}
            // className="right"
          >
            {event.title}
          </Link>
        </Col>
      </Row>
    );
  }

  getTag(tag) {
    return <li>{tag}</li>;
  }

  getState(user) {
    if (user.state !== undefined) {
      return (
        <Row>
          <Col className="no-padding">
            <p>
              From <b>{user.state}</b>
            </p>
          </Col>
        </Row>
      );
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getTab(tabMode, tabLable, mode) {
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tabLable}
          </div>
        </a>
      );
    }
  }

  listLocations(locations) {
    if (locations !== undefined) {
      return (
        <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
          {locations.map((location, index) => (
            <div className="oddeven" key={'key' + index}>
              {this.getLocation(location)}
            </div>
          ))}
        </Col>
      );
    }
  }

  getImage(type, locationStart) {
    let location = '';
    let locations = this.props.alllocations;
    const locationDetails = query('locationId')
      .is(locationStart.locationId)
      .on(locations);
    location = locationDetails[0];
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getLocation(location) {
    return (
      <Row className="no-padding">
        <Col xs={2} md={1} sm={2} lg={1} className="no-padding">
          {this.getImage('thumb', location)}
        </Col>
        <Col xs={8} md={9} sm={8} lg={9}>
          <span className="location-title">
            {location.locationTitle}
          </span>
          <span className="address">{location.address.street}</span>
          <span className="address">
            {location.address.city} {location.address.state},{' '}
            {location.address.zip}
          </span>
        </Col>
        <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
          <div className="right text-right">
            {/* <MaterialIcon
              icon="add"
              size={30}
              color="#f21d59"
              onClick={() => {
                this.addLocation(this.props.customEvent, location);
              }}
            /> */}
          </div>
        </Col>
      </Row>
    );
  }

  getConnectionsFavorites(favoriteLocations, profile) {
    let name = this.state.userProfile.firstName + "'s";
    if (
      favoriteLocations !== undefined &&
      favoriteLocations.length !== 0
    ) {
      return (
        <div className="wizard-section">
          <h6>{name} Favorite Places</h6>
          <Row>{this.listLocations(favoriteLocations)}</Row>
        </div>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/connections',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Contact</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  getConnectionsPlaces(locations, favs) {
    let favLocations = [];
    if (favs !== undefined) {
      favs.forEach(fav => {
        const locationDetails = query('locationId')
          .is(fav)
          .on(locations);
        let newLocation = {};
        newLocation = {
          locationTitle: locationDetails[0].locationTitle,
          locationId: locationDetails[0].locationId,
          locationThumb: locationDetails[0].locationThumb,
          address: locationDetails[0].address,
          weight: 1,
        };
        favLocations.push(newLocation);
      });
      return favLocations;
    }
  }

  // TAGS STUFF
  getTagStatus(tag) {
    let userTagsLove = this.props.profile.tags.love;
    let userTagsHate = this.props.profile.tags.hate;
    let otherUserTagsLove = null;
    let otherUserTagsHate = null;
    if (this.props.connectionsTagsAndFavs[0].tags !== undefined) {
      otherUserTagsLove = this.props.connectionsTagsAndFavs[0].tags
        .love;
    }
    if (this.props.connectionsTagsAndFavs[0].tags !== undefined) {
      otherUserTagsHate = this.props.connectionsTagsAndFavs[0].tags
        .hate;
    }
    let status = 0;
    Object.keys(userTagsLove).forEach(key => {
      userTagsLove[key].forEach(userTag => {
        if (tag === userTag) {
          status = 1;
          if (
            otherUserTagsLove !== undefined &&
            otherUserTagsLove !== null
          ) {
            Object.keys(otherUserTagsLove).forEach(key => {
              otherUserTagsLove[key].forEach(otherUserTag => {
                if (tag === otherUserTag) {
                  status = 3;
                }
              });
            });
          }
        }
      });
    });
    if (
      otherUserTagsLove !== undefined &&
      otherUserTagsLove !== null &&
      status === 0
    ) {
      Object.keys(otherUserTagsLove).forEach(key => {
        otherUserTagsLove[key].forEach(otherUserTag => {
          if (tag === otherUserTag) {
            status = 2;
          }
        });
      });
    }
    return status;
  }

  // getKey(profile) {
  //   return (
  //     <p>
  //       <b>Tag Colors: </b>
  //       <span className="tag-otherUser">
  //         {this.state.userProfile.firstName}
  //       </span>
  //       <span className="tag-match">Match!</span>
  //     </p>
  //   );
  // }

  setTags(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = this.getTagStatus(tag.tag);
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    this.setState({
      tagFilters: updatedTags,
    });
  }

  setUsersTags(userLove, userHate) {
    let userTags = [];
    let connectionsTags = [];

    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };

    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };

    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };

    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };

    if (
      this.props.connectionsTagsAndFavs[0] !== undefined &&
      this.props.connectionsTagsAndFavs[0].tags !== undefined
    ) {
      let allTags = {
        tagsBeverages: [],
        tagsDateType: [],
        tagsSetting: [],
        tagsCuisine: [],
      };
      // Combine Tags
      this.props.connectionsTagsAndFavs[0].tags.love.tagsBeverages.forEach(
        (tagcat, x) => {
          let n = allTags.tagsBeverages.includes(tagcat);
          if (n === false) {
            allTags.tagsBeverages.push(tagcat);
          }
        },
      );

      this.props.connectionsTagsAndFavs[0].tags.love.tagsDateType.forEach(
        (tagcat, x) => {
          let n = allTags.tagsDateType.includes(tagcat);
          if (n === false) {
            allTags.tagsDateType.push(tagcat);
          }
        },
      );

      this.props.connectionsTagsAndFavs[0].tags.love.tagsSetting.forEach(
        (tagcat, x) => {
          let n = allTags.tagsSetting.includes(tagcat);
          if (n === false) {
            allTags.tagsSetting.push(tagcat);
          }
        },
      );

      this.props.connectionsTagsAndFavs[0].tags.love.tagsCuisine.forEach(
        (tagcat, x) => {
          let n = allTags.tagsCuisine.includes(tagcat);
          if (n === false) {
            allTags.tagsCuisine.push(tagcat);
          }
        },
      );

      Object.keys(allTags).forEach(key => {
        allTags[key].map((tag, y) => {
          let newTag = {};
          let status = this.getTagStatus(tag);
          newTag.checked = false;
          newTag.tagindex = y;
          newTag.status = status;
          newTag.tag = tag;
          if (key === 'tagsBeverages') {
            newTag.parentindex = 0;
            tagsBeverages.tags.push(newTag);
          } else if (key === 'tagsCuisine') {
            newTag.parentindex = 1;
            tagsCuisine.tags.push(newTag);
          } else if (key === 'tagsDateType') {
            newTag.parentindex = 3;
            tagsDateType.tags.push(newTag);
          } else if (key === 'tagsSetting') {
            newTag.parentindex = 2;
            tagsSetting.tags.push(newTag);
          }
        });
      });
    }

    userTags.push(tagsBeverages);
    userTags.push(tagsCuisine);
    userTags.push(tagsSetting);
    userTags.push(tagsDateType);
    this.setState({
      userTags: userTags,
      loading: false,
    });
  }

  getButton(tag) {
    let style = 'tagFilterBtnNone';
    if (tag.status === 1) {
      style = 'tagFilterBtnContact';
    } else if (tag.status === 2) {
      style = 'tagFilterBtnContact';
    } else if (tag.status === 3) {
      style = 'tagFilterBtnMatchedContact';
    }

    if (tag.checked === false) {
      return <span className={style}>{tag.tag}</span>;
    } else {
      return <span className="tagFilterBtnActive">{tag.tag}</span>;
    }
  }

  getSection(tags, title) {
    let sortedTags = _.sortBy(tags, o => o.status).reverse();
    if (tags.length > 0) {
      return (
        <div className="clear">
          <h6>{title}</h6>
          {sortedTags.map((tag, index) => (
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              {this.getButton(tag)}
            </Col>
          ))}
        </div>
      );
    }
  }

  // END TAG STUFF

  getFavorties() {
    let connectionsFavoriteLocations = [];
    if (
      this.props.connectionsTagsAndFavs[0].favorites !== undefined
    ) {
      connectionsFavoriteLocations = this.getConnectionsPlaces(
        this.props.alllocations,
        this.props.connectionsTagsAndFavs[0].favorites,
      );
      return (
        <div className="favoriteSection">
          {this.getConnectionsFavorites(connectionsFavoriteLocations)}
        </div>
      );
    }
  }

  getPreferences() {
    return (
      <div>
        <div className="flow-root">
          {/* get rid oof key class and standardize */}
          {/* <div>{this.getKey()}</div> */}
          {this.getSection(this.state.userTags[0].tags, 'Beverages')}
          {this.getSection(this.state.userTags[1].tags, 'Cuisine')}
          {this.getSection(this.state.userTags[2].tags, 'Setting')}
          {this.getSection(
            this.state.userTags[3].tags,
            'Something...',
          )}
        </div>
        {this.getFavorties()}
      </div>
    );
  }

  getProfileTab(events) {
    return (
      <Container>
        <Row>
          <Col className="no-padding">
            {/* <Link
            to={{
              pathname: "/profile/edit"
            }}
            className="right redbutton"
          >
            Edit Profile
          </Link> */}
          </Col>
        </Row>
        <Row>
          <Col className="text-center no-padding">
            <UserAvatar
              size="100"
              name={
                this.state.userProfile.firstName +
                this.state.userProfile.lastName
              }
              src={this.state.userProfile.profilePhotoPath}
            />
            <h2>
              {this.state.userProfile.firstName}{' '}
              {this.state.userProfile.lastName}
            </h2>
            <h5>@{this.state.userProfile.userName}</h5>
            {this.getState(this.state.userProfile)}
          </Col>
        </Row>
        <Row>
          {/* <Col className="no-padding">
          <div className="profile-section">
            <h5>Email: {this.state.userProfile.email}</h5>
          </div>
        </Col> */}
        </Row>
        <div className="profile-section">
          <h5>Events With {this.state.userProfile.firstName}</h5>
          {events.map(event => (
            <div>{this.getEvent(event)}</div>
          ))}
        </div>
        {/* <div className="profile-section">{this.getTags()}</div> */}
      </Container>
    );
  }

  getContent(mode, events) {
    if (mode === 1) {
      return this.getProfileTab(events);
    } else if (mode === 2) {
      return this.getPreferences();
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div id="profile">
          {this.getTitleBar()}
          <div id="headersTopSecondary">
            <Row>
              <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
                {this.getTab(1, 'Profile', this.state.mode)}
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
                {this.getTab(2, 'Preferences', this.state.mode)}
              </Col>
            </Row>
          </div>

          <div className="section pushtwenty" />
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 59px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    const events = this.getEvents(
      this.props.events,
      this.props.match.params.id,
    );
    return (
      <div id="profile">
        {this.getTitleBar()}
        <div id="headersTopSecondary">
          <Row>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(1, 'Profile', this.state.mode)}
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(2, 'Preferences', this.state.mode)}
            </Col>
          </Row>
        </div>

        <div className="section pushtwenty">
          {this.getContent(this.state.mode, events)}
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 59px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getConnectionsTagsAndFavs: userId => {
      dispatch(getConnectionsTagsAndFavs(userId));
    },
  };
};

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    users: state.users.users,
    events: state.events.events,
    connectionsTagsAndFavs: state.tagsAndFavs.connectionsTagsAndFavs,
    alllocations: state.locations.locations,
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactProfile);
