import React from "react";
import { Col, Container, Row } from "reactstrap";

export default class OurServices extends React.Component {
  render() {
    return (
      <div>
        <div id="services">
          <Container className="no-padding">
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
                <div className="section">
                  <h1>Our Services</h1>
                  <p>
                    When we built our product, we didn’t want people to have to
                    pay a monthly membership fee just to have access to our
                    services. We believe that you should be able to get our
                    advice for free, and we believe that our product is the best
                    option out there, but there are a few features that we
                    couldn’t included for free… trust us, we tried. Upgrade your
                    membership, and receive our upgraded services.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
                <div className="features">
                  <div className="feature">
                    <div className="wrapper">
                      <Row className="no-margin">
                        <Col lg={6} md={6} sm={12} xs={12}></Col>
                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="align-middle"
                        >
                          <div>
                            <h2>Monthly Membership</h2>
                            <p>
                              Coming Soon
                              {/* We do offer a monthly membership program for those
                              who are interested. With this upgrade, you will
                              receive preferential treatment at our affiliated
                              restaurants, bars, and venues, with things such as
                              a free bottle of wine, flowers on the table, or
                              preferred seating. Everything you want on a date
                              just by signing up. */}
                            </p>
                            {/* <button className="services-button">
                              Become a Member
                            </button> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="feature">
                    <div className="wrapper">
                      <Row className="no-margin">
                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="align-middle"
                        >
                          <div>
                            <h2>Custom Itinerary Consultation</h2>
                            <p>Coming Soon</p>
                            {/* <button className="services-button">
                              Reach Out
                            </button> */}
                          </div>
                        </Col>
                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          // className="align-middle"
                        ></Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
