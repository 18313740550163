import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createFilter } from 'react-search-input';
import { Col, Container, Row } from 'reactstrap';
// Styles
import '../components/PackageListItem/PackageListItem.css';
import PromotedHero from '../components/PromotedHero';
import PromotionFeedMulti from '../components/PromotionFeedMulti';

// Reducers
import { init } from '../sagas/packagesSaga/reducer';
import { getAllLocations } from '../sagas/allLocationsSaga/reducer';
import { getConnectionsTagsAndFavs } from '../sagas/connectionsTagsAndFavsSaga/reducer';
import {
  getNewEvent,
  resetNewEvent,
} from '../sagas/createEventSaga/reducer';
import { resetCe } from '../sagas/customEventSaga/reducer';

const KEYS_TO_FILTERS = ['title'];

export class PlanEventOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: false,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  compontWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
  }

  componentDidMount() {
    this.getConnectionTagsandFavs(this.props.newEvent.invitedUser);
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getConnectionTagsandFavs(invitedUser) {
    if (Object.keys(invitedUser).length !== 0) {
      let inviteId = this.props.newEvent.invitedUser.userId;
      this.props.getConnectionsTagsAndFavs(inviteId);
    }
  }

  planFirst() {
    const newEvent = {
      connections: [],
      planFirst: true,
      connectFirst: false,
      clear: false,
    };
    this.props.getNewEvent(newEvent);
  }

  connectFirst() {
    const newEvent = {
      connections: [],
      planFirst: false,
      connectFirst: false,
      clear: false,
    };
    this.props.getNewEvent(newEvent);
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/events/home',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Create New Experience
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/',
                }}
                className="right"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getFooter(mode) {
    return (
      <div id="startBar">
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <center>
                <a
                  onClick={() => {
                    this.props.resetCe(true);
                  }}
                >
                  <span className="center solidredbutton">Skip!</span>
                </a>
              </center>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  render() {
    if (this.props.packages === undefined) {
      return null; // or render a loading icon
    }
    const filteredPackages = this.props.packages.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    const create = true;
    return (
      <div id="packages">
        {this.getTitleBar()}
        <div className="secondary-title-bar">
          <div>
            <Container>
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding text-center"
                >
                  <span>Recomendations</span>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* <div className="contentbox"> */}
        <div>
          <div className="section">
            <Container>
              <Row className="text-center">
                <Col lg={12} md={12} sm={12} xs={12}>
                  {/* <PromotionFeedMulti /> */}
                </Col>
              </Row>
            </Container>
          </div>
          {this.getFooter()}
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} .promotedHero {position: fixed; bottom: 0px;left: 50%; transform: translateX(-50%); min-width: 400px;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%}  .full-height{height:100%} .maincontent-push { padding-top: 40px; position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    alllocations: state.locations.locations,
    newEvent: state.newEvent,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(init());
    },
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    resetNewEvent: (newEvent) => {
      dispatch(resetNewEvent(newEvent));
    },
    getConnectionsTagsAndFavs: (userId) => {
      dispatch(getConnectionsTagsAndFavs(userId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanEventOptions);
