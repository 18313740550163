import firebase from '../config/firebase';

export function setFavorites(action) {
  const db = firebase.firestore();

  const getP = function () {
    return new Promise((resolve, reject) => {
      const update = db
        .collection('users')
        .doc(action.payload.userId);
      const setDoc = update
        .update({
          favorites: action.payload.favorites,
        })
        .then((result) => {
          resolve(setDoc);
        });
      return setDoc;
    });
  };
  return getP();
}

export function setEntertainmentFavorites(action) {
  const db = firebase.firestore();

  const getP = function () {
    return new Promise((resolve, reject) => {
      const update = db
        .collection('users')
        .doc(action.payload.userId);
      const setDoc = update
        .update({
          favoriteEntertainment: action.payload.favoriteEntertainment,
        })
        .then((result) => {
          resolve(setDoc);
        });
      return setDoc;
    });
  };
  return getP();
}
