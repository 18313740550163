import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import '../PromotedHero/PromotedHero.css';

const query = require('array-query');

class PromotedArticleHero extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      activeIndex: 0,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  UNSAFE_componentWillMount() {
    const carouselItems = [];
    const promotedArticleArray = query('promoted')
      .is(true)
      .on(this.props.articles);

    promotedArticleArray.forEach((promotedDoc) => {
      carouselItems.push({
        src: promotedDoc.hero,
        altText: promotedDoc.title,
        caption: promotedDoc.teaser,
        header: promotedDoc.title,
        id: promotedDoc.id,
        slug: promotedDoc.slug,
      });
    });

    this.setState({
      packages: promotedArticleArray,
      loading: false,
      carouselItems: carouselItems,
    });
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.carouselItems.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.carouselItems.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    const { activeIndex } = this.state;
    const slides = this.state.carouselItems.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
          className="custom-tag"
        >
          <Link
            to={{
              pathname: '/article/' + item.slug,
            }}
          >
            <div className="carousel-images">
              <img
                src={item.src}
                alt={item.altText}
                className="carousel-image"
              />
            </div>
            <div className="carousel-text">
              <CarouselCaption
                captionText={item.caption}
                captionHeader={item.header}
              />
            </div>
          </Link>
        </CarouselItem>
      );
    });
    return (
      <Row>
        <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
          <div className="promotedHero">
            <style>
              {`
            
              h3 {
    font-size: 30px;
    line-height: 44px;
    font-weight: 200 !important;
    margin-top: 30px;
    margin-bottom: 5px;
    color: #f21d59;
}
              .d-none {
  display: block !important;
}
              
              .carousel-image{
                width: 100%;
                height:100%;
              }
              .carousel-images{
                max-height:300px;
              }
              .carousel-text {
    display: block;
    background: #fbfbfb;
    border-top: 1px solid grey;
    position: relative;
    padding: 10px 30px 10px 30px;
}
.carousel {
    position: relative;
    margin: 0px auto;
    border-top: 1px solid grey;
    margin-bottom:0px
}
.carousel-caption {
    position: relative;
    right: 0%;
    bottom: 20px;
    left: 0%;
    z-index: 10;
    padding-top: 0px;
    padding-bottom: 0px;
    color: #110f10;
    text-align: start;
    font-size:18px;
}
.carousel-indicators li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color:#f00;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: none !important;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.active{
  background-color:#444 !important;
}
                
              }`}
            </style>
            <Carousel
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              interval="300000"
            >
              <CarouselIndicators
                items={this.state.carouselItems}
                activeIndex={activeIndex}
                onClickHandler={this.goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </Carousel>
          </div>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    articles: state.articles.articles,
  };
}

export default connect(mapStateToProps)(PromotedArticleHero);
