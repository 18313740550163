import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import geolib from 'geolib';
// Images
import trevelguide from '../images/trevelguide.png';
import Modal from 'react-modal';
// Components
import SignUpBanner from '../components/SignUpBanner';
import FilterModal from '../components/FilterModal';
import firebase from '../config/firebase';
import Select from 'react-select';

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
} from 'reactstrap';
import Features from '../components/Features';
import RedCallToAction from '../components/RedCallToAction';
import { Link } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import MaterialIcon from 'material-icons-react';
// Reducers
import { setLocations } from '../sagas/locationsSaga/reducer';
import { setPackages } from '../sagas/packagesSaga/reducer';
import { getUauth } from '../sagas/unauthenticatedSaga/reducer';

const query = require('array-query');
const _ = require('lodash');
const KEYS_TO_FILTERS = [
  'locationTitle',
  'address.city',
  'address.street',
  'tagsBeverages',
  'tagsCuisine',
  'tagsSettings',
  'tagsDateType',
];

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    width: '300px',
  },
};

export class SearchGuide extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      landing: true,
      promos: [],
      locations: [],
      sortState: 1,
      tagFilters: [],
      locationsWithDistance: [],
      distanceFilterState: 0,
      locationServices: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.setTagFilters = this.setTagFilters.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.title = 'St. Louis Trevel Guide';
    // if (this.props.user.authenticated === true) {
    //   this.props.history.push('/home');
    // }
  }
  componentDidMount() {
    this.setState({
      locationsWithDistance:
        this.props.uauth.guide.locationsWithDistance,
      filteredArray: this.props.uauth.guide.filteredArray,
      loading: false,
      landing: false,
      locationServices: true,
    });
    this.setTags(this.props.tags, this.props.locations);

    this.searchUpdated('');
  }

  // Filter Functions
  openFilterModal(user) {
    this.setState({ filterModalVisible: true });
  }

  closeFilterModal = () => {
    this.searchUpdated('');
    window.scrollTo(0, 0);
    this.setState({ filterModalVisible: false });
  };

  setTagFilters = (tagFilters) => {
    this.setState({ tagFilters });
  };

  setDistanceFilter = (value) => {
    this.setState({
      distanceFilterState: value,
    });
  };

  setMoodModalState(status) {
    this.setState({
      moodSet: status,
    });
    this.searchUpdated('');
  }

  // setFirstFilter(tag) {
  //   let updatedTags = this.state.tagFilters;
  //   updatedTags[tag.parentindex].tags[tag.tagindex].checked = true;
  //   this.setState({
  //     tagFilters: updatedTags,
  //   });
  //   this.setTagFilters(updatedTags);
  //   this.searchUpdated(this.state.searchTerm);
  //   this.clearLanding(false);
  //   this.updateUauth(updatedTags);
  // }

  getGeoLocation = (locationsx) => {
    let self = this;
    let currentLongitude = '';
    let currentLatitude = '';
    this.getPosition()
      .then((position) => {
        if (position.coords) {
          self.setState({
            currentLongitude: position.coords.longitude,
            currentLatitude: position.coords.latitude,
            error: null,
          });
          currentLatitude = position.coords.latitude;
          currentLongitude = position.coords.longitude;
          this.addDistance(
            this.state.locations,
            currentLongitude,
            currentLatitude,
          );
          this.searchUpdated('', locationsx);
        } else {
          alert('Geolocation is not supported by this browser.');
        }
      })
      .catch((err) => {
        console.error('something wrongg', err.message);
        self.setState({
          locationServices: false,
        });
      });
  };

  getPosition() {
    let options = {
      timeout: 5000,
      maximumAge: 10000,
      enableHighAccuracy: true,
    };
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          options,
        ),
      );
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }

  addDistance(locations, currentLongitude, currentLatitude) {
    const locationsArray = [];
    locations.forEach((location) => {
      const locLat = location.address.latitude;
      const locLong = location.address.longitude;
      const distance = geolib.getDistance(
        { latitude: locLong, longitude: locLat },
        { latitude: currentLatitude, longitude: currentLongitude },
      );
      // console.log("geolibtard", distance);
      const miles = geolib.convertUnit('mi', distance, 2); // -> 14,21
      location.address.distance = miles;

      locationsArray.push(location);
    });
    const sortedLocations = this.sortAndWeight(
      locations,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );

    this.updateUauthGuide(locationsArray, sortedLocations);

    this.setState({
      locationsWithDistance: locationsArray,
      filteredArray: sortedLocations,
      loading: false,
      locationServices: true,
    });
  }

  sortAndWeight(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    const sortedLocations = this.sortLocations(
      locations,
      distanceFilterState,
      currentLongitude,
      currentLatitude,
      sortState,
    );
    // const weightedLocations = this.getWeightedLocations(
    //   sortedLocations,
    //   this.props.profile,
    // );

    return sortedLocations;
  }

  sortLocations(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    if (sortState === 1) {
      const locationsArray = [];
      locations.forEach((location) => {
        const miles = location.address.distance;
        if (location.type !== 1) {
          if (distanceFilterState === 0) {
            if (miles <= 50) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 1) {
            if (miles <= 10) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 2) {
            locationsArray.push(location);
          }
        }
      });
      return _.sortBy(locationsArray, (o) => o.address.distance);
    } else {
      return _.sortBy(locations, (o) => o.locationTitle);
    }
  }

  searchUpdated(term, locationsx) {
    let locationsWithDistance = this.state.locationsWithDistance;
    if (this.props.uauth.guide.locationsWithDistance.length !== 0) {
      locationsWithDistance =
        this.props.uauth.guide.locationsWithDistance;
    }

    if (locationsx !== undefined) {
      let bundle = {
        locations: locationsx[0],
      };
      this.props.setLocations(bundle);
    }
    const filteredList = locationsWithDistance.filter(
      createFilter(term, KEYS_TO_FILTERS),
    );

    const filteredPromos = this.state.promos.filter(
      createFilter(term, KEYS_TO_FILTERS),
    );

    let filteredLocations = [];
    const sortedLocations = this.sortAndWeight(
      filteredList,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );

    let selectedTags = [];

    let tagFilters = this.state.tagFilters;
    if (this.props.uauth.guide.tagFilters.length !== 0) {
      tagFilters = this.props.uauth.guide.tagFilters;
    }

    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });

    if (selectedTags.length > 0) {
      filteredLocations = this.updateLocations(
        tagFilters,
        sortedLocations,
      );
    } else {
      filteredLocations = sortedLocations;
    }

    if (filteredLocations.length === 0) {
      this.setDistanceFilter(2);
      const sortedLocationsx = this.sortAndWeight(
        filteredList,
        2,
        this.state.currentLongitude,
        this.state.currentLatitude,
        this.state.sortState,
      );

      if (selectedTags.length > 0) {
        filteredLocations = this.updateLocations(
          tagFilters,
          sortedLocationsx,
        );
      } else {
        filteredLocations = sortedLocationsx;
      }
    }

    this.setState({
      searchTerm: term,
      filteredArray: filteredLocations,
      filteredPromos: filteredPromos,
    });
  }

  updateLocations(tags, locations) {
    // This will have every location but with its tags put into a single list
    const locationsReady = [];
    // For each location we push all of its tags into the allTags array
    locations.forEach((location) => {
      const newLocation = location;
      const allTags = [];
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      newLocation.tags = allTags;

      locationsReady.push(newLocation);
    });
    // const dif = _.differenceWith(cuisine, beverages, _.isEqual);
    // const filtered = _.filter(coll, item => _.some(filter, key => _.includes(item.tags, key)));

    // Filter Locations Based On Selected Categories
    function filterByMultipleFields(coll, filter) {
      // Organize Tags into their categories
      const tagsBeverages = [];
      const tagsSettings = [];
      const tagsCuisine = [];
      const tagsHours = [];
      const tagsDateType = [];
      const tagsLocationType = [];

      let beverages = [];
      let locationType = [];
      let setting = [];
      let type = [];
      let cuisine = [];
      let hours = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Hours') {
            tagsHours.push(tag.tag);
          } else if (tagcat.parent === 'Beverages') {
            tagsBeverages.push(tag.tag);
          } else if (tagcat.parent === 'Date Type') {
            tagsDateType.push(tag.tag);
          } else if (tagcat.parent === 'Cuisine Type') {
            tagsCuisine.push(tag.tag);
          } else if (tagcat.parent === 'Setting Type') {
            tagsSettings.push(tag.tag);
          } else if (tagcat.parent === 'Location Type') {
            tagsLocationType.push(tag.tag);
          }
        });
      });

      filter.forEach((tag) => {
        if (tagsBeverages.includes(tag) === true) {
          beverages = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsBeverages, key),
            ),
          );
        } else if (tagsDateType.includes(tag) === true) {
          type = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsDateType, key),
            ),
          );
        } else if (tagsLocationType.includes(tag) === true) {
          locationType = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsLocationType, key),
            ),
          );
        } else if (tagsSettings.includes(tag) === true) {
          setting = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsSettings, key),
            ),
          );
        } else if (tagsHours.includes(tag) === true) {
          hours = _.filter(locationsReady, (item) =>
            _.some(filter, (key) => _.includes(item.tagsHours, key)),
          );
        } else if (tagsCuisine.includes(tag) === true) {
          cuisine = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsCuisine, key),
            ),
          );
        }
      });
      const filtered = _.filter(coll, (item) =>
        _.some(filter, (key) => _.includes(item.tags, key)),
      );
      const arrays = [
        cuisine,
        beverages,
        type,
        setting,
        hours,
        locationType,
      ];
      const result = _.spread(_.union)(arrays);
      return result;
    }
    const filter = this.selectedTags(tags);
    const result = filterByMultipleFields(locationsReady, filter);

    return result;
    // if (this.props.selectedCategories.length > 0) {
    //   this.setState({
    //     filteredLocations: result,
    //   });
    // } else {
    //   this.setState({
    //     filteredLocations: locations,
    //   });
    // }
  }

  selectedTags(tagFilters) {
    let selectedTags = [];
    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });
    return selectedTags;
  }

  getCurrentDistance(filter) {
    let text = '';
    if (filter === 0) {
      text = '50';
    } else if (filter === 1) {
      text = '10';
    } else {
      text = '100+';
    }
    return text;
  }
  // End

  // Location Functions

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return <span className="mainTag">{location.tagsMain}</span>;
    } else {
      return <span className="mainTag" />;
    }
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getModalHeader = (value) => {
    return (
      <Row className="no-margin">
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
        <Col lg={8} md={8} sm={8} xs={8}>
          <center>
            <span className="page-header white">
              Filter Locations
            </span>
          </center>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
          <a onClick={() => this.closeFilterModal()}>
            <MaterialIcon icon="check" size={25} color="white" />
          </a>
        </Col>
      </Row>
    );
  };

  getButton(value, distance, state) {
    if (value !== state) {
      return (
        <button
          className="redbutton full-width"
          onClick={() => {
            this.setDistanceFilter(value);
          }}
        >
          {distance}
        </button>
      );
    } else {
      return (
        <button className="greybutton full-width">{distance}</button>
      );
    }
  }

  clearLanding(status) {
    this.setState({
      landing: status,
    });
  }

  getTags(tagFilters) {
    let activeTags = [];
    tagFilters.forEach((tag) => {
      tag.tags.forEach((tagx) => {
        if (tagx.checked === true) {
          activeTags.push(tagx);
        }
      });
    });
    return (
      <div>
        {activeTags.map((tag, index) => (
          <span className="tag-big" key={tag}>
            {tag.tag}
          </span>
        ))}
        <a
          onClick={() => {
            this.openFilterModal();
          }}
          style={{ color: '#f21d59', fontWeight: '600' }}
        >
          <span
            style={{
              color: 'white',
              fontWeight: '600',
              marginRight: '10px',
            }}
          >
            {activeTags.length === 0 ? 'Filter' : 'Change'}
          </span>
        </a>
        <span
          onClick={() => {
            this.clearTagFilters(this.state.tagFilters);
          }}
          style={{ fontSize: '16px', color: 'white' }}
        >
          Clear
        </span>
      </div>
    );
  }

  // criteria Handlers
  selectChange = (values) => {
    let updatedTags = this.state.tagFilters;
    updatedTags[values.value.parentindex].tags[
      values.value.tagindex
    ].checked = true;
    this.setState({
      tagFilters: updatedTags,
    });
    this.setTagFilters(updatedTags);
    this.searchUpdated(this.state.searchTerm);
    this.clearLanding(false);
    this.updateUauth(updatedTags);
  };

  updateUauth(updatedTags) {
    let bundle = {
      claimId: this.props.uauth.claimId,
      vendorId: this.props.uauth.vendorId,
      claimLink: this.props.uauth.claimLink,
      guide: {
        landing: false,
        loading: false,
        tagFilters: updatedTags,
        locationsWithDistance:
          this.props.uauth.guide.locationsWithDistance,
        filteredArray: this.props.uauth.guide.filteredArray,
        promos: this.props.uauth.guide.promos,
      },
    };
    this.props.getUauth(bundle);
  }

  updateUauthGuide(locationsWithDistance, filteredArray, promos) {
    let bundle = {
      claimId: this.props.uauth.claimId,
      vendorId: this.props.uauth.vendorId,
      claimLink: this.props.uauth.claimLink,
      guide: {
        landing: this.props.uauth.guide.landing,
        loading: this.props.uauth.guide.loading,
        tagFilters: this.props.uauth.guide.tagFilters,
        locationsWithDistance,
        filteredArray,
        // promos: promos,
      },
    };
    this.props.getUauth(bundle);
  }

  getSelectOptions() {
    let locations = this.state.locations;
    let allTags = [];

    locations.forEach((location) => {
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsMain !== undefined) {
        let status = allTags.includes(location.tagsMain);
        if (status === false) {
          allTags.push(location.tagsMain);
        }
      }
    });

    let selectOptions = [];
    this.state.tagFilters.forEach((tagx, indexA) => {
      tagx.tags.forEach((tag, indexB) => {
        let status = allTags.includes(tag.tag);
        if (status === true) {
          selectOptions.push({
            value: tag,
            label: tag.tag,
          });
        }
      });
    });

    let reversedOptions = selectOptions.reverse();
    // this.setTagFilters(updatedTagFilters);
    return reversedOptions;
  }

  setTags(tags, locations) {
    let filteredTags = this.filterTags(tags, locations);
    let updatedTags = filteredTags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = 1;
        if (this.props.user.authenticated === true) {
          status = this.getTagStatus(tag.tag);
        }
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let drinks = {
    //   checked: false,
    //   parentindex: 6,
    //   status: 1,
    //   tag: 'Drinks',
    //   tagindex: updatedTags[6].tags.length,
    // };

    let food = {
      checked: false,
      parentindex: 1,
      status: 1,
      tag: 'Food',
      tagindex: updatedTags[1].tags.length,
    };

    // updatedTags[6].tags.push(drinks);
    updatedTags[1].tags.push(food);
    updatedTags.forEach((tagcat, x) => {
      updatedTags[x].tags = updatedTags[x].tags.sort((a, b) =>
        a.tag.localeCompare(b.tag),
      );
      updatedTags[x].tags = updatedTags[x].tags.reverse();
      updatedTags[x].tags.map((tag, y) => {
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let sortedTags = []
    // updatedTags.forEach((tagcat, x) => {
    //   sortedTags = _.sortBy(updatedTags[x].tags, o => o.status);
    // }

    let origionalTags = [];
    updatedTags.forEach((tagcat) => {
      origionalTags.push(tagcat);
    });

    this.setState({
      tagFilters: updatedTags,
      origionalTags,
    });
    this.setTagFilters(updatedTags);
  }

  filterTags(tagFilters, locations) {
    let updatedTagFilters = [
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
      {
        id: '5vhCJSC7VgBE1MFNctgY',
        parent: 'Location Type',
        sid: 'locationType',
        tags: [],
      },
      {
        id: '7ayttBEiZgiM5TZ2iYsM',
        parent: 'Cuisine Type',
        sid: 'cuisineType',
        tags: [],
      },
      {
        id: '7kJeYaz9m5q434zO28Pg',
        parent: 'Setting Type',
        sid: 'setting',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Experience Type',
        sid: 'experienceType',
        tags: [],
      },
      {
        id: 'BW3qPhXbN7C4XMXWh4tv',
        parent: 'Date Type',
        sid: 'dateType',
        tags: [],
      },
      {
        id: 'CSKtI08vSBtyqKr10Nef',
        parent: ' Dietary Must Haves',
        sid: 'dietary',
        tags: [],
      },
      {
        id: 'F7R2nsOyZTiqKbW6AhFQ',
        parent: 'Hours',
        sid: 'hours',
        tags: [],
      },
      {
        id: 'xeytdS8Xwjlou67MVbVY',
        parent: 'Beverages',
        sid: 'beverages',
        tags: [],
      },
      {
        id: 'rxsnb1c2XX8c4MpugeEP',
        parent: 'Genre',
        sid: 'genre',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Entertainment Type',
        sid: 'entertainmentType',
        tags: [],
      },
      {
        id: 'eAVFUFVivBObckEaKiRR',
        parent: 'Event Type',
        sid: 'eventType',
        tags: [],
      },
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
    ];
    let allTags = [];
    locations.forEach((location) => {
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsMain !== undefined) {
        let status = allTags.includes(location.tagsMain);
        if (status === false) {
          allTags.push(location.tagsMain);
        }
      }
    });
    tagFilters.forEach((tagx, indexA) => {
      tagx.tags.forEach((tag, indexB) => {
        let status = allTags.includes(tag.tag);
        if (status === true) {
          updatedTagFilters[indexA].tags.push(tag);
        }
      });
    });
    return updatedTagFilters;

    // this.setState({
    //   tagFilters: updatedTagFilters,
    // });
    // this.setTagFilters(updatedTagFilters);
  }

  clearTagFilters(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    this.setState({
      tagFilters: updatedTags,
    });
    window.scrollTo(0, 0);
    this.searchUpdated('');
  }

  getPromos(promos) {
    let filteredPromos = this.filterPromos(promos);

    let categories = [];

    // filteredPromos.forEach((promo, index) => {
    //   console.log('filteredPromos', promo);
    //   promo.tagsExperienceTypes.forEach((tag, index) => {
    //     let status = categories.includes(tag);
    //     if (status === false) {
    //       categories.push(tag);
    //     }
    //   });
    // });

    if (filteredPromos.length > 0) {
      return (
        <div>
          <div
            className="text-center"
            style={{
              padding: '10px 0px 10px 0px',
            }}
          >
            <h4>Experiences</h4>
          </div>
          {/* {categories.map((tag, index) => (
            <div>
              <h5>{tag}s</h5>
              {this.getPromoGroup(tag, filteredPromos)}
            </div>
          ))} */}
          <Row>{this.getDemoPromos(filteredPromos)}</Row>
        </div>
      );
    } else {
      return <div />;
    }
  }

  filterPromos(promos) {
    let filteredPromos = [];
    let promoArray = [];

    this.props.demo.demoPromos.forEach((promo, index) => {
      filteredPromos.push(promo);
      promoArray.push(promo);
    });

    promos.forEach((promo, index) => {
      promoArray.push(promo);
      let status = false;
      promo.locations.forEach((location, index) => {
        const locationDetails = query('locationId')
          .is(location.locationId)
          .on(this.state.locations);
        locationDetails[0].tags.forEach((tag, index) => {
          this.state.tagFilters.forEach((tagx, index) => {
            tagx.tags.forEach((tagxx) => {
              if (tagxx.checked === true && tagxx.tag === tag) {
                status = true;
              }
            });
          });
        });
      });
      if (status === true) {
        filteredPromos.push(promo);
      }
    });

    let selectedTags = [];
    this.state.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });

    if (selectedTags.length > 0) {
      return filteredPromos;
    } else {
      return promoArray;
    }
  }

  getPromoGroup(tag, promos) {
    let promoGroup = [];
    promos.forEach((promo, index) => {
      promo.tagsExperienceTypes.forEach((tagx, index) => {
        if (tagx === tag) {
          promoGroup.push(promo);
        }
      });
    });
    return (
      <div>
        {promoGroup.map((promo, index) => (
          <div>{this.getPromo(promo)}</div>
        ))}
      </div>
    );
  }

  getExperienceTags(experience) {
    const experienceTypes = [];
    if (experience.tagsExperienceTypes !== undefined) {
      experience.tagsExperienceTypes.forEach((tag) => {
        experienceTypes.push({
          value: tag,
          label: tag,
        });
      });
    }
    return (
      <div>
        {experienceTypes &&
          experienceTypes.map((tag, index) => (
            <span className="experienceTag" key={tag.label}>
              {tag.label}
            </span>
          ))}
      </div>
    );
  }

  getPromoLocations(event) {
    return (
      <ul id="horizontalTitles">
        {event.locations &&
          event.locations.map((location, index) => (
            <li key={location.locationId}>
              {location.locationTitle}
            </li>
          ))}
      </ul>
    );
  }

  getDemoPromos(promos) {
    let promoArray = [];
    promos.forEach((promo) => {
      if (
        promo.id === '1gU7CfzO7Kkm2krYy4wA' ||
        promo.id === 'FA4wBUbKTbXDIZArBUFg'
      ) {
        promoArray.push(promo);
      }
    });
    return (
      <div>
        {promoArray.map((promo, index) => (
          <Col
            xs={12}
            md={6}
            sm={12}
            lg={6}
            key={promo.id}
            className="guidePromo"
          >
            {this.getPromo(promo)}
          </Col>
        ))}
      </div>
    );
  }

  getPromo(event) {
    const withNoDigits = event.id.replace(/[0-9]/g, '');
    const toggler = '#' + withNoDigits;
    let demo = false;
    if (event.demo === true) {
      demo = true;
    }
    let demoDetails = [];
    if (event.demo === true) {
      demoDetails = event;
    }
    return (
      // <Card style={{ border: '0px solid rgba(0, 0, 0, 0.125)' }}>

      <Card style={{ height: '100%' }}>
        <Link
          to={{
            pathname: '/vevent/' + event.id,
            state: {
              demo,
              demoDetails,
            },
          }}
          className="left"
        >
          <CardImg
            top
            width="100%"
            src={event.images.heroPath}
            alt="articleHero"
          />
        </Link>
        <CardBody>
          <Container>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
                style={{ textAlign: 'left' }}
              >
                <span className="title" style={{ fontSize: '17px' }}>
                  {event.title}
                </span>
                <span className="promo-criteria">
                  {event.description}
                </span>

                <div className="clear">
                  {this.getPromoLocations(event)}
                </div>
                <div style={{ marginBottom: '10px', clear: 'both' }}>
                  {this.getExperienceTags(event)}
                </div>
              </Col>
            </Row>
          </Container>
          {/* <UncontrolledCollapse toggler={toggler}>
            <div className="card-section">{event.teaser}</div>
          </UncontrolledCollapse> */}
        </CardBody>
      </Card>
    );
  }

  getPromotedTag(tagTerm) {
    let term = tagTerm;
    if (tagTerm === 'Parks & Recreation') {
      term = 'Park';
    }

    let tagFilters = this.state.tagFilters;
    let tagMatch = null;
    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (term === tag.tag) {
          tagMatch = tag;
        }
      });
    });
    if (tagMatch !== null) {
      return (
        <li
          onClick={() => {
            this.setFirstFilter(tagMatch);
          }}
        >
          <span>{tagTerm}</span>
        </li>
      );
    }
  }

  getTruncatedBody(article) {
    if (article.teaser !== undefined) {
      return article.teaser;
    } else {
      const length = 130;
      const truncatedString =
        article.body.substring(0, length) + '...';
      return truncatedString;
    }
  }

  getArticles() {
    let articleArray = [];
    this.props.articles.forEach((article) => {
      if (article.zoneIds !== undefined) {
        let match = article.zoneIds.includes(11);
        if (match === true && article.promoted === true) {
          articleArray.push(article);
        }
      }
    });

    return (
      <Row>
        {articleArray.map((article, index) => (
          <Col xs={12} md={6} sm={12} lg={6} key={'key' + index}>
            <Link
              to={{
                pathname: '/article/' + article.slug,
              }}
            >
              <div>
                <Card style={{ textAlign: 'left' }}>
                  <CardImg
                    top
                    width="100%"
                    src={article.hero}
                    alt="articleHero"
                  />
                  <CardBody>
                    <CardTitle>{article.title}</CardTitle>
                    {/* <small className="text-muted right">
                      {this.getPostDate(article.postdate)}
                    </small> */}
                    <p className="author">
                      {/* {article.author} - {this.getPostDate(article.postdate)} */}
                      {article.author}
                    </p>
                    <CardText>
                      {this.getTruncatedBody(article)}
                    </CardText>
                    <CardText />
                  </CardBody>
                </Card>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    );
  }

  getFilterModal() {
    if (this.state.tagFilters.length !== 0) {
      return (
        <FilterModal
          tagFilters={this.state.tagFilters}
          setTagFilters={this.setTagFilters}
          locations={this.state.locations}
          // selectOptions={selectOptions}
        />
      );
    }
  }

  getSideArticles(articles) {
    let articalArray = [];
    articles.forEach((article) => {
      if (article.zoneIds !== undefined) {
        let match = article.zoneIds.includes(11);
        if (match === true && article.promoted === true) {
          articalArray.push(article);
        }
      }
    });
    return (
      <div>
        {articalArray.map((article, index) => (
          <div>
            <Link
              to={{
                pathname: '/article/' + article.slug,
              }}
              className="left"
            >
              <Card style={{ textAlign: 'left' }}>
                <CardImg
                  top
                  width="100%"
                  src={article.hero}
                  alt="articleHero"
                />
                <CardBody>
                  <CardTitle>{article.title}</CardTitle>
                  {/* <small className="text-muted right">
                      {this.getPostDate(article.postdate)}
                    </small> */}
                  <p className="author">
                    {/* {article.author} - {this.getPostDate(article.postdate)} */}
                    {article.author}
                  </p>
                  <CardText>
                    {this.getTruncatedBody(article)}
                  </CardText>
                  <CardText />
                </CardBody>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    );
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/home',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Search The Guide</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  render() {
    let selectOptions = this.getSelectOptions();
    let searchTerm = this.state.searchTerm;

    if (this.state.loading) {
      return (
        <div
          style={{
            backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fstllanding.png?alt=media&token=30292636-ca2a-4bfc-b7dc-4da988d518ee')`,
            height: '100%',
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '150px',
              textAlign: 'center',
            }}
          >
            <h2
              style={{
                margin: '0px 0px 10px 0px',
                color: 'white',
                fontSize: '40px',
                textShadow: '#bb4a65 1px 4px',
              }}
            >
              <img
                style={{ width: '90%', maxWidth: '300px' }}
                src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelUnderlinedWhite.svg?alt=media&token=cbb68d7c-4f17-4c84-ae47-e7054850fea9"
              />
            </h2>

            <span
              style={{
                fontSize: '35px',
                lineHeight: '35px',
                color: 'white',
                textShadow: '#bb4a65 1px 4px',
              }}
            >
              {this.state.locationServices === true
                ? 'Loading'
                : 'Please Check Your Location Settings and Refresh'}
            </span>
          </div>

          <style
            dangerouslySetInnerHTML={{
              __html:
                ' #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%; background-color: #e0395b;} .footer-container{display:none}  .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    if (this.state.landing) {
      return (
        <div
          style={{
            backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fstllanding.png?alt=media&token=30292636-ca2a-4bfc-b7dc-4da988d518ee')`,
            height: '100%',
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '150px',
              textAlign: 'center',
            }}
          >
            <h2
              style={{
                margin: '0px 0px 10px 0px',
                color: 'white',
                fontSize: '40px',
                textShadow: '#bb4a65 1px 4px',
              }}
            >
              <img
                style={{ width: '90%', maxWidth: '300px' }}
                src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelUnderlinedWhite.svg?alt=media&token=cbb68d7c-4f17-4c84-ae47-e7054850fea9"
              />
            </h2>
            <span
              style={{
                fontSize: '35px',
                lineHeight: '35px',
                color: 'white',
                textShadow: '#bb4a65 1px 4px',
              }}
            >
              St. Louis
            </span>
            <div
              style={{
                maxWidth: '600px',
                margin: '0px auto',
                display: 'block',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              {/* <span
                style={{
                  fontSize: '30px',
                  lineHeight: '35px',
                  color: 'white',
                  textShadow: '#bb4a65 1px 4px',
                }}
              >
                What are you in the mood for?
              </span> */}
              <Container>
                <Row style={{ margin: '0px 0px 10px 0px' }}>
                  <Col
                    xs={12}
                    md={5}
                    sm={12}
                    lg={5}
                    // className="no-padding"
                    style={{ padding: '5px 5px 5px 5px' }}
                  >
                    <Select
                      value={this.state.selectInput}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) => this.selectChange(e, 0, 'one')}
                      options={selectOptions}
                      isMulti={false}
                      // placeholder={"What are you in the mood forr"}
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={7}
                    sm={12}
                    lg={7}
                    // className="no-padding"
                    style={{ padding: '5px 5px 5px 5px' }}
                  >
                    <SearchInput
                      className="search-input guide-search"
                      onChange={this.searchUpdated}
                      placeholder="All Locations"
                      value={searchTerm}
                    />
                    <a
                      onClick={() => {
                        this.clearLanding(false);
                      }}
                      className="pointer"
                    >
                      <MaterialIcon
                        icon="search"
                        size={45}
                        color="#ffffff"
                      />
                    </a>
                  </Col>
                  {/* <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    // className="no-padding"
                    style={{ padding: '5px 5px 5px 5px' }}
                  >
                    <a
                      onClick={() => {
                        this.clearLanding(false);
                      }}
                      className="pointer"
                    >
                      <MaterialIcon
                        icon="search"
                        size={45}
                        color="#ffffff"
                      />
                    </a>
                  </Col> */}
                </Row>
              </Container>
            </div>
            <div
              style={{
                textAlign: 'center',
              }}
              id="menu"
            >
              <ul>
                {this.getPromotedTag('Food')}
                {this.getPromotedTag('Drinks')}
                {this.getPromotedTag('Date Night')}
                {this.getPromotedTag('Happy Hour')}
                {this.getPromotedTag('Entertainment')}
                {this.getPromotedTag('Parks & Recreation')}
              </ul>
            </div>
            <div style={{ marginTop: '150px' }}>
              {this.getArticles()}
            </div>
          </div>

          <style
            dangerouslySetInnerHTML={{
              __html:
                ' .card{margin-bottom: 20px;} html{background: #e0395b !important;} #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer-container{display:none}  .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    if (this.state.landing === false) {
      return (
        <div id="home" className="lightGreyBack">
          <div>
            <div className="greybox">
              <Container>
                <Row style={{ margin: '20px 0px 10px 0px' }}>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <center>
                      <h4
                        style={{ color: 'white', fontWeight: '300' }}
                      >
                        Search The Guide
                      </h4>
                    </center>
                  </Col>
                </Row>
                <Row style={{ margin: '0px 0px 10px 0px' }}>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <SearchInput
                      className="search-input"
                      onChange={this.searchUpdated}
                      value={searchTerm}
                      placeholder="Name, Tags, Street, City...."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={8}
                    md={8}
                    sm={8}
                    lg={8}
                    className="no-padding"
                  >
                    <div
                      className="cityTagBar"
                      // style={{ textAlign: 'center' }}
                    >
                      {this.getTags(this.state.tagFilters)}
                    </div>
                  </Col>
                  <Col
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    className="no-padding"
                  >
                    <span
                      style={{ color: 'white' }}
                      className="mobileHide right"
                    >
                      Showing locations{' '}
                      <b>
                        {this.getCurrentDistance(
                          this.state.distanceFilterState,
                        )}
                      </b>{' '}
                      miles from you.
                    </span>
                    <span
                      style={{
                        color: 'white',
                      }}
                      className="mobileShow right"
                    >
                      <b>
                        {this.getCurrentDistance(
                          this.state.distanceFilterState,
                        )}
                      </b>{' '}
                      miles radius.
                    </span>
                  </Col>
                </Row>
              </Container>
            </div>
            <Row
              className="banner-section-nomargin"
              style={{ padding: '10px 0px 10px 0px' }}
            >
              <Container className="no-padding">
                <Col
                  xs={12}
                  md={8}
                  sm={12}
                  lg={8}
                  className="no-padding"
                >
                  <Link
                    to={{
                      pathname: '/signup',
                    }}
                  >
                    <p
                      className="banner-text"
                      style={{ color: '#3e3e3e' }}
                    >
                      Customize Your Guide And Plan With Friends For
                      Free!
                    </p>
                  </Link>
                </Col>
                <Col
                  xs={12}
                  md={4}
                  sm={12}
                  lg={4}
                  className="no-padding"
                >
                  <div>
                    <Link
                      to={{
                        pathname: '/signup',
                      }}
                      className="whitebutton right"
                      style={{ fontWeight: '800 ' }}
                    >
                      <span style={{ fontWeight: '800 ' }}>
                        Sign Up
                      </span>
                    </Link>
                    <Link
                      to={{
                        pathname: '/about',
                      }}
                      className="whitebutton right"
                      style={{ marginRight: '10px' }}
                    >
                      <span>Learn More</span>
                    </Link>
                  </div>
                </Col>
              </Container>
            </Row>

            <Container>
              <Row style={{ marginBottom: '30px' }}>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  <div id="events">
                    <div>
                      <Row style={{ marginBottom: '30px' }}>
                        <Col
                          xs={12}
                          md={9}
                          sm={12}
                          lg={9}
                          className="no-padding"
                        >
                          {/* {this.getPromos(this.state.filteredPromos)} */}

                          <Container className="no-padding">
                            {this.state.filteredArray.map(
                              (location, index) => (
                                <Fragment>
                                  {/* <div
                            // className="oddeven"
                            key={location.locationId}
                          > */}

                                  <Col
                                    xs={12}
                                    md={6}
                                    sm={12}
                                    lg={6}
                                    // className="no-padding"
                                    key={location.locationId}
                                    style={{ height: '110px' }}
                                  >
                                    <Row key={location.locationId}>
                                      <Col
                                        xs={4}
                                        md={4}
                                        sm={4}
                                        lg={4}
                                        className="no-padding thumbnailCol"
                                      >
                                        <Link
                                          to={{
                                            pathname:
                                              '/location/' +
                                              location.locationId,
                                            state: {
                                              location: { location },
                                              // eventId: { eventId },
                                              // token: { token },
                                              type: 57,
                                            },
                                          }}
                                        >
                                          {/* <a
                                    onClick={() => {
                                      this.showLocationDetails(
                                        location,
                                      );
                                    }}
                                  > */}
                                          {this.getImage(
                                            'thumb',
                                            location,
                                          )}
                                          {/* </a> */}
                                        </Link>
                                      </Col>
                                      <Col
                                        xs={6}
                                        md={6}
                                        sm={6}
                                        lg={6}
                                        className="no-padding"
                                      >
                                        <Link
                                          to={{
                                            pathname:
                                              '/location/' +
                                              location.locationId,
                                            state: {
                                              location: { location },
                                              // eventId: { eventId },
                                              // token: { token },
                                              type: 57,
                                            },
                                          }}
                                        >
                                          {/* <a
                                    onClick={() => {
                                      this.showLocationDetails(
                                        location,
                                      );
                                    }}
                                  > */}
                                          <span className="location-title">
                                            {location.locationTitle}
                                          </span>
                                          {/* <span className="location-title">
                                    {location.locationId}
                                  </span> */}
                                          <span className="address">
                                            {location.address.street}
                                          </span>
                                          <span className="address">
                                            {location.address.city}{' '}
                                            {location.address.state},{' '}
                                            {location.address.zip}
                                          </span>
                                          {/* </a> */}
                                        </Link>
                                      </Col>
                                      <Col
                                        xs={2}
                                        md={2}
                                        sm={2}
                                        lg={2}
                                        className="no-padding"
                                      >
                                        <span
                                          style={{
                                            display: 'block',

                                            position: 'relative',
                                            float: 'right',
                                            top: '-4px',
                                          }}
                                        >
                                          {/* {this.getFavoriteButton(
                        location.locationId,
                        favorites,
                      )} */}
                                        </span>
                                        {/* <div className="right text-right">
                      <MaterialIcon
                        icon="add_circle"
                        size={25}
                        color="#f21d59"
                        onClick={() => {
                          this.addLocation(
                            this.props.customEvent,
                            location,
                          );
                        }}
                      />
                    </div> */}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        xs={4}
                                        md={4}
                                        sm={4}
                                        lg={4}
                                        className="no-padding"
                                      >
                                        <span className="distance">
                                          {location.address.distance}{' '}
                                          miles
                                        </span>
                                      </Col>

                                      <Col
                                        xs={8}
                                        md={8}
                                        sm={8}
                                        lg={8}
                                        className="no-padding"
                                      >
                                        {this.getMainTag(location)}
                                        {/* {this.getes(location)}  */}
                                      </Col>
                                    </Row>
                                  </Col>
                                  {/* </div> */}
                                </Fragment>
                              ),
                            )}
                          </Container>
                        </Col>
                        <Col
                          xs={11}
                          md={3}
                          sm={11}
                          lg={3}
                          style={{ background: 'white' }}
                        >
                          {this.getSideArticles(this.props.articles)}
                          <center>
                            <Link
                              to={{
                                pathname: '/articles',
                              }}
                            >
                              <span>Read More</span>
                            </Link>
                          </center>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <RedCallToAction />
          <Features />
          <Modal
            isOpen={this.state.filterModalVisible}
            // onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeFilterModal}
            style={modalStyles}
            // contentLabel="Wait for Connection?"
          >
            <div className="full-height">
              <div className="modalHeader">
                {this.getModalHeader()}
              </div>
              <div className="secondary-title-bar">
                <div>
                  <Container>
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding text-center"
                      >
                        <span>What are you in the mood for?</span>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              <div className="wizard-section-border">
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <h5 className="red">Distance</h5>
                    <span>Radius (Miles):</span>
                  </Col>
                </Row>

                <Row>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="padding-ten"
                  >
                    {this.getButton(
                      1,
                      '10',
                      this.state.distanceFilterState,
                    )}
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="padding-ten"
                  >
                    {this.getButton(
                      0,
                      '50',
                      this.state.distanceFilterState,
                    )}
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="padding-ten"
                  >
                    {this.getButton(
                      2,
                      '100+',
                      this.state.distanceFilterState,
                    )}
                  </Col>
                </Row>
              </div>
              <div className="wizard-section-border">
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.getFilterModal()}
                  </Col>
                </Row>
              </div>
              {/* <div className="section">{this.getModalContent()}</div> */}
            </div>
          </Modal>

          <style
            dangerouslySetInnerHTML={{
              __html:
                '.maincontent {  background: #ffffff;}  @media (min-width: 801px) { .maincontent {margin-top: 55px;}}',
            }}
          />
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLocations: (locations) => {
      dispatch(setLocations(locations));
    },
    setPackages: (packages) => {
      dispatch(setPackages(packages));
    },
    getUauth: (bundle) => {
      dispatch(getUauth(bundle));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    tags: state.tags.tags,
    locations: state.locations.locations,
    articles: state.articles.articles,
    uauth: state.unauth,
    packages: state.packages.packages,
    demo: state.demo,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchGuide);
