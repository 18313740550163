import firebase from '../config/firebase';

export function getArticles() {
  const articlesArray = [];
  const db = firebase.firestore();

  const getP = function () {
    return new Promise((resolve, reject) => {
      db.collection('articles')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                title,
                body,
                author,
                postdate,
                hero,
                authorId,
                readMore,
                originator,
                promoted,
                slug,
                teaser,
                zoneIds,
              } = doc.data();
              articlesArray.push({
                id: doc.id,
                title,
                body,
                author,
                postdate,
                hero,
                authorId,
                readMore,
                originator,
                promoted,
                slug,
                teaser,
                zoneIds,
              });
            }
          });
          return articlesArray;
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  return getP();
}
