import React, { Component } from 'react';
import { connect } from 'react-redux';
// Components
import RelatedArticleListItem from '../../components/RelatedArticleListItem';

class RelatedArticles extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div>
        <RelatedArticleListItem articles={this.props.articles} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(RelatedArticles);
