import firebase from '../config/firebase';

// Get Tag Categories
export function getTagsAndFavs(users) {
  const docArray = [];
  const db = firebase.firestore();
  let promiseArr = [];
  const getV = function () {
    if (users.payload[0] !== undefined) {
      promiseArr = users.payload.map(function (user) {
        let userId = user.inviteId;
        const docs = db.collection('users').doc(userId);
        return new Promise((resolve, reject) => {
          const query = docs
            .get()
            .then((doc) => {
              if (doc && doc.exists) {
                const {
                  tags,
                  favorites,
                  firstName,
                  lastName,
                  favoriteEntertainment,
                } = doc.data();
                docArray.push({
                  userId,
                  tags,
                  favorites,
                  firstName,
                  lastName,
                  favoriteEntertainment,
                });
              }
              return docArray;
            })
            .then((result) => {
              resolve(docArray);
            });
        });
      });
    } else {
      let userId = users.payload.inviteId;
      const docs = db.collection('users').doc(userId);
      return new Promise((resolve, reject) => {
        const query = docs
          .get()
          .then((doc) => {
            if (doc && doc.exists) {
              const {
                tags,
                favorites,
                firstName,
                lastName,
                favoriteEntertainment,
              } = doc.data();
              docArray.push({
                userId,
                tags,
                favorites,
                firstName,
                lastName,
                favoriteEntertainment,
              });
            }
            return docArray;
          })
          .then((result) => {
            resolve(docArray);
          });
      });
    }

    return Promise.all(promiseArr)
      .then(function (resultsArray) {
        return resultsArray[0];
      })
      .catch(function (err) {});
  };
  return getV();
}
