import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
// Components
import PromotedArticles from '../components/PromotedArticles';
import whitelogo from '../images/TrevelUnderlinedWhite.svg';
import DailyLocations from '../components/DailyLocations';
import DailyWeather from '../components/DailyWeather';
import GuideCategory from '../components/GuideCategory';
import DailyContactLocations from '../components/DailyContactLocations';
import GuideEventsCategory from '../components/GuideEventsCategory';
import GuideEventsCategoryById from '../components/GuideEventsCategoryById';
import GuideCategoryRegion from '../components/GuideCategoryRegion';
import Select from 'react-select';
import PromotedHero from '../components/PromotedHero';
import DailySpotlight from '../components/DailySpotlight';
import PromotionFeed from '../components/PromotionFeed';
import GuideDemoCategory from '../components/GuideDemoCategory';
import firebase from '../config/firebase';
import TitleBar from '../components/TitleBar';
import moment from 'moment';
// Reducers
import { resetNewEvent } from '../sagas/createEventSaga/reducer';
import { resetCe } from '../sagas/customEventSaga/reducer';
import { clearUauth } from '../sagas/unauthenticatedSaga/reducer';
import { authhydrateApp } from '../sagas/authhydrateSaga/reducer';
import { clearTaRequest } from '../sagas/taRequestSaga/reducer';
// Images
import whiteLogo from '../images/trevel_logo_white.png';
import { startEvent } from '../sagas/activeEventSaga/reducer';

export class AuthHome extends React.Component {
  constructor() {
    super();
    this.state = {
      tagFilters: [],
      usedTags: [],
      moodText: '',
      nearbyRegions: [],
    };
    this.useTag = this.useTag.bind(this);
    this.changeCarousel = this.changeCarousel.bind(this);
    this.setRandomizer = this.setRandomizer.bind(this);
    this.weightMusic = this.weightMusic.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
      // } else if (
      //   this.props.profile !== undefined &&
      //   this.props.profile.tags !== undefined
      // ) {
      //   let love = this.props.profile.tags.love;
      //   let count = love.tagsBeverages.length + love.tagsCuisine.length;
      //   if (love.tagsGenre !== undefined) {
      //     count = count + love.tagsGenre.length;
      //   }
      //   if (love.tagsExperienceType !== undefined) {
      //     count = count + love.tagsExperienceType.length;
      //   }
      //   if (count === 0) {
      //     this.props.history.push('/profile/tag');
      //   }
    } else {
      if (this.props.profile.tags === undefined) {
        this.props.authhydrateApp(this.props.user);
      }
    }
    this.props.clearTaRequest(this.props.taRequest);
    this.props.clearUauth();

    if (
      this.props.newEvent.clear === false ||
      this.props.customEvent.clear === false
    ) {
      let dog = false;
      this.props.resetNewEvent(dog);
      this.props.resetCe();
    }
    this.updateDimensions();
  }

  useTag(tag) {
    let newUsedTags = [];
    if (this.state.usedTags !== undefined && tag !== undefined) {
      let n = this.state.usedTags.includes(tag);
      if (n === false) {
        newUsedTags.push(tag);
      }
      this.setState({
        usedTags: newUsedTags,
      });
    }
  }

  updateDimensions = () => {
    let width = window.innerWidth;
    let count = 2;
    if (width > 1023) {
      count = 4;
    } else if (width < 1023 && width > 767) {
      count = 3;
    }
    this.setState({
      count,
    });
  };

  componentDidMount() {
    if (
      this.props.locations.length === 0 ||
      this.props.profile.tags == undefined
    ) {
      this.props.authhydrateApp(this.props.user);
    }

    window.addEventListener('resize', this.updateDimensions);
    document.title = 'Trevel';
    this.setTags(this.props.tags, this.props.locations);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  weightMusic(
    timedEvents,
    userTagsLove,
    userTagsHate,
    otherUserTagsLove,
    otherUserTagsHate,
    catTitle,
    from,
  ) {
    // if (catTitle === 'Top Entertainment Picks') {
    //   console.log('weightedItems timedEvents', timedEvents);
    // }
    let weightedEvents = [];

    let timedEventsToday = [];
    let eventIds = [];

    // If there is a date selected, filter by dates
    if (
      this.props.customEvent.event.scheduledTime.date !== null &&
      this.props.customEvent.event.scheduledTime.date !== ''
    ) {
      function isSameWeek(firstDay, secondDay, offset) {
        var firstMoment = moment(firstDay);
        var secondMoment = moment(secondDay);

        var startOfWeek = function (_moment, _offset) {
          return _moment.add(
            'days',
            _moment.weekday() * -1 +
              (_moment.weekday() >= 7 + _offset
                ? 7 + _offset
                : _offset),
          );
        };

        return startOfWeek(firstMoment, offset).isSame(
          startOfWeek(secondMoment, offset),
          'day',
        );
      }

      timedEvents.forEach((event) => {
        if (event.scheduledTime !== undefined) {
          if (
            event.scheduledTime.dates.repeat !== undefined &&
            event.scheduledTime.dates.repeat === true
          ) {
            event.scheduledTime.dates.multiDates.forEach((date) => {
              if (
                this.props.customEvent.event.scheduledTime
                  .checkMonth === false &&
                this.props.customEvent.event.scheduledTime
                  .checkWeek === false &&
                this.props.customEvent.event.scheduledTime.date !==
                  '' &&
                this.props.customEvent.event.scheduledTime.date !==
                  null
              ) {
                let today = moment(date.timestamp).isSame(
                  this.props.customEvent.event.scheduledTime.date,
                  'day',
                );
                if (today === true) {
                  let status = eventIds.includes(event.id);
                  if (status === false) {
                    timedEventsToday.push(event);
                    eventIds.push(event.id);
                  }
                }
              } else if (
                this.props.customEvent.event.scheduledTime
                  .checkWeek === true &&
                this.props.customEvent.event.scheduledTime.date !== ''
              ) {
                let sameWeek = isSameWeek(
                  date.timestamp,
                  this.props.customEvent.event.scheduledTime.date,
                  7,
                );
                if (sameWeek === true) {
                  let status = eventIds.includes(event.id);
                  if (status === false) {
                    timedEventsToday.push(event);
                    eventIds.push(event.id);
                  }
                }
              } else if (
                this.props.customEvent.event.scheduledTime
                  .checkMonth === true &&
                this.props.customEvent.event.scheduledTime.date !== ''
              ) {
                let sameMonth = moment(date.timestamp).isSame(
                  this.props.customEvent.event.scheduledTime.date,
                  'month',
                );

                if (sameMonth === true) {
                  let status = eventIds.includes(event.id);
                  if (status === false) {
                    timedEventsToday.push(event);
                    eventIds.push(event.id);
                  }
                }
              }
            });
          } else {
            if (
              this.props.customEvent.event.scheduledTime
                .checkMonth === false &&
              this.props.customEvent.event.scheduledTime.checkWeek ===
                false &&
              this.props.customEvent.event.scheduledTime.date !==
                '' &&
              this.props.customEvent.event.scheduledTime.date !== null
            ) {
              let today = moment(
                event.scheduledTime.dates.startDate,
              ).isSame(
                this.props.customEvent.event.scheduledTime.date,
                'day',
              );
              if (today === true) {
                let status = eventIds.includes(event.id);
                if (status === false) {
                  timedEventsToday.push(event);
                  eventIds.push(event.id);
                }
              }
            } else if (
              this.props.customEvent.event.scheduledTime.checkWeek ===
                true &&
              this.props.customEvent.event.scheduledTime.date !== ''
            ) {
              let sameWeek = isSameWeek(
                event.scheduledTime.dates.startDate,
                this.props.customEvent.event.scheduledTime.date,
                7,
              );
              if (sameWeek === true) {
                let status = eventIds.includes(event.id);
                if (status === false) {
                  timedEventsToday.push(event);
                  eventIds.push(event.id);
                }
              }
            } else if (
              this.props.customEvent.event.scheduledTime
                .checkMonth === true &&
              this.props.customEvent.event.scheduledTime.date !== ''
            ) {
              let sameMonth = moment(
                event.scheduledTime.dates.startDate,
              ).isSame(
                this.props.customEvent.event.scheduledTime.date,
                'month',
              );

              if (sameMonth === true) {
                let status = eventIds.includes(event.id);
                if (status === false) {
                  timedEventsToday.push(event);
                  eventIds.push(event.id);
                }
              }
            }
          }
        }
      });
    } else {
      timedEventsToday = timedEvents;
    }

    timedEventsToday.forEach((event) => {
      let today = moment();

      let eventAfterStatus = moment(
        event.scheduledTime.dates.endDate,
      ).isAfter(today, 'day');

      let eventTodayStatus = moment(
        event.scheduledTime.dates.startDate,
      ).isSame(today, 'day');

      if (eventTodayStatus === true || eventAfterStatus === true) {
        let weightedEvent = event;
        let weight = 0;
        weightedEvent.matchesOtherUserLove = [];
        weightedEvent.matchesOtherUserHate = [];
        weightedEvent.matchesUserLove = [];
        weightedEvent.matchesUserHate = [];
        weightedEvent.weight = weight;

        // Other User Love
        if (otherUserTagsLove !== undefined) {
          if (event.tagsGenre !== undefined) {
            event.tagsGenre.forEach((userTag) => {
              if (otherUserTagsLove.tagsGenre !== undefined) {
                otherUserTagsLove.tagsGenre.forEach((tag) => {
                  if (userTag === tag) {
                    weightedEvent.matchesOtherUserLove.push(tag);
                    weight = weight + 10;
                  }
                });
              }
            });
          }
        }
        // Other User Hate
        if (otherUserTagsHate !== undefined) {
          if (event.tagsGenre !== undefined) {
            event.tagsGenre.forEach((userTag) => {
              if (otherUserTagsHate.tagsGenre !== undefined) {
                otherUserTagsHate.tagsGenre.forEach((tag) => {
                  if (userTag === tag) {
                    weightedEvent.matchesOtherUserHate.push(tag);
                    weight = weight - 25;
                  }
                });
              }
            });
          }
        }

        let selectedTags = [];
        this.props.taRequest.tagFilters.forEach((tagCat) => {
          tagCat.tags.forEach((tag) => {
            if (tag.checked === true) {
              selectedTags.push(tag.tag);
            }
          });
        });
        // User Love
        if (event.tagsGenre !== undefined) {
          event.tagsGenre.forEach((userTag) => {
            if (selectedTags.includes(userTag) === true) {
              weight = weight + 20;
            }
            if (userTagsLove.tagsGenre !== undefined) {
              userTagsLove.tagsGenre.forEach((tag) => {
                if (userTag === tag) {
                  weightedEvent.matchesUserLove.push(tag);
                  weight = weight + 5;
                }
              });
            }
          });
        }
        // User Hate
        if (event.tagsGenre !== undefined) {
          event.tagsGenre.forEach((userTag) => {
            if (userTagsHate.tagsGenre !== undefined) {
              userTagsHate.tagsGenre.forEach((tag) => {
                if (userTag === tag) {
                  weightedEvent.matchesUserHate.push(tag);
                  weight = weight - 25;
                }
              });
            }
          });
        }

        weightedEvent.weight = weight;
        weightedEvents.push(weightedEvent);
      }
    });

    if (catTitle === 'Top Entertainment Picks') {
      let bucketOne = [];
      let bucketTwo = [];
      let bucketThree = [];
      let bucketFour = [];
      let bucketFive = [];

      weightedEvents.forEach((event) => {
        if (event.bucket === 1) {
          bucketOne.push(event);
        } else if (event.bucket === 2) {
          bucketTwo.push(event);
        }
        if (event.bucket === 3) {
          bucketThree.push(event);
        }
        if (event.bucket === 4) {
          bucketFour.push(event);
        }
        if (event.bucket === 5) {
          bucketFive.push(event);
        }
      });

      let orderedBucketOne = _.orderBy(
        bucketOne,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketTwo = _.orderBy(
        bucketTwo,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketThree = _.orderBy(
        bucketThree,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketFour = _.orderBy(
        bucketFour,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketFive = _.orderBy(
        bucketFive,
        ['weight'],
        ['desc', 'asc'],
      );

      let topPicks = [];

      orderedBucketOne.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketTwo.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketThree.forEach((location) => {
        topPicks.push(location);
      });

      orderedBucketFour.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketFive.forEach((location) => {
        topPicks.push(location);
      });

      orderedBucketOne.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketTwo.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketThree.forEach((location) => {
        topPicks.push(location);
      });

      orderedBucketFour.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketFive.forEach((location) => {
        topPicks.push(location);
      });

      return topPicks;
    } else {
      return _.orderBy(weightedEvents, ['weight'], ['desc', 'asc']);
    }
  }

  getTotalScore() {
    let awardCount = this.props.awards.length;
    let eventsArray = [];
    if (this.props.events !== undefined) {
      this.props.events.forEach((event) => {
        if (
          event.creationDetails.plannerId ===
          this.props.profile.userId
        ) {
          eventsArray.push(event);
        }
      });
    }
    let eventCount = eventsArray.length;
    let total = eventCount + awardCount;
    return total;
  }

  getConnectionLocations(connections) {
    let readyConnections = {};
    if (connections.connections !== undefined) {
      readyConnections = connections.connections;
    }
    let connectionsArray = [];
    let connectionsCount = Object.keys(readyConnections).length;

    let loveTags = {};
    if (
      this.props.profile.tags !== undefined &&
      this.props.profile.tags.love !== undefined
    ) {
      loveTags = this.props.profile.tags.love;
    }

    if (connectionsCount > 0) {
      return (
        <DailyContactLocations
          locations={this.props.locations}
          userLove={loveTags}
          count={this.state.count}
        />
      );
    }
  }

  getActiveAwards() {
    let awardsArray = [];
    if (
      this.props.awards !== undefined &&
      this.props.awards !== null &&
      this.props.awards.status !== 0
    ) {
      this.props.awards.forEach((award) => {
        if (award.active === true) {
          awardsArray.push(award);
        }
      });
    }

    let count = awardsArray.length;
    return count;
  }

  setTags(tags, locations) {
    let filteredTags = this.filterTags(tags, locations);
    let updatedTags = filteredTags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = 1;
        if (this.props.user.authenticated === true) {
          // status = this.getTagStatus(tag.tag);
        }
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let drinks = {
    //   checked: false,
    //   parentindex: 6,
    //   status: 1,
    //   tag: 'Drinks',
    //   tagindex: updatedTags[6].tags.length,
    // };

    let food = {
      checked: false,
      parentindex: 1,
      status: 1,
      tag: 'Food',
      tagindex: updatedTags[1].tags.length,
    };

    // updatedTags[6].tags.push(drinks);
    updatedTags[1].tags.push(food);
    updatedTags.forEach((tagcat, x) => {
      updatedTags[x].tags = updatedTags[x].tags.sort((a, b) =>
        a.tag.localeCompare(b.tag),
      );
      updatedTags[x].tags = updatedTags[x].tags.reverse();
      updatedTags[x].tags.map((tag, y) => {
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let sortedTags = []
    // updatedTags.forEach((tagcat, x) => {
    //   sortedTags = _.sortBy(updatedTags[x].tags, o => o.status);
    // }

    let origionalTags = [];
    updatedTags.forEach((tagcat) => {
      origionalTags.push(tagcat);
    });

    this.setState({
      tagFilters: updatedTags,
      origionalTags,
    });
    this.setTagFilters(updatedTags);
  }

  filterTags(tagFilters, locationsx) {
    let locations = [];
    if (locationsx !== undefined) {
      locations = locationsx;
    }
    let updatedTagFilters = [
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
      {
        id: '5vhCJSC7VgBE1MFNctgY',
        parent: 'Location Type',
        sid: 'locationType',
        tags: [],
      },
      {
        id: '7ayttBEiZgiM5TZ2iYsM',
        parent: 'Cuisine Type',
        sid: 'cuisineType',
        tags: [],
      },
      {
        id: '7kJeYaz9m5q434zO28Pg',
        parent: 'Setting Type',
        sid: 'setting',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Experience Type',
        sid: 'experienceType',
        tags: [],
      },
      {
        id: 'BW3qPhXbN7C4XMXWh4tv',
        parent: 'Date Type',
        sid: 'dateType',
        tags: [],
      },
      {
        id: 'CSKtI08vSBtyqKr10Nef',
        parent: ' Dietary Must Haves',
        sid: 'dietary',
        tags: [],
      },
      {
        id: 'F7R2nsOyZTiqKbW6AhFQ',
        parent: 'Hours',
        sid: 'hours',
        tags: [],
      },
      {
        id: 'xeytdS8Xwjlou67MVbVY',
        parent: 'Beverages',
        sid: 'beverages',
        tags: [],
      },
      {
        id: 'rxsnb1c2XX8c4MpugeEP',
        parent: 'Genre',
        sid: 'genre',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Entertainment Type',
        sid: 'entertainmentType',
        tags: [],
      },
      {
        id: 'eAVFUFVivBObckEaKiRR',
        parent: 'Event Type',
        sid: 'eventType',
        tags: [],
      },
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
    ];
    let allTags = [];

    locations.forEach((location) => {
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      // if (location.tagsSettings !== undefined) {
      //   location.tagsSettings.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsEventType !== undefined) {
        location.tagsEventType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsMain !== undefined) {
        let status = allTags.includes(location.tagsMain);
        if (status === false) {
          allTags.push(location.tagsMain);
        }
      }
    });
    if (tagFilters != undefined) {
      tagFilters.forEach((tagx, indexA) => {
        if (tagx.tags !== undefined) {
          tagx.tags.forEach((tag, indexB) => {
            let status = allTags.includes(tag.tag);
            if (status === true) {
              updatedTagFilters[indexA].tags.push(tag);
            }
          });
        }
      });
    }

    return updatedTagFilters;

    // this.setState({
    //   tagFilters: updatedTagFilters,
    // });
    // this.setTagFilters(updatedTagFilters);
  }

  clearTagFilters(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    this.setState({
      tagFilters: updatedTags,
    });
    window.scrollTo(0, 0);
    this.searchUpdated('');
  }

  getSelectOptions() {
    let locations = [];
    if (
      this.props.locations !== undefined &&
      Array.isArray(this.props.locations) === true
    ) {
      locations = this.props.locations;
    }

    // if (
    //   this.state.locations !== undefined &&
    //   this.state.locations.length !== 0
    // ) {
    //   locations = this.state.locations;
    // } else if (
    //   this.props.uauth.guide !== undefined &&
    //   this.props.locations !== undefined &&
    //   this.props.locations.length !== 0
    // ) {
    //   locations = this.props.locations;
    // }

    let allTags = [];
    let selectOptions = [];
    locations.forEach((location) => {
      selectOptions.push({
        value: location.locationId,
        label: location.locationTitle,
      });

      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsMain !== undefined) {
        let status = allTags.includes(location.tagsMain);
        if (status === false) {
          allTags.push(location.tagsMain);
        }
      }
    });

    this.state.tagFilters.forEach((tagx, indexA) => {
      tagx.tags.forEach((tag, indexB) => {
        let status = allTags.includes(tag.tag);
        if (status === true) {
          selectOptions.push({
            value: tag,
            label: tag.tag,
          });
        }
      });
    });

    this.props.entertainment.forEach((entertainer) => {
      selectOptions.push({
        value: entertainer.slug + '-xxxx',
        label: entertainer.entertainmentTitle,
      });
    });

    let reversedOptions = selectOptions.reverse();
    // this.setTagFilters(updatedTagFilters);
    return reversedOptions;
  }

  setTagFilters = (tagFilters) => {
    this.setState({ tagFilters });
  };

  // criteria Handlers
  selectChange = (values) => {
    if (values.value.length > 15) {
      if (values.value.slice(-5) === '-xxxx') {
        const editedText = values.value.slice(0, -5); //'abcde'
        this.props.history.push('/entertainment/' + editedText);
      } else {
        this.props.history.push('/location/' + values.value);

        this.props.history.push({
          pathname: '/location/' + values.value,
          state: {},
        });
      }
    } else {
      this.props.history.push('/guide/category/' + values.label);
    }
    // let updatedTags = this.state.tagFilters;
    // updatedTags[values.value.parentindex].tags[
    //   values.value.tagindex
    // ].checked = true;
    // this.setState({
    //   tagFilters: updatedTags,
    // });
    // this.setTagFilters(updatedTags);
    // this.searchUpdated(this.state.searchTerm);
    // this.clearLanding(false);
    // this.updateUauth(updatedTags);
    // this.props.history.push('/guide/category/' + values.label);
  };

  moodTextChange = (text) => {
    if (text.length > 0) {
      this.setState({
        moodText: text,
      });
    }
  };

  getFavoriteLinks(tag) {
    return (
      <Link
        to={{
          pathname: '/guide/category/' + tag,
        }}
        // className="white"
      >
        <li>
          <span>{tag}</span>
        </li>
      </Link>
    );
  }

  getEntertainmentFavoriteLinks(tag) {
    return (
      <Link
        to={{
          pathname: '/guide/category/' + tag,
        }}
        className="white"
      >
        <li>
          <span>{tag}</span>
        </li>
      </Link>
    );
  }

  gotoSearch(text) {
    if (text.length > 0) {
      this.props.history.push('/guide/search/' + text);
    } else {
      this.props.history.push('/guide/search/');
    }
  }

  getCategory(category, activeZoneId) {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 't' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];
    let currentDate = new Date();
    let currentDay = dayArray[currentDate.getDay()].short;
    let checkit = [];
    category.filters.zones.forEach((zoneId) => {
      if (zoneId !== '') {
        checkit.push(parseInt(zoneId));
      } else {
        checkit.push(zoneId);
      }
    });
    // If time options set, check
    let show = true;
    if (
      category.time !== undefined &&
      category.time.activeDays !== undefined &&
      category.time.activeDays.length !== 0
    ) {
      let status = category.time.activeDays.includes(currentDay);
      if (status === false) {
        show = false;
      }
    }
    if (
      category.time !== undefined &&
      category.time.toTime !== undefined
    ) {
      let currentTime = moment().format('HH:mm');
      var format = 'hh:mm:ss';
      var time = moment(currentTime, format),
        beforeTime = moment(category.time.fromTime, format),
        afterTime = moment(category.time.toTime, format);
      if (time.isBetween(beforeTime, afterTime) === false) {
        show = false;
      }
    }

    let type = 'Locations';

    let includeLocations = false;
    if (category.types !== undefined) {
      includeLocations = category.types.includes('Location');
    } else if (category.type === 'Locations') {
      includeLocations = true;
    }

    let includeEvents = false;
    if (category.types !== undefined) {
      includeEvents = category.types.includes('Events');
      if (includeEvents === true) {
        type = 'Events';
      }
    } else if (category.type === 'Events') {
      includeEvents = true;
    }

    let includeEntertainers = false;
    if (category.types !== undefined) {
      includeEntertainers = category.types.includes('Entertainers');
    } else if (category.type === 'Entertainers') {
      includeEntertainers = true;
    }
    if (
      (checkit.includes(activeZoneId) === true && show === true) ||
      (checkit[0] === '' && show === true)
    ) {
      return (
        <GuideCategory
          category={category}
          weightMusic={this.weightMusic}
          type={type}
          locations={this.props.locations}
          timedEvents={this.props.timedEvents}
          count={this.state.count}
          catTitle={category.title}
          carouselPosition={
            this.state[category.categoryId + 'carouselPosition'] !==
            undefined
              ? this.state[category.categoryId + 'carouselPosition']
              : 0
          }
          changeCarousel={this.changeCarousel}
          setRandomizer={this.setRandomizer}
          randomizer={
            this.state[category.categoryId + 'randomizer'] !==
            undefined
              ? this.state[category.categoryId + 'randomizer']
              : null
          }
        />
      );
    }
  }

  changeCarousel = (index, id) => {
    this.setState({ [id + 'carouselPosition']: index });
  };

  setRandomizer = (value, id) => {
    this.setState({ [id + 'randomizer']: value });
  };

  handleKeyDown(e) {
    /* React Select hack for pages where tags are inside a form element:
      Default CreatableSelect functionality is that Enter key creates
      the new tag. So we let that happen, but if they hit Enter again,
      we kill it so the form doesn't submit. Yes, it's hack-y.
    */
    // switch (e.key) {
    //   case 'Enter':
    //     if (lastKey === 'Enter') {
    //       e.preventDefault();
    //       e.stopPropagation();
    //     }
    //     setLastKey('Enter');
    //     break;
    //   default:
    //     setLastKey('');
    // }
  }

  keyDownHandler = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  render() {
    // const title =
    //   "What would you like to do " + this.props.profile.firstName + "?";
    // const title = "What would you like to do?";
    const title = "Love where you're going";

    if (this.props.locations.length === 0) {
      return (
        <div
          style={{
            height: '100%',
            display: 'table',
            width: '100%',
            background:
              'linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%)',
          }}
        >
          <div
            style={{
              position: 'relative',
              textAlign: 'center',
              display: 'table-cell',
              verticalAlign: 'middle',
              top: '-100px',
            }}
          >
            <h2
              style={{
                margin: '0px 0px 10px 0px',
                color: 'white',
                fontSize: '40px',
                textShadow: '#bb4a65 1px 4px',
              }}
            >
              <img
                style={{
                  width: '90%',
                  maxWidth: '500px',
                  margin: '0px auto',
                }}
                src={whitelogo}
                className="mobileHide"
              />
              <img
                style={{
                  width: '80%',
                  maxWidth: '500px',
                  margin: '0px auto',
                }}
                src={whitelogo}
                className="mobileShow"
              />
            </h2>
            <span
              style={{
                fontSize: '35px',
                lineHeight: '40px',
                color: 'white',
                textShadow: '#bb4a65 1px 4px',
                marginLeft: '30px',
                paddingTop: '25px',
              }}
              className="mobileHide"
            >
              Creating a Guide
              <br />
              just for you
            </span>
            <div
              style={{
                width: '15px, margin: 0px auto',
                position: 'absolute',
                bottom: '-20px',
                left: '50%',
              }}
            >
              <div className="dot-bricks mobileHide" />
            </div>
            <span
              style={{
                fontSize: '30px',
                lineHeight: '40px',
                color: 'white',
                textShadow: '#bb4a65 1px 4px',
                paddingTop: '15px',
              }}
              className="mobileShow"
            >
              Creating a Guide
              <br />
              just for you
            </span>
            <div
              style={{
                width: '15px, margin: 0px auto',
                position: 'absolute',
                bottom: '-20px',
                left: '50%',
              }}
            >
              <div className="dot-bricks mobileShow" />
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'body {height: 100% }.toolbar{display: none}.section{max-width: 1000px} .container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }

    let activeZoneId = 10;
    if (
      this.props.locations[0] &&
      undefined &&
      this.props.locations[0].zoneId !== undefined
    ) {
      activeZoneId = this.props.locations[0].zoneId;
    }
    let selectOptions = this.getSelectOptions();

    let loveTags = {};
    if (
      this.props.profile.tags !== undefined &&
      this.props.profile.tags.love !== undefined
    ) {
      loveTags = this.props.profile.tags.love;
    }

    return (
      <div>
        {/* <TitleBar title={title} backlink="" /> */}
        <div id="auth-home">
          {/* <div className="phone-only"> */}
          {/* <div>
            <Container>
              <Row>
                <Col
                  xs={6}
                  md={6}
                  sm={6}
                  lg={6}
                  className="no-padding text-center"
                >
                  <Link
                    to={{
                      pathname: "/events/plan/1",
                    }}
                    className="homeButton right"
                  >
                    <MaterialIcon icon='add' size={30} color='white' />
                    <img src={whiteLogo} alt="logo" className="logo-btn" />
                    <span className="homeButton-text">Create New Social</span>
                  </Link>
                </Col>
                <Col
                  xs={6}
                  md={6}
                  sm={6}
                  lg={6}
                  className="no-padding text-center"
                >
                  <Link
                    to={{
                      pathname: "/events/upcoming",
                    }}
                    className="homeButton left"
                  >
                    <MaterialIcon
                      icon="insert_invitation"
                      size={30}
                      color="white"
                    />
                    <span className="homeButton-text">Social Calendar</span>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={6}
                  md={6}
                  sm={6}
                  lg={6}
                  className="no-padding text-center"
                >
                  <Link
                    to={{
                      pathname: "/activity",
                    }}
                    className="homeButton right"
                  >
                    <MaterialIcon
                      icon="notifications"
                      size={30}
                      color="white"
                    />
                    <span className="homeButton-text">Recent Activity</span>
                  </Link>
                </Col>
                <Col
                  xs={6}
                  md={6}
                  sm={6}
                  lg={6}
                  className="no-padding text-center"
                >
                  <Link
                    to={{
                      pathname: "/places",
                    }}
                    className="homeButton left"
                  >
                    <MaterialIcon icon="stars" size={30} color="white" />
                    <span className="homeButton-text">Favorite Places</span>
                  </Link>
                </Col>
              </Row>
            </Container>
          </div> */}
          {/* <div id="statsbar">
            <Row>
              <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
                <Link
                  to={{
                    pathname: '/stats',
                  }}
                >
                  <Row className="statBarLeft">
                    <Col
                      xs={10}
                      md={10}
                      sm={10}
                      lg={10}
                      className="no-padding"
                    >
                      <span className="left statBarTitle">
                        <img
                          src={whiteLogo}
                          alt="logo"
                          className="pointsIcon"
                        />
                        POINTS
                      </span>
                    </Col>

                    <Col
                      xs={2}
                      md={2}
                      sm={2}
                      lg={2}
                      className="no-padding"
                    >
                      <span className="right numberCircle">
                        <b>{this.getTotalScore()}</b>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </Col>
              <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
                <Link
                  to={{
                    pathname: '/rewards',
                  }}
                >
                  <Row className="statBarRight">
                    <Col
                      xs={2}
                      md={2}
                      sm={2}
                      lg={2}
                      className="no-padding"
                    >
                      <span className="left numberCircle">
                        <b>{this.getActiveAwards()}</b>
                      </span>
                    </Col>
                    <Col
                      xs={10}
                      md={10}
                      sm={10}
                      lg={10}
                      className="no-padding"
                    >
                      <span className="right statBarTitle">
                        REWARDS
                      </span>
                    </Col>
                  </Row>
                </Link>
              </Col>
            </Row>
          </div> */}

          {/* <div
            className="sectiontitle  text-center homebar"
            style={{ background: '#f21d59', color: 'white' }} */}
          {/* > */}
          {/* <div
            className="sectiontitle  text-center"
            style={{ background: 'rgb(238 36 91)', color: 'white' }}
          >
            <span className="primary">Daily Trevel Guide</span>
          </div> */}

          <div className="homeMoodBar">
            <Row style={{ margin: '0px 0px 0px 0px' }}>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                // className="no-padding"
                style={{ padding: '5px 5px 5px 5px' }}
              >
                <div
                  style={{
                    width: '350px',
                    margin: '0px auto',
                  }}
                >
                  <span
                    className="left"
                    style={{
                      width: '300px',
                    }}
                  >
                    <Select
                      value={this.state.selectInput}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) => this.selectChange(e, 0, 'one')}
                      options={selectOptions}
                      onInputChange={(e) =>
                        this.moodTextChange(e, 0, 'one')
                      }
                      isMulti={false}
                      placeholder={'What are you in the mood for?'}
                    />
                  </span>

                  <a
                    onClick={() => {
                      this.gotoSearch(this.state.moodText);
                    }}
                  >
                    <span
                      className="right squareSearch"
                      style={{
                        padding:
                          this.state.count > 2
                            ? '4px 4px 0px'
                            : '4px 4px 0px',
                      }}
                    >
                      <MaterialIcon
                        icon="search"
                        size={35}
                        color="#f21d59"
                      />
                    </span>
                  </a>
                </div>
              </Col>
            </Row>
            <Row
              style={{ background: 'whitesmoke', marginTop: '5px' }}
            >
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                // className="no-padding"
                style={{ padding: '5px 5px 5px 5px' }}
              >
                {/* <SearchInput
                  className="search-input guide-search"
                  onChange={this.searchUpdated}
                  placeholder="All Locations"
                  value={searchTerm}
                />
                <a
                  onClick={() => {
                    this.clearLanding(false);
                  }}
                  className="pointer"
                >
                  <MaterialIcon
                    icon="search"
                    size={45}
                    color="#ffffff"
                  />
                </a> */}
                <div
                  style={{
                    textAlign: 'center',
                  }}
                  id="menu"
                >
                  <ul>
                    {/* <Link
                      to={{
                        pathname: '/guide/category/Music',
                      }}
                      className="white"
                    >
                      <li>
                        <span>Music</span>
                      </li>
                    </Link> */}
                    {/* <Link
                      to={{
                        pathname: '/guide/category/wine',
                      }}
                      className="white"
                    >
                      <li>
                        <span>Wine</span>
                      </li>
                    </Link> */}

                    <Link
                      to={{
                        pathname: '/guide/category/food',
                      }}
                      // className="white"
                    >
                      <li>
                        <span>Food</span>
                      </li>
                    </Link>
                    <Link
                      to={{
                        pathname: '/guide/category/Music',
                      }}
                      // className="white"
                    >
                      <li>
                        <span>Music</span>
                      </li>
                    </Link>
                    <Link
                      to={{
                        pathname: '/guide/category/Happy-Hour',
                      }}
                      // className="white"
                    >
                      <li>
                        <span>Happy Hour</span>
                      </li>
                    </Link>
                    <Link
                      to={{
                        pathname: '/guide/category/intimate',
                      }}
                      // className="white"
                    >
                      <li>
                        <span style={{ wordBreak: 'break-all' }}>
                          Date<i className="red">-</i>Night
                        </span>
                      </li>
                    </Link>

                    {/* <Link
                      to={{
                        pathname: '/guide/category/cocktails',
                      }}
                      className="white"
                    >
                      <li>
                        <span>Cocktails</span>
                      </li>
                    </Link> */}

                    {/* <Link
                      to={{
                        pathname: '/guide/category/coffee',
                      }}
                      className="white"
                    >
                      <li>
                        <span>Coffee</span>
                      </li>
                    </Link> */}

                    <Link
                      to={{
                        pathname: '/guide/category/entertainment',
                      }}
                      // className="white"
                    >
                      <li>
                        <span>Entertainment</span>
                      </li>
                    </Link>
                  </ul>
                </div>
              </Col>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                style={{ padding: '5px 5px 5px 5px' }}
              >
                <div
                  style={{
                    textAlign: 'center',
                  }}
                  id="menu"
                >
                  {this.props.profile !== undefined &&
                  this.props.profile.tags !== undefined &&
                  this.props.profile.tags.love.tagsBeverages
                    .length === 0 &&
                  this.props.profile.tags.love.tagsCuisine.length ===
                    0 &&
                  this.props.profile.tags.love.tagsGenre !==
                    undefined &&
                  this.props.profile.tags.love.tagsGenre.length ===
                    0 &&
                  this.props.profile.tags.love.tagsExperienceType
                    .length === 0 ? (
                    <div>
                      <Link
                        to={{
                          pathname: '/profile/tag',
                          state: {},
                        }}
                      >
                        <span>
                          Don{"'"}t forget to add your preferences.
                        </span>
                      </Link>
                    </div>
                  ) : (
                    <ul>
                      {this.props.profile !== undefined &&
                        this.props.profile.tags !== undefined &&
                        this.props.profile.tags.love.tagsBeverages
                          .length === 0 &&
                        this.props.profile.tags.love.tagsBeverages.map(
                          (tag, index) => this.getFavoriteLinks(tag),
                        )}
                      {this.props.profile !== undefined &&
                        this.props.profile.tags !== undefined &&
                        this.props.profile.tags.love.tagsCuisine
                          .length === 0 &&
                        this.props.profile.tags.love.tagsCuisine.map(
                          (tag, index) => this.getFavoriteLinks(tag),
                        )}
                    </ul>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div id="homeSections">
            <div>
              {this.props.packages !== undefined && (
                <DailySpotlight />
              )}
            </div>
            {/* <div id="homeSections" className="contentbox"> */}
            {/* <div className="contentbox"> */}
            {/* <PromotedHero /> */}

            {/* <SignUpBanner /> */}
            <div>
              {/* <MainHomePackages /> */}
              {/* <PromotedArticleHero /> */}
              {this.props.promos !== undefined && <PromotionFeed />}
              {loveTags.tagsBeverages !== undefined && (
                <DailyLocations
                  locations={this.props.locations}
                  userLove={loveTags}
                  count={this.state.count}
                  usedTags={this.state.usedTags}
                  useTag={this.useTag}
                />
              )}

              {/* Connections Favorites */}

              {this.props.connections != undefined &&
                this.getConnectionLocations(this.props.connections)}

              {/* <GuideDemoCategory
                category={''}
                tagCat={''}
                timedEvents={this.props.demo}
                count={this.state.count}
                catTitle={'Trevel Experiences - Join The Fun'}
              /> */}

              {/* Favorite Entertainers */}
              {this.props.profile.favoriteEntertainment !==
                undefined &&
                this.props.profile.favoriteEntertainment.length !==
                  0 && (
                  <div>
                    {this.props.profile.favoriteEntertainment.map(
                      (entertainerId, index) => (
                        <div>
                          <GuideEventsCategoryById
                            timedEvents={this.props.timedEvents}
                            count={this.state.count}
                            id={entertainerId}
                          />
                        </div>
                      ),
                    )}
                  </div>
                )}

              {this.props.guideTemplates !== undefined &&
                this.props.guideTemplates.length !== 0 &&
                this.props.guideTemplates[1].categories !==
                  undefined && (
                  <div style={{ paddingTop: '20px' }}>
                    {this.props.guideTemplates[1].categories.sectionOne.map(
                      (category, index) => (
                        <div>
                          {category.visibility === true && (
                            <div>
                              {this.getCategory(
                                category,
                                activeZoneId,
                              )}
                            </div>
                          )}
                        </div>
                      ),
                    )}
                  </div>
                )}

              {this.props.guideTemplates !== undefined &&
                this.props.guideTemplates.length !== 0 &&
                this.props.guideTemplates[1].categories !==
                  undefined && (
                  <div style={{ paddingTop: '20px' }}>
                    {this.props.guideTemplates[1].categories.sectionTwo.map(
                      (category, index) => (
                        <div>
                          {category.visibility === true && (
                            <div>
                              {this.getCategory(
                                category,
                                activeZoneId,
                              )}
                            </div>
                          )}
                        </div>
                      ),
                    )}
                  </div>
                )}

              {this.props.guideTemplates === undefined ||
                (this.props.guideTemplates.length === 0 &&
                  this.props.guideTemplates[1].categories !==
                    undefined && (
                    <div style={{ paddingTop: '10px' }}>
                      <GuideEventsCategory
                        category={'Music'}
                        tagCat={'tagsGenre'}
                        timedEvents={this.props.timedEvents}
                        count={this.state.count}
                        catTitle={'Let There Be Music'}
                      />

                      {this.props.profile.zoneId === 11 && (
                        <GuideCategoryRegion
                          category={'Italian'}
                          tagCat={'tagsCuisine'}
                          zips={'63110'}
                          locations={this.props.locations}
                          count={this.state.count}
                          catTitle={
                            'The Hill - Authentic Italian Cuisine'
                          }
                        />
                      )}
                      {this.props.profile.zoneId === 11 && (
                        <GuideCategoryRegion
                          category={''}
                          tagCat={'all'}
                          zips={'63108'}
                          locations={this.props.locations}
                          count={this.state.count}
                          catTitle={'Explore The Central West End'}
                        />
                      )}
                      {this.props.profile.zoneId === 11 && (
                        <GuideCategoryRegion
                          category={''}
                          tagCat={'all'}
                          cities={'Clayton'}
                          locations={this.props.locations}
                          count={this.state.count}
                          catTitle={"Clayton Isn't Just for Work"}
                        />
                      )}
                      {this.props.profile.zoneId === 10 && (
                        <GuideCategoryRegion
                          category={''}
                          tagCat={'all'}
                          cities={'Overland Park'}
                          locations={this.props.locations}
                          count={this.state.count}
                          catTitle={'Explore Overland Park'}
                        />
                      )}
                    </div>
                  ))}

              <DailyWeather
                locations={this.props.locations}
                userLove={loveTags}
                count={this.state.count}
              />
              {this.props.guideTemplates !== undefined &&
                this.props.guideTemplates.length !== 0 &&
                this.props.guideTemplates[1].categories !==
                  undefined && (
                  <div style={{ paddingTop: '20px' }}>
                    {this.props.guideTemplates[1].categories.sectionThree.map(
                      (category, index) => (
                        <div>
                          {category.visibility === true && (
                            <div>
                              {this.getCategory(
                                category,
                                activeZoneId,
                              )}
                            </div>
                          )}
                        </div>
                      ),
                    )}
                  </div>
                )}
              <div id="promotedArticles">
                {this.props.articles !== undefined && (
                  <PromotedArticles
                    articles={this.props.articles}
                    count={this.state.count}
                  />
                )}
              </div>
              {/* <Link to={`/packages`}>Packages</Link> */}
            </div>
          </div>

          <br />
          {this.props.profile.userType === 1 ||
          this.props.profile.userType === 3 ||
          this.props.profile.userType === 4 ? (
            <div>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.toolbar {padding-top: 0px !important}.nav-signup { display: block } .nav-signup { display: block }  .maincontent { top: 98px; margin-top: 0px; height: 100%} #vendorTopLink{display: block} .toolbar__navigation{max-width:none}.container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important; display: block} #authleft {display:none;} #mobile-nav { display: block !important }  #bottomNav span{color: white !important}#bottomNav .material-icons{color: white !important} #bottomNav{     padding-top:15px; height: 80px; background: linear-gradient(180deg, rgba(242,29,89,1) 0%, rgb(162 8 51) 78%))  !important; border: 0px; background: rgb(242,29,89); width:100% !important; left: 0} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
                }}
              />
            </div>
          ) : (
            <div>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.nav-signup { display: block } .maincontent{top: 50px !important; margin-top:0px}',
                }}
              />
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '#vendorTopLink{display: block} .toolbar__navigation{max-width:none}.container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important; display: block} #authleft {display:none;} #mobile-nav { display: block !important }  .maincontent { top: 0px; margin-top: 0px; height: 100%} #bottomNav span{color: white !important}#bottomNav .material-icons{color: white !important} #bottomNav{     padding-top:15px; height: 80px; background: linear-gradient(180deg, rgba(242,29,89,1) 0%, rgb(162 8 51) 78%))  !important; border: 0px; background: rgb(242,29,89); width:100% !important; left: 0} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    clearUauth: () => {
      dispatch(clearUauth());
    },
    resetNewEvent: (newEvent) => {
      dispatch(resetNewEvent(newEvent));
    },
    authhydrateApp: (user) => {
      dispatch(authhydrateApp(user));
    },
    clearTaRequest: (user) => {
      dispatch(clearTaRequest(user));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    articles: state.articles.articles,
    profile: state.profile.profile,
    events: state.events.events,
    awards: state.userAwards.awards,
    newEvent: state.newEvent,
    packages: state.packages.packages,
    promos: state.promos.promos,
    customEvent: state.customEvent.customEvent,
    tags: state.tags.tags,
    locations: state.locations.locations,
    connections: state.connections.connections,
    timedEvents: state.timedEvents.events,
    guideTemplates: state.guideTemplates.templates,
    vendor: state.vendor.vendor,
    entertainment: state.entertainment.entertainment,
    demo: state.demo,
    taRequest: state.taRequest,
    regions: state.regions.regions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthHome);
