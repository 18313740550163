import { call, put, takeLatest } from 'redux-saga/effects';
import { getGuideTemplates } from '../../services/GuideTemplateServices';
import * as actions from './actions';

let defaultState = {
  events: {},
};

function* getGuideTemplatesAction(action) {
  let templates = yield call(getGuideTemplates);
  yield put({
    type: actions.SET_GUIDETEMPLATES,
    payload: {
      ...defaultState,
      templates,
    },
  });
}

export default function* sagas() {
  yield takeLatest(
    actions.GET_GUIDETEMPLATES,
    getGuideTemplatesAction,
  );
}
