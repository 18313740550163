import React from 'react';
import MaterialIcon from 'material-icons-react';
import { connect } from 'react-redux';
// Components
import SignUpBanner from '../components/SignUpBanner';
import { Col, Row, Container } from 'reactstrap';
import Features from '../components/Features';
import RedCallToAction from '../components/RedCallToAction';
import { clearUauth } from '../sagas/unauthenticatedSaga/reducer';
import { Link } from 'react-router-dom';
// Reducers
import { getArticles } from '../sagas/articleSaga/reducer';

export class Community extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      city: '',
    };
  }
  UNSAFE_componentWillMount() {
    document.title = 'Trevel Community';
  }
  componentDidMount() {
    let zoneId = null;
    if (
      this.props.uauth.guide.locationsWithDistance !== undefined &&
      this.props.uauth.guide.locationsWithDistance[1] !== undefined
    ) {
      zoneId = this.props.uauth.guide.locationsWithDistance[1].zoneId;
    }
    let city = 'St. Louis and Kansas City';
    if (this.props.match.params.city !== undefined) {
      if (this.props.match.params.city === 'stl') {
        city = 'St. Louis';
      }
      if (this.props.match.params.city === 'kc') {
        city = 'Kansas City';
      }
    }

    if (zoneId !== null) {
      if (zoneId === 11) {
        city = 'St. Louis';
      }
      if (zoneId === 10) {
        city = 'Kansas City';
      }
    }
    this.setState({
      city,
      loading: false,
    });
  }

  getBackButton() {
    let returnPath = '/';
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.returnPath !== undefined
    ) {
      returnPath = this.props.location.state.returnPath;
    }

    if (this.state.showHelp === true) {
      return (
        <span className="left">
          <MaterialIcon
            icon="keyboard_backspace"
            size={25}
            color="white"
            onClick={() => {
              this.hideHelp();
            }}
          />
        </span>
      );
    } else {
      return (
        <Link
          to={{
            pathname: returnPath,
          }}
          className="left"
        >
          <MaterialIcon
            icon="keyboard_backspace"
            size={25}
            color="white"
          />
        </Link>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          {/* <Row className="center" style={{ maxWidth: '1200px' }}> */}
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {this.getBackButton()}
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Trevel Community</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <a
                onClick={value => {
                  this.saveTags(this.props.user.id);
                }}
                className="right"
              >
                <MaterialIcon icon="check" size={25} color="white" />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getTrevelBanner() {
    let backgroundUrl = '';

    if (
      this.props.uauth.guide.locationsWithDistance[0].zoneId === 11
    ) {
      backgroundUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fstllanding.jpg?alt=media&token=2d50daf2-b14b-4dc3-9a04-358d2c764380';
    } else {
      backgroundUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fkclanding.jpg?alt=media&token=b8d9d9a8-5760-40ec-8101-ac66788aaf41';
    }

    let backgroundStyle = {
      backgroundImage: ' url(' + backgroundUrl + ')',
      backgroundSize: 'cover',

      backgroundPosition: 'center',
      position: 'relative',
    };
    return (
      <div style={backgroundStyle} className="trevelBanner">
        <div className="trevelBannerText">
          <span
            style={{
              display: 'block',
              clear: 'both',
              color: 'white',
            }}
            className="trevelBannerTop"
          >
            {this.props.uauth.guide.locationsWithDistance[0]
              .zoneId === 11
              ? 'St. Louis Community'
              : 'Kansas City Community'}
          </span>
          <span
            style={{
              display: 'block',
              clear: 'both',
              color: 'white',
            }}
            className="trevelBannerBottom"
          >
            Supporting Each Other
          </span>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div id="home">
        {this.getTitleBar()}
        {/* <div
          style={{
            marginBottom: '20px',
          }}
        >
          {this.getTrevelBanner()}
        </div> */}
        <div
          className="communityBack"
          id="bannerIntro"
          style={{
            height: '150px ',
          }}
        >
          {/* <img
              className="intro-image"
              height="527"
              src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fbanner1.png?alt=media&token=844aca0c-a108-4140-a566-6487a4f65344"
              width="609"
              alt="intro"
            /> */}

          <center>
            <h2
              style={{
                fontSize: '40px',
                color: 'white',
                lineHeight: '35px',
              }}
            >
              <b>
                Welcome to the Community!
                <br />{' '}
              </b>
            </h2>
          </center>
        </div>
        <div
          style={{
            // borderBottom: '3px solid black',
            padding: '30px 10px, 50px 10px',
            background: '#f3f3f3',
          }}
        >
          <div
            style={{
              display: 'block',
              padding: '10px',
              width: '95%',
              margin: '0px auto',
            }}
          >
            <Container
              style={{
                maxWidth: '1200px',
                paddingTop: '30px',
                paddingBottom: '30px',
              }}
            >
              <Row>
                <Col lg={8} md={8} sm={12} xs={12}>
                  <h4
                    style={{
                      fontWeight: '100',
                      padding: '5px 0px 10px 0px',
                    }}
                  >
                    New to Trevel?
                  </h4>

                  <p className="subline">
                    First off, Welcome! Trevel provides local
                    businesses, entertainers, artists and the
                    community new ways to connect, collaborate, and
                    support each other as our city battles back better
                    then before. This video is great place to start!
                  </p>

                  <div style={{ paddingBottom: '20px' }}>
                    <center>
                      <video
                        width="100%"
                        max-width="500px"
                        controls
                        controlsList="nodownload"
                        style={{ border: '1px solid #131313' }}
                      >
                        <source
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/video%2FTrevel1.mp4?alt=media&token=672a063d-31d3-47eb-b5fb-2ebfeef6dda7"
                          type="video/mp4"
                        />
                      </video>
                    </center>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <center>
                    <h4
                      style={{
                        fontWeight: '100',
                        padding: '5px 0px 10px 0px',
                      }}
                    >
                      Need Help?
                    </h4>
                  </center>

                  <p className="subline">
                    Here are some links to common help topics. If none
                    of this does you any good at all you can search
                    our larger knowledge base.
                  </p>
                  <div>
                    <ul>
                      <Link
                        to={{
                          pathname: '/support/1',
                          state: {
                            returnPath: '/community',
                          },
                        }}
                      >
                        <li>Create an Experience</li>
                      </Link>
                      <Link
                        to={{
                          pathname: '/support/3',
                          state: {
                            returnPath: '/community',
                          },
                        }}
                      >
                        <li>Starting an Experience</li>
                      </Link>
                      <Link
                        to={{
                          pathname: '/support/7',
                          state: {
                            returnPath: '/community',
                          },
                        }}
                      >
                        <li>Manage Your Contacts</li>
                      </Link>
                    </ul>
                  </div>

                  <center>
                    <Link
                      to={{
                        pathname: '/support',
                        state: {
                          returnPath: '/community',
                        },
                      }}
                    >
                      <span className="redbutton">
                        Search Knowledge Base
                      </span>
                    </Link>
                  </center>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="padding-top padding-bottom"
          style={{
            // background:
            //   'linear-gradient(180deg,rgba(167,25,65,1) 0%, rgba(242,29,89,1) 59%)',
            borderTop: '2px solid #760020',
            borderBottom: '2px solid #760020',
            paddingBottom: '20px',
          }}
        >
          <center>
            <h4
              style={{
                fontSize: '30px',
                lineHeight: '35px',
                color: 'black',
                marginBottom: '50px',
              }}
            >
              Upcoming Features
            </h4>
          </center>
          <div
            className="wrapper"
            style={{
              paddingBottom: '20px',
            }}
          >
            <Row className="no-margin">
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div>
                  <h4
                    className="inline-block"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Entertainers
                  </h4>
                  <p className="subline ">
                    All local {this.state.city} entertainers are
                    welcome to get listed in Trevel. You can share
                    your events, connect with your fans, and manage
                    your own Trevel Channel.
                  </p>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div>
                  <h4
                    className="inline-block"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Destinations
                  </h4>
                  <p className="subline ">
                    As people start to venture out into{' '}
                    {this.state.city} once again we want to make sure
                    they find the restaurants, museums, parks and
                    other Destinations that they will love to
                    experience together.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div>
                  <h4
                    className="inline-block"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Artists
                  </h4>
                  <p className="subline">
                    Local {this.state.city} Artists will be
                    spotlighted and promoted on Trevel. We are also
                    looking for local Artists to help create unique
                    regional badges that users will earn as they
                    support our local community.
                  </p>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div>
                  <h4
                    className="inline-block"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.city}
                  </h4>
                  <p className="subline">
                    Local {this.state.city} Artists will be
                    spotlighted and promoted on Trevel. We are also
                    looking for local Artists to help create unique
                    regional badges that users will earn as they
                    support our local community.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div style={{ paddingTop: '50px' }}>
                  <p className="subline">
                    Interested in providing feedback?
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div
          style={{
            // borderTop: '3px solid black',
            padding: '5px 10px, 30px 10px',
            marginTop: '20px',
          }}
        >
          {/* <div style={{ padding: '10px 0px 10px 0px' }}>
            <center>
              <h3>
                It{"'"}s Time to{' '}
                <span
                  style={{
                    fontWeight: '600',
                    fontSize: '36px',
                  }}
                >
                  Revel in Our Experiences
                </span>{' '}
                Again,{' '}
                <span
                  style={{
                    fontWeight: '600',
                    fontSize: '36px',
                  }}
                >
                  Together
                </span>
              </h3>
           
            </center>
          </div> */}
          <div
            style={{
              display: 'block',
              padding: '10px',
              width: '95%',
              margin: '0px auto',
              marginTop: '30px',
            }}
          >
            <Container style={{ maxWidth: '1200px' }}>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <h3 className="inline-block">
                    Trevel Stategy Series
                  </h3>
                  <h4
                    style={{
                      fontWeight: '100',
                      padding: '5px 0px 10px 0px',
                    }}
                  >
                    Coming Soon
                  </h4>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {this.props.user.authenticated === true && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.communityBack{margin-top: 40px; padding-top: 40px} #startBar{background-color: #ef0059;} .adminActionBar {top: 40px !important;} #events{position: relative;}  #headersTop {width: 100% !important;} #headersTopSecondary{width: 100%} #authright {padding-left: 0px !important;}  #mobile-nav { display: none } #authleft {display:none;} #events{position: relative; top: 40px}  #bottomNav{left:0px; width:100%} .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        )}
        {this.props.user.authenticated === false && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.backbar{display:none} .communityBack{padding-top: 40px}',
            }}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearUauth: () => {
      dispatch(clearUauth());
    },
    getArticles: (article) => {
      dispatch(getArticles(article));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    uauth: state.unauth,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Community);
