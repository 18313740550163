import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getUserEvents,
  setSchedule,
  finishEvent,
} from '../../services/UserEventServices';
import { push } from 'connected-react-router';
import * as actions from './actions';

let defaultState = {
  events: {},
};

function* getUserEventsAction(action) {
  let events = yield call(getUserEvents, action.payload);
  yield put({
    type: actions.SET_EVENTS,
    payload: {
      ...defaultState,
      events,
    },
  });
  // yield put(push("/home"));
}

function* refreshUserEventsAction(action) {
  const events = action.payload;
}

function* updateUserEventsAction(action) {
  let events = yield call(getUserEvents, action.payload);
  yield put({
    type: actions.SET_EVENTS,
    payload: {
      ...defaultState,
      events,
    },
  });
  yield put(push('/events/upcoming'));
}

export function* scheduleEventSaga(action) {
  yield call(setSchedule, action);
  const events = yield call(getUserEvents, action.payload.userId);
  yield put({
    type: actions.SET_EVENTS,
    payload: {
      ...defaultState,
      events,
    },
  });
  const url = '/event/' + action.payload.eventId + '?test';
  // yield put(push(url));
  yield put(push('/events/upcoming'));
}

export function* finishEventAction(action) {
  yield call(finishEvent, action.payload);
  yield put(push('/events/completed'));
}

export function* removeGuestAction(action) {
  yield call(finishEvent, action.payload);
}

export default function* sagas() {
  yield takeLatest(actions.GET_EVENTS, getUserEventsAction);
  yield takeLatest(actions.REFRESH_EVENTS, refreshUserEventsAction);
  yield takeLatest(actions.UPDATE_EVENTS, updateUserEventsAction);
  yield takeLatest(actions.SCHEDULE_EVENT, scheduleEventSaga);
  yield takeLatest(actions.FINISH_EVENT, finishEventAction);
  yield takeLatest(actions.REMOVE_GUEST, removeGuestAction);
}
