import * as action from './actions';

export const startEvent = (event) => {
  return {
    type: action.START_EVENT,
    payload: event,
  };
};

export const setEvent = (event) => {
  return {
    type: action.SET_EVENT,
    payload: event,
  };
};

export const resetEvent = (event) => {
  return {
    type: action.RESET_EVENT,
    payload: event,
  };
};

const ACTION_HANDLERS = {
  [action.START_EVENT]: (state, action) => {
    return { ...state };
  },
  [action.SET_EVENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.RESET_EVENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  activeEvent: {
    eventStarted: false,
  },
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
