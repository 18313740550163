import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// reducers
import { reducer as formReducer } from 'redux-form';
import { reducer as packagesSaga } from '../sagas/packagesSaga/reducer';
import { reducer as articleSaga } from '../sagas/articleSaga/reducer';
import { reducer as inviteSaga } from '../sagas/inviteSaga/reducer';
import { reducer as loginSaga } from '../sagas/loginSaga/reducer';
import { reducer as profileSaga } from '../sagas/getProfileSaga/reducer';
import { reducer as eventsSaga } from '../sagas/userEventsSaga/reducer';
import { reducer as locationsSaga } from '../sagas/locationsSaga/reducer';
import { reducer as allLocationsSaga } from '../sagas/allLocationsSaga/reducer';
import { reducer as usersSaga } from '../sagas/usersSaga/reducer';
import { reducer as adminUsersSaga } from '../sagas/adminUsersSaga/reducer';
import { reducer as vendorsSaga } from '../sagas/vendorsSaga/reducer';
import { reducer as vendorSaga } from '../sagas/vendorSaga/reducer';
import { reducer as vendorAwardedSaga } from '../sagas/vendorAwardedSaga/reducer';
import { reducer as tagsSaga } from '../sagas/tagsSaga/reducer';
import { reducer as setModeSaga } from '../sagas/setModeSaga/reducer';
import { reducer as hydrateSaga } from '../sagas/hydrateSaga/reducer';
import { reducer as authhydrateSaga } from '../sagas/authhydrateSaga/reducer';
import { reducer as setActivePackageSaga } from '../sagas/setActivePackageSaga/reducer';
import { reducer as customEventSaga } from '../sagas/customEventSaga/reducer';
import { reducer as notificationsSaga } from '../sagas/notificationsSaga/reducer';
import { reducer as activeEventSaga } from '../sagas/activeEventSaga/reducer';
import { reducer as userVoucherSaga } from '../sagas/userVoucherSaga/reducer';
import { reducer as checkOutSaga } from '../sagas/checkOutSaga/reducer';
import { reducer as purchaseSaga } from '../sagas/purchaseSaga/reducer';
import { reducer as allEventsSaga } from '../sagas/allEventsSaga/reducer';
import { reducer as createPackageSaga } from '../sagas/createPackageSaga/reducer';
import { reducer as connectionsSaga } from '../sagas/connectionsSaga/reducer';
import { reducer as createEventSaga } from '../sagas/createEventSaga/reducer';
import { reducer as promoSaga } from '../sagas/promoSaga/reducer';
import { reducer as eventsPromoSaga } from '../sagas/eventsSaga/reducer';
import { reducer as vendorPromoSaga } from '../sagas/vendorPromoSaga/reducer';
import { reducer as vendorLocationsSaga } from '../sagas/vendorLocationsSaga/reducer';
import { reducer as vendorEventsSaga } from '../sagas/vendorEventsSaga/reducer';
import { reducer as vendorRewardsSaga } from '../sagas/vendorRewardsSaga/reducer';
import { reducer as userAwardsSaga } from '../sagas/userAwardsSaga/reducer';
import { reducer as createVendorEventSaga } from '../sagas/createVendorEventSaga/reducer';
import { reducer as vendorUserEventsSaga } from '../sagas/vendorUserEventsSaga/reducer';
import { reducer as connectionsTagsAndFavsSaga } from '../sagas/connectionsTagsAndFavsSaga/reducer';
import { reducer as setDailySpotlightSaga } from '../sagas/setDailySpotlightSaga/reducer';
import { reducer as setDestinationSaga } from '../sagas/setDestinationSaga/reducer';
import { reducer as unauthenticatedSaga } from '../sagas/unauthenticatedSaga/reducer';
import { reducer as demoSaga } from '../sagas/demoSaga/reducer';
import { reducer as vendorTimedEventsSaga } from '../sagas/vendorTimedEventsSaga/reducer';
import { reducer as timedEventsSaga } from '../sagas/timedEventsSaga/reducer';
import { reducer as vendorEntertainmentSaga } from '../sagas/vendorEntertainmentSaga/reducer';
import { reducer as entertainmentSaga } from '../sagas/entertainmentSaga/reducer';
import { reducer as guideTemplatesSaga } from '../sagas/guideTemplatesSaga/reducer';
import { reducer as regionsSaga } from '../sagas/regionsSaga/reducer';
import { reducer as taRequestSaga } from '../sagas/taRequestSaga/reducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    packages: packagesSaga,
    taRequest: taRequestSaga,
    form: formReducer,
    articles: articleSaga,
    invite: inviteSaga,
    user: loginSaga,
    profile: profileSaga,
    events: eventsSaga,
    locations: locationsSaga,
    alllocations: allLocationsSaga,
    adminUsers: adminUsersSaga,
    users: usersSaga,
    vendors: vendorsSaga,
    vendor: vendorSaga,
    tags: tagsSaga,
    mode: setModeSaga,
    hydrated: hydrateSaga,
    authhydrated: authhydrateSaga,
    activePackage: setActivePackageSaga,
    customEvent: customEventSaga,
    notifications: notificationsSaga,
    activeEvent: activeEventSaga,
    vouchers: userVoucherSaga,
    checkoutBundle: checkOutSaga,
    purchaseSaga: purchaseSaga,
    allEvents: allEventsSaga,
    newPackage: createPackageSaga,
    connections: connectionsSaga,
    newEvent: createEventSaga,
    promos: promoSaga,
    vendorPromos: vendorPromoSaga,
    userAwards: userAwardsSaga,
    vendorAwarded: vendorAwardedSaga,
    vendorEvent: createVendorEventSaga,
    vendorEvents: vendorEventsSaga,
    vendorRewards: vendorRewardsSaga,
    vendorEntertainment: vendorEntertainmentSaga,
    promoEvents: eventsPromoSaga,
    vendorUserEvents: vendorUserEventsSaga,
    tagsAndFavs: connectionsTagsAndFavsSaga,
    vendorLocations: vendorLocationsSaga,
    dailySpotlight: setDailySpotlightSaga,
    destination: setDestinationSaga,
    unauth: unauthenticatedSaga,
    demo: demoSaga,
    vendorTimedEvents: vendorTimedEventsSaga,
    timedEvents: timedEventsSaga,
    entertainment: entertainmentSaga,
    guideTemplates: guideTemplatesSaga,
    regions: regionsSaga,
  });
