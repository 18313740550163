import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from 'reactstrap';
import moment from 'moment';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

class GuideDemoCategory extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {}

  findPromotedLocations(demo) {
    let bundle = {
      promotedLocations: demo.demoPromos,
    };

    return bundle;
  }

  getTagCount(userLove) {
    let userTags = [];
    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };
    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };
    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateType',
      tags: [],
    };
    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisineType',
      tags: [],
    };
    let allTags = {
      tagsBeverages: [],
      tagsDateType: [],
      tagsSetting: [],
      tagsCuisine: [],
    };
    // Combine Tags

    userLove.tagsBeverages.forEach((tagcat, x) => {
      let n = allTags.tagsBeverages.includes(tagcat);
      if (n === false) {
        allTags.tagsBeverages.push(tagcat);
      }
    });

    userLove.tagsDateType.forEach((tagcat, x) => {
      let n = allTags.tagsDateType.includes(tagcat);
      if (n === false) {
        allTags.tagsDateType.push(tagcat);
      }
    });

    userLove.tagsSetting.forEach((tagcat, x) => {
      let n = allTags.tagsSetting.includes(tagcat);
      if (n === false) {
        allTags.tagsSetting.push(tagcat);
      }
    });

    userLove.tagsCuisine.forEach((tagcat, x) => {
      let n = allTags.tagsCuisine.includes(tagcat);
      if (n === false) {
        allTags.tagsCuisine.push(tagcat);
      }
    });

    let tagCount =
      allTags.tagsCuisine.length +
      allTags.tagsSetting.length +
      allTags.tagsDateType.length +
      allTags.tagsBeverages.length;
    allTags.tagsSetting;

    return tagCount;
  }

  getImage(type, location) {
    let hero = '';
    let thumb = '';
    if (
      this.props.tagCat === 'tagsGenre' ||
      this.props.tagCat === 'tagsEventType'
    ) {
      hero = location.eventHero;
      thumb = location.eventThumb;
    } else {
      hero = location.locationHero;
      thumb = location.locationThumb;
    }
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = hero;
      } else {
        if (location !== undefined && hero !== undefined) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            hero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = thumb;
      } else {
        if (location !== undefined && thumb !== undefined) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            thumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  getSingle(promotedLocations) {
    let location = promotedLocations[0];
    if (promotedLocations.length === 1) {
      return (
        <div>
          <Link
            to={{
              pathname: '/location/' + location.locationId,
              state: {
                location: { location },
                // eventId: { eventId },
                // token: { token },
                type: 500,
              },
            }}
          >
            <div>
              <div>
                <MaterialIcon
                  icon="add_circle"
                  size={25}
                  color="rgb(242, 29, 89)"
                />
              </div>

              <img
                src={this.getImage('hero', location)}
                style={{ width: '100%' }}
              />
              <div className="section">
                <span className="title">
                  {location.locationTitle}
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: location.locationDescription,
                  }}
                  className="editoOverRide"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  getExperienceTags(experience) {
    const experienceTypes = [];
    if (experience.tagsExperienceTypes !== undefined) {
      experience.tagsExperienceTypes.forEach((tag) => {
        experienceTypes.push({
          value: tag,
          label: tag,
        });
      });
    }
    return (
      <div>
        {experienceTypes &&
          experienceTypes.map((tag, index) => (
            <span className="experienceTag" key={tag.label}>
              {tag.label}
            </span>
          ))}
      </div>
    );
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    return to;
  }

  getSlideSections(promotedLocations, count) {
    let sections = [];
    let sectionOne = [];
    let sectionTwo = [];
    let sectionThree = [];
    let sectionFour = [];
    let sectionFive = [];
    let sectionSix = [];
    let sectionSeven = [];
    let sectionEight = [];
    let sectionNine = [];
    let sectionTen = [];

    if (count === 4) {
      promotedLocations.forEach((location, index) => {
        if (index < 3 || index === 3) {
          sectionOne.push(location);
        }
        if (index > 3 && index < 8) {
          sectionTwo.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
    }

    if (count === 3) {
      promotedLocations.forEach((location, index) => {
        if (index < 2 || index === 2) {
          sectionOne.push(location);
        }
        if (index > 2 && index < 6) {
          sectionTwo.push(location);
        }
        if (index > 6 && index < 9) {
          sectionThree.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
    }

    if (count === 2) {
      promotedLocations.forEach((location, index) => {
        if (index === 0) {
          sectionOne.push(location);
        }
        if (index === 1) {
          sectionTwo.push(location);
        }
        if (index === 2) {
          sectionThree.push(location);
        }
        if (index === 3) {
          sectionFour.push(location);
        }
        if (index === 4) {
          sectionFive.push(location);
        }
        if (index === 5) {
          sectionSix.push(location);
        }
        if (index === 6) {
          sectionSeven.push(location);
        }
        if (index === 7) {
          sectionEight.push(location);
        }
        if (index === 8) {
          sectionNine.push(location);
        }
        if (index === 9) {
          sectionTen.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
      if (sectionFour.length !== 0) {
        sections.push(sectionFour);
      }
      if (sectionFive.length !== 0) {
        sections.push(sectionFive);
      }
      if (sectionSix.length !== 0) {
        sections.push(sectionSix);
      }
      if (sectionEight.length !== 0) {
        sections.push(sectionEight);
      }
      if (sectionSeven.length !== 0) {
        sections.push(sectionSeven);
      }
      if (sectionNine.length !== 0) {
        sections.push(sectionNine);
      }
      if (sectionTen.length !== 0) {
        sections.push(sectionTen);
      }
    }

    let eventDetails = [];

    return sections.map((section, index) => {
      return (
        <div style={{ height: '100%' }}>
          {section.map((event, index) => (
            <div
              style={{
                border: '0px solid rgba(0, 0, 0, 0.125)',
                background: 'white !important',
              }}
              className={count === 2 ? 'singleSlide' : 'multiSlide'}
            >
              <Link
                to={{
                  pathname: '/vevent/' + event.id,
                  state: {
                    demo: true,
                    demoDetails: event,
                  },
                }}
                className="left"
              >
                <CardImg
                  top
                  width="100%"
                  src={event.images.heroPath}
                  alt="articleHero"
                />
                <CardBody style={{ textAlign: 'left' }}>
                  <div style={{ paddingTop: '10px', border: '0px' }}>
                    <div style={{ float: 'right' }}>
                      <MaterialIcon
                        icon="local_activity"
                        size={25}
                        color="rgb(242, 29, 89)"
                      />
                    </div>
                    <span className="title">{event.title}</span>
                    <div
                      style={{
                        marginBottom: '10px',
                        clear: 'both',
                      }}
                    >
                      {this.getExperienceTags(event)}
                    </div>
                    <CardText>
                      <span className="promo-criteria">
                        {event.description}
                      </span>
                    </CardText>
                    <CardText />
                  </div>
                </CardBody>
              </Link>
            </div>
          ))}
        </div>
      );
    });
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span className="mainTagClear">{location.tagsMain}</span>
      );
    }
  }

  truncate(str) {
    if (str !== undefined) {
      let regex = /(<([^>]+)>)/gi;
      let result = str.replace(regex, '');
      let final = result.replace(/&amp;/g, '&');
      return final.length > 100
        ? final.substring(0, 100) + '...'
        : final;
    } else {
      return '';
    }
  }

  render() {
    let bundle = this.findPromotedLocations(this.props.demo);

    let promotedLocations = bundle.promotedLocations;

    let slug = '';
    if (this.props.category !== undefined) {
      slug = this.props.category.replace(/\s/g, '-');
    }

    let count = this.props.count;

    if (promotedLocations.length > 0) {
      return (
        // <div className="homeSection" id="homeSectionGrey">
        <div className="homeSection">
          <div
            style={{
              height: count > 2 ? '40px' : '80px',
              marginBottom: count > 2 ? '10px' : '10px',
            }}
          >
            <Row
              className="no-margin"
              style={{
                padding: count > 2 ? '0x' : '0px',
              }}
            >
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={9}
                className="no-padding"
              >
                <div className="sectiontitle text-center">
                  <span
                    className="homeCat "
                    style={{
                      float: count > 2 ? 'left' : 'left',
                      marginBottom: count > 2 ? '10px' : '',
                      textAlign: count > 2 ? 'left' : 'left',
                    }}
                  >
                    {this.props.catTitle}
                  </span>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={4}
                lg={3}
                className="no-padding"
              >
                <div
                  style={{
                    zindex: '99999',
                    textAlign: count > 2 ? 'left' : 'left',
                  }}
                >
                  <Link
                    to={{
                      pathname: '/experiences',
                    }}
                    className={count > 2 ? 'right' : 'seeMoreLeft'}
                  >
                    Create Trevel Experiences
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          {promotedLocations.length > 0 && (
            <Carousel
              showThumbs={false}
              // centerMode={true}
              // centerSlidePercentage={80}
              // selectedItem={this.getCount(promotedLocations)}
              showIndicators={false}
              showStatus={false}
              // showArrows={true}
              infiniteLoop={false}
              centerMode={count === 2 ? true : false}
              swipeScrollTolerance={50}
              preventMovementUntilSwipeScrollTolerance={true}
            >
              {this.getSlideSections(promotedLocations, count)}
            </Carousel>
          )}
        </div>
      );
    }
    return null; // or render a loading icon
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    demo: state.demo,
  };
}

export default connect(mapStateToProps)(GuideDemoCategory);
