import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
// Components
import LocationListItemEdit from '../../components/LocationListItemEdit';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
import TitleBar from '../../components/TitleBar';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';
import { getTags } from '../../sagas/tagsSaga/reducer';
import Select from 'react-select';

const KEYS_TO_FILTERS = [
  'locationTitle',
  'address.city',
  'address.street',
  'tagsBeverages',
  'tagsCuisine',
  'tagsSettings',
  'tagsDateType',
];

export class AdminLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      zoneFilter: 10,
      selectInput: {
        label: '10 - KC',
        value: 10,
      },
      typeFilter: '',
      showReport: false,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    }
  }

  componentDidMount() {
    if (
      (this.props.profile.userType !== undefined &&
        this.props.profile.userType === 1) ||
      (this.props.profile.userType !== undefined &&
        this.props.profile.userType === 4)
    ) {
      if (this.props.alllocations === undefined) {
        this.props.getAllLocations();
      }
      // this.props.getTags();
      this.setState({
        loading: false,
      });
    } else {
      this.props.history.push('/login');
    }
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getZoneIds(locations) {
    let zoneIds = [];
    let selectOptions = [];
    locations.forEach((location, indexA) => {
      let status = zoneIds.includes(location.zoneId);
      if (status === false) {
        selectOptions.push({
          value: location.zoneId,
          label: location.zoneId + ' - ' + location.address.state,
        });
        zoneIds.push(location.zoneId);
      }
    });
    let sorted = selectOptions.sort(function (a, b) {
      return a.value - b.value;
    });
    return sorted;
  }

  getTypes(locations) {
    let selectOptions = [];
    let options = [];
    locations.forEach((location, indexA) => {
      if (
        location.tagsLocationType !== undefined &&
        location.tagsLocationType.length !== 0
      ) {
        location.tagsLocationType.forEach((tag, indexA) => {
          let status = options.includes(tag);
          if (status === false) {
            selectOptions.push({
              value: tag,
              label: tag,
            });
            options.push(tag);
          }
        });
      }
    });
    return selectOptions;
  }

  selectChange = (values) => {
    this.setState({
      zoneFilter: values.value,
      selectInput: {
        label: values.label,
        value: values.value,
      },
    });
  };

  selectChangeType = (values) => {
    this.setState({
      typeFilter: values.value,
      selectInputType: {
        label: values.label,
        value: values.value,
      },
    });
  };

  showReport = (show, mode) => {
    this.setState({
      showReport: show,
      reportMode: mode,
    });
  };

  tagCounter(tags, sid, locationTagCat) {
    const options = {};
    let tagsArray = [];
    tags.forEach((cattag) => {
      if (cattag.sid === sid) {
        const cattagtags = cattag.tags;
        cattagtags.forEach((tag) => {
          if (options[tag.tag] === undefined) {
            options[tag.tag] = {
              tag: tag.tag,
              count: 0,
            };
          }
          if (Array.isArray(this.props.alllocations)) {
            this.props.alllocations.forEach((location) => {
              if (
                this.state.reportMode === 1 &&
                location.zoneId === this.state.zoneFilter
              ) {
                if (
                  location[locationTagCat] !== undefined &&
                  location[locationTagCat].length !== 0
                ) {
                  location[locationTagCat].forEach((tagx) => {
                    if (tagx === tag.tag) {
                      if (options[tagx] !== undefined) {
                        options[tagx].tag = tagx;
                        options[tagx].count = options[tagx].count + 1;
                      } else {
                        options[tagx] = {
                          tag: tagx,
                          count: 1,
                        };
                      }
                    }
                  });
                }
              } else if (this.state.reportMode === 2) {
                if (
                  location[locationTagCat] !== undefined &&
                  location[locationTagCat].length !== 0
                ) {
                  location[locationTagCat].forEach((tagx) => {
                    if (tagx === tag.tag) {
                      if (options[tagx] !== undefined) {
                        options[tagx].tag = tagx;
                        options[tagx].count = options[tagx].count + 1;
                      } else {
                        options[tagx] = {
                          tag: tagx,
                          count: 1,
                        };
                      }
                    }
                  });
                }
              }
            });
          }
        });
      }
    });

    Object.keys(options).forEach((key) => {
      tagsArray.push(options[key]);
    });
    let sorted = tagsArray.sort((a, b) => b.count - a.count);
    let numOptions = sorted.length;
    let rowLength = numOptions / 3;
    let rowOne = [];
    let rowTwo = [];
    let rowThree = [];
    sorted.forEach((option, index) => {
      if (index < rowLength) {
        rowOne.push(option);
      } else if (index > rowLength && index < rowLength * 2) {
        rowTwo.push(option);
      } else if (index > rowLength * 2 && index < rowLength * 3) {
        rowThree.push(option);
      }
    });
    let rows = [];
    rows.push(rowOne);
    rows.push(rowTwo);
    rows.push(rowThree);
    return (
      <div>
        <Col lg={4} md={4} sm={4} xs={4}>
          {rowOne.map((tag, index) => (
            <Row
              className="no-margin no-padding"
              style={{
                borderBottom: '1px solid #e4e4e4',
              }}
            >
              <Col
                lg={10}
                md={10}
                sm={10}
                xs={10}
                className="no-padding"
              >
                <span style={{ float: 'left' }}>{tag.tag}</span>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
                <span style={{ float: 'right' }}>{tag.count}</span>
              </Col>
            </Row>
          ))}
        </Col>
        <Col lg={4} md={4} sm={4} xs={4}>
          {rowTwo.map((tag, index) => (
            <Row
              className="no-margin no-padding"
              style={{
                borderBottom: '1px solid #e4e4e4',
              }}
            >
              <Col
                lg={10}
                md={10}
                sm={10}
                xs={10}
                className="no-padding"
              >
                <span style={{ float: 'left' }}>{tag.tag}</span>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
                <span style={{ float: 'right' }}>{tag.count}</span>
              </Col>
            </Row>
          ))}
        </Col>
        <Col lg={4} md={4} sm={4} xs={4}>
          {rowThree.map((tag, index) => (
            <Row
              className="no-margin no-padding"
              style={{
                borderBottom: '1px solid #e4e4e4',
              }}
            >
              <Col
                lg={10}
                md={10}
                sm={10}
                xs={10}
                className="no-padding"
              >
                <span style={{ float: 'left' }}>{tag.tag}</span>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
                <span style={{ float: 'right' }}>{tag.count}</span>
              </Col>
            </Row>
          ))}
        </Col>
      </div>
    );
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/home',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Manage Locations</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}></Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    let beverageTags = this.tagCounter(
      this.props.tags,
      'beverages',
      'tagsBeverages',
    );
    let cuisineTags = this.tagCounter(
      this.props.tags,
      'cuisineType',
      'tagsCuisine',
    );
    let locationTags = this.tagCounter(
      this.props.tags,
      'locationType',
      'tagsLocationType',
    );

    if (this.state.showReport === true) {
      return (
        <div>
          <div id="headers">
            <div className="backbar">
              <Container className="no-padding">
                <Row className="no-margin">
                  <Col lg={2} md={2} sm={2} xs={2}></Col>
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <center>
                      <span className="page-header">
                        {this.state.reportMode === 1
                          ? 'Zone ' +
                            this.state.zoneFilter +
                            ' ' +
                            'Report'
                          : 'All Zones Report'}
                      </span>
                    </center>
                  </Col>
                  <Col lg={2} md={2} sm={2} xs={2}>
                    <div
                      style={{
                        float: 'right',
                        marginLeft: '15px',
                        display: 'block',
                        position: 'relative',
                      }}
                    >
                      <a
                        onClick={() => {
                          this.showReport(false, 2);
                        }}
                      >
                        Close
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <div style={{ textAlign: 'center', paddingTop: '50px' }}>
            <div>
              <Container className="no-padding">
                <Row style={{ marginBottom: '20px' }}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Container>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <h4 style={{ float: 'left' }}>Beverages</h4>
                        </Col>
                      </Row>

                      {this.tagCounter(
                        this.props.tags,
                        'beverages',
                        'tagsBeverages',
                      )}
                    </Container>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Container>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <h4 style={{ float: 'left' }}>Cuisine</h4>
                        </Col>
                      </Row>

                      {this.tagCounter(
                        this.props.tags,
                        'cuisineType',
                        'tagsCuisine',
                      )}
                    </Container>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Container>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <h4 style={{ float: 'left' }}>
                            Location Type
                          </h4>
                        </Col>
                      </Row>

                      {this.tagCounter(
                        this.props.tags,
                        'locationType',
                        'tagsLocationType',
                      )}
                    </Container>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Container>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <h4 style={{ float: 'left' }}>Date Type</h4>
                        </Col>
                      </Row>

                      {this.tagCounter(
                        this.props.tags,
                        'dateType',
                        'tagsDateType',
                      )}
                    </Container>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Container>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <h4 style={{ float: 'left' }}>Setting</h4>
                        </Col>
                      </Row>

                      {this.tagCounter(
                        this.props.tags,
                        'setting',
                        'tagsSetting',
                      )}
                    </Container>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <style
            dangerouslySetInnerHTML={{
              __html:
                '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
            }}
          />
        </div>
      );
    }
    if (
      this.state.loading === true ||
      this.props.alllocations === undefined
    ) {
      return (
        <div>
          <div style={{ textAlign: 'center', padingTop: '100px' }}>
            <h4 style={{}}>Loading</h4>
          </div>

          <style
            dangerouslySetInnerHTML={{
              __html:
                '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
            }}
          />
        </div>
      );
    }

    let array = [];
    let selectOptions = this.getZoneIds(this.props.alllocations);
    let selectOptionsType = this.getTypes(this.props.alllocations);
    let locationsByZone = [];

    this.props.alllocations.forEach((location) => {
      if (location.zoneId === this.state.zoneFilter) {
        locationsByZone.push(location);
      }
    });

    if (this.state.typeFilter !== '') {
      locationsByZone.forEach((location, indexA) => {
        if (
          location.tagsLocationType !== undefined &&
          location.tagsLocationType.length !== 0
        ) {
          location.tagsLocationType.forEach((tag, indexA) => {
            if (tag === this.state.typeFilter) {
              array.push(location);
            }
          });
        }
      });
    } else {
      array = locationsByZone;
    }

    const filteredLocations = array.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );

    let resultsCounter = filteredLocations.length;
    return (
      <div id="admin">
        {this.getTitleBar()}
        <div className="section">
          <div
            className="header-container"
            style={{ display: 'inline-block', width: '100%' }}
          >
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>

            <span
              style={{
                float: 'right',
                display: 'block',
                top: '10px',
                fontWeight: '500',
                position: 'relative',
              }}
            >
              {resultsCounter} /{' '}
              <span
                style={{
                  color: 'grey',
                }}
              >
                {this.props.alllocations.length}
              </span>{' '}
              Results
            </span>
            <div
              style={{
                width: '200px',
                float: 'left',
                marginRight: '20px',
              }}
            >
              <span
                style={{
                  float: 'left',
                  marginRight: '10px',
                  display: 'block',
                  top: '10px',
                  fontWeight: '500',
                  position: 'relative',
                }}
              >
                Zone Id
              </span>
              <Select
                value={this.state.selectInput}
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                onChange={(e) => this.selectChange(e, 0, 'one')}
                options={selectOptions}
                isMulti={false}
                placeholder={'Filter By ZoneId'}
              />
            </div>
            <div style={{ width: '300px', float: 'left' }}>
              <span
                style={{
                  float: 'left',
                  marginRight: '10px',
                  display: 'block',
                  top: '10px',
                  fontWeight: '500',
                  position: 'relative',
                }}
              >
                Type
              </span>
              <Select
                value={this.state.selectInputType}
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                onChange={(e) => this.selectChangeType(e, 0, 'one')}
                options={selectOptionsType}
                isMulti={false}
                placeholder={'Filter By Type'}
              />
            </div>
            <div
              style={{
                float: 'left',
                marginLeft: '15px',
                display: 'block',
                position: 'relative',
                top: '15px',
              }}
            >
              <a
                onClick={() => {
                  this.showReport(true, 1);
                }}
              >
                Show Zone Report
              </a>
            </div>
            <div
              style={{
                float: 'left',
                marginLeft: '15px',
                display: 'block',
                position: 'relative',
                top: '15px',
              }}
            >
              <a
                onClick={() => {
                  this.showReport(true, 2);
                }}
              >
                Show All Zones Report
              </a>
            </div>
          </div>
        </div>
        <div>
          <LocationListItemEdit
            locations={filteredLocations}
            edit={true}
            editPath="/admin/location/edit/"
            backPath="/admin/locations"
          />
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.alllocations.locations,
    user: state.user,
    profile: state.profile.profile,
    tags: state.tags.tags,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
    getTags: (tag) => {
      dispatch(getTags(tag));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminLocations);
