import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import { Col, Row } from 'reactstrap';

const KEYS_TO_FILTERS = ['title'];

export class VendorRewards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      promos: [],
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  getStatus(status) {
    if (status === true) {
      return 'Active';
    } else {
      return 'Inactive';
    }
  }

  // getCount(promoId, starting) {
  //   let awardArray = [];
  //   this.props.vendorAwarded.forEach((award) => {
  //     if (award.promo.id === promoId) {
  //       awardArray.push(award);
  //     }
  //   });
  //   let count = awardArray.length;
  //   let remaining = starting - count;
  //   return <span>{remaining}</span>;
  // }

  getRedeemed(rewardId) {
    let awardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === rewardId) {
        if (award.redemption.redeemed === true) {
          awardArray.push(award);
        }
      }
    });
    let count = awardArray.length;
    return <span>{count}</span>;
  }

  getAvailable(rewardId) {
    let startingCount = 0;
    this.props.vendorPromos.forEach((promo) => {
      promo.rewards.forEach((reward) => {
        if (reward.id === rewardId) {
          startingCount = startingCount + reward.quantity;
        }
      });
    });

    let redeemedArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === rewardId) {
        if (award.earned === true) {
          redeemedArray.push(award);
        }
      }
    });
    let count = redeemedArray.length;
    let remaining = startingCount - count;

    return (
      <span>
        {remaining} / {startingCount}
      </span>
    );
  }

  getPromosIncluded(rewardId) {
    let promos = ' ';
    this.props.vendorPromos.forEach((promo) => {
      promo.rewards.forEach((reward) => {
        if (reward.id === rewardId) {
          if (promos === ' ') {
            promos = promo.title;
          } else {
            promos = promos + ', ' + promo.title;
          }
        }
      });
    });
    if (promos === ' ') {
      return <span>None</span>;
    } else {
      return <span>{promos}</span>;
    }
  }

  getEarned(rewardId) {
    let awardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === rewardId) {
        if (award.earned === true) {
          awardArray.push(award);
        }
      }
    });
    let count = awardArray.length;
    return <span>{count}</span>;
  }

  getDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getEditButton(id) {
    return (
      <Link
        to={{
          pathname: '/vendor/reward/edit/' + id,
        }}
        // className="redbutton"
      >
        <MaterialIcon icon="edit" size={25} color="red" />
      </Link>
    );
  }

  getPromos(promos) {
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={4} md={4} sm={4} lg={4} className="left">
                  <span className="label">Reward</span>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Promos</span>
                </Col>
                <Col
                  xs={2}
                  md={2}
                  sm={2}
                  lg={2}
                  className="text-center"
                >
                  <span className="label">Available</span>
                </Col>
                <Col
                  xs={1}
                  md={1}
                  sm={1}
                  lg={1}
                  className="text-center"
                >
                  <span className="label">Earned</span>
                </Col>
                <Col
                  xs={1}
                  md={1}
                  sm={1}
                  lg={1}
                  className="text-center"
                >
                  <span className="label">Redeemeed</span>
                </Col>
                <Col xs={1} md={1} sm={1} lg={1} className="left" />
              </div>
            </li>
            {this.props.vendorRewards.map((reward, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    key={'key' + index}
                  >
                    <Link
                      to={{
                        pathname: '/vendor/reward/' + reward.id,
                      }}
                    >
                      {reward.title}
                    </Link>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {this.getPromosIncluded(reward.id)}
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    sm={2}
                    lg={2}
                    className="text-center"
                  >
                    {this.getAvailable(reward.id)}
                  </Col>
                  <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="text-center"
                  >
                    {this.getEarned(reward.id)}
                  </Col>
                  <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="text-center"
                  >
                    {this.getRedeemed(reward.id)}
                  </Col>
                  <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="text-right"
                  >
                    {this.getEditButton(reward.id)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="left">
                {/* <MaterialIcon
                  icon="close"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.editMode(this.state.customEvent.event);
                  }}
                /> */}
              </span>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Rewards</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/create/reward',
                }}
              >
                <span className="right">
                  <MaterialIcon
                    icon="add"
                    size={25}
                    color="white"
                    onClick={() => {}}
                  />
                </span>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    const filteredPackages = this.state.promos.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="admin">
        {this.getTitleBar()}
        <div className="section">
          <div className="header-container">
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
          </div>
        </div>
        <div>{this.getPromos(filteredPackages)}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} #bottomNav { display: none } ',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vendorPromos: state.vendorPromos.promos,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    vendorRewards: state.vendorRewards.rewards,
    user: state.user,
  };
}

export default connect(mapStateToProps)(VendorRewards);
