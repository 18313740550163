import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';
// Services
import { deleteDocument } from '../../services/AdminServices';
// Styles
import './LocationListItem.css';

const query = require('array-query');

class VendorLocationListItemEdit extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      expiredOnly: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  getEdit(location) {
    const copyLink =
      'https://trevel.com/vendor/claim/' + location.locationId;
    if (this.props.edit === true) {
      const url = this.props.editPath + location.eventId;

      return (
        <div className="right">
          <Link
            to={{
              pathname: url,
              state: {
                timedEvent: location,
              },
            }}
          >
            <MaterialIcon icon="edit" size={25} color="#f21d59" />
          </Link>
          <Link
            to={{
              pathname:
                '/vendor/timedevent/clone/' + location.eventId,
            }}
            style={{
              marginRight: '10px',
              clear: 'both',
              display: 'block',
              marginTop: '15px',
            }}
          >
            <MaterialIcon
              icon="file_copy"
              color="#f21d59"
              size={25}
            />
          </Link>
        </div>
      );
    }
  }

  getTaggedStatus(location) {
    let tagsSetting = 0;
    let tagsBeverages = 0;
    let tagsCuisine = 0;
    let tagsHours = 0;
    let tagsDateType = 0;

    if (location.tagsSetting) {
      if (location.tagsSetting.length > 0) {
        tagsSetting = 20;
      }
    }
    if (location.tagsBeverages) {
      if (location.tagsBeverages.length > 0) {
        tagsBeverages = 20;
      }
    }
    if (location.tagsCuisine) {
      if (location.tagsCuisine.length > 0) {
        tagsCuisine = 20;
      }
    }
    if (location.tagsHours) {
      if (location.tagsHours.length > 0) {
        tagsHours = 20;
      }
    }
    if (location.tagsDateType) {
      if (location.tagsDateType.length > 0) {
        tagsDateType = 20;
      }
    }

    const count =
      tagsSetting +
      tagsBeverages +
      tagsCuisine +
      tagsHours +
      tagsDateType;

    if (count === 100) {
      return <span className="table-bold green">{count}%</span>;
    } else {
      return (
        <span className="table-bold error-message">{count}%</span>
      );
    }
  }

  getDescriptionStatus(location) {
    if (location.locationDescription !== undefined) {
      if (location.locationDescription !== '<p></p>') {
        return <span className="table-bold green">Yes</span>;
      }
    } else {
      return <span className="table-bold error-message">No</span>;
    }
  }

  getPhoneStatus(location) {
    if (location.phone !== undefined) {
      if (
        location.phone === '(555) 555-5555' ||
        location.phone === '(555)555-555' ||
        location.phone === '555-555-5555' ||
        location.phone === '555-555-5602' ||
        location.phone === ''
      ) {
        return (
          <span className="table-bold error-message">Needed</span>
        );
      } else {
        return <span className="">{location.phone}</span>;
      }
    } else {
      return <span className="table-bold error-message">Needed</span>;
    }
  }

  confirmDelete(id) {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            deleteDocument('location', id, '/admin/locations'),
        },
        {
          label: 'No',
          onClick: () => console.log('closed'),
        },
      ],
    });
  }

  getHeadliners(event) {
    let headliners = '';
    event.entertainment.headliners.forEach((entertainment, index) => {
      headliners = headliners + entertainment.entertainmentTitle;
      if (index + 1 !== event.entertainment.headliners.length) {
        headliners = headliners + ' & ';
      }
    });
    return headliners;
  }

  getImage(type, event) {
    let path = '';
    if (
      event !== undefined &&
      event.settings !== undefined &&
      event.settings.customImages !== undefined &&
      event.settings.customImages === true
    ) {
      path = event.eventThumb;
    } else {
      if (event !== undefined && event.eventThumb !== undefined) {
        path =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/event_images%2F' +
          event.eventThumb +
          '?alt=media';
      }
    }
    return <img src={path} alt="alt" className="thumbnail" />;
  }

  getHeadliners(event) {
    let headliners = '';
    event.entertainment.headliners.forEach((entertainment, index) => {
      headliners = headliners + entertainment.entertainmentTitle;
      if (index + 1 !== event.entertainment.headliners.length) {
        headliners = headliners + ' & ';
      }
    });
    return headliners;
  }

  getTimeStatus(event) {
    let today = moment();
    let status = moment(
      event.scheduledTime.dates.endTimestamp,
    ).isBefore(today);
    if (status === true && event.active === true) {
      return (
        <span style={{ color: 'red', fontSize: '12px' }}>
          Expired
        </span>
      );
    } else if (status === true && event.active === false) {
      return (
        <span style={{ color: '#c1c1c1', fontSize: '12px' }}>
          Expired
        </span>
      );
    }
  }

  getDate(event) {
    let selectedDay = {};
    let selectedEndDay = {};

    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }

    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.endTimestamp !== ''
    ) {
      selectedEndDay = moment(event.scheduledTime.dates.endTimestamp);
    }
    let startDate = selectedDay._d.toLocaleDateString();
    let endDate = selectedEndDay._d.toLocaleDateString();

    let today = moment();

    let status = moment(
      event.scheduledTime.dates.endTimestamp,
    ).isBefore(today);

    if (event.scheduledTime.dates.startTimestamp === null) {
      return 'No Dates Set';
    }
    if (status === true && event.active === true) {
      if (startDate === endDate) {
        return (
          <div>
            <span style={{ color: 'red' }}>{startDate}</span>
          </div>
        );
      } else {
        return (
          <div>
            <span style={{ color: 'red' }}>{startDate}</span>
            <br></br>
            <span style={{ color: 'red' }}>{endDate}</span>
          </div>
        );
      }
    } else if (status === true && event.active === false) {
      if (startDate === endDate) {
        return (
          <div>
            <span style={{ color: '#c1c1c1' }}>{startDate}</span>
          </div>
        );
      } else {
        return (
          <div>
            <span style={{ color: '#c1c1c1' }}>{startDate}</span>
            <br></br>
            <span style={{ color: '#c1c1c1' }}>{endDate}</span>
          </div>
        );
      }
    } else {
      if (startDate === endDate) {
        return (
          <div>
            <span>{startDate}</span>
            <br></br>
            <span>{endDate}</span>
          </div>
        );
      } else {
        return (
          <div>
            <span>{startDate}</span>
          </div>
        );
      }
    }
  }

  setExpiredOnly() {
    if (this.state.expiredOnly === true) {
      this.setState({ expiredOnly: false });
    } else {
      this.setState({ expiredOnly: true });
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    const eventId = this.props.eventId;
    const token = this.props.token;
    const type = this.props.type;

    let filteredEvents = [];

    if (this.state.expiredOnly === true) {
      this.props.events.forEach((event) => {
        let today = moment();
        let status = moment(
          event.scheduledTime.dates.startTimestamp,
        ).isBefore(today);
        if (status === true) {
          filteredEvents.push(event);
        }
      });
      this.props.changeSortMode('dateUp');
    } else {
      filteredEvents = this.props.events;
    }

    // Sort Functions
    let sortedArray = [];
    if (this.props.sortMode === 'dateUp') {
      sortedArray = filteredEvents.sort((a, b) =>
        moment(a.scheduledTime.dates.startTimestamp).isBefore(
          moment(b.scheduledTime.dates.startTimestamp),
        )
          ? 1
          : -1,
      );
    }
    if (this.props.sortMode === 'dateDown') {
      sortedArray = filteredEvents.sort((a, b) =>
        moment(a.scheduledTime.dates.startTimestamp).isBefore(
          moment(b.scheduledTime.dates.startTimestamp),
        )
          ? -1
          : 1,
      );
    }
    if (this.props.sortMode === 'statusActive') {
      sortedArray = filteredEvents.sort(function (x, y) {
        return x.active === y.active ? 0 : x.active ? -1 : 1;
      });
    }
    if (this.props.sortMode === 'statusNotActive') {
      sortedArray = filteredEvents.sort(function (x, y) {
        return x.active === y.active ? 0 : x.active ? 1 : -1;
      });
    }
    if (this.props.sortMode === 'alphaA') {
      sortedArray = filteredEvents.sort((a, b) =>
        a.eventTitle > b.eventTitle ? 1 : -1,
      );
    }
    if (this.props.sortMode === 'alphaZ') {
      sortedArray = filteredEvents.sort((a, b) =>
        a.eventTitle > b.eventTitle ? -1 : 1,
      );
    }

    // let subset = this.props.packages.slice(0, 10);
    return (
      <Row className=" no-margin no-padding">
        {/* <div style={{ marginBottom: '10px' }}>
          <input
            type="checkbox"
            // name="active"
            checked={this.state.active}
            onChange={this.setExpiredOnly.bind(this)}
            style={{
              width: '20px',
              height: '20px',
              float: 'left',
            }}
          />
          <label
            style={{
              float: 'left',
              margin: '0px 5px 0px 0px',
              top: '-2px',
              position: 'relative',
            }}
          >
            Expired Only
          </label>
        </div> */}
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={6} md={6} sm={6} lg={6} className="left">
                  <a
                    onClick={() => {
                      this.props.changeSortMode(
                        this.props.sortMode === 'alphaA'
                          ? 'alphaZ'
                          : 'alphaA',
                      );
                    }}
                    style={{
                      textDecoration: 'underline',
                      color: 'white',
                    }}
                  >
                    <span className="label">Event</span>
                  </a>
                </Col>
                {/* <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Phone</span>
                </Col> */}
                <Col
                  xs={2}
                  md={2}
                  sm={2}
                  lg={2}
                  className="left mobileHide"
                >
                  <a
                    onClick={() => {
                      this.props.changeSortMode(
                        this.props.sortMode === 'statusActive'
                          ? 'statusNotActive'
                          : 'statusActive',
                      );
                    }}
                    style={{
                      textDecoration: 'underline',
                      color: 'white',
                    }}
                  >
                    <span className="label">Status</span>
                  </a>
                </Col>
                <Col
                  xs={2}
                  md={2}
                  sm={2}
                  lg={2}
                  className="left mobileHide"
                >
                  <a
                    onClick={() => {
                      this.props.changeSortMode(
                        this.props.sortMode === 'dateDown'
                          ? 'dateUp'
                          : 'dateDown',
                      );
                    }}
                    style={{
                      textDecoration: 'underline',
                      color: 'white',
                    }}
                  >
                    <span className="label">Date</span>
                  </a>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="right">
                  <span className="label right">Actions</span>
                </Col>
              </div>
            </li>
            {sortedArray.map((event, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={6}
                    key={'key' + eventId}
                    className="mobileHide"
                  >
                    <Row>
                      <Col
                        xs={6}
                        md={6}
                        sm={6}
                        lg={6}
                        className="thumbnailCol left no-padding"
                      >
                        <div style={{ minHeight: '90px' }}>
                          <Link
                            to={{
                              pathname:
                                this.props.editPath + event.eventId,
                            }}
                          >
                            {event.eventThumb !== ''
                              ? this.getImage('thumb', event)
                              : 'Add Thumb'}
                          </Link>
                        </div>
                      </Col>
                      <Col
                        xs={6}
                        md={6}
                        sm={6}
                        lg={6}
                        className="left"
                      >
                        <div
                          style={{
                            marginBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              color: 'black',
                              fontWeight: '500',
                              fontSize: '13px',
                              padding: '0px 0px 5px 0px',
                              display: 'block',
                            }}
                          >
                            {event.tagsEventType[0] ===
                            'Happy Hour Event'
                              ? 'Happy Hour'
                              : event.tagsEventType[0]}
                          </span>
                        </div>
                        <a
                          href={'/timedevent/' + event.eventId}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            className="location-title red"
                            style={{ fontWeight: '600' }}
                          >
                            {event.eventTitle}
                          </span>
                        </a>
                        <span className="location-title">
                          {this.getHeadliners(event)}
                        </span>
                        <span
                          className="address"
                          style={{ fontSize: '14px' }}
                        >
                          @ {event.address.locationName}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={10}
                    md={10}
                    sm={10}
                    lg={10}
                    key={'key' + index}
                    className="mobileShow no-padding"
                  >
                    <Row>
                      <Col
                        xs={2}
                        md={2}
                        sm={2}
                        lg={2}
                        className="thumbnailCol left no-padding"
                      >
                        <Link
                          to={{
                            pathname:
                              this.props.editPath + event.eventId,
                          }}
                        >
                          {event.eventThumb !== ''
                            ? this.getImage('thumb', event)
                            : 'Add Thumb'}
                        </Link>
                        {event.active === true
                          ? 'Active'
                          : 'Not Active'}
                      </Col>

                      <Col
                        xs={10}
                        md={10}
                        sm={10}
                        lg={10}
                        className="left"
                      >
                        <div
                          style={{
                            marginBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              color: 'black',
                              fontSize: '13px',
                              padding: '0px 0px 5px 0px',
                              display: 'block',
                            }}
                          >
                            {event.tagsEventType[0]}
                          </span>
                        </div>
                        <a
                          href={'/timedevent/' + event.eventId}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span
                            className="location-title  red"
                            style={{ fontWeight: '600' }}
                          >
                            {event.eventTitle}
                          </span>
                        </a>
                        {this.props.profile.userType === 1 && (
                          <span className="location-title">
                            {this.getHeadliners(event)}
                          </span>
                        )}

                        <span
                          className="address"
                          style={{
                            marginBottom: '10px',
                            fontSize: '14px',
                          }}
                        >
                          @ {event.address.locationName}
                        </span>
                        <div>
                          <span className="address">
                            {this.getDate(event)}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col xs={2} md={2} sm={2} lg={2}>
                    {this.getPhoneStatus(location)}
                  </Col> */}

                  <Col
                    xs={2}
                    md={2}
                    sm={2}
                    lg={2}
                    className="mobileHide"
                  >
                    {event.active === true ? (
                      <span style={{ color: 'green' }}>Active </span>
                    ) : (
                      <span style={{ color: '#c1c1c1' }}>
                        Not Active
                      </span>
                    )}
                    <span>
                      {event.expired === true ? 'EXPIRED' : ''}
                    </span>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    sm={2}
                    lg={2}
                    className="mobileHide"
                  >
                    <span className="address">
                      {this.getDate(event)}
                    </span>
                    {this.getTimeStatus(event)}
                  </Col>

                  <Col
                    xs={2}
                    md={2}
                    sm={2}
                    lg={2}
                    className="no-padding"
                  >
                    {this.getEdit(event)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    profile: state.profile.profile,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorLocationListItemEdit);

// Location Details Available
// **************************
// location.locationTitle
// location.categories
// location.locationDescription
// location.locationHero
// location.ocationThumb
// location.locationId
// location.locationType
// ** Vouchers and Packages as well
