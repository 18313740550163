import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './CompanyInfo.css';

class CompanyInfo extends Component {
  render() {
    return (
      <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
        <div className="informationContainer">
          <Row className="info-row">
            <table>
              <tr>
                <td className="alignTop">
                  <div className="favicon">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FNavigation.png?alt=media&token=e8243199-6e4e-42fb-bb1a-b2160a05ed8d"
                      height="40px"
                    />
                  </div>
                </td>
                <td>
                  <p>
                    P.O. Box 8355
                    <br />
                    Prairie Village, KS 66208
                  </p>
                </td>
              </tr>
              <tr>
                <td className="alignTop">
                  <div className="favicon">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FPhone.png?alt=media&token=1116a57f-1eae-49ac-99b4-b7abe5018579"
                      height="40px"
                    />
                  </div>
                </td>
                <td>
                  <p>(913) 384-7377</p>
                </td>
              </tr>
              <tr>
                <td className="alignTop padding-top-fifteen">
                  <div className="favicon">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FEmail.png?alt=media&token=4d945580-9563-4b37-ba85-81c275c6ee52"
                      height="30px"
                    />
                  </div>
                </td>
                <td>
                  <p className="no-padding no-margin">
                    <a href="mailto:mike@trevel.com">
                      info@trevel.com
                    </a>
                  </p>
                </td>
              </tr>
            </table>
          </Row>
          {/* <Row>
            <br />
            <a className="social-button" href=" ">
              <span className="social-button">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FInstagram.png?alt=media&token=a88b25b9-b77f-40f9-9854-66653bbf7a21"
                  height="35px"
                />{" "}
              </span>
            </a>
            <a className="social-button" href=" ">
              <span className="social-button">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FFacebook.png?alt=media&token=466bf06a-40aa-4ab6-aaa9-817ef4e8befd"
                  height="35px"
                />{" "}
              </span>
            </a>
            <a className="social-button" href=" ">
              <span className="social-button">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTwitter.png?alt=media&token=1373db72-b3de-4d77-8640-b738fab55553"
                  height="35px"
                />{" "}
              </span>
            </a>
            <a className="social-button" href=" ">
              <span className="social-button">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FLinkedIn.png?alt=media&token=c0825971-27b3-4f4f-a1b4-b28bf8f7ed33"
                  height="35px"
                />{" "}
              </span>
            </a>
          </Row> */}
        </div>
      </Col>
    );
  }
}

export default CompanyInfo;
