import React from 'react';
import { connect } from 'react-redux';
import ProfileEditForm from '../components/ProfileEditForm';

export class ProfileEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div id="profile-edit">
        <div>
          <ProfileEditForm />
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    user: state.user,
  };
}

export default connect(mapStateToProps)(ProfileEdit);
