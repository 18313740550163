import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardImg,
  Col,
  Container,
  Row,
} from 'reactstrap';
// Images
import redLogo from '../../images/heartbig.png';
import {
  getNewEvent,
  resetNewEvent,
} from '../../sagas/createEventSaga/reducer';
// Reducers
import { resetCe } from '../../sagas/customEventSaga/reducer';

class PromotionFeedMulti extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      promotions: [],
    };
  }
  componentDidMount() {
    let promotions = [];
    if (this.props.alllocations !== undefined) {
      promotions = this.findPromotions(this.props.alllocations);
    }
    this.setState({ promotions: promotions, loading: false });
  }

  findPromotions(locations) {
    const promotions = [];
    locations.forEach((location) => {
      let happyHours = location.happyHours;
      if (happyHours !== undefined && happyHours.length !== 0) {
        happyHours.forEach((happyHour) => {
          if (happyHour.happyHourType === 3) {
            let bundle = { happyHour, location };
            promotions.push(bundle);
          }
        });
      }
    });
    return promotions;
  }

  getPromos(event) {
    let promos = [];
    if (this.props.promos !== undefined) {
      this.props.promos.forEach((promo) => {
        if (promo.events.length !== 0) {
          promo.events.forEach((eventx) => {
            if (eventx.id === event.id) {
              promos.push(promo);
            }
          });
        }
      });
    }
    if (promos.length !== 0) {
      return (
        <div>
          {promos.map((promo, index) => (
            <div key={'key' + index}>
              <div className="promo">
                <span className="promo-reward">{promo.title}</span>
                {/* <span className="promo-reward">{promo.reward.text}</span>
                <span className="promo-criteria">{promo.criteria.text}</span> */}
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  quickEvent(event) {
    let customEvent = {
      clear: false,
      step: 1,
      containsCustom: false,
      newEventRef: null,
      editMode: false,
      fromVEvent: true,
      vEvent: event,
      event: {
        description: event.description,
        title: event.title,
        status: 0,
        locations: event.locations,
        creationDetails: {
          plannerId: null,
          plannerPhoto: null,
          plannerFirstName: null,
          plannerLastName: null,
          plannerUserName: null,
          creationDate: null,
        },
        scheduledTime: {
          date: null,
          timestamp: null,
          timeString: null,
        },
        usersAttending: null,
        eventSettings: {
          itinerarySent: false,
          scheduled: false,
        },
        packageDetails: {
          packageId: null,
        },
      },
    };

    let usersObject = {};
    let user = this.props.newEvent.invitedUser;
    let fullName = user.firstName + ' ' + user.lastName;

    usersObject[user.userId] = {
      access: true,
      approved: true,
      editor: true,
      attending: true,
      rejected: false,
      // inviteAction: null,
      profile: {
        fullName: fullName,
        userName: user.userName,
        profilePhotoPath: user.profilePhotoPath,
      },
    };

    const newEvent = {
      connections: usersObject,
      planFirst: true,
      connectFirst: false,
      clear: false,
      step: 4,
      screen: 1,
      fromVEvent: true,
      vEvent: event,
      weightedLocations: [],
      customEvent,
    };

    this.props.getNewEvent(newEvent);
  }

  listEvents(event, index) {
    const withNoDigits = event.id.replace(/[0-9]/g, '');
    const toggler = '#' + withNoDigits;
    return (
      <Card>
        <Link
          to={{
            pathname: '/vevent/' + event.id,
          }}
          className="left red"
        >
          <CardImg
            top
            width="100%"
            src={
              'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
              event.heroImage +
              '?alt=media'
            }
            alt="articleHero"
          />
        </Link>
        <CardBody>
          <Container>
            <Row>
              <Col
                xs={10}
                md={10}
                sm={10}
                lg={10}
                className="no-padding"
              >
                <Link
                  to={{
                    pathname: '/vevent/' + event.id,
                  }}
                  className="left red"
                >
                  <span className="vendorEventTitle">
                    {event.title}
                  </span>
                </Link>
                <div className="clear">
                  <span className="vendorEventLocation">
                    {event.locations[0].locationTitle}
                  </span>
                </div>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
                {/* <a
                  onClick={() => {
                    this.quickEvent(event);
                  }}
                  className="right red"
                >
                  <img
                    src={redLogo}
                    className="bottomNavLogo right"
                    alt="logo"
                  />
                </a> */}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <div className="wizard-section-ten">
                  <span className="vendorEventTeaser">
                    {event.teaser}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                {this.getPromos(event)}
              </Col>
            </Row>
          </Container>
          {/* <UncontrolledCollapse toggler={toggler}>
            <div className="card-section">{event.teaser}</div>
          </UncontrolledCollapse> */}
        </CardBody>
      </Card>
    );
  }

  getContent(events) {
    if (events.length !== 0) {
      return (
        <div>
          <div className="sectiontitle">
            <h5 className="text-center">Experiences Near</h5>
          </div>
          {events.map((event, index) => (
            <div key={'key' + index}>
              {this.listEvents(event, index)}
            </div>
          ))}
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    if (
      this.props.promoEvents !== undefined &&
      this.props.promoEvents !== null &&
      this.props.promoEvents.length !== 0
    ) {
      return <div>{this.getContent(this.props.promoEvents)}</div>;
    } else {
      return <div></div>;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    resetNewEvent: (newEvent) => {
      dispatch(resetNewEvent(newEvent));
    },
  };
};

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    promoEvents: state.promoEvents.events,
    promos: state.promos.promos,
    newEvent: state.newEvent,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PromotionFeedMulti);
