import * as action from "./actions";

export const updatePackage = packageBundle => {
  return {
    type: action.UPDATE_PACKAGE,
    payload: {
      step: packageBundle.step,
      containsCustom: packageBundle.containsCustom,
      newEventRef: packageBundle.newEventRef,
      started: packageBundle.started,
      package: packageBundle.package
    }
  };
};

export const addPackage = packageBundle => {
  return {
    type: action.ADD_PACKAGE,
    payload: {
      step: packageBundle.step,
      containsCustom: packageBundle.containsCustom,
      newEventRef: packageBundle.newEventRef,
      started: packageBundle.started,
      package: packageBundle.package
    }
  };
};

export const setPackage = packageBundle => {
  return {
    type: action.SET_NEWPACKAGE,
    payload: packageBundle
  };
};

export const savePackage = packageBundle => {
  return {
    type: action.SAVE_PACKAGE,
    payload: packageBundle
  };
};

export const resetPackage = packageBundle => {
  return {
    type: action.RESET_PACKAGE,
    payload: packageBundle
  };
};

const ACTION_HANDLERS = {
  [action.UPDATE_PACKAGE]: (state, action) => {
    return { ...state };
  },
  [action.SET_NEWPACKAGE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SAVE_PACKAGE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.ADD_PACKAGE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.RESET_PACKAGE]: (state, action) => {
    return { ...state, ...action.payload };
  }
};

let defaultState = {
  step: 1,
  containsCustom: false,
  newEventRef: null,
  started: 0,
  package: {
    created: null,
    title: "",
    id: null,
    category: {
      Custom: true
    },
    locations: [],
    description: null,
    heroImage: null,
    thumbnail: null,
    zoneId: null,
    creatorInfo: {
      creatorImage: null,
      creatorName: null,
      creatorId: null
    }
  }
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
