import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
// Components
import UserListItem from '../../../../components/UserListItem';

export class NewUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    // if (this.props.users === undefined || this.props.users.length === 0) {
    // this.props.getUsers();
    // }
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  sortByDate(stuff) {
    stuff.sort(function compare(a, b) {
      let dateA = '';
      let dateB = '';
      if (
        a.created !== undefined &&
        a.created.seconds !== undefined
      ) {
        dateA = new Date(a.created.seconds * 1000);
      }
      if (
        b.created !== undefined &&
        b.created.seconds !== undefined
      ) {
        dateB = new Date(b.created.seconds * 1000);
      }
      return dateB - dateA;
    });
    return stuff;
  }

  render() {
    if (this.state.loading) {
    }
    let sortedByDate = this.sortByDate(this.props.users);

    let topTen = [];
    sortedByDate.forEach((x, i) => {
      if (topTen.length < 11) {
        topTen.push(x);
      }
    });
    const topUsers = this.state.topUsers;
    return (
      <div className="widget">
        <div className="section">
          <span className="widgetHeader">New Users</span>
        </div>
        <div className="section">
          <UserListItem users={topTen} edit={false} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.adminUsers.users,
  };
}

export default connect(mapStateToProps)(NewUsers);
