import React from 'react';
import MaterialIcon from 'material-icons-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import { Col, Row } from 'reactstrap';
import TitleBar from '../../components/TitleBar';

const KEYS_TO_FILTERS = ['title'];
const query = require('array-query');

export class AdminPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getLocationLink(allLocations, id) {
    const locationDetails = query('locationId')
      .is(id)
      .on(allLocations);
    const location = locationDetails[0];
    return (
      <Link
        to={{
          pathname: '/location',
          state: {
            location: { location },
            type: 4,
          },
        }}
      >
        <span>{locationDetails[0].locationTitle}</span>
      </Link>
    );
  }

  getLocations(packageLocations, thisPackage) {
    const locations = packageLocations;
    return (
      <div>
        {locations.map((location, index) => (
          <span>{location.locationName}</span>
        ))}
      </div>
    );
  }

  //   <div>
  //   {this.getLocationLink(
  //     this.props.alllocations,
  //     location.locationId,
  //   )}
  // </div>

  getPackageTitle(title, id) {
    const packageUrl = '/package/' + id;
    return (
      <a href={packageUrl} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    );
  }

  getPackages(packages) {
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={6} md={6} sm={6} lg={6} className="left">
                  <span className="label">Package Name</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label"># Locations</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Creation Info</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Actions</span>
                </Col>
              </div>
            </li>
            {packages.map((thisPackage, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={6}
                    key={'key' + index}
                  >
                    {this.getPackageTitle(
                      thisPackage.title,
                      thisPackage.packageId,
                    )}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {this.getLocations(
                      thisPackage.locations,
                      thisPackage,
                    )}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {thisPackage.creatorInfo.creatorName}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {this.getEdit(thisPackage.packageId)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  getEdit(id) {
    const url = '/admin/package/edit/' + id;
    return (
      <div>
        <Link
          to={{
            pathname: url,
            state: {},
          }}
          className="right"
        >
          <MaterialIcon icon="edit" size={25} color="#f21d59" />
        </Link>
      </div>
    );
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/home',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Manage Itineraries
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              <div className="right">
                <Link
                  to={{
                    pathname: '/admin/event/create/itinerary',
                  }}
                >
                  New
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
    }
    const filteredPackages = this.props.packages.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="admin">
        {this.getTitleBar()}
        <div className="section">
          <div className="header-container">
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
          </div>
        </div>
        <div>{this.getPackages(filteredPackages)}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    alllocations: state.locations.locations,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminPackages);
