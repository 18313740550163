import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const query = require('array-query');

class GuideEventsCategory extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {}

  findPromotedLocations(timedEvents, category, tagCat) {
    let setTag = undefined;

    // let tagsDateType = {
    //   parent: 'Date Type',
    //   sid: 'dateype',
    //   tags: [],
    // };

    // Combine Tags

    function getPromoted(locations) {
      let promotedLocations = [];
      let promotedLocationIds = [];

      if (locations !== undefined) {
        locations.forEach((location) => {
          if (tagCat === 'tagsDateType') {
            if (
              location.tagsDateType !== undefined &&
              location.tagsDateType.length !== 0
            ) {
              location.tagsDateType.forEach((tagx) => {
                if (tagx === category) {
                  const status = promotedLocationIds.includes(
                    location.locationId,
                  );
                  if (status === false) {
                    promotedLocationIds.push(location.locationId);
                    promotedLocations.push(location);
                  }
                }
              });
            }
          } else if (category === 'Music') {
            if (
              location.tagsEventType !== undefined &&
              location.tagsEventType.length !== 0
            ) {
              location.tagsEventType.forEach((tagx) => {
                if (tagx === category) {
                  const status = promotedLocationIds.includes(
                    location.eventId,
                  );
                  if (
                    status === false &&
                    location.eventId !== '7X90qrzIvTYK9RpXFUzK'
                  ) {
                    var todayDate = moment(new Date()).format(
                      'MM/DD/YYYY',
                    );
                    var pastDate = moment(
                      location.scheduledTime.dates.startDate,
                    );
                    var dDiff = pastDate.diff(todayDate);
                    if (dDiff > 0 && dDiff !== 0) {
                      promotedLocationIds.push(location.eventId);
                      promotedLocations.push(location);
                    } else if (dDiff === 0) {
                      promotedLocationIds.push(location.eventId);
                      promotedLocations.push(location);
                    } else {
                    }
                  }
                }
              });
            }
          } else {
            var todayDate = moment(new Date()).format('MM/DD/YYYY');
            var pastDate = moment(
              location.scheduledTime.dates.startDate,
            );
            var dDiff = pastDate.diff(todayDate);
            if (dDiff > 0 && dDiff !== 0) {
              promotedLocations.push(location);
            } else if (dDiff === 0) {
              promotedLocations.push(location);
            } else {
            }
          }
        });
      }

      return promotedLocations;
    }

    let promotedLocations = [];
    let i = 0;

    while (promotedLocations.length === 0 && i !== 10) {
      promotedLocations = getPromoted(timedEvents);
      i++;
    }

    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
    let shuffledLocations = shuffleArray(promotedLocations);
    let topThree = [];
    let topThreeIds = [];
    shuffledLocations.forEach((location) => {
      if (topThree.length !== 10) {
        const status = topThreeIds.includes(location.eventId);
        if (status === false) {
          topThreeIds.push(location.eventId);
          topThree.push(location);
        }
      }
    });
    let seeMore = false;
    if (shuffledLocations.length > 0) {
      seeMore = true;
    }
    let bundle = {
      promotedLocations: topThree,
      setTag,
      seeMore,
    };

    return bundle;
  }

  getTagCount(userLove) {
    let userTags = [];
    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };
    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };
    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };
    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };
    let allTags = {
      tagsBeverages: [],
      tagsDateType: [],
      tagsSetting: [],
      tagsCuisine: [],
    };
    // Combine Tags

    userLove.tagsBeverages.forEach((tagcat, x) => {
      let n = allTags.tagsBeverages.includes(tagcat);
      if (n === false) {
        allTags.tagsBeverages.push(tagcat);
      }
    });

    userLove.tagsDateType.forEach((tagcat, x) => {
      let n = allTags.tagsDateType.includes(tagcat);
      if (n === false) {
        allTags.tagsDateType.push(tagcat);
      }
    });

    userLove.tagsSetting.forEach((tagcat, x) => {
      let n = allTags.tagsSetting.includes(tagcat);
      if (n === false) {
        allTags.tagsSetting.push(tagcat);
      }
    });

    userLove.tagsCuisine.forEach((tagcat, x) => {
      let n = allTags.tagsCuisine.includes(tagcat);
      if (n === false) {
        allTags.tagsCuisine.push(tagcat);
      }
    });

    let tagCount =
      allTags.tagsCuisine.length +
      allTags.tagsSetting.length +
      allTags.tagsDateType.length +
      allTags.tagsBeverages.length;
    allTags.tagsSetting;

    return tagCount;
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.eventHero;
      } else {
        if (
          location !== undefined &&
          location.eventHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.eventHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.eventThumb;
      } else {
        if (
          location !== undefined &&
          location.eventThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.eventThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    return to;
  }

  getSingle(promotedLocations) {
    let location = promotedLocations[0];
    if (promotedLocations.length === 1) {
      return (
        <div>
          <Link
            to={{
              pathname: '/timedevent/' + location.eventId,
              state: {
                location: { location },
                // eventId: { eventId },
                // token: { token },
                type: 500,
              },
            }}
          >
            <div>
              <img
                src={this.getImage('hero', location)}
                style={{ width: '100%' }}
              />
              <div className="section">
                <span
                  className="title red"
                  style={{ fontSize: '20px' }}
                >
                  {location.eventTitle}
                </span>

                <span className="title clear">
                  {this.getHeadliners(location)} @{' '}
                  {location.address.locationName}
                </span>
                <span className="address">
                  {this.getDate(location)}
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: location.eventDescription,
                  }}
                  className="editoOverRide"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getHeadliners(event) {
    let headliners = '';
    event.entertainment.headliners.forEach((entertainment, index) => {
      headliners = headliners + entertainment.entertainmentTitle;
      if (index + 1 !== event.entertainment.headliners.length) {
        headliners = headliners + ' & ';
      }
    });
    return headliners;
  }

  getSlideSections(promotedLocations, count) {
    let sections = [];
    let sectionOne = [];
    let sectionTwo = [];
    let sectionThree = [];
    let sectionFour = [];
    let sectionFive = [];
    let sectionSix = [];
    let sectionSeven = [];
    let sectionEight = [];
    let sectionNine = [];
    let sectionTen = [];

    if (count === 4) {
      promotedLocations.forEach((location, index) => {
        if (index < 3 || index === 3) {
          sectionOne.push(location);
        }
        if (index > 3 && index < 8) {
          sectionTwo.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
    }

    if (count === 3) {
      promotedLocations.forEach((location, index) => {
        if (index < 2 || index === 2) {
          sectionOne.push(location);
        }
        if (index > 2 && index < 6) {
          sectionTwo.push(location);
        }
        if (index > 6 && index < 9) {
          sectionThree.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
    }

    if (count === 2) {
      promotedLocations.forEach((location, index) => {
        if (index === 0) {
          sectionOne.push(location);
        }
        if (index === 1) {
          sectionTwo.push(location);
        }
        if (index === 2) {
          sectionThree.push(location);
        }
        if (index === 3) {
          sectionFour.push(location);
        }
        if (index === 4) {
          sectionFive.push(location);
        }
        if (index === 5) {
          sectionSix.push(location);
        }
        if (index === 6) {
          sectionSeven.push(location);
        }
        if (index === 7) {
          sectionEight.push(location);
        }
        if (index === 8) {
          sectionNine.push(location);
        }
        if (index === 9) {
          sectionTen.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
      if (sectionFour.length !== 0) {
        sections.push(sectionFour);
      }
      if (sectionFive.length !== 0) {
        sections.push(sectionFive);
      }
      if (sectionSix.length !== 0) {
        sections.push(sectionSix);
      }
      if (sectionEight.length !== 0) {
        sections.push(sectionEight);
      }
      if (sectionSeven.length !== 0) {
        sections.push(sectionSeven);
      }
      if (sectionNine.length !== 0) {
        sections.push(sectionNine);
      }
      if (sectionTen.length !== 0) {
        sections.push(sectionTen);
      }
    }

    return sections.map((section, index) => {
      return (
        <div style={{ height: '100%' }}>
          {section.map((location, index) => (
            <div
              style={{
                border: '0px solid rgba(0, 0, 0, 0.125)',
                background: 'white !important',
              }}
              className={count === 2 ? 'singleSlide' : 'multiSlide'}
            >
              <Link
                to={{
                  pathname: '/timedevent/' + location.eventId,
                  state: {
                    location: { location },
                    // eventId: { eventId },
                    // token: { token },
                    type: 500,
                  },
                }}
              >
                <CardImg
                  top
                  width="100%"
                  src={this.getImage('hero', location)}
                  alt="articleHero"
                />
                <CardBody style={{ textAlign: 'left' }}>
                  <div style={{ paddingTop: '10px', border: '0px' }}>
                    <span className="title">
                      {location.eventTitle}
                    </span>
                    <span className="address">
                      {this.getHeadliners(location)} - @{' '}
                      {location.address.locationName}
                    </span>

                    <span className="address">
                      {this.getDate(location)}
                    </span>
                    <div
                      style={{
                        display: 'inline-block',
                        clear: 'both',
                        padding: '5px 0px 5px 0px',
                      }}
                    >
                      {this.getLocal(location)}
                      {location.tagsGenre.map((location, index) =>
                        this.getMainTag(location),
                      )}
                    </div>
                    {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                    <CardText>
                      <span className="editoOverRide">
                        {this.truncate(location.eventDescription)}
                      </span>
                    </CardText>
                    <CardText />
                  </div>
                </CardBody>
              </Link>
            </div>
          ))}
        </div>
      );
    });
  }

  getMainTag(tag) {
    return <span className="mainTagLeft">{tag}</span>;
  }

  getLocal(event) {
    let local = false;
    event.entertainment.entertainmentIds.forEach(
      (entertainmentId, index) => {
        const entertainmentDetails = query('id')
          .is(entertainmentId)
          .on(this.props.entertainment);
        let entertainer = entertainmentDetails[0];
        if (
          entertainer !== undefined &&
          entertainer.settings.local !== undefined &&
          entertainer.settings.local === true
        ) {
          local = true;
        }
      },
    );
    if (local === true) {
      return <span className="mainTagLeft local">Local</span>;
    }
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  render() {
    const bundle = this.findPromotedLocations(
      this.props.timedEvents,
      this.props.category,
      this.props.tagCat,
    );
    let promotedLocations = bundle.promotedLocations;

    let slug = this.props.category.replace(/\s/g, '-');

    let count = this.props.count;

    if (promotedLocations.length > 0) {
      return (
        // <div className="homeSection" id="homeSectionGrey">
        <div className="homeSection">
          <div
          // style={{ height: count > 2 ? '40px' : '80px' }}
          >
            <Row className="no-margin">
              <Col
                xs={12}
                sm={12}
                md={10}
                lg={10}
                className="no-padding"
              >
                <div className="sectiontitle text-center">
                  <span
                    className="homeCat "
                    style={{
                      float: count > 2 ? 'left' : 'left',
                      marginBottom: count > 2 ? '10px' : '',
                      textAlign: count > 2 ? 'left' : 'left',
                    }}
                  >
                    {this.props.catTitle}
                  </span>
                </div>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                className="no-padding"
              >
                {bundle.seeMore === true && (
                  <div
                    style={{
                      zindex: '99999',
                      textAlign: count > 2 ? 'left' : 'left',
                    }}
                  >
                    <Link
                      to={{
                        pathname: '/guide/category/' + slug,
                      }}
                      className={count > 2 ? 'right' : 'seeMoreLeft'}
                    >
                      {bundle.seeMore === true && 'See More'}
                    </Link>
                  </div>
                )}
              </Col>
            </Row>
          </div>

          {promotedLocations.length > 1 && (
            <Carousel
              showThumbs={false}
              // centerMode={true}
              // centerSlidePercentage={80}
              // selectedItem={this.getCount(promotedLocations)}
              showIndicators={false}
              showStatus={false}
              // showArrows={true}
              infiniteLoop={false}
              centerMode={count === 2 ? true : false}
              swipeScrollTolerance={50}
              preventMovementUntilSwipeScrollTolerance={true}
            >
              {this.getSlideSections(promotedLocations, count)}
            </Carousel>
          )}
          {this.getSingle(promotedLocations)}
        </div>
      );
    }
    return null; // or render a loading icon
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    entertainment: state.entertainment.entertainment,
  };
}

export default connect(mapStateToProps)(GuideEventsCategory);
