import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
// Components
import EventListItem from '../../components/EventListItem';
import TitleBar from '../../components/TitleBar';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';
import { resetCe } from '../../sagas/customEventSaga/reducer';
import {
  getEvents,
  refreshEvents,
} from '../../sagas/userEventsSaga/reducer';

const query = require('array-query');

export class EventsPlanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    // this.props.getEvents(this.props.user.id);
    // if (this.props.events.length !== 0) {
    //   this.setEvents(this.props.events);
    // } else {
    //   this.setState({
    //     loading: false
    //   });
    // }
    if (this.props.customEvent.clear === false) {
      const create = false;
      this.props.resetCe(create);
    }
  }

  componentDidMount() {
    // if (this.props.alllocations.length === 0) {
    //   this.props.getAllLocations(1);
    // } else {
    // this.props.getAllLocations(1);
    // }
    // Get Events In RealTime
    // const table = `users.${this.props.user.id}.access`;
    // const userEvents = firebase
    //   .firestore()
    //   .collection("user_events")
    //   .where(table, "==", true);
    // userEvents.onSnapshot(this.eventUpdate);
  }

  // eventUpdate = querySnapshot => {
  //   const eventsObject = {};
  //   eventsObject.events = [];

  //   querySnapshot.forEach(doc => {
  //     const {
  //       creationDetails,
  //       eventSettings,
  //       packageDetails,
  //       usersAttending,
  //       status,
  //       title,
  //       description,
  //       scheduledTime,
  //       heroImage,
  //       thumbnail,
  //       planners,
  //       editHistory,
  //       users,
  //       invitations
  //     } = doc.data();
  //     eventsObject.events.push({
  //       eventId: doc.id,
  //       creationDetails,
  //       eventSettings,
  //       packageDetails,
  //       usersAttending,
  //       status,
  //       title,
  //       description,
  //       scheduledTime,
  //       heroImage,
  //       thumbnail,
  //       planners,
  //       editHistory,
  //       users,
  //       invitations
  //     });
  //   });

  //   this.props.refreshEvents(eventsObject);

  //   this.setEvents(eventsObject.events);
  // };

  setEvents(events, userId) {
    const planned = [];
    const unplanned = [];
    let setEvents = {};

    if (events !== undefined) {
      events.forEach((event) => {
        if (
          event.users[userId].finished !== true &&
          event.status !== 9
        ) {
          if (event.status === 0) {
            planned.push(event);
          } else if (
            event.users[this.props.user.id].editor === true
          ) {
            unplanned.push(event);
          }
        }
      });
      let plannedCount = planned.length;
      let unplannedCount = unplanned.length;
      const activeCountArray = [];
      const activeInvitationsArray = [];
      events.forEach((event) => {
        if (event.users[this.props.user.id].inviteAction === false) {
          activeInvitationsArray.push(
            event.invitations[this.props.user.id],
          );
        }
      });
      activeInvitationsArray.forEach((event) => {
        if (event.status === 0 || event.status === 9) {
          activeCountArray.push(event);
        }
      });
      const activeCount = activeCountArray.length;

      setEvents = {
        planned: planned,
        unplanned: unplanned,
        plannedCount,
        unplannedCount,
        inviteCount: activeCount,
      };

      return setEvents;
    }
  }

  changeMode(mode) {
    this.setState({
      mode,
    });
  }

  getFooter() {
    return (
      <Container>
        <Row className="no-margin">
          <Col
            xs={12}
            md={12}
            sm={12}
            lg={12}
            className="no-padding text-center"
          >
            <Link
              to={{
                pathname: '/events/home',
              }}
            >
              <span className="footer-link-withborder">
                Upcoming Experiences
              </span>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  getContent(setEvents) {
    const unplanned = setEvents.unplanned;
    if (setEvents.unplannedCount === 0) {
      return (
        <Container className="pushfifty">
          <Row className="text-center">
            <span>You are not planning any new Experiences.</span>
            <div
              style={{
                padding: '15px 10px 10px 10px',
                border: '1px solid lightgrey',
                margin: '0px auto',
                width: '100%',
                marginTop: '100px',
                background: 'white',
                display: 'flex',
                textAlign: 'left',
              }}
            >
              <Col xs={2} md={1} sm={2} lg={1}>
                <div style={{ float: 'left' }}>
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="rgb(238 1 88)"
                  />
                </div>
              </Col>
              <Col xs={10} md={11} sm={10} lg={11}>
                <h4
                  style={{ fontSize: '18px', marginBottom: '10px' }}
                >
                  Your Experiences - Planning
                </h4>
                <p
                  style={{
                    margin: '0px',
                    padding: '0px 0px 10px 0px',
                  }}
                >
                  Here you will find all of your Experiences that have
                  not yet been approved by everyone attending or
                  finalized by the creator.
                </p>
              </Col>
            </div>
          </Row>
        </Container>
      );
    } else {
      return (
        <div>
          <div id="eventList">
            <EventListItem events={unplanned} mode="0" />
          </div>
        </div>
      );
    }
  }

  getActionButton(mode, title, count) {
    if (this.state.mode === mode) {
      return (
        <div
          className="text-center actionButton actionButtonActive"
          onClick={() => {
            this.changeMode(mode);
          }}
        >
          {title}
          <div className="counterGrey">
            <span>{count}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="text-center  actionButton actionButtonInactive"
          onClick={() => {
            this.changeMode(mode);
          }}
        >
          {title}
          <div className="counterGrey">
            <span>{count}</span>
          </div>
        </div>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/home',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Your Experiences</span>
              </center>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="right">
                {/* <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      this.props.customEvent,
                    );
                  }}
                /> */}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    let setEvents = this.setEvents(
      this.props.events,
      this.props.user.id,
    );
    return (
      <div id="events">
        {/* <TitleBar title="Social Calendar" backlink="" /> */}
        {this.getTitleBar()}
        <div
          className="adminActionBar"
          style={{ top: '40px !important', width: '100%' }}
        >
          <Row>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <Link
                to={{
                  pathname: '/events/upcoming',
                }}
              >
                <div className="text-center borderRight actionButton actionButtonInactive">
                  Upcoming
                  <div className="counterGrey">
                    <span>{setEvents.plannedCount}</span>
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <div className="text-center borderRight actionButtonActive actionButton">
                Planning
                <div className="counterGrey">
                  <span>{setEvents.unplannedCount}</span>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <Link
                to={{
                  pathname: '/events/invitations',
                }}
              >
                <div className="text-center borderRight actionButtonInactive">
                  Invitations
                  <div className="counterGrey">
                    <span>{setEvents.inviteCount}</span>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>

        <div className="actionBarPush">
          {this.getContent(setEvents)}
        </div>

        <div className="footer">{this.getFooter()}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#startBar{background-color: #ef0059;} .adminActionBar {top: 40px !important;} #events{position: relative;}  #headersTop {width: 100% !important;} #headersTopSecondary{width: 100%} #authright {padding-left: 0px !important;}  #mobile-nav { display: none } #authleft {display:none;} #events{position: relative; top: 40px}  #bottomNav{left:0px; width:100%} .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    events: state.events.events,
    alllocations: state.locations.locations,
    user: state.user,
    customEvent: state.customEvent.customEvent,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
    getEvents: (userId) => {
      dispatch(getEvents(userId));
    },
    refreshEvents: (events) => {
      dispatch(refreshEvents(events));
    },
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventsPlanning);
