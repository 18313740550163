import * as action from "./actions";

export const authhydrateApp = user => {
  return {
    type: action.AUTHHYDRATE_APP,
    payload: user
  };
};

const ACTION_HANDLERS = {
  [action.AUTHHYDRATE_APP]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.AUTHHYDRATE_SUCCESS]: (state, action) => {
    return { ...state, ...action.payload };
  }
};

let defaultState = { hydrated: false };

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
