import geolib from 'geolib';
import MaterialIcon from 'material-icons-react';
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import { Col, Container, Row } from 'reactstrap';
import FilterModal from '../components/FilterModal';
// Components
import PackageListItem from '../components/PackageListItem';
import LocationItineraryDetails from '../components/LocationItineraryDetails';
// Styles
import '../components/PackageListItem/PackageListItem.css';
import firebase from '../config/firebase';
import { getAllLocations } from '../sagas/allLocationsSaga/reducer';
// Reducers
import { init } from '../sagas/packagesSaga/reducer';

const KEYS_TO_FILTERS = ['title'];
const query = require('array-query');
const _ = require('lodash');

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    width: '300px',
  },
};

export class Packages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      filterModalVisible: false,
      filteredArray: null,
      distanceFilterState: 0,
      modalState: 1,
      tagFilters: [],
      packagesReady: [],
      packagesWithDistance: [],
      otherUserTagsHate: [],
      otherUserTagsLove: [],
      showLocation: false,
      locationServices: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.setTagFilters = this.setTagFilters.bind(this);
    this.showLocationDetails = this.showLocationDetails.bind(this);
    this.hideLocationDetails = this.hideLocationDetails.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.title = 'Plan Event';
    if (this.props.newEvent.planFirst !== true) {
      this.getUserTags(
        this.props.newEvent,
        this.props.users,
        this.props.profile.userId,
      );
    }
    this.getGeoLocation();
  }

  componentDidMount() {
    this.filterCustom(this.props.packages);
    // Update Other User Tags
    let connections = this.props.newEvent.connections;
    let userId = this.props.profile.userId;
    let otherUserId = '';
    let userMatch = [];
    Object.keys(connections).forEach((key) => {
      if (key !== userId) {
        if (connections[key].newUser !== true) {
          const user = query('userId').is(key).on(this.props.users);
          otherUserId = key;
          userMatch = user[0];
        }
      }
    });
    this.setState({
      otherUser: userMatch,
    });
    if (
      this.props.newEvent.planFirst !== true &&
      this.props.newEvent.invitedUser !== undefined &&
      this.props.newEvent.invitedUser.newUser !== true &&
      otherUserId !== ''
    ) {
      const db = firebase.firestore();
      const userEvents = db.collection('users').doc(otherUserId);
      userEvents.onSnapshot(this.updateOtherUser);
    } else {
      // this.searchUpdated("");
    }
  }

  updateOtherUser = (documentSnapshot) => {
    const eventsObject = {};
    eventsObject.events = [];
    const user = documentSnapshot.data();
    const tags = user.tags;

    if (tags !== null) {
      this.setState({
        otherUserTagsLove: tags.love,
        otherUserTagsHate: tags.hate,
      });
    } else {
      this.setState({
        otherUserTagsLove: undefined,
        otherUserTagsHate: undefined,
      });
    }
    // this.searchUpdated("");
  };

  getUserTags(newEvent, users, userId) {
    let connections = newEvent.connections;
    let userTags = null;
    let userMatch = [];
    Object.keys(connections).forEach((key) => {
      if (key !== userId) {
        if (connections[key].connected !== false) {
          if (connections[key].newUser !== true) {
            const user = query('userId').is(key).on(users);
            userTags = user[0].tags;
            userMatch = user[0];
          }
        }
      }
    });

    if (userTags !== null && userTags !== undefined) {
      this.setState({
        otherUserTagsLove: userTags.love,
        otherUserTagsHate: userTags.hate,
        otherUser: userMatch,
      });
    } else {
      this.setState({
        otherUser: userMatch,
      });
    }
  }

  openFilterModal(user) {
    this.setState({ filterModalVisible: true });
  }

  closeFilterModal = () => {
    this.searchUpdated('', this.state.packagesReady);
    window.scrollTo(0, 0);
    this.setState({ filterModalVisible: false });
  };

  setTagFilters(tagFilters) {
    this.setState({ tagFilters });
  }

  filterCustom(packages) {
    let packageArrayX = [];
    if (this.props.customEvent.event.locations.length !== 0) {
      this.props.customEvent.event.locations.map((location, y) => {
        let locationId = location.locationId;
        packages.map((packagex, y) => {
          packagex.locations.forEach((locationx, x) => {
            if (locationx.locationId === locationId) {
              packageArrayX.push(packagex);
            }
          });
        });
      });
    } else {
      packageArrayX = packages;
    }

    const packageArray = [];
    packageArrayX.forEach((packagex) => {
      // packages.forEach((packagex) => {
      if (
        packagex.category !== undefined &&
        packagex.category.Custom !== undefined &&
        packagex.category.Custom === true
      ) {
      } else {
        packageArray.push(packagex);
      }
    });

    let weightedPackages = this.getweightPackages(
      packageArray,
      this.props.profile,
    );
    this.setState({
      packagesReady: weightedPackages,
      loading: false,
    });
    this.searchUpdated('', weightedPackages);
  }

  getWeightedPackages(packages, user) {
    let otherUserTagsLove = this.state.otherUserTagsLove;
    let otherUserTagsHate = this.state.otherUserTagsHate;
    if (user.tags !== undefined) {
      let userTagsLove = user.tags.love;
      let userTagsHate = user.tags.hate;
      const weightedPackages = this.weightPackages(
        packages,
        userTagsLove,
        userTagsHate,
        otherUserTagsLove,
        otherUserTagsHate,
      );
      return weightedPackages;
    }
  }

  sortAndWeight(
    packages,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    const sortedPackages = this.sortPackages(
      packages,
      distanceFilterState,
      currentLongitude,
      currentLatitude,
      sortState,
    );
    const weightedLocations = this.getWeightedPackages(
      sortedPackages,
      this.props.profile,
    );
    return weightedLocations;
  }

  searchUpdated(term, packages) {
    // let searchedPackages = [];
    // searchedPackages = this.state.packagesWithDistance.filter(
    //   createFilter(term, KEYS_TO_FILTERS)
    // );

    const sortedPackages = this.sortAndWeight(
      packages,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );

    let selectedTags = [];
    let filteredPackages = [];

    if (this.state.tagFilters !== null) {
      this.state.tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.checked === true) {
            selectedTags.push(tag.tag);
          }
        });
      });
    }

    if (selectedTags.length > 0) {
      filteredPackages = this.updatePackages(
        this.props.tags,
        // sortedLocations
        sortedPackages,
      );
    } else {
      filteredPackages = sortedPackages;
    }
    this.setState({
      filteredArray: filteredPackages,
    });
  }

  getGeoLocation = () => {
    let self = this;
    let currentLongitude = '';
    let currentLatitude = '';
    this.getPosition()
      .then((position) => {
        self.setState({
          currentLongitude: position.coords.longitude,
          currentLatitude: position.coords.latitude,
          error: null,
        });
        currentLatitude = position.coords.latitude;
        currentLongitude = position.coords.longitude;
        this.addDistance(
          this.state.packagesReady,
          currentLongitude,
          currentLatitude,
        );
        // this.searchUpdated("");
      })
      .catch((err) => {
        console.error('something wrongg', err.message);
        self.setState({
          locationServices: false,
        });
      });
  };

  getPosition() {
    let options = {
      timeout: 5000,
      maximumAge: 10000,
      enableHighAccuracy: true,
    };
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          options,
        ),
      );
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }

  addDistance(packages, currentLongitude, currentLatitude) {
    let updatedPackages = [];

    packages.forEach((thispackage) => {
      let locationsArray = [];
      thispackage.locations.forEach((location) => {
        const locLat = location.address.latitude;
        const locLong = location.address.longitude;
        const distance = geolib.getDistance(
          { latitude: locLong, longitude: locLat },
          { latitude: currentLatitude, longitude: currentLongitude },
        );
        // console.log("geolibtard", distance);
        const miles = geolib.convertUnit('mi', distance, 2); // -> 14,21
        location.address.distance = miles;

        locationsArray.push(location);
      });
      thispackage.locations = locationsArray;
      updatedPackages.push(thispackage);
    });
    // const sortedLocations = this.sortAndWeight(
    //   locations,
    //   this.state.distanceFilterState,
    //   this.state.currentLongitude,
    //   this.state.currentLatitude,
    //   this.state.sortState
    // );
    this.setState({
      packagesWithDistance: updatedPackages,
    });
  }

  sortPackages(
    packages,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    const packagesArray = [];
    packages.forEach((thispackage) => {
      let match = false;
      thispackage.locations.forEach((location) => {
        let miles = 0;
        if (location.address !== undefined) {
          miles = location.address.distance;
          if (location.type !== 1) {
            if (distanceFilterState === 0) {
              if (miles <= 50) {
                if (match === false) {
                  packagesArray.push(thispackage);
                  match = true;
                }
              }
            } else if (distanceFilterState === 1) {
              if (miles <= 10) {
                if (match === false) {
                  packagesArray.push(thispackage);
                  match = true;
                }
              }
            } else if (distanceFilterState === 2) {
              if (match === false) {
                packagesArray.push(thispackage);
                match = true;
              }
            }
          }
        }
      });
    });
    // return _.sortBy(packagesArray, o => o.address.distance);
    // return packagesArray;
    return packages;
  }

  getKey() {
    if (this.state.otherUser.length !== 0) {
      return (
        <p>
          <b>Tag Colors: </b>
          <span className="tag-user">
            {/* {this.props.profile.firstName} */}
            You
          </span>
          <span className="tag-otherUser">
            {this.state.otherUser.firstName}
          </span>
          <span className="tag-match">Match!</span>
        </p>
      );
    } else {
      return (
        <p>
          <b>Tag Colors: </b>
          <span className="tag-user">You</span>
        </p>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <Link
                to={{
                  pathname: "/event/create/home/1",
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link> */}
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Trevel Agent</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/event/create/home/1',
                }}
                className="right"
              >
                <MaterialIcon
                  icon="keyboard_arrow_down"
                  size={25}
                  color="white"
                />
              </Link>
              {/* <Link
                to={{
                  pathname: "/",
                }}
                className="right"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  weightPackages(
    packages,
    userTagsLove,
    userTagsHate,
    otherUserTagsLove,
    otherUserTagsHate,
  ) {
    const weightedPackages = [];

    packages.forEach((thispackage, i) => {
      const weightedLocations = [];
      const updatedPackage = thispackage;
      let packageWeight = 0;
      thispackage.locations.forEach((locationx, x) => {
        const locationDetails = query('locationId')
          .is(locationx.locationId)
          .on(this.props.alllocations);

        let location = locationDetails[0];
        let weight = 0;
        if (location !== undefined) {
          let weightedLocation = location;

          weightedLocation.matchesOtherUserLove = [];
          weightedLocation.matchesOtherUserHate = [];
          weightedLocation.matchesUserLove = [];
          weightedLocation.matchesUserHate = [];
          weightedLocation.weight = weight;

          // Other User Love

          if (
            otherUserTagsLove.length !== 0 &&
            otherUserTagsLove !== null
          ) {
            if (location.tagsBeverages !== undefined) {
              location.tagsBeverages.forEach((userTag) => {
                otherUserTagsLove.tagsBeverages.forEach((tag) => {
                  if (userTag === tag) {
                    weightedLocation.matchesOtherUserLove.push(tag);
                    weight = weight + 5;
                  }
                });
              });
            }

            if (location.tagsCuisine !== undefined) {
              location.tagsCuisine.forEach((userTag) => {
                otherUserTagsLove.tagsCuisine.forEach((tag) => {
                  if (userTag === tag) {
                    weightedLocation.matchesOtherUserLove.push(tag);
                    weight = weight + 5;
                  }
                });
              });
            }

            if (location.tagsDateType !== undefined) {
              location.tagsDateType.forEach((userTag) => {
                otherUserTagsLove.tagsDateType.forEach((tag) => {
                  if (userTag === tag) {
                    weightedLocation.matchesOtherUserLove.push(tag);
                    weight = weight + 5;
                  }
                });
              });
            }

            if (location.tagsSetting !== undefined) {
              location.tagsSetting.forEach((userTag) => {
                otherUserTagsLove.tagsSetting.forEach((tag) => {
                  if (userTag === tag) {
                    weightedLocation.matchesOtherUserLove.push(tag);
                    weight = weight + 1;
                  }
                });
              });
            }
          }

          // Other User Hate
          if (
            otherUserTagsHate.length !== 0 &&
            otherUserTagsHate !== null
          ) {
            if (location.tagsBeverages !== undefined) {
              location.tagsBeverages.forEach((userTag) => {
                otherUserTagsHate.tagsBeverages.forEach((tag) => {
                  if (userTag === tag) {
                    weightedLocation.matchesOtherUserHate.push(tag);
                    weight = weight - 5;
                  }
                });
              });
            }

            if (location.tagsCuisine !== undefined) {
              location.tagsCuisine.forEach((userTag) => {
                otherUserTagsHate.tagsCuisine.forEach((tag) => {
                  if (userTag === tag) {
                    weightedLocation.matchesOtherUserHate.push(tag);
                    weight = weight - 5;
                  }
                });
              });
            }

            if (location.tagsDateType !== undefined) {
              location.tagsDateType.forEach((userTag) => {
                otherUserTagsHate.tagsDateType.forEach((tag) => {
                  if (userTag === tag) {
                    weightedLocation.matchesOtherUserHate.push(tag);
                    weight = weight - 5;
                  }
                });
              });
            }

            if (location.tagsSetting !== undefined) {
              location.tagsSetting.forEach((userTag) => {
                otherUserTagsHate.tagsSetting.forEach((tag) => {
                  if (userTag === tag) {
                    weightedLocation.matchesOtherUserHate.push(tag);
                    weight = weight - 5;
                  }
                });
              });
            }
          }

          // User Love

          if (location.tagsBeverages !== undefined) {
            location.tagsBeverages.forEach((userTag) => {
              userTagsLove.tagsBeverages.forEach((tag) => {
                if (userTag === tag) {
                  weightedLocation.matchesUserLove.push(tag);
                  weight = weight + 5;
                }
              });
            });
          }

          if (location.tagsCuisine !== undefined) {
            location.tagsCuisine.forEach((userTag) => {
              userTagsLove.tagsCuisine.forEach((tag) => {
                if (userTag === tag) {
                  weightedLocation.matchesUserLove.push(tag);
                  weight = weight + 5;
                }
              });
            });
          }

          if (location.tagsDateType !== undefined) {
            location.tagsDateType.forEach((userTag) => {
              userTagsLove.tagsDateType.forEach((tag) => {
                if (userTag === tag) {
                  weightedLocation.matchesUserLove.push(tag);
                  weight = weight + 5;
                }
              });
            });
          }

          if (location.tagsSetting !== undefined) {
            location.tagsSetting.forEach((userTag) => {
              userTagsLove.tagsSetting.forEach((tag) => {
                if (userTag === tag) {
                  weightedLocation.matchesUserLove.push(tag);
                  weight = weight + 1;
                }
              });
            });
          }

          // User Hate

          if (location.tagsBeverages !== undefined) {
            location.tagsBeverages.forEach((userTag) => {
              userTagsHate.tagsBeverages.forEach((tag) => {
                if (userTag === tag) {
                  weightedLocation.matchesUserHate.push(tag);
                  weight = weight - 5;
                }
              });
            });
          }

          if (location.tagsCuisine !== undefined) {
            location.tagsCuisine.forEach((userTag) => {
              userTagsHate.tagsCuisine.forEach((tag) => {
                if (userTag === tag) {
                  weightedLocation.matchesUserHate.push(tag);
                  weight = weight - 5;
                }
              });
            });
          }

          if (location.tagsDateType !== undefined) {
            location.tagsDateType.forEach((userTag) => {
              userTagsHate.tagsDateType.forEach((tag) => {
                if (userTag === tag) {
                  weightedLocation.matchesUserHate.push(tag);
                  weight = weight - 5;
                }
              });
            });
          }

          if (location.tagsSetting !== undefined) {
            location.tagsSetting.forEach((userTag) => {
              userTagsHate.tagsSetting.forEach((tag) => {
                if (userTag === tag) {
                  weightedLocation.matchesUserHate.push(tag);
                  weight = weight - 5;
                }
              });
            });
          }

          weightedLocation.weight = weight;
          packageWeight = packageWeight + weight;
          weightedLocations.push(weightedLocation);
        } else {
          packageWeight = packageWeight + weight;
          // weightedLocations.push(locationx);
        }
      });
      updatedPackage.locations = weightedLocations;
      updatedPackage.weight = packageWeight;
      weightedPackages[i] = updatedPackage;
    });

    return _.orderBy(weightedPackages, ['weight'], ['desc', 'asc']);
  }

  getweightPackages(packages, user) {
    let otherUserTagsLove = this.state.otherUserTagsLove;
    let otherUserTagsHate = this.state.otherUserTagsHate;
    if (user.tags !== undefined) {
      let userTagsLove = user.tags.love;
      let userTagsHate = user.tags.hate;
      const weightedPackages = this.weightPackages(
        packages,
        userTagsLove,
        userTagsHate,
        otherUserTagsLove,
        otherUserTagsHate,
      );
      return weightedPackages;
    }
  }

  updatePackages(tags, packages) {
    let updatedPackages = packages;
    // This will have every location but with its tags put into a single list
    let packagesReady = [];
    // For each location we push all of its tags into the allTags array
    packages.forEach((thispackage, i) => {
      const locationsReady = [];
      thispackage.locations.forEach((location) => {
        const allTags = [];
        if (location.tagsBeverages !== undefined) {
          location.tagsBeverages.forEach((tag) => {
            allTags.push(tag);
          });
        }

        if (location.tagsSetting !== undefined) {
          location.tagsSetting.forEach((tag) => {
            allTags.push(tag);
          });
        }

        if (location.tagsCuisine !== undefined) {
          location.tagsCuisine.forEach((tag) => {
            allTags.push(tag);
          });
        }

        if (location.tagsHours !== undefined) {
          location.tagsHours.forEach((tag) => {
            allTags.push(tag);
          });
        }

        if (location.tagsDateType !== undefined) {
          location.tagsDateType.forEach((tag) => {
            allTags.push(tag);
          });
        }

        const newLocation = location;
        newLocation.tags = allTags;
        locationsReady.push(newLocation);
      });
      updatedPackages[i].locations = locationsReady;
    });
    packagesReady = updatedPackages;

    // const dif = _.differenceWith(cuisine, beverages, _.isEqual);
    // const filtered = _.filter(coll, item => _.some(filter, key => _.includes(item.tags, key)));

    // Filter Locations Based On Selected Categories
    function filterByMultipleFields(coll, filter) {
      // Organize Tags into their categories
      const tagsBeverages = [];
      const tagsSetting = [];
      const tagsCuisine = [];
      const tagsHours = [];
      const tagsDateType = [];

      let setting = [];
      let type = [];
      let cuisine = [];
      let hours = [];
      let beverages = [];

      let filterdPackagesReady = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Hours') {
            tagsHours.push(tag.tag);
          } else if (tagcat.parent === 'Beverages') {
            tagsBeverages.push(tag.tag);
          } else if (tagcat.parent === 'Date Type') {
            tagsDateType.push(tag.tag);
          } else if (tagcat.parent === 'Cuisine Type') {
            tagsCuisine.push(tag.tag);
          } else if (tagcat.parent === 'Setting Type') {
            tagsSetting.push(tag.tag);
          }
        });
      });

      filter.forEach((tag) => {
        if (tagsBeverages.includes(tag) === true) {
          packagesReady.forEach((thispackage) => {
            beverages = _.filter(thispackage.locations, (item) =>
              _.some(filter, (key) =>
                _.includes(item.tagsBeverages, key),
              ),
            );
            if (beverages.length !== 0) {
              filterdPackagesReady.push(thispackage);
            }
          });
        } else if (tagsDateType.includes(tag) === true) {
          packagesReady.forEach((thispackage) => {
            type = _.filter(thispackage.locations, (item) =>
              _.some(filter, (key) =>
                _.includes(item.tagsDateType, key),
              ),
            );
            if (type.length !== 0) {
              filterdPackagesReady.push(thispackage);
            }
          });
        } else if (tagsSetting.includes(tag) === true) {
          packagesReady.forEach((thispackage) => {
            setting = _.filter(thispackage.locations, (item) =>
              _.some(filter, (key) =>
                _.includes(item.tagsSetting, key),
              ),
            );
            if (setting.length !== 0) {
              filterdPackagesReady.push(thispackage);
            }
          });
        } else if (tagsHours.includes(tag) === true) {
          packagesReady.forEach((thispackage) => {
            hours = _.filter(thispackage.locations, (item) =>
              _.some(filter, (key) =>
                _.includes(item.tagsHours, key),
              ),
            );
            if (hours.length !== 0) {
              filterdPackagesReady.push(thispackage);
            }
          });
        } else if (tagsCuisine.includes(tag) === true) {
          packagesReady.forEach((thispackage) => {
            cuisine = _.filter(thispackage.locations, (item) =>
              _.some(filter, (key) =>
                _.includes(item.tagsCuisine, key),
              ),
            );
            if (cuisine.length !== 0) {
              filterdPackagesReady.push(thispackage);
            }
          });
        }
      });
      const filtered = _.filter(coll, (item) =>
        _.some(filter, (key) => _.includes(item.tags, key)),
      );
      const arrays = [cuisine, beverages, type, setting, hours];
      const result = _.spread(_.union)(arrays);
      return filterdPackagesReady;
    }

    const filter = this.selectedTags(this.state.tagFilters);

    const result = filterByMultipleFields(packagesReady, filter);

    return result;
    // if (this.props.selectedCategories.length > 0) {
    //   this.setState({
    //     filteredLocations: result,
    //   });
    // } else {
    //   this.setState({
    //     filteredLocations: locations,
    //   });
    // }
  }

  selectedTags(tagFilters) {
    let selectedTags = [];
    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });

    return selectedTags;
  }

  clearTagFilters(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    this.setState({
      tagFilters: updatedTags,
    });
    window.scrollTo(0, 0);
    this.searchUpdated('', this.state.packagesReady);
  }

  getFilteredTag(tag) {
    return <span className="tag-match">{tag}</span>;
  }

  getFilters() {
    if (this.state.tagFilters.length !== 0) {
      let selectedTags = this.selectedTags(this.state.tagFilters);
      if (selectedTags.length > 0) {
        return (
          <p>
            <b>Applied Filters: </b>
            {selectedTags.map((tag) => {
              return this.getFilteredTag(tag);
            })}
            <span
              onClick={() => {
                this.clearTagFilters(this.props.tags);
              }}
              className="redlink"
            >
              clear all
            </span>
          </p>
        );
      }
    }
  }

  getModalHeader = (value) => {
    return (
      <Row className="no-margin">
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
        <Col lg={8} md={8} sm={8} xs={8}>
          <center>
            <span className="page-header white">Filter</span>
          </center>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
          <a onClick={() => this.closeFilterModal()}>
            <MaterialIcon icon="check" size={25} color="white" />
          </a>
        </Col>
      </Row>
    );
  };

  getCurrentDistance(filter) {
    let text = '';
    if (filter === 0) {
      text = '50';
    } else if (filter === 1) {
      text = '10';
    } else {
      text = '100+';
    }
    return text;
  }

  setDistanceFilter = (value) => {
    this.setState({
      distanceFilterState: value,
    });
  };

  getButton(value, distance, state) {
    if (value !== state) {
      return (
        <button
          className="redbutton full-width"
          onClick={() => {
            this.setDistanceFilter(value);
          }}
        >
          {distance}
        </button>
      );
    } else {
      return (
        <button className="greybutton full-width">{distance}</button>
      );
    }
  }

  getLocationTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <a
                onClick={() => {
                  this.hideLocationDetails();
                }}
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </a>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Location Details</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  showLocationDetails(location) {
    this.setState({
      showLocation: true,
      activeLocation: location,
    });
  }

  hideLocationDetails() {
    this.setState({
      showLocation: false,
      activeLocation: null,
    });
  }

  getLocationDetails(location) {
    return (
      <div>
        <LocationItineraryDetails
          location={this.state.activeLocation}
        />
      </div>
    );
  }

  render() {
    if (this.state.loading === true) {
      return null; // or render a loading icon
    }
    if (this.state.showLocation === true) {
      window.scrollTo(0, 0);
      return (
        <div id="home">
          {this.getLocationTitleBar()}
          {this.getLocationDetails(this.state.activeLocation)}
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    return (
      <div id="packages">
        {this.getTitleBar()}
        <div className="secondary-title-bar">
          <div>
            <Container>
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding text-center"
                >
                  <span>"Here are some recomendations."</span>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="contentbox">
          {/* <PromotedHero /> */}
          <div className="section">
            {/* <div className="header-container">
              <Row className="no-margin">
                <Col xs={11} md={11} sm={11} lg={11} className="no-padding">
                  <SearchInput
                    className="search-input"
                    onChange={this.searchUpdated}
                  />
                </Col>
                <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
                  <a
                    onClick={() => {
                      this.openFilterModal();
                    }}
                    className="right"
                  >
                    <MaterialIcon
                      icon="filter_list"
                      size={25}
                      color="#f21d59"
                    />
                  </a>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
                  <p>
                    Showing events with locations{" "}
                    <b>
                      {this.getCurrentDistance(this.state.distanceFilterState)}
                    </b>{" "}
                    miles from you.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
                  {this.getKey()}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
                  {this.getFilters()}
                </Col>
              </Row>
            </div> */}
          </div>
          <div className="section">
            <Container>
              <Row>
                {/* Stack the columns on mobile by making one full-width and the other half-width */}
                <PackageListItem
                  packages={this.state.filteredArray}
                  showLocationDetails={this.showLocationDetails}
                  hideLocationDetails={this.hideLocationDetails}
                />
              </Row>
            </Container>
          </div>
        </div>
        <Modal
          isOpen={this.state.filterModalVisible}
          // onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeFilterModal}
          style={modalStyles}
          // contentLabel="Wait for Connection?"
        >
          <div className="full-height">
            <div className="modalHeader">{this.getModalHeader()}</div>
            <div className="secondary-title-bar">
              <div>
                <Container>
                  <Row className="no-margin">
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding text-center"
                    >
                      <span>What are you in the mood for?</span>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="wizard-section-border">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <h5 className="red">Distance</h5>
                  <span>Radius (Miles):</span>
                </Col>
              </Row>

              <Row>
                <Col
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="padding-ten"
                >
                  {this.getButton(
                    1,
                    '10',
                    this.state.distanceFilterState,
                  )}
                </Col>
                <Col
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="padding-ten"
                >
                  {this.getButton(
                    0,
                    '50',
                    this.state.distanceFilterState,
                  )}
                </Col>
                <Col
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="padding-ten"
                >
                  {this.getButton(
                    2,
                    '100+',
                    this.state.distanceFilterState,
                  )}
                </Col>
              </Row>
            </div>
            <div className="wizard-section-border">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <FilterModal
                    tagFilters={this.state.tagFilters}
                    setTagFilters={this.setTagFilters}
                    otherUserTagsLove={this.state.otherUserTagsLove}
                    otherUser={this.state.otherUser}
                  />
                </Col>
              </Row>
            </div>
            {/* <div className="section">{this.getModalContent()}</div> */}
          </div>
        </Modal>
        <style
          dangerouslySetInnerHTML={{
            __html:
              // '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              '#headersTop {width: 100% !important;} #headersTopSecondary{width: 100%} #authright {padding-left: 0px !important;} #authleft {display:none;} #events{position: relative; top: 40px} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 40px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    alllocations: state.locations.locations,
    customEvent: state.customEvent.customEvent,
    profile: state.profile.profile,
    newEvent: state.newEvent,
    users: state.users.users,
    tags: state.tags.tags,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(init());
    },
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
