import * as action from './actions';

export const getTaRequest = (id) => {
  return {
    type: action.GET_TAREQUEST,
    payload: id,
  };
};

export const setTaRequest = (ap) => {
  return {
    type: action.SET_TAREQUEST,
    payload: ap,
  };
};

export const clearTaRequest = (taRequest) => {
  let defaultState = {
    tagFilters: [],
    distance: 10,
    startLocation: 'current',
    eventCats: {
      eat: false,
      watch: false,
      do: false,
      startingCat: 1,
      endCat: 1,
    },
    currentLatitude: taRequest.currentLatitude,
    currentLongitude: taRequest.currentLongitude,
    closestRegion: taRequest.closestRegion,
    userMoodYes: [],
    userMoodNo: [],
    reroll: false,
  };
  return {
    type: action.CLEAR_TAREQUEST,
    payload: defaultState,
  };
};

const ACTION_HANDLERS = {
  [action.GET_TAREQUEST]: (state, action) => {
    return { ...state };
  },
  [action.SET_TAREQUEST]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.CLEAR_TAREQUEST]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  tagFilters: [],
  distance: 10,
  startLocation: 'current',
  eventCats: {
    eat: false,
    watch: false,
    do: false,
    startingCat: 1,
    endCat: 1,
  },
  currentLatitude: null,
  currentLongitude: null,
  closestRegion: null,
  userMoodYes: [],
  userMoodNo: [],
  reroll: false,
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
