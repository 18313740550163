import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../config/firebase';
// Components
// Reducers
import { getProfile } from '../sagas/getProfileSaga/reducer';

export class SignUpTags extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      cuisineType: [],
      beverages: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getProfile(this.props.profile.userId);
  }

  saveTags(values, userId) {
    const db = firebase.firestore();
    const docToUpdate = db.collection('users').doc(userId);
    const timestamp = new Date();

    const tagsBeverages = [];
    this.state.beverages.forEach(doc => {
      tagsBeverages.push(doc.value);
    });

    const tagsCuisine = [];
    this.state.cuisineType.forEach(doc => {
      tagsCuisine.push(doc.value);
    });

    // const tagsDateType = [];
    // this.state.dateType.forEach(doc => {
    //   tagsDateType.push(doc.value);
    // });

    // const tagsHours = [];
    // this.state.hours.forEach(doc => {
    //   tagsHours.push(doc.value);
    // });

    // const tagsSetting = [];
    // this.state.setting.forEach(doc => {
    //   tagsSetting.push(doc.value);
    // });

    const tagsArray = {
      // tagsDateType,
      tagsCuisine,
      // tagsHours,
      tagsBeverages,
      // tagsSetting
    };

    const updateDoc = docToUpdate
      .update({
        tags: tagsArray,
      })
      .then(() => {
        this.props.getProfile(this.props.profile.userId);
        this.props.history.push('/home');
      });
  }

  getCategoryFields(tags) {
    const cuisineOptions = [];
    const beverageOptions = [];
    tags.forEach(tag => {
      if (tag.sid === 'cuisineType') {
        tag.tags.forEach(tag => {
          cuisineOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (tag.sid === 'beverages') {
        tag.tags.forEach(tag => {
          beverageOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      }
    });
    return (
      <div className="signup-questions">
        <div className="signup-question">
          <span className="signup-tag-lable textleft">
            Favorite Cuisine?
          </span>
          <Select
            value={this.state.cuisine}
            onChange={value => {
              this.handleOnChange('cuisineType', value);
            }}
            options={cuisineOptions}
            isMulti={true}
          />
        </div>
        <div className="signup-question">
          <span className="signup-tag-lable textleft">
            Favorite Drinks?
          </span>
          <Select
            value={this.state.beverages}
            onChange={value => {
              this.handleOnChange('beverages', value);
            }}
            options={beverageOptions}
            isMulti={true}
          />
        </div>
      </div>
    );
  }

  handleOnChange = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div id="login">
        <div className="signup-box-container">
          <div className="signup-box">
            <div className="profile-box-title-area">
              <h3>Set Some Preferences</h3>
              <p>You can set these later as well!</p>
            </div>
            <div>
              <Formik
                enableReinitialize
                onSubmit={values => {
                  this.saveTags(values, this.props.user.id);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Container>
                      <Row>
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="left"
                        >
                          <div className="section no-padding">
                            <div>
                              {this.getCategoryFields(
                                this.props.tags,
                              )}
                            </div>
                            <ErrorMessage
                              name="categories"
                              component="div"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <button
                      type="submit"
                      // className="redbutton"
                      disabled={isSubmitting}
                    >
                      Get Started!
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#authleft {display:none } #mobile-nav{display:none !important} .maincontent-push{top:0px !important; height:100%} #authright {padding-left:0px !important} header { display: none } .maincontent { top: 0px; margin-top: 0px;} .footer-container{display:none} .full-height{height:100% ',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProfile: userId => {
      dispatch(getProfile(userId));
    },
  };
};

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    user: state.user,
    tags: state.tags.tags,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpTags);
