import { all, fork } from 'redux-saga/effects';

import packagesSaga from './packagesSaga/saga';
import articleSaga from './articleSaga/saga';
import inviteSaga from './inviteSaga/saga';
import loginSaga from './loginSaga/saga';
import getProfileSaga from './getProfileSaga/saga';
import userEventsSaga from './userEventsSaga/saga';
import locationsSaga from './locationsSaga/saga';
import allLocationsSaga from './allLocationsSaga/saga';
import hydrateSaga from './hydrateSaga/saga';
import authhydrateSaga from './authhydrateSaga/saga';
import usersSaga from './usersSaga/saga';
import vendorsSaga from './vendorsSaga/saga';
import vendorSaga from './vendorSaga/saga';
import vendorAwardedSaga from './vendorAwardedSaga/saga';
import vendorRewardsSaga from './vendorRewardsSaga/saga';
import tagsSaga from './tagsSaga/saga';
import setModeSaga from './setModeSaga/saga';
import setActivePackageSaga from './setActivePackageSaga/saga';
import customEventSaga from './customEventSaga/saga';
import notificationsSaga from './notificationsSaga/saga';
import activeEventSaga from './activeEventSaga/saga';
import userVoucherSaga from './userVoucherSaga/saga';
import userAwardsSaga from './userAwardsSaga/saga';
import checkOutSaga from './checkOutSaga/saga';
import purchaseSaga from './purchaseSaga/saga';
import invitationSaga from './invitationSaga/saga';
import allEventsSaga from './allEventsSaga/saga';
import createPackageSaga from './createPackageSaga/saga';
import connectionsSaga from './connectionsSaga/saga';
import createEventSaga from './createEventSaga/saga';
import favoritesSaga from './favoritesSaga/saga';
import promoSaga from './promoSaga/saga';
import eventsSaga from './eventsSaga/saga';
import vendorPromoSaga from './vendorPromoSaga/saga';
import vendorLocationsSaga from './vendorLocationsSaga/saga';
import vendorEventsSaga from './vendorEventsSaga/saga';
import vendorEntertainmentSaga from './vendorEntertainmentSaga/saga';
import createVendorEventSaga from './createVendorEventSaga/saga';
import vendorUserEventsSaga from './vendorUserEventsSaga/saga';
import connectionsTagsAndFavsSaga from './connectionsTagsAndFavsSaga/saga';
import setDailySpotlightSaga from './setDailySpotlightSaga/saga';
import setDestinationSaga from './setDestinationSaga/saga';
import unauthenticatedSaga from './unauthenticatedSaga/saga';
import demoSaga from './demoSaga/saga';
import vendorTimedEventsSaga from './vendorTimedEventsSaga/saga';
import timedEventsSaga from './timedEventsSaga/saga';
import entertainmentSaga from './entertainmentSaga/saga';
import adminUsersSaga from './adminUsersSaga/saga';
import guideTemplatesSaga from './guideTemplatesSaga/saga';
import regionsSaga from './regionsSaga/saga';
import taRequestSaga from './taRequestSaga/saga';

export default function* root() {
  yield all([fork(packagesSaga)]);
  yield all([fork(articleSaga)]);
  yield all([fork(inviteSaga)]);
  yield all([fork(loginSaga)]);
  yield all([fork(getProfileSaga)]);
  yield all([fork(userEventsSaga)]);
  yield all([fork(locationsSaga)]);
  yield all([fork(allLocationsSaga)]);
  yield all([fork(hydrateSaga)]);
  yield all([fork(authhydrateSaga)]);
  yield all([fork(usersSaga)]);
  yield all([fork(vendorsSaga)]);
  yield all([fork(vendorSaga)]);
  yield all([fork(tagsSaga)]);
  yield all([fork(setModeSaga)]);
  yield all([fork(setActivePackageSaga)]);
  yield all([fork(customEventSaga)]);
  yield all([fork(notificationsSaga)]);
  yield all([fork(activeEventSaga)]);
  yield all([fork(userVoucherSaga)]);
  yield all([fork(checkOutSaga)]);
  yield all([fork(purchaseSaga)]);
  yield all([fork(invitationSaga)]);
  yield all([fork(allEventsSaga)]);
  yield all([fork(createPackageSaga)]);
  yield all([fork(connectionsSaga)]);
  yield all([fork(createEventSaga)]);
  yield all([fork(favoritesSaga)]);
  yield all([fork(promoSaga)]);
  yield all([fork(vendorPromoSaga)]);
  yield all([fork(vendorRewardsSaga)]);
  yield all([fork(vendorEventsSaga)]);
  yield all([fork(userAwardsSaga)]);
  yield all([fork(vendorAwardedSaga)]);
  yield all([fork(createVendorEventSaga)]);
  yield all([fork(eventsSaga)]);
  yield all([fork(vendorUserEventsSaga)]);
  yield all([fork(connectionsTagsAndFavsSaga)]);
  yield all([fork(vendorLocationsSaga)]);
  yield all([fork(setDailySpotlightSaga)]);
  yield all([fork(setDestinationSaga)]);
  yield all([fork(unauthenticatedSaga)]);
  yield all([fork(demoSaga)]);
  yield all([fork(vendorTimedEventsSaga)]);
  yield all([fork(timedEventsSaga)]);
  yield all([fork(vendorEntertainmentSaga)]);
  yield all([fork(entertainmentSaga)]);
  yield all([fork(adminUsersSaga)]);
  yield all([fork(guideTemplatesSaga)]);
  yield all([fork(regionsSaga)]);
  yield all([fork(taRequestSaga)]);
}
