import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import SearchInput, { createFilter } from 'react-search-input';
import { Link } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
// Components
import FFLocations from '../../components/FFLocations';
import TitleBar from '../../components/TitleBar';
// Styles
import '../../components/PackageListItem/PackageListItem.css';
// Reducers
import { updatePackage } from '../../sagas/createPackageSaga/reducer';

const KEYS_TO_FILTERS = ['locationTitle'];
const query = require('array-query');

export class AdminCreateEventAddLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      filteredArray: null,
      filteredList: '',
      mode: 1,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.addLocation = this.addLocation.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.custom === true) {
      this.filterCustom(this.props.alllocations);
    } else {
      this.setState({
        filteredArray: this.props.alllocations,
        loading: false,
      });
    }
  }

  componentDidMount() {
    this.searchUpdated('');
  }

  filterCustom(locations) {
    const filteredArray = [];
    const theselocations = locations;
    theselocations.forEach(location => {
      if (location.custom === true) {
        filteredArray.push(location);
      }
    });
    this.setState({
      filteredArray,
      loading: false,
    });
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  searchUpdated(term) {
    const filteredList = this.props.alllocations.filter(
      createFilter(term, KEYS_TO_FILTERS),
    );
    this.setState({ searchTerm: term, filteredArray: filteredList });
  }

  addLocation(packageBundle, location) {
    if (packageBundle.package.locations !== null) {
      if (packageBundle.placement === 1) {
        const newLocations = [];
        packageBundle.package.locations.forEach(location => {
          newLocations.push(location);
        });
        if (location.type === 1) {
          newLocations.push({ location: location, type: 1 });
        } else {
          newLocations.push({
            locationId: location.locationId,
            type: 0,
          });
        }
        packageBundle.package.locations = newLocations;
        this.props.updatePackage(packageBundle);
      } else {
        const newLocations = [];
        packageBundle.package.locations.forEach(location => {
          newLocations.push(location);
        });
        if (location.type === 1) {
          newLocations.unshift({ location: location, type: 1 });
        } else {
          newLocations.unshift({
            locationId: location.locationId,
            type: 0,
          });
        }
        packageBundle.package.locations = newLocations;
        this.props.updatePackage(packageBundle);
      }
    } else {
      const newLocations = [];
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          locationId: location.locationId,
          type: 0,
        });
      }
      packageBundle.package.locations = newLocations;
      this.props.updatePackage(packageBundle);
    }
  }

  // changeLocation(newLocationId, newLocationTitle, index, type, custom) {
  //   if (type === 0) {
  //     const updatedLocations = [...this.state.locations];
  //     if (index != null) {
  //       updatedLocations.splice(index, 1, {
  //         locationName: newLocationTitle,
  //         locationId: newLocationId,
  //         type
  //       });
  //       return updatedLocations;
  //     }
  //   } else {
  //     const updatedLocations = [...this.state.locations];
  //     if (index != null) {
  //       updatedLocations.splice(index, 1, {
  //         location: custom,
  //         type: 1
  //       });
  //       return updatedLocations;
  //     }
  //   }
  // }

  getFooter(mode) {
    return (
      <Container>
        <Row className="no-margin">
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <a
              onClick={() => {
                this.setMode(1);
              }}
            >
              <span className="footer-link left">Recommended</span>
            </a>
          </Col>
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <a
              onClick={() => {
                this.setMode(2);
              }}
            >
              <span className="right footer-link">Any Location</span>
            </a>
          </Col>
        </Row>
      </Container>
    );
  }

  getImage(type, locationStart) {
    let location = '';
    let locations = this.props.alllocations;
    const locationDetails = query('locationId')
      .is(locationStart.locationId)
      .on(locations);
    location = locationDetails[0];
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getContent(mode) {
    if (mode === 1) {
      return (
        <div id="events" className="section">
          <div className="header-container">
            <div className="section">
              <SearchInput
                className="search-input"
                onChange={this.searchUpdated}
              />
            </div>
          </div>
          <div>
            <Container className="no-padding">
              {this.state.filteredArray.map((location, index) => (
                <Row className="oddeven" key={'key' + index}>
                  <Col xs={10} md={10} sm={10} lg={10}>
                    <Link
                      to={{
                        pathname: '/location',
                        state: {
                          location: { location },
                          // eventId: { eventId },
                          // token: { token },
                          type: 5,
                        },
                      }}
                    >
                      {this.getImage('thumb', location)}
                      <span className="invite-label">
                        {location.locationTitle}
                      </span>
                      <br />
                      <span className="address">
                        {location.address.street}
                      </span>
                      <br />
                      <span className="address">
                        {location.address.city}{' '}
                        {location.address.state},{' '}
                        {location.address.zip}
                      </span>
                    </Link>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    sm={2}
                    lg={2}
                    key={'key' + index}
                  >
                    <div className="right text-right">
                      <MaterialIcon
                        icon="add_circle"
                        size={25}
                        color="#f21d59"
                        onClick={() => {
                          this.addLocation(
                            this.props.packageBundle,
                            location,
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              ))}
            </Container>
          </div>
        </div>
      );
    } else {
      return (
        <FFLocations
          locations={this.props.alllocations}
          addLocation={this.addLocation}
          customEvent={this.props.packageBundle}
        />
      );
    }
  }

  render() {
    if (this.state.filteredArray === null) {
    }
    return (
      <div id="home">
        <TitleBar title="Add Location" backlink="" />
        {this.getContent(this.state.mode)}
        <div className="footer">{this.getFooter()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    packageBundle: state.newPackage,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updatePackage: newPackage => {
      dispatch(updatePackage(newPackage));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminCreateEventAddLocation);
