import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
// Components
// Images
import redLogo from '../../images/heartbig.png';
import bellhop from '../../images/bellhop.svg';

export class BottomNav extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {}

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div id="bottomNav">
        <Container
          style={{ width: '100%', margin: '0px', maxWidth: 'none' }}
        >
          <Row className="no-margin">
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              <center>
                <NavLink
                  to={{
                    pathname: '/events/upcoming/',
                  }}
                  activeClassName="activeBottomNav"
                >
                  <MaterialIcon
                    icon="insert_invitation"
                    size={27}
                    color="white"
                  />
                  <span
                    style={{
                      fontSize: '13px',
                      display: 'block',
                      color: 'white',
                      top: '-10px',
                    }}
                  >
                    upcoming
                  </span>
                </NavLink>
              </center>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              <center>
                <NavLink
                  to={{
                    pathname: '/events/plan/1',
                  }}
                  activeClassName="activeBottomNav"
                  className="middleIcon"
                >
                  {/* <span
                    style={{
                      lineHeight: '35px',
                      display: 'block',
                      top: '-2px',
                      position: 'relative',
                    }}
                  >
                    <MaterialIcon
                      icon="room_service"
                      size={40}
                      color="white"
                    />
                  </span> */}
                  <center>
                    <img
                      src={bellhop}
                      style={{ width: '40px' }}
                      alt="logo"
                    />
                  </center>
                  <span
                    style={{
                      fontSize: '14px',
                      display: 'block',
                      top: '3px',
                      position: 'relative',
                      color: 'white',
                      fontWeight: '600',
                    }}
                  >
                    trevel agent
                  </span>
                </NavLink>
              </center>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              <center>
                <NavLink
                  to={{
                    pathname: '/rewards',
                  }}
                  activeClassName="activeBottomNav"
                >
                  <MaterialIcon
                    icon="local_activity"
                    size={27}
                    color="white"
                  />
                  <span
                    style={{
                      fontSize: '13px',
                      display: 'block',
                      color: 'white',
                    }}
                  >
                    rewards
                  </span>
                </NavLink>
              </center>
            </Col>
          </Row>
        </Container>
      </div>
      /* <div id="bottomNav">
        <Container>
          <Row className="no-margin">
            <Col xs={3} md={3} sm={3} lg={3} className="no-padding">
              <NavLink
                to={{
                  pathname: "/events/plan/1"
                }}
                activeClassName="activeBottomNav"
              >
               

                <center>
                  <img src={redLogo} className="bottomNavLogo" alt="logo" />
                </center>
              </NavLink>
            </Col>
            <Col xs={3} md={3} sm={3} lg={3} className="no-padding">
              <NavLink
                to={{
                  pathname: "/events/upcoming/"
                }}
                activeClassName="activeBottomNav"
              >
                <MaterialIcon
                  icon="insert_invitation"
                  size={27}
                  color="#f21d59"
                />
              </NavLink>
            </Col>
            <Col xs={3} md={3} sm={3} lg={3} className="no-padding">
              <NavLink
                to={{
                  pathname: "/places"
                }}
                activeClassName="activeBottomNav"
              >
                <MaterialIcon icon="stars" size={27} color="#f21d59" />
              </NavLink>
            </Col>
            <Col xs={3} md={3} sm={3} lg={3} className="no-padding">
              <NavLink
                to={{
                  pathname: "/activity"
                }}
                activeClassName="activeBottomNav"
              >
                <MaterialIcon icon="notifications" size={27} color="#f21d59" />
              </NavLink>
            </Col>
          </Row>
        </Container>
      </div> */
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(BottomNav);
