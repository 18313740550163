import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { push } from 'connected-react-router';
import { clearTaRequest } from '../taRequestSaga/reducer';

let defaultState = {
  tagFilters: [],
  distance: 10,
  startLocation: 'current',
  eventCats: {
    eat: false,
    watch: false,
    do: false,
    startingCat: 1,
    endCat: 2,
  },
  currentLatitude: null,
  currentLongitude: null,
  closestRegion: null,
  userMoodYes: [],
  userMoodNo: [],
  reroll: false,
};

function* getTaRequest(action) {
  yield put({
    type: actions.SET_TAREQUEST,
    payload: {
      tagFilters: action.payload.tagFilters,
      distance: action.payload.distance,
      startLocation: action.payload.startLocation,
      eventCats: action.payload.eventCats,
      currentLatitude: action.payload.currentLatitude,
      currentLongitude: action.payload.currentLongitude,
      closestRegion: action.payload.closestRegion,
      userMoodYes: action.payload.userMoodYes,
      userMoodNo: action.payload.userMoodNo,
      reroll: action.payload.reroll,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_TAREQUEST, getTaRequest);
}
