import $ from 'jquery';
import React, { Component } from 'react';
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
// Components
import PackageDrawer from '../../components/PackageDrawer';
// CSS
import './PackageListItem.css';
// Reducer
import { getCe } from '../../sagas/customEventSaga/reducer';

const query = require('array-query');

class PackageListItem extends Component {
  constructor() {
    super();
    this.state = {
      clicked: null,
      currentPage: 1,
      resultsPerPage: 10,
    };
  }

  componentDidMount() {
    $('.col-lg-6').mouseover(function () {
      $(this).addClass('topcard');
    });
    $('.col-lg-6').mouseout(function () {
      $(this).removeClass('topcard');
    });
  }

  changePager(pagerNum) {
    this.setState({
      currentPage: pagerNum,
    });
  }

  getImage(type, locationStart) {
    let location = '';
    let locations = this.props.alllocations;
    const locationDetails = query('locationId')
      .is(locationStart.locationId)
      .on(locations);
    location = locationDetails[0];
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getLocation(location, index) {
    if (location !== undefined) {
      return (
        <li className="adminlistitem" key={'key' + index}>
          <div className="fullwidth">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              key={'key' + index}
              className="no-padding"
            >
              <a
                onClick={() => {
                  this.props.showLocationDetails(location);
                }}
              >
                <Row>
                  <Col
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    className="no-padding thumbnailCol"
                  >
                    {this.getImage('thumb', location)}
                  </Col>
                  <Col xs={8} md={8} sm={8} lg={8}>
                    <span className="location-title">
                      {location.locationTitle}
                    </span>
                    <span className="address">
                      {location.address.street}
                    </span>
                    <span className="address">
                      {location.address.city} {location.address.state}
                      , {location.address.zip}
                    </span>
                  </Col>
                </Row>
              </a>
            </Col>
          </div>
        </li>
      );
    }
  }

  addLocations(customEvent, locations, heroImage, locationThumb) {
    const newLocations = [];
    // customEvent.event.locations.forEach((location) => {
    //   newLocations.push(location);
    // });
    locations.map((location) => {
      newLocations.push(location);
    });
    customEvent.event.heroImage = heroImage;
    customEvent.event.thumbnail = locationThumb;
    customEvent.event.locations = newLocations;
    customEvent.initialMoodSet = true;
    this.props.getCe(customEvent);
  }

  render() {
    const { currentPage, resultsPerPage } = this.state;
    // Logic for displaying todos
    const indexOfLastTodo = currentPage * resultsPerPage;
    const indexOfFirstTodo = indexOfLastTodo - resultsPerPage;
    const currentPackages = this.props.packages.slice(
      indexOfFirstTodo,
      indexOfLastTodo,
    );

    const renderPackages = currentPackages.map((packages, index) => {
      return (
        <Col
          xs={12}
          md={6}
          sm={6}
          lg={6}
          key={'key' + index}
          className="package-card no-padding"
        >
          <Card
            id={'card' + index}
            key="front"
            className="cardSection"
          >
            <CardImg
              top
              width="100%"
              src={
                'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
                packages.heroImage +
                '?alt=media'
              }
              alt="Card image cap"
            />
            <CardBody>
              <CardTitle>{packages.title}</CardTitle>
              {/* <CardSubtitle>Star Ratings?</CardSubtitle> */}
              <CardText>{packages.description}</CardText>
              <div>
                <h2>Itinerary</h2>
                <ul className="no-padding no-margin">
                  {packages.locations.map((location, index) => (
                    <div key={'key' + index}>
                      {this.getLocation(location, index)}
                    </div>
                  ))}
                </ul>
              </div>
              <div className="cardactions">
                <a
                  onClick={() => {
                    this.addLocations(
                      this.props.customEvent,
                      packages.locations,
                      packages.heroImage,
                      packages.locationThumb,
                    );
                  }}
                >
                  <button className="buy-package">
                    Add Locations
                  </button>
                </a>
                {/* <Link
                  to={{
                    pathname: "/package/" + packages.packageId,
                  }}
                >
                  <button className="buy-package">Add Locations</button>
                </Link> */}
              </div>
            </CardBody>
            <div className="card-drawer">
              <PackageDrawer locations={packages.locations} />
            </div>
          </Card>
        </Col>
      );
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(this.props.packages.length / resultsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      return (
        <li
          key={number}
          id={number}
          onClick={() => {
            this.changePager(number);
          }}
        >
          {number}
        </li>
      );
    });

    return (
      <div>
        {renderPackages}
        <ul id="page-numbers">{renderPageNumbers}</ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customEvent: state.customEvent.customEvent,
    alllocations: state.locations.locations,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PackageListItem);
