import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import geolib from 'geolib';
// Components
import SignUpBanner from '../components/SignUpBanner';
import FilterModal from '../components/FilterModal';
import firebase from '../config/firebase';
import whitelogo from '../images/TrevelUnderlinedWhite.svg';
import moment from 'moment';
import Modal from 'react-modal';
import Select from 'react-select';
import { Carousel } from 'react-responsive-carousel';
import datingapp from '../images/datingapp.png';
import bannerback from '../images/bannerback.png';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { createFilter } from 'react-search-input';
import MaterialIcon from 'material-icons-react';
// Reducers
import { setLocations } from '../sagas/locationsSaga/reducer';
import { setPackages } from '../sagas/packagesSaga/reducer';
import { getUauth } from '../sagas/unauthenticatedSaga/reducer';
import DailyWeather from '../components/DailyWeather';
import GuideCategory from '../components/GuideCategory';
import GuideDemoCategory from '../components/GuideDemoCategory';
import GuideCategoryRegion from '../components/GuideCategoryRegion';
import GuideEventsCategory from '../components/GuideEventsCategory';
import PromotedArticles from '../components/PromotedArticles';
// Reducers
import { getArticles } from '../sagas/articleSaga/reducer';
import { clearUauth } from '../sagas/unauthenticatedSaga/reducer';
import { getTimedEvents } from '../sagas/timedEventsSaga/reducer';
import { getEntertainment } from '../sagas/entertainmentSaga/reducer';
import { getGuideTemplates } from '../sagas/guideTemplatesSaga/reducer';
import { getRegions } from '../sagas/regionsSaga/reducer';
import { getTaRequest } from '../sagas/taRequestSaga/reducer';

const query = require('array-query');
const _ = require('lodash');
const KEYS_TO_FILTERS = ['locationTitle', 'locations.locationTitle'];

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    maxWidth: '900px',
    width: '90%',
  },
};

export class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      landing: true,
      toFar: false,
      mo: false,
      kc: false,
      promos: [],
      locations: [],
      sortState: 1,
      tagFilters: [],
      locationsWithDistance: [],
      distanceFilterState: 0,
      locationServices: true,
      zoneId: 0,
      geoAvailable: true,
      signUpModalVisible: false,
      modalShown: 1,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.setTagFilters = this.setTagFilters.bind(this);
    this.changeCarousel = this.changeCarousel.bind(this);
    this.setRandomizer = this.setRandomizer.bind(this);
    this.weightMusic = this.weightMusic.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.title = 'Trevel';
    // this.props.clearUauth();

    if (this.props.user.authenticated === true) {
      console.log('bounce');
      this.props.history.push('/home');
    }
    this.updateDimensions();
  }

  updateDimensions = () => {
    let width = window.innerWidth;
    let count = 2;
    if (width > 1023) {
      count = 4;
    } else if (width < 1023 && width > 767) {
      count = 3;
    }
    let finalCount = this.state.count;
    if (this.state.count !== count) {
      finalCount = count;
    }
    this.setState({
      count: finalCount,
    });

    let geoAvailable = true;
    // navigator.geolocation.getCurrentPosition(
    //   (i) => console.log('success', i),
    //   (i) => {
    //     this.setState({
    //       geoAvailable: false,
    //     });
    //     console.log('failed', i);
    //   },
    // );
    navigator.permissions &&
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((PermissionStatus) => {
          if (PermissionStatus.state == 'granted') {
          } else if (PermissionStatus.state == 'prompt') {
          } else {
            geoAvailable = false;
          }
          this.setState({
            geoAvailable,
          });
        });
  };

  myScrollFunc = () => {
    let myID = document.getElementById('SignUpBanner');
    var y = window.scrollY;
    if (y >= 700) {
      myID.className = 'show';
    } else {
      myID.className = 'hideThis';
    }
    if (y >= 1500) {
      myID.className = 'show';
      if (this.props.uauth.modalShown !== 3) {
        this.setState({ signUpModalVisible: true, modalShown: 3 });
        this.updateUauthModal(3);
      }
    }
  };

  componentDidMount() {
    if (this.props.user.authenticated === false) {
      if (
        this.props.guideTemplates === undefined ||
        this.props.guideTemplates.length === 0
      ) {
        this.props.getGuideTemplates();
      }
      let locations = [];
      let promos = [];
      let packages = [];
      let needsRefresh = false;

      // Signup Modal
      if (this.props.uauth.modalShown === 1) {
        this.updateUauthModal(2);
        this.setState({
          modalShown: 2,
        });
      }
      if (this.props.uauth.modalShown === 2) {
        this.setState({ signUpModalVisible: true, modalShown: 3 });
        this.updateUauthModal(3);
      }

      // Refresh Guide
      if (
        this.props.uauth.guide !== undefined &&
        this.props.uauth.guide.lastUpdated !== undefined
      ) {
        let min = moment().diff(
          this.props.uauth.guide.lastUpdated,
          'minutes',
        );
        if (min > 30) {
          needsRefresh = true;
        }
      }

      if (
        this.props.uauth.guide !== undefined &&
        this.props.uauth.guide.lastUpdated === undefined
      ) {
        needsRefresh = true;
      }

      // Get Guide if none
      const db = firebase.firestore();
      if (
        this.props.uauth.guide === undefined ||
        this.props.locations.length === 0 ||
        needsRefresh === true
      ) {
        let zoneId = new Promise((resolve, reject) => {
          let currentLongitude = '';
          let currentLatitude = '';
          let kcDistance = 0;
          let stlDistance = 0;
          let phoenixDistance = 0;
          this.getPosition()
            .then((position) => {
              if (position.coords) {
                let currentLatitude = position.coords.latitude;
                let currentLongitude = position.coords.longitude;

                let bundle = {
                  tagFilters: this.props.taRequest.tagFilters,
                  distance: this.props.taRequest.distance,
                  startLocation: this.props.taRequest.startLocation,
                  eventCats: this.props.taRequest.eventCats,
                  currentLatitude,
                  currentLongitude,
                  closestRegion: this.props.taRequest.closestRegion,
                  userMoodYes: [],
                  userMoodNo: [],
                  reroll: this.props.taRequest.reroll,
                };
                this.props.getTaRequest(bundle);

                kcDistance = geolib.getDistance(
                  { latitude: 39.099724, longitude: -94.578331 },
                  {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  },
                );
                stlDistance = geolib.getDistance(
                  { latitude: 38.627003, longitude: -90.199402 },
                  {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  },
                );
                phoenixDistance = geolib.getDistance(
                  { latitude: 33.45171, longitude: -112.07446 },
                  {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  },
                );

                let stlMiles = geolib.convertUnit(
                  'mi',
                  stlDistance,
                  2,
                );
                let phoenixMiles = geolib.convertUnit(
                  'mi',
                  phoenixDistance,
                  2,
                );
                let kcMiles = geolib.convertUnit('mi', kcDistance, 2);

                if (stlMiles < kcMiles && stlMiles < phoenixMiles) {
                  if (stlMiles > 100) {
                    this.setState({
                      toFar: true,
                      kc: false,
                      mo: true,
                      px: false,
                    });
                  } else {
                    resolve(11);
                  }
                } else if (
                  kcMiles < stlMiles &&
                  kcMiles < phoenixMiles
                ) {
                  if (kcMiles > 100) {
                    this.setState({
                      toFar: true,
                      mo: false,
                      kc: true,
                      px: false,
                    });
                  } else {
                    resolve(10);
                  }
                } else if (
                  phoenixMiles < stlMiles &&
                  phoenixMiles < phoenixMiles
                ) {
                  if (phoenixMiles > 100) {
                    this.setState({
                      toFar: true,
                      mo: false,
                      kc: true,
                      px: false,
                    });
                  } else {
                    resolve(12);
                  }
                }
              } else {
                alert(
                  'Geolocation is not supported by this browser.',
                );
              }
            })
            .catch((err) => {
              console.error('something wrongg', err.message);
              this.setState({
                locationServices: false,
              });
            });
        }).then((zoneId) => {
          if (this.props.user.authenticated === false) {
            //Get Regions
            this.props.getRegions(zoneId);
            // Get Timed Events
            this.props.getTimedEvents(zoneId);
            // Get Entertainment
            this.props.getEntertainment(zoneId);
            // If they aready have locations just use those
            if (
              this.props.locations != undefined &&
              Object.keys(this.props.locations).length > 0 &&
              this.props.locations[0].zoneId === zoneId &&
              needsRefresh === false
            ) {
              // Set Tags
              this.setTags(this.props.tags, this.props.locations);
              // Set Locations
              this.setState({
                locations: this.props.locations,
              });
              this.getGeoLocation();
            } else {
              // If they don't have locations, get them.
              db.collection('locations')
                .where('zoneId', '==', zoneId)
                .where('settings.published', '==', true)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach(function (doc) {
                    locations.push(doc.data());
                  });
                  let locationsx = [];
                  locationsx.push(locations);
                  let locationsReady = [];
                  locations.forEach((location) => {
                    const newLocation = location;
                    const allTags = [];
                    if (location.tagsBeverages !== undefined) {
                      location.tagsBeverages.forEach((tag) => {
                        allTags.push(tag);
                        let status =
                          newLocation.tagsBeverages.includes(
                            'Drinks',
                          );
                        if (status === false) {
                          allTags.push('Drinks');
                          newLocation.tagsBeverages.push('Drinks');
                        }
                      });
                    }
                    if (location.tagsSettings !== undefined) {
                      location.tagsSettings.forEach((tag) => {
                        allTags.push(tag);
                      });
                    }
                    if (location.tagsCuisine !== undefined) {
                      location.tagsCuisine.forEach((tag) => {
                        allTags.push(tag);
                        let status =
                          newLocation.tagsCuisine.includes('Food');
                        if (status === false) {
                          allTags.push('Food');
                          newLocation.tagsCuisine.push('Food');
                        }
                      });
                    }
                    if (location.tagsHours !== undefined) {
                      location.tagsHours.forEach((tag) => {
                        allTags.push(tag);
                      });
                    }
                    if (location.tagsDateType !== undefined) {
                      location.tagsDateType.forEach((tag) => {
                        allTags.push(tag);
                      });
                    }
                    if (location.tagsLocationType !== undefined) {
                      location.tagsLocationType.forEach((tag) => {
                        allTags.push(tag);
                      });
                    }
                    newLocation.tags = allTags;
                    locationsReady.push(newLocation);
                  });
                  let locationBundle = [];
                  locationBundle.push(locationsReady);
                  this.setState({
                    locations: locationsReady,
                  });
                  // Set Tags
                  this.setTags(this.props.tags, locationsReady);
                  this.getGeoLocation(locationBundle);
                })
                .catch(function (error) {
                  console.log('Error getting documents: ', error);
                });
            }
            // Get Promos
            db.collection('promos')
              .where('zoneId', '==', zoneId)
              .where('active', '==', true)
              .get()
              .then((querySnapshot) => {
                //Notice the arrow funtion which bind `this` automatically.
                querySnapshot.forEach(function (doc) {
                  // doc.data() is never undefined for query doc snapshots

                  let promo = doc.data();
                  promo.id = doc.id;
                  promos.push(promo);
                });

                this.setState({
                  promos,
                });
              });
            // Get Packages
            db.collection('packages')
              .where('zoneId', '==', zoneId)
              .get()
              .then((querySnapshot) => {
                //Notice the arrow funtion which bind `this` automatically.
                querySnapshot.forEach(function (doc) {
                  // doc.data() is never undefined for query doc snapshots

                  let packagex = doc.data();
                  packagex.packageId = doc.id;
                  packages.push(packagex);
                });
                let packagesxxx = {
                  packages: packages,
                };
                this.props.setPackages(packagesxxx);
              });
          }
        });
      } else {
        //If there is a guide, use that
        if (this.state.tagFilters.length === 0) {
          this.setTags(this.props.tags, this.props.locations);
        }
        this.setState({
          locationsWithDistance: this.props.locations,
          filteredArray: this.props.locations,
          loading: false,
          landing: false,
          locationServices: true,
          // zoneId:
          //  this.props.locations[0].zoneId,
        });
        // this.setTagFilters(this.props.uauth.guide.tagFilters);
        this.searchUpdated('');
      }

      if (this.props.regions !== undefined) {
        let closestRegion = { region: '', distance: 0 };
        this.props.regions.forEach((regionx) => {
          let currentLatitude = this.props.taRequest.currentLatitude;
          let currentLongitude =
            this.props.taRequest.currentLongitude;
          let regionLatitude = regionx.long;
          let regionLongitude = regionx.lat;

          let distance = this.calcCrow(
            currentLatitude,
            currentLongitude,
            regionLatitude,
            regionLongitude,
          );

          if (closestRegion.distance === 0) {
            closestRegion.region = regionx;
            closestRegion.distance = distance;
          } else {
            if (distance < closestRegion.distance) {
              closestRegion.region = regionx;
              closestRegion.distance = distance;
            }
          }
        });
        let bundle = {
          tagFilters: this.props.taRequest.tagFilters,
          distance: this.props.taRequest.distance,
          startLocation: this.props.taRequest.startLocation,
          eventCats: this.props.taRequest.eventCats,
          currentLatitude: this.props.taRequest.currentLatitude,
          currentLongitude: this.props.taRequest.currentLongitude,
          closestRegion: closestRegion.region.name,
          userMoodYes: [],
          userMoodNo: [],
          reroll: this.props.taRequest.reroll,
        };
        this.props.getTaRequest(bundle);
      }
      window.addEventListener('resize', this.updateDimensions);
      window.addEventListener('scroll', this.myScrollFunc);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('scroll', this.myScrollFunc);
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    var lat1 = this.toRad(lat1);
    var lat2 = this.toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(lat1) *
        Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  //Manually Load Guide
  manualLoad(zoneId, needsRefresh) {
    let locations = [];
    let promos = [];
    let packages = [];
    const db = firebase.firestore();
    if (
      this.props.uauth.guide === undefined ||
      this.props.locations.length === 0
    ) {
      if (this.props.user.authenticated === false) {
        this.props.getTimedEvents(zoneId);
        this.props.getEntertainment(zoneId);
        if (
          this.props.locations != undefined &&
          Object.keys(this.props.locations).length > 0 &&
          this.props.locations[0].zoneId === zoneId &&
          needsRefresh === false
        ) {
          this.setTags(this.props.tags, this.props.locations);
          this.setState({
            locations: this.props.locations,
          });
          // this.getGeoLocation();
        } else {
          db.collection('locations')
            .where('zoneId', '==', zoneId)
            .where('settings.published', '==', true)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach(function (doc) {
                locations.push(doc.data());
              });
              let locationsx = [];
              locationsx.push(locations);
              let locationsReady = [];
              locations.forEach((location) => {
                const newLocation = location;
                const allTags = [];
                if (location.tagsBeverages !== undefined) {
                  location.tagsBeverages.forEach((tag) => {
                    allTags.push(tag);
                    let status =
                      newLocation.tagsBeverages.includes('Drinks');
                    if (status === false) {
                      allTags.push('Drinks');
                      newLocation.tagsBeverages.push('Drinks');
                    }
                  });
                }
                if (location.tagsSettings !== undefined) {
                  location.tagsSettings.forEach((tag) => {
                    allTags.push(tag);
                  });
                }

                if (location.tagsCuisine !== undefined) {
                  location.tagsCuisine.forEach((tag) => {
                    allTags.push(tag);
                    let status =
                      newLocation.tagsCuisine.includes('Food');
                    if (status === false) {
                      allTags.push('Food');
                      newLocation.tagsCuisine.push('Food');
                    }
                  });
                }

                if (location.tagsHours !== undefined) {
                  location.tagsHours.forEach((tag) => {
                    allTags.push(tag);
                  });
                }

                if (location.tagsDateType !== undefined) {
                  location.tagsDateType.forEach((tag) => {
                    allTags.push(tag);
                  });
                }
                if (location.tagsLocationType !== undefined) {
                  location.tagsLocationType.forEach((tag) => {
                    allTags.push(tag);
                  });
                }
                newLocation.tags = allTags;
                locationsReady.push(newLocation);
              });

              let locationBundle = [];
              locationBundle.push(locationsReady);
              this.setState({
                locations: locationsReady,
              });
              let bundle = [];
              if (locationsx !== undefined) {
                bundle = {
                  locations: locationsx[0],
                };
              }
              this.props.setLocations(bundle);
              this.setTags(this.props.tags, locationsReady);
              // this.getGeoLocation(locationBundle);

              this.updateUauthGuide(locationsReady, locationsReady);
              this.setState({
                locationsWithDistance: locationsReady,
                filteredArray: locationsReady,
                loading: false,
                landing: false,
                locationServices: true,
                toFar: false,
                // zoneId:
                //  this.props.locations[0].zoneId,
              });
            })
            .catch(function (error) {
              console.log('Error getting documents: ', error);
            });
        }
        db.collection('promos')
          .where('zoneId', '==', zoneId)
          .where('active', '==', true)
          .get()
          .then((querySnapshot) => {
            //Notice the arrow funtion which bind `this` automatically.
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots

              let promo = doc.data();
              promo.id = doc.id;
              promos.push(promo);
            });

            this.setState({
              promos,
            });
          });
        db.collection('packages')
          .where('zoneId', '==', zoneId)
          .get()
          .then((querySnapshot) => {
            //Notice the arrow funtion which bind `this` automatically.
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots

              let packagex = doc.data();
              packagex.packageId = doc.id;
              packages.push(packagex);
            });
            let packagesxxx = {
              packages: packages,
            };
            this.props.setPackages(packagesxxx);
          });
      }
    } else {
      //If there is a guide, use that
      if (this.state.tagFilters.length === 0) {
        this.setTags(this.props.tags, this.props.locations);
      }
      this.setState({
        locationsWithDistance: this.props.locations,
        filteredArray: this.props.locations,
        loading: false,
        landing: false,
        locationServices: true,
        toFar: false,
        // zoneId:
        //  this.props.locations[0].zoneId,
      });
      // this.setTagFilters(this.props.uauth.guide.tagFilters);
      this.searchUpdated('');
    }
  }

  // Filter Functions
  openFilterModal(user) {
    this.setState({ filterModalVisible: true });
  }

  closeFilterModal = () => {
    this.searchUpdated('');
    window.scrollTo(0, 0);
    this.setState({ filterModalVisible: false });
  };

  setTagFilters = (tagFilters) => {
    this.setState({ tagFilters });
  };

  setDistanceFilter = (value) => {
    this.setState({
      distanceFilterState: value,
    });
  };

  setMoodModalState(status) {
    this.setState({
      moodSet: status,
    });
    this.searchUpdated('');
  }

  setFirstFilter(tag) {
    let updatedTags = this.state.tagFilters;
    updatedTags[tag.parentindex].tags[tag.tagindex].checked = true;
    this.setState({
      tagFilters: updatedTags,
    });
    this.setTagFilters(updatedTags);
    this.searchUpdated(this.state.searchTerm);
    this.clearLanding(false);
    this.updateUauth(updatedTags);
  }

  getGeoLocation = (locationsx) => {
    let locations = locationsx[0];
    if (this.state.locations.length !== 0) {
      locations = this.state.locations;
    }
    let self = this;
    let currentLongitude = '';
    let currentLatitude = '';
    this.getPosition()
      .then((position) => {
        if (position.coords) {
          self.setState({
            currentLongitude: position.coords.longitude,
            currentLatitude: position.coords.latitude,
            error: null,
          });
          currentLatitude = position.coords.latitude;
          currentLongitude = position.coords.longitude;
          this.addDistance(
            locations,
            currentLongitude,
            currentLatitude,
          );
          this.searchUpdated('', locationsx);
        } else {
          alert('Geolocation is not supported by this browser.');
        }
      })
      .catch((err) => {
        console.error('something wrongg', err.message);
        self.setState({
          locationServices: false,
        });
      });
  };

  getPosition() {
    let options = {
      timeout: 50000,
      maximumAge: 10000,
      enableHighAccuracy: false,
    };
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          options,
        ),
      );
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }

  addDistance(locations, currentLongitude, currentLatitude) {
    const locationsArray = [];
    locations.forEach((location) => {
      const locLat = location.address.latitude;
      const locLong = location.address.longitude;
      if (locLong !== null) {
        const distance = geolib.getDistance(
          { latitude: locLong, longitude: locLat },
          { latitude: currentLatitude, longitude: currentLongitude },
        );
        const miles = geolib.convertUnit('mi', distance, 2); // -> 14,21
        location.address.distance = miles;
        locationsArray.push(location);
      } else {
        location.address.distance = 5;
        locationsArray.push(location);
      }
    });
    const sortedLocations = this.sortAndWeight(
      locations,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );
    this.updateUauthGuide(locationsArray, sortedLocations);
    this.setState({
      locationsWithDistance: locationsArray,
      filteredArray: sortedLocations,
      loading: false,
      locationServices: true,
    });
  }

  sortAndWeight(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    const sortedLocations = this.sortLocations(
      locations,
      distanceFilterState,
      currentLongitude,
      currentLatitude,
      sortState,
    );
    // const weightedLocations = this.getWeightedLocations(
    //   sortedLocations,
    //   this.props.profile,
    // );

    return sortedLocations;
  }

  sortLocations(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    if (sortState === 1) {
      const locationsArray = [];
      locations.forEach((location) => {
        const miles = location.address.distance;
        if (location.type !== 1) {
          if (distanceFilterState === 0) {
            if (miles <= 50) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 1) {
            if (miles <= 10) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 2) {
            locationsArray.push(location);
          }
        }
      });
      return _.sortBy(locationsArray, (o) => o.address.distance);
    } else {
      return _.sortBy(locations, (o) => o.locationTitle);
    }
  }

  searchUpdated(term, locationsx) {
    let locationsWithDistance = this.state.locationsWithDistance;
    if (this.props.locations.length !== 0) {
      locationsWithDistance = this.props.locations;
    }

    if (locationsx !== undefined) {
      let bundle = {
        locations: locationsx[0],
      };
      this.props.setLocations(bundle);
    }
    const filteredList = locationsWithDistance.filter(
      createFilter(term, KEYS_TO_FILTERS),
    );

    const filteredPromos = this.state.promos.filter(
      createFilter(term, KEYS_TO_FILTERS),
    );

    let filteredLocations = [];
    const sortedLocations = this.sortAndWeight(
      filteredList,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );

    let selectedTags = [];

    let tagFilters = this.state.tagFilters;
    if (this.props.uauth.guide.tagFilters.length !== 0) {
      tagFilters = this.props.uauth.guide.tagFilters;
    }

    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });

    if (selectedTags.length > 0) {
      filteredLocations = this.updateLocations(
        tagFilters,
        sortedLocations,
      );
    } else {
      filteredLocations = sortedLocations;
    }

    if (filteredLocations.length === 0) {
      this.setDistanceFilter(2);
      const sortedLocationsx = this.sortAndWeight(
        filteredList,
        2,
        this.state.currentLongitude,
        this.state.currentLatitude,
        this.state.sortState,
      );

      if (selectedTags.length > 0) {
        filteredLocations = this.updateLocations(
          tagFilters,
          sortedLocationsx,
        );
      } else {
        filteredLocations = sortedLocationsx;
      }
    }

    this.setState({
      searchTerm: term,
      filteredArray: filteredLocations,
      filteredPromos: filteredPromos,
    });
  }

  updateLocations(tags, locations) {
    // This will have every location but with its tags put into a single list
    const locationsReady = [];
    // For each location we push all of its tags into the allTags array
    locations.forEach((location) => {
      const newLocation = location;
      const allTags = [];
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      newLocation.tags = allTags;

      locationsReady.push(newLocation);
    });
    // const dif = _.differenceWith(cuisine, beverages, _.isEqual);
    // const filtered = _.filter(coll, item => _.some(filter, key => _.includes(item.tags, key)));

    // Filter Locations Based On Selected Categories
    function filterByMultipleFields(coll, filter) {
      // Organize Tags into their categories
      const tagsBeverages = [];
      const tagsSettings = [];
      const tagsCuisine = [];
      const tagsHours = [];
      const tagsDateType = [];
      const tagsLocationType = [];

      let beverages = [];
      let locationType = [];
      let setting = [];
      let type = [];
      let cuisine = [];
      let hours = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Hours') {
            tagsHours.push(tag.tag);
          } else if (tagcat.parent === 'Beverages') {
            tagsBeverages.push(tag.tag);
          } else if (tagcat.parent === 'Date Type') {
            tagsDateType.push(tag.tag);
          } else if (tagcat.parent === 'Cuisine Type') {
            tagsCuisine.push(tag.tag);
          } else if (tagcat.parent === 'Setting Type') {
            tagsSettings.push(tag.tag);
          } else if (tagcat.parent === 'Location Type') {
            tagsLocationType.push(tag.tag);
          }
        });
      });

      filter.forEach((tag) => {
        if (tagsBeverages.includes(tag) === true) {
          beverages = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsBeverages, key),
            ),
          );
        } else if (tagsDateType.includes(tag) === true) {
          type = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsDateType, key),
            ),
          );
        } else if (tagsLocationType.includes(tag) === true) {
          locationType = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsLocationType, key),
            ),
          );
        } else if (tagsSettings.includes(tag) === true) {
          setting = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsSettings, key),
            ),
          );
        } else if (tagsHours.includes(tag) === true) {
          hours = _.filter(locationsReady, (item) =>
            _.some(filter, (key) => _.includes(item.tagsHours, key)),
          );
        } else if (tagsCuisine.includes(tag) === true) {
          cuisine = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsCuisine, key),
            ),
          );
        }
      });
      const filtered = _.filter(coll, (item) =>
        _.some(filter, (key) => _.includes(item.tags, key)),
      );
      const arrays = [
        cuisine,
        beverages,
        type,
        setting,
        hours,
        locationType,
      ];
      const result = _.spread(_.union)(arrays);
      return result;
    }
    const filter = this.selectedTags(tags);
    const result = filterByMultipleFields(locationsReady, filter);

    return result;
    // if (this.props.selectedCategories.length > 0) {
    //   this.setState({
    //     filteredLocations: result,
    //   });
    // } else {
    //   this.setState({
    //     filteredLocations: locations,
    //   });
    // }
  }

  selectedTags(tagFilters) {
    let selectedTags = [];
    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });
    return selectedTags;
  }

  getCurrentDistance(filter) {
    let text = '';
    if (filter === 0) {
      text = '50';
    } else if (filter === 1) {
      text = '10';
    } else {
      text = '100+';
    }
    return text;
  }
  // End

  // Location Functions

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return <span className="mainTag">{location.tagsMain}</span>;
    } else {
      return <span className="mainTag" />;
    }
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getModalHeader = (value) => {
    return (
      <Row className="no-margin">
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
        <Col lg={8} md={8} sm={8} xs={8}>
          <center>
            <span className="page-header white">
              Filter Locations
            </span>
          </center>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
          <a onClick={() => this.closeFilterModal()}>
            <MaterialIcon icon="check" size={25} color="white" />
          </a>
        </Col>
      </Row>
    );
  };

  getButton(value, distance, state) {
    if (value !== state) {
      return (
        <button
          className="redbutton full-width"
          onClick={() => {
            this.setDistanceFilter(value);
          }}
        >
          {distance}
        </button>
      );
    } else {
      return (
        <button className="greybutton full-width">{distance}</button>
      );
    }
  }

  clearLanding(status) {
    this.setState({
      landing: status,
    });
  }

  getTags(tagFilters) {
    let activeTags = [];
    tagFilters.forEach((tag) => {
      tag.tags.forEach((tagx) => {
        if (tagx.checked === true) {
          activeTags.push(tagx);
        }
      });
    });
    return (
      <div>
        {activeTags.map((tag, index) => (
          <span className="tag-big" key={tag}>
            {tag.tag}
          </span>
        ))}
        <a
          onClick={() => {
            this.openFilterModal();
          }}
        >
          <span>{activeTags.length === 0 ? 'Filter' : 'Change'}</span>
        </a>
        <span
          onClick={() => {
            this.clearTagFilters(this.state.tagFilters);
          }}
          className="redlink"
          style={{ fontSize: '16px' }}
        >
          Clear
        </span>
      </div>
    );
  }

  // criteria Handlers
  selectChange = (values) => {
    if (values.value.length > 15) {
      if (values.value.slice(-5) === '-xxxx') {
        const editedText = values.value.slice(0, -5); //'abcde'
        this.props.history.push('/entertainment/' + editedText);
      } else {
        this.props.history.push('/location/' + values.value);
      }
    } else {
      this.props.history.push('/guide/category/' + values.label);
    }
    // let updatedTags = this.state.tagFilters;
    // updatedTags[values.value.parentindex].tags[
    //   values.value.tagindex
    // ].checked = true;
    // this.setState({
    //   tagFilters: updatedTags,
    // });
    // this.setTagFilters(updatedTags);
    // this.searchUpdated(this.state.searchTerm);
    // this.clearLanding(false);
    // this.updateUauth(updatedTags);
    // this.props.history.push('/guide/category/' + values.label);
  };

  updateUauth(updatedTags) {
    var date = moment();
    let bundle = {
      claimId: this.props.uauth.claimId,
      vendorId: this.props.uauth.vendorId,
      claimLink: this.props.uauth.claimLink,
      guide: {
        landing: false,
        loading: false,
        tagFilters: updatedTags,
        locationsWithDistance: [],
        filteredArray: [],
        promos: this.props.uauth.guide.promos,
        lastUpdated: date,
      },
      modalShown: this.state.modalShown,
    };
    this.props.getUauth(bundle);
  }

  updateUauthGuide(locationsWithDistance, filteredArray, promos) {
    var date = moment();
    let bundle = {
      claimId: this.props.uauth.claimId,
      vendorId: this.props.uauth.vendorId,
      claimLink: this.props.uauth.claimLink,
      guide: {
        landing: this.props.uauth.guide.landing,
        loading: this.props.uauth.guide.loading,
        tagFilters: this.props.uauth.guide.tagFilters,
        locationsWithDistance: [],
        filteredArray: [],
        lastUpdated: date,
        // promos: promos,
      },
      modalShown: this.state.modalShown,
    };
    this.props.getUauth(bundle);
  }

  updateUauthModal(state) {
    var date = moment();
    let bundle = {
      claimId: this.props.uauth.claimId,
      vendorId: this.props.uauth.vendorId,
      claimLink: this.props.uauth.claimLink,
      guide: this.props.uauth.guide,
      modalShown: state,
    };
    this.props.getUauth(bundle);
  }

  getSelectOptions() {
    let locations = [];
    if (
      this.props.locations !== undefined &&
      Array.isArray(this.props.locations) === true
    ) {
      locations = this.props.locations;
    }

    // if (
    //   this.state.locations !== undefined &&
    //   this.state.locations.length !== 0
    // ) {
    //   locations = this.state.locations;
    // } else if (
    //   this.props.uauth.guide !== undefined &&
    //  this.props.locations !== undefined &&
    //  this.props.locations.length !== 0
    // ) {
    //   locations =this.props.locations;
    // }

    let allTags = [];
    let selectOptions = [];
    locations.forEach((location) => {
      selectOptions.push({
        value: location.locationId,
        label: location.locationTitle,
      });

      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsMain !== undefined) {
        let status = allTags.includes(location.tagsMain);
        if (status === false) {
          allTags.push(location.tagsMain);
        }
      }
    });

    this.state.tagFilters.forEach((tagx, indexA) => {
      tagx.tags.forEach((tag, indexB) => {
        let status = allTags.includes(tag.tag);
        if (status === true) {
          selectOptions.push({
            value: tag,
            label: tag.tag,
          });
        }
      });
    });

    if (this.props.entertainment !== undefined) {
      this.props.entertainment.forEach((entertainer) => {
        selectOptions.push({
          value: entertainer.slug + '-xxxx',
          label: entertainer.entertainmentTitle,
        });
      });
    }

    let reversedOptions = selectOptions.reverse();
    // this.setTagFilters(updatedTagFilters);
    return reversedOptions;
  }

  setTags(tags, locations) {
    let filteredTags = this.filterTags(tags, locations);
    let updatedTags = filteredTags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = 1;
        if (this.props.user.authenticated === true) {
          // status = this.getTagStatus(tag.tag);
        }
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let drinks = {
    //   checked: false,
    //   parentindex: 6,
    //   status: 1,
    //   tag: 'Drinks',
    //   tagindex: updatedTags[6].tags.length,
    // };

    let food = {
      checked: false,
      parentindex: 1,
      status: 1,
      tag: 'Food',
      tagindex: updatedTags[1].tags.length,
    };

    // updatedTags[6].tags.push(drinks);
    updatedTags[1].tags.push(food);
    updatedTags.forEach((tagcat, x) => {
      updatedTags[x].tags = updatedTags[x].tags.sort((a, b) =>
        a.tag.localeCompare(b.tag),
      );
      updatedTags[x].tags = updatedTags[x].tags.reverse();
      updatedTags[x].tags.map((tag, y) => {
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let sortedTags = []
    // updatedTags.forEach((tagcat, x) => {
    //   sortedTags = _.sortBy(updatedTags[x].tags, o => o.status);
    // }

    let origionalTags = [];
    updatedTags.forEach((tagcat) => {
      origionalTags.push(tagcat);
    });

    this.setState({
      tagFilters: updatedTags,
      origionalTags,
    });
    this.setTagFilters(updatedTags);
  }

  filterTags(tagFilters, locations) {
    let updatedTagFilters = [
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
      {
        id: '5vhCJSC7VgBE1MFNctgY',
        parent: 'Location Type',
        sid: 'locationType',
        tags: [],
      },
      {
        id: '7ayttBEiZgiM5TZ2iYsM',
        parent: 'Cuisine Type',
        sid: 'cuisineType',
        tags: [],
      },
      {
        id: '7kJeYaz9m5q434zO28Pg',
        parent: 'Setting Type',
        sid: 'setting',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Experience Type',
        sid: 'experienceType',
        tags: [],
      },
      {
        id: 'BW3qPhXbN7C4XMXWh4tv',
        parent: 'Date Type',
        sid: 'dateType',
        tags: [],
      },
      {
        id: 'CSKtI08vSBtyqKr10Nef',
        parent: ' Dietary Must Haves',
        sid: 'dietary',
        tags: [],
      },
      {
        id: 'F7R2nsOyZTiqKbW6AhFQ',
        parent: 'Hours',
        sid: 'hours',
        tags: [],
      },
      {
        id: 'xeytdS8Xwjlou67MVbVY',
        parent: 'Beverages',
        sid: 'beverages',
        tags: [],
      },
      {
        id: 'rxsnb1c2XX8c4MpugeEP',
        parent: 'Genre',
        sid: 'genre',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Entertainment Type',
        sid: 'entertainmentType',
        tags: [],
      },
      {
        id: 'eAVFUFVivBObckEaKiRR',
        parent: 'Event Type',
        sid: 'eventType',
        tags: [],
      },
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
    ];
    let allTags = [];
    locations.forEach((location) => {
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      // if (location.tagsSettings !== undefined) {
      //   location.tagsSettings.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsEventType !== undefined) {
        location.tagsEventType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsMain !== undefined) {
        let status = allTags.includes(location.tagsMain);
        if (status === false) {
          allTags.push(location.tagsMain);
        }
      }
    });
    if (tagFilters != undefined) {
      tagFilters.forEach((tagx, indexA) => {
        if (tagx.tags !== undefined) {
          tagx.tags.forEach((tag, indexB) => {
            let status = allTags.includes(tag.tag);
            if (status === true) {
              updatedTagFilters[indexA].tags.push(tag);
            }
          });
        }
      });
    }

    return updatedTagFilters;

    // this.setState({
    //   tagFilters: updatedTagFilters,
    // });
    // this.setTagFilters(updatedTagFilters);
  }

  clearTagFilters(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    this.setState({
      tagFilters: updatedTags,
    });
    window.scrollTo(0, 0);
    this.searchUpdated('');
  }

  getPromos(promos) {
    let categories = [];

    // filteredPromos.forEach((promo, index) => {
    //   console.log('filteredPromos', promo);
    //   promo.tagsExperienceTypes.forEach((tag, index) => {
    //     let status = categories.includes(tag);
    //     if (status === false) {
    //       categories.push(tag);
    //     }
    //   });
    // });

    // if (filteredPromos.length > 0) {
    return (
      <div>
        <div
          className="text-center"
          style={{
            padding: '10px 0px 10px 0px',
          }}
        >
          <h4>Experiences</h4>
        </div>
        {/* {categories.map((tag, index) => (
            <div>
              <h5>{tag}s</h5>
              {this.getPromoGroup(tag, filteredPromos)}
            </div>
          ))} */}
        <Row>{this.getDemoPromos(promos)}</Row>
      </div>
    );

    // } else {
    //   return <div></div>;
    // }
  }

  filterPromos(promos) {
    let filteredPromos = [];
    let promoArray = [];

    this.props.demo.demoPromos.forEach((promo, index) => {
      filteredPromos.push(promo);
      promoArray.push(promo);
    });

    promos.forEach((promo, index) => {
      promoArray.push(promo);
      let status = false;
      promo.locations.forEach((location, index) => {
        const locationDetails = query('locationId')
          .is(location.locationId)
          .on(this.state.locations);
        locationDetails[0].tags.forEach((tag, index) => {
          this.state.tagFilters.forEach((tagx, index) => {
            tagx.tags.forEach((tagxx) => {
              if (tagxx.checked === true && tagxx.tag === tag) {
                status = true;
              }
            });
          });
        });
      });
      if (status === true) {
        filteredPromos.push(promo);
      }
    });

    let selectedTags = [];
    this.state.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });

    if (selectedTags.length > 0) {
      return filteredPromos;
    } else {
      return promoArray;
    }
  }

  getPromoGroup(tag, promos) {
    let promoGroup = [];
    promos.forEach((promo, index) => {
      promo.tagsExperienceTypes.forEach((tagx, index) => {
        if (tagx === tag) {
          promoGroup.push(promo);
        }
      });
    });
    return (
      <div>
        {promoGroup.map((promo, index) => (
          <div>{this.getPromo(promo)}</div>
        ))}
      </div>
    );
  }

  getExperienceTags(experience) {
    const experienceTypes = [];
    if (experience.tagsExperienceTypes !== undefined) {
      experience.tagsExperienceTypes.forEach((tag) => {
        experienceTypes.push({
          value: tag,
          label: tag,
        });
      });
    }
    return (
      <div>
        {experienceTypes &&
          experienceTypes.map((tag, index) => (
            <span className="experienceTag" key={tag.label}>
              {tag.label}
            </span>
          ))}
      </div>
    );
  }

  getPromoLocations(event) {
    return (
      <ul id="horizontalTitles">
        {event.locations &&
          event.locations.map((location, index) => (
            <li key={location.locationId}>
              {location.locationTitle}
            </li>
          ))}
      </ul>
    );
  }

  getDemoPromos(promos) {
    let promoArray = [];
    promos.forEach((promo) => {
      if (
        promo.id === '1gU7CfzO7Kkm2krYy4wA' ||
        promo.id === 'FA4wBUbKTbXDIZArBUFg'
      ) {
        promoArray.push(promo);
      }
    });
    return (
      <div>
        {promoArray.map((promo, index) => (
          <Col
            xs={12}
            md={6}
            sm={12}
            lg={6}
            key={promo.id}
            className="guidePromo"
          >
            {this.getPromo(promo)}
          </Col>
        ))}
      </div>
    );
  }

  getPromo(event) {
    const withNoDigits = event.id.replace(/[0-9]/g, '');
    const toggler = '#' + withNoDigits;
    let demo = false;
    if (event.demo === true) {
      demo = true;
    }
    let demoDetails = [];
    if (event.demo === true) {
      demoDetails = event;
    }
    return (
      // <Card style={{ border: '0px solid rgba(0, 0, 0, 0.125)' }}>

      <Card style={{ height: '100%' }}>
        <Link
          to={{
            pathname: '/vevent/' + event.id,
            state: {
              demo,
              demoDetails,
            },
          }}
          className="left"
        >
          <CardImg
            top
            width="100%"
            src={event.images.heroPath}
            alt="articleHero"
          />
        </Link>
        <CardBody>
          <Container>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
                style={{ textAlign: 'left' }}
              >
                <span className="title" style={{ fontSize: '17px' }}>
                  {event.title}
                </span>
                <span className="promo-criteria">
                  {event.description}
                </span>

                <div className="clear">
                  {this.getPromoLocations(event)}
                </div>
                <div style={{ marginBottom: '10px', clear: 'both' }}>
                  {this.getExperienceTags(event)}
                </div>
              </Col>
            </Row>
          </Container>
          {/* <UncontrolledCollapse toggler={toggler}>
            <div className="card-section">{event.teaser}</div>
          </UncontrolledCollapse> */}
        </CardBody>
      </Card>
    );
  }

  closeSignUpModal() {
    this.setState({ signUpModalVisible: false });
  }

  getPromotedTag(tagTerm) {
    let term = tagTerm;
    if (tagTerm === 'Parks & Recreation') {
      term = 'Park';
    }

    let tagFilters = this.state.tagFilters;
    let tagMatch = null;
    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (term === tag.tag) {
          tagMatch = tag;
        }
      });
    });
    if (tagMatch !== null) {
      return (
        <li
          onClick={() => {
            this.setFirstFilter(tagMatch);
          }}
        >
          <span>{tagTerm}</span>
        </li>
      );
    }
  }

  getTruncatedBody(article) {
    if (article.teaser !== undefined) {
      return article.teaser;
    } else {
      const length = 130;
      const truncatedString =
        article.body.substring(0, length) + '...';
      return truncatedString;
    }
  }

  getArticles() {
    let articleArray = [];
    this.props.articles.forEach((article) => {
      if (article.zoneIds !== undefined) {
        let match = article.zoneIds.includes(11);
        if (match === true && article.promoted === true) {
          articleArray.push(article);
        }
      }
    });

    return (
      <Row>
        {articleArray.map((article, index) => (
          <Col xs={12} md={6} sm={12} lg={6} key={'key' + index}>
            <Link
              to={{
                pathname: '/article/' + article.slug,
              }}
            >
              <div>
                <Card style={{ textAlign: 'left' }}>
                  <CardImg
                    top
                    width="100%"
                    src={article.hero}
                    alt="articleHero"
                  />
                  <CardBody>
                    <CardTitle>{article.title}</CardTitle>
                    {/* <small className="text-muted right">
                      {this.getPostDate(article.postdate)}
                    </small> */}
                    <p className="author">
                      {/* {article.author} - {this.getPostDate(article.postdate)} */}
                      {article.author}
                    </p>
                    <CardText>
                      {this.getTruncatedBody(article)}
                    </CardText>
                    <CardText />
                  </CardBody>
                </Card>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    );
  }

  getFilterModal() {
    if (this.state.tagFilters.length !== 0) {
      return (
        <FilterModal
          tagFilters={this.state.tagFilters}
          setTagFilters={this.setTagFilters}
          locations={this.state.locations}
          // selectOptions={selectOptions}
        />
      );
    }
  }

  getSideArticles(articles) {
    let articalArray = [];
    articles.forEach((article) => {
      if (article.zoneIds !== undefined) {
        let match = article.zoneIds.includes(11);
        if (match === true && article.promoted === true) {
          articalArray.push(article);
        }
      }
    });
    return (
      <div>
        {articalArray.map((article, index) => (
          <div>
            <Link
              to={{
                pathname: '/article/' + article.slug,
              }}
              className="left"
            >
              <Card style={{ textAlign: 'left' }}>
                <CardImg
                  top
                  width="100%"
                  src={article.hero}
                  alt="articleHero"
                />
                <CardBody>
                  <CardTitle>{article.title}</CardTitle>
                  {/* <small className="text-muted right">
                      {this.getPostDate(article.postdate)}
                    </small> */}
                  <p className="author">
                    {/* {article.author} - {this.getPostDate(article.postdate)} */}
                    {article.author}
                  </p>
                  <CardText>
                    {this.getTruncatedBody(article)}
                  </CardText>
                  <CardText />
                </CardBody>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    );
  }

  getSignupBanner() {
    let backgroundUrl = '';

    if (
      this.props.uauth !== undefined &&
      this.props.uauth.guide !== undefined &&
      this.props.locations[0] !== undefined &&
      this.props.locations[0].zoneId !== undefined &&
      this.props.locations[0].zoneId === 11
    ) {
      backgroundUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fstllanding.jpg?alt=media&token=2d50daf2-b14b-4dc3-9a04-358d2c764380';
    } else {
      backgroundUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fkclanding.jpg?alt=media&token=b8d9d9a8-5760-40ec-8101-ac66788aaf41';
    }

    let backgroundStyle = {
      backgroundImage: ' url(' + backgroundUrl + ')',
      backgroundSize: 'cover',

      backgroundPosition: 'center',
      position: 'relative',
    };
    return (
      // <div style={backgroundStyle} className="trevelBanner">
      //   <div className="trevelBannerText">
      //     <span
      //       style={{
      //         display: 'block',
      //         clear: 'both',
      //         color: 'white',
      //       }}
      //       className="trevelBannerTop"
      //     >
      //       Connect With Friends To Find
      //       <br />
      //       The Perfect Experience
      //     </span>
      //     <div style={{ maxWidth: '215px', margin: '0px auto' }}>
      //       <Link
      //         to={{
      //           pathname: '/about',
      //         }}
      //         className="whitebuttonNoStack  right"
      //       >
      //         <span>Learn More</span>
      //       </Link>
      //       <Link
      //         to={{
      //           pathname: '/signup',
      //         }}
      //         className="whitebuttonNoStack  right"
      //         style={{ fontWeight: '800 ', marginRight: '10px' }}
      //       >
      //         <span style={{ fontWeight: '800 ' }}>Sign Up</span>
      //       </Link>
      //     </div>
      //   </div>
      // </div>
      <div className="bannerMain">
        <div className="bannerWrap">
          <div className="bannerImageWrap">
            <img src={bannerback} className="bannerImage" />
            <div className="bannerGradient"></div>
          </div>

          <div
            style={{
              position: 'absolute',
              top: '0px',
              width: '100%',
            }}
          >
            <div
              style={{
                maxWidth: 'calc(100% - 4rem)',
                margin: 'auto',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
                boxSizing: 'border-box',
                justifyContent: 'center',
              }}
              className="bannerText"
            >
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="no-padding"
                >
                  <div>
                    <span
                      style={{
                        fontWeight: '600',
                        fontSize: '30px',
                        display: 'block',
                        clear: 'both',
                        marginBottom: '5px',
                        color: '#f21b59',
                      }}
                    >
                      Welcome to Trevel!
                    </span>
                    <p
                      style={{
                        fontWeight: '500',
                        fontSize: '20px',
                        color: '#343434',
                      }}
                    >
                      As your{' '}
                      <span
                        style={{
                          color: '#f21b59',
                          fontWeight: '600',
                          fontSize: '20px',
                        }}
                      >
                        Trevel Agent
                      </span>{' '}
                      and personal concierge, my mission is to ensure
                      every experience you have is one you can truly
                      revel in.
                    </p>
                  </div>
                  <div
                    style={{
                      // backgroundColor: 'white',
                      // border: '4px solid #f3205c',
                      marginTop: '40px',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '600',
                        fontSize: '20px',
                        color: 'rgb(52, 52, 52)',
                      }}
                    >
                      Ready to plan your next excursion?
                    </span>
                    <div style={{ padding: '10px 0px 10px 0px' }}>
                      <Link
                        to={{
                          pathname: '/signup',
                        }}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <span
                          style={{
                            padding: '10px',
                            backgroundColor: '#f3205c',
                            color: 'white',
                            display: 'block',
                            marginBottom: '10px',
                            clear: 'both',
                            width: '100%',
                            fontWeight: '600',
                          }}
                        >
                          Create Free Account
                        </span>
                      </Link>
                      <Link
                        to={{
                          pathname: '/about',
                        }}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <span
                          style={{
                            padding: '10px',
                            backgroundColor: 'white',
                            color: '#f3205c',
                            display: 'block',
                            marginBottom: '10px',
                            clear: 'both',
                            width: '100%',
                            border: '1px solid #f3205c',
                            fontWeight: '600',
                          }}
                        >
                          Learn More
                        </span>
                      </Link>
                      <Link
                        to={{
                          pathname: '/login',
                        }}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <span
                          style={{
                            padding: '10px',
                            backgroundColor: 'white',
                            color: '#f3205c',
                            display: 'block',
                            marginBottom: '10px',
                            clear: 'both',
                            width: '100%',
                            border: '1px solid #f3205c',
                            fontWeight: '600',
                            cursor: 'pointer',
                          }}
                        >
                          Sign In
                        </span>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getCommunityBanner() {
    let backgroundUrl = '';

    if (
      this.props.uauth !== undefined &&
      this.props.uauth.guide !== undefined &&
      this.props.locations[0] !== undefined &&
      this.props.locations[0].zoneId !== undefined &&
      this.props.locations[0].zoneId === 11
    ) {
      backgroundUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fstllanding.jpg?alt=media&token=2d50daf2-b14b-4dc3-9a04-358d2c764380';
    } else {
      backgroundUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fkclanding.jpg?alt=media&token=b8d9d9a8-5760-40ec-8101-ac66788aaf41';
    }

    let backgroundStyle = {
      backgroundImage: ' url(' + backgroundUrl + ')',
      backgroundSize: 'cover',

      backgroundPosition: 'center',
      position: 'relative',
    };
    return (
      <div style={backgroundStyle} className="trevelBanner">
        <div className="trevelBannerText">
          <span
            style={{
              display: 'block',
              clear: 'both',
              color: 'white',
            }}
            className="trevelBannerTop"
          >
            {this.props.uauth !== undefined &&
            this.props.uauth.guide !== undefined &&
            this.props.locations[0] !== undefined &&
            this.props.locations[0].zoneId !== undefined &&
            this.props.locations[0].zoneId === 11
              ? 'St. Louis'
              : 'Kansas City'}
          </span>
          <span
            style={{
              display: 'block',
              clear: 'both',
              color: 'white',
            }}
            className="trevelBannerBottom"
          >
            Community Recovery
          </span>
        </div>
      </div>
    );
  }

  getTrevelBanner() {
    let backgroundUrl = '';

    if (
      this.props.uauth !== undefined &&
      this.props.uauth.guide !== undefined &&
      this.props.locations[0] !== undefined &&
      this.props.locations[0].zoneId !== undefined &&
      this.props.locations[0].zoneId === 11
    ) {
      backgroundUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fstllanding.jpg?alt=media&token=2d50daf2-b14b-4dc3-9a04-358d2c764380';
    } else {
      backgroundUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fkclanding.jpg?alt=media&token=b8d9d9a8-5760-40ec-8101-ac66788aaf41';
    }

    let backgroundStyle = {
      backgroundImage: ' url(' + backgroundUrl + ')',
      backgroundSize: 'cover',

      backgroundPosition: 'center',
      position: 'relative',
    };

    return (
      <div style={backgroundStyle} className="trevelBanner">
        <div className="trevelBannerText">
          <span
            style={{
              display: 'block',
              clear: 'both',
              color: 'white',
            }}
            className="trevelBannerTop"
          >
            {this.props.uauth !== undefined &&
            this.props.uauth.guide !== undefined &&
            this.props.locations[0] !== undefined &&
            this.props.locations[0].zoneId !== undefined &&
            this.props.locations[0].zoneId === 11
              ? 'St. Louis'
              : 'Kansas City'}
          </span>
          <span
            style={{
              display: 'block',
              clear: 'both',
              color: 'white',
            }}
            className="trevelBannerBottom"
          >
            Dining & Entertainment
          </span>
        </div>
      </div>
    );
  }

  weightMusic(
    timedEvents,
    userTagsLove,
    userTagsHate,
    otherUserTagsLove,
    otherUserTagsHate,
    catTitle,
    from,
  ) {
    if (catTitle === 'Top Entertainment Picks') {
      console.log('weightedItems timedEvents', timedEvents);
    }
    let weightedEvents = [];

    let timedEventsToday = [];
    let eventIds = [];

    // If there is a date selected, filter by dates
    if (
      this.props.customEvent.event.scheduledTime.date !== null &&
      this.props.customEvent.event.scheduledTime.date !== ''
    ) {
      function isSameWeek(firstDay, secondDay, offset) {
        var firstMoment = moment(firstDay);
        var secondMoment = moment(secondDay);

        var startOfWeek = function (_moment, _offset) {
          return _moment.add(
            'days',
            _moment.weekday() * -1 +
              (_moment.weekday() >= 7 + _offset
                ? 7 + _offset
                : _offset),
          );
        };

        return startOfWeek(firstMoment, offset).isSame(
          startOfWeek(secondMoment, offset),
          'day',
        );
      }

      timedEvents.forEach((event) => {
        if (event.scheduledTime !== undefined) {
          if (
            event.scheduledTime.dates.repeat !== undefined &&
            event.scheduledTime.dates.repeat === true
          ) {
            event.scheduledTime.dates.multiDates.forEach((date) => {
              if (
                this.props.customEvent.event.scheduledTime
                  .checkMonth === false &&
                this.props.customEvent.event.scheduledTime
                  .checkWeek === false &&
                this.props.customEvent.event.scheduledTime.date !==
                  '' &&
                this.props.customEvent.event.scheduledTime.date !==
                  null
              ) {
                let today = moment(date.timestamp).isSame(
                  this.props.customEvent.event.scheduledTime.date,
                  'day',
                );
                if (today === true) {
                  let status = eventIds.includes(event.id);
                  if (status === false) {
                    timedEventsToday.push(event);
                    eventIds.push(event.id);
                  }
                }
              } else if (
                this.props.customEvent.event.scheduledTime
                  .checkWeek === true &&
                this.props.customEvent.event.scheduledTime.date !== ''
              ) {
                let sameWeek = isSameWeek(
                  date.timestamp,
                  this.props.customEvent.event.scheduledTime.date,
                  7,
                );
                if (sameWeek === true) {
                  let status = eventIds.includes(event.id);
                  if (status === false) {
                    timedEventsToday.push(event);
                    eventIds.push(event.id);
                  }
                }
              } else if (
                this.props.customEvent.event.scheduledTime
                  .checkMonth === true &&
                this.props.customEvent.event.scheduledTime.date !== ''
              ) {
                let sameMonth = moment(date.timestamp).isSame(
                  this.props.customEvent.event.scheduledTime.date,
                  'month',
                );

                if (sameMonth === true) {
                  let status = eventIds.includes(event.id);
                  if (status === false) {
                    timedEventsToday.push(event);
                    eventIds.push(event.id);
                  }
                }
              }
            });
          } else {
            if (
              this.props.customEvent.event.scheduledTime
                .checkMonth === false &&
              this.props.customEvent.event.scheduledTime.checkWeek ===
                false &&
              this.props.customEvent.event.scheduledTime.date !==
                '' &&
              this.props.customEvent.event.scheduledTime.date !== null
            ) {
              let today = moment(
                event.scheduledTime.dates.startDate,
              ).isSame(
                this.props.customEvent.event.scheduledTime.date,
                'day',
              );
              if (today === true) {
                let status = eventIds.includes(event.id);
                if (status === false) {
                  timedEventsToday.push(event);
                  eventIds.push(event.id);
                }
              }
            } else if (
              this.props.customEvent.event.scheduledTime.checkWeek ===
                true &&
              this.props.customEvent.event.scheduledTime.date !== ''
            ) {
              let sameWeek = isSameWeek(
                event.scheduledTime.dates.startDate,
                this.props.customEvent.event.scheduledTime.date,
                7,
              );
              if (sameWeek === true) {
                let status = eventIds.includes(event.id);
                if (status === false) {
                  timedEventsToday.push(event);
                  eventIds.push(event.id);
                }
              }
            } else if (
              this.props.customEvent.event.scheduledTime
                .checkMonth === true &&
              this.props.customEvent.event.scheduledTime.date !== ''
            ) {
              let sameMonth = moment(
                event.scheduledTime.dates.startDate,
              ).isSame(
                this.props.customEvent.event.scheduledTime.date,
                'month',
              );

              if (sameMonth === true) {
                let status = eventIds.includes(event.id);
                if (status === false) {
                  timedEventsToday.push(event);
                  eventIds.push(event.id);
                }
              }
            }
          }
        }
      });
    } else {
      timedEventsToday = timedEvents;
    }

    timedEventsToday.forEach((event) => {
      let today = moment();

      let eventAfterStatus = moment(
        event.scheduledTime.dates.endDate,
      ).isAfter(today, 'day');

      let eventTodayStatus = moment(
        event.scheduledTime.dates.startDate,
      ).isSame(today, 'day');

      if (eventTodayStatus === true || eventAfterStatus === true) {
        let weightedEvent = event;
        let weight = 0;
        weightedEvent.matchesOtherUserLove = [];
        weightedEvent.matchesOtherUserHate = [];
        weightedEvent.matchesUserLove = [];
        weightedEvent.matchesUserHate = [];
        weightedEvent.weight = weight;

        // Other User Love
        if (otherUserTagsLove !== undefined) {
          if (event.tagsGenre !== undefined) {
            event.tagsGenre.forEach((userTag) => {
              if (otherUserTagsLove.tagsGenre !== undefined) {
                otherUserTagsLove.tagsGenre.forEach((tag) => {
                  if (userTag === tag) {
                    weightedEvent.matchesOtherUserLove.push(tag);
                    weight = weight + 10;
                  }
                });
              }
            });
          }
        }
        // Other User Hate
        if (otherUserTagsHate !== undefined) {
          if (event.tagsGenre !== undefined) {
            event.tagsGenre.forEach((userTag) => {
              if (otherUserTagsHate.tagsGenre !== undefined) {
                otherUserTagsHate.tagsGenre.forEach((tag) => {
                  if (userTag === tag) {
                    weightedEvent.matchesOtherUserHate.push(tag);
                    weight = weight - 25;
                  }
                });
              }
            });
          }
        }

        let selectedTags = [];
        this.props.taRequest.tagFilters.forEach((tagCat) => {
          tagCat.tags.forEach((tag) => {
            if (tag.checked === true) {
              selectedTags.push(tag.tag);
            }
          });
        });
        // User Love
        if (event.tagsGenre !== undefined) {
          event.tagsGenre.forEach((userTag) => {
            if (selectedTags.includes(userTag) === true) {
              weight = weight + 20;
            }
            if (userTagsLove.tagsGenre !== undefined) {
              userTagsLove.tagsGenre.forEach((tag) => {
                if (userTag === tag) {
                  weightedEvent.matchesUserLove.push(tag);
                  weight = weight + 5;
                }
              });
            }
          });
        }
        // User Hate
        if (event.tagsGenre !== undefined) {
          event.tagsGenre.forEach((userTag) => {
            if (userTagsHate.tagsGenre !== undefined) {
              userTagsHate.tagsGenre.forEach((tag) => {
                if (userTag === tag) {
                  weightedEvent.matchesUserHate.push(tag);
                  weight = weight - 25;
                }
              });
            }
          });
        }

        weightedEvent.weight = weight;
        weightedEvents.push(weightedEvent);
      }
    });

    if (catTitle === 'Top Entertainment Picks') {
      let bucketOne = [];
      let bucketTwo = [];
      let bucketThree = [];
      let bucketFour = [];
      let bucketFive = [];

      weightedEvents.forEach((event) => {
        if (event.bucket === 1) {
          bucketOne.push(event);
        } else if (event.bucket === 2) {
          bucketTwo.push(event);
        }
        if (event.bucket === 3) {
          bucketThree.push(event);
        }
        if (event.bucket === 4) {
          bucketFour.push(event);
        }
        if (event.bucket === 5) {
          bucketFive.push(event);
        }
      });

      let orderedBucketOne = _.orderBy(
        bucketOne,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketTwo = _.orderBy(
        bucketTwo,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketThree = _.orderBy(
        bucketThree,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketFour = _.orderBy(
        bucketFour,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketFive = _.orderBy(
        bucketFive,
        ['weight'],
        ['desc', 'asc'],
      );

      let topPicks = [];

      orderedBucketOne.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketTwo.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketThree.forEach((location) => {
        topPicks.push(location);
      });

      orderedBucketFour.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketFive.forEach((location) => {
        topPicks.push(location);
      });

      orderedBucketOne.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketTwo.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketThree.forEach((location) => {
        topPicks.push(location);
      });

      orderedBucketFour.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketFive.forEach((location) => {
        topPicks.push(location);
      });

      return topPicks;
    } else {
      return _.orderBy(weightedEvents, ['weight'], ['desc', 'asc']);
    }
  }

  goForth() {
    this.setState({
      toFar: false,
    });
  }

  getCategory(category, activeZoneId) {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 't' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];
    let currentDate = new Date();
    let currentDay = dayArray[currentDate.getDay()].short;
    let checkit = [];
    category.filters.zones.forEach((zoneId) => {
      if (zoneId !== '') {
        checkit.push(parseInt(zoneId));
      } else {
        checkit.push(zoneId);
      }
    });
    // If time options set, check
    let show = true;
    if (
      category.time !== undefined &&
      category.time.activeDays !== undefined &&
      category.time.activeDays.length !== 0
    ) {
      let status = category.time.activeDays.includes(currentDay);
      if (status === false) {
        show = false;
      }
    }
    if (
      category.time !== undefined &&
      category.time.toTime !== undefined
    ) {
      let currentTime = moment().format('HH:mm');
      var format = 'hh:mm:ss';
      var time = moment(currentTime, format),
        beforeTime = moment(category.time.fromTime, format),
        afterTime = moment(category.time.toTime, format);
      if (time.isBetween(beforeTime, afterTime) === false) {
        show = false;
      }
    }

    let type = 'Locations';

    let includeLocations = false;
    if (category.types !== undefined) {
      includeLocations = category.types.includes('Location');
    } else if (category.type === 'Locations') {
      includeLocations = true;
    }

    let includeEvents = false;
    if (category.types !== undefined) {
      includeEvents = category.types.includes('Events');
      if (includeEvents === true) {
        type = 'Events';
      }
    } else if (category.type === 'Events') {
      includeEvents = true;
    }

    let includeEntertainers = false;
    if (category.types !== undefined) {
      includeEntertainers = category.types.includes('Entertainers');
    } else if (category.type === 'Entertainers') {
      includeEntertainers = true;
    }

    let events = [];
    this.props.timedEvents.forEach((event) => {
      events.push(event);
    });

    if (
      (checkit.includes(activeZoneId) === true && show === true) ||
      (checkit[0] === '' && show === true)
    ) {
      return (
        <GuideCategory
          category={category}
          weightMusic={this.weightMusic}
          type={type}
          locations={this.props.locations}
          timedEvents={events}
          count={this.state.count}
          catTitle={category.title}
          currentLongitude={this.state.currentLongitude}
          currentLatitude={this.state.currentLatitude}
          carouselPosition={
            this.state[category.categoryId + 'carouselPosition'] !==
            undefined
              ? this.state[category.categoryId + 'carouselPosition']
              : 0
          }
          changeCarousel={this.changeCarousel}
          setRandomizer={this.setRandomizer}
          randomizer={
            this.state[category.categoryId + 'randomizer'] !==
            undefined
              ? this.state[category.categoryId + 'randomizer']
              : null
          }
        />
      );
    }
  }

  setRandomizer = (value, id) => {
    this.setState({ [id + 'randomizer']: value });
  };

  changeCarousel = (index, id) => {
    this.setState({ [id + 'carouselPosition']: index });
  };

  render() {
    let selectOptions = this.getSelectOptions();
    let searchTerm = this.state.searchTerm;
    let clayton = ['Clayton'];

    let geoAvailable = this.state.geoAvailable;

    let activeZoneId = 10;
    if (
      this.props.uauth !== undefined &&
      this.props.uauth.guide !== undefined &&
      this.props.locations[0] !== undefined &&
      this.props.locations[0].zoneId !== undefined
    ) {
      activeZoneId = this.props.locations[0].zoneId;
    }

    if (this.state.toFar) {
      return (
        <div style={{ height: '100%' }}>
          {this.getSignupBanner()}
          <div
            style={{
              height: '100%',
              display: 'table',
              width: '100%',
            }}
          >
            <div
              style={{
                position: 'relative',
                textAlign: 'center',
                display: 'table-cell',
                verticalAlign: 'middle',
                padding: '0px 20px 0px 20px',
              }}
            >
              <h2
                style={{
                  fontSize: '30px',
                  lineHeight: '32px',
                  color: 'white',
                  marginBottom: '15px',
                }}
              >
                Trevel Is Coming To Your Area Soon!
              </h2>
              <span
                style={{
                  fontSize: '20px',
                  lineHeight: '32px',
                  color: 'white',
                }}
              >
                Trevel is currently live in
                <br />
                St. Louis & Kasas City
              </span>

              <div
                style={{
                  maxWidth: '400px',
                  margin: '0px auto',
                  marginTop: '30px',
                }}
              >
                <Row className="no-margin">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Link
                      to={{
                        pathname: '/about',
                      }}
                      style={{
                        display: 'block',
                        clear: 'both',
                        padding: '10px',
                        border: '1px solid white',
                        background: 'white',
                        marginBottom: '15px',
                        color: '#f21d59',
                      }}
                    >
                      Learn More
                    </Link>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div
                      style={{
                        maxWidth: '400px',
                        margin: '0px auto',
                        marginTop: '30px',
                      }}
                    >
                      <Row className="no-margin">
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="no-padding"
                        >
                          <span
                            style={{
                              fontSize: '18px',
                              lineHeight: '25px',
                              color: 'white',
                              maxWidth: '700px',
                              margin: '0px auto',
                              display: 'block',
                              marginBottom: '10px',
                            }}
                          >
                            Select a City Guide
                          </span>
                          <a
                            onClick={() => {
                              this.manualLoad(11, false);
                            }}
                            style={{
                              display: 'block',
                              clear: 'both',
                              padding: '10px',
                              border: '1px solid white',
                              background: 'white',
                              marginBottom: '15px',
                              color: '#f21d59',
                            }}
                          >
                            <span>St. Louis</span>
                          </a>
                          <a
                            onClick={() => {
                              this.manualLoad(10, false);
                            }}
                            style={{
                              display: 'block',
                              clear: 'both',
                              padding: '10px',
                              border: '1px solid white',
                              background: 'white',
                              marginBottom: '15px',
                              color: '#f21d59',
                            }}
                          >
                            <span>Kansas City</span>
                          </a>
                        </Col>
                      </Row>
                    </div>
                    {/* <a
                      style={{
                        display: 'block',
                        clear: 'both',
                        padding: '10px',
                        border: '1px solid white',
                        background: 'white',
                        marginBottom: '15px',
                        color: '#f21d59',
                      }}
                      onClick={() => {
                        this.goForth();
                      }}
                    >
                      Check Out{' '}
                      {this.state.mo === true ? 'STL' : 'KC'}
                    </a> */}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                ' #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%; background-color: #e0395b;} .footer-container{display:none}  .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }

    if (this.state.locationsWithDistance.length === 0) {
      if (geoAvailable === true) {
        return (
          <div
            style={{
              height: '100%',
            }}
          >
            {this.getSignupBanner()}
            <div
              style={{
                display: 'block',
                clear: 'both',
                backgroundColor: '#f21b5a',
              }}
            >
              <Row
                style={{
                  padding: '15px',
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="no-padding"
                >
                  <div
                    className="mobileHide"
                    style={{ height: '100px' }}
                  >
                    <div
                      style={{
                        backgroundColor: '#fbfbfb',
                        border: '2px solid #161616',
                        padding: '15px',
                        maxWidth: '900px',
                        margin: '0px auto',
                        position: 'relative',
                        top: '-220px',
                      }}
                    >
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          md={7}
                          lg={7}
                          className="no-padding"
                        >
                          <div>
                            <span
                              style={{
                                fontSize: '24px',
                                fontWeight: '600',
                                display: 'block',
                                clear: 'both',
                                color: '#f21b59',
                              }}
                            >
                              Do you use Dating Apps?
                            </span>
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: '500',
                              }}
                            >
                              Trevel can make sure your experience is
                              great, even if the date isn't.
                            </span>
                            <div style={{ marginTop: '20px' }}>
                              <p>
                                By creating a{' '}
                                <span style={{ fontWeight: '600' }}>
                                  free account
                                </span>
                                , I can provide location and event
                                recommendations for any occasion,
                                whether you are finding a happy hour
                                after work, going on a date, or
                                meeting up with friends on the
                                weekend. All while keeping your data
                                private.
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={5}
                          lg={5}
                          className="no-padding"
                        >
                          <div
                            style={{
                              width: '100%',
                              marginTop: '0px',
                              marginBottom: '10px',
                              padding: '10px',
                            }}
                          >
                            <img
                              src={datingapp}
                              style={{
                                maxWidth: '400px',
                                display: 'block',
                                width: '100%',
                                maxHeight: '250px',
                              }}
                              className="center"
                            />
                          </div>
                          <center>
                            <Link
                              to={{
                                pathname: '/about',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '20px',
                                  fontWeight: '500',
                                }}
                              >
                                Learn More
                              </span>
                            </Link>
                          </center>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div
                    style={{ height: '400px' }}
                    className="mobileShow"
                  >
                    <div
                      style={{
                        backgroundColor: '#fbfbfb',
                        border: '2px solid #161616',
                        padding: '15px',
                        maxWidth: '900px',
                        margin: '0px auto',
                        position: 'relative',
                        top: '-200px',
                      }}
                    >
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          md={7}
                          lg={7}
                          className="no-padding"
                        >
                          <div>
                            <span
                              style={{
                                fontSize: '24px',
                                fontWeight: '600',
                                display: 'block',
                                clear: 'both',
                                color: '#f21b59',
                              }}
                            >
                              Do you use Dating Apps?
                            </span>
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: '500',
                              }}
                            >
                              Trevel can make sure your experience is
                              great, even if the date isn't.
                            </span>
                            <div style={{ marginTop: '20px' }}>
                              <p>
                                By creating a{' '}
                                <span style={{ fontWeight: '600' }}>
                                  free account
                                </span>
                                , I can provide location and event
                                recommendations for any occasion,
                                whether you are finding a happy hour
                                after work, going on a date, or
                                meeting up with friends on the
                                weekend. All while keeping your data
                                private.
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={5}
                          lg={5}
                          className="no-padding"
                        >
                          <div
                            style={{
                              width: '100%',
                              marginTop: '10px',
                              marginBottom: '10px',
                              padding: '10px',
                            }}
                          >
                            <img
                              src={datingapp}
                              style={{
                                maxWidth: '400px',
                                display: 'block',
                                width: '100%',
                                maxHeight: '250px',
                              }}
                              className="center"
                            />
                          </div>
                          <center>
                            <Link
                              to={{
                                pathname: '/about',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '20px',
                                  fontWeight: '500',
                                }}
                              >
                                Learn More
                              </span>
                            </Link>
                          </center>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div
              style={{
                height: '100%',
                display: 'table',
                width: '100%',
                background:
                  'linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%)',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  textAlign: 'center',
                  display: 'table-cell',
                  // verticalAlign: 'middle',
                  // top: '-100px',
                }}
              >
                {/* <h2
                  style={{
                    margin: '120px 0px 10px 0px',
                    color: 'white',
                    fontSize: '40px',
                    textShadow: '#bb4a65 1px 4px',
                  }}
                >
                  <img
                    style={{
                      width: '90%',
                      maxWidth: '500px',
                      margin: '0px auto',
                    }}
                    src={whitelogo}
                    className="mobileHide"
                  />
                  <img
                    style={{
                      width: '80%',
                      maxWidth: '500px',
                      margin: '0px auto',
                    }}
                    src={whitelogo}
                    className="mobileShow"
                  />
                </h2> */}
                {/* <span
                  style={{
                    fontSize: '35px',
                    lineHeight: '40px',
                    color: 'white',
                    textShadow: '#bb4a65 1px 4px',
                    // marginLeft: '30px',
                    paddingTop: '25px',
                  }}
                  className="mobileHide"
                > */}
                {/* Creating a Guide
                <br />
                just for you */}
                {/* Welcome! */}
                {/* </span>
                <div
                  style={{
                    width: '15px, margin: 0px auto',
                    position: 'absolute',
                    bottom: '20px',
                    left: '50%',
                  }}
                >
                  <div className="dot-bricks mobileHide" />
                </div>
                <span
                  style={{
                    fontSize: '30px',
                    lineHeight: '40px',
                    color: 'white',
                    textShadow: '#bb4a65 1px 4px',
                    paddingTop: '15px',
                  }}
                  className="mobileShow"
                > */}
                {/* Creating a Guide
                <br />
                just for you */}
                {/* Welcome!
                </span> */}
                <div
                  style={{
                    width: '15px, margin: 0px auto',
                    position: 'absolute',
                    bottom: '20px',
                    left: '50%',
                  }}
                >
                  <div className="dot-bricks mobileShow" />
                </div>
                <div
                  style={{
                    maxWidth: '400px',
                    margin: '0px auto',
                    marginTop: '30px',
                  }}
                >
                  <Row>
                    <div style={{ paddingBottom: '10px' }}>
                      <center>
                        <span
                          style={{
                            fontSize: '18px',
                            color: 'white',
                            fontWeight: '600',
                          }}
                        >
                          Not a member yet?
                          <br /> Maybe I can help you find something
                          anyway.
                        </span>
                      </center>
                    </div>
                  </Row>
                  <Row className="no-margin">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <span
                        style={{
                          fontSize: '18px',
                          lineHeight: '25px',
                          color: 'white',
                          maxWidth: '700px',
                          margin: '0px auto',
                          display: 'block',
                          marginBottom: '10px',
                        }}
                      >
                        Where are you?
                      </span>
                      <a
                        onClick={() => {
                          this.manualLoad(11, false);
                        }}
                        style={{
                          display: 'block',
                          clear: 'both',
                          padding: '10px',
                          border: '1px solid white',
                          background: 'white',
                          marginBottom: '15px',
                          color: '#f21d59',
                        }}
                      >
                        <span>St. Louis</span>
                      </a>
                      <a
                        onClick={() => {
                          this.manualLoad(10, false);
                        }}
                        style={{
                          display: 'block',
                          clear: 'both',
                          padding: '10px',
                          border: '1px solid white',
                          background: 'white',
                          marginBottom: '15px',
                          color: '#f21d59',
                        }}
                      >
                        <span>Kansas City</span>
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {this.state.count < 3 ? (
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.nav-signup { display: block } .maincontent{top: 80px !important; margin-top:0px}',
                }}
              />
            ) : (
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.nav-signup { display: block } .maincontent{top: 49px !important; margin-top:0px}',
                }}
              />
            )}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '.footer-container{display: block.toolbar{  height: 55px}.toolbar__navigation{max-width:none}.container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important; display: block} #authleft {display:none;}   .maincontent {  margin-top: 0px; height: 100%} #bottomNav span{color: white !important}#bottomNav .material-icons{color: white !important} #bottomNav{     padding-top:15px; height: 80px; background: linear-gradient(180deg, rgba(242,29,89,1) 0%, rgb(162 8 51) 78%))  !important; border: 0px; background: rgb(242,29,89); width:100% !important; left: 0} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              }}
            />
          </div>
        );
      } else {
        return (
          <div style={{ height: '100%' }}>
            <div
              style={{
                height: '100%',
                display: 'table',
                width: '100%',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  textAlign: 'center',
                  display: 'table-cell',
                  verticalAlign: 'middle',
                  padding: '0px 20px 0px 20px',
                }}
              >
                <h2
                  style={{
                    fontSize: '25px',
                    lineHeight: '32px',
                    color: 'white',
                    marginBottom: '15px',
                  }}
                >
                  We Can't Find Your Location
                </h2>
                <div
                  style={{
                    maxWidth: '400px',
                    margin: '0px auto',
                    marginTop: '30px',
                  }}
                >
                  <Row className="no-margin">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                    >
                      <span
                        style={{
                          fontSize: '18px',
                          lineHeight: '25px',
                          color: 'white',
                          maxWidth: '700px',
                          margin: '0px auto',
                          display: 'block',
                          marginBottom: '10px',
                        }}
                      >
                        Select a City Guide
                      </span>
                      <a
                        onClick={() => {
                          this.manualLoad(11, false);
                        }}
                        style={{
                          display: 'block',
                          clear: 'both',
                          padding: '10px',
                          border: '1px solid white',
                          background: 'white',
                          marginBottom: '15px',
                          color: '#f21d59',
                        }}
                      >
                        <span>St. Louis</span>
                      </a>
                      <a
                        onClick={() => {
                          this.manualLoad(10, false);
                        }}
                        style={{
                          display: 'block',
                          clear: 'both',
                          padding: '10px',
                          border: '1px solid white',
                          background: 'white',
                          marginBottom: '15px',
                          color: '#f21d59',
                        }}
                      >
                        <span>Kansas City</span>
                      </a>
                    </Col>
                  </Row>
                </div>
                <span
                  style={{
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: 'white',
                    maxWidth: '700px',
                    margin: '0px auto',
                    display: 'block',
                    marginTop: '20px',
                  }}
                >
                  Your Location Services might be turned off or
                  blocked on this browser. You will need to change
                  these settings and refresh this page if you want to
                  find places near you.
                </span>

                <div
                  style={{
                    maxWidth: '400px',
                    margin: '0px auto',
                    marginTop: '30px',
                  }}
                >
                  <a
                    href="/"
                    style={{
                      display: 'block',
                      clear: 'both',
                      padding: '10px',
                      border: '1px solid white',
                      background: 'white',
                      marginBottom: '15px',
                      color: '#f21d59',
                    }}
                  >
                    Refresh
                  </a>
                </div>
              </div>
            </div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ' #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%; background-color: #e0395b;} .footer-container{display:none}  .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              }}
            />
          </div>
        );
      }
    }

    if (
      this.props.uauth !== undefined &&
      this.props.uauth.guide !== undefined &&
      this.props.locations[0] !== undefined
    ) {
      return (
        <div>
          <div id="auth-home">
            {/* <div>
                {this.props.packages !== undefined && <DailySpotlight />}
              </div> */}
            {/* 
            <Carousel
              showThumbs={false}
              centerSlidePercentage={100}
              // selectedItem={this.getCount(promotedLocations)}
              showIndicators={false}
              showStatus={false}
              showArrows={true}
              infiniteLoop={true}
              centerMode={true}
              swipeScrollTolerance={50}
              preventMovementUntilSwipeScrollTolerance={true}
              // autoPlay={true}
            > */}
            <div>{this.getSignupBanner()}</div>
            <div
              style={{
                display: 'block',
                clear: 'both',
                backgroundColor: '#f21b5a',
              }}
            >
              <Row
                style={{
                  padding: '15px',
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="no-padding"
                >
                  <div
                    className="mobileHide"
                    style={{ height: '100px' }}
                  >
                    <div
                      style={{
                        backgroundColor: '#fbfbfb',
                        border: '2px solid #161616',
                        padding: '15px',
                        maxWidth: '900px',
                        margin: '0px auto',
                        position: 'relative',
                        top: '-220px',
                      }}
                    >
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          md={7}
                          lg={7}
                          className="no-padding"
                        >
                          <div>
                            <span
                              style={{
                                fontSize: '24px',
                                fontWeight: '600',
                                display: 'block',
                                clear: 'both',
                                color: '#f21b59',
                              }}
                            >
                              Do you use Dating Apps?
                            </span>
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: '500',
                              }}
                            >
                              Trevel can make sure your experience is
                              great, even if the date isn't.
                            </span>
                            <div style={{ marginTop: '20px' }}>
                              <p>
                                By creating a{' '}
                                <span style={{ fontWeight: '600' }}>
                                  free account
                                </span>
                                , I can provide location and event
                                recommendations for any occasion,
                                whether you are finding a happy hour
                                after work, going on a date, or
                                meeting up with friends on the
                                weekend. All while keeping your data
                                private.
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={5}
                          lg={5}
                          className="no-padding"
                        >
                          <div
                            style={{
                              width: '100%',
                              marginTop: '0px',
                              marginBottom: '10px',
                              padding: '10px',
                            }}
                          >
                            <img
                              src={datingapp}
                              style={{
                                maxWidth: '400px',
                                display: 'block',
                                width: '100%',
                                maxHeight: '250px',
                              }}
                              className="center"
                            />
                          </div>
                          <center>
                            <Link
                              to={{
                                pathname: '/about',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '20px',
                                  fontWeight: '500',
                                }}
                              >
                                Learn More
                              </span>
                            </Link>
                          </center>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div
                    style={{ height: '400px' }}
                    className="mobileShow"
                  >
                    <div
                      style={{
                        backgroundColor: '#fbfbfb',
                        border: '2px solid #161616',
                        padding: '15px',
                        maxWidth: '900px',
                        margin: '0px auto',
                        position: 'relative',
                        top: '-200px',
                      }}
                    >
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          md={7}
                          lg={7}
                          className="no-padding"
                        >
                          <div>
                            <span
                              style={{
                                fontSize: '24px',
                                fontWeight: '600',
                                display: 'block',
                                clear: 'both',
                                color: '#f21b59',
                              }}
                            >
                              Do you use Dating Apps?
                            </span>
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: '500',
                              }}
                            >
                              Trevel can make sure your experience is
                              great, even if the date isn't.
                            </span>
                            <div style={{ marginTop: '20px' }}>
                              <p>
                                By creating a{' '}
                                <span style={{ fontWeight: '600' }}>
                                  free account
                                </span>
                                , I can provide location and event
                                recommendations for any occasion,
                                whether you are finding a happy hour
                                after work, going on a date, or
                                meeting up with friends on the
                                weekend. All while keeping your data
                                private.
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={5}
                          lg={5}
                          className="no-padding"
                        >
                          <div
                            style={{
                              width: '100%',
                              marginTop: '10px',
                              marginBottom: '10px',
                              padding: '10px',
                            }}
                          >
                            <img
                              src={datingapp}
                              style={{
                                maxWidth: '400px',
                                display: 'block',
                                width: '100%',
                                maxHeight: '250px',
                              }}
                              className="center"
                            />
                          </div>
                          <center>
                            <Link
                              to={{
                                pathname: '/about',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '20px',
                                  fontWeight: '500',
                                }}
                              >
                                Learn More
                              </span>
                            </Link>
                          </center>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/* <div>{this.getCommunityBanner()}</div>
              <div>{this.getTrevelBanner()}</div> */}
            {/* </Carousel> */}

            <div className="homeMoodBar">
              <Row style={{ margin: '0px 0px 0px 0px' }}>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  // className="no-padding"
                  style={{ padding: '5px 5px 5px 5px' }}
                >
                  <div style={{ paddingBottom: '10px' }}>
                    <center>
                      <span
                        style={{
                          fontSize: '18px',
                          color: 'white',
                          fontWeight: '600',
                        }}
                      >
                        Not a member yet?
                        <br /> Maybe I can help you find something
                        anyway.
                      </span>
                    </center>
                  </div>
                  <div
                    style={{
                      width: '350px',
                      margin: '0px auto',
                    }}
                  >
                    <span
                      className="left"
                      style={{
                        width: '300px',
                      }}
                    >
                      <Select
                        value={this.state.selectInput}
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectChange(e, 0, 'one')
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'What are you in the mood for?'}
                      />
                    </span>
                    <Link
                      to={{
                        pathname: '/search',
                      }}
                    >
                      <span
                        className="right squareSearch"
                        style={{
                          padding:
                            this.state.count > 2
                              ? '4px 4px 0px'
                              : '4px 4px 0px',
                        }}
                      >
                        <MaterialIcon
                          icon="search"
                          size={35}
                          color="#f21d59"
                        />
                      </span>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row
                style={{ background: 'whitesmoke', marginTop: '5px' }}
              >
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ padding: '5px 5px 5px 5px' }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                    id="menu"
                  >
                    <ul>
                      <Link
                        to={{
                          pathname: '/guide/category/food',
                        }}
                        // className="white"
                      >
                        <li>
                          <span>Food</span>
                        </li>
                      </Link>
                      <Link
                        to={{
                          pathname: '/guide/category/Music',
                        }}
                        // className="white"
                      >
                        <li>
                          <span>Music</span>
                        </li>
                      </Link>
                      <Link
                        to={{
                          pathname: '/guide/category/Happy-Hour',
                        }}
                        // className="white"
                      >
                        <li>
                          <span>Happy Hour</span>
                        </li>
                      </Link>
                      <Link
                        to={{
                          pathname: '/guide/category/intimate',
                        }}
                        // className="white"
                      >
                        <li>
                          <span style={{ wordBreak: 'break-all' }}>
                            Date<i className="red">-</i>Night
                          </span>
                        </li>
                      </Link>

                      <Link
                        to={{
                          pathname: '/guide/category/cocktails',
                        }}
                        // className="white"
                      >
                        <li>
                          <span>Cocktails</span>
                        </li>
                      </Link>
                      <Link
                        to={{
                          pathname: '/guide/category/entertainment',
                        }}
                        // className="white"
                      >
                        <li>
                          <span>Entertainment</span>
                        </li>
                      </Link>
                    </ul>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ padding: '5px 5px 5px 5px' }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                    id="menu"
                  >
                    <div>
                      <Link
                        to={{
                          pathname: '/login',
                          state: {},
                        }}
                      >
                        <span
                          // className="white"
                          style={{ fontWeight: '600' }}
                        >
                          Login for the full Trevel Experience
                        </span>
                      </Link>
                    </div>
                  </div>
                </Col>
                {/* <Col
                        xs={1}
                        md={1}
                        sm={1}
                        lg={1}
                        // className="no-padding"
                        style={{ padding: '5px 5px 5px 5px' }}
                      >
                        <a
                          onClick={() => {
                            this.clearLanding(false);
                          }}
                          className="pointer"
                        >
                          <MaterialIcon
                            icon="search"
                            size={45}
                            color="#ffffff"
                          />
                        </a>
                      </Col> */}
              </Row>
            </div>
            <div id="homeSections">
              {/* <div id="homeSections" className="contentbox"> */}
              {/* <div className="contentbox"> */}
              {/* <PromotedHero /> */}

              {/* <SignUpBanner /> */}
              <div style={{ marginTop: '20px' }}>
                {/* <MainHomePackages /> */}
                {/* <PromotedArticleHero /> */}
                {/* {this.props.promos !== undefined && <PromotionFeed />} */}
                {/* <DailyLocations
                    locations={this.props.locations}
                    userLove={this.props.profile.tags.love}
                    count={this.state.count}
                  />
    
                  {this.getConnectionLocations(this.props.connections)} */}
                {/* <div>{this.getPromos(this.props.demo.demoPromos)}</div> */}

                {this.props.guideTemplates !== undefined &&
                  this.props.guideTemplates.length !== 0 && (
                    <div style={{ paddingTop: '10px' }}>
                      {this.props.guideTemplates[0].categories.sectionOne.map(
                        (category, index) => (
                          <div>
                            {category.visibility === true && (
                              <div>
                                {this.getCategory(
                                  category,
                                  activeZoneId,
                                )}
                              </div>
                            )}
                          </div>
                        ),
                      )}
                    </div>
                  )}
                {this.props.guideTemplates === undefined ||
                  (this.props.guideTemplates.length === 0 && (
                    <div style={{ paddingTop: '10px' }}>
                      <GuideEventsCategory
                        category={'Music'}
                        tagCat={'tagsGenre'}
                        timedEvents={this.props.timedEvents}
                        count={this.state.count}
                        catTitle={'Let There Be Music'}
                      />
                      <GuideCategory
                        key={'cocktails'}
                        category={{
                          type: 'Locations',
                          filters: {
                            tags: [
                              {
                                sid: 'beverages',
                                tagType: 'tagsBeverages',
                                tags: ['Cocktails'],
                                zones: [],
                                zips: [],
                                cities: [],
                              },
                            ],
                          },
                          visibility: true,
                          title: 'Sometimes You Just Need A Cocktail',
                        }}
                        type="Locations"
                        locations={this.props.locations}
                        timedEvents={this.props.timedEvents}
                        count={this.state.count}
                        catTitle={
                          'Sometimes You Just Need A Cocktail'
                        }
                      />
                    </div>
                  ))}

                <DailyWeather
                  locations={this.props.locations}
                  count={this.state.count}
                />

                {/* SECTION 2 */}
                <div className="announcementBox">
                  <h4 style={{ fontSize: '20px' }}>
                    This guide is new and growing everyday so check
                    back often.
                  </h4>
                </div>
                {this.props.guideTemplates !== undefined &&
                  this.props.guideTemplates.length !== 0 && (
                    <div style={{ paddingTop: '20px' }}>
                      {this.props.guideTemplates[0].categories.sectionTwo.map(
                        (category, index) => (
                          <div>
                            {category.visibility === true && (
                              <div>
                                {this.getCategory(
                                  category,
                                  activeZoneId,
                                )}
                              </div>
                            )}
                          </div>
                        ),
                      )}
                    </div>
                  )}

                {this.props.guideTemplates === undefined ||
                  (this.props.guideTemplates.length === 0 && (
                    <div style={{ paddingTop: '20px' }}>
                      <div>
                        <GuideCategory
                          key={'beer'}
                          category={{
                            type: 'Locations',
                            filters: {
                              tags: [
                                {
                                  sid: 'beverages',
                                  tagType: 'tagsBeverages',
                                  tags: ['Beer'],
                                  zones: [],
                                  zips: [],
                                  cities: [],
                                },
                              ],
                            },
                            visibility: true,
                            title: 'Great Places to Grab a Beer',
                          }}
                          type="Locations"
                          locations={this.props.locations}
                          timedEvents={this.props.timedEvents}
                          count={this.state.count}
                          catTitle={'Great Places to Grab a Beer'}
                        />
                      </div>
                    </div>
                  ))}

                {/* 
                <GuideDemoCategory
                  category={''}
                  tagCat={''}
                  timedEvents={this.props.demo}
                  count={this.state.count}
                  catTitle={'Trevel Experiences - Join The Fun'}
                /> */}
                {/*  

                {this.props.uauth !== undefined &&
                  this.props.uauth.guide !== undefined &&
                 this.props.locations[0] !==
                    undefined &&
                 this.props.locations[0]
                    .zoneId !== undefined &&
                 this.props.locations[0]
                    .zoneId === 11 && (
                    <GuideCategoryRegion
                      category={'Italian'}
                      tagCat={'tagsCuisine'}
                      zips={'63110'}
                      locations={
                       this.props.locations
                      }
                      count={this.state.count}
                      catTitle={
                        'The Hill - Authentic Italian Cuisine'
                      }
                    />
                  )}
                {this.props.uauth !== undefined &&
                  this.props.uauth.guide !== undefined &&
                 this.props.locations[0] !==
                    undefined &&
                 this.props.locations[0]
                    .zoneId !== undefined &&
                 this.props.locations[0]
                    .zoneId === 11 && (
                    <GuideCategoryRegion
                      category={''}
                      tagCat={'all'}
                      zips={'63108'}
                      locations={
                       this.props.locations
                      }
                      count={this.state.count}
                      catTitle={'Explore The Central West End'}
                    />
                  )}
                    */}

                {/* SECTION 3 */}

                {/* ANNOUNCENT BANNER   */}
                <div className="announcementBox">
                  <h4 style={{ fontSize: '20px' }}>
                    Would you like a personal assistant to help you
                    plan your nights out?
                  </h4>
                  <Link
                    to={{
                      pathname: '/signup',
                    }}
                  >
                    <h3
                      style={{ fontSize: '20px', color: '#f21d59' }}
                    >
                      Then make a Free Account!
                    </h3>
                  </Link>
                </div>

                {this.props.guideTemplates !== undefined &&
                  this.props.guideTemplates.length !== 0 && (
                    <div style={{ paddingTop: '20px' }}>
                      {this.props.guideTemplates[0].categories.sectionThree.map(
                        (category, index) => (
                          <div>
                            {category.visibility === true && (
                              <div>
                                {this.getCategory(
                                  category,
                                  activeZoneId,
                                )}
                              </div>
                            )}
                          </div>
                        ),
                      )}
                    </div>
                  )}
                {this.props.guideTemplates === undefined ||
                  (this.props.guideTemplates.length === 0 && (
                    <div style={{ paddingTop: '20px' }}>
                      <div>
                        <GuideCategory
                          key={'casual'}
                          category={{
                            type: 'Locations',
                            filters: {
                              tags: [
                                {
                                  sid: 'dateType',
                                  tagType: 'tagsDateType',
                                  tags: ['Casual'],
                                  zones: [],
                                  zips: [],
                                  cities: [],
                                },
                              ],
                            },
                            visibility: true,
                            title: 'Casual Dating at its Finest',
                          }}
                          type="Locations"
                          locations={this.props.locations}
                          timedEvents={this.props.timedEvents}
                          count={this.state.count}
                          catTitle={'Casual Dating at its Finest'}
                        />
                        <GuideCategory
                          key={'intimate'}
                          category={{
                            type: 'Locations',
                            filters: {
                              tags: [
                                {
                                  sid: 'setting',
                                  tagType: 'tagsSettings',
                                  tags: ['Intimate'],
                                  zones: [],
                                  zips: [],
                                  cities: [],
                                },
                              ],
                            },
                            visibility: true,
                            title: 'Perfect for Date Night',
                          }}
                          type="Locations"
                          locations={this.props.locations}
                          timedEvents={this.props.timedEvents}
                          count={this.state.count}
                          catTitle={'Perfect for Date Night'}
                        />
                        <GuideCategory
                          key={'coffee'}
                          category={{
                            type: 'Locations',
                            filters: {
                              tags: [
                                {
                                  sid: 'beverages',
                                  tagType: 'tagsBeverages',
                                  tags: ['Coffee'],
                                  zones: [],
                                  zips: [],
                                  cities: [],
                                },
                              ],
                            },
                            visibility: true,
                            title:
                              'Coffee Is for any time of the day',
                          }}
                          type="Locations"
                          locations={this.props.locations}
                          timedEvents={this.props.timedEvents}
                          count={this.state.count}
                          catTitle={
                            'Coffee Is for any time of the day'
                          }
                        />
                        <GuideCategory
                          key={'golf'}
                          category={{
                            type: 'Locations',
                            filters: {
                              tags: [
                                {
                                  sid: 'locationType',
                                  tagType: 'tagsLocationType',
                                  tags: ['Golf Course'],
                                  zones: [],
                                  zips: [],
                                  cities: [],
                                },
                              ],
                            },
                            visibility: true,
                            title:
                              'Summers Here - Book Your Tee Time!',
                          }}
                          type="Locations"
                          locations={this.props.locations}
                          timedEvents={this.props.timedEvents}
                          count={this.state.count}
                          catTitle={
                            'Summers Here - Book Your Tee Time!'
                          }
                        />
                      </div>
                    </div>
                  ))}

                {/*
                {this.props.uauth !== undefined &&
                  this.props.uauth.guide !== undefined &&
                 this.props.locations[0] !==
                    undefined &&
                 this.props.locations[0]
                    .zoneId !== undefined &&
                 this.props.locations[0]
                    .zoneId === 10 && (
                    <GuideCategoryRegion
                      category={''}
                      tagCat={'all'}
                      cities={'Overland Park'}
                      locations={
                       this.props.locations
                      }
                      count={this.state.count}
                      catTitle={'Explore Overland Park'}
                    />
                  )}
                <GuideEventsCategory
                  category={'entertainment'}
                  tagCat={'tagsEventType'}
                  timedEvents={this.props.timedEvents}
                  count={this.state.count}
                  catTitle={'Entertainment for Everyone'}
                />
             
                {/* <div id="promotedArticles"> */}
                {this.props.articles !== undefined && (
                  <PromotedArticles
                    articles={this.props.articles}
                    count={this.state.count}
                  />
                )}
                {/* </div> */}
                {/* <div id="promotedArticles">
                    {this.props.articles !== undefined && (
                      <PromotedArticles
                        articles={this.props.articles}
                        count={this.state.count}
                      />
                    )}
                  </div> */}
                {/* <Link to={`/packages`}>Packages</Link> */}
              </div>
            </div>
            <br />
            {this.state.count < 3 ? (
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.nav-signup { display: block } .maincontent{top: 80px !important; margin-top:0px}',
                }}
              />
            ) : (
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.nav-signup { display: block } .maincontent{top: 49px !important; margin-top:0px}',
                }}
              />
            )}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '.footer-container{display: block.toolbar{  height: 55px}.toolbar__navigation{max-width:none}.container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important; display: block} #authleft {display:none;}   .maincontent {  margin-top: 0px; height: 100%} #bottomNav span{color: white !important}#bottomNav .material-icons{color: white !important} #bottomNav{     padding-top:15px; height: 80px; background: linear-gradient(180deg, rgba(242,29,89,1) 0%, rgb(162 8 51) 78%))  !important; border: 0px; background: rgb(242,29,89); width:100% !important; left: 0} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              }}
            />
          </div>

          <div
            style={{
              position: 'fixed',
              bottom: '0px',
              minheight: '50px',
              width: '100%',
              zIndex: '9999',
            }}
            id="SignUpBanner"
            className="hideThis"
          >
            <SignUpBanner />
          </div>
          <Modal
            isOpen={this.state.signUpModalVisible}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeSignUpModal}
            style={modalStyles}
            contentLabel="Transport Modal"
          >
            <div className="full-height">
              <div className="modalHeader">
                <Row className="no-margin">
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    className="no-padding"
                  >
                    <a
                      onClick={() => {
                        this.closeSignUpModal();
                      }}
                    >
                      <MaterialIcon
                        icon="close"
                        size={25}
                        color="white"
                      />
                    </a>
                  </Col>
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <center>
                      <span className="modalHeader-text">
                        Sign Up For Trevel
                      </span>
                    </center>
                  </Col>
                  <Col lg={2} md={2} sm={2} xs={2}>
                    {/* <center>
                <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      this.props.customEvent,
                      this.props.locations,
                      this.props.packages
                    );
                  }}
                />
              </center> */}
                  </Col>
                </Row>
              </div>
              <div>
                <Container>
                  <Row className="no-margin">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="text-center"
                      style={{
                        backgroundColor: 'rgb(237 237 237)',
                        borderBottom: '1px solid red',
                        marginBottom: '20px',
                      }}
                    >
                      <div
                        style={{
                          maxWidth: '500px',
                          margin: '0px auto',
                          marginBottom: '20px',
                          marginTop: '20px  ',
                        }}
                      >
                        <Link
                          to={{
                            pathname: '/signup',
                          }}
                        >
                          <span
                            style={{
                              width: '100%',
                              display: 'block',
                              border: '1px solid #8b8b8b',
                              padding: '5px',
                              borderRadius: '10px',
                              marginBottom: '10px',
                              backgroundColor: '#f3265d',
                              color: 'white',
                            }}
                          >
                            Sign Up
                          </span>
                        </Link>
                        <Link
                          to={{
                            pathname: '/login',
                          }}
                        >
                          <span
                            style={{
                              width: '100%',
                              display: 'block',
                              border: '1px solid black',
                              padding: '5px',
                              borderRadius: '10px',
                              backgroundColor: 'white',
                            }}
                          >
                            Login
                          </span>
                        </Link>
                      </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div style={{ padding: '10px' }}>
                        <center>
                          <h4> Lets Experience Life Together </h4>
                        </center>
                        <div
                          style={{
                            display: 'block',
                            position: 'relative',
                            height: '20px',
                            borderTop: '1px solid #9e9e9e',
                            margin: '0px auto',
                            width: '300px',
                          }}
                        ></div>
                        <div
                          style={{
                            maxWidth: '600px',
                            margin: '0px auto',
                          }}
                        >
                          <p>
                            Whether you are making plans for a first
                            date, a night out with your spouse, or
                            grabbing drinks with a group of friends,
                            Trevel helps you craft an experience that
                            everyone attending will love.
                          </p>
                          <p>
                            When you connect with others to create an
                            event their preferences, history, favorite
                            locations and more are combined with yours
                            to find the perfect experience.
                          </p>
                        </div>

                        <div
                          style={{
                            paddingBottom: '20px',
                            marginTop: '20px',
                            maxWidth: '1000px',
                          }}
                        >
                          {/* <center>
                            <video
                              width="100%"
                              max-width="500px"
                              controls
                              controlsList="nodownload"
                              style={{ border: '1px solid #131313' }}
                            >
                              <source
                                src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/video%2FTrevelGrant.mp4?alt=media&token=672a063d-31d3-47eb-b5fb-2ebfeef6dda7"
                                type="video/mp4"
                              />
                            </video>
                          </center> */}
                        </div>
                        <center>
                          <Link
                            to={{
                              pathname: '/about',
                            }}
                          >
                            <span>Learn More</span>
                          </Link>
                        </center>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLocations: (locations) => {
      dispatch(setLocations(locations));
    },
    setPackages: (packages) => {
      dispatch(setPackages(packages));
    },
    getUauth: (bundle) => {
      dispatch(getUauth(bundle));
    },
    getArticles: (article) => {
      dispatch(getArticles(article));
    },
    clearUauth: () => {
      dispatch(clearUauth());
    },
    getTimedEvents: (zoneId) => {
      dispatch(getTimedEvents(zoneId));
    },
    getEntertainment: (zoneId) => {
      dispatch(getEntertainment(zoneId));
    },
    getGuideTemplates: () => {
      dispatch(getGuideTemplates());
    },
    getRegions: (zoneId) => {
      dispatch(getRegions(zoneId));
    },
    getTaRequest: (request) => {
      dispatch(getTaRequest(request));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    tags: state.tags.tags,
    locations: state.locations.locations,
    articles: state.articles.articles,
    uauth: state.unauth,
    packages: state.packages.packages,
    demo: state.demo,
    timedEvents: state.timedEvents.events,
    guideTemplates: state.guideTemplates.templates,
    entertainment: state.entertainment.entertainment,
    taRequest: state.taRequest,
    regions: state.regions.regions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
