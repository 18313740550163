import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import NewEventMessage from '../../components/NewEventMessage';
import firebase from '../../config/firebase';
// Reducers
import { savePurchase } from '../../sagas/purchaseSaga/reducer';
import { updateEvents } from '../../sagas/userEventsSaga/reducer';

const query = require('array-query');

const modalStyles = {
  content: {
    top: '0px',
    left: '0px',
    position: 'fixed',
    padding: '0px',
    height: '100%',
    width: '100%',
  },
};

export class PackageCheckoutReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      connectFirstModalVisible: false,
      modalState: 2,
      scheduleSet: false,
    };
    this.setSchedule = this.setSchedule.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.createEvent(this.props.activePackage, this.props.profile);
    this.storeId();
  }

  componentDidMount() {
    this.checkVouchers(this.props.selectedVouchers);
  }

  storeId() {
    // Get New Event ID to use later
    const db = firebase.firestore();
    const newEventRef = db.collection('user_events').doc();
    const dbref = newEventRef.id;
    this.setState({
      dbref: dbref,
    });
  }

  createEvent(activePackage, profile) {
    const userEvent = {};
    const userId = profile.userId;

    const { selectedVouchers, locations } = this.props;
    const newVouchersObject = {};
    const voucherObject = {};
    const packageId = activePackage.packageId;

    const timestamp = new Date();
    userEvent.creationDetails = {
      plannerId: profile.userId,
      plannerPhoto: profile.profilePhotoPath,
      plannerFirstName: profile.firstName,
      plannerLastName: profile.lastName,
      plannerUserName: profile.userName,
      creationDate: timestamp,
    };

    //Package Details
    userEvent.packageDetails = {
      packageId: activePackage.packageId,
    };
    // Add Locations
    const locationArray = [];
    let eventLocations = activePackage.locations;
    eventLocations.forEach(location => {
      const thislocation = query('locationId')
        .is(location.locationId)
        .on(this.props.alllocations);
      const readyLocation = {
        locationId: location.locationId,
        locationHero: thislocation[0].locationHero,
        locationDescription: thislocation[0].locationDescription,
        locationTitle: thislocation[0].locationTitle,
        locationType: 0,
        locationThumb: thislocation[0].locationThumb,
        address: thislocation[0].address,
      };
      locationArray.push(readyLocation);
    });
    userEvent.packageDetails.locations = locationArray;

    userEvent.title = activePackage.title;
    userEvent.description = activePackage.description;

    // SET USERS
    const fullName = profile.firstName + ' ' + profile.lastName;
    var usersObject = {};
    if (this.props.newEvent.planFirst === true) {
      usersObject[userId] = {
        access: true,
        editor: true,
        approved: true,
        attending: true,
        // inviteAction: null,
        profile: {
          profilePhotoPath: profile.profilePhotoPath,
          fullName: fullName,
          userName: profile.userName,
        },
      };
      userEvent.invitations = {};
    } else {
      usersObject = this.props.newEvent.connections;
    }

    userEvent.users = usersObject;

    // CREATE EDIT HISTORY
    const localDate = new Date();
    const editHistory = [];
    editHistory[0] = {
      editDate: localDate,
      eventType: 1,
      fullName: fullName,
      userId: profile.userId,
      username: profile.userName,
    };
    userEvent.editHistory = editHistory;

    // SET PACKAGE LOCATIONS AND VOUCHERS
    // const activePackageLocations = activePackage.locations;
    // activePackageLocations.forEach((purchaseLocation, i) => {
    //   const locationId = purchaseLocation.locationId;
    //   locations.forEach((location, iii) => {
    //     let voucherCheck = false;
    //     if (location.locationId === locationId) {
    //       if (location.vouchers) {
    //         location.vouchers.forEach(voucher => {
    //           const voucherId = voucher.voucherId;
    //           selectedVouchers.forEach((selectedVoucher, ii) => {
    //             if (selectedVoucher === voucherId) {
    //               voucherCheck = true;
    //               voucherObject[ii] = {
    //                 // voucherId: ,
    //                 voucherMasterId: voucher.voucherId,
    //                 voucherType: voucher.voucherType,
    //                 voucherPromo: voucher.voucherPromo,
    //                 voucherCost: voucher.voucherCost,
    //                 voucherStatus: true,
    //                 voucherCreationDate: timestamp,
    //                 voucherExpirationDate: "January 1, 2019",
    //                 voucherInfo: voucher.voucherInfo,
    //                 userId,
    //                 locationId: purchaseLocation.locationId,
    //                 packageId
    //               };
    //             }
    //           });
    //         });
    //         if (voucherCheck === true) {
    //           newVouchersObject[iii] = {
    //             vouchers: voucherObject
    //           };
    //         }
    //       }
    //     }
    //   });
    // });

    // SET PACKAGE SETTINGS
    userEvent.eventSettings = {
      scheduled: false,
      itinerarySent: false,
      customHero: false,
      customThumb: false,
    };

    if (this.props.newEvent.planFirst === true) {
      userEvent.eventSettings.eventApproved = true;
    } else {
      userEvent.eventSettings.eventApproved = false;
    }

    //Turn Off Edit Mode
    userEvent.editing = {
      editing: false,
      editorId: null,
    };

    // SET FAKE SCHEDULE
    userEvent.scheduledTime = {
      date: '',
      timestamp: '',
      timeString: '',
    };
    // SET EVENT IMAGES
    userEvent.heroImage = activePackage.heroImage;
    userEvent.thumbnail = activePackage.thumbnail;
    const usersAttendingObject = {};
    usersAttendingObject[userId] = { attending: true };
    userEvent.usersAttending = usersAttendingObject;

    // Set Status
    if (this.props.newEvent.planFirst === true) {
      userEvent.status = 0;
    } else {
      userEvent.status = 2;
    }
    this.setState({ userEvent, userVouchers: selectedVouchers });
  }

  getModalContent() {
    // if (this.state.modalState === 2) {
    //   return (
    //     <Container>
    //       <Row className="no-margin">
    //         <Col
    //           xs={12}
    //           md={12}
    //           sm={12}
    //           lg={12}
    //           className="no-padding text-center"
    //         >
    //           <p className="modalMessage">Have a date in mind?</p>
    //           <NewEventScheduleModal
    //             setSchedule={this.setSchedule()}
    //             skipSchedule={this.skipSchedule()}
    //           />
    //         </Col>
    //       </Row>
    //     </Container>
    //   );
    // }
    if (this.state.modalState === 2) {
      return (
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding text-center"
            >
              <p className="modalMessage">
                Include a message with your invite?
              </p>
              <NewEventMessage saveMessage={this.saveMessage()} />
            </Col>
          </Row>
        </Container>
      );
    }
  }

  setSchedule = index => (date, selectedTime) => {
    var timeString = null;
    var timeValue = null;

    if (selectedTime !== null) {
      const datestring = String(selectedTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':'); // convert to array
      // fetch
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      const seconds = Number(time[2]);
      // calculate
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours == 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`; // get minutes
      // timeValue += seconds < 10 ? `:0${seconds}` : `:${seconds}`; // get seconds
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.'; // get AM/PM
      timeString = selectedTime._d;
    }

    const timestamp = moment(date).unix();
    const timestampReady = timestamp * 1000;
    const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
    const schedule = {
      date: dateString,
      time: timeValue,
      timeString: timeString,
      timestamp: timestampReady,
    };
    this.setState({
      scheduledTime: schedule,
      scheduleSet: true,
      modalState: 3,
    });
  };

  skipSchedule = index => () => {
    this.setState({
      scheduleSet: false,
      skipSchedule: true,
      modalState: 3,
    });
  };

  triggerModal(newEvent) {
    if (newEvent.planFirst === true) {
      this.saveEvent(
        this.state.userEvent,
        this.props.profile,
        this.state.userVouchers,
        this.props.newEvent,
        null,
      );
    } else {
      this.setState({
        loading: false,
      });
      this.openConnectFirstModal();
    }
  }

  saveEvent(event, profile, userVouchers, newEvent, invitation) {
    const scheduledEvent = event;
    if (this.state.scheduleSet === true) {
      scheduledEvent.scheduledTime = this.state.scheduledTime;
    }
    const purchaseBundle = {
      event: scheduledEvent,
      profile: profile,
      userVouchers: userVouchers,
      invitation: invitation,
      eventId: this.state.dbref,
    };
    this.props.savePurchase(purchaseBundle);
  }

  saveMessage = index => message => {
    let inviteMessage = '';
    if (message === undefined) {
      inviteMessage = '';
    } else {
      inviteMessage = message;
    }
    const newEvent = this.props.newEvent;
    // const timestamp = new Date();
    const timestamp = new Date();
    const fullname = `${newEvent.invitedUser.firstName} ${
      newEvent.invitedUser.lastName
    }`;
    let userEvent = this.state.userEvent;
    // Set Locations
    // const packageDetails = query("packageId")
    //   .is(this.props.activePackage.packageId)
    //   .on(this.props.packages);
    // const locations = packageDetails[0].locations;
    const locations = userEvent.packageDetails.locations;
    // Set  Up Invite
    const invitation = {
      dateSent: timestamp,
      senderName: this.props.profile.firstName,
      senderLastName: this.props.profile.lastName,
      profilePhotoPath: this.props.profile.profilePhotoPath,
      senderId: this.props.profile.userId,
      userPurchaseId: this.props.activePackage.packageId,
      eventId: this.state.dbref,
      locations,
      receiverName: fullname,
      userName: newEvent.invitedUser.userName,
      userId: newEvent.invitedUser.userId,
      receiverPhotoPath: newEvent.invitedUser.profilePhotoPath,
      type: 1,
      status: 0,
      inviteMessage,
      editable: true,
    };
    // Add Invitation to Event
    let invitationObject = {};
    invitationObject[newEvent.invitedUser.userId] = invitation;
    userEvent.invitations = invitationObject;

    this.saveEvent(
      userEvent,
      this.props.profile,
      this.state.userVouchers,
      this.props.newEvent,
      invitation,
    );
  };

  openConnectFirstModal(user) {
    this.setState({ connectFirstModalVisible: true, newUser: user });
  }

  closeonnectFirstModal() {
    this.setState({ connectFirstModalVisible: false });
  }

  getModalHeader() {
    // if (this.state.modalState === 2) {
    //   return <span className="modalHeader-text">Schedule Event</span>;
    // } else if (this.state.modalState === 3) {
    //   return <span className="modalHeader-text">Write a Message</span>;
    // }
    return (
      <div>
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <MaterialIcon
                icon="keyboard_backspace"
                size={25}
                color="white"
                onClick={() => {
                  this.closeonnectFirstModal();
                }}
              />
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Invitation</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  checkVouchers(vouchers) {
    if (vouchers.length === 0) {
      this.triggerModal(this.props.newEvent);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  showLoadScreen() {
    return (
      <div id="loadscreen">
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important; background: #f21d59} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/checkout/setup',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Checkout</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <div>{this.showLoadScreen()}</div>;
    }
    return (
      <div>
        {this.getTitleBar()}
        <div id="checkout" className="section">
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                Total Price: ${this.props.cost}
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <button
                  className="redbutton"
                  onClick={() => {
                    this.triggerModal(this.props.newEvent);
                  }}
                >
                  Checkout!
                </button>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.connectFirstModalVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeonnectFirstModal}
          style={modalStyles}
          // contentLabel="Wait for Connection?"
        >
          <div className="full-height">
            {this.getModalHeader()}
            <div className="section">{this.getModalContent()}</div>
          </div>
        </Modal>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%}  .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    events: state.events.events,
    locations: state.locations.locations,
    user: state.user,
    activePackage: state.activePackage.ap,
    profile: state.profile.profile,
    cost: state.checkoutBundle.checkoutBundle.cost,
    selectedVouchers:
      state.checkoutBundle.checkoutBundle.selectedVouchers,
    newEvent: state.newEvent,
    alllocations: state.locations.locations,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateEvents: id => {
      dispatch(updateEvents(id));
    },
    savePurchase: purchaseBundle => {
      dispatch(savePurchase(purchaseBundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PackageCheckoutReview);
