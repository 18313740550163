import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
// Components
import TitleBar from '../../components/TitleBar';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';

export class VendorCreateEventHome extends React.Component {
  UNSAFE_componentWillMount() {
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }
  render() {
    if (this.props.vendor === undefined) {
      return null; // or render a loading icon
    }
    return (
      <div id="admin">
        <TitleBar title="Create Event" backlink="" />
        {/* <div className="section">
          <Container>
            <Row className="greybox">
              <Col xs={12} md={12} sm={12} lg={12}>
                <h5>News and Announcements</h5>
                <span className="bold">Trevel Is Currently In Beta</span>
                <p>
                  The iPhone version and the Web version of Trevel are currently
                  in Beta. So what does that mean? It means you might find a bug
                  on occasion as users test the tools, and that design is being
                  polished up as we get ready for mass consumption.
                </p>
                <hr />
                <span className="bold">
                  {this.props.vendor.businessName} Joined The Beta
                </span>
                <p>
                  Thank you for claiming your location and helping us make
                  Trevel an essential tool for both our users and our location
                  partners. Mike from Trevel will reach out to you with more
                  information soon.
                </p>
              </Col>
            </Row>
          </Container>
        </div> */}
        <div>
          <Container className="section">
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <center>
                  <h2>What kind of event can we help you with?</h2>
                </center>
              </Col>
            </Row>
          </Container>
          <Container className="section">
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h5>Scheduled Events</h5>
                <p>
                  Have a scheduled event that you want to promote,
                  like a wine tasting or a grand opening? Scheduled
                  Events are just the thing.
                </p>
                <a
                  href="/vendor/event/create/scheduled"
                  className="redbutton"
                >
                  Create a Scheduled Event
                </a>
              </Col>
            </Row>
          </Container>
          <hr />
          <Container className="section">
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h5>Recommended Itinerary</h5>
                <p>
                  Our goal at Trevel is to help our users have a great
                  night/day out. A big part of that comes in the form
                  of our recomended itineraries. An itineray involves
                  more then one location.
                </p>
                <a
                  href="/vendor/event/create/itinerary"
                  className="redbutton"
                >
                  Create a Recomended Itinerary
                </a>
              </Col>
            </Row>
          </Container>
          <div id="signupbanner" />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMode: mode => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorCreateEventHome);
