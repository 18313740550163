import { call, put, takeLatest } from 'redux-saga/effects';
import { getPackages } from '../../services/PackagesServices';

import * as actions from './actions';

let defaultState = {
  packages: [],
};

function* init(zoneId) {
  if (zoneId !== undefined) {
    let packages = yield call(getPackages, zoneId.zoneId);
    yield put({
      type: actions.SET_PACKAGES,
      payload: {
        ...defaultState,
        packages,
      },
    });
  }
}

export default function* sagas() {
  yield takeLatest(actions.INIT, init);
}
