import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import TitleBar from '../components/TitleBar';
import {
  withFormik,
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import ff from '../images/powered-by-foursquare-grey.svg';
import MaterialIcon from 'material-icons-react';
// Constants
const _ = require('lodash');
const query = require('array-query');

export class FFLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      loading: true,
      query: '',
      index: null,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.index !== undefined) {
      this.setState({
        index: this.props.index,
      });
    }
    this.getGeoLocation();
  }

  getGeoLocation = () => {
    let self = this;
    if (navigator.geolocation) {
      //check if geolocation is available
      navigator.geolocation.getCurrentPosition(
        position => {
          self.setState({
            currentLongitude: position.coords.longitude,
            currentLatitude: position.coords.latitude,
            error: null,
            loading: false,
          });
        },
        error => self.setState({ error: error.message }),
        {
          enableHighAccuracy: true,
          timeout: 20000,
          maximumAge: 1000,
        },
      );
    }
  };

  findLocation(query, lat, long) {
    const searchUrl = `https://api.foursquare.com/v2/venues/search/?ll=${lat},${long}&query=${query}&v=20180801&client_id=3XSSDOK24G0A5MRTUJ1P4KLAOV4PMV55ZEGBOM11N1UR5FKY&client_secret=GEABQXOWJUPGD2BBN5BOHMFWS40JVGGFFOQRTA2BXVTFMEK3`;
    fetch(searchUrl) // asynchronously load contents of the url
      // return a Promise that resolves when res is loaded
      .then(res => res.json()) // call this function when res is loaded
      // return a Promise with result of above function
      .then(res => {
        // call this function when the above chained Promise resolves
        // console.log('LOCATION DETAILS', res);
        // if (res.response.venue.description) {
        //   const description = res.response.venue.description;
        //   console.log('Description', description);
        // }
        // console.log('location - xxx', location);
        const venues = res.response.venues;
        // const sortedList = _.sortBy(venues, o => o.name);
        this.setState({
          results: venues,
        });
      });
  }

  displayResult(result) {
    let category = '';
    if (result.categories[0]) {
      category = result.categories[0].name;
    }
    return (
      <Row className="oddeven clear">
        <Col xs={10} md={10} sm={10} lg={10}>
          <span className="invite-label">{result.name}</span>
          <br />
          {result.location.formattedAddress[0]}
          <br />
          {result.location.formattedAddress[1]}
          <br />
          <span className="ffcategory">{category}</span>
        </Col>
        <Col xs={2} md={2} sm={2} lg={2}>
          <div className="right text-right">
            <MaterialIcon
              icon="add_circle"
              size={25}
              color="rgb(242, 29, 89)"
              onClick={() => {
                this.selectLocation(result, this.props.locations);
              }}
            />
          </div>
        </Col>
      </Row>
    );
  }

  setQuery(query) {
    this.setState({
      query,
    });
  }

  selectLocation(fflocation, locations) {
    let street = '';
    if (fflocation.location.address !== undefined) {
      street = fflocation.location.address;
    }
    let state = '';
    if (fflocation.location.state !== undefined) {
      state = fflocation.location.state;
    }
    let zip = '';
    if (fflocation.location.zip !== undefined) {
      zip = fflocation.location.zip;
    }
    let city = '';
    if (fflocation.location.city !== undefined) {
      city = fflocation.location.city;
    }
    const fflocationObject = {
      locationId: '',
      locationTitle: fflocation.name,
      locationDescription: '',
      // venId: fflocation.id,
      address: {
        city: city,
        latitude: fflocation.location.lng,
        longitude: fflocation.location.lat,
        state: state,
        street: street,
        zip: zip,
      },
      phone: null,
      categories: fflocation.categories,
      locationHero: 'default_hero.jpg',
      locationThumb: 'default_thumb.jpg',
      zoneId: 1,
      // vouchers: [
      //   {
      //     voucherCost: "",
      //     voucherId: "",
      //     voucherInfo: "",
      //     voucherPromo: "",
      //     voucherType: ""
      //   }
      // ],
      happyHours: [
        {
          happyHourId: '',
          happyHourInfo: '',
          happyHourPromo: '',
          happyHourType: '',
          happyHourTime: [],
        },
      ],
      packages: {},
      type: 1,
    };

    let locationExtreme = this.getLocation(
      fflocation,
      locations,
      fflocationObject,
    );
    this.props.addLocation(
      this.props.customEvent,
      locationExtreme,
      this.state.index,
    );
  }

  getLocation(fflocation, locations, fflocationObject) {
    let locationExtreme = fflocationObject;
    // Check if custom location already exists
    locations.forEach(location => {
      if (location.custom === true) {
        if (fflocation.id === location.venId) {
          locationExtreme = location;
        }
      }
    });
    return locationExtreme;
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    const aStyle = {
      width: '350px',
      margin: '0px auto',
    };
    const iStyle = {
      width: '300px',
      float: 'left',
    };
    const bStyle = {
      width: '50px',
      float: 'right',
      height: '38px',
      background: 'transparent',
      border: '0px',
    };
    return (
      <div>
        <div id="events" className="section">
          <div className="section text-center">
            <h3 className="no-margin">Search for Anywhere!</h3>
            <a
              href="http://foursquare.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ff} className="fsquare" />
            </a>
          </div>
          <div className="header-container">
            <div className="section">
              <Formik
                enableReinitialize
                onSubmit={values => {
                  this.findLocation(
                    values.query,
                    this.state.currentLatitude,
                    this.state.currentLongitude,
                  );
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div style={aStyle}>
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="error-message"
                      />
                      <Field
                        type="text"
                        name="query"
                        placeholder="Search"
                        size="30"
                        className="search-input"
                        style={iStyle}
                      />
                      <button
                        type="submit"
                        style={bStyle}
                        className="no-padding"
                        disabled={isSubmitting}
                      >
                        <MaterialIcon
                          icon="search"
                          size={25}
                          color="#f21d59"
                        />
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div>
            {this.state.results.map((result, index) => (
              <div>{this.displayResult(result)}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(FFLocations);
