import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import NewEventMessage from '../../components/NewEventMessage';
import firebase from '../../config/firebase';
// Reducers
import {
  editCe,
  editCeP,
  saveCe,
  saveCeP,
} from '../../sagas/customEventSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    height: '550px',
    width: '300px',
  },
};

export class CustomCheckoutReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      connectFirstModalVisible: false,
      modalState: 2,
      scheduleSet: false,
      dbref: null,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.storeId();
    this.createCustomEvent(
      this.props.profile,
      this.props.customEvent,
      this.props.locations,
      this.props.packages,
    );
    // this.checkVouchers(this.props.selectedVouchers);
  }

  storeId() {
    // Get New Event ID to use later
    const db = firebase.firestore();
    const newEventRef = db.collection('user_events').doc();
    const dbref = newEventRef.id;
    this.setState({
      dbref: dbref,
    });
  }

  uploadLocation(location, id) {
    const newLocation = Object.assign({}, location, {
      locationId: id,
      custom: true,
    });
    const db = firebase.firestore();
    const setDoc = db
      .collection('locations')
      .doc(id)
      .set(newLocation)
      .then((ref) => {
        console.log(ref);
      });
  }

  triggerModal(newEvent) {
    if (newEvent.planFirst === true) {
      this.saveEvent(
        this.state.userEvent,
        this.props.profile,
        this.state.userVouchers,
        this.props.customEvent.editMode,
        this.props.newEvent,
        null,
      );
    } else {
      this.setState({
        loading: false,
      });
      this.openConnectFirstModal();
    }
  }

  getModalContent() {
    // if (this.state.modalState === 2) {
    //   return (
    //     <Container>
    //       <Row className="no-margin">
    //         <Col
    //           xs={12}
    //           md={12}
    //           sm={12}
    //           lg={12}
    //           className="no-padding text-center"
    //         >
    //           <p className="modalMessage">Have a date in mind?</p>
    //           <NewEventScheduleModal
    //             setSchedule={this.setSchedule()}
    //             skipSchedule={this.skipSchedule()}
    //           />
    //         </Col>
    //       </Row>
    //     </Container>
    //   );
    // }
    if (this.state.modalState === 2) {
      return (
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding text-center"
            >
              <p className="modalMessage">
                Include a message with your invite??
              </p>
              <NewEventMessage saveMessage={this.saveMessage()} />
            </Col>
          </Row>
        </Container>
      );
    }
  }

  openConnectFirstModal(user) {
    this.setState({ connectFirstModalVisible: true, newUser: user });
  }

  closeonnectFirstModal() {
    this.setState({ connectFirstModalVisible: false });
  }

  getModalHeader() {
    if (this.state.modalState === 2) {
      return <span className="modalHeader-text">Schedule Event</span>;
    } else if (this.state.modalState === 3) {
      return (
        <span className="modalHeader-text">Write a Message</span>
      );
    }
  }

  setSchedule = (index) => (date, selectedTime) => {
    var timeString = null;
    var timeValue = null;

    if (selectedTime !== null) {
      const datestring = String(selectedTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':'); // convert to array
      // fetch
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      const seconds = Number(time[2]);
      // calculate
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours == 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`; // get minutes
      // timeValue += seconds < 10 ? `:0${seconds}` : `:${seconds}`; // get seconds
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.'; // get AM/PM
      timeString = selectedTime._d;
    }

    const timestamp = moment(date).unix();
    const timestampReady = timestamp * 1000;
    const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
    const schedule = {
      date: dateString,
      time: timeValue,
      timeString: timeString,
      timestamp: timestampReady,
    };
    this.setState({
      scheduledTime: schedule,
      scheduleSet: true,
      modalState: 3,
    });
  };

  skipSchedule = (index) => () => {
    this.setState({
      scheduleSet: false,
      skipSchedule: true,
      modalState: 3,
    });
  };

  saveMessage(userEvent, userPackage) {
    console.log('userEvent', userEvent);
    // *** Removing Invite Message
    // let inviteMessage = '';
    // if (message === undefined) {
    //   inviteMessage = '';
    // } else {
    //   inviteMessage = message;
    // }
    // let userEvent = this.state.userEvent;
    let newEvent = this.props.newEvent;
    let invitationObject = {};
    const locations = userEvent.packageDetails.locations;
    const timestamp = new Date();

    if (
      newEvent.recreate !== undefined &&
      newEvent.recreate === true
    ) {
      Object.keys(newEvent.connections).forEach((key) => {
        const invitation = {
          dateSent: timestamp,
          senderName: this.props.profile.firstName,
          senderLastName: this.props.profile.lastName,
          profilePhotoPath: this.props.profile.profilePhotoPath,
          senderId: this.props.profile.userId,
          userPurchaseId: this.props.activePackage.packageId,
          eventId: this.state.dbref,
          locations,
          receiverName: newEvent.connections[key].profile.fullName,
          userName: newEvent.connections[key].profile.userName,
          userId: key,
          receiverPhotoPath:
            newEvent.connections[key].profile.profilePhotoPath,
          type: 1,
          status: 0,
          // inviteMessage,
          editable: true,
        };
        invitationObject[key] = invitation;
      });
    } else {
      // const fullname = `${newEvent.invitedUser.firstName} ${newEvent.invitedUser.lastName}`;
      // Set  Up Invite
      // const invitation = {
      //   dateSent: timestamp,
      //   senderName: this.props.profile.firstName,
      //   senderLastName: this.props.profile.lastName,
      //   profilePhotoPath: this.props.profile.profilePhotoPath,
      //   senderId: this.props.profile.userId,
      //   userPurchaseId: this.props.activePackage.packageId,
      //   eventId: this.props.customEvent.eventId,
      //   locations,
      //   receiverName: fullname,
      //   userName: newEvent.invitedUser.userName,
      //   userId: newEvent.invitedUser.userId,
      //   receiverPhotoPath: newEvent.invitedUser.profilePhotoPath,
      //   type: 1,
      //   status: 0,
      //   inviteMessage,
      //   editable: true,
      // };
      // invitationObject[newEvent.invitedUser.userId] = invitation;
      Object.keys(newEvent.connections).forEach((key) => {
        const invitation = {
          dateSent: timestamp,
          senderName: this.props.profile.firstName,
          senderLastName: this.props.profile.lastName,
          profilePhotoPath: this.props.profile.profilePhotoPath,
          senderId: this.props.profile.userId,
          userPurchaseId: this.props.activePackage.packageId,
          eventId: this.props.customEvent.eventId,
          locations,
          receiverName: newEvent.connections[key].profile.fullName,
          userName: newEvent.connections[key].profile.userName,
          userId: key,
          receiverPhotoPath:
            newEvent.connections[key].profile.profilePhotoPath,
          type: 1,
          status: 0,
          // inviteMessage,
          editable: true,
        };
        if (key !== this.props.profile.userId) {
          invitationObject[key] = invitation;
        }
      });
    }
    if (
      this.props.customEvent.fromVEvent !== undefined &&
      this.props.customEvent.fromVEvent === true
    ) {
      userEvent.vEvent = true;
      userEvent.vEventDetails = {
        id: this.props.customEvent.vEvent.id,
        vendorId: this.props.customEvent.vEvent.account.vendorId,
      };
    }

    userEvent.invitations = invitationObject;
    this.saveEvent(
      userEvent,
      this.props.profile,
      // this.state.userVouchers,
      this.props.customEvent.editMode,
      this.props.newEvent,
      userPackage,
    );
  }

  saveEvent(
    event,
    profile,
    // userVouchers,
    editMode,
    newEvent,
    userPackage,
  ) {
    let eventId = '';
    //Set Default ID
    if (this.props.customEvent.eventId !== undefined) {
      eventId = this.props.customEvent.eventId;
    } else {
      // Get New Event ID to use later
      const db = firebase.firestore();
      const newEventRef = db.collection('package').doc();
      const dbref = newEventRef.id;
      eventId = dbref;
    }

    const scheduledEvent = event;
    if (this.state.scheduleSet === true) {
      scheduledEvent.scheduledTime = this.state.scheduledTime;
    }

    if (newEvent.planFirst === true) {
      scheduledEvent.eventSettings.eventApproved = true;
    }

    // check if there are any custom locations in the event and save them and update.
    if (
      editMode.editMode !== undefined &&
      editMode.editMode === true
    ) {
      const eventBundle = {
        profile: profile,
        event: this.props.customEvent.event,
        package: userPackage,
        // userVouchers: userVouchers,
        // invitation: invitation,
        eventId: eventId,
      };
      // if (this.state.match === true) {
      //   this.props.editCe(eventBundle);
      // } else {
      //   this.props.editCeP(eventBundle);
      // }
    } else {
      const eventBundle = {
        profile: profile,
        event: scheduledEvent,
        package: userPackage,
        // userVouchers: userVouchers, // can we remove?
        // invitation: invitation,
        eventId: eventId,
      };

      if (this.state.match === true) {
        this.props.saveCe(eventBundle);
      } else {
        // this.props.saveCeP(eventBundle); // Removed new packages
        this.props.saveCe(eventBundle);
      }
    }
  }

  createCustomEvent(profile, customEvent, locations, packages) {
    const timestamp = new Date();
    // Get New Event ID to use later
    const db = firebase.firestore();
    const newEventRef = db.collection('package').doc();
    const dbref = newEventRef.id;
    // Create Variables
    let locationThumb = null;
    let locationHero = null;
    const locationArray = [];
    let customTitle = '';
    let customDescription = '';
    let zoneId = null;

    // Create Custom Title
    if (customEvent.title === null) {
      customTitle = `${profile.firstName}'s Event`;
    } else {
      customTitle = customEvent.event.title;
    }

    // Create Custom Description
    if (customEvent.event.description === null) {
      customDescription = '';
    } else {
      customDescription = customEvent.event.description;
    }

    // Create Locations Array and Default Hero/Thumbnail
    const eventLocations = customEvent.event.locations;
    // Check if there are any new locations in the event and if so add to DB
    eventLocations.forEach((location, i) => {
      // if (location.type === 1) {
      //   const db = firebase.firestore();
      //   const newLocationRef = db.collection('locations').doc();
      //   const dbref = newLocationRef.id;
      //   locationArray[i] = {
      //     locationId: dbref,
      //     locationTitle: location.location.locationTitle,
      //     locationType: 1,
      //     locationThumb: location.location.locationThumb,
      //     address: location.location.address,
      //   };
      //   this.uploadLocation(location.location, dbref);
      // } else {
      let locationReady = [];

      if (
        location.locationId !== 'PQszNY3Mpy8oJXTQMooB' &&
        location.locationId !== 'WkhbxzFvfPjnDTXCBVyr' &&
        location.locationId !== '-LKT3cWR6JqNdUTEST'
      ) {
        if (location.locationId !== null) {
          const locationDetails = query('locationId')
            .is(location.locationId)
            .on(locations);
          locationReady = locationDetails[0];
          locationReady.event = false;
          locationReady.eventId = '';
        } else {
          locationReady = location;
          locationReady.locationThumb = location.locationThumb;
          locationReady.locationHero = location.locationHero;
        }
        let customImages = false;
        if (
          locationReady.settings !== undefined &&
          locationReady.settings.customImages !== undefined
        ) {
          customImages = locationReady.settings.customImages;
        }
        locationArray[i] = {
          locationId: locationReady.locationId,
          locationTitle: locationReady.locationTitle,
          locationType: 0,
          locationThumb: locationReady.locationThumb,
          address: locationReady.address,
          settings: {
            customImages: customImages,
          },
          event: locationReady.event,
          eventId: locationReady.eventId,
        };
      } else {
        locationDetails = query('locationId')
          .is(location.locationId)
          .on(this.props.demo.demoLocations);
        let customImages = false;
        if (locationReady.settings.customImages !== undefined) {
          customImages = location.settings.customImages;
        }
        locationArray[i] = {
          locationId: locationReady.locationId,
          locationTitle: locationReady.locationTitle,
          locationType: 0,
          locationThumb: locationReady.locationThumb,
          address: locationReady.address,
          settings: {
            customImages: customImages,
          },
        };
      }
      // Set the default thumbnails
      if (i === 0) {
        zoneId = locationReady.zoneId;
        // If Event is from a location that has a custom image
        if (
          locationReady.settings !== undefined &&
          locationReady.settings.customImages === true
        ) {
          locationThumb = locationReady.locationThumb;
          locationHero = locationReady.locationHero;
        } else {
          // Check if location is an event loction with no tdId

          if (location.locationId === null) {
            locationThumb = location.locationThumb;
            locationHero = location.locationHero;
          } else {
            // If Event is from a location that has a an old school image we need to create the path first
            let heroURL =
              'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
              locationReady.locationHero +
              '?alt=media';

            let thumbURL =
              'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
              locationReady.locationThumb +
              '?alt=media';

            locationThumb = thumbURL;
            locationHero = heroURL;
          }
        }

        // If Event is from a Vendor Experience use the Experience images
        if (this.props.newEvent.fromVEvent === true) {
          locationThumb = this.props.newEvent.vEvent.images.thumbPath;
          locationHero = this.props.newEvent.vEvent.images.heroPath;
        }

        // If Event is from a Package Itinerary
        if (
          this.props.newEvent.fromPackage !== undefined &&
          this.props.newEvent.fromPackage === true
        ) {
          let heroURL =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            this.props.newEvent.vEvent.heroImage +
            '?alt=media';
          let thumbURL =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            this.props.newEvent.vEvent.thumbnail +
            '?alt=media';
          locationThumb = thumbURL;
          locationHero = heroURL;
        }
      }
    });

    // Check for Custom Images
    // if (customEvent.event.eventSettings.customThumb === true) {
    //   locationThumb = customEvent.event.thumbnail;
    // }
    // if (customEvent.event.eventSettings.customHero === true) {
    //   locationHero = customEvent.event.heroImage;
    // }

    // set zoneId - WE MIGHT NOT NEEED THIS

    // if (locationArray.length !== 0) {
    //   if (
    //     locationArray[0].locationId !== 'PQszNY3Mpy8oJXTQMooB' &&
    //     locationArray[0].locationId !== 'WkhbxzFvfPjnDTXCBVyr' &&
    //     locationArray[0].locationId !== '-LKT3cWR6JqNdUTEST'
    //   ) {
    //     locationDetails = query('locationId')
    //       .is(locationArray[0].locationId)
    //       .on(locations);
    //   } else {
    //     locationDetails = query('locationId')
    //       .is(locationArray[0].locationId)
    //       .on(this.props.demo.demoLocations);
    //   }
    //   zoneId = locationDetails[0].zoneId;
    // }

    // Create Package
    const userPackage = {
      created: timestamp,
      title: customTitle,
      id: dbref,
      category: {
        custom: true,
      },
      locations: locationArray,
      description: '',
      heroImage: locationHero,
      thumbnail: locationThumb,
      zoneId,
      creatorInfo: {
        creatorImage: profile.profilePhotoPath,
        creatorName: `${profile.firstName} ${profile.lastName}`,
        creatorId: profile.userId,
      },
    };
    // CREATE PURCHASE RECORD
    const userEvent = {};
    const { selectedVouchers } = this.props;
    // const newVouchersObject = {};
    // const voucherObject = {};
    const { userId } = this.props.profile;

    // SET PURCHASE DETAILS
    userEvent.creationDetails = {
      plannerId: profile.userId,
      plannerPhoto: profile.profilePhotoPath,
      plannerFirstName: profile.firstName,
      plannerLastName: profile.lastName,
      plannerUserName: profile.userName,
      creationDate: timestamp,
    };

    // SET USERS
    const fullName = profile.firstName + profile.lastName;
    var usersObject = {};
    if (this.props.newEvent.planFirst === true) {
      usersObject[userId] = {
        access: true,
        editor: true,
        approved: true,
        attending: true,
        // inviteAction: null,
        profile: {
          profilePhotoPath: profile.profilePhotoPath,
          fullName: fullName,
          userName: profile.userName,
        },
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
      };
      userEvent.invitations = {};
    } else {
      usersObject = this.props.newEvent.connections;
      usersObject[this.props.profile.userId].userMoodYes =
        this.props.taRequest.userMoodYes;
      usersObject[this.props.profile.userId].userMoodNo =
        this.props.taRequest.userMoodNo;
    }
    userEvent.users = usersObject;

    // CREATE EDIT HISTORY
    const localDate = new Date();
    const editHistory = [];
    editHistory[0] = {
      editDate: localDate,
      eventType: 0,
      fullName: fullName,
      userId: profile.userId,
      username: profile.userName,
    };
    userEvent.editHistory = editHistory;

    // SET PACKAGE DETAILS
    userEvent.packageDetails = {
      packageId: dbref,
    };
    userEvent.packageDetails.locations = locationArray;

    // SET EVENT IMAGES
    userEvent.heroImage = locationHero;
    userEvent.thumbnail = locationThumb;

    // Check for Matching Package
    const locationIdArray = [];
    locationArray.forEach((location) => {
      locationIdArray.push(location.locationId);
    });
    packages.some((thispackage) => {
      const tempArray = [];
      const { locations } = thispackage;
      locations.forEach((location) => {
        tempArray.push(location.locationId);
      });
      const status = _.isEqual(tempArray, locationIdArray);
      if (status === true) {
        // SET PACKAGE DETAILS
        userEvent.packageDetails.packageId = thispackage.packageId;
        this.setState({
          match: true,
        });
        return true;
      }
    });

    // SET FAKE SCHEDULE
    // userEvent.scheduledTime = {
    //   date: "",
    //   timestamp: "",
    //   timeString: ""
    // };
    userEvent.scheduledTime = customEvent.event.scheduledTime;
    // SET CREATED DATE
    userEvent.created = timestamp;
    // SET TITLE
    userEvent.title = customTitle;
    // SET DESCRIPTIONS
    userEvent.description = customDescription;
    // SET PACKAGE LOCATIONS AND VOUCHERS
    const activePackageLocations = customEvent.event.locations;
    activePackageLocations.forEach((purchaseLocation, i) => {
      const { locationId } = purchaseLocation;
      // TEMPORARY!!!
      // locations.forEach((location, iii) => {
      //   let voucherCheck = false;
      //   if (location.locationId === locationId) {
      //     if (location.vouchers) {
      //       location.vouchers.forEach(voucher => {
      //         const { voucherId } = voucher;
      //         selectedVouchers.forEach((selectedVoucher, ii) => {
      //           if (selectedVoucher === voucherId) {
      //             voucherCheck = true;
      //             voucherObject[ii] = {
      //               // voucherId: ,
      //               voucherMasterId: voucher.voucherId,
      //               voucherType: voucher.voucherType,
      //               voucherPromo: voucher.voucherPromo,
      //               voucherCost: voucher.voucherCost,
      //               voucherStatus: true,
      //               voucherCreationDate: timestamp,
      //               voucherExpirationDate: "January 1, 2019",
      //               voucherInfo: voucher.voucherInfo,
      //               userId,
      //               locationId: purchaseLocation.locationId,
      //               packageId: dbref
      //             };
      //           }
      //         });
      //       });
      //       if (voucherCheck === true) {
      //         newVouchersObject[iii] = {
      //           vouchers: voucherObject
      //         };
      //       }
      //     }
      //   }
      // });
    });

    // SET PACKAGE SETTINGS
    userEvent.eventSettings = {
      scheduled: false,
      itinerarySent: false,
      editable: true,
      // customHero: customEvent.event.eventSettings.customHero,
      // customThumb: customEvent.event.eventSettings.customThumb,
    };
    if (this.props.newEvent.planFirst === true) {
      userEvent.eventSettings.eventApproved = true;
    } else {
      userEvent.eventSettings.eventApproved = false;
    }

    // In Progess Stuff
    let dateTypes = [];
    this.props.taRequest.tagFilters.forEach((tagCat) => {
      if (tagCat.sid === 'dateType') {
        tagCat.tags.forEach((tag) => {
          if (tag.checked === true) {
            dateTypes.push(tag.tag);
          }
        });
      }
    });
    userEvent.eventSettings.inProgress = {
      eventCats: this.props.taRequest.eventCats,
      startLocation: this.props.taRequest.startLocation,
      dateTypes,
    };

    userEvent.eventSettings.connectFirst =
      this.props.newEvent.connectFirst;

    //Turn Off Edit Mode
    userEvent.editing = {
      editing: false,
      editorId: null,
    };

    // Set Status
    if (this.props.newEvent.planFirst === true) {
      userEvent.status = 0;
    } else {
      userEvent.status = 2;
    }

    if (
      this.props.customEvent.editMode !== undefined &&
      this.props.customEvent.editMode.editMode !== undefined &&
      this.props.customEvent.editMode.editMode === true
    ) {
      const thisEvent = this.props.customEvent.event;
      thisEvent.packageDetails.packageId =
        userEvent.packageDetails.packageId;

      this.setState({
        userEvent: thisEvent,
        userVouchers: selectedVouchers,
        userPackage,
      });
      this.saveMessage(thisEvent, userPackage);
    } else {
      this.setState({
        userEvent,
        userVouchers: selectedVouchers,
        userPackage,
      });
      this.saveMessage(userEvent, userPackage);
    }

    // this.setState({ userEvent, loading: false, userVouchers: voucherObject });
  }

  // checkVouchers(vouchers) {
  //   if (vouchers.length === 0) {
  //     this.triggerModal(this.props.newEvent);
  //   } else {
  //     this.setState({
  //       loading: false,
  //     });
  //   }
  // }

  showLoadScreen() {
    return (
      <div id="loadscreen">
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important; background: #f21d59} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/checkout/customsetup/',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Checkout</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <div>{this.showLoadScreen()}</div>;
    }
    return (
      <div>
        {this.getTitleBar()}
        <div id="checkout" className="section">
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                {/* <button
                  className="redbutton"
                  onClick={() => {
                    this.saveEvent(
                      this.state.userEvent,
                      this.props.profile,
                      this.props.customEvent.editMode
                    );
                  }}
                >
                  Checkout!
                </button> */}
                <button
                  className="redbutton"
                  onClick={() => {
                    this.triggerModal(this.props.newEvent);
                  }}
                >
                  Checkout!
                </button>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.connectFirstModalVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeConnectFirstModal}
          style={modalStyles}
          // contentLabel="Wait for Connection?"
        >
          <div className="full-height">
            <div className="modalHeader">
              <a onPress={() => this.props.closeConnectFirstModal()}>
                {this.getModalHeader()}
              </a>
            </div>
            <div className="section">{this.getModalContent()}</div>
          </div>
        </Modal>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%}  .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    events: state.events.events,
    locations: state.locations.locations,
    user: state.user,
    activePackage: state.activePackage.ap,
    profile: state.profile.profile,
    customEvent: state.customEvent.customEvent,
    cost: state.checkoutBundle.checkoutBundle.cost,
    selectedVouchers:
      state.checkoutBundle.checkoutBundle.selectedVouchers,
    newEvent: state.newEvent,
    demo: state.demo,
    taRequest: state.taRequest,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveCe: (customEvent, newPackage) => {
      dispatch(saveCe(customEvent, newPackage));
    },
    saveCeP: (customEvent, newPackage) => {
      dispatch(saveCeP(customEvent, newPackage));
    },
    editCe: (customEvent, newPackage) => {
      dispatch(editCe(customEvent, newPackage));
    },
    editCeP: (customEvent, newPackage) => {
      dispatch(editCeP(customEvent, newPackage));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomCheckoutReview);
