import React from 'react';
import { connect } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
// Components
import LocationListItemEdit from '../../components/LocationListItemEdit';
import TitleBar from '../../components/TitleBar';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';

const KEYS_TO_FILTERS = ['locationTitle'];

export class AdminCustomLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getAllLocations(1);
    this.filterCustom(this.props.alllocations);
  }

  filterCustom(locations) {
    const filteredArray = [];
    const theselocations = locations;
    theselocations.forEach(location => {
      if (location.custom === true) {
        filteredArray.push(location);
      }
    });
    this.setState({
      filteredArray,
      loading: false,
    });
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  render() {
    if (this.state.loading) {
    }
    const filteredLocations = this.state.filteredArray.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="admin">
        <TitleBar title="All Locations" backlink="" />
        <div className="section">
          <div className="header-container">
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
          </div>
        </div>
        <div>
          <LocationListItemEdit
            locations={filteredLocations}
            edit={true}
            editPath="/admin/customlocation/edit/"
            backPath="/admin/customlocations"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getAllLocations: zone => {
      dispatch(getAllLocations(zone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminCustomLocations);
