import React from 'react';
import { connect } from 'react-redux';
// Components
import VendorLocationsForm from '../../components/VendorLocationsForm';

const query = require('array-query');

export class VendorLocationsEdit extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  getLocation(locationId, locations) {
    const locationDetails = query('locationId')
      .is(locationId)
      .on(locations);
    return locationDetails[0];
  }

  render() {
    let location = [];
    if (this.props.vendor.vendorId !== 'KZry0625YQCjZV99ZZh1') {
      location = this.getLocation(
        this.props.match.params.id,
        this.props.alllocations,
      );
    } else {
      // location = this.getLocation(
      //   this.props.match.params.id,
      //   this.props.demo.demoLocations,
      // );
      location = this.getLocation(
        this.props.match.params.id,
        this.props.alllocations,
      );
    }

    return (
      <div id="vendor">
        <VendorLocationsForm
          editlocation={location}
          history={this.props.history}
          vendor={this.props.vendor}
        />
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} html {display: grid} #analytics{height: 100%}  .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    vendor: state.vendor.vendor,
    demo: state.demo,
  };
}

export default connect(mapStateToProps)(VendorLocationsEdit);
