import * as action from "./actions";

export const getTags = (id) => {
  return {
    type: action.GET_TAGS,
    payload: id,
  };
};

export const setTags = (tags) => {
  return {
    type: action.SET_TAGS,
    payload: tags,
  };
};

const ACTION_HANDLERS = {
  [action.GET_TAGS]: (state, action) => {
    return { ...state };
  },
  [action.SET_TAGS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
