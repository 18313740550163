import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import DailyEntertainmentSpotlight from '../../components/DailyEntertainmentSpotlight';

class GuideCategoryMusicGenre extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {}

  findPromotedLocations(timedEvents, category, tagCat) {
    let upcomingEvents = [];
    timedEvents.forEach((location) => {
      var todayDate = moment(new Date()).format('MM/DD/YYYY');
      var pastDate = moment(location.scheduledTime.dates.endDate);
      var dDiff = pastDate.diff(todayDate);
      if (dDiff > 0 && dDiff !== 0) {
        upcomingEvents.push(location);
      }
    });
    let setTag = undefined;
    let slug = category;
    if (category !== 'Post-Hardcore') {
      slug = category.replace(/-/g, ' ');
    }
    let categoryReady = slug.charAt(0).toUpperCase() + slug.slice(1);

    // Combine Tags'
    let tags = this.props.tags;
    let promotedLocations = [];

    function getPromoted(locations) {
      let promotedLocationIds = [];

      const tagsGenre = [];
      let genre = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Genre') {
            tagsGenre.push(tag.tag);
          }
        });
      });

      upcomingEvents.forEach((location) => {
        if (tagsGenre.includes(categoryReady) === true) {
          if (
            location.tagsGenre !== undefined &&
            location.tagsGenre.length !== 0
          ) {
            location.tagsGenre.forEach((tagx) => {
              if (tagx === categoryReady) {
                const status = promotedLocationIds.includes(
                  location.eventId,
                );
                if (status === false) {
                  promotedLocationIds.push(location.eventId);
                  promotedLocations.push(location);
                }
              }
            });
          }
        }
      });
      return promotedLocations;
    }

    let i = 0;

    while (promotedLocations.length === 0 && i !== 10) {
      promotedLocations = getPromoted(timedEvents);
      i++;
    }

    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
    let shuffledLocations = shuffleArray(promotedLocations);
    let topThree = [];
    let topThreeIds = [];
    shuffledLocations.forEach((location) => {
      const status = topThreeIds.includes(location.eventId);
      if (status === false) {
        topThreeIds.push(location.eventId);
        topThree.push(location);
      }
    });

    let bundle = {
      promotedLocations: topThree,
      setTag,
    };

    return bundle;
  }

  getTagCount(userLove) {
    let userTags = [];
    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };
    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };
    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };
    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };
    let allTags = {
      tagsBeverages: [],
      tagsDateType: [],
      tagsSetting: [],
      tagsCuisine: [],
    };
    // Combine Tags

    userLove.tagsBeverages.forEach((tagcat, x) => {
      let n = allTags.tagsBeverages.includes(tagcat);
      if (n === false) {
        allTags.tagsBeverages.push(tagcat);
      }
    });

    userLove.tagsDateType.forEach((tagcat, x) => {
      let n = allTags.tagsDateType.includes(tagcat);
      if (n === false) {
        allTags.tagsDateType.push(tagcat);
      }
    });

    userLove.tagsSetting.forEach((tagcat, x) => {
      let n = allTags.tagsSetting.includes(tagcat);
      if (n === false) {
        allTags.tagsSetting.push(tagcat);
      }
    });

    userLove.tagsCuisine.forEach((tagcat, x) => {
      let n = allTags.tagsCuisine.includes(tagcat);
      if (n === false) {
        allTags.tagsCuisine.push(tagcat);
      }
    });

    let tagCount =
      allTags.tagsCuisine.length +
      allTags.tagsSetting.length +
      allTags.tagsDateType.length +
      allTags.tagsBeverages.length;
    allTags.tagsSetting;

    return tagCount;
  }

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    return to;
  }

  getSingle(promotedLocations) {
    let location = promotedLocations[0];
    if (promotedLocations.length === 1) {
      return (
        <div>
          <Link
            to={{
              pathname: '/timedevent/' + location.eventId,
              state: {
                location: { location },
                // eventId: { eventId },
                // token: { token },
                type: 500,
              },
            }}
          >
            <div>
              <img
                src={location.eventHero}
                style={{ width: '100%' }}
              />
              <div className="section">
                <span className="title">{location.eventTitle}</span>

                <span className="title clear">
                  {this.getHeadliners(location)} - @{' '}
                  {location.address.locationName}
                </span>
                <span className="address">
                  {this.getDate(location)}
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: location.eventDescription,
                  }}
                  className="editoOverRide"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span className="mainTagClear">{location.tagsMain}</span>
      );
    }
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  getHeadliners(event) {
    let headliners = '';
    event.entertainment.headliners.forEach((entertainment, index) => {
      headliners = headliners + entertainment.entertainmentTitle;
      if (index + 1 !== event.entertainment.headliners.length) {
        headliners = headliners + ' & ';
      }
    });
    return headliners;
  }

  getMoodLinks() {
    let locations = this.props.timedEvents;
    let upcomingEvents = [];
    locations.forEach((location) => {
      var todayDate = moment(new Date()).format('MM/DD/YYYY');
      var pastDate = moment(location.scheduledTime.dates.startDate);
      var dDiff = pastDate.diff(todayDate);
      if (dDiff > 0 && dDiff !== 0) {
        upcomingEvents.push(location);
      }
    });
    let allTags = [];
    upcomingEvents.forEach((event) => {
      if (
        event.tagsEventType !== undefined &&
        event.tagsEventType.length !== 0
      ) {
        let status = event.tagsEventType.includes('Music');
        if (status === true) {
          if (event.tagsGenre !== undefined) {
            event.tagsGenre.forEach((tag) => {
              let status = allTags.includes(tag);
              if (status === false) {
                allTags.push(tag);
              }
            });
          }
        }
      }
    });
    return allTags;
  }

  render() {
    const bundle = this.findPromotedLocations(
      this.props.timedEvents,
      this.props.category,
      this.props.tagCat,
    );
    let promotedLocations = bundle.promotedLocations;
    let back = window.location.pathname;
    let count = this.props.count;
    let links = this.getMoodLinks();
    if (promotedLocations.length > 0) {
      return (
        <div>
          <div>
            <DailyEntertainmentSpotlight
              location={promotedLocations[0]}
            />
          </div>
          <div className="homeMoodBar">
            <Row style={{ margin: '0px 0px 0px 0px' }}>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                // className="no-padding"
                style={{ padding: '5px 5px 5px 5px' }}
              >
                <div
                  style={{
                    width: '80%',
                    maxWidth: '400px',
                    margin: '0px auto',
                  }}
                >
                  {/* <Select
                  value={this.state.selectInput}
                  closeMenuOnSelect={true}
                  blurInputOnSelect={false}
                  onChange={(e) => this.selectChange(e, 0, 'one')}
                  options={selectOptions}
                  isMulti={false}
                  placeholder={'What are you in the mood for?'}
                /> */}
                </div>
              </Col>
            </Row>
            <Row
              style={{ background: 'whitesmoke', marginTop: '5px' }}
            >
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                // className="no-padding"
                style={{ padding: '5px 5px 5px 5px' }}
              >
                {/* <SearchInput
                  className="search-input guide-search"
                  onChange={this.searchUpdated}
                  placeholder="All Locations"
                  value={searchTerm}
                />
                <a
                  onClick={() => {
                    this.clearLanding(false);
                  }}
                  className="pointer"
                >
                  <MaterialIcon
                    icon="search"
                    size={45}
                    color="#ffffff"
                  />
                </a> */}
                <div
                  style={{
                    textAlign: 'center',
                  }}
                  id="menu"
                >
                  <ul>
                    {links.map((link, index) => (
                      <Link
                        to={{
                          pathname: '/guide/category/music/' + link,
                        }}
                        key={index + 1}
                        className={
                          link === this.props.category &&
                          'inactiveLink'
                        }
                      >
                        <li>
                          <span
                            className={
                              link === this.props.category &&
                              'inactiveLink'
                            }
                          >
                            {link}
                          </span>
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="homeSectionAll" id="guide-category">
            {promotedLocations.length > 0 && (
              <div>
                <Container>
                  <Row style={{ marginBottom: '30px' }}>
                    {promotedLocations.map((location, index) => (
                      <Col
                        xs={6}
                        md={4}
                        sm={6}
                        lg={3}
                        className="no-padding"
                      >
                        <div
                          style={{
                            border: '0px solid rgba(0, 0, 0, 0.125)',
                            background: 'white !important',
                          }}
                          className="multiSlide"
                        >
                          <Link
                            to={{
                              pathname:
                                '/timedevent/' + location.eventId,
                              state: {
                                location: { location },
                                // eventId: { eventId },
                                // token: { token },
                                back: { back },
                                type: 500,
                              },
                            }}
                          >
                            <CardImg
                              top
                              width="100%"
                              src={location.eventHero}
                              alt="articleHero"
                            />
                            <CardBody style={{ textAlign: 'left' }}>
                              <div
                                style={{
                                  paddingTop: '10px',
                                  border: '0px',
                                }}
                              >
                                <span className="title">
                                  {location.eventTitle}
                                </span>
                                <span className="address">
                                  {this.getHeadliners(location)} - @{' '}
                                  {location.address.locationName}
                                </span>

                                <span className="address">
                                  {this.getDate(location)}
                                </span>
                                {this.getMainTag(location)}
                                {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                                <CardText>
                                  <span className="editoOverRide">
                                    {this.truncate(
                                      location.eventDescription,
                                    )}
                                  </span>
                                </CardText>
                                <CardText />
                              </div>
                            </CardBody>
                          </Link>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            )}
            {/* {this.getSingle(promotedLocations)} */}
          </div>
        </div>
      );
    }
    return null; // or render a loading icon
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    tags: state.tags.tags,
  };
}

export default connect(mapStateToProps)(GuideCategoryMusicGenre);
