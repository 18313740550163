import firebase from "../config/firebase";

export function getUserVouchers(userId) {
  const vouchersArray = [];
  const db = firebase.firestore();

  const getV = function () {
    return new Promise((resolve, reject) => {
      db.collection("user_vouchers")
        .where("userId", "==", userId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                packageId,
                locationId,
                userId,
                voucherCost,
                voucherCreationDate,
                voucherExpirationDate,
                voucherInfo,
                voucherMasterId,
                voucherPromo,
                voucherStatus,
                voucherType,
              } = doc.data();
              // const expireDateString = expireDate.toDateString();
              // const purchaseDateString = purchaseDate.toDateString();
              vouchersArray.push({
                voucherId: doc.id,
                locationId,
                userId,
                voucherCost,
                voucherCreationDate,
                voucherExpirationDate,
                voucherInfo,
                voucherMasterId,
                voucherPromo,
                voucherStatus,
                voucherType,
                packageId,
              });
            }
          });
          return vouchersArray;
        })
        .then((result) => {
          // const final_vouchers = result[0]
          resolve(vouchersArray);
        });
    });
  };
  return getV();
}
