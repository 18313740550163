import React from 'react';
import { connect } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import firebase from '../../config/firebase';
import { getAllRegions } from '../../sagas/regionsSaga/reducer';
import {
  UncontrolledCollapse,
  Col,
  Row,
  Container,
} from 'reactstrap';
// Components
import TitleBar from '../../components/TitleBar';

const query = require('array-query');

export class AdminRegionEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      title: '',
      numbers: ['One', 'Two', 'Three', 'Four', 'Five', 'Six'],
      loading: true,
      zoneFilter: null,
    };
  }

  componentDidMount() {
    // this.setCurrentValues();
  }

  onChangeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleOnChange = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  getCategoryFields(tags, catId) {
    const options = [];
    tags.forEach((tagCat) => {
      if (
        tagCat.sid !== 'entertainmentType' &&
        tagCat.sid !== 'eventType' &&
        tagCat.sid !== 'genre'
      )
        tagCat.tags.forEach((tag) => {
          options.push({
            value: tag.tag + ',' + tagCat.sid,
            label: tag.tag,
          });
        });
    });
    return (
      <div style={{ marginBottom: '5px' }}>
        <span
          style={{
            fontWeight: '500',
            marginBottom: '5px',
            display: 'block',
            position: 'relative',
          }}
        >
          Tags
        </span>
        <Select
          value={this.state[catId + 'Tags']}
          onChange={(value) => {
            this.handleOnChange(catId + 'Tags', value);
          }}
          options={options}
          isMulti={true}
        />
      </div>
    );
  }

  getEventsCategoryFields(tags, catId) {
    const options = [];
    tags.forEach((tagCat) => {
      if (tagCat.sid === 'eventType' || tagCat.sid === 'genre')
        tagCat.tags.forEach((tag) => {
          options.push({
            value: tag.tag + ',' + tagCat.sid,
            label: tag.tag,
          });
        });
    });
    return (
      <div style={{ marginBottom: '5px' }}>
        <span
          style={{
            fontWeight: '500',
            marginBottom: '5px',
            display: 'block',
            position: 'relative',
          }}
        >
          Tags
        </span>
        <Select
          value={this.state[catId + 'Tags']}
          onChange={(value) => {
            this.handleOnChange(catId + 'Tags', value);
          }}
          options={options}
          isMulti={true}
        />
      </div>
    );
  }

  getEntertainersCategoryFields(tags, catId) {
    const options = [];
    tags.forEach((tagCat) => {
      if (
        tagCat.sid === 'entertainmentType' ||
        tagCat.sid === 'genre'
      )
        tagCat.tags.forEach((tag) => {
          options.push({
            value: tag.tag + ',' + tagCat.sid,
            label: tag.tag,
          });
        });
    });
    return (
      <div style={{ marginBottom: '5px' }}>
        <span
          style={{
            fontWeight: '500',
            marginBottom: '5px',
            display: 'block',
            position: 'relative',
          }}
        >
          Tags
        </span>
        <Select
          value={this.state[catId + 'Tags']}
          onChange={(value) => {
            this.handleOnChange(catId + 'Tags', value);
          }}
          options={options}
          isMulti={true}
        />
      </div>
    );
  }

  setCurrentValues() {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 't' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];
    if (this.props.match.params.id !== undefined) {
      const doc = this.getContent(
        this.props.match.params.id,
        this.props.guideTemplates,
      );
      this.setState({
        title: doc.title,
        categories: doc.categories,
        loading: false,
        templateId: this.props.match.params.id,
      });

      doc.categories.sectionOne.forEach((category, index) => {
        // let catId = 'cat' + this.state.numbers[category.position - 1];
        const zones = category.filters.zones.join(',');
        const zips = category.filters.zips.join(',');
        const cities = category.filters.cities.join(',');
        const type = category.type;
        const visibility = category.visibility;
        const tags = category.filters.tags;
        if (category.time !== undefined) {
          if (category.time.toTime !== undefined) {
            this.setState({
              [category.categoryId + 'ToTime']: category.time.toTime,
              [category.categoryId + 'showTimes']: true,
            });
          }
        }
        if (category.time !== undefined) {
          if (category.time.fromTime !== undefined) {
            this.setState({
              [category.categoryId + 'FromTime']:
                category.time.fromTime,
              [category.categoryId + 'showTimes']: true,
            });
          }
        }
        if (category.time !== undefined) {
          if (category.time.activeDays !== undefined) {
            category.time.activeDays.forEach((day, index) => {
              dayArray.forEach((dayx, index) => {
                if (dayx.short === day) {
                  this.setState({
                    [category.categoryId + dayx.full]: true,
                    [category.categoryId + 'showTimes']: true,
                  });
                }
              });
            });
          }
        }
        const section = category.section;
        let tagArray = [];
        tags.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            tagArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        this.setState({
          [category.categoryId + 'Title']: category.title,
          [category.categoryId + 'Visibility']: visibility,
          [category.categoryId + 'ZipCodes']: zips,
          [category.categoryId + 'Zones']: zones,
          [category.categoryId + 'Cities']: cities,
          [category.categoryId + 'Type']: type,
          [category.categoryId + 'Tags']: tagArray,
          [category.categoryId + 'Section']: section,
        });
      });

      doc.categories.sectionTwo.forEach((category, index) => {
        // let catId = 'cat' + this.state.numbers[category.position - 1];
        const zones = category.filters.zones.join(',');
        const zips = category.filters.zips.join(',');
        const cities = category.filters.cities.join(',');
        const type = category.type;
        const visibility = category.visibility;
        const tags = category.filters.tags;
        const section = category.section;
        let tagArray = [];
        tags.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            tagArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        this.setState({
          [category.categoryId + 'Title']: category.title,
          [category.categoryId + 'Visibility']: visibility,
          [category.categoryId + 'ZipCodes']: zips,
          [category.categoryId + 'Zones']: zones,
          [category.categoryId + 'Cities']: cities,
          [category.categoryId + 'Type']: type,
          [category.categoryId + 'Tags']: tagArray,
          [category.categoryId + 'Section']: section,
        });
      });
      doc.categories.sectionThree.forEach((category, index) => {
        // let catId = 'cat' + this.state.numbers[category.position - 1];
        const zones = category.filters.zones.join(',');
        const zips = category.filters.zips.join(',');
        const cities = category.filters.cities.join(',');
        const type = category.type;
        const visibility = category.visibility;
        const tags = category.filters.tags;
        const section = category.section;
        let tagArray = [];
        tags.forEach((tag, index) => {
          tag.tags.forEach((tagx, index) => {
            tagArray.push({
              value: tagx + ',' + tag.sid,
              label: tagx,
            });
          });
        });
        this.setState({
          [category.categoryId + 'Title']: category.title,
          [category.categoryId + 'Visibility']: visibility,
          [category.categoryId + 'ZipCodes']: zips,
          [category.categoryId + 'Zones']: zones,
          [category.categoryId + 'Cities']: cities,
          [category.categoryId + 'Type']: type,
          [category.categoryId + 'Tags']: tagArray,
          [category.categoryId + 'Section']: section,
        });
      });
    }
  }

  getContent(id, array) {
    if (id !== undefined) {
      const contentDetails = query('id').is(id).on(array);
      return contentDetails[0];
    } else {
      return false;
    }
  }

  saveTemplate = (id, values) => {
    let templateTitle = values.title;

    let tempArray = [];
    tempArray.push(this.state.categories.sectionOne);
    tempArray.push(this.state.categories.sectionTwo);
    tempArray.push(this.state.categories.sectionThree);

    let categories = {
      sectionOne: [],
      sectionTwo: [],
      sectionThree: [],
    };

    tempArray.forEach((sections, index) => {
      sections.forEach((category, index) => {
        let newCategory = {
          filters: {
            tags: [],
            zips: [],
            cities: [],
            zones: [],
          },
          time: {},
          title: this.state[category.categoryId + 'Title'],
          categoryId: category.categoryId,
          position: category.position,
          type: this.state[category.categoryId + 'Type'],
          visibility: this.state[category.categoryId + 'Visibility'],
          section: this.state[category.categoryId + 'Section'],
        };

        if (
          this.state[category.categoryId + 'FromTime'] !== undefined
        ) {
          newCategory.time.fromTime =
            this.state[category.categoryId + 'FromTime'];
        }
        if (
          this.state[category.categoryId + 'ToTime'] !== undefined
        ) {
          newCategory.time.toTime =
            this.state[category.categoryId + 'ToTime'];
        }

        let dayArray = [
          { full: 'Sunday', short: 'su' },
          { full: 'Monday', short: 'm' },
          { full: 'Tuesday', short: 't' },
          { full: 'Wednesday', short: 'w' },
          { full: 'Thursday', short: 'th' },
          { full: 'Friday', short: 'f' },
          { full: 'Saturday', short: 'sa' },
        ];
        let selectedDayArray = [];
        dayArray.forEach((day, index) => {
          if (this.state[category.categoryId + day.full] === true) {
            selectedDayArray.push(day.short);
          }
        });
        newCategory.time.activeDays = selectedDayArray;

        let tagFilters = {};
        let tags = this.state[category.categoryId + 'Tags'];
        if (tags !== undefined) {
          tags.forEach((tagGroup, index) => {
            let broken = tagGroup.value
              .split(',')
              .filter((string) => Boolean(string));
            let tagx = broken[0];
            let sid = broken[1];
            let tagType = '';
            if (sid === 'activityType') {
              tagType = 'tagsActivity';
            } else if (sid === 'beverages') {
              tagType = 'tagsBeverages';
            } else if (sid === 'cuisineType') {
              tagType = 'tagsCuisine';
            } else if (sid === 'locationType') {
              tagType = 'tagsLocationType';
            } else if (sid === 'setting') {
              tagType = 'tagsSetting';
            } else if (sid === 'dateType') {
              tagType = 'tagsDateType';
            } else if (sid === 'experienceType') {
              tagType = 'tagsExperienceType';
            } else if (sid === 'eventType') {
              tagType = 'tagsEventType';
            } else if (sid === 'genre') {
              tagType = 'tagsGenre';
            } else if (sid === 'entertainmentType') {
              tagType = 'tagsEntertainmentType';
            } else if (sid === 'dietary') {
              tagType = 'tagsDietary';
            }
            if (tagFilters[sid] === undefined) {
              tagFilters[sid] = {
                tags: [tagx],
                sid: sid,
                tagType: tagType,
              };
            } else {
              tagFilters[sid].tags.push(tagx);
            }
          });
        }

        if (
          this.state[category.categoryId + 'ZipCodes'] !== undefined
        ) {
          var result = this.state[category.categoryId + 'ZipCodes']
            .split(',')
            .map(function (value) {
              return value.trim();
            });
          result.forEach((zipCode, index) => {
            newCategory.filters.zips.push(zipCode);
          });
        }
        if (this.state[category.categoryId + 'Zones'] !== undefined) {
          var result = this.state[category.categoryId + 'Zones']
            .split(',')
            .map(function (value) {
              return value.trim();
            });
          result.forEach((zoneId, index) => {
            newCategory.filters.zones.push(zoneId);
          });
        }
        if (
          this.state[category.categoryId + 'Cities'] !== undefined
        ) {
          var result = this.state[category.categoryId + 'Cities']
            .split(',')
            .map(function (value) {
              return value.trim();
            });
          result.forEach((city, index) => {
            newCategory.filters.cities.push(city);
          });
        }
        Object.keys(tagFilters).map(function (key) {
          newCategory.filters.tags.push(tagFilters[key]);
        });

        if (this.state[category.categoryId + 'Section'] === 1) {
          categories.sectionOne.push(newCategory);
        } else if (
          this.state[category.categoryId + 'Section'] === 2
        ) {
          categories.sectionTwo.push(newCategory);
        } else if (
          this.state[category.categoryId + 'Section'] === 3
        ) {
          categories.sectionThree.push(newCategory);
        }
      });
    });

    let template = {
      title: templateTitle,
      categories: categories,
    };

    console.log('templatex', template);

    // const db = firebase.firestore();
    // const timestamp = new Date();
    // const templateToUpdate = db
    //   .collection('guide_templates')
    //   .doc(this.state.templateId);
    // const updateDoc = templateToUpdate
    //   .update({
    //     editHistory: {
    //       lastUpdated: timestamp,
    //       lastUpdater: this.props.profile.firstName,
    //     },
    //     title: templateTitle,
    //     categories: categories,
    //   })
    //   .then(() => {
    //     this.props.getGuideTemplates();
    //     this.props.history.push('/admin/templates');
    //   });
  };

  changeText = (e) => {
    // const index = this.state.categories.findIndex((object) => {
    //   return object.categoryId === e.target.name;
    // });

    let x = e.target.id.split('/');
    let catId = x[0];
    let target = x[1];
    let toChange = x[2];

    // console.log('dodo', x);
    // let newCategories = [];
    // let section = '';
    // if (target === 1) {
    //   section = 'sectionOne';
    // } else if (target === 2) {
    //   section = 'sectionTwo';
    // } else if (target === 3) {
    //   section = 'sectionThree';
    // }

    // let oldCategory = this.state.categories[section][x];

    // this.state.categories.forEach((category, i) => {
    //   let categoryTemp = {};
    //   if (i === index) {
    //     categoryTemp = {
    //       title: e.target.value,
    //       position: category.position,
    //       filters: category.filters,
    //       categoryId: category.categoryId,
    //     };
    //   } else {
    //     categoryTemp = category;
    //   }
    //   newCategories.push(categoryTemp);
    // });
    // this.setState({
    //   categories: newCategories,
    // });

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  changeVisibility(category, visibility) {
    this.setState({
      [category.categoryId + 'Visibility']: visibility,
    });
  }

  addCategory = (e) => {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * charactersLength),
      );
    }
    let categories = this.state.categories;
    let newCategory = {
      categoryId: result,
      filters: {
        tags: [],
        zones: [],
        zips: [],
        cities: [],
      },
      position: this.state.categories.sectionThree.length + 1,
      title: 'New Category',
      section: 3,
    };
    categories.sectionThree.push(newCategory);
    this.setState({
      categories,
      [result + 'Title']: 'New Category',
      [result + 'Visibility']: true,
      [result + 'Section']: 3,
    });
  };

  getDeleteButton(category, index, section) {
    return (
      <div style={{ width: '100%', display: 'inline-block' }}>
        <div style={{ float: 'right' }}>
          <MaterialIcon
            icon="delete"
            size={20}
            color="rgb(242, 29, 89)"
            onClick={() => {
              this.confirmDelete(category, index, section);
            }}
          />
        </div>
      </div>
    );
  }

  deleteCategory(category, index, onClose, section) {
    let newCategories = this.state.categories;
    newCategories[section].splice(index, 1);
    this.setState({ categories: newCategories });
    onClose();
  }

  confirmDelete(category, index, section) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div style={{ textAlign: 'center' }}>
              <span
                style={{
                  fontWeight: '600',
                  marginBottom: '15px',
                  display: 'block',
                  fontSize: '20px',
                }}
              >
                Are you sure you want to delete this category?
              </span>
              <span
                className="title"
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  fontSize: '18px',
                }}
              >
                "{category.title}"
              </span>
              <div style={{ marginBottom: '20px' }}>
                <a
                  className="red"
                  onClick={() => {
                    this.deleteCategory(
                      category,
                      index,
                      onClose,
                      section,
                    );
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Delete Category
                </a>
              </div>
            </div>

            <div>
              <div>
                <button onClick={onClose} className="redbutton">
                  Nevermind
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  getVisibleButton(category) {
    if (this.state[category.categoryId + 'Visibility'] == true) {
      return (
        <div style={{ width: '100%', display: 'inline-block' }}>
          <div style={{ float: 'right' }}>
            <MaterialIcon
              icon="visibility"
              size={20}
              color="rgb(242, 29, 89)"
              key={category.categoryId + 'Visibility'}
              onClick={() => {
                this.changeVisibility(category, false);
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: '100%', display: 'inline-block' }}>
          <div style={{ float: 'right' }}>
            <MaterialIcon
              icon="visibility_off"
              size={20}
              color="grey"
              key={category.categoryId + 'VisibilityOff'}
              onClick={() => {
                this.changeVisibility(category, true);
              }}
            />
          </div>
        </div>
      );
    }
  }

  getMoveButton(index, categories, category, section) {
    if (this.state.categories[section].length === 1) {
      if (section === 'sectionOne') {
        return (
          <div style={{ width: '100%', display: 'inline-block' }}>
            <div style={{ float: 'right' }}>
              <MaterialIcon
                icon="arrow_upward"
                size={20}
                color="#d0d0d0"
              />
              <a
                onClick={() => {
                  this.changeSection(
                    categories,
                    'sectionOne',
                    'sectionTwo',
                    index,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_downward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconDown'}
                />
              </a>
            </div>
          </div>
        );
      }
      if (section === 'sectionTwo') {
        return (
          <div style={{ width: '100%', display: 'inline-block' }}>
            <div style={{ float: 'right' }}>
              <a
                onClick={() => {
                  this.changeSection(
                    categories,
                    'sectionTwo',
                    'sectionOne',
                    index,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_upward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconUp'}
                />
              </a>
              <a
                onClick={() => {
                  this.changeSection(
                    categories,
                    'sectionOne',
                    'sectionTwo',
                    index,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_downward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconDown'}
                />
              </a>
            </div>
          </div>
        );
      }
      if (section === 'sectionThree') {
        return (
          <div style={{ width: '100%', display: 'inline-block' }}>
            <div style={{ float: 'right' }}>
              <a
                onClick={() => {
                  this.changeSection(
                    categories,
                    'sectionThree',
                    'sectionTwo',
                    index,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_upward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconUp'}
                />
              </a>
              <MaterialIcon
                icon="arrow_downward"
                size={20}
                color="#d0d0d0"
              />
            </div>
          </div>
        );
      }
    } else {
      if (index === 0) {
        if (section === 'sectionOne') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <MaterialIcon
                  icon="arrow_upward"
                  size={20}
                  color="#d0d0d0"
                />
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'down',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_downward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconDown'}
                  />
                </a>
              </div>
            </div>
          );
        } else if (section === 'sectionTwo') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <a
                  onClick={() => {
                    this.changeSection(
                      categories,
                      'sectionTwo',
                      'sectionOne',
                      index,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_upward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconUp'}
                  />
                </a>
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'down',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_downward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconDown'}
                  />
                </a>
              </div>
            </div>
          );
        } else if (section === 'sectionThree') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <a
                  onClick={() => {
                    this.changeSection(
                      categories,
                      'sectionThree',
                      'sectionTwo',
                      index,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_upward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconUp'}
                  />
                </a>
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'down',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_downward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconDown'}
                  />
                </a>
              </div>
            </div>
          );
        }
      } else if (index > 0 && index !== categories.length - 1) {
        return (
          <div
            style={{ width: '100%', display: 'inline-block' }}
            key={categories[index].categoryId + 'icons'}
          >
            <div style={{ float: 'right' }}>
              <a
                onClick={() => {
                  this.changeOrder(
                    categories,
                    categories[index].position - 1,
                    'up',
                    section,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_upward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconUp'}
                />
              </a>
              <a
                onClick={() => {
                  this.changeOrder(
                    categories,
                    categories[index].position - 1,
                    'down',
                    section,
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_downward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconDown'}
                />
              </a>
            </div>
          </div>
        );
      } else if (index === categories.length - 1) {
        if (section === 'sectionOne') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'up',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_upward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconUp'}
                  />
                </a>
                <a
                  onClick={() => {
                    this.changeSection(
                      categories,
                      'sectionOne',
                      'sectionTwo',
                      index,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_downward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconDown'}
                  />
                </a>
              </div>
            </div>
          );
        } else if (section === 'sectionTwo') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'up',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_upward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconUp'}
                  />
                </a>
                <a
                  onClick={() => {
                    this.changeSection(
                      categories,
                      'sectionTwo',
                      'sectionThree',
                      index,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_downward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconDown'}
                  />
                </a>
              </div>
            </div>
          );
        } else if (section === 'sectionThree') {
          return (
            <div
              style={{ width: '100%', display: 'inline-block' }}
              key={categories[index].categoryId + 'icons'}
            >
              <div style={{ float: 'right' }}>
                <a
                  onClick={() => {
                    this.changeOrder(
                      categories,
                      categories[index].position - 1,
                      'up',
                      section,
                    );
                  }}
                >
                  <MaterialIcon
                    icon="arrow_upward"
                    size={20}
                    color="#f21d59"
                    key={categories[index].categoryId + 'iconUp'}
                  />
                </a>
                <MaterialIcon
                  icon="arrow_downward"
                  size={20}
                  color="#d0d0d0"
                />
              </div>
            </div>
          );
        }
      } else {
        return (
          <div
            style={{ width: '100%', display: 'inline-block' }}
            key={categories[index].categoryId + 'icons'}
          >
            <div style={{ float: 'right' }}>
              <a
                onClick={() => {
                  this.changeOrder(
                    categories,
                    categories[index].position - 1,
                    'up',
                  );
                }}
              >
                <MaterialIcon
                  icon="arrow_upward"
                  size={20}
                  color="#f21d59"
                  key={categories[index].categoryId + 'iconUp'}
                />
              </a>
            </div>
          </div>
        );
      }
    }
  }

  changeSection(categories, oldSection, newSection, index) {
    let newCategories = this.state.categories;
    let oldCategories = this.state.categories;
    let section = 1;
    if (newSection === 'sectionTwo' && oldSection === 'sectionOne') {
      newCategories[newSection].unshift(
        newCategories[oldSection][index],
      );
      section = 2;
      newCategories[oldSection][index].section = 2;
    } else if (
      newSection === 'sectionTwo' &&
      oldSection === 'sectionThree'
    ) {
      newCategories[newSection].push(
        newCategories[oldSection][index],
      );
      section = 2;
      newCategories[oldSection][index].section = 2;
    } else if (newSection === 'sectionThree') {
      newCategories[newSection].unshift(
        newCategories[oldSection][index],
      );
      section = 3;
      newCategories[oldSection][index].section = 3;
    } else if (newSection === 'sectionOne') {
      newCategories[newSection].push(
        newCategories[oldSection][index],
      );
      section = 1;
      newCategories[oldSection][index].section = 1;
    }
    newCategories[newSection].forEach((category, i) => {
      newCategories[newSection][i].position = i + 1;
    });
    let categoryId = oldCategories[oldSection][index].categoryId;
    newCategories[oldSection].splice(index, 1);
    this.setState({
      [categoryId + 'Section']: section,
      categories: newCategories,
    });
  }

  changeOrder(categories, from, direction, section) {
    let newCategories = this.state.categories;
    let to = 0;
    if (direction === 'up') {
      to = from - 1;
    }
    if (direction === 'down') {
      to = from + 1;
    }
    let categoriesX = [];
    let tempGroup = [];
    newCategories[section].forEach((category, i) => {
      categoriesX.push(category);
    });
    var f = categoriesX.splice(from, 1)[0];
    categoriesX.splice(to, 0, f);
    categoriesX.forEach((category, i) => {
      let categoryTemp = {
        title: category.title,
        position: i + 1,
        filters: category.filters,
        categoryId: category.categoryId,
        section: category.section,
      };
      tempGroup.push(categoryTemp);
      newCategories[section] = tempGroup;
    });
    this.setState({
      categories: newCategories,
    });
  }

  getCategoryBlock(category, index, section) {
    let catId = category.categoryId;
    let tags = category.filters.tags;

    let showTimes = false;
    if (this.state[catId + 'showTimes'] !== undefined) {
      showTimes = this.state[catId + 'showTimes'];
    }

    return (
      <div
        id={catId}
        key={catId + 'block'}
        style={{
          borderBottom: '1px solid #dddddd',
          marginBottom: '10px',
        }}
      >
        <div style={{ width: '100%', height: '30px', clear: 'both' }}>
          <span style={{ fontWeight: '600' }}>
            {this.state[category.categoryId + 'Title']}
          </span>
          <div style={{ float: 'right', marginRight: '10px' }}>
            {this.getDeleteButton(category, index, section)}
          </div>
          <div style={{ float: 'right', marginRight: '10px' }}>
            {this.getVisibleButton(category)}
          </div>
          <div
            id={catId + 'Toggle'}
            key={catId + 'Toggle'}
            className={
              this.state[catId + 'Toggler'] === true
                ? 'collapsed'
                : ''
            }
            data-toggle="collapse"
            style={{ float: 'right', marginRight: '10px' }}
          >
            <MaterialIcon icon="edit" size={25} color="#f21d59" />
          </div>
          <div style={{ float: 'right', marginRight: '10px' }}>
            {this.getMoveButton(
              index,
              this.state.categories[section],
              category,
              section,
            )}
          </div>
        </div>

        <UncontrolledCollapse
          toggler={catId + 'Toggle'}
          defaultOpen={false}
          key={catId + 'collapse'}
          style={{ width: '100%' }}
        >
          <div
            className="criteria"
            style={{
              padding: '10px',
              border: '1px solid',
            }}
            key={catId + 'innerdiv'}
          >
            <div key={catId + 'button'}>
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                }}
              >
                Category Title
              </span>
              <input
                name={
                  this.state.categories[section][index].categoryId +
                  'Title'
                }
                value={
                  this.state[
                    this.state.categories[section][index].categoryId +
                      'Title'
                  ]
                }
                onChange={this.changeText}
                type="text"
                placeholder=""
                key={catId + 'input'}
              />
              {/* {tags.map((tag, index) => (
                <div>{tag.tag}</div>
              ))} */}
              {/* {this.props.tags.map((cattag, index) => (
          <div>{this.getMainCuisineFields(cattag)}</div>
        ))} */}
            </div>
            <div
              onChange={this.onChangeValue}
              style={{ padding: '10px' }}
            >
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                Type
              </span>
              <input
                type="radio"
                value="Locations"
                name={category.categoryId + 'Type'}
                style={{
                  width: 'auto',
                  minWidth: 'auto',
                }}
                checked={
                  this.state[category.categoryId + 'Type'] ===
                  'Locations'
                    ? true
                    : false
                }
              />
              <span
                style={{
                  marginRight: '10px',
                  marginLeft: '5px',
                }}
              >
                Locations
              </span>
              <input
                type="radio"
                value="Entertainers"
                checked={
                  this.state[category.categoryId + 'Type'] ===
                  'Entertainers'
                    ? true
                    : false
                }
                name={category.categoryId + 'Type'}
                style={{
                  width: 'auto',
                  minWidth: 'auto',
                  marginRight: '5px',
                }}
              />
              <span
                style={{
                  marginRight: '10px',
                }}
              >
                Entertainers
              </span>
              <input
                type="radio"
                value="Events"
                name={category.categoryId + 'Type'}
                style={{
                  width: 'auto',
                  minWidth: 'auto',
                }}
                checked={
                  this.state[category.categoryId + 'Type'] ===
                  'Events'
                    ? true
                    : false
                }
              />
              <span
                style={{
                  marginRight: '10px',
                  marginLeft: '5px',
                }}
              >
                Events
              </span>
            </div>

            <div style={{ padding: '10px' }}>
              {this.state[category.categoryId + 'Type'] ===
                'Events' &&
                this.getEventsCategoryFields(this.props.tags, catId)}

              {this.state[category.categoryId + 'Type'] ===
                'Locations' &&
                this.getCategoryFields(this.props.tags, catId)}

              {this.state[category.categoryId + 'Type'] ===
                'Entertainers' &&
                this.getEntertainersCategoryFields(
                  this.props.tags,
                  catId,
                )}

              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                }}
              >
                City Names
              </span>
              <input
                id={
                  this.state[category.categoryId + 'Section'] +
                  '/' +
                  index +
                  '/' +
                  'cities'
                }
                name={catId + 'Cities'}
                value={this.state[catId + 'Cities']}
                onChange={this.changeText}
                type="text"
                placeholder=""
              />
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                }}
              >
                Zip Codes
              </span>
              <input
                id={catId + '/ZipCodes'}
                name={catId + 'ZipCodes'}
                value={this.state[catId + 'ZipCodes']}
                onChange={this.changeText}
                type="text"
                placeholder=""
              />

              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                }}
              >
                Zones
              </span>
              <input
                id={catId + '/Zones'}
                name={catId + 'Zones'}
                value={this.state[catId + 'Zones']}
                onChange={this.changeText}
                type="text"
                placeholder=""
              />
            </div>
            {showTimes === false && (
              <div>
                <span
                  onClick={() => {
                    this.showTimes(catId);
                  }}
                  style={{ color: 'rgb(242, 29, 89)' }}
                >
                  Show Time Options
                </span>
              </div>
            )}
            {showTimes === true && (
              <div
                style={{
                  padding: '10px',
                }}
              >
                <span
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                    position: 'relative',
                  }}
                >
                  Time Options
                </span>
                <Container>
                  <Row>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Sun
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state[catId + 'Sunday']}
                        onChange={(value) => {
                          this.onChangeDate(catId, 'Sunday', value);
                        }}
                        className="closedCheckBox"
                        style={{ float: 'none' }}
                      />
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Mon
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state[catId + 'Monday']}
                        onChange={(value) => {
                          this.onChangeDate(catId, 'Monday', value);
                        }}
                        className="closedCheckBox"
                        style={{ float: 'none' }}
                      />
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Tues
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state[catId + 'Tuesday']}
                        onChange={(value) => {
                          this.onChangeDate(catId, 'Tuesday', value);
                        }}
                        className="closedCheckBox"
                        style={{ float: 'none' }}
                      />
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Wed
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state[catId + 'Wednesday']}
                        onChange={(value) => {
                          this.onChangeDate(
                            catId,
                            'Wednesday',
                            value,
                          );
                        }}
                        className="closedCheckBox"
                        style={{ float: 'none' }}
                      />
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Thurs
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state[catId + 'Thursday']}
                        onChange={(value) => {
                          this.onChangeDate(catId, 'Thursday', value);
                        }}
                        className="closedCheckBox"
                        style={{ float: 'none' }}
                      />
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Fri
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state[catId + 'Friday']}
                        onChange={(value) => {
                          this.onChangeDate(catId, 'Friday', value);
                        }}
                        className="closedCheckBox"
                        style={{ float: 'none' }}
                      />
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Sat
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state[catId + 'Saturday']}
                        onChange={(value) => {
                          this.onChangeDate(catId, 'Saturday', value);
                        }}
                        className="closedCheckBox"
                        style={{ float: 'none' }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <span
                        className="inputLabel"
                        style={{ clear: 'both', display: 'block' }}
                      >
                        From
                      </span>
                      <input
                        name={catId + 'FromTime'}
                        value={this.state[catId + 'FromTime']}
                        onChange={(value) => {
                          this.fromTimeChange(
                            catId,
                            'FromTime',
                            value,
                          );
                        }}
                        type="time"
                        placeholder=""
                        // min="09:00"
                        // max="18:00"
                        style={{ float: 'left', minWidth: '40px' }}
                      />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                      <span
                        className="inputLabel"
                        style={{ clear: 'both', display: 'block' }}
                      >
                        To
                      </span>
                      <input
                        name={catId + 'ToTime'}
                        value={this.state[catId + 'ToTime']}
                        onChange={(value) => {
                          this.fromTimeChange(catId, 'ToTime', value);
                        }}
                        type="time"
                        placeholder=""
                        // min="09:00"
                        // max="18:00"
                        style={{ float: 'left', minWidth: '40px' }}
                      />
                    </div>
                  </Row>
                </Container>
              </div>
            )}
          </div>
        </UncontrolledCollapse>
      </div>
    );
  }

  onChangeDate = (catId, day, value) => {
    let combo = catId + day;
    this.setState({
      [combo]: value.target.checked,
    });
  };

  fromTimeChange = (catId, day, value) => {
    let combo = catId + day;
    console.log('sometime', combo, value.target.value);
    this.setState({
      [combo]: value.target.value,
    });
  };

  // getZoneIds(locations) {
  //   let zoneIds = [];
  //   let selectOptions = [];
  //   locations.forEach((location, indexA) => {
  //     let status = zoneIds.includes(location.zoneId);
  //     if (status === false) {
  //       selectOptions.push({
  //         value: location.zoneId,
  //         label: location.zoneId + ' - ' + location.address.state,
  //       });
  //       zoneIds.push(location.zoneId);
  //     }
  //   });
  //   let sorted = selectOptions.sort(function (a, b) {
  //     return a.value - b.value;
  //   });
  //   return sorted;
  // }

  getZoneIds() {
    let selectOptions = [
      {
        value: 1,
        label: 1 + ' - NE',
      },
      {
        value: 2,
        label: 2 + ' - CO',
      },
      {
        value: 3,
        label: 3 + ' - LA',
      },
      {
        value: 4,
        label: 4 + ' - PA',
      },
      {
        value: 5,
        label: 5 + ' - CA',
      },
      {
        value: 6,
        label: 6 + ' - OK',
      },
      {
        value: 9,
        label: 9 + ' - DC',
      },
      {
        value: 10,
        label: 10 + ' - KC',
      },
      {
        value: 11,
        label: 11 + ' - MO',
      },
      {
        value: 27,
        label: 27 + ' - MI',
      },
    ];

    let sorted = selectOptions.sort(function (a, b) {
      return a.value - b.value;
    });
    return sorted;
  }

  selectChange = (values) => {
    this.setState({
      zoneFilter: values.value,
      selectInput: {
        label: values.label,
        value: values.value,
      },
    });
  };

  clearZone = () => {
    this.setState({
      zoneFilter: null,
      selectInput: {
        label: '',
        value: '',
      },
    });
  };

  showTimes = (catId) => {
    this.setState({
      [catId + 'showTimes']: true,
    });
  };

  getForm(sectionOne, sectionTwo, sectionThree) {
    return (
      <div key="form">
        <Formik
          enableReinitialize
          initialValues={{
            title: this.state.title,
          }}
          validate={(values) => {
            let errors = {};
            if (!values.title) {
              errors.title = 'Required';
            }
            return errors;
          }}
          onSubmit={(values) => {
            this.saveTemplate(this.state.id, values);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Container>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="left"
                  >
                    <span className="inputLabel">Title</span>
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="error-message"
                    />
                    <Field
                      type="textfield"
                      name="title"
                      placeholder="Template Title"
                    />
                    <div
                      style={{
                        width: '250px',
                        float: 'left',
                        marginRight: '20px',
                      }}
                    >
                      {/* <span
                        style={{
                          float: 'left',
                          marginRight: '10px',
                          display: 'block',
                          top: '10px',
                          fontWeight: '500',
                          position: 'relative',
                        }}
                      >
                        Zone Id
                      </span> */}
                      {/* <Select
                        value={this.state.selectInput}
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectChange(e, 0, 'one')
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'Filter By ZoneId'}
                      /> */}
                      <span
                        style={{
                          fontWeight: '500',
                          marginBottom: '5px',
                          display: 'block',
                          position: 'relative',
                        }}
                      >
                        City Names
                      </span>
                      <input
                        name={'cities'}
                        value={this.state.cities}
                        onChange={this.changeText}
                        type="text"
                        placeholder=""
                      />
                      <span
                        style={{
                          fontWeight: '500',
                          marginBottom: '5px',
                          display: 'block',
                          position: 'relative',
                        }}
                      >
                        Zip Codes
                      </span>
                      <input
                        name={'zipCodes'}
                        value={this.state.zipCodes}
                        onChange={this.changeText}
                        type="text"
                        placeholder=""
                      />
                    </div>
                    <a
                      onClick={(e) => this.clearZone()}
                      style={{
                        float: 'left',
                        position: 'relative',
                        top: '10px',
                      }}
                    >
                      <span>Clear</span>
                    </a>
                  </Col>
                </Row>
              </Container>

              <button
                type="submit"
                className="redbutton right"
                // disabled={isSubmitting}
                style={{ marginLeft: '10px' }}
              >
                Save
              </button>

              <span
                className="redbutton right"
                onClick={() => {
                  this.addCategory();
                }}
              >
                Add Category
              </span>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  render() {
    // let categories = this.state.categories;
    // if (this.state.loading) {
    //   return null;
    // }
    return (
      <div>
        <TitleBar title="Edit Template" backlink="/admin/templates" />
        <div className="section">
          <Container>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12}>
                {this.getForm()}
              </Col>
            </Row>
          </Container>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.backbar{background: #313131 !important} [data-toggle="collapse"]:after{display: none} .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllRegions: () => {
      dispatch(getAllRegions());
    },
  };
};

function mapStateToProps(state) {
  return {
    regions: state.regions.regions,
    tags: state.tags.tags,
    user: state.user,
    profile: state.profile.profile,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminRegionEdit);
