import firebase from '../config/firebase';
import geolib from 'geolib';

export function getRegions(zoneId) {
  const returnArray = [];
  let nearbyAddedRegions = [];
  const db = firebase.firestore();
  const guideTemplates = db.collection('regions');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = guideTemplates
        .where('zoneId', '==', zoneId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const { name, slug, zips, lat, long, nearbyRegions } =
                doc.data();
              returnArray.push({
                id: doc.id,
                name,
                nearbyRegions,
                slug,
                zips,
                lat,
                long,
                zoneId,
              });
            }
          });

          // returnArray.forEach((region, index) => {
          //   let newRegion = {
          //     id: region.id,
          //     lat: region.lat,
          //     long: region.long,
          //     name: region.name,
          //     nearbyRegions: [],
          //     slug: region.slug,
          //     zips: region.zips,
          //     zoneId: region.zoneId,
          //   };

          // returnArray.forEach((regionTwo) => {
          //   let regionDistance = geolib.getDistance(
          //     { latitude: region.long, longitude: region.lat },
          //     {
          //       latitude: regionTwo.long,
          //       longitude: regionTwo.lat,
          //     },
          //   );

          //   let regionDistanceMiles = geolib.convertUnit(
          //     'mi',
          //     regionDistance,
          //     2,
          //   );

          //   if (regionDistanceMiles < 3) {
          //     newRegion.nearbyRegions.push(regionTwo);
          //   }
          // });
          // console.log('bulldog', returnArray);
          // if (region.nearbyRegions !== undefined) {
          //   region.nearbyRegions.forEach((nearbyRegion) => {
          //     newRegion.nearbyRegions.push(nearbyRegion.name);
          //   });
          // }

          // nearbyAddedRegions.push(newRegion);

          return returnArray;
        })
        .then((result) => {
          resolve(returnArray);
        });
    });
  };
  return getP();
}

export function getAllRegions() {
  let nearbyAddedRegions = [];
  const returnArray = [];
  const db = firebase.firestore();
  const guideTemplates = db.collection('regions');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = guideTemplates
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                name,
                slug,
                zips,
                zoneId,
                lat,
                long,
                nearbyRegions,
              } = doc.data();
              returnArray.push({
                id: doc.id,
                name,
                slug,
                zips,
                nearbyRegions,
                zoneId,
                lat,
                long,
                zoneId,
              });
            }
          });
          // returnArray.forEach((region, index) => {
          //   let newRegion = {
          //     id: region.id,
          //     lat: region.lat,
          //     long: region.long,
          //     name: region.name,
          //     nearbyRegions: [],
          //     slug: region.slug,
          //     zips: region.zips,
          //     zoneId: region.zoneId,
          //   };

          // returnArray.forEach((regionTwo) => {
          //   let regionDistance = geolib.getDistance(
          //     { latitude: region.long, longitude: region.lat },
          //     {
          //       latitude: regionTwo.long,
          //       longitude: regionTwo.lat,
          //     },
          //   );

          //   let regionDistanceMiles = geolib.convertUnit(
          //     'mi',
          //     regionDistance,
          //     2,
          //   );

          //   if (regionDistanceMiles < 3) {
          //     newRegion.nearbyRegions.push(regionTwo);
          //   }
          // });

          // if (region.nearbyRegions !== undefined) {
          //   region.nearbyRegions.forEach((nearbyRegion) => {
          //     newRegion.nearbyRegions.push(nearbyRegion.name);
          //   });
          // }
          // nearbyAddedRegions.push(newRegion);
          // });
          return returnArray;
        })
        .then((result) => {
          resolve(returnArray);
        });
    });
  };
  return getP();
}
