import React from 'react';
import { connect } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
import { Col, Row } from 'reactstrap';
// Components
import TitleBar from '../../components/TitleBar';
// Reducers
import { getAllEvents } from '../../sagas/allEventsSaga/reducer';

const KEYS_TO_FILTERS = ['title'];

export class AdminEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  UNSAFE_componentWillMount() {
    if (Object.keys(this.props.allEvents).length === 0) {
      this.props.getAllEvents();
    }
    this.setState({ loading: false });
  }

  getEventTitle(event) {
    const url = '/event/' + event.eventId;
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {event.title}
      </a>
    );
  }

  getEvents(events) {
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={6} md={6} sm={6} lg={6} className="left">
                  <span className="label">Event Title</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Creator</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label"># Locations</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Actions</span>
                </Col>
              </div>
            </li>
            {events.map((event, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={6}
                    key={'key' + index}
                  >
                    {this.getEventTitle(event)}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {event.creationDetails.plannerFirstName}{' '}
                    {event.creationDetails.plannerLastName}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left" />
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {/* {this.getEdit(vendor.id)} */}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    const filteredEvents = this.props.allEvents.events.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="admin">
        <TitleBar title="User Events" backlink="" />
        <div className="section">
          <div className="header-container">
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
          </div>
        </div>
        <div>{this.getEvents(filteredEvents)}</div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllEvents: zone => {
      dispatch(getAllEvents(zone));
    },
  };
};

function mapStateToProps(state) {
  return {
    allEvents: state.allEvents,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminEvents);
