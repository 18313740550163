import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

// Components
import GuestListItem from '../GuestListItem';

const query = require('array-query');

class GuestList extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  createGuestList(event) {
    const guestListArray = [];

    if (event.connections !== undefined) {
      Object.keys(event.connections).forEach((key) => {
        let status = 0;
        if (event.connections[key].attending === true) {
          status = 1;
        }
        if (
          event.connections[key].rejected !== undefined &&
          event.connections[key].rejected === true
        ) {
          status = 2;
        }
        if (event.connections[key].access === true) {
          guestListArray.push({
            fullName: event.connections[key].profile.fullName,
            profilePhotoPath:
              event.connections[key].profile.profilePhotoPath,
            userId: key,
            userName: event.connections[key].profile.userName,
            user: event.connections[key],
            status,
          });
        }
      });
    } else {
      Object.keys(event.users).forEach((key) => {
        let status = 0;
        if (event.users[key].attending === true) {
          status = 1;
        }
        if (
          event.users[key].rejected !== undefined &&
          event.users[key].rejected === true
        ) {
          status = 2;
        }
        if (event.users[key].access === true) {
          guestListArray.push({
            fullName: event.users[key].profile.fullName,
            profilePhotoPath:
              event.users[key].profile.profilePhotoPath,
            userId: key,
            userName: event.users[key].profile.userName,
            user: event.users[key],
            status,
          });
        }
      });
    }

    // Attendees

    // Invited
    //  this.props.notifications.forEach((notification) => {
    //    if (notification.eventId === this.props.activePackage.eventId) {
    //      const firstName = notification.receiverName
    //        .split(' ')
    //        .slice(0, -1)
    //        .join(' ');
    //      const lastName = notification.receiverName
    //        .split(' ')
    //        .slice(-1)
    //        .join(' ');
    //      guestListArray.push({
    //        firstName,
    //        lastName,
    //        profilePhotoPath: notification.receiverPhotoPath,
    //        userId: notification.userId,
    //        userName: notification.userName,
    //        status: notification.status,
    //      });
    //    }
    //  });
    // Remove Duplicates and Sort by First Name
    //  const dup = _.uniqBy(guestListArray, 'userId');
    //  const listReady = _.sortBy(dup, o => o.firstName);

    return guestListArray;
  }

  getAddButton(users, userId) {
    if (
      users[userId] !== undefined &&
      users[userId].editor === true &&
      this.props.event.status !== 9
    ) {
      return (
        <MaterialIcon
          icon="add_circle"
          size={40}
          color="#f21d59"
          onClick={() => {
            this.props.changeScreenAndResetToken(2);
          }}
        />
      );
    }
  }

  render() {
    let list = [];
    if (this.props.customEvent.clear === true) {
      list = this.props.event;
    } else {
      list = this.props.customEvent.event;
    }

    let guestListArrray = this.createGuestList(list);
    let connections = [];
    if (this.props.event.connections !== undefined) {
      connections = this.props.event.connections;
    } else {
      connections = this.props.event.users;
    }
    let planner = false;
    if (
      (this.props.customEvent.clear === false &&
        this.props.event.creationDetails !== undefined &&
        this.props.profile.userId ===
          this.props.event.creationDetails.plannerId) ||
      this.props.customEvent.clear === true
    ) {
      planner = true;
    }
    return (
      <div>
        <Container>
          {this.state.searchTerm === '' && (
            <Row className="no-margin" style={{ paddingTop: '20px' }}>
              <Col xs={12} md={12} sm={12} lg={12}>
                <div style={{ padding: '0px 0px 0px 0px' }}>
                  {this.getConnections(connectionsArray)}
                </div>
              </Col>
            </Row>
          )}
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              {guestListArrray.map((guest, index) => (
                <GuestListItem
                  guest={guest}
                  index={index}
                  key={'key' + index}
                  planner={planner}
                  confirmDelete={this.props.confirmDelete}
                  plannerId={this.props.profile.userId}
                  confirmCopy={this.props.confirmCopy}
                  event={this.props.event}
                />
              ))}
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding text-right"
            >
              <div className="addicon">
                {this.getAddButton(
                  connections,
                  this.props.profile.userId,
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { customEvent: state.customEvent.customEvent };
}

export default connect(mapStateToProps)(GuestList);

// Location Details Available
// **************************
// this.props.location.state.location.location.locationTitle
// this.props.location.state.location.location.categories
// this.props.location.state.location.location.locationDescription
// this.props.location.state.location.location.locationHero
// this.props.location.state.location.location.locationThumb
// this.props.location.state.location.location.locationId
// this.props.location.state.location.location.locationType
// ** Vouchers and Packages as well
