import * as action from "./actions";

export const getAllLocations = (zoneId) => {
  return {
    type: action.GETALL_LOCATIONS,
    payload: {},
    zoneId: zoneId,
  };
};

export const setLocations = (location) => {
  return {
    type: action.SETALL_LOCATIONS,
    payload: location,
  };
};

const ACTION_HANDLERS = {
  [action.GETALL_LOCATIONS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SETALL_LOCATIONS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
