import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getVendorPromos,
  approveVendorPromo,
  editMode,
} from '../../services/VendorPromoServices';
import { push } from 'connected-react-router';

import * as actions from './actions';

let defaultState = {
  promos: [],
};

function* getVendorPromosAction(vendorId) {
  if (vendorId !== undefined) {
    let promos = yield call(getVendorPromos, vendorId.vendorId);
    yield put({
      type: actions.SET_VENDORPROMOS,
      payload: {
        ...defaultState,
        promos,
      },
    });
  }
}

function* refreshVendorPromosAction(action) {
  const promos = action.payload;
}

// Approve Promo
function* approvePromoAction(action) {
  const url = '/vendor/experiences';
  yield call(approveVendorPromo, action.payload);
  yield put(push(url));
}

// Reject Promo
function* rejectPromoAction(action) {
  // yield call(rejectPromo, action.payload);
  // yield put(push('/events/upcoming/'));
}

// Enter Promo Edit Mode
function* editPromoModeAction(action) {
  yield call(editMode, action.payload);

  // yield put({
  //   type: actions.SET_CE,
  //   payload: {
  //     ...defaultState,
  //     defaultState
  //   }
  // });

  let url = '';
  if (action.payload.editing.editing === true) {
    url = '/vendor/promo/edit/' + action.payload.promo.id;
  } else url = '/vendor/experiences';

  yield put(push(url));
}

export default function* sagas() {
  yield takeLatest(actions.GET_VENDORPROMOS, getVendorPromosAction);
  yield takeLatest(
    actions.REFRESH_VENDORPROMOS,
    refreshVendorPromosAction,
  );
  yield takeLatest(actions.APPROVE_VENDORPROMOS, approvePromoAction);
  yield takeLatest(actions.REJECT_VENDORPROMOS, rejectPromoAction);
  yield takeLatest(actions.EDIT_VENDORPROMOS, editPromoModeAction);
}
