import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import './LocationDetails.css';

const query = require('array-query');

const AnyReactComponent = ({ text }) => (
  <div>
    <center>
      <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgoicon.png?alt=media&token=44afb950-2e1a-4e8a-b7e2-a0178a49fd4f" />
    </center>
  </div>
);

class LocationDetails extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  static defaultProps = {
    zoom: 15,
  };

  setHappyHourArray(happyHours) {
    const happyHourArray = [];
    if (happyHours.length !== 0) {
      happyHours.forEach(happyHour => {
        if (happyHour.happyHourType !== 2) {
          happyHourArray.push(happyHour);
        }
      });
    }
    return happyHourArray;
  }

  happyHourSection(happyHours) {
    let happyHourArray = this.setHappyHourArray(happyHours);
    if (happyHourArray.length !== 0) {
      return (
        <div className="card-section">
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div className="happyHourListing">
                <span className="location-title">Happy Hours</span>
                <div className="happyHour-container">
                  {happyHourArray.map(happyHour => (
                    <div className="happyHour">
                      <p>{happyHour.happyHourPromo}</p>
                      {/* <button className="purchase">Select HappyHour</button> */}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }

  promoSection(happyHours) {
    const promoArray = this.setPromoArray(happyHours);
    if (promoArray.length === 0) {
      return <p>No Promos Available</p>;
    } else {
      return (
        <div className="happyHour-container">
          {promoArray.map(promo => (
            <div className="happyHour">
              <p>{promo.happyHourPromo}</p>
              {/* <button className="purchase">Select HappyHour</button> */}
            </div>
          ))}
        </div>
      );
    }
  }

  getPromos(locationId, promos) {
    let promoArray = [];
    if (promos !== null && promos !== undefined) {
      promos.forEach(promo => {
        if (promo.locationId === locationId) {
          promoArray.push(promo);
        }
      });
    }
    if (promoArray.length !== 0) {
      return (
        <div className="card-section">
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div className="happyHourListing">
                <div className="happyHour-container">
                  <span className="location-title">Promotions</span>
                  {promoArray.map(promo => (
                    <div className="happyHour">
                      <span>{promo.title}</span>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }

  getHappyHoursAndPromos(location) {
    if (location.happyHours !== undefined) {
      return (
        <div>
          {this.getPromos(location.locationId, this.props.promos)}
          {this.happyHourSection(location.happyHours)}
        </div>
      );
    }
  }

  getWebsite(location) {
    if (location.website !== undefined && location.website !== null) {
      return (
        <Row className="no-margin">
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            <div>
              <a
                href={location.website}
                target="_blank"
                rel="noopener noreferrer"
                className="red"
              >
                <span className="left website">Visit Website</span>
              </a>
            </div>
          </Col>
        </Row>
      );
    }
  }

  getPhone(location) {
    if (location.phone !== undefined && location.phone !== null) {
      let path = 'tel:' + location.phone;
      return (
        <a href={path} className="red">
          <span className="right phone">{location.phone}</span>
        </a>
      );
    }
  }

  render() {
    return (
      <div>
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div>
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={
                      'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
                      this.props.location.locationHero +
                      '?alt=media'
                    }
                    alt="articleHero"
                  />
                  <CardBody>
                    <CardTitle>
                      {this.props.location.locationTitle}
                    </CardTitle>
                    <CardText>
                      <div className="card-section">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.props.location
                              .locationDescription,
                          }}
                        />
                      </div>
                      <div className="card-section">
                        <Row className="no-margin">
                          <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={6}
                            className="no-padding"
                          >
                            <span className="address bold">
                              {this.props.location.address.street}
                            </span>
                            <span className="address">
                              {this.props.location.address.city}{' '}
                              {this.props.location.address.state},{' '}
                              {this.props.location.address.zip}
                            </span>
                          </Col>
                          <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={6}
                            className="no-padding"
                          >
                            {this.getPhone(this.props.location)}
                          </Col>
                        </Row>
                        {this.getWebsite(this.props.location)}
                      </div>

                      {this.getHappyHoursAndPromos(
                        this.props.location,
                      )}

                      {/* <div className="card-section">
                        <div style={{ height: "200px", width: "100%" }}>
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: "AIzaSyBGGWTnJQdshIr_HSsNYJREN_vWIlxZUX8",
                            }}
                            defaultCenter={[
                              this.props.location.address.longitude,
                              this.props.location.address.latitude,
                            ]}
                            defaultZoom={this.props.zoom}
                          >
                            <AnyReactComponent
                              lat={this.props.location.address.longitude}
                              lng={this.props.location.address.latitude}
                              text={this.props.location.locationTitle}
                            />
                          </GoogleMapReact>
                        </div>
                      </div> */}
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(LocationDetails);

// Location Details Available
// **************************
// this.props.location.locationTitle
// this.props.location.categories
// this.props.location.locationDescription
// this.props.location.locationHero
// this.props.location.locationThumb
// this.props.location.locationId
// this.props.location.locationType
// ** HappyHours and Packages as well
