import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import AwardDetails from '../components/AwardDetails';
import { updateLocations } from '../sagas/locationsSaga/reducer';
import firebase from '../config/firebase';
import whiteLogo from '../images/trevel_logo_white.png';
import { Link } from 'react-router-dom';
// Components
import TitleBar from '../components/TitleBar';

const query = require('array-query');
const _ = require('lodash');

export class UserVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      collapse: false,
      mode: 1,
      awardCount: 0,
      redeemedCount: 0,
      validate: false,
      redeem: false,
      showAwardDetails: false,
      showConfirm: false,
    };
    this.validate = this.validate.bind(this);
    this.redeemAward = this.redeemAward.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    // const activeVouchers = [];
    // const completedVouchers = [];
    // this.props.vouchers.forEach(voucher => {
    //   if (voucher.voucherStatus === true) {
    //     activeVouchers.push({
    //       voucher
    //     });
    //   } else {
    //     completedVouchers.push({
    //       voucher
    //     });
    //   }
    // });

    let awardArray = [];
    let redeemedArray = [];
    let earnedArray = [];
    this.props.awards.forEach((award) => {
      if (award.active === true || award.active === false) {
        if (award.redemption.redeemed === true) {
          redeemedArray.push(award);
        } else if (award.earned === true) {
          earnedArray.push(award);
        } else {
          awardArray.push(award);
        }
      }
    });
    let awardCount = awardArray.length;
    let redeemedCount = redeemedArray.length;
    let earnedCount = earnedArray.length;
    this.setState({
      // activeVouchers,
      // completedVouchers,
      rewards: awardArray,
      awardCount,
      redeemedCount,
      earnedCount,
      redeemed: redeemedArray,
      loading: false,
      earned: earnedArray,
    });
  }

  getPosition() {
    let options = {
      timeout: 50000,
      maximumAge: 10000,
      enableHighAccuracy: true,
    };
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          options,
        ),
      );
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }

  getDistance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'M') {
      dist = dist * 0.8684;
    }
    return dist;
  }

  getGeoLocation = () => {
    let self = this;
    let currentLongitude = '';
    let currentLatitude = '';
    let distance = null;
    this.getPosition()
      .then((position) => {
        if (position.coords) {
          currentLatitude = position.coords.latitude;
          currentLongitude = position.coords.longitude;
          distance = this.getDistance(
            currentLongitude,
            currentLatitude,
            -90.3792863,
            38.4126687,
            'M',
          );
        } else {
          alert('Geolocation is not supported by this browser.');
        }
      })
      .catch((err) => {
        console.error('something wrongg', err.message);
        self.setState({
          locationServices: false,
        });
      });
  };

  componentDidMount() {
    document.title = 'Rewards';
    this.getGeoLocation();
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getTab(tabMode, tabLable, mode, array) {
    let count = array.length;
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
          <div className="counterGrey">
            <span>{count}</span>
          </div>
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tabLable}
            <div className="counterGrey">
              <span>{count}</span>
            </div>
          </div>
        </a>
      );
    }
  }
  validate = (value, activeCriteriaIndex) => {
    this.setState({
      validate: value,
      activeCriteriaIndex,
    });
  };

  closeValidate() {
    this.setState({
      codeOne: '',
      codeTwo: '',
      codeThree: '',
      codeFour: '',
      codeFive: '',
      codeSix: '',
      validate: false,
    });
  }

  showAward(award, locationTitle) {
    this.setState({
      activeAward: award,
      locationTitle: locationTitle,
      showAwardDetails: true,
    });
  }

  closeAward() {
    this.setState({
      redeem: false,
    });
  }

  hideAwardDetails() {
    this.setState({
      activeAward: null,
      showAwardDetails: false,
    });
  }

  listVoucher(award, locations) {
    let title = '';
    if (award.rewards !== undefined && award.rewards.length === 1) {
      title = award.rewards[0].title;
    } else if (
      award.rewards !== undefined &&
      award.rewards.length > 1
    ) {
      if (
        award.rewardSettings !== undefined &&
        award.rewardSettings.colabMode === 'choose'
      ) {
        award.rewards.forEach((rewardx, x) => {
          if (x === 0) {
            title = rewardx.title;
          } else {
            title = title + ' or ' + rewardx.title;
          }
        });
      } else if (award.rewardSettings.colabMode === 'all') {
        award.rewards.forEach((rewardx, x) => {
          if (x === 0) {
            title = rewardx.title;
          } else {
            title = title + ' & ' + rewardx.title;
          }
        });
      }
    }

    // const locationDetails = query('locationId')
    //   .is(award.promo.locationId)
    //   .on(this.props.locations);
    // if (
    //   locationDetails !== undefined &&
    //   locationDetails.length === 0
    // ) {
    //   let favArray = [];
    //   favArray.push(award.promo.locationId);
    //   let destination = 2;
    //   const bundle = { favArray, locations, destination };
    //   this.props.updateLocations(bundle);
    // } else {
    // let locationTitle = locationDetails[0].locationTitle;
    return (
      <div
        onClick={() => {
          // this.showAward(award, locationTitle);
          this.showAward(award);
        }}
        className="awardback"
      >
        {/* <Col
          xs={2}
          md={2}
          sm={2}
          lg={2}
          className="no-padding thumbnailCol"
        > */}
        {/* <Link
              to={{
                pathname: '/event/' + event.eventId,
              }}
            >
              <img
                src={event.thumbnail}
                alt={event.title}
                className="thumbnail"
              />
            </Link> */}
        {/* </Col> */}
        <Row>
          <Col
            xs={12}
            md={12}
            sm={12}
            lg={12}
            onClick={() => {
              this.showAward(award);
            }}
            className="no-padding"
          >
            <span className="award-title">{title}</span>
          </Col>
          {/* <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
         
            <span className="right">
              <MaterialIcon
                icon="keyboard_arrow_right"
                size={30}
                color="#f21d59"
                onClick={() => {
                  this.showAward(award);
                }}
              />
            </span>
          </Col> */}
        </Row>
        <Row>
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            <div id="locationList">
              <span style={{ float: 'left', fontWeight: '600' }}>
                @
              </span>
              <ul>
                {award.promo.locations.map((location, index) => (
                  <li>{location.locationTitle}</li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
    // }
  }

  listRewardList(award, locations) {
    // const locationDetails = query('locationId')
    //   .is(award.promo.locationId)
    //   .on(this.props.locations);
    // if (
    //   locationDetails !== undefined &&
    //   locationDetails.length === 0
    // ) {
    //   let favArray = [];
    //   favArray.push(award.promo.locationId);
    //   let destination = 2;
    //   const bundle = { favArray, locations, destination };
    //   this.props.updateLocations(bundle);
    // } else {
    // let locationTitle = locationDetails[0].locationTitle;
    return (
      <div
        style={{
          borderBottom: '1px solid #c5c5c5',
          padding: '10px 0px 20px 0px',
        }}
      >
        {/* <Col
          xs={2}
          md={2}
          sm={2}
          lg={2}
          className="no-padding thumbnailCol"
        > */}
        {/* <Link
              to={{
                pathname: '/event/' + event.eventId,
              }}
            >
              <img
                src={event.thumbnail}
                alt={event.title}
                className="thumbnail"
              />
            </Link> */}
        {/* </Col> */}
        <Row>
          <Col
            xs={11}
            md={11}
            sm={11}
            lg={11}
            onClick={() => {
              this.showAward(award);
            }}
            className="no-padding"
          >
            <span className="award-title">{award.reward.title}</span>
          </Col>
          <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
            {/* {this.getEditLink(event.eventId)} */}

            <span className="right">
              <MaterialIcon
                icon="keyboard_arrow_right"
                size={30}
                color="#f21d59"
                onClick={() => {
                  this.showAward(award);
                }}
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            <div id="locationList">
              <span style={{ float: 'left', fontWeight: '600' }}>
                @
              </span>
              <ul>
                {award.promo.locations.map((location, index) => (
                  <li>{location.locationTitle}</li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
    // }
  }

  getContent(mode, locations) {
    if (mode === 1) {
      return (
        <div>
          <div
            className="adminActionBar"
            style={{ top: '40px !important', width: '100%' }}
          >
            <Row>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(
                  1,
                  'In Progress',
                  this.state.mode,
                  this.state.rewards,
                )}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(
                  3,
                  'Earned',
                  this.state.mode,
                  this.state.earned,
                )}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(
                  2,
                  'Redeemed',
                  this.state.mode,
                  this.state.redeemed,
                )}
              </Col>
            </Row>
          </div>
          <div
            className="section actionBarPush"
            style={{ paddingTop: '40px' }}
          >
            {this.state.rewards.length < 1 ? (
              <div className="text-center">
                <span className="center-message">
                  You do not have any Rewards in progress.
                </span>
                <div
                  style={{
                    padding: '15px 10px 10px 10px',
                    border: '1px solid lightgrey',
                    margin: '0px auto',
                    width: '100%',
                    marginTop: '100px',
                    background: 'white',
                    display: 'flex',
                    textAlign: 'left',
                    maxWidth: '1000px',
                  }}
                >
                  <Col xs={2} md={1} sm={2} lg={1}>
                    <div style={{ float: 'left' }}>
                      <MaterialIcon
                        icon="help_outline"
                        size={35}
                        color="rgb(238 1 88)"
                      />
                    </div>
                  </Col>
                  <Col xs={10} md={11} sm={10} lg={11}>
                    <h4
                      style={{
                        fontSize: '18px',
                        marginBottom: '10px',
                      }}
                    >
                      Rewards - In Progress
                    </h4>
                    <p
                      style={{
                        margin: '0px',
                        padding: '0px 0px 10px 0px',
                      }}
                    >
                      Here you will find any Rewards that you are
                      working towards earning as part of a{' '}
                      <span style={{ fontWeight: '600' }}>
                        Rewarding Experience
                      </span>
                      .
                    </p>
                    <p>
                      Once you receive your first validation towards a
                      Reward you can visit this tab to quickly return
                      to that Experience.
                    </p>
                  </Col>
                </div>
              </div>
            ) : (
              <div>
                {this.state.rewards.map((reward, index) => (
                  <div>{this.listVoucher(reward, locations)}</div>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    } else if (mode === 2) {
      return (
        <div>
          <div
            className="adminActionBar"
            style={{ top: '40px !important', width: '100%' }}
          >
            <Row>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(
                  1,
                  'In Progress',
                  this.state.mode,
                  this.state.rewards,
                )}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(
                  3,
                  'Earned',
                  this.state.mode,
                  this.state.earned,
                )}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(
                  2,
                  'Redeemed',
                  this.state.mode,
                  this.state.redeemed,
                )}
              </Col>
            </Row>
          </div>
          <div
            className="section actionBarPush"
            style={{ paddingTop: '40px' }}
          >
            {this.state.redeemed.length < 1 ? (
              <div className="text-center">
                <span className="center-message">
                  You do not have any redeemed Rewards.
                </span>
                <div
                  style={{
                    padding: '15px 10px 10px 10px',
                    border: '1px solid lightgrey',
                    margin: '0px auto',
                    width: '100%',
                    marginTop: '100px',
                    background: 'white',
                    display: 'flex',
                    textAlign: 'left',
                    maxWidth: '1000px',
                  }}
                >
                  <Col xs={2} md={1} sm={2} lg={1}>
                    <div style={{ float: 'left' }}>
                      <MaterialIcon
                        icon="help_outline"
                        size={35}
                        color="rgb(238 1 88)"
                      />
                    </div>
                  </Col>
                  <Col xs={10} md={11} sm={10} lg={11}>
                    <h4
                      style={{
                        fontSize: '18px',
                        marginBottom: '10px',
                      }}
                    >
                      Rewards - Redeemed
                    </h4>
                    <p
                      style={{
                        margin: '0px',
                        padding: '0px 0px 10px 0px',
                      }}
                    >
                      Here you find all of your redeemed Rewards.
                      Ah..good times.
                    </p>
                  </Col>
                </div>
              </div>
            ) : (
              <div>
                {this.state.redeemed.map((reward, index) => (
                  <div>{this.listRewardList(reward, locations)}</div>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    } else if (mode === 3) {
      return (
        <div>
          <div
            className="adminActionBar"
            style={{ top: '40px !important', width: '100%' }}
          >
            <Row>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(
                  1,
                  'In Progress',
                  this.state.mode,
                  this.state.rewards,
                )}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(
                  3,
                  'Earned',
                  this.state.mode,
                  this.state.earned,
                )}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(
                  2,
                  'Redeemed',
                  this.state.mode,
                  this.state.redeemed,
                )}
              </Col>
            </Row>
          </div>
          <div
            className="section actionBarPush"
            style={{ paddingTop: '40px' }}
          >
            {this.state.earned.length < 1 ? (
              <div
                className="text-center"
                style={{ marginTop: '20px' }}
              >
                <span className="center-message">
                  You do not have any unused Rewards.
                </span>
                <div
                  style={{
                    padding: '15px 10px 10px 10px',
                    border: '1px solid lightgrey',
                    margin: '0px auto',
                    width: '100%',
                    marginTop: '100px',
                    background: 'white',
                    display: 'flex',
                    textAlign: 'left',
                    maxWidth: '1000px',
                  }}
                >
                  <Col xs={2} md={1} sm={2} lg={1}>
                    <div style={{ float: 'left' }}>
                      <MaterialIcon
                        icon="help_outline"
                        size={35}
                        color="rgb(238 1 88)"
                      />
                    </div>
                  </Col>
                  <Col xs={10} md={11} sm={10} lg={11}>
                    <h4
                      style={{
                        fontSize: '18px',
                        marginBottom: '10px',
                      }}
                    >
                      Rewards - Earned
                    </h4>
                    <p
                      style={{
                        margin: '0px',
                        padding: '0px 0px 10px 0px',
                      }}
                    >
                      Think of this tab as your Reward Wallet. Here
                      you fill find earned Rewards that you haven{"'"}
                      t redeemed yet.
                    </p>
                  </Col>
                </div>
              </div>
            ) : (
              <div>
                {this.state.earned.map((reward, index) => (
                  <div>{this.listVoucher(reward, locations)}</div>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  getDetailsTitleBar(a) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <a
                onClick={() => {
                  this.hideAwardDetails();
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </a>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Award Details</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <a
                onClick={value => {
                  this.saveTags(this.props.user.id);
                }}
                className="right"
              >
                <MaterialIcon icon="check" size={25} color="white" />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getTitleBar(a) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {this.state.showAwardDetails ? (
                <a
                  onClick={() => {
                    this.hideAwardDetails();
                  }}
                  className="left"
                >
                  <MaterialIcon
                    icon="keyboard_backspace"
                    size={25}
                    color="white"
                  />
                </a>
              ) : (
                <Link
                  to={{
                    pathname: '/home',
                  }}
                  className="left"
                >
                  <MaterialIcon
                    icon="close"
                    size={25}
                    color="white"
                  />
                </Link>
              )}
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  {this.state.showAwardDetails
                    ? 'Reward Details'
                    : 'Your Rewards'}
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <a
                onClick={value => {
                  this.saveTags(this.props.user.id);
                }}
                className="right"
              >
                <MaterialIcon icon="check" size={25} color="white" />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getValidateScreen(awards) {
    let validatorMode = false;
    let activeUserAward = {};
    awards.forEach((award) => {
      if (
        award.validatorMode !== undefined &&
        award.validatorMode === true
      ) {
        validatorMode = true;
        activeUserAward = award;
      }
    });

    // console.log('this.state.activeCriteriaIndex', activeUserAward);
    if (validatorMode === true) {
      if (activeUserAward.validationType === 1) {
        return (
          <div id="loadscreen">
            <div className="text-center push">
              <div className="middleMe">
                <img src={whiteLogo} />
                <span>Waiting on Validation!</span>
              </div>
              <br />
              <button
                onClick={() => {
                  this.cancelValidation(
                    this.state.activeCriteriaIndex,
                    activeUserAward,
                  );
                }}
              >
                <span>Cancel</span>
              </button>
            </div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#activeContent { display: none } .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 40px !important; background: #f21d59} #bottomNav{display:none} #loadscreen p{color:white}',
              }}
            />
          </div>
        );
      } else {
        return (
          <div id="loadscreen">
            <div className="text-center push">
              <div className="middleMe">
                <img src={whiteLogo} />
                <span>Redeeming...</span>
              </div>
              <br />
              <button
                onClick={() => {
                  this.cancelRedemption(activeUserAward);
                }}
              >
                <span>Cancel</span>
              </button>
            </div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#activeContent { display: none } .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 40px !important; background: #f21d59} #bottomNav{display:none} #loadscreen p{color:white}',
              }}
            />
          </div>
        );
      }
    }
  }

  cancelValidation(index, userReward) {
    let newAward = userReward;
    newAward.criteria[index].activeCriteria = false;
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(newAward.awardId);
    const setDoc = update
      .update({
        // criteria: newAward.criteria,
        validatorMode: false,
        validationType: 0,
      })
      .then((ref) => {
        // this.setState({
        //   showConfirm: false,
        //   validate: false,
        //   redeem: false,
        // });
      });
  }

  setRewards(events, userId) {
    const planned = [];
    const unplanned = [];
    let setEvents = {};

    if (events !== undefined) {
      events.forEach((event) => {
        if (
          event.users[userId].finished !== true &&
          event.status !== 9
        ) {
          if (event.status === 0) {
            planned.push(event);
          } else if (
            event.users[this.props.user.id].editor === true
          ) {
            unplanned.push(event);
          }
        }
      });
      let plannedCount = planned.length;
      let unplannedCount = unplanned.length;
      const activeCountArray = [];
      const activeInvitationsArray = [];
      events.forEach((event) => {
        if (event.users[this.props.user.id].inviteAction === false) {
          activeInvitationsArray.push(
            event.invitations[this.props.user.id],
          );
        }
      });
      activeInvitationsArray.forEach((event) => {
        if (event.status === 0 || event.status === 9) {
          activeCountArray.push(event);
        }
      });
      const activeCount = activeCountArray.length;

      setEvents = {
        planned: planned,
        unplanned: unplanned,
        plannedCount,
        unplannedCount,
        inviteCount: activeCount,
      };
      return setEvents;
    }
  }

  showConfirm(mode) {
    this.setState({
      showConfirm: mode,
    });
  }

  // Ask for Validation (Reward Started)
  requestRedemption(userReward) {
    let newAward = userReward;
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(newAward.awardId);
    const setDoc = update
      .update({
        validatorMode: true,
        validationType: 2, // 2 means redeem
      })
      .then((ref) => {});

    this.setState({
      redeem: false,
    });
  }

  getConfirmMessage(award) {
    if (this.state.showConfirm === true) {
      return (
        <div className="section pushfifty padding-forty">
          <span className="confirmMessage">
            Are you sure you want to redeem this Award?{' '}
          </span>
          {/* <a
            onClick={() => {
              this.validate(true);
            }}
          > */}
          <a
            onClick={() => {
              this.requestRedemption(award);
            }}
          >
            <span className="confirmBtn">Yes!</span>
          </a>
        </div>
      );
    }
  }

  redeemAward(award) {
    let promoId = award.promo.id;
    const promo = query('id').is(promoId).on(this.props.promos);
    this.setState({
      redeem: true,
      activeAward: award,
      activePromo: promo[0],
    });
  }

  showRedeemScreen(award) {
    return (
      <div id="loadscreen  pin-area">
        <div className="section text-center pin-area">
          <Container>
            <Row className="no-margin">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="no-padding"
              />
              <Col
                xs={10}
                md={10}
                sm={10}
                lg={10}
                className="no-padding"
              >
                <h2 className="white">Redeem Award</h2>
                <h3 className="white">{award.reward.text}</h3>
              </Col>
              <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
                <a
                  onClick={() => {
                    this.closeAward();
                  }}
                >
                  <MaterialIcon
                    icon="close"
                    size={25}
                    color="white"
                  />
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="text-center pushfifty">
          <a
            onClick={() => {
              this.showConfirm(true);
            }}
            className="pin-code-btn"
          >
            <MaterialIcon
              icon="check_circle"
              size={100}
              color="white"
            />
          </a>
          <div>{this.getConfirmMessage(award)}</div>
        </div>

        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important; background: #f21d59 !important} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    if (this.state.redeem === true) {
      return (
        <div>{this.showRedeemScreen(this.state.activeAward)}</div>
      );
    }
    if (this.state.showAwardDetails === true) {
      return (
        <div id="vouchers" style={{ height: '100%' }}>
          <div id="validator">
            {this.getValidateScreen(this.props.awards)}
          </div>
          {this.getTitleBar()}
          <AwardDetails
            activeReward={this.state.activeAward}
            awardId={this.state.activeAward.awardId}
            locationTitle={this.state.locationTitle}
            // validate={this.validate()}
            redeemAward={this.redeemAward}
            promos={this.props.promos}
          />
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} @media (min-width: 801px) {.maincontent-push { top: 25px; position: relative; height: 100%;} .maincontent-push { top:20; relative; height: 100%;}}',
            }}
          />
        </div>
      );
    }
    return (
      <div id="events">
        {/* <TitleBar title="Your Rewards" backlink="" /> */}
        {this.getTitleBar()}
        {this.getContent(this.state.mode, this.props.locations)}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#startBar{background-color: #ef0059;} .adminActionBar {top: 40px !important;} #events{position: relative;}  #headersTop {width: 100% !important;} #headersTopSecondary{width: 100%} #authright {padding-left: 0px !important;}  #mobile-nav { display: none } #authleft {display:none;} #events{position: relative; top: 40px}  #bottomNav{left:0px; width:100%} .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vouchers: state.vouchers.vouchers,
    awards: state.userAwards.awards,
    locations: state.locations.locations,
    promos: state.promos.promos,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateLocations: (bundle) => {
      dispatch(updateLocations(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserVouchers);
