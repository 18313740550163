import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Footer.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// Images
import whitelogo from '../../images/TrevelUnderlinedWhiteSmall.svg';

class Footer extends Component {
  render() {
    return (
      <div className="footer-container">
        <div className="footer-content ">
          <div
            style={{
              display: 'block',
              clear: 'both',
              marginBottom: '10px',
            }}
          >
            <span style={{ fontWeight: '600', fontSize: '18px' }}>
              Here are some helpful links.
            </span>
          </div>
          <Col lg={3} md={3} sm={4} xs={4} className="no-padding">
            <ul className="footer-nav-left">
              <li>
                <a href="/contactus">Contact Us</a>
              </li>
              <li>
                <a href="/about">What is Trevel?</a>
              </li>
              {/* <li>
                <a href="/workwithus">Work With Us</a>
              </li> */}
              {/* <li>
                <a href="/affiliates">Affiliate Program</a>
              </li> */}
              <li>
                <a href="/privacy">Privacy</a>
              </li>
              {/* <li>
              <a href="/ourservices">Our Services</a>
            </li> */}
            </ul>
          </Col>
          <Col lg={3} md={3} sm={4} xs={4} className="no-padding">
            <ul className="footer-nav-left">
              <li>
                <a href="/experiences">Trevel Experiences</a>
              </li>
              <li>
                <a href="/articles">Articles</a>
              </li>
              {/* <li>
                <a href="/workwithus">Work With Us</a>
              </li> */}
              {/* <li>
                <a href="/affiliates">Affiliate Program</a>
              </li> */}
              {/* <li>
                <a href="/privacy">Privacy</a>
              </li> */}
              {/* <li>
              <a href="/ourservices">Our Services</a>
            </li> */}
            </ul>
          </Col>
          <Col lg={6} md={6} sm={4} xs={4} className="no-padding">
            <div className="footer-right-column">
              <img src={whitelogo} height="30px" />
              {/* <p className="footer-text">Get to Know Us</p>
              <Row>
                <a className="social-button" href=" ">
                  <span className="social-button">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FInstagram.png?alt=media&token=a88b25b9-b77f-40f9-9854-66653bbf7a21"
                      height="20px"
                    />
                  </span>
                </a>
                <a className="social-button" href=" ">
                  <span className="social-button">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FFacebook.png?alt=media&token=466bf06a-40aa-4ab6-aaa9-817ef4e8befd"
                      height="20px"
                    />{" "}
                  </span>
                </a>
                <a className="social-button" href=" ">
                  <span className="social-button">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTwitter.png?alt=media&token=1373db72-b3de-4d77-8640-b738fab55553"
                      height="20px"
                    />
                  </span>
                </a>
                <a className="social-button" href=" ">
                  <span className="social-button">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FLinkedIn.png?alt=media&token=c0825971-27b3-4f4f-a1b4-b28bf8f7ed33"
                      height="20px"
                    />
                  </span>
                </a>
              </Row> */}
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

export default Footer;
