import firebase from '../config/firebase';

export function getUsers() {
  const usersArray = [];
  const db = firebase.firestore();

  const getV = function () {
    return new Promise((resolve, reject) => {
      db.collection('users')
        .where('verified', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                firstName,
                lastName,
                profilePhotoPath,
                userName,
                // userType,
                // created,
                // email,
                // tags,
                zoneId,
                state,
              } = doc.data();
              // const expireDateString = expireDate.toDateString();
              // const purchaseDateString = purchaseDate.toDateString();
              usersArray.push({
                userId: doc.id,
                firstName,
                lastName,
                profilePhotoPath,
                userName,
                // userType,
                // created,
                // email,
                // tags,
                zoneId,
                state,
              });
            }
          });
          return usersArray;
        })
        .then((result) => {
          // const final_vouchers = result[0]
          resolve(usersArray);
        });
    });
  };

  return getV();
}
