import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { push } from 'connected-react-router';

let defaultState = {
  claimLink: false,
  claimId: null,
  vendorId: null,
  vendorType: null,
  vendorSignup: false,
  referalId: null,
  guide: {
    landing: true,
    loading: true,
    tagFilters: [],
    locationsWithDistance: [],
    filteredArray: [],
    promos: [],
  },
  modalShown: 1,
};

function* getUauth(action) {
  yield put({
    type: actions.SET_UAUTH,
    payload: {
      claimLink: action.payload.claimLink,
      claimId: action.payload.claimId,
      vendorId: action.payload.vendorId,
      vendorType: action.payload.vendorType,
      guide: action.payload.guide,
      vendorSignup: action.payload.vendorSignup,
      referalId: action.payload.referalId,
      modalShown: action.payload.modalShown,
    },
  });
  if (
    action.payload.claimLink === true &&
    action.payload.vendorId !== null
  ) {
    yield put(
      push('/vendor/signupprofile/' + action.payload.claimId),
    );
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_UAUTH, getUauth);
}
