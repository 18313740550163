import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createFilter } from 'react-search-input';
import { Col, Row } from 'reactstrap';

const KEYS_TO_FILTERS = ['plannerUserName'];

const query = require('array-query');

export class VendorEventDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      events: [],
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  componentDidMount() {
    this.getEventDetails();
  }

  getEventDetails() {
    const eventDetails = query('id')
      .is(this.props.match.params.id)
      .on(this.props.vendorEvents);
    let event = eventDetails[0];
    this.setState({
      event,
      loading: false,
    });
  }

  getDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getCount(events) {
    let count = events.length;
    return count;
  }

  getActiveStatus(status) {
    if (status === true) {
      return <span className="right loudStatus">ACTIVE</span>;
    } else {
      return <span className="right loudStatus">INACTIVE</span>;
    }
  }

  getEvents(events) {
    return (
      <Row className="no-margin">
        <div className="fullwidth">
          <div className="reportHeader">
            <span className="reportHeaderText">User Events</span>
            <span className="right white reportHeaderText">
              Total Created:{' '}
              <b>{this.getCount(this.props.vendorUserEvents)}</b>
            </span>
          </div>
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={4} md={4} sm={4} lg={4} className="left">
                  <span className="label">Creator</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Status</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left ">
                  <span className="label">Created</span>
                </Col>
                <Col xs={4} md={4} sm={4} lg={4} className="left">
                  <span className="label">Guest Count</span>
                </Col>
              </div>
            </li>
            {this.props.vendorUserEvents.map((event, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    key={'key' + index}
                  >
                    {event.creationDetails.plannerUserName}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    -
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {this.getDate(event.created)}
                  </Col>
                  <Col xs={4} md={4} sm={4} lg={4} className="left">
                    {event.attendingCount}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/events/',
                }}
              >
                <span className="left">
                  <MaterialIcon
                    icon="keyboard_backspace"
                    size={25}
                    color="white"
                  />
                </span>
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">{title}</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/event/edit/' + id,
                }}
              >
                <span className="right">
                  <MaterialIcon icon="edit" size={25} color="white" />
                </span>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    const filteredEvents = this.props.vendorUserEvents.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    let event = this.state.event;
    return (
      <div id="admin">
        {/* <TitleBar title={event.title} backlink="/vendor/events" /> */}
        {this.getTitleBar(event.title, event.id)}
        <div className="section">
          <div className="wizard-section-border-ten">
            <Row className="no-padding">
              <Col
                xs={6}
                md={6}
                sm={6}
                lg={6}
                className="left no-padding"
              >
                <p>
                  Created By: <b>{event.creatorInfo.creatorName}</b>
                </p>
                <p>
                  Created: <b>{this.getDate(event.created)}</b>
                </p>
              </Col>
              <Col
                xs={6}
                md={6}
                sm={6}
                lg={6}
                className="right no-padding"
              >
                {this.getActiveStatus(event.active)}
              </Col>
            </Row>
          </div>
          <div className="wizard-section-ten">
            <b>Description</b>
            <p>{event.description}</p>
          </div>

          {/* <div className="wizard-section-ten">
            <b>Guest Events</b>
            <p>Total Created: {this.getCount(this.props.vendorUserEvents)}</p>
          </div> */}

          {/* <div className="header-container">
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
          </div> */}
        </div>
        <div>{this.getEvents(filteredEvents)}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} #bottomNav { display: none } ',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vendorEvents: state.vendorEvents.events,
    vendorUserEvents: state.vendorUserEvents.userEvents,
  };
}

export default connect(mapStateToProps)(VendorEventDetails);
