import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getConnections,
  removeConnectionTwo,
  removeConnectionOne,
  favConnection,
} from '../../services/ConnectionsServices';
import * as actions from './actions';
import { createConnectionRecord } from '../../services/InvitationServices';

let defaultState = {
  connections: {
    connections: {
      connections: {},
    },
  },
};

function* getConnectionsAction(action) {
  let connections = yield call(getConnections, action.payload);
  if (connections === undefined) {
    yield call(createConnectionRecord, action.payload);
  }
  yield put({
    type: actions.SET_CONNECTIONS,
    payload: {
      ...defaultState,
      connections,
    },
  });
}

function* removeConnectionsAction(action) {
  yield call(
    removeConnectionOne,
    action.payload.userId,
    action.payload.newUserId,
  );
  yield call(
    removeConnectionTwo,
    action.payload.userId,
    action.payload.newUserId,
  );
  let connections = yield call(getConnections, action.payload.userId);
  yield put({
    type: actions.SET_CONNECTIONS,
    payload: {
      ...defaultState,
      connections,
    },
  });
}

function* favConnectionsAction(action) {
  yield call(favConnection, action.payload);
  let connections = yield call(getConnections, action.payload.userId);
  yield put({
    type: actions.SET_CONNECTIONS,
    payload: {
      ...defaultState,
      connections,
    },
  });
}

// function* clearProfileAction(action) {
//   console.log("clearing profile saga", action);
//   yield put(clearUser());
// }

export default function* sagas() {
  yield takeLatest(actions.GET_CONNECTIONS, getConnectionsAction);
  yield takeLatest(
    actions.REMOVE_CONNECTIONS,
    removeConnectionsAction,
  );
  yield takeLatest(actions.FAV_CONNECTIONS, favConnectionsAction);
}
