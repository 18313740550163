import firebase from '../config/firebase';

export function getVendorPromos(vendorId) {
  const promoArray = [];
  const db = firebase.firestore();

  const getP = function () {
    return new Promise((resolve, reject) => {
      const table = `participants.${vendorId}.access`;
      db.collection('promos')
        .where(table, '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                title,
                account,
                active,
                creationDetails,
                locationId,
                locationTitle,
                rewards,
                events,
                description,
                images,
                locations,
                scheduledTime,
                tagsExperienceTypes,
                goldenTicket,
                settings,
                participants,
                status,
                editing,
              } = doc.data();
              promoArray.push({
                id: doc.id,
                title,
                active,
                account,
                creationDetails,
                locationId,
                locationTitle,
                rewards,
                events,
                description,
                images,
                locations,
                scheduledTime,
                tagsExperienceTypes,
                goldenTicket,
                settings,
                participants,
                status,
                editing,
              });
            }
          });
          return promoArray;
        })
        .then((result) => {
          resolve(promoArray);
        });
    });
  };
  return getP();
}

// Approve Event
export function approveVendorPromo(bundle) {
  const db = firebase.firestore();
  const promo = db.collection('promos').doc(bundle.promoId);

  let participantsObject = bundle.participants;

  let status = 2;
  let maybes = 0;
  let approved = 0;
  let rejected = 0;

  let participantCount = Object.keys(participantsObject).length;

  Object.keys(participantsObject).forEach((key) => {
    if (participantsObject[key].approved === true) {
      approved = approved + 1;
    } else if (
      participantsObject[key].rejected !== undefined &&
      participantsObject[key].rejected === true
    ) {
      approved = rejected + 1;
    } else {
      maybes = maybes + 1;
    }
  });

  if (approved + rejected === participantCount && approved > 1) {
    status = 0;
  }
  // console.log('status - plannerCount', plannerCount);
  // console.log('status - approved', approved);
  // console.log('status - rejected', rejected);
  // console.log('status - maybes', maybes);
  // console.log('status', status);

  const setDoc = promo.update({
    status: status,
    participants: bundle.participants,
    // editHistory: bundle.event.editHistory,
  });
}

// Enter Edit Mode
export function editMode(bundle) {
  const db = firebase.firestore();
  const userEvent = db.collection('promos').doc(bundle.promo.id);
  const setDoc = userEvent.update({
    editing: bundle.editing,
  });
}
