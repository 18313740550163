import MaterialIcon from 'material-icons-react';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
// Reducers
import { getCheckout } from '../../sagas/checkOutSaga/reducer';

const query = require('array-query');

export class PackageSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedVouchers: [],
      currentCost: 0,
    };
  }

  UNSAFE_componentWillMount() {
    const locations = this.props.activePackageLocations;
    const voucherArray = [];
    locations.forEach(location => {
      // if (location.type === 0) {
      const locationDetails = query('locationId')
        .is(location.locationId)
        .on(this.props.alllocations);
      const vouchers = locationDetails[0].vouchers;
      // vouchers.forEach((voucher) => {
      //   if (voucher.voucherType !== 2) {
      //     voucherArray.push(voucher);
      //   }
      // });
      // }
      if (voucherArray.length === 0) {
        this.props.history.push('/checkout/review');
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  checkChange(
    e,
    cost,
    voucherType,
    voucherPromo,
    voucherInfo,
    locationId,
    userId,
  ) {
    const voucherCost = parseInt(cost, 10);
    const timestamp = new Date();
    let currentVouchers = this.state.selectedVouchers;
    let currentCost = this.state.currentCost;
    if (e.target.checked === true) {
      const updatedCost = currentCost + voucherCost;
      const selectedVoucher = {
        voucherMasterId: e.target.name,
        voucherType,
        voucherPromo,
        voucherCost,
        voucherInfo,
        locationId,
        userId,
        voucherCreationDate: timestamp,
        voucherStatus: true,
        voucherExpirationDate: 'January 1, 2020',
      };
      currentVouchers.push(selectedVoucher);
      this.setState({
        currentCost: updatedCost,
        selectedVouchers: currentVouchers,
      });
    } else {
      const updatedCost = currentCost - voucherCost;
      const updatedVouchers = [];
      currentVouchers.forEach((voucher, i) => {
        if ((voucher.voucherMasterId = e.target.name)) {
          updatedVouchers.splice(i, 1);
        }
      });
      this.setState({
        currentCost: updatedCost,
        selectedVouchers: updatedVouchers,
      });
    }
  }

  // listVouchers(vouchers, locationId) {
  //   return (
  //     <div>
  //       {vouchers.map((voucher, index) => (
  //         <div>
  //           {voucher.voucherPromo === "" ? (
  //             <Row>
  //               <Col xs={10} md={10} sm={10} lg={10} className="no-padding">
  //                 <span>No Discounts Available</span>
  //               </Col>
  //               <Col xs={2} md={2} sm={2} lg={2} className="no-padding" />
  //             </Row>
  //           ) : (
  //             <Row>
  //               <Col xs={10} md={10} sm={10} lg={10} className="no-padding">
  //                 <span>{voucher.voucherPromo}</span>
  //               </Col>
  //               <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
  //                 <Checkbox
  //                   name={voucher.voucherId}
  //                   onChange={(e) =>
  //                     this.checkChange(
  //                       e,
  //                       voucher.voucherCost,
  //                       voucher.voucherType,
  //                       voucher.voucherPromo,
  //                       voucher.voucherInfo,
  //                       locationId,
  //                       // this.props.customEvent.event.packageDetails.packageId,
  //                       this.props.profile.userId
  //                     )
  //                   }
  //                   disabled={this.state.disabled}
  //                   className="right"
  //                 />
  //               </Col>
  //             </Row>
  //           )}
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }

  listLocation(location) {
    const locationDetails = query('locationId')
      .is(location.locationId)
      .on(this.props.alllocations);
    return (
      <div className="oddeven">
        <span className="bold-title">
          {locationDetails[0].locationTitle}
        </span>
        {/* {this.listVouchers(
          locationDetails[0].vouchers,
          locationDetails[0].locationId
        )} */}
      </div>
    );
  }

  checkOut(currentCost, selectedVouchers) {
    const checkoutBundle = {
      cost: currentCost,
      selectedVouchers: selectedVouchers,
    };
    this.props.getCheckout(checkoutBundle);
  }

  getFooter(cost) {
    return (
      <Container>
        <Row className="no-margin">
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <span className="footer-link left">
              Total Price: ${cost}
            </span>
          </Col>
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <span
              onClick={() => {
                this.checkOut(
                  this.state.currentCost,
                  this.state.selectedVouchers,
                );
              }}
              className="footer-link right"
            >
              Checkout
            </span>
          </Col>
        </Row>
      </Container>
    );
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/package/' + this.props.activePackageId,
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Promotions</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div id="checkout">
        {this.getTitleBar()}
        <div className="text-center section">
          <span>Choose Your Discounts</span>
        </div>
        <div className="section">
          {this.props.activePackageLocations.map(
            (location, index) => (
              <div>{this.listLocation(location)}</div>
            ),
          )}
        </div>
        <div className="footer">
          {this.getFooter(this.state.currentCost)}
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%}  .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activePackageLocations: state.activePackage.ap.locations,
    alllocations: state.locations.locations,
    activePackageId: state.activePackage.ap.packageId,
    profile: state.profile.profile,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getCheckout: checkoutBundle => {
      dispatch(getCheckout(checkoutBundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PackageSetup);
