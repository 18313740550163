import firebase from '../config/firebase';

export function getPromos(zoneId) {
  const promoArray = [];
  const db = firebase.firestore();
  const promos = db.collection('promos');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = promos
        .where('zoneId', '==', zoneId)
        .where('active', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                title,
                active,
                criteria,
                locationId,
                locationTitle,
                rewards,
                account,
                events,
                description,
                images,
                locations,
                scheduledTime,
                tagsExperienceTypes,
                goldenTicket,
                demo,
              } = doc.data();
              promoArray.push({
                id: doc.id,
                title,
                active,
                criteria,
                locationId,
                locationTitle,
                rewards,
                account,
                events,
                description,
                images,
                locations,
                scheduledTime,
                tagsExperienceTypes,
                goldenTicket,
                demo,
              });
            }
          });
          return promoArray;
        })
        .then((result) => {
          resolve(promoArray);
        });
    });
  };
  return getP();
}

export function getDemoPromos(zoneId) {
  const promoArray = [];
  const db = firebase.firestore();
  const promos = db.collection('promos');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = promos
        .where('demo', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                title,
                active,
                criteria,
                locationId,
                locationTitle,
                rewards,
                account,
                events,
                description,
                images,
                locations,
                scheduledTime,
                tagsExperienceTypes,
                goldenTicket,
                demo,
                strategy,
              } = doc.data();
              promoArray.push({
                id: doc.id,
                title,
                active,
                criteria,
                locationId,
                locationTitle,
                rewards,
                account,
                events,
                description,
                images,
                locations,
                scheduledTime,
                tagsExperienceTypes,
                goldenTicket,
                demo,
                strategy,
              });
            }
          });
          return promoArray;
        })
        .then((result) => {
          resolve(promoArray);
        });
    });
  };
  return getP();
}
