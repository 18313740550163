import * as action from './actions';

export const getConnections = (id) => {
  return {
    type: action.GET_CONNECTIONS,
    payload: id,
  };
};

export const removeConnections = (id) => {
  return {
    type: action.REMOVE_CONNECTIONS,
    payload: id,
  };
};

export const favConnections = (bundle) => {
  return {
    type: action.FAV_CONNECTIONS,
    payload: bundle,
  };
};

export const setConnections = (connections) => {
  return {
    type: action.SET_CONNECTIONS,
    payload: connections,
  };
};

export const clearConnections = (connections) => {
  const clearedConnections = {
    connections: {
      connections: {
        connections: {},
      },
    },
  };
  return {
    type: action.CLEAR_CONNECTIONS,
    payload: clearedConnections,
  };
};

const ACTION_HANDLERS = {
  [action.GET_CONNECTIONS]: (state, action) => {
    return { ...state };
  },
  [action.SET_CONNECTIONS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.CLEAR_CONNECTIONS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.REMOVE_CONNECTIONS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.FAV_CONNECTIONS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  connections: {
    connections: {
      connections: {},
    },
  },
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
