import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../../config/firebase';
// Reducers
import { setVendorLogin } from '../../sagas/loginSaga/reducer';
import { getVendor } from '../../sagas/vendorSaga/reducer';
import { getVendorPromos } from '../../sagas/vendorPromoSaga/reducer';
import { getVendorAwarded } from '../../sagas/vendorAwardedSaga/reducer';
import { getVendorRewards } from '../../sagas/vendorRewardsSaga/reducer';
import { getVendorUserEvents } from '../../sagas/vendorUserEventsSaga/reducer';
// import { getVendorEvents } from '../../sagas/vendorEventsSaga/reducer';
import { getVendorTimedEvents } from '../../sagas/vendorTimedEventsSaga/reducer';
import { getVendorEntertainment } from '../../sagas/vendorEntertainmentSaga/reducer';

const query = require('array-query');

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  step: 1,
  loading: true,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class VendorProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    // const locationId = this.props.locationId;
    // const locationDetails = query("locationId")
    //   .is(locationId)
    //   .on(this.props.locations);
    // console.log("locationDetails[0]", this.props.locationId);
    // console.log("locationDetails[0]", locationDetails[0]);
    // this.setState({
    // locationId,
    // locationDetails: this.props.locations,
    // });
    if (this.props.user.authenticated === true) {
      if (this.props.unauth.claimLink === true) {
        if (this.props.profile.userId !== undefined) {
          if (this.props.unauth.vendorId !== null) {
            this.updateVendorAccount(this.props.unauth.vendorId);
          } else if (this.props.profile.vendorAccount !== null) {
            this.updateVendorAccount(
              this.props.profile.vendorAccount.id,
            );
          } else if (this.props.unauth.vendorId === null) {
            this.setState({
              step: 2,
              loading: false,
              claim: true,
            });
          } else {
            this.setState({
              loading: false,
              claim: true,
            });
          }
        } else {
          this.setState({
            claim: true,
            loading: false,
          });
        }
      } else {
        this.setState({
          claim: false,
          loading: false,
        });
      }
    }
  }

  saveProfile(
    firstName,
    lastName,
    userName,
    state,
    businessName,
    businessCity,
    businessAddress,
    businessZip,
    phone,
  ) {
    // this.props.setSignupStatus();
    this.setState({
      loaded: false,
    });
    let zone = 11;
    if (state === 'MO') {
      zone = 11;
    } else if (state === 'KS') {
      zone = 10;
    }

    const db = firebase.firestore();
    const newEventRef = db.collection('package').doc();
    const dbref = newEventRef.id;

    const timestamp = new Date();

    let vendorAccount = null;
    if (this.state.claim === true) {
      vendorAccount = {
        id: dbref,
        joinDate: timestamp,
      };
    }

    let favoriteEntertainment = [];
    if (
      this.props.unauth.referalId !== undefined &&
      this.props.unauth.referalId !== null
    ) {
      favoriteEntertainment.push(this.props.unauth.referalId);
    }

    const userRef = firebase
      .firestore()
      .collection('users')
      .doc(this.props.user.id);
    userRef
      .set({
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        email: this.props.user.email,
        sex: 0,
        profilePhotoPath: '',
        created: timestamp,
        state: state,
        zoneId: zone,
        userType: 3,
        favorites: [],
        favoriteEntertainment,
        tags: {
          love: {
            tagsBeverages: [],
            tagsCuisine: [],
            tagsSetting: [],
            tagsDateType: [],
            tagsExperienceType: [],
            tagsGenre: [],
          },
          hate: {
            tagsBeverages: [],
            tagsCuisine: [],
            tagsSetting: [],
            tagsDateType: [],
            tagsExperienceType: [],
            tagsGenre: [],
          },
        },
        vendorAccount,
      })
      .then(() => {
        const user = firebase.auth().currentUser;
        user
          .updateProfile({
            displayName: userName,
          })
          .then(() => {
            console.log('UPDATED');
          })
          .catch((error) => {
            // An error happened.
          });
      })
      .then(() => {
        let user = {};
        if (this.state.claim === true) {
          user = {
            id: this.props.user.id,
            userName: userName,
            email: this.props.user.email,
            authenticated: true,
            newuser: true,
            fromClaim: true,
          };
        } else {
          user = {
            id: this.props.user.id,
            userName: userName,
            email: this.props.user.email,
            authenticated: true,
            newuser: true,
            fromClaim: false,
          };
        }

        if (this.state.claim === true) {
          this.createVendorAccount(
            this.props.user,
            this.state.locationDetails,
            dbref,
            businessName,
            businessCity,
            businessAddress,
            businessZip,
            state,
            phone,
          );
        }

        this.props.setVendorLogin(user);
      });
  }

  createVendorAccount(
    user,
    location,
    dbref,
    businessName,
    businessCity,
    businessAddress,
    businessZip,
    businessState,
    phone,
  ) {
    const db = firebase.firestore();
    const timestamp = new Date();

    let locations = [];
    let entertainment = [];

    let vendorTypes = [];
    if (this.props.unauth.vendorType === 1) {
      vendorTypes.push(1);
      if (this.props.locationId !== null) {
        locations.push(this.props.locationId);
      }
    } else if (this.props.unauth.vendorType === 2) {
      vendorTypes.push(2);
      if (this.props.locationId !== null) {
        entertainment.push(this.props.locationId);
      }
    }

    db.collection('vendors')
      .doc(dbref)
      .set({
        businessName,
        primaryAddress: {
          businessCity,
          businessAddress,
          businessZip,
          businessState,
        },
        primaryContact: {
          phone: phone,
        },
        created: timestamp,
        locations: locations,
        entertainment: entertainment,
        users: {
          [user.id]: {
            active: true,
            joinDate: timestamp,
            role: 0,
          },
        },
        subscription: {
          active: false,
        },
        vendorId: dbref,
        vendorTypes,
      })
      .then(() => {
        if (this.props.unauth.vendorType === 2) {
          const update = db
            .collection('entertainment')
            .doc(this.props.unauth.claimId);
          const setDoc = update
            .update({
              claimed: true,
            })
            .then(() => {
              const update = db
                .collection('entertainment')
                .doc(this.props.unauth.claimId);
              const setDoc = update
                .update({
                  account: { vendorId: dbref },
                })
                .then(() => {
                  this.props.getVendorEntertainment(dbref);
                  this.props.timedEvents.forEach((event) => {
                    if (
                      event.entertainmentId ===
                      this.props.unauth.claimId
                    ) {
                      const update = db
                        .collection('vendor_events')
                        .doc(event.eventId);
                      const setDoc = update
                        .update({
                          account: { vendorId: dbref },
                        })
                        .then(() => {
                          this.props.getVendorTimedEvents(dbref);
                        });
                    }
                  });
                });

              this.getVendor(dbref, true);

              this.props.getVendorUserEvents(dbref);
              this.props.getVendorRewards(dbref);
              this.props.getVendorPromos(dbref);
            });
        } else if (this.props.unauth.claimId !== null) {
          const update = db
            .collection('locations')
            .doc(this.props.unauth.claimId);
          const setDoc = update.update({
            claimed: true,
          });
          this.getVendor(dbref, true);
        } else {
          this.getVendor(dbref, false);
        }
      });
  }

  updateVendorAccount(id) {
    let locations = [];
    const db = firebase.firestore();
    var docRef = db.collection('vendors').doc(id);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { locations } = doc.data();
          let newLocations = locations;
          let status = newLocations.includes(
            this.props.unauth.claimId,
          );
          if (status === false) {
            newLocations.push(this.props.unauth.claimId);
            const db = firebase.firestore();
            const update = db.collection('vendors').doc(id);
            const setDoc = update
              .update({
                locations: newLocations,
              })
              .then((ref) => {
                const update = db
                  .collection('locations')
                  .doc(this.props.unauth.claimId);
                const setDoc = update.update({
                  claimed: true,
                });
                this.getVendor(id);
              });
          } else {
          }
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }

  getVendor(id, claim) {
    let path = undefined;
    let locationId = null;
    if (this.props.locationId !== undefined) {
      locationId = this.props.locationId;
    }

    if (claim === false) {
      path = '/trevel-experiences/signup';
    }
    let bundle = {
      userId: id,
      claim,
      path,
      locationId,
    };
    this.props.getVendor(bundle);
  }

  checkUserName(userName) {
    if (this.state.usersArray.indexOf(userName) + 1) {
      return true;
    }
  }

  setStep(firstName, lastName, userName, state) {
    if (this.state.claim === false) {
      this.saveProfile(firstName, lastName, userName, state);
    } else {
      this.setState({
        firstName,
        lastName,
        userName,
        state,
        step: 2,
      });
    }
  }

  getForm(step) {
    if (step === 1) {
      return (
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            username: '',
            state: '',
          }}
          validate={(values) => {
            let errors = {};
            if (!values.firstname) {
              errors.firstname = 'Required';
            }
            if (!values.lastname) {
              errors.lastname = 'Required';
            }
            if (!values.username) {
              errors.username = 'Required';
            }
            // if (values.username) {
            //   let check = this.checkUserName(values.username);
            //   if (check === true) {
            //     errors.username = 'This User Name is Taken. Sorry!';
            //   }
            // }
            if (values.state === '') {
              errors.state = 'Required';
            }

            return errors;
          }}
          onSubmit={(values) => {
            this.setStep(
              values.firstname,
              values.lastname,
              values.username,
              values.state,
            );
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            } = props;
            return (
              <Form>
                <Container>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="left"
                    >
                      {/* <h4 className="hfour">Required</h4> */}
                      <Field
                        type="text"
                        name="firstname"
                        placeholder="First Name"
                      />
                      <ErrorMessage
                        name="firstname"
                        component="div"
                        className="error-message"
                      />
                      <Field
                        type="text"
                        name="lastname"
                        placeholder="Last Name"
                      />
                      <ErrorMessage
                        name="lastname"
                        component="div"
                        className="error-message"
                      />
                      <Field
                        type="text"
                        name="username"
                        placeholder="User Name"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="error-message"
                      />

                      <fieldset>
                        <select
                          id="state"
                          type="text"
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? 'text-input error'
                              : 'text-input'
                          }
                        >
                          <option value="none">
                            Choose Your City
                          </option>
                          <option value="MO">St. Louis</option>
                          <option value="KS">Kansas City</option>]
                        </select>
                      </fieldset>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="error-message"
                      />
                    </Col>
                    {/* <Col xs={12} md={6} sm={12} lg={6}>
                      <h4 className="hfour">Optional</h4>
                      <Field component="select" name="gender">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other?</option>
                      </Field>
                      <ErrorMessage name="firstname" component="div" />
                    </Col> */}
                  </Row>
                  <Row>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <div className="submitarea">
                        <center>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="center"
                          >
                            Next
                          </button>
                        </center>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Form>
            );
          }}
        </Formik>
      );
    } else {
      return (
        <Formik
          initialValues={{
            businessName: '',
            phones: '',
          }}
          validate={(values) => {
            let errors = {};
            if (!values.businessName) {
              errors.businessName = 'Required';
            }
            if (values.phones === '') {
              errors.phones = 'Required';
            }

            return errors;
          }}
          onSubmit={(values) => {
            this.saveProfile(
              this.state.firstName,
              this.state.lastName,
              this.state.userName,
              this.state.state,
              values.businessName,
              values.businessCity,
              values.businessAddress,
              values.businessZip,
              values.phones,
            );
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmittingg,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            } = props;
            return (
              <Form>
                <Container>
                  <Row>
                    <Col
                      xs={12}
                      md={6}
                      sm={12}
                      lg={6}
                      className="left"
                    >
                      <span className="inputLabel">
                        Business Name
                      </span>
                      <Field
                        type="text"
                        name="businessName"
                        placeholder="Business Name"
                      />
                      <ErrorMessage
                        name="businessName"
                        component="div"
                        className="error-message"
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      sm={12}
                      lg={6}
                      className="left"
                    >
                      <span className="inputLabel">
                        Primary Contact Information
                      </span>
                      <Field
                        type="phones"
                        name="phones"
                        placeholder="Phone Number"
                      />
                      <ErrorMessage name="phones" component="div" />
                      <span className="inputLabel">
                        Primary Address
                      </span>
                      <Field
                        type="text"
                        name="businessAddress"
                        placeholder="Street"
                      />
                      <ErrorMessage
                        name="businessAddress"
                        component="div"
                        className="error-message"
                      />
                      <Field
                        type="text"
                        name="businessCity"
                        placeholder="City"
                      />
                      <ErrorMessage
                        name="businessCity"
                        component="div"
                        className="error-message"
                      />
                      <Field
                        type="text"
                        name="businessState"
                        placeholder="State"
                      />
                      <ErrorMessage
                        name="businessState"
                        component="div"
                        className="error-message"
                      />
                      <Field
                        type="text"
                        name="businessZip"
                        placeholder="Zip"
                      />
                      <ErrorMessage
                        name="businessZip"
                        component="div"
                        className="error-message"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <div className="submitarea">
                        <center>
                          <button
                            type="submit"
                            disabled={isSubmittingg}
                            className="center"
                          >
                            Next
                          </button>
                        </center>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Form>
            );
          }}
        </Formik>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div id="signup">
        <div>
          <div className="title-area">
            {/* <h3>Create a New Account</h3> */}
            <p className="signup-box-info">
              {/* <span>Or </span>
              <a href="/login">login to your account</a>
              <span>.</span> */}
            </p>
          </div>
          {this.getForm(this.state.step)}
          <style
            dangerouslySetInnerHTML={{
              __html:
                'header { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer-container{display:none} .full-height{height:100%}',
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.invite.event,
    invite: state.invite,
    locations: state.locations.locations,
    users: state.users.users,
    unauth: state.unauth,
    profile: state.profile.profile,
    vendor: state.vendor.vendor,
    timedEvents: state.timedEvents.events,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setVendorLogin: (user) => {
      dispatch(setVendorLogin(user));
    },
    getVendor: (id) => {
      dispatch(getVendor(id));
    },

    getVendorEntertainment: (id) => {
      dispatch(getVendorEntertainment(id));
    },

    getVendorUserEvents: (id) => {
      dispatch(getVendorUserEvents(id));
    },

    getVendorRewards: (id) => {
      dispatch(getVendorRewards(id));
    },

    getVendorTimedEvents: (id) => {
      dispatch(getVendorTimedEvents(id));
    },

    getVendorAwarded: (id) => {
      dispatch(getVendorAwarded(id));
    },

    getVendorPromos: (id) => {
      dispatch(getVendorPromos(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorProfileForm);
