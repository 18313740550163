import firebase from "../config/firebase";

// Get All Packages
export function getPackages(zoneId) {
  const packagesArray = [];
  const db = firebase.firestore();

  const getP = function () {
    return new Promise((resolve, reject) => {
      db.collection("packages")
        .where("zoneId", "==", zoneId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                title,
                description,
                thumbnail,
                heroImage,
                creatorInfo,
                locations,
                promoted,
                bannerText,
                category,
              } = doc.data();
              packagesArray.push({
                packageId: doc.id,
                title,
                description,
                thumbnail,
                heroImage,
                creatorInfo,
                locations,
                promoted,
                bannerText,
                category,
              });
            }
          });
          return packagesArray;
        })
        .then((result) => {
          resolve(packagesArray);
        });
    });
  };
  return getP();
}

// Get All Packages
export function savePackage(packages) {
  const db = firebase.firestore();
  const getP = function () {
    return new Promise((resolve, reject) => {
      const setDoc = db
        // .collection('package')
        .collection("packages")
        .doc(packages.id)
        .set(packages)
        .then((ref) => {
          // console.log("Added document with ID: ", ref);
          return ref;
        })
        .then((result) => {
          resolve(result);
        });
    });
  };
  return getP();
}

// Save Event
export function saveEvent(event) {
  const db = firebase.firestore();
  const getP = function () {
    return new Promise((resolve, reject) => {
      const setDoc = db
        // .collection('package')
        .collection("events")
        .doc(event.id)
        .set(event)
        .then((ref) => {
          // console.log("Added document with ID: ", ref);
          return ref;
        })
        .then((result) => {
          resolve(result);
        });
    });
  };
  return getP();
}
