import React, { Component } from 'react';

class GoogleAd extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  render() {
    return (
      <div>
        <ins
          className="adsbygoogle"
          style={{
            display: 'block',
          }}
          data-ad-client={this.props.client}
          data-ad-slot={this.props.slot}
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
    );
  }
}

export default GoogleAd;
