import { call, put, takeLatest } from "redux-saga/effects";
import { getTags } from "../../services/AdminServices";
import * as actions from "./actions";

let defaultState = {
  events: {},
};

function* getTagsAction(action) {
  let tags = yield call(getTags);
  yield put({
    type: actions.SET_TAGS,
    payload: {
      ...defaultState,
      tags,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_TAGS, getTagsAction);
}
