import React from 'react';
import { connect } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
import { Container, Row, Col } from 'reactstrap';
import AdminVendorListItem from '../../components/AdminVendorListItem';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
// Components
import TitleBar from '../../components/TitleBar';
// Reducers
import { getVendors } from '../../sagas/vendorsSaga/reducer';

const KEYS_TO_FILTERS = ['businessName'];

export class AdminVendors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getVendors();
  }

  componentDidMount() {
    console.log('vendor', this.props);
    this.setState({
      loading: false,
    });
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/home',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Manage Vendors</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              <div className="right">
                <a href="/admin/vendor/new">New</a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
    }
    const filteredUsers = this.props.vendors.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="admin">
        {this.getTitleBar()}
        <div className="section">
          <div className="header-container">
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
          </div>
        </div>
        <div>
          <AdminVendorListItem vendors={filteredUsers} />
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vendors: state.vendors.vendors,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVendors: () => {
      dispatch(getVendors());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminVendors);
