import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createFilter } from 'react-search-input';
import { Col, Container, Row } from 'reactstrap';
// Styles
import '../components/PackageListItem/PackageListItem.css';
import { getAllLocations } from '../sagas/allLocationsSaga/reducer';
import {
  getNewEvent,
  resetNewEvent,
} from '../sagas/createEventSaga/reducer';
import { resetCe } from '../sagas/customEventSaga/reducer';
// Reducers
import { init } from '../sagas/packagesSaga/reducer';

const KEYS_TO_FILTERS = ['title'];

export class PlanEventHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
    };
    // this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    if (
      this.props.profile.zoneId === undefined ||
      this.props.profile.zoneId === 0
    ) {
      this.props.history.push('/setup');
    }
  }

  componentDidMount() {
    document.title = 'Plan Event';
    this.connectFirst();
    this.setState({
      loading: false,
    });
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  planFirst() {
    // const newEvent = {
    //   connections: [],
    //   planFirst: true,
    //   connectFirst: false,
    //   clear: false,
    //   step: 3,
    // };
    // this.props.getNewEvent(newEvent);
  }

  connectFirst() {
    // const newEvent = {
    //   connections: [],
    //   planFirst: false,
    //   connectFirst: false,
    //   clear: false,
    //   step: 2,
    //   screen: 1,
    // };
    // this.props.getNewEvent(newEvent);
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Create New Experience
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.packages === undefined) {
      return null; // or render a loading icon
    }
    const filteredPackages = this.props.packages.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="packages">
        {this.getTitleBar()}
        <div className="secondary-title-bar">
          <div>
            <Container className="no-margin">
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding text-center"
                >
                  <span>How would you like to get started?</span>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* <div className="contentbox"> */}
        <div>
          <div className="section">
            <div className="header-container">
              {/* <div className="section text-center">
                <span className="guestListTitle">
                  How would you like to get started?
                </span>
              </div> */}
            </div>
            <Container>
              <Row className="text-center">
                <Col lg={6} md={6} sm={12} xs={12}>
                  <a
                    onClick={() => {
                      this.connectFirst();
                    }}
                  >
                    <span className="redbutton white display-block">
                      Make Plans With Others
                    </span>
                  </a>
                </Col>
              </Row>
              <Row className="text-center">
                <Col lg={6} md={6} sm={12} xs={12}>
                  <a
                    onClick={() => {
                      this.planFirst();
                    }}
                  >
                    <span className="stackedRedButtonBlock white display-block">
                      Make Plans First & Invite Others
                    </span>
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    alllocations: state.locations.locations,
    newEvent: state.newEvent,
    user: state.user,
    profile: state.profile.profile,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(init());
    },
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    resetNewEvent: (newEvent) => {
      dispatch(resetNewEvent(newEvent));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanEventHome);
