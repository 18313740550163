import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from 'reactstrap';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

class DailyWeather extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  UNSAFE_componentWillMount() {
    axios
      .get('https://api.weather.gov/points/38.4128,-90.3864', {
        headers: {
          // token: 'osccMsGGcKjhuZkpUSfbGooOzxqBPWeR',
          // UserAgent: 'trevel.com',
        },
      })
      .then((response) => {
        let path = response.data.properties.forecast;

        axios
          .get(path, {
            headers: {
              // token: 'osccMsGGcKjhuZkpUSfbGooOzxqBPWeR',
              // UserAgent: 'trevel.com',
            },
          })
          .then((response) => {
            let name = response.data.properties.periods[0].name;
            let temp =
              response.data.properties.periods[0].temperature;

            let promotedLocations = this.findPromotedLocations(
              this.props.locations,
              temp,
            );
            this.setState({
              promotedLocations,
              loading: false,
              temp,
            });
            // console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  findPromotedLocations(locations, temp) {
    const promotedLocations = [];

    // Get Speak Eeasy
    if (temp < 65) {
      let randomNum = Math.floor(Math.random() * 2);
      let promotedLocationIds = [];
      locations.forEach((location) => {
        if (
          location.tagsSetting !== undefined &&
          location.tagsSetting.length !== 0
        ) {
          location.tagsSetting.forEach((tagx) => {
            if (tagx === 'Speakeasy') {
              const status = promotedLocationIds.includes(
                location.locationId,
              );
              if (status === false) {
                promotedLocationIds.push(location.locationId);
                promotedLocations.push(location);
              }
            }
          });
        }
        if (
          location.tagsSettings !== undefined &&
          location.tagsSettings.length !== 0
        ) {
          location.tagsSettings.forEach((tagx) => {
            if (tagx === 'Bistro') {
              const status = promotedLocationIds.includes(
                location.locationId,
              );
              if (status === false) {
                promotedLocationIds.push(location.locationId);
                promotedLocations.push(location);
              }
            }
          });
        }
      });
    }

    // Get Patio
    if (temp > 65) {
      let randomNum = Math.floor(Math.random() * 2);
      let promotedLocationIds = [];
      locations.forEach((location) => {
        if (
          location.tagsSetting !== undefined &&
          location.tagsSetting.length !== 0
        ) {
          location.tagsSetting.forEach((tagx) => {
            if (tagx === 'Patio') {
              const status = promotedLocationIds.includes(
                location.locationId,
              );
              if (status === false) {
                promotedLocationIds.push(location.locationId);
                promotedLocations.push(location);
              }
            }
          });
        }
      });
    }
    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
    let shuffledLocations = shuffleArray(promotedLocations);
    let topThree = [];
    let topThreeIds = [];
    shuffledLocations.forEach((location) => {
      if (topThree.length !== 2) {
        const status = topThreeIds.includes(location.locationId);
        if (status === false) {
          topThreeIds.push(location.locationId);
          topThree.push(location);
        }
      }
    });
    return topThree;
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getSingle(promotedLocations) {
    let location = promotedLocations[0];
    if (promotedLocations.length === 1) {
      return (
        <div
          style={{
            background: 'white',
          }}
        >
          <Link
            to={{
              pathname: '/location/' + location.locationId,
              state: {
                location: { location },
                // eventId: { eventId },
                // token: { token },
                type: 500,
              },
            }}
          >
            <div
              style={{
                background: 'white',
              }}
            >
              <img
                src={this.getImage('hero', location)}
                style={{ width: '100%' }}
              />
              <div className="section">
                <span className="title">
                  {location.locationTitle}
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: location.locationDescription,
                  }}
                  className="editoOverRide"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getSlideSections(promotedLocations, count) {
    let sections = [];
    let sectionOne = [];
    let sectionTwo = [];
    let sectionThree = [];
    let sectionFour = [];
    let sectionFive = [];

    if (count === 4) {
      promotedLocations.forEach((location, index) => {
        if (index < 3 || index === 3) {
          sectionOne.push(location);
        }
        if (index > 3 && index < 8) {
          sectionTwo.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
    }

    if (count === 3) {
      promotedLocations.forEach((location, index) => {
        if (index < 2 || index === 2) {
          sectionOne.push(location);
        }
        if (index > 2 && index < 6) {
          sectionTwo.push(location);
        }
        if (index > 6 && index < 9) {
          sectionThree.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
    }

    if (count === 2) {
      promotedLocations.forEach((location, index) => {
        if (index < 1 || index === 1) {
          sectionOne.push(location);
        }
        if (index > 1 && index < 4) {
          sectionTwo.push(location);
        }
        if (index > 4 && index < 7) {
          sectionThree.push(location);
        }
        if (index > 7 && index < 10) {
          sectionFour.push(location);
        }
        if (index > 10 && index < 13) {
          sectionFive.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
      if (sectionFour.length !== 0) {
        sections.push(sectionFour);
      }
      if (sectionFive.length !== 0) {
        sections.push(sectionFive);
      }
    }

    return sections.map((section, index) => {
      return (
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {section.map((location, index) => (
            <div
              style={{
                border: '0px solid rgba(0, 0, 0, 0.125)',
                background: 'white !important',
              }}
              className="multiSlideCallout"
            >
              <Link
                to={{
                  pathname: '/location/' + location.locationId,
                  state: {
                    location: { location },
                    // eventId: { eventId },
                    // token: { token },
                    type: 500,
                  },
                }}
              >
                <CardImg
                  top
                  width="100%"
                  src={this.getImage('hero', location)}
                  alt="articleHero"
                />
                <CardBody style={{ textAlign: 'left' }}>
                  <div style={{ paddingTop: '10px', border: '0px' }}>
                    <span className="title">
                      {location.locationTitle}
                    </span>
                    {this.getMainTag(location)}
                    {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                    <CardText>
                      <span className="editoOverRide">
                        {this.truncate(location.locationDescription)}
                      </span>
                    </CardText>
                    <CardText />
                  </div>
                </CardBody>
              </Link>
            </div>
          ))}
        </div>
      );
    });
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span className="mainTagClear">{location.tagsMain}</span>
      );
    }
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    let count = this.props.count;

    return (
      <div
        className="text-center"
        // id={
        //   this.state.promotedLocations.length > 1 && 'homeSectionGrey'
        // }
        id="coldWeather"
      >
        <div className="sectiontitle text-center">
          {this.state.temp > 65 ? (
            <span className="homeCatCenter white">
              Its a beautiful {this.state.temp} outside. Patio?
            </span>
          ) : (
            <div>
              <span className="homeCatCenter white">
                It{"'"}s Cold Outside.
              </span>
              <span style={{ textShadow: '2px 2px #23679e' }}>
                Here are some speakeasies to warm your soul.
              </span>
            </div>
          )}
        </div>
        {this.state.promotedLocations.length > 1 && (
          <div>
            {this.getSlideSections(
              this.state.promotedLocations,
              count,
            )}
          </div>
        )}
        {this.getSingle(this.state.promotedLocations)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(DailyWeather);
