import { FastField } from 'formik';
import MaterialIcon from 'material-icons-react';
import TimePicker from 'rc-time-picker';
import React from 'react';
import moment from 'moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-modal';
import VendorRewardForm from '../../components/VendorRewardForm';
import logo from '../../images/TrevelUnderlined.svg';
import {
  UncontrolledCollapse,
  Card,
  Button,
  Col,
  Row,
  CardBody,
  Container,
} from 'reactstrap';
import firebase from '../../config/firebase';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import UserAvatar from 'react-user-avatar';
// Reducers
import { getVendorPromos } from '../../sagas/vendorPromoSaga/reducer';
import { getDemos } from '../../sagas/demoSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

const format = 'h:mm a';
const now = moment().hour(0).minute(0);

const conversationModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    // minWidth: '75%',
    maxHeight: '75%',
    minWidth: '700px',
    background: '#fafafa',
  },
};

export class VendorGallery extends React.Component {
  constructor() {
    super();
    this.state = {
      activeSet: false,
      activeLevel: 1,
      criteriaDetailView: false,
      rewardModalVisible: false,
      locationModalVisible: false,
      createNewRewardFormActive: false,
      editRewardFormActive: false,
      selectedLocations: [],
      togglerCa: true,
      togglerCb: true,
      togglerCc: true,
      togglerCd: true,
      togglerCe: true,
      togglerCf: true,
      togglerCg: true,
      togglerCh: true,
      togglerCi: true,
      togglerCj: true,
      togglerCk: true,
      togglerCl: true,
      togglerCm: true,
      togglerCn: true,
      togglerCo: true,
      togglerCp: true,
      togglerCq: true,
      loading: true,
      showPicker: false,
      showHelp: false,
      differentEnd: 'no',
      differentTime: 'no',
      from: undefined,
      to: undefined,
      previewMode: 1,
      title: '',
      description: '',
      promoLocations: [],
      goldenTicketLevel: [],
      goldenTicketDetails: '',
      goldenTicketQuantity: 0,
      levelOne: '',
      levelTwo: '',
      levelThree: '',
      levelFour: '',
      levelFive: '',
      levelSix: '',
      restriction: 0,
      lockedOne: false,
      lockedTwo: false,
      lockedThree: false,
      lockedFour: false,
      lockedFive: false,
      quantityOne: 0,
      quantityTwo: 0,
      quantityThree: 0,
      quantityFour: 0,
      quantityFive: 0,
      rewardDetailsOne: '',
      rewardDetailsTwo: '',
      rewardDetailsThree: '',
      rewardDetailsFour: '',
      rewardDetailsFive: '',
      guidelinesOne: '',
      guidelinesTwo: '',
      guidelinesThree: '',
      guidelinesFour: '',
      guidelinesFive: '',
      rewardTwo: '',
      criteriaTwo: '',
      dcount: 1,
      levelThreeLocations: '',
      dfields: [
        {
          id: 1,
          value: 'select',
        },
      ],
      levelCriteriaCounts: {
        levelOneCriteriaCount: 1,
        levelTwoCriteriaCount: 1,
        levelThreeCriteriaCount: 1,
        levelFourCriteriaCount: 1,
        levelFiveCriteriaCount: 1,
      },
      levelOneCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelTwoCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelThreeCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelFourCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelFiveCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      mode: 1,
      events: [],
      checked: false,
      crop: {
        unit: '%',
        width: 30,
        aspect: 800 / 259,
      },
      cropThumb: {
        unit: '%',
        width: 30,
        aspect: 146 / 122,
      },
      cropping: false,
      croppingThumb: false,
      thumbPath: '',
      heroPath: '',
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    document.title = 'Trevel Experiences Gallery';
  }

  componentDidMount() {
    this.props.getDemos();
    let events = [];
    let rewards = [];
    let locations = [];

    // this.props.vendorEvents.forEach((event, i) => {
    //   events.push({
    //     value: event.id,
    //     label: event.title,
    //   });
    // });

    this.props.demo.demoPromos.forEach((event, i) => {
      events.push({
        value: event.id,
        label: event.title,
      });
    });

    this.props.vendorRewards.forEach((event, i) => {
      rewards.push({
        value: event.id,
        label: event.title,
      });
    });

    this.props.vendor.locations.forEach((location, i) => {
      let locationDetails = query('locationId')
        .is(location)
        .on(this.props.locations);
      if (locationDetails[0] !== undefined) {
        locations.push({
          value: location,
          label: locationDetails[0].locationTitle,
        });
      } else {
        locationDetails = query('locationId')
          .is(location)
          .on(this.props.demo.demoLocations);
        if (locationDetails[0] !== undefined) {
          locations.push({
            value: location,
            label: locationDetails[0].locationTitle,
          });
        }
      }
    });

    this.setState({
      locationOptions: locations,
      eventOptions: events,
      rewardsOptions: rewards,
      loading: false,
      dbref: this.props.match.params.id,
    });

    this.setContent(this.props.match.params.id);
  }

  setContent(id) {
    let events = [];

    // this.props.vendorEvents.forEach((event, i) => {
    //   events.push({
    //     value: event.id,
    //     label: event.title,
    //   });
    // });

    this.props.demo.demoPromos.forEach((event, i) => {
      events.push({
        value: event.id,
        label: event.title,
      });
    });

    if (id !== undefined) {
      const contentDetails = query('id')
        .is(id)
        .on(this.props.vendorPromos);

      // Set Schedule
      if (
        contentDetails[0].scheduledTime.dates.startTimestamp !== ''
      ) {
        let selectedDay = moment(
          contentDetails[0].scheduledTime.dates.startTimestamp,
        );
        this.setState({
          from: selectedDay._d,
        });
      }

      if (contentDetails[0].scheduledTime.dates.endTimestamp !== '') {
        let selectedDay = moment(
          contentDetails[0].scheduledTime.dates.endTimestamp,
        );
        this.setState({
          to: selectedDay._d,
        });
      }

      if (contentDetails[0].scheduledTime.times.regHours === false) {
        let startTime =
          contentDetails[0].scheduledTime.times.startTimeString
            .seconds * 1000; // Unix ms timestamp

        let endTime =
          contentDetails[0].scheduledTime.times.endTimeString
            .seconds * 1000; // Unix ms timestamp

        let selectedStartTime = moment(startTime);
        let selectedEndTime = moment(endTime);
        this.setState({
          selectedStartTime: selectedStartTime,
          selectedEndTime: selectedEndTime,
          differentTime: 'yes',
        });
      }

      // Set Experience Types
      const experienceType = [];
      if (contentDetails[0].tagsExperienceTypes !== undefined) {
        contentDetails[0].tagsExperienceTypes.forEach((tag) => {
          experienceType.push({
            value: tag,
            label: tag,
          });
        });
      }
      this.setState({
        experienceType,
      });

      // Golden Ticket
      const goldenTicketLevel = [];
      if (contentDetails[0].goldenTicket !== undefined) {
        contentDetails[0].goldenTicket.levels.forEach((level) => {
          let label = 'Level ' + level;
          goldenTicketLevel.push({
            value: level,
            label: label,
          });
        });
      }
      let goldenTicketReward = {
        value: contentDetails[0].goldenTicket.reward.id,
        label: contentDetails[0].goldenTicket.reward.title,
      };
      this.setState({
        goldenTicketLevel,
        goldenTicketDetails: contentDetails[0].goldenTicket.details,
        goldenTicketQuantity: contentDetails[0].goldenTicket.quantity,
        levelSix: goldenTicketReward,
      });

      // Locations
      let selectedLocations = [];
      contentDetails[0].locations.forEach((location, i) => {
        const locationDetails = query('locationId')
          .is(location.locationId)
          .on(this.props.locations);

        let newLocation = {
          approved: location.approved,
          approverId: location.approverId,
          locationId: location.locationId,
          locationTitle: location.locationTitle,
          approvalDate: location.approvalDate,
          locationThumb: location.locationThumb,
          address: location.address,
          settings: locationDetails[0].settings,
        };
        selectedLocations.push(newLocation);
      });

      // let eventsArray = [];
      // if (contentDetails[0].events.length !== 0) {
      //   contentDetails[0].events.forEach((event, i) => {
      //     eventsArray.push({
      //       value: event.id,
      //       label: event.title,
      //     });
      //   });
      //   this.setState({
      //     events: eventsArray,
      //   });
      // }

      // Set Default Images
      let heroPath = '';
      if (contentDetails[0].images.heroPath !== null) {
        heroPath = contentDetails[0].images.heroPath;
      }
      let thumbPath = '';
      if (contentDetails[0].images.thumbPath !== null) {
        thumbPath = contentDetails[0].images.thumbPath;
      }
      this.setState({
        selectedLocations,
        promoLocations: contentDetails[0].locations,
        eventOptions: events,
        title: contentDetails[0].title,
        description: contentDetails[0].description,
        loading: false,
        promoId: contentDetails[0].id,
        checked: contentDetails[0].active,
        heroPath,
        thumbPath,
      });
      let rewards = contentDetails[0].rewards;

      let levelOneCriteriaCount = 1;
      let levelTwoCriteriaCount = 1;
      let levelThreeCriteriaCount = 1;
      let levelFourCriteriaCount = 1;
      let levelFiveCriteriaCount = 1;

      rewards.forEach((reward, i) => {
        let level = {
          value: reward.id,
          label: reward.title,
        };
        if (i === 0) {
          let defaultCriteria = this.state.levelOneCriteria;
          reward.criteria.forEach((criteria, i) => {
            defaultCriteria[i] = criteria;
          });
          levelOneCriteriaCount = defaultCriteria.length + 1;
          this.setState({
            levelOneCriteria: defaultCriteria,
            lockedOne: reward.locked,
            levelOne: level,
            quantityOne: reward.quantity,
          });
        } else if (i === 1) {
          let defaultCriteria = this.state.levelTwoCriteria;
          reward.criteria.forEach((criteria, i) => {
            defaultCriteria[i] = criteria;
          });
          levelTwoCriteriaCount = defaultCriteria.length + 1;
          this.setState({
            levelTwoCriteria: defaultCriteria,
            lockedTwo: reward.locked,
            levelTwo: level,
            quantityTwo: reward.quantity,
          });
        } else if (i === 2) {
          let defaultCriteria = this.state.levelThreeCriteria;
          reward.criteria.forEach((criteria, i) => {
            defaultCriteria[i] = criteria;
          });
          levelThreeCriteriaCount = defaultCriteria.length + 1;
          this.setState({
            levelThreeCriteria: defaultCriteria,
            lockedThree: reward.locked,
            levelThree: level,
            quantityThree: reward.quantity,
          });
        } else if (i === 3) {
          let defaultCriteria = this.state.levelFourCriteria;
          reward.criteria.forEach((criteria, i) => {
            defaultCriteria[i] = criteria;
          });
          levelFourCriteriaCount = defaultCriteria.length + 1;
          this.setState({
            levelFourCriteria: defaultCriteria,
            lockedFour: reward.locked,
            levelFour: level,
            quantityFour: reward.quantity,
          });
        } else if (i === 4) {
          let defaultCriteria = this.state.levelFiveCriteria;
          reward.criteria.forEach((criteria, i) => {
            defaultCriteria[i] = criteria;
          });
          levelFiveCriteriaCount = defaultCriteria.length + 1;
          this.setState({
            levelFiveCriteria: defaultCriteria,
            lockedFive: reward.locked,
            levelFive: level,
            quantityFive: reward.quantity,
          });
        }
      });

      // Set Num of Criteria
      this.setState({
        levelCriteriaCounts: {
          levelOneCriteriaCount,
          levelTwoCriteriaCount,
          levelThreeCriteriaCount,
          levelFourCriteriaCount,
          levelFiveCriteriaCount,
        },
      });
    } else {
      return false;
    }
  }

  // handleUploadStartThumb = () =>
  //   this.setState({ isUploading: true, progress: 0 });
  // handleProgressThumb = progress => this.setState({ progress });
  // handleUploadErrorThumb = error => {
  //   this.setState({ isUploading: false });
  //   console.error(error);
  // };
  // handleUploadSuccessThumb = filename => {
  //   const uploadUrl = "test/" + this.props.profile.vendorAccount.id;
  //   this.setState({ hero: filename, progress: 100, isUploading: false });
  //   firebase
  //     .storage()
  //     .ref(uploadUrl)
  //     .child(filename)
  //     .getDownloadURL()
  //     .then(url => {
  //       console.log("returned URL", url);
  //       this.setState({ thumbPath: url });
  //     })
  //     .then(() => {
  //       let updatedPackageBundle = this.props.packageBundle;
  //       updatedPackageBundle.package.thumbnail = filename;
  //       this.props.updatePackage(updatedPackageBundle);
  //     });
  // };

  // Dependecy Fields
  change = (selectName, selectedOption) => {
    let newFields = this.state.dfields;
    let index = selectName - 1;
    newFields[index].value = selectedOption.target.value;
    console.log('change!', selectName);
    console.log('change!', selectedOption.target.value);
    this.setState({ dfields: newFields });
  };

  getDependecyField(dfield) {
    console.log('NOTOTOT dfields', dfield);
    console.log('xxx', this.props.vendorRewards);
    let options = [{ value: 'none', name: 'none' }];
    let promoCount = this.props.vendorRewards.length;
    let matchCount = 0;
    console.log('NOTOTOT y');
    this.props.vendorRewards.forEach((promo, i) => {
      let match = false;
      this.state.dfields.forEach((dfieldx, i) => {
        if (
          dfieldx.value === promo.title &&
          dfield.value !== promo.title
        ) {
          console.log('NOTOTOT match');
          match = true;
          matchCount = matchCount + 1;
        }
      });
      if (match === false) {
        options.push({
          value: promo.title,
          name: promo.title,
        });
      }
    });
    if (matchCount !== promoCount) {
      return (
        <select
          name={dfield.id}
          value={dfield.value}
          onChange={(e) => this.change(dfield.id, e)}
        >
          {options.map((e, key) => {
            return (
              <option key={key} value={e.value}>
                {e.name}
              </option>
            );
          })}
        </select>
      );
    }
  }

  getAddButton() {
    let promoCount = this.props.vendorPromos.length;
    let matchCount = 0;
    this.props.vendorPromos.forEach((promo, i) => {
      let match = false;
      this.state.dfields.forEach((dfieldx, i) => {
        if (dfieldx.value === promo.title) {
          console.log('NOTOTOT match');
          match = true;
          matchCount = matchCount + 1;
        }
      });
    });
    if (matchCount !== promoCount) {
      return (
        <span
          onClick={() => {
            this.addField(this.state.dcount);
          }}
        >
          add
        </span>
      );
    }
  }

  addField(dcount) {
    let newCount = dcount + 1;
    let newFields = this.state.dfields;
    newFields.push({
      id: newCount,
      value: '',
    });
    this.setState({ dfields: newFields, dcount: newCount });
  }

  // End

  // Reward 1
  rewardDetailsOneOnChange(e) {
    this.setState({ rewardDetailsOne: e.target.value });
  }
  rewardDetailsTwoOnChange(e) {
    this.setState({ rewardDetailsTwo: e.target.value });
  }
  rewardDetailsThreeOnChange(e) {
    this.setState({ rewardDetailsThree: e.target.value });
  }
  rewardDetailsFourOnChange(e) {
    this.setState({ rewardDetailsFour: e.target.value });
  }
  rewardDetailsFiveOnChange(e) {
    this.setState({ rewardDetailsFive: e.target.value });
  }

  // Locked
  lockedOneChange(e) {
    if (this.state.lockedOne === true) {
      this.setState({ lockedOne: false });
    } else {
      this.setState({ lockedOne: true });
    }
  }
  lockedTwoChange(e) {
    if (this.state.lockedTwo === true) {
      this.setState({ lockedTwo: false });
    } else {
      this.setState({ lockedTwo: true });
    }
  }
  lockedThreeChange(e) {
    if (this.state.lockedThree === true) {
      this.setState({ lockedThree: false });
    } else {
      this.setState({ lockedThree: true });
    }
  }
  lockedFourChange(e) {
    if (this.state.lockedFour === true) {
      this.setState({ lockedFour: false });
    } else {
      this.setState({ lockedFour: true });
    }
  }
  lockedFiveChange(e) {
    if (this.state.lockedFive === true) {
      this.setState({ lockedFive: false });
    } else {
      this.setState({ lockedFive: true });
    }
  }

  // Reward Quantity
  quantityOneOnChange(e) {
    let num = Number(e.target.value);
    this.setState({ quantityOne: num });
  }
  quantityTwoOnChange(e) {
    let num = Number(e.target.value);
    this.setState({ quantityTwo: num });
  }
  quantityThreeOnChange(e) {
    let num = Number(e.target.value);
    this.setState({ quantityThree: num });
  }
  quantityFourOnChange(e) {
    let num = Number(e.target.value);
    this.setState({ quantitFour: num });
  }
  quantityFiveOnChange(e) {
    let num = Number(e.target.value);
    this.setState({ quantityFive: num });
  }

  titleOnChange(e) {
    this.setState({ title: e.target.value });
  }
  descriptionOnChange(e) {
    this.setState({ description: e.target.value });
  }
  restrictionChange(e) {
    this.setState({ restriction: e.target.value });
  }
  handleOnChange = (value) => {
    this.setState({ events: value });
  };

  // Level Handlers
  handleOnChangeLevelOne = (value) => {
    if (this.state.rewardDetailsOne === '') {
      this.setState({ rewardDetailsOne: 'hello' });
    }
    this.setState({ levelOne: value, activeLevel: 1 });
  };
  handleOnChangeLevelTwo = (value) => {
    this.setState({ levelTwo: value, activeLevel: 2 });
  };
  handleOnChangeLevelThree = (value) => {
    this.setState({ levelThree: value, activeLevel: 3 });
  };
  handleOnChangeLevelFour = (value) => {
    this.setState({ levelFour: value, activeLevel: 4 });
  };
  handleOnChangeLevelFive = (value) => {
    this.setState({ levelFive: value, activeLevel: 5 });
  };
  handleOnChangeLevelSix = (value) => {
    this.setState({ levelSix: value, activeLevel: 6 });
  };

  handleOnChangeGoldenLevel = (value) => {
    this.setState({ goldenTicketLevel: value });
  };

  goldenTicketDetailsOnChange = (e) => {
    this.setState({ goldenTicketDetails: e.target.value });
  };

  goldenTicketQuantityOnChange = (e) => {
    let num = Number(e.target.value);
    this.setState({ goldenTicketQuantity: num });
  };

  // Critera Handlers
  criteriaTextHandler = (e) => {
    let newCriteria = [];
    if (e.target.id === 'one') {
      newCriteria = this.state.levelOneCriteria;
    } else if (e.target.id === 'two') {
      newCriteria = this.state.levelTwoCriteria;
    } else if (e.target.id === 'three') {
      newCriteria = this.state.levelThreeCriteria;
    } else if (e.target.id === 'four') {
      newCriteria = this.state.levelFourCriteria;
    } else if (e.target.id === 'five') {
      newCriteria = this.state.levelFiveCriteria;
    }
    newCriteria[e.target.name].text = e.target.value;
    if (e.target.id === 'one') {
      this.setState({ levelOneCriteria: newCriteria });
    } else if (e.target.id === 'two') {
      this.setState({ levelTwoCriteria: newCriteria });
    } else if (e.target.id === 'three') {
      this.setState({ levelThreeCriteria: newCriteria });
    } else if (e.target.id === 'four') {
      this.setState({ levelFourCriteria: newCriteria });
    } else if (e.target.id === 'five') {
      this.setState({ levelFiveCriteria: newCriteria });
    }
  };

  // criteria Handlers
  criteriaLocationsHandler = (values, index, id) => {
    let newLocations = '';
    if (id === 'one') {
      newLocations = this.state.levelOneCriteria;
    } else if (id === 'two') {
      newLocations = this.state.levelTwoCriteria;
    } else if (id === 'three') {
      newLocations = this.state.levelThreeCriteria;
    } else if (id === 'four') {
      newLocations = this.state.levelFourCriteria;
    } else if (id === 'five') {
      newLocations = this.state.levelFiveCriteria;
    }
    newLocations[index].locations = values;
    if (id === 'one') {
      this.setState({ levelOneCriteria: newLocations });
    } else if (id === 'two') {
      this.setState({ levelTwoCriteria: newLocations });
    } else if (id === 'three') {
      this.setState({ levelThreeCriteria: newLocations });
    } else if (id === 'four') {
      this.setState({ levelFourCriteria: newLocations });
    } else if (id === 'five') {
      this.setState({ levelFiveCriteria: newLocations });
    }
  };

  criteriaGuidelinesHandler = (e) => {
    let newGuidelines = [];
    if (e.target.id === 'one') {
      newGuidelines = this.state.levelOneCriteria;
    } else if (e.target.id === 'two') {
      newGuidelines = this.state.levelTwoCriteria;
    } else if (e.target.id === 'three') {
      newGuidelines = this.state.levelThreeCriteria;
    } else if (e.target.id === 'four') {
      newGuidelines = this.state.levelFourCriteria;
    } else if (e.target.id === 'five') {
      newGuidelines = this.state.levelFiveCriteria;
    }
    newGuidelines[e.target.name].guidelines = e.target.value;
    if (e.target.id === 'one') {
      this.setState({ levelOneCriteria: newGuidelines });
    } else if (e.target.id === 'two') {
      this.setState({ levelTwoCriteria: newGuidelines });
    } else if (e.target.id === 'three') {
      this.setState({ levelThreeCriteria: newGuidelines });
    } else if (e.target.id === 'four') {
      this.setState({ levelFourCriteria: newGuidelines });
    } else if (e.target.id === 'five') {
      this.setState({ levelFiveCriteria: newGuidelines });
    }
  };
  criteriaNumTimesHandler = (e) => {
    let newNumTimes = '';
    if (e.target.id === 'one') {
      newNumTimes = this.state.levelOneCriteria;
    } else if (e.target.id === 'two') {
      newNumTimes = this.state.levelTwoCriteria;
    } else if (e.target.id === 'three') {
      newNumTimes = this.state.levelThreeCriteria;
    } else if (e.target.id === 'four') {
      newNumTimes = this.state.levelFourCriteria;
    } else if (e.target.id === 'five') {
      newNumTimes = this.state.levelFiveCriteria;
    }
    newNumTimes[e.target.name].numTimes = e.target.value;
    if (e.target.id === 'one') {
      this.setState({ levelOneCriteria: newNumTimes });
    } else if (e.target.id === 'two') {
      this.setState({ levelTwoCriteria: newNumTimes });
    } else if (e.target.id === 'three') {
      this.setState({ levelThreeCriteria: newNumTimes });
    } else if (e.target.id === 'four') {
      this.setState({ levelFourCriteria: newNumTimes });
    } else if (e.target.id === 'five') {
      this.setState({ levelFiveCriteria: newNumTimes });
    }
  };

  criteriaOncePerDayHandler = (e) => {
    let newNumTimes = '';
    if (e.target.id === 'one') {
      newNumTimes = this.state.levelOneCriteria;
    } else if (e.target.id === 'two') {
      newNumTimes = this.state.levelTwoCriteria;
    } else if (e.target.id === 'three') {
      newNumTimes = this.state.levelThreeCriteria;
    } else if (e.target.id === 'four') {
      newNumTimes = this.state.levelFourCriteria;
    } else if (e.target.id === 'five') {
      newNumTimes = this.state.levelFiveCriteria;
    }
    if (newNumTimes[e.target.name].oncePerDay === true) {
      newNumTimes[e.target.name].oncePerDay = false;
    } else {
      newNumTimes[e.target.name].oncePerDay = true;
    }
    if (e.target.id === 'one') {
      this.setState({ levelOneCriteria: newNumTimes });
    } else if (e.target.id === 'two') {
      this.setState({ levelTwoCriteria: newNumTimes });
    } else if (e.target.id === 'three') {
      this.setState({ levelThreeCriteria: newNumTimes });
    } else if (e.target.id === 'four') {
      this.setState({ levelFourCriteria: newNumTimes });
    } else if (e.target.id === 'five') {
      this.setState({ levelFiveCriteria: newNumTimes });
    }
  };

  activeOnChange() {
    if (this.state.checked === true) {
      this.setState({ checked: false });
    } else {
      this.setState({ checked: true });
    }
  }

  setLevel(level) {
    console.log('level', level);
    this.setState({ activeLevel: level });
  }

  // getCriteria(currentCriteria, criteriaCount) {
  //   if (
  //     criteriaCount === currentCriteria ||
  //     criteriaCount > currentCriteria
  //   ) {
  //     return <span></span>;
  //   }
  // }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  // Crop Functions
  getHeroImage() {
    if (this.state.heroPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '500px' }}
          src={this.state.heroPath}
        />
      );
    }
  }

  getThumbnail() {
    console.log('this.state.thumbPath', this.state.thumbPath);
    if (this.state.thumbPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '200px' }}
          src={this.state.thumbPath}
        />
      );
    }
  }

  getTab(tabMode, tabLable, mode, last) {
    if (tabMode === mode) {
      if (last === true) {
        return (
          <div className="noBorderRight actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      } else {
        return (
          <div className="actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      }
    } else {
      if (last === true) {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton noBorderRight  actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      }
    }
  }

  getSection(mode) {
    if (mode === 1) {
      return (
        <div
          id="experienceForm"
          style={{ width: '70%', float: 'left' }}
        >
          {this.getTitleBar('Trevel Experience Gallery')}
          <div id="experienceFormContent">
            {this.getExperiences(this.props.vendorPromos)}
          </div>
        </div>
      );
    }
  }

  getTitleBar(title, id) {
    return (
      <div>
        <div id="galleryTitleBar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <h2 className="left">{title}</h2>
              {/* </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding"> */}
              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>

              <div className="saveButtons">
                <Link
                  to={{
                    pathname: '/vendor/experiences',
                  }}
                >
                  <span className="cancelButton">Close</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getCategoryFields(cattag) {
    const tags = cattag.tags;
    const options = [];
    tags.forEach((tag) => {
      options.push({
        value: tag.tag,
        label: tag.tag,
      });
    });
    if (cattag.parent === 'Experience Type') {
      return (
        <div>
          <span className="inputLabel">{cattag.parent}</span>
          <Select
            value={this.state.experienceType}
            onChange={(value) => {
              this.handleOnTagChange('experienceType', value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  handleOnTagChange = (fieldName, value) => {
    console.log('changeit', this.state.experienceType);
    this.setState({ [fieldName]: value });
  };

  getTagTab() {
    return (
      <div className="section">
        {this.props.tags.map((cattag, index) => (
          <div>{this.getCategoryFields(cattag)}</div>
        ))}
      </div>
    );
  }

  handleResetClick() {
    this.setState({ from: undefined, to: undefined });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  setPreviewMode(mode) {
    this.setState({
      previewMode: mode,
    });
  }

  getLeftMenu() {
    return (
      <div className="vendorLeftNavButtons">
        {this.getTab(1, 'Details', this.state.mode, false)}
        {this.getTab(2, 'Rewards', this.state.mode, false)}
        {this.getTab(3, 'Images', this.state.mode, false)}
        {this.getTab(4, 'Schedule', this.state.mode, false)}
        {this.getTab(5, 'Tags', this.state.mode, false)}
      </div>
    );
  }

  getPromoImage(heroPath) {
    if (heroPath !== '') {
      return (
        <div className="promoImage">
          <img src={heroPath} />
        </div>
      );
    } else {
      return (
        <div className="promoImage">
          <span>hello</span>
        </div>
      );
    }
  }

  getCriteria(criteria, index) {
    let indexPlusOne = index + 1;
    if (criteria.type === 1) {
      if (criteria.numTimes < 2) {
        return (
          <div
            className="criteriaBorder"
            onClick={() => {
              this.setCriteriaDetailView(true, criteria);
            }}
          >
            <Row className=" no-margin no-padding">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="left no-padding"
              >
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="check_box_outline_blank"
                    size={25}
                    color="#9a0039"
                  />
                </span>
              </Col>
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="left no-padding"
              >
                <span className="criteriaText">{criteria.text}</span>
                <br />
                {criteria.locations.map((location, index) => (
                  <span className="locationTag">
                    {location.label}
                  </span>
                ))}
              </Col>
            </Row>
          </div>
        );
      } else {
        let currentNum = 0;
        let width =
          ((currentNum / criteria.numTimes) * 100).toFixed(2) + '%';
        return (
          <div className="criteriaBorder">
            <Row className=" no-margin no-padding">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="left no-padding"
              >
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="check_box_outline_blank"
                    size={25}
                    color="#9a0039"
                  />
                </span>
              </Col>
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="left no-padding"
              >
                <span className="criteriaText">{criteria.text}</span>
                <div id="progressBarBack">
                  <span className="right red">
                    <span className="progressGoal">
                      {criteria.numTimes}
                    </span>
                  </span>
                  <div
                    className="progressBar"
                    style={{ width: width }}
                  >
                    <span className="progressTotal">
                      {currentNum}
                    </span>
                  </div>
                </div>
                {criteria.locations.map((location, index) => (
                  <span className="locationTag">
                    {location.label}
                  </span>
                ))}
              </Col>
            </Row>
          </div>
        );
      }
    }
  }

  // getRewardIcon(index) {
  //   let status = false;
  //   if (index === 0) {
  //     let status = this.state.lockedOne;
  //   } else if (index === 1) {
  //     let status = this.state.lockedTwo;
  //   }
  //   if (index === 2) {
  //     let status = this.state.lockedThree;
  //   }
  //   if (index === 0) {
  //     let status = this.state.lockedFour;
  //   }
  //   if (reward.locked === false) {
  //     return <MaterialIcon icon="lock_open" size={25} color="red" />;
  //   } else if (reward.locked === true) {
  //     return <MaterialIcon icon="lock" size={25} color="red" />;
  //   }
  // }

  getGoldenTicket(reward, index) {
    let match = false;
    this.state.goldenTicketLevel.forEach((level, i) => {
      if (level.value === index) {
        match = true;
      }
    });
    if (match === true) {
      return (
        <span>
          <span style={{ color: 'black' }}>or </span>
          <span className="goldenTitle">
            {this.state.levelSix.label}
          </span>
        </span>
      );
    }
  }

  getReward(reward, index, promo, locationId) {
    return (
      <div className="promo-criteria">
        <div id={'toggler' + index}>
          <span>
            <span className="rewardIcon">
              {reward.locked && (
                <MaterialIcon icon="lock" size={25} color="red" />
              )}
              {!reward.locked && (
                <MaterialIcon
                  icon="lock_open"
                  size={25}
                  color="red"
                />
              )}
            </span>
            <span>
              <b>Level {index + 1}:</b>
            </span>{' '}
            <span className="rewardTitle">
              {reward.title} {this.getGoldenTicket(reward, index + 1)}
            </span>
          </span>
        </div>
        <UncontrolledCollapse
          toggler={'#toggler' + index}
          defaultOpen={true}
        >
          {reward.criteria.map((criteria, index) => (
            <div>{this.getCriteria(criteria, index)}</div>
          ))}
        </UncontrolledCollapse>
      </div>
    );
  }

  getPreviewRewards() {
    let levelOne = {
      id: this.state.levelOne.value,
      title: this.state.levelOne.label,
      locked: false,
      lockDependency: '',
      quantity: this.state.quantityOne,
    };
    let levelOneCriteria = [];
    this.state.levelOneCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelOneCriteria.push(criteria);
      }
    });
    levelOne.criteria = levelOneCriteria;

    // Level 2
    let levelTwo = {
      id: this.state.levelTwo.value,
      title: this.state.levelTwo.label,
      locked: this.state.lockedTwo,
      quantity: this.state.quantityTwo,
      status: 1,
    };
    let levelTwoCriteria = [];
    this.state.levelTwoCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelTwoCriteria.push(criteria);
      }
    });
    levelTwo.criteria = levelTwoCriteria;
    if (
      this.state.lockedTwo === 'on' ||
      this.state.lockedTwo === true
    ) {
      levelTwo.lockDependency = this.state.levelOne.value;
    } else {
      levelTwo.lockDependency = '';
    }

    // Level 3
    let levelThree = {
      id: this.state.levelThree.value,
      title: this.state.levelThree.label,
      locked: this.state.lockedThree,
      quantity: this.state.quantityThree,
      status: 1,
    };
    let levelThreeCriteria = [];
    this.state.levelThreeCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelThreeCriteria.push(criteria);
      }
    });
    levelThree.criteria = levelThreeCriteria;
    if (
      this.state.lockedThree === 'on' ||
      this.state.lockedThree === true
    ) {
      levelThree.lockDependency = this.state.levelThree.value;
    } else {
      levelThree.lockDependency = '';
    }

    // Level 4
    let levelFour = {
      id: this.state.levelFour.value,
      title: this.state.levelFour.label,
      locked: this.state.lockedFour,
      quantity: this.state.quantityFour,
      status: 1,
    };
    let levelFourCriteria = [];
    this.state.levelFourCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelFourCriteria.push(criteria);
      }
    });
    levelFour.criteria = levelFourCriteria;
    if (
      this.state.lockedFour === 'on' ||
      this.state.lockedFour === true
    ) {
      levelFour.lockDependency = this.state.levelFour.value;
    } else {
      levelFour.lockDependency = '';
    }

    // Level 5
    let levelFive = {
      id: this.state.levelFive.value,
      title: this.state.levelFive.label,
      locked: this.state.lockedFive,
      quantity: this.state.quantityFive,
      criteria: this.state.levelFiveCriteria,
      status: 1,
    };
    let levelFiveCriteria = [];
    this.state.levelFiveCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelFiveCriteria.push(criteria);
      }
    });
    levelFive.criteria = levelFiveCriteria;
    if (
      this.state.lockedFive === 'on' ||
      this.state.lockedFive === true
    ) {
      levelFive.lockDependency = this.state.levelFive.value;
    } else {
      levelFive.lockDependency = '';
    }

    let newRewards = [];
    if (levelOne.id !== undefined && levelOne.id !== '') {
      newRewards.push(levelOne);
    }
    if (levelTwo.id !== undefined && levelTwo.id !== '') {
      newRewards.push(levelTwo);
    }
    if (levelThree.id !== undefined && levelThree.id !== '') {
      newRewards.push(levelThree);
    }
    if (levelFour.id !== undefined && levelFour.id !== '') {
      newRewards.push(levelFour);
    }
    if (levelFive.id !== undefined && levelFive.id !== '') {
      newRewards.push(levelFive);
    }

    return (
      <div>
        {newRewards.map((reward, index) => (
          <div className="reward" key={index + 1}>
            {this.getReward(reward, index)}
          </div>
        ))}
      </div>
    );
  }

  getPreviewSchedule() {
    let date = '';
    if (
      this.state.to !== undefined &&
      this.state.from !== undefined &&
      this.state.to !== null &&
      this.state.from !== null
    ) {
      if (this.state.to === this.state.from) {
        let from = this.state.from.toLocaleDateString();
        date = from;
      } else {
        let from = this.state.from.toLocaleDateString();
        let to = this.state.to.toLocaleDateString();
        date = from + ' - ' + to;
      }
    } else {
      date = 'Not Scheduled';
    }
    return date;
  }

  setCriteriaDetailView(mode, criteria) {
    this.setState({
      criteriaDetailView: mode,
      activeCriteriaPreview: criteria,
    });
  }

  setActiveExperience(experience) {
    console.log('experience', experience);
    // Set Schedule
    if (
      experience.scheduledTime !== undefined &&
      experience.scheduledTime.dates.startTimestamp !== ''
    ) {
      let selectedDay = moment(
        experience.scheduledTime.dates.startTimestamp,
      );
      this.setState({
        from: selectedDay._d,
      });
    }
    if (
      experience.scheduledTime !== undefined &&
      experience.scheduledTime.dates.endTimestamp !== ''
    ) {
      let selectedDay = moment(
        experience.scheduledTime.dates.endTimestamp,
      );
      this.setState({
        to: selectedDay._d,
      });
    }
    if (
      experience.scheduledTime !== undefined &&
      experience.scheduledTime.times.regHours === false
    ) {
      let startTime =
        experience.scheduledTime.times.startTimeString.seconds * 1000; // Unix ms timestamp
      let endTime =
        experience.scheduledTime.times.endTimeString.seconds * 1000; // Unix ms timestamp
      let selectedStartTime = moment(startTime);
      let selectedEndTime = moment(endTime);
      this.setState({
        selectedStartTime: selectedStartTime,
        selectedEndTime: selectedEndTime,
        differentTime: 'yes',
      });
    }

    // Set Experience Types
    const experienceType = [];
    if (experience.tagsExperienceTypes !== undefined) {
      experience.tagsExperienceTypes.forEach((tag) => {
        experienceType.push({
          value: tag,
          label: tag,
        });
      });
    }
    this.setState({
      experienceType,
      activeSet: true,
    });

    // Golden Ticket
    const goldenTicketLevel = [];
    let goldenTicketReward = {};
    let goldenTicketDetails = '';
    let goldenTicketQuantity = '';
    if (experience.goldenTicket !== undefined) {
      experience.goldenTicket.levels.forEach((level) => {
        let label = 'Level ' + level;
        goldenTicketLevel.push({
          value: level,
          label: label,
        });
      });
      goldenTicketReward = {
        value: experience.goldenTicket.reward.id,
        label: experience.goldenTicket.reward.title,
      };
      goldenTicketDetails = experience.goldenTicket.details;
      goldenTicketQuantity = experience.goldenTicket.quantity;
    }

    this.setState({
      goldenTicketLevel,
      goldenTicketDetails,
      goldenTicketQuantity,
      levelSix: goldenTicketReward,
    });

    // Locations
    let selectedLocations = [];
    let locationDetails = [];
    if (experience.demo !== true) {
      experience.locations.forEach((location, i) => {
        locationDetails = query('locationId')
          .is(location.locationId)
          .on(this.props.locations);
        let newLocation = {
          approved: location.approved,
          approverId: location.approverId,
          locationId: location.locationId,
          locationTitle: location.locationTitle,
          approvalDate: location.approvalDate,
          locationThumb: locationDetails[0].locationThumb,
          address: locationDetails[0].address,
          settings: locationDetails[0].settings,
        };
        selectedLocations.push(newLocation);
      });
    } else {
      experience.locations.forEach((location, i) => {
        locationDetails = query('locationId')
          .is(location.locationId)
          .on(this.props.demo.demoLocations);
        let newLocation = {
          approved: location.approved,
          approverId: location.approverId,
          locationId: location.locationId,
          locationTitle: location.locationTitle,
          approvalDate: location.approvalDate,
          locationThumb: locationDetails[0].locationThumb,
          address: locationDetails[0].address,
          settings: locationDetails[0].settings,
        };
        selectedLocations.push(newLocation);
      });
    }
    // Set Default Images
    let heroPath = '';
    if (experience.images.heroPath !== null) {
      heroPath = experience.images.heroPath;
    }
    let thumbPath = '';
    if (experience.images.thumbPath !== null) {
      thumbPath = experience.images.thumbPath;
    }
    this.setState({
      selectedLocations,
      promoLocations: experience.locations,
      title: experience.title,
      strategy: experience.strategy,
      description: experience.description,
      promoId: experience.id,
      checked: experience.active,
      heroPath,
      thumbPath,
    });

    let rewards = experience.rewards;

    let levelOneCriteriaCount = 1;
    let levelTwoCriteriaCount = 1;
    let levelThreeCriteriaCount = 1;
    let levelFourCriteriaCount = 1;
    let levelFiveCriteriaCount = 1;

    rewards.forEach((reward, i) => {
      let level = {
        value: reward.id,
        label: reward.title,
      };
      if (i === 0) {
        let defaultCriteria = this.state.levelOneCriteria;
        reward.criteria.forEach((criteria, i) => {
          defaultCriteria[i] = criteria;
        });
        levelOneCriteriaCount = defaultCriteria.length + 1;
        this.setState({
          levelOneCriteria: defaultCriteria,
          lockedOne: reward.locked,
          levelOne: level,
          quantityOne: reward.quantity,
        });
      } else if (i === 1) {
        let defaultCriteria = this.state.levelTwoCriteria;
        reward.criteria.forEach((criteria, i) => {
          defaultCriteria[i] = criteria;
        });
        levelTwoCriteriaCount = defaultCriteria.length + 1;
        this.setState({
          levelTwoCriteria: defaultCriteria,
          lockedTwo: reward.locked,
          levelTwo: level,
          quantityTwo: reward.quantity,
        });
      } else if (i === 2) {
        let defaultCriteria = this.state.levelThreeCriteria;
        reward.criteria.forEach((criteria, i) => {
          defaultCriteria[i] = criteria;
        });
        levelThreeCriteriaCount = defaultCriteria.length + 1;
        this.setState({
          levelThreeCriteria: defaultCriteria,
          lockedThree: reward.locked,
          levelThree: level,
          quantityThree: reward.quantity,
        });
      } else if (i === 3) {
        let defaultCriteria = this.state.levelFourCriteria;
        reward.criteria.forEach((criteria, i) => {
          defaultCriteria[i] = criteria;
        });
        levelFourCriteriaCount = defaultCriteria.length + 1;
        this.setState({
          levelFourCriteria: defaultCriteria,
          lockedFour: reward.locked,
          levelFour: level,
          quantityFour: reward.quantity,
        });
      } else if (i === 4) {
        let defaultCriteria = this.state.levelFiveCriteria;
        reward.criteria.forEach((criteria, i) => {
          defaultCriteria[i] = criteria;
        });
        levelFiveCriteriaCount = defaultCriteria.length + 1;
        this.setState({
          levelFiveCriteria: defaultCriteria,
          lockedFive: reward.locked,
          levelFive: level,
          quantityFive: reward.quantity,
        });
      }
    });

    // Set Num of Criteria
    this.setState({
      levelCriteriaCounts: {
        levelOneCriteriaCount,
        levelTwoCriteriaCount,
        levelThreeCriteriaCount,
        levelFourCriteriaCount,
        levelFiveCriteriaCount,
      },
    });
  }

  getExperienceTags(experience) {
    const experienceTypes = [];
    if (experience.tagsExperienceTypes !== undefined) {
      experience.tagsExperienceTypes.forEach((tag) => {
        experienceTypes.push({
          value: tag,
          label: tag,
        });
      });
    }
    return (
      <div>
        {experienceTypes &&
          experienceTypes.map((tag, index) => (
            <span className="experienceTag">{tag.label}</span>
          ))}
      </div>
    );
  }

  getExperiences(experiences) {
    let allExperiences = [];
    // experiences.forEach((experience) => {
    //   allExperiences.push(experience);
    // });
    this.props.demo.demoPromos.forEach((experience) => {
      allExperiences.push(experience);
    });
    return (
      <div id="experiences">
        {allExperiences.map((experience, key) => {
          return (
            <div
              onClick={() => {
                this.setActiveExperience(experience);
              }}
              className="galleryExperience"
            >
              <div>
                {this.getPromoImage(experience.images.heroPath)}
                <div className="promoBody">
                  <span
                    className="promo-reward"
                    style={{ color: 'white' }}
                  >
                    {experience.title}
                  </span>
                  <span
                    className="promo-criteria"
                    style={{ color: 'white' }}
                  >
                    {experience.description}
                  </span>

                  <div style={{ marginBottom: '20px' }}>
                    {this.getExperienceTags(experience)}
                  </div>
                  {/* {this.getPreviewRewards()} */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  getPreview(mode) {
    console.log('mode', mode);
    let heroPath = this.state.heroPath;
    let title = this.state.title;
    let description = this.state.description;

    const startTimestamp = moment(this.state.selectedStartDay).unix();
    const startDateString = moment
      .unix(startTimestamp)
      .format('MM-DD-YYYY');
    const endTimestamp = moment(this.state.selectedEndDay).unix();
    const endDateString = moment
      .unix(endTimestamp)
      .format('MM-DD-YYYY');

    if (this.state.criteriaDetailView === true) {
      return (
        <div id="previewArea">
          <div className="promo criteriaDetails">
            {this.state.activeSet === true ? (
              <div>
                <div className="right">
                  <MaterialIcon
                    icon="close"
                    size={25}
                    color="white"
                    onClick={() => {
                      this.setCriteriaDetailView(false);
                    }}
                  />
                </div>
                <div
                  style={{
                    clear: 'both',
                    display: 'block',
                    padding: '10px',
                  }}
                >
                  <span style={{ color: 'white', fontSize: '18px' }}>
                    {this.state.activeCriteriaPreview.text}
                  </span>
                  <hr />
                  <div style={{ minHeight: '350px' }}>
                    {this.state.activeCriteriaPreview.guidelines !==
                      '' && (
                      <span style={{ color: 'white' }}>
                        {this.state.activeCriteriaPreview.guidelines}
                      </span>
                    )}
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      background: 'white',
                      padding: '10px',
                      borderRadius: '5px',
                      color: '#f21d59',
                    }}
                  >
                    Get Validated!
                  </span>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      );
    } else if (mode === 3) {
      return (
        <div id="previewArea">
          <div>{this.state.strategy}</div>
        </div>
      );
    } else if (mode === 1) {
      return (
        <div id="previewArea">
          {this.state.activeSet === true ? (
            <div>
              <div className="promo">
                {this.getPromoImage(heroPath)}
                <div className="promoBody">
                  <span className="promo-reward">{title}</span>
                  <span className="promo-criteria">
                    {description}
                  </span>
                  <span className="promo-criteria">
                    {this.getPreviewSchedule()}
                  </span>
                  <div style={{ marginBottom: '20px' }}>
                    {this.state.experienceType &&
                      this.state.experienceType.map((tag, index) => (
                        <span className="experienceTag">
                          {tag.label}
                        </span>
                      ))}
                  </div>
                  {this.getPreviewRewards()}
                </div>
              </div>
              {/* <span className="previewerButton">Import</span> */}
            </div>
          ) : (
            <div className="middleMe">
              <center />
              <span style={{ color: 'white' }}>
                Choose an Experience to start exploring!
              </span>
            </div>
          )}
        </div>
      );
    } else if (mode === 2) {
      return (
        <div id="previewArea">
          <div>
            <Row className="oddeven">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <div className="full-height left">
                  <img
                    src={this.state.thumbPath}
                    className="thumbnail"
                  />
                </div>

                <span className="location-title">
                  {this.state.title}
                </span>
                <span className="address">
                  {this.state.description}
                </span>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }

  getSaveBar() {
    return (
      <div id="saveBar">
        <a
          onClick={() => {
            this.createPromo();
          }}
        >
          <span className="saveButton">Save</span>
        </a>
        <span>{this.state.title}</span>
        <Link
          to={{
            pathname: '/vendor/experiences',
          }}
        >
          <span className="cancelButton">Cancel</span>
        </Link>
      </div>
    );
  }

  getAppContent(rewards) {
    return (
      <div style={{ height: '100%', backgroundColor: '#353535' }}>
        {/* <div className="vendorLeftNav">
          <div style={{ padding: '10px' }}>
            <span style={{ fontWeight: '600', color: 'white' }}>
              SETUP
            </span>
          </div>

          {this.getLeftMenu()}
        </div> */}
        {this.getSection(
          this.state.mode,
          this.state.activeLevel,
          this.state.levelCriteriaCounts,
          rewards,
        )}
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <div id="previewer">
          <div id="previewTabs">
            <span style={{ float: 'left' }}>PREVIEW</span>
            <a
              className={
                this.state.previewMode === 1
                  ? 'activePreviewTab'
                  : 'previewTab'
              }
              onClick={() => {
                this.setPreviewMode(1);
              }}
            >
              <span className="left">Details</span>
            </a>
            <a
              className={
                this.state.previewMode === 2
                  ? 'activePreviewTab'
                  : 'previewTab'
              }
              onClick={() => {
                this.setPreviewMode(2);
              }}
            >
              <span className="left">List</span>
            </a>
          </div>
          <div id="previewTabs" style={{ float: 'right' }}>
            <a
              className={
                this.state.previewMode === 3
                  ? 'activePreviewTab'
                  : 'previewTab'
              }
              onClick={() => {
                this.setPreviewMode(3);
              }}
            >
              <span className="right">Strategy</span>
            </a>
          </div>
          {this.getPreview(this.state.previewMode)}
        </div>

        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} body{display: grid} .inputLabel {font-weight: 400;} .container {max-width: 100%; !important} .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }

  openRewardModal() {
    this.setState({ rewardModalVisible: true });
  }

  closeRewardModal() {
    this.setState({ rewardModalVisible: false });
  }

  openLocationModal() {
    this.setState({ locationModalVisible: true });
  }

  closeLocationModal() {
    this.setState({ locationModalVisible: false });
  }

  getVendorRewards() {
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={11} md={11} sm={11} lg={11} className="left">
                  <span className="label">Reward</span>
                </Col>
                {/* <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Promos</span>
                </Col>
                <Col
                  xs={2}
                  md={2}
                  sm={2}
                  lg={2}
                  className="text-center"
                >
                  <span className="label">Available</span>
                </Col>
                <Col
                  xs={1}
                  md={1}
                  sm={1}
                  lg={1}
                  className="text-center"
                >
                  <span className="label">Earned</span>
                </Col>
                <Col
                  xs={1}
                  md={1}
                  sm={1}
                  lg={1}
                  className="text-center"
                >
                  <span className="label">Redeemeed</span>
                </Col> */}
                <Col xs={1} md={1} sm={1} lg={1} className="left" />
              </div>
            </li>
            {this.props.vendorRewards.map((reward, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={11}
                    md={11}
                    sm={11}
                    lg={11}
                    key={'key' + index}
                  >
                    {/* <Link
                      to={{
                        pathname: '/vendor/reward/' + reward.id,
                      }}
                    > */}
                    {reward.title}
                    {/* </Link> */}
                  </Col>
                  {/* <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {this.getPromosIncluded(reward.id)}
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    sm={2}
                    lg={2}
                    className="text-center"
                  >
                    {this.getAvailable(reward.id)}
                  </Col>
                  <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="text-center"
                  >
                    {this.getEarned(reward.id)}
                  </Col>
                  <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="text-center"
                  >
                    {this.getRedeemed(reward.id)}
                  </Col> */}
                  <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="text-right"
                  >
                    {this.getEditButton(reward)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getLocationStatus(location) {
    let newLocations = this.state.selectedLocations;
    const locationCheck = query('locationId')
      .is(location.locationId)
      .on(newLocations);
    if (locationCheck[0] !== undefined) {
      return 'adminlistitem selectedLocationBack';
    } else {
      return 'adminlistitem';
    }
  }

  getVendorLocations() {
    let locations = [];
    this.props.vendor.locations.forEach((location, i) => {
      const locationDetails = query('locationId')
        .is(location)
        .on(this.props.locations);
      locations.push(locationDetails[0]);
    });
    return (
      <div className="section">
        <div className="fullwidth">
          <ul className="no-padding no-margin">
            {locations.map((location, index) => (
              <li
                className={this.getLocationStatus(location)}
                key={'key' + index}
                onClick={(setLocation) => {
                  this.setLocation(location, 1);
                }}
              >
                <div className="fullwidth">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    key={'key' + index}
                    className="no-padding"
                  >
                    <Row>
                      <Col
                        xs={4}
                        md={4}
                        sm={4}
                        lg={4}
                        className="no-padding thumbnailCol"
                      >
                        {this.getImage(
                          'thumb',
                          location,
                          this.props.locations,
                        )}
                      </Col>
                      <Col xs={8} md={8} sm={8} lg={8}>
                        <span className="location-title">
                          {location.locationTitle}
                        </span>
                        <span className="address">
                          {location.address.street}
                        </span>
                        <span className="address">
                          {location.address.city}{' '}
                          {location.address.state},{' '}
                          {location.address.zip}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  setLocation(selectedLocation) {
    let newLocations = this.state.selectedLocations;

    const locationCheck = query('locationId')
      .is(selectedLocation.locationId)
      .on(newLocations);

    if (locationCheck[0] === undefined) {
      const today = new Date();
      let location = {
        approved: true,
        approverId: 'rX8stcgqvqXvrJVJS3FAiqvRwcG3',
        locationId: selectedLocation.locationId,
        locationTitle: selectedLocation.locationTitle,
        approvalDate: today,
        locationThumb: selectedLocation.locationThumb,
        address: selectedLocation.address,
        settings: selectedLocation.settings,
      };
      newLocations.push(location);
    } else {
      newLocations = newLocations.filter(function (obj) {
        return obj.locationId !== selectedLocation.locationId;
      });
    }

    // mode 1 = add mode 2 = remove

    this.setState({
      selectedLocations: newLocations,
    });

    console.log('currentlocations', newLocations);
  }

  getEditButton(reward) {
    return (
      <a
        onClick={() => {
          this.setEditRewardFormActive(true, reward);
        }}
      >
        <MaterialIcon icon="edit" size={25} color="red" />
      </a>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    let rewards = [];
    this.props.vendorRewards.forEach((event, i) => {
      rewards.push({
        value: event.id,
        label: event.title,
      });
    });
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <div className="appPickerDrawer">
            <div
              className="sidebar-logo"
              style={{ height: '80px', margin: '0px 0px 25px 0px' }}
            >
              <div className=" left">
                <center>
                  <img
                    src={logo}
                    style={{
                      maxWidth: '160px',
                      margin: '0px 0px 6px 0px',
                    }}
                  />
                  <h5>Experiences</h5>
                </center>
              </div>
              <span className="right">
                <MaterialIcon
                  icon="close"
                  size={30}
                  color="rgb(238 1 88)"
                  onClick={() => {
                    this.showPicker(false);
                  }}
                />
              </span>
            </div>

            <NavLink
              to={{
                pathname: '/vendor/home',
              }}
              activeClassName="activeMemuItem"
              style={{ float: 'left' }}
            >
              <span className="appTitle">Home Base</span>
            </NavLink>
            {/* <div className="appList"> */}
            <div style={{ margin: '100px 0px 0px 0px' }}>
              <span className="appListCategory">Tools</span>
              <NavLink
                to={{
                  pathname: '/vendor/experiences',
                }}
                activeClassName="activeMemuItem"
              >
                <span
                  className="appTitle"
                  style={{ fontWeight: '600' }}
                >
                  Workshop
                </span>
              </NavLink>
              <NavLink
                to={{
                  pathname: '/vendor/insights',
                }}
                activeClassName="activeMemuItem"
              >
                <span
                  className="appTitle"
                  style={{ fontWeight: '600' }}
                >
                  Insights
                </span>
              </NavLink>
              <NavLink
                to={{
                  pathname: '/vendor/validator',
                }}
                activeClassName="activeMemuItem"
              >
                <span
                  className="appTitle"
                  style={{ fontWeight: '600' }}
                >
                  Validator
                </span>
              </NavLink>
            </div>
            <div style={{ margin: '60px 0px 0px 0px' }}>
              <span className="appListCategory">MANAGE</span>
              <NavLink
                to={{
                  pathname: '/vendor/locations',
                }}
                activeClassName="activeMemuItem"
              >
                <span className="appTitle">Locations</span>
              </NavLink>
              <NavLink
                to={{
                  pathname: '/vendor/users',
                }}
                activeClassName="activeMemuItem"
              >
                <span className="appTitle">Users</span>
              </NavLink>
              <NavLink
                to={{
                  pathname: '/vendor/account',
                }}
                activeClassName="activeMemuItem"
              >
                <span className="appTitle">Account</span>
              </NavLink>
            </div>
          </div>
        )}

        {this.getAppContent(rewards)}

        <style
          dangerouslySetInnerHTML={{
            __html:
              'body:{display: grid} #analytics{height: 100%} .maincontent{margin-top: 0px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customEvent: state.customEvent.customEvent,
    locations: state.locations.locations,
    events: state.events.events,
    packages: state.packages.packages,
    profile: state.profile.profile,
    packageBundle: state.newPackage,
    vendor: state.vendor.vendor,
    vendorEvents: state.vendorEvents.events,
    vendorPromos: state.vendorPromos.promos,
    vendorRewards: state.vendorRewards.rewards,
    tags: state.tags.tags,
    demo: state.demo,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorPromos: (id) => {
      dispatch(getVendorPromos(id));
    },
    getDemos: (id) => {
      dispatch(getDemos(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorGallery);
