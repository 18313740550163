import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as auth from '../../config/auth';
import MaterialIcon from 'material-icons-react';

// Images
import logo from '../../images/TrevelLogoText.svg';
import { clearProfile } from '../../sagas/getProfileSaga/reducer';
// Reducers
import { clearUser, setUser } from '../../sagas/loginSaga/reducer';
// Components
import DrawerToggleButton from '../DrawerToggleButton';
import './Toolbar.css';

export class ToolBar extends React.Component {
  constructor() {
    super();
    this.logOut = this.logOut.bind(this);
    this.logIn = this.logIn.bind(this);
    this.signUp = this.signUp.bind(this);
  }

  UNSAFE_componentWillMount() {}

  logOut() {
    const user = {
      id: null,
      userName: null,
      email: null,
      authenticated: false,
    };
    auth.doSignOut();
    this.props.clearProfile();
    // this.props.clearUser();
  }

  logIn() {
    this.props.history.push('/login');
  }

  signUp() {
    this.props.history.push('/signup?source=topbar');
  }

  navitems() {
    if (this.props.user.authenticated === true) {
      return (
        <div>
          <a
            href="/profile"
            className="toolbar_navigation-item profilelink"
          >
            Profile
          </a>
          <a
            href="/articles"
            className="toolbar_navigation-item articlelink"
          >
            Articles
          </a>

          {/* <a href="/custompackagehome" className="toolbar_navigation-item">
            Create Your Night
          </a> */}
          <a
            href="/packages"
            className="toolbar_navigation-item packageslink"
          >
            Create New Experience
          </a>
          <a
            href="/events/upcoming"
            className="toolbar_navigation-item upcominglink"
          >
            Upcoming Experiences
          </a>
        </div>
      );
    } else {
      return (
        <div>
          {/* <Link
            to={{
              pathname: '/articles',
            }}
            className="toolbar_navigation-item articleslink"
          >
            Articles
          </Link> */}
          {/* <Link
            to={{
              pathname: '/contactus',
            }}
            className="toolbar_navigation-item contactlink"
          >
            Contact
          </Link> */}
          {/* <Link
            to={{
              pathname: '/aboutus',
            }}
            className="toolbar_navigation-item aboutlink"
          >
            Team
          </Link> */}
          {/* <Link
            to={{
              pathname: '/',
            }}
            className="toolbar_navigation-item aboutlink"
          >
            About
          </Link> */}
          {/* <a
            href="/ourservices"
            className="toolbar_navigation-item serviceslink"
          >
            Services
          </a> */}
        </div>
      );
    }
  }

  authLink() {
    if (this.props.user.authenticated === true) {
      return (
        <div>
          <a onClick={this.logOut} className="nav-logout">
            Log Out
          </a>
        </div>
      );
    } else {
      return (
        <div>
          {/* <a onClick={this.logIn} className='nav-login'> */}
          <Link
            to={{
              pathname: '/experiences',
            }}
            style={{ marginRight: '10px', color: '#f21d59' }}
          >
            <span className="nav-business">Get Listed</span>
          </Link>
          <a href="/login" className="nav-login">
            Sign In
          </a>
          <a onClick={this.signUp} className="nav-signup">
            Create Free Account
          </a>
        </div>
      );
    }
  }

  render() {
    const navitems = this.navitems();
    const authLink = this.authLink();
    return (
      <header className="toolbar">
        <nav className="toolbar__navigation" id="full-nav">
          <div className="toolbar__logo">
            <Link
              to={{
                pathname: '/',
              }}
            >
              <img src={logo} />
            </Link>
          </div>
          <div
            className="toolbar_navigation_authlinks"
            style={{ marginLeft: '10px' }}
          >
            <Link
              to={{
                pathname: '/about',
              }}
            >
              <MaterialIcon icon="help" size={35} color="#f21d59" />
            </Link>
          </div>
          <div className="toolbar_navigation-items">{navitems}</div>
          <div className="toolbar_navigation_authlinks">
            {authLink}
          </div>
        </nav>

        <nav className="toolbar__navigation" id="mobile-nav">
          <Container>
            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <div className="toolbar__toggle-button">
                  <DrawerToggleButton
                    click={this.props.drawerClickHandler}
                  />
                </div>
              </Col>
              <Col xs={8} md={8} sm={8} lg={8}>
                <center>
                  <div className="toolbar__logo">
                    <Link
                      to={{
                        pathname: '/',
                      }}
                    >
                      <img src={logo} />
                    </Link>
                  </div>
                </center>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <div
                  className="toolbar_navigation_authlinks"
                  style={{ marginLeft: '10px' }}
                >
                  <Link
                    to={{
                      pathname: '/about',
                    }}
                  >
                    <MaterialIcon
                      icon="help"
                      size={35}
                      color="#f21d59"
                    />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </nav>
        <div id="mobile-authlinks">
          <div className="wrapper">
            <Container>
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  <div className="toolbar_navigation_authlinks_mobile">
                    {authLink}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
    clearProfile: (user) => {
      dispatch(clearProfile(user));
    },
    clearUser: (user) => {
      dispatch(clearUser(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolBar);

// Drop Down
{
  /* <div className="eventDropDown"> */
}

{
  /* <div className="dropdown-content">
          <li>
            <a href="/packages">Romantic</a>
          </li>
          <li>
            <a href="/packages ">Adventurous</a>
          </li>
          <li>
            <a href="/packages ">Category</a>
          </li>
        </div> */
}
{
  /* </div> */
}
