import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
// Styles
import './ArticleListItem.css';

class RelatedArticleListItem extends Component {
  getPostDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getTruncatedBody(article) {
    if (article.teaser !== undefined) {
      return article.teaser;
    } else {
      const length = 130;
      const truncatedString =
        article.body.substring(0, length) + '...';
      return truncatedString;
    }
  }

  render() {
    return (
      <Row>
        {this.props.articles.map((article, index) => (
          <Col xs={12} md={6} sm={12} lg={6} key={'key' + index}>
            <Link
              to={{
                pathname: '/article/' + article.slug,
              }}
            >
              <div>
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={article.hero}
                    alt="articleHero"
                  />
                  <CardBody>
                    <CardTitle>{article.title}</CardTitle>
                    {/* <small className="text-muted right">
                      {this.getPostDate(article.postdate)}
                    </small> */}
                    <p className="author">
                      {/* {article.author} - {this.getPostDate(article.postdate)} */}
                      {article.author}
                    </p>
                    <CardText>
                      {this.getTruncatedBody(article)}
                    </CardText>
                    <CardText />
                  </CardBody>
                </Card>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    );
  }
}

export default RelatedArticleListItem;

// Article Fields Available
// ************************
// article.author
// article.authorId
// article.body
// article.hero
// article.id
// article.postdate
// article.readMore (This is the read more URL)
// article.slug (This is what is used for URL of article)
// article.title
