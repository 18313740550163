import React from 'react';
import { connect } from 'react-redux';
// Components
import VendorSignUpForm from '../components/VendorSignUpForm';
import firebase from '../config/firebase';
// Reducers
import { setNewUser, setUser } from '../sagas/loginSaga/reducer';
import {
  setNewVendor,
  setVendorLogin,
} from '../sagas/loginSaga/reducer';
import { getUauth } from '../sagas/unauthenticatedSaga/reducer';

export class VendorSignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loadForm: false,
      fromClaimLink: false,
    };
  }

  UNSAFE_componentWillMount = () => {
    document.title = 'Trevel';

    let bundle = {
      claimId: this.props.unauth.claimId,
      vendorId: this.props.unauth.vendorId,
      claimLink: this.props.unauth.claimLink,
      guide: this.props.unauth.guide,
      vendorType: this.props.unauth.vendorType,
      vendorSignup: true,
    };
    this.props.getUauth(bundle);

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.locationId !== undefined
    ) {
      this.setState({ fromClaimLink: true });
    }

    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        // const token = result.credential.accessToken;
        if (result.credential) {
          let locationId = null;
          if (
            this.props.location.state !== undefined &&
            this.props.location.state.locationId !== undefined
          ) {
            locationId = this.props.location.state.locationId;
          }
          const user = result.user;
          // console.log("fb login result", result);
          if (result.additionalUserInfo.isNewUser === true) {
            const user = {
              id: result.user.uid,
              userName: result.user.displayName,
              email: result.user.email,
              authenticated: true,
              newuser: true,
              locationId: locationId,
            };
            this.props.setNewVendor(user);
          } else {
            const user = {
              id: result.user.uid,
              userName: result.user.displayName,
              email: result.user.email,
              authenticated: true,
              newuser: false,
              locationId: locationId,
            };
            // console.log("fb login user", user);
            this.props.setNewVendor(user);
          }
        } else {
          this.setState({ loadForm: true });
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        // ...
      });
  };

  loadForm() {
    if (this.state.loadForm === true) {
      let locationId = null;
      if (
        this.props.location.state !== undefined &&
        this.props.location.state.locationId !== undefined
      ) {
        locationId = this.props.location.state.locationId;
      }

      return (
        <div className="signup-box">
          <VendorSignUpForm
            locationId={locationId}
            fromClaimLink={this.state.fromClaimLink}
            history={this.props.history}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div id="login">
        <div className="signup-box-container">{this.loadForm()}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'header { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer-container{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    unauth: state.unauth,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
    setNewUser: (user) => {
      dispatch(setNewUser(user));
    },
    setNewVendor: (user) => {
      dispatch(setNewVendor(user));
    },
    setVendorLogin: (user) => {
      dispatch(setVendorLogin(user));
    },
    getUauth: (bundle) => {
      dispatch(getUauth(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorSignUp);
