import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import TitleBar from '../../components/TitleBar';
import {
  inviteAccept,
  inviteEdit,
  inviteReject,
} from '../../sagas/invitationSaga/reducer';

const query = require('array-query');

export class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mode: 0,
      collapse: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
  }

  componentDidMount() {
    // const activeInvitationsArray = [];
    // let senderArray = [];
    // let receiverArray = [];
    // let activeArray = [];
    // this.props.events.forEach(event => {
    //   console.log("eventCheck", event);
    //   if (event.users[this.props.user.id].inviteAction === false) {
    //     activeInvitationsArray.push(event.invitations[this.props.user.id]);
    //   }
    //   console.log(
    //     "eventCheck - activeInvitationsArray",
    //     activeInvitationsArray
    //   );
    // });
    // activeInvitationsArray.forEach(event => {
    //   if (event.status === 0) {
    //     activeArray.push(event);
    //   }
    // });
    // this.setState({
    //   loading: false,
    //   activeArray,
    //   senderArray
    // });
  }

  setEvents(events, userId) {
    const planned = [];
    const unplanned = [];
    const finished = [];
    let setEvents = {};

    if (events !== undefined) {
      events.forEach((event) => {
        if (
          (event.users[userId].finished === true &&
            event.users[userId].delete !== true) ||
          (event.users[userId].canceled === true &&
            event.users[userId].delete !== true)
        ) {
          if (event.status !== 11) {
            event.mode = '6';
            finished.push(event);
          } else if (
            event.status === 11 &&
            event.users[this.props.user.id].editor === true
          ) {
            event.mode = '6';
            finished.push(event);
          }
        } else if (
          event.users[userId].finished !== true &&
          event.status !== 9
        ) {
          if (event.status === 0) {
            event.mode = '1';
            planned.push(event);
          } else if (
            (event.users[this.props.user.id].editor === true &&
              event.status === 2) ||
            event.status === 3 ||
            event.status === 4 ||
            event.status === 11
          ) {
            event.mode = '0';
            unplanned.push(event);
          }
        }
      });
      let plannedCount = planned.length;
      let unplannedCount = unplanned.length;
      const activeCountArray = [];
      const activeInvitationsArray = [];
      events.forEach((event) => {
        if (
          event.users[this.props.user.id].inviteAction !==
            undefined &&
          event.users[this.props.user.id].inviteAction === false
        ) {
          activeInvitationsArray.push(event);
        }
      });
      activeInvitationsArray.forEach((event) => {
        if (
          event.invitations[this.props.user.id].status === 0 ||
          event.invitations[this.props.user.id].status === 9
        ) {
          activeCountArray.push(event);
        }
      });
      const activeCount = activeCountArray.length;

      setEvents = {
        plannedCount,
        unplannedCount,
        inviteCount: activeCount,
        invites: activeCountArray,
        finished,
      };

      return setEvents;
    }
  }

  getFooter() {
    return (
      <Container>
        <Row className="no-margin">
          <Col
            xs={12}
            md={12}
            sm={12}
            lg={12}
            className="no-padding text-center"
          >
            <Link
              to={{
                pathname: '/events/home',
              }}
            >
              <span className="footer-link-withborder">
                Plan New Event
              </span>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  toggle(notificationId) {
    const cstate = this.state[notificationId];
    const returnObj = {};
    returnObj[notificationId] = !cstate;
    this.setState(returnObj);
    this.getArrow(notificationId);
  }

  getImage(type, locationStart) {
    let location = locationStart;
    let locations = this.props.alllocations;
    // const locationDetails = query('locationId')
    //   .is(locationStart.locationId)
    //   .on(locations);
    // location = locationDetails[0];
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={locationStart.locationTitle}
        className={className}
      />
    );
  }

  getLocationArray(locations) {
    if (locations.length !== 0) {
      // const locationsArray = [];
      // locations.forEach((location) => {
      //   const thislocation = query('locationId')
      //     .is(location.locationId)
      //     .on(this.props.alllocations);
      //   locationsArray.push(thislocation[0]);
      // });
      return (
        <div>
          {locations.map((location) => (
            <div className="location-info fullwidth">
              <Row>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="no-padding thumbnailCol"
                >
                  <Link
                    to={{
                      pathname: '/location',
                      state: {
                        location: { location },
                        // packageId: { packageId },
                        type: 6,
                      },
                    }}
                  >
                    {this.getImage('thumb', location)}
                  </Link>
                </Col>
                <Col xs={8} md={8} sm={8} lg={8}>
                  <Link
                    to={{
                      pathname: '/location',
                      state: {
                        location: { location },
                        packageId: '',
                        type: 6,
                      },
                    }}
                  >
                    <div>
                      <span className="red">
                        {location.locationTitle}
                      </span>

                      <p className="invite-font">
                        {location.address.street}
                        <br />
                        {location.address.city}{' '}
                        {location.address.state},{' '}
                        {location.address.zip}
                      </p>
                    </div>
                  </Link>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      );
    } else {
      return <div>{/* <p>No Locations</p> */}</div>;
    }
  }

  getArrow(notificationId) {
    if (this.state[notificationId] === false) {
      return (
        <MaterialIcon
          icon="keyboard_arrow_down"
          size={25}
          color="#f21d59"
        />
      );
    } else {
      return (
        <MaterialIcon
          icon="keyboard_arrow_up"
          size={25}
          color="#f21d59"
        />
      );
    }
  }

  getInviteHeader(notification) {
    if (notification.type !== 4) {
      return (
        <div>
          <span className="lightbold invite-font clear">
            You've been invited to an event!
          </span>
          <span className="invite-label invite-font clear">
            With {notification.senderName}{' '}
            {notification.senderLastName}
          </span>
        </div>
      );
    } else {
      return (
        <span className="lightbold invite-font clear">
          You've been invited to make plans with{' '}
          {notification.senderName} {notification.senderLastName}!
        </span>
      );
    }
  }

  displayNotification(notification, mode, user, event) {
    const withNoDigits = notification.eventId.replace(/[0-9]/g, '');
    const toggler = '#' + withNoDigits;
    const divId = withNoDigits;
    const { title, description, scheduledTime } = event;

    const date = this.getPostDate(scheduledTime);

    if (user.id === notification.senderId) {
      return (
        <Card className="inviteCard">
          <CardBody>
            <Container className="no-padding">
              <Row className="no-padding">
                <Col
                  xs={2}
                  md={2}
                  sm={2}
                  lg={2}
                  className="left no-padding"
                >
                  <div className="left">
                    <UserAvatar
                      size="40"
                      name={notification.receiverName}
                      src={notification.receiverPhotoPath}
                    />
                  </div>
                </Col>
                <Col
                  xs={9}
                  md={9}
                  sm={9}
                  lg={9}
                  className="left no-padding"
                >
                  <div className="left">
                    {this.getInviteHeader(notification)}
                  </div>
                </Col>
                <Col
                  xs={1}
                  md={1}
                  sm={1}
                  lg={1}
                  className="left no-padding"
                >
                  <span
                    className="red"
                    id={divId}
                    style={{ marginBottom: '1rem' }}
                  >
                    {this.getArrow(notification.notificationId)}
                  </span>
                </Col>
              </Row>
            </Container>
          </CardBody>
          <UncontrolledCollapse toggler={toggler}>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12} className="left">
                {/* <div className="card-section">
                  {notification.inviteMessage}
                </div> */}
                <div className="card-section">
                  <h2 className="title">{title}</h2>
                  <p className="description">{description}</p>
                </div>
                <div className="card-section">When: {date}</div>
                {this.getLocationArray(notification.locations)}
                <div className="card-section">
                  {this.getActions(notification, date, event)}
                </div>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </Card>
      );
    } else {
      return (
        <Card className="inviteCard">
          <CardBody>
            <Container className="no-padding">
              <Row className="no-padding">
                <Col
                  xs={2}
                  md={2}
                  sm={2}
                  lg={2}
                  className="left no-padding"
                >
                  <div className="left">
                    <UserAvatar
                      size="40"
                      name={notification.senderName}
                      src={notification.profilePhotoPath}
                    />
                  </div>
                </Col>
                <Col
                  xs={9}
                  md={9}
                  sm={9}
                  lg={9}
                  className="left no-padding"
                >
                  <div className="left">
                    {this.getInviteHeader(notification)}
                  </div>
                </Col>
                <Col
                  xs={1}
                  md={1}
                  sm={1}
                  lg={1}
                  className="left no-padding"
                >
                  <span
                    className="red"
                    id={divId}
                    style={{ marginBottom: '1rem' }}
                  >
                    {this.getArrow(notification.notificationId)}
                  </span>
                </Col>
              </Row>
            </Container>
          </CardBody>
          <UncontrolledCollapse toggler={toggler}>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12} className="left">
                {/* <div>
                  <div className="card-section">
                    <span className="invite-font">
                      {notification.inviteMessage}
                    </span>
                  </div>
                </div> */}

                <div className="card-section">
                  <h2 className="invite-title">{title}</h2>
                  <p className="invite-font">{description}</p>
                  <span className="invite-font">{date}</span>
                </div>

                {this.getLocationArray(
                  event.packageDetails.locations,
                )}

                <div className="card-section">
                  {this.getActions(notification, date, event)}
                </div>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </Card>
      );
    }
  }

  acceptInvite(profile, notification, event, connections) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      event,
      senderLastName,
      senderName,
      connections,
    };
    this.props.inviteAccept(bundle);
  }

  rejectInvite(profile, notification, event) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      senderLastName,
      senderName,
      event,
    };
    this.props.inviteReject(bundle);
  }

  acceptConnection(profile, notification, event, connections) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      notificationId,
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      notificationId,
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      senderLastName,
      senderName,
      event,
      connections,
    };
    this.props.inviteEdit(bundle);
  }

  editInvite(profile, notification, event, connections) {
    const { userName, firstName, lastName, userId } = profile;
    const userPhoto = profile.profilePhotoPath;
    const {
      notificationId,
      eventId,
      senderId,
      profilePhotoPath,
      replyMessage,
      senderLastName,
      senderName,
    } = notification;
    const bundle = {
      notificationId,
      eventId,
      senderId,
      senderLastName,
      senderName,
      profilePhotoPath,
      replyMessage: '',
      userName,
      firstName,
      lastName,
      userId,
      userPhoto,
      event,
      connections,
    };
    this.props.inviteEdit(bundle);
  }

  getPostDate(scheduledTime) {
    let atsign = '';
    const dayText = moment
      .utc(scheduledTime.timestamp)
      .format('dddd');
    const dateText = moment.utc(scheduledTime.timestamp).format('Do');
    const monthText = moment
      .utc(scheduledTime.timestamp)
      .format('MMM');
    if (scheduledTime.timestamp !== '') {
      atsign = '@';
    }
    if (scheduledTime.date === '') {
      return 'This event is not scheduled yet.';
    } else {
      return (
        <span>
          {dayText} {monthText} {dateText} {atsign}{' '}
          {scheduledTime.time}
        </span>
      );
    }
  }

  getEditButton(notification, event, connections) {
    if (notification.editable === true) {
      return (
        <div>
          <center>
            <span
              onClick={() =>
                this.editInvite(
                  this.props.profile,
                  notification,
                  event,
                  connections,
                )
              }
              className="red"
            >
              Edit
            </span>
          </center>
        </div>
      );
    } else {
      return <div />;
    }
  }

  getActions(notification, date, event) {
    if (notification.status === 0 || notification.status === 9) {
      if (notification.type !== 4) {
        return (
          <Row>
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              <center>
                <span
                  onClick={() =>
                    this.acceptInvite(
                      this.props.profile,
                      notification,
                      event,
                      this.props.connections,
                    )
                  }
                  className="red"
                >
                  Accept
                </span>
              </center>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              <center>
                <span
                  onClick={() =>
                    this.rejectInvite(
                      this.props.profile,
                      notification,
                      event,
                    )
                  }
                  className="red"
                >
                  Reject
                </span>
              </center>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              <div>
                {this.getEditButton(
                  notification,
                  event,
                  this.props.connections,
                )}
              </div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
              <span
                onClick={() =>
                  this.acceptConnection(
                    this.props.profile,
                    notification,
                    event,
                    this.props.connections,
                  )
                }
                className="red"
              >
                Accept
              </span>
            </Col>
            <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
              <span
                onClick={() =>
                  this.rejectInvite(
                    this.props.profile,
                    notification,
                    event,
                  )
                }
                className="red"
              >
                Reject
              </span>
            </Col>
          </Row>
        );
      }
    } else {
      return (
        <div>
          <span className="right">
            Started
            <br />
            {date}
          </span>
        </div>
      );
    }
  }

  getContent(events, mode, user) {
    if (events.length === 0) {
      return (
        <Container className="pushfifty">
          <Row className="text-center">
            <span>You do not have any new Invitations.</span>
            <div
              style={{
                padding: '15px 10px 10px 10px',
                border: '1px solid lightgrey',
                margin: '0px auto',
                width: '100%',
                marginTop: '100px',
                background: 'white',
                display: 'flex',
                textAlign: 'left',
              }}
            >
              <Col xs={2} md={1} sm={2} lg={1}>
                <div style={{ float: 'left' }}>
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="rgb(238 1 88)"
                  />
                </div>
              </Col>
              <Col xs={10} md={11} sm={10} lg={11}>
                <h4
                  style={{ fontSize: '18px', marginBottom: '10px' }}
                >
                  Your Experiences - Invitations
                </h4>
                <p
                  style={{
                    margin: '0px',
                    padding: '0px 0px 10px 0px',
                  }}
                >
                  Here you will find any Invitations that you haven
                  {"'"}t Accepted or Rejected.
                </p>
              </Col>
            </div>
          </Row>
        </Container>
      );
    } else {
      return (
        <div>
          <div id="notifications">
            <Container>
              <Row className="no-margin no-padding">
                {events.map((event) => (
                  <Col
                    xs={12}
                    md={6}
                    sm={12}
                    lg={6}
                    className="no-padding"
                  >
                    {this.displayNotification(
                      event.invitations[this.props.user.id],
                      mode,
                      user,
                      event,
                    )}
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/home',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Upcoming Experiences
                </span>
              </center>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="right">
                {/* <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      this.props.customEvent,
                    );
                  }}
                /> */}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    let setEvents = this.setEvents(
      this.props.events,
      this.props.user.id,
    );
    return (
      <div id="events">
        {/* <TitleBar title="Social Calendar" backlink="" /> */}
        {this.getTitleBar()}
        <div
          className="adminActionBar"
          style={{ top: '40px !important', width: '100%' }}
        >
          <Row>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <Link
                to={{
                  pathname: '/events/upcoming',
                }}
              >
                <div className="text-center actionButtonInactive actionButton">
                  Upcoming
                  <div className="counterGrey">
                    <span>
                      {setEvents.plannedCount +
                        setEvents.unplannedCount}
                    </span>
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <Link
                to={{
                  pathname: '/events/completed',
                }}
              >
                <div className="text-center actionButton actionButtonInactive">
                  Completed
                  <div className="counterGrey">
                    <span>{setEvents.finished.length}</span>
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <Link
                to={{
                  pathname: '/events/invitations',
                }}
              >
                <div className="text-center borderRight actionButtonActive actionButton noBorderRight ">
                  Invitations
                  <div className="counterGrey">
                    <span>{setEvents.inviteCount}</span>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
        <div className="actionBarPush">
          {this.getContent(
            setEvents.invites,
            this.state.mode,
            this.props.user,
          )}
        </div>
        <div className="footer">{this.getFooter()}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#startBar{background-color: #ef0059;} .adminActionBar {top: 40px !important;} #events{position: relative;}  #headersTop {width: 100% !important;} #headersTopSecondary{width: 100%} #authright {padding-left: 0px !important;}  #mobile-nav { display: none } #authleft {display:none;} #events{position: relative; top: 40px}  #bottomNav{left:0px; width:100%} .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
    user: state.user,
    profile: state.profile.profile,
    alllocations: state.locations.locations,
    events: state.events.events,
    connections: state.connections,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    inviteAccept: (bundle) => {
      dispatch(inviteAccept(bundle));
    },
    inviteReject: (bundle) => {
      dispatch(inviteReject(bundle));
    },
    inviteEdit: (bundle) => {
      dispatch(inviteEdit(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);
