import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* setSpotlightAction(action) {
  const spotlight = action.payload;
  yield put({
    type: actions.SET_SPOTLIGHT,
    payload: {
      packageId: spotlight.packageId,
      title: spotlight.title,
      description: spotlight.description,
      thumbnail: spotlight.thumbnail,
      heroImage: spotlight.heroImage,
      creatorId: spotlight.creatorId,
      creatorImage: spotlight.creatorImage,
      creatorName: spotlight.creatorName,
      category: spotlight.category,
      dailySpotlight: true,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_SPOTLIGHT, setSpotlightAction);
}
