import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import VendorMenu from '../../components/VendorMenu';
// Components
import TitleBar from '../../components/TitleBar';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';

export class VendorSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
    };
    this.showPicker = this.showPicker.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.title = 'Trevel Help';
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  getAppContent(filteredLocations) {
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuNew mobileHide">
          <VendorMenu popupMenu={false} />
        </div>
        <div id="vendorScreenBody">
          <div style={{ padding: '10px', margin: '0px auto' }}>
            <div>
              <div>
                {/* <div>
                <Container>
                  <Row className="greybox">
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <h5>News and Announcements</h5>
                      <span className="bold">
                        Trevel Is Currently In Beta
                      </span>
                      <p>
                        The iPhone version and the Web version of
                        Trevel are currently in Beta. So what does
                        that mean? It means you might find a bug on
                        occasion as users test the tools, and that
                        design is being polished up as we get ready
                        for mass consumption.
                      </p>
                      <hr />
                      <span className="bold">
                        {this.props.vendor.businessName} Joined The
                        Beta
                      </span>
                      <p>
                        Thank you for claiming your location and
                        helping us make Trevel an essential tool for
                        both our users and our location partners. Mike
                        from Trevel will reach out to you with more
                        information soon.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div> */}
                <div className="section">
                  <Container>
                    <Row>
                      <Col xs={12} md={12} sm={12} lg={12}>
                        <h2>Manage Locations</h2>
                        <p>
                          You can edit your location's basic
                          information by clicking on{' '}
                          <span className="bold">Locations</span> in
                          the menu. From there just look for the
                          action links on the right of each location.
                        </p>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div
                          style={{
                            width: '100%',
                            borderTop: '1px solid #c9c9c9',
                            padding: '20px 0px 0px',
                          }}
                        >
                          <h5>Details</h5>
                          <p>
                            The details tab covers most of your
                            basics. This includes: business name,
                            contact info and general description.
                          </p>
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <ul className="supportList">
                          <li>
                            <span className="title">
                              Contact Info
                            </span>
                            <span
                              style={{
                                display: 'block',
                                clear: 'both',
                                color: 'black',
                              }}
                            >
                              This includes your address, website and
                              phone number.
                            </span>
                          </li>

                          <li>
                            <span className="title">Description</span>
                            <span
                              style={{
                                display: 'block',
                                clear: 'both',
                                color: 'black',
                              }}
                            >
                              You can keep it simple, or dive in
                              depth. It{"'"}s up to you.
                            </span>
                          </li>
                        </ul>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12} />
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div
                          style={{
                            width: '100%',
                            borderTop: '1px solid #c9c9c9',
                            padding: '20px 0px 0px',
                          }}
                        >
                          <h5>Your Story</h5>
                          <p>
                            Trevel is here to serve our communities
                            and your business is part of that
                            community. People love to hear the story
                            behind a place rather then just what is on
                            the menu. Use this field to tell your
                            story. Why you love what you do, how you
                            got through the pandemic. Its optional but
                            highly recommended.
                          </p>
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div
                          style={{
                            width: '100%',
                            borderTop: '1px solid #c9c9c9',
                            padding: '20px 0px 0px',
                          }}
                        >
                          <h5>Tags</h5>
                          <p>
                            Tags not only help describe your location,
                            they are critical to how Trevel connects
                            users with their perfect experience. Take
                            time to select the tags that best define
                            your location.
                          </p>
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div
                          style={{
                            width: '100%',
                            borderTop: '1px solid #c9c9c9',
                            padding: '20px 0px 0px',
                          }}
                        >
                          <h5>Images</h5>
                          <p>
                            Currently you can upload a{' '}
                            <span style={{ fontWeight: '600' }}>
                              Banner Image
                            </span>{' '}
                            and a{' '}
                            <span style={{ fontWeight: '600' }}>
                              Thumbnail Image
                            </span>{' '}
                            for each location. We will soon be
                            allowing users to upload additional
                            content as well.
                          </p>
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div
                          style={{
                            width: '100%',
                            borderTop: '1px solid #c9c9c9',
                            padding: '20px 0px 0px',
                          }}
                        >
                          <h5>Hours</h5>
                          <p>
                            Let users know when you are open or
                            closed.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <div style={{ marginTop: '60px' }}>
                    <a name="experiences">
                      <Container>
                        <Row>
                          <Col xs={12} md={12} sm={12} lg={12}>
                            <div
                              style={{
                                display: 'block',
                                margin: '0px auto',
                                maxWidth: '400px',
                                width: '80%',
                                marginBottom: '40px',
                              }}
                            >
                              <img
                                style={{
                                  maxWidth: '300px',
                                }}
                                src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880"
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} md={12} sm={12} lg={12}>
                            <h2>Create an Experience</h2>
                            <p>
                              We built Trevel Experiences to give our
                              partners the freedom to create
                              promotions that work for their unique
                              business. That reaches, incentives, and
                              engages their customers. So as you learn
                              more about Trevel Experiences start to
                              let your imagination go. We are you to
                              help you build something incredible.
                            </p>
                          </Col>
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <div
                              style={{
                                width: '100%',
                                borderTop: '1px solid #c9c9c9',
                                padding: '20px 0px 0px',
                              }}
                            >
                              <h5>Details Tab</h5>
                              <p>
                                The details tab covers most of your
                                basics. This includes: Title,
                                Description, and which Locations are
                                participating in this experience.
                              </p>
                            </div>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <ul className="supportList">
                              <li>
                                <span className="title">Title</span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  The Title will appear in list,
                                  banners, and should entice a user to
                                  click on it.
                                </span>
                              </li>

                              <li>
                                <span className="title">
                                  Description
                                </span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  The description should summarize the
                                  event.
                                </span>
                              </li>

                              <li>
                                <span className="title">
                                  Locations
                                </span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  Select which locations are
                                  participating in this promotion.
                                  Right now you can only select
                                  locations from your account however
                                  we will soon be turning on
                                  "Community" features that will allow
                                  you to connect with other
                                  businesses.
                                </span>
                              </li>
                            </ul>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12} />
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <div
                              style={{
                                width: '100%',
                                borderTop: '1px solid #c9c9c9',
                                padding: '20px 0px 0px',
                              }}
                            >
                              <h5>Rewards Tab</h5>
                              <p>
                                Rewards are Optional when making an
                                Experience but they play an important
                                role in engaging and maintaining new
                                custmers and should be strongly
                                considered.
                              </p>
                            </div>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <h6>Manage Rewards</h6>
                            <p>
                              Before creating your first Experience
                              you will want to set up a couple of
                              Rewards that customers can earn during
                              your Experience.
                            </p>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12} />
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <h6>Choose Rewards</h6>
                            <p>
                              You can have up to 5 Reward Levels that
                              customers can earn during an Experience.
                              To start off, select the Level 1 Reward
                              from the dropdown list.
                            </p>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12} />
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <h6>Reward Setup</h6>
                            <p>
                              Once you have chosen a Reward you will
                              need to set it up. You can click on the
                              Setup icon to the right of each Reward
                              Level to change its settings.
                            </p>

                            <ul className="supportList">
                              <li>
                                <span className="title">
                                  Description
                                </span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  The description field is
                                  pre-populated with the description
                                  you added when you made the Reward,
                                  but you can change it to what ever
                                  you like for this experience.
                                </span>
                              </li>

                              <li>
                                <span className="title">
                                  Quantity
                                </span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  How many of these Rewards are
                                  available?
                                </span>
                              </li>

                              <li>
                                <span className="title">
                                  Locked Until Previous Levels
                                  Complete
                                </span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  This option will only appear on
                                  Reward Levels 2-5. When checked this
                                  level will remain locked to the
                                  customer until they have earned the
                                  previous Reward.
                                </span>
                              </li>
                              <li>
                                <span
                                  className="title"
                                  style={{
                                    fontWeight: '600',
                                  }}
                                >
                                  Criteria
                                </span>
                                <p>
                                  Each Reward can have multiple
                                  criteria that a customer is required
                                  to fullfill in order to earn the
                                  Reward. A criteria can be anything
                                  that can be confirmed by you.{' '}
                                </p>

                                <ul className="supportList">
                                  <li>
                                    <span className="title">
                                      What do they have to do?
                                    </span>
                                    <span
                                      style={{
                                        display: 'block',
                                        clear: 'both',
                                        color: 'black',
                                      }}
                                    >
                                      Examples: Purchase an entree,
                                      Pickup your order, Visit in a
                                      group of 3 or more.
                                    </span>
                                  </li>

                                  <li>
                                    <span className="title">
                                      Restrictions and Guidelines
                                    </span>
                                    <span
                                      style={{
                                        display: 'block',
                                        clear: 'both',
                                        color: 'black',
                                      }}
                                    >
                                      Use this field to describe any
                                      extra restrictions or guidelines
                                      surrounding this criteria. For
                                      example, if the criteria is to
                                      Purchase an Entree, a
                                      restriction might be that the
                                      purchase is $10 or more.
                                    </span>
                                  </li>

                                  <li>
                                    <span className="title">
                                      How Many Times?
                                    </span>
                                    <span
                                      style={{
                                        display: 'block',
                                        clear: 'both',
                                        color: 'black',
                                      }}
                                    >
                                      How many times do they have to
                                      do this criteria to complete it?
                                      If you choose more then one the
                                      customers will be shown a
                                      progress bar.
                                    </span>
                                  </li>
                                  <li>
                                    <span className="title">
                                      Once Per Day
                                    </span>
                                    <span
                                      style={{
                                        display: 'block',
                                        clear: 'both',
                                        color: 'black',
                                      }}
                                    >
                                      When checked, customers can be
                                      validated for this criteria once
                                      per day. If left unchecked
                                      customers can ask to be
                                      validated multiple times.
                                    </span>
                                  </li>
                                  <li>
                                    <span className="title">
                                      Locations
                                    </span>
                                    <span
                                      style={{
                                        display: 'block',
                                        clear: 'both',
                                        color: 'black',
                                      }}
                                    >
                                      At which locations can this
                                      criteria be validated?
                                    </span>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                            <p>
                              Add addition Criteria by clicking on the
                              ADD Icon.
                            </p>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12} />
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <h6>Golden Ticket</h6>
                            <p>
                              The Golden Ticket is an optional kind of
                              Reward that like Willy Wonka's famous
                              promotion, will randomly go to customers
                              when then earn a reward that has the
                              Golden Ticket attached to it.
                            </p>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12} />

                          <Col lg={12} md={12} sm={12} xs={12}>
                            <div
                              style={{
                                width: '100%',
                                borderTop: '1px solid #c9c9c9',
                                padding: '20px 0px 0px',
                              }}
                            >
                              <h5>Tags Tab</h5>
                              <p>
                                Tag your Experiences to help us match
                                them with customers.
                              </p>
                            </div>
                          </Col>

                          <Col lg={6} md={6} sm={12} xs={12}>
                            <ul className="supportList">
                              <li>
                                <span className="title">
                                  Experience Type
                                </span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  What is the main tag for this
                                  Experience?
                                </span>
                              </li>
                            </ul>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12} />

                          <Col lg={12} md={12} sm={12} xs={12}>
                            <div
                              style={{
                                width: '100%',
                                borderTop: '1px solid #c9c9c9',
                                padding: '20px 0px 0px',
                              }}
                            >
                              <h5>Schedule Tab</h5>
                              <p>
                                Scheduling your Experience is
                                optional.
                              </p>
                            </div>
                          </Col>

                          <Col lg={6} md={6} sm={12} xs={12}>
                            <ul className="supportList">
                              <li>
                                <span className="title">
                                  Events Dates
                                </span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  Choose the start and end dates of
                                  the Experience.
                                </span>
                              </li>

                              <li>
                                <span className="title">
                                  Event Time
                                </span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  Check this box and enter a time if
                                  the Experience is different then
                                  your normal hours.
                                </span>
                              </li>
                            </ul>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12} />
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <div
                              style={{
                                width: '100%',
                                borderTop: '1px solid #c9c9c9',
                                padding: '20px 0px 0px',
                              }}
                            >
                              <h5>Images Tab</h5>
                            </div>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <ul className="supportList">
                              <li>
                                <span className="title">
                                  Banner Image
                                </span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  Your Banner image is used as the
                                  main graphic for your experience.
                                </span>
                              </li>

                              <li>
                                <span className="title">
                                  Thumbnail Image
                                </span>
                                <span
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    color: 'black',
                                  }}
                                >
                                  The thumbail will appear in lists.
                                </span>
                              </li>
                            </ul>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <h5>Cropping</h5>
                            <p>
                              After you select an image for either
                              your Banner or Thumbnail image you will
                              be taken to the Cropping Tool. Just drag
                              the box around the area of the image you
                              would like to use and press save.
                            </p>
                          </Col>

                          <Col lg={12} md={12} sm={12} xs={12}>
                            <div
                              style={{
                                width: '100%',
                                borderTop: '1px solid #c9c9c9',
                                padding: '20px 0px 0px',
                              }}
                            >
                              <h5>Previewer</h5>
                            </div>
                            <p>
                              The Preview panel on the right will show
                              you what your Experience will look like
                              for users and will fill in as you craft
                              your new Experience. You can toggle
                              between list and detail view to see how
                              it will look through out the app. You
                              also interact with the Experience as if
                              it is live.
                            </p>
                          </Col>

                          <Col lg={12} md={12} sm={12} xs={12}>
                            <div
                              style={{
                                width: '100%',
                                borderTop: '1px solid #c9c9c9',
                                padding: '20px 0px 0px',
                              }}
                            >
                              <h5>Edit Experiences</h5>
                            </div>
                            <p>
                              To Edit an Experience simply select it
                              {"'"}s name from Create Tool home
                              screen.
                            </p>
                          </Col>
                        </Row>
                        {/* <Row>
                        <Col xs={12} md={12} sm={12} lg={12}>
                          <div
                            style={{
                              width: '100%',
                              borderTop: '1px solid #c9c9c9',
                              padding: '20px 0px 0px',
                            }}
                          >
                            <h2>Manage an Experience</h2>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor
                              incididunt ut labore et dolore magna
                              aliqua.
                            </p>
                          </div>
                        </Col>
                        <Col xs={12} md={12} sm={12} lg={12}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12} sm={12} lg={12}>
                          <div
                            style={{
                              width: '100%',
                              borderTop: '1px solid #c9c9c9',
                              padding: '20px 0px 0px',
                            }}
                          >
                            <h2>Analyze an Experience</h2>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor
                              incididunt ut labore et dolore magna
                              aliqua.
                            </p>
                          </div>
                        </Col>
                        <Col xs={12} md={12} sm={12} lg={12}></Col>
                      </Row> */}
                      </Container>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">{title}</span>

              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.vendor === undefined) {
      return null; // or render a loading icon
    }
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Help')}
        <div className="vendorContent">{this.getAppContent()}</div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorSupport);
