import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './AppContainer';
// import 'bootstrap/dist/css/bootstrap.css';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/lib/integration/react';
import ReactGA from 'react-ga';
import reducers from './reducers';
import createSagaMiddleware from 'redux-saga';
import { ConnectedRouter } from 'connected-react-router';
import sagas from './sagas';

// Components
import ScrollToTop from './components/ScrollToTop';

const history = createBrowserHistory();

ReactGA.initialize('UA-136779720-1');

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: [''],
  version: 1,
};

const gaTrackingMiddleware = (store) => (next) => (action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    // console.log("TRACKED", action.payload.pathname);
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
    trackPage(nextPage);
  }
  return next(action);
};

const trackPage = (page) => {
  ReactGA.pageview(page);
};

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

const persistedReducer = persistReducer(
  persistConfig,
  reducers(history),
);

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history),
      gaTrackingMiddleware,
    ),
  ),
);

let persistor = persistStore(store);

sagaMiddleware.run(sagas);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ScrollToTop history={history}>
          <App history={history} />
        </ScrollToTop>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  // document.getElementById('root'),
);
