import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';

import VendorCommunityMenu from '../../components/VendorCommunityMenu';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';

export class VendorCommunityHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
    };
    this.showPicker = this.showPicker.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    }
    if (this.props.user.authenticated === true) {
      let locationStatus = false;
      let entertainmentStatus = false;
      locationStatus = this.props.vendor.vendorTypes.includes(1);
      entertainmentStatus = this.props.vendor.vendorTypes.includes(2);
      if (locationStatus === true) {
        if (this.props.vendor.locations.length === 0) {
          this.props.history.push('/vendor/locations/add');
        }
      } else if (entertainmentStatus === true) {
        if (this.props.vendor.entertainment.length === 0) {
          this.props.history.push('/vendor/entertainment/add');
        }
      }
    }
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }

  componentDidMount() {
    document.title = 'Trevel Experiences';
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getTitleBar(title, rewards) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={10}
              md={10}
              sm={10}
              xs={10}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">
                <span style={{ color: '#f21d59', fontSize: '22px' }}>
                  Trevel{' '}
                </span>
                Community
              </span>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right mobileHide">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                  className
                />
              </span>
              {rewards.length > 0 ? (
                <Link
                  to={{
                    pathname: '/vendor/validator',
                  }}
                >
                  <span
                    style={{
                      padding: '0px 10px 0px 0px',
                      float: 'right',
                      top: '-2px',
                      display: 'block',
                      position: 'relative',
                    }}
                  >
                    <MaterialIcon
                      icon="notifications_active"
                      size={35}
                      color="rgb(242 29 89)"
                    />
                  </span>
                </Link>
              ) : (
                <div />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getAppContent() {
    let entertainment = '';
    if (this.props.vendor.entertainment[0] !== undefined) {
      entertainment = this.props.vendor.entertainment[0];
    }
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuCommunityNew mobileHide">
          <VendorCommunityMenu popupMenu={false} />
        </div>
        <div id="vendorScreenBodyCommunity">
          <div className="section">
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <h3>Welcome!</h3>
              The Trevel Community is under a bit of construction,
              please check back soon!
            </div>
          </div>
        </div>
      </div>
    );
  }

  getRewards(vendorAwarded) {
    const awardArray = [];
    if (vendorAwarded !== undefined) {
      vendorAwarded.forEach((award) => {
        if (award.validatorMode === true) {
          awardArray.push(award);
        }
      });
    }
    return awardArray;
  }

  render() {
    if (this.props.vendor === undefined) {
      return null; // or render a loading icon
    }
    let rewards = this.getRewards(this.props.vendorAwarded);
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorCommunityMenu
            showPicker={this.showPicker}
            popupMenu={true}
          />
        )}
        {this.getTitleBar('Trevel Community', rewards)}
        <div className="vendorContent">{this.getAppContent()}</div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
    user: state.user,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    vendorPromos: state.vendorPromos.promos,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorCommunityHome);
