import React from 'react';
import { connect } from 'react-redux';

export class TopicStartEvent extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div id="helpTopic">
        <div className="wizard-section-border noTopPadding">
          <h6>Start An Event</h6>
          <p>
            After a Social is accepted, a button will appear that
            allows you to start your Social.
          </p>
          <p>
            Starting your event is important because it leads to more
            in-app features that help you navigate through your
            Social. These include navigational features, ride-share
            features, and special promotions that will save you money.
          </p>
          {/* <Card>
            <a id='inviteNow' className='red helpTopic'>
              Invite Someone Now
            </a>
            <UncontrolledCollapse toggler='#inviteNow'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card>
          <Card>
            <a id='inviteLater' className='red helpTopic'>
              Invite Someone Later
            </a>
            <UncontrolledCollapse toggler='#inviteLater'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {};
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
    user: state.user,
    events: state.events.events,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicStartEvent);
