import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import VendorMenu from '../../components/VendorMenu';
import VendorLocationListItemEdit from '../../components/VendorLocationListItemEdit';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';
import { clearUauth } from '../../sagas/unauthenticatedSaga/reducer';

const KEYS_TO_FILTERS = ['locationTitle', 'website'];

const query = require('array-query');

var options = {
  enableHighAccuracy: true,
  timeout: 10,
  maximumAge: 0,
};
function success(pos) {
  console.log('suceess!');
  var crd = pos.coords;

  console.log('Your current position is:');
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);
}

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

export class VendorLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
      searchTerm: '',
      loading: true,
      promos: [],
      vendorLocations: [],
    };
    this.showPicker = this.showPicker.bind(this);
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    document.title = 'Locations';
    this.props.clearUauth();
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }

  componentDidMount() {
    let vendorLocations = this.getLocations();
    this.setState({
      vendorLocations,
      loading: false,
    });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getLocations() {
    const docArray = [];
    this.props.vendor.locations.forEach((location) => {
      if (this.props.vendor.vendorId !== 'KZry0625YQCjZV99ZZh1') {
        const docDetails = query('locationId')
          .is(location)
          .on(this.props.alllocations);
        if (docDetails[0] !== undefined) {
          docArray.push(docDetails[0]);
        }
      } else {
        const docDetails = query('locationId')
          .is(location)
          .on(this.props.demo.demoLocations);
        if (docDetails[0] !== undefined) {
          docArray.push(docDetails[0]);
        }
      }
    });
    return docArray;
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">{title}</span>

              <span className="helpButton mobileHide right">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right  mobileHide">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>

              <div className="saveButtons mobileHide">
                <Link
                  to={{
                    pathname: '/vendor/locations/add',
                  }}
                  className="right"
                >
                  <span className="saveButton">New Location</span>
                </Link>
              </div>

              <div className="right mobileShow">
                <Link
                  to={{
                    pathname: '/vendor/locations/add',
                  }}
                  className="right"
                >
                  <span className="saveButton">New Location</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getAppContent(filteredLocations) {
    console.log('filteredLocations', filteredLocations);
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuNew mobileHide">
          <VendorMenu popupMenu={false} />
        </div>
        <div id="vendorScreenBody">
          <div style={{ padding: '10px', margin: '0px auto' }}>
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
            <VendorLocationListItemEdit
              locations={filteredLocations}
              edit={true}
              editPath="/vendor/location/edit/"
              backPath="/vendor/locations"
              type={12}
            />
          </div>
        </div>
      </div>
    );
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    const filteredLocations = this.state.vendorLocations.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );

    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Manage Locations')}

        <div className="vendorContent">
          {this.getAppContent(filteredLocations)}
        </div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
    clearUauth: () => {
      dispatch(clearUauth());
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
    vendorPromos: state.vendorPromos.promos,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    alllocations: state.locations.locations,
    demo: state.demo,
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorLocations);
