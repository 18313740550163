import React from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
// Components
import PackageItinerary from '../components/PackageItinerary';
// Reducers
import { getAp } from '../sagas/setActivePackageSaga/reducer';
import { getNewEvent } from '../sagas/createEventSaga/reducer';

const query = require('array-query');

export class Packages extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  UNSAFE_componentWillMount() {
    const packageDetails = query('packageId')
      .is(this.props.match.params.id)
      .on(this.props.packages);

    let newPackage = packageDetails[0];

    packageDetails[0].locations.forEach((location, x) => {
      console.log(' packageDetails[0]', packageDetails[0]);
      const locationDetails = query('locationId')
        .is(location.locationId)
        .on(this.props.locations);

      let newLocation = {
        locationTitle: locationDetails[0].locationTitle,
        address: locationDetails[0].address,
        locationType: location.locationType,
        locationId: locationDetails[0].locationId,
      };
      newPackage.locations[x] = newLocation;
    });

    this.setState({
      packageDetails: newPackage,
      loading: false,
    });
  }

  setActivePackage(ap) {
    this.props.getAp(ap);
    this.props.history.push('/checkout/setup');
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Experience Details
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  getFooter(event, mobile) {
    return (
      <Container className={mobile === false ? 'getGoing' : ''}>
        <Row className="no-margin">
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            <center>
              {this.props.user.authenticated === true && (
                <a
                  onClick={() => {
                    this.quickEvent(event);
                  }}
                >
                  <span
                    className="center solidredbutton"
                    style={{ margin: '0px auto' }}
                  >
                    Add To Itinerary
                  </span>
                </a>
              )}
              {this.props.user.authenticated === false && (
                <Link
                  to={{
                    pathname: '/signup',
                    // state: {
                    //   destination:
                    //     '/location/' + this.state.location.locationId,
                    // },
                  }}
                >
                  <span className="center solidredbutton">
                    Add To Itinerary
                  </span>
                </Link>
              )}
            </center>
          </Col>
        </Row>
      </Container>
    );
  }

  quickEvent(event) {
    const newEvent = {
      connections: [],
      planFirst: false,
      connectFirst: false,
      clear: false,
      step: 2,
      screen: 1,
      fromVEvent: false,
      fromPackage: true,
      vEvent: event,
      weightedLocations: [],
    };

    this.props.getNewEvent(newEvent);
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                ' #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer-container{display:none}  .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    return (
      <div id="package" style={{ height: '100%' }}>
        {this.getTitleBar()}
        <Container style={{ height: '100%' }}>
          <Row className="no-margin" style={{ height: '100%' }}>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
              style={{ height: '100%' }}
            >
              <div style={{ height: '100%' }}>
                <Card style={{ height: '100%' }}>
                  <CardImg
                    top
                    width="100%"
                    src={
                      'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
                      this.state.packageDetails.heroImage +
                      '?alt=media'
                    }
                    alt="articleHero"
                  />
                  <CardBody>
                    <CardTitle>
                      {this.state.packageDetails.title}
                    </CardTitle>
                    <CardText>
                      <p>{this.state.packageDetails.description}</p>
                    </CardText>

                    {/* <div className="card-section">
                      <p>
                        This package was created by{' '}
                        {
                          this.state.packageDetails.creatorInfo
                            .creatorName
                        }
                      </p>
                    </div> */}
                    <div
                      className="card-section"
                      id="package-itinerary"
                    >
                      {/* <h5 className="cartd-title">Recommended Itinerary</h5> */}

                      <PackageItinerary
                        locations={
                          this.state.packageDetails.locations
                        }
                        packageId={
                          this.state.packageDetails.packageId
                        }
                        type={3}
                      />
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
        <div
          id={
            this.props.user.authenticated === true
              ? 'startBar'
              : 'startBar'
          }
          style={{ padding: '0px' }}
          className="mobileHide"
        >
          {this.getFooter(this.state.packageDetails, false)}
        </div>
        <div
          id={
            this.props.user.authenticated === true
              ? 'startBar'
              : 'startBar'
          }
          className="mobileShow"
        >
          {this.getFooter(this.state.packageDetails, true)}
        </div>
        {this.props.user.authenticated === true && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.toolbar{display: none} html{display:grid} #mobile-nav { display: none }  .maincontent { top: 39px !important; margin-top: 0px; height: 100%} .App{background-color: #fafafa;}',
            }}
          />
        )}
        {this.props.user.authenticated === false && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.toolbar{display: none }@media (max-width: 800px){.maincontent { top: 80px !important;}}  ',
            }}
          />
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.toolbar{display: none} @media (max-width: 800px){.maincontent { top: 39px}} html{display:grid} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;}.footer-container{display:none} .App{background-color: #fafafa;}  #authleft {display:none;} #bottomNav { display: none } .maincontent { top: 0px; height: 100%}.footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;} p{max-width: 100% !important}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    user: state.user,
    locations: state.locations.locations,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAp: (ap) => {
      dispatch(getAp(ap));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
