import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import firebase from '../../config/firebase';
// Reducers
import { refreshNotifications } from '../../sagas/notificationsSaga/reducer';
import { refreshEvents } from '../../sagas/userEventsSaga/reducer';
import { refreshAwards } from '../../sagas/userAwardsSaga/reducer';
import { refreshVendorAwarded } from '../../sagas/vendorAwardedSaga/reducer';
import { refreshVendorPromos } from '../../sagas/vendorPromoSaga/reducer';

const _ = require('lodash');
const query = require('array-query');

export class InviteHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      notifications: [],
    };
  }

  UNSAFE_componentWillMount() {
    // console.log("notifications", this.props.notifications);
    // this.setState({
    //   notifications: this.props.notifications
    // });
    // let invitesArray = [];
    // invitesArray = query("userId")
    //   .is(this.props.user.id)
    //   .on(this.props.notifications);
    // console.log("invitesArray", invitesArray);
    // let count = 0;
    // invitesArray.forEach(invite => {
    //   if (invite.status === 0) {
    //     count += 1;
    //   }
    // });
    // this.setState({
    //   count,
    //   loading: false
    // });
  }

  componentDidMount() {
    //   console.log("NOTES COUNTE", this.state.notifications.length);
    //   const rdb = firebase
    //     .firestore()
    //     .collection("notifications")
    //     .where("userId", "==", this.props.userId);
    //   const sdb = firebase
    //     .firestore()
    //     .collection("notifications")
    //     .where("senderId", "==", this.props.userId);
    //   rdb.onSnapshot(this.onCollectionUpdate);
    //   sdb.onSnapshot(this.onCollectionUpdate);
    // }

    // onCollectionUpdate = querySnapshot => {
    //   const notificationsArray = [];
    //   querySnapshot.forEach(doc => {
    //     const {
    //       status,
    //       dateSent,
    //       locations,
    //       message,
    //       senderId,
    //       type,
    //       senderName,
    //       profilePhotoPath,
    //       userId,
    //       receiverName,
    //       userPurchaseId,
    //       eventId,
    //       usersAttending,
    //       replyMessage,
    //       scheduledTime,
    //       receiverPhotoPath,
    //       userName,
    //       editable
    //     } = doc.data();
    //     const notificationId = doc.id;
    //     // const id = doc.ref._documentPath._parts[1];
    //     notificationsArray.push({
    //       notificationId,
    //       status,
    //       dateSent,
    //       locations,
    //       message,
    //       senderId,
    //       type,
    //       senderName,
    //       profilePhotoPath,
    //       userId,
    //       receiverName,
    //       userPurchaseId,
    //       eventId,
    //       usersAttending,
    //       replyMessage,
    //       scheduledTime,
    //       receiverPhotoPath,
    //       userName,
    //       editable
    //     });
    //   });
    //   if (this.props.notificationsSet) {
    //     var notifications = notificationsArray;
    //   } else {
    //     const notificationsTemp = notificationsArray.concat(
    //       this.props.notifications
    //     );
    //     var notifications = _.uniqBy(notificationsTemp, "notificationId");
    //   }
    //   this.props.refreshNotifications(notifications);

    // Get Events In RealTime
    const table = `users.${this.props.user.id}.access`;
    const userEvents = firebase
      .firestore()
      .collection('user_events')
      .where(table, '==', true);
    userEvents.onSnapshot(this.eventUpdate);

    // Get Awards In RealTime
    const userAwards = firebase
      .firestore()
      .collection('user_awards')
      .where('owner.userId', '==', this.props.user.id);
    userAwards.onSnapshot(this.awardUpdate);

    if (
      this.props.profile.vendorAccount !== undefined &&
      this.props.profile.vendorAccount !== null
    ) {
      // Get Vendor Awarded In RealTime
      const vendorAwarded = firebase
        .firestore()
        .collection('user_awards')
        .where(
          'promo.vendorId',
          '==',
          this.props.profile.vendorAccount.id,
        );
      vendorAwarded.onSnapshot(this.vendorAwardedUpdate);

      const table = `participants.${this.props.profile.vendorAccount.id}.access`;
      const promos = firebase
        .firestore()
        .collection('promos')
        .where(table, '==', true);
      promos.onSnapshot(this.vendorPromosUpdate);
    }
  }

  awardUpdate = (querySnapshot) => {
    const awardsObject = {};
    awardsObject.awards = [];
    querySnapshot.forEach((doc) => {
      const {
        active,
        creationDetails,
        criteria,
        reward,
        rewards,
        owner,
        redemption,
        restrictions,
        rewardSettings,
        promo,
        earned,
        validatorMode,
        validationType,
      } = doc.data();
      awardsObject.awards.push({
        awardId: doc.id,
        active,
        creationDetails,
        criteria,
        reward,
        rewards,
        rewardSettings,
        owner,
        redemption,
        restrictions,
        promo,
        earned,
        validatorMode,
        validationType,
      });
    });
    // Update Awards
    this.props.refreshAwards(awardsObject);
  };

  vendorAwardedUpdate = (querySnapshot) => {
    const vendorAwardedObject = {};
    vendorAwardedObject.vendorAwarded = [];
    querySnapshot.forEach((doc) => {
      const {
        active,
        creationDetails,
        criteria,
        reward,
        rewards,
        rewardSettings,
        owner,
        redemption,
        restrictions,
        promo,
        earned,
        validatorMode,
        validationType,
      } = doc.data();
      vendorAwardedObject.vendorAwarded.push({
        awardId: doc.id,
        active,
        creationDetails,
        criteria,
        reward,
        rewards,
        rewardSettings,
        owner,
        redemption,
        restrictions,
        promo,
        earned,
        validatorMode,
        validationType,
      });
    });
    // Update Awards
    this.props.refreshVendorAwarded(vendorAwardedObject);
  };

  vendorPromosUpdate = (querySnapshot) => {
    const vendorPromosObject = {};
    vendorPromosObject.promos = [];
    querySnapshot.forEach((doc) => {
      const {
        title,
        account,
        active,
        creationDetails,
        locationId,
        locationTitle,
        rewards,
        events,
        description,
        images,
        locations,
        scheduledTime,
        tagsExperienceTypes,
        goldenTicket,
        settings,
        participants,
        status,
        editing,
      } = doc.data();
      vendorPromosObject.promos.push({
        id: doc.id,
        title,
        account,
        active,
        creationDetails,
        locationId,
        locationTitle,
        rewards,
        events,
        description,
        images,
        locations,
        scheduledTime,
        tagsExperienceTypes,
        goldenTicket,
        settings,
        status,
        participants,
        editing,
      });
    });
    // Update Awards
    this.props.refreshVendorPromos(vendorPromosObject);
  };

  eventUpdate = (querySnapshot) => {
    const eventsObject = {};
    eventsObject.events = [];
    querySnapshot.forEach((doc) => {
      const {
        creationDetails,
        eventSettings,
        packageDetails,
        status,
        title,
        description,
        scheduledTime,
        heroImage,
        thumbnail,
        editHistory,
        users,
        invitations,
        editing,
      } = doc.data();
      eventsObject.events.push({
        eventId: doc.id,
        creationDetails,
        eventSettings,
        packageDetails,
        status,
        title,
        description,
        scheduledTime,
        heroImage,
        thumbnail,
        editHistory,
        users,
        invitations,
        editing,
      });
    });

    this.props.refreshEvents(eventsObject);

    const activeInvitationsArray = [];
    if (this.props.events !== undefined) {
      this.props.events.forEach((event) => {
        if (event.users[this.props.user.id].inviteAction === false) {
          activeInvitationsArray.push(
            event.invitations[this.props.user.id],
          );
        }
      });
    }

    this.setState({
      notifications: activeInvitationsArray,
    });
    this.setEvents(activeInvitationsArray);

    // this.setEvents(eventsObject.events);
  };

  setEvents(activeEventsArray) {
    // let activeEventsArray = [];
    const activeCountArray = [];
    // activeEventsArray = query("userId")
    //   .is(this.props.userId)
    //   .on(this.state.notifications);
    activeEventsArray.forEach((event) => {
      if (event.status === 0 || event.status === 9) {
        activeCountArray.push(event);
      }
    });
    const activeCount = activeCountArray.length;
    this.setState({
      sentInvites: activeEventsArray,
      loading: false,
      count: activeCount,
    });
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    if (this.state.count === 0) {
      return (
        <div className="inviteHeader">
          <div className="right notificationIcon">
            <Link
              to={{
                pathname: '/events/invitations',
              }}
            >
              <MaterialIcon icon="mail" size={40} color="#f21d59" />
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="inviteHeader">
          <div className="right notificationIcon">
            <Link
              to={{
                pathname: '/events/invitations',
              }}
              className="notificationCount"
            >
              <span>{this.state.count}</span>
            </Link>
            <Link
              to={{
                pathname: '/events/invitations',
              }}
            >
              <MaterialIcon icon="drafts" size={40} color="#f21d59" />
            </Link>
          </div>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    refreshNotifications: (notifications) => {
      dispatch(refreshNotifications(notifications));
    },
    refreshEvents: (events) => {
      dispatch(refreshEvents(events));
    },
    refreshAwards: (awards) => {
      dispatch(refreshAwards(awards));
    },
    refreshVendorAwarded: (vendorAwarded) => {
      dispatch(refreshVendorAwarded(vendorAwarded));
    },
    refreshVendorPromos: (promos) => {
      dispatch(refreshVendorPromos(promos));
    },
  };
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
    user: state.user,
    events: state.events.events,
    profile: state.profile.profile,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteHeader);
