import MaterialIcon from 'material-icons-react';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { createFilter } from 'react-search-input';
import UserAvatar from 'react-user-avatar';
import Select from 'react-select';
import logo from '../../images/TrevelUnderlined.svg';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineSeries,
  LineMarkSeries,
  RadialChart,
  FlexibleWidthXYPlot,
  DiscreteColorLegend,
  ContinuousColorLegend,
} from 'react-vis';

import { Col, Row } from 'reactstrap';
import '../../../node_modules/react-vis/dist/style.css';
import '../../../node_modules/react-vis/dist/styles/radial-chart.scss';

const KEYS_TO_FILTERS = ['title'];

const query = require('array-query');

const Checkbox = (props) => <input type="checkbox" {...props} />;

const mainLinks = [
  {
    report: 1,
    label: 'Players',
    icon: 'local_activity',
  },
  {
    report: 2,
    label: 'Activity',
    icon: 'ballot',
  },

  {
    report: 3,
    label: 'Awards',
    icon: 'stars',
  },
];

const secondaryLinks = [
  {
    report: 0,
    label: 'Dashboard',
    icon: 'local_activity',
  },
];

export class VendorPromoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      promos: [],
      startSelected: null,
      endSelected: null,
      checks: {
        redeemed: true,
        earned: true,
        validations: true,
      },
      showPicker: false,
      report: 0,
      showPicker: false,
      showHelp: false,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.handleStartDayClick = this.handleStartDayClick.bind(this);
    this.handleEndDayClick = this.handleEndDayClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showPicker = this.showPicker.bind(this);
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.getPromoDetails();
    if (this.props.history) {
      this.setState({
        currentUrl: this.props.history.location.pathname,
      });
    }
  }

  getPromoDetails() {
    const promoDetails = query('id')
      .is(this.props.match.params.id)
      .on(this.props.vendorPromos);
    let promo = promoDetails[0];

    let awardArray = [];

    this.props.vendorAwarded.forEach((award) => {
      promo.rewards.forEach((reward) => {
        console.log('reward - award', award.reward.id);
        console.log('reward', reward.id);
        console.log('reward - award', award.promo.id);
        console.log('reward', this.props.match.params.id);
        if (
          award.reward.id === reward.id &&
          award.promo.id === this.props.match.params.id
        ) {
          console.log('awardArray', reward);
          console.log('awardArrays', award);
          awardArray.push(award);
        }
      });
    });
    console.log('awardArray', awardArray);
    console.log('promo', promo);
    // const start = this.getDate(promo.creationDetails.createdDate);
    const startSelected = moment(start);
    const today = moment();

    // const start = moment()
    //   .subtract(1, 'months')
    //   .startOf('month')
    //   .format('MM/DD/YYYY');
    const end = moment()
      // .subtract(1, 'months')
      .endOf('month')
      .format('MM/DD/YYYY');

    const start = moment()
      // .subtract(1, 'months')
      .startOf('month')
      .format('MM/DD/YYYY');

    this.setState({
      awards: awardArray,
      promo,
      loading: false,
      end,
      start,
      startSelected: startSelected._d,
      initialStartMonth: startSelected._d,
      initialEndMonth: end._d,
      endSelected: end._d,
    });
  }

  getStatus(status) {
    if (status === true) {
      return <span>Redeemed</span>;
    } else {
      return <span className="redeemed">Active</span>;
    }
  }

  getActiveStatus(status) {
    if (status === true) {
      return <span className="right loudStatus">ACTIVE</span>;
    } else {
      return <span className="right loudStatus">INACTIVE</span>;
    }
  }

  getAwardActiveStatus(status) {
    if (status === true) {
      return <span className="right">Active</span>;
    } else {
      return <span className="right">Inactive</span>;
    }
  }

  getEarnedStatus(earnedStatus, redeemedStatus, activeStatus) {
    console.log('geting status');
    if (redeemedStatus === true) {
      return <span className="">Redeemed</span>;
    } else if (activeStatus === false) {
      return <span className="">Expired</span>;
    } else if (earnedStatus === true) {
      return <span className="">Earned</span>;
    } else {
      return <span className="">In Progress</span>;
    }
  }

  getEarnedStatusLoud(earnedStatus, redeemedStatus, activeStatus) {
    console.log('geting status 2');
    if (redeemedStatus === true) {
      return <span className="right loudStatus">Redeemed</span>;
    } else if (activeStatus === false) {
      return <span className="right loudStatus">Expired</span>;
    } else if (earnedStatus === true) {
      return <span className="right loudStatus">Earned</span>;
    } else {
      return <span className="right loudStatus">In Progress</span>;
    }
  }

  getHistoryValidationStatus(status) {
    if (status === true) {
      return <span className="green">Approved</span>;
    } else {
      return <span className="red">Rejected</span>;
    }
  }

  getAwardCreatedDate(date) {
    if (date !== null) {
      let timestamp = this.getDate(date);
      return <span>{timestamp}</span>;
    } else {
      return <span className="text-center">-</span>;
    }
  }

  getCount(rewardId, starting) {
    let awardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === rewardId) {
        awardArray.push(award);
      }
    });
    let count = awardArray.length;
    // let remaining = starting - count;
    return <span>{count}</span>;
  }

  getRemaining(rewardId, starting) {
    let awardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === rewardId) {
        awardArray.push(award);
      }
    });
    let count = awardArray.length;
    let remaining = starting - count;
    return <span>{remaining}</span>;
  }

  getRedeemed(rewardId) {
    let rewardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === rewardId) {
        if (award.redemption.redeemed === true) {
          rewardArray.push(award);
        }
      }
    });
    let count = rewardArray.length;
    return <span>{count}</span>;
  }

  getDate(timestamp) {
    console.log('timestamp', timestamp);
    let myDate = new Date(timestamp.seconds * 1000);
    let dayTextFinal = moment(myDate).format('MM/DD/YYYY');
    console.log('dayTextFinal', dayTextFinal);
    return dayTextFinal;
  }

  getUnused(reward) {
    // Get Awarded
    let awardArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === reward.id) {
        awardArray.push(award);
      }
    });
    let awarded = awardArray.length;
    // Get Redeemed
    let redeemedArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.reward.id === reward.id) {
        if (award.redemption.redeemed === true) {
          redeemedArray.push(award);
        }
      }
    });
    let redeemed = redeemedArray.length;
    // Get Unused
    let unused = awarded - redeemed;
    return <span>{unused}</span>;
  }

  getRedeemer(award) {
    if (award.redemption.date !== null) {
      return <span>{award.redemption.redeemerFullName}</span>;
    } else {
      return <span className="text-center">-</span>;
    }
  }

  getAwards(promos) {
    return (
      <Row className="no-margin">
        <div className="fullwidth">
          <div className="reportHeader">
            <span className="reportHeaderText">User Rewards</span>
          </div>
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Owner</span>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Reward</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Earned</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Created</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="right">
                  <span className="label">History</span>
                </Col>
              </div>
            </li>
            {this.state.awards.map((award, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {award.owner.firstName} {award.owner.lastName}
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {award.reward.title}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {/* <span>{award.criteria[0].validationHistory[0].fullName}</span> */}
                    {this.getEarnedStatus(
                      award.earned,
                      award.redemption.redeemed,
                      award.active,
                    )}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {this.getAwardCreatedDate(
                      award.creationDetails.startDate,
                    )}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    <a
                      onClick={() => {
                        this.showRewardDetails(award);
                      }}
                      // className="right"
                    >
                      <span className="pointer">Show History</span>
                    </a>
                    {/* {this.getRedeemer(award)} */}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  // Charts

  handleStartDayClick(day, { selected }) {
    let start = moment(day).format('MM/DD/YYYY');
    this.setState({
      startSelected: selected ? undefined : day,
      start,
    });
  }

  handleEndDayClick(day, { selected }) {
    let end = moment(day).format('MM/DD/YYYY');
    this.setState({
      endSelected: selected ? undefined : day,
      end,
    });
  }

  getDates(start, end) {
    var dates = [start];
    var currDate = moment(start).startOf('day');
    var lastDate = moment(end).startOf('day');
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      let date = currDate.clone().toDate();
      let dayTextFinal = moment(date).format('MM/DD/YYYY');
      dates.push(dayTextFinal);
    }
    dates.push(end);
    return dates;
  }

  setRange(period, promo) {
    let start = this.state.start;
    let end = this.state.end;
    const today = moment();
    if (period === 1) {
      start = today.startOf('week').format('MM/DD/YYYY');
      end = today.endOf('week').format('MM/DD/YYYY');
    }
    if (period === 2) {
      start = today.startOf('month').format('MM/DD/YYYY');
      end = today.endOf('month').format('MM/DD/YYYY');
    }
    if (period === 3) {
      start = this.getDate(promo.creationDetails.createdDate);
      end = today.endOf('month').format('MM/DD/YYYY');
    }
    const startSelected = moment(start);
    const endSelected = moment(end);
    this.setState({
      end,
      start,
      startSelected: startSelected._d,
      endSelected: endSelected._d,
      initialStartMonth: startSelected._d,
      initialEndMonth: endSelected._d,
    });
  }

  getLineChart(awards) {
    console.log('awards', awards);
    let count = function (ary, classifier) {
      classifier = classifier || String;
      return ary.reduce(function (counter, item) {
        var p = classifier(item);
        counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
        return counter;
      }, {});
    };

    let awardEarnedArray = [];
    let awardValidationArray = [];
    let awardRedeemedArray = [];
    let finalData = [];

    let start = this.state.start;
    let end = this.state.end;

    awards.forEach((award) => {
      console.log('awardx', award);
      if (award.earned === true) {
        let date = this.getDate(award.creationDetails.earnedDate);
        let dataObject = {
          date,
          award,
          type: 'Earned',
        };
        let inRange = moment(date).isBetween(start, end);
        if (inRange === true) {
          awardEarnedArray.push(dataObject);
        }
      }
      if (award.redemption.redeemed === true) {
        let date = this.getDate(award.redemption.date);
        let dataObject = {
          date,
          award,
          type: 'Redeemed',
        };
        let inRange = moment(date).isBetween(start, end);
        if (inRange === true) {
          awardRedeemedArray.push(dataObject);
        }
      }
      award.criteria.forEach((criteria) => {
        criteria.validationHistory.forEach((history) => {
          let date = this.getDate(history.date);
          let dataObject = {
            date,
            award,
            type: 'Validated',
          };
          let inRange = moment(date).isBetween(start, end);
          if (inRange === true) {
            awardValidationArray.push(dataObject);
          }
        });
      });
    });

    let finalArray = [];
    if (this.state.checks.validations === true) {
      awardValidationArray.forEach((object) => {
        finalArray.push(object);
      });
    }
    if (this.state.checks.earned === true) {
      awardEarnedArray.forEach((object) => {
        finalArray.push(object);
      });
    }
    if (this.state.checks.redeemed === true) {
      awardRedeemedArray.forEach((object) => {
        finalArray.push(object);
      });
    }

    let countByDate = count(finalArray, function (item) {
      return item.date;
    });
    Object.keys(countByDate).forEach(function (key) {
      let datObject = {
        x: key,
        y: countByDate[key],
      };
      finalData.push(datObject);
    });
    finalData.sort(
      (a, b) =>
        Date.parse(a.x) / 86400000 - Date.parse(b.x) / 86400000,
    );

    let dates = this.getDates(start, end);
    let res = [];
    for (let i = 0, j = 0; i < dates.length; i++) {
      if (finalData[j] !== undefined) {
        let newDate = moment(dates[i]).format('MMM D');
        res[i] = {
          x: newDate,
          y: dates[i] === finalData[j].x ? finalData[j++].y : 0,
        };
      }
    }

    let redeemed = this.state.checks.redeemed;
    let earned = this.state.checks.earned;
    let validations = this.state.checks.validations;

    return (
      <div className="section">
        <label className="analFilter">
          <Checkbox
            checked={validations}
            onChange={this.handleChange}
            name="validations"
          />
          <span>Validations</span>
        </label>

        <label className="analFilter">
          <Checkbox
            checked={earned}
            onChange={this.handleChange}
            name="earned"
          />
          <span>Earned</span>
        </label>

        <label className="analFilter">
          <Checkbox
            checked={redeemed}
            onChange={this.handleChange}
            name="redeemed"
          />
          <span>Redeemed</span>
        </label>

        {this.getReport(res)}

        <Row className="no-margin">
          <div className="fullwidth">
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Total Actions</span>
                <span className="analLoud">
                  {this.getAllCount(
                    this.state.redeemed,
                    this.state.awards,
                    awardValidationArray,
                  )}
                </span>
              </div>
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Redeemed</span>
                <span className="analLoud">
                  {this.getRedeemedCount(this.state.redeemed)}
                </span>
              </div>
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Earned</span>
                <span className="analLoud">
                  {this.getEarnedCount(this.state.awards)}
                </span>
              </div>
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Validations</span>
                <span className="analLoud">
                  {this.getValidationCount(awardValidationArray)}
                </span>
              </div>
            </Col>
          </div>
        </Row>

        <Row className="no-margin">
          <div className="fullwidth">
            {/* <div className="reportHeader">
              <span className="reportHeaderText">
                Promotion Players
              </span>
            </div> */}
            <ul className="no-padding">
              <li className="adminlistitem adminlistheader ">
                <div>
                  <Col xs={6} md={6} sm={6} lg={6} className="left">
                    <span className="label">Player Name</span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="right">
                    <span className="label">Date</span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    <span className="label">Action</span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    <span className="label">Award</span>
                  </Col>
                  {/*  <Col xs={2} md={2} sm={2} lg={2} className="right">
                    <span className="label">History</span>
                  </Col> */}
                </div>
              </li>
              {finalArray.map((data, index) => (
                <li className="adminlistitem">
                  <div className="fullwidth">
                    <Col xs={6} md={6} sm={6} lg={6} className="left">
                      {index + 1}. {data.award.owner.firstName}{' '}
                      {data.award.owner.lastName}
                    </Col>
                    <Col
                      xs={2}
                      md={2}
                      sm={2}
                      lg={2}
                      className="right"
                    >
                      {data.date}
                    </Col>
                    <Col xs={2} md={2} sm={2} lg={2} className="left">
                      {data.type}
                    </Col>
                    <Col xs={2} md={2} sm={2} lg={2} className="left">
                      {data.award.reward.title}
                    </Col>
                    {/*  <Col xs={2} md={2} sm={2} lg={2} className="left">
                     
                    </Col> */}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Row>
      </div>
    );
  }

  getReport(res) {
    if (res.length > 0) {
      return (
        <div className="analChart">
          <FlexibleWidthXYPlot xType="ordinal" height={500}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis title="Date" />
            <YAxis
              title="User Activity"
              tickFormat={(val) =>
                Math.round(val) === val ? val : ''
              }
            />
            <LineMarkSeries
              data={res}
              color={'#ba0c3b'}
              strokeWidth={3}
            />
          </FlexibleWidthXYPlot>
        </div>
      );
    } else {
      return (
        <div className="alertMessage">
          <center>
            <h4>No Data Available For This Date Range</h4>
          </center>
        </div>
      );
    }
  }

  getOtherReport(res) {
    if (res.length > 0) {
      return (
        <div className="analChart">
          <FlexibleWidthXYPlot xType="ordinal" height={500}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis title="Date" />
            <YAxis
              title="User Activity"
              tickFormat={(val) =>
                Math.round(val) === val ? val : ''
              }
            />
            <LineMarkSeries data={res} color={'#ba0c3b'} />
          </FlexibleWidthXYPlot>
        </div>
      );
    } else {
      return (
        <div className="alertMessage">
          <center>
            <h4>No Data Available For This Date Range</h4>
          </center>
        </div>
      );
    }
  }

  getAllCount(redeemed, awards, awardValidationArray) {
    let rewardArray = [];
    this.state.awards.forEach((award) => {
      if (award.redemption.redeemed === true) {
        rewardArray.push(award);
      }
    });

    let earnedArray = [];
    awards.forEach((award) => {
      if (award.earned === true) {
        earnedArray.push(award);
      }
    });

    let earnedCount = earnedArray.length;
    let redeemedCount = rewardArray.length;
    let validationsCount = awardValidationArray.length;

    let count = earnedCount + redeemedCount + validationsCount;

    return count;
  }

  getRedeemedCount() {
    let rewardArray = [];
    this.state.awards.forEach((award) => {
      if (award.redemption.redeemed === true) {
        rewardArray.push(award);
      }
    });
    let count = rewardArray.length;
    return count;
  }

  getValidationCount(awardValidationArray) {
    let count = awardValidationArray.length;
    return count;
  }

  getEarnedCount(awards) {
    let rewardArray = [];
    awards.forEach((award) => {
      if (award.earned === true) {
        rewardArray.push(award);
      }
    });
    let count = rewardArray.length;
    return count;
  }

  getPlayerCount(players) {
    let count = players.length;
    return count;
  }

  handleChange = (event) => {
    let checks = this.state.checks;
    checks[event.target.name] = event.target.checked;
    this.setState({ checks: checks });
  };

  showPicker = (value) => {
    this.setState({ showPicker: value });
  };

  getUserLineChart(awards) {
    console.log('userObjectArray - awards', awards);
    let userArray = [];
    let userObjectArray = [];
    let userData = [];
    let start = this.state.start;
    let end = this.state.end;

    awards.forEach((award) => {
      const status = userArray.includes(award.owner.userId);
      const startDate =
        award.creationDetails.startDate.seconds * 1000; // Unix ms timestamp
      const startDateText = moment(startDate).format('MM/DD/YYYY');
      let inRange = moment(startDateText).isBetween(start, end);
      const userId = award.owner.userId;
      console.log('userObjectArray - in range', inRange);
      if (inRange === true) {
        if (status === false) {
          const fullName =
            award.owner.firstName + ' ' + award.owner.lastName;
          let userObject = {
            userId,
            fullName,
            startDate: startDateText,
          };
          userArray.push(award.owner.userId);
          userObjectArray.push(userObject);
        } else {
          const elementsIndex = userObjectArray.findIndex(
            (element) => element.userId == userId,
          );
          const currentStartDate =
            userObjectArray[elementsIndex].startDate;
          let timeCheck =
            moment(startDateText).isBefore(currentStartDate); // true
          if (timeCheck === true) {
            userObjectArray[elementsIndex].startDate = startDate;
          }
        }
      }
    });
    let count = function (ary, classifier) {
      classifier = classifier || String;
      return ary.reduce(function (counter, item) {
        var p = classifier(item);
        counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
        return counter;
      }, {});
    };

    let countByDate = count(userObjectArray, function (item) {
      return item.startDate;
    });
    Object.keys(countByDate).forEach(function (key) {
      let datObject = {
        x: key,
        y: countByDate[key],
      };
      userData.push(datObject);
    });
    userData.sort(
      (a, b) =>
        Date.parse(a.x) / 86400000 - Date.parse(b.x) / 86400000,
    );
    let dates = this.getDates(start, end);
    let res = [];
    for (let i = 0, j = 0; i < dates.length; i++) {
      if (userData[j] !== undefined) {
        let newDate = moment(dates[i]).format('MMM D');
        res[i] = {
          x: newDate,
          y: dates[i] === userData[j].x ? userData[j++].y : 0,
        };
      }
    }
    const today = new Date();
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
      },
    };
    console.log('userObjectArray', userObjectArray);
    return (
      <div className="section">
        {this.getOtherReport(res)}

        <Row className="no-margin">
          <div className="fullwidth">
            <Col
              xs={4}
              md={4}
              sm={4}
              lg={4}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Players</span>
                <span className="analLoud">
                  {this.getPlayerCount(userObjectArray)}
                </span>
              </div>
            </Col>
            <Col
              xs={4}
              md={4}
              sm={4}
              lg={4}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">New Players</span>
                <span className="analLoud">
                  {this.getPlayerCount(userObjectArray)}
                </span>
              </div>
            </Col>
            <Col
              xs={4}
              md={4}
              sm={4}
              lg={4}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Returning Players</span>
                <span className="analLoud">
                  {this.getPlayerCount(userObjectArray)}
                </span>
              </div>
            </Col>
          </div>
        </Row>

        <Row className="no-margin">
          <div className="fullwidth">
            {/* <div className="reportHeader">
              <span className="reportHeaderText">
                Promotion Players
              </span>
            </div> */}

            <ul className="no-padding">
              <li className="adminlistitem adminlistheader ">
                <div>
                  <Col xs={8} md={8} sm={8} lg={8} className="left">
                    <span className="label">Player Name</span>
                  </Col>
                  <Col xs={4} md={4} sm={4} lg={4} className="right">
                    <span className="label">Start Date</span>
                  </Col>
                  {/* <Col xs={2} md={2} sm={2} lg={2} className="left">
                    <span className="label">Earned</span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    <span className="label">Created</span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="right">
                    <span className="label">History</span>
                  </Col> */}
                </div>
              </li>

              {userObjectArray.map((user, index) => (
                <li className="adminlistitem">
                  <div className="fullwidth">
                    <Col xs={8} md={8} sm={8} lg={8} className="left">
                      {index + 1}. {user.fullName}
                    </Col>
                    <Col
                      xs={4}
                      md={4}
                      sm={4}
                      lg={4}
                      className="right"
                    >
                      {user.startDate}
                    </Col>
                    {/* <Col xs={2} md={2} sm={2} lg={2} className="left">
                    
                    </Col>
                    <Col xs={2} md={2} sm={2} lg={2} className="left">
                   
                    </Col>
                    <Col xs={2} md={2} sm={2} lg={2} className="left">
                     
                    </Col> */}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Row>
      </div>
    );
  }

  getValidationHistory(validationHistory) {
    return (
      <Row className="no-margin">
        <div className="fullwidth">
          {/* <div className="reportHeader">
            <span className="reportHeaderText">Validation History</span>
          </div> */}
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={6} md={6} sm={6} lg={6} className="left">
                  <span className="label">Validator Name</span>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Status</span>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Date</span>
                </Col>
              </div>
            </li>
            {validationHistory.map((validation, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col xs={6} md={6} sm={6} lg={6} className="left">
                    {validation.fullName}
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {this.getHistoryValidationStatus(
                      validation.validated,
                    )}
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {this.getAwardCreatedDate(validation.date)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  getCriteriaPercentage(criteria) {
    console.log('criteriass', criteria);
    let currentNum = 0;
    let numTimes = criteria.numTimes;
    criteria.validationHistory.forEach((validation) => {
      if (validation.validated === true) {
        currentNum = currentNum + 1;
      }
    });
    if (currentNum === numTimes) {
      return <span className="criteriaStatusLight">Satisfied</span>;
    } else {
      return (
        <span className="criteriaStatus">
          {currentNum} / {numTimes}
        </span>
      );
    }
    return (
      <span className="criteriaStatus">
        {currentNum} / {numTimes}
      </span>
    );
  }

  getAwardHistory(reward) {
    console.log('heavy', reward);
    const promoDetails = query('id')
      .is(reward.promo.id)
      .on(this.props.vendorPromos);
    let promo = promoDetails[0];

    let startedDate = this.getDate(reward.creationDetails.startDate);
    let earnedDate = null;
    if (
      reward.creationDetails.earnedDate !== undefined &&
      reward.creationDetails.earnedDate !== undefined
    ) {
      earnedDate = this.getDate(reward.creationDetails.earnedDate);
    }
    let redeemedDate = null;
    if (reward.redemption.redeemed === true) {
      redeemedDate = this.getDate(reward.redemption.date);
    }

    return (
      <div>
        <div className="wizard-section-border-ten section">
          <Row>
            <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
              <span style={{ fontWeight: 600 }}>Owner:</span>
              <span>
                {reward.owner.firstName} {reward.owner.lastName}
              </span>
              <div>
                <span style={{ fontWeight: 600 }}>Reward: </span>
                <span> {reward.reward.title}</span>
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Experience:</span>
                <span> {promo.title}</span>
              </div>
              <div className="clear">
                {/* <span><b>Total Actions</b></span><span>{this.getTotalActions(reward.owner.id)}</span> */}
              </div>
            </Col>
            <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
              {this.getEarnedStatusLoud(
                reward.earned,
                reward.redemption.redeemed,
                reward.active,
              )}
              <span style={{ fontWeight: 600 }}>Start Date: </span>
              <span>{startedDate}</span>
              <div>
                <span style={{ fontWeight: 600 }}>Earned Date: </span>
                {earnedDate !== null && <span>{earnedDate}</span>}
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>
                  Redeemed Date:{' '}
                </span>
                {redeemedDate !== null && <span>{redeemedDate}</span>}
              </div>
            </Col>
          </Row>
        </div>
        {reward.criteria.map((criteria, index) => (
          <div className="section">
            <div style={{ display: 'block', float: 'left' }}>
              <b>Criteria</b>
            </div>
            <div style={{ display: 'block', float: 'right' }}>
              {this.getCriteriaPercentage(criteria)}
            </div>
            <div style={{ display: 'block', clear: 'both' }}>
              <span>{criteria.text}</span>
            </div>
            <br />
            {this.getValidationHistory(criteria.validationHistory)}
          </div>
        ))}
      </div>
    );
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  setReport(report) {
    console.log('setReport', report);
    this.setState({ report });
  }

  showRewardDetails(reward) {
    this.setState({ showRewardDetails: true, activeReward: reward });
  }

  closeRewardDetails() {
    this.setState({ showRewardDetails: false, activeReward: null });
  }

  getModalTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <a
                onClick={() => {
                  this.closeRewardDetails();
                }}
              >
                <span className="left">
                  <MaterialIcon
                    icon="close"
                    size={25}
                    color="white"
                  />
                </span>
              </a>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Reward Validation History
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} #analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }

  getRewards(reward, index) {
    return (
      <div className="card section">
        <div className="wizard-section-border-ten">
          <span>
            <b>Level {index + 1}:</b>
          </span>{' '}
          <span className="rewardTitle">{reward.title}</span>
        </div>
        {/* <div className="wizard-section-ten">
            <b>Offer Criteria</b> */}
        {/* <p>{promo.criteria.text}</p> */}
        {/* </div> */}
        <Row>
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <div className="wizard-section-ten">
              <Row>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="left no-padding"
                >
                  Awarded:
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="text-right"
                >
                  {this.getCount(reward.id, reward.quantity)}
                </Col>
              </Row>
              <Row>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="left no-padding"
                >
                  Redeemed:
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="text-right"
                >
                  {this.getRedeemed(reward.id)}
                </Col>
              </Row>

              <Row>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="left no-padding"
                >
                  Unused:
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="text-right"
                >
                  {this.getUnused(reward)}
                </Col>
              </Row>

              <Row>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="left no-padding"
                >
                  Available:
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="text-right"
                >
                  {this.getRemaining(reward.id, reward.quantity)}
                </Col>
              </Row>

              <br />

              <Row>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="left no-padding"
                >
                  Max Quantity:
                </Col>
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="text-right"
                >
                  <span>{reward.quantity}</span>
                </Col>
              </Row>
            </div>
          </Col>

          <Col
            xs={6}
            md={6}
            sm={6}
            lg={6}
            className="right no-padding text-center"
          >
            <span className="chart-title">Awards Remaining</span>
            <div id="promoChart">{this.getChart(reward)}</div>
          </Col>
        </Row>
      </div>
    );
  }

  getChart(promo) {
    // Get Starting Quantity
    let starting = promo.quantity;
    // Get Awarded
    let awardArray = [];

    this.props.vendorAwarded.forEach((award) => {
      if (award.promo.id === promo.id) {
        awardArray.push(award);
      }
    });
    let awarded = awardArray.length;
    // Get Available
    let available = starting - awarded;
    // Get Redeemed
    let redeemedArray = [];

    this.props.vendorAwarded.forEach((award) => {
      if (award.promo.id === promo.id) {
        if (award.redemption.redeemed === true) {
          redeemedArray.push(award);
        }
      }
    });
    let redeemed = redeemedArray.length;
    // Get Unused
    let unused = awarded - redeemed;

    let unusedPercent = (unused / starting) * 100;
    let availablePercent = (available / starting) * 100;
    let redeemedPercent = (redeemed / starting) * 100;
    const myData = [
      { angle: unusedPercent, label: 'Unused' },
      { angle: redeemedPercent, label: 'Redeemed' },
      { angle: availablePercent, label: 'Available' },
    ];
    return (
      <div>
        {/* <RadialChart data={myData} width={150} height={150} showLabels={true} /> */}
        <RadialChart
          data={myData}
          width={150}
          height={150}
          colorRange={['#b90b3b', '#f21d59']}
          // showLabels={true}
          // labelsRadiusMultiplier={1.34}
          // labelsAboveChildren={true}
        />
      </div>
    );
  }

  getDashboard(awards) {
    let userArray = [];
    let userIdArray = [];
    let newArray = [];
    let returnArray = [];
    awards.forEach((award) => {
      if (award.promo.id === this.state.promo.id) {
        const status = userIdArray.includes(award.owner.userId);
        if (status === false) {
          userArray.push(award);
          userIdArray.push(award.owner.userId);
        }
      }
    });

    userArray.forEach((user) => {
      let match = false;
      this.props.vendorAwarded.forEach((award) => {
        if (user.owner.userId === award.owner.userId) {
          console.log('step1', award);
          if (award.promo.id === this.state.promo.id) {
            console.log('step2', user);
            let inRange = moment(
              user.creationDetails.startDate,
            ).isBefore(award.creationDetails.startDate);
            if (inRange === true) {
              const status = newArray.includes(award.owner.userId);
              if (status === false) {
                newArray.push(award.owner.userId);
              }
            } else {
              const status = returnArray.includes(award.owner.userId);
              if (status === false) {
                returnArray.push(award.owner.userId);
              }
            }
          }
        }
      });
    });

    let playerCount = userArray.length;
    let newPlayerCount = newArray.length;
    let returnPlayerCount = returnArray.length;

    let redeemed = this.state.checks.redeemed;
    let earned = this.state.checks.earned;
    let validations = this.state.checks.validations;

    return (
      <div>
        <Row className="no-margin">
          <div className="fullwidth">
            <h5>Players</h5>
            <Col
              xs={4}
              md={4}
              sm={4}
              lg={4}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Players</span>
                <span className="analLoud">{playerCount}</span>
              </div>
            </Col>
            <Col
              xs={4}
              md={4}
              sm={4}
              lg={4}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">New Players</span>
                <span className="analLoud">
                  <span className="analLoud">{newPlayerCount}</span>
                </span>
              </div>
            </Col>
            <Col
              xs={4}
              md={4}
              sm={4}
              lg={4}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Returning Players</span>
                <span className="analLoud">
                  <span className="analLoud">
                    {returnPlayerCount}
                  </span>
                </span>
              </div>
            </Col>
          </div>
        </Row>

        <Row className="no-margin">
          <div className="fullwidth">
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Total Actions</span>
                <span className="analLoud">
                  {/* {this.getAllCount(
                    this.state.redeemed,
                    this.state.awards,
                    awardValidationArray,
                  )} */}
                </span>
              </div>
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Redeemed</span>
                <span className="analLoud">
                  {/* {this.getRedeemedCount(this.state.redeemed)} */}
                </span>
              </div>
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Earned</span>
                <span className="analLoud">
                  {/* {this.getEarnedCount(this.state.awards)} */}
                </span>
              </div>
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="left no-padding"
            >
              <div className="analCard">
                <span className="analQuite">Validations</span>
                <span className="analLoud">
                  {/* {this.getValidationCount(awardValidationArray)} */}
                </span>
              </div>
            </Col>
          </div>
        </Row>
        {/* <label className="analFilter">
          <Checkbox
            checked={validations}
            onChange={this.handleChange}
            name="validations"
          />
          <span>Validations</span>
        </label>

        <label className="analFilter">
          <Checkbox
            checked={earned}
            onChange={this.handleChange}
            name="earned"
          />
          <span>Earned</span>
        </label>

        <label className="analFilter">
          <Checkbox
            checked={redeemed}
            onChange={this.handleChange}
            name="redeemed"
          />
          <span>Redeemed</span>
        </label> */}
      </div>
    );
  }

  getContent(report, filteredAwards) {
    if (report === 0) {
      return (
        <div className="wizard-section-border-ten">
          <div className="section">
            {/* <h3>Dashboard</h3> */}
            <div>{this.getDashboard(this.state.awards)}</div>
          </div>
        </div>
      );
    } else if (report === 1) {
      return (
        <div className="wizard-section-border-ten">
          <div className="section">
            <h3>Players Report</h3>
            <div>{this.getUserLineChart(this.state.awards)}</div>
          </div>
        </div>
      );
    } else if (report === 2) {
      return (
        <div className="wizard-section-border-ten">
          <div className="section">
            <h3>Player Activity Report</h3>
            <div>{this.getLineChart(this.state.awards)}</div>
          </div>
        </div>
      );
    } else if (report === 3) {
      return (
        <div className="wizard-section-border-ten">
          <div className="section">
            <h3>Awards Report</h3>
            <Row className="no-padding">
              <Col xs={12} md={6} sm={12} lg={6}>
                {this.state.promo.rewards.map((reward, index) => (
                  <Col xs={12} md={12} sm={12} lg={12}>
                    {this.getRewards(reward, index)}
                  </Col>
                ))}
                {/* </Row> */}
                {/* </div> */}
                {/* <div className="section">
            <Row className="no-padding"> */}
              </Col>
              <Col xs={12} md={6} sm={12} lg={6}>
                {/* <div className="header-container">
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
          </div> */}
                <div>{this.getAwards(filteredAwards)}</div>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }

  getPicker(showPicker) {
    if (showPicker === true) {
      const modifiersStyles = {
        selected: {
          color: 'white',
          backgroundColor: '#f21d59',
        },
      };
      return (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="no-padding">
            <div id="pickerArea">
              <DayPicker
                onDayClick={this.handleEndDayClick}
                selectedDays={this.state.endSelected}
                modifiersStyles={modifiersStyles}
                disabledDays={{
                  before: this.state.startSelected,
                }}
                initialMonth={this.state.initialEndMonth}
                month={this.state.initialEndMonth}
              />
              <DayPicker
                onDayClick={this.handleStartDayClick}
                selectedDays={this.state.startSelected}
                modifiersStyles={modifiersStyles}
                initialMonth={this.state.initialStartMonth}
                month={this.state.initialStartMonth}
              />
              <div
                style={{
                  float: 'right',
                  top: '15px',
                  position: 'relative',
                }}
              >
                <span
                  onClick={() => {
                    this.setRange(1, this.state.promo);
                  }}
                  className="clear left red"
                  style={{ cursor: 'pointer' }}
                >
                  This Week
                </span>
                <span
                  onClick={() => {
                    this.setRange(2, this.state.promo);
                  }}
                  className="clear left red"
                  style={{ cursor: 'pointer' }}
                >
                  This Month
                </span>
                <span
                  onClick={() => {
                    this.setRange(3, this.state.promo);
                  }}
                  style={{ cursor: 'pointer' }}
                  className="clear left red "
                >
                  Start of Promo
                </span>{' '}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  }

  getPickerButton(showPicker) {
    console.log('showpicker', showPicker);
    if (showPicker === true) {
      return (
        <span>
          {' '}
          <MaterialIcon
            icon="keyboard_arrow_up"
            size={40}
            color="#f21d59"
            onClick={() => {
              this.showCalendarPicker(false);
            }}
          />
        </span>
      );
    } else {
      return (
        <span>
          <MaterialIcon
            icon="keyboard_arrow_down"
            size={40}
            color="#f21d59"
            onClick={() => {
              this.showCalendarPicker(true);
            }}
          />
        </span>
      );
    }
  }

  displayLink(menuItem, report) {
    if (menuItem.report === report) {
      return (
        <li className="activeReportLink">
          {/* <MaterialIcon
            icon={menuItem.icon}
            size={20}
            color="#f21d59"
          /> */}
          <span
            onClick={() => {
              this.setReport(menuItem.report);
            }}
            style={{
              padding: '10px 0px 5px 0px',
              color: 'white',
            }}
          >
            {menuItem.label}
          </span>
        </li>
      );
    } else {
      return (
        <li>
          {/* <MaterialIcon
            icon={menuItem.icon}
            size={20}
            color="#f21d59"
          /> */}

          <span
            onClick={() => {
              this.setReport(menuItem.report);
            }}
            style={{
              padding: '10px 0px 5px 0px',
              color: 'white',
            }}
          >
            {menuItem.label}
          </span>
        </li>
      );
    }
  }

  showCalendarPicker(mode) {
    this.setState({
      showCalendarPicker: mode,
    });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">{title}</span>

              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
              <div className="saveButtons">
                <Link
                  to={{
                    pathname: '/vendor/insights',
                  }}
                >
                  <span className="cancelButton">Close</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getAppContent(promo, filteredAwards) {
    return (
      <Row style={{ height: '100%' }}>
        <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
          <div className="section">
            <div className="wizard-section-border-ten">
              {/* <Row className="no-padding">
              <Col
                xs={6}
                md={6}
                sm={6}
                lg={6}
                className="left no-padding"
              >
                <p>
                  Created By:{' '}
                  <b>{promo.creationDetails.creator.fullName}</b>
                </p>
                <p>
                  Created:{' '}
                  <b>
                    {this.getDate(promo.creationDetails.createdDate)}
                  </b>
                </p>
              </Col>
              <Col
                xs={6}
                md={6}
                sm={6}
                lg={6}
                className="right no-padding"
              >
                {this.getActiveStatus(promo.active)}
              </Col>
            </Row
            > */}

              <Row className="no-padding">
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h3>{promo.title}</h3>
                </Col>

                <Col xs={6} md={6} sm={6} lg={6}>
                  <span className="right analTime">
                    {this.state.start} - {this.state.end}
                    <span
                      className="right"
                      style={{ top: '-5px', position: 'relative' }}
                    >
                      {' '}
                      {this.getPickerButton(
                        this.state.showCalendarPicker,
                      )}
                    </span>
                  </span>
                </Col>
              </Row>
              <div>
                {this.getPicker(this.state.showCalendarPicker)}
              </div>
            </div>
            {this.getContent(this.state.report, filteredAwards)}
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    if (this.state.showRewardDetails === true) {
      let reward = this.state.activeReward;
      return (
        <div id="admin">
          {this.getModalTitleBar()}
          {this.getAwardHistory(reward)}
          <style
            dangerouslySetInnerHTML={{
              __html: '#bottomNav { display: none } ',
            }}
          />
        </div>
      );
    }
    const filteredAwards = this.state.awards.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    let promo = this.state.promo;

    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <div className="appPickerDrawer">
            <div
              className="sidebar-logo"
              style={{ height: '80px', margin: '0px 0px 25px 0px' }}
            >
              <div className=" left">
                <center>
                  <img
                    src={logo}
                    style={{
                      maxWidth: '160px',
                      margin: '0px 0px 6px 0px',
                    }}
                  />
                  <h5>Experiences</h5>
                </center>
              </div>
              <span className="right">
                <MaterialIcon
                  icon="close"
                  size={30}
                  color="rgb(238 1 88)"
                  onClick={() => {
                    this.showPicker(false);
                  }}
                />
              </span>
            </div>

            <NavLink
              to={{
                pathname: '/vendor/home',
              }}
              activeClassName="activeMemuItem"
              style={{ float: 'left' }}
            >
              <span className="appTitle">Home Base</span>
            </NavLink>
            {/* <div className="appList"> */}
            <div style={{ margin: '100px 0px 0px 0px' }}>
              <span className="appListCategory">Tools</span>
              <NavLink
                to={{
                  pathname: '/vendor/experiences',
                }}
                activeClassName="activeMemuItem"
              >
                <span
                  className="appTitle"
                  style={{ fontWeight: '600' }}
                >
                  Workshop
                </span>
              </NavLink>
              <NavLink
                to={{
                  pathname: '/vendor/insights',
                }}
                activeClassName="activeMemuItem"
              >
                <span
                  className="appTitle"
                  style={{ fontWeight: '600' }}
                >
                  Insights
                </span>
              </NavLink>
              <NavLink
                to={{
                  pathname: '/vendor/validator',
                }}
                activeClassName="activeMemuItem"
              >
                <span
                  className="appTitle"
                  style={{ fontWeight: '600' }}
                >
                  Validator
                </span>
              </NavLink>
            </div>
            <div style={{ margin: '60px 0px 0px 0px' }}>
              <span className="appListCategory">MANAGE</span>
              <NavLink
                to={{
                  pathname: '/vendor/locations',
                }}
                activeClassName="activeMemuItem"
              >
                <span className="appTitle">Locations</span>
              </NavLink>
              <NavLink
                to={{
                  pathname: '/vendor/users',
                }}
                activeClassName="activeMemuItem"
              >
                <span className="appTitle">Users</span>
              </NavLink>
              <NavLink
                to={{
                  pathname: '/vendor/account',
                }}
                activeClassName="activeMemuItem"
              >
                <span className="appTitle">Account</span>
              </NavLink>
            </div>
          </div>
        )}
        {this.getTitleBar('Analyze')}
        <div
          className="vendorLeftNav"
          style={{
            padding: '10px',
            position: 'fixed',
            width: '105px',
          }}
        >
          <div style={{ marginTop: '15px' }}>
            <div id="right-filter">
              <ul className="drawerMenuList">
                {secondaryLinks.map((menuItem, index) => (
                  <div key={'key' + index}>
                    {this.displayLink(menuItem, this.state.report)}
                  </div>
                ))}
              </ul>
            </div>
            <span className="clear" style={{ color: 'white' }}>
              REPORTS
            </span>
            <div id="right-filter">
              <ul className="drawerMenuList">
                {mainLinks.map((menuItem, index) => (
                  <div key={'key' + index}>
                    {this.displayLink(menuItem, this.state.report)}
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div
          className="vendorContent"
          style={{ float: 'left', width: '90%', left: '100px' }}
        >
          {this.getAppContent(promo, filteredAwards)}
        </div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vendorPromos: state.vendorPromos.promos,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    profile: state.profile.profile,
  };
}

export default connect(mapStateToProps)(VendorPromoDetails);
