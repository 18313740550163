import { ErrorMessage, Field, Form, Formik } from 'formik';
import MaterialIcon from 'material-icons-react';
import React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../../config/firebase';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// Reducers
import { getUpdatedProfile } from '../../sagas/getProfileSaga/reducer';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  isUploading: false,
  progress: 0,
  avatar: '',
  cropThumb: {
    unit: '%',
    width: 30,
    aspect: 146 / 122,
  },
  cropping: false,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class ProfileEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      avatarURL: this.props.profile.profilePhotoPath,
    };
  }

  saveProfile(firstName, lastName, userName) {
    // this.props.setSignupStatus();
    this.setState({
      loaded: false,
    });
    const timestamp = new Date();
    const userRef = firebase
      .firestore()
      .collection('users')
      .doc(this.props.profile.userId);
    userRef
      .update({
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        // email: this.props.user.email,
        // sex: 0,
        // profilePhotoPath: "",
        lastUpdated: timestamp,
      })
      .then(() => {
        this.props.getUpdatedProfile(this.props.profile.userId);
      });
  }

  // handleUploadStart = () =>
  //   this.setState({ isUploading: true, progress: 0 });
  // handleProgress = (progress) => this.setState({ progress });
  // handleUploadError = (error) => {
  //   this.setState({ isUploading: false });
  //   console.error(error);
  // };
  // handleUploadSuccess = (filename) => {
  //   this.setState({
  //     avatar: filename,
  //     progress: 100,
  //     isUploading: false,
  //   });
  //   firebase
  //     .storage()
  //     .ref('profile_photos')
  //     .child(filename)
  //     .getDownloadURL()
  //     .then((url) => {
  //       this.setState({ avatarURL: url });
  //     })
  //     .then(() => {
  //       const db = firebase.firestore();
  //       const docToUpdate = db
  //         .collection('users')
  //         .doc(this.props.profile.userId);
  //       const updateDoc = docToUpdate.update({
  //         profilePhotoPath: this.state.avatarURL,
  //       });
  //     })
  //     .then(() => {
  //       this.props.getUpdatedProfile(this.props.profile.userId);
  //     });
  // };

  cancelCropperThumb() {
    this.setState({
      croppingThumb: false,
    });
  }

  saveCropperThumb() {
    this.uploadImage(this.state.blob, 'thumb');
    this.setState({
      croppingThumb: false,
    });
  }

  // Thumb Crop
  onSelectFileThumb = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      croppingThumb: true,
    });
  };

  onImageLoadedThumb = (image) => {
    this.imageRef = image;
  };

  onCropCompleteThumb = (crop) => {
    this.makeClientCropThumb(crop);
  };

  onCropChangeThumb = (cropThumb, percentCrop) => {
    this.setState({ cropThumb });
  };

  async makeClientCropThumb(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImgThumb(
        this.imageRef,
        crop,
        this.props.profile.userId + '.jpeg',
      );
      this.setState({
        thumbURL: croppedImageUrl,
      });
    }
  }

  getCroppedImgThumb(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getThumbnailCropper(thumbnailCropping) {
    const { cropThumb, croppedImageUrl, src } = this.state;
    if (thumbnailCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropperThumb();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropperThumb();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={cropThumb}
                onImageLoaded={this.onImageLoadedThumb}
                onComplete={this.onCropCompleteThumb}
                onChange={this.onCropChangeThumb}
              />
            )}
          </div>
        </div>
      );
    }
  }
  //End Thumb Crop

  uploadImage = (image, type) => {
    const uploadUrl = 'profile_photos/' + image.name;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadUrl);
    // imageRef.put(image).then((snapshot) => {
    //   const path = snapshot.ref.location.path_;

    //     let thumbnailImagePath =
    //       'event_images%2F' + this.state.dbref + '%2Fthumbnail.jpeg';
    //     let thumbPath =
    //       'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
    //       thumbnailImagePath +
    //       '?alt=media';
    //     this.setState({
    //       thumbPath,
    //       customThumb: true,
    //     });

    // });
  };

  render() {
    return (
      <div id="signup">
        <div>
          {this.getThumbnailCropper(this.state.croppingThumb)}
        </div>
        <div>
          <Formik
            initialValues={{
              firstName: this.props.profile.firstName,
              lastName: this.props.profile.lastName,
              userName: this.props.profile.userName,
            }}
            // validate={values => {
            //   let errors = {};
            //   if (!values.firstname) {
            //     errors.firstname = "Required";
            //   }
            //   return errors;
            // }}
            onSubmit={(values) => {
              this.saveProfile(
                values.firstName,
                values.lastName,
                values.userName,
              );
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div id="headersTop">
                  <div className="backbar">
                    <Row className="no-margin">
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="no-padding"
                      >
                        <Link
                          to={{
                            pathname: '/',
                          }}
                          className="left"
                        >
                          <MaterialIcon
                            icon="close"
                            size={25}
                            color="white"
                          />
                        </Link>
                      </Col>
                      <Col
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        className="no-padding"
                      >
                        <center>
                          <span className="page-header">
                            Edit Your Treveller Profile
                          </span>
                        </center>
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="no-padding"
                      >
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="right noBack"
                        >
                          <MaterialIcon
                            icon="check"
                            size={25}
                            color="white"
                          />
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="section">
                  <Container>
                    <Row>
                      <Col xs={12} md={6} sm={6} lg={6}>
                        <span className="inputLabel">First Name</span>
                        <Field
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                        />
                        <span className="inputLabel">Last Name</span>
                        <Field
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                        />
                        <span className="inputLabel">
                          Display Name
                        </span>
                        <Field
                          type="text"
                          name="userName"
                          placeholder="User Name"
                        />
                        <ErrorMessage
                          name="userName"
                          component="div"
                        />
                        {/* <span className="inputLabel">Gender</span>
                        <Field component="select" name="gender">
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other?</option>
                        </Field> */}
                        <ErrorMessage name="gender" component="div" />
                        <div className="submitarea">
                          {/* <button
                            type="submit"
                            disabled={isSubmitting}
                            className="left"
                          >
                            Save
                          </button> */}
                        </div>
                      </Col>
                      <Col xs={12} md={6} sm={6} lg={6}>
                        <span className="inputLabel">
                          Change Profile Photo
                        </span>
                        {this.state.isUploading && (
                          <p>Progress: {this.state.progress}</p>
                        )}
                        {this.state.avatarURL && (
                          <UserAvatar
                            size="100"
                            name={
                              this.props.profile.firstName +
                              this.props.profile.lastName
                            }
                            src={this.state.avatarURL}
                          />
                        )}
                        {/* <FileUploader
                          accept="image/*"
                          name="hero"
                          // randomizeFilename
                          filename={(file) =>
                            this.props.profile.userId
                          }
                          storageRef={firebase
                            .storage()
                            .ref('profile_photos')}
                          onUploadStart={this.handleUploadStart}
                          onUploadError={this.handleUploadError}
                          onUploadSuccess={this.handleUploadSuccess}
                          onProgress={this.handleProgress}
                        /> */}
                        <input
                          type="file"
                          onChange={this.onSelectFileThumb}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUpdatedProfile: (userId) => {
      dispatch(getUpdatedProfile(userId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileEditForm);
