import firebase from '../config/firebase';

export function getGuideTemplates() {
  const eventArray = [];
  const db = firebase.firestore();
  const guideTemplates = db.collection('guide_templates');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = guideTemplates
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const { title, categories, editHistory } = doc.data();
              eventArray.push({
                id: doc.id,
                title,
                categories,
                editHistory,
              });
            }
          });
          return eventArray;
        })
        .then((result) => {
          resolve(eventArray);
        });
    });
  };
  return getP();
}
