import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import { getSpotlight } from '../../sagas/setDailySpotlightSaga/reducer';
import { Link } from 'react-router-dom';

const query = require('array-query');

class DailyLocationSpotlight extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      dailySpotlight: {},
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.dailySpotlight.dailySpotlight == false) {
      let trevelPackages = [];
      this.props.packages.forEach(packageX => {
        if (packageX.category === 1) {
          trevelPackages.push(packageX);
        }
      });
      let item =
        trevelPackages[
          Math.floor(Math.random() * trevelPackages.length)
        ];
      this.props.getSpotlight(item);
      this.setState({
        loading: false,
        dailySpotlight: item,
      });
    } else {
      this.setState({
        loading: false,
        dailySpotlight: this.props.dailySpotlight,
      });
    }
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  getSpotlight(dailySpotlight) {
    let backgroundStyle = {
      backgroundImage:
        'linear-gradient(45deg,rgba(242, 29, 89, 1),rgba(245, 246, 252, 0)), url(' +
        this.getImage('hero', dailySpotlight) +
        ')',
      backgroundSize: 'cover',
      minHeight: '400px',
      backgroundPosition: 'center',
      position: 'relative',
    };

    let backgroundStyleMobile = {
      backgroundImage:
        'linear-gradient(45deg,rgba(242, 29, 89, 1),rgba(245, 246, 252, 0)), url(' +
        this.getImage('hero', dailySpotlight) +
        ')',
      backgroundSize: 'cover',
      minHeight: '200px',
      backgroundPosition: 'center',
      position: 'relative',
    };

    return (
      <Link
        to={{
          pathname: '/location/' + dailySpotlight.locationId,
          state: {
            location: { dailySpotlight },
            // eventId: { eventId },
            // token: { token },
            type: 500,
          },
        }}
      >
        <div style={backgroundStyle} className="mobileHide">
          <div className="spotlightText">
            <span className="spotlightTitle white">
              {dailySpotlight.locationTitle}
            </span>
            <span className="white">
              {this.truncate(dailySpotlight.locationDescription)}
            </span>
          </div>
        </div>
        <div style={backgroundStyleMobile} className="mobileShow">
          <div className="spotlightText">
            <span className="spotlightTitle white">
              {dailySpotlight.locationTitle}
            </span>
            <span className="white">
              {this.truncate(dailySpotlight.locationDescription)}
            </span>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    if (this.props.loading) {
      return null; // or render a loading icon
    }

    return <div>{this.getSpotlight(this.props.location)}</div>;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSpotlight: spotlight => {
      dispatch(getSpotlight(spotlight));
    },
  };
};

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    dailySpotlight: state.dailySpotlight,
    connectionsTagsAndFavs: state.tagsAndFavs.connectionsTagsAndFavs,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyLocationSpotlight);
