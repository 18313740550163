import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
// Components
import EventDetails from '../components/EventDetails';
import {
  approveEvent,
  editMode,
  rejectEvent,
} from '../sagas/customEventSaga/reducer';

const query = require('array-query');

export class Event extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
  }

  componentDidMount() {
    // if someone is editing for more then 5 hours clear.
    const event = this.getEvent(
      this.props.match.params.id,
      this.props.events,
    );
    if (
      event.editing.editing === true &&
      event.editing.editorId !== this.props.user.id
    ) {
      if (
        moment(event.editing.timestamp).isAfter(
          moment().subtract(5, 'hours'),
        ) === false
      ) {
        this.editMode(event);
      }
    }
  }

  editMode(event) {
    const timestamp = new Date();
    const bundle = {};
    bundle.event = event;
    const editing = {
      editing: true,
      editorId: this.props.profile.userId,
      timestamp,
    };
    bundle.editing = editing;
    this.props.editMode(bundle);
  }

  getEvent(id, events) {
    const event = query('eventId').is(id).on(events);
    return event[0];
  }

  getContent(event) {
    return <EventDetails event={event} events={this.props.events} />;
  }

  getTitleBar(event) {
    let backLink = '';
    if (event.status === 0) {
      backLink = '/events/upcoming';
    } else if (event.status === 9) {
      backLink = '/past-events';
    } else {
      backLink = '/events/upcoming';
    }
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: backLink,
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Event Details</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <center>
                <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      this.props.customEvent,
                      this.props.locations,
                      this.props.packages
                    );
                  }}
                />
              </center> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getOverlay(event, profile) {
    const plannerArray = [];
    Object.keys(event.users).forEach((key) => {
      let firstName = event.users[key].profile.fullName.replace(
        / .*/,
        '',
      );
      if (key === event.editing.editorId) {
        plannerArray.push({
          firstName: firstName,
          id: [key],
          fullName: event.users[key].profile.fullName,
          profilePhotoPath: event.users[key].profile.profilePhotoPath,
          approved: event.users[key].approved,
        });
      }
    });
    if (
      event.editing.editing === true &&
      event.editing.editorId !== profile.userId
    ) {
      return (
        <div id="editingOverlay">
          <div className="section">
            <Link
              to={{
                pathname: '/events/upcoming',
              }}
              className="left"
            >
              <MaterialIcon
                icon="keyboard_backspace"
                size={25}
                color="white"
              />
            </Link>
          </div>
          {plannerArray.map((planner, index) => (
            <div className="overlayContentArea">
              <UserAvatar
                size="140"
                name={planner.fullName}
                src={planner.profilePhotoPath}
              />

              <div className="overlayMessageArea">
                <span className="overlayMessage">
                  <span style={{ fontSize: '45px' }}>
                    {planner.firstName}
                  </span>
                  <br /> IS EDITING
                </span>
                <span className="loading" />
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  render() {
    const event = this.getEvent(
      this.props.match.params.id,
      this.props.events,
    );
    if (event === undefined) {
      this.props.history.push('/events/upcoming');
    }
    if (
      this.props.profile.userId === undefined ||
      event === undefined ||
      this.props.locations === undefined
    ) {
      return null; // or render a loading icon
    }

    return (
      <div style={{ height: '100%' }}>
        {this.getOverlay(event, this.props.profile)}
        {/* {this.getTitleBar(event)} */}
        <div id="event">{this.getContent(event)}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'html{background: white} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
        {/* <style
          dangerouslySetInnerHTML={{
            __html:
              '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    invite: state.invite.event,
    state: state,
    events: state.events.events,
    locations: state.locations.locations,
    profile: state.profile.profile,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    editMode: (bundle) => {
      dispatch(editMode(bundle));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);
