import { call, put, takeLatest } from 'redux-saga/effects';
import { getProfile } from '../getProfileSaga/reducer';
import { getDemos } from '../demoSaga/reducer';
import { getLocations } from '../locationsSaga/reducer';
import { getEvents } from '../userEventsSaga/reducer';
import { getTags } from '../tagsSaga/reducer';
import { init as getPackages } from '../packagesSaga/reducer';
import { getUsers } from '../usersSaga/reducer';
import { getVendors } from '../vendorsSaga/reducer';
import { getUauth } from '../unauthenticatedSaga/reducer';
import { getNotifications } from '../notificationsSaga/reducer';
import { getUserVouchers } from '../userVoucherSaga/reducer';
import { getUserAwards } from '../userAwardsSaga/reducer';
import { getAllEvents } from '../allEventsSaga/reducer';
import { getConnections } from '../connectionsSaga/reducer';
import { getArticles } from '../articleSaga/reducer';
import { getPromos } from '../promoSaga/reducer';
import { getPromoEvents } from '../eventsSaga/reducer';
import { getProfile as getProfileService } from '../../services/GetProfileServices';
import { getTimedEvents } from '../timedEventsSaga/reducer';
import { getEntertainment } from '../entertainmentSaga/reducer';
import { getRegions } from '../regionsSaga/reducer';
import { push } from 'connected-react-router';
import * as actions from './actions';

import { ContinuousColorLegend } from 'react-vis';

function* authhydrateAction(user) {
  console.log('hydrating!', user);
  let bundle = {};
  if (user.payload.payload !== undefined) {
    bundle = user.payload.payload;
  } else {
    bundle = user.payload;
  }
  const invitedUrl = '/event/' + bundle.eventId;
  // const invitedToEditUrl = '/setup/' + bundle.eventId;
  const invitedToEditUrl = '/event/' + bundle.eventId;

  let createEvent = false;
  if (bundle.createEvent !== undefined) {
    createEvent = bundle.createEvent;
  }
  let profileBundle = {
    id: bundle.id,
    createEvent: createEvent,
  };
  yield put(getProfile(profileBundle));
  yield put(getArticles());

  let profile = yield call(getProfileService, bundle.id);
  if (profile.zoneId !== undefined) {
    yield put(getLocations(profile.zoneId));
  } else {
    console.log('AUTHHYDRATE_APP - profile UNDEFINED');
  }
  if (profile.zoneId !== undefined) {
    yield put(getPackages(profile.zoneId));
  } else {
    console.log('AUTHHYDRATE_APP - PROFILE UNDEFINED');
  }
  if (profile.zoneId !== undefined) {
    yield put(getPromos(profile.zoneId));
  } else {
    console.log('AUTHHYDRATE_APP - PROFILE UNDEFINED');
  }

  yield put(getDemos());

  if (profile.zoneId !== undefined) {
    yield put(getPromoEvents(profile.zoneId));
  } else {
    console.log('AUTHHYDRATE_APP - PROFILE UNDEFINED');
  }

  if (profile.zoneId !== undefined) {
    yield put(getRegions(profile.zoneId));
  } else {
    console.log('AUTHHYDRATE_APP - PROFILE UNDEFINED');
  }

  // yield put(getNotifications(bundle.id));
  yield put(getEvents(bundle.id));
  yield put(getUserVouchers(bundle.id));
  // yield put(getUserAwards(bundle.id));
  yield put(getTags());
  yield put(getUsers());

  // yield put(getVendors());

  if (profile.zoneId !== undefined) {
    yield put(getTimedEvents(profile.zoneId));
  } else {
    console.log('AUTHHYDRATE_APP - PROFILE UNDEFINED');
  }
  if (profile.zoneId !== undefined) {
    yield put(getEntertainment(profile.zoneId));
  } else {
    console.log('AUTHHYDRATE_APP - PROFILE UNDEFINED');
  }

  // yield put(getAllEvents());
  yield put(getConnections(bundle.id));

  yield put({
    type: actions.AUTHHYDRATE_SUCCESS,
    payload: {
      hydrated: true,
    },
  });

  if (bundle.newuser === true) {
    if (bundle.invited === true) {
      // CHANGE THIS WE ADDED THE OR 1/ difference betwen invite with edit and just invite
      if (bundle.inviteType === 4 || 1) {
        yield put(push(invitedToEditUrl));
      } else {
        yield put(push(invitedUrl));
      }
    } else if (bundle.claimLink === true) {
      yield put(push('/vendor/signupprofile/' + bundle.claimId));
    } else if (bundle.fromClaim === true) {
      // yield put(push('/vendor/home'));
    } else if (bundle.fromClaim === false) {
      yield put(push('/trevel-experiences/signup'));
    } else if (
      bundle.createEvent !== undefined &&
      bundle.createEvent === true
    ) {
      yield put(push('/events/plan/1'));
    } else {
      // yield put(push('/setup'));
      // yield put(push('/home'));
      yield put(push('/profile/tag'));
    }
    // yield put(push('/signup/photo'));
  } else if (bundle.claimLink === true) {
    if (profile.vendorAccount !== undefined) {
      let bundle = {
        claimLink: bundle.claimLink,
        claimId: bundle.claimId,
        vendorId: profile.vendorAccount.id,
      };
      yield put(getUauth(bundle));
    }
  } else if (bundle.vendorSignup === true) {
    yield put(push('/trevel-experiences/signup'));
  } else if (
    bundle.eventId !== null &&
    bundle.eventId !== undefined
  ) {
    yield put(push(invitedUrl));
  } else if (
    bundle.createEvent !== undefined &&
    bundle.createEvent === true
  ) {
    yield put(push('/events/plan/1'));
  } else {
    yield put(push('/home'));
  }
}

export default function* sagas() {
  yield takeLatest(actions.AUTHHYDRATE_APP, authhydrateAction);
}
