import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
// Components
import TitleBar from '../../components/TitleBar';
import NewUsers from '../../components/Admin/Widgets/NewUsers';
import NewCustomLocations from '../../components/Admin/Widgets/NewCustomLocations';

export class Admin extends React.Component {
  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    }
    if (
      this.props.user.authenticated === true &&
      this.props.profile.userType !== 1
    ) {
      this.props.history.push('/');
    }
  }
  render() {
    return (
      <div id="admin">
        <TitleBar title="Dashboard" backlink="" />
        <div className="section">
          <Container>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <NewUsers />
              </Col>

              <Col lg={6} md={6} sm={12} xs={12}>
                <NewCustomLocations />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: state.profile.profile,
  };
}

export default connect(mapStateToProps)(Admin);
