import * as action from "./actions";

export const inviteAccept = token => {
  return {
    type: action.INVITE_ACCEPT,
    invite: token
  };
};

export const inviteMaybe = token => {
  return {
    type: action.INVITE_MAYBE,
    invite: token
  };
};

export const inviteEdit = token => {
  return {
    type: action.INVITE_EDIT,
    invite: token
  };
};

export const inviteReject = token => {
  return {
    type: action.INVITE_REJECT,
    invite: token
  };
};

export const inviteSuccess = invite => {
  return {
    type: action.INVITE_SUCCESS,
    payload: invite
  };
};

const ACTION_HANDLERS = {
  [action.INVITE_ACCEPT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.INVITE_MAYBE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.INVITE_EDIT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.INVITE_REJECT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.INVITE_SUCCESS]: (state, action) => {
    return { ...state, ...action.payload };
  }
};

let defaultState = {
  event: {
    title: null
  }
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
