import { call, put, takeLatest } from 'redux-saga/effects';
import { getVendors } from '../../services/VendorServices';
import * as actions from './actions';

let defaultState = {
  events: {},
};

function* getVendorsAction(action) {
  let vendors = yield call(getVendors);
  yield put({
    type: actions.SET_VENDORS,
    payload: {
      ...defaultState,
      vendors,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_VENDORS, getVendorsAction);
}
