import * as action from './actions';

export const getVendorTimedEvents = (vendorId) => {
  return {
    type: action.GET_VENDORTIMEDEVENTS,
    payload: {},
    vendorId: vendorId,
  };
};

export const setVendorTimedEvents = (events) => {
  return {
    type: action.SET_VENDORTIMEDEVENTS,
    payload: events,
  };
};

const ACTION_HANDLERS = {
  [action.SET_VENDORTIMEDEVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_VENDORTIMEDEVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  events: [],
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
