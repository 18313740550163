import React from 'react';
import { connect } from 'react-redux';

export class TopicYourPreferences extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div id="helpTopic">
        <div className="wizard-section-border noTopPadding">
          <h6>Your Preferences</h6>
          <p>
            By selecting your likes and dislikes, it helps our
            recommendation engine help sort through all of our
            location data to select locations that you are really
            going to enjoy.
          </p>
          <p>
            Our recommendation engine also takes into account the
            likes and dislikes of your guests. Preferences shared by
            you and your guest(s) will show up in red to help let you
            know that is a shared interest.
          </p>
          {/* <Card>
            <a id='inviteNow' className='red helpTopic'>
              Invite Someone Now
            </a>
            <UncontrolledCollapse toggler='#inviteNow'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card>
          <Card>
            <a id='inviteLater' className='red helpTopic'>
              Invite Someone Later
            </a>
            <UncontrolledCollapse toggler='#inviteLater'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {};
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
    user: state.user,
    events: state.events.events,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicYourPreferences);
