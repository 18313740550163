import firebase from '../config/firebase';

export function savePurchase(payload) {
  const db = firebase.firestore();
  const event = payload.event;

  const getP = function () {
    if (payload.eventId !== undefined) {
      const id = payload.eventId;
      return new Promise((resolve, reject) => {
        const setDoc = db
          .collection('user_events')
          .doc(id)
          .set(event)
          .then((ref) => {
            // console.log("Added document with ID: ", ref);
            return ref;
          })
          .then((result) => {
            resolve(result);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        const setDoc = db
          .collection('user_events')
          .add(event)
          .then((ref) => {
            // console.log("Added document with ID: ", ref);
            return ref;
          })
          .then((result) => {
            resolve(result);
          });
      });
    }
  };
  return getP();
}

export function saveVouchers(vouchers) {
  const db = firebase.firestore();

  var voucherCounter = Object.keys(vouchers);
  var voucherCount = voucherCounter.length;

  const getP = function () {
    if (voucherCount > 0) {
      vouchers.forEach((voucher, i) => {
        const setDoc = db
          .collection('user_vouchers')
          .add(voucher)
          .then((ref) => {
            console.log('Added document with ID: ', ref);
          });
        return 'success';
      });
    } else {
      // console.log("nope");
    }
  };
  return getP();
  // const db = firebase.firestore();
  // const getP = function() {
  //   for (const key in vouchers) {
  //     if (vouchers.hasOwnProperty(key)) {
  //       const setDoc = db
  //         .collection("user_vouchers")
  //         .add(vouchers[key])
  //         .then(ref => {
  //           console.log("Added document with ID: ", ref);
  //         });
  //     }
  //   }
  //   return "success";
  // };
  // return getP();
}

export function saveVoucher(voucher) {
  const db = firebase.firestore();
  const getP = function () {
    const setDoc = db
      .collection('user_vouchers')
      .add(voucher)
      .then((ref) => {
        console.log('Added document with ID: ', ref);
      });
    return 'success';
  };
  return getP();
}

export function saveCustomEvent(customEvent) {
  const db = firebase.firestore();
  const getP = function () {
    return new Promise((resolve, reject) => {
      const eventId = '';
      // Add a new document in collection "cities" with ID 'LA'
      const setDoc = db
        .collection('packages')
        .doc(customEvent.id)
        .set(customEvent)
        .then((ref) => {
          // console.log("Added document with ID: ", ref);
          return ref;
        })
        .then((result) => {
          resolve(result);
        });
    });
  };
  return getP();
}
