import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getProfile,
  saveState,
} from '../../services/GetProfileServices';
import { push } from 'connected-react-router';
import * as actions from './actions';
import { getVendor } from '../vendorSaga/reducer';
import { getVendors } from '../vendorsSaga/reducer';
import { getAdminUsers } from '../adminUsersSaga/reducer';
import { clearUser } from '../loginSaga/reducer';
import { getLocations } from '../locationsSaga/reducer';
import { init as getPackages } from '../packagesSaga/reducer';

let defaultState = {
  profile: {
    profile: {
      userType: 0,
    },
  },
};

function* getProfileAction(action) {
  let id = '';
  if (action.payload.id !== undefined) {
    id = action.payload.id;
  } else {
    id = action.payload;
  }
  let createEvent = false;
  if (action.payload.createEvent !== undefined) {
    createEvent = action.payload.createEvent;
  }
  let profile = yield call(getProfile, id);

  if (
    profile.vendorAccount !== undefined &&
    profile.vendorAccount !== null
  ) {
    let path = '/vendor/home';
    if (
      profile.vendorAccount.vendorType !== undefined &&
      profile.vendorAccount.vendorType === 1
    ) {
      path = '/vendor/locations/add';
    } else if (
      profile.vendorAccount.vendorType !== undefined &&
      profile.vendorAccount.vendorType === 2
    ) {
      path = '/vendor/entertainment/add';
    }

    let bundle = {
      claim: false,
      userId: profile.vendorAccount.id,
      path,
      vendorType: profile.vendorAccount.vendorType,
      createEvent,
    };

    yield put(getVendor(bundle));
    yield put(getVendors());
    if (profile.userType !== undefined && profile.userType === 1) {
      yield put(getAdminUsers(bundle));
    }
  }

  yield put({
    type: actions.SET_PROFILE,
    payload: {
      ...defaultState,
      profile,
    },
  });
}

function* getUpdatedProfileAction(action) {
  let profile = yield call(getProfile, action.payload);
  yield put({
    type: actions.SET_PROFILE,
    payload: {
      ...defaultState,
      profile,
    },
  });
  yield put(push('/profile'));
}

function* getUpdatedProfileFavortiesAction(action) {
  let profile = yield call(getProfile, action.payload);
  yield put({
    type: actions.SET_PROFILE,
    payload: {
      ...defaultState,
      profile,
    },
  });
}

function* saveStateAction(action) {
  let zoneId = 0;
  const state = action.payload.state;
  const userId = action.payload.userId;

  const zoneOne = ['KS', 'MO', 'NE', 'OK', 'CO', 'IL'];
  const zoneTwo = ['MN', 'WI'];
  const zoneThree = ['TX'];
  const zoneFour = ['KY', 'TN', 'PA', 'DC', 'GA', 'MA'];
  const zoneFive = ['WA', 'OR', 'CA', 'NV', 'AZ'];

  if (zoneOne.indexOf(state) + 1) {
    zoneId = 1;
  } else if (zoneTwo.indexOf(state) + 1) {
    zoneId = 2;
  } else if (zoneThree.indexOf(state) + 1) {
    zoneId = 3;
  } else if (zoneFour.indexOf(state) + 1) {
    zoneId = 4;
  } else if (zoneFive.indexOf(state) + 1) {
    zoneId = 5;
  }

  const bundle = {
    userId,
    state,
    zoneId,
  };
  yield put(saveState(bundle));
  yield put(getLocations(bundle.zoneId));
  yield put(getPackages(bundle.zoneId));
  let profile = yield call(getProfile, action.payload.userId);
  yield put({
    type: actions.SET_PROFILE,
    payload: {
      ...defaultState,
      profile,
    },
  });
  // yield put(push('/events/home'));
}

function* clearProfileAction(action) {
  yield put(clearUser());
}

export default function* sagas() {
  yield takeLatest(actions.GET_PROFILE, getProfileAction);
  yield takeLatest(actions.CLEAR_PROFILE, clearProfileAction);
  yield takeLatest(
    actions.GET_UPDATEDPROFILE,
    getUpdatedProfileAction,
  );
  yield takeLatest(actions.SAVE_STATE, saveStateAction);
  yield takeLatest(
    actions.GET_UPDATEDPROFILEFAVS,
    getUpdatedProfileFavortiesAction,
  );
}
