import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
// Components
import AdminArticlesForm from "../../components/AdminArticlesForm";
import TitleBar from "../../components/TitleBar";

const query = require("array-query");

export class AdminArticlesEdit extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getContent(id, array) {
    if (id !== undefined) {
      const contentDetails = query("id")
        .is(id)
        .on(array);
      return contentDetails[0];
    } else {
      return false;
    }
  }

  getForm() {
    if (this.props.match.params.id !== undefined) {
      const doc = this.getContent(
        this.props.match.params.id,
        this.props.articles
      );
      return <AdminArticlesForm editableDoc={doc} new={false} />;
    } else {
      return <AdminArticlesForm editableDoc={""} new={true} />;
    }
  }

  render() {
    return (
      <div id="admin">
        <TitleBar title="Edit/Add Article" backlink="/admin/articles" />
        <div className="section">
          <Container>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12}>
                {this.getForm()}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    articles: state.articles.articles
  };
}

export default connect(mapStateToProps)(AdminArticlesEdit);
