import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import MaterialIcon from 'material-icons-react';
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from 'reactstrap';
// Reducers
import { scheduleEvent } from '../../sagas/userEventsSaga/reducer';

const format = 'h:mm a';
const now = moment().hour(0).minute(0);
const query = require('array-query');

class ScheduleModal extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      screen: 1,
      selectedDay: null,
      selectedTime: '10:00 a.m.',
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    const defaultTime = moment(
      this.props.event.scheduledTime.timeString,
      'h:mm a',
    );
    this.setState({ selectedTime: defaultTime });
  }

  changeScreen(screen) {
    this.setState({
      screen,
    });
  }

  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  }

  onTimeChange = (time) => {
    this.setState({ selectedTime: time });
  };

  saveSchedule(date, selectedTime) {
    const datestring = String(selectedTime);
    const newtime = datestring.split(' ');
    const time = newtime[4].split(':'); // convert to array
    // fetch
    const hours = Number(time[0]);
    const minutes = Number(time[1]);
    const seconds = Number(time[2]);
    // calculate
    let timeValue;
    if (hours > 0 && hours <= 12) {
      timeValue = `${hours}`;
    } else if (hours > 12) {
      timeValue = `${hours - 12}`;
    } else if (hours == 0) {
      timeValue = '12';
    }
    timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`; // get minutes
    // timeValue += seconds < 10 ? `:0${seconds}` : `:${seconds}`; // get seconds
    timeValue += hours >= 12 ? ' P.M.' : ' A.M.'; // get AM/PM

    const timestamp = moment(date).unix();
    const timestampReady = timestamp * 1000;

    const dateString = moment.unix(timestamp).format('YYYY-MM-DD');

    const bundle = {
      date: dateString,
      time: timeValue,
      timeString: selectedTime._d,
      timestamp: timestampReady,
      eventId: this.props.event.eventId,
      userId: this.props.profile.userId,
    };
    this.props.scheduleEvent(bundle);
    this.props.closeScheduleModal();
  }

  render() {
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
      },
    };
    return (
      <div>
        <div className="modalHeader">
          <a
            onClick={() => {
              this.props.closeScheduleModal();
            }}
          >
            <div className="modalCloser text-center">
              <MaterialIcon
                icon="keyboard_arrow_down"
                size={25}
                color="white"
              />
            </div>
          </a>
          <a
            onClick={() => {
              this.props.closeScheduleModal();
            }}
          >
            <span className="modalHeader-text">Schedule Event</span>
          </a>
        </div>
        <div className="section">
          <Container>
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <div>
                  <div className="text-center">
                    <span className="inputLabel">Time</span>
                    <TimePicker
                      showSecond={false}
                      // defaultValue={this.state.selectedTime}
                      className="xxx"
                      onChange={this.onTimeChange}
                      format={format}
                      use12Hours
                      inputReadOnly
                    />
                  </div>
                  <div className="text-center">
                    {/* {this.state.selectedDay ? (
              <p>You clicked {this.state.selectedDay.toLocaleDateString()}</p>
            ) : (
              <p>Please select a day.</p>
            )} */}
                    <DayPicker
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiersStyles={modifiersStyles}
                    />
                    <p>
                      {this.state.selectedDay
                        ? this.state.selectedDay.toLocaleDateString()
                        : 'Please select a day'}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <button
                  className="redbutton"
                  onClick={() => {
                    this.saveSchedule(
                      this.state.selectedDay,
                      this.state.selectedTime,
                    );
                  }}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    packages: state.packages.packages,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    scheduleEvent: (bundle) => {
      dispatch(scheduleEvent(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleModal);
