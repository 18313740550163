import { call, put, takeLatest } from "redux-saga/effects";
import { getVendorUserEvents } from "../../services/VendorServices";

import * as actions from "./actions";

let defaultState = {
  userEvents: [],
};

function* getVendorUserEventsAction(vendorId) {
  if (vendorId !== undefined) {
    let userEvents = yield call(getVendorUserEvents, vendorId.vendorId);
    yield put({
      type: actions.SET_VENDORUSEREVENTS,
      payload: {
        ...defaultState,
        userEvents,
      },
    });
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_VENDORUSEREVENTS, getVendorUserEventsAction);
}
