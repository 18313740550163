import firebase from "../config/firebase";

const _ = require("lodash");

export function getNotifications(userId) {
  const notificationArray = [];
  const notificationUserArray = [];
  const notificationSenderArray = [];
  const db = firebase.firestore();
  const notifications = db.collection("notifications");

  const getS = function () {
    return new Promise((resolve, reject) => {
      const query = notifications
        .where("senderId", "==", userId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                status,
                dateSent,
                locations,
                message,
                senderId,
                type,
                senderName,
                senderLastName,
                profilePhotoPath,
                userId,
                receiverName,
                userPurchaseId,
                eventId,
                usersAttending,
                replyMessage,
                receiverPhotoPath,
                editable,
              } = doc.data();
              // const expireDateString = expireDate.toDateString();
              // const purchaseDateString = purchaseDate.toDateString();
              notificationSenderArray.push({
                notificationId: doc.id,
                status,
                dateSent,
                locations,
                message,
                senderId,
                type,
                senderName,
                senderLastName,
                profilePhotoPath,
                userId,
                receiverName,
                userPurchaseId,
                eventId,
                usersAttending,
                replyMessage,
                receiverPhotoPath,
                editable,
              });
            }
          });
          return notificationSenderArray;
        })
        .then((result) => {
          // const final_vouchers = result[0]
          resolve(notificationSenderArray);
        });
    });
  };

  const getU = function () {
    return new Promise((resolve, reject) => {
      const query = notifications
        .where("userId", "==", userId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                status,
                dateSent,
                locations,
                message,
                senderId,
                type,
                senderName,
                profilePhotoPath,
                userId,
                receiverName,
                userPurchaseId,
                eventId,
                usersAttending,
                replyMessage,
                receiverPhotoPath,
                userName,
              } = doc.data();
              // const expireDateString = expireDate.toDateString();
              // const purchaseDateString = purchaseDate.toDateString();
              notificationUserArray.push({
                notificationId: doc.id,
                status,
                dateSent,
                locations,
                message,
                senderId,
                type,
                senderName,
                profilePhotoPath,
                userId,
                receiverName,
                userPurchaseId,
                eventId,
                usersAttending,
                replyMessage,
                receiverPhotoPath,
                userName,
              });
            }
          });
          return notificationUserArray;
        })
        .then((result) => {
          // const final_vouchers = result[0]
          resolve(notificationUserArray);
        });
    });
  };

  const getF = function () {
    return new Promise((resolve, reject) => {
      const notificationsTemp = notificationUserArray.concat(
        notificationSenderArray
      );
      const notificationArray = _.uniqBy(notificationsTemp, "notificationId");
      resolve(notificationArray);
    });
  };

  return getS()
    .then((result) => getU())
    .then((result) => getF());
}
