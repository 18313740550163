import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import firebase from '../../config/firebase';
import Select from 'react-select';

const query = require('array-query');

export class AdminVendorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedOption: null,
      loading: true,
      businessName: '',
      phone: '',
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
    };
  }

  componentDidMount() {
    if (this.props.new === false) {
      this.setCurrentValues();
    }
    this.setAutoCompleteOptions();
    this.setState({
      loading: false,
    });
  }

  setAutoCompleteOptions() {
    const options = [];
    this.props.locations.forEach((location) => {
      options.push({
        value: location.locationId,
        label: location.locationTitle,
      });
    });
    this.setState({
      locationOptions: options,
    });
  }

  setCurrentValues() {
    console.log(
      'this.props.editableDoc.locations',
      this.props.editableDoc.locations,
    );
    const selectedLocations = [];
    const locs = this.props.editableDoc.locations;

    locs.forEach((locationId) => {
      const locationDetails = query('locationId')
        .is(locationId)
        .on(this.props.locations);
      selectedLocations.push({
        value: locationId,
        label: locationDetails[0].locationTitle,
      });
    });
    this.setState({
      selectedOption: selectedLocations,
      id: this.props.editableDoc.id,
      businessName: this.props.editableDoc.businessName,
      phone: this.props.editableDoc.primaryContact.phone,
    });
  }

  getUserOptions(users) {
    const options = [];
    users.forEach((user) => {
      const name = user.firstName + ' ' + user.lastName;
      options.push({
        value: user.userId,
        label: name,
      });
    });
    return options;
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  saveDoc(id, values) {
    const db = firebase.firestore();
    const timestamp = new Date();
    const locations = [];
    this.state.selectedOption.forEach((selectedOption) => {
      const id = selectedOption.value;
      locations.push({
        locationId: id,
      });
    });
    if (this.props.new === false) {
      const docToUpdate = db.collection('vendors').doc(id);
      const updateDoc = docToUpdate
        .update({
          businessName: values.businessName,
          primaryContact: {
            phone: values.phone,
          },
          lastUpdated: timestamp,
          locations: locations,
        })
        .then(() => {
          this.props.history.push('/admin/vendors');
        });
    } else {
      db.collection('vendors')
        .add({
          businessName: values.businessName,
          primaryContact: {
            phone: values.phone,
          },
          created: timestamp,
          locations: locations,
        })
        .then(() => {
          this.props.history.push('/admin/vendors');
        });
    }
  }

  toggleClearable = () =>
    this.setState((state) => ({ isClearable: !state.isClearable }));
  toggleDisabled = () =>
    this.setState((state) => ({ isDisabled: !state.isDisabled }));
  toggleLoading = () =>
    this.setState((state) => ({ isLoading: !state.isLoading }));
  toggleRtl = () =>
    this.setState((state) => ({ isRtl: !state.isRtl }));
  toggleSearchable = () =>
    this.setState((state) => ({ isSearchable: !state.isSearchable }));

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl,
    } = this.state;
    const { selectedOption } = this.state;
    const userOptions = this.getUserOptions(this.props.users);
    return (
      <div id="admin">
        <div>
          <Formik
            initialValues={{
              businessName: this.state.businessName,
              phone: this.state.phone,
            }}
            // validate={values => {
            //   let errors = {};
            //   if (!values.firstName) {
            //     errors.firstName = "Required";
            //   }
            //   return errors;
            // }}
            onSubmit={(values) => {
              this.saveDoc(this.state.id, values);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Container>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="left"
                    >
                      <br />
                      <div className="border-box greyback">
                        <span>
                          <span className="inputLabel">
                            ID: {this.state.id}
                          </span>
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} sm={6} lg={6} className="left">
                      <span className="inputLabel">
                        Business Name
                      </span>
                      <Field
                        type="text"
                        name="businessName"
                        placeholder="Business Name"
                      />
                      <ErrorMessage
                        name="businessName"
                        component="div"
                      />
                    </Col>
                    <Col xs={6} md={6} sm={6} lg={6}>
                      <span className="inputLabel">Phone Number</span>
                      <Field
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                      />
                      <ErrorMessage name="phone" component="div" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <span className="inputLabel">Locations</span>
                      <Select
                        value={selectedOption}
                        onChange={(value) => {
                          this.handleOnChange(value);
                        }}
                        options={this.state.locationOptions}
                        isMulti={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <span className="inputLabel">Admin User</span>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={userOptions}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="left"
                    >
                      <br />
                      <div className="submitarea">
                        <button
                          type="submit"
                          className="redbutton"
                          disabled={isSubmitting}
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locations: state.locations.locations,
    users: state.users.users,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminVendorForm);
