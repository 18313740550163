import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import DailyEntertainerSpotlight from '../../components/DailyEntertainerSpotlight';

class GuideCategoryEntertainerType extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {}

  findPromotedLocations(entertainment, category, tagCat) {
    let upcomingEvents = entertainment;

    let setTag = undefined;
    let slug = category.replace(/-/g, ' ');
    let categoryReady = slug.charAt(0).toUpperCase() + slug.slice(1);

    // Combine Tags'
    let tags = this.props.tags;
    let promotedLocations = [];

    function getPromoted(locations) {
      let promotedLocationIds = [];

      const tagsEntertainmentType = [];
      let entertainmentType = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Entertainment Type') {
            tagsEntertainmentType.push(tag.tag);
          }
        });
      });

      upcomingEvents.forEach((location) => {
        if (tagsEntertainmentType.includes(categoryReady) === true) {
          if (
            location.tagsEntertainmentType !== undefined &&
            location.tagsEntertainmentType.length !== 0
          ) {
            location.tagsEntertainmentType.forEach((tagx) => {
              if (tagx === categoryReady) {
                const status = promotedLocationIds.includes(
                  location.entertainmentId,
                );
                if (status === false) {
                  promotedLocationIds.push(location.entertainmentId);
                  promotedLocations.push(location);
                }
              }
            });
          }
        }
      });
      return promotedLocations;
    }

    let i = 0;

    while (promotedLocations.length === 0 && i !== 10) {
      promotedLocations = getPromoted(entertainment);
      i++;
    }

    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
    let shuffledLocations = shuffleArray(promotedLocations);
    let topThree = [];
    let topThreeIds = [];
    shuffledLocations.forEach((location) => {
      const status = topThreeIds.includes(location.entertainmentId);
      if (status === false) {
        topThreeIds.push(location.entertainmentId);
        topThree.push(location);
      }
    });

    let bundle = {
      promotedLocations: topThree,
      setTag,
    };

    return bundle;
  }

  getTagCount(userLove) {
    let userTags = [];
    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };
    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };
    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };
    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };
    let allTags = {
      tagsBeverages: [],
      tagsDateType: [],
      tagsSetting: [],
      tagsCuisine: [],
    };
    // Combine Tags

    userLove.tagsBeverages.forEach((tagcat, x) => {
      let n = allTags.tagsBeverages.includes(tagcat);
      if (n === false) {
        allTags.tagsBeverages.push(tagcat);
      }
    });

    userLove.tagsDateType.forEach((tagcat, x) => {
      let n = allTags.tagsDateType.includes(tagcat);
      if (n === false) {
        allTags.tagsDateType.push(tagcat);
      }
    });

    userLove.tagsSetting.forEach((tagcat, x) => {
      let n = allTags.tagsSetting.includes(tagcat);
      if (n === false) {
        allTags.tagsSetting.push(tagcat);
      }
    });

    userLove.tagsCuisine.forEach((tagcat, x) => {
      let n = allTags.tagsCuisine.includes(tagcat);
      if (n === false) {
        allTags.tagsCuisine.push(tagcat);
      }
    });

    let tagCount =
      allTags.tagsCuisine.length +
      allTags.tagsSetting.length +
      allTags.tagsDateType.length +
      allTags.tagsBeverages.length;
    allTags.tagsSetting;

    return tagCount;
  }

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    return to;
  }

  getSingle(promotedLocations) {
    let location = promotedLocations[0];
    if (promotedLocations.length === 1) {
      return (
        <div>
          <Link
            to={{
              pathname: '/entertainment/' + location.slug,
              state: {
                location: { location },
                // eventId: { eventId },
                // token: { token },
                type: '',
              },
            }}
          >
            <div>
              <img
                src={location.entertainmentHero}
                style={{ width: '100%' }}
              />
              <div className="section">
                <span className="title">
                  {location.entertainmentTitle}
                </span>

                <span
                  dangerouslySetInnerHTML={{
                    __html: location.eentertainmentDescription,
                  }}
                  className="editoOverRide"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span className="mainTagClear">{location.tagsMain}</span>
      );
    }
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  render() {
    let entertainment = [];
    if (this.props.entertainment !== undefined) {
      entertainment = this.props.entertainment;
    }
    const bundle = this.findPromotedLocations(
      entertainment,
      this.props.category,
      this.props.tagCat,
    );

    let promotedLocations = bundle.promotedLocations;

    let count = this.props.count;

    if (promotedLocations.length > 0) {
      return (
        <div>
          <div>
            <DailyEntertainerSpotlight
              location={promotedLocations[0]}
            />
          </div>
          <div className="homeSectionAll" id="guide-category">
            {promotedLocations.length > 1 && (
              <div>
                <Container>
                  <Row style={{ marginBottom: '30px' }}>
                    {promotedLocations.map((location, index) => (
                      <Col
                        xs={6}
                        md={4}
                        sm={6}
                        lg={3}
                        className="no-padding"
                      >
                        <div
                          style={{
                            border: '0px solid rgba(0, 0, 0, 0.125)',
                            background: 'white !important',
                          }}
                          className="multiSlide"
                        >
                          <Link
                            to={{
                              pathname:
                                '/entertainment/' + location.slug,
                              state: {
                                location: { location },
                                // eventId: { eventId },
                                // token: { token },
                                type: 500,
                              },
                            }}
                          >
                            <CardImg
                              top
                              width="100%"
                              src={
                                location.entertainmentHero !== ''
                                  ? location.entertainmentHero
                                  : 'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgenericmusichero.png?alt=media&token=7462811e-567a-4585-9c48-97882b9270e2'
                              }
                              alt="articleHero"
                            />
                            <CardBody style={{ textAlign: 'left' }}>
                              <div
                                style={{
                                  paddingTop: '10px',
                                  border: '0px',
                                }}
                              >
                                <span className="title">
                                  {location.entertainmentTitle}
                                </span>

                                {/* {this.getMainTag(location)} */}
                                {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                                <CardText>
                                  <span className="editoOverRide">
                                    {this.truncate(
                                      location.entertainmentDescription,
                                    )}
                                  </span>
                                </CardText>
                                <CardText />
                              </div>
                            </CardBody>
                          </Link>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            )}
            {this.getSingle(promotedLocations)}
          </div>
        </div>
      );
    }
    return null; // or render a loading icon
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    tags: state.tags.tags,
  };
}

export default connect(mapStateToProps)(GuideCategoryEntertainerType);
