import firebase from '../config/firebase';

export function getProfile(userId) {
  console.log('whatup', userId);
  const profile = [];
  const db = firebase.firestore();
  const getP = function () {
    return new Promise((resolve, reject) => {
      const docRef = db.collection('users').doc(userId);
      docRef
        .get()
        .then((doc) => {
          if (doc && doc.exists) {
            const {
              firstName,
              lastName,
              userName,
              profilePhotoPath,
              userId,
              sex,
              email,
              userType,
              vendorAccount,
              tags,
              zones,
              zoneId,
              state,
              favorites,
              favoriteEntertainment,
            } = doc.data();
            profile.push({
              userId: doc.id,
              firstName,
              lastName,
              userName,
              profilePhotoPath,
              sex,
              email,
              userType,
              vendorAccount,
              tags,
              zones,
              zoneId,
              state,
              favorites,
              favoriteEntertainment,
            });
          }

          return profile;
        })
        .then((result) => {
          const final_profile = result[0];
          resolve(final_profile);
        });
    });
  };
  return getP();
}

// Save Event
export function saveState(bundle) {
  const db = firebase.firestore();
  const getP = function () {
    const id = bundle.userId;
    return new Promise((resolve, reject) => {
      const userUpdate = db.collection('users').doc(id);
      const zoneId = bundle.zoneId;
      const state = bundle.state;
      const setDoc = userUpdate
        .update({
          zoneId,
          state,
        })
        .then((result) => {
          resolve(result);
        });
    });
  };
  return getP();
}
