import React from 'react';
import { connect } from 'react-redux';
import VendorClaimReadyContent from '../../components/VendorClaimReadyContent';
// Components
import { vendorHydrateApp } from '../../sagas/hydrateSaga/reducer';
import { getUauth } from '../../sagas/unauthenticatedSaga/reducer';

const query = require('array-query');

export class VendorClaimReady extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      locationId: '',
      aboutMode: 0,
    };
  }

  componentDidMount() {
    console.log(
      'this.props.match.params.id',
      this.props.match.params.id,
    );
    let vendorId = null;
    if (this.props.user.authenticated === true) {
      if (this.props.profile.vendorAccount !== null) {
        vendorId = this.props.profile.vendorAccount.id;
      }
    }

    let newUauth = {
      claimLink: true,
      claimId: this.props.match.params.id,
      vendorId,
      vendorType: 1,
    };
    this.props.getUauth(newUauth);

    if (this.props.location.search === '?source=qr') {
      this.props.history.push('/vendor/signup');
    }
  }

  render() {
    if (this.props.locations === undefined) {
      return null; // or render a loading icon
    }
    return (
      <div id="vendorClaim">
        <VendorClaimReadyContent
          locationId={this.props.match.params.id}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    locations: state.locations.locations,
    uauth: state.auath,
    user: state.user,
    profile: state.profile.profile,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    vendorHydrateApp: (id) => {
      dispatch(vendorHydrateApp(id));
    },
    getUauth: (bundle) => {
      dispatch(getUauth(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorClaimReady);
