import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';

import VendorCommunityMenu from '../../components/VendorCommunityMenu';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';

const query = require('array-query');

const KEYS_TO_FILTERS = [
  'locationTitle',
  'address.city',
  'address.street',
  'tagsBeverages',
  'tagsCuisine',
  'tagsSettings',
  'tagsDateType',
];

export class VendorCommunityDestinationsDirectory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
      searchTerm: '',
    };
    this.showPicker = this.showPicker.bind(this);
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    }
    if (this.props.user.authenticated === true) {
      let locationStatus = false;
      let entertainmentStatus = false;
      locationStatus = this.props.vendor.vendorTypes.includes(1);
      entertainmentStatus = this.props.vendor.vendorTypes.includes(2);
      if (locationStatus === true) {
        if (this.props.vendor.locations.length === 0) {
          this.props.history.push('/vendor/locations/add');
        }
      } else if (entertainmentStatus === true) {
        if (this.props.vendor.entertainment.length === 0) {
          this.props.history.push('/vendor/entertainment/add');
        }
      }
    }
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }

  componentDidMount() {
    document.title = 'Trevel Experiences';
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getTitleBar(title, rewards) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={10}
              md={10}
              sm={10}
              xs={10}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">
                <span style={{ color: '#f21d59', fontSize: '22px' }}>
                  Trevel{' '}
                </span>
                Community
              </span>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right mobileHide">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                  className
                />
              </span>
              {rewards.length > 0 ? (
                <Link
                  to={{
                    pathname: '/vendor/validator',
                  }}
                >
                  <span
                    style={{
                      padding: '0px 10px 0px 0px',
                      float: 'right',
                      top: '-2px',
                      display: 'block',
                      position: 'relative',
                    }}
                  >
                    <MaterialIcon
                      icon="notifications_active"
                      size={35}
                      color="rgb(242 29 89)"
                    />
                  </span>
                </Link>
              ) : (
                <div />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getLocations(vendors) {
    let locations = [];
    vendors.forEach((vendor) => {
      vendor.locations.forEach((locationId) => {
        const locationDetails = query('locationId')
          .is(locationId)
          .on(this.props.locations);
        if (locationDetails[0] !== undefined) {
          locationDetails[0].vendorName = vendor.businessName;
          locationDetails[0].phone = vendor.primaryContact.phone;
          locations.push(locationDetails[0]);
        }
      });
    });
    return locations;
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <p className="mainTagClear" style={{ margin: '0px' }}>
          {location.tagsMain}
        </p>
      );
    }
  }

  getDirectory() {
    let vendors = [];
    this.props.vendors.forEach((vendor) => {
      let status = vendor.vendorTypes.includes(1);
      if (status === true) {
        vendors.push(vendor);
      }
    });
    let locations = this.getLocations(vendors);

    const filteredLocations = locations.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );

    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={4} md={4} sm={4} lg={4} className="left">
                  <span className="label">Destination</span>
                </Col>

                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label" />
                </Col>
                <Col xs={3} md={3} sm={3} lg={3} c>
                  <span className="label " />
                </Col>

                <Col xs={2} md={2} sm={2} lg={2} className="right">
                  <span className="label right" />
                </Col>
              </div>
            </li>
            {filteredLocations.map((location, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    key={'key' + index}
                  >
                    <a
                      href={'/location/' + location.locationId}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span
                        className="location-title  red"
                        style={{
                          marginTop: '0px',
                          fontSize: '18px',
                          fontWeight: '800',
                          lineHeight: '20px',
                        }}
                      >
                        {location.locationTitle}
                      </span>
                    </a>
                    <p
                      style={{
                        color: 'grey',
                        fontSize: '12px !important',
                        marginBottom: '5px',
                      }}
                    >
                      {location.vendorName}
                    </p>
                    {this.getMainTag(location)}
                  </Col>
                  <Col
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    key={'key' + index}
                  >
                    <span className="address">
                      {location.address.street}
                    </span>
                    <span className="address">
                      {location.address.city} {location.address.state}
                      , {location.address.zip}
                    </span>
                  </Col>
                  <Col xs={4} md={4} sm={4} lg={4}>
                    <span
                      style={{
                        display: 'block',
                        marginBottom: '5px',
                        fontWeight: '600',
                      }}
                    >
                      {location.phone}
                    </span>
                  </Col>
                  {/* <Col xs={2} md={2} sm={2} lg={2} className="right">
                    <span
                      className="redbutton right"
                      style={{ textAlign: 'center' }}
                    >
                      Connect
                    </span>
                  </Col> */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  getAppContent() {
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuCommunityNew mobileHide">
          <VendorCommunityMenu popupMenu={false} />
        </div>
        <div id="vendorScreenBodyCommunity">
          <div style={{ padding: '10px', margin: '0px auto' }}>
            <div style={{ padding: '20px', margin: '0px auto' }}>
              <div style={{ marginBottom: '10px' }}>
                {/* <Select
                value={this.state.selectInput}
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                onChange={(e) => this.selectChange(e, 0, 'one')}
                options={selectOptions}
                isMulti={false}
                placeholder={'Filter By Entertainer'}
              /> */}

                <SearchInput
                  className="search-input"
                  onChange={this.searchUpdated}
                />
              </div>
              <div>{this.getDirectory()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getRewards(vendorAwarded) {
    const awardArray = [];
    if (vendorAwarded !== undefined) {
      vendorAwarded.forEach((award) => {
        if (award.validatorMode === true) {
          awardArray.push(award);
        }
      });
    }
    return awardArray;
  }

  render() {
    if (this.props.vendor === undefined) {
      return null; // or render a loading icon
    }
    let rewards = this.getRewards(this.props.vendorAwarded);
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorCommunityMenu
            showPicker={this.showPicker}
            popupMenu={true}
          />
        )}
        {this.getTitleBar('Trevel Community', rewards)}
        <div className="vendorContent">{this.getAppContent()}</div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
    user: state.user,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    vendorPromos: state.vendorPromos.promos,
    vendors: state.vendors.vendors,
    locations: state.locations.locations,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorCommunityDestinationsDirectory);
