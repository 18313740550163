import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
// Components
import Connections from '../components/Connections';
import TitleBar from '../components/TitleBar';
import { getNewEvent } from '../sagas/createEventSaga/reducer';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
// Reducers
import { init } from '../sagas/packagesSaga/reducer';

const KEYS_TO_FILTERS = ['title'];
const query = require('array-query');

export class ConnectionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    // this.setConnectionsArray(this.props.connections);
  }

  componentDidMount() {
    document.title = 'Connections';
    // if (this.props.articles === undefined) {
    // this.props.init();
    // } else {
    this.setState({
      loading: false,
    });
    // }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          {/* <Row className="center" style={{ maxWidth: '1200px' }}> */}
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Trevel Companions</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <a
                onClick={value => {
                  this.saveTags(this.props.user.id);
                }}
                className="right"
              >
                <MaterialIcon icon="check" size={25} color="white" />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  // setConnectionsArray(connections) {
  //   let readyConnections = {};
  //   if (connections.connections !== undefined) {
  //     readyConnections = connections.connections;
  //   }
  //   let connectionsArray = [];
  //   let connectionsCount = Object.keys(readyConnections).length;
  //   if (connectionsCount > 0) {
  //     Object.keys(readyConnections).forEach(key => {
  //       Object.keys(readyConnections[key]).forEach(keyinner => {
  //         console.log(
  //           "readyConnections[key][keyinner]",
  //           readyConnections[key][keyinner]
  //         );
  //         if (readyConnections[key][keyinner].connected === true) {
  //           connectionsArray.push(readyConnections[key][keyinner]);
  //         }
  //       });
  //     });
  //   }

  //   console.log("connectionsArray", connectionsArray);
  //   this.setState({
  //     connectionsArray
  //   });
  // }

  connectFirst() {
    const newEvent = {
      connections: [],
      planFirst: false,
      connectFirst: false,
      clear: false,
      step: 2,
      screen: 1,
      weightedLocations: [],
    };
    this.props.getNewEvent(newEvent);
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  setTitleBar() {
    // if (this.props.profile.profilePhotoPath !== undefined) {
    return <TitleBar title="Contacts" backlink="" />;
    // }
  }

  getConnections(connectionsArray, allConnections) {
    if (connectionsArray.length !== 0) {
      return (
        <div>
          <Connections
            connections={connectionsArray}
            allConnections={allConnections}
          />
        </div>
      );
    } else {
      return (
        <Container className="no-margin">
          <center>
            <p>No Connections</p>
          </center>
        </Container>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    } else {
      // const filteredUsers = this.props.users.filter(
      //   createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
      // );
      // const sortedArticles = this.sortArticles(filteredArticles);
      // console.log("filteredArticles", filteredArticles);
      let connections = this.props.connections;
      let readyConnections = {};
      if (connections.connections !== undefined) {
        readyConnections = connections.connections;
      }
      let connectionsArray = [];
      let connectionsCount = Object.keys(readyConnections).length;
      if (connectionsCount > 0) {
        Object.keys(readyConnections).forEach((key) => {
          Object.keys(readyConnections[key]).forEach((keyinner) => {
            if (readyConnections[key][keyinner].connected === true) {
              connectionsArray.push(readyConnections[key][keyinner]);
            }
          });
        });
      }

      let listReady = [];
      connectionsArray.forEach((connection) => {
        const userTits = connection.userId;
        const docDetails = query('userId')
          .is(userTits)
          .on(this.props.users);
        const userInfo = docDetails[0];
        if (userInfo !== undefined) {
          userInfo.favorite = false;
        }

        if (connection.favorite !== undefined) {
          userInfo.favorite = connection.favorite;
        }

        listReady.push(userInfo);
      });
      function compare(a, b) {
        if (a.firstName < b.firstName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        return 0;
      }

      listReady.sort(compare);

      return (
        <div id="articles">
          {/* {this.setTitleBar()} */}
          {this.getTitleBar()}
          <div>
            <div className="section">
              <Container>
                {/* <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
                  <SearchInput
                    className="search-input"
                    onChange={this.searchUpdated}
                  />
                </Col>
              </Row> */}
                <Row className="no-margin no-padding">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-margin no-padding"
                  >
                    {this.getConnections(listReady, connectionsArray)}
                  </Col>
                  {/* <Col xs={3} md={3} sm={3} lg={3} className="twocolRight">
                <div id="right-filter">
                  <h2>Sort and Filter</h2>
                </div>
              </Col> */}
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <span>
                      To add a <b>new contact</b> just invite someone
                      to join you for a Social Event!
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <a
                      onClick={() => {
                        this.connectFirst();
                      }}
                    >
                      <span className="header-button white left">
                        Invite Someone New!
                      </span>
                    </a>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    connections: state.connections,
    users: state.users.users,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(init());
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectionsPage);
