import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import './PromotedHero.css';

const query = require('array-query');

class PromotedHero extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      activeIndex: 0,
      carouselItems: [],
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  UNSAFE_componentWillMount() {
    let carouselItems = [];
    if (
      this.props.packages !== undefined &&
      this.props.packages !== null
    ) {
      carouselItems = this.setCarouselItems();
    }
    this.setState({
      loading: false,
      carouselItems: carouselItems,
    });
  }

  setCarouselItems() {
    const carouselItems = [];
    // const promotedPackageArray = query("promoted")
    //   .is(true)
    //   .on(this.props.packages);
    const promotedPackageArray = this.props.packages;
    promotedPackageArray.forEach(promotedPackage => {
      let bannerText = '';
      if (promotedPackage.bannerText !== undefined) {
        bannerText = promotedPackage.bannerText;
      }
      carouselItems.push({
        src:
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
          promotedPackage.heroImage +
          '?alt=media',
        altText: promotedPackage.title,
        caption: bannerText,
        header: promotedPackage.title,
        id: promotedPackage.packageId,
      });
    });
    return carouselItems;
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.carouselItems.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.carouselItems.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  getCarousel(carouselItems) {
    const { activeIndex } = this.state;
    const slides = carouselItems.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
          className="custom-tag"
        >
          <a href={'/package/' + item.id}>
            <div className="carousel-images">
              <img
                src={item.src}
                alt={item.altText}
                className="carousel-image"
              />
            </div>
            <div className="carousel-text">
              <CarouselCaption
                captionText={item.caption}
                captionHeader={item.header}
              />
            </div>
          </a>
        </CarouselItem>
      );
    });

    return (
      <div className="promotedHero">
        <style>
          {`
             h3 {
    font-size: 1.5rem;
    line-height: 44px;
    font-weight: 200 !important;
    margin-top: 30px;
    margin-bottom: 5px;
    color: #f21d59;
}
              .d-none {
  display: block !important;
}
              
              .carousel-image{
                width: 100%;
                height:100%;
              }
              .carousel-images{
                max-height:300px;
              }
              .carousel-text {
    display: block;
    background: #fbfbfb;
    border-top: 1px solid grey;
    position: relative;
    padding: 10px 30px 10px 30px;
}
.carousel {
    position: relative;
    margin: 0px auto;
    border-top: 1px solid grey;
    margin-bottom:0px
}
.carousel-caption {
    position: relative;
    right: 0%;
    bottom: 20px;
    left: 0%;
    z-index: 10;
    padding-top: 0px;
    padding-bottom: 0px;
    color: #110f10;
    text-align: start;
    font-size:18px;
}
.carousel-indicators li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color:#f00;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.active{
  background-color:#444 !important;
}
                
              }`}
        </style>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          interval="300000"
        >
          <CarouselIndicators
            items={carouselItems}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </div>
    );
  }

  render() {
    if (this.props.packages === null) {
      return null; // or render a loading icon
    }
    if (this.state.carouselItems.length !== 0) {
      return (
        <Row>
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            {this.getCarousel(this.state.carouselItems)}
          </Col>
        </Row>
      );
    } else {
      return <Row />;
    }
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
  };
}

export default connect(mapStateToProps)(PromotedHero);
