import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Features from '../../components/Features';
import SignUpBanner from '../../components/SignUpBanner';
import VendorFeatures from '../../components/VendorFeatures';
import ex1 from '../../images/ex1.png';
const query = require('array-query');

class VendorClaimReadyContent extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      aboutMode: 0,
    };
  }

  componentDidMount() {}

  getAboutButtons(aboutMode) {
    if (aboutMode === 0) {
      return (
        <div>
          <Container>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="greybutton text-center">
                  <span>Why Partner With Trevel?</span>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div
                  className="redbutton text-center full-width"
                  onClick={() => {
                    this.setMode(1);
                  }}
                >
                  <span>Trevel 101</span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      return (
        <div>
          <Container>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div
                  className="redbutton text-center full-width"
                  onClick={() => {
                    this.setMode(0);
                  }}
                >
                  <span>Why Partner With Trevel?</span>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="greybutton text-center">
                  <span>Trevel 101</span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

  getAbout(aboutMode) {
    if (aboutMode === 0) {
      return (
        <div id="home">
          <div
            className="cityBanner"
            style={{ padding: '20px 0px 0px 0px' }}
          >
            <h2
              style={{
                margin: '10px 0px 0px 0px',
                color: 'black',
              }}
            >
              <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880" />
            </h2>
            <h2
              style={{
                fontSize: '30px',
                lineHeight: '35px',
                color: '#f21f5a',
                marginBottom: '30px',
              }}
            >
              Gamify Your Business
            </h2>
          </div>
          <img
            src={ex1}
            style={{
              maxWidth: '75%',
              margin: '0px auto',
              display: 'block',
            }}
          />
          <VendorFeatures />
        </div>
      );
    } else {
      return <Features />;
    }
  }

  setMode(mode) {
    this.setState({
      aboutMode: mode,
    });
  }

  render() {
    if (this.props.location === undefined) {
      return null;
    }
    return (
      <div className="wide">
        <Container className="no-padding">
          <Row className="inline-block">
            <Col lg={5} md={5} sm={12} xs={12}>
              <h1 className="welcometitle">Welcome to Trevel</h1>
              <div
                style={{
                  // borderBottom: '1px solid grey',
                  paddingBottom: '10px',
                }}
              >
                <div
                  style={{
                    // borderBottom: '1px solid grey',
                    paddingBottom: '5px',
                  }}
                >
                  <p>
                    You have one of our favorite locations in the city
                    and we want others to know it too. Now that people
                    can get out and experience life again we want to
                    make sure that they can find the perfect
                    experience that we ALL deserve.
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: 'block',
                  clear: 'both',
                  // margin: '0px auto',
                  maxWidth: '250px',
                  padding: '10px 0px 10px 0px',
                }}
              >
                <img
                  style={{
                    maxWidth: '350px',
                    width: '90%',
                    minWidth: '200px',
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ftrevelguidelogo.svg?alt=media&token=1171ea14-1877-4f12-b432-b736d7c64888"
                />
              </div>
              <p>
                The{' '}
                <span style={{ fontWeight: '600' }}>
                  Trevel Guide
                </span>{' '}
                connects people to places and experiences that they
                will love to do with others.
              </p>
              <p>
                By claiming your location{' '}
                <span className="bold">(For Free)</span> you can:
              </p>
              <ul>
                <li>Edit your locations information</li>
                <li>Upload photos</li>
                <li>
                  <span className="bold">Promote your business</span>
                </li>
              </ul>
              <a
                href={'/location/' + this.props.location.locationId}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <span
                  style={{
                    padding: '5px 10px 5px 10px',
                    background: 'white',
                    border: '#ee0059 1px solid',
                    borderRadius: '5px',
                    marginTop: '10px',
                    display: 'inline-block',
                  }}
                >
                  View Your Location In The Guide
                </span> */}
                {/* <div
                  className="infoBox"
                  style={{ maxWidth: '350px' }}
                > */}

                <Link
                  to={{
                    pathname: '/vendor/signup',
                    state: {
                      locationId: this.props.location.locationId,
                    },
                  }}
                >
                  <span
                    className="claimButton"
                    style={{
                      float: 'left',
                      marginTop: '10px',
                      marginBottom: '20px',
                    }}
                  >
                    Claim {this.props.location.locationTitle}
                  </span>
                </Link>
                {/* </div> */}
              </a>
            </Col>
            <Col lg={7} md={7} sm={12} xs={12}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fbanner1.png?alt=media&token=844aca0c-a108-4140-a566-6487a4f65344"
                style={{
                  // maxWidth: '550px',
                  width: '100%',
                  margin: 'auto 0px',
                  padding: '0px 0px 0px 0px',
                  // marginTop: '10%',
                }}
              />
            </Col>
          </Row>

          {/* <Row className="inline-block">
            <Col lg={6} md={6} sm={6} xs={12}>
            
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
            
            </Col>
          </Row> */}
        </Container>
        <Row className="sectionBox">
          <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
            {/* <div className="text-center sectionBoxHeader">
              <h2>Learn About Trevel</h2>
              <div>{this.getAboutButtons(this.state.aboutMode)}</div>
            </div> */}
            <div>{this.getAbout(this.state.aboutMode)}</div>
          </Col>
        </Row>

        <div id="signupbanner">
          <SignUpBanner />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    invite: state.invite,
    location: state.locations.location,
  };
}

export default connect(mapStateToProps)(VendorClaimReadyContent);
