import { call, put, takeLatest } from "redux-saga/effects";
import { getUsers } from "../../services/UserServices";
import * as actions from "./actions";

let defaultState = {
  events: {},
};

function* getUserAction(action) {
  let users = yield call(getUsers);
  yield put({
    type: actions.SET_USERS,
    payload: {
      ...defaultState,
      users,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_USERS, getUserAction);
}
