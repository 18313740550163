import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import * as auth from "../../config/auth";
import firebase from "../../config/firebase";
// Reducers
import { setNewUser, setUser } from "../../sagas/loginSaga/reducer";

const INITIAL_STATE = {
  email: "",
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class PasswordResetFormBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  // signIn(email, password) {
  //   auth
  //     .doSignInWithEmailAndPassword(email, password)
  //     .then(result => {
  //       const user = {
  //         id: result.user.uid,
  //         userName: result.user.displayName,
  //         email: result.user.email,
  //         authenticated: true,
  //         newuser: false
  //       };
  //       this.setState({ ...INITIAL_STATE });
  //       this.props.setUser(user);
  //     })
  //     .catch(error => {
  //       console.log("errorx", error);
  //       this.setState(byPropKey("error", error));
  //     });
  // }

  getErrorMessage(error) {
    if (error !== null) {
      console.log("error -", error);
      return error.message;
    }
  }
  fetchSignInMethodsForEmail;
  onSubmit = (event) => {
    const { email } = this.state;
    firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((result) => {
        if (result.length === 0) {
          this.props.changeStep(2);
        } else {
          if (result[0] === "password") {
            this.setState({ ...INITIAL_STATE });
            auth
              .doPasswordReset(email)
              .then(() => {
                this.setState({ ...INITIAL_STATE });
              })
              .catch((error) => {
                console.log("ERROR", error);
                this.setState({ error });
              });
            this.props.changeStep(2);
          } else {
            this.props.changeStep(3);
          }
        }
      });
    //
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const isInvalid = email === "";

    return (
      <div id="signup">
        <div>
          <div className="title-area">
            <h3>Reset Your Password</h3>
            {/* <p className="signup-box-info">
              <span>Or </span>
              <a href="/signup">create an account</a>
              <span> for free.</span>
            </p> */}
          </div>
          <form onSubmit={this.onSubmit}>
            <Container>
              <Row>
                <Col xs={12} md={6} sm={12} lg={6} className="left">
                  <input
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email Address"
                  />
                  <button disabled={isInvalid} type="submit">
                    Reset My Password
                  </button>
                  {error && <p>{error.message}</p>}
                </Col>
              </Row>
              <Row xs={12} md={12} sm={12} lg={12} className="disclaimer" />
            </Container>
          </form>
          <Row className="disclaimer">
            <Col xs={12} md={12} sm={12} lg={12}>
              <center>
                <Link
                  to={{
                    pathname: "/login",
                  }}
                >
                  Return To Log In
                </Link>
              </center>
            </Col>
          </Row>

          <style
            dangerouslySetInnerHTML={{
              __html: ".nav-signin { display: none }",
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
    setNewUser: (user) => {
      dispatch(setNewUser(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetFormBase);
