import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
// Components
import TitleBar from '../components/TitleBar';
import * as auth from '../config/auth';
import firebase from '../config/firebase';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
// Reducers
import { clearProfile } from '../sagas/getProfileSaga/reducer';

const query = require('array-query');

export class Account extends React.Component {
  constructor() {
    super();
    this.state = {
      showConfimCancel: false,
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
  }

  componentDidMount() {
    document.title = 'Account';
  }

  showConfirm(state) {
    this.setState({ showConfimCancel: state });
  }

  closeAccount(uid) {
    var user = firebase.auth().currentUser;
    const db = firebase.firestore();
    const timestamp = new Date();

    user
      .delete()
      .then(function() {
        // User deleted.
      })
      .catch(function(error) {
        // An error happened.
      });
    const update = db.collection('users').doc(uid);
    const setDoc = update
      .update({
        active: false,
        history: {
          deletedDate: timestamp,
        },
      })
      .then(ref => {
        auth.doSignOut();
        this.props.clearProfile();
      });
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          {/* <Row className="center" style={{ maxWidth: '1200px' }}> */}
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Manage Your Account
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <a
                onClick={value => {
                  this.saveTags(this.props.user.id);
                }}
                className="right"
              >
                <MaterialIcon icon="check" size={25} color="white" />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    if (this.state.showConfimCancel === true) {
      return (
        <div id="profile">
          {/* <TitleBar title="Account" backlink="" /> */}
          {this.getTitleBar()}
          <div className="section">
            <Container>
              <Row>
                <Col className="no-padding">
                  <div className="text-center">
                    <span>
                      Are you sure you would like to cancel your
                      account?
                    </span>
                    <a
                      className="redbutton"
                      onClick={() => {
                        this.closeAccount(this.props.profile.userId);
                      }}
                    >
                      Yes
                    </a>
                    <a
                      className="stackedRedButtonBlock"
                      onClick={() => {
                        this.showConfirm(false);
                      }}
                    >
                      No
                    </a>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-center no-padding" />
              </Row>
              <Row />
            </Container>
          </div>
        </div>
      );
    }
    return (
      <div id="profile">
        {/* <TitleBar title="Account" backlink="" /> */}
        {this.getTitleBar()}
        <div className="section">
          <Container>
            <Row>
              <Col className="no-padding">
                <div className="text-center">
                  <a
                    onClick={() => {
                      this.showConfirm(true);
                    }}
                    className="redbutton"
                  >
                    Close Account
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center no-padding" />
            </Row>
            <Row />
          </Container>
        </div>

        {/* <div>
          <Row>
            <p className="secondary-title-bar">secondary-title-bar</p>
          </Row>
        </div>
        <div>
          <Row>
            <p className="secondary-title-bar-agent">secondary-title-bar-agent</p>
          </Row>
        </div>

        <div>
          <Row>
            <p className="solidredbutton">solidredbutton</p>
          </Row>
        </div>
        <div>
          <Row>
            <p className="redbutton">redbutton</p>
          </Row>
        </div> */}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearProfile: user => {
      dispatch(clearProfile(user));
    },
  };
};

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    users: state.users.users,
    events: state.events.events,
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Account);
