import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import VendorMenu from '../../components/VendorMenu';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';
const KEYS_TO_FILTERS = ['title'];

export class VendorInsights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
      searchTerm: '',
      loading: false,
      promos: [],
    };
    this.showPicker = this.showPicker.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }

  componentDidMount() {
    document.title = 'Analyze';
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getStatus(status) {
    if (status === true) {
      return 'Active';
    } else {
      return 'Inactive';
    }
  }

  // getCount(promoId, starting) {
  //   let awardArray = [];
  //   this.props.vendorAwarded.forEach((award) => {
  //     if (award.promo.id === promoId) {
  //       awardArray.push(award);
  //     }
  //   });
  //   let count = awardArray.length;
  //   let remaining = starting - count;
  //   return <span>{remaining}</span>;
  // }

  getEarned(promoId) {
    let earnedArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.promo.id === promoId && award.earned === true) {
        earnedArray.push(award);
      }
    });
    let earnedCount = earnedArray.length;
    return <span>{earnedCount}</span>;
  }

  getInProgress(promoId) {
    let inProgressArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.promo.id === promoId && award.earned === false) {
        inProgressArray.push(award);
      }
    });
    let inProgressCount = inProgressArray.length;
    return <span>{inProgressCount}</span>;
  }

  getDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getPromos(promos) {
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          {this.props.vendorPromos.length > 0 ? (
            <ul className="no-padding">
              <li className="adminlistitem adminlistheader ">
                <div>
                  <Col xs={9} md={9} sm={9} lg={9} className="left">
                    <span className="label">Experience</span>
                  </Col>
                  <Col xs={1} md={1} sm={1} lg={1} className="left">
                    <span className="label">Status</span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    <span className="label">Created</span>
                  </Col>
                </div>
              </li>
              <div>
                {this.props.vendorPromos.map((promo, index) => (
                  <li className="adminlistitem">
                    <div className="fullwidth">
                      <Col
                        xs={9}
                        md={9}
                        sm={9}
                        lg={9}
                        key={'key' + index}
                      >
                        <Link
                          to={{
                            pathname: '/vendor/promo/' + promo.id,
                          }}
                          className="left"
                        >
                          <span style={{ fontWeight: '600' }}>
                            {promo.title}
                          </span>
                        </Link>
                      </Col>
                      <Col
                        xs={1}
                        md={1}
                        sm={1}
                        lg={1}
                        className="left"
                      >
                        {this.getStatus(promo.active)}
                      </Col>
                      <Col
                        xs={2}
                        md={2}
                        sm={2}
                        lg={2}
                        className="left"
                      >
                        {this.getDate(
                          promo.creationDetails.createdDate,
                        )}
                      </Col>
                    </div>
                  </li>
                ))}
              </div>
            </ul>
          ) : (
            <div />
          )}
        </div>
      </Row>
    );
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={10}
              md={10}
              sm={10}
              xs={10}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">
                <span style={{ color: '#f21d59', fontSize: '22px' }}>
                  Analyze{' '}
                </span>
                Experiences
              </span>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getAppContent(filteredPackages) {
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuNew">
          <VendorMenu popupMenu={false} />
        </div>
        <div style={{ float: 'left', width: 'calc(100% - 120px)' }}>
          <div
            style={{
              width: '100%',
              padding: '5px 10px 5px 10px',
              borderBottom: '1px solid rgb(142 12 60)',
              margin: '0px auto',

              display: 'flex',
              background: '#ffebf3',
            }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <center>
                <span>
                  You are not currently subscribed to Trevel
                  Experiences. You can explore and create but you
                  cannot publish live experiences.{'  '}
                  <Link
                    to={{
                      pathname: '/vendor/pilot',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>
                      Learn More
                    </span>
                  </Link>
                </span>
              </center>
            </Col>
            {/* <Col lg={2} md={4} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/pilot',
                }}
                className="right"
              >
                <span style={{ float: 'right' }}>Learn More</span>
              </Link>
            </Col> */}
          </div>
          <div id="openBox">
            {this.props.vendorPromos.length === 0 && (
              <div
                style={{
                  padding: '30px',
                  border: '1px solid lightgrey',
                  margin: '0px auto',
                  width: '100%',
                  marginTop: '100px',
                  background: 'white',
                  display: 'flex',
                }}
              >
                <Col xs={1} md={1} sm={1} lg={1}>
                  <div style={{ float: 'left' }}>
                    <MaterialIcon
                      icon="help_outline"
                      size={45}
                      color="#353535"
                    />
                  </div>
                </Col>
                <Col xs={11} md={11} sm={11} lg={11}>
                  <h4
                    style={{ fontSize: '18px', marginBottom: '10px' }}
                  >
                    Analyze Experiences
                  </h4>
                  <p style={{ padding: '0px', margin: '0px' }}>
                    Here you wil find the data and insights you need
                    to to improve your experiences and drive more
                    engagement.
                  </p>
                </Col>
              </div>
            )}
            {/* <div className="leftNav">
          <span>hello</span>
        </div> */}

            {/* <div style={{ padding: '10px' }}>
              <center>
                <h5 style={{}}>Choose an Experience</h5>
              </center>
            </div> */}

            <div style={{ padding: '10px', clear: 'both' }}>
              <div>{this.getPromos(filteredPackages)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    const filteredPackages = this.state.promos.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Analyze Experiences')}
        <div className="vendorContent">
          {this.getAppContent(filteredPackages)}
        </div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} #analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
    vendorPromos: state.vendorPromos.promos,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorInsights);
