import firebase from "../config/firebase";

export function getVendorEvents(vendorId) {
  const eventArray = [];
  const db = firebase.firestore();
  const events = db.collection("events");
  const getP = function() {
    return new Promise((resolve, reject) => {
      const query = events
        .where("creatorInfo.vendorId", "==", vendorId)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            if (doc && doc.exists) {
              const {
                active,
                created,
                creatorInfo,
                description,
                heroImage,
                locations,
                scheduledTime,
                status,
                thumbnail,
                teaser,
                title,
                zoneId
              } = doc.data();
              eventArray.push({
                id: doc.id,
                active,
                created,
                creatorInfo,
                description,
                heroImage,
                locations,
                scheduledTime,
                status,
                thumbnail,
                teaser,
                title,
                zoneId
              });
            }
          });
          return eventArray;
        })
        .then(result => {
          resolve(eventArray);
        });
    });
  };
  return getP();
}
