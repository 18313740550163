import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import { Col, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import VendorMenu from '../../components/VendorMenu';
import { editVendorPromos } from '../../sagas/vendorPromoSaga/reducer';

const KEYS_TO_FILTERS = ['title'];

const query = require('array-query');

export class VendorVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      promos: [],
      showPicker: false,
      showHelp: false,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.showPicker = this.showPicker.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    document.title = 'Create';
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  getStatus(status) {
    if (status === true) {
      return 'Active';
    } else {
      return 'Inactive';
    }
  }

  // getCount(promoId, starting) {
  //   let awardArray = [];
  //   this.props.vendorAwarded.forEach((award) => {
  //     if (award.promo.id === promoId) {
  //       awardArray.push(award);
  //     }
  //   });
  //   let count = awardArray.length;
  //   let remaining = starting - count;
  //   return <span>{remaining}</span>;
  // }

  getEarned(promoId) {
    let earnedArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.promo.id === promoId && award.earned === true) {
        earnedArray.push(award);
      }
    });
    let earnedCount = earnedArray.length;
    return <span>{earnedCount}</span>;
  }

  getInProgress(promoId) {
    let inProgressArray = [];
    this.props.vendorAwarded.forEach((award) => {
      if (award.promo.id === promoId && award.earned === false) {
        inProgressArray.push(award);
      }
    });
    let inProgressCount = inProgressArray.length;
    return <span>{inProgressCount}</span>;
  }

  getDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getEditButton(id) {
    return (
      <Link
        to={{
          pathname: '/vendor/promo/edit/' + id,
        }}
        // className="redbutton"
        className="right"
      >
        <MaterialIcon icon="edit" size={25} color="red" />
      </Link>
    );
  }

  getAnalyticsButton(id) {
    return (
      <Link
        to={{
          pathname: '/vendor/promo/' + id,
        }}
        // className="redbutton"
        className="right"
      >
        <MaterialIcon icon="bar_chart" size={25} color="red" />
      </Link>
    );
  }

  getVendorName(vendorId) {
    const locationDetails = query('id')
      .is(vendorId)
      .on(this.props.vendors);
    return locationDetails[0].businessName;
  }

  editMode(promo) {
    const timestamp = new Date();
    const bundle = {};
    const editing = {
      editing: true,
      editorId: this.props.profile.userId,
      timestamp,
    };
    if (promo.editing.editing === true) {
      editing.timestamp = promo.editing.timestamp;
      editing.editorId = promo.editing.editorId;
    }
    bundle.promo = promo;
    bundle.editing = editing;
    this.props.editVendorPromos(bundle);
  }

  getPromos(promos) {
    let invites = [];
    let vendorPromos = [];

    this.props.vendorPromos.forEach((promo) => {
      if (
        promo.settings !== undefined &&
        promo.settings.community !== undefined &&
        promo.settings.community === true &&
        promo.account.vendorId !== this.props.vendor.vendorId
      ) {
        invites.push(promo);
      } else {
        vendorPromos.push(promo);
      }
    });

    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          {this.props.vendorPromos.length > 0 ? (
            <ul className="no-padding">
              <li className="adminlistitem adminlistheader ">
                <div>
                  <Col xs={8} md={8} sm={8} lg={8} className="left">
                    <span className="label">Experience</span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    <span className="label">Status</span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    <span className="label">Created</span>
                  </Col>
                </div>
              </li>
              <div style={{ borderBottom: '2px solid grey' }}>
                {invites.map((promo, index) => (
                  <li className="adminlistitem">
                    <div className="fullwidth">
                      <Col
                        xs={8}
                        md={8}
                        sm={8}
                        lg={8}
                        key={'key' + index}
                      >
                        <a
                          onClick={() => {
                            this.editMode(promo);
                          }}
                        >
                          <span
                            className="left"
                            style={{
                              top: '2px',
                              position: 'relative',
                              marginRight: '5px',
                            }}
                          >
                            <MaterialIcon
                              icon="mail"
                              size={20}
                              color="#f21d59"
                            />
                          </span>
                          <span style={{ fontWeight: '600' }}>
                            {promo.title}
                          </span>
                        </a>
                        <div
                          style={{
                            display: 'block',
                            clear: 'both',
                            paddingLeft: '25px',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '14px',
                              display: 'block',
                              clear: 'both',
                            }}
                          >
                            {promo.creationDetails.creator.fullName}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              display: 'block',
                              clear: 'both',
                            }}
                          >
                            {this.getVendorName(
                              promo.account.vendorId,
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col
                        xs={2}
                        md={2}
                        sm={2}
                        lg={2}
                        className="left"
                      >
                        Waiting Approval
                      </Col>
                      <Col
                        xs={2}
                        md={2}
                        sm={2}
                        lg={2}
                        className="left"
                      >
                        {this.getDate(
                          promo.creationDetails.createdDate,
                        )}
                      </Col>
                    </div>
                  </li>
                ))}
              </div>
              <div>
                {vendorPromos.map((promo, index) => (
                  <li className="adminlistitem">
                    <div className="fullwidth">
                      <Col
                        xs={8}
                        md={8}
                        sm={8}
                        lg={8}
                        key={'key' + index}
                      >
                        <a
                          onClick={() => {
                            this.editMode(promo);
                          }}
                        >
                          <span style={{ fontWeight: '600' }}>
                            {promo.title}
                          </span>
                        </a>
                      </Col>
                      <Col
                        xs={2}
                        md={2}
                        sm={2}
                        lg={2}
                        className="left"
                      >
                        {this.getStatus(promo.active)}
                      </Col>
                      <Col
                        xs={2}
                        md={2}
                        sm={2}
                        lg={2}
                        className="left"
                      >
                        {this.getDate(
                          promo.creationDetails.createdDate,
                        )}
                      </Col>
                    </div>
                  </li>
                ))}
              </div>
            </ul>
          ) : (
            <div />
          )}
        </div>
      </Row>
    );
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">
                <span style={{ color: '#f21d59', fontSize: '22px' }}>
                  Create{' '}
                </span>
                Experiences
              </span>

              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
              <div className="saveButtons">
                <Link
                  to={{
                    pathname: '/vendor/experience-gallery',
                  }}
                  className="right"
                >
                  <span className="cancelButton">View Gallery</span>
                </Link>
                <Link
                  to={{
                    pathname: '/vendor/create/promo',
                  }}
                  className="right"
                >
                  <span className="saveButton">
                    Create New Experience
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getAppContent(filteredPackages) {
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuNew">
          <VendorMenu popupMenu={false} />
        </div>
        <div style={{ float: 'left', width: 'calc(100% - 120px)' }}>
          <div
            style={{
              width: '100%',
              padding: '5px 10px 5px 10px',
              borderBottom: '1px solid rgb(142 12 60)',
              margin: '0px auto',

              display: 'flex',
              background: '#ffebf3',
            }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <center>
                <span>
                  You are not currently subscribed to Trevel
                  Experiences. You can explore and create but you
                  cannot publish live experiences.{'  '}
                  <Link
                    to={{
                      pathname: '/vendor/pilot',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>
                      Learn More
                    </span>
                  </Link>
                </span>
              </center>
            </Col>
            {/* <Col lg={2} md={4} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/pilot',
                }}
                className="right"
              >
                <span style={{ float: 'right' }}>Learn More</span>
              </Link>
            </Col> */}
          </div>

          <div id="openBox">
            {this.props.vendorPromos.length === 0 && (
              <div
                style={{
                  padding: '30px',
                  border: '1px solid lightgrey',
                  margin: '0px auto',
                  width: '100%',
                  marginTop: '100px',
                  background: 'white',
                  display: 'flex',
                }}
              >
                <Col xs={1} md={1} sm={1} lg={1}>
                  <div style={{ float: 'left' }}>
                    <MaterialIcon
                      icon="help_outline"
                      size={45}
                      color="#353535"
                    />
                  </div>
                </Col>
                <Col xs={11} md={11} sm={11} lg={11}>
                  <h4
                    style={{ fontSize: '20px', marginBottom: '10px' }}
                  >
                    Create Experiences
                  </h4>
                  <p style={{ padding: '0px', margin: '0px' }}>
                    Click on{' '}
                    <span style={{ fontWeight: '600' }}>
                      Create New Experience
                    </span>{' '}
                    to get started crafting your first experience!
                  </p>
                  <h5 style={{ padding: '50px 0px 20px 0px' }}>
                    Need some inspiration?{' '}
                    <Link
                      to={{
                        pathname: '/vendor/experience-gallery',
                      }}
                    >
                      Check out the Gallery!
                    </Link>
                  </h5>
                </Col>
              </div>
            )}
            {/* <div style={{ padding: '10px' }}>
              <center>
                <h5 style={{}}>Open an Experience</h5>
              </center>
            </div> */}

            <div
              style={{
                padding: '10px',
                clear: 'both',
              }}
            >
              <div>{this.getPromos(filteredPackages)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    const filteredPackages = this.state.promos.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}

        {this.getTitleBar('Create Experiences')}
        <div className="vendorContent">
          {this.getAppContent(filteredPackages)}
        </div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} #analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editVendorPromos: (bundle) => {
      dispatch(editVendorPromos(bundle));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendorPromos: state.vendorPromos.promos,
    vendorAwarded: state.vendorAwarded.vendorAwarded,
    profile: state.profile.profile,
    user: state.user,
    vendors: state.vendors.vendors,
    vendor: state.vendor.vendor,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorVouchers);
