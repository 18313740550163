import React from 'react';
import { connect } from 'react-redux';
import firebase from '../config/firebase';
import FileUploader from 'react-firebase-file-uploader';
import UserAvatar from 'react-user-avatar';
// Components
// Reducers
import { getUpdatedProfile } from '../sagas/getProfileSaga/reducer';
// import { CommunicationStayCurrentLandscape } from "material-ui/svg-icons";

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  isUploading: false,
  progress: 0,
  avatar: '',
  loading: false,
};

export class SignUpPhoto extends React.Component {
  constructor() {
    super();
    this.state = {
      ...INITIAL_STATE,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      avatarURL: this.props.profile.profilePhotoPath,
      loading: false,
    });
  }

  handleUploadStart = () =>
    this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    // console.error(error);
  };
  handleUploadSuccess = filename => {
    this.setState({
      avatar: filename,
      progress: 100,
      isUploading: false,
    });
    firebase
      .storage()
      .ref('profile_photos')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        const db = firebase.firestore();
        const docToUpdate = db
          .collection('users')
          .doc(this.props.profile.userId);
        const updateDoc = docToUpdate.update({
          profilePhotoPath: url,
        });
        this.setState({ avatarURL: url });
      });
  };

  goToPref() {
    this.props.history.push('/signup/preferences');
  }

  getButton() {
    if (this.state.avatarURL === '') {
      return (
        <button
          onClick={() => {
            this.goToPref();
          }}
          className="redbutton"
        >
          Skip
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            this.goToPref();
          }}
          className="redbutton"
        >
          Next
        </button>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div id="login">
        <div className="signup-box-container">
          <div className="signup-box">
            <div className="profile-box-title-area">
              <h3>Upload Profile Image</h3>
            </div>
            <div>
              {this.state.isUploading && (
                <p>Progress: {this.state.progress}</p>
              )}
              {this.state.avatarURL && (
                <UserAvatar
                  size="100"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.state.avatarURL}
                />
              )}
              <FileUploader
                accept="image/*"
                name="hero"
                // randomizeFilename
                filename={file => this.props.profile.userId}
                storageRef={firebase.storage().ref('profile_photos')}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
            </div>
            {this.getButton()}
          </div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#authleft {display:none } .maincontent-push{top:0px !important; height:100%} #authright {padding-left:0px !important} header { display: none } .maincontent { top: 0px; margin-top: 0px;} .footer-container{display:none} .full-height{height:100%} #mobile-nav{display:none}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    user: state.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getUpdatedProfile: userId => {
      dispatch(getUpdatedProfile(userId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpPhoto);
