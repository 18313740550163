import React from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
// Components
import AdminLocationsForm from "../../components/AdminLocationsForm";
import TitleBar from "../../components/TitleBar";

const query = require("array-query");

export class AdminCustomLocationsEdit extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  getLocation(locationId, locations) {
    const locationDetails = query("locationId").is(locationId).on(locations);
    console.log(
      "AdminCustomLocationEdit - locationDetails",
      locationDetails[0]
    );
    return locationDetails[0];
  }

  render() {
    const location = this.getLocation(
      this.props.match.params.id,
      this.props.alllocations
    );
    console.log("AdminCustomLocationEdit - location", location);
    return (
      <div id="admin">
        <TitleBar title="Edit Location" backlink="/admin/customlocations/" />
        <div className="section">
          <Container>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12}>
                <AdminLocationsForm editlocation={location} />
              </Col>
            </Row>
          </Container>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}",
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
  };
}

export default connect(mapStateToProps)(AdminCustomLocationsEdit);
