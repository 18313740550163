import * as action from "./actions";

export const getUserAwards = id => {
  return {
    type: action.GET_AWARDS,
    payload: id
  };
};

export const setUserAwards = awards => {
  return {
    type: action.SET_AWARDS,
    payload: awards
  };
};

export const refreshAwards = awards => {
  return {
    type: action.REFRESH_AWARDS,
    payload: awards
  };
};

const ACTION_HANDLERS = {
  [action.GET_AWARDS]: (state, action) => {
    return { ...state };
  },
  [action.SET_AWARDS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.REFRESH_AWARDS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  awards: {
    status: 0
  }
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
