import { call, put, takeLatest } from 'redux-saga/effects';
import { getDemoPromos } from '../../services/PromoServices';
import { getDemoLocations } from '../../services/LocationServices';

import * as actions from './actions';

let defaultState = {
  demoLocations: [],
  demoPromos: [],
};

function* getDemosAction(zoneId) {
  let demoPromos = yield call(getDemoPromos);
  let demoLocations = yield call(getDemoLocations);

  yield put({
    type: actions.SET_DEMOS,
    payload: {
      demoPromos,
      demoLocations,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_DEMOS, getDemosAction);
}
