import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import 'react-confirm-alert/src/react-confirm-alert.css';
import MaterialIcon from 'material-icons-react';
// Reducers
import {
  getNewEvent,
  resetNewEvent,
} from '../../sagas/createEventSaga/reducer';
import {
  removeConnections,
  favConnections,
} from '../../sagas/connectionsSaga/reducer';
// Images
import redLogo from '../../images/heartbig.png';

const query = require('array-query');

class Connections extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  setConnection(newUser, user) {
    const newUserFullName =
      newUser.firstName + ' ' + newUser.lastName;
    const fullName = user.firstName + ' ' + user.lastName;
    var newEvent = {};
    const usersObject = {};
    newEvent = {
      connectFirst: false,
      clear: false,
      step: 3,
    };
    newEvent.invitedUser = newUser;
    usersObject[newUser.userId] = {
      access: true,
      approved: false,
      editor: false,
      attending: false,
      rejected: false,
      inviteAction: false,
      profile: {
        fullName: newUserFullName,
        profilePhotoPath: newUser.profilePhotoPath,
        userName: newUser.userName,
      },
    };
    usersObject[user.userId] = {
      access: true,
      approved: true,
      editor: true,
      attending: true,
      rejected: false,
      profile: {
        fullName: fullName,
        userName: user.userName,
        profilePhotoPath: user.profilePhotoPath,
      },
    };
    newEvent.connections = usersObject;
    this.props.getNewEvent(newEvent);
  }

  removeConnections(newUser, user) {
    let newUserId = newUser.userId;
    let userId = user.userId;
    let bundle = {
      userId: userId,
      newUserId: newUserId,
    };
    this.props.removeConnections(bundle);
  }

  showPrivacy(id, state) {
    this.setState({
      [id + 'privacy']: state,
    });
  }

  getFavStatus(contact, user, connections) {
    let add = {
      mode: 1,
      contactId: contact.userId,
      userId: user.userId,
      created: connections[contact.userId].created,
    };
    let remove = {
      mode: 2,
      contactId: contact.userId,
      userId: user.userId,
      created: connections[contact.userId].created,
    };
    if (contact.favorite !== undefined && contact.favorite === true) {
      return (
        <span
          onClick={() => {
            this.props.favConnections(remove);
          }}
          key="nonFav"
        >
          <MaterialIcon icon="favorite" size={30} color="#f21d59" />
        </span>
      );
    } else {
      return (
        <span
          onClick={() => {
            this.props.favConnections(add);
          }}
          key="fav"
        >
          <MaterialIcon icon="favorite" size={30} color="grey" />
        </span>
      );
    }
  }

  getUser(userInfo, index) {
    if (userInfo !== undefined) {
      return (
        <div>
          <Row className="no-padding">
            <Col
              xs={10}
              md={11}
              sm={10}
              lg={11}
              className="no-padding"
            >
              <Link
                to={{
                  pathname: '/connections/profile/' + userInfo.userId,
                }}
              >
                <div className="left">
                  <UserAvatar
                    size="50"
                    name={userInfo.firstName + userInfo.lastName}
                    src={userInfo.profilePhotoPath}
                    onClick={() => {
                      this.setConnection(userInfo, this.props.user);
                    }}
                  />
                </div>
                <div className="left">
                  <span className="invite-label">
                    {userInfo.firstName} {userInfo.lastName}
                  </span>
                  <br />
                  <span className="address">
                    @{userInfo.userName}
                  </span>
                </div>
              </Link>
            </Col>
            <Col xs={2} md={1} sm={2} lg={1} className="no-padding">
              <Row>
                <Col
                  xs={6}
                  md={6}
                  sm={6}
                  lg={6}
                  className="no-padding"
                >
                  {this.getFavStatus(
                    this.props.connections[index],
                    this.props.user,
                    this.props.allconnections.connections.connections,
                  )}
                </Col>
                <Col
                  xs={6}
                  md={6}
                  sm={6}
                  lg={6}
                  className="no-padding"
                >
                  <span className="right">
                    <MaterialIcon
                      icon="delete"
                      size={25}
                      color="#f21d59"
                      onClick={() => {
                        this.removeConnections(
                          userInfo,
                          this.props.user,
                        );
                      }}
                    />
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={2}
              lg={12}
              className="no-padding"
            >
              <div
                style={{
                  marginBottom: '10px',
                  marginTop: '10px',
                  paddingLeft: '5px',
                }}
              >
                {this.state[this.props.user.userId + 'privacy'] ===
                true ? (
                  <span
                    style={{ fontWeight: '600', color: '#ee0059' }}
                    onClick={() => {
                      this.showPrivacy(this.props.user.userId, false);
                    }}
                  >
                    Hide Privacy Settings
                  </span>
                ) : (
                  <span
                    style={{ fontWeight: '600', color: '#ee0059' }}
                    onClick={() => {
                      this.showPrivacy(this.props.user.userId, true);
                    }}
                  >
                    Show Privacy Settings
                  </span>
                )}
              </div>

              {this.state[this.props.user.userId + 'privacy'] ===
                true && (
                <div
                  style={{
                    paddingLeft: '5px',
                  }}
                >
                  <div
                    style={{
                      clear: 'both',
                      display: 'block',
                      marginBottom: '10px',
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={this.state.shareMood}
                      onChange={this.selectCat}
                      className="closedCheckBox"
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '5px',
                        top: '2px',
                        marginLeft: '0px',
                      }}
                      alt="shareMood"
                    />
                    <span>Share what you are in the mood for.</span>
                  </div>

                  <div
                    style={{
                      clear: 'both',
                      display: 'block',
                      marginBottom: '10px',
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={this.state.shareMood}
                      onChange={this.selectCat}
                      className="closedCheckBox"
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '5px',
                        marginLeft: '0px',
                        top: '2px',
                      }}
                      alt="shareFood"
                    />
                    <span>
                      Share your favorite food and beverages.
                    </span>
                  </div>

                  <div
                    style={{
                      clear: 'both',
                      display: 'block',
                      marginBottom: '10px',
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={this.state.shareMood}
                      onChange={this.selectCat}
                      className="closedCheckBox"
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '5px',
                        marginLeft: '0px',
                        top: '2px',
                      }}
                      alt="shareEntertainers"
                    />
                    <span>Share your favorite entertainers.</span>
                  </div>

                  <div
                    style={{
                      clear: 'both',
                      display: 'block',
                      marginBottom: '10px',
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={this.state.shareMood}
                      onChange={this.selectCat}
                      className="closedCheckBox"
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '5px',
                        top: '2px',
                        marginLeft: '0px',
                      }}
                      alt="sharePlaces"
                    />
                    <span>Share your favorite places.</span>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            {this.props.connections.map((user, index) => (
              <li className="adminlistitem">
                {this.getUser(user, index)}
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    // connections: state.connections.connections.connections,
    users: state.users.users,
    user: state.profile.profile,
    allconnections: state.connections,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    removeConnections: (bundle) => {
      dispatch(removeConnections(bundle));
    },
    favConnections: (bundle) => {
      dispatch(favConnections(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Connections);
