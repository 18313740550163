import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// Components
import PasswordResetFormBase from '../components/PasswordResetFormBase';
// Reducers
import { setNewUser, setUser } from '../sagas/loginSaga/reducer';

export class ResetPasswordBase extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loadForm: true,
      step: 1,
    };
    this.changeStep = this.changeStep.bind(this);
  }

  UNSAFE_componentWillMount = () => {
    document.title = 'Trevel';
  };

  changeStep(step) {
    this.setState({
      step,
    });
  }

  getStep(step) {
    if (step === 1) {
      return <PasswordResetFormBase changeStep={this.changeStep} />;
    } else if (step === 2) {
      return (
        <div>
          <p>
            If a user exist with this address we will send an email
            with a link to reset your password.
          </p>
          <Link
            to={{
              pathname: '/login',
            }}
          >
            Return To Log In
          </Link>
        </div>
      );
    } else if (step === 3) {
      return (
        <div>
          <p>
            It looks like this email is link to a social media
            account. You can reset your password then and then come
            back to log into Trevel!
          </p>
          <Link
            to={{
              pathname: '/login',
            }}
          >
            Return To Log In
          </Link>
        </div>
      );
    }
  }

  render() {
    return (
      <div id="login">
        <div className="signup-box-container">
          <div className="signup-box">
            {this.getStep(this.state.step)}
          </div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'header { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer-container{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    invite: state.invite,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user));
    },
    setNewUser: user => {
      dispatch(setNewUser(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordBase);
