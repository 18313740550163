import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import firebase from '../../config/firebase';
// Reducers
import { setUser } from '../../sagas/loginSaga/reducer';
import './ContactUsForm.css';

const INITIAL_STATE = {
  email: '',
  name: '',
  phone: '',
  message: '',
  mood: '',
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class ContactUsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  UNSAFE_componentWillMount() {}

  sendMessage(values) {
    const db = firebase.firestore();
    const timestamp = new Date();
    db.collection('contactus').add({
      created: timestamp,
      message: values.message,
      name: values.name,
      email: values.email,
      phone: values.phone,
    });
  }

  render() {
    const { email, phone, error, name, message, mood } = this.state;
    const isInvalid =
      phone === '' ||
      name === '' ||
      email === '' ||
      message === '' ||
      mood === '';

    return (
      <div id="signup">
        <div>
          <Formik
            initialValues={{
              name: '',
              email: '',
              phone: '',
              message: '',
              mood: '',
            }}
            // validate={values => {
            //   let errors = {};
            //   if (!values.email) {
            //     errors.email = "Required";
            //   } else if (
            //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            //   ) {
            //     errors.email = "Invalid email address";
            //   }
            //   return errors;
            // }}
            onSubmit={values => {
              this.sendMessage(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Container>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Field
                        type="name"
                        name="name"
                        placeholder="Name"
                      />
                      <ErrorMessage name="Name" component="div" />
                      <Field
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                      />
                      <ErrorMessage name="phone" component="div" />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email Address"
                      />
                      <ErrorMessage name="email" component="div" />
                      <Field
                        type="text"
                        name="mood"
                        placeholder="What is your mood?"
                      />
                      <ErrorMessage name="mood" component="div" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <Field
                        type="textarea"
                        name="message"
                        placeholder="Your Message"
                        component="textarea"
                      />
                      <ErrorMessage name="message" component="div" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <button
                        className="contact-submit"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Send Message!
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
          <style
            dangerouslySetInnerHTML={{
              __html: '.nav-signup { display: none }',
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactUsForm);
