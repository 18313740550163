import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
} from 'reactstrap';
import moment from 'moment';
import Modal from 'react-modal';
import SearchInput, { createFilter } from 'react-search-input';
import FilterModal from '../../components/FilterModal';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import DailyLocationSpotlight from '../../components/DailyLocationSpotlight ';
import { filter } from 'lodash';

const query = require('array-query');
const KEYS_TO_FILTERS = [
  'locationTitle',
  'address.city',
  'address.street',
  'tagsBeverages',
  'tagsCuisine',
  'tagsSettings',
  'tagsDateType',
];
const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    width: '300px',
  },
};

class GuideCategoryAll extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      width: 0,
      height: 0,
      tagFilters: [],
      selectedRegion: {
        value: 'Current Location',
        label: 'Current Location',
      },
      filterModalVisible: false,
      filteredArray: [],
    };
  }

  componentDidMount() {
    document.title = 'Trevel';
    this.setTags(this.props.tags, this.props.locations);
    this.searchUpdated('');
  }

  findPromotedLocations(locations, category, tagCat) {
    let setTag = undefined;
    let slug = category.replace(/-/g, ' ');
    let categoryReady = slug.charAt(0).toUpperCase() + slug.slice(1);

    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };

    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };

    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };

    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };

    // Combine Tags'

    let tags = this.props.tags;
    let promotedLocations = [];

    const capitalizeWords = (str) => {
      return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    function getPromoted(locations) {
      let promotedLocationIds = [];

      const tagsBeverages = [];
      const tagsTrevelTags = [];
      const tagsSettings = [];
      const tagsCuisine = [];
      const tagsHours = [];
      const tagsDateType = [];
      const tagsLocationType = [];

      let beverages = [];
      let locationType = [];
      let setting = [];
      let type = [];
      let cuisine = [];
      let hours = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Hours') {
            tagsHours.push(tag.tag);
          } else if (tagcat.parent === 'Beverages') {
            tagsBeverages.push(tag.tag);
          } else if (tagcat.parent === 'Trevel Tags') {
            let tagx = capitalizeWords(tag.tag);
            tagsTrevelTags.push(tagx);
          } else if (tagcat.parent === 'Date Type') {
            tagsDateType.push(tag.tag);
          } else if (tagcat.parent === 'Cuisine Type') {
            tagsCuisine.push(tag.tag);
          } else if (tagcat.parent === 'Setting Type') {
            tagsSettings.push(tag.tag);
          } else if (tagcat.parent === 'Location Type') {
            tagsLocationType.push(tag.tag);
          }
        });
      });

      locations.forEach((location) => {
        if (tagsBeverages.includes(categoryReady) === true) {
          if (
            location.tagsBeverages !== undefined &&
            location.tagsBeverages.length !== 0
          ) {
            location.tagsBeverages.forEach((tagx) => {
              if (tagx === categoryReady) {
                const status = promotedLocationIds.includes(
                  location.locationId,
                );
                if (status === false) {
                  promotedLocationIds.push(location.locationId);
                  promotedLocations.push(location);
                }
              }
            });
          }
        } else if (tagsDateType.includes(categoryReady) === true) {
          if (
            location.tagsDateType !== undefined &&
            location.tagsDateType.length !== 0
          ) {
            location.tagsDateType.forEach((tagx) => {
              if (tagx === categoryReady) {
                const status = promotedLocationIds.includes(
                  location.locationId,
                );
                if (status === false) {
                  promotedLocationIds.push(location.locationId);
                  promotedLocations.push(location);
                }
              }
            });
          }
        } else if (
          tagsLocationType.includes(categoryReady) === true
        ) {
          if (
            location.tagsLocationType !== undefined &&
            location.tagsLocationType.length !== 0
          ) {
            location.tagsLocationType.forEach((tagx) => {
              if (tagx === categoryReady) {
                const status = promotedLocationIds.includes(
                  location.locationId,
                );
                if (status === false) {
                  promotedLocationIds.push(location.locationId);
                  promotedLocations.push(location);
                }
              }
            });
          }
        } else if (tagsSettings.includes(categoryReady) === true) {
          if (
            location.tagsSettings !== undefined &&
            location.tagsSettings.length !== 0
          ) {
            location.tagsSettings.forEach((tagx) => {
              if (tagx === categoryReady) {
                const status = promotedLocationIds.includes(
                  location.locationId,
                );
                if (status === false) {
                  promotedLocationIds.push(location.locationId);
                  promotedLocations.push(location);
                }
              }
            });
          }
          if (
            location.tagsSetting !== undefined &&
            location.tagsSetting.length !== 0
          ) {
            location.tagsSetting.forEach((tagx) => {
              if (tagx === categoryReady) {
                const status = promotedLocationIds.includes(
                  location.locationId,
                );
                if (status === false) {
                  promotedLocationIds.push(location.locationId);
                  promotedLocations.push(location);
                }
              }
            });
          }
        } else if (tagsCuisine.includes(categoryReady) === true) {
          if (
            location.tagsCuisine !== undefined &&
            location.tagsCuisine.length !== 0
          ) {
            location.tagsCuisine.forEach((tagx) => {
              if (tagx === categoryReady) {
                const status = promotedLocationIds.includes(
                  location.locationId,
                );
                if (status === false) {
                  promotedLocationIds.push(location.locationId);
                  promotedLocations.push(location);
                }
              }
            });
          }
        } else if (
          tagsTrevelTags.includes(capitalizeWords(categoryReady)) ===
          true
        ) {
          if (
            location.tagsTrevelTags !== undefined &&
            location.tagsTrevelTags.length !== 0
          ) {
            location.tagsTrevelTags.forEach((tagx) => {
              if (capitalizeWords(tagx) === categoryReady) {
                const status = promotedLocationIds.includes(
                  location.locationId,
                );
                if (status === false) {
                  promotedLocationIds.push(location.locationId);
                  promotedLocations.push(location);
                }
              }
            });
          }
        }
        if (
          location.tagsMain !== undefined &&
          location.tagsMain.length !== 0
        ) {
          if (location.tagsMain === categoryReady) {
            const status = promotedLocationIds.includes(
              location.locationId,
            );
            if (status === false) {
              promotedLocationIds.push(location.locationId);
              promotedLocations.push(location);
            }
          }
        }
      });
      return promotedLocations;
    }

    let i = 0;

    while (promotedLocations.length === 0 && i !== 10) {
      promotedLocations = getPromoted(locations);
      i++;
    }

    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
    let shuffledLocations = shuffleArray(promotedLocations);
    let topThree = [];
    let topThreeIds = [];
    shuffledLocations.forEach((location) => {
      const status = topThreeIds.includes(location.locationId);
      if (status === false) {
        topThreeIds.push(location.locationId);
        topThree.push(location);
      }
    });

    let bundle = {
      promotedLocations: topThree,
      setTag,
    };

    return bundle;
  }

  getTagCount(userLove) {
    let userTags = [];
    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };
    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };
    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };
    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };
    let allTags = {
      tagsBeverages: [],
      tagsDateType: [],
      tagsSetting: [],
      tagsCuisine: [],
    };
    // Combine Tags

    userLove.tagsBeverages.forEach((tagcat, x) => {
      let n = allTags.tagsBeverages.includes(tagcat);
      if (n === false) {
        allTags.tagsBeverages.push(tagcat);
      }
    });

    userLove.tagsDateType.forEach((tagcat, x) => {
      let n = allTags.tagsDateType.includes(tagcat);
      if (n === false) {
        allTags.tagsDateType.push(tagcat);
      }
    });

    userLove.tagsSetting.forEach((tagcat, x) => {
      let n = allTags.tagsSetting.includes(tagcat);
      if (n === false) {
        allTags.tagsSetting.push(tagcat);
      }
    });

    userLove.tagsCuisine.forEach((tagcat, x) => {
      let n = allTags.tagsCuisine.includes(tagcat);
      if (n === false) {
        allTags.tagsCuisine.push(tagcat);
      }
    });

    let tagCount =
      allTags.tagsCuisine.length +
      allTags.tagsSetting.length +
      allTags.tagsDateType.length +
      allTags.tagsBeverages.length;
    allTags.tagsSetting;

    return tagCount;
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  getSingle(promotedLocations) {
    let location = promotedLocations[0];
    if (promotedLocations.length === 1) {
      return (
        <div>
          <Link
            to={{
              pathname: '/location/' + location.locationId,
              state: {
                location: { location },
                // eventId: { eventId },
                // token: { token },
                type: 500,
              },
            }}
          >
            <div>
              <img
                src={this.getImage('hero', location)}
                style={{ width: '100%' }}
              />
              <div className="section">
                <span className="title">
                  {location.locationTitle}
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: location.locationDescription,
                  }}
                  className="editoOverRide"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span className="mainTagClear">{location.tagsMain}</span>
      );
    }
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  getSelectOptions() {
    let locations = [];
    if (
      this.props.locations !== undefined &&
      Array.isArray(this.props.locations) === true
    ) {
      locations = this.props.locations;
    }

    // if (
    //   this.state.locations !== undefined &&
    //   this.state.locations.length !== 0
    // ) {
    //   locations = this.state.locations;
    // } else if (
    //   this.props.uauth.guide !== undefined &&
    //  this.props.locations !== undefined &&
    //  this.props.locations.length !== 0
    // ) {
    //   locations =this.props.locations;
    // }

    let allTags = [];
    let selectOptions = [];
    locations.forEach((location) => {
      selectOptions.push({
        value: location.locationId,
        label: location.locationTitle,
      });

      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsMain !== undefined) {
        let status = allTags.includes(location.tagsMain);
        if (status === false) {
          allTags.push(location.tagsMain);
        }
      }
    });

    this.state.tagFilters.forEach((tagx, indexA) => {
      tagx.tags.forEach((tag, indexB) => {
        let status = allTags.includes(tag.tag);
        if (status === true) {
          selectOptions.push({
            value: tag,
            label: tag.tag,
          });
        }
      });
    });

    let entertainment = [];
    if (this.props.entertainment !== undefined) {
      entertainment = this.props.entertainment;
    }
    entertainment.forEach((entertainer) => {
      selectOptions.push({
        value: entertainer.slug + '-xxxx',
        label: entertainer.entertainmentTitle,
      });
    });

    let reversedOptions = selectOptions.reverse();
    // this.setTagFilters(updatedTagFilters);
    return reversedOptions;
  }

  // Filter

  getModalHeader = (value) => {
    return (
      <Row className="no-margin">
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
        <Col lg={8} md={8} sm={8} xs={8}>
          <center>
            <span className="page-header white">
              Filter Locations
            </span>
          </center>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
          <a onClick={() => this.closeFilterModal()}>
            <MaterialIcon icon="check" size={25} color="white" />
          </a>
        </Col>
      </Row>
    );
  };

  setDistanceFilter = (value) => {
    this.setState({
      distanceFilterState: value,
    });
  };

  getButton(value, distance, state) {
    if (value !== state) {
      return (
        <button
          className="redbutton full-width"
          onClick={() => {
            this.setDistanceFilter(value);
          }}
        >
          {distance}
        </button>
      );
    } else {
      return (
        <button className="greybutton full-width">{distance}</button>
      );
    }
  }

  sortAndWeight(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    const sortedLocations = this.sortLocations(
      locations,
      distanceFilterState,
      currentLongitude,
      currentLatitude,
      sortState,
    );
    // const weightedLocations = this.getWeightedLocations(
    //   sortedLocations,
    //   this.props.profile,
    // );

    return sortedLocations;
  }

  sortLocations(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    if (sortState === 1) {
      const locationsArray = [];
      locations.forEach((location) => {
        const miles = location.address.distance;
        if (location.type !== 1) {
          if (distanceFilterState === 0) {
            if (miles <= 50) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 1) {
            if (miles <= 10) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 2) {
            locationsArray.push(location);
          }
        }
      });
      return _.sortBy(locationsArray, (o) => o.address.distance);
    } else {
      return _.sortBy(locations, (o) => o.locationTitle);
    }
  }

  searchUpdated(term, locationsx) {
    let locationsWithDistance = [];
    if (this.props.locations !== undefined) {
      locationsWithDistance = this.props.locations;
    }

    if (this.state.locationsWithDistance !== undefined) {
      locationsWithDistance = this.state.locationsWithDistance;
    }

    if (this.props.locations.length !== 0) {
      locationsWithDistance = this.props.locations;
    }

    if (locationsx !== undefined) {
      this.props.setLocations(locationsx);
    }
    const filteredList = locationsWithDistance.filter(
      createFilter(term, KEYS_TO_FILTERS),
    );

    let filteredLocations = [];
    const sortedLocations = this.sortAndWeight(
      filteredList,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );

    let selectedTags = [];

    let tagFilters = this.state.tagFilters;
    if (this.props.uauth.guide.tagFilters.length !== 0) {
      tagFilters = this.props.uauth.guide.tagFilters;
    }

    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });

    if (selectedTags.length > 0) {
      filteredLocations = this.updateLocations(
        tagFilters,
        sortedLocations,
      );
    } else {
      filteredLocations = sortedLocations;
    }

    if (filteredLocations.length === 0) {
      this.setDistanceFilter(2);
      const sortedLocationsx = this.sortAndWeight(
        filteredList,
        2,
        this.state.currentLongitude,
        this.state.currentLatitude,
        this.state.sortState,
      );

      if (selectedTags.length > 0) {
        filteredLocations = this.updateLocations(
          tagFilters,
          sortedLocationsx,
        );
      } else {
        filteredLocations = sortedLocationsx;
      }
    }

    this.setState({
      searchTerm: term,
      filteredArray: filteredLocations,
    });
  }

  openFilterModal(user) {
    this.setState({ filterModalVisible: true });
  }

  closeFilterModal = () => {
    this.searchUpdated('');
    window.scrollTo(0, 0);
    this.setState({ filterModalVisible: false });
  };

  filterTags(tagFilters, locationsx) {
    let locations = [];
    if (locationsx !== undefined) {
      locations = locationsx;
    }
    let updatedTagFilters = [
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
      {
        id: '5vhCJSC7VgBE1MFNctgY',
        parent: 'Location Type',
        sid: 'locationType',
        tags: [],
      },
      {
        id: '7ayttBEiZgiM5TZ2iYsM',
        parent: 'Cuisine Type',
        sid: 'cuisineType',
        tags: [],
      },
      {
        id: '7kJeYaz9m5q434zO28Pg',
        parent: 'Setting Type',
        sid: 'setting',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Experience Type',
        sid: 'experienceType',
        tags: [],
      },
      {
        id: 'BW3qPhXbN7C4XMXWh4tv',
        parent: 'Date Type',
        sid: 'dateType',
        tags: [],
      },
      {
        id: 'CSKtI08vSBtyqKr10Nef',
        parent: ' Dietary Must Haves',
        sid: 'dietary',
        tags: [],
      },
      {
        id: 'F7R2nsOyZTiqKbW6AhFQ',
        parent: 'Hours',
        sid: 'hours',
        tags: [],
      },
      {
        id: 'xeytdS8Xwjlou67MVbVY',
        parent: 'Beverages',
        sid: 'beverages',
        tags: [],
      },
      {
        id: 'rxsnb1c2XX8c4MpugeEP',
        parent: 'Genre',
        sid: 'genre',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Entertainment Type',
        sid: 'entertainmentType',
        tags: [],
      },
      {
        id: 'eAVFUFVivBObckEaKiRR',
        parent: 'Event Type',
        sid: 'eventType',
        tags: [],
      },
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
    ];
    let allTags = [];

    locations.forEach((location) => {
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      // if (location.tagsSettings !== undefined) {
      //   location.tagsSettings.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsEventType !== undefined) {
        location.tagsEventType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsMain !== undefined) {
        let status = allTags.includes(location.tagsMain);
        if (status === false) {
          allTags.push(location.tagsMain);
        }
      }
    });
    if (tagFilters != undefined) {
      tagFilters.forEach((tagx, indexA) => {
        if (tagx.tags !== undefined) {
          tagx.tags.forEach((tag, indexB) => {
            let status = allTags.includes(tag.tag);
            if (status === true) {
              updatedTagFilters[indexA].tags.push(tag);
            }
          });
        }
      });
    }

    return updatedTagFilters;

    // this.setState({
    //   tagFilters: updatedTagFilters,
    // });
    // this.setTagFilters(updatedTagFilters);
  }

  updateLocations(tags, locations) {
    // This will have every location but with its tags put into a single list
    const locationsReady = [];
    // For each location we push all of its tags into the allTags array
    locations.forEach((location) => {
      const newLocation = location;
      const allTags = [];
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      newLocation.tags = allTags;

      locationsReady.push(newLocation);
    });
    // const dif = _.differenceWith(cuisine, beverages, _.isEqual);
    // const filtered = _.filter(coll, item => _.some(filter, key => _.includes(item.tags, key)));

    // Filter Locations Based On Selected Categories
    function filterByMultipleFields(coll, filter) {
      // Organize Tags into their categories
      const tagsBeverages = [];
      const tagsSettings = [];
      const tagsCuisine = [];
      const tagsHours = [];
      const tagsDateType = [];
      const tagsLocationType = [];

      let beverages = [];
      let locationType = [];
      let setting = [];
      let type = [];
      let cuisine = [];
      let hours = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Hours') {
            tagsHours.push(tag.tag);
          } else if (tagcat.parent === 'Beverages') {
            tagsBeverages.push(tag.tag);
          } else if (tagcat.parent === 'Date Type') {
            tagsDateType.push(tag.tag);
          } else if (tagcat.parent === 'Cuisine Type') {
            tagsCuisine.push(tag.tag);
          } else if (tagcat.parent === 'Setting Type') {
            tagsSettings.push(tag.tag);
          } else if (tagcat.parent === 'Location Type') {
            tagsLocationType.push(tag.tag);
          }
        });
      });

      filter.forEach((tag) => {
        if (tagsBeverages.includes(tag) === true) {
          beverages = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsBeverages, key),
            ),
          );
        } else if (tagsDateType.includes(tag) === true) {
          type = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsDateType, key),
            ),
          );
        } else if (tagsLocationType.includes(tag) === true) {
          locationType = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsLocationType, key),
            ),
          );
        } else if (tagsSettings.includes(tag) === true) {
          setting = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsSettings, key),
            ),
          );
        } else if (tagsHours.includes(tag) === true) {
          hours = _.filter(locationsReady, (item) =>
            _.some(filter, (key) => _.includes(item.tagsHours, key)),
          );
        } else if (tagsCuisine.includes(tag) === true) {
          cuisine = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsCuisine, key),
            ),
          );
        }
      });
      const filtered = _.filter(coll, (item) =>
        _.some(filter, (key) => _.includes(item.tags, key)),
      );
      const arrays = [
        cuisine,
        beverages,
        type,
        setting,
        hours,
        locationType,
      ];
      const result = _.spread(_.union)(arrays);
      return result;
    }
    const filter = this.selectedTags(tags);
    const result = filterByMultipleFields(locationsReady, filter);

    return result;
    // if (this.props.selectedCategories.length > 0) {
    //   this.setState({
    //     filteredLocations: result,
    //   });
    // } else {
    //   this.setState({
    //     filteredLocations: locations,
    //   });
    // }
  }

  selectedTags(tagFilters) {
    let selectedTags = [];
    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });
    return selectedTags;
  }

  getTags(tagFilters) {
    let activeTags = [];
    tagFilters.forEach((tag) => {
      tag.tags.forEach((tagx) => {
        if (tagx.checked === true) {
          activeTags.push(tagx);
        }
      });
    });
    return (
      <div>
        {activeTags.map((tag, index) => (
          <span className="tag-big" key={tag}>
            {tag.tag}
          </span>
        ))}
        <a
          onClick={() => {
            this.openFilterModal();
          }}
          style={{ color: '#f21d59', fontWeight: '600' }}
        >
          <span
            style={{
              color: 'white',
              fontWeight: '600',
              marginRight: '10px',
            }}
          >
            {activeTags.length === 0 ? 'Filter' : 'Change'}
          </span>
        </a>
        <span
          onClick={() => {
            this.clearTagFilters(this.state.tagFilters);
          }}
          style={{ fontSize: '16px', color: 'white' }}
        >
          Clear
        </span>
      </div>
    );
  }

  setTagFilters = (tagFilters) => {
    this.setState({ tagFilters });
  };

  setTags(tags, locations) {
    let filteredTags = this.filterTags(tags, locations);
    let updatedTags = filteredTags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = 1;
        // if (this.props.user.authenticated === true) {
        //   // status = this.getTagStatus(tag.tag);
        // }
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let drinks = {
    //   checked: false,
    //   parentindex: 6,
    //   status: 1,
    //   tag: 'Drinks',
    //   tagindex: updatedTags[6].tags.length,
    // };

    let food = {
      checked: false,
      parentindex: 1,
      status: 1,
      tag: 'Food',
      tagindex: updatedTags[1].tags.length,
    };

    // updatedTags[6].tags.push(drinks);
    updatedTags[1].tags.push(food);
    updatedTags.forEach((tagcat, x) => {
      updatedTags[x].tags = updatedTags[x].tags.sort((a, b) =>
        a.tag.localeCompare(b.tag),
      );
      updatedTags[x].tags = updatedTags[x].tags.reverse();
      updatedTags[x].tags.map((tag, y) => {
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let sortedTags = []
    // updatedTags.forEach((tagcat, x) => {
    //   sortedTags = _.sortBy(updatedTags[x].tags, o => o.status);
    // }

    let origionalTags = [];
    updatedTags.forEach((tagcat) => {
      origionalTags.push(tagcat);
    });

    this.setState({
      tagFilters: updatedTags,
      origionalTags,
    });
    this.setTagFilters(updatedTags);
  }

  // criteria Handlers
  selectRegionChange = (values) => {
    this.setState({
      selectedRegion: values,
    });
  };

  getRegions(regions) {
    let selectOptions = [];
    selectOptions.push({
      value: 'Current Location',
      label: 'Current Location',
    });
    regions.forEach((region) => {
      selectOptions.push({
        value: region.name,
        label: region.name,
      });
    });
    return selectOptions;
  }

  getFilteredLocations(locations, selectedRegion) {
    let filteredLocations = [];
    let regionInfo = query('name')
      .is(selectedRegion.value)
      .on(this.props.regions);
    if (selectedRegion.value !== 'Current Location') {
      locations.forEach((location) => {
        regionInfo[0].zips.forEach((zip) => {
          let status = location.address.zip === zip;
          if (status === true) {
            filteredLocations.push(location);
          }
        });
      });
    } else {
      filteredLocations = locations;
    }
    return filteredLocations;
  }

  getFilterModal() {
    if (this.state.tagFilters.length !== 0) {
      return (
        <FilterModal
          tagFilters={this.state.tagFilters}
          setTagFilters={this.setTagFilters}
          locations={this.state.locations}
          // selectOptions={selectOptions}
        />
      );
    }
  }

  // criteria Handlers
  selectChange = (values) => {
    if (values.value.length > 15) {
      if (values.value.slice(-5) === '-xxxx') {
        const editedText = values.value.slice(0, -5); //'abcde'
        this.props.history.push('/entertainment/' + editedText);
      } else {
        this.props.history.push('/location/' + values.value);
      }
    } else {
      this.props.history.push('/guide/category/' + values.label);
    }
    // let updatedTags = this.state.tagFilters;
    // updatedTags[values.value.parentindex].tags[
    //   values.value.tagindex
    // ].checked = true;
    // this.setState({
    //   tagFilters: updatedTags,
    // });
    // this.setTagFilters(updatedTags);
    // this.searchUpdated(this.state.searchTerm);
    // this.clearLanding(false);
    // this.updateUauth(updatedTags);
    // this.props.history.push('/guide/category/' + values.label);
  };

  moodTextChange = (text) => {
    if (text.length > 0) {
      this.setState({
        moodText: text,
      });
    }
  };

  render() {
    // let filteredLocations = this.getFilteredLocations(
    //   this.state.filteredArray,
    //   this.state.selectedRegion,
    // );

    let filteredLocations = this.state.filteredArray;

    const bundle = this.findPromotedLocations(
      filteredLocations,
      this.props.category,
      this.props.tagCat,
    );
    let promotedLocations = bundle.promotedLocations;

    let count = this.props.count;
    // let regions = this.getRegions(this.props.regions);
    let selectOptions = this.getSelectOptions();
    if (promotedLocations.length > 0) {
      return (
        <div>
          <div>
            <DailyLocationSpotlight location={promotedLocations[0]} />
          </div>
          <div className="homeMoodBar">
            <Row style={{ margin: '0px 0px 0px 0px' }}>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                // className="no-padding"
                style={{ padding: '5px 5px 5px 5px' }}
              >
                <div
                  style={{
                    width: '350px',
                    margin: '0px auto',
                  }}
                >
                  <span
                    className="left"
                    style={{
                      width: '300px',
                    }}
                  >
                    <Select
                      value={this.state.selectInput}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) => this.selectChange(e, 0, 'one')}
                      options={selectOptions}
                      onInputChange={(e) =>
                        this.moodTextChange(e, 0, 'one')
                      }
                      isMulti={false}
                      placeholder={'Search!'}
                    />
                  </span>

                  <a
                    onClick={() => {
                      this.gotoSearch(this.state.moodText);
                    }}
                  >
                    <span
                      className="right squareSearch"
                      style={{
                        padding:
                          this.state.count > 2
                            ? '4px 4px 0px'
                            : '4px 4px 0px',
                      }}
                    >
                      <MaterialIcon
                        icon="search"
                        size={35}
                        color="#f21d59"
                      />
                    </span>
                  </a>
                </div>
              </Col>
            </Row>
            {/* <Row
              style={{ background: 'whitesmoke', marginTop: '5px' }}
            >
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                // className="no-padding"
                style={{ padding: '5px 5px 5px 5px' }}
              >
                <span
                  className="left"
                  style={{ marginRight: '10px' }}
                >
                  Area:
                </span>

                <span
                  className="left"
                  style={{
                    width: '300px',
                    height: '50px',
                  }}
                >
                  <Select
                    value={this.state.selectedRegion}
                    closeMenuOnSelect={true}
                    blurInputOnSelect={false}
                    onChange={(e) =>
                      this.selectRegionChange(e, 0, 'one')
                    }
                    options={regions}
                    // onInputChange={(e) =>
                    //   this.moodTextChange(e, 0, 'one')
                    // }
                    isMulti={false}
                    placeholder={'Select area of City'}
                  />
                </span>
              </Col>
              <div
                className="cityTagBar"
                // style={{ textAlign: 'center' }}
              >
                {this.getTags(this.state.tagFilters)}
              </div>
            </Row> */}
          </div>
          <div className="homeSectionAll">
            {promotedLocations.length > 0 && (
              <div>
                <Container>
                  <Row style={{ marginBottom: '30px' }}>
                    {promotedLocations.map((location, index) => (
                      <Col
                        xs={6}
                        md={4}
                        sm={6}
                        lg={3}
                        className="no-padding"
                      >
                        <div
                          style={{
                            border: '0px solid rgba(0, 0, 0, 0.125)',
                            background: 'white !important',
                          }}
                          className="multiSlide"
                        >
                          {this.props.editMode === false && (
                            <div
                              className={
                                count === 2
                                  ? 'guideAddButtonMobile'
                                  : 'guideAddButton'
                              }
                            >
                              <a
                                className="guideAddButtonLink"
                                onClick={() => {
                                  this.props.addLocation(
                                    this.props.customEvent,
                                    location,
                                  );
                                }}
                              >
                                <span>
                                  <MaterialIcon
                                    icon="add"
                                    size={30}
                                    color="white"
                                  />
                                </span>
                              </a>
                            </div>
                          )}

                          <Link
                            to={{
                              pathname:
                                '/location/' + location.locationId,
                              state: {
                                location: { location },
                                // eventId: { eventId },
                                // token: { token },
                                type: 500,
                              },
                            }}
                          >
                            <CardImg
                              top
                              width="100%"
                              src={this.getImage('hero', location)}
                              alt="articleHero"
                            />
                            <CardBody style={{ textAlign: 'left' }}>
                              <div
                                style={{
                                  paddingTop: '10px',
                                  border: '0px',
                                }}
                              >
                                <span className="title">
                                  {location.locationTitle}
                                </span>
                                {this.getMainTag(location)}
                                {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                                <CardText>
                                  <span className="editoOverRide">
                                    {this.truncate(
                                      location.locationDescription,
                                    )}
                                  </span>
                                </CardText>
                                <CardText />
                                <div
                                  style={{
                                    display: 'block',

                                    bottom: '10px',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      display: 'inline-block',
                                    }}
                                    className="left "
                                  >
                                    {location.address.region}
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Link>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            )}
            {/* {this.getSingle(promotedLocations)} */}
          </div>
          <Modal
            isOpen={this.state.filterModalVisible}
            // onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeFilterModal}
            style={modalStyles}
            // contentLabel="Wait for Connection?"
          >
            <div className="full-height">
              <div className="modalHeader">
                {this.getModalHeader()}
              </div>
              <div className="secondary-title-bar">
                <div>
                  <Container>
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding text-center"
                      >
                        <span>What are you in the mood for?</span>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              <div className="wizard-section-border">
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <h5 className="red">Distance</h5>
                    <span>Radius (Miles):</span>
                  </Col>
                </Row>

                <Row>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="padding-ten"
                  >
                    {this.getButton(
                      1,
                      '10',
                      this.state.distanceFilterState,
                    )}
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="padding-ten"
                  >
                    {this.getButton(
                      0,
                      '50',
                      this.state.distanceFilterState,
                    )}
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="padding-ten"
                  >
                    {this.getButton(
                      2,
                      '100+',
                      this.state.distanceFilterState,
                    )}
                  </Col>
                </Row>
              </div>
              <div className="wizard-section-border">
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.getFilterModal()}
                  </Col>
                </Row>
              </div>
              {/* <div className="section">{this.getModalContent()}</div> */}
            </div>
          </Modal>
        </div>
      );
    } else {
      return (
        <div
          style={{
            padding: '100px 10px 30px 10px',
            textAlign: 'center',
          }}
        >
          <h4 style={{ fontSize: '30px', marginBottom: '20px' }}>
            Sorry!
          </h4>
          <h4 style={{ fontSize: '20px' }}>
            It looks like we don{"'"}t have any destinations tagged to{' '}
            {this.props.category} in your area yet.
          </h4>
          <h4 style={{ fontSize: '20px' }}>
            This guide is new and growing everyday so please check
            back often.
          </h4>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    tags: state.tags.tags,
    entertainment: state.entertainment.entertainment,
    regions: state.regions.regions,
    tags: state.tags.tags,
    locations: state.locations.locations,
    uauth: state.unauth,
  };
}

export default connect(mapStateToProps)(GuideCategoryAll);
