import React from 'react';
import { connect } from 'react-redux';
// Components
import SignInForm from '../components/SignInForm';
import firebase from '../config/firebase';
import whitelogo from '../images/TrevelUnderlinedWhite.svg';
// Reducers
import { setNewUser, setUser } from '../sagas/loginSaga/reducer';
import { getEvents } from '../sagas/userEventsSaga/reducer';

export class LoginScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loadForm: false,
    };
  }

  UNSAFE_componentWillMount = () => {
    if (this.props.user.authenticated === true) {
      this.props.history.push('/home');
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      }
      //when data is loaded, set loading to false
      this.setState({ loading: false });
    });

    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        // const token = result.credential.accessToken;
        if (result.user !== null) {
          const user = result.user;
          if (result.additionalUserInfo.isNewUser === true) {
            const user = {
              id: result.user.uid,
              userName: result.user.displayName,
              email: result.user.email,
              authenticated: true,
              newuser: true,
              claimLink: this.props.unauth.claimLink,
              claimId: this.props.unauth.claimId,
              vendorSignup: this.props.unauth.vendorSignup,
              referalId: this.props.unauth.referalId,
            };
            // this.props.setNewUser(user);
          } else {
            const user = {
              id: result.user.uid,
              userName: result.user.displayName,
              email: result.user.email,
              authenticated: true,
              newuser: false,
              claimLink: this.props.unauth.claimLink,
              claimId: this.props.unauth.claimId,
              vendorSignup: this.props.unauth.vendorSignup,
              referalId: this.props.unauth.referalId,
              eventId: null,
              inviteId: null,
            };

            if (this.props.newEvent.clear === false) {
              user.createEvent = true;
            }

            // Check if this user was sent an invite to join
            if (this.props.invite.event.title !== null) {
              user.invited = true;
              user.eventId = this.props.invite.event[0].eventId;
              user.inviteType =
                this.props.invite.event[0].invitations[
                  this.props.invite.event[0].token
                ].type;
            }

            // We will want to seperate this from setUser
            if (this.props.invite.event.title !== null) {
              let profile = [];
              const db = firebase.firestore();

              db.collection('users')
                .doc(result.user.uid)
                .get()
                .then((doc) => {
                  if (doc && doc.exists) {
                    const {
                      firstName,
                      lastName,
                      userName,
                      profilePhotoPath,
                      userId,
                      sex,
                      email,
                      userType,
                      vendorAccount,
                      tags,
                      zones,
                      zoneId,
                      state,
                      favorites,
                      favoriteEntertainment,
                    } = doc.data();
                    profile.push({
                      userId: doc.id,
                      firstName,
                      lastName,
                      userName,
                      profilePhotoPath,
                      sex,
                      email,
                      userType,
                      vendorAccount,
                      tags,
                      zones,
                      zoneId,
                      state,
                      favorites,
                      favoriteEntertainment,
                    });
                  }

                  this.updateEvent(
                    this.props.invite.event[0],
                    profile[0].firstName,
                    profile[0].lastName,
                    profile[0].userName,
                    this.props.invite.event[0].creationDetails
                      .plannerId,
                    profile[0].userId,
                    this.props.invite,
                    user,
                  );
                })
                .catch(function (error) {
                  console.log('Error getting documents: ', error);
                });
            } else {
              this.props.setUser(user);
            }
          }
        } else {
          this.setState({ loadForm: true });
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        // console.log('errors - errorCode', errorCode);
        // console.log('errors - errorMessage', errorMessage);
        // console.log('errors - email', email);
        // console.log('errors - credential', credential);
        // ...
      });
  };

  updateEvent(
    event,
    firstName,
    lastName,
    userName,
    senderId,
    userId,
    invite,
    user,
  ) {
    const db = firebase.firestore();
    const eventId = event.eventId;
    const userEvent = db.collection('user_events').doc(eventId);
    const timestamp = new Date();

    const userConnectionObject = {
      connected: true,
      created: timestamp,
      userId: senderId,
    };

    const senderConnectionObject = {
      connected: true,
      created: timestamp,
      userId: userId,
    };

    const usersObject = event.users;
    const invitationObject = event.invitations;
    const fullname = firstName + ' ' + lastName;
    let updatedUser = {};

    // Add User With New UID
    if (invite.event[0].eventSettings.eventApproved === true) {
      updatedUser = {
        access: true,
        approved: true,
        editor: false,
        attending: true,
        rejected: false,
        inviteAction: true,
        profile: {
          fullName: fullname,
          profilePhotoPath: '',
          userName: userName,
        },
      };
    } else {
      // is planning invite
      updatedUser = {
        access: true,
        approved: false,
        editor: true,
        attending: true,
        rejected: false,
        inviteAction: true,
        profile: {
          fullName: fullname,
          profilePhotoPath: '',
          userName: userName,
        },
      };
    }

    usersObject[userId] = updatedUser;
    // Delete temp id
    delete usersObject[event.token];
    // Update Invitations
    invitationObject[userId] = event.invitations[event.token];
    invitationObject[userId].userId = userId;
    // Delete Old Invite
    delete invitationObject[event.token];
    // Make New Event
    // let updatedEvent = event;
    // updatedEvent.users = usersObject;
    // updatedEvent.invitations = invitationObject;
    // Delete Token Property
    // delete updatedEvent.token;
    // console.log("updatedEvent", updatedEvent);
    const setDoc = userEvent
      .update({
        users: usersObject,
        invitations: invitationObject,
        // status: 2
      })
      .then(() => {
        const setDoc = db
          .collection('connections')
          .doc(userId)
          .set({
            connections: {
              [senderId]: {
                connected: true,
                created: timestamp,
                userId: senderId,
              },
            },
          })
          .then(() => {
            const senderConnections = db
              .collection('connections')
              .doc(senderId);
            const setDoc3 = senderConnections
              .update({
                ['connections.' + userId]: senderConnectionObject,
              })
              .then(() => {
                this.props.getEvents(userId);
                this.props.setUser(user);
              });
          });
      });
  }

  loadForm() {
    if (this.state.loadForm === true) {
      return (
        <div className="signup-box">
          <SignInForm />
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
            display: 'table',
            width: '100%',
            background:
              'linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%)',
          }}
        >
          <div
            style={{
              position: 'relative',
              textAlign: 'center',
              display: 'table-cell',
              verticalAlign: 'middle',
              top: '-100px',
            }}
          >
            <h2
              style={{
                margin: '0px 0px 10px 0px',
                color: 'white',
                fontSize: '40px',
                textShadow: '#bb4a65 1px 4px',
              }}
            >
              <img
                style={{
                  width: '90%',
                  maxWidth: '500px',
                  margin: '0px auto',
                }}
                src={whitelogo}
                className="mobileHide"
              />
              <img
                style={{
                  width: '80%',
                  maxWidth: '500px',
                  margin: '0px auto',
                }}
                src={whitelogo}
                className="mobileShow"
              />
            </h2>
            {/* <span
              style={{
                fontSize: '35px',
                lineHeight: '40px',
                color: 'white',
                textShadow: '#bb4a65 1px 4px',
                marginLeft: '30px',
                paddingTop: '25px',
              }}
              className="mobileHide"
            >
              Creating a Guide
              <br />
              just for you
            </span>
            <span
              style={{
                fontSize: '30px',
                lineHeight: '40px',
                color: 'white',
                textShadow: '#bb4a65 1px 4px',
                paddingTop: '15px',
              }}
              className="mobileShow"
            >
              Creating a Guide
              <br />
              just for you
            </span> */}
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'body {height: 100%} header { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer-container{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    if (this.state.redirect === true) {
      this.props.setUser(this.state.user);
    }
    // if (this.props.user.authenticated === true) {
    //   return <Redirect to="/home" />;
    // }
    return (
      <div id="login">
        <div className="signup-box-container">{this.loadForm()}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body {height: 100%} header { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer-container{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    unauth: state.unauth,
    profile: state.profile.profile,
    invite: state.invite,
    newEvent: state.newEvent,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
    setNewUser: (user) => {
      dispatch(setNewUser(user));
    },
    getEvents: (user) => {
      dispatch(getEvents(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginScreen);
