import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
import UserAvatar from 'react-user-avatar';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../config/firebase';
// Components
import TitleBar from '../components/TitleBar';

export class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  componentDidMount() {
    document.title = 'Profile';
  }

  getTags() {
    if (this.props.profile.tags) {
      const beverages = this.props.profile.tags.tagsBeverages;
      const cuisine = this.props.profile.tags.tagsCuisine;
      return (
        <Row>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="left no-padding"
          >
            <h5>Favorite Drinks</h5>
            <ul>
              {beverages.map(tag => (
                <div>{this.getTag(tag)}</div>
              ))}
            </ul>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="left no-padding"
          >
            <h5>Favorite Cuisine</h5>
            <ul>
              {cuisine.map(tag => (
                <div>{this.getTag(tag)}</div>
              ))}
            </ul>
          </Col>
        </Row>
      );
    }
  }

  tagImport() {
    const db = firebase.firestore();
    let tagArray = [];
    let tags = this.props.tags;
    tagArray.forEach(location => {
      let tagsCuisine = [];
      let tagsDateType = [];
      let tagsSettings = [];
      let tagsBeverages = [];
      let tagsMain = location.tags[0];
      let tagsHours = [];
      let tagBundle = {};

      location.tags.forEach(locationTag => {
        tags.forEach((tagcat, x) => {
          tagcat.tags.map((tag, y) => {
            if (tag.tag === locationTag) {
              if (tagcat.sid === 'hours') {
                tagsHours.push(locationTag);
              } else if (tagcat.sid === 'setting') {
                tagsSettings.push(locationTag);
              } else if (tagcat.sid === 'cuisineType') {
                tagsCuisine.push(locationTag);
              } else if (tagcat.sid === 'dateType') {
                tagsDateType.push(locationTag);
              } else if (tagcat.sid === 'beverages') {
                tagsBeverages.push(locationTag);
              }
            }
          });
        });
      });
      tagBundle.tagsCuisine = tagsCuisine;
      tagBundle.tagsDateType = tagsDateType;
      tagBundle.tagsSettings = tagsSettings;
      tagBundle.tagsHours = tagsHours;
      tagBundle.tagsBeverages = tagsBeverages;
      const update = db
        .collection('locations')
        .doc(location.locationId);
      const setDoc = update
        .update({
          tagsCuisine,
          tagsDateType,
          tagsSettings,
          tagsHours,
          tagsBeverages,
          tagsMain,
        })
        .then(() => {
          console.log('Write succeeded!');
        });
    });
  }

  getTag(tag) {
    return <li>{tag}</li>;
  }

  getState(profile) {
    if (profile.state !== undefined) {
      return (
        <div>
          <h5>State: {this.props.profile.state}</h5>
        </div>
      );
    } else {
      return (
        <div>
          <h5>State: Not Selected Yet</h5>
        </div>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Treveller Profile</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <a
                onClick={value => {
                  this.saveTags(this.props.user.id);
                }}
                className="right"
              >
                <MaterialIcon icon="check" size={25} color="white" />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div id="profile">
        {/* <TitleBar title="Profile" backlink="" /> */}
        {this.getTitleBar()}
        <div className="section">
          <Container>
            <Row>
              <Col className="no-padding">
                <Link
                  to={{
                    pathname: '/profile/edit',
                  }}
                  className="right redbutton"
                >
                  Edit Profile
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className="text-center no-padding">
                <UserAvatar
                  size="120"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
                <h2>
                  {this.props.profile.firstName}{' '}
                  {this.props.profile.lastName}
                </h2>
                <h5>@{this.props.profile.userName}</h5>
              </Col>
            </Row>
            <Row>
              <Col className="no-padding">
                <div className="profile-section">
                  <h5>Email: {this.props.profile.email}</h5>
                </div>
                {this.getState(this.props.profile)}
              </Col>
            </Row>
            {/* <div className='profile-section'>
              <Row>
                <Col className='no-padding'>
                  <Link
                    to={{
                      pathname: '/profile/tag'
                    }}
                    className='right redbutton'
                  >
                    Edit Preferences
                  </Link>
                </Col>
              </Row>
              {this.getTags()}
            </div> */}
          </Container>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    user: state.user,
    tags: state.tags.tags,
  };
}

export default connect(mapStateToProps)(Profile);
