import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import MaterialIcon from 'material-icons-react';
// Styles
import './LocationListItem.css';
// Services
import { deleteDocument } from '../../services/AdminServices';

class AdminArticleListItem extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  getEdit(id) {
    if (this.props.edit === true) {
      const url = '/admin/article/edit/' + id;
      return (
        <div>
          <a href={url} className="right">
            <MaterialIcon icon="edit" size={25} color="#f21d59" />
          </a>{' '}
          <span className="right">
            <MaterialIcon
              icon="delete"
              size={25}
              onClick={() => {
                this.confirmDelete(id);
              }}
            />
          </span>
        </div>
      );
    }
  }

  getPostDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x')
      .subtract(10, 'days')
      .calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getTitleLink(article) {
    const link = '/article/' + article.slug;
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {article.title}
      </a>
    );
  }

  confirmDelete(id) {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this article?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            deleteDocument('articles', id, '/admin/articles'),
        },
        {
          label: 'No',
          onClick: () => console.log('closed'),
        },
      ],
    });
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={6} md={6} sm={6} lg={6} className="left">
                  <span className="label">Title</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Author</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Created Date</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left" />
              </div>
            </li>
            {this.props.articles.map((article, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={6}
                    key={'key' + index}
                  >
                    {this.getTitleLink(article)}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {article.author}
                  </Col>
                  {/* <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {this.getPostDate(article.postdate)}
                  </Col> */}
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {this.getEdit(article.id)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminArticleListItem);
