import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserAvatar from 'react-user-avatar';
import { Col, Row } from 'reactstrap';
import firebase from '../../config/firebase';

// Components

// Reducers

const _ = require('lodash');

class ConversationModal extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      conversation: [],
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    const rdb = firebase
      .firestore()
      .collection('user_conversations')
      .where('eventId', '==', this.props.eventId);
    rdb.onSnapshot(this.onCollectionUpdate);
  }

  onCollectionUpdate = (querySnapshot) => {
    this.setState({
      loading: true,
    });
    const messageArray = [];
    querySnapshot.forEach((doc) => {
      const { message, userId, fullName, eventId, userPhoto, time } =
        doc.data();
      const messageId = doc.id;
      messageArray.push({
        messageId,
        message,
        userId,
        fullName,
        eventId,
        userPhoto,
        time,
      });
    });

    const newMessageArray = _.sortBy(messageArray, ['time']);

    this.setState({
      loading: false,
      conversation: newMessageArray,
    });
  };

  _resetTextInput() {
    const time = firebase.firestore.FieldValue.serverTimestamp();
    const fullName = `${this.props.profile.firstName} ${this.props.profile.lastName}`;
    const message = {
      eventId: this.props.eventId,
      fullName,
      userId: this.props.profile.userId,
      message: this.state.postInput,
      time,
      userPhoto: this.props.profile.profilePhotoPath,
    };

    const db = firebase.firestore();
    const setDoc = db
      .collection('user_conversations')
      .add(message)
      .then((ref) => {
        console.log('Added document with ID: ', ref);
      });
    this.setState({ postInput: '' });
  }

  getTime(post) {
    const time = post.time.getTime();
    const postTime = moment(time).format('h:mm a');
    return postTime;
  }

  showPost(post) {
    return (
      <Row>
        <Col
          xs={2}
          md={2}
          sm={2}
          lg={2}
          className="postAvatar no-padding"
        >
          <UserAvatar
            size="30"
            name={post.fullName}
            src={post.userPhoto}
          />
        </Col>
        <Col xs={8} md={8} sm={8} lg={8} className="no-padding">
          <span>{post.message}</span>
          <span className="postName">{post.fullName}</span>
        </Col>
        <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
          {/* <span className="postTime">{this.getTime(post)}</span> */}
        </Col>
      </Row>
    );
  }

  postOnChange(e) {
    this.setState({ postInput: e.target.value });
  }

  onKeyPress = (e) => {
    if (e.which == 13 && e.shiftKey) {
    } else if (e.which == 13) {
      e.preventDefault(); //Stops enter from creating a new line
      this._resetTextInput();
    }
  };

  getPosts(conversation) {
    if (conversation.length !== 0) {
      return (
        <div>
          {conversation.map((post, index) => (
            <div className="post">{this.showPost(post)}</div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="pushfifty">
          <center>
            <span>Get the conversation started!</span>
          </center>
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div className="full-height">
        <div className="postArea">
          {this.getPosts(this.state.conversation)}
        </div>

        <div className="postInputArea">
          <textarea
            type="text"
            onChange={this.postOnChange.bind(this)}
            className="full-width postInput"
            placeholder="Say something..."
            onKeyPress={this.onKeyPress}
          />
        </div>
        <div className="postActions">
          <span
            onClick={() => {
              this._resetTextInput();
            }}
            className="postSubmitBtn"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConversationModal);
