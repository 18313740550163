import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import { Col, Container, Row } from 'reactstrap';
import ConversationModal from '../components/ConversationModal';
import GuestListModal from '../components/GuestListModal';
import { updateLocations } from '../sagas/locationsSaga/reducer';
// import addbtn from "../images/addbtn.png";
import firebase from '../config/firebase';
// Reducers
import {
  editCe,
  editCeP,
  editMode,
  getCe,
  aveCe,
  saveCeP,
  updateCe,
} from '../sagas/customEventSaga/reducer';
import { getConnectionsTagsAndFavs } from '../sagas/connectionsTagsAndFavsSaga/reducer';
import {
  finishEvent,
  removeGuest,
} from '../sagas/userEventsSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

const conversationModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    height: '100%',
    width: '100%',
  },
};

const format = 'h:mm a';
const now = moment().hour(0).minute(0);

export class CustomPackageEditHome extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      copied: false,
      selectedDay: '',
      selectedTime: '',
      crop: {
        unit: '%',
        width: 30,
        aspect: 800 / 259,
      },
      cropThumb: {
        unit: '%',
        width: 30,
        aspect: 146 / 122,
      },
      cropping: false,
      croppingThumb: false,
      ideas: false,
      trevelAgent: true,
      calendarLock: false,
      showTrevelAgent: false,
      forceCalendar: false,
      showTopMenu: true,
    };
    this.handleDayClick = this.handleDayClick.bind(this);
    this.openConversationModal =
      this.openConversationModal.bind(this);
    this.closeConversationModal =
      this.closeConversationModal.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.confirmCopy = this.confirmCopy.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
  }

  componentDidMount() {
    const modeInt = parseInt(this.props.match.params.mode, 10);
    if (
      this.props.customEvent.event.eventId ===
      this.props.match.params.id
    ) {
      if (this.props.customEvent.hydrated === true) {
        if (
          this.props.customEvent.event.scheduledTime.timestamp !== ''
        ) {
          let selectedDay = moment(
            this.props.customEvent.event.scheduledTime.timestamp,
          );
          this.setState({
            selectedDay: selectedDay._d,
          });
        }

        if (
          this.props.customEvent.event.scheduledTime.timeString !== ''
        ) {
          let selectedTime = moment(
            this.props.customEvent.event.scheduledTime.time,
            [moment.ISO_8601, 'HH:mm'],
          );
          this.setState({
            selectedTime,
          });
        }

        let heroURL = '';
        if (
          this.props.customEvent.event.eventSettings.customHero ===
          false
        ) {
          heroURL =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            this.props.customEvent.event.heroImage +
            '?alt=media';
        } else {
          heroURL =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
            this.props.customEvent.event.heroImage +
            '?alt=media';
        }

        let thumbURL = '';
        if (
          this.props.customEvent.event.eventSettings.customThumb ===
          false
        ) {
          thumbURL =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            this.props.customEvent.event.thumbnail +
            '?alt=media';
        } else {
          thumbURL =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
            this.props.customEvent.event.thumbnail +
            '?alt=media';
        }

        let allTagsandFavs = [];

        let emptyBundle = {
          edit: true,
        };
        // Object.keys(this.props.customEvent.event.invitations).forEach(
        //   (key) => {
        //     let inviteId =
        //       this.props.customEvent.event.invitations[key].userId;
        //     let locations = this.props.locations;
        //     let bundle = {
        //       inviteId,
        //       locations,
        //       edit: true,
        //     };
        //     allTagsandFavs.push(bundle);
        //   },
        // );
        // if (allTagsandFavs.length === 0) {
        //   allTagsandFavs.push(emptyBundle);
        // }
        // if (
        //   Object.keys(this.props.customEvent.event.invitations)
        //     .length !== 0
        // ) {
        //   this.props.getConnectionsTagsAndFavs(allTagsandFavs);
        // }

        let customThumb = false;
        if (
          this.props.customEvent.event.eventSettings.customThumb !==
          undefined
        ) {
          customThumb =
            this.props.customEvent.event.eventSettings.customThumb;
        }
        let customHero = false;
        if (
          this.props.customEvent.event.eventSettings.customHero !==
          undefined
        ) {
          customHero =
            this.props.customEvent.event.eventSettings.customHero;
        }

        this.setState({
          loading: false,
          customHero,
          customThumb,
          customEvent: this.props.customEvent,
          title: this.props.customEvent.event.title,
          description: this.props.customEvent.event.description,
          heroURL,
          thumbURL,
          mode: modeInt,
        });
      } else {
        let eventReady = this.props.customEvent.event;
        eventReady.hydrated = true;
        this.props.getCe(eventReady);
      }
    } else {
      const eventDetails = query('eventId')
        .is(this.props.match.params.id)
        .on(this.props.events);
      const activeEvent = eventDetails[0];
      // const locations = this.getLocations(activeEvent);
      const locations = activeEvent.packageDetails.locations;
      let allTagsandFavs = [];
      let emptyBundle = {
        inviteId: '',
        locations: [],
        edit: true,
      };
      Object.keys(activeEvent.invitations).forEach((key) => {
        let inviteId = activeEvent.invitations[key].userId;
        let locations = this.props.locations;
        let bundle = {
          inviteId,
          locations,
          edit: true,
        };
        allTagsandFavs.push(bundle);
      });
      if (allTagsandFavs.length === 0) {
        allTagsandFavs.push(emptyBundle);
      }
      if (Object.keys(activeEvent.invitations).length !== 0) {
        this.props.getConnectionsTagsAndFavs(allTagsandFavs);
      }

      let customThumb = false;
      if (eventDetails[0].eventSettings.customThumb !== undefined) {
        customThumb = eventDetails[0].eventSettings.customThumb;
      }
      let customHero = false;
      if (eventDetails[0].eventSettings.customHero !== undefined) {
        customHero = eventDetails[0].eventSettings.customHero;
      }

      const customEvent = {
        urlMode: this.props.match.params.mode,
        clear: false,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: true,
        hydrated: true,
        event: {
          eventId: eventDetails[0].eventId,
          editing: eventDetails[0].editing,
          description: eventDetails[0].description,
          title: eventDetails[0].title,
          status: eventDetails[0].status,
          locations: locations,
          creationDetails: {
            plannerId: eventDetails[0].creationDetails.plannerId,
            plannerPhoto:
              eventDetails[0].creationDetails.plannerPhoto,
            plannerFirstName:
              eventDetails[0].creationDetails.plannerFirstName,
            plannerLastName:
              eventDetails[0].creationDetails.plannerLastName,
            plannerUserName:
              eventDetails[0].creationDetails.plannerUserName,
            creationDate:
              eventDetails[0].creationDetails.creationDate,
          },
          scheduledTime: eventDetails[0].scheduledTime,
          // usersAttending: eventDetails[0].usersAttending,
          eventSettings: {
            itinerarySent:
              eventDetails[0].eventSettings.itinerarySent,
            scheduled: eventDetails[0].eventSettings.scheduled,
            customHero,
            customThumb,
            eventApproved:
              eventDetails[0].eventSettings.eventApproved,
          },
          packageDetails: {
            packageId: eventDetails[0].packageDetails.packageId,
            locations: locations,
          },
          heroImage: eventDetails[0].heroImage,
          thumbnail: eventDetails[0].thumbnail,
          // planners: eventDetails[0].planners,
          users: eventDetails[0].users,
          editHistory: eventDetails[0].editHistory,
          invitations: eventDetails[0].invitations,
        },
      };
      this.props.getCe(customEvent);

      if (eventDetails[0].scheduledTime.timestamp !== '') {
        let selectedDay = moment(
          eventDetails[0].scheduledTime.timestamp,
        );

        this.setState({
          selectedDay: selectedDay._d,
        });
      }

      if (eventDetails[0].scheduledTime.timeString !== '') {
        let selectedTime = moment(
          eventDetails[0].scheduledTime.time,
          [moment.ISO_8601, 'HH:mm'],
        );
        this.setState({
          selectedTime,
        });
      }

      let heroURL = '';

      if (eventDetails[0].eventSettings.customHero === false) {
        heroURL =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
          eventDetails[0].heroImage +
          '?alt=media';
      } else {
        heroURL =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          eventDetails[0].heroImage +
          '?alt=media';
      }

      let thumbURL = '';

      if (eventDetails[0].eventSettings.customThumb === false) {
        thumbURL =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
          eventDetails[0].thumbnail +
          '?alt=media';
      } else {
        thumbURL =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          eventDetails[0].thumbnail +
          '?alt=media';
      }

      this.setState({
        loading: false,
        customEvent: customEvent,
        title: customEvent.event.title,
        description: customEvent.event.description,
        heroURL,
        thumbURL,
        mode: modeInt,
      });
    }
  }

  confirmDelete(userId, fullName, status) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h4
              style={{
                fontSize: '20px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Remove Guest
            </h4>

            <div
              style={{ textAlign: 'center', marginBottom: '10px' }}
            >
              <p>Are you sure you want to remove {fullName}?</p>
              {status === 1 && (
                <p>
                  It looks like they already approved this Experience.
                </p>
              )}
            </div>

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <div>
                  <button
                    onClick={() => {
                      this.removeGuest(
                        this.props.profile,
                        userId,
                        onClose,
                      );
                    }}
                    className="redbutton right"
                  >
                    Yes
                  </button>
                </div>
              </Col>
              <Col xs={6} md={6} sm={6} lg={6}>
                <div>
                  <button
                    onClick={onClose}
                    className="redbutton left"
                  >
                    Nevermind
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        );
      },
    });
  }

  removeGuest(profile, guestId, onClose) {
    // This users info
    const fullName = profile.firstName + ' ' + profile.lastName;
    let userId = profile.userId;
    // The event they are canceling
    let customEvent = this.props.customEvent;

    // Remove Guests Access
    customEvent.event.users[guestId].attending = false;
    customEvent.event.users[guestId].editor = false;
    customEvent.event.users[guestId].access = false;

    // Check how many guests are finished.
    let approvedArray = [];
    let usersArray = [];
    Object.keys(customEvent.event.users).forEach((key) => {
      if (customEvent.event.users[key].access === true) {
        usersArray.push(customEvent.event.users[key]);
      }
      if (customEvent.event.users[key].approved === true) {
        approvedArray.push(customEvent.event.users[key]);
      }
    });

    // Is Everyone Finshed?
    if (approvedArray.length === usersArray.length) {
      customEvent.event.eventSettings.eventApproved = true;
      customEvent.event.status = 0;
    }

    // Update History
    const updatedHistory = customEvent.event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 15,
      fullName: fullName,
      userId: userId,
    };
    updatedHistory.push(newHistory);
    customEvent.event.editHistory = updatedHistory;
    onClose();
    this.props.getCe(customEvent);
    this.tabBounce();
  }

  tabBounce() {
    this.setState({
      mode: 1,
      removing: true,
    });
    setTimeout(() => this.tabRebounce(), 10);
  }

  tabRebounce() {
    this.setState({
      mode: 3,
      removing: false,
    });
  }

  // Crop Functions
  // Banner Crop
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      cropping: true,
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'heroImage.jpeg',
      );
      this.setState({ heroURL: croppedImageUrl, customHero: true });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getBannerCropper(bannerCropping) {
    const { crop, croppedImageUrl, src } = this.state;
    if (bannerCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropper();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropper();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </div>
        </div>
      );
    }
  }
  // End Banner Crop
  // Thumb Crop
  onSelectFileThumb = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      croppingThumb: true,
    });
  };

  onImageLoadedThumb = (image) => {
    this.imageRef = image;
  };

  onCropCompleteThumb = (crop) => {
    this.makeClientCropThumb(crop);
  };

  onCropChangeThumb = (cropThumb, percentCrop) => {
    this.setState({ cropThumb });
  };

  async makeClientCropThumb(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImgThumb(
        this.imageRef,
        crop,
        'thumbnail.jpeg',
      );
      this.setState({ thumbURL: croppedImageUrl, customThumb: true });
    }
  }

  getCroppedImgThumb(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getThumbnailCropper(thumbnailCropping) {
    const { cropThumb, croppedImageUrl, src } = this.state;
    if (thumbnailCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropperThumb();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropperThumb();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={cropThumb}
                onImageLoaded={this.onImageLoadedThumb}
                onComplete={this.onCropCompleteThumb}
                onChange={this.onCropChangeThumb}
              />
            )}
          </div>
        </div>
      );
    }
  }
  //End Thumb Crop
  cancelCropper() {
    this.setState({
      cropping: false,
    });
  }

  saveCropper() {
    this.uploadImage(this.state.blob);
    this.setState({
      cropping: false,
    });
  }

  cancelCropperThumb() {
    this.setState({
      croppingThumb: false,
    });
  }

  saveCropperThumb() {
    this.uploadImage(this.state.blob);
    this.setState({
      croppingThumb: false,
    });
  }

  uploadImage = (image) => {
    const uploadUrl =
      'event_images/' +
      this.state.customEvent.event.eventId +
      '/' +
      image.name;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadUrl);
    imageRef.put(image).then((snapshot) => {
      // const path = snapshot.ref.location.path_;
    });
  };

  // Conversation Functions
  openConversationModal() {
    this.setState({ conversationModalVisible: true });
  }

  closeConversationModal() {
    this.setState({ conversationModalVisible: false });
  }

  // Schedule Functions
  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  }

  onTimeChange = (time) => {
    this.setState({ selectedTime: time });
  };

  quickTitle(value) {
    this.setState({ title: value });
  }

  displayForm() {
    return (
      <div id="eventDetailsForm">
        <div>
          <div>
            <Container>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12} className="left">
                  <span className="inputLabel">Title</span>

                  <input
                    type="text"
                    onChange={this.titleOnChange.bind(this)}
                    value={this.state.title}
                    className="full-width"
                    style={{ padding: '10px 5px 10px 5px' }}
                  />

                  <div>
                    <div className="quickText">
                      <span
                        onClick={() => {
                          this.quickTitle('Dinner');
                        }}
                      >
                        Dinner
                      </span>
                    </div>
                    <div className="quickText">
                      <span
                        onClick={() => {
                          this.quickTitle('Drinks');
                        }}
                      >
                        Drinks
                      </span>
                    </div>
                    <div className="quickText">
                      <span
                        onClick={() => {
                          this.quickTitle('Date Night');
                        }}
                      >
                        Date Night
                      </span>
                    </div>
                    <div className="quickText">
                      <span
                        onClick={() => {
                          this.quickTitle('Debauchery');
                        }}
                      >
                        Debauchery
                      </span>
                    </div>
                  </div>
                  <span className="inputLabel">
                    Description / Message
                  </span>
                  <textarea
                    type="text"
                    onChange={this.descriptionOnChange.bind(this)}
                    value={this.state.description}
                    className="full-width"
                    style={{ minHeight: '100px' }}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  getLocations(activeEvent) {
    const packageDetails = query('packageId')
      .is(activeEvent.packageDetails.packageId)
      .on(this.props.packages);
    return packageDetails[0].locations;
  }

  getImage(type, locationStart) {
    let location = '';

    if (locationStart.locationId !== null) {
      let locations = this.props.locations;
      const locationDetails = query('locationId')
        .is(locationStart.locationId)
        .on(locations);
      location = locationDetails[0];
    } else {
      location = locationStart;
    }

    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (location.locationId === null) {
          path = location.heroThumb;
        } else {
          if (
            location !== undefined &&
            location.locationHero !== undefined
          ) {
            path =
              'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
              location.locationHero +
              '?alt=media';
          }
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (location.locationId === null) {
          path = location.locationThumb;
        } else {
          if (
            location !== undefined &&
            location.locationThumb !== undefined
          ) {
            path =
              'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
              location.locationThumb +
              '?alt=media';
          }
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getLocationDetails(location, locations, index, editMode) {
    let locationReady = [];
    if (location.locationId !== null) {
      const locationDetails = query('locationId')
        .is(location.locationId)
        .on(locations);
      locationReady = locationDetails[0];
    } else {
      locationReady = location;
    }
    // MIGHT NEED THIS?

    // let favArray = [];
    // favArray.push(location.locationId);
    // let destination = 2;
    // const bundle = { favArray, locations, destination };
    // this.props.updateLocations(bundle);

    const currentIndex = index;

    if (locationReady !== undefined) {
      return (
        <div>
          <Row className="oddeven">
            <Col
              xs={10}
              md={10}
              sm={10}
              lg={10}
              className="no-padding"
            >
              <Link
                to={{
                  pathname:
                    locationReady.locationId !== null &&
                    '/location/' + locationReady.locationId,
                  state: {
                    location: { locationReady },
                    type: 7,
                    eventId: this.props.customEvent.event.eventId,
                  },
                }}
              >
                <div className="full-height left">
                  {this.getImage('thumb', location)}
                </div>
                <span className="location-title">
                  {locationReady.locationTitle}
                </span>
                <span className="address">
                  {locationReady.address.street}
                </span>
                <span className="address">
                  {locationReady.address.city}{' '}
                  {locationReady.address.state},{' '}
                  {locationReady.address.zip}
                </span>
              </Link>
            </Col>
            <Col
              xs={2}
              md={2}
              sm={2}
              lg={2}
              key={'key' + index}
              className="no-padding"
            >
              <div className="right text-right">
                {this.getDeleteButton(index, this.props.customEvent)}
              </div>
              {this.getEditButton(editMode, currentIndex)}
              <div
                style={{
                  clear: 'both',
                  right: '0px',
                  bottom: '0px',
                  position: 'absolute',
                }}
              >
                {this.getMoveButton(index, this.props.customEvent)}
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }

  getMoveButton(index, customEvent) {
    const locations = customEvent.event.locations;
    if (locations.length === 1) {
      return (
        <div>
          <MaterialIcon
            icon="arrow_downward"
            size={20}
            color="#d0d0d0"
          />
        </div>
      );
    } else {
      if (index === 0) {
        return (
          <MaterialIcon
            icon="arrow_downward"
            size={20}
            color="#f21d59"
            onClick={() => {
              this.changeOrder(customEvent, index, 'down');
            }}
          />
        );
      } else if (index > 0 && index !== locations.length - 1) {
        return (
          <div>
            <MaterialIcon
              icon="arrow_upward"
              size={20}
              color="#f21d59"
              onClick={() => {
                this.changeOrder(customEvent, index, 'up');
              }}
            />
            <MaterialIcon
              icon="arrow_downward"
              size={20}
              color="#f21d59"
              onClick={() => {
                this.changeOrder(customEvent, index, 'down');
              }}
            />
          </div>
        );
      } else {
        return (
          <MaterialIcon
            icon="arrow_upward"
            size={20}
            color="#f21d59"
            onClick={() => {
              this.changeOrder(customEvent, index, 'up');
            }}
          />
        );
      }
    }
  }

  changeOrder(customEvent, from, direction) {
    let to = 0;
    if (direction === 'up') {
      to = from - 1;
    }
    if (direction === 'down') {
      to = from + 1;
    }
    const locations = customEvent.event.locations;
    var f = locations.splice(from, 1)[0];
    locations.splice(to, 0, f);
    customEvent.locations = locations;
    customEvent.step = 6;
    this.props.getCe(customEvent);
  }

  getEditButton(editMode, index) {
    const currentIndex = index;
    if (editMode === true) {
      return (
        <div>
          <Link
            to={{
              pathname: '/event/create/add/',
              state: {
                index: currentIndex,
                editItinerary: true,
              },
            }}
            className="right"
          >
            <MaterialIcon icon="edit" size={20} color="#f21d59" />
          </Link>
        </div>
      );
    }
  }

  getDeleteButton(index, customEvent) {
    const locations = customEvent.event.locations;
    if (locations.length === 1) {
      return (
        <div>
          <MaterialIcon icon="delete" size={20} color="#d0d0d0" />
        </div>
      );
    } else {
      return (
        <MaterialIcon
          icon="delete"
          size={20}
          color="#f21d59"
          onClick={() => {
            this.removeLocation(index, customEvent);
          }}
        />
      );
    }
  }

  removeLocation(i, customEvent) {
    const locations = customEvent.event.locations;
    if (locations.length === 1) {
    } else {
      const step = customEvent.step - 1;
      locations.splice(i, 1);
      customEvent.event.locations = locations;
      this.props.getCe(customEvent);
    }
  }

  getFooter(mode) {
    return (
      <Container>
        <Row className="no-margin">
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <div>
              <Link
                to={{
                  pathname: '/events/upcoming',
                }}
              >
                <span className="footer-link left">Cancel</span>
              </Link>
            </div>
          </Col>
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <span
              className="right footer-link"
              onClick={() => {
                this.createCustomEvent(
                  this.props.profile,
                  this.props.customEvent,
                  this.props.locations,
                  this.props.packages,
                );
              }}
            >
              Save
            </span>
          </Col>
        </Row>
      </Container>
    );
  }

  updateAndAdd(customEvent) {
    let updatedCustomEvent = customEvent;
    updatedCustomEvent.event.title = this.state.title;
    updatedCustomEvent.event.description = this.state.description;
    this.props.updateCe(updatedCustomEvent);
  }

  saveEdit(customEvent, values) {
    const updatedCustomEvent = customEvent;
    updatedCustomEvent.event.title = values.title;
    updatedCustomEvent.event.description = values.description;
  }

  uploadLocation(location, id) {
    const newLocation = Object.assign({}, location, {
      locationId: id,
      custom: true,
    });
    const db = firebase.firestore();
    const setDoc = db
      .collection('locations')
      .doc(id)
      .set(newLocation)
      .then((ref) => {
        console.log(ref);
      });
  }

  confirmCopy() {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);
  }

  titleOnChange(e) {
    this.setState({ title: e.target.value });
  }

  descriptionOnChange(e) {
    this.setState({ description: e.target.value });
  }

  createCustomEvent(profile, customEvent, locations, packages) {
    let matchStatus = false;
    const timestamp = new Date();
    // Get New Event ID to use later
    const db = firebase.firestore();
    const newEventRef = db.collection('packages').doc();
    const dbref = newEventRef.id;
    // Create Variables
    let locationThumb = null;
    let locationHero = null;
    const locationArray = [];
    // Create Custom Title
    const customTitle = `${profile.firstName}'s Event`;
    // Create Locatins Array and Default Hero/Thumbnail
    const eventLocations = customEvent.event.locations;
    // Check if there are any new locations in the event and if so add to DB
    let zone = null;
    eventLocations.forEach((location, i) => {
      // if (location.type === 1) {
      //   const db = firebase.firestore();
      //   const newLocationRef = db.collection('locations').doc();
      //   const dbref = newLocationRef.id;
      //   locationArray[i] = {
      //     locationId: dbref,
      //     locationTitle: location.location.locationTitle,
      //     locationThumb: location.location.locationThumb,
      //     locationHero: location.location.locationHero,
      //     locationDescription: location.location.locationDescription,
      //     address: location.location.address,
      //     locationType: 1,
      //   };
      //   this.uploadLocation(location.location, dbref);
      // } else {

      let locationReady = {};
      if (location.locationId !== null) {
        const locationDetails = query('locationId')
          .is(location.locationId)
          .on(locations);
        locationReady = locationDetails[0];
      } else {
        locationReady = location;
        (locationReady.locationDescription = ''),
          (locationReady.locationHero =
            this.props.customEvent.event.heroImage);
      }

      locationArray[i] = {
        locationId: locationReady.locationId,
        locationTitle: locationReady.locationTitle,
        address: locationReady.address,
        locationThumb: locationReady.locationThumb,
        locationHero: locationReady.locationHero,
        locationDescription: locationReady.locationDescription,
        locationType: 0,
        settings: {
          customImage: true,
        },
      };
      zone = locationReady.zoneId;
      // Set the default thumbnails
      if (i === 0) {
        locationThumb = locationReady.locationThumb;
        if (locationReady.locationHero !== undefined) {
          locationHero = locationReady.locationHero;
        } else {
          locationHero = this.props.customEvent.event.heroImage;
        }
      }
      // }
    });

    // Create Package
    const userPackage = {
      created: timestamp,
      title: customTitle,
      id: dbref,
      category: {
        Custom: true,
      },
      locations: locationArray,
      description: '',
      heroImage: locationHero,
      thumbnail: locationThumb,
      zoneId: zone,
      creatorInfo: {
        creatorImage: profile.profilePhotoPath,
        creatorName: `${profile.firstName} ${profile.lastName}`,
        creatorId: profile.userId,
      },
    };
    // CREATE PURCHASE RECORD
    const userEvent = {};
    const { selectedVouchers } = this.props;
    const newVouchersObject = {};
    const voucherObject = {};
    const { userId } = this.props.profile;

    // SET PURCHASE DETAILS
    userEvent.creationDetails = {
      plannerId: profile.userId,
      plannerPhoto: profile.profilePhotoPath,
      plannerFirstName: profile.firstName,
      plannerLastName: profile.lastName,
      plannerUserName: profile.userName,
      creationDate: timestamp,
    };
    // SET PACKAGE DETAILS
    userEvent.packageDetails = {
      packageId: dbref,
    };
    // SET EVENT IMAGES
    userEvent.heroImage = locationHero;
    userEvent.thumbnail = locationThumb;
    // Check for Matching Package
    const locationIdArray = [];
    locationArray.forEach((location) => {
      locationIdArray.push(location.locationId);
    });
    packages.some((thispackage) => {
      const tempArray = [];
      const { locations } = thispackage;
      locations.forEach((location) => {
        tempArray.push(location.locationId);
      });
      const status = _.isEqual(tempArray, locationIdArray);
      if (status === true) {
        // SET PACKAGE DETAILS
        userEvent.packageDetails = {
          packageId: thispackage.packageId,
        };
        matchStatus = true;
        return true;
      }
    });
    // SET FAKE SCHEDULE
    userEvent.scheduledTime = {
      date: '',
      timestamp: '',
      timeString: '',
    };
    // SET CREATED DATE
    userEvent.created = timestamp;
    // SET TITLE
    userEvent.title = customTitle;
    // SET DESCRIPTIONS
    userEvent.description = '';
    // // SET PACKAGE LOCATIONS AND VOUCHERS
    // const activePackageLocations = customEvent.event.locations;
    // activePackageLocations.forEach((purchaseLocation, i) => {
    //   const { locationId } = purchaseLocation;
    //   // TEMPORARY!!!
    //   locations.forEach((location, iii) => {
    //     let voucherCheck = false;
    //     if (location.locationId === locationId) {
    //       if (location.vouchers) {
    //         location.vouchers.forEach(voucher => {
    //           const { voucherId } = voucher;
    //           selectedVouchers.forEach((selectedVoucher, ii) => {
    //             if (selectedVoucher === voucherId) {
    //               voucherCheck = true;
    //               voucherObject[ii] = {
    //                 // voucherId: ,
    //                 voucherMasterId: voucher.voucherId,
    //                 voucherType: voucher.voucherType,
    //                 voucherPromo: voucher.voucherPromo,
    //                 voucherCost: voucher.voucherCost,
    //                 voucherStatus: true,
    //                 voucherCreationDate: timestamp,
    //                 voucherExpirationDate: "January 1, 2019",
    //                 voucherInfo: voucher.voucherInfo,
    //                 userId,
    //                 locationId: purchaseLocation.locationId,
    //                 packageId: dbref
    //               };
    //             }
    //           });
    //         });
    //         if (voucherCheck === true) {
    //           newVouchersObject[iii] = {
    //             vouchers: voucherObject
    //           };
    //         }
    //       }
    //     }
    //   });
    // });
    // SET PACKAGE SETTINGS
    userEvent.eventSettings = {
      scheduled: false,
      itinerarySent: false,
    };

    // SET USERS
    const fullName = profile.firstName + ' ' + profile.lastName;
    const usersObject = this.props.customEvent.event.users;
    const newUsersObject = {};

    Object.keys(usersObject).forEach((key) => {
      let approvedStatus = false;
      let attendingStatus = false;
      if (this.props.customEvent.event.status === 0) {
        approvedStatus = usersObject[key].approved;
        attendingStatus = usersObject[key].attending;
      }

      if (key === userId) {
        let newUserStatus = false;
        if (usersObject[key].newUser !== undefined) {
          newUserStatus = usersObject[key].newUser;
        }
        newUsersObject[userId] = {
          access: true,
          editor: true,
          approved: true,
          attending: true,
          inviteAction: true,
          newUser: newUserStatus,
          profile: {
            profilePhotoPath: profile.profilePhotoPath,
            fullName: fullName,
            userName: profile.userName,
          },
        };
      } else {
        let newUserStatus = false;
        if (usersObject[key].newUser !== undefined) {
          newUserStatus = usersObject[key].newUser;
        }

        let inviteAction = true;
        if (usersObject[key].inviteAction !== undefined) {
          inviteAction = usersObject[key].inviteAction;
        }

        newUsersObject[key] = {
          access: usersObject[key].access,
          editor: usersObject[key].editor,
          inviteAction: inviteAction,
          approved: approvedStatus,
          attending: attendingStatus,
          newUser: newUserStatus,
          profile: {
            profilePhotoPath:
              usersObject[key].profile.profilePhotoPath,
            fullName: usersObject[key].profile.fullName,
            userName: usersObject[key].profile.userName,
          },
        };
      }
    });

    userEvent.users = usersObject;

    userEvent.status = 0;

    // Create Final Event Record
    const thisEvent = this.props.customEvent.event;
    //Update Event Record
    thisEvent.packageDetails.locations = locationArray;
    thisEvent.packageDetails.packageId =
      userEvent.packageDetails.packageId;
    thisEvent.title = this.state.title;
    thisEvent.description = this.state.description;

    let heroPath = '';
    let thumbnailPath = '';

    if (this.state.customHero === true) {
      heroPath =
        'event_images%2F' +
        this.props.customEvent.event.eventId +
        '%2FheroImage.jpeg';
      thisEvent.eventSettings.customHero = true;
    } else {
      heroPath = thisEvent.heroImage;
    }

    if (this.state.customThumb === true) {
      thumbnailPath =
        'event_images%2F' +
        this.props.customEvent.event.eventId +
        '%2Fthumbnail.jpeg';
      thisEvent.eventSettings.customThumb = true;
    } else {
      thumbnailPath = thisEvent.thumbnail;
    }

    thisEvent.heroImage = heroPath;
    thisEvent.thumbnail = thumbnailPath;
    thisEvent.scheduledTime = this.setSchedule(
      this.state.selectedDay,
      this.state.selectedTime,
    );
    thisEvent.users = newUsersObject;

    //Turn Off Edit Mode
    thisEvent.editing = {
      editing: false,
      editorId: null,
    };

    // Update History
    const updatedHistory = thisEvent.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 1,
      fullName: fullName,
      userId: userId,
    };
    updatedHistory.push(newHistory);
    thisEvent.editHistory = updatedHistory;
    this.saveEvent(
      thisEvent,
      this.props.profile,
      userPackage,
      matchStatus,
    );
  }

  saveEvent(event, profile, userPackage, status) {
    const eventBundle = {
      profile: profile,
      event: event,
      package: userPackage,
      // userVouchers: this.state.userVouchers
    };
    if (status === true) {
      this.props.editCe(eventBundle);
    } else {
      this.props.editCeP(eventBundle);
    }
  }

  setSchedule(selectedDay, selectedTime) {
    let bundle = {
      date: '',
      time: '',
      timeString: '',
      timestamp: '',
    };
    // Check if Time Set
    if (
      selectedTime !== '' &&
      selectedTime !== undefined &&
      selectedTime !== null
    ) {
      const datestring = String(selectedTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.time = timeValue;
      bundle.timeString = selectedTime._d;
    }
    // Check for Date
    if (
      selectedDay !== '' &&
      selectedDay !== undefined &&
      selectedDay !== null
    ) {
      const timestamp = moment(selectedDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.date = dateString;
      bundle.timestamp = timestampReady;
    }

    return bundle;
  }

  getPlanners(planners, profile) {
    const userProfile = profile;
    const plannerArray = [];
    Object.keys(planners).forEach((key) => {
      if (key !== userProfile.userId) {
        plannerArray.push({
          id: [key],
          fullName: planners[key].profile.fullName,
          profilePhotoPath: planners[key].profile.profilePhotoPath,
          approved: planners[key].approved,
        });
      }
    });

    if (plannerArray.length > 0 && plannerArray.length < 2) {
      return (
        <div>
          {plannerArray.map((planner, index) => (
            <div key={'key' + index} className="planners section">
              <Container>
                {/* <a
                  className="action-icon right"
                  onClick={() => {
                    this.openConversationModal();
                  }}
                >
                  <MaterialIcon icon="sms" size={15} color="white" />
                </a> */}
                {/* <Row>
                  <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
                    <span>You are planning this event with:</span>
                  </Col>
                </Row> */}
                <Row className="plannerName">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div>
                      <UserAvatar
                        size="30"
                        name={planner.fullName}
                        src={planner.profilePhotoPath}
                      />
                      <span className="left">{planner.fullName}</span>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div>
          <div className="planners section">
            <Container>
              {/* <a
                className="action-icon right"
                onClick={() => {
                  this.openConversationModal();
                }}
              >
                <MaterialIcon icon="sms" size={15} color="white" />
              </a> */}
              {/* <Row>
                  <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
                    <span>You are planning this event with:</span>
                  </Col>
                </Row> */}
              <Row className="plannerName">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  <div>
                    <span>
                      <b>{plannerArray.length}</b> Guests
                    </span>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }

  getLastEdit(editHistory, profile) {
    const fullName = profile.firstName + ' ' + profile.lastName;
    if (editHistory !== undefined) {
      return (
        <div id="lastEdit">
          <p>
            Last Edited by{' '}
            {editHistory[editHistory.length - 1].fullName}
          </p>
        </div>
      );
    } else {
      return (
        <div id="lastEdit">
          <p>Last Edited by {fullName}</p>
        </div>
      );
    }
  }

  //Tabs
  getDetailsTab(users, userId, locations) {
    const usersArray = [];
    if (users !== undefined) {
      Object.keys(users).forEach((key) => {
        if (key !== userId) {
          usersArray.push({
            id: key,
            fullName: users[key].fullName,
            profilePhotoPath: users[key].profilePhotoPath,
            approved: users[key].approved,
          });
        }
      });
    }
    if (usersArray.length === 0) {
      return (
        <div style={{ maxWidth: '1000px', margin: '0px auto' }}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <span className="inputLabel">Itinerary</span>
              <div>
                {this.state.customEvent.event.locations.map(
                  (location, index) => (
                    <div key={'key' + index}>
                      {this.getLocationDetails(
                        location,
                        locations,
                        index,
                        true,
                      )}
                    </div>
                  ),
                )}
              </div>
              <div>
                <a
                  onClick={() => {
                    this.updateAndAdd(this.props.customEvent);
                  }}
                >
                  <span className="left addLocationButton">
                    <MaterialIcon
                      icon="add"
                      size={35}
                      color="#ffffff"
                    />
                  </span>
                  {/* <img src={addbtn} className="left addLocationButton" /> */}
                </a>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="no-padding">
              <div>{this.displayForm()}</div>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        // <Row>
        /* <Col xs={12} sm={12} md={6} lg={6}>
            {this.getPlanners(
              this.state.customEvent.event.users,
              this.props.profile
            )}
            <ConversationModal
              eventId={this.props.match.params.id}
              className="conversationModal"
            />
          </Col> */
        /* <Col xs={12} sm={12} md={6} lg={6}>
            <h5>Current Proposal</h5>
            {this.getLastEdit(
              this.state.customEvent.event.editHistory,
              this.props.profile
            )}

            <span className="inputLabel">Itinerary</span>
            <div>
              {this.state.customEvent.event.locations.map((location, index) => (
                <div>
                  {this.getLocationDetails(
                    location,
                    this.props.locations,
                    index,
                    true
                  )}
                </div>
              ))}
            </div>
            <div>
              <a
                onClick={() => {
                  this.updateAndAdd(this.props.customEvent);
                }}
              >
                <img src={addbtn} className="left" />
              </a>
            </div>
            <div>{this.displayForm()}</div>
          </Col>
        </Row> */
        <div style={{ maxWidth: '1000px', margin: '0px auto' }}>
          {/* <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="no-padding"
            >
              {this.getPlanners(
                this.state.customEvent.event.users,
                this.props.profile,
              )}
            </Col>
          </Row> */}
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              {/* <h5>Current Proposal</h5> */}
              {/* {this.getLastEdit(
                this.state.customEvent.event.editHistory,
                this.props.profile
              )} */}
              <span className="inputLabel">Itinerary</span>
              <div>
                {this.state.customEvent.event.locations.map(
                  (location, index) => (
                    <div key={'key' + index}>
                      {this.getLocationDetails(
                        location,
                        this.props.locations,
                        index,
                        true,
                      )}
                    </div>
                  ),
                )}
              </div>
              <div>
                <a
                  onClick={() => {
                    this.updateAndAdd(this.props.customEvent);
                  }}
                >
                  <span className="left addLocationButton">
                    <MaterialIcon
                      icon="add"
                      size={35}
                      color="#ffffff"
                    />
                  </span>
                  {/* <img src={addbtn} className="left addLocationButton" /> */}
                </a>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="no-padding">
              <div>{this.displayForm()}</div>
            </Col>
          </Row>
        </div>
      );
    }
  }

  getGuestListTab(customEvent) {
    return (
      <div style={{ margin: '0px auto' }}>
        <GuestListModal
          event={customEvent.event}
          confirmDelete={this.confirmDelete}
          confirmCopy={this.confirmCopy}
          // closeGuestListModal={this.closeGuestListModal}
        />
      </div>
    );
  }

  // getImagesTab() {
  //   return (
  //     <Container id="imageUpload">
  //       <Row>
  //         <Col lg={12} md={6} sm={12} xs={12} className="left">
  //           <span className="inputLabel">Banner Image</span>
  //           <img
  //             alt="Crop"
  //             style={{ maxWidth: '100%' }}
  //             src={this.state.heroURL}
  //           />
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col lg={12} md={6} sm={12} xs={12} className="left">
  //           <div>
  //             <input type="file" onChange={this.onSelectFile} />
  //           </div>
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col lg={12} md={6} sm={12} xs={12} className="left">
  //           <span className="inputLabel">Thumbnail Image</span>
  //           <img
  //             alt="Crop"
  //             style={{ maxWidth: '100%' }}
  //             src={this.state.thumbURL}
  //           />
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col lg={12} md={6} sm={12} xs={12} className="left">
  //           <div>
  //             <input type="file" onChange={this.onSelectFileThumb} />
  //           </div>
  //         </Col>
  //       </Row>
  //     </Container>
  //   );
  // }

  isDayDisabled = (day) => {
    // Remember, in this scenario - TRUE means the day is disabled. FALSE means it is not disabled.
    let locationClosed = false;
    let eventNotAvailable = false;
    let noItineraryAvailable = null;
    let locations = this.props.customEvent.event.locations;
    const today = moment().format('YYYY-MM-DD');
    const timestamp = moment(day).unix();
    const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
    let dayName = moment(day).format('dddd');
    let lowercase = dayName.toLowerCase();
    if (lowercase === 'saturday') {
      lowercase = 'saterday';
    }
    let openString = lowercase + 'Open';
    // Is this day in the past?
    let pastDate = moment(dateString).isBefore(today);
    // First we check if date is already in the past
    if (pastDate === false) {
      // check for events with multiple days and build out the multiDates Array
      if (locations.length !== 0) {
        locations.forEach((location) => {
          // If this is an event lets get to it.
          if (
            location.eventId !== undefined &&
            location.eventId !== ''
          ) {
            //This is an Event
            let multiDates = [];
            let match = false;
            let eventDetails = query('eventId')
              .is(location.eventId)
              .on(this.props.timedEvents);

            if (
              eventDetails[0].scheduledTime.dates !== undefined &&
              eventDetails[0].scheduledTime.dates.multiDates !==
                undefined
            ) {
              eventDetails[0].scheduledTime.dates.multiDates.forEach(
                (date) => {
                  multiDates.push(
                    moment(date.date).format('YYYY-MM-DD'),
                  );
                },
              );
            }
            if (multiDates.length !== 0) {
              multiDates.forEach((date) => {
                let check = moment(dateString).isSame(date);
                if (check === true) {
                  match = true;
                }
              });
            }
            if (match === false) {
              eventNotAvailable = true;
            }
          } else {
            if (
              location !== undefined &&
              location.hours !== undefined &&
              location.hours[lowercase][openString] !== undefined &&
              location.hours[lowercase][openString] === true
            ) {
              locationClosed = true;
            } else if (location.hours === undefined) {
              let locationDetails = query('locationId')
                .is(location.locationId)
                .on(this.props.locations);

              if (
                locationDetails[0] !== undefined &&
                locationDetails[0].hours !== undefined &&
                locationDetails[0].hours[lowercase][openString] !==
                  undefined &&
                locationDetails[0].hours[lowercase][openString] ===
                  true
              ) {
                locationClosed = true;
              }
            }
          }
        });
      } else {
        let check = moment(dateString).isSame(today);
        let past = moment(dateString).isBefore(today);
        if (past === false || check === true) {
          noItineraryAvailable = true;
        } else {
          noItineraryAvailable = false;
        }
      }
    }
    if (
      locationClosed === true ||
      eventNotAvailable === true ||
      pastDate === true ||
      noItineraryAvailable === false
    ) {
      return true;
    } else {
      return false;
    }
  };

  getWhen(event) {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 'tu' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];

    let days = [];

    if (event.scheduledTime.dates.repeatDays !== undefined) {
      event.scheduledTime.dates.repeatDays.forEach((day) => {
        let eventDetailsx = query('short').is(day).on(dayArray);
        let eventx = eventDetailsx[0];
        days.push(eventx.full);
      });
      let dayText = '';
      days.forEach((day, index) => {
        if (days.length === 1) {
          dayText = day;
        } else if (days.length === 7) {
          dayText = 'Day';
        } else if (days.length === 2) {
          if (index === 0) {
            dayText = day;
          } else {
            dayText = dayText + ' & ' + day;
          }
        } else if (
          days.length > 2 &&
          event.scheduledTime.dates.timesSelected > 1
        ) {
          if (index === 0) {
            dayText = day + 's';
          } else if (days.length - 1 === index) {
            dayText = dayText + ', & ' + day + 's';
          } else {
            dayText = dayText + ',  ' + day + 's';
          }
        } else if (
          days.length > 2 &&
          event.scheduledTime.dates.timesSelected === 1
        ) {
          if (index === 0) {
            dayText = day;
          } else if (days.length - 1 === index) {
            dayText = dayText + ', & ' + day;
          } else {
            dayText = dayText + ',  ' + day;
          }
        }
      });

      let weekly = '';
      if (event.scheduledTime.dates.timesSelected === 1) {
        weekly = 'week';
      } else {
        weekly = event.scheduledTime.dates.timesSelected + ' weeks ';
      }
      let statement = '';
      if (event.scheduledTime.dates.timesSelected === 1) {
        if (event.scheduledTime.dates.repeatType === 'Weekly') {
          statement = 'every ' + dayText;
        }
      } else {
        if (event.scheduledTime.dates.repeatType === 'Weekly') {
          statement = 'every ' + weekly + ' on ' + dayText;
        }
      }

      return statement;
    } else {
      let statement = '';
      event.scheduledTime.dates.multiDates.forEach((date, index) => {
        if (index === 0) {
          let currentTime = moment(date.date).format('MMMM D');
          statement = statement + currentTime + ' ';
        } else if (
          (index = event.scheduledTime.dates.multiDates.length - 1)
        ) {
          let currentTime = moment(date.date).format('D');
          statement = statement + '& ' + currentTime;
        } else {
          let currentTime = moment(date.date).format('D');
          statement = statement + ',' + currentTime;
        }
      });
      return statement;
    }
  }

  getScheduleTab() {
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
      },
    };

    let eventInfo = { eventTitle: '' };
    let multiDates = [];

    // check for events with multiple days
    if (this.props.customEvent.event.locations.length !== 0) {
      this.props.customEvent.event.locations.forEach((location) => {
        if (
          location.eventId !== undefined &&
          location.eventId !== ''
        ) {
          let eventDetails = query('eventId')
            .is(location.eventId)
            .on(this.props.timedEvents);

          eventDetails[0].scheduledTime.dates.multiDates.forEach(
            (date) => {
              multiDates.push(moment(date.date).format('YYYY-MM-DD'));
            },
          );
          eventInfo = eventDetails[0];
        }
      });
    }
    return (
      <Container>
        {multiDates.length > 1 && (
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div style={{ padding: '15px', marginTop: '10px' }}>
                <center>
                  <span>
                    <span style={{ fontWeight: '600' }}>
                      {eventInfo.eventTitle} is{' '}
                      {this.getWhen(eventInfo)}
                    </span>
                  </span>
                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginTop: '15px',
                      marginBottom: '10px',
                      fontWeight: '500',
                      fontSize: '18px',
                      color: '#ed0259',
                    }}
                  >
                    When would you like to go?
                  </span>
                </center>
              </div>
            </Col>
          </Row>
        )}

        <Row className="no-margin">
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            {this.state.calendarLock === false ? (
              <div>
                <div className="text-center">
                  {multiDates.length > 1 ? (
                    <DayPicker
                      // month={new Date(2015, 8)}
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiersStyles={modifiersStyles}
                      disabledDays={this.isDayDisabled}
                    />
                  ) : (
                    <DayPicker
                      // month={new Date(2015, 8)}
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiersStyles={modifiersStyles}
                      disabledDays={this.isDayDisabled}
                    />
                  )}

                  {/* <center>
                    <p style={{ marginTop: '20px' }}>
                      {this.state.selectedDay
                        ? this.state.selectedDay.toLocaleDateString()
                        : 'Please select a day'}
                    </p>
                  </center> */}
                </div>
                <div className="text-center">
                  <span className="inputLabel">Time</span>
                  <TimePicker
                    showSecond={false}
                    defaultValue={this.state.selectedTime}
                    className="xxx"
                    onChange={this.onTimeChange}
                    format={format}
                    use12Hours
                    inputReadOnly
                  />
                </div>
              </div>
            ) : (
              <div>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div
                      style={{ padding: '15px', marginTop: '10px' }}
                    >
                      <center>
                        <span>
                          <span style={{ fontWeight: '600' }}>
                            {eventInfo.eventTitle} is{' '}
                            {this.getWhen(eventInfo)}.
                          </span>
                        </span>
                      </center>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <center>
                    {/* <h4 style={{ marginTop: '80px' }}>
                      {this.state.event.eventTitle}
                    </h4> */}

                    <h2 style={{ marginTop: '80px' }}>
                      {this.getDate(this.state.event)}
                    </h2>
                  </center>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    let t = to.substr(0, to.lastIndexOf('/'));
    return t;
  }

  getContent(mode, users, userId, customEvent, locations) {
    if (mode === 1) {
      return this.getDetailsTab(users, userId, locations);
    } else if (mode === 2) {
      return this.getScheduleTab();
    } else {
      // return this.getImagesTab();
      return this.getGuestListTab(customEvent);
    }
  }

  getTitleBar(customEvent) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="left">
                <MaterialIcon
                  icon="close"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.editMode(this.state.customEvent.event);
                  }}
                />
              </span>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Edit Experience</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="right">
                <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      customEvent,
                      this.props.locations,
                      this.props.packages,
                    );
                  }}
                />
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getTab(tabMode, tabLable, mode) {
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tabLable}
          </div>
        </a>
      );
    }
  }

  editMode(event) {
    const bundle = {};
    bundle.event = event;
    bundle.editing = false;
    this.props.editMode(bundle);
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    let customEvent = this.props.customEvent;
    return (
      <div id="home">
        <div
          id="snackbar"
          style={{
            visibility:
              this.state.copied === true ? 'visible' : 'hidden',
            opacity: this.state.copied === true ? '1' : '0',
          }}
        >
          Invite Link Copied!
        </div>

        <div>{this.getBannerCropper(this.state.cropping)}</div>
        <div>
          {this.getThumbnailCropper(this.state.croppingThumb)}
        </div>
        {this.getTitleBar(this.props.customEvent)}
        <div id="headersTopSecondary">
          <Row>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getTab(3, 'Who', this.state.mode)}
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getTab(1, 'What', this.state.mode)}
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getTab(2, 'When', this.state.mode)}
            </Col>
          </Row>
        </div>
        <div id="events">
          {this.getContent(
            this.state.mode,
            this.state.customEvent.event.users,
            this.props.profile.userId,
            customEvent,
            this.props.locations,
          )}
        </div>
        <div className="footer">{this.getFooter()}</div>
        {/* <style
          dangerouslySetInnerHTML={{
            __html:
              '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 75px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        /> */}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 80px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />

        <Modal
          isOpen={this.state.conversationModalVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeConversationModal}
          style={conversationModalStyles}
          contentLabel="Conversation Modal"
        >
          <div className="modalHeader">
            <a
              onClick={() => {
                this.closeConversationModal();
              }}
            >
              <div className="modalCloser text-center">
                <MaterialIcon
                  icon="keyboard_arrow_down"
                  size={25}
                  color="white"
                />
              </div>
            </a>
            <a
              onClick={() => {
                this.closeConversationModal();
              }}
            >
              <span className="modalHeader-text">Conversation</span>
            </a>
          </div>

          <ConversationModal
            eventId={this.props.match.params.id}
            className="conversationModal"
          />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customEvent: state.customEvent.customEvent,
    locations: state.locations.locations,
    events: state.events.events,
    packages: state.packages.packages,
    profile: state.profile.profile,
    user: state.user,
    taRequest: state.taRequest,
    timedEvents: state.timedEvents.events,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
    saveCe: (customEvent, newPackage) => {
      dispatch(saveCe(customEvent, newPackage));
    },
    saveCeP: (customEvent, newPackage) => {
      dispatch(saveCeP(customEvent, newPackage));
    },
    editCe: (customEvent, newPackage) => {
      dispatch(editCe(customEvent, newPackage));
    },
    editCeP: (customEvent, newPackage) => {
      dispatch(editCeP(customEvent, newPackage));
    },
    updateCe: (customEvent) => {
      dispatch(updateCe(customEvent));
    },
    editMode: (bundle) => {
      dispatch(editMode(bundle));
    },
    getConnectionsTagsAndFavs: (userId) => {
      dispatch(getConnectionsTagsAndFavs(userId));
    },
    finishEvent: (newEvent) => {
      dispatch(finishEvent(newEvent));
    },
    removeGuest: (newEvent) => {
      dispatch(removeGuest(newEvent));
    },
    updateLocations: (bundle) => {
      dispatch(updateLocations(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomPackageEditHome);
