export const GET_CE = 'GET_CE';
export const RESET_CE = 'RESET_CE';
export const SET_CE = 'SET_CE';
export const SAVE_CE = 'SAVE_CE';
export const SAVE_CEP = 'SAVE_CEP';
export const EDIT_CE = 'EDIT_CE';
export const EDIT_CEP = 'EDIT_CEP';
export const UPDATE_CE = 'UPDATE_CE';
export const APPROVE_EVENT = 'APPROVE_EVENT';
export const REJECT_EVENT = 'REJECT_EVENT';
export const EDIT_MODE = 'EDIT_MODE';
export const SET_DATE = 'SET_DATE';
