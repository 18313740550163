import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import UserAvatar from 'react-user-avatar';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';

const query = require('array-query');

class UserListItem extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  confirmDelete(id) {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            this.props.deleteDocument('users', id, '/admin/users'),
        },
        {
          label: 'No',
          onClick: () => console.log('closed'),
        },
      ],
    });
  }

  getPostDate(timestamp) {
    let myDate = new Date(timestamp * 1000);
    let dayTextFinal = moment(myDate).format('MM/DD/YYYY');
    return dayTextFinal;
  }

  getAdminStatus(user) {
    if (user.userType === 1) {
      return <span>Admin</span>;
    } else {
      return <span>User</span>;
    }
  }

  // onClick={() => {
  //   this.props.deleteDocument("users", Id);
  // }}

  getEdit(id) {
    const url = '/admin/user/edit/' + id;
    return (
      <div>
        <Link to={url}>
          <MaterialIcon icon="edit" size={25} color="#f21d59" />
        </Link>
        {/* <MaterialIcon
          icon="delete"
          size={25}
          onClick={() => {
            this.confirmDelete(id);
          }}
        /> */}
      </div>
    );
  }

  getCreatedDate(user) {
    if (user.created !== undefined) {
      const date = this.getPostDate(user.created.seconds);
      return date;
    }
  }

  getVerifiedStatus(user) {
    if (user.verified === true) {
      return (
        <span
          style={{
            color: 'green',
            fontWeight: '600',
            marginTop: '3px',
          }}
        >
          Verified
        </span>
      );
    } else {
      return (
        <span
          style={{
            color: 'red',
            fontWeight: '600',
            marginTop: '3px',
          }}
        >
          Not Verified
        </span>
      );
    }
  }

  getTagStatus(user) {
    let count = 0;
    if (user.tags !== undefined) {
      if (user.tags.love.tagsExperienceType !== undefined) {
        count = count + user.tags.love.tagsExperienceType.length;
      }
      if (user.tags.love.tagsBeverages !== undefined) {
        count = count + user.tags.love.tagsBeverages.length;
      }
      if (user.tags.love.tagsSetting !== undefined) {
        count = count + user.tags.love.tagsSetting.length;
      }
      if (user.tags.love.tagsDietary !== undefined) {
        count = count + user.tags.love.tagsDietary.length;
      }
      if (user.tags.love.tagsDateType !== undefined) {
        count = count + user.tags.love.tagsDateType.length;
      }
      if (user.tags.love.tagsCuisine !== undefined) {
        count = count + user.tags.love.tagsCuisine.length;
      }
      if (user.tags.love.tagsGenre !== undefined) {
        count = count + user.tags.love.tagsGenre.length;
      }
      if (user.tags.love.tagsEventType !== undefined) {
        count = count + user.tags.love.tagsEventType.length;
      }
    }
    if (user.tags !== undefined) {
      if (user.tags.hate.tagsExperienceType !== undefined) {
        count = count + user.tags.hate.tagsExperienceType.length;
      }
      if (user.tags.hate.tagsBeverages !== undefined) {
        count = count + user.tags.hate.tagsBeverages.length;
      }
      if (user.tags.hate.tagsSetting !== undefined) {
        count = count + user.tags.hate.tagsSetting.length;
      }
      if (user.tags.hate.tagsDietary !== undefined) {
        count = count + user.tags.hate.tagsDietary.length;
      }
      if (user.tags.hate.tagsDateType !== undefined) {
        count = count + user.tags.hate.tagsDateType.length;
      }
      if (user.tags.hate.tagsCuisine !== undefined) {
        count = count + user.tags.hate.tagsCuisine.length;
      }
      if (user.tags.hate.tagsGenre !== undefined) {
        count = count + user.tags.hate.tagsGenre.length;
      }
      if (user.tags.hate.tagsEventType !== undefined) {
        count = count + user.tags.hate.tagsEventType.length;
      }
    }

    let status = 0;
    if (count !== 0) {
      status = 1;
    }
    if (status === 0) {
      return (
        <span
          style={{
            color: 'red',
            fontWeight: '600',
            marginTop: '3px',
          }}
        >
          {' '}
          - {count} Tags
        </span>
      );
    } else {
      return (
        <span
          style={{
            color: 'green',
            fontWeight: '600',
            marginTop: '3px',
          }}
        >
          {' '}
          - {count} Tags
        </span>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={7} md={7} sm={7} lg={7} className="left">
                  <span className="label">User</span>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Created</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Actions</span>
                </Col>
              </div>
            </li>
            {this.props.users.map((user, index) => (
              <li className="adminlistitem">
                <div>
                  <Col xs={7} md={7} sm={7} lg={7} className="left">
                    <div className="left">
                      <UserAvatar
                        size="50"
                        name={user.firstName + user.lastName}
                        src={user.profilePhotoPath}
                      />
                    </div>
                    <div className="left">
                      <span className="invite-label">
                        {user.firstName} {user.lastName}
                      </span>
                      <br />
                      <span className="address">
                        @{user.userName}
                      </span>
                      {this.getVerifiedStatus(user)}
                      {this.getTagStatus(user)}
                    </div>
                  </Col>

                  <Col xs={3} md={3} sm={3} lg={3} className="left">
                    <div
                      style={{
                        width: '100%',
                        display: 'inline-block',
                      }}
                    >
                      {this.getCreatedDate(user)}
                    </div>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {this.getEdit(user.userId)}
                    {user.verified}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserListItem);

// Location Details Available
// **************************
// location.locationTitle
// location.categories
// location.locationDescription
// location.locationHero
// location.ocationThumb
// location.locationId
// location.locationType
// ** Vouchers and Packages as well
