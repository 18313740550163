import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

// Routes
import LoginScreen from './LoginScreen';
import InviteLink from './InviteLink';
import AuthHome from './AuthHome';
import Home from './Home';
import About from './About';
import CommunityRecovery from './CommunityRecovery';
import Community from './Community';
import Packages from './Packages';
import Package from './Package';
import Articles from './Articles';
import Article from './Article';
import SearchGuide from './SearchGuide';
import Event from './Event';
import Invite from './Invite';
import SignUp from './SignUp';
import Profile from './Profile';
import Places from './Places';
import ProfileEdit from './ProfileEdit';
import ProfileTag from './ProfileTag';
import SignUpProfile from './SignUpProfile';
import SignUpPhoto from './SignUpPhoto';
import SignUpTags from './SignUpTags';
import Activity from './Activity';
import Stats from './Stats';
import TrevelDestinations from './TrevelDestinations';
import PublicGuide from './Cities/PublicGuide';
import PublicGuideCategory from './Cities/PublicGuideCategory';
import PublicGuideMusicCategory from './Cities/PublicGuideMusicCategory';
import PublicGuideEntertainmentCategory from './Cities/PublicGuideEntertainmentCategory';
import PublicGuideEntertainers from './Cities/PublicGuideEntertainers';
import PublicGuideEntertainerCategory from './Cities/PublicGuideEntertainerCategory';
import ArchGrant from './ArchGrant';
// Events

import EventsUpcoming from './Events/EventsUpcoming';
import EventsCompleted from './Events/EventsCompleted';
import EventsPlanning from './Events/EventsPlanning';
import ScrapBook from './Events/ScrapBook';
import Location from './Location';
import Support from './Support';
import Feedback from './Feedback';
import EventHome from './EventHome';
import CustomPackageHome from './CustomPackageHome';
import CustomPackageEditHome from './CustomPackageEditHome';
import CustomPackageAddLocation from './CustomPackageAddLocation';
import CustomPackageEditLocation from './CustomPackageEditLocation';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';
import Affiliates from './Affiliates';
import WorkWithUs from './WorkWithUs';
import Privacy from './Privacy';
import ResponsibleDisclosure from './ResponsibleDisclosure';
import OurServices from './OurServices';
import Notifications from './Events/Notifications';
import PlanEventHome from './PlanEventHome';
import PlanEventConnect from './PlanEventConnect';
import PlanEventOptions from './PlanEventOptions';
import ActiveEvent from './ActiveEvent';
import UserVouchers from './UserVouchers';
import ConnectionsPage from './ConnectionsPage';
import ContactProfile from './ContactProfile';
import Wizard from './Wizard';
import Account from './Account';
import VEvent from './VEvent';
// Check Out //
import PackageCheckoutReview from './Checkout/PackageCheckoutReview';
import CustomCheckoutReview from './Checkout/CustomCheckoutReview';
import PackageSetup from './Checkout/PackageSetup';
import CustomPackageSetup from './Checkout/CustomPackageSetup';
// Admin
import Admin from './Admin/Admin';
import AdminLocations from './Admin/AdminLocations';
import AdminImports from './Admin/AdminImports';
import AdminPackages from './Admin/AdminPackages';
import AdminUsers from './Admin/AdminUsers';
import AdminEvents from './Admin/AdminEvents';
import AdminArticles from './Admin/AdminArticles';
import AdminTemplates from './Admin/AdminTemplates';
import AdminArticlesEdit from './Admin/AdminArticlesEdit';
import AdminCustomLocations from './Admin/AdminCustomLocations';
import AdminCustomLocationsEdit from './Admin/AdminCustomLocationsEdit';
import AdminLocationsEdit from './Admin/AdminLocationsEdit';
import AdminUsersEdit from './Admin/AdminUsersEdit';
import AdminVendors from './Admin/AdminVendors';
import AdminVendorEdit from './Admin/AdminVendorEdit';
import AdminCreateEvent from './Admin/AdminCreateEvent';
import AdminCreateScheduledEvent from './Admin/AdminCreateScheduledEvent';
import AdminCreateEventHome from './Admin/AdminCreateEventHome';
import AdminCreateEventAddLocation from './Admin/AdminCreateEventAddLocation';
import AdminTemplateEdit from './Admin/AdminTemplateEdit';
import AdminTags from './Admin/AdminTags';
import AdminTagsEdit from './Admin/AdminTagsEdit';
import AdminRegions from './Admin/AdminRegions';
import AdminRegionEdit from './Admin/AdminRegionEdit';

// Vendor
import VendorPromote from './Vendor/VendorPromote';
import VendorHome from './Vendor/VendorHome';
import VendorLocations from './Vendor/VendorLocations';
import VendorAccount from './Vendor/VendorAccount';
import VendorLocationsEdit from './Vendor/VendorLocationsEdit';
// import VendorVouchersEdit from "./Vendor/VendorVouchersEdit";
// import VendorVouchers from "./Vendor/VendorVouchers";
import VendorUsers from './Vendor/VendorUsers';
import VendorEditUser from './Vendor/VendorEditUser';
import VendorAddUser from './Vendor/VendorAddUser';
import VendorAddUsers from './Vendor/VendorAddUsers';
import VendorInsights from './Vendor/VendorInsights';
import VendorCommunityHome from './Vendor/VendorCommunityHome';
import VendorCommunityDestinationsDirectory from './Vendor/VendorCommunityDestinationsDirectory';
import VendorCommunitEntertainmentDirectory from './Vendor/VendorCommunitEntertainmentDirectory';
import VendorVouchers from './Vendor/VendorVouchers';
import VendorRewards from './Vendor/VendorRewards';
import VendorAbout from './Vendor/VendorAbout';
import VendorClaim from './Vendor/VendorClaim';
import VendorClaimReady from './Vendor/VendorClaimReady';
import VendorSignUp from './VendorSignUp';
import VendorSignUpProfile from './VendorSignUpProfile';
import VendorCreateReward from './Vendor/VendorCreateReward';
import VendorCreateEvent from './Vendor/VendorCreateEvent';
import VendorEditReward from './Vendor/VendorEditReward';
import VendorCreateEventAddLocation from './Vendor/VendorCreateEventAddLocation';
import VendorEvents from './Vendor/VendorEvents';
import VendorCreateEventHome from './Vendor/VendorCreateEventHome';
import VendorCreateScheduledEvent from './Vendor/VendorCreateScheduledEvent';
import VendorCreatePromo from './Vendor/VendorCreatePromo';
import VendorLocationsAdd from './Vendor/VendorLocationsAdd';
import VendorPromoDetails from './Vendor/VendorPromoDetails';
import VendorRewardDetails from './Vendor/VendorRewardDetails';
import VendorEditPromo from './Vendor/VendorEditPromo';
import VendorEventDetails from './Vendor/VendorEventDetails';
import VendorEditEvent from './Vendor/VendorEditEvent';
import VendorSupport from './Vendor/VendorSupport';
import VendorPilot from './Vendor/VendorPilot';
import VendorGallery from './Vendor/VendorGallery';
import VendorValidator from './Vendor/VendorValidator';
import VendorTimedEvents from './Vendor/VendorTimedEvents';
import VendorTimedEventsPromos from './Vendor/VendorTimedEventsPromos';
import TimedEvent from './TimedEvent';
import VendorTimedEventAdd from './Vendor/VendorTimedEventAdd';
import VendorTimedEventEdit from './Vendor/VendorTimedEventEdit';
import VendorTimedEventClone from './Vendor/VendorTimedEventClone';
import VendorEntertainmentAdd from './Vendor/VendorEntertainmentAdd';
import VendorEntertainmentAddAdmin from './Vendor/VendorEntertainmentAddAdmin';
import VendorEntertainmentEdit from './Vendor/VendorEntertainmentEdit';
import VendorEntertainmentEditAdmin from './Vendor/VendorEntertainmentEditAdmin';
import VendorAdminEntertainment from './Vendor/VendorAdminEntertainment';
import VendorTrevelExperiencesSignUp from './Vendor/VendorTrevelExperiencesSignUp';
import { ResetPassword } from './ResetPassword';
import { ResetPasswordBase } from './ResetPasswordBase';
import EmailVerification from './EmailVerification';
import Entertainment from './Entertainment';

export default class Routes extends Component {
  render() {
    return (
      <div className="fullheight">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={AuthHome} />
          {/* <Route exact path="/signup" component={SignUp} /> */}
          <Route path="/signup/profile/" component={SignUpProfile} />
          <Route
            path="/signup/emailverification/"
            component={EmailVerification}
          />
          <Route path="/signup/photo/" component={SignUpPhoto} />
          <Route path="/signup/preferences/" component={SignUpTags} />
          <Route exact path="/signup/:token?" component={SignUp} />
          <Route exact path="/login" component={LoginScreen} />
          <Route
            exact
            path="/reset/request"
            component={ResetPasswordBase}
          />
          <Route
            exact
            path="/reset/password"
            component={ResetPassword}
          />
          {/* Plan Event */}
          <Route
            exact
            path="/events/plan/options"
            component={PlanEventOptions}
          />
          <Route
            exact
            path="/events/plan/:screen?"
            component={PlanEventConnect}
          />
          <Route
            exact
            path="/events/home"
            component={PlanEventHome}
          />
          <Route exact path="/packages" component={Packages} />
          <Route
            exact
            path="/connections"
            component={ConnectionsPage}
          />
          <Route
            exact
            path="/connections/profile/:id"
            component={ContactProfile}
          />
          <Route exact path="/archgrants" component={ArchGrant} />
          <Route
            exact
            path="/event/create/home/:mode?"
            component={CustomPackageHome}
          />
          <Route
            exact
            path="/event/edit/:id/:mode?"
            component={CustomPackageEditHome}
          />
          <Route
            exact
            path="/event/create/add"
            component={CustomPackageAddLocation}
          />
          <Route
            exact
            path="/event/create/edit"
            component={CustomPackageEditLocation}
          />
          <Route
            path="/event/create/review"
            component={CustomCheckoutReview}
          />
          <Route
            exact
            path="/guide/search/:term?"
            component={TrevelDestinations}
          />
          <Route exact path="/search:term?" component={SearchGuide} />
          <Route
            exact
            path="/guide/category/:cat"
            component={PublicGuideCategory}
          />
          <Route
            exact
            path="/guide/category/music/:cat"
            component={PublicGuideMusicCategory}
          />
          <Route
            exact
            path="/guide/entertainers/:cat"
            component={PublicGuideEntertainerCategory}
          />
          <Route
            exact
            path="/guide/entertainers"
            component={PublicGuideEntertainers}
          />
          <Route
            exact
            path="/guide/category/entertainment/:cat"
            component={PublicGuideEntertainmentCategory}
          />
          {/* Old Guide Remove Maybe */}
          {/* <Route exact path="/guide/" component={PublicGuide} /> */}
          <Route exact path="/about/" component={About} />
          <Route
            path="/community/recovery/:city?"
            component={CommunityRecovery}
          />
          <Route path="/community/:city?" component={Community} />
          <Route exact path="/package/:id" component={Package} />
          <Route
            exact
            path="/timedevent/:id"
            component={TimedEvent}
          />
          <Route
            exact
            path="/entertainment/:id"
            component={Entertainment}
          />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/profile/edit" component={ProfileEdit} />
          <Route exact path="/profile/tag" component={ProfileTag} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/articles" component={Articles} />
          <Route path="/article/:title" component={Article} />
          <Route path="/invite/:id" component={Invite} />
          <Route path="/event/:id" component={Event} />
          <Route path="/events/upcoming" component={EventsUpcoming} />
          <Route
            path="/events/completed"
            component={EventsCompleted}
          />
          <Route path="/events/planning" component={EventsPlanning} />
          <Route
            exact
            path="/events/invitations"
            component={Notifications}
          />
          <Route exact path="/location/:id?" component={Location} />
          <Route path="/support/:topic?" component={Support} />
          <Route path="/feedback" component={Feedback} />
          <Route path="/eventhome" component={EventHome} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/affiliates" component={Affiliates} />
          <Route path="/workwithus" component={WorkWithUs} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/stats" component={Stats} />
          <Route path="/rewards" component={UserVouchers} />
          <Route path="/setup/:eventId?" component={Wizard} />
          <Route path="/past-events" component={ScrapBook} />
          <Route path="/places" component={Places} />
          <Route path="/activity" component={Activity} />
          <Route path="/vevent/:id" component={VEvent} />
          <Route
            path="/responsibledisclosure"
            component={ResponsibleDisclosure}
          />
          T
          <Route path="/ourservices" component={OurServices} />
          <Route
            path="/custompackagehome"
            component={CustomPackageHome}
          />
          {/* ADMIN ROUTES */}
          <Route path="/admin/home" component={Admin} />
          <Route path="/admin/vendors" component={AdminVendors} />
          <Route
            path="/admin/vendor/edit/:id"
            component={AdminVendorEdit}
          />
          <Route
            path="/admin/vendor/new/"
            component={AdminVendorEdit}
          />
          <Route path="/admin/articles" component={AdminArticles} />
          <Route
            path="/admin/article/edit/:id"
            component={AdminArticlesEdit}
          />
          <Route
            path="/admin/article/new"
            component={AdminArticlesEdit}
          />
          <Route path="/admin/locations" component={AdminLocations} />
          <Route
            path="/admin/location/edit/:id"
            component={AdminLocationsEdit}
          />
          <Route
            path="/admin/customlocations"
            component={AdminCustomLocations}
          />
          <Route
            path="/admin/customlocation/edit/:id"
            component={AdminCustomLocationsEdit}
          />
          <Route path="/admin/packages" component={AdminPackages} />
          <Route
            path="/admin/createpackage"
            component={AdminCreateEventHome}
          />
          <Route path="/admin/users" component={AdminUsers} />
          <Route
            path="/admin/user/edit/:id"
            component={AdminUsersEdit}
          />
          <Route path="/admin/imports" component={AdminImports} />
          <Route path="/admin/events" component={AdminEvents} />
          <Route
            path="/admin/event/create/scheduled"
            component={AdminCreateScheduledEvent}
          />
          <Route
            path="/admin/event/create/add"
            component={AdminCreateEventAddLocation}
          />
          <Route
            exact
            path="/admin/event/create/itinerary"
            component={AdminCreateEvent}
          />
          <Route
            exact
            path="/admin/templates"
            component={AdminTemplates}
          />
          <Route
            path="/admin/template/edit/:id"
            component={AdminTemplateEdit}
          />
          <Route
            exact
            path="/admin/regions"
            component={AdminRegions}
          />
          <Route
            path="/admin/region/edit/:id"
            component={AdminRegionEdit}
          />
          <Route exact path="/admin/tags/" component={AdminTags} />
          <Route
            path="/admin/tags/edit/:id"
            component={AdminTagsEdit}
          />{' '}
          */}
          {/* Vendor Routes */}
          <Route
            exact
            path="/vendor/locations"
            component={VendorLocations}
          />
          <Route
            exact
            path="/vendor/location/edit/:id"
            component={VendorLocationsEdit}
          />
          <Route
            exact
            path="/vendor/signup"
            component={VendorSignUp}
          />
          <Route
            exact
            path="/vendor/experience-gallery"
            component={VendorGallery}
          />
          <Route
            exact
            path="/vendor/signupprofile/:id?"
            component={VendorSignUpProfile}
          />
          <Route
            exact
            path="/trevel-experiences/signup"
            component={VendorTrevelExperiencesSignUp}
          />
          <Route
            path="/vendor/validator"
            component={VendorValidator}
          />
          <Route path="/vendor/home" component={VendorHome} />
          <Route path="/vendor/promote" component={VendorPromote} />
          <Route
            exact
            path="/vendor/community"
            component={VendorCommunityHome}
          />
          <Route
            exact
            path="/vendor/directory-entertainers"
            component={VendorCommunitEntertainmentDirectory}
          />
          <Route
            exact
            path="/vendor/directory-destinations"
            component={VendorCommunityDestinationsDirectory}
          />
          <Route path="/vendor/support" component={VendorSupport} />
          <Route path="/vendor/pilot" component={VendorPilot} />
          <Route
            path="/vendor/experiences"
            component={VendorVouchers}
          />
          <Route path="/vendor/rewards" component={VendorRewards} />
          <Route path="/vendor/users" component={VendorUsers} />
          <Route
            path="/vendor/add/users"
            component={VendorAddUsers}
          />
          <Route
            path="/vendor/user/edit/:id"
            component={VendorEditUser}
          />
          <Route path="/vendor/new/user" component={VendorAddUser} />
          <Route path="/vendor/claim/:id?" component={VendorClaim} />
          <Route
            path="/vendor/claimready/:id"
            component={VendorClaimReady}
          />
          <Route path="/vendor/insights" component={VendorInsights} />
          <Route
            path="/experiences/:persona?"
            component={VendorAbout}
          />
          <Route
            exact
            path="/vendor/reward/:id"
            component={VendorRewardDetails}
          />
          <Route
            exact
            path="/vendor/promo/:id"
            component={VendorPromoDetails}
          />
          <Route
            exact
            path="/vendor/account"
            component={VendorAccount}
          />
          <Route
            exact
            path="/vendor/event/:id"
            component={VendorEventDetails}
          />
          <Route
            path="/vendor/events/create/"
            component={VendorCreateEventHome}
          />
          <Route
            path="/vendor/timedevents/"
            component={VendorTimedEvents}
          />
          <Route
            path="/vendor/timedpromos/"
            component={VendorTimedEventsPromos}
          />
          <Route
            exact
            path="/vendor/admin/entertainment/"
            component={VendorAdminEntertainment}
          />
          <Route
            exact
            path="/vendor/admin/entertainment/add"
            component={VendorEntertainmentAddAdmin}
          />
          <Route
            exact
            path="/vendor/entertainment/add"
            component={VendorEntertainmentAdd}
          />
          <Route
            exact
            path="/vendor/entertainment/edit/:id"
            component={VendorEntertainmentEdit}
          />
          <Route
            exact
            path="/vendor/admin/entertainment/edit/:id"
            component={VendorEntertainmentEditAdmin}
          />
          <Route
            exact
            path="/vendor/timedevent/add/:type?"
            component={VendorTimedEventAdd}
          />
          <Route
            exact
            path="/vendor/timedevent/edit/:id"
            component={VendorTimedEventEdit}
          />
          <Route
            exact
            path="/vendor/timedevent/clone/:id"
            component={VendorTimedEventClone}
          />
          <Route
            exact
            path="/vendor/locations/add/"
            component={VendorLocationsAdd}
          />
          <Route
            path="/vendor/event/create/scheduled"
            component={VendorCreateScheduledEvent}
          />
          <Route
            path="/vendor/event/create/add"
            component={VendorCreateEventAddLocation}
          />
          <Route
            exact
            path="/vendor/event/create/itinerary"
            component={VendorCreateEvent}
          />
          <Route path="/vendor/events" component={VendorEvents} />
          <Route
            exact
            path="/vendor/create/promo"
            component={VendorCreatePromo}
          />
          <Route
            path="/vendor/promo/edit/:id"
            component={VendorEditPromo}
          />
          <Route
            exact
            path="/vendor/create/reward"
            component={VendorCreateReward}
          />
          <Route
            path="/vendor/reward/edit/:id"
            component={VendorEditReward}
          />
          <Route
            path="/vendor/event/edit/:id/:mode?"
            component={VendorEditEvent}
          />
          {/* Check Out */}
          <Route
            path="/checkout/review"
            component={PackageCheckoutReview}
          />
          <Route path="/checkout/setup" component={PackageSetup} />
          <Route
            path="/checkout/customsetup/"
            component={CustomPackageSetup}
          />
          {/* Active Event */}
          <Route exact path="/active" component={ActiveEvent} />
          <Route
            exact
            path="/invitelink/:token/:eventId"
            component={InviteLink}
          />
          <Route component={Home} />
        </Switch>
      </div>
    );
  }
}
