import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Routes from './routes';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { connect } from 'react-redux';
// Components
import ToolBar from './components/ToolBar';
import InviteFull from './components/InviteFull';
import AuthTopBar from './components/AuthTopBar';
import SideDrawer from './components/SideDrawer';
import AuthSideDrawer from './components/AuthSideDrawer';
import AuthSideMenu from './components/AuthSideMenu';
import BackDrop from './components/BackDrop';
import Footer from './components/Footer';
import BottomNav from './components/BottomNav';
// Styles
import './App.css';
import './bootstrap.css';
import { first } from 'draft-js/lib/DefaultDraftBlockRenderMap';

class App extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      authUser: null,
      sideDrawerOpen: false,
    };
  }
  static propTypes = {
    init: PropTypes.func,
  };

  UNSAFE_componentWillMount() {
    const pathname = window.location.pathname;
    const hostname = window.location.hostname;
    const redirect = 'https://trevel.com' + pathname;
    if (
      hostname === 'gotogetherapp' ||
      hostname === 'gotogetherapp.com'
    ) {
      window.location.replace(redirect);
    }

    if (this.props.hydrated.hydrated === false) {
      this.props.hydrateApp();
    }
    if (this.props.user.authenticate === true) {
      if (this.props.authhydrated.hydrated === false) {
        this.props.authhydrateApp();
      }
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((authUser) => {
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    });

    this.unlisten = this.props.history.listen((location, action) => {
      const path = location.pathname.split('/');
      let firstPath = path[1];

      if (
        firstPath !== 'location' &&
        firstPath !== '' &&
        firstPath !== 'home' &&
        firstPath !== 'login' &&
        firstPath !== 'experiences' &&
        firstPath !== 'signup' &&
        firstPath !== 'about' &&
        firstPath !== 'reset' &&
        firstPath !== 'article' &&
        firstPath !== 'articles' &&
        firstPath !== 'contactus' &&
        firstPath !== 'privacy' &&
        firstPath !== 'vendor' &&
        firstPath !== 'trevel-experiences'
      ) {
        if (this.props.locations.length === 0) {
          this.props.history.push('/');
        }
      }
    });

    let startPath = this.props.history.location.pathname;

    const splitStartPath = startPath.split('/');

    let firstStartPathOne = splitStartPath[1];
    if (
      firstStartPathOne !== 'location' &&
      firstStartPathOne !== '' &&
      firstStartPathOne !== 'home' &&
      firstStartPathOne !== 'login' &&
      firstStartPathOne !== 'experiences' &&
      firstStartPathOne !== '/' &&
      firstStartPathOne !== 'signup' &&
      firstStartPathOne !== 'about' &&
      firstStartPathOne !== 'reset' &&
      firstStartPathOne !== 'article' &&
      firstStartPathOne !== 'articles' &&
      firstStartPathOne !== 'contactus' &&
      firstStartPathOne !== 'privacy' &&
      firstStartPathOne !== 'vendor' &&
      firstStartPathOne !== 'trevel-experiences'
    ) {
      if (this.props.locations.length === 0) {
        this.props.history.push('/');
      }
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  // Side Drawer
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  getFullInvite(events) {
    const activeInvitationsArray = [];
    if (events !== undefined && events !== null) {
      events.forEach((event) => {
        if (
          event.users[this.props.user.id] !== undefined &&
          event.users[this.props.user.id].inviteAction === false
        ) {
          activeInvitationsArray.push(event);
        }
      });
    }
    const activeCountArray = [];
    activeInvitationsArray.forEach((event) => {
      if (
        event.invitations[this.props.user.id] !== undefined &&
        event.invitations[this.props.user.id].status === 0
      ) {
        activeCountArray.push(event);
      }
    });
    let count = activeCountArray.length;
    if (count > 0) {
      return (
        <div className="App">
          <InviteFull eventsArray={activeCountArray} />
          <div style={{ visibility: 'none' }}>
            <AuthTopBar
              drawerClickHandler={this.drawerToggleClickHandler}
              history={this.props.history}
            />
          </div>
        </div>
      );
    } else {
      let backdrop;
      if (this.state.sideDrawerOpen) {
        backdrop = <BackDrop click={this.backdropClickHandler} />;
      }
      return (
        <div className="App">
          {backdrop}
          <AuthSideDrawer
            show={this.state.sideDrawerOpen}
            click={this.backdropClickHandler}
            history={this.props.history}
          />

          <div id="authleft">
            <AuthSideMenu
              history={this.props.history}
              click={this.backdropClickHandler}
            />
          </div>
          <div id="authright">
            <AuthTopBar
              drawerClickHandler={this.drawerToggleClickHandler}
              history={this.props.history}
            />
            <div className="fullheight maincontent" id="top">
              <div className="maincontent-push">
                <Routes
                  authUser={this.state.authUser}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>

          <BottomNav />
        </div>
      );
    }
  }

  render() {
    let content;
    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <BackDrop click={this.backdropClickHandler} />;
    }
    if (this.props.profile.profilePhotoPath === undefined) {
      content = (
        <div className="App">
          <div className="fullheight">
            <SideDrawer
              show={this.state.sideDrawerOpen}
              click={this.backdropClickHandler}
            />
            {backdrop}
            <ToolBar
              drawerClickHandler={this.drawerToggleClickHandler}
              history={this.props.history}
            />
            <div className="maincontent" id="top">
              <div className="maincontent-push">
                <Routes
                  authUser={this.state.authUser}
                  history={this.props.history}
                />

                <Footer />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      content = (
        <div className="fullheight">
          {this.getFullInvite(this.props.events)}

          <style
            dangerouslySetInnerHTML={{
              __html:
                '#root, { height: 100%; position: absolute; width: 100%;}',
            }}
          />
        </div>
      );
    }

    return <div className="full-height">{content}</div>;
  }
}

function mapStateToProps(state) {
  return {
    events: state.events.events,
    profile: state.profile.profile,
    locations: state.locations.locations,
  };
}

export default connect(mapStateToProps)(App);
