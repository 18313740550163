import * as action from './actions';

export const getVendorAwarded = (id) => {
  return {
    type: action.GET_VENDORAWARDED,
    payload: id,
  };
};

export const setVendorAwarded = (vendorAwarded) => {
  return {
    type: action.SET_VENDORAWARDED,
    payload: vendorAwarded,
  };
};

export const refreshVendorAwarded = (vendorAwarded) => {
  return {
    type: action.REFRESH_VENDORAWARDED,
    payload: vendorAwarded,
  };
};

const ACTION_HANDLERS = {
  [action.GET_VENDORAWARDED]: (state, action) => {
    return { ...state };
  },
  [action.SET_VENDORAWARDED]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.REFRESH_VENDORAWARDED]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
