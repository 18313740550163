import * as action from "./actions";

export const getConnectionsTagsAndFavs = (id) => {
  return {
    type: action.GET_TAGSANDFAVS,
    payload: id,
  };
};

export const setConnectionsTagsAndFavs = (bundle) => {
  return {
    type: action.SET_TAGSANDFAVS,
    payload: bundle,
  };
};

export const resetConnectionsTagsAndFavs = (bundle) => {
  return {
    type: action.RESET_TAGSANDFAVS,
    payload: bundle,
  };
};

const ACTION_HANDLERS = {
  [action.GET_TAGSANDFAVS]: (state, action) => {
    return { ...state };
  },
  [action.RESET_TAGSANDFAVS]: (state, action) => {
    return { ...state };
  },
  [action.SET_TAGSANDFAVS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  connectionsTagsAndFavs: {
    status: 0,
  },
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
