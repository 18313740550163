import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as auth from '../../config/auth';
import firebase from '../../config/firebase';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../images/TrevelLogoText.svg';
// Reducers
import { setNewUser, setUser } from '../../sagas/loginSaga/reducer';
import { getEvents } from '../../sagas/userEventsSaga/reducer';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class SignInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  signIn(email, password) {
    auth
      .doSignInWithEmailAndPassword(email, password)
      .then((result) => {
        const user = {
          id: result.user.uid,
          userName: result.user.displayName,
          email: result.user.email,
          authenticated: true,
          newuser: false,
          claimLink: this.props.unauth.claimLink,
          claimId: this.props.unauth.claimId,
          vendorSignup: this.props.unauth.vendorSignup,
          referalId: this.props.unauth.referalId,
          eventId: null,
          inviteId: null,
        };

        // Check if this user was sent an invite to join
        if (this.props.invite.event.title !== null) {
          user.invited = true;
          user.eventId = this.props.invite.event[0].eventId;
          user.inviteType =
            this.props.invite.event[0].invitations[
              this.props.invite.event[0].token
            ].type;
        }

        // Check if user has click an "Invite Someone Link"
        if (this.props.newEvent.clear === false) {
          user.createEvent = true;
        }

        // We will want to seperate this from setUser
        if (this.props.invite.event.title !== null) {
          let profile = [];
          const db = firebase.firestore();

          db.collection('users')
            .doc(result.user.uid)
            .get()
            .then((doc) => {
              if (doc && doc.exists) {
                const {
                  firstName,
                  lastName,
                  userName,
                  profilePhotoPath,
                  userId,
                  sex,
                  email,
                  userType,
                  vendorAccount,
                  tags,
                  zones,
                  zoneId,
                  state,
                  favorites,
                  favoriteEntertainment,
                } = doc.data();
                profile.push({
                  userId: doc.id,
                  firstName,
                  lastName,
                  userName,
                  profilePhotoPath,
                  sex,
                  email,
                  userType,
                  vendorAccount,
                  tags,
                  zones,
                  zoneId,
                  state,
                  favorites,
                  favoriteEntertainment,
                });
              }

              this.updateEvent(
                this.props.invite.event[0],
                profile[0].firstName,
                profile[0].lastName,
                profile[0].userName,
                this.props.invite.event[0].creationDetails.plannerId,
                profile[0].userId,
                this.props.invite,
                user,
              );
            })
            .catch(function (error) {});
        } else {
          this.setState({ ...INITIAL_STATE });
          this.props.setUser(user);
        }
      })
      .catch((error) => {
        this.setState(byPropKey('error', error));
      });
  }

  // twitterLogin = () => {
  //   var provider = new firebase.auth.TwitterAuthProvider();
  //   firebase.auth().signInWithRedirect(provider);
  // };

  // facebookLogin = () => {
  //   var provider = new firebase.auth.FacebookAuthProvider();
  //   firebase.auth().signInWithRedirect(provider);
  // };

  googleLogin = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  };

  getErrorMessage(error) {
    if (error !== null) {
      let message = error.message;
      if (
        message ===
        'Firebase: The password is invalid or the user does not have a password. (auth/wrong-password).'
      ) {
        message =
          'This username or password does not match our records. Please try again.';
      }
      return message;
    }
  }

  updateEvent(
    event,
    firstName,
    lastName,
    userName,
    senderId,
    userId,
    invite,
    user,
  ) {
    const db = firebase.firestore();
    const eventId = event.eventId;
    const userEvent = db.collection('user_events').doc(eventId);
    const timestamp = new Date();

    const userConnectionObject = {
      connected: true,
      created: timestamp,
      userId: senderId,
    };

    const senderConnectionObject = {
      connected: true,
      created: timestamp,
      userId: userId,
    };

    const usersObject = event.users;
    const invitationObject = event.invitations;
    const fullname = firstName + ' ' + lastName;
    let updatedUser = {};

    // Add User With New UID
    if (invite.event[0].eventSettings.eventApproved === true) {
      updatedUser = {
        access: true,
        approved: true,
        editor: false,
        attending: true,
        rejected: false,
        inviteAction: true,
        profile: {
          fullName: fullname,
          profilePhotoPath: '',
          userName: userName,
        },
      };
    } else {
      // is planning invite
      updatedUser = {
        access: true,
        approved: false,
        editor: true,
        attending: true,
        rejected: false,
        inviteAction: true,
        profile: {
          fullName: fullname,
          profilePhotoPath: '',
          userName: userName,
        },
      };
    }

    usersObject[userId] = updatedUser;
    // Delete temp id
    delete usersObject[event.token];
    // Update Invitations
    invitationObject[userId] = event.invitations[event.token];
    invitationObject[userId].userId = userId;
    // Delete Old Invite
    delete invitationObject[event.token];
    // Make New Event
    // let updatedEvent = event;
    // updatedEvent.users = usersObject;
    // updatedEvent.invitations = invitationObject;
    // Delete Token Property
    // delete updatedEvent.token;
    // console.log("updatedEvent", updatedEvent);
    const setDoc = userEvent
      .update({
        users: usersObject,
        invitations: invitationObject,
        // status: 2
      })
      .then(() => {
        const setDoc = db
          .collection('connections')
          .doc(userId)
          .set({
            connections: {
              [senderId]: {
                connected: true,
                created: timestamp,
                userId: senderId,
              },
            },
          })
          .then(() => {
            const senderConnections = db
              .collection('connections')
              .doc(senderId);
            const setDoc3 = senderConnections
              .update({
                ['connections.' + userId]: senderConnectionObject,
              })
              .then(() => {
                this.props.getEvents(userId);
                this.props.setUser(user);
              });
          });
      });
  }

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <div id="signup">
        <div>
          <div className="title-area">
            <div
              style={{
                maxWidth: '200px',
                margin: '0px auto',
                marginBottom: '20px',
                position: 'relative',
                left: '-10px',
              }}
            >
              <img src={logo} />
            </div>

            <h3>Login to Trevel</h3>
            <p className="signup-box-info">
              <span>Or </span>
              <Link
                to={{
                  pathname: '/signup',
                }}
              >
                create an account
              </Link>
              <span> for free.</span>
            </p>
          </div>
          <Formik
            initialValues={{ email: '', password: '' }}
            validate={(values) => {
              let errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.email,
                )
              ) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }}
            onSubmit={(values) => {
              this.signIn(values.email, values.password);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Container>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="left"
                    >
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                      <Field
                        type="password"
                        name="password"
                        placeholder="Please enter your password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-message"
                      />
                      <span className="error-message">
                        {this.getErrorMessage(this.state.error)}
                      </span>
                      {/* <button type="submit" disabled={isSubmitting}> */}
                      <button type="submit">Login</button>
                      <span
                        className="social-button"
                        onClick={() => {
                          this.googleLogin();
                        }}
                      >
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgoogle-logo.png?alt=media&token=d0484856-780c-429a-9f95-fa774dc5652b"
                          height="20px"
                        />{' '}
                        Login with Google
                      </span>
                    </Col>
                  </Row>
                  <Row className="disclaimer">
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <center>
                        <Link
                          to={{
                            pathname: '/reset/request',
                          }}
                        >
                          Forgot Password
                        </Link>
                      </center>
                    </Col>
                  </Row>
                  <Row
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="disclaimer"
                  />
                </Container>
              </Form>
            )}
          </Formik>
          <style
            dangerouslySetInnerHTML={{
              __html: '.nav-signin { display: none }',
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    unauth: state.unauth,
    newEvent: state.newEvent,
    invite: state.invite,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
    setNewUser: (user) => {
      dispatch(setNewUser(user));
    },
    getEvents: (user) => {
      dispatch(getEvents(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInForm);
