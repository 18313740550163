import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledCollapse,
  Collapse,
} from 'reactstrap';
import { startEvent } from '../../sagas/activeEventSaga/reducer';
import { getNewEvent } from '../../sagas/createEventSaga/reducer';
// Reducers
import {
  approveEvent,
  rejectEvent,
} from '../../sagas/customEventSaga/reducer';
// Components
import LocationListItem from '../LocationListItem';
// Styles
import './EventListItem.css';

const query = require('array-query');

class EventListItem extends Component {
  getLocations(event) {
    if (event.packageDetails.locations === undefined) {
      const packageDetails = query('packageId')
        .is(event.packageDetails.packageId)
        .on(this.props.packages);
      return (
        <div className="card-section no-padding">
          <LocationListItem
            locations={packageDetails[0].locations}
            eventId={event.eventId}
          />
        </div>
      );
    } else {
      return (
        <div className="card-section no-padding">
          <LocationListItem
            locations={event.packageDetails.locations}
            eventId={event.eventId}
          />
        </div>
      );
    }
  }

  getLocationsList(event) {
    if (event.packageDetails.locations === undefined) {
      const packageDetails = query('packageId')
        .is(event.packageDetails.packageId)
        .on(this.props.packages);
      return (
        <div className="no-padding">
          {packageDetails[0].locations.map((location, index) => (
            <div>
              <span style={{ color: 'black' }}>
                {location.locationTitle}
              </span>
              {event.packageDetails.locations.length !== index + 1 &&
                ', '}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="no-padding">
          {event.packageDetails.locations.map((location, index) => (
            <span>
              <span style={{ color: 'black' }}>
                {location.locationTitle}
              </span>
              {event.packageDetails.locations.length !== index + 1 &&
                ', '}
            </span>
          ))}
        </div>
      );
    }
  }

  approveEvent(event, profile) {
    const bundle = {};
    bundle.event = event;

    // Update History
    const updatedHistory = event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 2,
      fullName: profile.userName,
      userId: profile.userId,
    };
    updatedHistory.push(newHistory);
    bundle.event.editHistory = updatedHistory;

    // SET USERS
    const usersObject = event.users;
    const newUsersObject = {};
    Object.keys(usersObject).forEach((key) => {
      newUsersObject[key] = {
        access: usersObject[key].access,
        editor: usersObject[key].editor,
        approved: true,
        attending: true,
        profile: {
          profilePhotoPath: usersObject[key].profile.profilePhotoPath,
          fullName: usersObject[key].profile.fullName,
        },
      };
    });
    bundle.event.users = newUsersObject;

    this.props.approveEvent(bundle);
  }

  rejectEvent(event, profile) {
    const bundle = {};
    bundle.event = event;

    // Update History
    const updatedHistory = event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 3,
      fullName: profile.userName,
      userId: profile.userId,
    };
    updatedHistory.push(newHistory);
    bundle.event.editHistory = updatedHistory;

    // SET USERS
    const userId = profile.userId;
    const usersObject = event.users;
    const newUsersObject = {};
    Object.keys(usersObject).forEach((key) => {
      if (key === userId) {
        newUsersObject[key] = {
          access: false,
          editor: false,
          approved: false,
          attending: false,
          rejected: true,
          profile: {
            profilePhotoPath:
              usersObject[key].profile.profilePhotoPath,
            fullName: usersObject[key].profile.fullName,
          },
        };
      } else {
        newUsersObject[key] = {
          access: usersObject[key].access,
          editor: usersObject[key].editor,
          approved: usersObject[key].approved,
          attending: usersObject[key].attending,
          profile: {
            profilePhotoPath:
              usersObject[key].profile.profilePhotoPath,
            fullName: usersObject[key].profile.fullName,
          },
        };
      }
    });
    bundle.event.users = newUsersObject;

    this.props.rejectEvent(bundle);
  }

  getPackageThumb(event) {
    let thumbUrl = '';
    if (event.eventSettings.customThumb === false) {
      thumbUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
        event.thumbnail +
        '?alt=media';
    } else {
      thumbUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
        event.thumbnail +
        '?alt=media';
    }
    return thumbUrl;
  }

  getDate(event) {
    const dayText = moment
      .utc(event.scheduledTime.timestamp)
      .format('dddd');
    const dateText = moment
      .utc(event.scheduledTime.timestamp)
      .format('Do');
    const monthText = moment
      .utc(event.scheduledTime.timestamp)
      .format('MMM');

    let time = '';
    if (
      event.scheduledTime.time !== '' &&
      event.scheduledTime.time !== undefined
    ) {
      time = '@ ' + event.scheduledTime.time;
    }

    if (event.status === 11) {
      return (
        <span style={{ fontWeight: '600' }}>
          This event has been canceled
        </span>
      );
    } else if (event.scheduledTime.timestamp !== '') {
      const date = dayText + ' ' + monthText + ' ' + dateText + time;
      return date;
    } else {
      return 'No Date Set';
    }
  }

  getAcceptLink(event, userId, link) {
    if (event.users !== undefined) {
      if (event.users[userId].approved === true) {
        return (
          // <a href={url} className="right marginLeftTen">
          //   <MaterialIcon icon="thumb_up" size={20} color="#00a927" />
          // </a>
          <span className="right approvalStatus">APPROVED!</span>
        );
      } else {
        if (link === true) {
          return (
            <a
              onClick={() => {
                this.approveEvent(event, this.props.profile);
              }}
              className="right marginLeftTen"
            >
              <MaterialIcon
                icon="thumb_up"
                size={20}
                color="#f21d59"
              />
            </a>
          );
        } else {
          if (event.users[userId].rejected !== true) {
            return (
              <span className="right approvalStatus">WAITING</span>
            );
          }
        }
      }
    }
  }

  getRejectLink(event, userId, link) {
    if (event.users !== undefined) {
      const url = '/event/edit/' + userId;
      if (event.users[userId].rejected === true) {
        return <span className="right approvalStatus">REJECTED</span>;
      } else {
        if (event.users[userId].approved === true) {
          if (link === true) {
            return (
              <a
                onClick={() => {
                  this.rejectEvent(event, this.props.profile);
                }}
                className="right marginLeftTen"
              >
                <MaterialIcon
                  icon="thumb_down"
                  size={20}
                  color="#f21d59"
                />
              </a>
            );
          } else {
            // return (
            //   <a href={url} className="right marginLeftTen">
            //     <MaterialIcon icon="thumb_down" size={20} color="#e0e0e0" />
            //   </a>
            // );
          }
        } else {
          if (link === true) {
            return (
              <a
                onClick={() => {
                  this.rejectEvent(event, this.props.profile);
                }}
                className="right marginLeftTen"
              >
                <MaterialIcon
                  icon="thumb_down"
                  size={20}
                  color="#f21d59"
                />
              </a>
            );
          } else {
            // return (
            //   <a href={url} className="right marginLeftTen">
            //     <MaterialIcon icon="thumb_down" size={20} color="#e0e0e0" />
            //   </a>
            // );
          }
        }
      }
    }
  }

  getApprovalMessage(event, userId) {
    if (event.users !== undefined) {
      if (event.editing === true) {
        return (
          <div className="approvalMessage">
            <span>This event is being edited!</span>
          </div>
        );
      } else if (event.status === 3) {
        return (
          <div className="approvalMessage">
            <span>This event has been Rejected.</span>
          </div>
        );
      } else if (event.users[userId].approved === false) {
        return (
          <div className="approvalMessage">
            <span>
              It{"'"}s your move <b>Approve, Reject,</b> or{' '}
              <b>Edit</b>
            </span>
          </div>
        );
      } else {
        if (Object.keys(event.users).length === 2) {
          let firstName = '';
          Object.keys(event.users).forEach((key) => {
            if (key !== userId) {
              firstName = event.users[key].profile.fullName.replace(
                / .*/,
                '',
              );
            }
          });

          return (
            <div className="approvalMessage">
              <span>Just waiting on {firstName}</span>
            </div>
          );
        } else {
          return (
            <div className="approvalMessage">
              <span>Just waiting on them.</span>
            </div>
          );
        }
      }
    }
  }

  getEditLink(id) {
    const url = '/event/edit/' + id + '/1';
    return (
      <Link
        to={{
          pathname: url,
        }}
        className="right marginLeftTen"
      >
        <MaterialIcon icon="edit" size={20} color="#f21d59" />
      </Link>
    );
  }

  getLastEdit(editHistory, profile) {
    const fullName = profile.firstName + '  ' + profile.lastName;
    var latestHistory = editHistory[editHistory.length - 1];
    if (editHistory !== undefined) {
      return (
        <div id="lastEdit">
          <p>Last Edited by {latestHistory.fullName}</p>
        </div>
      );
    } else {
      return (
        <div id="lastEdit">
          <p>Last Edited by {fullName}</p>
        </div>
      );
    }
  }

  getUserStatusBorder(planner) {
    let className = 'unapprovedBorder';
    if (planner.canceled === true) {
      className = 'rejectedBorder';
    } else if (planner.approved === true) {
      className = 'approvedBorder';
    } else if (
      planner.rejected !== undefined &&
      planner.rejected === true
    ) {
      className = 'rejectedBorder';
    }
    return className;
  }

  getUserStatusName(planner) {
    let className = 'unapprovedName';
    if (planner.canceled === true) {
      className = 'rejectedName';
    } else if (planner.approved === true) {
      className = 'approvedName';
    } else if (
      planner.rejected !== undefined &&
      planner.rejected === true
    ) {
      className = 'rejectedName';
    }
    return className;
  }

  getMessage(planners, profile, event) {
    const fullName = profile.firstName + ' ' + profile.lastName;
    const userProfile = profile;
    const plannerArray = [];
    if (planners !== undefined) {
      Object.keys(planners).forEach((key) => {
        if (key !== userProfile.userId) {
          let firstName = planners[key].profile.fullName.replace(
            / .*/,
            '',
          );
          if (planners[key].access === true) {
            plannerArray.push({
              firstName,
              userId: key,
              fullName: planners[key].profile.fullName,
              profilePhotoPath:
                planners[key].profile.profilePhotoPath,
              approved: planners[key].approved,
              rejected: planners[key].rejected,
              canceled: planners[key].canceled,
              finished: planners[key].finished,
            });
          }
        }
      });

      return (
        <div style={{ display: 'block', clear: 'both' }}>
          {event.mode === '0' &&
            this.getApprovalMessage(event, this.props.profile.userId)}
        </div>
      );
    }
  }

  getPlanners(planners, profile, event) {
    const fullName = profile.firstName + ' ' + profile.lastName;
    const userProfile = profile;
    const plannerArray = [];
    if (planners !== undefined) {
      Object.keys(planners).forEach((key) => {
        if (key !== userProfile.userId) {
          let firstName = planners[key].profile.fullName.replace(
            / .*/,
            '',
          );
          if (planners[key].access === true) {
            plannerArray.push({
              firstName,
              userId: key,
              fullName: planners[key].profile.fullName,
              profilePhotoPath:
                planners[key].profile.profilePhotoPath,
              approved: planners[key].approved,
              rejected: planners[key].rejected,
              canceled: planners[key].canceled,
              finished: planners[key].finished,
            });
          }
        }
      });

      return (
        // <div className="planners">
        <div style={{ display: 'block', clear: 'both' }}>
          {/* <Container>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
                {this.getApprovalMessage(event, profile.userId)}
              </Col>
            </Row>
            <Row className="plannerName">
              <Col xs={9} md={9} sm={9} lg={9} className="no-padding">
                <div>
                  <UserAvatar
                    size="30"
                    name={fullName}
                    src={profile.profilePhotoPath}
                  />
                  <span className="left">{fullName}</span>
                </div>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3} className="no-padding">
                <div>
                  {this.getEditLink(event.eventId)}
                  {this.getRejectLink(event, profile.userId, true)}
                  {this.getAcceptLink(event, profile.userId, true)}
                </div>
              </Col>
            </Row>
          </Container> */}
          <span
            style={{
              float: 'left',
              margin: '0px 10px 7px 0px',
              color: 'black',
              fontWeight: '600',
            }}
          >
            Who:
          </span>
          {plannerArray.map((planner, index) => (
            <div
              style={{
                float: 'left',
                margin: '0px 10px 7px 0px',
              }}
            >
              <div className={this.getUserStatusBorder(planner)}>
                <UserAvatar
                  size="25"
                  name={planner.fullName}
                  src={planner.profilePhotoPath}
                />
              </div>
              <span className={this.getUserStatusName(planner)}>
                {planner.firstName}
              </span>
              {planner.canceled && (
                <span style={{ fontWeight: '600' }}>CANCELED</span>
              )}
            </div>
          ))}
        </div>
      );
    }
  }

  recreate(event) {
    let readyLocations = [];

    event.packageDetails.locations.forEach((location) => {
      readyLocations.push({
        type: 0,
        locationId: location.locationId,
        locationTitle: location.locationTitle,
        locationThumb: location.locationThumb,
        locationHero: location.locationHero,
        address: location.address,
      });
    });

    let newEvent = {};
    const usersObject = {};
    // Setup newEvent
    newEvent = {
      connectFirst: false,
      clear: false,
      step: 4,
      recreate: true,
    };
    // Set Origionally Invites
    Object.keys(event.users).forEach((key) => {
      usersObject[key] = {
        access: true,
        approved: false,
        editor: true,
        attending: false,
        rejected: false,
        inviteAction: false,
        newUser: false,
        profile: event.users[key].profile,
      };
    });
    newEvent.connections = usersObject;
    // Create New Custom Event
    let customEvent = {
      clear: false,
      step: 1,
      containsCustom: false,
      newEventRef: null,
      editMode: false,
      event: {
        description: event.description,
        title: event.title,
        status: 0,
        locations: readyLocations,
        creationDetails: {
          plannerId: null,
          plannerPhoto: null,
          plannerFirstName: null,
          plannerLastName: null,
          plannerUserName: null,
          creationDate: null,
        },
        scheduledTime: {
          date: null,
          timestamp: null,
          timeString: null,
        },
        usersAttending: null,
        eventSettings: {
          itinerarySent: false,
          scheduled: false,
        },
        packageDetails: {
          packageId: null,
        },
      },
    };
    newEvent.customEvent = customEvent;
    this.props.getNewEvent(newEvent);
  }

  startEvent(event) {
    this.props.startEvent(event);
  }

  getContent(event, mode) {
    const withNoDigits = event.eventId.replace(/[0-9]/g, '');
    const toggler = '#' + withNoDigits;
    const divId = withNoDigits;
    if (mode === '0') {
      const url = '/event/edit/' + event.eventId;
      return (
        <div>
          <Row id={event.eventId.replace(/[0-9]/g, '')}>
            {/* <Col
              xs={2}
              md={2}
              sm={2}
              lg={2}
              // className="no-padding thumbnailCol"
              className="no-padding"
            >
              <Link
                to={{
                  pathname: '/event/' + event.eventId,
                }}
              >
                <img
                  src={
                    event.thumbnail !== null
                      ? event.thumbnail
                      : 'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2Fcivil_life_stl_thumb.jpg?alt=media'
                  }
                  alt={event.title}
                  className="thumbnail"
                />
              </Link>
            </Col> */}
            <Col
              xs={11}
              md={11}
              sm={11}
              lg={11}
              style={{ padding: '0px 5px 10px 5px' }}
            >
              <Link
                to={{
                  pathname: '/event/' + event.eventId,
                }}
              >
                <div>
                  {this.getPlanners(
                    event.users,
                    this.props.profile,
                    event,
                  )}
                  <div
                    style={{
                      display: 'block',
                      clear: 'both',
                    }}
                  >
                    <span
                      style={{
                        color: 'black',
                        float: 'left',
                        fontWeight: '600',
                        marginRight: '12px',
                      }}
                    >
                      What:
                    </span>
                    {this.getLocationsList(event)}
                  </div>
                  <div
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginTop: '5px',
                      marginBottom: '10px',
                    }}
                  >
                    <span
                      style={{
                        color: 'black',
                        float: 'left',
                        fontWeight: '600',
                        marginRight: '8px',
                      }}
                    >
                      When:{' '}
                    </span>
                    <span
                      style={{
                        color: 'black',
                      }}
                    >
                      {this.getDate(event)}
                    </span>
                  </div>

                  {/* <div>
                    <h2 className="title">{event.title}</h2>

                    <span className="description">
                      {event.description}
                    </span>
                  </div> */}
                </div>
                <div
                  style={{
                    display: 'block',
                    clear: 'both',
                    marginTop: '15px',
                  }}
                >
                  {this.getMessage(
                    event.users,
                    this.props.profile,
                    event,
                  )}
                </div>
              </Link>
            </Col>
            <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
              {/* {this.getEditLink(event.eventId)} */}
              <Link
                to={{
                  pathname: '/event/' + event.eventId,
                }}
              >
                <span className="arrowFix" style={{ float: 'right' }}>
                  <MaterialIcon
                    icon="keyboard_arrow_right"
                    size={30}
                    color="#f21d59"
                  />
                </span>
              </Link>
            </Col>
          </Row>

          {/* <div
            style={{
              display: 'block',
              clear: 'both',
              width: '100%',
              padding: '0px 0px 0px 0px',
              margin: '10px 0px 0px 0px',
              // borderTop: '1px solid rgb(234 234 234)',
              color: '#515151',
              fontWeight: '300',
            }}
          >
            <div
              style={{
                display: 'flex',
                position: 'relative',
                clear: 'both',
                padding: '10px 0px 0px 0px',
              }}
            >
              <Collapse isOpen={this.state[event.eventId]}>
                {this.getPlanners(
                  event.users,
                  this.props.profile,
                  event,
                )}
              </Collapse>
            </div> 
          </div> */}

          {/* <div>
            <UncontrolledCollapse toggler={toggler}>
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  {event.description && (
                    <div>
                      <p>
                        <b>Description</b>
                      </p>
                      <p className="description">
                        {event.description}
                      </p>
                      <br />
                    </div>
                  )}

                  <p>{this.getDate(event)}</p>
                  <br />
                  <p>
                    <b>Current Proposal</b>
                  </p>
                  <p>
                    {this.getLastEdit(
                      event.editHistory,
                      this.props.profile,
                    )}
                  </p>

                  {this.getLocations(event)}
                </Col>
              </Row>
            </UncontrolledCollapse>
          </div> */}
        </div>
      );
    } else if (mode === '1') {
      return (
        <div>
          <a
            onClick={() => {
              this.startEvent(event);
            }}
            className="startButton"
            style={{
              width: '100%',
              textAlign: 'center',
              maxWidth: '100px',
            }}
          >
            <span>Start</span>
          </a>
          <Link
            to={{
              pathname: '/event/' + event.eventId,
            }}
          >
            <div className="eventCard">
              <div style={{ display: 'block' }}>
                <Row>
                  <div
                    style={{
                      display: 'inline-block',
                      // maxWidth: '100px',
                      width: '100%',
                      marginBottom: '13px',
                    }}
                  >
                    <img
                      src={
                        event.heroImage !== null
                          ? event.heroImage
                          : 'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2Fcivil_life_stl_hero.jpg?alt=media'
                      }
                      alt={event.title}
                      style={{
                        minWidth: '100%',
                        border: '1px black',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                    />
                  </div>
                  <Col
                    xs={11}
                    md={11}
                    sm={11}
                    lg={11}
                    style={{ padding: '0px 5px 10px 5px' }}
                  >
                    <div
                      style={{
                        verticalAlign: 'top',
                      }}
                    >
                      <Link
                        to={{
                          pathname: '/event/' + event.eventId,
                        }}
                      >
                        <div>
                          <div>
                            {event.title !== '' && (
                              <h2 className="title">{event.title}</h2>
                            )}

                            {event.description !== '' && (
                              <span
                                tyle={{
                                  fontSize: '15px',
                                  color: 'black',
                                }}
                              >
                                {event.description}
                              </span>
                            )}
                            {/* <p>{this.getDate(event)}</p> */}
                            <div
                              style={{
                                display: 'block',
                                clear: 'both',
                                width: '100%',
                                padding: '0px 0px 0px 0px',
                                margin: '10px 0px 0px 0px',

                                color: '#515151',
                                fontWeight: '300',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  position: 'relative',
                                  clear: 'both',
                                  padding: '10px 0px 0px 0px',
                                }}
                              >
                                {this.getPlanners(
                                  event.users,
                                  this.props.profile,
                                  event,
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'block',
                              clear: 'both',
                            }}
                          >
                            <span
                              style={{
                                color: 'black',
                                float: 'left',
                                fontWeight: '600',
                                marginRight: '12px',
                              }}
                            >
                              What:
                            </span>
                            {this.getLocationsList(event)}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="no-padding"
                  >
                    {/* {this.getEditLink(event.eventId)} */}
                    <Link
                      to={{
                        pathname: '/event/' + event.eventId,
                      }}
                    >
                      <span className="arrowFix right">
                        <MaterialIcon
                          icon="keyboard_arrow_right"
                          size={30}
                          color="#f21d59"
                        />
                      </span>
                    </Link>
                  </Col>
                </Row>
              </div>
            </div>
          </Link>
        </div>
      );
    } else if (mode === '6') {
      return (
        <div className="eventCard">
          <div style={{ display: 'block' }}>
            <Row>
              <Col
                xs={2}
                md={2}
                sm={2}
                lg={2}
                // className="no-padding thumbnailCol"
                className="no-padding"
              >
                <Link
                  to={{
                    pathname: '/event/' + event.eventId,
                  }}
                >
                  <img
                    src={
                      event.thumbnail !== null
                        ? event.thumbnail
                        : 'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2Fcivil_life_stl_thumb.jpg?alt=media'
                    }
                    alt={event.title}
                    className="thumbnail"
                  />
                </Link>
                {/* {(event.status === 9 &&
                  event.eventSettings.approved === true) ||
                  (event.status === 11 && (
                    <a
                      onClick={() => {
                        this.recreate(event);
                      }}
                      className="startButton"
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        maxWidth: '100px',
                      }}
                    >
                      <span>Recreate</span>
                    </a>
                  ))} */}
              </Col>
              <Col
                xs={9}
                md={9}
                sm={9}
                lg={9}
                style={{ padding: '0px 5px 10px 5px' }}
              >
                <Link
                  to={{
                    pathname: '/event/' + event.eventId,
                  }}
                >
                  <div>
                    <div>
                      <h2 className="title">{event.title}</h2>
                      <p className="description">
                        {event.description}
                      </p>
                      <p>{this.getDate(event)}</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
                {/* {this.getEditLink(event.eventId)} */}
                <Link
                  to={{
                    pathname: '/event/' + event.eventId,
                  }}
                >
                  <span className="arrowFix right">
                    <MaterialIcon
                      icon="keyboard_arrow_right"
                      size={30}
                      color="#f21d59"
                    />
                  </span>
                </Link>
              </Col>
            </Row>
          </div>

          <div
            style={{
              display: 'block',
              clear: 'both',
              width: '100%',
              padding: '0px 0px 0px 0px',
              margin: '10px 0px 0px 0px',

              color: '#515151',
              fontWeight: '300',
            }}
          >
            <div
              style={{
                display: 'flex',
                position: 'relative',
                clear: 'both',
                padding: '10px 0px 0px 0px',
              }}
            >
              {this.getPlanners(
                event.users,
                this.props.profile,
                event,
              )}
            </div>
          </div>
        </div>
      );
    } else if (mode === '5') {
      return (
        <div className="eventCard">
          <div style={{ display: 'block' }}>
            <Row>
              <Col
                xs={2}
                md={2}
                sm={2}
                lg={2}
                // className="no-padding thumbnailCol"
                className="no-padding"
              >
                <Link
                  to={{
                    pathname: '/event/' + event.eventId,
                  }}
                >
                  <img
                    src={event.thumbnail}
                    alt={event.title}
                    className="thumbnail"
                  />
                </Link>
                {/* {event.status === 11 && (
                  <a
                    onClick={() => {
                      this.recreate(event);
                    }}
                    className="startButton"
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      maxWidth: '100px',
                    }}
                  >
                    <span>Recreate</span>
                  </a>
                )} */}
              </Col>
              <Col
                xs={9}
                md={9}
                sm={9}
                lg={9}
                style={{ padding: '0px 5px 10px 5px' }}
              >
                <Link
                  to={{
                    pathname: '/event/' + event.eventId,
                  }}
                >
                  <div>
                    <div>
                      <h2 className="title">{event.title}</h2>
                      <p className="description">
                        {event.description}
                      </p>
                      <p>{this.getDate(event)}</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
                {/* {this.getEditLink(event.eventId)} */}
                <Link
                  to={{
                    pathname: '/event/' + event.eventId,
                  }}
                >
                  <span className="arrowFix right">
                    <MaterialIcon
                      icon="keyboard_arrow_right"
                      size={30}
                      color="#f21d59"
                    />
                  </span>
                </Link>
              </Col>
            </Row>
          </div>

          <div
            style={{
              display: 'block',
              clear: 'both',
              width: '100%',
              padding: '0px 0px 0px 0px',
              margin: '10px 0px 0px 0px',

              color: '#515151',
              fontWeight: '300',
            }}
          >
            <div
              style={{
                display: 'flex',
                position: 'relative',
                clear: 'both',
                padding: '10px 0px 0px 0px',
              }}
            >
              {this.getPlanners(
                event.users,
                this.props.profile,
                event,
              )}
            </div>
          </div>
        </div>
      );
    } else if (mode === '3') {
      return (
        <div className="eventCard">
          {/* <Col
            xs={2}
            md={2}
            sm={2}
            lg={2}
            className="no-padding thumbnailCol"
          
          >
           
          </Col> */}
          <Col xs={11} md={11} sm={11} lg={11}>
            <Link
              to={{
                pathname: '/event/' + event.eventId,
              }}
            >
              <img
                src={event.thumbnail}
                alt={event.title}
                className="thumbnail"
              />

              <div>
                <div>
                  <h2 className="title">{event.title}</h2>
                  <p className="description">{event.description}</p>
                  <p>{this.getDate(event)}</p>
                </div>
              </div>
            </Link>
          </Col>
          <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
            {/* {this.getEditLink(event.eventId)} */}
            <Link
              to={{
                pathname: '/event/' + event.eventId,
              }}
            >
              <span className="arrowFix">
                <MaterialIcon
                  icon="keyboard_arrow_right"
                  size={30}
                  color="#f21d59"
                />
              </span>
            </Link>
          </Col>
        </div>
      );
    }
  }

  render() {
    return (
      <ul>
        {this.props.events.map((event) => (
          <div>
            {event.mode === '1' ? (
              <li
                className="inviteCard card"
                style={{ paddingTop: '0px' }}
              >
                {this.getContent(event, event.mode)}
              </li>
            ) : (
              <li className="inviteCard card">
                {this.getContent(event, event.mode)}
              </li>
            )}
          </div>
        ))}
      </ul>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    profile: state.profile.profile,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    approveEvent: (bundle) => {
      dispatch(approveEvent(bundle));
    },
    rejectEvent: (bundle) => {
      dispatch(rejectEvent(bundle));
    },
    startEvent: (event) => {
      dispatch(startEvent(event));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventListItem);
