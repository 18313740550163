import React from 'react';
import { connect } from 'react-redux';
// Components
import AdminUserForm from '../../components/AdminUserForm';
import TitleBar from '../../components/TitleBar';

const query = require('array-query');

export class AdminUsersEdit extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  getUser(userId, users) {
    if (this.props.match.params.id !== undefined) {
      const userDetails = query('userId')
        .is(this.props.match.params.id)
        .on(this.props.users);
      return userDetails[0];
    } else {
      return false;
    }
  }

  render() {
    const user = this.getUser();
    console.log('Auser', user);
    return (
      <div>
        <TitleBar title="Edit/Add User" backlink="/admin/users/" />
        <div className="section">
          <AdminUserForm
            editableUser={user}
            history={this.props.history}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.adminUsers.users,
  };
}

export default connect(mapStateToProps)(AdminUsersEdit);
