import MaterialIcon from 'material-icons-react';
import TimePicker from 'rc-time-picker';
import React from 'react';
import moment from 'moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-modal';
import VendorRewardForm from '../../components/VendorRewardForm';
import SearchInput, { createFilter } from 'react-search-input';
import {
  UncontrolledCollapse,
  Card,
  Button,
  Col,
  Row,
  CardBody,
  Container,
} from 'reactstrap';
import firebase from '../../config/firebase';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import UserAvatar from 'react-user-avatar';
// Reducers
import { getVendorPromos } from '../../sagas/vendorPromoSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

const format = 'h:mm a';
const now = moment().hour(0).minute(0);

const KEYS_TO_FILTERS = [
  'locationTitle',
  'entertainmentTitle',
  'address.city',
  'address.street',
  'tagsBeverages',
  'tagsCuisine',
  'tagsSettings',
  'tagsDateType',
  'tagsGenre',
];

const conversationModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    // minWidth: '75%',
    maxHeight: '75%',
    minWidth: '700px',
    background: '#fafafa',
  },
};

export class VendorCreatePromo extends React.Component {
  constructor() {
    super();
    this.state = {
      otherVendorRewards: [],
      community: false,
      participants: [],
      editHistory: [],
      status: null,
      settings: {},
      searchTerm: '',
      activeLevel: 1,
      participantsMode: 1,
      participantsType: 'destinations',
      criteriaDetailView: false,
      rewardModalVisible: false,
      locationModalVisible: false,
      createNewRewardFormActive: false,
      vendorImagesModalVisible: false,
      vendorImagesType: undefined,
      editRewardFormActive: false,
      selectedLocations: [],
      experienceType: [],
      togglerCa: false,
      togglerCb: true,
      togglerCc: true,
      togglerCd: true,
      togglerCe: true,
      togglerCf: false,
      togglerCg: true,
      togglerCh: true,
      togglerCi: false,
      togglerCj: true,
      togglerCk: true,
      togglerCl: false,
      togglerCm: true,
      togglerCn: true,
      togglerCo: false,
      togglerCp: true,
      togglerCq: true,
      loading: true,
      showPicker: false,
      showHelp: false,
      differentEnd: 'no',
      differentTime: 'no',
      colabOne: 'all',
      colabTwo: 'all',
      colabThree: 'all',
      colabFour: 'all',
      colabFive: 'all',
      colabOneQuantity: 1,
      colabTwoQuantity: 1,
      colabThreeQuantity: 1,
      colabFourQuantity: 1,
      colabFiveQuantity: 1,
      from: undefined,
      to: undefined,
      previewMode: 1,
      title: '',
      description: '',
      promoLocations: [],
      goldenTicketLevel: [],
      goldenTicketDetails: '',
      goldenTicketQuantity: 0,
      levelOne: '',
      levelTwo: '',
      levelThree: '',
      levelFour: '',
      levelFive: '',
      levelSix: '',
      restriction: 0,
      lockedOne: false,
      lockedTwo: false,
      lockedThree: false,
      lockedFour: false,
      lockedFive: false,
      quantityOne: 0,
      quantityTwo: 0,
      quantityThree: 0,
      quantityFour: 0,
      quantityFive: 0,
      rewardDetailsOne: '',
      rewardDetailsTwo: '',
      rewardDetailsThree: '',
      rewardDetailsFour: '',
      rewardDetailsFive: '',
      guidelinesOne: '',
      guidelinesTwo: '',
      guidelinesThree: '',
      guidelinesFour: '',
      guidelinesFive: '',
      rewardTwo: '',
      criteriaTwo: '',
      dcount: 1,
      levelThreeLocations: '',
      dfields: [
        {
          id: 1,
          value: 'select',
        },
      ],
      levelCriteriaCounts: {
        levelOneCriteriaCount: 1,
        levelTwoCriteriaCount: 1,
        levelThreeCriteriaCount: 1,
        levelFourCriteriaCount: 1,
        levelFiveCriteriaCount: 1,
      },
      levelOneCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelTwoCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelThreeCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelFourCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelFiveCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      mode: 1,
      events: [],
      checked: false,
      crop: {
        unit: '%',
        width: 30,
        aspect: 800 / 259,
      },
      cropThumb: {
        unit: '%',
        width: 30,
        aspect: 146 / 122,
      },
      cropping: false,
      croppingThumb: false,
      thumbPath: '',
      heroPath: '',
      vendorImages: [],
    };
    this.handleStartDayClick = this.handleStartDayClick.bind(this);
    this.handleEndDayClick = this.handleEndDayClick.bind(this);
    this.openRewardModal = this.openRewardModal.bind(this);
    this.closeRewardModal = this.closeRewardModal.bind(this);
    this.openLocationModal = this.openLocationModal.bind(this);
    this.closeLocationModal = this.closeLocationModal.bind(this);
    this.setCreateNewRewardFormActive =
      this.setCreateNewRewardFormActive.bind(this);
    this.setEditRewardFormActive =
      this.setEditRewardFormActive.bind(this);
    this.divElement = React.createRef();
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  componentDidMount() {
    this.getVendorImages();
    let events = [];
    let rewards = [];
    let locations = [];

    this.props.vendorEvents.forEach((event, i) => {
      events.push({
        value: event.id,
        label: event.title,
      });
    });

    this.props.vendorRewards.forEach((event, i) => {
      rewards.push({
        value: event.id,
        label: event.title,
      });
    });
    this.props.vendor.locations.forEach((location, i) => {
      const locationDetails = query('locationId')
        .is(location)
        .on(this.props.locations);
      if (locationDetails[0] !== undefined) {
        locations.push({
          value: location,
          label: locationDetails[0].locationTitle,
        });
      }
      if (locationDetails[0] === undefined) {
        const locationDetails = query('locationId')
          .is(location)
          .on(this.props.demo.demoLocations);
        if (locationDetails[0] !== undefined) {
          locations.push({
            value: location,
            label: locationDetails[0].locationTitle,
          });
        }
      }
    });

    // Get New Event ID to use later
    const db = firebase.firestore();
    const newEventRef = db.collection('promos').doc();
    const dbref = newEventRef.id;
    this.setState({
      dbref: dbref,
    });

    if (this.props.vendorRewards.length === 0) {
      this.setState({
        locationOptions: locations,
        eventOptions: events,
        rewardsOptions: rewards,
        loading: false,
        rewardModalVisible: true,
      });
    }

    // const uuid = uuidv4();
    this.setState({
      uniqueStorageFolderID: 'he765e7',
    });

    this.setState({
      locationOptions: locations,
      eventOptions: events,
      rewardsOptions: rewards,
      loading: false,
    });
  }

  // handleUploadStartThumb = () =>
  //   this.setState({ isUploading: true, progress: 0 });
  // handleProgressThumb = progress => this.setState({ progress });
  // handleUploadErrorThumb = error => {
  //   this.setState({ isUploading: false });
  //   console.error(error);
  // };
  // handleUploadSuccessThumb = filename => {
  //   const uploadUrl = "test/" + this.props.profile.vendorAccount.id;
  //   this.setState({ hero: filename, progress: 100, isUploading: false });
  //   firebase
  //     .storage()
  //     .ref(uploadUrl)
  //     .child(filename)
  //     .getDownloadURL()
  //     .then(url => {
  //       console.log("returned URL", url);
  //       this.setState({ thumbPath: url });
  //     })
  //     .then(() => {
  //       let updatedPackageBundle = this.props.packageBundle;
  //       updatedPackageBundle.package.thumbnail = filename;
  //       this.props.updatePackage(updatedPackageBundle);
  //     });
  // };

  // Dependecy Fields
  change = (selectName, selectedOption) => {
    let newFields = this.state.dfields;
    let index = selectName - 1;
    newFields[index].value = selectedOption.target.value;
    this.setState({ dfields: newFields });
  };

  updateDimensions = (state) => {
    let height = this.divElement.current.clientHeight;
    if (state === false) {
      height = this.divElement.current.clientHeight + 400;
    } else {
      height = this.divElement.current.clientHeight - 400;
    }
    this.setState({ height });
  };

  getDependecyField(dfield) {
    let options = [{ value: 'none', name: 'none' }];
    let promoCount = this.props.vendorRewards.length;
    let matchCount = 0;
    this.props.vendorRewards.forEach((promo, i) => {
      let match = false;
      this.state.dfields.forEach((dfieldx, i) => {
        if (
          dfieldx.value === promo.title &&
          dfield.value !== promo.title
        ) {
          match = true;
          matchCount = matchCount + 1;
        }
      });
      if (match === false) {
        options.push({
          value: promo.title,
          name: promo.title,
        });
      }
    });
    if (matchCount !== promoCount) {
      return (
        <select
          name={dfield.id}
          value={dfield.value}
          onChange={(e) => this.change(dfield.id, e)}
        >
          {options.map((e, key) => {
            return (
              <option key={key} value={e.value}>
                {e.name}
              </option>
            );
          })}
        </select>
      );
    }
  }

  getDirectory() {
    let vendors = [];
    this.props.vendors.forEach((vendor) => {
      let status = vendor.vendorTypes.includes(1);
      if (status === true) {
        vendors.push(vendor);
      }
    });
    let locations = this.getCommunityVendorLocations(vendors);

    const filteredLocations = locations.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );

    return (
      <div
        className="section"
        style={{ top: '35px', position: 'relative' }}
      >
        <div className="fullwidth">
          <div style={{ clear: 'both', display: 'inline-block' }}>
            <div
              style={{
                marginBottom: '10px',
                float: 'left',
                width: '400px',
              }}
            >
              <SearchInput
                className="search-input"
                onChange={this.searchUpdated}
                style={{ float: 'left' }}
              />
            </div>

            <div
              style={{ float: 'left', margin: '10px 0px 0px 10px' }}
            >
              <label
                style={{
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                <input
                  style={{
                    marginRight: '5px',
                    float: 'left',
                    minWidth: 'unset',
                    position: 'relative',
                    top: '5px',
                  }}
                  type="radio"
                  value="destinations"
                  checked={
                    this.state.participantsType === 'destinations'
                  }
                  onChange={this.participantsTypeChange.bind(this)}
                />
                Destinations
              </label>

              <label
                style={{
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                <input
                  style={{
                    marginRight: '5px',
                    float: 'left',
                    minWidth: 'unset',
                    position: 'relative',
                    top: '5px',
                  }}
                  type="radio"
                  value="entertainment"
                  checked={
                    this.state.participantsType === 'entertainment'
                  }
                  onChange={this.participantsTypeChange.bind(this)}
                />
                Entertainment
              </label>
            </div>
          </div>
          <ul className="no-padding no-margin">
            {filteredLocations.map((location, index) => (
              <li
                className={this.getLocationStatus(location)}
                key={'key' + index}
                onClick={(setLocation) => {
                  this.setParticipant(location, 1);
                }}
              >
                <div className="fullwidth">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    key={'key' + index}
                    className="no-padding"
                  >
                    <Row>
                      <Col
                        xs={4}
                        md={4}
                        sm={4}
                        lg={4}
                        className="no-padding thumbnailCol"
                      >
                        {this.getImage(
                          'thumb',
                          location,
                          this.props.locations,
                        )}
                      </Col>
                      <Col xs={8} md={8} sm={8} lg={8}>
                        <span className="location-title">
                          {location.locationTitle}
                        </span>
                        <span className="address">
                          {location.address.street}
                        </span>
                        <span className="address">
                          {location.address.city}{' '}
                          {location.address.state},{' '}
                          {location.address.zip}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  getEntertainmentDirectory() {
    let vendors = [];
    this.props.vendors.forEach((vendor) => {
      let status = vendor.vendorTypes.includes(2);
      if (status === true) {
        vendors.push(vendor);
      }
    });
    let locations = this.getCommunityVendorLocations(vendors);

    const filteredLocations = locations.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );

    return (
      <div
        className="section"
        style={{ top: '35px', position: 'relative' }}
      >
        <div className="fullwidth">
          <div
            style={{
              marginBottom: '10px',
              float: 'left',
              width: '400px',
            }}
          >
            <SearchInput
              className="search-input"
              onChange={this.searchUpdated}
              style={{ float: 'left' }}
            />
          </div>

          <div style={{ float: 'left', margin: '10px 0px 0px 10px' }}>
            <label
              style={{
                float: 'left',
                marginRight: '10px',
              }}
            >
              <input
                style={{
                  marginRight: '5px',
                  float: 'left',
                  minWidth: 'unset',
                  position: 'relative',
                  top: '5px',
                }}
                type="radio"
                value="destinations"
                checked={
                  this.state.participantsType === 'destinations'
                }
                onChange={this.participantsTypeChange.bind(this)}
              />
              Destinations
            </label>

            <label
              style={{
                float: 'left',
                marginRight: '10px',
              }}
            >
              <input
                style={{
                  marginRight: '5px',
                  float: 'left',
                  minWidth: 'unset',
                  position: 'relative',
                  top: '5px',
                }}
                type="radio"
                value="entertainment"
                checked={
                  this.state.participantsType === 'entertainment'
                }
                onChange={this.participantsTypeChange.bind(this)}
              />
              Entertainment
            </label>
          </div>
        </div>

        <ul className="no-padding no-margin">
          {filteredLocations.map((location, index) => (
            <li
              className={this.getLocationStatus(location)}
              key={'key' + index}
              onClick={(setLocation) => {
                this.setParticipant(location, 1);
              }}
            >
              <div className="fullwidth">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  key={'key' + index}
                  className="no-padding"
                >
                  <Row>
                    <Col
                      xs={4}
                      md={4}
                      sm={4}
                      lg={4}
                      className="no-padding thumbnailCol"
                    >
                      {this.getImage(
                        'thumb',
                        location,
                        this.props.locations,
                      )}
                    </Col>
                    <Col xs={8} md={8} sm={8} lg={8}>
                      <span className="location-title">
                        {location.locationTitle}
                      </span>
                      <span className="address">
                        {location.address.street}
                      </span>
                      <span className="address">
                        {location.address.city}{' '}
                        {location.address.state},{' '}
                        {location.address.zip}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getCommunityLocations() {
    if (this.state.participantsType === 'destinations') {
      return <div>{this.getDirectory()}</div>;
    } else {
      return <div>{this.getEntertainmentDirectory()}</div>;
    }
  }

  getCommunityVendorLocations(vendors) {
    let locations = [];
    vendors.forEach((vendor) => {
      if (this.props.vendor.vendorId !== vendor.id) {
        vendor.locations.forEach((locationId) => {
          const locationDetails = query('locationId')
            .is(locationId)
            .on(this.props.locations);
          if (locationDetails[0] !== undefined) {
            locationDetails[0].vendorName = vendor.businessName;
            locationDetails[0].phone = vendor.primaryContact.phone;
            locationDetails[0].vendorId = vendor.id;
            locations.push(locationDetails[0]);
          }
        });
      }
    });
    return locations;
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <p className="mainTagClear" style={{ margin: '0px' }}>
          {location.tagsMain}
        </p>
      );
    }
  }

  getParticipantsTab(tabMode, tabLable, mode) {
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setParticipantsMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tabLable}
          </div>
        </a>
      );
    }
  }

  getAddButton() {
    let promoCount = this.props.vendorPromos.length;
    let matchCount = 0;
    this.props.vendorPromos.forEach((promo, i) => {
      let match = false;
      this.state.dfields.forEach((dfieldx, i) => {
        if (dfieldx.value === promo.title) {
          match = true;
          matchCount = matchCount + 1;
        }
      });
    });
    if (matchCount !== promoCount) {
      return (
        <span
          onClick={() => {
            this.addField(this.state.dcount);
          }}
        >
          add
        </span>
      );
    }
  }

  addField(dcount) {
    let newCount = dcount + 1;
    let newFields = this.state.dfields;
    newFields.push({
      id: newCount,
      value: '',
    });
    this.setState({ dfields: newFields, dcount: newCount });
  }

  // End

  // Reward 1
  rewardDetailsOneOnChange(e) {
    this.setState({ rewardDetailsOne: e.target.value });
  }
  rewardDetailsTwoOnChange(e) {
    this.setState({ rewardDetailsTwo: e.target.value });
  }
  rewardDetailsThreeOnChange(e) {
    this.setState({ rewardDetailsThree: e.target.value });
  }
  rewardDetailsFourOnChange(e) {
    this.setState({ rewardDetailsFour: e.target.value });
  }
  rewardDetailsFiveOnChange(e) {
    this.setState({ rewardDetailsFive: e.target.value });
  }

  // Locked
  lockedOneChange(e) {
    if (this.state.lockedOne === true) {
      this.setState({ lockedOne: false });
    } else {
      this.setState({ lockedOne: true });
    }
  }
  lockedTwoChange(e) {
    if (this.state.lockedTwo === true) {
      this.setState({ lockedTwo: false });
    } else {
      this.setState({ lockedTwo: true });
    }
  }
  lockedThreeChange(e) {
    if (this.state.lockedThree === true) {
      this.setState({ lockedThree: false });
    } else {
      this.setState({ lockedThree: true });
    }
  }
  lockedFourChange(e) {
    if (this.state.lockedFour === true) {
      this.setState({ lockedFour: false });
    } else {
      this.setState({ lockedFour: true });
    }
  }
  lockedFiveChange(e) {
    if (this.state.lockedFive === true) {
      this.setState({ lockedFive: false });
    } else {
      this.setState({ lockedFive: true });
    }
  }

  // Reward Quantity
  quantityOneOnChange(e) {
    let num = Number(e.target.value);
    this.setState({ quantityOne: num });
  }
  quantityTwoOnChange(e) {
    let num = Number(e.target.value);
    this.setState({ quantityTwo: num });
  }
  quantityThreeOnChange(e) {
    let num = Number(e.target.value);
    this.setState({ quantityThree: num });
  }
  quantityFourOnChange(e) {
    let num = Number(e.target.value);
    this.setState({ quantityFour: num });
  }
  quantityFiveOnChange(e) {
    let num = Number(e.target.value);
    this.setState({ quantityFive: num });
  }

  titleOnChange(e) {
    this.setState({ title: e.target.value });
  }
  descriptionOnChange(e) {
    this.setState({ description: e.target.value });
  }
  restrictionChange(e) {
    this.setState({ restriction: e.target.value });
  }
  handleOnChange = (value) => {
    this.setState({ events: value });
  };

  // Level Handlers
  handleOnChangeLevelOne = (value) => {
    if (this.state.rewardDetailsOne === '') {
      this.setState({ rewardDetailsOne: 'hello' });
    }
    this.setState({ levelOne: value, activeLevel: 1 });
  };
  handleOnChangeLevelTwo = (value) => {
    this.setState({ levelTwo: value, activeLevel: 2 });
  };
  handleOnChangeLevelThree = (value) => {
    this.setState({ levelThree: value, activeLevel: 3 });
  };
  handleOnChangeLevelFour = (value) => {
    this.setState({ levelFour: value, activeLevel: 4 });
  };
  handleOnChangeLevelFive = (value) => {
    this.setState({ levelFive: value, activeLevel: 5 });
  };
  handleOnChangeLevelSix = (value) => {
    this.setState({ levelSix: value, activeLevel: 6 });
  };

  handleOnChangeGoldenLevel = (value) => {
    this.setState({ goldenTicketLevel: value });
  };

  goldenTicketDetailsOnChange = (e) => {
    this.setState({ goldenTicketDetails: e.target.value });
  };

  goldenTicketQuantityOnChange = (e) => {
    let num = Number(e.target.value);
    this.setState({ goldenTicketQuantity: num });
  };

  // Critera Handlers
  criteriaTextHandler = (e) => {
    let newCriteria = [];
    if (e.target.id === 'one') {
      newCriteria = this.state.levelOneCriteria;
    } else if (e.target.id === 'two') {
      newCriteria = this.state.levelTwoCriteria;
    } else if (e.target.id === 'three') {
      newCriteria = this.state.levelThreeCriteria;
    } else if (e.target.id === 'four') {
      newCriteria = this.state.levelFourCriteria;
    } else if (e.target.id === 'five') {
      newCriteria = this.state.levelFiveCriteria;
    }
    newCriteria[e.target.name].text = e.target.value;
    if (e.target.id === 'one') {
      this.setState({ levelOneCriteria: newCriteria });
    } else if (e.target.id === 'two') {
      this.setState({ levelTwoCriteria: newCriteria });
    } else if (e.target.id === 'three') {
      this.setState({ levelThreeCriteria: newCriteria });
    } else if (e.target.id === 'four') {
      this.setState({ levelFourCriteria: newCriteria });
    } else if (e.target.id === 'five') {
      this.setState({ levelFiveCriteria: newCriteria });
    }
  };

  // criteria Handlers
  criteriaLocationsHandler = (values, index, id) => {
    let newLocations = '';
    if (id === 'one') {
      newLocations = this.state.levelOneCriteria;
    } else if (id === 'two') {
      newLocations = this.state.levelTwoCriteria;
    } else if (id === 'three') {
      newLocations = this.state.levelThreeCriteria;
    } else if (id === 'four') {
      newLocations = this.state.levelFourCriteria;
    } else if (id === 'five') {
      newLocations = this.state.levelFiveCriteria;
    }
    newLocations[index].locations = values;
    if (id === 'one') {
      this.setState({ levelOneCriteria: newLocations });
    } else if (id === 'two') {
      this.setState({ levelTwoCriteria: newLocations });
    } else if (id === 'three') {
      this.setState({ levelThreeCriteria: newLocations });
    } else if (id === 'four') {
      this.setState({ levelFourCriteria: newLocations });
    } else if (id === 'five') {
      this.setState({ levelFiveCriteria: newLocations });
    }
  };

  criteriaGuidelinesHandler = (e) => {
    let newGuidelines = [];
    if (e.target.id === 'one') {
      newGuidelines = this.state.levelOneCriteria;
    } else if (e.target.id === 'two') {
      newGuidelines = this.state.levelTwoCriteria;
    } else if (e.target.id === 'three') {
      newGuidelines = this.state.levelThreeCriteria;
    } else if (e.target.id === 'four') {
      newGuidelines = this.state.levelFourCriteria;
    } else if (e.target.id === 'five') {
      newGuidelines = this.state.levelFiveCriteria;
    }
    newGuidelines[e.target.name].guidelines = e.target.value;
    if (e.target.id === 'one') {
      this.setState({ levelOneCriteria: newGuidelines });
    } else if (e.target.id === 'two') {
      this.setState({ levelTwoCriteria: newGuidelines });
    } else if (e.target.id === 'three') {
      this.setState({ levelThreeCriteria: newGuidelines });
    } else if (e.target.id === 'four') {
      this.setState({ levelFourCriteria: newGuidelines });
    } else if (e.target.id === 'five') {
      this.setState({ levelFiveCriteria: newGuidelines });
    }
  };
  criteriaNumTimesHandler = (e) => {
    let newNumTimes = '';
    if (e.target.id === 'one') {
      newNumTimes = this.state.levelOneCriteria;
    } else if (e.target.id === 'two') {
      newNumTimes = this.state.levelTwoCriteria;
    } else if (e.target.id === 'three') {
      newNumTimes = this.state.levelThreeCriteria;
    } else if (e.target.id === 'four') {
      newNumTimes = this.state.levelFourCriteria;
    } else if (e.target.id === 'five') {
      newNumTimes = this.state.levelFiveCriteria;
    }
    const count = Number(e.target.value);
    newNumTimes[e.target.name].numTimes = count;
    if (e.target.id === 'one') {
      this.setState({ levelOneCriteria: newNumTimes });
    } else if (e.target.id === 'two') {
      this.setState({ levelTwoCriteria: newNumTimes });
    } else if (e.target.id === 'three') {
      this.setState({ levelThreeCriteria: newNumTimes });
    } else if (e.target.id === 'four') {
      this.setState({ levelFourCriteria: newNumTimes });
    } else if (e.target.id === 'five') {
      this.setState({ levelFiveCriteria: newNumTimes });
    }
  };

  criteriaOncePerDayHandler = (e) => {
    let newNumTimes = '';
    if (e.target.id === 'one') {
      newNumTimes = this.state.levelOneCriteria;
    } else if (e.target.id === 'two') {
      newNumTimes = this.state.levelTwoCriteria;
    } else if (e.target.id === 'three') {
      newNumTimes = this.state.levelThreeCriteria;
    } else if (e.target.id === 'four') {
      newNumTimes = this.state.levelFourCriteria;
    } else if (e.target.id === 'five') {
      newNumTimes = this.state.levelFiveCriteria;
    }
    if (newNumTimes[e.target.name].oncePerDay === true) {
      newNumTimes[e.target.name].oncePerDay = false;
    } else {
      newNumTimes[e.target.name].oncePerDay = true;
    }
    if (e.target.id === 'one') {
      this.setState({ levelOneCriteria: newNumTimes });
    } else if (e.target.id === 'two') {
      this.setState({ levelTwoCriteria: newNumTimes });
    } else if (e.target.id === 'three') {
      this.setState({ levelThreeCriteria: newNumTimes });
    } else if (e.target.id === 'four') {
      this.setState({ levelFourCriteria: newNumTimes });
    } else if (e.target.id === 'five') {
      this.setState({ levelFiveCriteria: newNumTimes });
    }
  };

  activeOnChange() {
    if (this.state.checked === true) {
      this.setState({ checked: false });
    } else {
      this.setState({ checked: true });
    }
  }

  displayDetailsForm() {
    // const uploadUrl = "test/" + this.props.profile.vendorAccount.id;
    let options = [
      { value: '0', name: 'No Restrictions' },
      { value: '1', name: 'Limit 1 Per User' },
    ];
    return (
      // <div id="eventDetailsForm">
      <div>
        <div>
          <div>
            <Container>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12} className="left">
                  <div className="wizard-section-ten">
                    <span className="inputLabel">
                      Experience Title
                    </span>
                    <input
                      type="text"
                      onChange={this.titleOnChange.bind(this)}
                      value={this.state.title}
                      className="full-width"
                    />
                    <span className="inputLabel">
                      Experience Description
                    </span>
                    <textarea
                      type="text"
                      onChange={this.descriptionOnChange.bind(this)}
                      value={this.state.description}
                      className="full-width"
                    />
                  </div>
                  <div className="wizard-section-ten">
                    {/* <input
                      type="checkbox"
                        // name="active"
                      checked={this.state.checked}
                      onChange={this.activeOnChange.bind(this)}
                    />
                    <label for="active" className="activeBtn">
                      Active
                    </label> */}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="left">
                  {/* <div className="wizard-section-ten">
                    <span className="inputLabel">
                      Connected Events
                    </span>
                    <Select
                      value={this.state.events}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(value) => {
                        this.handleOnChange(value);
                      }}
                      options={this.state.eventOptions}
                      isMulti={true}
                    />
                  </div> */}
                  <span className="inputLabel">
                    Participating Destinations & Entertainment
                  </span>

                  <div className="clear">
                    <div>
                      <div className="fullwidth">
                        <ul className="no-padding no-margin">
                          {this.state.selectedLocations.map(
                            (location, index) => (
                              <li
                                className="adminlistitem"
                                key={location.locationId}
                              >
                                <div className="fullwidth">
                                  <Col
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={12}
                                    key={'key' + index}
                                    className="no-padding"
                                  >
                                    <Row>
                                      <Col
                                        xs={4}
                                        md={4}
                                        sm={4}
                                        lg={4}
                                        className="no-padding thumbnailCol"
                                      >
                                        {this.getImage(
                                          'thumb',
                                          location,
                                          this.props.locations,
                                        )}
                                      </Col>
                                      <Col
                                        xs={7}
                                        md={7}
                                        sm={7}
                                        lg={7}
                                      >
                                        <span className="location-title">
                                          {location.title}
                                        </span>
                                        <span className="address">
                                          {location.address.street}
                                        </span>
                                        <span className="address">
                                          {location.address.city}{' '}
                                          {location.address.state},{' '}
                                          {location.address.zip}
                                        </span>
                                      </Col>
                                      <Col
                                        xs={1}
                                        md={1}
                                        sm={1}
                                        lg={1}
                                        className="no-padding"
                                      >
                                        <span className="right">
                                          <MaterialIcon
                                            icon="delete"
                                            size={25}
                                            onClick={(
                                              setLocation,
                                            ) => {
                                              this.removeParticipant(
                                                location.id,
                                              );
                                            }}
                                          />
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                </div>
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="clear"
                    style={{ height: '60px', marginBottom: '20px' }}
                  >
                    <a
                      onClick={() => {
                        this.openLocationModal();
                      }}
                    >
                      <span className="left addLocationButton">
                        <MaterialIcon
                          icon="add"
                          size={35}
                          color="white"
                        />
                      </span>
                    </a>
                  </div>
                  {this.state.community === true && (
                    <div
                      className="clear"
                      style={{
                        padding: '10px',
                        border: '1px solid grey',
                        background: 'white',
                      }}
                    >
                      <h6>Community Experience</h6>
                      <span>
                        This experience includes a Destination or
                        Entertainer from the Community. After you save
                        this event they will be sent an invite and
                        will need to Approve this Experience before it
                        an go live.
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
            {/* <Container id="imageUpload">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="left">
                  <span className="inputLabel">Image</span>
                  {this.state.isUploading && (
                    <p>Progress: {this.state.progressThumb}</p>
                  )}
                  {this.state.heroPath && (
                    <img src={this.state.thumbPath} className="heroInputImage" />
                  )}
                  <FileUploader
                    accept="image/*"
                    name="thumb"
                    randomizeFilename
                    storageRef={firebase.storage().ref(uploadUrl)}
                    onUploadStart={this.handleUploadStartThumb}
                    onUploadError={this.handleUploadErrorThumb}
                    onUploadSuccess={this.handleUploadSuccessThumb}
                    onProgress={this.handleProgressThumb}
                    className="imageUpload"
                  />
                  <ErrorMessage name="locationThumb" component="div" />
                </Col>
              </Row>
            </Container> */}
          </div>
        </div>
      </div>
    );
  }

  getEditCriteriaButton(activeLevel, levelState, level) {
    let xx = level - 1;
    if (levelState !== '') {
      if (activeLevel === level) {
        return (
          <span className="editCriteriaActive" key={xx}>
            <MaterialIcon icon="edit" size={25} color="#444444" />
          </span>
        );
      } else {
        return (
          <span
            className="editCriteria"
            onClick={() => {
              this.setLevel(level);
            }}
            key={level}
          >
            <MaterialIcon icon="edit" size={26} color="#ee0158" />
          </span>
        );
      }
    }
  }

  getEditRewardButton(levelState) {
    if (levelState !== '') {
      return <MaterialIcon icon="edit" size={25} color="#ee0158" />;
    }
  }

  setLevel(level) {
    this.setState({ activeLevel: level });
  }

  // getCriteria(currentCriteria, criteriaCount) {
  //   if (
  //     criteriaCount === currentCriteria ||
  //     criteriaCount > currentCriteria
  //   ) {
  //     return <span></span>;
  //   }
  // }

  addCriteriaCount(level) {
    let newCriteriaCounts = this.state.levelCriteriaCounts;
    if (level === 1) {
      newCriteriaCounts.levelOneCriteriaCount =
        newCriteriaCounts.levelOneCriteriaCount + 1;
    } else if (level === 2) {
      newCriteriaCounts.levelTwoCriteriaCount =
        newCriteriaCounts.levelTwoCriteriaCount + 1;
    } else if (level === 3) {
      newCriteriaCounts.levelThreeCriteriaCount =
        newCriteriaCounts.levelThreeCriteriaCount + 1;
    } else if (level === 4) {
      newCriteriaCounts.levelFourCriteriaCount =
        newCriteriaCounts.levelFourCriteriaCount + 1;
    } else if (level === 5) {
      newCriteriaCounts.levelFiveCriteriaCount =
        newCriteriaCounts.levelFiveCriteriaCount + 1;
    }
    this.setState({ levelCriteriaCounts: newCriteriaCounts });
  }

  flip(e) {
    let state = e.target.id;
    let newX = false;
    if (state === 'togglerCa') {
      let current = this.state.togglerCa;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCa: newX });
    } else if (state === 'togglerCb') {
      let current = this.state.togglerCb;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCb: newX });
    }
    if (state === 'togglerCc') {
      let current = this.state.togglerCc;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCc: newX });
    }
    if (state === 'togglerCd') {
      let current = this.state.togglerCd;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCd: newX });
    }
    if (state === 'togglerCe') {
      let current = this.state.togglerCe;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCe: newX });
    }

    if (state === 'togglerCf') {
      let current = this.state.togglerCf;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCf: newX });
    }
    if (state === 'togglerCg') {
      let current = this.state.togglerCg;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCg: newX });
    }
    if (state === 'togglerCh') {
      let current = this.state.togglerCh;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCh: newX });
    }
    if (state === 'togglerCi') {
      let current = this.state.togglerCi;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCi: newX });
    }
    if (state === 'togglerCj') {
      let current = this.state.togglerCj;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCj: newX });
    }

    if (state === 'togglerCk') {
      let current = this.state.togglerCk;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCk: newX });
    }
    if (state === 'togglerCl') {
      let current = this.state.togglerCl;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCl: newX });
    }
    if (state === 'togglerCm') {
      let current = this.state.togglerCm;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCm: newX });
    }
    if (state === 'togglerCn') {
      let current = this.state.togglerCn;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCn: newX });
    }
    if (state === 'togglerCo') {
      let current = this.state.togglerCo;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCo: newX });
    }
    if (state === 'togglerCp') {
      let current = this.state.togglerCp;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCp: newX });
    }
    if (state === 'togglerCq') {
      let current = this.state.togglerCq;
      if (current === false) {
        newX = true;
      }
      this.setState({ togglerCq: newX });
    }

    this.updateDimensions(newX);
  }

  getLevelDetails(activeLevel, levelCriteriaCounts, locations) {
    if (activeLevel === 1) {
      return (
        <div className="wizard-section-ten">
          <h5>
            Level 1 Reward Setup -{' '}
            <span style={{ color: '#f21d59', fontSize: '19px' }}>
              {this.state.levelOne.label}
            </span>
          </h5>
          <div className="rewardCreateBox">
            <span className="inputLabel">Reward Description</span>
            <textarea
              type="text"
              onChange={this.rewardDetailsOneOnChange.bind(this)}
              value={this.state.rewardDetailsOne}
              className="full-width"
              name="detailsOne"
            />
            <span className="inputLabel">
              How many of this reward are available?
            </span>
            <input
              type="number"
              onChange={this.quantityOneOnChange.bind(this)}
              value={this.state.quantityOne}
              maxLength="4"
              size="4"
            />
            {this.state.community === true && (
              <div className="wizard-section-ten">
                <div style={{ marginTop: '10px' }}>
                  <span className="title">
                    Collaboration Settings
                  </span>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <label style={{ display: 'block', clear: 'both' }}>
                    <input
                      style={{ marginRight: '5px' }}
                      type="radio"
                      value="all"
                      checked={this.state.colabOne === 'all'}
                      onChange={this.colabOneChange.bind(this)}
                    />
                    User earns all of the Level 1 Rewards.
                  </label>

                  <label style={{ display: 'block', clear: 'both' }}>
                    <input
                      style={{ marginRight: '5px' }}
                      type="radio"
                      value="choose"
                      checked={this.state.colabOne === 'choose'}
                      onChange={this.colabOneChange.bind(this)}
                    />
                    User must choose which Level 1 Rewards they want.
                  </label>
                  {this.state.colabOne === 'choose' && (
                    <div>
                      <span className="inputLabel">
                        How many can they choose?
                      </span>
                      <input
                        type="number"
                        onChange={this.colabOneQuantityChange.bind(
                          this,
                        )}
                        value={this.state.colabOneQuantity}
                        maxLength="4"
                        size="4"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <hr></hr>
            {levelCriteriaCounts.levelOneCriteriaCount > 0 && (
              <div>
                <div
                  id="togglerCa"
                  className={
                    this.state.togglerCa === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 1
                </div>
                <UncontrolledCollapse
                  toggler="#togglerCa"
                  defaultOpen={true}
                >
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelOneCriteria[0].text}
                      name={0}
                      id="one"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelOneCriteria[0].numTimes}
                      maxLength="4"
                      size="4"
                      name={0}
                      id="one"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={this.state.levelOneCriteria[0].locations}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 0, 'one')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelOneCriteria[0].guidelines
                      }
                      className="full-width"
                      name={0}
                      id="one"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelOneCriteria[0].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={0}
                        id="one"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelOneCriteriaCount > 1 && (
              <div>
                <div
                  id="togglerCb"
                  className={
                    this.state.togglerCb === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 2
                </div>
                <UncontrolledCollapse toggler="#togglerCb">
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelOneCriteria[1].text}
                      name={1}
                      id="one"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelOneCriteria[1].numTimes}
                      maxLength="4"
                      size="4"
                      name={1}
                      id="one"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={this.state.levelOneCriteria[1].locations}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 1, 'one')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelOneCriteria[1].guidelines
                      }
                      className="full-width"
                      name={1}
                      id="one"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        //   // name="active"
                        checked={
                          this.state.levelOneCriteria[1].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={1}
                        id="one"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelOneCriteriaCount > 2 && (
              <div>
                <div
                  id="togglerCc"
                  className={
                    this.state.togglerCc === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 3
                </div>
                <UncontrolledCollapse toggler="#togglerCc">
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelOneCriteria[2].text}
                      name={2}
                      id="one"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelOneCriteria[2].numTimes}
                      maxLength="4"
                      size="4"
                      name={2}
                      id="one"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={this.state.levelOneCriteria[2].locations}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 2, 'one')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelOneCriteria[2].guidelines
                      }
                      className="full-width"
                      name={2}
                      id="one"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelOneCriteria[2].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={2}
                        id="one"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelOneCriteriaCount < 3 && (
              <a
                onClick={() => {
                  this.addCriteriaCount(1);
                }}
                style={{
                  margin: '10px 0px 10px 0px',
                  display: 'block',
                }}
              >
                <span style={{ position: 'relative', top: '6px' }}>
                  <MaterialIcon
                    icon="add_box"
                    size={25}
                    color="rgb(238 1 88)"
                  />
                </span>{' '}
                Add Additional Criteria
              </a>
            )}
          </div>
        </div>
      );
    } else if (activeLevel === 2) {
      return (
        <div className="wizard-section-ten">
          <h5>
            Level 2 Reward Setup -{' '}
            <span style={{ color: '#f21d59', fontSize: '19px' }}>
              {this.state.levelTwo.label}
            </span>
          </h5>
          <div className="rewardCreateBox">
            <span className="inputLabel">Reward Description</span>
            <textarea
              type="text"
              onChange={this.rewardDetailsTwoOnChange.bind(this)}
              value={this.state.rewardDetailsTwo}
              className="full-width"
            />
            <span className="inputLabel">
              How many of this reward are available?
            </span>
            <input
              type="number"
              onChange={this.quantityTwoOnChange.bind(this)}
              value={this.state.quantityTwo}
              maxLength="4"
              size="4"
            />
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
              style={{ marginBottom: '30px', marginTop: '20px' }}
            >
              <input
                type="checkbox"
                // name="active"
                checked={this.state.lockedTwo}
                onChange={this.lockedTwoChange.bind(this)}
                style={{
                  minWidth: 'unset',
                  float: 'left',
                  position: 'relative',
                  transform: 'scale(1.5)',
                  top: '5px',
                  right: '-5px',
                  paddingRight: '-10px',
                }}
              />
              <span
                className=""
                style={{ minWidth: 'unset', float: 'left' }}
              >
                Locked Until Previous Levels Complete
              </span>
            </Col>
            {this.state.community === true && (
              <div className="wizard-section-ten">
                <div style={{ marginTop: '10px' }}>
                  <span className="title">
                    Collaboration Settings
                  </span>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <label style={{ display: 'block', clear: 'both' }}>
                    <input
                      style={{ marginRight: '5px' }}
                      type="radio"
                      value="all"
                      checked={this.state.colabTwo === 'all'}
                      onChange={this.colabTwoChange.bind(this)}
                    />
                    User earns all of the Level 2 Rewards.
                  </label>

                  <label style={{ display: 'block', clear: 'both' }}>
                    <input
                      style={{ marginRight: '5px' }}
                      type="radio"
                      value="choose"
                      checked={this.state.colabTwo === 'choose'}
                      onChange={this.colabTwoChange.bind(this)}
                    />
                    User must choose which Level 2 Rewards they want.
                  </label>
                  {this.state.colabTwo === 'choose' && (
                    <div>
                      <span className="inputLabel">
                        How many can they choose?
                      </span>
                      <input
                        type="number"
                        onChange={this.colabTwoQuantityChange.bind(
                          this,
                        )}
                        value={this.state.colabTwoQuantity}
                        maxLength="4"
                        size="4"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {levelCriteriaCounts.levelTwoCriteriaCount > 0 && (
              <div>
                <div
                  id="togglerCf"
                  className={
                    this.state.togglerCf === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 1
                </div>
                <UncontrolledCollapse
                  toggler="#togglerCf"
                  defaultOpen={true}
                >
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelTwoCriteria[0].text}
                      name={0}
                      id="two"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelTwoCriteria[0].numTimes}
                      maxLength="4"
                      size="4"
                      name={0}
                      id="two"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={this.state.levelTwoCriteria[0].locations}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 0, 'two')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelTwoCriteria[0].guidelines
                      }
                      className="full-width"
                      name={0}
                      id="two"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelTwoCriteria[0].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={0}
                        id="two"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelTwoCriteriaCount > 1 && (
              <div>
                <div
                  id="togglerCb"
                  className={
                    this.state.togglerCb === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 2
                </div>
                <UncontrolledCollapse toggler="#togglerCb">
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelTwoCriteria[1].text}
                      name={1}
                      id="two"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelTwoCriteria[1].numTimes}
                      maxLength="4"
                      size="4"
                      name={1}
                      id="two"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={this.state.levelTwoCriteria[1].locations}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 1, 'two')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelTwoCriteria[1].guidelines
                      }
                      className="full-width"
                      name={1}
                      id="two"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelTwoCriteria[1].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={1}
                        id="two"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelTwoCriteriaCount > 2 && (
              <div>
                <div
                  id="togglerCc"
                  className={
                    this.state.togglerCc === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 3
                </div>
                <UncontrolledCollapse toggler="#togglerCc">
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelTwoCriteria[2].text}
                      name={2}
                      id="two"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelTwoCriteria[2].numTimes}
                      maxLength="4"
                      size="4"
                      name={2}
                      id="two"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={this.state.levelTwoCriteria[2].locations}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 2, 'two')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelTwoCriteria[2].guidelines
                      }
                      className="full-width"
                      name={2}
                      id="two"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelTwoCriteria[2].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={2}
                        id="two"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelTwoCriteriaCount < 3 && (
              <a
                onClick={() => {
                  this.addCriteriaCount(2);
                }}
                style={{
                  margin: '10px 0px 10px 0px',
                  display: 'block',
                }}
              >
                <span style={{ position: 'relative', top: '6px' }}>
                  <MaterialIcon
                    icon="add_box"
                    size={25}
                    color="rgb(238 1 88)"
                  />
                </span>{' '}
                Add Additional Criteria
              </a>
            )}
          </div>
        </div>
      );
    } else if (activeLevel === 3) {
      return (
        <div className="wizard-section-ten">
          <h5>
            Level 3 Reward Setup -{' '}
            <span style={{ color: '#f21d59', fontSize: '19px' }}>
              {this.state.levelThree.label}
            </span>
          </h5>
          <div className="rewardCreateBox">
            <span className="inputLabel">Reward Description</span>
            <textarea
              type="text"
              onChange={this.rewardDetailsThreeOnChange.bind(this)}
              value={this.state.rewardDetailsThree}
              className="full-width"
              name="detailsThree"
            />
            <span className="inputLabel">
              How many of this reward are available?
            </span>
            <input
              type="number"
              onChange={this.quantityThreeOnChange.bind(this)}
              value={this.state.quantityThree}
              maxLength="4"
              size="4"
            />
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
              style={{ marginBottom: '30px', marginTop: '20px' }}
            >
              <input
                type="checkbox"
                // name="active"
                checked={this.state.lockedThree}
                onChange={this.lockedThreeChange.bind(this)}
                style={{
                  minWidth: 'unset',
                  float: 'left',
                  position: 'relative',
                  transform: 'scale(1.5)',
                  top: '5px',
                  right: '-5px',
                  paddingRight: '-10px',
                }}
              />
              <span
                className=""
                style={{ minWidth: 'unset', float: 'left' }}
              >
                Locked Until Previous Levels Complete
              </span>
            </Col>
            {this.state.community === true && (
              <div className="wizard-section-ten">
                <div style={{ marginTop: '10px' }}>
                  <span className="title">
                    Collaboration Settings
                  </span>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <label style={{ display: 'block', clear: 'both' }}>
                    <input
                      style={{ marginRight: '5px' }}
                      type="radio"
                      value="all"
                      checked={this.state.colabThree === 'all'}
                      onChange={this.colabThreeChange.bind(this)}
                    />
                    User earns all of the Level 3 Rewards.
                  </label>

                  <label style={{ display: 'block', clear: 'both' }}>
                    <input
                      style={{ marginRight: '5px' }}
                      type="radio"
                      value="choose"
                      checked={this.state.colabThree === 'choose'}
                      onChange={this.colabThreeChange.bind(this)}
                    />
                    User must choose which Level 3 Rewards they want.
                  </label>
                  {this.state.colabThree === 'choose' && (
                    <div>
                      <span className="inputLabel">
                        How many can they choose?
                      </span>
                      <input
                        type="number"
                        onChange={this.colabThreeQuantityChange.bind(
                          this,
                        )}
                        value={this.state.colabThreeQuantity}
                        maxLength="4"
                        size="4"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <hr></hr>
            {levelCriteriaCounts.levelThreeCriteriaCount > 0 && (
              <div>
                <div
                  id="togglerCi"
                  className={
                    this.state.togglerCi === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 1
                </div>
                <UncontrolledCollapse
                  toggler="#togglerCi"
                  defaultOpen={true}
                >
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelThreeCriteria[0].text}
                      name={0}
                      id="three"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelThreeCriteria[0].numTimes
                      }
                      maxLength="4"
                      size="4"
                      name={0}
                      id="three"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={
                        this.state.levelThreeCriteria[0].locations
                      }
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 0, 'three')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelThreeCriteria[0].guidelines
                      }
                      className="full-width"
                      name={0}
                      id="three"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelThreeCriteria[0].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={0}
                        id="three"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelThreeCriteriaCount > 1 && (
              <div>
                <div
                  id="togglerCb"
                  className={
                    this.state.togglerCb === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 2
                </div>
                <UncontrolledCollapse toggler="#togglerCb">
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelThreeCriteria[1].text}
                      name={1}
                      id="three"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelThreeCriteria[1].numTimes
                      }
                      maxLength="4"
                      size="4"
                      name={1}
                      id="three"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={
                        this.state.levelThreeCriteria[1].locations
                      }
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 1, 'three')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelThreeCriteria[1].guidelines
                      }
                      className="full-width"
                      name={1}
                      id="three"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelThreeCriteria[1].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={1}
                        id="three"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelThreeCriteriaCount > 2 && (
              <div>
                <div
                  id="togglerCc"
                  className={
                    this.state.togglerCc === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 3
                </div>
                <UncontrolledCollapse toggler="#togglerCc">
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelThreeCriteria[2].text}
                      name={2}
                      id="three"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelThreeCriteria[2].numTimes
                      }
                      maxLength="4"
                      size="4"
                      name={2}
                      id="three"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={
                        this.state.levelThreeCriteria[2].locations
                      }
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 2, 'three')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelThreeCriteria[2].guidelines
                      }
                      className="full-width"
                      name={2}
                      id="three"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelThreeCriteria[2].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={2}
                        id="three"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelThreeCriteriaCount < 3 && (
              <a
                onClick={() => {
                  this.addCriteriaCount(3);
                }}
                style={{
                  margin: '10px 0px 10px 0px',
                  display: 'block',
                }}
              >
                <span style={{ position: 'relative', top: '6px' }}>
                  <MaterialIcon
                    icon="add_box"
                    size={25}
                    color="rgb(238 1 88)"
                  />
                </span>{' '}
                Add Additional Criteria
              </a>
            )}
          </div>
        </div>
      );
    } else if (activeLevel === 4) {
      return (
        <div className="wizard-section-ten">
          <h5>
            Level 4 Reward Setup -{' '}
            <span style={{ color: '#f21d59', fontSize: '19px' }}>
              {this.state.levelFour.label}
            </span>
          </h5>
          <div className="rewardCreateBox">
            <span className="inputLabel">Reward Description</span>
            <textarea
              type="text"
              onChange={this.rewardDetailsFourOnChange.bind(this)}
              value={this.state.rewardDetailsFour}
              className="full-width"
              name="detailsFour"
            />
            <span className="inputLabel">
              How many of this reward are available?
            </span>
            <input
              type="number"
              onChange={this.quantityFourOnChange.bind(this)}
              value={this.state.quantityFour}
              maxLength="4"
              size="4"
            />
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
              style={{ marginBottom: '30px', marginTop: '20px' }}
            >
              <input
                type="checkbox"
                // name="active"
                checked={this.state.lockedFour}
                onChange={this.lockedFourChange.bind(this)}
                style={{
                  minWidth: 'unset',
                  float: 'left',
                  position: 'relative',
                  transform: 'scale(1.5)',
                  top: '5px',
                  right: '-5px',
                  paddingRight: '-10px',
                }}
              />
              <span
                className=""
                style={{ minWidth: 'unset', float: 'left' }}
              >
                Locked Until Previous Levels Complete
              </span>
            </Col>
            {this.state.community === true && (
              <div className="wizard-section-ten">
                <div style={{ marginTop: '10px' }}>
                  <span className="title">
                    Collaboration Settings
                  </span>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <label style={{ display: 'block', clear: 'both' }}>
                    <input
                      style={{ marginRight: '5px' }}
                      type="radio"
                      value="all"
                      checked={this.state.colabFour === 'all'}
                      onChange={this.colabFourChange.bind(this)}
                    />
                    User earns all of the Level 4 Rewards.
                  </label>

                  <label style={{ display: 'block', clear: 'both' }}>
                    <input
                      style={{ marginRight: '5px' }}
                      type="radio"
                      value="choose"
                      checked={this.state.colabFour === 'choose'}
                      onChange={this.colabFourChange.bind(this)}
                    />
                    User must choose which Level 4 Rewards they want.
                  </label>
                  {this.state.colabFour === 'choose' && (
                    <div>
                      <span className="inputLabel">
                        How many can they choose?
                      </span>
                      <input
                        type="number"
                        onChange={this.colabFourQuantityChange.bind(
                          this,
                        )}
                        value={this.state.colabFourQuantity}
                        maxLength="4"
                        size="4"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <hr></hr>
            {levelCriteriaCounts.levelFourCriteriaCount > 0 && (
              <div>
                <div
                  id="togglerCl"
                  className={
                    this.state.togglerCl === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 1
                </div>
                <UncontrolledCollapse
                  toggler="#togglerCl"
                  defaultOpen={true}
                >
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelFourCriteria[0].text}
                      name={0}
                      id="four"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelFourCriteria[0].numTimes}
                      maxLength="4"
                      size="4"
                      name={0}
                      id="four"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={
                        this.state.levelFourCriteria[0].locations
                      }
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 0, 'four')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelFourCriteria[0].guidelines
                      }
                      className="full-width"
                      name={0}
                      id="four"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelFourCriteria[0].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={0}
                        id="four"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelFourCriteriaCount > 1 && (
              <div>
                <div
                  id="togglerCb"
                  className={
                    this.state.togglerCb === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 2
                </div>
                <UncontrolledCollapse toggler="#togglerCb">
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelFourCriteria[1].text}
                      name={1}
                      id="four"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelFourCriteria[1].numTimes}
                      maxLength="4"
                      size="4"
                      name={1}
                      id="four"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={
                        this.state.levelFourCriteria[1].locations
                      }
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 1, 'four')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelFourCriteria[1].guidelines
                      }
                      className="full-width"
                      name={1}
                      id="four"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelFourCriteria[1].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={1}
                        id="four"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelFourCriteriaCount > 2 && (
              <div>
                <div
                  id="togglerCc"
                  className={
                    this.state.togglerCc === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 3
                </div>
                <UncontrolledCollapse toggler="#togglerCc">
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelFourCriteria[2].text}
                      name={2}
                      id="four"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelFourCriteria[2].numTimes}
                      maxLength="4"
                      size="4"
                      name={2}
                      id="four"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={
                        this.state.levelFourCriteria[2].locations
                      }
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 2, 'four')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelFourCriteria[2].guidelines
                      }
                      className="full-width"
                      name={2}
                      id="four"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelFourCriteria[2].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={2}
                        id="four"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelFourCriteriaCount < 3 && (
              <a
                onClick={() => {
                  this.addCriteriaCount(4);
                }}
                style={{
                  margin: '10px 0px 10px 0px',
                  display: 'block',
                }}
              >
                <span style={{ position: 'relative', top: '6px' }}>
                  <MaterialIcon
                    icon="add_box"
                    size={25}
                    color="rgb(238 1 88)"
                  />
                </span>{' '}
                Add Additional Criteria
              </a>
            )}
          </div>
        </div>
      );
    } else if (activeLevel === 5) {
      return (
        <div className="wizard-section-ten">
          <h5>
            Level 5 Reward Setup -{' '}
            <span style={{ color: '#f21d59', fontSize: '19px' }}>
              {this.state.levelFive.label}
            </span>
          </h5>
          <div className="rewardCreateBox">
            <span className="inputLabel">Reward Description</span>
            <textarea
              type="text"
              onChange={this.rewardDetailsFiveOnChange.bind(this)}
              value={this.state.rewardDetailsFive}
              className="full-width"
              name="detailsFive"
            />
            <span className="inputLabel">
              How many of this reward are available?
            </span>
            <input
              type="number"
              onChange={this.quantityFiveOnChange.bind(this)}
              value={this.state.quantityFive}
              maxLength="4"
              size="4"
            />
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
              style={{ marginBottom: '30px', marginTop: '20px' }}
            >
              <input
                type="checkbox"
                // name="active"
                checked={this.state.lockedFive}
                onChange={this.lockedFiveChange.bind(this)}
                style={{
                  minWidth: 'unset',
                  float: 'left',
                  position: 'relative',
                  transform: 'scale(1.5)',
                  top: '5px',
                  right: '-5px',
                  paddingRight: '-10px',
                }}
              />
              <span
                className=""
                style={{ minWidth: 'unset', float: 'left' }}
              >
                Locked Until Previous Levels Complete
              </span>
            </Col>
            {this.state.community === true && (
              <div className="wizard-section-ten">
                <div style={{ marginTop: '10px' }}>
                  <span className="title">
                    Collaboration Settings
                  </span>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <label style={{ display: 'block', clear: 'both' }}>
                    <input
                      style={{ marginRight: '5px' }}
                      type="radio"
                      value="all"
                      checked={this.state.colabFive === 'all'}
                      onChange={this.colabFiveChange.bind(this)}
                    />
                    User earns all of the Level 5 Rewards.
                  </label>

                  <label style={{ display: 'block', clear: 'both' }}>
                    <input
                      style={{ marginRight: '5px' }}
                      type="radio"
                      value="choose"
                      checked={this.state.colabFive === 'choose'}
                      onChange={this.colabFiveChange.bind(this)}
                    />
                    User must choose which Level 5 Rewards they want.
                  </label>
                  {this.state.colabFive === 'choose' && (
                    <div>
                      <span className="inputLabel">
                        How many can they choose?
                      </span>
                      <input
                        type="number"
                        onChange={this.colabFiveQuantityChange.bind(
                          this,
                        )}
                        value={this.state.colabFiveQuantity}
                        maxLength="4"
                        size="4"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <hr></hr>
            {levelCriteriaCounts.levelFiveCriteriaCount > 0 && (
              <div>
                <div
                  id="togglerCo"
                  className={
                    this.state.togglerCo === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 1
                </div>
                <UncontrolledCollapse
                  toggler="#togglerCo"
                  defaultOpen={true}
                >
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelFiveCriteria[0].text}
                      name={0}
                      id="five"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelFiveCriteria[0].numTimes}
                      maxLength="4"
                      size="4"
                      name={0}
                      id="five"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={
                        this.state.levelFiveCriteria[0].locations
                      }
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 0, 'five')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelFiveCriteria[0].guidelines
                      }
                      className="full-width"
                      name={0}
                      id="five"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelFiveCriteria[0].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={0}
                        id="five"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelFiveCriteriaCount > 1 && (
              <div>
                <div
                  id="togglerCb"
                  className={
                    this.state.togglerCb === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 2
                </div>
                <UncontrolledCollapse toggler="#togglerCb">
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelFiveCriteria[1].text}
                      name={1}
                      id="five"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelFiveCriteria[1].numTimes}
                      maxLength="4"
                      size="4"
                      name={1}
                      id="five"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={
                        this.state.levelFiveCriteria[1].locations
                      }
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 1, 'five')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelFiveCriteria[1].guidelines
                      }
                      className="full-width"
                      name={1}
                      id="five"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelFiveCriteria[1].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={1}
                        id="five"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelFiveCriteriaCount > 2 && (
              <div>
                <div
                  id="togglerCc"
                  className={
                    this.state.togglerCc === true
                      ? 'criteriaToggler collapsed'
                      : 'criteriaToggler'
                  }
                  data-toggle="collapse"
                  onClick={this.flip.bind(this)}
                >
                  Criteria 3
                </div>
                <UncontrolledCollapse toggler="#togglerCc">
                  <div className="criteria">
                    <span className="inputLabel">
                      What do they have to do?
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaTextHandler.bind(this)}
                      value={this.state.levelFiveCriteria[2].text}
                      name={2}
                      id="five"
                      className="full-width"
                    />

                    <span className="inputLabel">
                      How many times do they have to complete this
                      criteria?
                    </span>
                    <input
                      type="number"
                      onChange={this.criteriaNumTimesHandler.bind(
                        this,
                      )}
                      value={this.state.levelFiveCriteria[2].numTimes}
                      maxLength="4"
                      size="4"
                      name={2}
                      id="five"
                    />
                    <span className="inputLabel">
                      Where can this criteria be validated?
                    </span>
                    <Select
                      value={
                        this.state.levelFiveCriteria[2].locations
                      }
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.criteriaLocationsHandler(e, 2, 'five')
                      }
                      options={locations}
                      isMulti={true}
                    />
                    <span
                      className="inputLabel"
                      style={{ paddingTop: '20px' }}
                    >
                      Restrictions and Guidelines
                    </span>
                    <textarea
                      type="text"
                      onChange={this.criteriaGuidelinesHandler.bind(
                        this,
                      )}
                      value={
                        this.state.levelFiveCriteria[2].guidelines
                      }
                      className="full-width"
                      name={2}
                      id="five"
                    />
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                      style={{ marginBottom: '20px' }}
                    >
                      <input
                        type="checkbox"
                        // name="active"
                        checked={
                          this.state.levelFiveCriteria[2].oncePerDay
                        }
                        onChange={this.criteriaOncePerDayHandler.bind(
                          this,
                        )}
                        name={2}
                        id="five"
                        style={{
                          minWidth: 'unset',
                          float: 'left',
                          position: 'relative',
                          transform: 'scale(1.5)',
                          top: '5px',
                          right: '-5px',
                          paddingRight: '-10px',
                        }}
                      />
                      <span
                        className=""
                        style={{ minWidth: 'unset', float: 'left' }}
                      >
                        Can only be validated once per day.
                      </span>
                    </Col>
                  </div>
                </UncontrolledCollapse>
              </div>
            )}
            {levelCriteriaCounts.levelFiveCriteriaCount < 3 && (
              <a
                onClick={() => {
                  this.addCriteriaCount(5);
                }}
                style={{
                  margin: '10px 0px 10px 0px',
                  display: 'block',
                }}
              >
                <span style={{ position: 'relative', top: '6px' }}>
                  <MaterialIcon
                    icon="add_box"
                    size={25}
                    color="rgb(238 1 88)"
                  />
                </span>{' '}
                Add Additional Criteria
              </a>
            )}
          </div>
        </div>
      );
    } else if (activeLevel === 6) {
      let levels = [
        {
          value: 1,
          label: 'Level 1',
        },
        {
          value: 2,
          label: 'Level 2',
        },
        {
          value: 3,
          label: 'Level 3',
        },
        {
          value: 4,
          label: 'Level 4',
        },
        {
          value: 5,
          label: 'Level 5',
        },
      ];
      return (
        <div className="wizard-section-ten">
          <h5>Golden Ticket Setup</h5>
          <div className="rewardCreateBox">
            <span className="inputLabel">Apply to which Levels?</span>
            <Select
              value={this.state.goldenTicketLevel}
              closeMenuOnSelect={true}
              blurInputOnSelect={false}
              onChange={(value) => {
                this.handleOnChangeGoldenLevel(value);
              }}
              options={levels}
              isMulti={true}
              // className="activeLevel"
            />
            <span className="inputLabel">Reward Description</span>
            <textarea
              type="text"
              onChange={this.goldenTicketDetailsOnChange.bind(this)}
              value={this.state.goldenTicketDetails}
              className="full-width"
              name="detailsFive"
            />
            <span className="inputLabel">
              How many Golden Tickets are there?
            </span>
            <input
              type="number"
              onChange={this.goldenTicketQuantityOnChange.bind(this)}
              value={this.state.goldenTicketQuantity}
              maxLength="4"
              size="4"
            />
          </div>
        </div>
      );
    }
  }

  displayRewardsForm(
    activeLevel,
    levelCriteriaCounts,
    rewards,
    locations,
  ) {
    // const uploadUrl = "test/" + this.props.profile.vendorAccount.id;
    let options = [
      { value: '0', name: 'No Restrictions' },
      { value: '1', name: 'Limit 1 Per User' },
    ];
    return (
      // <div id="eventDetailsForm">
      <div>
        <div>
          <div>
            <Container>
              <Row className="no-padding">
                <Col
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="levelSection"
                >
                  <h5>Choose Rewards</h5>
                  <div className="wizard-section-ten">
                    <Container className="no-padding">
                      <Row className="no-padding">
                        <Col
                          lg={10}
                          md={10}
                          sm={10}
                          xs={10}
                          className="no-padding"
                        >
                          <span className="inputLabel">
                            <b>Level 1 Reward</b>
                          </span>
                          <Select
                            value={this.state.levelOne}
                            closeMenuOnSelect={true}
                            blurInputOnSelect={false}
                            onChange={(value) => {
                              this.handleOnChangeLevelOne(value);
                            }}
                            options={rewards}
                            isMulti={false}
                            // className="activeLevel"
                          />

                          {this.state.otherVendorRewards.length !==
                            0 && (
                            <div className="otherVendorReward">
                              {this.state.otherVendorRewards[0].title}

                              <span className="address">
                                {
                                  this.state.otherVendorRewards[0]
                                    .vendorName
                                }
                              </span>
                            </div>
                          )}
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="no-padding"
                        >
                          {/* <span className="levelEditIcon">
                            {this.getEditRewardButton(
                              this.state.levelOne,
                            )}
                          </span> */}
                          {this.getEditCriteriaButton(
                            activeLevel,
                            this.state.levelOne,
                            1,
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  {this.state.levelOne !== '' && (
                    <div className="wizard-section-ten">
                      <Container className="no-padding">
                        <Row className="no-padding">
                          <Col
                            lg={10}
                            md={10}
                            sm={10}
                            xs={10}
                            className="no-padding"
                          >
                            <span className="inputLabel">
                              <b>Level 2 Reward</b>
                            </span>
                            <Select
                              value={this.state.levelTwo}
                              closeMenuOnSelect={true}
                              blurInputOnSelect={false}
                              onChange={(value) => {
                                this.handleOnChangeLevelTwo(value);
                              }}
                              options={rewards}
                              isMulti={false}
                            />
                            {this.state.otherVendorRewards.length !==
                              0 && (
                              <div className="otherVendorReward">
                                {
                                  this.state.otherVendorRewards[1]
                                    .title
                                }

                                <span className="address">
                                  {
                                    this.state.otherVendorRewards[1]
                                      .vendorName
                                  }
                                </span>
                              </div>
                            )}
                          </Col>
                          <Col
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className="no-padding"
                          >
                            {/* <span className="levelEditIcon">
                              {this.getEditRewardButton(
                                this.state.levelTwo,
                              )}
                            </span> */}
                            {this.state.otherVendorRewards.length !==
                            0
                              ? this.getEditCriteriaButton(
                                  activeLevel,
                                  this.state.levelTwo,
                                  2,
                                  true,
                                )
                              : this.getEditCriteriaButton(
                                  activeLevel,
                                  this.state.levelTwo,
                                  2,
                                  false,
                                )}
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  )}
                  {this.state.levelTwo !== '' && (
                    <div className="wizard-section-ten">
                      <Container className="no-padding">
                        <Row className="no-padding">
                          <Col
                            lg={10}
                            md={10}
                            sm={10}
                            xs={10}
                            className="no-padding"
                          >
                            <span className="inputLabel">
                              {' '}
                              <b>Level 3 Reward</b>
                            </span>
                            <Select
                              value={this.state.levelThree}
                              closeMenuOnSelect={true}
                              blurInputOnSelect={false}
                              onChange={(value) => {
                                this.handleOnChangeLevelThree(value);
                              }}
                              options={rewards}
                              isMulti={false}
                            />
                            {/* <span
                              className="editCriteria"
                              onClick={() => {
                                this.setLevel(3);
                              }}
                            >
                              {this.getEditCriteriaButton(
                                this.state.levelThree,
                                3,
                              )}
                            </span> */}
                          </Col>
                          <Col
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className="no-padding"
                          >
                            {/* <span className="levelEditIcon">
                              {this.getEditRewardButton(
                                this.state.levelThree,
                              )}
                            </span> */}
                            {this.getEditCriteriaButton(
                              activeLevel,
                              this.state.levelThree,
                              3,
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  )}
                  {this.state.levelThree !== '' && (
                    <div className="wizard-section-ten">
                      <Container className="no-padding">
                        <Row className="no-padding">
                          <Col
                            lg={10}
                            md={10}
                            sm={10}
                            xs={10}
                            className="no-padding"
                          >
                            <span className="inputLabel">
                              <b>Level 4 Reward</b>
                            </span>
                            <Select
                              value={this.state.levelFour}
                              closeMenuOnSelect={true}
                              blurInputOnSelect={false}
                              onChange={(value) => {
                                this.handleOnChangeLevelFour(value);
                              }}
                              options={rewards}
                              isMulti={false}
                            />
                            {/* <span
                              className="editCriteria"
                              onClick={() => {
                                this.setLevel(4);
                              }}
                            >
                              {this.getEditCriteriaButton(
                                this.state.levelFour,
                                4,
                              )}
                            </span> */}
                          </Col>
                          <Col
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className="no-padding"
                          >
                            {/* <span className="levelEditIcon">
                              {this.getEditRewardButton(
                                this.state.levelFour,
                              )}
                            </span> */}
                            {this.getEditCriteriaButton(
                              activeLevel,
                              this.state.levelFour,
                              4,
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  )}
                  {this.state.levelFour !== '' && (
                    <div className="wizard-section-ten">
                      <Container className="no-padding">
                        <Row className="no-padding">
                          <Col
                            lg={10}
                            md={10}
                            sm={10}
                            xs={10}
                            className="no-padding"
                          >
                            <span className="inputLabel">
                              <b>Level 5 Reward</b>
                            </span>
                            <Select
                              value={this.state.levelFive}
                              closeMenuOnSelect={true}
                              blurInputOnSelect={false}
                              onChange={(value) => {
                                this.handleOnChangeLevelFive(value);
                              }}
                              options={rewards}
                              isMulti={false}
                            />
                            {/* <span
                              className="editCriteria"
                              onClick={() => {
                                this.setLevel(5);
                              }}
                            >
                              {this.getEditCriteriaButton(
                                this.state.levelFive,
                                5,
                              )}
                            </span> */}
                          </Col>
                          <Col
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className="no-padding"
                          >
                            {/* <span className="levelEditIcon">
                              {this.getEditRewardButton(
                                this.state.levelFive,
                              )}
                            </span> */}
                            {this.getEditCriteriaButton(
                              activeLevel,
                              this.state.levelFive,
                              5,
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  )}
                  <div className="wizard-section-ten">
                    <hr></hr>
                    <Container className="no-padding">
                      <Row className="no-padding">
                        <Col
                          lg={10}
                          md={10}
                          sm={10}
                          xs={10}
                          className="no-padding"
                        >
                          <span className="inputLabel">
                            <b>Golden Ticket</b>
                          </span>
                          <Select
                            value={this.state.levelSix}
                            closeMenuOnSelect={true}
                            blurInputOnSelect={false}
                            onChange={(value) => {
                              this.handleOnChangeLevelSix(value);
                            }}
                            options={this.state.rewardsOptions}
                            isMulti={false}
                          />
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          className="no-padding"
                        >
                          {this.getEditCriteriaButton(
                            activeLevel,
                            this.state.levelSix,
                            6,
                          )}
                        </Col>
                      </Row>
                      <div
                        style={{
                          marginTop: '20px',
                          display: 'block',
                        }}
                      >
                        <a
                          onClick={() => {
                            this.openRewardModal();
                          }}
                        >
                          <span className="left">
                            Add / Manage Your Rewards
                          </span>
                        </a>
                      </div>
                    </Container>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={12} className="left">
                  {this.state.levelOne !== '' ? (
                    <div>
                      {this.getLevelDetails(
                        activeLevel,
                        levelCriteriaCounts,
                        locations,
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: 'center',
                        paddingTop: '80px',
                      }}
                    >
                      <span style={{ fontWeight: '600' }}>
                        Choose your Level 1 Reward.
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
            {/* <Container id="imageUpload">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="left">
                  <span className="inputLabel">Image</span>
                  {this.state.isUploading && (
                    <p>Progress: {this.state.progressThumb}</p>
                  )}
                  {this.state.heroURL && (
                    <img src={this.state.thumbURL} className="heroInputImage" />
                  )}
                  <FileUploader
                    accept="image/*"
                    name="thumb"
                    randomizeFilename
                    storageRef={firebase.storage().ref(uploadUrl)}
                    onUploadStart={this.handleUploadStartThumb}
                    onUploadError={this.handleUploadErrorThumb}
                    onUploadSuccess={this.handleUploadSuccessThumb}
                    onProgress={this.handleProgressThumb}
                    className="imageUpload"
                  />
                  <ErrorMessage name="locationThumb" component="div" />
                </Col>
              </Row>
            </Container> */}
          </div>
        </div>
      </div>
    );
  }

  //   getLocations(activeEvent) {
  //     const packageDetails = query("packageId")
  //       .is(activeEvent.packageDetails.packageId)
  //       .on(this.props.packages);
  //     return packageDetails[0].locations;
  //   }

  updateAndAdd(packageBundle, placement) {
    let updatedPackageBundle = packageBundle;
    if (updatedPackageBundle.started === 0) {
      updatedPackageBundle.package.locations = this.state.locations;
    }
    updatedPackageBundle.package.title = this.state.title;
    updatedPackageBundle.package.description = this.state.description;
    updatedPackageBundle.started = 1;
    updatedPackageBundle.placement = placement;
    this.props.addPackage(updatedPackageBundle);
  }

  resetPackage() {
    this.props.resetPackage();
  }

  createPromo() {
    const locationDetails = query('locationId')
      .is(this.props.vendor.locations[0])
      .on(this.props.locations);
    let location = locationDetails[0];
    const db = firebase.firestore();
    const timestamp = new Date();
    let fullName =
      this.props.profile.firstName +
      ' ' +
      this.props.profile.lastName;

    const tagsExperienceTypes = [];
    this.state.experienceType.forEach((doc) => {
      tagsExperienceTypes.push(doc.value);
    });

    let promo = {
      account: {
        vendorId: this.props.vendor.vendorId,
      },
      active: this.state.checked,
      creationDetails: {
        createdDate: timestamp,
        creator: {
          fullName: fullName,
          id: this.props.profile.userId,
        },
      },
      tagsExperienceTypes,
      locations: this.state.promoLocations,
      // locationId: this.props.vendor.locations[0],
      // locationTitle: location.locationTitle,
      title: this.state.title,
      description: this.state.description,
      zoneId: this.props.profile.zoneId,
      // events: [],
      images: {
        heroPath: this.state.heroPath,
        thumbPath: this.state.thumbPath,
      },
      status: 2,
      settings: {
        community: this.state.community,
      },
    };
    // Rewards
    let levelOne = {
      locked: false,
      lockDependency: '',
    };
    // Collaboration Settings

    let colabSettings = {};
    colabSettings.colabOne = this.state.colabOne;
    colabSettings.colabOneQuantity = this.state.colabOneQuantity;
    levelOne.colabSettings = colabSettings;

    //Criteria
    let levelOneCriteria = [];
    this.state.levelOneCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelOneCriteria.push(criteria);
      }
    });
    levelOne.criteria = levelOneCriteria;
    let levelOneRewards = [];
    let vendorLevelOne = {
      id: this.state.levelOne.value,
      title: this.state.levelOne.label,
      quantity: this.state.quantityOne,
      vendorId: this.props.profile.vendorAccount.id,
    };
    if (this.state.levelOne.value !== undefined) {
      levelOneRewards.push(vendorLevelOne);
    }
    this.state.otherVendorRewards.forEach((reward, i) => {
      if (reward.level === 1) {
        let otherVendorReward = {
          id: reward.id,
          title: reward.title,
          quantity: reward.quantity,
          vendorId: reward.vendorId,
        };
        levelOneRewards.push(otherVendorReward);
      }
    });
    levelOne.reward = levelOneRewards;
    // Level 2
    let levelTwo = {
      locked: this.state.lockedTwo,
      status: 1,
    };
    // Collaboration Settings
    levelTwo.colabSettings = {};
    levelTwo.colabSettings.colabTwo = this.state.colabTwo;
    levelTwo.colabSettings.colabTwoQuantity =
      this.state.colabTwoQuantity;
    //Criteria
    let levelTwoCriteria = [];
    this.state.levelTwoCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelTwoCriteria.push(criteria);
      }
    });
    levelTwo.criteria = levelTwoCriteria;
    if (
      this.state.lockedTwo === 'on' ||
      this.state.lockedTwo === true
    ) {
      levelTwo.lockDependency = this.state.levelOne.value;
    } else {
      levelTwo.lockDependency = '';
    }
    let levelTwoRewards = [];
    let vendorLevelTwo = {
      id: this.state.levelTwo.value,
      title: this.state.levelTwo.label,
      quantity: this.state.quantityTwo,
      vendorId: this.props.profile.vendorAccount.id,
    };
    if (this.state.levelTwo.value !== undefined) {
      levelTwoRewards.push(vendorLevelTwo);
    }
    this.state.otherVendorRewards.forEach((reward, i) => {
      if (reward.level === 2) {
        let otherVendorReward = {
          id: reward.id,
          title: reward.title,
          quantity: reward.quantity,
          vendorId: reward.vendorId,
        };
        levelTwoRewards.push(otherVendorReward);
      }
    });
    levelTwo.reward = levelTwoRewards;
    // Level 3
    let levelThree = {
      locked: this.state.lockedThree,
      status: 1,
    };
    // Collaboration Settings
    levelThree.colabSettings = {};
    levelThree.colabSettings.colabThree = this.state.colabThree;
    levelThree.colabSettings.colabThreeQuantity =
      this.state.colabThreeQuantity;
    //Criteria
    let levelThreeCriteria = [];
    this.state.levelThreeCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelThreeCriteria.push(criteria);
      }
    });
    levelThree.criteria = levelThreeCriteria;
    if (
      this.state.lockedThree === 'on' ||
      this.state.lockedThree === true
    ) {
      if (this.state.levelTwo.value !== undefined) {
        levelThree.lockDependency = this.state.levelTwo.value;
      }
    } else {
      levelThree.lockDependency = '';
    }
    let levelThreeRewards = [];
    let vendorLevelThree = {
      id: this.state.levelThree.value,
      title: this.state.levelThree.label,
      quantity: this.state.quantityThree,
      vendorId: this.props.profile.vendorAccount.id,
    };
    if (this.state.levelThree.value !== undefined) {
      levelThreeRewards.push(vendorLevelThree);
    }
    this.state.otherVendorRewards.forEach((reward, i) => {
      if (reward.level === 3) {
        let otherVendorReward = {
          id: reward.id,
          title: reward.title,
          quantity: reward.quantity,
          vendorId: reward.vendorId,
        };
        levelThreeRewards.push(otherVendorReward);
      }
    });
    levelThree.reward = levelThreeRewards;
    // Level 4
    let levelFour = {
      locked: this.state.lockedFour,
      status: 1,
    };
    // Collaboration Settings
    levelFour.colabSettings = {};
    levelFour.colabSettings.colabFour = this.state.colabFour;
    levelFour.colabSettings.colabFourQuantity =
      this.state.colabFourQuantity;
    //Criteria
    let levelFourCriteria = [];
    this.state.levelFourCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelFourCriteria.push(criteria);
      }
    });
    levelFour.criteria = levelFourCriteria;
    if (
      this.state.lockedFour === 'on' ||
      this.state.lockedFour === true
    ) {
      if (this.state.levelThree.value !== undefined) {
        levelFour.lockDependency = this.state.levelThree.value;
      }
    } else {
      levelFour.lockDependency = '';
    }
    let levelFourRewards = [];
    let vendorLevelFour = {
      id: this.state.levelFour.value,
      title: this.state.levelFour.label,
      quantity: this.state.quantityFour,
      vendorId: this.props.profile.vendorAccount.id,
    };
    if (this.state.levelFour.value !== undefined) {
      levelFourRewards.push(vendorLevelFour);
    }
    this.state.otherVendorRewards.forEach((reward, i) => {
      if (reward.level === 4) {
        let otherVendorReward = {
          id: reward.id,
          title: reward.title,
          quantity: reward.quantity,
          vendorId: reward.vendorId,
        };
        levelFourRewards.push(otherVendorReward);
      }
    });
    levelFour.reward = levelFourRewards;
    // Level 5
    let levelFive = {
      locked: this.state.lockedFive,
      status: 1,
    };
    // Collaboration Settings
    levelFive.colabSettings = {};
    levelFive.colabSettings.colabFive = this.state.colabFive;
    levelFive.colabSettings.colabFiveQuantity =
      this.state.colabFiveQuantity;
    //Criteria
    let levelFiveCriteria = [];
    this.state.levelFiveCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelFiveCriteria.push(criteria);
      }
    });
    levelFive.criteria = levelFiveCriteria;
    if (
      this.state.lockedFive === 'on' ||
      this.state.lockedFive === true
    ) {
      levelFive.lockDependency = this.state.levelFour.value;
    } else {
      levelFive.lockDependency = '';
    }
    let levelFiveRewards = [];
    let vendorLevelFive = {
      id: this.state.levelFive.value,
      title: this.state.levelFive.label,
      quantity: this.state.quantityFive,
      vendorId: this.props.profile.vendorAccount.id,
    };
    if (this.state.levelFive.value !== undefined) {
      levelFiveRewards.push(vendorLevelFive);
    }
    this.state.otherVendorRewards.forEach((reward, i) => {
      if (reward.level === 5) {
        let otherVendorReward = {
          id: reward.id,
          title: reward.title,
          quantity: reward.quantity,
          vendorId: reward.vendorId,
        };
        levelFiveRewards.push(otherVendorReward);
      }
    });
    levelFive.reward = levelFiveRewards;

    let newRewards = [];
    if (
      levelOne.reward !== undefined &&
      levelOne.reward.length !== 0
    ) {
      newRewards.push(levelOne);
    }
    if (
      levelTwo.reward !== undefined &&
      levelTwo.reward.length !== 0
    ) {
      newRewards.push(levelTwo);
    }
    if (
      levelThree.reward !== undefined &&
      levelThree.reward.length !== 0
    ) {
      newRewards.push(levelThree);
    }
    if (
      levelFour.reward !== undefined &&
      levelFour.reward.length !== 0
    ) {
      newRewards.push(levelFour);
    }
    if (
      levelFive.reward !== undefined &&
      levelFive.reward.length !== 0
    ) {
      newRewards.push(levelFive);
    }
    promo.rewards = newRewards;

    // Golden Ticket
    const goldenTicketLevels = [];
    this.state.goldenTicketLevel.forEach((doc) => {
      goldenTicketLevels.push(doc.value);
    });
    let rewardTitle = '';
    if (this.state.levelSix.value !== undefined) {
      rewardTitle = this.state.levelSix.label;
    }
    let rewardId = '';
    if (this.state.levelSix.value !== undefined) {
      rewardId = this.state.levelSix.value;
    }
    promo.goldenTicket = {
      levels: goldenTicketLevels,
      details: this.state.goldenTicketDetails,
      quantity: this.state.goldenTicketQuantity,
      reward: {
        id: rewardId,
        title: rewardTitle,
      },
    };

    // Update History
    const updatedHistory = this.state.editHistory;
    // eventType = 2 = EDIT
    // eventType = 1 = Created
    const newHistory = {
      editDate: timestamp,
      eventType: 1,
      fullName: fullName,
      userId: this.props.profile.userId,
    };
    updatedHistory.push(newHistory);
    promo.editHistory = updatedHistory;

    //Participants
    let newParticipants = {};
    this.state.selectedLocations.forEach((location, i) => {
      newParticipants[location.vendorId] = {};
      newParticipants[location.vendorId].approved = false;
      newParticipants[location.vendorId].rejected = false;
      newParticipants[location.vendorId].access = true;
      newParticipants[location.vendorId].decisionDate = null;
      newParticipants[location.vendorId].participantId = null;
    });
    promo.participants = newParticipants;

    // Scheduled Time
    promo.scheduledTime = {};
    promo.scheduledTime.times = this.setScheduleTimes(
      this.state.selectedStartTime,
      this.state.selectedEndTime,
      this.state.differentTime,
    );
    let to = null;
    if (this.state.to !== undefined) {
      to = this.state.to;
    } else {
      to = this.state.from;
    }
    promo.scheduledTime.dates = this.setSchedule(this.state.from, to);

    // Locations
    let newLocations = [];
    this.state.selectedLocations.forEach((location, i) => {
      let newLocation = {
        locationId: location.id,
        locationTitle: location.title,
        locationThumb: location.locationThumb,
        address: location.address,
      };
      newLocations.push(newLocation);
    });
    promo.locations = newLocations;

    // const setDoc = db
    //   .collection('promos')
    //   .doc(this.state.dbref)
    //   .set(promo)
    //   .then((ref) => {
    //     this.props.getVendorPromos(this.props.vendor.vendorId);
    //     this.props.history.push('/vendor/experiences');
    //   });
  }

  // createPackage(profile, packageBundle, locations, vendor) {
  //   const db = firebase.firestore();
  //   const newEventRef = db.collection("package").doc();
  //   const dbref = newEventRef.id;
  //   const fullname = profile.firstName + " " + profile.lastName;
  //   const timestamp = new Date();
  //   // Prepare Locations
  //   const locationArray = [];
  //   // Check if there are any new locations in the event and if so add to DB
  //   const eventLocations = packageBundle.package.locations;
  //   eventLocations.forEach((location, i) => {
  //     if (location.type === 1) {
  //       const db = firebase.firestore();
  //       const newLocationRef = db.collection("locationTest").doc();
  //       const dbref = newLocationRef.id;
  //       locationArray[i] = {
  //         locationId: dbref,
  //         locationTitle: location.location.locationTitle,
  //         locationType: 1
  //       };
  //       this.uploadLocation(
  //         location.location,
  //         dbref,
  //         profile.vendorAccount.id,
  //         vendor.businessName
  //       );
  //     } else {
  //       const locationDetails = query("locationId")
  //         .is(location.locationId)
  //         .on(locations);
  //       locationArray[i] = {
  //         locationId: locationDetails[0].locationId,
  //         locationTitle: locationDetails[0].locationTitle,
  //         locationType: 0
  //       };
  //     }
  //   });
  //   // Create Package
  //   const newPackage = {
  //     created: timestamp,
  //     title: packageBundle.package.title,
  //     id: dbref,
  //     category: {
  //       Custom: false
  //     },
  //     locations: locationArray,
  //     description: packageBundle.package.description,
  //     heroImage: packageBundle.package.heroImage,
  //     thumbnail: packageBundle.package.thumbnail,
  //     zoneId: 1,
  //     creatorInfo: {
  //       creatorImage: profile.profilePhotoPath,
  //       creatorName: fullname,
  //       creatorId: profile.userId,
  //       type: 3,
  //       vendorId: profile.vendorAccount.id,
  //       vendorBusinessName: vendor.businessName
  //     }
  //   };
  //   console.log("SavePackage", newPackage);
  //   this.props.savePackage(newPackage);
  // }

  uploadLocation(location, id, vendorId, vendorBusinessName) {
    const newLocation = Object.assign({}, location, {
      locationId: id,
      custom: true,
      vendorReferral: true,
      vendorReferralInfo: {
        vendorId: vendorId,
        vendorBusinessName: vendorBusinessName,
      },
    });
    const db = firebase.firestore();
    const setDoc = db
      .collection('locations')
      .doc(id)
      .set(newLocation)
      .then((ref) => {
        console.log(ref);
      });
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  setParticipantsMode(mode) {
    this.setState({
      participantsMode: mode,
    });
  }

  setCreateNewRewardFormActive(mode) {
    this.setState({
      createNewRewardFormActive: mode,
    });
  }

  setEditRewardFormActive(mode, activeReward) {
    this.setState({
      editRewardFormActive: mode,
      editActiveReward: activeReward,
    });
  }

  // Crop Functions
  getHeroImage() {
    if (this.state.heroPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '500px' }}
          src={this.state.heroPath}
        />
      );
    }
  }

  getThumbnail() {
    if (this.state.thumbPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '200px' }}
          src={this.state.thumbPath}
        />
      );
    }
  }
  getImagesTab() {
    return (
      <Container>
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <span className="inputLabel">Banner Image</span>
            {this.getHeroImage()}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <div>
              <input type="file" onChange={this.onSelectFile} />
            </div>
          </Col>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <div>
              <a
                href="#"
                onClick={() =>
                  this.openVendorImagesModal('heroImage')
                }
              >
                {' '}
                Or select from existing Banner Images
              </a>
            </div>
          </Col>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <div>&nbsp;</div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <span className="inputLabel">Thumbnail Image</span>
            {this.getThumbnail()}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <div>
              <input type="file" onChange={this.onSelectFileThumb} />
            </div>
          </Col>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <div>
              <a
                href="#"
                onClick={() =>
                  this.openVendorImagesModal('thumbnail')
                }
              >
                {' '}
                Or select from existing Banner Images
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  // Banner Crop
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      cropping: true,
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'heroImage.jpeg',
      );
      this.setState({ heroPath: croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getBannerCropper(bannerCropping) {
    const { crop, croppedImageUrl, src } = this.state;
    if (bannerCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="Bar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropper();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropper();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </div>
        </div>
      );
    }
  }
  // End Banner Crop
  // Thumb Crop
  onSelectFileThumb = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      croppingThumb: true,
    });
  };

  onImageLoadedThumb = (image) => {
    this.imageRef = image;
  };

  onCropCompleteThumb = (crop) => {
    this.makeClientCropThumb(crop);
  };

  onCropChangeThumb = (cropThumb, percentCrop) => {
    this.setState({ cropThumb });
  };

  async makeClientCropThumb(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImgThumb(
        this.imageRef,
        crop,
        'thumbnail.jpeg',
      );
      this.setState({
        thumbPath: croppedImageUrl,
      });
    }
  }

  getCroppedImgThumb(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getThumbnailCropper(thumbnailCropping) {
    const { cropThumb, croppedImageUrl, src } = this.state;
    if (thumbnailCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropperThumb();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropperThumb();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={cropThumb}
                onImageLoaded={this.onImageLoadedThumb}
                onComplete={this.onCropCompleteThumb}
                onChange={this.onCropChangeThumb}
              />
            )}
          </div>
        </div>
      );
    }
  }
  //End Thumb Crop
  cancelCropper() {
    this.setState({
      cropping: false,
    });
  }

  saveCropper() {
    this.uploadImage(this.state.blob, 'hero');
    this.setState({
      cropping: false,
    });
  }

  cancelCropperThumb() {
    this.setState({
      croppingThumb: false,
    });
  }

  saveCropperThumb() {
    this.uploadImage(this.state.blob, 'thumb');
    this.setState({
      croppingThumb: false,
    });
  }

  // New Gary Stuff

  showVendorImages() {
    return (
      <div className="section">
        <div>
          <Row>
            {this.state.vendorImages
              .filter((image) =>
                image.name.includes(this.state.vendorImagesType),
              )
              .map((image, index) => (
                <Col
                  xs={2}
                  md={2}
                  sm={2}
                  lg={2}
                  style={{ paddingBottom: '23px' }}
                >
                  <a
                    href="#"
                    onClick={() =>
                      this.setImagesFromVendorImages(image.url)
                    }
                  >
                    <img src={image.url} width="198" />
                  </a>
                </Col>
              ))}
          </Row>
        </div>
      </div>
    );
  }
  getVendorImages(type) {
    var storageRef = firebase
      .storage()
      .ref('vendor_images/' + this.props.vendor.vendorId);
    const vendorImages = [];

    const getVendorImages = async function () {
      const results = await storageRef.listAll();
      results.prefixes.forEach(async (folderRef) => {
        const folderContents = await folderRef.listAll();
        folderContents.items.forEach(async (itemRef) => {
          const url = await itemRef.getDownloadURL();
          vendorImages.push({ url, name: itemRef.name });
        });
      });
    };
    getVendorImages();
    console.log('itemRef', vendorImages);
    this.setState({
      vendorImages,
    });
  }

  openVendorImagesModal(type) {
    this.setState({
      vendorImagesModalVisible: true,
      vendorImagesType: type,
    });
  }

  setImagesFromVendorImages(url) {
    if (this.state.vendorImagesType === 'thumbnail') {
      this.setState({ thumbPath: url });
    } else {
      this.setState({ heroPath: url });
    }
    this.setState({ vendorImagesModalVisible: false });
  }

  closeVendorImagesModal() {
    this.setState({ vendorImagesModalVisible: false });
  }

  // End Gary

  uploadImage = (image, type) => {
    const uploadUrl =
      'vendor_images/' +
      this.props.vendor.vendorId +
      '/' +
      this.state.dbref +
      '/' +
      image.name;
    // const uploadUrl =
    //   'event_images/' + this.state.dbref + '/' + image.name;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadUrl);
    imageRef.put(image).then((snapshot) => {
      // const path = snapshot.ref.location.path_;
      if (type === 'hero') {
        let heroImagePath =
          'vendor_images%2F' +
          this.props.vendor.vendorId +
          '%2F' +
          this.state.dbref +
          '%2FheroImage.jpeg';
        let heroPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          heroImagePath +
          '?alt=media';
        this.setState({
          heroPath,
          customHero: true,
        });
      } else {
        let thumbnailImagePath =
          'vendor_images%2F' +
          this.props.vendor.vendorId +
          '%2F' +
          this.state.dbref +
          '%2Fthumbnail.jpeg';
        let thumbPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          thumbnailImagePath +
          '?alt=media';
        this.setState({
          thumbPath,
          customThumb: true,
        });
      }
    });
  };

  getTab(tabMode, tabLable, mode, last) {
    if (tabMode === mode) {
      if (last === true) {
        return (
          <div className="noBorderRight actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      } else {
        return (
          <div className="actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      }
    } else {
      if (last === true) {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton noBorderRight  actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      }
    }
  }

  getReviewTab() {
    let participants = this.getParticipants(this.state.participants);
    return (
      <div className="section">
        <h4>Review</h4>
        <p>
          Please review this Expereince in the Previewer before
          Approving.
        </p>
        <hr />
        <Row>
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <div
              style={{
                position: 'relative',
                clear: 'both',
                marginBottom: '20px',
              }}
            >
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  marginBottom: '10px',
                  display: 'block',
                }}
              >
                Participants
              </span>

              {participants.map((participant, index) => (
                <div style={{ marginBottom: '10px' }}>
                  <span style={{ fontWeight: '500' }}>
                    {participant.businessName}
                  </span>
                  <span
                    style={{
                      position: 'relative',
                      clear: 'both',
                      display: 'block',
                    }}
                  >
                    Status:{' '}
                    {participant.approved === true && (
                      <span style={{ color: 'green' }}>Approved</span>
                    )}
                    {participant.rejected === true && (
                      <span style={{ color: 'red' }}>Rejected</span>
                    )}
                    {participant.rejected !== true &&
                      participant.approved !== true && (
                        <span style={{ color: 'grey' }}>
                          Editing / Reviewing
                        </span>
                      )}
                  </span>
                </div>
              ))}
            </div>
          </Col>
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <div
              style={{
                position: 'relative',
                clear: 'both',
                marginBottom: '20px',
              }}
            >
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  marginBottom: '10px',
                  display: 'block',
                }}
              >
                Experience Dates
              </span>
              <span
                style={{
                  position: 'relative',
                  clear: 'both',
                  display: 'block',
                  fontWeight: '300',
                }}
              >
                {this.getPreviewSchedule()}
              </span>
            </div>
          </Col>
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <div
              style={{
                position: 'relative',
                clear: 'both',
                marginBottom: '20px',
              }}
            >
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  marginBottom: '10px',
                  display: 'block',
                }}
              >
                Your Rewards
              </span>
              <span
                style={{
                  position: 'relative',
                  clear: 'both',
                  display: 'block',
                  fontWeight: '300',
                }}
              >
                {this.state.quantityOne !== 0 &&
                  this.state.quantityOne}{' '}
                {this.state.levelOne.label !== 'Select...' &&
                  this.state.levelOne.label}{' '}
                Vouchers
              </span>
              <span
                style={{
                  position: 'relative',
                  clear: 'both',
                  display: 'block',
                  fontWeight: '300',
                }}
              >
                {this.state.levelTwo.label !== 'Select...' &&
                  this.state.levelTwo.label}
              </span>
              <span
                style={{
                  position: 'relative',
                  clear: 'both',
                  display: 'block',
                  fontWeight: '300',
                }}
              >
                {this.state.levelSix.label !== 'Select...' &&
                  this.state.levelSix.label}
              </span>
            </div>
          </Col>
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <div
              style={{
                position: 'relative',
                clear: 'both',
                marginBottom: '20px',
              }}
            >
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  marginBottom: '10px',
                  display: 'block',
                }}
              >
                Validation Commitments
              </span>
              {this.getReviewCommitments()}
            </div>
          </Col>
        </Row>

        <hr />
        {this.state.participants[
          this.props.profile.vendorAccount.id
        ] !== undefined &&
        this.state.participants[this.props.profile.vendorAccount.id]
          .approved !== true ? (
          <div>
            <a
              onClick={() => {
                this.approvePromo(
                  this.state.promoId,
                  this.state.participants,
                  this.props.profile,
                );
              }}
            >
              <span
                className="redbutton"
                style={{
                  marginBottom: '10px',
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                Approve
              </span>
            </a>
            <a
              onClick={() => {
                this.rejectPromo(
                  this.state.promoId,
                  this.state.participants,
                  this.props.profile,
                );
              }}
            >
              <span
                style={{
                  marginBottom: '10px',
                  display: 'block',
                  textAlign: 'center',
                  maxWidth: '400px',
                  margin: '0px auto',
                }}
                className="greybutton"
              >
                Reject
              </span>
            </a>
          </div>
        ) : (
          <div style={{ paddingTop: '20px' }}>
            <span className="title">
              This Experience has been Approved.
            </span>
            <span style={{ display: 'block', clear: 'both' }}>
              Date:{' '}
              {this.getDate(
                this.state.participants[
                  this.props.profile.vendorAccount.id
                ].decisionDate,
              )}
            </span>
          </div>
        )}
      </div>
    );
  }

  getSection(
    mode,
    activeLevel,
    levelCriteriaCounts,
    rewards,
    locations,
  ) {
    if (mode === 1) {
      return (
        <div id="experienceForm">
          <div id="experienceFormContent">
            {/* {this.getSaveBar()} */}
            {this.displayDetailsForm(locations)}
          </div>
        </div>
      );
    } else if (mode === 2) {
      return (
        <div id="experienceForm">
          {/* {this.getSaveBar()} */}
          <div id="experienceFormContent">
            {this.displayRewardsForm(
              activeLevel,
              levelCriteriaCounts,
              rewards,
              locations,
            )}
          </div>
        </div>
      );
    } else if (mode === 4) {
      return (
        <div id="experienceForm">
          {/* {this.getSaveBar()} */}
          <div id="experienceFormContent">
            <div className="section">{this.getScheduleTab()}</div>
          </div>
        </div>
      );
    } else if (mode === 5) {
      return (
        <div id="experienceForm">
          {/* {this.getSaveBar()} */}
          <div id="experienceFormContent">{this.getTagTab()}</div>
        </div>
      );
    } else if (mode === 6) {
      return (
        <div id="experienceForm">
          {/* {this.getSaveBar()} */}
          <div id="experienceFormContent">{this.getReviewTab()}</div>
        </div>
      );
    } else {
      return (
        <div id="experienceForm">
          {/* {this.getSaveBar()} */}
          <div id="experienceFormContent">{this.getImagesTab()}</div>
        </div>
      );
    }
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              {/* <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span> */}
              <span className="vendorAppTitle">
                <span style={{ color: '#f21d59', fontSize: '22px' }}>
                  Create{' '}
                </span>
                Experience
              </span>
              {/* </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding"> */}
              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
              <div className="saveButtons">
                <a
                  onClick={() => {
                    this.createPromo();
                  }}
                >
                  <span className="saveButton">Save</span>
                </a>

                <Link
                  to={{
                    pathname: '/vendor/experiences',
                  }}
                >
                  <span className="cancelButton">Cancel</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getCategoryFields(cattag) {
    const tags = cattag.tags;
    const options = [];
    tags.forEach((tag) => {
      options.push({
        value: tag.tag,
        label: tag.tag,
      });
    });
    if (cattag.parent === 'Experience Type') {
      return (
        <div>
          <span className="inputLabel">{cattag.parent}</span>
          <Select
            value={this.state.experienceType}
            onChange={(value) => {
              this.handleOnTagChange('experienceType', value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  handleOnTagChange = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  getTagTab() {
    return (
      <div className="section">
        {this.props.tags.map((cattag, index) => (
          <div>{this.getCategoryFields(cattag)}</div>
        ))}
      </div>
    );
  }

  getParticipants(participants) {
    let vendorIds = [];
    let vendors = [];
    Object.keys(participants).forEach(function (key) {
      let id = Object.getOwnPropertyNames(participants[key]);
      vendorIds.push(key);
    });
    vendorIds.forEach((vendorId) => {
      const locationDetails = query('id')
        .is(vendorId)
        .on(this.props.vendors);
      let vendorDetails = locationDetails[0];
      vendorDetails.approved = participants[vendorId].approved;
      vendorDetails.rejected = participants[vendorId].rejected;
      vendors.push(vendorDetails);
    });
    return vendors;
  }

  differentTimeChange(event) {
    this.setState({
      differentTime: event.target.value,
    });
  }

  colabOneQuantityChange(e) {
    let num = Number(e.target.value);
    this.setState({ colabOneQuantity: num });
  }
  colabTwoQuantityChange(e) {
    let num = Number(e.target.value);
    this.setState({ colabTwoQuantity: num });
  }
  colabThreeQuantityChange(e) {
    let num = Number(e.target.value);
    this.setState({ colabThreeQuantity: num });
  }
  colabFourQuantityChange(e) {
    let num = Number(e.target.value);
    this.setState({ colabFourQuantity: num });
  }
  colabFiveQuantityChange(e) {
    let num = Number(e.target.value);
    this.setState({ colabFiveQuantity: num });
  }

  colabOneChange(event) {
    this.setState({
      colabOne: event.target.value,
    });
  }
  colabTwoChange(event) {
    this.setState({
      colabTwo: event.target.value,
    });
  }
  colabThreeChange(event) {
    this.setState({
      colabThree: event.target.value,
    });
  }
  colabFourChange(event) {
    this.setState({
      colabFour: event.target.value,
    });
  }
  colabFiveChange(event) {
    this.setState({
      colabFive: event.target.value,
    });
  }

  participantsTypeChange(event) {
    this.setState({
      participantsType: event.target.value,
    });
  }

  differentTimeChange(event) {
    this.setState({
      differentTime: event.target.value,
    });
  }

  onTimeChange = (time) => {
    this.setState({ selectedTime: time });
  };

  setSchedule(selectedStartDay, selectedEndDay) {
    let bundle = {
      startDate: null,
      startTimestamp: null,
      endDate: null,
      endTimestamp: null,
      oneDayEvent: null,
    };

    if (selectedStartDay === selectedEndDay) {
      bundle.oneDayEvent = true;
    } else {
      bundle.oneDayEvent = false;
    }

    // Check for Date
    if (
      selectedStartDay !== '' &&
      selectedStartDay !== undefined &&
      selectedStartDay !== null
    ) {
      const timestamp = moment(selectedStartDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.startDate = dateString;
      bundle.startTimestamp = timestampReady;
    }

    if (
      selectedEndDay !== '' &&
      selectedEndDay !== undefined &&
      selectedEndDay !== null
    ) {
      const timestamp = moment(selectedEndDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.endDate = dateString;
      bundle.endTimestamp = timestampReady;
    }
    return bundle;
  }

  setScheduleTimes(
    selectedStartTime,
    selectedEndTime,
    differentTime,
  ) {
    // Check if Time Set
    let bundle = {
      startTime: null,
      startTimeString: null,
      endTime: null,
      endTimeString: null,
      regHours: null,
    };

    if (differentTime === 'no') {
      bundle.regHours = true;
    } else {
      bundle.regHours = false;
    }

    if (
      selectedStartTime !== '' &&
      selectedStartTime !== undefined &&
      selectedStartTime !== null
    ) {
      const datestring = String(selectedStartTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      // const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.startTime = timeValue;
      bundle.startTimeString = selectedStartTime._d;
    }

    if (
      selectedEndTime !== '' &&
      selectedEndTime !== undefined &&
      selectedEndTime !== null
    ) {
      const datestring = String(selectedEndTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      // const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.endTime = timeValue;
      bundle.endTimeString = selectedEndTime._d;
    }

    return bundle;
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleStartDayClick(day, { selected }) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);

    // this.setState({
    //   selectedStartDay: selected ? undefined : day
    // });
  }

  handleEndDayClick(day, { selected }) {
    this.setState({
      selectedEndDay: selected ? undefined : day,
    });
  }

  onStartTimeChange = (time) => {
    this.setState({ selectedStartTime: time });
  };

  onEndTimeChange = (time) => {
    this.setState({ selectedEndTime: time });
  };

  getDifferentTimePicker(differentTime, modifiersStyles, today) {
    if (differentTime === 'yes') {
      return (
        <div>
          <div className="clear">
            <span className="inputLabel">Start Time</span>
            <TimePicker
              showSecond={false}
              defaultValue={this.state.selectedStartTime}
              className="xxx"
              onChange={this.onStartTimeChange}
              format={format}
              use12Hours
              inputReadOnly
            />
          </div>
          <div className="clear">
            <span className="inputLabel">End Time</span>
            <TimePicker
              showSecond={false}
              defaultValue={this.state.selectedEndTime}
              className="xxx"
              onChange={this.onEndTimeChange}
              format={format}
              use12Hours
              inputReadOnly
            />
          </div>
        </div>
      );
    }
  }

  getScheduleTab() {
    const { from, to } = this.state;
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
      },
    };
    const modifiers = { start: from, end: to };
    const today = new Date();

    return (
      <Container>
        <Row className="no-margin">
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <Row className="no-margin">
              <h5>Event Dates</h5>
              <div className="full-width">
                <p>
                  {!from && !to && 'Please select the first day.'}
                  {from && !to && 'Please select the last day.'}
                  {from &&
                    to &&
                    `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
                  {from && to && (
                    <button
                      className="link"
                      onClick={this.handleResetClick.bind(this)}
                    >
                      Reset
                    </button>
                  )}
                </p>
              </div>
              <div id="vendorSchedule">
                <DayPicker
                  onDayClick={this.handleStartDayClick}
                  selectedDays={[from, { from, to }]}
                  modifiers={modifiers}
                  modifiersStyles={modifiersStyles}
                  disabledDays={{ before: today }}
                />
              </div>
              {/* <p>
                    {this.state.selectedStartDay
                      ? this.state.selectedStartDay.toLocaleDateString()
                      : "Please select a day"}
                  </p> */}
            </Row>
            <Row>
              <div></div>
            </Row>
          </Col>
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <Row>
              <h5>Event Time</h5>
              <div className="full-width">
                <label>
                  Is the time of the event different than your regular
                  hours?
                </label>
              </div>
              <div className="clear">
                <div style={{ margin: '0x 10px 0px 0px' }}>
                  <label>
                    <input
                      type="radio"
                      value="no"
                      checked={this.state.differentTime === 'no'}
                      onChange={this.differentTimeChange.bind(this)}
                    />
                    No
                  </label>
                </div>

                <label>
                  <input
                    type="radio"
                    value="yes"
                    checked={this.state.differentTime === 'yes'}
                    onChange={this.differentTimeChange.bind(this)}
                  />
                  Yes
                </label>
              </div>
            </Row>
            <Row>
              {this.getDifferentTimePicker(
                this.state.differentTime,
                modifiersStyles,
                today,
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  handleResetClick() {
    this.setState({ from: undefined, to: undefined });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  setPreviewMode(mode) {
    this.setState({
      previewMode: mode,
    });
  }

  getLeftMenu() {
    return (
      <div className="vendorLeftNavButtons">
        {this.getTab(1, 'Details', this.state.mode, false)}
        {this.getTab(2, 'Rewards', this.state.mode, false)}
        {this.getTab(3, 'Images', this.state.mode, false)}
        {this.getTab(4, 'Schedule', this.state.mode, false)}
        {this.getTab(5, 'Tags', this.state.mode, false)}
      </div>
    );
  }

  getPromoImage(heroPath) {
    if (heroPath !== '') {
      return (
        <div className="promoImage">
          <img src={heroPath} />
        </div>
      );
    } else {
      return (
        <div className="promoImage">
          <span>hello</span>
        </div>
      );
    }
  }

  getCriteria(criteria, index) {
    let indexPlusOne = index + 1;
    if (criteria.type === 1) {
      if (criteria.numTimes < 2) {
        return (
          <div
            className="criteriaBorder"
            onClick={() => {
              this.setCriteriaDetailView(true, criteria);
            }}
          >
            <Row className=" no-margin no-padding">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="left no-padding"
              >
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="check_box_outline_blank"
                    size={25}
                    color="#9a0039"
                  />
                </span>
              </Col>
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="left no-padding"
              >
                <span className="criteriaText">{criteria.text}</span>
                <br></br>
                {criteria.locations.map((location, index) => (
                  <span className="locationTag">
                    {location.label}
                  </span>
                ))}
              </Col>
            </Row>
          </div>
        );
      } else {
        let currentNum = 0;
        let width =
          ((currentNum / criteria.numTimes) * 100).toFixed(2) + '%';
        return (
          <div className="criteriaBorder">
            <Row className=" no-margin no-padding">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="left no-padding"
              >
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="check_box_outline_blank"
                    size={25}
                    color="#9a0039"
                  />
                </span>
              </Col>
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="left no-padding"
              >
                <span className="criteriaText">{criteria.text}</span>
                <div id="progressBarBack">
                  <span className="right red">
                    <span className="progressGoal">
                      {criteria.numTimes}
                    </span>
                  </span>
                  <div
                    className="progressBar"
                    style={{ width: width }}
                  >
                    <span className="progressTotal">
                      {currentNum}
                    </span>
                  </div>
                </div>
                {criteria.locations.map((location, index) => (
                  <span className="locationTag">
                    {location.label}
                  </span>
                ))}
              </Col>
            </Row>
          </div>
        );
      }
    }
  }

  // getRewardIcon(index) {
  //   let status = false;
  //   if (index === 0) {
  //     let status = this.state.lockedOne;
  //   } else if (index === 1) {
  //     let status = this.state.lockedTwo;
  //   }
  //   if (index === 2) {
  //     let status = this.state.lockedThree;
  //   }
  //   if (index === 0) {
  //     let status = this.state.lockedFour;
  //   }
  //   if (reward.locked === false) {
  //     return <MaterialIcon icon="lock_open" size={25} color="red" />;
  //   } else if (reward.locked === true) {
  //     return <MaterialIcon icon="lock" size={25} color="red" />;
  //   }
  // }

  getGoldenTicket(reward, index) {
    let match = false;
    this.state.goldenTicketLevel.forEach((level, i) => {
      if (level.value === index) {
        match = true;
      }
    });
    if (match === true) {
      return (
        <span>
          <span style={{ color: 'black' }}>or </span>
          <span className="goldenTitle">
            {this.state.levelSix.label}
          </span>
        </span>
      );
    }
  }

  getReward(reward, index, promo, locationId) {
    return (
      <div className="promo-criteria">
        <div id={'toggler' + index}>
          <span>
            <span className="rewardIcon">
              {reward.locked && (
                <MaterialIcon icon="lock" size={25} color="red" />
              )}
              {!reward.locked && (
                <MaterialIcon
                  icon="lock_open"
                  size={25}
                  color="red"
                />
              )}
            </span>
            <span>
              <b>Level {index + 1}:</b>
            </span>{' '}
            <span className="rewardTitle">
              {reward.title} {this.getGoldenTicket(reward, index + 1)}
            </span>
          </span>
        </div>
        <UncontrolledCollapse
          toggler={'#toggler' + index}
          defaultOpen={true}
        >
          {reward.criteria.map((criteria, index) => (
            <div>{this.getCriteria(criteria, index)}</div>
          ))}
        </UncontrolledCollapse>
      </div>
    );
  }

  getVendorsLocations() {
    const docArray = [];
    this.props.vendor.locations.forEach((location) => {
      if (this.props.vendor.vendorId !== 'KZry0625YQCjZV99ZZh1') {
        const docDetails = query('locationId')
          .is(location)
          .on(this.props.alllocations);
        if (docDetails[0] !== undefined) {
          docArray.push(docDetails[0]);
        }
      } else {
        const docDetails = query('locationId')
          .is(location)
          .on(this.props.demo.demoLocations);
        if (docDetails[0] !== undefined) {
          docArray.push(docDetails[0]);
        }
      }
    });

    return docArray;
  }

  getPreviewRewards() {
    let levelOne = {
      id: this.state.levelOne.value,
      title: this.state.levelOne.label,
      locked: false,
      lockDependency: '',
      quantity: this.state.quantityOne,
    };
    let levelOneCriteria = [];
    this.state.levelOneCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelOneCriteria.push(criteria);
      }
    });
    levelOne.criteria = levelOneCriteria;

    // Level 2
    let levelTwo = {
      id: this.state.levelTwo.value,
      title: this.state.levelTwo.label,
      locked: this.state.lockedTwo,
      quantity: this.state.quantityTwo,
      status: 1,
    };
    let levelTwoCriteria = [];
    this.state.levelTwoCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelTwoCriteria.push(criteria);
      }
    });
    levelTwo.criteria = levelTwoCriteria;
    if (
      this.state.lockedTwo === 'on' ||
      this.state.lockedTwo === true
    ) {
      levelTwo.lockDependency = this.state.levelOne.value;
    } else {
      levelTwo.lockDependency = '';
    }

    // Level 3
    let levelThree = {
      id: this.state.levelThree.value,
      title: this.state.levelThree.label,
      locked: this.state.lockedThree,
      quantity: this.state.quantityThree,
      status: 1,
    };
    let levelThreeCriteria = [];
    this.state.levelThreeCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelThreeCriteria.push(criteria);
      }
    });
    levelThree.criteria = levelThreeCriteria;
    if (
      this.state.lockedThree === 'on' ||
      this.state.lockedThree === true
    ) {
      levelThree.lockDependency = this.state.levelThree.value;
    } else {
      levelThree.lockDependency = '';
    }

    // Level 4
    let levelFour = {
      id: this.state.levelFour.value,
      title: this.state.levelFour.label,
      locked: this.state.lockedFour,
      quantity: this.state.quantityFour,
      status: 1,
    };
    let levelFourCriteria = [];
    this.state.levelFourCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelFourCriteria.push(criteria);
      }
    });
    levelFour.criteria = levelFourCriteria;
    if (
      this.state.lockedFour === 'on' ||
      this.state.lockedFour === true
    ) {
      levelFour.lockDependency = this.state.levelFour.value;
    } else {
      levelFour.lockDependency = '';
    }

    // Level 5
    let levelFive = {
      id: this.state.levelFive.value,
      title: this.state.levelFive.label,
      locked: this.state.lockedFive,
      quantity: this.state.quantityFive,
      criteria: this.state.levelFiveCriteria,
      status: 1,
    };
    let levelFiveCriteria = [];
    this.state.levelFiveCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelFiveCriteria.push(criteria);
      }
    });
    levelFive.criteria = levelFiveCriteria;
    if (
      this.state.lockedFive === 'on' ||
      this.state.lockedFive === true
    ) {
      levelFive.lockDependency = this.state.levelFive.value;
    } else {
      levelFive.lockDependency = '';
    }

    let newRewards = [];
    if (levelOne.id !== undefined && levelOne.id !== '') {
      newRewards.push(levelOne);
    }
    if (levelTwo.id !== undefined && levelTwo.id !== '') {
      newRewards.push(levelTwo);
    }
    if (levelThree.id !== undefined && levelThree.id !== '') {
      newRewards.push(levelThree);
    }
    if (levelFour.id !== undefined && levelFour.id !== '') {
      newRewards.push(levelFour);
    }
    if (levelFive.id !== undefined && levelFive.id !== '') {
      newRewards.push(levelFive);
    }
    if (newRewards.length === 0) {
      return (
        <span style={{ color: 'grey' }}>
          No Rewards Added (Optional)
        </span>
      );
    } else {
      return (
        <div>
          {newRewards.map((reward, index) => (
            <div className="reward" key={index + 1}>
              {this.getReward(reward, index)}
            </div>
          ))}
        </div>
      );
    }
  }

  getPreviewSchedule() {
    let date = '';
    if (
      this.state.to !== undefined &&
      this.state.from !== undefined &&
      this.state.to !== null &&
      this.state.from !== null
    ) {
      if (this.state.to === this.state.from) {
        let from = this.state.from.toLocaleDateString();
        date = from;
      } else {
        let from = this.state.from.toLocaleDateString();
        let to = this.state.to.toLocaleDateString();
        date = from + ' - ' + to;
      }
    } else {
      date = 'Not Scheduled (Optional)';
    }
    return date;
  }

  setCriteriaDetailView(mode, criteria) {
    this.setState({
      criteriaDetailView: mode,
      activeCriteriaPreview: criteria,
    });
  }

  getPreview(mode) {
    let heroPath = this.state.heroPath;
    let title = this.state.title;
    let description = this.state.description;

    const startTimestamp = moment(this.state.selectedStartDay).unix();
    const startDateString = moment
      .unix(startTimestamp)
      .format('MM-DD-YYYY');
    const endTimestamp = moment(this.state.selectedEndDay).unix();
    const endDateString = moment
      .unix(endTimestamp)
      .format('MM-DD-YYYY');

    if (this.state.criteriaDetailView === true) {
      return (
        <div id="previewArea">
          <div className="promo criteriaDetails">
            <div className="right">
              <MaterialIcon
                icon="close"
                size={25}
                color="white"
                onClick={() => {
                  this.setCriteriaDetailView(false);
                }}
              />
            </div>
            <div
              style={{
                clear: 'both',
                display: 'block',
                padding: '10px',
              }}
            >
              <span style={{ color: 'white', fontSize: '18px' }}>
                {this.state.activeCriteriaPreview.text}
              </span>
              <hr></hr>
              <div style={{ minHeight: '350px' }}>
                {this.state.activeCriteriaPreview.guidelines !==
                  '' && (
                  <span style={{ color: 'white' }}>
                    {this.state.activeCriteriaPreview.guidelines}
                  </span>
                )}
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <span
                style={{
                  background: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  color: '#f21d59',
                }}
              >
                Get Validated!
              </span>
            </div>
          </div>
        </div>
      );
    } else if (mode === 1) {
      return (
        <div id="previewArea">
          <div className="promo">
            {heroPath !== '' ? (
              this.getPromoImage(heroPath)
            ) : (
              <div
                style={{
                  background: '#9a9a9a',
                  textAlign: 'center',
                  minHeight: '150px',
                }}
              >
                <div>
                  <span
                    style={{
                      position: 'relative',
                      display: 'block',
                      fontWeight: '100',
                      top: '60px',
                    }}
                  >
                    Please select a Banner Image
                  </span>
                </div>
              </div>
            )}
            <div className="promoBody">
              <span className="promo-reward">
                {title != '' ? title : 'Please add a title'}
              </span>
              <span className="promo-criteria">
                {description != ''
                  ? description
                  : 'You still need to add a description'}
              </span>
              <span className="promo-criteria">
                {this.getPreviewSchedule()}
              </span>
              <div style={{ marginBottom: '20px' }}>
                {this.state.experienceType &&
                  this.state.experienceType.map((tag, index) => (
                    <span className="experienceTag">{tag.label}</span>
                  ))}
              </div>
              {this.getPreviewRewards()}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="previewArea">
          <div>
            <Row className="oddeven">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <div className="full-height left">
                  <img
                    src={this.state.thumbPath}
                    className="thumbnail"
                  />
                </div>

                <span className="location-title">
                  {this.state.title}
                </span>
                <span className="address">
                  {this.state.description}
                </span>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }

  getSaveBar() {
    return (
      <div id="saveBar">
        <a
          onClick={() => {
            this.createPromo();
          }}
        >
          <span className="saveButton">Save</span>
        </a>
        <span>{this.state.title}</span>
        <Link
          to={{
            pathname: '/vendor/experiences',
          }}
        >
          <span className="cancelButton">Cancel</span>
        </Link>
      </div>
    );
  }

  getAppContent(rewards, locations) {
    return (
      <div
        style={{
          height: '100%',
          // display: 'inline-table',
          width: '100%',
        }}
      >
        <div
          className="vendorLeftNav"
          style={{ height: this.state.height + 'px' }}
        >
          <div style={{ padding: '10px' }}>
            <span style={{ fontWeight: '600', color: 'white' }}>
              SETUP
            </span>
          </div>

          {this.getLeftMenu()}
          {this.state.community === true && (
            <div>
              <div style={{ background: 'grey' }}>
                <div style={{ padding: '10px' }}>
                  <span style={{ fontWeight: '600', color: 'white' }}>
                    COLLABORATION
                  </span>
                </div>
              </div>
              <div className="vendorLeftNavButtons">
                <div className="actionButton noBorderRight  actionButtonInactive">
                  Invite Sent After Saving
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          ref={this.divElement}
          style={{ display: 'inline-table', width: '60% ' }}
        >
          {this.getSection(
            this.state.mode,
            this.state.activeLevel,
            this.state.levelCriteriaCounts,
            rewards,
            locations,
          )}
        </div>

        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <div
          id="previewer"
          style={{ height: this.state.height + 'px' }}
        >
          <div id="previewTabs">
            <span style={{ float: 'left' }}>PREVIEW</span>
            <a
              className={
                this.state.previewMode === 2
                  ? 'activePreviewTab'
                  : 'previewTab'
              }
              onClick={() => {
                this.setPreviewMode(2);
              }}
            >
              <span className="right">List</span>
            </a>
            <a
              className={
                this.state.previewMode === 1
                  ? 'activePreviewTab'
                  : 'previewTab'
              }
              onClick={() => {
                this.setPreviewMode(1);
              }}
            >
              <span className="right">Details</span>
            </a>
          </div>
          {this.getPreview(this.state.previewMode)}
        </div>

        <style
          dangerouslySetInnerHTML={{
            __html:
              '.inputLabel {font-weight: 400;} .container {max-width: 100%; !important} .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }

  openRewardModal() {
    this.setState({ rewardModalVisible: true });
  }

  closeRewardModal() {
    this.setState({ rewardModalVisible: false });
  }

  openLocationModal() {
    this.setState({ locationModalVisible: true });
  }

  closeLocationModal() {
    this.setState({ locationModalVisible: false });
  }

  getVendorRewards() {
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={11} md={11} sm={11} lg={11} className="left">
                  <span className="label">Reward</span>
                </Col>
                {/* <Col xs={3} md={3} sm={3} lg={3} className="left">
                  <span className="label">Promos</span>
                </Col>
                <Col
                  xs={2}
                  md={2}
                  sm={2}
                  lg={2}
                  className="text-center"
                >
                  <span className="label">Available</span>
                </Col>
                <Col
                  xs={1}
                  md={1}
                  sm={1}
                  lg={1}
                  className="text-center"
                >
                  <span className="label">Earned</span>
                </Col>
                <Col
                  xs={1}
                  md={1}
                  sm={1}
                  lg={1}
                  className="text-center"
                >
                  <span className="label">Redeemeed</span>
                </Col> */}
                <Col
                  xs={1}
                  md={1}
                  sm={1}
                  lg={1}
                  className="left"
                ></Col>
              </div>
            </li>
            {this.props.vendorRewards.map((reward, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={11}
                    md={11}
                    sm={11}
                    lg={11}
                    key={'key' + index}
                  >
                    {/* <Link
                      to={{
                        pathname: '/vendor/reward/' + reward.id,
                      }}
                    > */}
                    {reward.title}
                    {/* </Link> */}
                  </Col>
                  {/* <Col xs={3} md={3} sm={3} lg={3} className="left">
                    {this.getPromosIncluded(reward.id)}
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    sm={2}
                    lg={2}
                    className="text-center"
                  >
                    {this.getAvailable(reward.id)}
                  </Col>
                  <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="text-center"
                  >
                    {this.getEarned(reward.id)}
                  </Col>
                  <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="text-center"
                  >
                    {this.getRedeemed(reward.id)}
                  </Col> */}
                  <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="text-right"
                  >
                    {this.getEditButton(reward)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getLocationStatus(location) {
    let newLocations = this.state.selectedLocations;
    const locationCheck = query('id')
      .is(location.locationId)
      .on(newLocations);
    if (locationCheck[0] !== undefined) {
      return 'adminlistitem selectedLocationBack';
    } else {
      return 'adminlistitem';
    }
  }

  getEntertainmentStatus(entertainment) {
    let newLocations = this.state.selectedLocations;
    const locationCheck = query('id')
      .is(entertainment.entertainmentId)
      .on(newLocations);
    if (locationCheck[0] !== undefined) {
      return 'adminlistitem selectedLocationBack';
    } else {
      return 'adminlistitem';
    }
  }

  removeParticipant(id) {
    let newParticipants = [];
    this.state.selectedLocations.forEach((participant) => {
      if (participant.id !== id) {
        newParticipants.push(participant);
      }
    });
    this.setState({
      selectedLocations: newParticipants,
    });
  }

  getGenreTag(tag) {
    return <span className="mainTagLeft">{tag}</span>;
  }

  setParticipant(selectedParticipant, type) {
    let vendorIds = [];
    this.props.vendor.locations.forEach((id) => {
      vendorIds.push(id);
    });
    this.props.vendor.entertainment.forEach((id) => {
      vendorIds.push(id);
    });

    let participant = [];
    let newParticipants = this.state.selectedLocations;
    let participantId = '';
    if (selectedParticipant.locationId !== undefined) {
      participantId = selectedParticipant.locationId;
    } else {
      participantId = selectedParticipant.entertainmentId;
    }

    let community = false;
    let status = vendorIds.includes(participantId);
    if (status === false) {
      community = true;
    }
    let vendorId = this.props.profile.vendorAccount.id;
    if (selectedParticipant.vendorId !== undefined) {
      vendorId = selectedParticipant.vendorId;
    }
    const check = query('id').is(participantId).on(newParticipants);
    if (check[0] === undefined) {
      const today = new Date();
      if (type === 1) {
        participant = {
          id: participantId,
          approved: true,
          approverId: '',
          title: selectedParticipant.locationTitle,
          approvalDate: today,
          locationThumb: selectedParticipant.locationThumb,
          address: selectedParticipant.address,
          settings: selectedParticipant.settings,
          type: 1,
          vendorId,
        };
      } else {
        participant = {
          id: participantId,
          approved: true,
          approverId: '',
          title: selectedParticipant.entertainmentTitle,
          approvalDate: today,
          locationThumb: selectedParticipant.entertainmentThumb,
          address: '',
          settings: selectedParticipant.settings,
          type: 2,
          vendorId,
        };
      }
      newParticipants.push(participant);
    } else {
      newParticipants = newParticipants.filter(function (obj) {
        return obj.id !== participantId;
      });
    }

    // mode 1 = add mode 2 = remove

    this.setState({
      selectedLocations: newParticipants,
      community,
    });
  }

  getCommitment(reward, index, promo, locationId) {
    return (
      <div>
        {reward.criteria.map((criteria, index) => (
          <div>{this.getReviewCriteria(criteria, index)}</div>
        ))}
      </div>
    );
  }

  getReviewCommitments() {
    let vendorLocations = this.getVendorsLocations();
    // Level 1
    let levelOne = {};
    let otherLevelOne = [];
    this.state.otherVendorRewards.forEach((reward, i) => {
      if (reward.level === 1) {
        otherLevelOne.push(reward.title);
      }
    });
    if (
      this.state.levelOne.value !== undefined &&
      otherLevelOne.length === 0
    ) {
      levelOne = {
        id: this.state.levelOne.value,
        title: this.state.levelOne.label,
        locked: false,
        lockDependency: '',
        quantity: this.state.quantityOne,
        status: 1,
      };
    } else if (
      this.state.levelOne.value !== undefined &&
      otherLevelOne.length > 0
    ) {
      let joiner = ' or ';
      if (this.state.colabOne === 'all') {
        joiner = ' & ';
      }
      let title =
        otherLevelOne.join() + joiner + this.state.levelOne.label;
      levelOne = {
        id: this.state.levelOne.value,
        title: title,
        locked: false,
        lockDependency: '',
        quantity: this.state.quantityOne,
        status: 1,
      };
    } else if (
      this.state.levelOne.value === undefined &&
      otherLevelOne.length > 0
    ) {
      let title = otherLevelOne.join(', ');
      levelOne = {
        id: this.state.levelOne.value,
        title: title,
        locked: false,
        lockDependency: '',
        quantity: this.state.quantityOne,
        status: 1,
      };
    }
    let levelOneCriteria = [];
    this.state.levelOneCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelOneCriteria.push(criteria);
      }
    });
    levelOne.criteria = levelOneCriteria;
    // Level 2
    let levelTwo = {};
    let otherLevelTwo = [];
    this.state.otherVendorRewards.forEach((reward, i) => {
      if (reward.level === 2) {
        otherLevelTwo.push(reward.title);
      }
    });
    if (
      this.state.levelTwo.value !== undefined &&
      otherLevelTwo.length === 0
    ) {
      levelTwo = {
        id: this.state.levelTwo.value,
        title: this.state.levelTwo.label,
        locked: this.state.lockedTwo,
        lockDependency: '',
        quantity: this.state.quantityTwo,
        status: 1,
      };
    } else if (
      this.state.levelTwo.value !== undefined &&
      otherLevelTwo.length > 0
    ) {
      let joiner = ' or ';
      if (this.state.colabTwo === 'all') {
        joiner = ' & ';
      }
      let title =
        otherLevelTwo.join() + joiner + this.state.levelTwo.label;
      levelTwo = {
        id: this.state.levelTwo.value,
        title: title,
        locked: this.state.lockedTwo,
        lockDependency: '',
        quantity: this.state.quantityTwo,
        status: 1,
      };
    } else if (
      this.state.levelTwo.value === undefined &&
      otherLevelTwo.length > 0
    ) {
      let title = otherLevelTwo.join(', ');
      levelTwo = {
        id: null,
        title: title,
        locked: this.state.lockedTwo,
        lockDependency: '',
        quantity: '',
        status: 1,
      };
    }

    let levelTwoCriteria = [];
    this.state.levelTwoCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        criteria.locations.forEach((location, i) => {
          vendorLocations.forEach((locationx, i) => {
            if (location.value === locationx.locationId) {
              levelTwoCriteria.push(criteria);
            }
          });
        });
      }
    });
    levelTwo.criteria = levelTwoCriteria;
    if (
      this.state.lockedTwo === 'on' ||
      this.state.lockedTwo === true
    ) {
      levelTwo.lockDependency = this.state.levelOne.value;
    } else {
      levelTwo.lockDependency = '';
    }
    // Level 3
    let levelThree = {};
    let otherLevelThree = [];
    this.state.otherVendorRewards.forEach((reward, i) => {
      if (reward.level === 3) {
        otherLevelThree.push(reward.title);
      }
    });
    if (
      this.state.levelThree.value !== undefined &&
      otherLevelThree.length === 0
    ) {
      levelThree = {
        id: this.state.levelThree.value,
        title: this.state.levelThree.label,
        locked: this.state.lockedThree,
        lockDependency: '',
        quantity: this.state.quantityThree,
        status: 1,
      };
    } else if (
      this.state.levelThree.value !== undefined &&
      otherLevelThree.length > 0
    ) {
      let joiner = ' or ';
      if (this.state.colabThree === 'all') {
        joiner = ' & ';
      }
      let title =
        otherLevelThree.join() + joiner + this.state.levelThree.label;
      levelThree = {
        id: this.state.levelThree.value,
        title: title,
        locked: this.state.lockedThree,
        lockDependency: '',
        quantity: this.state.quantityThree,
        status: 1,
      };
    } else if (
      this.state.levelThree.value === undefined &&
      otherLevelThree.length > 0
    ) {
      let title = otherLevelThree.join(', ');
      levelThree = {
        id: null,
        title: title,
        locked: this.state.lockedThree,
        lockDependency: '',
        quantity: '',
        status: 1,
      };
    }

    let levelThreeCriteria = [];
    this.state.levelThreeCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        criteria.locations.forEach((location, i) => {
          vendorLocations.forEach((locationx, i) => {
            if (location.value === locationx.locationId) {
              levelThreeCriteria.push(criteria);
            }
          });
        });
      }
    });

    levelThree.criteria = levelThreeCriteria;
    if (
      this.state.lockedThree === 'on' ||
      this.state.lockedThree === true
    ) {
      levelThree.lockDependency = this.state.levelOne.value;
    } else {
      levelThree.lockDependency = '';
    }
    // Level 4
    let levelFour = {};
    let otherLevelFour = [];
    this.state.otherVendorRewards.forEach((reward, i) => {
      if (reward.level === 4) {
        otherLevelFour.push(reward.title);
      }
    });
    if (
      this.state.levelFour.value !== undefined &&
      otherLevelFour.length === 0
    ) {
      levelFour = {
        id: this.state.levelFour.value,
        title: this.state.levelFour.label,
        locked: this.state.lockedFour,
        lockDependency: '',
        quantity: this.state.quantityFour,
        status: 1,
      };
    } else if (
      this.state.levelFour.value !== undefined &&
      otherLevelFour.length > 0
    ) {
      let joiner = ' or ';
      if (this.state.colabFour === 'all') {
        joiner = ' & ';
      }
      let title =
        otherLevelFour.join() + joiner + this.state.levelFour.label;
      levelFour = {
        id: this.state.levelFour.value,
        title: title,
        locked: this.state.lockedFour,
        lockDependency: '',
        quantity: this.state.quantityFour,
        status: 1,
      };
    } else if (
      this.state.levelFour.value === undefined &&
      otherLevelFour.length > 0
    ) {
      let title = otherLevelFour.join(', ');
      levelFour = {
        id: null,
        title: title,
        locked: this.state.lockedFour,
        lockDependency: '',
        quantity: '',
        status: 1,
      };
    }

    let levelFourCriteria = [];
    this.state.levelFourCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        criteria.locations.forEach((location, i) => {
          vendorLocations.forEach((locationx, i) => {
            if (location.value === locationx.locationId) {
              levelFourCriteria.push(criteria);
            }
          });
        });
      }
    });

    levelFour.criteria = levelFourCriteria;
    if (
      this.state.lockedFour === 'on' ||
      this.state.lockedFour === true
    ) {
      levelFour.lockDependency = this.state.levelOne.value;
    } else {
      levelFour.lockDependency = '';
    }
    // Level 5
    let levelFive = {};
    let otherLevelFive = [];
    this.state.otherVendorRewards.forEach((reward, i) => {
      if (reward.level === 5) {
        otherLevelFive.push(reward.title);
      }
    });
    if (
      this.state.levelFive.value !== undefined &&
      otherLevelFive.length === 0
    ) {
      levelFive = {
        id: this.state.levelFive.value,
        title: this.state.levelFive.label,
        locked: this.state.lockedFive,
        lockDependency: '',
        quantity: this.state.quantityFive,
        status: 1,
      };
    } else if (
      this.state.levelFive.value !== undefined &&
      otherLevelFive.length > 0
    ) {
      let joiner = ' or ';
      if (this.state.colabFive === 'all') {
        joiner = ' & ';
      }
      let title =
        otherLevelFive.join() + joiner + this.state.levelFive.label;
      levelFive = {
        id: this.state.levelFive.value,
        title: title,
        locked: this.state.lockedFive,
        lockDependency: '',
        quantity: this.state.quantityFive,
        status: 1,
      };
    } else if (
      this.state.levelFive.value === undefined &&
      otherLevelFive.length > 0
    ) {
      let title = otherLevelFive.join(', ');
      levelFive = {
        id: null,
        title: title,
        locked: this.state.lockedFive,
        lockDependency: '',
        quantity: '',
        status: 1,
      };
    }
    let levelFiveCriteria = [];
    this.state.levelFiveCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        criteria.locations.forEach((location, i) => {
          vendorLocations.forEach((locationx, i) => {
            if (location.value === locationx.locationId) {
              levelFiveCriteria.push(criteria);
            }
          });
        });
      }
    });
    levelFive.criteria = levelFiveCriteria;
    if (
      this.state.lockedFive === 'on' ||
      this.state.lockedFive === true
    ) {
      levelFive.lockDependency = this.state.levelOne.value;
    } else {
      levelFive.lockDependency = '';
    }
    let newRewards = [];
    if (levelOne.id !== undefined && levelOne.id !== '') {
      newRewards.push(levelOne);
    }
    if (levelTwo.id !== undefined && levelTwo.id !== '') {
      newRewards.push(levelTwo);
    }
    if (levelThree.id !== undefined && levelThree.id !== '') {
      newRewards.push(levelThree);
    }
    if (levelFour.id !== undefined && levelFour.id !== '') {
      newRewards.push(levelFour);
    }
    if (levelFive.id !== undefined && levelFive.id !== '') {
      newRewards.push(levelFive);
    }

    return (
      <div>
        {newRewards.map((reward, index) => (
          <div key={index + 1}>
            {this.getCommitment(reward, index)}
          </div>
        ))}
      </div>
    );
  }

  getVendorLocations() {
    let locations = [];
    this.props.vendor.locations.forEach((location, i) => {
      const locationDetails = query('locationId')
        .is(location)
        .on(this.props.locations);
      if (locationDetails[0] !== undefined) {
        locations.push(locationDetails[0]);
      } else {
        if (locationDetails[0] === undefined) {
          const locationDetails = query('locationId')
            .is(location)
            .on(this.props.demo.demoLocations);

          if (locationDetails[0] !== undefined) {
            locations.push(locationDetails[0]);
          }
        }
      }
    });

    if (this.state.participantsType === 'destinations') {
      const filteredLocations = locations.filter(
        createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
      );
      return (
        <div
          className="section"
          style={{ top: '35px', position: 'relative' }}
        >
          <div className="fullwidth">
            <div style={{ clear: 'both', display: 'inline-block' }}>
              <div
                style={{
                  marginBottom: '10px',
                  float: 'left',
                  width: '400px',
                }}
              >
                <SearchInput
                  className="search-input"
                  onChange={this.searchUpdated}
                  style={{ float: 'left' }}
                />
              </div>

              <div
                style={{ float: 'left', margin: '10px 0px 0px 10px' }}
              >
                <label
                  style={{
                    float: 'left',
                    marginRight: '10px',
                  }}
                >
                  <input
                    style={{
                      marginRight: '5px',
                      float: 'left',
                      minWidth: 'unset',
                      position: 'relative',
                      top: '5px',
                    }}
                    type="radio"
                    value="destinations"
                    checked={
                      this.state.participantsType === 'destinations'
                    }
                    onChange={this.participantsTypeChange.bind(this)}
                  />
                  Destinations
                </label>

                <label
                  style={{
                    float: 'left',
                    marginRight: '10px',
                  }}
                >
                  <input
                    style={{
                      marginRight: '5px',
                      float: 'left',
                      minWidth: 'unset',
                      position: 'relative',
                      top: '5px',
                    }}
                    type="radio"
                    value="entertainment"
                    checked={
                      this.state.participantsType === 'entertainment'
                    }
                    onChange={this.participantsTypeChange.bind(this)}
                  />
                  Entertainment
                </label>
              </div>
            </div>
            <ul className="no-padding no-margin">
              {filteredLocations.map((location, index) => (
                <li
                  className={this.getLocationStatus(location)}
                  key={'key' + index}
                  onClick={(setLocation) => {
                    this.setParticipant(location, 1);
                  }}
                >
                  <div className="fullwidth">
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      key={'key' + index}
                      className="no-padding"
                    >
                      <Row>
                        <Col
                          xs={4}
                          md={4}
                          sm={4}
                          lg={4}
                          className="no-padding thumbnailCol"
                        >
                          {this.getImage(
                            'thumb',
                            location,
                            this.props.locations,
                          )}
                        </Col>
                        <Col xs={8} md={8} sm={8} lg={8}>
                          <span className="location-title">
                            {location.locationTitle}
                          </span>
                          <span className="address">
                            {location.address.street}
                          </span>
                          <span className="address">
                            {location.address.city}{' '}
                            {location.address.state},{' '}
                            {location.address.zip}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    } else {
      let entertainment = this.props.vendorEntertainment;
      const filteredEntertainers = entertainment.filter(
        createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
      );
      return (
        <Row
          className="section"
          style={{ top: '35px', position: 'relative' }}
        >
          <div className="fullwidth">
            <div style={{ clear: 'both', display: 'inline-block' }}>
              <div
                style={{
                  marginBottom: '10px',
                  float: 'left',
                  width: '400px',
                }}
              >
                <SearchInput
                  className="search-input"
                  onChange={this.searchUpdated}
                  style={{ float: 'left' }}
                />
              </div>

              <div
                style={{ float: 'left', margin: '10px 0px 0px 10px' }}
              >
                <label
                  style={{
                    float: 'left',
                    marginRight: '10px',
                  }}
                >
                  <input
                    style={{
                      marginRight: '5px',
                      float: 'left',
                      minWidth: 'unset',
                      position: 'relative',
                      top: '5px',
                    }}
                    type="radio"
                    value="destinations"
                    checked={
                      this.state.participantsType === 'destinations'
                    }
                    onChange={this.participantsTypeChange.bind(this)}
                  />
                  Destinations
                </label>

                <label
                  style={{
                    float: 'left',
                    marginRight: '10px',
                  }}
                >
                  <input
                    style={{
                      marginRight: '5px',
                      float: 'left',
                      minWidth: 'unset',
                      position: 'relative',
                      top: '5px',
                    }}
                    type="radio"
                    value="entertainment"
                    checked={
                      this.state.participantsType === 'entertainment'
                    }
                    onChange={this.participantsTypeChange.bind(this)}
                  />
                  Entertainment
                </label>
              </div>
            </div>
            <ul className="no-padding no-margin">
              {filteredEntertainers.map((entertainer, index) => (
                <li
                  className={this.getEntertainmentStatus(entertainer)}
                  key={'key' + index}
                  onClick={(setLocation) => {
                    this.setParticipant(entertainer, 2);
                  }}
                >
                  <div className="fullwidth">
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      key={'key' + index}
                      className="no-padding"
                    >
                      <Row>
                        <Col
                          xs={4}
                          md={4}
                          sm={4}
                          lg={4}
                          className="no-padding thumbnailCol"
                        >
                          <img
                            src={entertainer.entertainmentThumb}
                            alt={entertainer.entertainmentTitle}
                            className="thumbnail"
                          />
                        </Col>
                        <Col xs={8} md={8} sm={8} lg={8}>
                          <span className="location-title">
                            {entertainer.entertainmentTitle}
                          </span>
                          {this.getGenreTag(entertainer.tagsGenre)}
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Row>
      );
    }
  }
  setLocation(selectedLocation) {
    let newLocations = this.state.selectedLocations;

    const locationCheck = query('locationId')
      .is(selectedLocation.locationId)
      .on(newLocations);

    if (locationCheck[0] === undefined) {
      const today = new Date();
      let location = {
        approved: true,
        approverId: 'rX8stcgqvqXvrJVJS3FAiqvRwcG3',
        locationId: selectedLocation.locationId,
        locationTitle: selectedLocation.locationTitle,
        approvalDate: today,
        locationThumb: selectedLocation.locationThumb,
        address: selectedLocation.address,
        settings: selectedLocation.settings,
      };
      newLocations.push(location);
    } else {
      newLocations = newLocations.filter(function (obj) {
        return obj.locationId !== selectedLocation.locationId;
      });
    }

    // mode 1 = add mode 2 = remove

    this.setState({
      selectedLocations: newLocations,
    });
  }

  getEditButton(reward) {
    return (
      <a
        onClick={() => {
          this.setEditRewardFormActive(true, reward);
        }}
      >
        <MaterialIcon icon="edit" size={25} color="red" />
      </a>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    let rewards = [];
    this.props.vendorRewards.forEach((event, i) => {
      rewards.push({
        value: event.id,
        label: event.title,
      });
    });
    let locations = [];
    this.state.selectedLocations.forEach((location, i) => {
      locations.push({
        value: location.id,
        label: location.title,
      });
    });
    return (
      <div id="vendor">
        <div>{this.getBannerCropper(this.state.cropping)}</div>
        <div>
          {this.getThumbnailCropper(this.state.croppingThumb)}
        </div>
        {this.getTitleBar('Create Experience')}
        {this.getAppContent(rewards, locations)}
        <Modal
          isOpen={this.state.rewardModalVisible}
          onRequestClose={this.closeRewardModal}
          style={conversationModalStyles}
          contentLabel="Reward Modal"
        >
          {this.state.createNewRewardFormActive === true ? (
            <VendorRewardForm
              setCreateNewRewardFormActive={
                this.setCreateNewRewardFormActive
              }
              type={1}
            />
          ) : this.state.editRewardFormActive === true ? (
            <VendorRewardForm
              setEditRewardFormActive={this.setEditRewardFormActive}
              activeReward={this.state.editActiveReward}
              type={2}
            />
          ) : (
            <div>
              <div className="vendorModalHeader">
                <span className="vendorAppTitle">
                  Rewards Manager
                </span>
                <div className="saveButtons">
                  <a
                    onClick={() => {
                      this.setCreateNewRewardFormActive(true);
                    }}
                  >
                    <span className="saveButton">
                      Create New Reward
                    </span>
                  </a>

                  {this.props.vendorRewards.length !== 0 && (
                    <a
                      onClick={() => {
                        this.closeRewardModal();
                      }}
                    >
                      <span className="cancelButton">Close</span>
                    </a>
                  )}
                </div>
              </div>
              {this.getVendorRewards()}
              {this.props.vendorRewards.length === 0 && (
                <div
                  style={{
                    padding: '20px',
                    border: '1px solid lightgrey',
                    margin: '20px auto',
                    width: '95%',
                    marginTop: '100px',
                    background: 'white',
                    display: 'flex',
                  }}
                >
                  <Col xs={1} md={1} sm={1} lg={1}>
                    <div
                      style={{
                        float: 'left',
                        display: 'block',
                        position: 'relative',
                        top: '-2px',
                      }}
                    >
                      <MaterialIcon
                        icon="help_outline"
                        size={35}
                        color="#000000"
                      />
                    </div>
                  </Col>
                  <Col xs={11} md={11} sm={11} lg={11}>
                    <h4
                      style={{
                        fontSize: '18px',
                        marginBottom: '10px',
                      }}
                    >
                      Rewards
                    </h4>
                    <p style={{ padding: '0px' }}>
                      Rewards are offered to users during experiences.
                    </p>
                    <p>
                      To get started with your first Experience, lets
                      setup at least 1 Reward.
                    </p>
                    <p style={{ padding: '0px', margin: '0px' }}>
                      Click on{' '}
                      <span style={{ fontWeight: '600' }}>
                        Create New Reward
                      </span>{' '}
                      to get started.
                    </p>
                  </Col>
                </div>
              )}
            </div>
          )}
        </Modal>
        <Modal
          isOpen={this.state.locationModalVisible}
          onRequestClose={this.closeLocationModal}
          style={conversationModalStyles}
          contentLabel="Location Modal"
        >
          <div>
            <div
              className="vendorModalHeader"
              style={{ borderBottom: '1px solid grey' }}
            >
              <span className="vendorAppTitle">
                Participating Destinations & Entertainment
              </span>
              <div className="saveButtons">
                {/* <a
                  onClick={() => {
                    this.setCreateNewRewardFormActive(true);
                  }}
                >
                  <span className="saveButton">
                    Create New Reward
                  </span>
                </a> */}
                <a
                  onClick={() => {
                    this.closeLocationModal();
                  }}
                >
                  <span className="cancelButton">Close</span>
                </a>
              </div>
            </div>
            <div
              className="adminActionBar"
              style={{ width: '100%', top: 'unset' }}
            >
              <Row>
                <Col
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  className="no-padding"
                >
                  {this.getParticipantsTab(
                    1,
                    'Your Account',
                    this.state.participantsMode,
                  )}
                </Col>
                <Col
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  className="no-padding"
                >
                  {this.getParticipantsTab(
                    2,
                    'Community',
                    this.state.participantsMode,
                  )}
                </Col>
              </Row>
            </div>
            {this.state.participantsMode === 1
              ? this.getVendorLocations()
              : this.getCommunityLocations(this.props.vendors)}
          </div>
        </Modal>

        <Modal
          isOpen={this.state.vendorImagesModalVisible}
          style={conversationModalStyles}
          contentLabel="Vendor Images Modal"
        >
          <div>
            <div className="vendorModalHeader">
              <span className="vendorAppTitle">Vendor Images</span>
              <div className="saveButtons">
                <a
                  onClick={() => {
                    this.closeVendorImagesModal();
                  }}
                >
                  <span className="cancelButton">Close</span>
                </a>
              </div>
            </div>
            {this.showVendorImages()}
          </div>
        </Modal>

        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%}  #analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customEvent: state.customEvent.customEvent,
    locations: state.locations.locations,
    events: state.events.events,
    packages: state.packages.packages,
    profile: state.profile.profile,
    packageBundle: state.newPackage,
    vendor: state.vendor.vendor,
    vendors: state.vendors.vendors,
    vendorEvents: state.vendorEvents.events,
    vendorPromos: state.vendorPromos.promos,
    vendorRewards: state.vendorRewards.rewards,
    tags: state.tags.tags,
    demo: state.demo,
    user: state.user,
    vendorEntertainment: state.vendorEntertainment.entertainment,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorPromos: (id) => {
      dispatch(getVendorPromos(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorCreatePromo);
