import firebase from '../config/firebase';

// ADD CONNECTIONS IS IN INVITATION SERVICES
export function getConnections(userId) {
  const connectionsArray = [];
  const db = firebase.firestore();

  const getP = function () {
    return new Promise((resolve, reject) => {
      const docRef = db.collection('connections').doc(userId);
      docRef
        .get()
        .then((doc) => {
          if (doc && doc.exists) {
            const { connections } = doc.data();
            connectionsArray.push({
              connections,
            });
          }
          return connectionsArray;
        })
        .then((result) => {
          const final_connections = result[0];
          resolve(final_connections);
        });
    });
  };
  return getP();
}

// Remove Connection Part 1
export function removeConnectionOne(userId, newUserId) {
  const timestamp = new Date();
  const db = firebase.firestore();
  const newConnectionUpdate = db
    .collection('connections')
    .doc(userId);
  const connectionObject = {
    connected: false,
    created: timestamp,
    userId: newUserId,
  };
  const getV = function () {
    return new Promise((resolve, reject) => {
      const setDoc = newConnectionUpdate
        .update({
          ['connections.' + newUserId]: connectionObject,
        })
        .then(() => {
          // console.log("Write succeeded!");
          resolve();
        });
    });
  };
  return getV();
}

// Remove Connection Part 2
export function removeConnectionTwo(userId, newUserId) {
  const timestamp = new Date();
  const db = firebase.firestore();
  const newConnection = db.collection('connections').doc(newUserId);
  const connectionObject = {
    connected: false,
    created: timestamp,
    userId: userId,
  };
  const getV = function () {
    return new Promise((resolve, reject) => {
      const setDoc = newConnection
        .update({
          ['connections.' + userId]: connectionObject,
        })
        .then(() => {
          // console.log("Write succeeded!");
          resolve();
        });
    });
  };
  return getV();
}

// Fav Connection
export function favConnection(bundle) {
  const timestamp = new Date();
  const db = firebase.firestore();
  const newConnection = db
    .collection('connections')
    .doc(bundle.userId);
  const connectionObject = {
    connected: true,
    created: timestamp,
    userId: bundle.contactId,
  };
  if (bundle.mode === 1) {
    connectionObject.favorite = true;
  } else {
    connectionObject.favorite = false;
  }
  const getV = function () {
    return new Promise((resolve, reject) => {
      const setDoc = newConnection
        .update({
          ['connections.' + bundle.contactId]: connectionObject,
        })
        .then(() => {
          // console.log("Write succeeded!");
          resolve();
        });
    });
  };
  return getV();
}
