import firebase from '../config/firebase';

// Get Locations of Specific Package
export function getLocationDetailsService(packageId) {
  const locationsArray = [];
  const db = firebase.firestore();
  const locations = db.collection('locations');
  var packageId = `packages.${packageId}`;

  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = locations
        .where(packageId, '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                locationTitle,
                locationDescription,
                locationDescriptionLong,
                hours,
                happyHour,
                locationType,
                locationId,
                happyHours,
                packages,
                locationThumb,
                locationHero,
                categories,
                tagsSetting,
                tagsSettings,
                tagsBeverages,
                tagsCuisine,
                tagsHours,
                tagsDateType,
                tagsTrevelTags,
                tagsLocationType,
                tagsMain,
                eventSettings,
                website,
                settings,
              } = doc.data();
              locationsArray.push({
                key: doc.id,
                locationTitle,
                locationDescription,
                locationDescriptionLong,
                hours,
                happyHour,
                locationType,
                locationId,
                happyHours,
                packages,
                locationThumb,
                locationHero,
                categories,
                tagsSetting,
                tagsSettings,
                tagsBeverages,
                tagsLocationType,
                tagsTrevelTags,
                tagsCuisine,
                tagsHours,
                tagsDateType,
                tagsMain,
                eventSettings,
                website,
                settings,
              });
            }
          });
          return locationsArray;
        })
        .then((result) => {
          resolve(locationsArray);
        });
    });
  };

  return getP();
}

// Get Users Locations by Zone Id
export function getLocations(zoneId) {
  const locationsArray = [];
  const db = firebase.firestore();
  const locations = db.collection('locations');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = locations
        .where('zoneId', '==', zoneId)
        .where('settings.published', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                locationTitle,
                locationDescription,
                locationDescriptionLong,
                hours,
                happyHour,
                locationType,
                locationId,
                happyHours,
                packages,
                locationThumb,
                locationHero,
                address,
                phone,
                zip,
                categories,
                venId,
                custom,
                zoneId,
                contact,
                tagsSetting,
                tagsSettings,
                tagsLocationType,
                tagsBeverages,
                tagsTrevelTags,
                tagsCuisine,
                tagsHours,
                tagsDateType,
                tagsMain,
                eventSettings,
                website,
                type,
                settings,
                menuLink,
              } = doc.data();
              locationsArray.push({
                key: doc.id,
                locationTitle,
                locationDescription,
                locationDescriptionLong,
                hours,
                happyHour,
                locationType,
                locationId,
                happyHours,
                packages,
                locationThumb,
                locationHero,
                address,
                phone,
                zip,
                categories,
                venId,
                custom,
                zoneId,
                contact,
                tagsSetting,
                tagsSettings,
                tagsLocationType,
                tagsBeverages,
                tagsCuisine,
                tagsTrevelTags,
                tagsHours,
                tagsDateType,
                tagsMain,
                eventSettings,
                website,
                type,
                settings,
                menuLink,
              });
            }
          });
          return locationsArray;
        })
        .then((result) => {
          resolve(locationsArray);
        });
    });
  };
  return getP();
}

// Get Users Locations by Zone Id
export function getDemoLocations() {
  const locationsArray = [];
  const db = firebase.firestore();
  const locations = db.collection('locations');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = locations
        .where('settings.demo', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                locationTitle,
                locationDescription,
                locationDescriptionLong,
                hours,
                happyHour,
                locationType,
                locationId,
                happyHours,
                packages,
                locationThumb,
                locationHero,
                address,
                phone,
                zip,
                categories,
                venId,
                custom,
                zoneId,
                contact,
                tagsSetting,
                tagsSettings,
                tagsBeverages,
                tagsLocationType,
                tagsTrevelTags,
                tagsCuisine,
                tagsHours,
                tagsDateType,
                tagsMain,
                eventSettings,
                website,
                type,
                settings,
                menuLink,
              } = doc.data();
              locationsArray.push({
                key: doc.id,
                locationTitle,
                locationDescription,
                locationDescriptionLong,
                hours,
                happyHour,
                locationType,
                locationId,
                happyHours,
                packages,
                locationThumb,
                locationHero,
                address,
                phone,
                zip,
                categories,
                venId,
                custom,
                zoneId,
                contact,
                tagsSetting,
                tagsLocationType,
                tagsSettings,
                tagsTrevelTags,
                tagsBeverages,
                tagsCuisine,
                tagsHours,
                tagsDateType,
                tagsMain,
                eventSettings,
                website,
                type,
                settings,
                menuLink,
              });
            }
          });
          return locationsArray;
        })
        .then((result) => {
          resolve(locationsArray);
        });
    });
  };
  return getP();
}

// Get Location Details
export function getLocation(locationId) {
  const locations = [];
  const db = firebase.firestore();
  const getP = function () {
    return new Promise((resolve, reject) => {
      const docRef = db.collection('locations').doc(locationId);
      docRef
        .get()
        .then((doc) => {
          if (doc && doc.exists) {
            const {
              locationTitle,
              locationDescription,
              locationDescriptionLong,
              hours,
              happyHour,
              locationType,
              locationId,
              happyHours,
              locationThumb,
              locationHero,
              address,
              phone,
              zip,
              categories,
              venId,
              custom,
              zoneId,
              contact,
              tagsSetting,
              tagsLocationType,
              tagsSettings,
              tagsBeverages,
              tagsCuisine,
              tagsTrevelTags,
              tagsHours,
              tagsDateType,
              tagsMain,
              eventSettings,
              website,
              type,
              settings,
              menuLink,
            } = doc.data();
            locations.push({
              locationTitle,
              locationDescription,
              locationDescriptionLong,
              hours,
              locationType,
              locationId,
              happyHours,
              locationThumb,
              locationHero,
              address,
              phone,
              zip,
              categories,
              venId,
              custom,
              zoneId,
              contact,
              tagsSetting,
              tagsSettings,
              tagsBeverages,
              tagsLocationType,
              tagsTrevelTags,
              tagsCuisine,
              tagsHours,
              tagsDateType,
              tagsMain,
              eventSettings,
              website,
              type,
              settings,
              menuLink,
            });
          }
          return locations;
        })
        .then((result) => {
          const final_locations = result[0];
          resolve(final_locations);
        });
    });
  };
  return getP();
}

// Get All Locations - Admin
export function getAllLocations() {
  const locationsArray = [];
  const db = firebase.firestore();
  const locations = db.collection('locations');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = locations
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                locationTitle,
                locationDescription,
                locationDescriptionLong,
                hours,
                happyHour,
                locationType,
                locationId,
                happyHours,
                packages,
                locationThumb,
                locationHero,
                address,
                phone,
                zip,
                categories,
                venId,
                custom,
                zoneId,
                contact,
                tagsSetting,
                tagsLocationType,
                tagsSettings,
                tagsBeverages,
                tagsCuisine,
                tagsTrevelTags,
                tagsHours,
                tagsDateType,
                tagsMain,
                eventSettings,
                website,
                type,
                settings,
                menuLink,
              } = doc.data();
              locationsArray.push({
                key: doc.id,
                locationTitle,
                locationDescription,
                locationDescriptionLong,
                hours,
                happyHour,
                locationType,
                locationId,
                happyHours,
                packages,
                locationThumb,
                locationHero,
                address,
                phone,
                zip,
                categories,
                venId,
                custom,
                zoneId,
                contact,
                tagsTrevelTags,
                tagsSetting,
                tagsSettings,
                tagsBeverages,
                tagsCuisine,
                tagsHours,
                tagsDateType,
                tagsLocationType,
                tagsMain,
                eventSettings,
                website,
                type,
                settings,
                menuLink,
              });
            }
          });
          return locationsArray;
        })
        .then((result) => {
          resolve(locationsArray);
        });
    });
  };
  return getP();
}

export function updateLocationsService(array) {
  const locationsArray = [];
  let newLocations = [];
  const db = firebase.firestore();
  const locations = db.collection('locations');
  let favLocations = [];
  const updateArray = array.bundle.favArray;
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = locations
        .where('locationId', 'in', updateArray)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let newLocation = {};
            if (doc && doc.exists) {
              const {
                locationTitle,
                settings,
                locationDescription,
                locationDescriptionLong,
                hours,
                happyHour,
                locationType,
                locationId,
                happyHours,
                packages,
                locationThumb,
                locationHero,
                address,
                phone,
                zip,
                categories,
                venId,
                custom,
                zoneId,
                contact,
                tagsSetting,
                tagsSettings,
                tagsBeverages,
                tagsTrevelTags,
                tagsCuisine,
                tagsHours,
                tagsDateType,
                tagsMain,
                eventSettings,
                website,
                type,
              } = doc.data();

              newLocation = {
                locationTitle,
                locationDescription,
                locationDescriptionLong,
                hours,
                happyHour,
                locationType,
                settings,
                locationId,
                happyHours,
                packages,
                locationThumb,
                locationHero,
                address,
                phone,
                zip,
                categories,
                venId,
                custom,
                zoneId,
                contact,
                tagsSetting,
                tagsSettings,
                tagsBeverages,
                tagsCuisine,
                tagsTrevelTags,
                tagsHours,
                tagsDateType,
                tagsMain,
                eventSettings,
                website,
                type,
                outOfZone: true,
              };
            }
            favLocations.push(newLocation);
          });
          let newLocations = [
            ...array.bundle.locations,
            ...favLocations,
          ];

          return newLocations;
        })
        .then((newLocations) => {
          resolve(newLocations);
        })
        .catch(function (error) {
          console.log('Error getting document:', error);
        });
    });
  };
  return getP();
}
