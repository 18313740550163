import { ErrorMessage } from 'formik';
import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../../config/firebase';
import Select from 'react-select';
import ReactCrop from 'react-image-crop';
// Reducers
import { getVendorRewards } from '../../sagas/vendorRewardsSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

export class VendorRewardForm extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      title: '',
      details: '',
      restrictions: '',
      cropThumb: {
        unit: '%',
        width: 30,
        aspect: 146 / 122,
      },
      cropping: false,
      croppingThumb: false,
      thumbPath: '',
    };
  }
  componentDidMount() {
    if (this.props.type === 2) {
      let reward = this.props.activeReward;
      let locations = [];
      if (reward.locations !== undefined) {
        locations = reward.locations;
      }
      this.setState({
        title: reward.title,
        details: reward.details,
        restrictions: reward.restrictions,
        locations,
      });
    }
  }

  getCloseButton() {
    if (this.props.type === 1) {
      return (
        <a
          onClick={() => {
            this.props.setCreateNewRewardFormActive(false);
          }}
        >
          <span className="cancelButton">Cancel</span>
        </a>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.props.setEditRewardFormActive(false);
          }}
        >
          <span className="cancelButton">Cancel</span>
        </a>
      );
    }
  }

  // Thumb Crop
  onSelectFileThumb = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      croppingThumb: true,
    });
  };

  onImageLoadedThumb = (image) => {
    this.imageRef = image;
  };

  onCropCompleteThumb = (crop) => {
    this.makeClientCropThumb(crop);
  };

  onCropChangeThumb = (cropThumb, percentCrop) => {
    this.setState({ cropThumb });
  };

  async makeClientCropThumb(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImgThumb(
        this.imageRef,
        crop,
        'thumbnail.jpeg',
      );
      this.setState({
        thumbPath: croppedImageUrl,
      });
    }
  }

  getCroppedImgThumb(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getThumbnailCropper(thumbnailCropping) {
    const { cropThumb, croppedImageUrl, src } = this.state;
    if (thumbnailCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropperThumb();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropperThumb();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={cropThumb}
                onImageLoaded={this.onImageLoadedThumb}
                onComplete={this.onCropCompleteThumb}
                onChange={this.onCropChangeThumb}
              />
            )}
          </div>
        </div>
      );
    }
  }
  //End Thumb Crop
  cancelCropper() {
    this.setState({
      cropping: false,
    });
  }

  saveCropper() {
    this.uploadImage(this.state.blob, 'hero');
    this.setState({
      cropping: false,
    });
  }

  cancelCropperThumb() {
    this.setState({
      croppingThumb: false,
    });
  }

  saveCropperThumb() {
    // this.uploadImage(this.state.blob, 'thumb');
    this.setState({
      croppingThumb: false,
    });
  }

  getThumbnail() {
    if (this.state.thumbPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '200px', marginBottom: '20px' }}
          src={this.state.thumbPath}
        />
      );
    }
  }

  displayForm() {
    let locations = [];
    this.props.vendor.locations.forEach((location, i) => {
      let locationDetails = query('locationId')
        .is(location)
        .on(this.props.locations);

      if (locationDetails[0] === undefined) {
        locationDetails = query('locationId')
          .is(location)
          .on(this.props.demo.demoLocations);
      }
      if (locationDetails[0] !== undefined) {
        locations.push({
          value: location,
          label: locationDetails[0].locationTitle,
        });
      }
    });
    return (
      <div>
        <div className="vendorModalHeader">
          <span className="vendorAppTitle">
            {this.props.type === 1
              ? 'Rewards Manager - Create New Reward'
              : 'Rewards Manager - Edit Reward'}
          </span>
          <div className="saveButtons">
            <a
              onClick={() => {
                this.createReward(this.props.profile);
              }}
            >
              <span className="saveButton">Save</span>
            </a>
            {this.getCloseButton()}
          </div>
        </div>
        <div>
          <Container>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                style={{ padding: '10px 15px 0px 15px' }}
              >
                <span className="inputLabel">Title</span>
                <input
                  type="text"
                  onChange={this.titleOnChange.bind(this)}
                  value={this.state.title}
                  className="full-width"
                />
                <span className="inputLabel">Details</span>
                <textarea
                  type="text"
                  onChange={this.detailsOnChange.bind(this)}
                  value={this.state.details}
                  className="full-width"
                />
                <span className="inputLabel">Restrictions</span>
                <textarea
                  type="text"
                  onChange={this.restrictionsOnChange.bind(this)}
                  value={this.state.restrictions}
                  className="full-width"
                />
                <span className="inputLabel">
                  Available Locations
                </span>
                <Select
                  value={this.state.locations}
                  closeMenuOnSelect={true}
                  blurInputOnSelect={false}
                  onChange={(e) => this.criteriaLocationsHandler(e)}
                  options={locations}
                  isMulti={true}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col lg={12} md={6} sm={12} xs={12} className="left">
                <span className="inputLabel">Thumbnail Image</span>

                {this.getThumbnail()}
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={6} sm={12} xs={12} className="left">
                <div>
                  <input
                    type="file"
                    onChange={this.onSelectFileThumb}
                  />
                </div>
              </Col>
            </Row> */}

            {this.props.vendorRewards.length === 0 && (
              <div
                style={{
                  padding: '20px',
                  border: '1px solid lightgrey',
                  margin: '20px auto',
                  width: '95%',
                  marginTop: '50px',
                  background: 'white',
                  display: 'flex',
                }}
              >
                <Col xs={1} md={1} sm={1} lg={1}>
                  <div
                    style={{
                      float: 'left',
                      display: 'block',
                      position: 'relative',
                      top: '-2px',
                    }}
                  >
                    <MaterialIcon
                      icon="help_outline"
                      size={35}
                      color="#000000"
                    />
                  </div>
                </Col>
                <Col xs={11} md={11} sm={11} lg={11}>
                  <h4
                    style={{
                      fontSize: '18px',
                      marginBottom: '10px',
                    }}
                  >
                    Create a Reward
                  </h4>
                  <p style={{ padding: '0px' }}>
                    A Reward can be anything that can be earned a
                    user, like a free Drink, Entree, or T-Shirt. It
                    can also be something that doesn{"'"}t really have
                    a monetary value, like a Reserved Table, or their
                    name on a chalkboard.
                  </p>
                  <h4
                    style={{
                      fontSize: '16px',
                      marginBottom: '10px',
                    }}
                  >
                    Available Locations
                  </h4>
                  <p>Where can a user redeem this Reward?</p>
                </Col>
              </div>
            )}
          </Container>
          {/* <Container id="imageUpload"></Container> */}
        </div>
      </div>
    );
  }

  criteriaLocationsHandler = (values) => {
    this.setState({ locations: values });
  };

  uploadImage = (image) => {
    const uploadUrl =
      'vendor_images/' +
      this.props.vendor.vendorId +
      '/' +
      this.state.dbref +
      '/' +
      image.name;
    // const uploadUrl =
    //   'event_images/' + this.state.dbref + '/' + image.name;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadUrl);
    // imageRef.put(image).then((snapshot) => {
    //   const path = snapshot.ref.location.path_;

    //     this.setState({
    //       heroPath,
    //       customHero: true,
    //     });

    // });
  };

  createReward(profile) {
    const db = firebase.firestore();
    const newEventRef = db.collection('vendor_rewards').doc();
    const dbref = newEventRef.id;
    const timestamp = new Date();
    let newReward = {
      title: this.state.title,
      id: dbref,
      details: this.state.details,
      // heroImage: packageBundle.package.heroImage,
      // thumbnail: packageBundle.package.thumbnail,
      restrictions: this.state.restrictions,
      vendorId: profile.vendorAccount.id,
      creatorInfo: {
        creatorId: profile.userId,
        created: timestamp,
      },
      locations: this.state.locations,
    };

    if (this.props.type === 1) {
      const setDoc = db
        .collection('vendor_rewards')
        .add(newReward)
        .then((ref) => {
          this.props.getVendorRewards(this.props.vendor.vendorId);
          this.props.setCreateNewRewardFormActive(false);
        });
    } else {
      let updatedReward = {
        title: this.state.title,
        id: this.props.activeReward.id,
        details: this.state.details,
        // heroImage: packageBundle.package.heroImage,
        // thumbnail: packageBundle.package.thumbnail,
        restrictions: this.state.restrictions,
        vendorId: this.props.vendor.vendorId,
        creatorInfo: {
          creatorId: this.props.activeReward.creatorInfo.creatorId,
          created: this.props.activeReward.creatorInfo.created,
        },
        locations: this.state.locations,
      };
      const update = db
        .collection('vendor_rewards')
        .doc(this.props.activeReward.id);
      const setDoc = update.update(updatedReward).then((ref) => {
        this.props.getVendorRewards(this.props.vendor.vendorId);
        this.props.setEditRewardFormActive(false);
      });
    }
  }

  titleOnChange(e) {
    this.setState({ title: e.target.value });
  }

  detailsOnChange(e) {
    this.setState({ details: e.target.value });
  }

  restrictionsOnChange(e) {
    this.setState({ restrictions: e.target.value });
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div>
        <div>
          {this.getThumbnailCropper(this.state.croppingThumb)}
        </div>
        <div>{this.displayForm()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    vendor: state.vendor.vendor,
    locations: state.locations.locations,
    vendorRewards: state.vendorRewards.rewards,
    demo: state.demo,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorRewards: (awards) => {
      dispatch(getVendorRewards(awards));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorRewardForm);
