import React from 'react';
import { connect } from 'react-redux';
// Components
import SignUpBanner from '../components/SignUpBanner';
import { Col, Row, Container } from 'reactstrap';
import Features from '../components/Features';
import RedCallToAction from '../components/RedCallToAction';
import { clearUauth } from '../sagas/unauthenticatedSaga/reducer';
import { Link } from 'react-router-dom';
// Reducers
import { getArticles } from '../sagas/articleSaga/reducer';

export class CommunityRecovery extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      city: '',
    };
  }
  UNSAFE_componentWillMount() {
    document.title = 'Trevel Community';
  }
  componentDidMount() {
    let zoneId = null;
    if (
      this.props.uauth.guide.locationsWithDistance !== undefined &&
      this.props.uauth.guide.locationsWithDistance[1] !== undefined
    ) {
      zoneId = this.props.uauth.guide.locationsWithDistance[1].zoneId;
    }
    let city = 'St. Louis and Kansas City';
    if (this.props.match.params.city !== undefined) {
      if (this.props.match.params.city === 'stl') {
        city = 'St. Louis';
      }
      if (this.props.match.params.city === 'kc') {
        city = 'Kansas City';
      }
    }

    if (zoneId !== null) {
      if (zoneId === 11) {
        city = 'St. Louis';
      }
      if (zoneId === 10) {
        city = 'Kansas City';
      }
    }
    this.setState({
      city,
      loading: false,
    });
  }

  getTrevelBanner() {
    let backgroundUrl = '';

    if (
      this.props.uauth.guide.locationsWithDistance[0].zoneId === 11
    ) {
      backgroundUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fstllanding.jpg?alt=media&token=2d50daf2-b14b-4dc3-9a04-358d2c764380';
    } else {
      backgroundUrl =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fkclanding.jpg?alt=media&token=b8d9d9a8-5760-40ec-8101-ac66788aaf41';
    }

    let backgroundStyle = {
      backgroundImage: ' url(' + backgroundUrl + ')',
      backgroundSize: 'cover',

      backgroundPosition: 'center',
      position: 'relative',
    };
    return (
      <div style={backgroundStyle} className="trevelBanner">
        <div className="trevelBannerText">
          <span
            style={{
              display: 'block',
              clear: 'both',
              color: 'white',
            }}
            className="trevelBannerTop"
          >
            {this.props.uauth.guide.locationsWithDistance[0]
              .zoneId === 11
              ? 'St. Louis Community'
              : 'Kansas City Community'}
          </span>
          <span
            style={{
              display: 'block',
              clear: 'both',
              color: 'white',
            }}
            className="trevelBannerBottom"
          >
            Supporting Each Other
          </span>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div id="home">
        {/* <div
          style={{
            marginBottom: '20px',
          }}
        >
          {this.getTrevelBanner()}
        </div> */}
        <div className="communityBack" id="bannerIntro">
          <div className="wrapper wide top">
            {/* <img
              className="intro-image"
              height="527"
              src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fbanner1.png?alt=media&token=844aca0c-a108-4140-a566-6487a4f65344"
              width="609"
              alt="intro"
            /> */}
            <div className="hero-wrapper">
              <h2>
                <b>
                  Trevel Community
                  <br />{' '}
                  <b style={{ fontSize: '40px' }}>
                    Recovery Campaign
                  </b>
                </b>
              </h2>
            </div>
          </div>
        </div>
        <div
          style={{
            // borderBottom: '3px solid black',
            padding: '30px 10px, 50px 10px',
            background: '#f3f3f3',
          }}
        >
          <div
            style={{
              display: 'block',
              padding: '10px',
              width: '95%',
              margin: '0px auto',
            }}
          >
            <Container
              style={{
                maxWidth: '1000px',
                paddingTop: '30px',
                paddingBottom: '30px',
              }}
            >
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <center>
                    <h4
                      style={{
                        fontSize: '40px',
                        lineHeight: '35px',
                        color: '#2e2e2e',
                        marginBottom: '30px',
                      }}
                    >
                      We Are In This Together{' '}
                      {this.state.city !==
                        'St. Louis and Kansas City' &&
                        this.state.city}
                    </h4>
                    <h3
                      style={{
                        fontSize: '35px',
                        lineHeight: '35px',
                        color: '#f21d59',
                        marginBottom: '50px',
                      }}
                    >
                      Because Everythings{' '}
                      <x style={{ textDecoration: 'line-through' }}>
                        Easier
                      </x>{' '}
                      <b style={{ fontWeight: '500' }}>More Fun</b>{' '}
                      With Others
                    </h3>
                  </center>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <p className="subline">
                    During the pandemic, we saw many of our favorite
                    local businesses and entertainers struggeling, so
                    we decided to take action and began updating
                    Trevel as a way to help.
                  </p>
                  <p className="subline">
                    Trevel provides local businesses, entertainers,
                    artists and the community new ways to connect,
                    collaborate, and support each other as our city
                    battles back better then before.
                  </p>
                  <h4
                    style={{
                      fontWeight: '100',
                      padding: '5px 0px 10px 0px',
                    }}
                  >
                    Our Goal
                  </h4>
                  <p className="subline">
                    The goal of the Recovery Campaign is to connect
                    all of {this.state.city} together through gamified
                    Trevel Experiences that help support our local
                    community while ensuring we all find an experience
                    to do together that we will love.
                  </p>
                  {/* <p className="subline">
                    Learn More About Trevel Experinces
                  </p> */}
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <div style={{ paddingBottom: '20px' }}>
                    <center>
                      <video
                        width="100%"
                        max-width="500px"
                        controls
                        controlsList="nodownload"
                        style={{ border: '1px solid #131313' }}
                      >
                        <source
                          src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/video%2FTrevel1.mp4?alt=media&token=672a063d-31d3-47eb-b5fb-2ebfeef6dda7"
                          type="video/mp4"
                        />
                      </video>
                    </center>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="padding-top padding-bottom"
          style={{
            // background:
            //   'linear-gradient(180deg,rgba(167,25,65,1) 0%, rgba(242,29,89,1) 59%)',
            borderTop: '2px solid #760020',
            borderBottom: '2px solid #760020',
            paddingBottom: '20px',
          }}
        >
          <center>
            <h4
              style={{
                fontSize: '30px',
                lineHeight: '35px',
                color: 'black',
                marginBottom: '50px',
              }}
            >
              Who Will This Campaign Help?
            </h4>
          </center>
          <div
            className="wrapper"
            style={{
              paddingBottom: '20px',
            }}
          >
            <Row className="no-margin">
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div>
                  <h4
                    className="inline-block"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Entertainers
                  </h4>
                  <p className="subline ">
                    All local {this.state.city} entertainers are
                    welcome to get listed in Trevel. You can share
                    your events, connect with your fans, and manage
                    your own Trevel Channel.
                  </p>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div>
                  <h4
                    className="inline-block"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Destinations
                  </h4>
                  <p className="subline ">
                    As people start to venture out into{' '}
                    {this.state.city} once again we want to make sure
                    they find the restaurants, museums, parks and
                    other Destinations that they will love to
                    experience together.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div>
                  <h4
                    className="inline-block"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Artists
                  </h4>
                  <p className="subline">
                    Local {this.state.city} Artists will be
                    spotlighted and promoted on Trevel. We are also
                    looking for local Artists to help create unique
                    regional badges that users will earn as they
                    support our local community.
                  </p>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="align-middle"
              >
                <div>
                  <h4
                    className="inline-block"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.city}
                  </h4>
                  <p className="subline">
                    Local {this.state.city} Artists will be
                    spotlighted and promoted on Trevel. We are also
                    looking for local Artists to help create unique
                    regional badges that users will earn as they
                    support our local community.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div
          style={{
            // borderTop: '3px solid black',
            padding: '5px 10px, 30px 10px',
            marginTop: '20px',
          }}
        >
          {/* <div style={{ padding: '10px 0px 10px 0px' }}>
            <center>
              <h3>
                It{"'"}s Time to{' '}
                <span
                  style={{
                    fontWeight: '600',
                    fontSize: '36px',
                  }}
                >
                  Revel in Our Experiences
                </span>{' '}
                Again,{' '}
                <span
                  style={{
                    fontWeight: '600',
                    fontSize: '36px',
                  }}
                >
                  Together
                </span>
              </h3>
           
            </center>
          </div> */}
          <div
            style={{
              display: 'block',
              padding: '10px',
              width: '95%',
              margin: '0px auto',
              marginTop: '30px',
            }}
          >
            <Container style={{ maxWidth: '1200px' }}>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <h3 className="inline-block">
                    How Can Trevel Help?
                  </h3>
                  <h4
                    style={{
                      fontWeight: '100',
                      padding: '5px 0px 10px 0px',
                    }}
                  >
                    Let's Gamify {this.state.city}
                  </h4>
                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginBottom: '10px',
                    }}
                  >
                    During the pandemic, we saw many of our favorite
                    local businesses and entertainers struggeling, so
                    we decided to take action and began building
                    Trevel as a way to help.
                  </span>

                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginBottom: '10px',
                    }}
                  >
                    Trevel provides local businesses, entertainers,
                    artists and the community new ways to connect,
                    collaborate, and support each other as our city
                    battles back better than before.
                  </span>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <center>
                    <img
                      style={{
                        width: '100%',
                        border: '1px solid #0c0c0c',
                        marginBottom: '30px',
                      }}
                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ftrevelguide2.png?alt=media&token=a365b206-5d26-4708-98ec-481c5ca9590e"
                    />
                  </center>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearUauth: () => {
      dispatch(clearUauth());
    },
    getArticles: (article) => {
      dispatch(getArticles(article));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    uauth: state.unauth,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommunityRecovery);
