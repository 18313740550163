import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { push } from 'connected-react-router';
import { getTagsAndFavs } from '../../services/ConnectionsTagsAndFavsServices';
import { getCe } from '../customEventSaga/reducer';
import { getConnectionsTagsAndFavs } from '../connectionsTagsAndFavsSaga/reducer';
import { updateLocations } from '../locationsSaga/reducer';
import { mergeWith } from 'lodash';

function* setNewEventAction(action) {
  const newEvent = action.payload;
  yield put({
    type: actions.SET_NEWEVENT,
    payload: {
      connectFirst: newEvent.connectFirst,
      planFirst: newEvent.planFirst,
      connections: newEvent.connections,
      clear: newEvent.clear,
      step: newEvent.step,
      customEvent: newEvent.customEvent,
      vEvent: newEvent.vEvent,
      singleLocation: newEvent.singleLocation,
      fromLocation: newEvent.fromLocation,
      fromVEvent: newEvent.fromVEvent,
      fromPackage: newEvent.fromPackage,
      fromEntertainment: newEvent.fromEntertainment,
      entertainmentEvent: newEvent.entertainmentEvent,
      weightedLocations: newEvent.weightedLocations,
    },
  });

  if (newEvent.step === 3) {
    if (
      action.payload.invitedUsers !== undefined &&
      action.payload.invitedUsers !== null &&
      action.payload.invitedUsers.length !== 0
    ) {
      let allTagsandFavs = [];
      action.payload.invitedUsers.forEach(function (userId) {
        if (action.payload.connections[userId].connected !== false) {
          let inviteId = userId;
          let locations = [];
          if (action.payload.locations !== undefined) {
            locations = action.payload.locations;
          }
          let bundle = {
            inviteId,
            locations,
            connected: action.payload.connections[userId].connected,
          };
          allTagsandFavs.push(bundle);
        }
      });
      yield put(getConnectionsTagsAndFavs(allTagsandFavs));
    } else {
      yield put(push('/event/create/home/1'));
    }
  } else if (newEvent.step === 2) {
    let url = '/events/plan/' + newEvent.screen;
    yield put(push(url));
  } else if (newEvent.step === 4) {
    if (
      action.payload.invitedUsers !== undefined &&
      action.payload.invitedUsers !== null &&
      action.payload.invitedUsers.length !== 0
    ) {
      let allTagsandFavs = [];
      action.payload.invitedUsers.forEach(function (userId) {
        if (action.payload.connections[userId].connected !== false) {
          let inviteId = userId;
          let locations = [];
          if (action.payload.locations !== undefined) {
            locations = action.payload.locations;
          }
          let bundle = {
            inviteId,
            locations,
            connected: action.payload.connections[userId].connected,
          };
          allTagsandFavs.push(bundle);
        }
      });
      yield put(getCe(newEvent.customEvent));
      yield put(getConnectionsTagsAndFavs(allTagsandFavs));
    } else {
      yield put(getCe(newEvent.customEvent));
    }
  } else if (newEvent.step === 10) {
    yield put(push('/signup'));
  }
}

function* resetNewEventAction(action) {
  yield put({
    type: actions.SET_NEWEVENT,
    payload: {
      connectFirst: false,
      planFirst: false,
      connections: [],
      clear: true,
      invitedUsers: {},
      step: 1,
      favoriteLocations: [],
      locations: [],
      fromFavorite: false,
      fromVEvent: false,
      fromPackage: false,
      vEvent: {},
      customEvent: {},
      singleLocation: {},
      fromLocation: false,
      fromEntertainment: false,
      entertainmentEvent: {},
      weightedLocations: [],
    },
  });
}

function* updateNewEventAction(action) {
  yield put({
    type: actions.SET_NEWEVENT,
    payload: {
      connectFirst: action.payload.connectFirst,
      planFirst: action.payload.planFirst,
      connections: action.payload.connections,
      clear: action.payload.clear,
      invitedUsers: action.payload.invitedUsers,
      step: action.payload.step,
      favoriteLocations: action.payload.favoriteLocations,
      locations: action.payload.locations,
      fromFavorite: action.payload.fromFavorite,
      fromVEvent: action.payload.fromVEvent,
      fromPackage: action.payload.fromPackage,
      vEvent: action.payload.vEvent,
      customEvent: action.payload.connections,
      singleLocation: action.payload.singleLocation,
      fromLocation: action.payload.fromLocation,
      fromEntertainment: action.payload.fromEntertainment,
      entertainmentEvent: action.payload.entertainmentEvent,
      weightedLocations: action.payload.weightedLocations,
    },
  });
  if (
    action.payload.invitedUsers !== undefined &&
    action.payload.invitedUsers !== null &&
    action.payload.invitedUsers.length !== 0
  ) {
    let allTagsandFavs = [];
    action.payload.invitedUsers.forEach(function (userId) {
      let inviteId = userId;
      let locations = action.payload.locations;
      let bundle = {
        inviteId,
        locations,
      };
      allTagsandFavs.push(bundle);
    });
    yield put(getConnectionsTagsAndFavs(allTagsandFavs));
  } else {
    yield put(push('/event/create/home/1'));
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_NEWEVENT, setNewEventAction);
  yield takeLatest(actions.RESET_NEWEVENT, resetNewEventAction);
  yield takeLatest(actions.UPDATE_NEWEVENT, updateNewEventAction);
}
