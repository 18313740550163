import * as action from './actions';

export const getTimedEvents = (zoneId) => {
  return {
    type: action.GET_TIMEDEVENTS,
    payload: {},
    zoneId: zoneId,
  };
};

const ACTION_HANDLERS = {
  [action.SET_TIMEDEVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_TIMEDEVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = { events: [] };

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
