import * as action from './actions';

export const getDestination = (id) => {
  return {
    type: action.GET_DESTINATION,
    payload: id,
  };
};

export const setDestination = (destination) => {
  return {
    type: action.SET_DESTINATION,
    payload: destination,
  };
};

const ACTION_HANDLERS = {
  [action.GET_DESTINATION]: (state, action) => {
    return { ...state };
  },
  [action.SET_DESTINATION]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = { destination: null };

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
