import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap';
import './SignUpBanner.css';

class SignUpBanner extends Component {
  render() {
    return (
      <Row className="banner-section">
        <div
          className="mobileHide"
          style={{
            width: '100%',
            maxWidth: '800px',
            margin: '0px auto',
          }}
        >
          <Col xs={12} md={8} sm={12} lg={8} className="no-padding">
            <Link
              to={{
                pathname: '/signup',
              }}
            >
              <p className="banner-text">
                Customize Your Guide And Plan With Friends For Free!
              </p>
            </Link>
          </Col>
          <Col xs={12} md={4} sm={12} lg={4} className="no-padding">
            <div>
              <Link
                to={{
                  pathname: '/about',
                }}
                className="whitebutton right"
              >
                <span>Learn More</span>
              </Link>
              <Link
                to={{
                  pathname: '/signup',
                }}
                className="whitebutton right"
                style={{ fontWeight: '800 ', marginRight: '10px' }}
              >
                <span style={{ fontWeight: '800 ' }}>Sign Up</span>
              </Link>
            </div>
          </Col>
        </div>
        <div className="mobileShow" style={{ width: '100%' }}>
          <Col xs={6} md={6} sm={6} lg={6}>
            <Link
              to={{
                pathname: '/signup',
              }}
              className="whitebutton right"
              style={{ fontWeight: '800', marginTop: '0px' }}
            >
              <span style={{ fontWeight: '800 ' }}>Sign Up</span>
            </Link>
          </Col>
          <Col xs={6} md={6} sm={6} lg={6}>
            <div>
              <Link
                to={{
                  pathname: '/about',
                }}
                className="whitebutton right"
                style={{ marginRight: '10px', marginTop: '0px' }}
              >
                <span>Learn More</span>
              </Link>
            </div>
          </Col>
        </div>
      </Row>
    );
  }
}

export default SignUpBanner;
