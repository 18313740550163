import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
// Components
import TitleBar from '../components/TitleBar';

const query = require('array-query');

export class Activity extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      mode: 1,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    document.title = 'Activity';
  }

  getTotalEvents(events) {
    let count = events.length;
    return (
      <div>
        <span className="statcat">Total Events</span>
        <span className="right stat">{count}</span>
      </div>
    );
  }

  getTotalAwards(awards) {
    let count = awards.length;
    return (
      <div>
        <span className="statcat">Rewards Earned</span>
        <span className="right stat">{count}</span>
      </div>
    );
  }

  getEventsCreated(events, userId) {
    let eventsArray = [];
    events.forEach(event => {
      if (event.creationDetails.plannerId === userId) {
        eventsArray.push(event);
      }
    });

    let count = eventsArray.length;
    return (
      <div>
        <span className="statcat">Events Created</span>
        <span className="right stat">{count}</span>
      </div>
    );
  }

  getTab(tabMode, tabLable, mode) {
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tabLable}
          </div>
        </a>
      );
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getContent(mode, sortedActivites) {
    if (mode === 2) {
      return (
        <div className="pushfifty text-center">
          <b>Coming Soon!</b>
        </div>
      );
    } else if (mode === 1) {
      return (
        <div className="pushtwenty">
          <Row className=" no-margin no-padding">
            <div className="fullwidth">
              <ul className="no-padding">
                {sortedActivites.map((edit, index) => (
                  <div>
                    <li className="adminlistitem">
                      {this.listActivity(edit)}
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          </Row>
        </div>
      );
    }
  }

  getEventName(edit) {
    // return getEventName;
  }

  sortActivites(events) {
    let eventEdits = [];
    events.forEach(event => {
      event.editHistory.forEach(edit => {
        let newEvent = {
          editDate: edit.editDate,
          eventType: edit.eventType,
          fullName: edit.fullName,
          userId: edit.userId,
          eventId: event.eventId,
          eventTitle: event.title,
        };
        eventEdits.push(newEvent);
      });
    });
    return eventEdits;
  }

  // {this.getEventName(edit)}

  listActivity(edit) {
    if (edit.eventType === 1) {
      return (
        <div>
          <span>
            {edit.fullName} made an edit to <b>{edit.eventTitle}</b>
          </span>
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    let sortedActivites = this.sortActivites(this.props.events);
    return (
      <div id="profile">
        <TitleBar title="Trevel Activity" backlink="" />
        {/* <div className="adminActionBar">
          <Row>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(1, "Your Points", this.state.mode)}
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(2, "Your Ranking", this.state.mode)}
            </Col>
          </Row>
        </div> */}
        {/* <div className="section actionBarPush"> */}
        <div className="section">
          {this.getContent(this.state.mode, sortedActivites)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    events: state.events.events,
    awards: state.userAwards.awards,
  };
}

export default connect(mapStateToProps)(Activity);
