import * as action from './actions';

export const getVendorRewards = (vendorId) => {
  return {
    type: action.GET_VENDORREWARDS,
    payload: {},
    vendorId: vendorId,
  };
};

const ACTION_HANDLERS = {
  [action.SET_VENDORREWARDS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_VENDORREWARDS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  rewards: [],
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
