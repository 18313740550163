import React, { Component } from 'react';
import MaterialIcon from 'material-icons-react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './LocationDetails.css';
// Reducers
import { setFavorites } from '../../sagas/favoritesSaga/reducer';
import GoogleAd from '../GoogleAd';

const query = require('array-query');
const _ = require('lodash');

const AnyReactComponent = ({ text }) => (
  <div>
    <center>
      <img src="https://firebasestorage.googleapis.com/v0/b/go-toser-6b9cc.appspot.com/o/web%2Fgoicon.png?alt=media&token=44afb950-2e1a-4e8a-b7e2-a0178a49fd4f" />
    </center>
  </div>
);

class LocationDetails extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      readMore: false,
      readMoreDescription: false,
      descHeight: 0,
      storyHeight: 0,
      promoTypes: [
        'Food & Beverage Event',
        'Happy Hour',
        'Happy Hour Event',
      ],
    };
  }
  static defaultProps = {
    zoom: 15,
  };

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const descHeight = this.divElementDesc.clientHeight;
    const storyHeight = this.divElementStory.clientHeight;

    this.setState({ descHeight, storyHeight });
  }

  setHappyHourArray(happyHours) {
    const happyHourArray = [];
    if (happyHours.length !== 0) {
      happyHours.forEach((happyHour) => {
        if (happyHour.happyHourType !== 2) {
          happyHourArray.push(happyHour);
        }
      });
    }
    return happyHourArray;
  }

  setPromoArray(happyHours) {
    let promoArray = [];
    if (happyHours.length !== 0) {
      happyHours.forEach((happyHour) => {
        if (happyHour.happyHourType === 2) {
          promoArray.push(happyHour);
        }
      });
    }
    return promoArray;
  }

  happyHourSection(happyHours) {
    let happyHourArray = this.setHappyHourArray(happyHours);
    if (happyHourArray.length !== 0) {
      return (
        <div className="card-section">
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div className="happyHourListing">
                <span className="location-title">Happy Hours</span>
                <div className="happyHour-container">
                  {happyHourArray.map((happyHour) => (
                    <div className="happyHour">
                      <p>{happyHour.happyHourPromo}</p>
                      {/* <button className="purchase">Select HappyHour</button> */}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }

  promoSection(happyHours) {
    const promoArray = this.setPromoArray(happyHours);
    if (promoArray.length === 0) {
      return <p>No Promos Available</p>;
    } else {
      return (
        <div className="happyHour-container">
          {promoArray.map((promo) => (
            <div className="happyHour">
              <p>{promo.happyHourPromo}</p>
              {/* <button className="purchase">Select HappyHour</button> */}
            </div>
          ))}
        </div>
      );
    }
  }

  getPromos(locationId, promos) {
    let promoArray = [];
    if (this.props.user.authenticated === true) {
      if (promos !== null && promos !== undefined) {
        promos.forEach((promo) => {
          let match = false;
          promo.locations.forEach((location) => {
            if (location.locationId === locationId) {
              match = true;
            }
          });
          if (match === true) {
            promoArray.push(promo);
          }
        });
      }
    } else {
      promoArray = this.props.locationPromos;
    }

    if (promoArray.length !== 0) {
      return (
        <div className="card-section">
          <span
            className="title"
            style={{
              margin: '0px 0px 20px 0px',
              fontSize: '18px',
              color: '#464646',
            }}
          >
            Experiences
          </span>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  {/* <span className="location-title">Promotions</span> */}
                  {promoArray.map((promo, index) => (
                    <Row
                      className="location-info"
                      key={'key' + index}
                    >
                      <Col
                        xs={4}
                        md={4}
                        sm={4}
                        lg={4}
                        className="no-padding thumbnailCol"
                      >
                        {this.getPromoImage(promo)}
                      </Col>
                      <Col xs={8} md={8} sm={8} lg={8}>
                        <span className="title">{promo.title}</span>
                        <p>{promo.description}</p>
                        {promo.rewards.length !== 0 && (
                          <div style={{ margin: '10px 0px 0px 0px' }}>
                            <span
                              style={{
                                float: 'left',
                                margin: '0px 5px 0px 0px',
                                fontWeight: '600',
                              }}
                            >
                              Rewards:{' '}
                            </span>

                            {promo.rewards.map((reward, index) => (
                              <div>
                                {reward.reward.map(
                                  (rewardx, index) => (
                                    <span style={{ float: 'left' }}>
                                      {rewardx.title}
                                      {reward.reward.length > 1 &&
                                        reward.reward.length !==
                                          index &&
                                        ', '}
                                    </span>
                                  ),
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </Col>
                    </Row>
                  ))}
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }

  //  <div>

  //           <div className="promoImage">
  //             <img src={promo.images.heroPath} />
  //           </div>
  //         </div>

  getPromoImage(promo) {
    if (promo.images.heroPath !== '') {
      return (
        <Link
          to={{
            pathname: '/vevent/' + promo.id,
            state: {
              promo: { promo },
              // eventId: { eventId },
              // token: { token },
              // type: { type },
            },
          }}
        >
          <img
            src={promo.images.thumbPath}
            className="thumbnail"
            style={{ margin: '5px 0px 0px 0px' }}
          />
        </Link>
      );
    }
  }

  getHappyHoursAndPromos(location) {
    return (
      <div>
        {this.getPromos(location.locationId, this.props.promos)}
      </div>
    );

    // if (location.happyHours !== undefined) {
    //   return <div>{this.happyHourSection(location.happyHours)}</div>;
    // }
  }

  getWebsite(location) {
    if (location.website !== undefined && location.website !== null) {
      return (
        <div style={{ clear: 'both' }}>
          <a
            href={location.website}
            target="_blank"
            rel="noopener noreferrer"
            className="red"
          >
            <span className="right website">Visit Website</span>
          </a>
        </div>
      );
    }
  }

  getMenu(location) {
    if (
      location.menuLink !== undefined &&
      location.menuLink !== null &&
      location.menuLink !== ''
    ) {
      return (
        <div style={{ clear: 'both' }}>
          <a
            href={location.menuLink}
            target="_blank"
            rel="noopener noreferrer"
            className="red"
          >
            <span
              className="right"
              style={{
                backgroundColor: '#f21d59',
                padding: '5px 10px 5px 10px',
                marginTop: '10px',
                borderRadius: '4px',
                color: 'white',
              }}
            >
              View Menu
            </span>
          </a>
        </div>
      );
    }
  }

  getPhone(location) {
    if (
      location.phone !== undefined &&
      location.phone !== null &&
      location.phone !== 'None'
    ) {
      let path = 'tel:' + location.phone;
      return (
        <a href={path} className="red">
          <span className="right phone">{location.phone}</span>
        </a>
      );
    }
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  setFavorite(locationId, favorites, userId) {
    let update = favorites;
    update.push(locationId);
    let bundle = {
      userId,
      favorites: update,
      type: 1,
    };
    this.props.setFavorites(bundle);
  }

  removeFavorite(locationId, favorites, userId) {
    let update = favorites;
    _.remove(update, function (e) {
      return e === locationId;
    });
    let bundle = {
      userId,
      favorites: update,
      type: 1,
    };
    this.props.setFavorites(bundle);
  }

  getFavoriteButton(locationId, favorites) {
    let isFavorite = _.includes(favorites, locationId);

    if (isFavorite === true) {
      return (
        <span key="stuff">
          <MaterialIcon
            icon="favorite"
            size={30}
            color="#f21d59"
            onClick={() => {
              this.removeFavorite(
                locationId,
                favorites,
                this.props.profile.userId,
              );
            }}
          />
        </span>
      );
    } else {
      return (
        <span key="stufff">
          <MaterialIcon
            icon="favorite"
            size={30}
            color="grey"
            onClick={() => {
              this.setFavorite(
                locationId,
                favorites,
                this.props.profile.userId,
              );
            }}
          />
        </span>
      );
    }
  }

  getClaimButton() {
    return (
      <a
        onClick={() => {
          this.props.setClaim(true);
        }}
      >
        <span
          style={{
            float: 'right',
            fontWeight: '600',
            color: '#f21d59',
          }}
        >
          Claim Location
        </span>
      </a>
    );
  }
  getWhen(event) {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 'tu' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];

    let days = [];

    if (event.scheduledTime.dates.repeatDays !== undefined) {
      event.scheduledTime.dates.repeatDays.forEach((day) => {
        let eventDetailsx = query('short').is(day).on(dayArray);
        let eventx = eventDetailsx[0];
        days.push(eventx.full);
      });
    }

    let dayText = '';
    days.forEach((day, index) => {
      if (days.length === 1) {
        dayText = day;
      } else if (days.length === 2) {
        if (index === 0) {
          dayText = day;
        } else {
          dayText = dayText + ' & ' + day;
        }
      } else if (
        days.length > 2 &&
        event.scheduledTime.dates.timesSelected > 1
      ) {
        if (index === 0) {
          dayText = day + 's';
        } else if (days.length - 1 === index) {
          dayText = dayText + ', & ' + day + 's';
        } else {
          dayText = dayText + ',  ' + day + 's';
        }
      } else if (
        days.length > 2 &&
        event.scheduledTime.dates.timesSelected === 1
      ) {
        if (index === 0) {
          dayText = day;
        } else if (days.length - 1 === index) {
          dayText = dayText + ', & ' + day;
        } else {
          dayText = dayText + ',  ' + day;
        }
      }
    });

    let weekly = '';
    if (event.scheduledTime.dates.timesSelected === 1) {
      weekly = 'week';
    } else {
      weekly = event.scheduledTime.dates.timesSelected + ' weeks ';
    }
    let statement = '';
    if (event.scheduledTime.dates.timesSelected === 1) {
      if (event.scheduledTime.dates.repeatType === 'Weekly') {
        statement = 'Every ' + dayText;
      }
    } else {
      if (event.scheduledTime.dates.repeatType === 'Weekly') {
        statement = 'Every ' + weekly + ' on ' + dayText;
      }
    }

    return statement;
  }

  getUpcomingEvents(timedEvents, location, promoMode) {
    let eventsArray = [];
    if (timedEvents !== undefined) {
      let sortedEvents = timedEvents.sort(
        (a, b) =>
          moment(a.scheduledTime.dates.startTimestamp).valueOf() -
          moment(b.scheduledTime.dates.startTimestamp).valueOf(),
      );
      sortedEvents.forEach((event) => {
        event.tagsEventType.forEach((tag) => {
          let status = this.state.promoTypes.includes(tag);
          if (status === promoMode) {
            if (event.address.tdId === location.locationId) {
              var todayDate = moment(new Date()).format('MM/DD/YYYY');
              var pastDate = moment(
                event.scheduledTime.dates.endDate,
              );
              var dDiff = pastDate.diff(todayDate);
              if (dDiff > 0 && dDiff !== 0) {
                eventsArray.push(event);
              } else if (dDiff === 0) {
                eventsArray.push(event);
              }
            }
          }
        });
      });
    }
    return eventsArray;
  }

  getHappyHour(happyHour) {
    let days = [];

    let dayObjects = [];

    Object.keys(happyHour.days).forEach((key) => {
      days.push(key.charAt(0).toUpperCase() + key.slice(1));
    });

    Object.keys(happyHour.days).forEach((key) => {
      dayObjects.push(happyHour.days[key]);
    });

    let reversedDays = days.reverse();

    return (
      <div>
        <div style={{ display: 'block', clear: 'both' }}>
          <span style={{ fontSize: '18px', fontWeight: '500' }}>
            Happy Hour
          </span>
        </div>

        <div style={{ display: 'block', clear: 'both' }}>
          {happyHour.differentTimes === false ? (
            <span>
              {reversedDays.map((day, index) => (
                <span>
                  {index !== days.length - 1 ? day + ', ' : day}
                </span>
              ))}{' '}
              from{' '}
              {moment(dayObjects[0].fromTime, 'HH:mm').format(
                'h:mm a',
              )}{' '}
              -{' '}
              {moment(dayObjects[0].toTime, 'HH:mm').format('h:mm a')}
            </span>
          ) : (
            <span>
              {reversedDays.map((day, index) => (
                <div
                  style={{
                    float: 'left',
                    width: '150px',
                    marginRight: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    {index !== days.length - 1 ? day + ', ' : day}
                  </span>
                  <div style={{ display: 'block', clear: 'both' }}>
                    <span>
                      {moment(
                        dayObjects[index].fromTime,
                        'HH:mm',
                      ).format('h:mm a')}{' '}
                      -{' '}
                      {moment(
                        dayObjects[index].toTime,
                        'HH:mm',
                      ).format('h:mm a')}
                    </span>
                  </div>
                </div>
              ))}
            </span>
          )}
        </div>
        <div
          style={{
            display: 'block',
            clear: 'both',
            marginTop: '10px',
          }}
        >
          <span>{happyHour.details}</span>
        </div>
        <div
          style={{
            display: 'block',
            clear: 'both',
            marginTop: '10px',
          }}
        >
          {happyHour.link !== '' && (
            <a
              href={happyHour.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>View Details!</span>
            </a>
          )}
        </div>
      </div>
    );
  }

  getHours(hours) {
    var date = moment();
    var dayString = date.format('dddd');
    return (
      <div
        className="no-margin"
        style={{ paddingBottom: '50px', display: 'inline-block' }}
      >
        <div style={{ display: 'block', clear: 'both' }}>
          <span className="title">Hours</span>
        </div>
        <div style={{ display: 'block', clear: 'both' }}>
          <div
            style={{
              float: 'left',
              minWidth: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Sunday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Sunday</span>
            <div>
              {hours.sunday.sundayOpen === false ? (
                <div
                  style={{ float: 'left' }}
                  className={
                    hours.sunday.sundayTwo === true && 'secondSet'
                  }
                >
                  {hours.sunday.sundayFromHour}:
                  {hours.sunday.sundayFromMin > 10
                    ? hours.sunday.sundayFromMin
                    : '0' + hours.sunday.sundayFromMin}{' '}
                  {hours.sunday.sundayFromAMPM}
                  <br />
                  {hours.sunday.sundayToHour}:
                  {hours.sunday.sundayToMin > 10
                    ? hours.sunday.sundayToMin
                    : '0' + hours.sunday.sundayToMin}{' '}
                  {hours.sunday.sundayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
              {hours.sunday.sundayTwo === true && (
                <div style={{ float: 'left', marginLeft: '10px' }}>
                  {hours.sunday.sundayFromHourTwo}:
                  {hours.sunday.sundayFromMinTwo > 10
                    ? hours.sunday.sundayFromMinTwo
                    : '0' + hours.sunday.sundayFromMinTwo}{' '}
                  {hours.sunday.sundayFromAMPMTwo}
                  <br />
                  {hours.sunday.sundayToHourTwo}:
                  {hours.sunday.sundayToMinTwo > 10
                    ? hours.sunday.sundayToMinTwo
                    : '0' + hours.sunday.sundayToMinTwo}{' '}
                  {hours.sunday.sundayToAMPMTwo}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Monday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Monday</span>
            <div>
              {hours.monday.mondayOpen === false ? (
                <div>
                  {hours.monday.mondayFromHour}:
                  {hours.monday.mondayFromMin > 10
                    ? hours.monday.mondayFromMin
                    : '0' + hours.monday.mondayFromMin}{' '}
                  {hours.monday.mondayFromAMPM} <br />
                  {hours.monday.mondayToHour}:
                  {hours.monday.mondayToMin > 10
                    ? hours.monday.mondayToMin
                    : '0' + hours.monday.mondayToMin}{' '}
                  {hours.monday.mondayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Tuesday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Tuesday</span>
            <div>
              {hours.tuesday.tuesdayOpen === false ? (
                <div>
                  {hours.tuesday.tuesdayFromHour}:
                  {hours.tuesday.tuesdayFromMin > 10
                    ? hours.tuesday.tuesdayFromMin
                    : '0' + hours.tuesday.tuesdayFromMin}{' '}
                  {hours.tuesday.tuesdayFromAMPM} <br />
                  {hours.tuesday.tuesdayToHour}:
                  {hours.tuesday.tuesdayToMin > 10
                    ? hours.tuesday.tuesdayToMin
                    : '0' + hours.tuesday.tuesdayToMin}{' '}
                  {hours.tuesday.tuesdayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Wednesday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Wednesday</span>
            <div>
              {hours.wednesday.wednesdayOpen === false ? (
                <div>
                  {hours.wednesday.wednesdayFromHour}:
                  {hours.wednesday.wednesdayFromMin > 10
                    ? hours.wednesday.wednesdayFromMin
                    : '0' + hours.wednesday.wednesdayFromMin}{' '}
                  {hours.wednesday.wednesdayFromAMPM} <br />
                  {hours.wednesday.wednesdayToHour}:
                  {hours.wednesday.wednesdayToMin > 10
                    ? hours.wednesday.wednesdayToMin
                    : '0' + hours.wednesday.wednesdayToMin}{' '}
                  {hours.wednesday.wednesdayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Thursday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Thursday</span>
            <div>
              {hours.thursday.thursdayOpen === false ? (
                <div>
                  {hours.thursday.thursdayFromHour}:
                  {hours.thursday.thursdayFromMin > 10
                    ? hours.thursday.thursdayFromMin
                    : '0' + hours.thursday.thursdayFromMin}{' '}
                  {hours.thursday.thursdayFromAMPM} <br />
                  {hours.thursday.thursdayToHour}:
                  {hours.thursday.thursdayToMin > 10
                    ? hours.thursday.thursdayToMin
                    : '0' + hours.thursday.thursdayToMin}{' '}
                  {hours.thursday.thursdayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Friday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Friday</span>
            <div>
              {hours.friday.fridayOpen === false ? (
                <div>
                  {hours.friday.fridayFromHour}:
                  {hours.friday.fridayFromMin > 10
                    ? hours.friday.fridayFromMin
                    : '0' + hours.friday.fridayFromMin}{' '}
                  {hours.friday.fridayFromAMPM} <br />
                  {hours.friday.fridayToHour}:
                  {hours.friday.fridayToMin > 10
                    ? hours.friday.fridayToMin
                    : '0' + hours.friday.fridayToMin}{' '}
                  {hours.friday.fridayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              float: 'left',
              width: '100px',
              marginRight: '10px',
              marginBottom: '0px',
              padding: '5px',
            }}
            className={
              dayString === 'Saturday' ? 'activeDay' : 'inactiveDay'
            }
          >
            <span className="title">Saturday</span>
            <div>
              {hours.saterday.saterdayOpen === false ? (
                <div>
                  {hours.saterday.saterdayFromHour}:
                  {hours.saterday.saterdayFromMin > 10
                    ? hours.saterday.saterdayFromMin
                    : '0' + hours.saterday.saterdayFromMin}{' '}
                  {hours.saterday.saterdayFromAMPM} <br />
                  {hours.saterday.saterdayToHour}:
                  {hours.saterday.saterdayToMin > 10
                    ? hours.saterday.saterdayToMin
                    : '0' + hours.saterday.saterdayToMin}{' '}
                  {hours.saterday.saterdayToAMPM}
                </div>
              ) : (
                <div>
                  <span>CLOSED</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <Link
          to={{
            pathname: '/guide/category/' + location.tagsMain,
            state: {},
          }}
        >
          <span className="mainTag">{location.tagsMain}</span>;
        </Link>
      );
    }
  }

  checkTag(main, tag) {
    if (main !== tag && tag !== 'Food') {
      return <span className="tag-user">{tag}</span>;
    }
  }

  getTags(location) {
    return (
      <div style={{ paddingTop: '20px' }}>
        <Link
          to={{
            pathname: '/guide/category/' + location.tagsMain,
            state: {},
          }}
        >
          <span className="tag-user">{location.tagsMain}</span>
        </Link>
        {location.tagsBeverages.map((tag, index) => (
          <Link
            to={{
              pathname: '/guide/category/' + tag,
              state: {},
            }}
          >
            <span>{this.checkTag(location.tagsMain, tag)}</span>
          </Link>
        ))}
        {location.tagsCuisine !== undefined &&
          location.tagsCuisine.map((tag, index) => (
            <Link
              to={{
                pathname: '/guide/category/' + tag,
                state: {},
              }}
            >
              <span>{this.checkTag(location.tagsMain, tag)}</span>
            </Link>
          ))}
        {location.tagsDateType.map((tag, index) => (
          <Link
            to={{
              pathname: '/guide/category/' + tag,
              state: {},
            }}
          >
            <span>{this.checkTag(location.tagsMain, tag)}</span>
          </Link>
        ))}
        {location.tagsSetting !== undefined && (
          <div style={{ display: 'inline-block' }}>
            {location.tagsSetting.map((tag, index) => (
              <Link
                to={{
                  pathname: '/guide/category/' + tag,
                  state: {},
                }}
              >
                <span>{this.checkTag(location.tagsMain, tag)}</span>
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  }

  getMatches(location) {
    return <div />;
  }

  getPackageImage(type, locationStart) {
    let location = '';

    if (this.props.user.authenticated === true) {
      let locations = this.props.alllocations;
      const locationDetails = query('locationId')
        .is(locationStart.locationId)
        .on(locations);
      location = locationDetails[0];
    } else {
      location = locationStart;
      // this.props.nonauthLocations
    }

    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getPackageLocations(locations) {
    let locationsx = this.props.locations;
    if (this.props.user.authenticated === true) {
      locationsx = this.props.alllocations;
    }
    let locationsArray = [];

    locations.forEach((location) => {
      const thislocation = query('locationId')
        .is(location.locationId)
        .on(locationsx);
      if (thislocation[0] !== undefined) {
        locationsArray.push(thislocation[0]);
      }
    });

    return (
      <div>
        {locationsArray.map((location, index) => (
          <div className="location-info" key={'key' + index}>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
                key={'key' + index}
              >
                <Link
                  to={{
                    pathname: '/location/' + location.locationId,
                    state: {
                      // location: { location },
                      // packageId: packagex.packageId,
                      // type: this.props.type,
                      // eventId: this.props.eventId,
                    },
                  }}
                >
                  <Row>
                    <Col
                      xs={4}
                      md={4}
                      sm={4}
                      lg={4}
                      className="no-padding thumbnailColSmall"
                    >
                      <Link
                        to={{
                          pathname:
                            '/location/' + location.locationId,
                          state: {
                            // location: { location },
                            // packageId: packagex.packageId,
                            // type: this.props.type,
                            // eventId: this.props.eventId,
                          },
                        }}
                      >
                        {this.getPackageImage('thumb', location)}
                      </Link>
                    </Col>
                    <Col
                      xs={8}
                      md={8}
                      sm={8}
                      lg={8}
                      style={{ padding: '0px 0px 0px 10px' }}
                    >
                      <Link
                        to={{
                          pathname:
                            '/location/' + location.locationId,
                          state: {
                            // location: { location },
                            // packageId: packagex.packageId,
                            // type: this.props.type,
                            // eventId: this.props.eventId,
                          },
                        }}
                      >
                        <div>
                          <span className="location-title">
                            {location.locationTitle}
                          </span>
                          {/* <span className="address">
                           
                          </span> */}
                          <span className="address">
                            {location.address.street},{' '}
                            {location.address.city}{' '}
                            {location.address.state},{' '}
                            {location.address.zip}
                          </span>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </Link>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    {/* {this.getMainTag(location)} */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  }

  readMore(state, mode) {
    this.setState({
      [state]: mode,
    });
  }

  getPackages(packagesArray) {
    if (packagesArray.length !== 0 && this.props.type !== 35) {
      return (
        <Col xs={12} md={3} sm={12} lg={3}>
          <center>
            <h5>Make it an Experience</h5>
          </center>
          {packagesArray.map((packagex, index) => (
            <div style={{ marginTop: '20px' }}>
              <h6> {packagex.title}</h6>
              <span
                style={{
                  fontSize: '14px',
                }}
              >
                {packagex.description}
              </span>
              <Link
                to={{
                  pathname: '/package/' + packagex.packageId,
                  state: {
                    // location: { location },
                    // packageId: packagex.packageId,
                    // type: this.props.type,
                    // eventId: this.props.eventId,
                  },
                }}
              >
                <span
                  style={{
                    fontSize: '14px',
                    padding: '5px 0px 5px 0px',
                    display: 'block',
                  }}
                >
                  View Experience
                </span>
              </Link>

              {/* <div className="card-section">
                        <p>
                          This package was created by{' '}
                          {
                            this.state.packageDetails.creatorInfo
                              .creatorName
                          }
                        </p>
                      </div> */}
              <div className="card-section" id="package-itinerary">
                {/* <h5 className="cartd-title">Recommended Itinerary</h5> */}

                <div>
                  <div className="itinerary">
                    {this.getPackageLocations(packagex.locations)}
                  </div>
                  <br />
                </div>
              </div>
            </div>
          ))}
          {/* <div
            style={{
              borderTop: '1px solid grey',
            }}
          >
            <span style={{ fontWeight: '500', fontSize: '14px' }}>
              Random Advertisement Below
            </span>
            <span
              style={{
                fontWeight: '500',
                display: 'block',
                clear: 'both',
              }}
            >
              Thank You For Your Support!
            </span>
            <div
              style={{
                borderTop: '1px solid grey',
                marginTop: '5px',
              }}
              id="googleadd"
            >
              <GoogleAd
                slot={'7757011337'}
                client={'ca-pub-4916458608267365'}
              />
            </div>
          </div> */}
        </Col>
      );
    } else {
      return (
        <Col xs={12} md={3} sm={12} lg={3} className="no-padding">
          <center>
            {/* <div
              style={{
                borderTop: '1px solid grey',
              }}
            >
              <span style={{ fontWeight: '500', fontSize: '14px' }}>
                Random Advertisement Below
              </span>
              <span
                style={{
                  fontWeight: '500',
                  display: 'block',
                  clear: 'both',
                }}
              >
                Thank You For Your Support!
              </span>
              <div
                style={{
                  borderTop: '1px solid grey',
                  marginTop: '5px',
                }}
                id="googleadd"
              >
                <GoogleAd
                  slot={'7757011337'}
                  client={'ca-pub-4916458608267365'}
                />
              </div>
            </div> */}
          </center>
        </Col>
      );
    }
  }

  truncateShort(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  getEventImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        if (location.tagsEventType[0] === 'Happy Hour') {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2Feat_hero.jpg?alt=media';
        } else {
          path = location.eventHero;
        }
      } else {
        if (
          location !== undefined &&
          location.eventHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.eventHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.eventThumb;
      } else {
        if (
          location !== undefined &&
          location.eventThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.eventThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  truncate(str) {
    return str.length > 10 ? str.substring(0, 7) + '...' : str;
  }

  getDate(entertainment) {
    let selectedDay = {};
    if (
      entertainment.scheduledTime !== undefined &&
      entertainment.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(
        entertainment.scheduledTime.dates.startTimestamp,
      );
    }
    let to = selectedDay._d.toLocaleDateString();
    let t = to.substr(0, to.lastIndexOf('/'));
    return t;
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    let upcomingEvents = this.getUpcomingEvents(
      this.props.timedEvents,
      this.props.location,
      false,
    );
    let upcomingPromos = this.getUpcomingEvents(
      this.props.timedEvents,
      this.props.location,
      true,
    );
    let favorites = [];
    if (this.props.profile.favorites !== undefined) {
      favorites = this.props.profile.favorites;
    }
    let path = this.getImage('hero', this.props.location);

    let packagesArray = [];
    this.props.packages.forEach((packagex) => {
      packagex.locations.forEach((location) => {
        if (location.locationId === this.props.location.locationId) {
          packagesArray.push(packagex);
        }
      });
    });
    return (
      <div style={{ height: '100%' }}>
        <Container style={{ height: '100%' }}>
          <Row className="no-margin" style={{ height: '100%' }}>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
              style={{ height: '100%' }}
            >
              <div style={{ height: '100%' }}>
                <Card
                  style={{ height: '100%', paddingBottom: '60px' }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={path}
                    alt="articleHero"
                  />
                  <CardBody>
                    {/* <Col
                      xs={12}
                      md={packagesArray.length !== 0 ? 9 : 12}
                      sm={12}
                      lg={packagesArray.length !== 0 ? 9 : 12}
                      className="locationCard"
                      style={{
                        paddingRight: '15px',
                        paddingLeft: '0px',
                      }}
                    > */}
                    <Col
                      xs={12}
                      md={9}
                      sm={12}
                      lg={9}
                      className="locationCard"
                      style={{
                        paddingRight: '15px',
                        paddingLeft: '0px',
                      }}
                    >
                      <Row className="no-margin">
                        <Col
                          xs={12}
                          md={12}
                          sm={12}
                          lg={12}
                          className="no-padding"
                        >
                          <CardTitle className="left">
                            {this.props.user.authenticated ===
                              true && (
                              <span
                                style={{
                                  display: 'block',
                                  left: '-3px',
                                  position: 'relative',
                                  float: 'left',
                                  top: '0px',
                                }}
                              >
                                {this.getFavoriteButton(
                                  this.props.location.locationId,
                                  favorites,
                                )}
                              </span>
                            )}
                            {this.props.location !== undefined &&
                              this.props.location.locationTitle}
                          </CardTitle>
                          {/* </Col>
                      <Col
                        xs={12}
                        md={6}
                        sm={6}
                        lg={6}
                        className="no-padding"
                      > */}
                          {this.props.location !== undefined &&
                            this.props.location.claimed !== true &&
                            this.props.type !== 35 &&
                            this.getClaimButton()}
                        </Col>
                      </Row>
                      <CardText>
                        <div className="card-section">
                          <div
                            ref={(divElementDesc) => {
                              this.divElementDesc = divElementDesc;
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.props.location
                                    .locationDescription,
                              }}
                              className={
                                this.state.readMoreDescription ===
                                true
                                  ? 'editoOverRide'
                                  : 'editoOverRide editoOverRideShort'
                              }
                            />
                          </div>

                          {this.state.readMoreDescription ===
                          false ? (
                            <div
                              onClick={() => {
                                this.readMore(
                                  'readMoreDescription',
                                  true,
                                );
                              }}
                              id="readMore"
                            >
                              {this.state.descHeight > 99 && (
                                <span
                                  style={{
                                    fontSize: '15px',
                                    color: '#f21d59',
                                  }}
                                >
                                  Read More
                                </span>
                              )}
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                this.readMore(
                                  'readMoreDescription',
                                  false,
                                );
                              }}
                              id="readMore"
                            >
                              {this.state.descHeight > 99 && (
                                <span
                                  style={{
                                    fontSize: '15px',
                                    color: '#f21d59',
                                  }}
                                >
                                  Show Less
                                </span>
                              )}
                            </div>
                          )}

                          <div>
                            {/* {this.getMainTag(this.props.location)} */}
                            {/* {this.props.user.authenticated === true &&
                            this.getMatches(this.props.location)} */}
                            {/* {this.props.user.authenticated === false &&
                            this.getTags(this.props.location)} */}

                            {this.getTags(this.props.location)}
                          </div>
                        </div>
                        {upcomingEvents.length > 0 ? (
                          <div className="card-section">
                            <Container>
                              <h5>Upcoming Events</h5>
                              <Row style={{ marginBottom: '30px' }}>
                                {upcomingEvents.map(
                                  (event, index) => (
                                    <Col
                                      xs={6}
                                      md={4}
                                      sm={6}
                                      lg={3}
                                      className="no-padding"
                                    >
                                      <div
                                        style={{
                                          border:
                                            '0px solid rgba(0, 0, 0, 0.125)',
                                          background:
                                            'white !important',
                                          marginRight: '10px',
                                        }}
                                      >
                                        <Link
                                          to={{
                                            pathname:
                                              '/timedevent/' +
                                              event.eventId,
                                            state: {
                                              event: { event },
                                              // eventId: { eventId },
                                              // token: { token },
                                              type: 500,
                                              back: {
                                                back: window.location
                                                  .pathname,
                                              },
                                            },
                                          }}
                                        >
                                          <CardImg
                                            top
                                            width="100%"
                                            src={this.getEventImage(
                                              'hero',
                                              event,
                                            )}
                                            alt="articleHero"
                                          />

                                          <div
                                            style={{
                                              paddingTop: '10px',
                                              border: '0px',
                                            }}
                                          >
                                            <span className="title">
                                              {event.eventTitle}
                                            </span>
                                            {event.scheduledTime.dates
                                              .repeatType !==
                                              undefined &&
                                            event.scheduledTime.dates
                                              .repeatType ===
                                              'Weekly' ? (
                                              <span
                                                className="address"
                                                style={{
                                                  marginTop: '5px',
                                                }}
                                              >
                                                {this.getWhen(event)}
                                              </span>
                                            ) : (
                                              <span
                                                className="address"
                                                style={{
                                                  marginTop: '5px',
                                                }}
                                              >
                                                {this.getDate(event)}
                                              </span>
                                            )}

                                            {this.getMainTag(event)}
                                            {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                                            <CardText>
                                              <span className="editoOverRide">
                                                {this.truncateShort(
                                                  event.eventDescription,
                                                )}
                                              </span>
                                            </CardText>
                                            <CardText />
                                          </div>
                                        </Link>
                                      </div>
                                    </Col>
                                  ),
                                )}
                              </Row>
                            </Container>
                          </div>
                        ) : (
                          <div />
                        )}
                        {upcomingPromos.length > 0 ? (
                          <div className="card-section">
                            <Container>
                              <h5>Promotions</h5>
                              <Row style={{ marginBottom: '30px' }}>
                                {upcomingPromos.map(
                                  (event, index) => (
                                    <Col
                                      xs={6}
                                      md={4}
                                      sm={6}
                                      lg={3}
                                      className="no-padding"
                                    >
                                      <div
                                        style={{
                                          border:
                                            '0px solid rgba(0, 0, 0, 0.125)',
                                          background:
                                            'white !important',
                                          marginRight: '10px',
                                        }}
                                      >
                                        <Link
                                          to={{
                                            pathname:
                                              '/timedevent/' +
                                              event.eventId,
                                            state: {
                                              event: { event },
                                              // eventId: { eventId },
                                              // token: { token },
                                              type: 500,
                                              back: {
                                                back: window.location
                                                  .pathname,
                                              },
                                            },
                                          }}
                                        >
                                          <CardImg
                                            top
                                            width="100%"
                                            src={this.getEventImage(
                                              'hero',
                                              event,
                                            )}
                                            alt="articleHero"
                                          />

                                          <div
                                            style={{
                                              paddingTop: '10px',
                                              border: '0px',
                                            }}
                                          >
                                            <span className="title">
                                              {event.eventTitle}
                                            </span>
                                            {event.scheduledTime.dates
                                              .repeatType !==
                                              undefined &&
                                            event.scheduledTime.dates
                                              .repeatType ===
                                              'Weekly' ? (
                                              <span
                                                className="address"
                                                style={{
                                                  marginTop: '5px',
                                                }}
                                              >
                                                {this.getWhen(event)}
                                              </span>
                                            ) : (
                                              <span
                                                className="address"
                                                style={{
                                                  marginTop: '5px',
                                                }}
                                              >
                                                {this.getDate(event)}
                                              </span>
                                            )}

                                            {this.getMainTag(event)}
                                            {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                                            <CardText>
                                              <span className="editoOverRide">
                                                {this.truncateShort(
                                                  event.eventDescription,
                                                )}
                                              </span>
                                            </CardText>
                                            <CardText />
                                          </div>
                                        </Link>
                                      </div>
                                    </Col>
                                  ),
                                )}
                              </Row>
                            </Container>
                          </div>
                        ) : (
                          <div />
                        )}
                        <div
                          ref={(divElementStory) => {
                            this.divElementStory = divElementStory;
                          }}
                        >
                          {this.props.location
                            .locationDescriptionLong !== undefined &&
                            this.props.location
                              .locationDescriptionLong.length >
                              12 && (
                              <div className="card-section">
                                <h5>Our Story</h5>

                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      this.props.location
                                        .locationDescriptionLong,
                                  }}
                                  className={
                                    this.state.readMore === true
                                      ? 'editoOverRide'
                                      : 'editoOverRide editoOverRideShort'
                                  }
                                />
                                {this.state.readMore === false ? (
                                  <div
                                    onClick={() => {
                                      this.readMore('readMore', true);
                                    }}
                                  >
                                    {this.state.storyHeight > 99 && (
                                      <span
                                        style={{
                                          fontSize: '15px',
                                          color: '#f21d59',
                                        }}
                                      >
                                        Read More
                                      </span>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      this.readMore(
                                        'readMore',
                                        false,
                                      );
                                    }}
                                  >
                                    {this.state.storyHeight > 99 && (
                                      <span
                                        style={{
                                          fontSize: '15px',
                                          color: '#f21d59',
                                        }}
                                      >
                                        Show Less
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                        <div className="card-section">
                          <Row className="no-margin">
                            <Col
                              xs={6}
                              md={6}
                              sm={6}
                              lg={6}
                              className="no-padding"
                            >
                              <span className="address bold">
                                {this.props.location.address.street}
                              </span>
                              <span className="address">
                                {this.props.location.address.city}{' '}
                                {this.props.location.address.state},{' '}
                                {this.props.location.address.zip}
                              </span>
                            </Col>
                            <Col
                              xs={6}
                              md={6}
                              sm={6}
                              lg={6}
                              className="no-padding"
                            >
                              {this.getPhone(this.props.location)}
                              {this.getWebsite(this.props.location)}
                              {this.getMenu(this.props.location)}
                            </Col>
                          </Row>
                        </div>
                        {this.props.location.hours !== undefined &&
                          this.props.location.hours.showHours !==
                            undefined &&
                          this.props.location.hours.showHours !==
                            false && (
                            <div className="card-section">
                              {this.getHours(
                                this.props.location.hours,
                              )}
                            </div>
                          )}
                        {this.props.location.hours !== undefined &&
                          this.props.location.hours.showHours ===
                            undefined && (
                            <div className="card-section">
                              {this.getHours(
                                this.props.location.hours,
                              )}
                            </div>
                          )}

                        {this.props.location.happyHour !==
                          undefined &&
                          this.props.location.happyHour.days
                            .length !== 0 &&
                          Object.keys(
                            this.props.location.happyHour.days,
                          ).length !== 0 && (
                            <div className="card-section">
                              {this.getHappyHour(
                                this.props.location.happyHour,
                              )}
                            </div>
                          )}

                        {this.getHappyHoursAndPromos(
                          this.props.location,
                        )}

                        {/* <div className="card-section">
                        <div
                          style={{ height: '200px', width: '100%' }}
                        >
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key:
                                'AIzaSyBGGWTnJQdshIr_HSsNYJREN_vWIlxZUX8',
                            }}
                            defaultCenter={[
                              this.props.location.address.longitude,
                              this.props.location.address.latitude,
                            ]}
                            defaultZoom={this.props.zoom}
                          >
                            <AnyReactComponent
                              lat={
                                this.props.location.address.longitude
                              }
                              lng={
                                this.props.location.address.latitude
                              }
                              text={this.props.location.locationTitle}
                            />
                          </GoogleMapReact>
                        </div>
                      </div> */}
                      </CardText>
                    </Col>
                    {this.getPackages(packagesArray)}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFavorites: (bundle) => {
      dispatch(setFavorites(bundle));
    },
  };
};

function mapStateToProps(state) {
  return {
    promos: state.promos.promos,
    profile: state.profile.profile,
    user: state.user,
    packages: state.packages.packages,
    locations: state.locations.locations,
    alllocations: state.locations.locations,
    timedEvents: state.timedEvents.events,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationDetails);

// Location Details Available
// **************************
// this.props.location.location.locationTitle
// this.props.location.location.categories
// this.props.location.location.locationDescription
// this.props.location.location.locationHero
// this.props.location.location.locationThumb
// this.props.location.location.locationId
// this.props.location.location.locationType
// ** HappyHours and Packages as well
