import MaterialIcon from 'material-icons-react';
// import addbtn from "../images/addbtn.png";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { confirmAlert } from 'react-confirm-alert';
import 'react-day-picker/lib/style.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../config/firebase';
import GuestListModal from '../components/GuestListModal';
// Components
import TrevelAgent from '../components/TrevelAgent';
// Reducers
import { getCe, updateCe } from '../sagas/customEventSaga/reducer';
import { clearTaRequest } from '../sagas/taRequestSaga/reducer';
import {
  getNewEvent,
  resetNewEvent,
} from '../sagas/createEventSaga/reducer';

import PromotionFeedMulti from '../components/PromotionFeedMulti';

const query = require('array-query');

const format = 'h:mm a';
const now = moment().hour(0).minute(0);

export class CustomPackageHome extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      selectedDay: '',
      selectedTime: '',
      copied: false,
      crop: {
        unit: '%',
        width: 30,
        aspect: 800 / 259,
      },
      cropThumb: {
        unit: '%',
        width: 30,
        aspect: 146 / 122,
      },
      cropping: false,
      croppingThumb: false,
      ideas: false,
      trevelAgent: true,
      calendarLock: false,
      showTrevelAgent: false,
      forceCalendar: false,
      showTopMenu: true,
      disableSave: false,
    };
    this.handleDayClick = this.handleDayClick.bind(this);
    this.setTagFilters = this.setTagFilters.bind(this);
    this.setTrevelAgentState = this.setTrevelAgentState.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.confirmCopy = this.confirmCopy.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.showTopMenu = this.showTopMenu.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }

    if (this.props.newEvent.fromLocation === true) {
      const newEvent = {
        connections: this.props.newEvent.connections,
        planFirst: this.props.newEvent.planFirst,
        connectFirst: this.props.newEvent.connectFirst,
        clear: this.props.newEvent.clear,
        step: this.props.newEvent.step,
        invitedUsers: this.props.newEvent.invitedUsers,
        favoriteLocations: this.props.newEvent.favoriteLocations,
        fromFavorite: this.props.newEvent.fromFavorite,
        fromVEvent: this.props.newEvent.fromVEvent,
        fromLocation: false,
        fromPackage: this.props.newEvent.fromPackage,
        vEvent: this.props.newEvent.vEvent,
        location: this.props.newEvent.locations,
        weightedLocations: this.props.newEvent.weightedLocations,
        customEvent: this.props.customEvent,
      };
      this.props.getNewEvent(newEvent);
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.customEvent.event.description !==
      prevProps.customEvent.event.description
    ) {
      this.setState({
        description: this.props.customEvent.event.description,
      });
    }

    if (
      this.props.customEvent.event.title !==
      prevProps.customEvent.event.title
    ) {
      this.setState({
        title: this.props.customEvent.event.title,
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // let multiDates = [];
    // if (this.props.customEvent.event.locations.length !== 0) {
    //   this.props.customEvent.event.locations.forEach((location) => {
    //     if (location.eventId !== undefined) {
    //       if (
    //         this.props.customEvent.event.scheduledTime.date === null
    //       ) {
    //         let eventDetails = query('eventId')
    //           .is(location.eventId)
    //           .on(this.props.timedEvents);

    //         eventDetails[0].scheduledTime.dates.multiDates.forEach(
    //           (date) => {
    //             multiDates.push(
    //               moment(date.date).format('YYYY-MM-DD'),
    //             );
    //           },
    //         );
    //         this.setState({ showTrevelAgent: true });
    //         console.log('multiDates', multiDates);
    //       }
    //     }
    //   });
    // }

    // Clear taRequest if iniialMoodSet === true   What is initialMood again?
    if (
      this.props.customEvent.initialMoodSet !== undefined &&
      this.props.customEvent.initialMoodSet === true
    ) {
      let taRequest = this.props.taRequest;
      taRequest.tagFilters = [];
      this.props.clearTaRequest(taRequest);
      this.setState({ trevelAgent: false });
    }

    // if (
    //   (this.props.customEvent.initialMoodSet !== undefined &&
    //     this.props.customEvent.initialMoodSet === true) ||
    //   (this.props.customEvent.event.locations !== undefined &&
    //     this.props.customEvent.event.locations.length > 0)
    // ) {
    //   console.log('this');
    //   this.setState({ trevelAgent: false });
    // }

    // If Event Incluced with Date Set That Date and Lock Calendar
    let showTopMenu = true;
    if (this.props.customEvent.event.locations.length > 0) {
      this.props.customEvent.event.locations.forEach(
        (location, i) => {
          console.log('thing', this.props.customEvent.event);
          let currentEvent = null;
          if (location !== null && location.event === true) {
            const eventDetails = query('id')
              .is(location.eventId)
              .on(this.props.timedEvents);
            currentEvent = eventDetails[0];
          }

          if (currentEvent !== null) {
            if (
              (currentEvent.scheduledTime.dates.repeat !==
                undefined &&
                currentEvent.scheduledTime.dates.repeat === true) ||
              (currentEvent.scheduledTime.dates.multiDates !==
                undefined &&
                currentEvent.scheduledTime.dates.multiDates.length >
                  1)
            ) {
              if (
                this.props.customEvent.event.scheduledTime
                  .timestamp === null
              ) {
                showTopMenu = false;
              }
            }
          }
        },
      );
    }
    this.setState({
      showTopMenu,
    });

    this.getUserTags(
      this.props.newEvent,
      this.props.users,
      this.props.profile.userId,
    );

    const modeInt = parseInt(this.props.match.params.mode, 10);

    // Set Default Images
    let heroURL = '';
    if (
      this.props.customEvent.event.eventSettings.customHero === true
    ) {
      heroURL =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
        this.props.customEvent.event.heroImage +
        '?alt=media';
    }
    let thumbURL = '';
    if (
      this.props.customEvent.event.eventSettings.customThumb === true
    ) {
      thumbURL =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
        this.props.customEvent.event.thumbnail +
        '?alt=media';
    }
    //Set Default ID
    if (this.props.customEvent.eventId !== undefined) {
      this.setState({
        dbref: this.props.customEvent.eventId,
      });
    } else {
      // Get New Event ID to use later
      const db = firebase.firestore();
      const newEventRef = db.collection('package').doc();
      const dbref = newEventRef.id;
      this.setState({
        dbref: dbref,
      });
    }

    // Set Default Schedule
    if (
      this.props.customEvent.event.scheduledTime.timestamp !== null &&
      this.props.customEvent.event.scheduledTime.timestamp !== ''
    ) {
      let selectedDay = moment(
        this.props.customEvent.event.scheduledTime.timestamp,
      );
      this.setState({
        selectedDay: selectedDay._d,
      });
    }
    if (
      this.props.customEvent.event.scheduledTime.timeString !==
        null &&
      this.props.customEvent.event.scheduledTime.timeString !== ''
    ) {
      let selectedTime = moment(
        this.props.customEvent.event.scheduledTime.timeString,
      );
      this.setState({
        selectedTime: selectedTime,
      });
    }

    // If Event Incluced with Date Set That Date and Lock Calendar
    let event = null;
    if (this.props.customEvent.event.locations.length > 0) {
      this.props.customEvent.event.locations.forEach(
        (location, i) => {
          if (location !== null && location.event === true) {
            const eventDetails = query('id')
              .is(location.eventId)
              .on(this.props.timedEvents);
            event = eventDetails[0];
          }
        },
      );

      if (event !== null) {
        let selectedDay = moment(
          event.scheduledTime.dates.startTimestamp,
        );
        if (
          (event.scheduledTime.dates.repeat !== undefined &&
            event.scheduledTime.dates.repeat === true) ||
          (event.scheduledTime.dates.multiDates !== undefined &&
            event.scheduledTime.dates.multiDates.length > 1)
        ) {
          this.setState({
            // selectedDay: selectedDay._d,
            calendarLock: false,
            event,
            mode: 2,
            forceCalendar: true,
          });
        } else {
          this.setState({
            calendarLock: true,
            event,
          });
        }
      }
    }

    this.setState({
      title: this.props.customEvent.event.title,
      description: this.props.customEvent.event.description,
      customHero:
        this.props.customEvent.event.eventSettings.customHero,
      customThumb:
        this.props.customEvent.event.eventSettings.customThumb,
      heroPath: heroURL,
      thumbPath: thumbURL,
      mode: modeInt,
      loading: false,
    });
  }

  setSelectedDate(date) {
    this.setState({
      selectedDay: date,
    });
  }

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    let t = to.substr(0, to.lastIndexOf('/'));
    return t;
  }

  setTagFilters(tagFilters) {
    this.setState({ tagFilters });
  }

  setTrevelAgentState(status) {
    this.setState({
      trevelAgent: status,
    });
    // this.searchUpdated("");
  }

  getUserTags(newEvent, users, userId) {
    let connections = newEvent.connections;
    let userTags = null;
    let userMatch = [];
    Object.keys(connections).forEach((key) => {
      if (key !== userId) {
        if (connections[key].connected !== false) {
          if (connections[key].newUser !== true) {
            const user = query('userId').is(key).on(users);
            userTags = user[0].tags;
            userMatch = user[0];
          }
        }
      }
    });

    if (userTags !== undefined && userTags !== null) {
      this.setState({
        otherUserTagsLove: userTags.love,
        otherUserTagsHate: userTags.hate,
        otherUser: userMatch,
      });
    } else {
      this.setState({
        otherUserTagsLove: undefined,
        otherUserTagsHate: undefined,
        otherUser: userMatch,
      });
    }
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail bitch';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  removeGuest(profile, guestId, onClose) {
    // This users info

    let connections = this.props.newEvent.connections;
    delete connections[guestId];
    let invitedUsers = this.props.newEvent.invitedUsers;

    let newInviteList = [];
    invitedUsers.forEach((guest) => {
      if (guest !== guestId) {
        newInviteList.push(guest);
      }
    });

    const newEvent = {
      connections,
      planFirst: this.props.newEvent.planFirst,
      connectFirst: this.props.newEvent.connectFirst,
      clear: this.props.newEvent.clear,
      step: this.props.newEvent.step,
      invitedUsers: newInviteList,
      favoriteLocations: this.props.newEvent.favoriteLocations,
      fromFavorite: this.props.newEvent.fromFavorite,
      fromVEvent: this.props.newEvent.fromVEvent,
      fromLocation: this.props.newEvent.fromLocation,
      fromPackage: this.props.newEvent.fromPackage,
      vEvent: this.props.newEvent.vEvent,
      location: this.props.newEvent.locations,
      weightedLocations: this.props.newEvent.weightedLocations,
      customEvent: this.props.customEvent,
    };
    this.props.getNewEvent(newEvent);
    onClose();
    this.tabBounce();
  }

  tabBounce() {
    this.setState({
      mode: 1,
      removing: true,
    });
    setTimeout(() => this.tabRebounce(), 10);
  }

  tabRebounce() {
    this.setState({
      mode: 3,
      removing: false,
    });
  }

  confirmDelete(userId, fullName, status) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h4
              style={{
                fontSize: '20px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Remove Guest
            </h4>

            <div
              style={{ textAlign: 'center', marginBottom: '10px' }}
            >
              <p>Are you sure you want to remove {fullName}?</p>
              {status === 1 && (
                <p>
                  It looks like they already approved this Experience.
                </p>
              )}
            </div>

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <div>
                  <button
                    onClick={() => {
                      this.removeGuest(
                        this.props.profile,
                        userId,
                        onClose,
                      );
                    }}
                    className="redbutton right"
                  >
                    Yes
                  </button>
                </div>
              </Col>
              <Col xs={6} md={6} sm={6} lg={6}>
                <div>
                  <button
                    onClick={onClose}
                    className="redbutton left"
                  >
                    Nevermind
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        );
      },
    });
  }

  getHeadliners(event) {
    let headliners = '';
    event.entertainment.headliners.forEach((entertainment, index) => {
      headliners = headliners + entertainment.entertainmentTitle;
      if (index + 1 !== event.entertainment.headliners.length) {
        headliners = headliners + ' & ';
      }
    });
    if (headliners === '') {
      return event.eventTitle;
    } else {
      return headliners;
    }
  }

  getLocationDetails(location, locations, index, editMode) {
    const currentIndex = index;
    let locationReady = '';
    let event = {};
    let dayName = '';
    let timeStatus = true;

    // Check if this is an event
    if (
      location !== null &&
      location.event !== undefined &&
      location.event === true
    ) {
      const eventDetails = query('id')
        .is(location.eventId)
        .on(this.props.timedEvents);
      event = eventDetails[0];

      if (event !== undefined && event !== null) {
        if (event.scheduledTime.dates.oneDayEvent === true) {
          if (
            this.props.customEvent.event.scheduledTime.date !== null
          ) {
            let check = moment(
              event.scheduledTime.dates.startDate,
            ).isSame(this.props.customEvent.event.scheduledTime.date);
            if (check === true) {
            } else {
            }
          }
        }
      }
    } else {
      let lowercase = '';
      if (this.props.customEvent.event.scheduledTime.date !== null) {
        dayName = moment(
          this.props.customEvent.event.scheduledTime.date,
        ).format('dddd');
        lowercase = dayName.toLowerCase();
        if (lowercase === 'saturday') {
          lowercase = 'saterday';
        }
        let openString = lowercase + 'Open';
        if (
          location.hours !== undefined &&
          location.hours[lowercase][openString] !== undefined &&
          location.hours[lowercase][openString] === true
        ) {
          timeStatus = false;
        } else {
        }
      }

      // if (
      //   this.props.customEvent.event.scheduledTime.date !== null
      // ) {
      //   let check = moment(
      //     event.scheduledTime.dates.startDate,
      //   ).isSame(this.props.customEvent.event.scheduledTime.date);
      //   if (check === true) {
      //     console.log('stevens true');
      //   } else {
      //     console.log('stevens false');
      //   }
      // }
    }

    if (location.type === 1) {
      locationReady = location.location;
    } else {
      if (
        location.locationId !== 'PQszNY3Mpy8oJXTQMooB' &&
        location.locationId !== 'WkhbxzFvfPjnDTXCBVyr' &&
        location.locationId !== '-LKT3cWR6JqNdUTEST'
      ) {
        if (location.locationId !== null) {
          const locationDetails = query('locationId')
            .is(location.locationId)
            .on(locations);
          locationReady = locationDetails[0];
        } else {
          locationReady = location;
        }
      } else {
        const locationDetails = query('locationId')
          .is(location.locationId)
          .on(this.props.demo.demoLocations);
        locationReady = locationDetails[0];
      }
    }

    return (
      <div>
        <Row className="oddeven">
          <Col
            xs={10}
            md={10}
            sm={10}
            lg={10}
            key={'key' + index}
            className="no-padding"
          >
            <Link
              to={{
                pathname:
                  locationReady.locationId !== null &&
                  '/location/' + locationReady.locationId,
                state: {
                  location: { locationReady },
                  // eventId: { eventId },
                  // token: { token },
                  type: 35,
                },
              }}
            >
              {location.event === true ? (
                <div className="full-height left">
                  <img
                    src={event.eventThumb}
                    alt={location.locationTitle}
                    className="thumbnail"
                  />
                </div>
              ) : (
                <div className="full-height left">
                  {this.getImage('thumb', locationReady)}
                </div>
              )}

              {location.event === true &&
              location.tagsEventType !== 'Happy Hour' ? (
                <div>
                  <span className="location-title">
                    {this.getHeadliners(event)}
                  </span>

                  <span className="address">
                    @ {locationReady.locationTitle}
                  </span>
                </div>
              ) : (
                <span className="location-title">
                  {locationReady.locationTitle}
                </span>
              )}

              <span className="address">
                {locationReady.address.street}
              </span>
              <span className="address">
                {locationReady.address.city}{' '}
                {locationReady.address.state},{' '}
                {locationReady.address.zip}
              </span>
            </Link>
          </Col>
          <Col
            xs={2}
            md={2}
            sm={2}
            lg={2}
            key={'key' + index}
            className="no-padding"
          >
            <div className="right text-right">
              {this.getDeleteButton(index, this.props.customEvent)}
            </div>
            {this.getEditButton(editMode, currentIndex)}
            <div
              style={{
                clear: 'both',
                right: '0px',
                bottom: '0px',
                position: 'absolute',
              }}
            >
              {this.getMoveButton(index, this.props.customEvent)}
            </div>
          </Col>
          {timeStatus === false && (
            <div
              style={{
                border: 'red 1px solid',
                padding: '10px',
                margin: '10px 0px 0px 0px ',
                clear: 'both',
                display: 'block',
                width: '100%',
                background: '#ee015a',
              }}
            >
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: 'white',
                }}
              >
                Sorry, but this location is Closed on {dayName}.
              </span>
              <span
                style={{
                  fontSize: '13px',
                  display: 'block',
                  clear: 'both',
                  color: 'white',
                }}
              >
                Please change or remove this location before saving.
              </span>
            </div>
          )}
        </Row>
      </div>
    );
  }

  getMoveButton(index, customEvent) {
    const locations = customEvent.event.locations;
    if (locations.length === 1) {
      return (
        <div>
          <MaterialIcon
            icon="arrow_downward"
            size={20}
            color="#d0d0d0"
          />
        </div>
      );
    } else {
      if (index === 0) {
        return (
          <MaterialIcon
            icon="arrow_downward"
            size={20}
            color="#f21d59"
            onClick={() => {
              this.changeOrder(customEvent, index, 'down');
            }}
          />
        );
      } else if (index > 0 && index !== locations.length - 1) {
        return (
          <div>
            <MaterialIcon
              icon="arrow_upward"
              size={20}
              color="#f21d59"
              onClick={() => {
                this.changeOrder(customEvent, index, 'up');
              }}
            />
            <MaterialIcon
              icon="arrow_downward"
              size={20}
              color="#f21d59"
              onClick={() => {
                this.changeOrder(customEvent, index, 'down');
              }}
            />
          </div>
        );
      } else {
        return (
          <MaterialIcon
            icon="arrow_upward"
            size={20}
            color="#f21d59"
            onClick={() => {
              this.changeOrder(customEvent, index, 'up');
            }}
          />
        );
      }
    }
  }

  getDeleteButton(index, customEvent) {
    const locations = customEvent.event.locations;
    if (locations.length === 1) {
      return (
        <div>
          <MaterialIcon icon="delete" size={20} color="#d0d0d0" />
        </div>
      );
    } else {
      return (
        <MaterialIcon
          icon="delete"
          size={20}
          color="#f21d59"
          onClick={() => {
            this.removeLocation(index, customEvent);
          }}
        />
      );
    }
  }

  getEditButton(editMode, index) {
    const currentIndex = index;
    if (editMode === true) {
      return (
        <div>
          <Link
            to={{
              pathname: '/event/create/add/',
              state: {
                index: currentIndex,
                editItinerary: true,
              },
            }}
            className="right"
          >
            <MaterialIcon icon="edit" size={20} color="#f21d59" />
          </Link>
        </div>
      );
    }
  }

  // getFooter(mode) {
  //   return (
  //     <Container>
  //       <Row className="no-margin">
  //         <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
  //           <Link
  //             to={{
  //               pathname: "/events/home"
  //             }}
  //             className="footer-link"
  //           >
  //             <span className="footer-link left">Cancel</span>
  //           </Link>
  //         </Col>
  //         <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
  //           <Link
  //             to={{
  //               pathname: "/checkout/customsetup/"
  //             }}
  //             className="footer-link"
  //           >
  //             <span className="right">Save</span>
  //           </Link>
  //         </Col>
  //       </Row>
  //     </Container>
  //   );
  // }

  titleOnChange(e) {
    this.setState({ title: e.target.value });
  }

  quickTitle(value) {
    this.setState({ title: value });
  }

  descriptionOnChange(e) {
    this.setState({ description: e.target.value });
  }

  displayForm(description) {
    return (
      // <div id="eventDetailsForm">
      <div id="eventDetailsForm">
        <div>
          <div>
            <Container>
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="left no-padding"
                >
                  <span className="inputLabel">Title</span>

                  <input
                    type="text"
                    onChange={this.titleOnChange.bind(this)}
                    value={this.state.title}
                    className="full-width"
                    style={{ padding: '10px 5px 10px 5px' }}
                  />

                  <div>
                    <div className="quickText">
                      <span
                        onClick={() => {
                          this.quickTitle('Dinner');
                        }}
                      >
                        Dinner
                      </span>
                    </div>
                    <div className="quickText">
                      <span
                        onClick={() => {
                          this.quickTitle('Drinks');
                        }}
                      >
                        Drinks
                      </span>
                    </div>
                    <div className="quickText">
                      <span
                        onClick={() => {
                          this.quickTitle('Date Night');
                        }}
                      >
                        Date Night
                      </span>
                    </div>
                    <div className="quickText">
                      <span
                        onClick={() => {
                          this.quickTitle('Debauchery');
                        }}
                      >
                        Debauchery
                      </span>
                    </div>
                  </div>

                  <span className="inputLabel">
                    Description / Message
                  </span>
                  <textarea
                    type="text"
                    onChange={this.descriptionOnChange.bind(this)}
                    value={this.state.description}
                    className="full-width"
                    style={{ minHeight: '100px' }}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  removeLocation(i, customEvent) {
    const locations = customEvent.event.locations;
    if (locations.length === 1) {
      // this.scaleAnimationDialog.show();
      // this.setState({
      //   loading: false
      // });
    } else {
      const step = customEvent.step - 1;
      locations.splice(i, 1);
      customEvent.event.locations = locations;
      this.props.getCe(customEvent);
    }
  }

  changeOrder(customEvent, from, direction) {
    let to = 0;
    if (direction === 'up') {
      to = from - 1;
    }
    if (direction === 'down') {
      to = from + 1;
    }
    const locations = customEvent.event.locations;
    var f = locations.splice(from, 1)[0];
    locations.splice(to, 0, f);
    customEvent.locations = locations;
    customEvent.step = 6;
    this.props.getCe(customEvent);
  }

  getInstructions(customEvent) {
    const locationCount = customEvent.event.locations.length;
    if (locationCount === 0) {
      return (
        <span className="instructions">
          Click the button below to add your first location!
        </span>
      );
    } else {
      return (
        <span className="instructions">
          Add another location or just click{' '}
          <span className="checkFix">
            <MaterialIcon icon="check" size={25} color="#b90b3a" />
          </span>
        </span>
      );
    }
  }

  // Crop Functions
  // Banner Crop
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      cropping: true,
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'heroImage.jpeg',
      );
      this.setState({ heroURL: croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getBannerCropper(bannerCropping) {
    const { crop, croppedImageUrl, src } = this.state;
    if (bannerCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="Bar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropper();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropper();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </div>
        </div>
      );
    }
  }
  // End Banner Crop
  // Thumb Crop
  onSelectFileThumb = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      croppingThumb: true,
    });
  };

  onImageLoadedThumb = (image) => {
    this.imageRef = image;
  };

  onCropCompleteThumb = (crop) => {
    this.makeClientCropThumb(crop);
  };

  onCropChangeThumb = (cropThumb, percentCrop) => {
    this.setState({ cropThumb });
  };

  async makeClientCropThumb(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImgThumb(
        this.imageRef,
        crop,
        'thumbnail.jpeg',
      );
      this.setState({
        thumbURL: croppedImageUrl,
      });
    }
  }

  getCroppedImgThumb(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getThumbnailCropper(thumbnailCropping) {
    const { cropThumb, croppedImageUrl, src } = this.state;
    if (thumbnailCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropperThumb();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropperThumb();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={cropThumb}
                onImageLoaded={this.onImageLoadedThumb}
                onComplete={this.onCropCompleteThumb}
                onChange={this.onCropChangeThumb}
              />
            )}
          </div>
        </div>
      );
    }
  }
  //End Thumb Crop
  cancelCropper() {
    this.setState({
      cropping: false,
    });
  }

  saveCropper() {
    this.uploadImage(this.state.blob, 'hero');
    this.setState({
      cropping: false,
    });
  }

  cancelCropperThumb() {
    this.setState({
      croppingThumb: false,
    });
  }

  saveCropperThumb() {
    this.uploadImage(this.state.blob, 'thumb');
    this.setState({
      croppingThumb: false,
    });
  }

  uploadImage = (image, type) => {
    const uploadUrl =
      'event_images/' + this.state.dbref + '/' + image.name;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadUrl);
    imageRef.put(image).then((snapshot) => {
      // const path = snapshot.ref.location.path_;
      if (type === 'hero') {
        let heroImagePath =
          'event_images%2F' + this.state.dbref + '%2FheroImage.jpeg';
        let heroPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          heroImagePath +
          '?alt=media';
        this.setState({
          heroPath,
          customHero: true,
        });
      } else {
        let thumbnailImagePath =
          'event_images%2F' + this.state.dbref + '%2Fthumbnail.jpeg';
        let thumbPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          thumbnailImagePath +
          '?alt=media';
        this.setState({
          thumbPath,
          customThumb: true,
        });
      }
    });
  };

  //Tabs
  getDetailsTab() {
    return (
      <div className="section">
        <Container>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div className="header-container">
                <div className="header text-center">
                  {this.getInstructions(this.props.customEvent)}
                </div>
              </div>
              <div>
                {/* <span className="inputLabel">Itinerary</span> */}
                {this.props.customEvent.event.locations.map(
                  (location, index) => (
                    <div>
                      {this.getLocationDetails(
                        location,
                        this.props.locations,
                        index,
                        true,
                      )}
                    </div>
                  ),
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div className="add-btn">
                <a
                  onClick={() => {
                    this.updateAndAdd(this.props.customEvent);
                  }}
                >
                  <span className="left addLocationButton">
                    <MaterialIcon
                      icon="add"
                      size={35}
                      color="#ffffff"
                    />
                  </span>

                  {/* <img src={addbtn} className="left addLocationButton" /> */}
                </a>
                <div>
                  {this.getSuggestionsLink(
                    this.props.customEvent,
                    this.props.packages,
                  )}
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="no-padding"
            >
              <div>{this.displayForm()}</div>
            </Col>
          </Row>
          {/* <div className="pushtwenty wizard-border-top">
          {this.getPromotionalFeed()}
        </div> */}
        </Container>
      </div>
    );
  }

  getSuggestionsLink(customEvent, packages) {
    let packageMatch = false;
    if (
      customEvent.event.locations.length !== 0 &&
      customEvent.event.locations.length < 2
    ) {
      packages.forEach((packagex) => {
        packagex.locations.forEach((location) => {
          if (
            location.locationId ===
            customEvent.event.locations[0].locationId
          ) {
            packageMatch = true;
          }
        });
      });
    } else if (customEvent.event.locations.length === 0) {
      packageMatch = true;
    }

    if (packageMatch === true) {
      return (
        <Link
          to={{
            pathname: '/packages',
          }}
          className="suggestionLink"
        >
          <span
            style={{
              padding: '5px 10px 5px 10px',
              background: 'white',
              border: '#ee0059 1px solid',
              borderRadius: '5px',
              marginTop: '10px',
              display: 'inline-block',
            }}
          >
            We Have A Suggestion!
          </span>
        </Link>
      );
    }
  }

  getHeroImage() {
    if (this.state.customHero === true) {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '100%' }}
          src={this.state.heroPath}
        />
      );
    }
  }

  getThumbnail() {
    if (this.state.customThumb === true) {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '100%' }}
          src={this.state.thumbPath}
        />
      );
    }
  }

  getImagesTab() {
    return (
      <Container id="imageUpload">
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <span className="inputLabel">Banner Image</span>
            {this.getHeroImage()}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <div>
              <input type="file" onChange={this.onSelectFile} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <span className="inputLabel">Thumbnail Image</span>
            {this.getThumbnail()}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <div>
              <input type="file" onChange={this.onSelectFileThumb} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  // Schedule Functions
  handleDayClick(day, { selected }) {
    let thisEvent = this.props.customEvent;
    // Update Schedule
    thisEvent.event.scheduledTime = this.setSchedule(
      day,
      this.state.selectedTime,
    );

    thisEvent.step = 5;

    this.props.getCe(thisEvent);
    this.setState({
      selectedDay: selected ? undefined : day,
      showTopMenu: true,
    });
    this.setMode(1);
  }

  onTimeChange = (time) => {
    this.setState({ selectedTime: time });
  };

  isDayDisabled = (day) => {
    // Remember, in this scenario - TRUE means the day is disabled. FALSE means it is not disabled.
    let locationClosed = false;
    let eventNotAvailable = false;
    let noItineraryAvailable = null;
    let locations = this.props.customEvent.event.locations;
    const today = moment().format('YYYY-MM-DD');
    const timestamp = moment(day).unix();
    const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
    let dayName = moment(day).format('dddd');
    let lowercase = dayName.toLowerCase();
    if (lowercase === 'saturday') {
      lowercase = 'saterday';
    }
    let openString = lowercase + 'Open';
    // Is this day in the past?
    let pastDate = moment(dateString).isBefore(today);
    // First we check if date is already in the past
    if (pastDate === false) {
      // check for events with multiple days and build out the multiDates Array
      if (locations.length !== 0) {
        locations.forEach((location) => {
          // If this is an event lets get to it.
          if (
            location.eventId !== undefined &&
            location.eventId !== ''
          ) {
            //This is an Event
            let multiDates = [];
            let match = false;
            let eventDetails = query('eventId')
              .is(location.eventId)
              .on(this.props.timedEvents);

            if (
              eventDetails[0].scheduledTime.dates !== undefined &&
              eventDetails[0].scheduledTime.dates.multiDates !==
                undefined
            ) {
              eventDetails[0].scheduledTime.dates.multiDates.forEach(
                (date) => {
                  multiDates.push(
                    moment(date.date).format('YYYY-MM-DD'),
                  );
                },
              );
            }
            if (multiDates.length !== 0) {
              multiDates.forEach((date) => {
                let check = moment(dateString).isSame(date);
                if (check === true) {
                  match = true;
                }
              });
            }
            if (match === false) {
              eventNotAvailable = true;
            }
          } else {
            if (
              location !== undefined &&
              location.hours !== undefined &&
              location.hours[lowercase][openString] !== undefined &&
              location.hours[lowercase][openString] === true
            ) {
              locationClosed = true;
            } else if (location.hours === undefined) {
              let locationDetails = query('locationId')
                .is(location.locationId)
                .on(this.props.locations);

              if (
                locationDetails[0] !== undefined &&
                locationDetails[0].hours !== undefined &&
                locationDetails[0].hours[lowercase][openString] !==
                  undefined &&
                locationDetails[0].hours[lowercase][openString] ===
                  true
              ) {
                locationClosed = true;
              }
            }
          }
        });
      } else {
        let check = moment(dateString).isSame(today);
        let past = moment(dateString).isBefore(today);
        if (past === false || check === true) {
          noItineraryAvailable = true;
        } else {
          noItineraryAvailable = false;
        }
      }
    }
    if (
      locationClosed === true ||
      eventNotAvailable === true ||
      pastDate === true ||
      noItineraryAvailable === false
    ) {
      return true;
    } else {
      return false;
    }
  };

  getWhen(event) {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 'tu' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];
    let days = [];

    if (event.scheduledTime.dates.repeatDays !== undefined) {
      event.scheduledTime.dates.repeatDays.forEach((day) => {
        let eventDetailsx = query('short').is(day).on(dayArray);
        let eventx = eventDetailsx[0];
        days.push(eventx.full);
      });
      let dayText = '';
      days.forEach((day, index) => {
        if (days.length === 1) {
          dayText = day;
        } else if (days.length === 7) {
          dayText = 'Day';
        } else if (days.length === 2) {
          if (index === 0) {
            dayText = day;
          } else {
            dayText = dayText + ' & ' + day;
          }
        } else if (
          days.length > 2 &&
          event.scheduledTime.dates.timesSelected > 1
        ) {
          if (index === 0) {
            dayText = day + 's';
          } else if (days.length - 1 === index) {
            dayText = dayText + ', & ' + day + 's';
          } else {
            dayText = dayText + ',  ' + day + 's';
          }
        } else if (
          days.length > 2 &&
          event.scheduledTime.dates.timesSelected === 1
        ) {
          if (index === 0) {
            dayText = day;
          } else if (days.length - 1 === index) {
            dayText = dayText + ', & ' + day;
          } else {
            dayText = dayText + ',  ' + day;
          }
        }
      });

      let weekly = '';
      if (event.scheduledTime.dates.timesSelected === 1) {
        weekly = 'week';
      } else {
        weekly = event.scheduledTime.dates.timesSelected + ' weeks ';
      }
      let statement = '';
      if (event.scheduledTime.dates.timesSelected === 1) {
        if (event.scheduledTime.dates.repeatType === 'Weekly') {
          statement = 'every ' + dayText;
        }
      } else {
        if (event.scheduledTime.dates.repeatType === 'Weekly') {
          statement = 'every ' + weekly + ' on ' + dayText;
        }
      }

      return statement;
    } else {
      let statement = '';
      event.scheduledTime.dates.multiDates.forEach((date, index) => {
        if (index === 0) {
          let currentTime = moment(date.date).format('MMMM D');
          statement = statement + currentTime + ' ';
        } else if (
          (index = event.scheduledTime.dates.multiDates.length - 1)
        ) {
          let currentTime = moment(date.date).format('D');
          statement = statement + '& ' + currentTime;
        } else {
          let currentTime = moment(date.date).format('D');
          statement = statement + ',' + currentTime;
        }
      });
      return statement;
    }
  }

  getScheduleTab() {
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
      },
    };

    let eventInfo = { eventTitle: '' };
    let multiDates = [];

    // check for events with multiple days
    if (this.props.customEvent.event.locations.length !== 0) {
      this.props.customEvent.event.locations.forEach((location) => {
        if (
          location.eventId !== undefined &&
          location.eventId !== ''
        ) {
          let eventDetails = query('eventId')
            .is(location.eventId)
            .on(this.props.timedEvents);

          eventDetails[0].scheduledTime.dates.multiDates.forEach(
            (date) => {
              multiDates.push(moment(date.date).format('YYYY-MM-DD'));
            },
          );
          eventInfo = eventDetails[0];
        }
      });
    }
    return (
      <Container>
        {multiDates.length > 1 && (
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div style={{ padding: '15px', marginTop: '10px' }}>
                <center>
                  <span>
                    <span style={{ fontWeight: '600' }}>
                      {eventInfo.eventTitle} is{' '}
                      {this.getWhen(eventInfo)}
                    </span>
                  </span>
                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginTop: '15px',
                      marginBottom: '10px',
                      fontWeight: '500',
                      fontSize: '18px',
                      color: '#ed0259',
                    }}
                  >
                    When would you like to go?
                  </span>
                </center>
              </div>
            </Col>
          </Row>
        )}

        <Row className="no-margin">
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            {this.state.calendarLock === false ? (
              <div>
                <div className="text-center">
                  {multiDates.length > 1 ? (
                    <DayPicker
                      // month={new Date(2015, 8)}
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiersStyles={modifiersStyles}
                      disabledDays={this.isDayDisabled}
                    />
                  ) : (
                    <DayPicker
                      // month={new Date(2015, 8)}
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiersStyles={modifiersStyles}
                      disabledDays={this.isDayDisabled}
                    />
                  )}

                  {/* <center>
                    <p style={{ marginTop: '20px' }}>
                      {this.state.selectedDay
                        ? this.state.selectedDay.toLocaleDateString()
                        : 'Please select a day'}
                    </p>
                  </center> */}
                </div>
                <div className="text-center">
                  <span className="inputLabel">Time</span>
                  <TimePicker
                    showSecond={false}
                    defaultValue={this.state.selectedTime}
                    className="xxx"
                    onChange={this.onTimeChange}
                    format={format}
                    use12Hours
                    inputReadOnly
                  />
                </div>
              </div>
            ) : (
              <div>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div
                      style={{ padding: '15px', marginTop: '10px' }}
                    >
                      <center>
                        <span>
                          <span style={{ fontWeight: '600' }}>
                            {eventInfo.eventTitle} is{' '}
                            {this.getWhen(eventInfo)}.
                          </span>
                        </span>
                      </center>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <center>
                    {/* <h4 style={{ marginTop: '80px' }}>
                      {this.state.event.eventTitle}
                    </h4> */}

                    <h2 style={{ marginTop: '80px' }}>
                      {this.getDate(this.state.event)}
                    </h2>
                  </center>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  confirmCopy() {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);
  }

  getGuestListTab() {
    let eventBundle = {
      connections: this.props.newEvent.connections,
      invitations: this.props.newEvent.invitedUsers,
      newEvent: true,
    };
    return (
      <div>
        <GuestListModal
          event={eventBundle}
          confirmDelete={this.confirmDelete}
          confirmCopy={this.confirmCopy}
          // closeGuestListModal={this.closeGuestListModal}
        />
      </div>
    );
  }

  getContent(mode, users, userId, description) {
    if (mode === 1) {
      // return this.getDetailsTab(users, userId, description);
      return this.getDetailsTab();
    } else if (mode === 2) {
      return this.getScheduleTab(description);
    } else {
      // return this.getImagesTab();
      return this.getGuestListTab();
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getTab(tabMode, tabLable, mode, last) {
    if (tabMode === mode) {
      if (last === true) {
        return (
          <div className="text-center noBorderRight actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      } else {
        return (
          <div className="text-center actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      }
    } else {
      if (last === true) {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="text-center actionButton noBorderRight  actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="text-center actionButton actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      }
    }
  }

  showTopMenu(mode) {
    this.setState({
      showTopMenu: mode,
    });
  }

  disableSave(customEvent) {
    let dayName = '';
    let lowercase = '';
    let disable = false;
    if (customEvent.event.scheduledTime.date !== null) {
      dayName = moment(customEvent.event.scheduledTime.date).format(
        'dddd',
      );
      lowercase = dayName.toLowerCase();
      if (lowercase === 'saturday') {
        lowercase = 'saterday';
      }
      let openString = lowercase + 'Open';
      customEvent.event.locations.forEach((location, index) => {
        if (
          location !== null &&
          location.hours !== undefined &&
          location.hours[lowercase][openString] !== undefined &&
          location.hours[lowercase][openString] === true
        ) {
          disable = true;
        }
      });
    }

    return disable;
  }

  getTitleBar(disableSave) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/home',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  {this.state.forceCalendar === false
                    ? 'Create New Experience'
                    : 'Trevel Agent'}
                </span>
              </center>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="right">
                {this.state.showTopMenu === true &&
                disableSave !== true ? (
                  <MaterialIcon
                    icon="check"
                    size={25}
                    color="white"
                    onClick={() => {
                      this.createCustomEvent(
                        this.props.profile,
                        this.props.customEvent,
                      );
                    }}
                  />
                ) : (
                  <span></span>
                )}
                {disableSave === true && (
                  <MaterialIcon
                    icon="check"
                    size={25}
                    color="#ff6fa5"
                  />
                )}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  updateAndAdd(customEvent) {
    let updatedCustomEvent = customEvent;
    updatedCustomEvent.eventId = this.state.dbref;
    updatedCustomEvent.event.title = this.state.title;
    updatedCustomEvent.event.description = this.state.description;
    let heroPath = '';
    let thumbnailPath = '';
    if (this.state.customHero === true) {
      heroPath =
        'event_images%2F' + this.state.dbref + '%2FheroImage.jpeg';
      updatedCustomEvent.event.eventSettings.customHero = true;
    } else {
      heroPath = updatedCustomEvent.event.heroImage;
      updatedCustomEvent.event.eventSettings.customHero = false;
    }
    if (this.state.customThumb === true) {
      thumbnailPath =
        'event_images%2F' + this.state.dbref + '%2Fthumbnail.jpeg';
      updatedCustomEvent.event.eventSettings.customThumb = true;
    } else {
      thumbnailPath = updatedCustomEvent.event.thumbnail;
      updatedCustomEvent.event.eventSettings.customThumb = false;
    }
    updatedCustomEvent.event.heroImage = heroPath;
    updatedCustomEvent.event.thumbnail = thumbnailPath;
    updatedCustomEvent.event.locations = customEvent.event.locations;

    // Update Schedule

    // updatedCustomEvent.event.scheduledTime = this.setSchedule(
    //   this.state.selectedDay,
    //   this.state.selectedTime,
    // );

    let customEventNew = {
      customEvent: updatedCustomEvent,
    };
    this.props.updateCe(customEventNew);
  }

  createCustomEvent(profile, customEvent) {
    // Create Final Event Record
    const customEventUpdate = customEvent;
    const thisEvent = customEvent.event;

    // Check if from Vendor Created Event
    if (
      customEvent.fromVEvent !== undefined &&
      customEvent.fromVEvent === true
    ) {
      customEventUpdate.fromVEvent = true;
      customEventUpdate.vEvent = customEvent.vEvent;
    }
    // Set ID
    customEventUpdate.eventId = this.state.dbref;

    // Update Title and Description
    thisEvent.title = this.state.title;
    thisEvent.description = this.state.description;

    // Update Hero and Thumbnail
    let heroPath = '';
    let thumbnailPath = '';

    if (this.state.customHero === true) {
      heroPath =
        'event_images%2F' + this.state.dbref + '%2FheroImage.jpeg';
      thisEvent.eventSettings.customHero = true;
    } else {
      heroPath = thisEvent.heroImage;
      thisEvent.eventSettings.customHero = false;
    }

    if (this.state.customThumb === true) {
      thumbnailPath =
        'event_images%2F' + this.state.dbref + '%2Fthumbnail.jpeg';
      thisEvent.eventSettings.customThumb = true;
    } else {
      thumbnailPath = thisEvent.thumbnail;
      thisEvent.eventSettings.customThumb = false;
    }
    thisEvent.heroImage = heroPath;
    thisEvent.thumbnail = thumbnailPath;

    // Update Schedule
    thisEvent.scheduledTime = this.setSchedule(
      this.state.selectedDay,
      this.state.selectedTime,
    );
    //Set Step
    customEventUpdate.event = thisEvent;
    customEventUpdate.step = 2;
    customEventUpdate.editMode = { editMode: false };

    // Update Custom Event
    this.props.getCe(customEventUpdate);
  }

  setSchedule(selectedDay, selectedTime) {
    let bundle = {
      date: '',
      time: '',
      timeString: '',
      timestamp: '',
    };
    // Check if Time Set
    if (
      selectedTime !== '' &&
      selectedTime !== undefined &&
      selectedTime !== null
    ) {
      const datestring = String(selectedTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.time = timeValue;
      bundle.timeString = selectedTime._d;
    }
    // Check for Date
    if (
      selectedDay !== '' &&
      selectedDay !== undefined &&
      selectedDay !== null
    ) {
      const timestamp = moment(selectedDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.date = dateString;
      bundle.timestamp = timestampReady;
    }
    return bundle;
  }

  getFooter(mode) {
    return (
      <div id="startBar">
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <center>
                <a
                  onClick={() => {
                    this.showIdeas();
                  }}
                >
                  <span className="center solidredbutton">
                    Trevel Agent{' '}
                    <MaterialIcon
                      icon="keyboard_arrow_up"
                      size={25}
                      color="white"
                    />
                  </span>
                </a>
              </center>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  showIdeas() {
    if (this.props.customEvent.event.locations.length === 0) {
      this.setState({
        ideas: true,
      });
    }
  }

  getPromotionalFeed() {
    if (this.props.customEvent.event.locations.length === 0) {
      return <PromotionFeedMulti />;
    }
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    if (
      (this.state.trevelAgent === true &&
        this.props.customEvent.initialMoodSet !== true &&
        this.props.customEvent.editMode !== true) ||
      (this.state.showTrevelAgent === true &&
        this.props.customEvent.editMode !== true)
    ) {
      return (
        <TrevelAgent
          setTagFilters={this.setTagFilters}
          setTrevelAgentState={this.setTrevelAgentState}
          otherUserTagsLove={this.state.otherUserTagsLove}
          otherUser={this.state.otherUser}
          tags={this.props.tags}
          setSelectedDate={this.setSelectedDate}
          showTopMenu={this.showTopMenu}
        />
      );
    }

    let disableSave = this.disableSave(this.props.customEvent);

    if (this.state.ideas === true) {
      return (
        <div id="editingOverlay">
          <div className="section">
            <Link
              to={{
                pathname: '/events/upcoming',
              }}
              className="left"
            >
              <MaterialIcon
                icon="keyboard_backspace"
                size={25}
                color="white"
              />
            </Link>
          </div>
          <div className="section text-center">
            <h4 className="white">
              Are you looking for a single location or feeling
              adventurous?
            </h4>
            <Link
              to={{
                pathname: '/event/create/add/',
              }}
              className=""
            >
              <span className="whitebutton">Single Location</span>
            </Link>
            <Link
              to={{
                pathname: '/packages',
              }}
              className=""
            >
              <span className="whitebutton">Multiple Locations</span>
            </Link>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#startBar{background-color: #ef0059;} #events{position: relative; top: 40px} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 40px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    return (
      <div id="home">
        <div
          id="snackbar"
          style={{
            visibility:
              this.state.copied === true ? 'visible' : 'hidden',
            opacity: this.state.copied === true ? '1' : '0',
          }}
        >
          Invite Link Copied!
        </div>

        <div>{this.getBannerCropper(this.state.cropping)}</div>
        <div>
          {this.getThumbnailCropper(this.state.croppingThumb)}
        </div>
        {this.getTitleBar(disableSave)}
        {/* <div className="secondary-title-bar-fixed">
          <div>
            <Container>
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding text-center"
                >
                  <span>Create Your Own Event</span>
                </Col>
              </Row>
            </Container>
          </div>
        </div> */}
        <div id="headersTopSecondary">
          {this.state.showTopMenu === true ? (
            <Row>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(3, 'Who', this.state.mode, true)}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(1, 'What', this.state.mode, false)}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(2, 'When', this.state.mode, false)}
              </Col>
            </Row>
          ) : (
            <Row></Row>
          )}
        </div>
        <div id="events">
          {this.getContent(
            this.state.mode,
            this.props.customEvent.event.users,
            this.props.profile.userId,
            this.state.description,
          )}
        </div>

        {/* {this.getFooter()} */}
        {/* <div className="footer">{this.getFooter()}</div> */}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#headersTop {width: 100% !important;} #headersTopSecondary{width: 100%} #authright {padding-left: 0px !important;} #authleft {display:none;} #events{position: relative; top: 40px} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 40px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customEvent: state.customEvent.customEvent,
    locations: state.locations.locations,
    profile: state.profile.profile,
    newEvent: state.newEvent,
    users: state.users.users,
    tags: state.tags.tags,
    packages: state.packages.packages,
    user: state.user,
    demo: state.demo,
    timedEvents: state.timedEvents.events,
    taRequest: state.taRequest,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
    updateCe: (customEvent) => {
      dispatch(updateCe(customEvent));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    clearTaRequest: (user) => {
      dispatch(clearTaRequest(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomPackageHome);
