import firebase from '../config/firebase';
import { inviteAccept } from '../sagas/invitationSaga/reducer';

// Get Users Events
export function getUserEvents(userId) {
  const eventsArray = [];
  const db = firebase.firestore();

  const getV = function () {
    return new Promise((resolve, reject) => {
      // const table = `usersAttending.${userId}.attending`;
      const table = `users.${userId}.access`;
      db.collection('user_events')
        .where(table, '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                creationDetails,
                eventSettings,
                packageDetails,
                status,
                title,
                description,
                scheduledTime,
                heroImage,
                thumbnail,
                editHistory,
                users,
                invitations,
                editing,
              } = doc.data();
              eventsArray.push({
                eventId: doc.id,
                creationDetails,
                eventSettings,
                packageDetails,
                status,
                title,
                description,
                scheduledTime,
                heroImage,
                thumbnail,
                editHistory,
                users,
                invitations,
                editing,
              });
            }
          });
          return eventsArray;
        })
        .then((result) => {
          resolve(eventsArray);
        });
    });
  };
  return getV();
}

// Get Users Events
export function getAllUserEvents() {
  const eventsArray = [];
  const db = firebase.firestore();
  const user_events = db.collection('user_events');

  const getV = function () {
    return new Promise((resolve, reject) => {
      const query = user_events
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                creationDetails,
                eventSettings,
                packageDetails,
                status,
                title,
                description,
                scheduledTime,
                heroImage,
                thumbnail,
                editHistory,
                users,
                editing,
              } = doc.data();
              eventsArray.push({
                eventId: doc.id,
                creationDetails,
                eventSettings,
                packageDetails,
                status,
                title,
                description,
                scheduledTime,
                heroImage,
                thumbnail,
                editHistory,
                users,
                editing,
              });
            }
          });
          return eventsArray;
        })
        .then((result) => {
          resolve(eventsArray);
        });
    });
  };
  return getV();
}

// Edit Event Package
export function updateEventPackage(bundle) {
  const db = firebase.firestore();
  const userEvent = db
    .collection('user_events')
    .doc(bundle.event.eventId);
  const packageDetails = bundle.event.packageDetails;
  let title = '';
  if (bundle.event.title !== null) {
    title = bundle.event.title;
  }
  let description = '';
  if (bundle.event.description !== '') {
    description = bundle.event.description;
  }
  const users = bundle.event.users;
  const editHistory = bundle.event.editHistory;
  const scheduledTime = bundle.event.scheduledTime;
  const heroImage = bundle.event.heroImage;
  const thumbnail = bundle.event.thumbnail;
  const eventSettings = bundle.event.eventSettings;
  const editing = bundle.event.editing;
  const invitations = bundle.event.invitations;
  const status = bundle.event.status;

  const setDoc = userEvent.update({
    scheduledTime,
    packageDetails,
    title,
    description,
    users,
    editHistory,
    heroImage,
    thumbnail,
    eventSettings,
    editing,
    invitations,
    status,
  });
}

// Save Event
export function saveEvent(event, eventId) {
  const db = firebase.firestore();

  const getP = function () {
    if (eventId !== undefined) {
      const id = eventId;
      return new Promise((resolve, reject) => {
        const setDoc = db
          .collection('user_events')
          .doc(id)
          .set(event)
          .then((ref) => {
            // console.log("Added document with ID: ", ref);
            return ref;
          })
          .then((result) => {
            resolve(result);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        const setDoc = db
          .collection('user_events')
          .add(event)
          .then((ref) => {
            // console.log("Added document with ID: ", ref);
            return ref;
          })
          .then((result) => {
            resolve(result);
          });
      });
    }
  };
  return getP();
}

// Finish Event
export function finishEvent(event) {
  const db = firebase.firestore();
  const getP = function () {
    const id = event.eventId;
    return new Promise((resolve, reject) => {
      const setDoc = db
        .collection('user_events')
        .doc(id)
        .set(event)
        .then((ref) => {
          console.log('Added document with ID: ', ref);
          return ref;
        })
        .then((result) => {
          resolve(result);
        });
    });
  };
  return getP();
}

// Save Event With ID
export function saveColabEvent(event) {
  const getP = function () {
    // return new Promise((resolve, reject) => {
    //   const db = firebase.firestore();
    //   db.collection("user_events")
    //   .doc(event.eventId)
    //   .set(event)
    //     .then(() => {
    //       console.log("returned event");
    //       resolve();
    //     });
    // });
  };
  return getP();
}

// Schedule Event
export function setSchedule(event) {
  const db = firebase.firestore();
  const userEvent = db
    .collection('user_events')
    .doc(event.payload.eventId);
  const scheduledTime = {
    date: event.payload.date,
    time: event.payload.time,
    timestamp: event.payload.timestamp,
    timeString: event.payload.timeString,
  };

  const setDoc = userEvent.update({
    scheduledTime,
  });
}

// Approve Event
export function approveEvent(bundle) {
  const db = firebase.firestore();
  const userEvent = db
    .collection('user_events')
    .doc(bundle.event.eventId);

  let usersObject = bundle.event.users;
  let status = 2;

  let maybes = 0;
  let approved = 0;
  let rejected = 0;
  let plannerCount = Object.keys(usersObject).length;

  Object.keys(usersObject).forEach((key) => {
    if (usersObject[key].approved === true) {
      approved = approved + 1;
    } else if (
      usersObject[key].rejected !== undefined &&
      usersObject[key].rejected === true
    ) {
      approved = rejected + 1;
    } else {
      maybes = maybes + 1;
    }
  });

  if (approved + rejected === plannerCount && approved > 1) {
    // console.log('status - YEP');
    status = 0;
  }
  // console.log('status - plannerCount', plannerCount);
  // console.log('status - approved', approved);
  // console.log('status - rejected', rejected);
  // console.log('status - maybes', maybes);
  // console.log('status', status);

  const setDoc = userEvent.update({
    status: status,
    users: bundle.event.users,
    editHistory: bundle.event.editHistory,
  });
}

// Reject Event
export function rejectEvent(bundle) {
  const db = firebase.firestore();
  const userEvent = db
    .collection('user_events')
    .doc(bundle.event.eventId);

  const setDoc = userEvent.update({
    status: 3,
    users: bundle.event.users,
    editHistory: bundle.event.editHistory,
  });
}

// Enter Edit Mode
export function editMode(bundle) {
  const db = firebase.firestore();
  const userEvent = db
    .collection('user_events')
    .doc(bundle.event.eventId);

  const setDoc = userEvent.update({
    editing: bundle.editing,
  });
}
