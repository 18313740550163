import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../../config/firebase';
// Reducers
import { setVendorLogin } from '../../sagas/loginSaga/reducer';
import { getVendor } from '../../sagas/vendorSaga/reducer';
import { getVendorPromos } from '../../sagas/vendorPromoSaga/reducer';
import { getVendorAwarded } from '../../sagas/vendorAwardedSaga/reducer';
import { getVendorRewards } from '../../sagas/vendorRewardsSaga/reducer';
import { getVendorUserEvents } from '../../sagas/vendorUserEventsSaga/reducer';
// import { getVendorEvents } from '../../sagas/vendorEventsSaga/reducer';
import { getVendorTimedEvents } from '../../sagas/vendorTimedEventsSaga/reducer';
import { getVendorEntertainment } from '../../sagas/vendorEntertainmentSaga/reducer';
import { getProfile } from '../../sagas/getProfileSaga/reducer';

const query = require('array-query');

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  step: 1,
  loading: true,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class VendorProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    // const locationId = this.props.locationId;
    // const locationDetails = query("locationId")
    //   .is(locationId)
    //   .on(this.props.locations);
    // console.log("locationDetails[0]", this.props.locationId);
    // console.log("locationDetails[0]", locationDetails[0]);
    // this.setState({
    // locationId,
    // locationDetails: this.props.locations,
    // });
    if (this.props.user.authenticated === true) {
      if (this.props.unauth.claimLink === true) {
        if (this.props.profile.userId !== undefined) {
          if (this.props.unauth.vendorId !== null) {
            this.updateVendorAccount(this.props.unauth.vendorId);
          } else if (this.props.profile.vendorAccount !== null) {
            this.updateVendorAccount(
              this.props.profile.vendorAccount.id,
            );
          } else if (this.props.unauth.vendorId === null) {
            this.setState({
              step: 2,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
        } else {
          this.setState({
            loading: false,
          });
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  }

  // this.createVendorAccount(
  //   this.props.user,
  //   dbref,
  //   businessName,
  //   businessCity,
  //   businessAddress,
  //   businessZip,
  //   state,
  //   phone,
  // );

  createVendorAccount(
    user,
    dbref,
    businessName,
    businessCity,
    businessAddress,
    businessZip,
    businessState,
    phone,
  ) {
    const db = firebase.firestore();
    const timestamp = new Date();
    db.collection('vendors')
      .doc(dbref)
      .set({
        businessName,
        primaryAddress: {
          businessCity,
          businessAddress,
          businessZip,
          businessState,
        },
        primaryContact: {
          phone: phone,
        },
        created: timestamp,
        locations: [],
        entertainment: [],
        users: {
          [user.id]: {
            active: true,
            joinDate: timestamp,
            role: 0,
          },
        },
        subscription: {
          active: false,
        },
        vendorId: dbref,
        vendorTypes: [this.props.vendorType],
        verified: false,
      })
      .then(() => {
        this.updateUserAccount(this.props.profile.userId, dbref);
      });
  }

  updateUserAccount(id, dbref) {
    const timestamp = new Date();
    const db = firebase.firestore();
    const update = db.collection('users').doc(id);
    const setDoc = update
      .update({
        userType: 3,
        vendorAccount: {
          id: dbref,
          joinDate: timestamp,
          vendorType: this.props.vendorType,
        },
      })
      .then((ref) => {
        this.props.getProfile(id);
      });
  }

  checkUserName(userName) {
    if (this.state.usersArray.indexOf(userName) + 1) {
      return true;
    }
  }

  setStep(firstName, lastName, userName, state) {
    this.setState({
      firstName,
      lastName,
      userName,
      state,
      step: 2,
    });
  }

  getForm(step) {
    const db = firebase.firestore();
    const newEventRef = db.collection('vendors').doc();
    const dbref = newEventRef.id;
    return (
      <Formik
        initialValues={{
          businessName: '',
          phone: '',
        }}
        validate={(values) => {
          let errors = {};
          if (!values.businessName) {
            errors.businessName = 'Required';
          }
          if (values.phone === '') {
            errors.phone = 'Required';
          }

          return errors;
        }}
        onSubmit={(values) => {
          this.createVendorAccount(
            this.props.user,
            dbref,
            values.businessName,
            values.businessCity,
            values.businessAddress,
            values.businessZip,
            this.props.profile.state,
            values.phone,
          );
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmittingg,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          } = props;
          return (
            <Form>
              <Container>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="left"
                  >
                    <span className="inputLabel">
                      Organization / Business Name
                    </span>
                    <Field
                      type="text"
                      name="businessName"
                      placeholder="Organization / Business Name"
                    />
                    <ErrorMessage
                      name="businessName"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="left"
                  >
                    <span className="inputLabel">
                      Primary Contact Information
                    </span>
                    <Field
                      type="text"
                      name="phone"
                      placeholder="Phone Number"
                    />
                    <ErrorMessage
                      name="phones"
                      component="div"
                      className="error-message"
                    />
                    <span className="inputLabel">
                      Primary Address
                    </span>
                    <Field
                      type="text"
                      name="businessAddress"
                      placeholder="Street"
                    />
                    <ErrorMessage
                      name="businessAddress"
                      component="div"
                      className="error-message"
                    />
                    <Field
                      type="text"
                      name="businessCity"
                      placeholder="City"
                    />
                    <ErrorMessage
                      name="businessCity"
                      component="div"
                      className="error-message"
                    />
                    <Field
                      type="text"
                      name="businessState"
                      placeholder="State"
                    />
                    <ErrorMessage
                      name="businessState"
                      component="div"
                      className="error-message"
                    />
                    <Field
                      type="text"
                      name="businessZip"
                      placeholder="Zip"
                    />
                    <ErrorMessage
                      name="businessZip"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} sm={12} lg={12}>
                    <div className="submitarea">
                      <center>
                        <button
                          type="submit"
                          disabled={isSubmittingg}
                          className="redbutton center"
                        >
                          Next
                        </button>
                      </center>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    );
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div id="signup">
        <div>
          <div className="title-area">
            {/* <h3>Create a New Account</h3> */}
            <p className="signup-box-info">
              {/* <span>Or </span>
              <a href="/login">login to your account</a>
              <span>.</span> */}
            </p>
          </div>
          {this.getForm(this.state.step)}
          <style
            dangerouslySetInnerHTML={{
              __html:
                'header { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer-container{display:none} .full-height{height:100%}',
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.invite.event,
    invite: state.invite,
    locations: state.locations.locations,
    users: state.users.users,
    unauth: state.unauth,
    profile: state.profile.profile,
    vendor: state.vendor.vendor,
    timedEvents: state.timedEvents.events,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setVendorLogin: (user) => {
      dispatch(setVendorLogin(user));
    },
    getVendor: (id) => {
      dispatch(getVendor(id));
    },
    getProfile: (id) => {
      dispatch(getProfile(id));
    },

    getVendorEntertainment: (id) => {
      dispatch(getVendorEntertainment(id));
    },

    getVendorUserEvents: (id) => {
      dispatch(getVendorUserEvents(id));
    },

    getVendorRewards: (id) => {
      dispatch(getVendorRewards(id));
    },

    getVendorTimedEvents: (id) => {
      dispatch(getVendorTimedEvents(id));
    },

    getVendorAwarded: (id) => {
      dispatch(getVendorAwarded(id));
    },

    getVendorPromos: (id) => {
      dispatch(getVendorPromos(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorProfileForm);
