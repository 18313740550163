import * as action from './actions';

export const getDemos = (zoneId) => {
  return {
    type: action.GET_DEMOS,
    payload: {},
    zoneId: zoneId,
  };
};

const ACTION_HANDLERS = {
  [action.GET_DEMOS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SET_DEMOS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  demoLocations: [],
  demoPromos: [],
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
