import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { push } from 'connected-react-router';
import { savePackage } from '../../services/PackagesServices';
import {
  saveEvent,
  updateEventPackage,
  approveEvent,
  rejectEvent,
  editMode,
} from '../../services/UserEventServices';
import { sendInvitation } from '../../services/InvitationServices';
import { saveVouchers } from '../../services/SavePurchase';
import { init as getPackages } from '../packagesSaga/reducer';
import { getUserVouchers } from '../userVoucherSaga/reducer';
import { getEvents } from '../userEventsSaga/reducer';
import { getAllLocations } from '../allLocationsSaga/reducer';
import { resetConnectionsTagsAndFavs } from '../connectionsTagsAndFavsSaga/reducer';
import { resetEvent } from '../activeEventSaga/reducer';

function* saveCustomEventAction(action) {
  yield call(saveEvent, action.payload.event, action.payload.eventId);
  yield put(
    getEvents(action.payload.event.creationDetails.plannerId),
  );
  // yield call(saveVouchers, action.payload.userVouchers);
  // yield put(getUserVouchers(action.payload.profile.userId));
  // Send Invitation if there is one
  // if (action.payload.invitation !== null) {
  //   yield call(sendInvitation, action.payload.invitation);
  // }

  // yield put(getAllLocations(action.payload.profile.zoneId));

  yield put({
    type: actions.SET_CE,
    payload: {
      customEvent: {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        event: {
          description: null,
          title: null,
          status: 0,
          locations: [],
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: false,
            checkMonth: false,
          },
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
            editable: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      },
      event: {},
      package: {},
      profile: {},
      userVouchers: [],
      eventId: null,
    },
  });

  let users = action.payload.event.users;
  let url = '';
  let newUserId = null;
  Object.keys(users).forEach((key) => {
    if (
      users[key].newUser !== undefined &&
      users[key].newUser === true
    ) {
      newUserId = key;
    }
  });
  if (newUserId === null) {
    url = '/event/' + action.payload.eventId;
  } else {
    url = '/invitelink/' + newUserId + '/' + action.payload.eventId;
  }

  yield put(push(url));
}

function* saveCustomEventAndPackageAction(action) {
  // yield call(savePackage, action.payload.package);
  yield call(saveEvent, action.payload.event, action.payload.eventId);
  // yield put(getPackages(action.payload.profile.zoneId));
  yield put(
    getEvents(action.payload.event.creationDetails.plannerId),
  );
  // yield call(saveVouchers, action.payload.userVouchers);
  // yield put(getUserVouchers(action.payload.profile.userId));
  // yield put(getAllLocations(action.payload.profile.zoneId));
  // Send Invitation if there is one
  // if (action.payload.invitation !== null) {
  //   yield call(sendInvitation, action.payload.invitation);
  // }
  yield put({
    type: actions.SET_CE,
    payload: {
      customEvent: {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        event: {
          description: null,
          title: null,
          status: 0,
          locations: [],
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: false,
            checkMonth: false,
          },
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
            editable: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      },
    },
  });
  let users = action.payload.event.users;
  let url = '';
  let newUserId = null;
  Object.keys(users).forEach((key) => {
    if (
      users[key].newUser !== undefined &&
      users[key].newUser === true
    ) {
      newUserId = key;
    }
  });
  if (newUserId === null) {
    url = '/event/' + action.payload.eventId;
  } else {
    url = '/invitelink/' + newUserId + '/' + action.payload.eventId;
  }

  yield put(push(url));
}

function* setInitialDate(action) {
  const customEvent = action.payload;
  yield put({
    type: actions.SET_CE,
    payload: {
      customEvent,
    },
  });
}

function* setCustomEventAction(action) {
  const customEvent = action.payload;
  yield put({
    type: actions.SET_CE,
    payload: {
      customEvent,
    },
  });

  if (customEvent.vendorMode !== undefined) {
    if (
      customEvent.editMode === true &&
      customEvent.vendorMode === false
    ) {
      const url =
        '/event/edit/' +
        customEvent.event.eventId +
        '/' +
        customEvent.urlMode;
      yield put(push(url));
    } else if (
      customEvent.editMode !== undefined &&
      customEvent.editMode === true &&
      customEvent.vendorMode === true
    ) {
      const url =
        '/vendor/event/edit/' +
        customEvent.event.eventId +
        '/' +
        customEvent.urlMode;
      yield put(push(url));
    } else {
      if (customEvent.step === 2) {
        yield put(push('/checkout/customsetup/'));
      } else {
        yield put(push('/event/create/home/1'));
      }
    }
  } else {
    if (customEvent.editMode === true) {
      const url =
        '/event/edit/' +
        customEvent.event.eventId +
        '/' +
        customEvent.urlMode;
      yield put(push(url));
    } else {
      if (customEvent.step === 2) {
        yield put(push('/checkout/customsetup/'));
      } else if (customEvent.step === 4) {
        yield put(push('/event/create/add/'));
      } else if (customEvent.step === 6) {
        yield put(push('/event/create/home/1'));
      } else if (customEvent.step === 5) {
      } else {
        if (customEvent.activeEventCats !== undefined) {
          if (
            customEvent.activeEventCats.do === true ||
            customEvent.activeEventCats.watch === true ||
            customEvent.activeEventCats.eat === true
          ) {
            yield put(push('/event/create/add'));
          } else {
            yield put(push('/event/create/home/1'));
          }
        } else {
          yield put(push('/event/create/home/1'));
        }
      }
    }
  }
}

function* updateCustomEventAction(action) {
  const customEvent = action.payload;
  // yield put({
  //   type: actions.SET_CE,
  //   payload: {
  //     customEvent
  //   }
  // });
  yield put(push('/event/create/add/'));
}

function* editCustomEventAndPackageAction(action) {
  // yield call(savePackage, action.payload.package);
  yield call(updateEventPackage, action.payload);
  // yield put(getPackages(action.payload.profile.zoneId));
  // GOOD WAY TO TEST
  // yield put(getEvents(action.payload.event.creationDetails.plannerId));
  yield put({
    type: actions.SET_CE,
    payload: {
      customEvent: {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        event: {
          description: null,
          title: null,
          status: 0,
          locations: [],
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: false,
            checkMonth: false,
          },
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
            editable: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      },
    },
  });
  const url = '/event/' + action.payload.event.eventId;
  yield put(push(url));
  // yield put(push("/events/upcoming/"));
}

// Approve Event
function* approveEventAction(action) {
  const url = '/event/' + action.payload.event.eventId;
  yield call(approveEvent, action.payload);
  // yield put(getPackages());
  // yield put(getEvents(action.payload.event.creationDetails.plannerId));
  // yield put({
  //   type: actions.SET_CE,
  //   payload: {
  //     ...defaultState,
  //     defaultState
  //   }
  // });
  yield put(push(url));
}

// Reject Event
function* rejectEventAction(action) {
  yield call(rejectEvent, action.payload);
  // yield put(getPackages());
  // yield put(getEvents(action.payload.event.creationDetails.plannerId));
  // yield put({
  //   type: actions.SET_CE,
  //   payload: {
  //     ...defaultState,
  //     defaultState
  //   }
  // });
  yield put(push('/events/upcoming/'));
}

// Enter Edit Mode
function* editModeAction(action) {
  yield call(editMode, action.payload);
  // yield put(getPackages());
  // yield put(getEvents(action.payload.event.creationDetails.plannerId));
  // yield put({
  //   type: actions.SET_CE,
  //   payload: {
  //     ...defaultState,
  //     defaultState
  //   }
  // });
  let url = '';
  if (action.payload.editing.editing === true) {
    url = '/event/edit/' + action.payload.event.eventId + '/1';
  } else url = '/event/' + action.payload.event.eventId;

  yield put(push(url));
}

function* editCustomEventAction(action) {
  yield call(updateEventPackage, action.payload);
  // yield put(
  //   getEvents(action.payload.event.creationDetails.plannerId),
  // );
  yield put({
    type: actions.SET_CE,
    payload: {
      customEvent: {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        event: {
          description: null,
          title: null,
          status: 0,
          locations: [],
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: false,
            checkMonth: false,
          },
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
            editable: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      },
    },
  });
  const url = '/event/' + action.payload.event.eventId;
  yield put(push(url));
}

function* resetCustomEventAction(action) {
  yield put({
    type: actions.SET_CE,
    payload: {
      customEvent: {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        event: {
          description: null,
          title: null,
          status: 0,
          locations: [],
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: false,
            checkMonth: false,
          },
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
            editable: false,
          },
          packageDetails: {
            packageId: null,
          },
          thumbnail: null,
          heroImage: null,
        },
      },
      event: {},
      package: {},
      profile: {},
      userVouchers: [],
      eventId: null,
    },
  });

  yield put(resetEvent());
  yield put(resetConnectionsTagsAndFavs());
  if (action.payload === true) {
    yield put(push('/event/create/home/1'));
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_CE, setCustomEventAction);
  yield takeLatest(actions.SAVE_CE, saveCustomEventAction);
  yield takeLatest(actions.SAVE_CEP, saveCustomEventAndPackageAction);
  yield takeLatest(actions.EDIT_CE, editCustomEventAction);
  yield takeLatest(actions.EDIT_CEP, editCustomEventAndPackageAction);
  yield takeLatest(actions.UPDATE_CE, updateCustomEventAction);
  yield takeLatest(actions.RESET_CE, resetCustomEventAction);
  yield takeLatest(actions.APPROVE_EVENT, approveEventAction);
  yield takeLatest(actions.REJECT_EVENT, rejectEventAction);
  yield takeLatest(actions.EDIT_MODE, editModeAction);
  yield takeLatest(actions.SET_DATE, setInitialDate);
}
