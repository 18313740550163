import { call, put, takeLatest } from 'redux-saga/effects';
import { getRegions } from '../../services/RegionServices';
import { getAllRegions } from '../../services/RegionServices';
import * as actions from './actions';

let defaultState = {
  regions: {},
};

function* getRegionsAction(action) {
  let zoneId = action.payload;
  let regions = [];
  if (zoneId !== undefined) {
    regions = yield call(getRegions, zoneId);
  }
  yield put({
    type: actions.SET_REGIONS,
    payload: {
      regions,
    },
  });
}

function* getAllRegionsAction(action) {
  // let zoneId = action.payload.zoneId;
  let regions = yield call(getAllRegions);
  yield put({
    type: actions.SET_REGIONS,
    payload: {
      regions,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_REGIONS, getRegionsAction);
  yield takeLatest(actions.GET_ALLREGIONS, getAllRegionsAction);
}
