import * as action from './actions';

export const getLocations = (zoneId) => {
  return {
    type: action.GET_LOCATIONS,
    payload: {},
    zoneId: zoneId,
  };
};

export const getLocation = (locationId) => {
  return {
    type: action.GET_LOCATION,
    payload: {},
    locationId: locationId,
  };
};

export const updateLocations = (bundle) => {
  return {
    type: action.UPDATE_LOCATIONS,
    payload: {},
    bundle: bundle,
  };
};

export const setLocations = (location) => {
  return {
    type: action.SET_LOCATIONS,
    payload: location,
  };
};

const ACTION_HANDLERS = {
  [action.GET_LOCATIONS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_LOCATION]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.UPDATE_LOCATIONS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SET_LOCATIONS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = { locations: [] };

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
