import { ErrorMessage } from 'formik';
import MaterialIcon from 'material-icons-react';
import React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import TitleBar from '../../components/TitleBar';
import firebase from '../../config/firebase';
import addbtn from '../../images/addbtn.png';
// Reducers
import { getVendorRewards } from '../../sagas/vendorRewardsSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

export class VendorCreateReward extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      title: '',
      details: '',
      restrictions: '',
    };
  }
  componentDidMount() {}

  displayForm() {
    const uploadUrl = 'test/' + this.props.profile.vendorAccount.id;
    return (
      <div id="eventDetailsForm">
        <div>
          <div>
            <Container>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12} className="left">
                  <span className="inputLabel">Title</span>
                  <input
                    type="text"
                    onChange={this.titleOnChange.bind(this)}
                    value={this.state.title}
                    className="full-width"
                  />
                  <span className="inputLabel">Details</span>
                  <textarea
                    type="text"
                    onChange={this.detailsOnChange.bind(this)}
                    value={this.state.details}
                    className="full-width"
                  />
                  <span className="inputLabel">Restrictions</span>
                  <textarea
                    type="text"
                    onChange={this.restrictionsOnChange.bind(this)}
                    value={this.state.restrictions}
                    className="full-width"
                  />
                </Col>
              </Row>
            </Container>
            <Container id="imageUpload"></Container>
          </div>
        </div>
      </div>
    );
  }

  createReward(profile) {
    const db = firebase.firestore();
    const newEventRef = db.collection('vendor_rewards').doc();
    const dbref = newEventRef.id;
    const timestamp = new Date();
    let newReward = {
      title: this.state.title,
      id: dbref,
      details: this.state.details,
      // heroImage: packageBundle.package.heroImage,
      // thumbnail: packageBundle.package.thumbnail,
      restrictions: this.state.restrictions,
      vendorId: profile.vendorAccount.id,
      creatorInfo: {
        creatorId: profile.userId,
        created: timestamp,
      },
    };
    console.log('newReward', newReward);
    const setDoc = db
      .collection('vendor_rewards')
      .add(newReward)
      .then((ref) => {
        console.log('Added document with ID: ', ref);
        this.props.getVendorRewards(this.props.vendor.vendorId);
        this.props.history.push('/vendor/rewards');
      });
  }

  titleOnChange(e) {
    this.setState({ title: e.target.value });
  }

  detailsOnChange(e) {
    this.setState({ details: e.target.value });
  }

  restrictionsOnChange(e) {
    this.setState({ restrictions: e.target.value });
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/rewards',
                }}
              >
                <MaterialIcon
                  // icon="keyboard_backspace"
                  icon="close"
                  size={25}
                  color="white"
                  // onClick={() => {
                  //   this.resetPackage();
                  // }}
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Create New Reward</span>
              </center>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="right">
                <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createReward(this.props.profile);
                  }}
                />
              </span>
            </Col>
          </Row>
        </div>
        {/* <div id="headersTopSecondaryEightyWidthless">
          <Row>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getTab(1, "Details", this.state.mode, false)}
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getTab(2, "Rewards", this.state.mode, false)}
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getTab(3, "Images", this.state.mode, false)}
            </Col>
            {/* <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getTab(3, "Images", this.state.mode, true)}
            </Col> */}
        {/* </Row>
        </div> */}
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div>
        {this.getTitleBar()}
        <div id="events" className="section bottom-push grey-back">
          {/* <div className="header-container">
            <div className="header text-center">
              <h1>Edit Your Event</h1>
            </div>
          </div> */}
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              {this.displayForm()}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}></Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customEvent: state.customEvent.customEvent,
    locations: state.locations.locations,
    events: state.events.events,
    packages: state.packages.packages,
    profile: state.profile.profile,
    packageBundle: state.newPackage,
    vendor: state.vendor.vendor,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorRewards: (awards) => {
      dispatch(getVendorRewards(awards));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorCreateReward);
