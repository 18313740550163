import { put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";

let defaultState = 1;

function* setModeAction(action) {
  const mode = action.payload;
  yield put({
    type: actions.SET_MODE,
    payload: {
      ...defaultState,
      mode,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_MODE, setModeAction);
}
