import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import firebase from '../config/firebase';
import {
  UncontrolledCollapse,
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
// Components
import PackageItinerary from '../components/PackageItinerary';
// Reducers
import { resetCe } from '../sagas/customEventSaga/reducer';
import {
  getNewEvent,
  resetNewEvent,
} from '../sagas/createEventSaga/reducer';
import { ContinuousColorLegend } from 'react-vis';

const query = require('array-query');

export class VEvent extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      levelOne: '',
      levelTwo: '',
      levelThree: '',
      levelFour: '',
      levelFive: '',
      levelSix: '',
      levelOneCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelTwoCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelThreeCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelFourCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
      levelFiveCriteria: [
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
        {
          text: '',
          guidelines: '',
          numTimes: 1,
          type: 1,
          locations: [],
          oncePerDay: true,
        },
      ],
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      // this.props.history.push('/login');
    }
    if (this.props.newEvent.clear === false) {
      this.props.resetNewEvent();
      this.props.resetCe();
    }
  }

  componentDidMount() {
    let eventDetails = [];
    let locationsArray = [];
    let promo = [];

    if (this.props.user.authenticated === true) {
      eventDetails = query('id')
        .is(this.props.match.params.id)
        .on(this.props.promos);
      let finalEvent = {};
      if (
        eventDetails[0] === undefined &&
        this.props.location.state.demo !== undefined &&
        this.props.location.state.demo === true
      ) {
        finalEvent = this.props.location.state.demoDetails;
      } else {
        finalEvent = eventDetails[0];
      }

      this.setState({
        eventDetails: finalEvent,
        loading: false,
      });

      this.setContent(
        this.props.match.params.id,
        this.props.location.state.demoDetails,
      );
    } else {
      const db = firebase.firestore();
      if (
        this.props.location.state !== undefined &&
        this.props.location.state.promo !== undefined &&
        this.props.location.state.promo.promo !== undefined
      ) {
        eventDetails = this.props.location.state.promo.promo;
        let count = eventDetails.locations.length;
        let counter = 1;
        eventDetails.locations.forEach((location) => {
          db.collection('locations')
            .where('locationId', '==', location.locationId)
            .get()
            .then((querySnapshot) => {
              //Notice the arrow funtion which bind `this` automatically.
              querySnapshot.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                locationsArray.push(doc.data());
              });
              if (count === counter) {
                this.setState({
                  locationsArray: locationsArray,
                  eventDetails: eventDetails,
                  loading: false,
                });
                this.setContent(this.props.match.params.id);
              } else {
                counter = counter + 1;
              }
            })
            .catch(function (error) {
              console.log('Error getting documents: ', error);
            });
        });
      } else if (
        this.props.location.state.demo !== undefined &&
        this.props.location.state.demo === true
      ) {
        this.setState({
          eventDetails: this.props.location.state.demoDetails,
          loading: false,
        });
        this.setContent(
          this.props.match.params.id,
          this.props.location.state.demoDetails,
        );
      } else {
        const promoRef = db
          .collection('promos')
          .doc(this.props.match.params.id)
          .get()
          .then((doc) => {
            eventDetails = doc.data();
            let count = eventDetails.locations.length;
            let counter = 1;

            eventDetails.locations.forEach((location) => {
              db.collection('locations')
                .where('locationId', '==', location.locationId)
                .get()
                .then((querySnapshot) => {
                  //Notice the arrow funtion which bind `this` automatically.
                  querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    locationsArray.push(doc.data());
                  });
                  if (count === counter) {
                    this.setState({
                      locationsArray: locationsArray,
                      eventDetails: eventDetails,
                      loading: false,
                    });
                    this.setContent(this.props.match.params.id);
                  } else {
                    counter = counter + 1;
                  }
                })
                .catch(function (error) {
                  console.log('Error getting documents: ', error);
                });
            });
          });
      }
    }
  }

  setContent(id, demo) {
    if (id !== undefined) {
      let contentDetails = [];
      if (this.props.user.authenticated === true) {
        let contentDetailsAll = query('id')
          .is(id)
          .on(this.props.promos);
        contentDetails = contentDetailsAll[0];
        if (contentDetails === undefined) {
          if (demo !== undefined) {
            contentDetails = demo;
          }
        }
      } else {
        if (demo !== undefined) {
          contentDetails = demo;
        } else {
          contentDetails = this.state.eventDetails;
        }
      }

      // Set Schedule
      if (contentDetails.scheduledTime.dates.startTimestamp !== '') {
        let selectedDay = moment(
          contentDetails.scheduledTime.dates.startTimestamp,
        );
        this.setState({
          from: selectedDay._d,
        });
      }

      if (contentDetails.scheduledTime.dates.endTimestamp !== '') {
        let selectedDay = moment(
          contentDetails.scheduledTime.dates.endTimestamp,
        );
        this.setState({
          to: selectedDay._d,
        });
      }
      if (contentDetails.scheduledTime.times.regHours === false) {
        let startTime =
          contentDetails.scheduledTime.times.startTimeString.seconds *
          1000; // Unix ms timestamp

        let endTime =
          contentDetails.scheduledTime.times.endTimeString.seconds *
          1000; // Unix ms timestamp

        let selectedStartTime = moment(startTime);
        let selectedEndTime = moment(endTime);
        this.setState({
          selectedStartTime: selectedStartTime,
          selectedEndTime: selectedEndTime,
          differentTime: 'yes',
        });
      }
      // Set Experience Types
      const experienceType = [];
      if (contentDetails.tagsExperienceTypes !== undefined) {
        contentDetails.tagsExperienceTypes.forEach((tag) => {
          experienceType.push({
            value: tag,
            label: tag,
          });
        });
      }
      this.setState({
        experienceType,
      });

      // Golden Ticket
      const goldenTicketLevel = [];
      if (contentDetails.goldenTicket !== undefined) {
        contentDetails.goldenTicket.levels.forEach((level) => {
          let label = 'Level ' + level;
          goldenTicketLevel.push({
            value: level,
            label: label,
          });
        });
      }
      let goldenTicketReward = {
        value: contentDetails.goldenTicket.reward.id,
        label: contentDetails.goldenTicket.reward.title,
      };
      this.setState({
        goldenTicketLevel,
        goldenTicketDetails: contentDetails.goldenTicket.details,
        goldenTicketQuantity: contentDetails.goldenTicket.quantity,
        levelSix: goldenTicketReward,
      });

      // Locations
      // let selectedLocations = [];
      // contentDetails.locations.forEach((location, i) => {
      //   const locationDetails = query('locationId')
      //     .is(location.locationId)
      //     .on(this.props.locations);

      // let newLocation = {
      //   approved: location.approved,
      //   approverId: location.approverId,
      //   locationId: location.locationId,
      //   locationTitle: location.locationTitle,
      //   approvalDate: location.approvalDate,
      //   locationThumb: location.locationThumb,
      //   address: location.address,
      //   settings: locationDetails[0].settings,
      // };
      // selectedLocations.push(newLocation);
      // });
      // Set Default Images
      // let heroPath = '';
      // if (contentDetails[0].images.heroPath !== null) {
      //   heroPath = contentDetails.images.heroPath;
      // }
      // let thumbPath = '';
      // if (contentDetails[0].images.thumbPath !== null) {
      //   thumbPath = contentDetails.images.thumbPath;
      // }

      // this.setState({
      //   // selectedLocations,
      //   promoLocations: contentDetails.locations,
      //   title: contentDetails.title,
      //   description: contentDetails.description,
      //   loading: false,
      //   promoId: contentDetails.id,
      //   checked: contentDetails.active,
      //   // heroPath,
      //   // thumbPath,
      // });

      let rewards = contentDetails.rewards;

      let levelOneCriteriaCount = 1;
      let levelTwoCriteriaCount = 1;
      let levelThreeCriteriaCount = 1;
      let levelFourCriteriaCount = 1;
      let levelFiveCriteriaCount = 1;

      rewards.forEach((reward, i) => {
        let level = {
          value: '',
          label: '',
        };

        if (reward.reward.length === 1) {
          level.value = reward.reward[0].id;
          level.label = reward.reward[0].title;
        } else {
          let title = '';
          let count = i + 1;
          let colabMode = 'all';
          if (count === 1) {
            colabMode = reward.colabSettings.colabOne;
          } else if (count === 2) {
            colabMode = reward.colabSettings.colabTwo;
          } else if (count === 3) {
            colabMode = reward.colabSettings.colabThree;
          } else if (count === 4) {
            colabMode = reward.colabSettings.colabFour;
          } else if (count === 5) {
            colabMode = reward.colabSettings.colabFive;
          }

          if (colabMode === 'choose') {
            reward.reward.forEach((rewardx, x) => {
              if (x === 0) {
                title = rewardx.title;
              } else {
                title = title + ' or ' + rewardx.title;
              }
            });
          } else if (colabMode === 'all') {
            reward.reward.forEach((rewardx, x) => {
              if (x === 0) {
                title = rewardx.title;
              } else {
                title = title + ' & ' + rewardx.title;
              }
            });
          }
          level.label = title;
        }

        if (i === 0) {
          let defaultCriteria = this.state.levelOneCriteria;
          reward.criteria.forEach((criteria, i) => {
            defaultCriteria[i] = criteria;
          });
          levelOneCriteriaCount = defaultCriteria.length + 1;
          this.setState({
            levelOneCriteria: defaultCriteria,
            lockedOne: reward.locked,
            levelOne: level,
            quantityOne: reward.quantity,
          });
        } else if (i === 1) {
          let defaultCriteria = this.state.levelTwoCriteria;
          reward.criteria.forEach((criteria, i) => {
            defaultCriteria[i] = criteria;
          });
          levelTwoCriteriaCount = defaultCriteria.length + 1;
          this.setState({
            levelTwoCriteria: defaultCriteria,
            lockedTwo: reward.locked,
            levelTwo: level,
            quantityTwo: reward.quantity,
          });
        } else if (i === 2) {
          let defaultCriteria = this.state.levelThreeCriteria;
          reward.criteria.forEach((criteria, i) => {
            defaultCriteria[i] = criteria;
          });
          levelThreeCriteriaCount = defaultCriteria.length + 1;
          this.setState({
            levelThreeCriteria: defaultCriteria,
            lockedThree: reward.locked,
            levelThree: level,
            quantityThree: reward.quantity,
          });
        } else if (i === 3) {
          let defaultCriteria = this.state.levelFourCriteria;
          reward.criteria.forEach((criteria, i) => {
            defaultCriteria[i] = criteria;
          });
          levelFourCriteriaCount = defaultCriteria.length + 1;
          this.setState({
            levelFourCriteria: defaultCriteria,
            lockedFour: reward.locked,
            levelFour: level,
            quantityFour: reward.quantity,
          });
        } else if (i === 4) {
          let defaultCriteria = this.state.levelFiveCriteria;
          reward.criteria.forEach((criteria, i) => {
            defaultCriteria[i] = criteria;
          });
          levelFiveCriteriaCount = defaultCriteria.length + 1;
          this.setState({
            levelFiveCriteria: defaultCriteria,
            lockedFive: reward.locked,
            levelFive: level,
            quantityFive: reward.quantity,
          });
        }
      });

      // Set Num of Criteria
      this.setState({
        levelCriteriaCounts: {
          levelOneCriteriaCount,
          levelTwoCriteriaCount,
          levelThreeCriteriaCount,
          levelFourCriteriaCount,
          levelFiveCriteriaCount,
        },
      });
    }
  }

  getGoldenTicket(reward, index) {
    let match = false;
    this.state.goldenTicketLevel.forEach((level, i) => {
      if (level.value === index) {
        match = true;
      }
    });
    if (match === true) {
      return (
        <span>
          <span style={{ color: 'black' }}>or </span>
          <span className="goldenTitle">
            {this.state.levelSix.label}
          </span>
        </span>
      );
    }
  }

  setActivePackage(ap) {
    // this.props.getAp(ap);
    // this.props.history.push("/checkout/setup");
  }

  quickEvent(event) {
    const newEvent = {
      connections: [],
      planFirst: false,
      connectFirst: false,
      clear: false,
      step: 2,
      screen: 1,
      fromVEvent: true,
      vEvent: event,
    };
    this.props.getNewEvent(newEvent);
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Experience Details
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  getFooter(mobile) {
    return (
      <Container className={mobile === false ? 'getGoing' : ''}>
        <Row className="no-margin">
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            <center>
              {/* <Link
                to={{
                  pathname: '/events/plan/1',
                }}
              > */}
              {this.props.user.authenticated === true && (
                <a
                  onClick={() => {
                    this.quickEvent(this.state.eventDetails);
                  }}
                >
                  <span className="center solidredbutton">
                    Add To Itinerary
                  </span>
                </a>
              )}
              {this.props.user.authenticated === false && (
                <Link
                  to={{
                    pathname: '/signup',
                    state: {
                      destination:
                        '/vevent/' + this.props.match.params.id,
                    },
                  }}
                >
                  <span className="center solidredbutton">
                    Add To Itinerary
                  </span>
                </Link>
              )}

              {/* </Link> */}
            </center>
          </Col>
        </Row>
      </Container>
    );
  }

  getPromos(event) {
    let promos = [];
    if (this.props.promos !== undefined) {
      this.props.promos.forEach((promo) => {
        if (promo.events.length !== 0) {
          promo.events.forEach((eventx) => {
            if (eventx.id === event.id) {
              promos.push(promo);
            }
          });
        }
      });
    }

    if (promos.length !== 0) {
      return (
        <div>
          {promos.map((promo, index) => (
            <div key={'key' + index}>
              <div className="promo">
                <span className="promo-reward">{promo.title}</span>
                {/* <span className="promo-criteria">{promo.criteria.text}</span> */}
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  getPreviewSchedule() {
    let date = '';
    if (
      this.state.to !== undefined &&
      this.state.from !== undefined &&
      this.state.to !== null &&
      this.state.from !== null
    ) {
      if (this.state.to === this.state.from) {
        let from = this.state.from.toLocaleDateString();
        date = from;
      } else {
        let from = this.state.from.toLocaleDateString();
        let to = this.state.to.toLocaleDateString();
        date = from + ' - ' + to;
      }
    } else {
      date = 'Not Scheduled';
    }
    return date;
  }

  setCriteriaDetailView(mode, criteria) {
    this.setState({
      criteriaDetailView: mode,
      activeCriteriaPreview: criteria,
    });
  }

  getCriteria(criteria, index) {
    let indexPlusOne = index + 1;
    if (criteria.type === 1) {
      if (criteria.numTimes < 2) {
        return (
          <div
            className="criteriaBorder"
            onClick={() => {
              {
                this.props.user.authenticated === true &&
                  this.setCriteriaDetailView(true, criteria);
              }
            }}
          >
            <Row className=" no-margin no-padding">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="left no-padding"
              >
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="check_box_outline_blank"
                    size={25}
                    color="#9a0039"
                  />
                </span>
              </Col>
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="left no-padding"
              >
                <span className="criteriaText">{criteria.text}</span>
                <br />
                {criteria.locations.map((location, index) => (
                  <span className="locationTag">
                    {location.label}
                  </span>
                ))}
              </Col>
            </Row>
          </div>
        );
      } else {
        let currentNum = 0;
        let width =
          ((currentNum / criteria.numTimes) * 100).toFixed(2) + '%';
        return (
          <div className="criteriaBorder">
            <Row className=" no-margin no-padding">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="left no-padding"
              >
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="check_box_outline_blank"
                    size={25}
                    color="#9a0039"
                  />
                </span>
              </Col>
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="left no-padding"
              >
                <span className="criteriaText">{criteria.text}</span>
                <div id="progressBarBack">
                  <span className="right red">
                    <span className="progressGoal">
                      {criteria.numTimes}
                    </span>
                  </span>
                  <div
                    className="progressBar"
                    style={{ width: width }}
                  >
                    <span className="progressTotal">
                      {currentNum}
                    </span>
                  </div>
                </div>
                {criteria.locations.map((location, index) => (
                  <span className="locationTag">
                    {location.label}
                  </span>
                ))}
              </Col>
            </Row>
          </div>
        );
      }
    }
  }

  getReward(reward, index, promo, locationId) {
    return (
      <div className="promo-criteria">
        <div id={'toggler' + index}>
          <span>
            <span className="rewardIcon">
              {reward.locked && (
                <MaterialIcon icon="lock" size={25} color="red" />
              )}
              {!reward.locked && (
                <MaterialIcon
                  icon="lock_open"
                  size={25}
                  color="red"
                />
              )}
            </span>
            <span>
              <b>Level {index + 1}:</b>
            </span>{' '}
            <span className="rewardTitle">
              {reward.title} {this.getGoldenTicket(reward, index + 1)}
            </span>
          </span>
        </div>
        <UncontrolledCollapse
          toggler={'#toggler' + index}
          defaultOpen={true}
        >
          {reward.criteria.map((criteria, index) => (
            <div>{this.getCriteria(criteria, index)}</div>
          ))}
        </UncontrolledCollapse>
      </div>
    );
  }

  getImage(type, locationStart) {
    let location = '';
    let locations = this.props.alllocations;
    const locationDetails = query('locationId')
      .is(locationStart.locationId)
      .on(locations);
    location = locationDetails[0];
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getPreviewRewards() {
    let levelOne = {
      title: this.state.levelOne.label,
      locked: false,
      lockDependency: '',
      quantity: this.state.quantityOne,
    };
    let levelOneCriteria = [];
    this.state.levelOneCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelOneCriteria.push(criteria);
      }
    });
    levelOne.criteria = levelOneCriteria;

    // Level 2
    let levelTwo = {
      title: this.state.levelTwo.label,
      locked: this.state.lockedTwo,
      quantity: this.state.quantityTwo,
      status: 1,
    };
    let levelTwoCriteria = [];
    this.state.levelTwoCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelTwoCriteria.push(criteria);
      }
    });
    levelTwo.criteria = levelTwoCriteria;
    if (
      this.state.lockedTwo === 'on' ||
      this.state.lockedTwo === true
    ) {
      levelTwo.lockDependency = this.state.levelOne.value;
    } else {
      levelTwo.lockDependency = '';
    }

    // Level 3
    let levelThree = {
      title: this.state.levelThree.label,
      locked: this.state.lockedThree,
      quantity: this.state.quantityThree,
      status: 1,
    };
    let levelThreeCriteria = [];
    this.state.levelThreeCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelThreeCriteria.push(criteria);
      }
    });
    levelThree.criteria = levelThreeCriteria;
    if (
      this.state.lockedThree === 'on' ||
      this.state.lockedThree === true
    ) {
      levelThree.lockDependency = this.state.levelThree.value;
    } else {
      levelThree.lockDependency = '';
    }

    // Level 4
    let levelFour = {
      title: this.state.levelFour.label,
      locked: this.state.lockedFour,
      quantity: this.state.quantityFour,
      status: 1,
    };
    let levelFourCriteria = [];
    this.state.levelFourCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelFourCriteria.push(criteria);
      }
    });
    levelFour.criteria = levelFourCriteria;
    if (
      this.state.lockedFour === 'on' ||
      this.state.lockedFour === true
    ) {
      levelFour.lockDependency = this.state.levelFour.value;
    } else {
      levelFour.lockDependency = '';
    }

    // Level 5
    let levelFive = {
      title: this.state.levelFive.label,
      locked: this.state.lockedFive,
      quantity: this.state.quantityFive,
      criteria: this.state.levelFiveCriteria,
      status: 1,
    };
    let levelFiveCriteria = [];
    this.state.levelFiveCriteria.forEach((criteria, i) => {
      if (criteria.text !== '') {
        levelFiveCriteria.push(criteria);
      }
    });
    levelFive.criteria = levelFiveCriteria;
    if (
      this.state.lockedFive === 'on' ||
      this.state.lockedFive === true
    ) {
      levelFive.lockDependency = this.state.levelFive.value;
    } else {
      levelFive.lockDependency = '';
    }

    let newRewards = [];
    if (levelOne.title !== undefined && levelOne.title !== '') {
      newRewards.push(levelOne);
    }
    if (levelTwo.title !== undefined && levelTwo.title !== '') {
      newRewards.push(levelTwo);
    }
    if (levelThree.title !== undefined && levelThree.title !== '') {
      newRewards.push(levelThree);
    }
    if (levelFour.title !== undefined && levelFour.title !== '') {
      newRewards.push(levelFour);
    }
    if (levelFive.title !== undefined && levelFive.title !== '') {
      newRewards.push(levelFive);
    }

    return (
      <div style={{ paddingBottom: '50px' }}>
        {newRewards.map((reward, index) => (
          <div className="reward" key={index + 1}>
            {this.getReward(reward, index)}
          </div>
        ))}
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          {this.props.user.authenticated === true && (
            <style
              dangerouslySetInnerHTML={{
                __html:
                  'html{display:grid} #mobile-nav { display: none }  .maincontent { top: 39px !important; margin-top: 0px; height: 100%} .App{background-color: #fafafa;}',
              }}
            />
          )}
          {this.props.user.authenticated === false && (
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '.toolbar{display: none}@media (max-width: 800px){.maincontent { top: 80px !important;}}  ',
              }}
            />
          )}
          <style
            dangerouslySetInnerHTML={{
              __html:
                '@media (max-width: 800px){.maincontent { top: 39px}} html{display:grid} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;}.footer-container{display:none} .App{background-color: #fafafa;}  #authleft {display:none;} #bottomNav { display: none } .maincontent { top: 0px; height: 100%}.footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;} p{max-width: 100% !important}',
            }}
          />
        </div>
      );
    }

    if (this.state.criteriaDetailView === true) {
      return (
        <div id="package" style={{ height: '100%' }}>
          <div className="criteriaDetails" style={{ height: '100%' }}>
            <div className="right">
              <MaterialIcon
                icon="close"
                size={25}
                color="white"
                onClick={() => {
                  this.setCriteriaDetailView(false);
                }}
              />
            </div>
            <div
              style={{
                clear: 'both',
                display: 'block',
                padding: '10px',
              }}
            >
              <span style={{ color: 'white', fontSize: '18px' }}>
                {this.state.activeCriteriaPreview.text}
              </span>
              <hr />
              <div style={{ minHeight: '350px' }}>
                {this.state.activeCriteriaPreview.guidelines !==
                  '' && (
                  <span style={{ color: 'white' }}>
                    {this.state.activeCriteriaPreview.guidelines}
                  </span>
                )}
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <span
                style={{
                  background: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  color: '#f21d59',
                }}
              >
                Get Validated!
              </span>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none}  .footer-container{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    return (
      <div id="package">
        {this.getTitleBar()}
        <Container style={{ height: '100%' }}>
          <Row className="no-margin" style={{ height: '100%' }}>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div style={{ height: '100%' }}>
                <Card style={{ height: '100%' }}>
                  <CardImg
                    top
                    width="100%"
                    src={this.state.eventDetails.images.heroPath}
                    alt="articleHero"
                  />
                  <CardBody>
                    {this.props.location.state !== undefined &&
                      this.props.location.state.demo !== undefined &&
                      this.props.location.state.demo === true && (
                        <div
                          style={{
                            display: 'block',
                            padding: '10px',
                            border: '1px solid #d5003a',
                            marginBottom: '20px',
                          }}
                        >
                          <div>
                            <center>
                              <span style={{ fontWeight: '600' }}>
                                This is a demo of a Rewarding
                                Experience.
                              </span>
                            </center>
                          </div>
                          <div>
                            <center>
                              <span>
                                Are you a business or entertainer
                                looking for a better way to
                                incentivize your fans?
                              </span>
                            </center>
                          </div>
                          <div style={{ marginTop: '10px' }}>
                            <center>
                              <Link
                                to={{
                                  pathname: '/experiences',
                                }}
                              >
                                <span>
                                  Check Out Trevel Experiences
                                </span>
                              </Link>
                            </center>
                          </div>
                        </div>
                      )}
                    <CardTitle>
                      {this.state.eventDetails.title}
                    </CardTitle>
                    <CardText>
                      <span className="description">
                        {this.state.eventDetails.description}
                      </span>
                      <span className="promo-criteria">
                        {this.getPreviewSchedule(
                          this.state.eventDetails.schedule,
                        )}
                      </span>
                      <div style={{ marginBottom: '20px' }}>
                        {this.state.experienceType &&
                          this.state.experienceType.map(
                            (tag, index) => (
                              <span className="experienceTag">
                                {tag.label}
                              </span>
                            ),
                          )}
                      </div>
                      <div
                        style={{ padding: '10px 0px 0px 0px' }}
                        id="package-itinerary"
                      >
                        {/* {this.state.eventDetails.demo !== true && ( */}
                        <PackageItinerary
                          locations={
                            this.state.eventDetails.locations
                          }
                          nonauthLocations={this.state.locationsArray}
                          eventId={this.state.eventDetails.id}
                          type={14}
                          demo={this.state.eventDetails.demo}
                          demoLocations={
                            this.props.demo.demoLocations
                          }
                        />
                        {/* )} */}
                      </div>
                      <span className="title">Available Rewards</span>
                      {this.getPreviewRewards()}
                      {/* {this.getPromos(this.state.eventDetails)} */}
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
        <div
          id={
            this.props.user.authenticated === true
              ? 'startBar'
              : 'startBar'
          }
          style={{ padding: '0px' }}
          className="mobileHide"
        >
          {this.getFooter(false)}
        </div>
        <div
          id={
            this.props.user.authenticated === true
              ? 'startBar'
              : 'startBar'
          }
          className="mobileShow"
        >
          {this.getFooter(true)}
        </div>
        {this.props.user.authenticated === true && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                'html{display:grid} #mobile-nav { display: none }  .maincontent { top: 39px !important; margin-top: 0px; height: 100%} .App{background-color: #fafafa;}',
            }}
          />
        )}
        {this.props.user.authenticated === false && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.backbar{display:none} .@media (max-width: 800px){.maincontent { top: 80px !important;}}  ',
            }}
          />
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.mainTag{float: none !important}@media (max-width: 800px){.maincontent { top: 80px}} html{display:grid} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;}.footer-container{display:none} .App{background-color: #fafafa;}  #authleft {display:none;} #bottomNav { display: none } .maincontent {  height: 100%}.footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;} p{max-width: 100% !important}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    promoEvents: state.promoEvents.events,
    user: state.user,
    newEvent: state.newEvent,
    promos: state.promos.promos,
    locations: state.locations.locations,
    demo: state.demo,
    ulocations: state.locations.locations,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    resetNewEvent: (newEvent) => {
      dispatch(resetNewEvent(newEvent));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VEvent);
