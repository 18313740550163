import * as action from "./actions";

export const getAp = id => {
  return {
    type: action.GET_AP,
    payload: id
  };
};

export const setAp = ap => {
  return {
    type: action.SET_AP,
    payload: ap
  };
};

const ACTION_HANDLERS = {
  [action.GET_AP]: (state, action) => {
    return { ...state };
  },
  [action.SET_AP]: (state, action) => {
    return { ...state, ...action.payload };
  }
};

let defaultState = {
  ap: {
    packageId: null,
    title: null,
    description: null,
    thumbnail: null,
    heroImage: null,
    creatorId: null,
    creatorImage: null,
    creatorName: null,
    category: null
  }
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
