import { call, put, takeLatest } from 'redux-saga/effects';
import { getVendorEntertainment } from '../../services/VendorEntertainmentServices';
import { getVendor } from '../vendorSaga/reducer';

import * as actions from './actions';

let defaultState = {
  entertainment: [],
};

function* getVendorEntertainmentAction(vendorId) {
  if (vendorId !== undefined) {
    let entertainment = yield call(
      getVendorEntertainment,
      vendorId.vendorId,
    );
    // let bundle = {
    //   claim: false,
    //   userId: vendorId.vendorId,
    //   newEntertainment: true,
    // };
    // yield put(getVendor(bundle));
    yield put({
      type: actions.SET_VENDORENTERTAINMENT,
      payload: {
        ...defaultState,
        entertainment,
      },
    });
  }
}

export default function* sagas() {
  yield takeLatest(
    actions.GET_VENDORENTERTAINMENT,
    getVendorEntertainmentAction,
  );
}
