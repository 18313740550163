import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
// Images
import {
  getNewEvent,
  resetNewEvent,
} from '../sagas/createEventSaga/reducer';
import { resetCe } from '../sagas/customEventSaga/reducer';
// Reducers
import { setFavorites } from '../sagas/favoritesSaga/reducer';
import { ContinuousColorLegend } from 'react-vis';

const _ = require('lodash');
const query = require('array-query');

export class Places extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      mode: 1,
      locations: [],
      favoriteLocations: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    }
    if (this.props.newEvent.clear === false) {
      this.props.resetNewEvent();
      this.props.resetCe();
    }
  }

  componentDidMount() {
    document.title = 'Favorite Places';
  }

  quickEvent(location) {
    let locations = [];

    locations.push({
      type: 0,
      locationId: location.locationId,
      locationTitle: location.locationTitle,
      locationThumb: location.locationThumb,
      address: location.address,
    });

    const newEvent = {
      connections: [],
      planFirst: false,
      connectFirst: false,
      clear: false,
      step: 2,
      screen: 1,
      fromFavorite: true,
      favoriteLocations: locations,
      weightedLocations: [],
    };
    this.props.getNewEvent(newEvent);
  }

  getPlaces(events, profile) {
    let places = [];
    let favorites = [];
    if (profile.favorites !== undefined) {
      favorites = profile.favorites;

      favorites.forEach((favorite) => {
        const locationDetails = query('locationId')
          .is(favorite)
          .on(this.props.alllocations);
        let location = locationDetails[0];

        places.push(location);
      });
    }

    return places;
  }

  getEntertainment(events, profile) {
    let entertainers = [];
    let favoriteEntertainment = [];
    if (profile.favoriteEntertainment !== undefined) {
      favoriteEntertainment = profile.favoriteEntertainment;
      favoriteEntertainment.forEach((favorite) => {
        const entertainmentDetails = query('entertainmentId')
          .is(favorite)
          .on(this.props.entertainment);
        let entertainer = entertainmentDetails[0];
        if (entertainmentDetails[0] !== undefined) {
          entertainers.push(entertainer);
        }
      });
    }

    return entertainers;
  }

  setFavorite(locationId, favorites, userId) {
    let update = favorites;
    update.push(locationId);
    let bundle = {
      userId,
      favorites: update,
      type: 1,
    };
    this.props.setFavorites(bundle);
  }

  removeFavorite(locationId, favorites, userId) {
    let update = favorites;
    _.remove(update, function (e) {
      return e === locationId;
    });
    let bundle = {
      userId,
      favorites: update,
      type: 1,
    };
    this.props.setFavorites(bundle);
  }

  removeEntertainmentFavorite(locationId, favorites, userId) {
    let ids = [];
    favorites.forEach((favorite) => {
      ids.push(favorite);
    });

    let update = ids;
    _.remove(update, function (e) {
      return e === locationId;
    });
    let bundle = {
      userId,
      favoriteEntertainment: update,
      type: 2,
    };
    this.props.setFavorites(bundle);
  }

  getEntertainmentFavoriteButton(locationId, favorites) {
    let ids = [];
    favorites.forEach((favorite) => {
      ids.push(favorite);
    });
    let isFavorite = _.includes(ids, locationId);
    let icon = 'star';
    if (isFavorite === true) {
      let icon = 'close';
      return (
        <a
          onClick={() => {
            this.removeEntertainmentFavorite(
              locationId,
              favorites,
              this.props.profile.userId,
            );
          }}
          className="right"
        >
          <span key={icon}>
            <MaterialIcon
              icon={icon}
              size={30}
              color="#f21d59"
              onClick={() => {
                this.removeEntertainmentFavorite(
                  locationId,
                  favorites,
                  this.props.profile.userId,
                );
              }}
            />
          </span>
        </a>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setFavorite(
              locationId,
              favorites,
              this.props.profile.userId,
            );
          }}
        >
          <span key={icon}>
            <MaterialIcon
              icon={icon}
              size={30}
              color="#f21d59"
              onClick={() => {
                this.setFavorite(
                  locationId,
                  favorites,
                  this.props.profile.userId,
                );
              }}
            />
          </span>
        </a>
      );
    }
  }

  getFavoriteButton(mode, locationId, favorites) {
    let isFavorite = _.includes(favorites, locationId);
    let icon = 'star';
    if (isFavorite === true) {
      let icon = 'close';
      return (
        <a
          onClick={() => {
            this.removeFavorite(
              locationId,
              favorites,
              this.props.profile.userId,
            );
          }}
          className="right"
        >
          <span key={icon}>
            <MaterialIcon
              icon={icon}
              size={30}
              color="#f21d59"
              onClick={() => {
                this.removeFavorite(
                  locationId,
                  favorites,
                  this.props.profile.userId,
                );
              }}
            />
          </span>
        </a>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setFavorite(
              locationId,
              favorites,
              this.props.profile.userId,
            );
          }}
        >
          <span key={icon}>
            <MaterialIcon
              icon={icon}
              size={30}
              color="#f21d59"
              onClick={() => {
                this.setFavorite(
                  locationId,
                  favorites,
                  this.props.profile.userId,
                );
              }}
            />
          </span>
        </a>
      );
    }
  }

  getImage(type, locationStart) {
    let location = '';
    let locations = this.props.alllocations;
    const locationDetails = query('locationId')
      .is(locationStart.locationId)
      .on(locations);
    location = locationDetails[0];
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getLocation(location, index, mode, favorites) {
    if (location !== undefined) {
      return (
        <Row className="no-padding">
          <Col xs={2} md={1} sm={2} lg={1} className="no-padding">
            <Link
              to={{
                pathname: '/location/' + location.locationId,
                state: {
                  location: { location },
                  type: 73,
                },
              }}
              style={{ width: '100%' }}
            >
              {this.getImage('thumb', location)}
            </Link>
          </Col>

          <Col xs={8} md={9} sm={8} lg={9}>
            <Link
              to={{
                pathname: '/location/' + location.locationId,
                state: {
                  location: { location },
                  type: 73,
                },
              }}
              style={{ width: '100%' }}
            >
              <span className="location-title">
                {location.locationTitle}
              </span>
              <span className="address">
                {location.address.street}
              </span>
              <span className="address">
                {location.address.city} {location.address.state},{' '}
                {location.address.zip}
              </span>
            </Link>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
            <div className="right text-right">
              {/* <a
                onClick={() => {
                  this.quickEvent(location);
                }}
              >
                <img
                  src={redLogo}
                  className="bottomNavLogo"
                  alt="logo"
                />
              </a> */}

              <span>
                {this.getFavoriteButton(
                  mode,
                  location.locationId,
                  favorites,
                )}
              </span>
            </div>
          </Col>
        </Row>
      );
    }
  }

  getEntertainer(entertainment, index, favorites) {
    let slug = '';
    if (entertainment.slug !== undefined) {
      slug = entertainment.slug;
    }
    let path = '/entertainment/' + slug;
    return (
      <Row className="no-padding">
        <Col xs={2} md={1} sm={2} lg={1} className="no-padding">
          <Link
            to={{
              pathname: path,
              state: {
                // location: { location },
                // type: 73,
              },
            }}
            style={{ width: '100%' }}
          >
            <img
              src={entertainment.entertainmentThumb}
              alt={entertainment.entertainmentTitle}
              className="thumbnail"
            />
          </Link>
        </Col>
        <Col xs={8} md={9} sm={8} lg={9}>
          <Link
            to={{
              pathname: entertainment,
              state: {
                // location: { location },
                // type: 73,
              },
            }}
            style={{ width: '100%' }}
          >
            <span className="location-title">
              {entertainment.entertainmentTitle}
            </span>
            {/* <span className="address">
              {entertainment.tagsEntertainmentType[0]}
            </span> */}
          </Link>
        </Col>
        <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
          <div className="right text-right">
            {/* <a
              onClick={() => {
                this.quickEvent(location);
              }}
            >
              <img
                src={redLogo}
                className="bottomNavLogo"
                alt="logo"
              />
            </a> */}
            <span>
              {this.getEntertainmentFavoriteButton(
                entertainment.entertainmentId,
                favorites,
              )}
            </span>
          </div>
        </Col>
      </Row>
    );
  }

  getTab(tabMode, tabLable, mode) {
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tabLable}
          </div>
        </a>
      );
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getContent(mode, favorites, locations) {
    if (mode === 2) {
      return this.listLocations(locations, mode, favorites);
    } else if (mode === 1) {
      return (
        <div style={{ width: '100%' }}>
          {locations.length !== 0 &&
            locations.map((location, index) => (
              <div className="oddeven" key={'key' + index}>
                {this.getLocation(location, index, mode, favorites)}
              </div>
            ))}
          {locations.length === 0 && (
            <div>
              <span>
                You haven{"'"}t marked any Favorite Destinations yet.
              </span>
              {/* <div
                style={{
                  padding: '15px 10px 10px 10px',
                  border: '1px solid lightgrey',
                  margin: '0px auto',
                  width: '100%',
                  marginTop: '100px',
                  background: 'white',
                  display: 'flex',
                  textAlign: 'left',
                }}
              >
                <Col xs={2} md={1} sm={2} lg={1}>
                  <div style={{ float: 'left' }}>
                    <MaterialIcon
                      icon="help_outline"
                      size={35}
                      color="rgb(238 1 88)"
                    />
                  </div>
                </Col>
                <Col xs={10} md={11} sm={10} lg={11}>
                  <h4
                    style={{ fontSize: '18px', marginBottom: '10px' }}
                  >
                    Favorite Locations
                  </h4>
                  <p
                    style={{
                      margin: '0px',
                      padding: '0px 0px 10px 0px',
                    }}
                  >
                    Click on the{' '}
                    <span
                      style={{ fontWeight: '600', fontSize: '20px' }}
                    >
                      +
                    </span>{' '}
                    in the top right to visit the Trevel Guide. (Tip:
                    You can always find the Guide in the menu.){' '}
                  </p>
                  <p>
                    When you find a location you love just click on
                    the Star Button to mark it as a Favorite Location.
                  </p>
                </Col>
              </div> */}
            </div>
          )}
        </div>
      );
    }
  }

  getEntertainmentContent(favorites, entertainment) {
    return (
      <div style={{ width: '100%' }}>
        {entertainment.length !== 0 &&
          entertainment.map((entertainer, index) => (
            <div className="oddeven" key={'key' + index}>
              {this.getEntertainer(entertainer, index, favorites)}
            </div>
          ))}
        {entertainment.length === 0 && (
          <div>
            <span>
              You have not marked any Favorite Entertainers yet.
            </span>
            {/* <div
              style={{
                padding: '15px 10px 10px 10px',
                border: '1px solid lightgrey',
                margin: '0px auto',
                width: '100%',
                marginTop: '100px',
                background: 'white',
                display: 'flex',
                textAlign: 'left',
              }}
            >
              <Col xs={2} md={1} sm={2} lg={1}>
                <div style={{ float: 'left' }}>
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="rgb(238 1 88)"
                  />
                </div>
              </Col>
              <Col xs={10} md={11} sm={10} lg={11}>
                <h4
                  style={{ fontSize: '18px', marginBottom: '10px' }}
                >
                  Favorite Locations
                </h4>
                <p
                  style={{
                    margin: '0px',
                    padding: '0px 0px 10px 0px',
                  }}
                >
                  Click on the{' '}
                  <span
                    style={{ fontWeight: '600', fontSize: '20px' }}
                  >
                    +
                  </span>{' '}
                  in the top right to visit the Trevel Guide. (Tip:
                  You can always find the Guide in the menu.){' '}
                </p>
                <p>
                  When you find a location you love just click on the
                  Star Button to mark it as a Favorite Location.
                </p>
              </Col>
            </div> */}
          </div>
        )}
      </div>
    );
  }

  listLocations(locations, mode, favorites) {
    return (
      <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
        {locations.length === 0 &&
          locations.map((location, index) => (
            <div className="oddeven" key={'key' + index}>
              {this.getLocation(location, index, mode, favorites)}
            </div>
          ))}
      </Col>
    );
  }

  listEntertainment(entertainment, mode, favorites) {
    return (
      <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
        {entertainment.length === 0 &&
          entertainment.map((entertainer, index) => (
            <div className="oddeven" key={'key' + index}>
              {this.getLocation(entertainer, index, mode, favorites)}
            </div>
          ))}
      </Col>
    );
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">My Favorites</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <Link
                to={{
                  pathname: '/guide/search',
                }}
                className="right"
              >
                <MaterialIcon icon="add" size={25} color="white" />
              </Link> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    const places = this.getPlaces(
      this.props.events,
      this.props.profile,
    );

    const entertainers = this.getEntertainment(
      this.props.events,
      this.props.profile,
    );

    let favorites = [];
    if (this.props.profile.favorites !== undefined) {
      favorites = this.props.profile.favorites;
    }

    let favoriteEntertainment = [];
    if (this.props.profile.favoriteEntertainment !== undefined) {
      favoriteEntertainment =
        this.props.profile.favoriteEntertainment;
    }

    return (
      <div id="profile">
        {/* <TitleBar title="Favorite Places" backlink="" /> */}
        {this.getTitleBar()}
        {/* <div id="headersTopSecondary" className="full-width">
          <Row>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(1, 'Favorites', this.state.mode)}
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(2, 'Add Location', this.state.mode)}
            </Col>
          </Row>
        </div> */}
        <div className="section ">
          <Container>
            <span
              style={{
                fontSize: '17px',
                fontWeight: '500',
                marginTop: '15px',
                display: 'block',
              }}
            >
              Destinations
            </span>
            <Row>
              {this.getContent(this.state.mode, favorites, places)}
            </Row>
          </Container>
          <Container style={{ marginTop: '20px' }}>
            <span style={{ fontSize: '17px', fontWeight: '500' }}>
              Entertainment
            </span>
            <Row>
              {this.getEntertainmentContent(
                favoriteEntertainment,
                entertainers,
              )}
            </Row>
            <div className="text-center">
              <div
                style={{
                  padding: '15px 10px 10px 0px',
                  border: '1px solid lightgrey',
                  margin: '0px auto',
                  width: '100%',
                  marginTop: '100px',
                  background: 'white',
                  display: 'flex',
                  textAlign: 'left',
                }}
              >
                <Col xs={2} md={1} sm={2} lg={1}>
                  <div style={{ float: 'left' }}>
                    <MaterialIcon
                      icon="help_outline"
                      size={35}
                      color="rgb(238 1 88)"
                    />
                  </div>
                </Col>
                <Col xs={10} md={11} sm={10} lg={11}>
                  <h4
                    style={{ fontSize: '18px', marginBottom: '10px' }}
                  >
                    All Your Favorite Things
                  </h4>

                  <p>
                    When you find a Destination or Entertainer you
                    love just click on the{' '}
                    <span style={{ fontWeight: '600' }}>
                      <span
                        style={{
                          marginTop: '0px',
                          top: '5px',
                          display: 'inline-block',
                          position: 'relative',
                        }}
                      >
                        <MaterialIcon
                          icon="favorite"
                          size={25}
                          color="grey"
                        />
                      </span>{' '}
                    </span>{' '}
                    to mark them as a Favorite. That way their
                    upcoming events and promotions will appear on your
                    custom guide. <br /> <br />
                    Plus, when you are making plans with others they
                    can see all your favorite things to make finding
                    the experience easier!
                  </p>
                </Col>
              </div>
            </div>
          </Container>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFavorites: (bundle) => {
      dispatch(setFavorites(bundle));
    },
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    resetNewEvent: (newEvent) => {
      dispatch(resetNewEvent(newEvent));
    },
  };
};

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    events: state.events.events,
    user: state.user,
    newEvent: state.newEvent,
    alllocations: state.locations.locations,
    entertainment: state.entertainment.entertainment,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Places);
