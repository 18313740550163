import React from 'react';
import { connect } from 'react-redux';

export class TopicPromotions extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div id="helpTopic">
        <div className="wizard-section-border noTopPadding">
          <h6>Promotions</h6>
          <p>
            Our vendors often give us special promotions to run on the
            platform.
          </p>
          <p>
            If you earn a reward or have a voucher that you can
            redeem, "Start" your event to find the vouchers. Have the
            representative from the location "Redeem" the voucher to
            receive your discount or special offer.
          </p>

          <p>
            Some of these vouchers are limited in number, so we can
            {"'"}t always guarentee that there will be one waiting for
            you. We allow our vendors to limit the number of
            promotions to help make sure they are not losing money in
            the process. Our goal is to support local businesses, and
            limiting the number of vouchers is sometimes a good way to
            do that for our vendors.
          </p>
          {/* <Card>
            <a id='inviteNow' className='red helpTopic'>
              Invite Someone Now
            </a>
            <UncontrolledCollapse toggler='#inviteNow'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card>
          <Card>
            <a id='inviteLater' className='red helpTopic'>
              Invite Someone Later
            </a>
            <UncontrolledCollapse toggler='#inviteLater'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {};
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
    user: state.user,
    events: state.events.events,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicPromotions);
