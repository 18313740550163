import { call, put, takeLatest } from 'redux-saga/effects';
import { getUserAwards } from '../../services/UserAwardsServices';
import * as actions from './actions';

let defaultState = {
  awards: {
    status: 0,
  },
};

function* getUserAwardsAction(action) {
  let awards = yield call(getUserAwards, action.payload);
  yield put({
    type: actions.SET_AWARDS,
    payload: {
      ...defaultState,
      awards,
    },
  });
}

function* refreshUserAwardsAction(action) {
  const awards = action.payload;
}

export default function* sagas() {
  yield takeLatest(actions.GET_AWARDS, getUserAwardsAction);
  yield takeLatest(actions.REFRESH_AWARDS, refreshUserAwardsAction);
}
