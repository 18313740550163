import { call, put, takeLatest } from "redux-saga/effects";
import { getAllLocations } from "../../services/LocationServices";

import * as actions from "./actions";

let defaultState = {
  alllocations: [],
};

function* getLocationsAction(bundle) {
  let locations = yield call(getAllLocations, bundle.zoneId);
  yield put({
    type: actions.SETALL_LOCATIONS,
    payload: {
      ...defaultState,
      locations,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GETALL_LOCATIONS, getLocationsAction);
}
