import copy from 'copy-to-clipboard';
import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
// Reducers
import { sendInvite } from '../../sagas/inviteSaga/reducer';
import { updateNewEvent } from '../../sagas/createEventSaga/reducer';
import { getCe } from '../../sagas/customEventSaga/reducer';
// Components
import GuestList from '../GuestList';
import InviteUser from '../InviteUser';
import WriteMessage from '../WriteMessage';

const query = require('array-query');

class GuestListModal extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      screen: 1,
      fullName: '',
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.prepareInvite = this.prepareInvite.bind(this);
    this.prepareMultipleInvites =
      this.prepareMultipleInvites.bind(this);
    this.gotoMessage = this.gotoMessage.bind(this);
    this.resetToken = this.resetToken.bind(this);
    this.changeScreenAndResetToken =
      this.changeScreenAndResetToken.bind(this);
  }

  UNSAFE_componentWillMount() {
    const token = (Math.floor(Math.random() * 10000) + 10000)
      .toString()
      .substring(1);
    this.setState({
      token,
    });
  }

  changeScreen(screen) {
    this.setState({
      screen,
    });
  }

  resetToken() {
    const token = (Math.floor(Math.random() * 10000) + 10000)
      .toString()
      .substring(1);
    this.setState({
      token,
    });
  }

  changeScreenAndResetToken(screen) {
    const token = (Math.floor(Math.random() * 10000) + 10000)
      .toString()
      .substring(1);
    this.setState({
      token,
      screen,
    });
  }

  getScreen() {
    if (this.state.screen === 1) {
      return (
        <div className="section">
          <GuestList
            event={this.props.event}
            changeScreen={this.changeScreen}
            changeScreenAndResetToken={this.changeScreenAndResetToken}
            profile={this.props.profile}
            confirmDelete={this.props.confirmDelete}
            confirmCopy={this.props.confirmCopy}
          />
        </div>
      );
    } else if (this.state.screen === 2) {
      return (
        <InviteUser
          event={this.props.event}
          changeScreen={this.changeScreen}
          prepareInvite={this.prepareInvite}
          prepareMultipleInvites={this.prepareMultipleInvites}
          gotoMessage={this.gotoMessage}
        />
      );
    } else if (this.state.screen === 3) {
      return (
        <WriteMessage
          event={this.props.event}
          changeScreen={this.changeScreen}
          invitedUser={this.state.invitedUser}
          profile={this.props.profile}
          packages={this.props.packages}
          sendInvite={this.props.sendInvite}
          fullName={this.state.fullName}
        />
      );
    } else if (this.state.screen === 4) {
      return (
        <div>
          <div className="section centerText">
            <h2 className="guestListTitle">
              Your Invitation Is Ready!
            </h2>
            <span>
              Just click on the button bellow to copy your link and
              send it to your guest however you want!
            </span>
          </div>
          <div className="section">
            <center>
              <a
                onClick={() => {
                  this.copyLink(this.state.fullName);
                }}
                className="redbutton white display-block"
              >
                <span>Copy Invite Link</span>
              </a>
            </center>
          </div>
        </div>
      );
    }
  }

  copyLink() {
    let textToCopy =
      'http://www.trevel.com/invite/' + this.state.token;
    copy(textToCopy);
    this.setState({
      copied: true,
    });
    this.changeScreen(1);
  }

  prepareMultipleInvites(newConnections, invitedUsers) {
    let connections = null;
    let editMode = false;

    let locationArray = [];
    if (this.props.newEvent.locations !== undefined) {
      locationArray = this.props.newEvent.locations;
    } else if (this.props.newEvent.weightedLocations !== undefined) {
      locationArray = this.props.newEvent.weightedLocations;
    }

    if (this.props.event.connections !== undefined) {
      connections = this.props.event.connections;
    } else {
      editMode = true;
      connections = this.props.event.users;
    }
    if (editMode === false) {
      let invitedUsersUpdate = this.props.event.invitations;
      let updatedConnections = connections;
      Object.keys(newConnections).forEach((key) => {
        invitedUsersUpdate.push(key);
        updatedConnections[key] = {
          access: true,
          approved: false,
          editor: false,
          attending: false,
          rejected: false,
          inviteAction: false,
          profile: {
            fullName: newConnections[key].profile.fullName,
            profilePhotoPath:
              newConnections[key].profile.profilePhotoPath,
            userName: newConnections[key].profile.userName,
          },
        };
      });

      let updatedEvent = {
        connectFirst: this.props.newEvent.connectFirst,
        planFirst: false,
        connections: updatedConnections,
        clear: this.props.newEvent.clear,
        invitedUsers: invitedUsersUpdate,
        step: this.props.newEvent.step,
        favoriteLocations: this.props.newEvent.favoriteLocations,
        locations: locationArray,
        fromFavorite: this.props.newEvent.fromFavorite,
        fromVEvent: this.props.newEvent.fromVEvent,
        fromPackage: this.props.newEvent.fromPackage,
        vEvent: this.props.newEvent.vEvent,
        customEvent: this.props.newEvent.customEvent,
        singleLocation: this.props.newEvent.singleLocation,
        fromLocation: this.props.newEvent.fromLocation,
        weightedLocations: this.props.newEvent.weightedLocations,
      };
      this.props.updateNewEvent(updatedEvent);
      this.changeScreen(1);
    } else {
      let updatedEvent = this.props.customEvent;
      const timestamp = new Date();
      Object.keys(newConnections).forEach((key) => {
        updatedEvent.event.users[key] = {
          access: true,
          approved: false,
          editor: false,
          attending: false,
          rejected: false,
          inviteAction: false,
          profile: {
            fullName: newConnections[key].profile.fullName,
            profilePhotoPath:
              newConnections[key].profile.profilePhotoPath,
            userName: newConnections[key].profile.userName,
          },
        };

        let editable = true;
        if (this.props.customEvent.event.status === 0) {
          editable = false;
        }
        let type = 1;
        if (this.props.customEvent.event.status === 0) {
          type = 4;
        }
        updatedEvent.event.invitations[key] = {
          dateSent: timestamp,
          senderName: this.props.profile.firstName,
          senderLastName: this.props.profile.lastName,
          profilePhotoPath: this.props.profile.profilePhotoPath,
          senderId: this.props.profile.userId,
          userPurchaseId: null,
          eventId: this.props.customEvent.event.eventId,
          locations: this.props.customEvent.event.locations,
          receiverName: newConnections[key].profile.fullName,
          userName: newConnections[key].profile.userName,
          userId: key,
          receiverPhotoPath:
            newConnections[key].profile.profilePhotoPath,
          type,
          status: 0,
          editable,
        };
      });
      this.props.getCe(updatedEvent);
      this.changeScreen(1);
    }
  }

  prepareInvite(user) {
    let connections = null;
    let editMode = false;

    if (this.props.event.connections !== undefined) {
      connections = this.props.event.connections;
    } else {
      editMode = true;
      connections = this.props.event.users;
    }

    if (editMode === false) {
      let invitedUsers = this.props.event.invitations;
      let newUserFullName = '';
      if (user.newUser !== undefined && user.newUser === true) {
        newUserFullName = user.fullName;
      } else {
        newUserFullName = user.firstName + ' ' + user.lastName;
      }

      let newConnections = connections;

      if (newConnections[user.userId] === undefined) {
        // Add Guest
        if (user.newUser !== undefined && user.newUser === true) {
          newConnections[user.userId] = {
            connected: false,
            access: true,
            approved: false,
            editor: false,
            attending: false,
            rejected: false,
            inviteAction: false,
            newUser: true,
            profile: {
              fullName: newUserFullName,
              profilePhotoPath: user.profilePhotoPath,
              userName: user.userName,
            },
          };
        } else {
          newConnections[user.userId] = {
            connected: false,
            access: true,
            approved: false,
            editor: false,
            attending: false,
            rejected: false,
            inviteAction: false,
            profile: {
              fullName: newUserFullName,
              profilePhotoPath: user.profilePhotoPath,
              userName: user.userName,
            },
          };
          // }
        }
      } else {
        let xxx = {};
        Object.keys(connections).forEach((key) => {
          if (key !== user.userId) {
            xxx[key] = connections[key];
          }
        });
        newConnections = xxx;
      }
      let invitedUsersUpdate = invitedUsers;
      invitedUsersUpdate.push(user.userId);
      let updatedEvent = {
        connectFirst: this.props.newEvent.connectFirst,
        planFirst: this.props.newEvent.planFirst,
        connections: newConnections,
        clear: this.props.newEvent.clear,
        invitedUsers: invitedUsersUpdate,
        step: this.props.newEvent.step,
        favoriteLocations: this.props.newEvent.favoriteLocations,
        locations: this.props.newEvent.locations,
        fromFavorite: this.props.newEvent.fromFavorite,
        fromVEvent: this.props.newEvent.fromVEvent,
        fromPackage: this.props.newEvent.fromPackage,
        vEvent: this.props.newEvent.vEvent,
        customEvent: this.props.newEvent.customEvent,
        singleLocation: this.props.newEvent.singleLocation,
        fromLocation: this.props.newEvent.fromLocation,
        weightedLocations: this.props.newEvent.weightedLocations,
      };
      // this.props.updateNewEvent(updatedEvent);
      // this.changeScreen(1);
    } else {
      let updatedEvent = this.props.customEvent;
      const timestamp = new Date();
      let newUserFullName = '';
      if (user.newUser !== undefined && user.newUser === true) {
        newUserFullName = user.fullName;
      } else {
        newUserFullName = user.firstName + ' ' + user.lastName;
      }

      if (updatedEvent.event.users[user.userId] === undefined) {
        // Add Guest
        if (user.newUser !== undefined && user.newUser === true) {
          updatedEvent.event.users[user.userId] = {
            access: true,
            approved: false,
            editor: false,
            attending: false,
            rejected: false,
            inviteAction: false,
            newUser: true,
            profile: {
              fullName: newUserFullName,
              profilePhotoPath: user.profilePhotoPath,
              userName: user.userName,
            },
          };
        } else {
          updatedEvent.event.users[user.userId] = {
            access: true,
            approved: false,
            editor: false,
            attending: false,
            rejected: false,
            inviteAction: false,
            profile: {
              fullName: newUserFullName,
              profilePhotoPath: user.profilePhotoPath,
              userName: user.userName,
            },
          };
          // }
        }
      }
      // else {
      //   let xxx = {};
      //   Object.keys(connections).forEach((key) => {
      //     if (key !== user.userId) {
      //       xxx[key] = connections[key];
      //     }
      //   });
      //   newConnections = xxx;
      // }

      let editable = true;
      if (this.props.customEvent.event.status === 0) {
        editable = false;
      }
      let type = 1;
      if (this.props.customEvent.event.status === 0) {
        type = 4;
      }

      updatedEvent.event.invitations[user.userId] = {
        dateSent: timestamp,
        senderName: this.props.profile.firstName,
        senderLastName: this.props.profile.lastName,
        profilePhotoPath: this.props.profile.profilePhotoPath,
        senderId: this.props.profile.userId,
        userPurchaseId: null,
        eventId: this.props.customEvent.event.eventId,
        locations: this.props.customEvent.event.locations,
        receiverName: newUserFullName,
        userName: user.userName,
        userId: user.userId,
        receiverPhotoPath: user.profilePhotoPath,
        type,
        status: 0,
        editable,
      };
      // this.props.getCe(updatedEvent);
      // this.changeScreen(1);
    }
    this.changeScreen(1);
  }

  //   prepareInvite(userId) {

  // // update newEvent

  //     this.setState({
  //       invitedUser: userId,
  //     });
  //     this.changeScreen(3);

  //   }

  // New User Invite

  gotoMessage(fullName) {
    const invitedUser = {
      type: 0,
      userId: this.state.token,
      fullName: fullName,
      profilePhotoPath: '',
      userName: '',
      newUser: true,
    };
    this.prepareInvite(invitedUser);
    this.setState({
      fullName: fullName,
      invitedUser,
    });

    // this.changeScreen(3);
  }

  render() {
    return (
      <div>
        {/* <div className="modalHeader">
          <a
            onClick={() => {
              this.props.closeGuestListModal();
            }}
          >
            <div className="modalCloser text-center">
              <MaterialIcon
                icon="keyboard_arrow_down"
                size={25}
                color="white"
              />
            </div>
          </a>
          <a
            onClick={() => {
              this.props.closeGuestListModal();
            }}
          >
            <span className="modalHeader-text">Guest List</span>
          </a>
        </div> */}

        <div>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              {this.getScreen()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    packages: state.packages.packages,
    newEvent: state.newEvent,
    customEvent: state.customEvent.customEvent,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendInvite: (invite) => {
      dispatch(sendInvite(invite));
    },
    updateNewEvent: (newEvent) => {
      dispatch(updateNewEvent(newEvent));
    },
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuestListModal);

// Location Details Available
// **************************
// this.props.location.state.location.location.locationTitle
// this.props.location.state.location.location.categories
// this.props.location.state.location.location.locationDescription
// this.props.location.state.location.location.locationHero
// this.props.location.state.location.location.locationThumb
// this.props.location.state.location.location.locationId
// this.props.location.state.location.location.locationType
// ** Vouchers and Packages as well
