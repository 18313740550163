import { ErrorMessage } from 'formik';
import MaterialIcon from 'material-icons-react';
import React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Select from 'react-select';
import TitleBar from '../../components/TitleBar';
import firebase from '../../config/firebase';
import addbtn from '../../images/addbtn.png';
import UserAvatar from 'react-user-avatar';
// Reducers
import { getVendorRewards } from '../../sagas/vendorRewardsSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

export class VendorEditUser extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      showPicker: false,
      mode: 1,
    };
  }
  componentDidMount() {
    let userDetails = [];
    if (this.props.match.params.id !== undefined) {
      userDetails = query('userId')
        .is(this.props.match.params.id)
        .on(this.props.vendor.users);
    }

    let locations = [];
    this.props.vendor.locations.forEach((location, i) => {
      const locationDetails = query('locationId')
        .is(location)
        .on(this.props.locations);
      locations.push({
        value: location,
        label: locationDetails[0].locationTitle,
      });
    });
    this.setState({
      locationOptions: locations,
      userDetails: userDetails[0],
      loading: false,
    });
  }

  criteriaValidatorHandler = (values) => {
    this.setState({ selectedValidatorLocations: values });
  };

  criteriaAdminHandler = (values) => {
    this.setState({ selectedAdminLocations: values });
  };

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  displayForm() {
    const uploadUrl = 'test/' + this.props.profile.vendorAccount.id;
    return (
      <div>
        <div>
          <div>
            <Container>
              {/* <Row>
                <Col xs={12} md={12} sm={12} lg={12} className="left">
                  <span className="inputLabel">Title</span>
                  <input
                    type="text"
                    onChange={this.titleOnChange.bind(this)}
                    value={this.state.title}
                    className="full-width"
                  />
                  <span className="inputLabel">Details</span>
                  <textarea
                    type="text"
                    onChange={this.detailsOnChange.bind(this)}
                    value={this.state.details}
                    className="full-width"
                  />
                  <span className="inputLabel">Restrictions</span>
                  <textarea
                    type="text"
                    onChange={this.restrictionsOnChange.bind(this)}
                    value={this.state.restrictions}
                    className="full-width"
                  />
                </Col>
              </Row> */}
              <Row>
                <Col xs={12} md={12} sm={12} lg={12} className="left">
                  <span className="inputLabel">
                    <b>Admin Locations</b>{' '}
                  </span>
                  <Select
                    value={this.state.selectedAdminLocations}
                    closeMenuOnSelect={true}
                    blurInputOnSelect={false}
                    onChange={(e) => this.criteriaAdminHandler(e)}
                    options={this.state.locationOptions}
                    isMulti={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12} className="left">
                  <span className="inputLabel">
                    <b>Validator Locations</b>{' '}
                  </span>
                  <Select
                    value={this.state.selectedValidatorLocations}
                    closeMenuOnSelect={true}
                    blurInputOnSelect={false}
                    onChange={(e) => this.criteriaValidatorHandler(e)}
                    options={this.state.locationOptions}
                    isMulti={true}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  titleOnChange(e) {
    this.setState({ title: e.target.value });
  }

  detailsOnChange(e) {
    this.setState({ details: e.target.value });
  }

  restrictionsOnChange(e) {
    this.setState({ restrictions: e.target.value });
  }

  resetPackage() {
    this.props.resetPackage();
  }

  getLeftMenu() {
    return (
      <div className="vendorLeftNavButtons">
        {this.getTab(1, 'Permissions', this.state.mode, false)}
      </div>
    );
  }

  getTitleBar(title, name) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              {/* <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span> */}
              <span className="vendorAppTitle">
                {title}
                <span
                  className="vendorAppTitle"
                  style={{ color: 'grey' }}
                >
                  {name}
                </span>
              </span>
              {/* </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding"> */}
              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
              <div className="saveButtons">
                <a
                  onClick={() => {
                    this.createPromo();
                  }}
                >
                  <span className="saveButton">Save</span>
                </a>

                <Link
                  to={{
                    pathname: '/vendor/users',
                  }}
                >
                  <span className="cancelButton">Cancel</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getSection(mode) {
    if (mode === 1) {
      return (
        <div id="experienceForm">
          <div id="experienceFormContent">{this.displayForm()}</div>
        </div>
      );
    } else if (mode === 2) {
      return (
        <div id="experienceForm">
          {/* {this.getSaveBar()} */}
          <div id="experienceFormContent">
            <span>2</span>
          </div>
        </div>
      );
    } else if (mode === 3) {
      return (
        <div id="experienceForm">
          {/* {this.getSaveBar()} */}
          <div id="experienceFormContent">
            <span>3</span>
          </div>
        </div>
      );
    }
  }

  getTab(tabMode, tabLable, mode, last) {
    if (tabMode === mode) {
      if (last === true) {
        return (
          <div className="noBorderRight actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      } else {
        return (
          <div className="actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      }
    } else {
      if (last === true) {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton noBorderRight  actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      }
    }
  }

  getAppContent() {
    return (
      <div style={{ height: '100%' }}>
        <div className="vendorLeftNav">
          <div style={{ padding: '10px' }}>
            <span style={{ fontWeight: '600', color: 'white' }}>
              MANAGE
            </span>
          </div>

          {this.getLeftMenu()}
        </div>
        {this.getSection(this.state.mode)}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .inputLabel {font-weight: 400;} .container {max-width: 100%; !important} .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div id="vendor">
        {/* {this.state.showPicker === true && (
        
        )} */}
        {this.getTitleBar(
          'Edit User: ',
          this.state.userDetails.fullName,
        )}
        {this.getAppContent()}

        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} #analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customEvent: state.customEvent.customEvent,
    locations: state.locations.locations,
    events: state.events.events,
    packages: state.packages.packages,
    profile: state.profile.profile,
    packageBundle: state.newPackage,
    vendor: state.vendor.vendor,
    vendorRewards: state.vendorRewards.rewards,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorRewards: (awards) => {
      dispatch(getVendorRewards(awards));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorEditUser);
