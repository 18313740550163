import React, { Component } from 'react';
import { connect } from 'react-redux';
import geolib from 'geolib';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
} from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import GuideCategory from '../../components/GuideCategory';
import DailyLocationSpotlight from '../../components/DailyLocationSpotlight ';
import { withRouter } from 'react-router-dom';
import { getCe } from '../../sagas/customEventSaga/reducer';

const _ = require('lodash');
const KEYS_TO_FILTERS = ['locationTitle', 'locations.locationTitle'];

class GuideDestinationsAll extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      width: 0,
      height: 0,
      tagFilters: [],
      promos: [],
    };
    this.addLocation = this.addLocation.bind(this);
  }

  componentDidMount() {
    this.searchUpdated('');

    let locations = [];
    if (this.props.user.authenticated === true) {
      locations = this.props.authLocations;
    } else {
      locations = this.props.locations;
    }

    if (this.state.tagFilters.length === 0) {
      this.setTags(this.props.tags, locations);
    }
  }

  addLocation(customEvent, location) {
    let customImages = false;
    if (location.settings.customImages !== undefined) {
      customImages = location.settings.customImages;
    }

    if (customEvent.event.locations !== null) {
      const newLocations = [];
      customEvent.event.locations.forEach((location) => {
        newLocations.push(location);
      });
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          type: 0,
          locationId: location.locationId,
          locationTitle: location.locationTitle,
          locationThumb: location.locationThumb,
          address: location.address,
          hours: location.hours,
          settings: {
            customImages,
          },
        });
      }
      customEvent.event.locations = newLocations;
      customEvent.step = 1;
      this.props.getCe(customEvent);
    } else {
      const newLocations = [];
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          locationId: location.locationId,
          type: 0,
        });
      }
      customEvent.step = 1;
      customEvent.event.locations = newLocations;
      this.props.getCe(customEvent);
      this.props.history.push('/event/create/home/1');
    }
  }

  findPromotedLocations(locations, category, tagCat) {
    let setTag = undefined;

    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisineType',
      tags: [],
    };

    // Combine Tags

    function getPromoted(locations) {
      let promotedLocations = [];
      let promotedLocationIds = [];
      if (locations !== undefined) {
        locations.forEach((location) => {
          if (tagCat === 'tagsCuisine') {
            if (category === 'food' || category === 'food') {
              promotedLocations.push(location);
            }
          }
        });
      }

      return promotedLocations;
    }

    let promotedLocations = [];
    let i = 0;

    while (promotedLocations.length === 0 && i !== 10) {
      promotedLocations = getPromoted(locations);
      i++;
    }

    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
    // let shuffledLocations = shuffleArray(promotedLocations);
    let shuffledLocations = promotedLocations;
    let topThree = [];
    let topThreeIds = [];

    shuffledLocations.forEach((location) => {
      if (topThree.length !== 10) {
        const status = topThreeIds.includes(location.locationId);
        if (status === false) {
          topThreeIds.push(location.locationId);
          topThree.push(location);
        }
      }
    });
    let seeMore = false;
    if (shuffledLocations.length > 10) {
      seeMore = true;
    }

    let bundle = {
      promotedLocations: topThree,
      setTag,
      seeMore,
    };

    return bundle;
  }

  getCategories(cattag) {
    const categories = [];

    if (
      this.props.category === 'Music' ||
      this.props.category === 'music'
    ) {
      this.props.tags.forEach((cattag) => {
        if (
          // cattag.sid === 'entertainmentType' ||
          cattag.sid === 'genre'
        ) {
          const tags = cattag.tags;
          tags.forEach((tag) => {
            let tagBundle = {};
            if (tag.plural !== undefined) {
              tagBundle.category = tag.plural;
            } else {
              tagBundle.category = tag.tag;
            }
            tagBundle.tag = tag.tag;
            categories.push(tagBundle);
          });
        }
      });
    } else if (
      this.props.category === 'Entertainment' ||
      this.props.category === 'entertainment'
    ) {
      this.props.tags.forEach((cattag) => {
        if (
          cattag.sid === 'eventType'
          // cattag.sid === 'genre'
        ) {
          const tags = cattag.tags;
          tags.forEach((tag) => {
            let tagBundle = {};
            if (tag.plural !== undefined) {
              tagBundle.category = tag.plural;
            } else {
              tagBundle.category = tag.tag;
            }
            tagBundle.tag = tag.tag;
            categories.push(tagBundle);
          });
        }
      });
    } else if (
      this.props.category === 'food' ||
      this.props.category === 'Food'
    ) {
      this.props.tags.forEach((cattag) => {
        if (
          cattag.sid === 'cuisineType'
          // cattag.sid === 'genre'
        ) {
          const tags = cattag.tags;
          tags.forEach((tag) => {
            let tagBundle = {
              type: 'Locations',
              filters: {
                tags: [
                  {
                    sid: 'cuisineType',
                    tagType: 'tagsCuisine',
                    tags: [tag.tag],
                    zones: [],
                    zips: [],
                    cities: [],
                  },
                ],
              },
              visibility: true,
              title: tag.tag,
            };
            // if (tag.plural !== undefined) {
            //   tagBundle.category = tag.plural;
            // } else {
            //   tagBundle.category = tag.tag;
            // }
            // tagBundle.tag = tag.tag;

            categories.push(tagBundle);
          });
        }
      });
    }
    return categories;
  }

  setTags(tags, locations) {
    let filteredTags = this.filterTags(tags, locations);
    let updatedTags = filteredTags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = 1;
        if (this.props.user.authenticated === true) {
          // status = this.getTagStatus(tag.tag);
        }
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let drinks = {
    //   checked: false,
    //   parentindex: 6,
    //   status: 1,
    //   tag: 'Drinks',
    //   tagindex: updatedTags[6].tags.length,
    // };

    let food = {
      checked: false,
      parentindex: 1,
      status: 1,
      tag: 'Food',
      tagindex: updatedTags[1].tags.length,
    };

    // updatedTags[6].tags.push(drinks);
    updatedTags[1].tags.push(food);
    updatedTags.forEach((tagcat, x) => {
      updatedTags[x].tags = updatedTags[x].tags.sort((a, b) =>
        a.tag.localeCompare(b.tag),
      );
      updatedTags[x].tags = updatedTags[x].tags.reverse();
      updatedTags[x].tags.map((tag, y) => {
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    // let sortedTags = []
    // updatedTags.forEach((tagcat, x) => {
    //   sortedTags = _.sortBy(updatedTags[x].tags, o => o.status);
    // }

    let origionalTags = [];
    updatedTags.forEach((tagcat) => {
      origionalTags.push(tagcat);
    });

    this.setState({
      tagFilters: updatedTags,
      origionalTags,
    });
    this.setTagFilters(updatedTags);
  }

  filterTags(tagFilters, locations) {
    let updatedTagFilters = [
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
      {
        id: '5vhCJSC7VgBE1MFNctgY',
        parent: 'Location Type',
        sid: 'locationType',
        tags: [],
      },
      {
        id: '7ayttBEiZgiM5TZ2iYsM',
        parent: 'Cuisine Type',
        sid: 'cuisineType',
        tags: [],
      },
      {
        id: '7kJeYaz9m5q434zO28Pg',
        parent: 'Setting Type',
        sid: 'setting',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Experience Type',
        sid: 'experienceType',
        tags: [],
      },
      {
        id: 'BW3qPhXbN7C4XMXWh4tv',
        parent: 'Date Type',
        sid: 'dateType',
        tags: [],
      },
      {
        id: 'CSKtI08vSBtyqKr10Nef',
        parent: ' Dietary Must Haves',
        sid: 'dietary',
        tags: [],
      },
      {
        id: 'F7R2nsOyZTiqKbW6AhFQ',
        parent: 'Hours',
        sid: 'hours',
        tags: [],
      },
      {
        id: 'xeytdS8Xwjlou67MVbVY',
        parent: 'Beverages',
        sid: 'beverages',
        tags: [],
      },
      {
        id: 'rxsnb1c2XX8c4MpugeEP',
        parent: 'Genre',
        sid: 'genre',
        tags: [],
      },
      {
        id: 'Ap7TyGtbBRDlMX5iK6r1',
        parent: 'Entertainment Type',
        sid: 'entertainmentType',
        tags: [],
      },
      {
        id: 'eAVFUFVivBObckEaKiRR',
        parent: 'Event Type',
        sid: 'eventType',
        tags: [],
      },
      {
        id: '1qSeY7pg5lEEGx7k9v17',
        parent: 'Activity Type',
        sid: 'activityType',
        tags: [],
      },
    ];
    let allTags = [];
    locations.forEach((location) => {
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      // if (location.tagsSettings !== undefined) {
      //   location.tagsSettings.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      // if (location.tagsHours !== undefined) {
      //   location.tagsHours.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsEventType !== undefined) {
      //   location.tagsEventType.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsDateType !== undefined) {
      //   location.tagsDateType.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsLocationType !== undefined) {
      //   location.tagsLocationType.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsMain !== undefined) {
      //   let status = allTags.includes(location.tagsMain);
      //   if (status === false) {
      //     allTags.push(location.tagsMain);
      //   }
      // }
    });
    if (tagFilters != undefined) {
      tagFilters.forEach((tagx, indexA) => {
        if (tagx.tags !== undefined) {
          tagx.tags.forEach((tag, indexB) => {
            let status = allTags.includes(tag.tag);
            if (status === true) {
              updatedTagFilters[indexA].tags.push(tag);
            }
          });
        }
      });
    }

    return updatedTagFilters;

    // this.setTagFilters(updatedTagFilters);
  }

  clearTagFilters(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    this.setState({
      tagFilters: updatedTags,
    });
    window.scrollTo(0, 0);
    this.searchUpdated('');
  }

  getSelectOptions() {
    let locations = [];
    if (
      this.props.locations !== undefined &&
      this.props.locations.length !== 0
    ) {
      locations = this.props.locations;
    } else if (
      this.props.authLocations !== undefined &&
      this.props.authLocations.length !== 0
    ) {
      locations = this.props.authLocations;
    } else if (
      this.props.locations !== undefined &&
      this.props.locations.length !== 0
    ) {
      locations = this.props.locations;
    }

    let allTags = [];

    locations.forEach((location) => {
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          let status = allTags.includes(tag);
          if (status === false) {
            allTags.push(tag);
          }
        });
      }

      if (location.tagsMain !== undefined) {
        let status = allTags.includes(location.tagsMain);
        if (status === false) {
          allTags.push(location.tagsMain);
        }
      }
    });

    let selectOptions = [];
    this.state.tagFilters.forEach((tagx, indexA) => {
      tagx.tags.forEach((tag, indexB) => {
        let status = allTags.includes(tag.tag);
        if (status === true) {
          selectOptions.push({
            value: tag,
            label: tag.tag,
          });
        }
      });
    });

    let reversedOptions = selectOptions.reverse();
    // this.setTagFilters(updatedTagFilters);
    return reversedOptions;
  }

  // Filter Functions
  openFilterModal(user) {
    this.setState({ filterModalVisible: true });
  }

  closeFilterModal = () => {
    this.searchUpdated('');
    window.scrollTo(0, 0);
    this.setState({ filterModalVisible: false });
  };

  setTagFilters = (tagFilters) => {
    this.setState({ tagFilters });
  };

  setDistanceFilter = (value) => {
    this.setState({
      distanceFilterState: value,
    });
  };

  setMoodModalState(status) {
    this.setState({
      moodSet: status,
    });
    this.searchUpdated('');
  }

  setFirstFilter(tag) {
    let updatedTags = this.state.tagFilters;
    updatedTags[tag.parentindex].tags[tag.tagindex].checked = true;
    this.setState({
      tagFilters: updatedTags,
    });
    this.setTagFilters(updatedTags);
    this.searchUpdated(this.state.searchTerm);
    this.clearLanding(false);
    this.updateUauth(updatedTags);
  }

  getGeoLocation = (locationsx) => {
    let self = this;
    let locations = [];
    if (
      this.props.locations !== undefined &&
      this.props.locations.length !== 0
    ) {
      locations = this.props.locations;
    } else if (
      this.props.authLocations !== undefined &&
      this.props.authLocations.length !== 0
    ) {
      locations = this.props.authLocations;
    } else if (
      this.props.locations !== undefined &&
      this.props.locations.length !== 0
    ) {
      locations = this.props.locations;
    }
    let currentLongitude = '';
    let currentLatitude = '';
    this.getPosition()
      .then((position) => {
        if (position.coords) {
          self.setState({
            currentLongitude: position.coords.longitude,
            currentLatitude: position.coords.latitude,
            error: null,
          });
          currentLatitude = position.coords.latitude;
          currentLongitude = position.coords.longitude;
          this.addDistance(
            locations,
            currentLongitude,
            currentLatitude,
          );
          this.searchUpdated('', locationsx);
        } else {
          alert('Geolocation is not supported by this browser.');
        }
      })
      .catch((err) => {
        console.error('something wrongg', err.message);
        self.setState({
          locationServices: false,
        });
      });
  };

  getPosition() {
    let options = {
      timeout: 50000,
      maximumAge: 10000,
      enableHighAccuracy: true,
    };
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          options,
        ),
      );
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }

  addDistance(locations, currentLongitude, currentLatitude) {
    const locationsArray = [];
    locations.forEach((location) => {
      const locLat = location.address.latitude;
      const locLong = location.address.longitude;
      const distance = geolib.getDistance(
        { latitude: locLong, longitude: locLat },
        { latitude: currentLatitude, longitude: currentLongitude },
      );
      // console.log("geolibtard", distance);
      const miles = geolib.convertUnit('mi', distance, 2); // -> 14,21
      location.address.distance = miles;

      locationsArray.push(location);
    });
    const sortedLocations = this.sortAndWeight(
      locations,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );

    this.updateUauthGuide(locationsArray, sortedLocations);

    this.setState({
      locationsWithDistance: locationsArray,
      filteredArray: sortedLocations,
      loading: false,
      locationServices: true,
    });
  }

  sortAndWeight(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    const sortedLocations = this.sortLocations(
      locations,
      distanceFilterState,
      currentLongitude,
      currentLatitude,
      sortState,
    );
    // const weightedLocations = this.getWeightedLocations(
    //   sortedLocations,
    //   this.props.profile,
    // );

    return sortedLocations;
  }

  sortLocations(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    if (sortState === 1) {
      const locationsArray = [];
      locations.forEach((location) => {
        const miles = location.address.distance;
        if (location.type !== 1) {
          if (distanceFilterState === 0) {
            if (miles <= 50) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 1) {
            if (miles <= 10) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 2) {
            locationsArray.push(location);
          }
        }
      });
      return _.sortBy(locationsArray, (o) => o.address.distance);
    } else {
      return _.sortBy(locations, (o) => o.locationTitle);
    }
  }

  searchUpdated(term, locationsx) {
    let locationsWithDistance = [];

    if (this.props.user.authenticated === false) {
      if (this.props.locations.length !== 0) {
        locationsWithDistance = this.props.locations;
      }
    } else {
      locationsWithDistance = this.props.authLocations;
    }

    if (locationsx !== undefined) {
      let bundle = {
        locations: locationsx[0],
      };
      this.props.setLocations(bundle);
    }
    const filteredList = locationsWithDistance.filter(
      createFilter(term, KEYS_TO_FILTERS),
    );

    const filteredPromos = this.state.promos.filter(
      createFilter(term, KEYS_TO_FILTERS),
    );

    let filteredLocations = [];
    const sortedLocations = this.sortAndWeight(
      filteredList,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );

    let selectedTags = [];

    let tagFilters = this.state.tagFilters;
    if (this.props.uauth.guide.tagFilters.length !== 0) {
      tagFilters = this.props.uauth.guide.tagFilters;
    }

    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });

    if (selectedTags.length > 0) {
      filteredLocations = this.updateLocations(
        tagFilters,
        sortedLocations,
      );
    } else {
      filteredLocations = sortedLocations;
    }

    if (filteredLocations.length === 0) {
      this.setDistanceFilter(2);
      const sortedLocationsx = this.sortAndWeight(
        filteredList,
        2,
        this.state.currentLongitude,
        this.state.currentLatitude,
        this.state.sortState,
      );

      if (selectedTags.length > 0) {
        filteredLocations = this.updateLocations(
          tagFilters,
          sortedLocationsx,
        );
      } else {
        filteredLocations = sortedLocationsx;
      }
    }

    this.setState({
      searchTerm: term,
      filteredArray: filteredLocations,
      filteredPromos: filteredPromos,
    });
  }

  updateLocations(tags, locations) {
    // This will have every location but with its tags put into a single list
    const locationsReady = [];
    // For each location we push all of its tags into the allTags array
    locations.forEach((location) => {
      const newLocation = location;
      const allTags = [];
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsSettings !== undefined) {
        location.tagsSettings.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsLocationType !== undefined) {
        location.tagsLocationType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      newLocation.tags = allTags;

      locationsReady.push(newLocation);
    });
    // const dif = _.differenceWith(cuisine, beverages, _.isEqual);
    // const filtered = _.filter(coll, item => _.some(filter, key => _.includes(item.tags, key)));

    // Filter Locations Based On Selected Categories
    function filterByMultipleFields(coll, filter) {
      // Organize Tags into their categories
      const tagsBeverages = [];
      const tagsSettings = [];
      const tagsCuisine = [];
      const tagsHours = [];
      const tagsDateType = [];
      const tagsLocationType = [];

      let beverages = [];
      let locationType = [];
      let setting = [];
      let type = [];
      let cuisine = [];
      let hours = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Hours') {
            tagsHours.push(tag.tag);
          } else if (tagcat.parent === 'Beverages') {
            tagsBeverages.push(tag.tag);
          } else if (tagcat.parent === 'Date Type') {
            tagsDateType.push(tag.tag);
          } else if (tagcat.parent === 'Cuisine Type') {
            tagsCuisine.push(tag.tag);
          } else if (tagcat.parent === 'Setting Type') {
            tagsSettings.push(tag.tag);
          } else if (tagcat.parent === 'Location Type') {
            tagsLocationType.push(tag.tag);
          }
        });
      });

      filter.forEach((tag) => {
        if (tagsBeverages.includes(tag) === true) {
          beverages = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsBeverages, key),
            ),
          );
        } else if (tagsDateType.includes(tag) === true) {
          type = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsDateType, key),
            ),
          );
        } else if (tagsLocationType.includes(tag) === true) {
          locationType = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsLocationType, key),
            ),
          );
        } else if (tagsSettings.includes(tag) === true) {
          setting = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsSettings, key),
            ),
          );
        } else if (tagsHours.includes(tag) === true) {
          hours = _.filter(locationsReady, (item) =>
            _.some(filter, (key) => _.includes(item.tagsHours, key)),
          );
        } else if (tagsCuisine.includes(tag) === true) {
          cuisine = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsCuisine, key),
            ),
          );
        }
      });
      const filtered = _.filter(coll, (item) =>
        _.some(filter, (key) => _.includes(item.tags, key)),
      );
      const arrays = [
        cuisine,
        beverages,
        type,
        setting,
        hours,
        locationType,
      ];
      const result = _.spread(_.union)(arrays);
      return result;
    }
    const filter = this.selectedTags(tags);
    const result = filterByMultipleFields(locationsReady, filter);

    return result;
    // if (this.props.selectedCategories.length > 0) {
    //   this.setState({
    //     filteredLocations: result,
    //   });
    // } else {
    //   this.setState({
    //     filteredLocations: locations,
    //   });
    // }
  }

  selectedTags(tagFilters) {
    let selectedTags = [];
    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });
    return selectedTags;
  }

  getCurrentDistance(filter) {
    let text = '';
    if (filter === 0) {
      text = '50';
    } else if (filter === 1) {
      text = '10';
    } else {
      text = '100+';
    }
    return text;
  }

  selectChange = (values) => {
    // let updatedTags = this.state.tagFilters;
    // updatedTags[values.value.parentindex].tags[
    //   values.value.tagindex
    // ].checked = true;
    // this.setState({
    //   tagFilters: updatedTags,
    // });
    // this.setTagFilters(updatedTags);
    // this.searchUpdated(this.state.searchTerm);
    // this.clearLanding(false);
    // this.updateUauth(updatedTags);

    this.props.history.push('/guide/category/' + values.label);
  };

  render() {
    let count = this.props.count;
    let selectOptions = this.getSelectOptions();
    let categories = this.getCategories();

    let locations = [];

    if (
      this.props.locations !== undefined &&
      this.props.locations.length !== 0
    ) {
      locations = this.props.locations;
    } else if (
      this.props.authLocations !== undefined &&
      this.props.authLocations.length !== 0
    ) {
      locations = this.props.authLocations;
    } else if (
      this.props.locations !== undefined &&
      this.props.locations.length !== 0
    ) {
      locations = this.props.locations;
    }

    const bundle = this.findPromotedLocations(
      locations,
      this.props.category,
      this.props.tagCat,
    );

    let promotedLocations = bundle.promotedLocations;

    return (
      <div>
        <div>
          <div>
            <DailyLocationSpotlight location={promotedLocations[0]} />
          </div>
        </div>
        <div className="homeMoodBar">
          <Row style={{ margin: '0px 0px 0px 0px' }}>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              // className="no-padding"
              style={{ padding: '5px 5px 5px 5px' }}
            >
              <div
                style={{
                  width: '80%',
                  maxWidth: '400px',
                  margin: '0px auto',
                }}
              >
                <Select
                  value={this.state.selectInput}
                  closeMenuOnSelect={true}
                  blurInputOnSelect={false}
                  onChange={(e) => this.selectChange(e, 0, 'one')}
                  options={selectOptions}
                  isMulti={false}
                  placeholder={'What are you in the mood for?'}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ background: 'whitesmoke', marginTop: '5px' }}>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              // className="no-padding"
              style={{ padding: '5px 5px 5px 5px' }}
            >
              {/* <SearchInput
                  className="search-input guide-search"
                  onChange={this.searchUpdated}
                  placeholder="All Locations"
                  value={searchTerm}
                />
                <a
                  onClick={() => {
                    this.clearLanding(false);
                  }}
                  className="pointer"
                >
                  <MaterialIcon
                    icon="search"
                    size={45}
                    color="#ffffff"
                  />
                </a> */}
              <div
                style={{
                  textAlign: 'center',
                }}
                id="menu"
              >
                <ul>
                  {/* <Link
                      to={{
                        pathname: '/guide/category/Music',
                      }}
                      className="white"
                    >
                      <li>
                        <span>Music</span>
                      </li>
                    </Link> */}
                  {/* <Link
                      to={{
                        pathname: '/guide/category/wine',
                      }}
                      className="white"
                    >
                      <li>
                        <span>Wine</span>
                      </li>
                    </Link> */}

                  <Link
                    to={{
                      pathname: '/guide/category/breakfast',
                    }}
                    // className="white"
                  >
                    <li>
                      <span>Breakfast</span>
                    </li>
                  </Link>
                  <Link
                    to={{
                      pathname: '/guide/category/brunch',
                    }}
                    // className="white"
                  >
                    <li>
                      <span>Brunch</span>
                    </li>
                  </Link>

                  <Link
                    to={{
                      pathname: '/guide/category/Happy-Hour',
                    }}
                    // className="white"
                  >
                    <li>
                      <span>Happy Hour</span>
                    </li>
                  </Link>
                  <Link
                    to={{
                      pathname: '/guide/category/intimate',
                    }}
                    // className="white"
                  >
                    <li>Date Night</li>
                  </Link>

                  <Link
                    to={{
                      pathname: '/guide/category/cocktails',
                    }}
                    // className="white"
                  >
                    <li>
                      <span>Cocktails</span>
                    </li>
                  </Link>

                  <Link
                    to={{
                      pathname: '/guide/category/coffee',
                    }}
                    // className="white"
                  >
                    <li>
                      <span>Coffee</span>
                    </li>
                  </Link>
                </ul>
              </div>
            </Col>
            {/* <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    // className="no-padding"
                    style={{ padding: '5px 5px 5px 5px' }}
                  >
                    <a
                      onClick={() => {
                        this.clearLanding(false);
                      }}
                      className="pointer"
                    >
                      <MaterialIcon
                        icon="search"
                        size={45}
                        color="#ffffff"
                      />
                    </a>
                  </Col> */}
          </Row>
        </div>
        <div className="homeSectionAll">
          <div>
            {categories.map((category, index) => (
              <GuideCategory
                key={index + 1}
                category={category}
                type="Locations"
                locations={locations}
                timedEvents={this.props.timedEvents}
                count={this.props.count}
                catTitle={category.title}
                addLocation={this.props.addLocation}
                editMode={this.props.editMode}
                customEvent={this.props.customEvent}
                addEntertainment={this.props.addEntertainment}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
  };
};

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    locations: state.locations.locations,
    authLocations: state.locations.locations,
    tags: state.tags.tags,
    uauth: state.unauth,
    user: state.user,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GuideDestinationsAll),
);
