import * as action from './actions';

export const getNewEvent = (id) => {
  return {
    type: action.GET_NEWEVENT,
    payload: id,
  };
};

export const setNewEvent = (ne) => {
  return {
    type: action.SET_NEWEVENT,
    payload: ne,
  };
};

export const resetNewEvent = (id) => {
  return {
    type: action.RESET_NEWEVENT,
    payload: id,
  };
};

export const updateNewEvent = (payload) => {
  return {
    type: action.UPDATE_NEWEVENT,
    payload: payload,
  };
};

const ACTION_HANDLERS = {
  [action.SET_NEWEVENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_NEWEVENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.RESET_NEWEVENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.UPDATE_NEWEVENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  connectFirst: false,
  planFirst: false,
  connections: [],
  clear: true,
  invitedUsers: {},
  step: 1,
  favoriteLocations: [],
  fromFavorite: false,
  fromVEvent: false,
  vEvent: {},
  customEvent: {},
  singleLocation: {},
  fromLocation: false,
  weightedLocations: [],
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
