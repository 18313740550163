import copy from 'copy-to-clipboard';
import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserAvatar from 'react-user-avatar';
import { Col, Row } from 'reactstrap';
// Components

const query = require('array-query');

class GuestListItem extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  getContent() {
    if (
      this.props.guest.user.newUser !== undefined &&
      this.props.guest.user.newUser !== false
    ) {
      const link =
        'https://www.trevel.com/invite/' + this.props.guest.userId;
      return (
        <div>
          <div>
            <span className="userName">Not signed up yet.</span>
          </div>
          <div style={{ marginTop: '10px' }}>
            <a
              onClick={() => {
                copy(link), this.props.confirmCopy();
              }}
            >
              <MaterialIcon
                icon="file_copy"
                size={20}
                color="#ee0059"
                onClick={() => {
                  copy(link), this.props.confirmCopy();
                }}
              />
              <span
                className="userName"
                style={{
                  color: 'rgb(238, 0, 89)',
                  top: '-5px',
                  position: 'relative',
                  marginLeft: '5px',
                }}
              >
                {link}
              </span>
            </a>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'inline-block' }}>
          <span className="userName">
            @{this.props.guest.userName}
          </span>
          {this.props.guest.user.connected === false && (
            <span
              style={{
                display: 'block',
                clear: 'both',
                fontSize: '14px',
                color: 'red',
              }}
            >
              Not Connected
            </span>
          )}
        </div>
      );
    }
  }

  render() {
    const link =
      'https://www.trevel.com/invite/' + this.props.guest.userId;
    return (
      <Row className="no-margin oddeven">
        <Col xs={10} md={10} sm={10} lg={10} className="no-padding">
          <div
            style={{
              minHeight: '60px',
              width: '60px',
              float: 'left',
            }}
          >
            <UserAvatar
              size="50"
              name={this.props.guest.fullName}
              src={this.props.guest.profilePhotoPath}
            />
          </div>
          {/* </Col>
        <Col xs={8} md={8} sm={8} lg={8}> */}
          <div>
            <span className="">
              {this.props.plannerId !== this.props.guest.userId
                ? this.props.guest.fullName
                : 'You'}
            </span>
            <br />
            {this.getContent()}
          </div>
        </Col>
        <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
          {this.props.guest.status === 1 && (
            <div className="right text-right clear">
              <MaterialIcon
                icon="check_circle_outline"
                size={25}
                color="#0fa000"
              />
            </div>
          )}

          {this.props.guest.status === 2 && (
            <div className="right text-right clear">
              <MaterialIcon
                icon="highlight_off"
                size={25}
                color="red"
              />
            </div>
          )}

          {this.props.guest.status === 0 && (
            <div className="right text-right">
              <MaterialIcon
                icon="check_circle_outline"
                size={25}
                color="#d3d3d3"
              />
            </div>
          )}

          {/* {this.props.guest.user.newUser !== undefined &&
          this.props.guest.user.newUser !== false ? (
            <div className="clear">
              <span
                className="right"
                style={{
                  right: '0',
                  bottom: '0',
                }}
              >
                <MaterialIcon
                  icon="file_copy"
                  size={25}
                  color="#ee0059"
                  onClick={() => {
                    copy(link);
                  }}
                />
              </span>
            </div>
          ) : (
            <div className="right text-right"></div>
          )} */}

          {this.props.planner === true &&
          this.props.plannerId !== this.props.guest.userId ? (
            <div
              className="clear"
              style={{ display: 'block', height: '25px' }}
            >
              <span
                className="right"
                style={{
                  right: '0',
                  bottom: '0',
                  marginTop: '5px',
                }}
              >
                <MaterialIcon
                  icon="delete"
                  size={25}
                  color="#ee0059"
                  onClick={() => {
                    this.props.confirmDelete(
                      this.props.guest.userId,
                      this.props.guest.fullName,
                      this.props.guest.status,
                    );
                  }}
                />
              </span>
            </div>
          ) : (
            <div
              className="clear"
              style={{ display: 'block', height: '25px' }}
            ></div>
          )}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(GuestListItem);
