import { call, put, takeLatest } from 'redux-saga/effects';
import { getVendorAwards } from '../../services/VendorServices';
import * as actions from './actions';

let defaultState = {
  events: {},
};

function* getVendorAwardedAction(action) {
  let vendorAwarded = yield call(getVendorAwards, action.payload);
  yield put({
    type: actions.SET_VENDORAWARDED,
    payload: {
      ...defaultState,
      vendorAwarded,
    },
  });
}

function* refreshVendorAwardedAction(action) {
  let awards = action;
}

export default function* sagas() {
  yield takeLatest(actions.GET_VENDORAWARDED, getVendorAwardedAction);
  yield takeLatest(
    actions.REFRESH_VENDORAWARDED,
    refreshVendorAwardedAction,
  );
}
