import { call, put, takeLatest } from 'redux-saga/effects';
import { getTagsAndFavs } from '../../services/ConnectionsTagsAndFavsServices';
import * as actions from './actions';
import { push } from 'connected-react-router';
import { updateLocations } from '../locationsSaga/reducer';

const query = require('array-query');

let defaultState = {
  awards: {
    status: 0,
  },
};

function* getTagsAndFavsAction(action) {
  console.log('longblond', action);
  let tagsAndFavs = yield call(getTagsAndFavs, action);
  let favArray = [];
  let locations = [];
  if (
    action.payload[0] !== undefined &&
    action.payload[0].locations !== undefined
  ) {
    locations = action.payload[0].locations;
  } else {
    locations = action.payload.locations;
  }
  tagsAndFavs.forEach(function (user) {
    if (
      user !== undefined &&
      user.favorites !== undefined &&
      user.favorites.length !== 0
    ) {
      user.favorites.forEach((fav) => {
        const locationDetails = query('locationId')
          .is(fav)
          .on(locations);
        if (
          locationDetails !== undefined &&
          locationDetails.length === 0
        ) {
          favArray.push(fav);
        }
      });
    }
  });
  if (favArray.length !== 0) {
    let destination = 0;
    // let destination = 1;
    const bundle = { favArray, locations, destination };
    yield put({
      type: actions.SET_TAGSANDFAVS,
      payload: {
        ...defaultState,
        connectionsTagsAndFavs: tagsAndFavs,
      },
    });
    yield put(updateLocations(bundle));
  } else {
    yield put({
      type: actions.SET_TAGSANDFAVS,
      payload: {
        ...defaultState,
        connectionsTagsAndFavs: tagsAndFavs,
      },
    });
  }

  if (
    action.payload.contactProfile !== undefined &&
    action.payload.contactProfile === true
  ) {
    // yield put(push('/connections/profile/' + ''));
  } else if (
    action.payload.homePage !== undefined &&
    action.payload.homePage === true
  ) {
    // yield put(push('/connections/profile/' + ''));
  } else if (
    action.payload[0] !== undefined &&
    action.payload[0].edit !== undefined &&
    action.payload[0].edit === true
  ) {
  } else {
    yield put(push('/event/create/home/1'));
  }
}

function* resetTagsAndFavsAction(action) {
  yield put({
    type: actions.SET_TAGSANDFAVS,
    payload: {
      connectionsTagsAndFavs: {
        status: 0,
      },
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_TAGSANDFAVS, getTagsAndFavsAction);
  yield takeLatest(actions.RESET_TAGSANDFAVS, resetTagsAndFavsAction);
}
