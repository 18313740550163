import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import VendorMenu from '../../components/VendorMenu';
import VendorEventListItemEdit from '../../components/VendorEventListItemEdit';
import firebase from '../../config/firebase';
// Reducers
import Select from 'react-select';
import { getMode } from '../../sagas/setModeSaga/reducer';
import { clearUauth } from '../../sagas/unauthenticatedSaga/reducer';
import { filter } from 'lodash';
const KEYS_TO_FILTERS = ['eventTitle', 'address.city'];

const query = require('array-query');

var options = {
  enableHighAccuracy: true,
  timeout: 10,
  maximumAge: 0,
};
function success(pos) {
  console.log('suceess!');
  var crd = pos.coords;

  console.log('Your current position is:');
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);
}

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

export class VendorTimedEventsPromos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
      searchTerm: '',
      loading: false,
      promos: [],
      vendorLocations: [],
      sortMode: 'dateDown',
      entertainmentTitle: '',
    };
    this.showPicker = this.showPicker.bind(this);
    this.changeSortMode = this.changeSortMode.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    document.title = 'Promotions';
    this.props.clearUauth();
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }

  componentDidMount() {}

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getLocations() {
    const docArray = [];
    this.props.vendor.locations.forEach((location) => {
      if (this.props.vendor.vendorId !== 'KZry0625YQCjZV99ZZh1') {
        const docDetails = query('locationId')
          .is(location)
          .on(this.props.alllocations);
        if (docDetails[0] !== undefined) {
          docArray.push(docDetails[0]);
        }
      } else {
        const docDetails = query('locationId')
          .is(location)
          .on(this.props.demo.demoLocations);
        if (docDetails[0] !== undefined) {
          docArray.push(docDetails[0]);
        }
      }
    });
    return docArray;
  }

  changeSortMode(mode) {
    this.setState({
      sortMode: mode,
    });
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">{title}</span>

              <span className="helpButton mobileHide right">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right mobileHide">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
              <div className="saveButtons mobileHide">
                <Link
                  to={{
                    pathname: '/vendor/timedevent/add/promo',
                  }}
                  className="right"
                >
                  <span className="saveButton">New Promotion</span>
                </Link>

                {/* <span
                    className="saveButton"
                    onClick={() => {
                      this.deactivate(this.props.vendorTimedEvents);
                    }}
                  >
                    Deactivate
                  </span> */}
              </div>

              <div className="right mobileShow">
                <Link
                  to={{
                    pathname: '/vendor/timedevent/add/promo',
                  }}
                  className="right"
                >
                  <span className="saveButton">New Promo</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getSelectOptions() {
    let selectOptions = [];
    this.props.entertainment.forEach((entertainment) => {
      selectOptions.push({
        value:
          entertainment.entertainmentTitle +
          '/' +
          entertainment.entertainmentId,
        label: entertainment.entertainmentTitle,
      });
    });
    return selectOptions;
  }

  selectChange = (values) => {
    const text = values.value.split('/', 5);

    this.setState({
      entertainmentTitle: text[0],
      entertainmentId: text[1],
      selectInput: { value: text[0], label: text[0] },
    });
  };

  clearSearch = () => {
    this.setState({
      entertainmentTitle: '',
      entertainmentId: '',
      selectInput: { value: null, label: '' },
    });
  };

  filteredEvents(events, entertainmentTitle) {
    let promoType = [
      'Food & Beverage Event',
      'Happy Hour',
      'Happy Hour Event',
    ];
    let eventsArray = [];

    events.forEach((event) => {
      event.tagsEventType.forEach((type) => {
        let status = promoType.includes(type);

        if (status === true) {
          eventsArray.push(event);
        }
        // else {
        //   event.entertainment.headliners.forEach((entertainment) => {
        //     if (
        //       entertainment.entertainmentTitle === entertainmentTitle
        //     ) {
        //       eventsArray.push(event);
        //     }
        //   });
        // }
      });
    });

    // if (eventsArray.length === 0) {
    //   eventsArray = events;
    // }
    return eventsArray;
  }

  getAppContent(vendorTimedEvents, sortMode, entertainmentTitle) {
    let filteredEvents = this.filteredEvents(
      vendorTimedEvents,
      entertainmentTitle,
    );
    const filteredLocations = filteredEvents.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    let selectOptions = this.getSelectOptions();
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuNew mobileHide">
          <VendorMenu popupMenu={false} />
        </div>
        <div id="vendorScreenBody">
          <div style={{ padding: '10px', margin: '0px auto' }}>
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
            {/* {this.props.profile.userType !== 3 && ( */}
            {this.props.profile.userType === 1 && (
              <div style={{ marginBottom: '10px' }}>
                <div style={{ float: 'right', padding: '10px' }}>
                  <a
                    onClick={() => {
                      this.clearSearch();
                    }}
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    <span>Reset</span>
                  </a>
                </div>
                <div style={{ width: '90%' }}>
                  <Select
                    value={this.state.selectInput}
                    closeMenuOnSelect={true}
                    blurInputOnSelect={false}
                    onChange={(e) => this.selectChange(e, 0, 'one')}
                    options={selectOptions}
                    isMulti={false}
                    placeholder={'Filter By Entertainer'}
                  />
                </div>
              </div>
            )}
            <div>
              {filteredEvents.length > 0 ? (
                <VendorEventListItemEdit
                  events={filteredLocations}
                  edit={true}
                  editPath="/vendor/timedevent/edit/"
                  backPath="/vendor/timedpromos"
                  type={12}
                  changeSortMode={this.changeSortMode}
                  sortMode={this.state.sortMode}
                />
              ) : (
                <div style={{ marginTop: '80px', display: 'block' }}>
                  <div
                    style={{
                      padding: '30px',
                      border: '1px solid lightgrey',
                      margin: '0px auto',
                      width: '90%',
                      marginTop: '100px',
                      background: 'white',
                      textAlign: 'center',
                      maxWidth: '500px',
                    }}
                  >
                    {this.props.vendorTimedEvents.length > 0 ? (
                      <center>
                        <h3
                          style={{
                            marginBottom: '20px',
                            marginTop: '15px',
                          }}
                        >
                          Lets Add Your First Promotion!
                        </h3>
                        <p>
                          To get started just click on the{' '}
                          <span style={{ fontWeight: '600' }}>
                            New Promotion
                          </span>{' '}
                          button above.
                        </p>
                      </center>
                    ) : (
                      <center>
                        <h3
                          style={{
                            marginBottom: '20px',
                            marginTop: '15px',
                          }}
                        >
                          Lets Add Your First Promotion!
                        </h3>
                        <p>
                          To get started just click on the{' '}
                          <span style={{ fontWeight: '600' }}>
                            New Promotion
                          </span>{' '}
                          button above.
                        </p>
                      </center>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  deactivate(events) {
    const db = firebase.firestore();
    const timestamp = new Date();
    let expiredEvents = [];
    events.forEach((event, i) => {
      if (event.scheduledTime.dates.multiDates === undefined) {
        let today = moment();
        let status = moment(
          event.scheduledTime.dates.endTimestamp,
        ).isBefore(today);
        if (status === true && event.active === true) {
          expiredEvents.push(event);
        }
      }
    });

    expiredEvents.forEach((event, i) => {
      let id = event.eventId;
      console.log('expired', id);
      const docToUpdate = db.collection('vendor_events').doc(id);
      const updateDoc = docToUpdate.update({
        active: false,
        lastUpdated: timestamp,
      });
    });
  }
  searchUpdated = (term) => {
    this.setState({ searchTerm: term });
  };

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Manage Promotions')}
        <div className="vendorContent">
          {this.getAppContent(
            this.props.vendorTimedEvents,
            this.state.sortMode,
            this.state.entertainmentTitle,
          )}
        </div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
    clearUauth: () => {
      dispatch(clearUauth());
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
    vendorTimedEvents: state.vendorTimedEvents.events,
    alllocations: state.locations.locations,
    demo: state.demo,
    user: state.user,
    entertainment: state.vendorEntertainment.entertainment,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorTimedEventsPromos);
