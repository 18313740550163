import React from 'react';
import { connect } from 'react-redux';
import firebase from '../config/firebase';
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MaterialIcon from 'material-icons-react';
// Components
import VendorFeatures from '../components/VendorFeatures';
// Reducers
import { getAp } from '../sagas/setActivePackageSaga/reducer';
import { getNewEvent } from '../sagas/createEventSaga/reducer';
import { getUauth } from '../sagas/unauthenticatedSaga/reducer';
import { setFavorites } from '../sagas/favoritesSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

export class Entertainment extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      claim: false,
      entertainment: [],
      claimCode: [],
      events: [],
    };
    this.setUp = this.setUp.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setUp();
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.entertainment.entertainment.length === 0) {
      this.setUp();
    } else {
      let eventsArray = [];
      const entertainmentDetails = query('slug')
        .is(this.props.match.params.id)
        .on(this.props.entertainment.entertainment);
      let entertainment = entertainmentDetails[0];

      if (entertainment.social === undefined) {
        let entertainmentTemp = [];
        const db = firebase.firestore();
        db.collection('entertainment')
          .where('slug', '==', this.props.match.params.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              entertainmentTemp.push(doc.data());
            });
            entertainment = entertainmentTemp[0];
            let id = null;
            if (entertainment !== undefined) {
              id = entertainment.id;
            }
            this.props.timedEvents.forEach((event) => {
              if (
                event.entertainment !== undefined &&
                entertainment !== undefined
              ) {
                const status =
                  event.entertainment.entertainmentIds.includes(
                    entertainment.entertainmentId,
                  );
                if (status === true) {
                  // var todayDate = moment(new Date()).format('MM/DD/YYYY');
                  // var pastDate = moment(event.scheduledTime.dates.startDate);
                  // var dDiff = pastDate.diff(todayDate);
                  // if (dDiff > 0 && dDiff !== 0) {
                  //   eventsArray.push(event);
                  // } else if (dDiff === 0) {
                  //   eventsArray.push(event);
                  // }
                  eventsArray.push(event);
                }
              }
            });

            let sortedEventsArray = [];
            if (eventsArray !== undefined) {
              let sortedEvents = eventsArray.sort(
                (a, b) =>
                  moment(
                    a.scheduledTime.dates.startTimestamp,
                  ).valueOf() -
                  moment(
                    b.scheduledTime.dates.startTimestamp,
                  ).valueOf(),
              );
              sortedEvents.forEach((event) => {
                var todayDate = moment(new Date()).format(
                  'MM/DD/YYYY',
                );
                var pastDate = moment(
                  event.scheduledTime.dates.endDate,
                );
                var dDiff = pastDate.diff(todayDate);
                if (dDiff > 0 && dDiff !== 0) {
                  sortedEventsArray.push(event);
                } else if (dDiff === 0) {
                  sortedEventsArray.push(event);
                }
              });
            }

            if (
              this.props.unauth.referalId === null ||
              this.props.unauth.referalId === undefined
            ) {
              let newUauth = {
                claimLink: this.props.unauth.claimLink,
                claimId: this.props.unauth.claimId,
                vendorId: this.props.unauth.vendorId,
                vendorType: this.props.unauth.vendorType,
                vendorSignup: this.props.unauth.vendorSignup,
                referalId: id,
                guide: this.props.unauth.guide,
              };
              this.props.getUauth(newUauth);
            }

            this.setState({
              events: sortedEventsArray,
              entertainment: entertainment,
              loading: false,
            });
          });
      } else {
        let id = null;
        if (entertainment !== undefined) {
          id = entertainment.id;
        }
        this.props.timedEvents.forEach((event) => {
          if (
            event.entertainment !== undefined &&
            entertainment !== undefined
          ) {
            const status =
              event.entertainment.entertainmentIds.includes(
                entertainment.entertainmentId,
              );
            if (status === true) {
              // var todayDate = moment(new Date()).format('MM/DD/YYYY');
              // var pastDate = moment(event.scheduledTime.dates.startDate);
              // var dDiff = pastDate.diff(todayDate);
              // if (dDiff > 0 && dDiff !== 0) {
              //   eventsArray.push(event);
              // } else if (dDiff === 0) {
              //   eventsArray.push(event);
              // }
              eventsArray.push(event);
            }
          }
        });

        let sortedEventsArray = [];
        if (eventsArray !== undefined) {
          let sortedEvents = eventsArray.sort(
            (a, b) =>
              moment(a.scheduledTime.dates.startTimestamp).valueOf() -
              moment(b.scheduledTime.dates.startTimestamp).valueOf(),
          );
          sortedEvents.forEach((event) => {
            var todayDate = moment(new Date()).format('MM/DD/YYYY');
            var pastDate = moment(event.scheduledTime.dates.endDate);
            var dDiff = pastDate.diff(todayDate);
            if (dDiff > 0 && dDiff !== 0) {
              sortedEventsArray.push(event);
            } else if (dDiff === 0) {
              sortedEventsArray.push(event);
            }
          });
        }

        if (
          this.props.unauth.referalId === null ||
          this.props.unauth.referalId === undefined
        ) {
          let newUauth = {
            claimLink: this.props.unauth.claimLink,
            claimId: this.props.unauth.claimId,
            vendorId: this.props.unauth.vendorId,
            vendorType: this.props.unauth.vendorType,
            vendorSignup: this.props.unauth.vendorSignup,
            referalId: id,
            guide: this.props.unauth.guide,
          };
          this.props.getUauth(newUauth);
        }

        this.setState({
          events: sortedEventsArray,
          entertainment: entertainment,
          loading: false,
        });
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  checkTag(main, tag) {
    if (main !== tag && tag !== 'Food') {
      return <span className="tag-user">{tag}</span>;
    }
  }

  getTags(entertainer) {
    return (
      <div style={{ paddingTop: '10px', paddingBottom: '5px' }}>
        {entertainer.tagsGenre.map((tag, index) => (
          <Link
            to={{
              pathname: '/guide/category/' + tag,
              state: {},
            }}
          >
            <span>{this.checkTag(location.tagsMain, tag)}</span>
          </Link>
        ))}
      </div>
    );
  }

  onChange = (event) => {
    this.setState({ claimCode: event.target.value });
  };

  setUp = () => {
    // Get Packages if they are not there already

    // Get this specific locations Details and Promos if the user is NOT signed in

    let entertainment = [];
    let timedEvents = [];
    let entertainments = [];
    let entertainmentArray = [];

    if (this.props.user.authenticated === false) {
      const db = firebase.firestore();
      db.collection('entertainment')
        .where('slug', '==', this.props.match.params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            entertainment.push(doc.data());
          });
          let newUauth = {
            claimLink: this.props.unauth.claimLink,
            claimId: this.props.unauth.claimId,
            vendorId: this.props.unauth.vendorId,
            vendorType: this.props.unauth.vendorType,
            vendorSignup: this.props.unauth.vendorSignup,
            referalId: entertainment[0].id,
            guide: this.props.unauth.guide,
          };
          this.props.getUauth(newUauth);
          this.setState({
            entertainment: entertainment[0],
            path: '/home',
          });

          db.collection('vendor_events')
            .where(
              'entertainmentIds',
              'array-contains',
              entertainment[0].entertainmentId,
            )
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach(function (doc) {
                let promo = doc.data();
                timedEvents.push(doc.data());
              });

              let eventsArray = [];
              if (timedEvents !== undefined) {
                let sortedEvents = timedEvents.sort(
                  (a, b) =>
                    moment(
                      a.scheduledTime.dates.startTimestamp,
                    ).valueOf() -
                    moment(
                      b.scheduledTime.dates.startTimestamp,
                    ).valueOf(),
                );
                sortedEvents.forEach((event) => {
                  var todayDate = moment(new Date()).format(
                    'MM/DD/YYYY',
                  );
                  var pastDate = moment(
                    event.scheduledTime.dates.endDate,
                  );
                  var dDiff = pastDate.diff(todayDate);
                  if (dDiff > 0 && dDiff !== 0) {
                    eventsArray.push(event);
                  } else if (dDiff === 0) {
                    eventsArray.push(event);
                  }
                });
              }

              this.setState({
                events: eventsArray,
                loading: false,
              });
            })
            .catch(function (error) {
              console.log('Error getting documents: ', error);
            });
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
        });
    } else {
      const entertainmentDetails = query('entertainmentId')
        .is(this.props.match.params.id)
        .on(this.props.entertainment);
      let entertainment = entertainmentDetails[0];
      let eventsArray = [];

      if (entertainment.social === undefined) {
        db.collection('entertainment')
          .where('slug', '==', this.props.match.params.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              entertainment.push(doc.data());
            });
            entertainment = entertainment[0];
          });
      }

      this.props.timedEvents.forEach((event) => {
        if (
          event.entertainmentId ===
          entertainmentDetails[0].entertainmentId
        ) {
          eventsArray.push(event);
        }
      });

      let sortedEventsArray = [];
      if (eventsArray !== undefined) {
        let sortedEvents = eventsArray.sort(
          (a, b) =>
            moment(a.scheduledTime.dates.startTimestamp).valueOf() -
            moment(b.scheduledTime.dates.startTimestamp).valueOf(),
        );
        sortedEvents.forEach((event) => {
          var todayDate = moment(new Date()).format('MM/DD/YYYY');
          var pastDate = moment(event.scheduledTime.dates.endDate);
          var dDiff = pastDate.diff(todayDate);
          if (dDiff > 0 && dDiff !== 0) {
            sortedEventsArray.push(event);
          } else if (dDiff === 0) {
            sortedEventsArray.push(event);
          }
        });
      }

      let path = '/home';
      if (
        this.props.history.location.state.currentUrl === undefined
      ) {
        path = this.props.history.location.state.currentUrl;
      }

      this.setState({
        events: sortedEventsArray,
        entertainment: entertainment,
        path,
        loading: false,
      });
    }
  };

  getTitleBar() {
    let path = '/home';
    if (
      this.props.history.location.state !== undefined &&
      this.props.history.location.state.currentUrl !== undefined
    ) {
      path = this.props.history.location.state.currentUrl;
    }
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: path,
                  state: {
                    back: {
                      back: window.location.pathname,
                    },
                  },
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  {this.state.entertainment.entertainmentTitle}
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  setClaim(mode) {
    this.setState({ claim: mode });
  }

  getFooter(mobile) {
    if (this.props.location.state.type !== 35) {
      return (
        <Container className={mobile === false ? 'getGoing' : ''}>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <center>
                {this.props.user.authenticated === true && (
                  <a
                    onClick={() => {
                      this.quickEvent(this.state.location);
                    }}
                  >
                    <span className="center solidredbutton">
                      Add To Itinerary
                    </span>
                  </a>
                )}
                {this.props.user.authenticated === false && (
                  <Link
                    to={{
                      pathname: '/signup',
                      state: {
                        destination:
                          '/location/' +
                          this.state.location.locationId,
                      },
                    }}
                  >
                    <span className="center solidredbutton">
                      Add To Itinerary
                    </span>
                  </Link>
                )}
              </center>
            </Col>
          </Row>
        </Container>
      );
    }
  }

  quickEntertainment(entertainment) {
    const newEntertainment = {
      connections: [],
      planFirst: false,
      connectFirst: false,
      clear: false,
      step: 2,
      screen: 1,
      fromVEntertainment: false,
      fromPackage: true,
      vEntertainment: entertainment,
      weightedLocations: [],
    };
    this.props.getNewEntertainment(newEntertainment);
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.entertainmentHero !== ''
      ) {
        path = location.entertainmentHero;
      } else if (
        location !== undefined &&
        location.entertainmentHero !== undefined &&
        location.entertainmentHero !== ''
      ) {
        path =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
          location.entertainmentHero +
          '?alt=media';
      } else if (
        location !== undefined &&
        location.entertainmentHero === ''
      ) {
        path =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgenericmusichero.png?alt=media&token=7462811e-567a-4585-9c48-97882b9270e2';
      }
    } else if (type === 'thumb') {
      if (location !== undefined) {
        path = location.entertainmentThumb;
      } else {
        if (
          location !== undefined &&
          location.entertainmentThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.entertainmentThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  getEventImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true &&
        location.eventHero !== ''
      ) {
        path = location.eventHero;
      } else if (
        location !== undefined &&
        location.eventHero !== undefined &&
        location.eventHero !== ''
      ) {
        path =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
          location.eventHero +
          '?alt=media';
      } else {
        path =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgenericmusic.png?alt=media&token=86ae93a5-3d7b-4dea-ab01-b31f84bdaa39';
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true &&
        location.eventThumb !== ''
      ) {
        path = location.eventThumb;
      } else if (
        location !== undefined &&
        location.eventThumb !== undefined &&
        location.eventThumb !== ''
      ) {
        path =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
          location.eventThumb +
          '?alt=media';
      } else {
        path =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgenericmusicthumb.png?alt=media&token=08612126-8058-4b82-9105-d1b3be0176b7';
      }
    }
    return path;
  }

  getDate(entertainment) {
    let selectedDay = {};
    if (
      entertainment.scheduledTime !== undefined &&
      entertainment.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(
        entertainment.scheduledTime.dates.startTimestamp,
      );
    }
    let to = selectedDay._d.toLocaleDateString();
    let t = to.substr(0, to.lastIndexOf('/'));
    return t;
  }

  getWebsite(location) {
    if (location.website !== undefined && location.website !== null) {
      return (
        <div style={{ clear: 'both' }}>
          <a
            href={location.website}
            target="_blank"
            rel="noopener noreferrer"
            className="red"
          >
            <span className="left website">Visit Website</span>
          </a>
        </div>
      );
    }
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span className="mainTagClear">{location.tagsMain}</span>
      );
    }
  }

  setTitleBar() {
    let path = this.state.path;
    if (this.props.profile.profilePhotoPath !== undefined) {
      return (
        <div id="headersTop">
          <div className="backbar">
            <Row className="no-margin">
              <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
                <Link
                  to={{
                    pathname: path,
                    state: {
                      back: {
                        back: window.location.pathname,
                      },
                    },
                  }}
                  className="left"
                >
                  <MaterialIcon
                    icon="keyboard_backspace"
                    size={25}
                    color="white"
                  />
                </Link>
              </Col>
              <Col lg={8} md={8} sm={8} xs={8}>
                <center>
                  <span className="page-header">
                    Location Details
                  </span>
                </center>
              </Col>
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={2}
                className="no-padding"
              />
            </Row>
          </div>
        </div>
      );
    }
  }
  getClaimButton() {
    return (
      <a
        onClick={() => {
          this.setClaim(true);
        }}
      >
        <span
          style={{
            float: 'right',
            fontWeight: '600',
            color: '#f21d59',
          }}
        >
          Claim Page
        </span>
      </a>
    );
  }

  claimLocation(code) {
    const db = firebase.firestore();
    let location = [];
    db.collection('vendorCodes')
      .where(
        'locationId',
        '==',
        this.state.entertainment.entertainmentId,
      )
      .get()
      .then((querySnapshot) => {
        //Notice the arrow funtion which bind `this` automatically.
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          location.push(doc.data());
        });
        if (code === location[0].vendorCode) {
          let newUauth = {
            claimLink: true,
            claimId: this.state.entertainment.entertainmentId,
            vendorId: null,
            vendorType: 2,
          };
          this.props.getUauth(newUauth);
          let path = '';
          if (this.props.user.authenticated === true) {
            path =
              '/vendor/signupprofile/' +
              this.state.entertainment.entertainmentId;
          } else {
            path = '/vendor/signup/';
          }

          this.props.history.push(path);
        }

        // this.setState({
        //   location: location[0],
        //   path: '/home',
        //   loading: false,
        // });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  }

  removeEntertainmentFavorite(locationId, favorites, userId) {
    let ids = [];
    favorites.forEach((favorite) => {
      ids.push(favorite);
    });

    let update = ids;
    _.remove(update, function (e) {
      return e === locationId;
    });
    let bundle = {
      userId,
      favoriteEntertainment: update,
      type: 2,
    };
    this.props.setFavorites(bundle);
  }

  setFavorite(locationId, favorites, userId) {
    let update = [];
    favorites.forEach((favorite) => {
      update.push(favorite);
    });

    update.push(locationId);
    let bundle = {
      userId,
      favoriteEntertainment: update,
      type: 2,
    };
    this.props.setFavorites(bundle);
  }

  getEntertainmentFavoriteButton(locationId, favorites) {
    let isFavorite = _.includes(favorites, locationId);

    if (isFavorite === true) {
      return (
        <span key="stuff">
          <MaterialIcon
            icon="favorite"
            size={30}
            color="#f21d59"
            onClick={() => {
              this.removeEntertainmentFavorite(
                locationId,
                favorites,
                this.props.profile.userId,
              );
            }}
          />
        </span>
      );
    } else {
      return (
        <span key="stufff">
          <MaterialIcon
            icon="favorite"
            size={30}
            color="grey"
            onClick={() => {
              this.setFavorite(
                locationId,
                favorites,
                this.props.profile.userId,
              );
            }}
          />
        </span>
      );
    }
  }

  render() {
    let favoriteEntertainment = [];
    if (this.props.profile.favoriteEntertainment !== undefined) {
      favoriteEntertainment =
        this.props.profile.favoriteEntertainment;
    }

    if (this.state.loading) {
      return (
        <div>
          <div
            style={{
              height: '100%',
              display: 'table',
              width: '100%',
              background:
                'linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%)',
            }}
          >
            <div
              style={{
                position: 'relative',
                textAlign: 'center',
                display: 'table-cell',
                verticalAlign: 'middle',
                top: '-100px',
              }}
            ></div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'header { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer-container{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    if (this.state.claim === true) {
      return (
        <div>
          {this.props.user.authenticated === true &&
            this.setTitleBar()}
          <div
            style={{
              width: '300px',
              margin: '0px auto',
              marginBottom: '80px',
            }}
          >
            <center>
              <h4>Enter Claim Number</h4>
              <div style={{ width: '200px', margin: '0px auto' }}>
                <input
                  name="code"
                  value={this.state.claimCode}
                  onChange={this.onChange}
                  type="text"
                  placeholder=""
                  maxLength="6"
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                <center>
                  <span>
                    Don{"'"}t have your claim code? No Problem!
                    <br /> Just call us at (913) 384-7377
                  </span>
                </center>
              </div>
              {this.state.claimCode.length < 6 ? (
                <span
                  className="cancelButton"
                  style={{ float: 'inherit' }}
                >
                  Claim
                </span>
              ) : (
                <a
                  onClick={() => {
                    this.claimLocation(this.state.claimCode);
                  }}
                >
                  <span
                    className="saveButton"
                    style={{ float: 'inherit' }}
                  >
                    Claim
                  </span>
                </a>
              )}
              <a
                onClick={() => {
                  this.setClaim(false);
                }}
              >
                <span
                  className="saveButton"
                  style={{ float: 'inherit' }}
                >
                  Cancel
                </span>
              </a>
            </center>
          </div>
          <VendorFeatures persona={'2'} />

          {this.props.user.authenticated === true && (
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#mobile-nav { display: none }  .maincontent { top: 60px !important; margin-top: 0px; height: 100%} ',
              }}
            />
          )}
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;}.footer-container{display:none} .App{background-color: #fafafa;}  #authleft {display:none;} #bottomNav { display: none } .maincontent { top: 50px; height: 100%}.footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;} p{max-width: 100% !important}',
            }}
          />
        </div>
      );
    }
    return (
      <div id="package" style={{ height: '100%' }}>
        {this.props.user.authenticated === true && this.getTitleBar()}
        <Container style={{ height: '100%' }}>
          <Row className="no-margin" style={{ height: '100%' }}>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div style={{ height: '100%' }}>
                <Card style={{ height: '100%' }}>
                  <CardImg
                    top
                    width="100%"
                    src={this.getImage(
                      'hero',
                      this.state.entertainment,
                    )}
                    alt="articleHero"
                  />
                  <CardBody>
                    {this.props.location.claimed !== true &&
                      this.props.type !== 35 &&
                      this.getClaimButton()}

                    <CardTitle className="left">
                      {this.props.user.authenticated === true && (
                        <span
                          style={{
                            display: 'block',
                            left: '-3px',
                            position: 'relative',
                            float: 'left',
                            top: '0px',
                          }}
                        >
                          {this.getEntertainmentFavoriteButton(
                            this.state.entertainment.entertainmentId,
                            favoriteEntertainment,
                          )}
                        </span>
                      )}
                      {this.state.entertainment.entertainmentTitle}
                    </CardTitle>

                    <CardText>
                      <div className="card-section clear">
                        {this.getTags(this.state.entertainment)}
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.entertainment
                                .entertainmentDescription,
                          }}
                        />
                      </div>
                      <div className="card-section">
                        <Row className="no-margin">
                          <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={6}
                            className="no-padding"
                          >
                            {this.getWebsite(
                              this.state.entertainment,
                            )}
                          </Col>
                          <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={6}
                            className="no-padding"
                          />
                        </Row>
                        <Row className="no-margin">
                          <Col
                            xs={12}
                            md={12}
                            sm={12}
                            lg={12}
                            className="no-padding"
                          >
                            <div style={{ paddingTop: '10px' }}>
                              {this.state.entertainment.social !==
                                undefined &&
                                this.state.entertainment.social
                                  .twitter !== '' && (
                                  <a
                                    href={
                                      this.state.entertainment.social
                                        .twitter
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ftwitter.svg?alt=media&token=2bf6ec93-57c0-45f6-b239-ac7632f656eb"
                                      style={{
                                        width: '30px',
                                        marginRight: '5px',
                                      }}
                                    />
                                  </a>
                                )}
                              {this.state.entertainment.social !==
                                undefined &&
                                this.state.entertainment.social
                                  .facebook !== '' && (
                                  <a
                                    href={
                                      this.state.entertainment.social
                                        .facebook
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ffacebook.svg?alt=media&token=9b30b56d-84d3-4997-abb3-aff9a13539d0"
                                      style={{
                                        width: '30px',
                                        marginRight: '5px',
                                      }}
                                    />
                                  </a>
                                )}
                              {this.state.entertainment.social !==
                                undefined &&
                                this.state.entertainment.social
                                  .instagram !== '' && (
                                  <a
                                    href={
                                      this.state.entertainment.social
                                        .instagram
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Finstagram.svg?alt=media&token=18e433bf-829e-48fd-ba0d-705aa029ed29"
                                      style={{
                                        width: '34px',
                                        marginRight: '5px',
                                      }}
                                    />
                                  </a>
                                )}
                              {this.state.entertainment.social !==
                                undefined &&
                                this.state.entertainment.social
                                  .twitch !== '' && (
                                  <a
                                    href={
                                      this.state.entertainment.social
                                        .twitch
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ftwitch.svg?alt=media&token=d3a2a8c8-9a6a-419c-8925-0c14b7e11979"
                                      style={{
                                        width: '31px',
                                        marginRight: '5px',
                                      }}
                                    />
                                  </a>
                                )}
                              {this.state.entertainment.social !==
                                undefined &&
                                this.state.entertainment.social
                                  .youtube !== '' && (
                                  <a
                                    href={
                                      this.state.entertainment.social
                                        .youtube
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fyoutube.svg?alt=media&token=2fe7e085-e48a-4291-bd5b-0f9a25a020a2"
                                      style={{
                                        width: '31px',
                                        marginRight: '5px',
                                      }}
                                    />
                                  </a>
                                )}

                              {this.state.entertainment.social !==
                                undefined &&
                                this.state.entertainment.social
                                  .tiktok !== '' && (
                                  <a
                                    href={
                                      this.state.entertainment.social
                                        .tiktok
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Ftictok.svg?alt=media&token=4c47e096-6e32-4941-a3af-99af3da764dd"
                                      style={{
                                        width: '31px',
                                        marginRight: '5px',
                                      }}
                                    />
                                  </a>
                                )}
                              {this.state.entertainment.social !==
                                undefined &&
                                this.state.entertainment.social
                                  .patreon !== '' && (
                                  <a
                                    href={
                                      this.state.entertainment.social
                                        .patreon
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fpatreon.svg?alt=media&token=3f460591-a28b-466d-bd87-d09569d533ca"
                                      style={{
                                        width: '31px',
                                        marginRight: '5px',
                                      }}
                                    />
                                  </a>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardText>
                    <div>
                      <hr />
                    </div>
                    <div>
                      <Container>
                        <h5>Upcoming Events</h5>
                        {this.state.events.length > 0 ? (
                          <Row style={{ marginBottom: '30px' }}>
                            {this.state.events.map((event, index) => (
                              <Col
                                xs={6}
                                md={4}
                                sm={6}
                                lg={3}
                                className="no-padding"
                              >
                                <div
                                  style={{
                                    border:
                                      '0px solid rgba(0, 0, 0, 0.125)',
                                    background: 'white !important',
                                    marginRight: '10px',
                                  }}
                                >
                                  <Link
                                    to={{
                                      pathname:
                                        '/timedevent/' +
                                        event.eventId,
                                      state: {
                                        event: { event },
                                        // eventId: { eventId },
                                        // token: { token },
                                        type: 500,
                                        back: {
                                          back: window.location
                                            .pathname,
                                        },
                                      },
                                    }}
                                  >
                                    <CardImg
                                      top
                                      width="100%"
                                      src={this.getEventImage(
                                        'hero',
                                        event,
                                      )}
                                      alt="articleHero"
                                    />
                                    <CardBody
                                      style={{
                                        textAlign: 'left',
                                      }}
                                      className="nopadding"
                                    >
                                      <div
                                        style={{
                                          paddingTop: '10px',
                                          border: '0px',
                                        }}
                                      >
                                        <span className="title">
                                          {event.eventTitle}
                                        </span>
                                        <span className="address">
                                          @{' '}
                                          {event.address.locationName}{' '}
                                          - {this.getDate(event)}
                                        </span>

                                        {this.getMainTag(event)}
                                        {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                                        <CardText>
                                          <span className="editoOverRide">
                                            {this.truncate(
                                              event.eventDescription,
                                            )}
                                          </span>
                                        </CardText>
                                        <CardText />
                                      </div>
                                    </CardBody>
                                  </Link>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        ) : (
                          <Row style={{ marginBottom: '30px' }}>
                            <span className="title">
                              There are no Upcoming Events in your
                              area.
                            </span>
                          </Row>
                        )}
                      </Container>
                    </div>

                    {/* <div className="card-section">
                      <p>
                        This package was created by{' '}
                        {
                          this.state.entertainmentDetails.creatorInfo
                            .creatorName
                        }
                      </p>
                    </div> */}
                    <div
                      className="card-section"
                      id="package-itinerary"
                    >
                      {/* <h5 className="cartd-title">Recommended Itinerary</h5> */}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <div
          id={
            this.props.user.authenticated === true
              ? 'startBarCentered'
              : 'startBar'
          }
        ></div> */}
        {this.props.user.authenticated === true && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                'html{display:grid} #mobile-nav { display: none }  .maincontent { top: 39px !important; margin-top: 0px; height: 100%} .App{background-color: #fafafa;}',
            }}
          />
        )}
        {this.props.user.authenticated === false && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                '@media (max-width: 800px){.maincontent { top: 80px !important;}}  ',
            }}
          />
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.nopadding{padding: 0px !important} @media (max-width: 800px){.maincontent { top: 39px}} html{display:grid} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;}.footer-container{display:none} .App{background-color: #fafafa;}  #authleft {display:none;} #bottomNav { display: none } .maincontent { top: 0px; height: 100%}.footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;} p{max-width: 100% !important}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    user: state.user,
    entertainment: state.entertainment,
    profile: state.profile.profile,
    timedEvents: state.timedEvents.events,
    unauth: state.unauth,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAp: (ap) => {
      dispatch(getAp(ap));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    getUauth: (bundle) => {
      dispatch(getUauth(bundle));
    },
    setFavorites: (bundle) => {
      dispatch(setFavorites(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Entertainment);
