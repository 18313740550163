import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { push } from 'connected-react-router';

let defaultState = {
  activeEvent: {
    eventStarted: false,
  },
};

function* startEventAction(action) {
  const activeEvent = action.payload;
  yield put({
    type: actions.SET_EVENT,
    payload: {
      ...defaultState,
      activeEvent,
    },
  });
  yield put(push('/active/'));
}

function* resetEventAction(action) {
  yield put({
    type: actions.SET_EVENT,
    payload: {
      activeEvent: {
        eventStarted: false,
      },
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.START_EVENT, startEventAction);
  yield takeLatest(actions.RESET_EVENT, resetEventAction);
}
