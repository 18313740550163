import geolib from 'geolib';
import MaterialIcon from 'material-icons-react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import { Col, Container, Row } from 'reactstrap';
import FFLocations from '../components/FFLocations';
import FilterModal from '../components/FilterModal';
import LocationDetailsAdd from '../components/LocationDetailsAdd';
import GuideEventsCategoryById from '../components/GuideEventsCategoryById';
import GuideCategoryAdd from '../components/GuideCategoryAdd';
import GuideCategory from '../components/GuideCategory';
import UserAvatar from 'react-user-avatar';
import Select from 'react-select';
import whitelogo from '../images/TrevelUnderlinedWhite.svg';
// import MoodModal from "../components/MoodModal";
// Styles
import '../components/PackageListItem/PackageListItem.css';
import firebase from '../config/firebase';
import { getAllLocations } from '../sagas/allLocationsSaga/reducer';
import { getCe } from '../sagas/customEventSaga/reducer';
import { getNewEvent } from '../sagas/createEventSaga/reducer';
import { getTaRequest } from '../sagas/taRequestSaga/reducer';
import { clearTaRequest } from '../sagas/taRequestSaga/reducer';
// Reducers
import { init } from '../sagas/packagesSaga/reducer';
import { takeRightWhile } from 'lodash';
import GoogleAd from '../components/GoogleAd';
import { ImageFilterTiltShift } from 'material-ui/svg-icons';
import { ContinuousColorLegend } from 'react-vis';

const KEYS_TO_FILTERS = ['locationTitle'];
const format = 'h:mm a';

const query = require('array-query');
const _ = require('lodash');

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    maxWidth: '500px',
  },
};

export class CustomPackageAddCustomLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      filteredArray: null,
      filteredList: '',
      mode: 1,
      distanceFilterState: 2,
      sortState: 2,
      filterModalVisible: false,
      modalState: 1,
      tagFilters: [],
      showLocation: false,
      locationsWithDistance: [],
      moodSet: false,
      locationServices: true,
      recMode: 1,
      closeThree: [],
      guestListArray: [],
      moodSelected: false,
      hateMatch: {
        match: false,
        hateWord: '',
      },
      selections: 0,
      reFilterMode: false,
      guestFavLocations: null,
      otherUser: [],
      regionInputOn: false,
      selectedRegion:
        this.props.taRequest.startLocation !== 'current' &&
        this.props.taRequest.startLocation !== 'lastLocation'
          ? {
              label: this.props.taRequest.startLocation,
              value: this.props.taRequest.startLocation,
            }
          : '',
      participantsType: this.props.taRequest.startLocation,
      otherUserTagsHate: undefined,
      otherUserTagsLove: undefined,
      fullScreen: true,
      register: [],
      step: 1,
      multiLocationQuestions: false,
      userMoodYes: [],
      userMoodNo: [],
      genreType: [],
      eventTypes: [],
      displayAd: false,
      adShown: false,
      venues: [],
      nearbyRegions: [],
      weightedMusic: [],
      guideMode: 1,
      moreDining: false,
      moreEntertainment: false,
      selectedDay: null,
      Watch: false,
      Dine: false,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.addLocation = this.addLocation.bind(this);
    this.setTagFilters = this.setTagFilters.bind(this);
    this.setMoodModalState = this.setMoodModalState.bind(this);
    this.showLocationDetails = this.showLocationDetails.bind(this);
    this.addEntertainment = this.addEntertainment.bind(this);
    this.setRecMode = this.setRecMode.bind(this);
    this.changeCarousel = this.changeCarousel.bind(this);
    this.setRandomizer = this.setRandomizer.bind(this);
    this.weightMusic = this.weightMusic.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }

    Modal.setAppElement('body');
    if (this.props.custom === true) {
      this.filterCustom(this.props.alllocations);
    } else {
      // this.setState({
      //   filteredArray: this.props.alllocations
      // });
    }
    this.updateDimensions();
  }

  getGuideMode(taRequest) {
    // let newTagFilters = [];
    // if (taRequest.tagFilters !== undefined) {
    //   newTagFilters = _.cloneDeep(
    //     this.props.taRequest.tagFilters,
    //   );
    //   newTagFilters = taRequest.tagFilters;
    // }

    let guideMode = 1;

    // Set Guide Mode

    // If ONLY Watch
    if (
      taRequest.eventCats.watch === true &&
      taRequest.eventCats.eat === false
    ) {
      guideMode = 2;
    } else if (
      // If ONLY Eat
      taRequest.eventCats.watch === false &&
      taRequest.eventCats.eat === true
    ) {
      guideMode = 1;
    } else if (taRequest.eventCats.startingCat !== undefined) {
      // If Watch AND Eat

      if (taRequest.eventCats.startingCat === 2) {
        // // newTagFilters[8].tags[2] = Eat
        // newTagFilters[8].tags[2].checked = false;
        // // newTagFilters[8].tags[0] = Do
        // newTagFilters[8].tags[0].checked = true;
        // // newTagFilters[8].tags[1] = Watch
        // newTagFilters[8].tags[1].checked = true;
        guideMode = 2;
      } else {
        // newTagFilters[8].tags[0].checked = false;
        // newTagFilters[8].tags[1].checked = false;
        // newTagFilters[8].tags[2].checked = true;
        guideMode = 1;
      }
    }

    // let bundle = {
    //   tagFilters: newTagFilters,
    //   distance: taRequest.distance,
    //   startLocation: taRequest.startLocation,
    //   eventCats: taRequest.eventCats,
    //   currentLatitude: taRequest.currentLatitude,
    //   currentLongitude: taRequest.currentLongitude,
    //   closestRegion: taRequest.closestRegion,
    //   userMoodYes: taRequest.userMoodYes,
    //   userMoodNo: taRequest.userMoodNo,
    // };
    // this.props.getTaRequest(bundle);
    return guideMode;
  }

  componentDidMount() {
    let newTagFilters = [];
    if (this.props.taRequest.tagFilters !== undefined) {
      newTagFilters = this.props.taRequest.tagFilters;
    }
    if (this.props.taRequest.eventCats.startingCat !== undefined) {
      if (newTagFilters.length !== 0) {
        if (this.props.taRequest.eventCats.startingCat === 2) {
          // newTagFilters[8].tags[2] = Eat
          newTagFilters[8].tags[2].checked = false;
          // newTagFilters[8].tags[0] = Do
          newTagFilters[8].tags[0].checked = true;
          // newTagFilters[8].tags[1] = Watch
          newTagFilters[8].tags[1].checked = true;
        } else {
          newTagFilters[8].tags[0].checked = false;
          newTagFilters[8].tags[1].checked = false;
          newTagFilters[8].tags[2].checked = true;
        }
      }
    }

    let startLocation = this.props.taRequest.startLocation;
    if (
      this.props.customEvent.event.locations !== undefined &&
      this.props.customEvent.event.locations.length !== 0
    ) {
      startLocation = 'lastLocation';
      this.setState({
        participantsType: startLocation,
      });
    }

    let bundle = {
      tagFilters: newTagFilters,
      distance: this.props.taRequest.distance,
      startLocation,
      eventCats: this.props.taRequest.eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
    };
    this.props.getTaRequest(bundle);

    let allEvents = this.props.timedEvents.slice();
    this.setState({
      allEvents,
    });

    // Set Venues
    let venues = [];
    this.props.alllocations.forEach((location) => {
      if (
        location.tagsLocationType !== undefined &&
        location.tagsLocationType.includes('Entertainment Venue') ===
          true
      ) {
        venues.push(location);
      }
    });
    this.setState({
      venues,
    });

    // if region selected get nearby regions
    let nearbyRegions = [];
    if (
      this.props.taRequest.startLocation !== 'current' &&
      this.props.taRequest.startLocation !== 'lastLocation'
    ) {
      let currentRegion = {};
      this.props.regions.forEach((region) => {
        if (region.name === this.props.taRequest.startLocation) {
          currentRegion = region;
        }
      });

      this.props.regions.forEach((region) => {
        if (region.name === this.props.taRequest.startLocation) {
          this.setState({
            nearbyRegions: region.nearbyRegions,
          });
        }
      });
    } else {
      if (
        this.props.customEvent.event.locations[0] !== undefined &&
        this.props.customEvent.event.locations[0].address !==
          undefined
      ) {
        let lastRegion =
          this.props.customEvent.event.locations[0].address.region;
        this.props.regions.forEach((region) => {
          if (region.name === lastRegion) {
            this.setState({
              nearbyRegions: region.nearbyRegions,
            });
          }
        });
      } else if (
        this.props.customEvent.event.locations[0] !== undefined &&
        this.props.customEvent.event.locations[0].locationId !==
          undefined
      ) {
        const locationDetails = query('locationId')
          .is(this.props.customEvent.event.locations[0].locationId)
          .on(this.props.alllocations);
        let lastRegion = locationDetails[0].address.region;
        this.props.regions.forEach((region) => {
          if (region.name === lastRegion) {
            this.setState({
              nearbyRegions: region.nearbyRegions,
            });
          }
        });
      }
    }

    let allTagFilters = [];

    // Set GenreType and UserMoodYes
    let genreType = [];
    let eventTypes = [];
    let userMoodYes = [];

    this.props.taRequest.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          if (tagCat.sid === 'genre') {
            genreType.push({
              value: tag.tag,
              label: tag.tag,
            });
          } else if (tagCat.sid === 'eventType') {
            eventTypes.push({
              value: tag.tag,
              label: tag.tag,
            });
          } else if (
            tagCat.sid === 'beverages' ||
            tagCat.sid === 'cuisineType'
          ) {
            userMoodYes.push({
              value: tag.tag,
              label: tag.tag,
            });
          }
          let status = allTagFilters.includes(tag.tag);
          if (status === false) {
            allTagFilters.push(tag.tag);
          }
        }
      });
    });
    this.setState({
      eventTypes,
      genreType,
      userMoodYes,
    });

    // Set Date Type Filters (First Date, Casual)
    this.props.tags.forEach((tagcat) => {
      if (tagcat.sid === 'dateType') {
        tagcat.tags.forEach((tag) => {
          if (allTagFilters.includes(tag.tag) === true) {
            this.setState({
              ['type' + tag.tag]: true,
            });
          }
        });
      }
    });

    //Set UserMoodNo
    if (this.props.taRequest.userMoodNo !== undefined) {
      this.setState({
        userMoodNo: this.props.taRequest.userMoodNo,
      });
    }

    // if (this.props.taRequest.userMoodYes !== undefined) {
    //   this.setState({
    //     userMoodYes: this.props.taRequest.userMoodYes,
    //   });
    // }

    if (
      this.props.taRequest.startLocation !== 'current' &&
      this.props.taRequest.startLocation !== 'lastLocation'
    ) {
      this.setState({
        regionInputOn: true,
      });
    }

    if (
      // this.props.customEvent.event.locations.length !== 0 &&
      this.props.taRequest.tagFilters.length === 0
      // this.props.customEvent.event.locations.length !== 0
    ) {
      this.setState({
        multiLocationQuestions: true,
      });
    }

    // Scroll Top Of Page
    window.scrollTo(0, 0);

    // Setup TagsFilters and Users Tags
    if (
      this.props.newEvent !== undefined &&
      this.props.newEvent.invitedUsers !== undefined &&
      Object.keys(this.props.newEvent.invitedUsers).length !== 0 &&
      this.props.connectionsTagsAndFavs[0] !== undefined
    ) {
      if (Object.keys(this.props.taRequest.tagFilters).length > 0) {
        this.setTagFilters(this.props.taRequest.tagFilters);
      } else {
        this.setTags(this.props.tags);
      }
      this.setUsersTags(
        this.props.profile.tags.love,
        this.props.profile.tags.hate,
      );
    } else {
      if (Object.keys(this.props.taRequest.tagFilters).length > 0) {
        this.setTagFilters(this.props.taRequest.tagFilters);
      } else {
        this.setTags(this.props.tags);
      }
      this.setUsersTags(
        this.props.profile.tags.love,
        this.props.profile.tags.hate,
      );
    }

    // if (
    //   this.props.customEvent.selectedTags !== undefined &&
    //   this.props.customEvent.selectedTags !== null
    // ) {
    //   this.setTagFilters(this.props.customEvent.selectedTags);
    // }

    // Setup Guest List
    this.createGuestList(this.props.newEvent);

    // Store Users tags in store
    this.getUserTags(
      this.props.newEvent,
      this.props.users,
      this.props.profile.userId,
    );

    // Update Other User Tags
    let connections = {};
    if (this.props.newEvent.clear === false) {
      connections = this.props.newEvent.connections;
    } else {
      connections = this.props.customEvent.event.invitations;
    }
    let userId = this.props.profile.userId;
    let otherUserId = '';
    let userMatch = [];
    if (connections !== undefined) {
      Object.keys(connections).forEach((key) => {
        if (key !== userId) {
          if (
            connections[key].newUser !== undefined &&
            connections[key].newUser !== true
          ) {
            const user = query('userId').is(key).on(this.props.users);
            otherUserId = key;
            userMatch = user[0];
          } else if (
            connections[key].newUser === undefined &&
            connections[key].newUser === true
          ) {
            const user = [];
            if (user !== undefined) {
              otherUserId = key;
              userMatch = user;
            }
          } else if (this.props.newEvent.clear === true) {
            const user = this.props.customEvent.event.users[key];
            if (user !== undefined) {
              otherUserId = key;
              userMatch = user;
            }
          }
        }
      });
    }
    this.setState({
      otherUser: userMatch,
    });

    // what is this?
    if (
      this.props.newEvent.planFirst !== true &&
      this.props.newEvent.invitedUser !== undefined &&
      this.props.newEvent.invitedUser.newUser !== true &&
      otherUserId !== ''
    ) {
      const db = firebase.firestore();
      const userEvents = db.collection('users').doc(otherUserId);
      userEvents.onSnapshot(this.updateOtherUser);
    }

    // Weight Music
    if (
      this.props.taRequest.eventCats.do === true ||
      this.props.taRequest.eventCats.watch === true
    ) {
      let weightedMusic = this.getWeightedMusic(
        allEvents,
        this.props.profile,
      );
      this.setState({
        weightedMusic,
      });
    } else {
      // console.log('weightedMusicppp');
    }

    // Set Various Check Box Tags Status
    if (
      this.props.newEvent.fromLocation === true ||
      this.props.taRequest.eventCats.eat === true
    ) {
      this.setState({
        Dine: true,
      });
    }
    if (this.props.newEvent.fromEntertainment === true) {
      this.setState({
        Music: true,
        Watch: true,
      });
    }
    if (this.props.taRequest.eventCats.watch === true) {
      this.setState({
        Watch: true,
      });
    }
    if (this.props.taRequest.eventCats.do === true) {
      this.setState({
        Do: true,
      });
    }

    // Set Locations With Distance
    if (
      this.props.newEvent.weightedLocations !== undefined &&
      this.props.newEvent.weightedLocations.length !== 0
    ) {
      if (this.props.customEvent.event.locations.length === 0) {
        this.setState({
          locationsWithDistance:
            this.props.newEvent.weightedLocations,
          filteredArray: this.props.newEvent.weightedLocations,
          loading: false,
        });
        // this.searchUpdated('');
      } else {
        if (
          this.props.newEvent.reRoll !== undefined &&
          this.props.newEvent.reRoll === false
        ) {
          this.getGeoLocation(true);
        } else {
          // this.setState({
          //   locationsWithDistance:
          //     this.props.newEvent.weightedLocations,
          //   filteredArray: this.props.newEvent.weightedLocations,
          // });
          // this.searchUpdated('');
          this.getGeoLocation(true);
        }
      }
    } else {
      this.getGeoLocation(false);
    }

    window.addEventListener('resize', this.updateDimensions);

    // If Event Incluced with Date Set That Date and Lock Calendar
    let currentEvent = null;
    if (this.props.customEvent.event.locations.length > 0) {
      this.props.customEvent.event.locations.forEach(
        (location, i) => {
          if (location.event === true) {
            const eventDetails = query('id')
              .is(location.eventId)
              .on(allEvents);
            currentEvent = eventDetails[0];
          }
        },
      );

      if (currentEvent !== null) {
        if (
          (currentEvent.scheduledTime.dates.repeat !== undefined &&
            currentEvent.scheduledTime.dates.repeat === true &&
            this.props.customEvent.event.scheduledTime.date ===
              null) ||
          (currentEvent.scheduledTime.dates.multiDates !==
            undefined &&
            currentEvent.scheduledTime.dates.multiDates.length > 1 &&
            this.props.customEvent.event.scheduledTime.date === null)
        ) {
          this.reFilter(4);
        } else {
          this.reFilter(1);
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  fullScreenMode(mode) {
    window.scrollTo(0, 0);
    this.setState({
      fullScreen: mode,
    });
  }

  updateDimensions = () => {
    let width = window.innerWidth;
    let count = 2;
    if (width > 1023) {
      count = 4;
    } else if (width < 1023 && width > 767) {
      count = 3;
    }
    let finalCount = this.state.count;
    if (this.state.count !== count) {
      finalCount = count;
    }
    this.setState({
      count: finalCount,
    });
  };

  updateOtherUser = (documentSnapshot) => {
    const eventsObject = {};
    eventsObject.events = [];
    const user = documentSnapshot.data();

    let tags = undefined;
    if (user.tags !== undefined) {
      tags = user.tags;
    }

    if (tags !== undefined && tags !== null) {
      this.setState({
        otherUserTagsLove: tags.love,
        otherUserTagsHate: tags.hate,
      });
    } else {
      this.setState({
        otherUserTagsLove: undefined,
        otherUserTagsHate: undefined,
      });
    }
    this.searchUpdated('');
  };

  getUserTags(newEvent, users, userId) {
    let connections = newEvent.connections;
    let userTags = null;
    let userMatch = [];

    if (
      connections !== undefined &&
      this.props.connectionsTagsAndFavs !== undefined
    ) {
      Object.keys(connections).forEach((key) => {
        if (key !== userId) {
          if (connections[key].connected !== false) {
            if (connections[key].newUser !== true) {
              const user = query('userId')
                .is(key)
                .on(this.props.connectionsTagsAndFavs);

              userTags = user[0].tags;
              userMatch = user[0];
            }
          }
        }
      });
    }

    if (userTags !== undefined && userTags !== null) {
      this.setState({
        otherUserTagsLove: userTags.love,
        otherUserTagsHate: userTags.hate,
        otherUser: userMatch,
      });
    } else {
      this.setState({
        otherUserTagsLove: undefined,
        otherUserTagsHate: undefined,
        otherUser: userMatch,
      });
    }
  }

  openFilterModal(user) {
    this.setState({ filterModalVisible: true });
  }

  closeFilterModal = () => {
    this.searchUpdated('');
    window.scrollTo(0, 0);
    this.setState({ filterModalVisible: false });
  };

  setTagFilters(tagFilters) {
    this.setState({ tagFilters });
  }

  reRoll() {
    this.addDistance(
      this.props.alllocations,
      this.props.taRequest.currentLongitude,
      this.props.taRequest.currentLatitude,
    );
    this.searchUpdated('');
  }

  setRandomizer = (value, id) => {
    this.setState({ [id + 'randomizer']: value });
  };

  getGeoLocation = (reRoll) => {
    let self = this;
    let currentLongitude = '';
    let currentLatitude = '';

    if (this.props.taRequest.startLocation === 'current') {
      if (this.props.taRequest.currentLatitude !== null) {
        this.addDistance(
          this.props.alllocations,
          this.props.taRequest.currentLongitude,
          this.props.taRequest.currentLatitude,
          reRoll,
        );
        this.searchUpdated('');
      } else {
        this.getPosition()
          .then((position) => {
            self.setState({
              currentLongitude: position.coords.longitude,
              currentLatitude: position.coords.latitude,
              error: null,
            });

            let bundle = {
              tagFilters: this.props.taRequest.tagFilters,
              distance: this.props.taRequest.distance,
              startLocation: this.props.taRequest.startLocation,
              eventCats: this.props.taRequest.eventCats,
              currentLatitude: position.coords.latitude,
              currentLongitude: position.coords.longitude,
              closestRegion: this.props.taRequest.closestRegion,
              userMoodYes: this.props.taRequest.userMoodYes,
              userMoodNo: this.props.taRequest.userMoodNo,
            };

            currentLatitude = position.coords.latitude;
            currentLongitude = position.coords.longitude;

            this.addDistance(
              this.props.alllocations,
              currentLongitude,
              currentLatitude,
              reRoll,
            );
            this.searchUpdated('');
            this.props.getTaRequest(bundle);
          })
          .catch((err) => {
            self.setState({
              locationServices: false,
            });
          });
      }
    } else if (
      this.props.taRequest.startLocation === 'lastLocation'
    ) {
      if (this.props.taRequest.currentLatitude !== null) {
        this.addDistance(
          this.props.alllocations,
          this.props.taRequest.currentLongitude,
          this.props.taRequest.currentLatitude,
          reRoll,
        );
        this.searchUpdated('');
      } else {
        this.getPosition()
          .then((position) => {
            self.setState({
              currentLongitude: position.coords.longitude,
              currentLatitude: position.coords.latitude,
              error: null,
            });

            let bundle = {
              tagFilters: this.props.taRequest.tagFilters,
              distance: this.props.taRequest.distance,
              startLocation: this.props.taRequest.startLocation,
              eventCats: this.props.taRequest.eventCats,
              currentLatitude: position.coords.latitude,
              currentLongitude: position.coords.longitude,
              closestRegion: this.props.taRequest.closestRegion,
              userMoodYes: this.props.taRequest.userMoodYes,
              userMoodNo: this.props.taRequest.userMoodNo,
            };

            currentLatitude = position.coords.latitude;
            currentLongitude = position.coords.longitude;

            this.addDistance(
              this.props.alllocations,
              currentLongitude,
              currentLatitude,
              reRoll,
            );
            this.searchUpdated('');
            this.props.getTaRequest(bundle);
          })
          .catch((err) => {
            self.setState({
              locationServices: false,
            });
          });
      }
    } else {
      const docDetails = query('name')
        .is(this.props.taRequest.startLocation)
        .on(this.props.regions);
      this.addDistance(
        this.props.alllocations,
        docDetails[0].lat,
        docDetails[0].long,
        reRoll,
      );
      this.searchUpdated('');
    }
  };

  getPosition() {
    let options = {
      timeout: 10000,
      maximumAge: 10000,
      enableHighAccuracy: true,
    };
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          options,
        ),
      );
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }

  filterCustom(locations) {
    const filteredArray = [];
    const theselocations = locations;
    theselocations.forEach((location) => {
      if (location.custom === true) {
        filteredArray.push(location);
      }
    });
    this.setState({
      filteredArray,
    });
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  searchUpdated(term) {
    const filteredList = this.state.locationsWithDistance.filter(
      createFilter(term, KEYS_TO_FILTERS),
    );
    let filteredLocations = [];
    const sortedLocations = this.sortAndWeight(
      filteredList,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );
    let selectedTags = [];
    this.state.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });
    if (selectedTags.length > 0) {
      filteredLocations = this.updateLocations(
        this.props.tags,
        sortedLocations,
      );
    } else {
      filteredLocations = sortedLocations;
    }
    let sorted = _.orderBy(
      filteredLocations,
      ['weight'],
      ['desc', 'asc'],
    );
    this.setState({
      searchTerm: term,
      filteredArray: sorted,
      loading: false,
    });
  }

  clearTagFilters(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    this.setState({
      tagFilters: updatedTags,
    });
    window.scrollTo(0, 0);
    this.searchUpdated('');
  }

  addDistance(
    locations,
    currentLongitude,
    currentLatitude,
    reRoll,
    skipWeights,
  ) {
    this.setState({
      loading: true,
    });
    let long = currentLongitude;
    let lat = currentLatitude;

    const locationsArray = [];

    let num = 0;

    if (reRoll === true) {
      let xnum = this.props.customEvent.event.locations.length;
      if (xnum !== 0) {
        num = Number(xnum) - 1;
      }

      if (
        this.props.customEvent.event.locations[num].address
          .latitude !== null
      ) {
        long =
          this.props.customEvent.event.locations[num].address
            .latitude;
        lat =
          this.props.customEvent.event.locations[num].address
            .longitude;
      }
    }

    let totalCount = locations.length;
    let count = 0;
    if (reRoll === true) {
      count = count + 1;
    }

    locations.forEach((location) => {
      if (reRoll === true) {
        if (
          location.locationId !==
          this.props.customEvent.event.locations[num].locationId
        ) {
          const locLat = location.address.latitude;
          const locLong = location.address.longitude;
          if (locLong !== null) {
            const distance = geolib.getDistance(
              { latitude: locLong, longitude: locLat },
              { latitude: lat, longitude: long },
            );
            const miles = geolib.convertUnit('mi', distance, 2); // -> 14,21
            location.address.distance = miles;
            locationsArray.push(location);
            count = count + 1;
          } else {
            location.address.distance = 5;
            locationsArray.push(location);
            count = count + 1;
          }
        }
      } else {
        const locLat = location.address.latitude;
        const locLong = location.address.longitude;
        if (locLong !== null) {
          const distance = geolib.getDistance(
            { latitude: locLong, longitude: locLat },
            { latitude: lat, longitude: long },
          );
          const miles = geolib.convertUnit('mi', distance, 2); // -> 14,21
          location.address.distance = miles;
          locationsArray.push(location);
          count = count + 1;
        } else {
          location.address.distance = 5;
          locationsArray.push(location);
          count = count + 1;
        }
      }
    });

    let sortedLocations = [];
    // NEED TO FINISH THIS
    if (skipWeights === true) {
    } else {
      // This checks the users range filter and sorts them
      sortedLocations = this.sortAndWeight(
        locations,
        this.state.distanceFilterState,
        this.state.currentLongitude,
        this.state.currentLatitude,
        this.state.sortState,
      );
    }

    let updatedNewEvent = this.props.newEvent;
    updatedNewEvent.weightedLocations = sortedLocations;
    updatedNewEvent.step = 5;
    if (reRoll !== undefined && reRoll === true) {
      updatedNewEvent.reRoll = true;
    } else {
      updatedNewEvent.reRoll = false;
    }
    this.props.getNewEvent(updatedNewEvent);
    if (totalCount === count) {
      this.setState({
        locationsWithDistance: locationsArray,
        loading: false,
        filteredArray: sortedLocations,
      });
    }
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    var lat1 = this.toRad(lat1);
    var lat2 = this.toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(lat1) *
        Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  weightMusic(
    timedEvents,
    userTagsLove,
    userTagsHate,
    otherUserTagsLove,
    otherUserTagsHate,
    catTitle,
    from,
  ) {
    let weightedEvents = [];
    let timedEventsToday = [];
    let eventIds = [];
    let selectedTags = [];
    this.props.taRequest.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });

    // If there is a date selected, filter by dates
    if (
      this.props.customEvent.event.scheduledTime.date !== null &&
      this.props.customEvent.event.scheduledTime.date !== ''
    ) {
      // function to check if event happens in the same week.
      function isSameWeek(firstDay, secondDay, offset) {
        var firstMoment = moment(firstDay);
        var secondMoment = moment(secondDay);
        var startOfWeek = function (_moment, _offset) {
          return _moment.add(
            'days',
            _moment.weekday() * -1 +
              (_moment.weekday() >= 7 + _offset
                ? 7 + _offset
                : _offset),
          );
        };
        return startOfWeek(firstMoment, offset).isSame(
          startOfWeek(secondMoment, offset),
          'day',
        );
      }

      timedEvents.forEach((event) => {
        if (event.scheduledTime !== undefined) {
          if (
            event.scheduledTime.dates.repeat !== undefined &&
            event.scheduledTime.dates.repeat === true
          ) {
            event.scheduledTime.dates.multiDates.forEach((date) => {
              if (
                this.props.customEvent.event.scheduledTime
                  .checkMonth === false &&
                this.props.customEvent.event.scheduledTime
                  .checkWeek === false &&
                this.props.customEvent.event.scheduledTime.date !==
                  '' &&
                this.props.customEvent.event.scheduledTime.date !==
                  null
              ) {
                let today = moment(date.timestamp).isSame(
                  this.props.customEvent.event.scheduledTime.date,
                  'day',
                );
                if (today === true) {
                  let status = eventIds.includes(event.id);
                  if (status === false) {
                    timedEventsToday.push(event);
                    eventIds.push(event.id);
                  }
                }
              } else if (
                this.props.customEvent.event.scheduledTime
                  .checkWeek === true &&
                this.props.customEvent.event.scheduledTime.date !== ''
              ) {
                let sameWeek = isSameWeek(
                  date.timestamp,
                  this.props.customEvent.event.scheduledTime.date,
                  7,
                );
                if (sameWeek === true) {
                  let status = eventIds.includes(event.id);
                  if (status === false) {
                    timedEventsToday.push(event);
                    eventIds.push(event.id);
                  }
                }
              } else if (
                this.props.customEvent.event.scheduledTime
                  .checkMonth === true &&
                this.props.customEvent.event.scheduledTime.date !== ''
              ) {
                let sameMonth = moment(date.timestamp).isSame(
                  this.props.customEvent.event.scheduledTime.date,
                  'month',
                );

                if (sameMonth === true) {
                  let status = eventIds.includes(event.id);
                  if (status === false) {
                    timedEventsToday.push(event);
                    eventIds.push(event.id);
                  }
                }
              }
            });
          } else {
            if (
              this.props.customEvent.event.scheduledTime
                .checkMonth === false &&
              this.props.customEvent.event.scheduledTime.checkWeek ===
                false &&
              this.props.customEvent.event.scheduledTime.date !==
                '' &&
              this.props.customEvent.event.scheduledTime.date !== null
            ) {
              let today = moment(
                event.scheduledTime.dates.startDate,
              ).isSame(
                this.props.customEvent.event.scheduledTime.date,
                'day',
              );
              if (today === true) {
                let status = eventIds.includes(event.id);
                if (status === false) {
                  timedEventsToday.push(event);
                  eventIds.push(event.id);
                }
              }
            } else if (
              this.props.customEvent.event.scheduledTime.checkWeek ===
                true &&
              this.props.customEvent.event.scheduledTime.date !== ''
            ) {
              let sameWeek = isSameWeek(
                event.scheduledTime.dates.startDate,
                this.props.customEvent.event.scheduledTime.date,
                7,
              );
              if (sameWeek === true) {
                let status = eventIds.includes(event.id);
                if (status === false) {
                  timedEventsToday.push(event);
                  eventIds.push(event.id);
                }
              }
            } else if (
              this.props.customEvent.event.scheduledTime
                .checkMonth === true &&
              this.props.customEvent.event.scheduledTime.date !== ''
            ) {
              let sameMonth = moment(
                event.scheduledTime.dates.startDate,
              ).isSame(
                this.props.customEvent.event.scheduledTime.date,
                'month',
              );

              if (sameMonth === true) {
                let status = eventIds.includes(event.id);
                if (status === false) {
                  timedEventsToday.push(event);
                  eventIds.push(event.id);
                }
              }
            }
          }
        }
      });
    } else {
      // If no date is selected just use all events
      timedEventsToday = timedEvents;
    }
    // if ((catTitle = 'Top Entertainment Picks')) {
    //   console.log('hogw', timedEventsToday);
    // }

    // Weight every event that is in the date ranges
    timedEventsToday.forEach((event) => {
      let today = moment();
      let eventAfterStatus = moment(
        event.scheduledTime.dates.endDate,
      ).isAfter(today, 'day');

      let eventTodayStatus = moment(
        event.scheduledTime.dates.startDate,
      ).isSame(today, 'day');

      if (eventTodayStatus === true || eventAfterStatus === true) {
        let weightedEvent = event;
        let weight = 0;
        weightedEvent.matchesOtherUserLove = [];
        weightedEvent.matchesOtherUserHate = [];
        weightedEvent.matchesUserLove = [];
        weightedEvent.matchesUserHate = [];
        weightedEvent.weight = weight;

        // Other User Love

        if (otherUserTagsLove !== undefined) {
          if (event.tagsGenre !== undefined) {
            event.tagsGenre.forEach((userTag) => {
              if (otherUserTagsLove.tagsGenre !== undefined) {
                otherUserTagsLove.tagsGenre.forEach((tag) => {
                  if (userTag === tag) {
                    weightedEvent.matchesOtherUserLove.push(tag);
                    weight = weight + 10;
                  }
                });
              }
            });
          }
        }

        // Other User Hate
        if (otherUserTagsHate !== undefined) {
          if (event.tagsGenre !== undefined) {
            event.tagsGenre.forEach((userTag) => {
              if (otherUserTagsHate.tagsGenre !== undefined) {
                otherUserTagsHate.tagsGenre.forEach((tag) => {
                  if (userTag === tag) {
                    weightedEvent.matchesOtherUserHate.push(tag);
                    weight = weight - 25;
                  }
                });
              }
            });
          }
        }

        // User Love
        if (event.tagsGenre !== undefined) {
          event.tagsGenre.forEach((userTag) => {
            if (selectedTags.includes(userTag) === true) {
              weight = weight + 20;
            }
            if (userTagsLove.tagsGenre !== undefined) {
              userTagsLove.tagsGenre.forEach((tag) => {
                if (userTag === tag) {
                  weightedEvent.matchesUserLove.push(tag);
                  weight = weight + 5;
                }
              });
            }
          });
        }
        if (event.tagsEventType !== undefined) {
          event.tagsEventType.forEach((eventTag) => {
            // if (selectedTags.includes(eventTag) === true) {
            //   weight = weight + 20;
            // }
            if (userTagsLove.tagsEventType !== undefined) {
              userTagsLove.tagsEventType.forEach((tag) => {
                if (eventTag === tag) {
                  weightedEvent.matchesUserLove.push(tag);
                  weight = weight + 5;
                }
              });
            }
          });
        }
        // User Hate
        if (event.tagsGenre !== undefined) {
          event.tagsGenre.forEach((userTag) => {
            if (userTagsHate.tagsGenre !== undefined) {
              userTagsHate.tagsGenre.forEach((tag) => {
                if (userTag === tag) {
                  weightedEvent.matchesUserHate.push(tag);
                  weight = weight - 25;
                }
              });
            }
          });
        }

        weightedEvent.weight = weight;
        weightedEvents.push(weightedEvent);
      }
    });

    if (catTitle === 'Top Entertainment Picks') {
      let bucketOne = [];
      let bucketTwo = [];
      let bucketThree = [];
      let bucketFour = [];
      let bucketFive = [];
      let bucketSix = [];
      let bucketSeven = [];
      let noRegion = [];
      let bucketOneArray = [];
      let bucketTwoArray = [];
      let bucketThreeArray = [];
      let bucketFourArray = [];
      let bucketFiveArray = [];
      let bucketSixArray = [];
      let bucketSevenArray = [];
      let noRegionArray = [];

      // Set Selected Region
      let region = this.props.taRequest.startLocation;
      let startLocation = this.props.taRequest.startLocation;
      let num = 0;
      if (startLocation === 'lastLocation') {
        let xnum = this.props.customEvent.event.locations.length;
        if (xnum !== 0) {
          num = Number(xnum) - 1;
        }
        if (
          this.props.customEvent.event.locations[num] !== undefined
        ) {
          region =
            this.props.customEvent.event.locations[num].address
              .region;
        }
      }
      if (startLocation === 'current') {
        region = this.props.taRequest.closestRegion;

        if (region === null) {
          let closestRegion = { region: '', distance: 0 };
          this.props.regions.forEach((regionx) => {
            let currentLatitude =
              this.props.taRequest.currentLatitude;
            let currentLongitude =
              this.props.taRequest.currentLongitude;
            let regionLatitude = regionx.long;
            let regionLongitude = regionx.lat;

            let distance = this.calcCrow(
              currentLatitude,
              currentLongitude,
              regionLatitude,
              regionLongitude,
            );

            if (closestRegion.distance === 0) {
              closestRegion.region = regionx;
              closestRegion.distance = distance;
            } else {
              if (distance < closestRegion.distance) {
                closestRegion.region = regionx;
                closestRegion.distance = distance;
              }
            }
          });
          region = closestRegion.region.name;
        }
      }

      // Find Nearby Regions
      let nearbyRegions = [];
      this.props.regions.forEach((regionx) => {
        if (regionx.name === region) {
          nearbyRegions = regionx.nearbyRegions;
        }
      });

      weightedEvents.forEach((event) => {
        if (event.bucket !== 7) {
          let matchedOne = false;

          // Set the Events Region
          let eventRegion = event.address.region;
          if (event.address.tdId !== null) {
            if (
              event.address.region === undefined ||
              event.address.region === null
            ) {
              const locationDetails = query('locationId')
                .is(event.address.tdId)
                .on(this.props.alllocations);
              let location = locationDetails[0];
              eventRegion = location.address.region;
            }
          }

          if (eventRegion === '') {
            if (noRegion.includes(location.locationTitle) === false) {
              noRegion.push(location.locationTitle);
            }
            if (noRegion.includes(location.locationName) === false) {
              noRegion.push(location.locationName);
            }
          }

          // Check if location matches a nearby region
          if (nearbyRegions !== undefined) {
            nearbyRegions.forEach((regiony) => {
              if (
                eventRegion !== undefined &&
                eventRegion === regiony
              ) {
                matchedOne = true;
              }
            });
          }

          let selectedEventTypeTags = [];

          if (this.props.taRequest.tagFilters[9] !== undefined) {
            this.props.taRequest.tagFilters[9].tags.forEach((tag) => {
              if (tag.checked === true) {
                selectedEventTypeTags.push(tag.tag);
              }
            });
          }

          // Bucket 1: What you asked for, in your region
          if (eventRegion === region) {
            if (selectedEventTypeTags.length > 0) {
              selectedEventTypeTags.forEach((tag) => {
                let status = event.tagsEventType.includes(tag);
                if (status === true) {
                  if (this.props.newEvent.clear === true) {
                    if (
                      eventRegion !== null &&
                      eventRegion !== undefined
                    ) {
                      event.taNote = eventRegion;
                    }
                  } else {
                    event.taNote = 'Good Match In ' + region;
                  }

                  let statusId = bucketOneArray.includes(event.id);
                  if (statusId === false) {
                    bucketOneArray.push(event.id);
                    event.bucket = 1;
                    // console.log('bucketed 1', event);
                    bucketOne.push(event);
                  }
                } else {
                  // Bucket 4: Region you asked for but not event type
                  if (this.props.newEvent.clear === true) {
                    if (
                      eventRegion !== null &&
                      eventRegion !== undefined
                    ) {
                      event.taNote = eventRegion;
                    }
                  } else {
                    event.taNote = 'Good Match In ' + region;
                  }

                  let statusId = bucketFourArray.includes(event.id);
                  if (statusId === false) {
                    bucketFourArray.push(event.id);
                    event.bucket = 4;
                    // console.log('bucketed 4', event);
                    bucketFour.push(event);
                  }
                }
              });
            } else {
              // Bucket 4: Region you asked for but not event type
              if (this.props.newEvent.clear === true) {
                if (
                  eventRegion !== null &&
                  eventRegion !== undefined
                ) {
                  event.taNote = eventRegion;
                }
              } else {
                event.taNote = 'Good Match In ' + region;
              }

              let statusId = bucketFourArray.includes(event.id);
              if (statusId === false) {
                bucketFourArray.push(event.id);
                event.bucket = 4;
                // console.log('bucketed 4', event);
                bucketFour.push(event);
              }
            }
          } else if (matchedOne === true) {
            // Bucket 2: What you asked for, in nearby regions
            if (selectedEventTypeTags.length > 0) {
              selectedEventTypeTags.forEach((tag) => {
                let status = event.tagsEventType.includes(tag);
                if (status === true) {
                  let statusId = bucketTwoArray.includes(event.id);
                  if (statusId === false) {
                    if (this.props.newEvent.clear === true) {
                      if (
                        eventRegion !== null &&
                        eventRegion !== undefined
                      ) {
                        event.taNote = eventRegion;
                      }
                    } else {
                      event.taNote =
                        'Near ' + region + ' in ' + eventRegion;
                    }

                    bucketTwoArray.push(event.id);
                    event.bucket = 2;
                    // console.log('bucketed 2', event);
                    bucketTwo.push(event);
                  }
                } else {
                  // Bucket 5: In Nearby Region but not event type
                  let statusId = bucketFiveArray.includes(event.id);
                  if (statusId === false) {
                    if (this.props.newEvent.clear === true) {
                      if (
                        eventRegion !== null &&
                        eventRegion !== undefined
                      ) {
                        event.taNote = eventRegion;
                      }
                    } else {
                      event.taNote =
                        'Great option in  ' + eventRegion;
                    }

                    bucketFiveArray.push(event.id);
                    event.bucket = 5;
                    // console.log('bucketed 5', event);
                    bucketFive.push(event);
                  }
                }
              });
            } else {
              // console.log('bucketed 2');
              // Bucket 2: What you asked for, in nearby regions (no filters)
              if (this.props.newEvent.clear === true) {
                if (
                  eventRegion !== null &&
                  eventRegion !== undefined
                ) {
                  event.taNote = eventRegion;
                }
              } else {
                event.taNote =
                  'Near ' + region + ' in ' + eventRegion;
              }

              let statusId = bucketTwoArray.includes(event.id);
              if (statusId === false) {
                bucketTwoArray.push(event.id);
                event.bucket = 2;
                // console.log('bucketed 2', event);
                bucketTwo.push(event);
              }
            }
          } else {
            // Bucket 3: Not in region, not in near by regions, matches event type
            if (selectedEventTypeTags.length > 0) {
              selectedEventTypeTags.forEach((tag) => {
                let status = event.tagsEventType.includes(tag);
                if (status === true) {
                  if (this.props.newEvent.clear === true) {
                    if (
                      eventRegion !== null &&
                      eventRegion !== undefined
                    ) {
                      event.taNote = eventRegion;
                    }
                  } else {
                    if (this.props.newEvent.clear === true) {
                      if (
                        eventRegion !== null &&
                        eventRegion !== undefined
                      ) {
                        event.taNote = eventRegion;
                      }
                    } else {
                      event.taNote =
                        'Great option in  ' + eventRegion;
                    }
                  }

                  // Limit BucketThree to only 3 places
                  let status = bucketThreeArray.includes(event.id);
                  if (status === false) {
                    bucketThreeArray.push(event.id);
                    // if (bucketThree.length !== 2) {
                    //   bucketThree.push(event);
                    // }
                    event.bucket = 3;
                    // console.log('bucketed 3', event);
                    bucketThree.push(event);
                  }
                }
              });
            } else {
              // Bucket 6: Not in region, not in near by regions, no event type selected
              if (this.props.newEvent.clear === true) {
                if (
                  eventRegion !== null &&
                  eventRegion !== undefined
                ) {
                  event.taNote = eventRegion;
                }
              } else {
                if (this.props.newEvent.clear === true) {
                  if (
                    eventRegion !== null &&
                    eventRegion !== undefined
                  ) {
                    event.taNote = eventRegion;
                  }
                } else {
                  event.taNote = 'Great option in  ' + eventRegion;
                }
              }

              let statusId = bucketSixArray.includes(event.id);
              if (statusId === false) {
                bucketSixArray.push(event.id);
                event.buckets = 6;
                // console.log('bucketed 6', event);
                bucketSix.push(event);
              }
            }
          }
          // console.log('memento', bucketThree);
          // if (event.bucket === 1) {
          //   bucketOne.push(event);
          // } else if (event.bucket === 2) {
          //   bucketTwo.push(event);
          // }
          // if (event.bucket === 3) {
          //   bucketThree.push(event);
          // }
          // if (event.bucket === 4) {
          //   bucketFour.push(event);
          // }
          // if (event.bucket === 5) {
          //   bucketFive.push(event);
          // } else {
          //   bucketFive.push(event);
          // }
        } else {
          let statusId = bucketSevenArray.includes(event.id);
          if (statusId === false) {
            bucketSevenArray.push(event.id);
            // console.log('bucketed 7', event);
            bucketSeven.push(event);
          }
        }
      });

      let orderedBucketOne = _.orderBy(
        bucketOne,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketTwo = _.orderBy(
        bucketTwo,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketThree = _.orderBy(
        bucketThree,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketFour = _.orderBy(
        bucketFour,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketFive = _.orderBy(
        bucketFive,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketSix = _.orderBy(
        bucketSix,
        ['weight'],
        ['desc', 'asc'],
      );
      let orderedBucketSeven = _.orderBy(
        bucketSeven,
        ['weight'],
        ['desc', 'asc'],
      );
      if (catTitle === 'Top Entertainment Picks') {
        // console.log(
        //   'Buckets: 1',
        //   orderedBucketOne,
        //   '2',
        //   orderedBucketTwo,
        //   '3',
        //   orderedBucketThree,
        //   '4',
        //   orderedBucketFour,
        //   '5',
        //   orderedBucketFive,
        //   '6',
        //   orderedBucketSix,
        //   '7',
        //   orderedBucketSeven,
        // );
      }
      let topPicks = [];

      orderedBucketOne.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketTwo.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketThree.forEach((location) => {
        topPicks.push(location);
      });

      orderedBucketFour.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketFive.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketSix.forEach((location) => {
        topPicks.push(location);
      });
      orderedBucketSeven.forEach((location) => {
        topPicks.push(location);
      });

      // if (catTitle === 'Top Entertainment Picks') {
      //   console.log('topPicks', topPicks);
      // }
      return topPicks;
    } else {
      return _.orderBy(weightedEvents, ['weight'], ['desc', 'asc']);
    }
  }

  weightLocations(
    locations,
    userTagsLove,
    userTagsHate,
    otherUserTagsLove,
    otherUserTagsHate,
  ) {
    const weightedLocations = [];

    locations.forEach((location) => {
      let weightedLocation = location;
      let weight = 0;
      weightedLocation.matchesOtherUserLove = [];
      weightedLocation.matchesOtherUserHate = [];
      weightedLocation.matchesUserLove = [];
      weightedLocation.matchesUserHate = [];
      weightedLocation.weight = weight;

      // Other User Love
      if (otherUserTagsLove !== undefined) {
        if (location.tagsBeverages !== undefined) {
          location.tagsBeverages.forEach((userTag) => {
            otherUserTagsLove.tagsBeverages.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 10;
              }
            });
          });
        }
        if (location.tagsCuisine !== undefined) {
          location.tagsCuisine.forEach((userTag) => {
            otherUserTagsLove.tagsCuisine.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 10;
              }
            });
          });
        }
        if (location.tagsDateType !== undefined) {
          location.tagsDateType.forEach((userTag) => {
            otherUserTagsLove.tagsDateType.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 10;
              }
            });
          });
        }
        if (location.tagsSetting !== undefined) {
          location.tagsSetting.forEach((userTag) => {
            otherUserTagsLove.tagsSetting.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 3;
              }
            });
          });
        }
      }
      // Other User Hate
      if (otherUserTagsHate !== undefined) {
        if (location.tagsBeverages !== undefined) {
          location.tagsBeverages.forEach((userTag) => {
            otherUserTagsHate.tagsBeverages.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 25;
              }
            });
          });
        }
        if (location.tagsCuisine !== undefined) {
          location.tagsCuisine.forEach((userTag) => {
            otherUserTagsHate.tagsCuisine.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 25;
              }
            });
          });
        }
        if (location.tagsDateType !== undefined) {
          location.tagsDateType.forEach((userTag) => {
            otherUserTagsHate.tagsDateType.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 25;
              }
            });
          });
        }
        if (location.tagsSetting !== undefined) {
          location.tagsSetting.forEach((userTag) => {
            otherUserTagsHate.tagsSetting.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 25;
              }
            });
          });
        }
      }
      // User Love
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((userTag) => {
          userTagsLove.tagsBeverages.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 5;
            }
          });
        });
      }
      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((userTag) => {
          userTagsLove.tagsCuisine.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 5;
            }
          });
        });
      }
      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((userTag) => {
          userTagsLove.tagsDateType.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 5;
            }
          });
        });
      }
      if (location.tagsSetting !== undefined) {
        location.tagsSetting.forEach((userTag) => {
          userTagsLove.tagsSetting.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 1;
            }
          });
        });
      }
      // User Hate
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((userTag) => {
          userTagsHate.tagsBeverages.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 25;
            }
          });
        });
      }
      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((userTag) => {
          userTagsHate.tagsCuisine.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 25;
            }
          });
        });
      }
      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((userTag) => {
          userTagsHate.tagsDateType.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 25;
            }
          });
        });
      }
      if (location.tagsSetting !== undefined) {
        location.tagsSetting.forEach((userTag) => {
          userTagsHate.tagsSetting.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 25;
            }
          });
        });
      }

      weightedLocation.weight = weight;

      weightedLocations.push(weightedLocation);
    });

    return _.orderBy(weightedLocations, ['weight'], ['desc', 'asc']);
  }

  getWeightedMusic(timedEvents, user) {
    let otherUserTagsLove = this.state.otherUserTagsLove;
    let otherUserTagsHate = this.state.otherUserTagsHate;
    if (user.tags !== undefined) {
      let userTagsLove = user.tags.love;
      let userTagsHate = user.tags.hate;
      const weightedMusic = this.weightMusic(
        timedEvents,
        userTagsLove,
        userTagsHate,
        otherUserTagsLove,
        otherUserTagsHate,
        'none',
        'addlocations',
      );
      return weightedMusic;
    }
  }

  getWeightedLocations(locations, user) {
    let otherUserTagsLove = this.state.otherUserTagsLove;
    let otherUserTagsHate = this.state.otherUserTagsHate;
    if (user.tags !== undefined) {
      let userTagsLove = user.tags.love;
      let userTagsHate = user.tags.hate;
      const weightedLocations = this.weightLocations(
        locations,
        userTagsLove,
        userTagsHate,
        otherUserTagsLove,
        otherUserTagsHate,
      );
      return weightedLocations;
    }
  }

  reWeight(locations, relatedTags, allTags, selectedTags) {
    let reWeightedLocations = [];
    locations.forEach((location) => {
      relatedTags.forEach((relatedTag) => {
        let status = location.tags.includes(relatedTag.tag);
        if (status === true) {
          location.weight = location.weight + relatedTag.value;
        }
      });
      selectedTags.forEach((relatedTag) => {
        let status = location.tags.includes(relatedTag.tag);
        if (status === true) {
          location.weight = location.weight + 50;
        }
      });
      reWeightedLocations.push(location);
    });
    return reWeightedLocations;
  }

  updateLocations(tags, locations) {
    // This will have every location but with its tags put into a single list
    const locationsReady = [];
    // For each location we push all of its tags into the allTags array
    locations.forEach((location) => {
      const allTags = [];
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsSetting !== undefined) {
        location.tagsSetting.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      const newLocation = location;
      newLocation.tags = allTags;

      locationsReady.push(newLocation);
    });

    // const dif = _.differenceWith(cuisine, beverages, _.isEqual);
    // const filtered = _.filter(coll, item => _.some(filter, key => _.includes(item.tags, key)));

    // Filter Locations Based On Selected Categories
    function filterByMultipleFields(coll, filter) {
      // Organize Tags into their categories
      const tagsBeverages = [];
      const tagsSetting = [];
      const tagsCuisine = [];
      const tagsHours = [];
      const tagsDateType = [];

      let beverages = [];
      let setting = [];
      let type = [];
      let cuisine = [];
      let hours = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Hours') {
            tagsHours.push(tag.tag);
          } else if (tagcat.parent === 'Beverages') {
            tagsBeverages.push(tag.tag);
          } else if (tagcat.parent === 'Date Type') {
            tagsDateType.push(tag.tag);
          } else if (tagcat.parent === 'Cuisine Type') {
            tagsCuisine.push(tag.tag);
          } else if (tagcat.parent === 'Setting Type') {
            tagsSetting.push(tag.tag);
          }
        });
      });

      filter.forEach((tag) => {
        if (tagsBeverages.includes(tag) === true) {
          beverages = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsBeverages, key),
            ),
          );
        } else if (tagsDateType.includes(tag) === true) {
          type = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsDateType, key),
            ),
          );
        } else if (tagsSetting.includes(tag) === true) {
          setting = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsSetting, key),
            ),
          );
        } else if (tagsHours.includes(tag) === true) {
          hours = _.filter(locationsReady, (item) =>
            _.some(filter, (key) => _.includes(item.tagsHours, key)),
          );
        } else if (tagsCuisine.includes(tag) === true) {
          cuisine = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsCuisine, key),
            ),
          );
        }
      });

      // const filtered = _.filter(coll, (item) =>
      //   _.some(filter, (key) => _.includes(item.tags, key)),
      // );

      const arrays = [cuisine, beverages, type, setting, hours];

      const result = _.spread(_.union)(arrays);
      return result;
    }

    let filterBundle = this.selectedTags(this.state.tagFilters);
    const filter = filterBundle.allTags;
    const relatedTags = filterBundle.relatedTags;
    const selectedTags = filterBundle.selectedTags;

    const reWeightedLocations = this.reWeight(
      locationsReady,
      relatedTags,
      filter,
      selectedTags,
    );

    const result = filterByMultipleFields(
      reWeightedLocations,
      filter,
    );

    return result;
    // if (this.props.selectedCategories.length > 0) {
    //   this.setState({
    //     filteredLocations: result,
    //   });
    // } else {
    //   this.setState({
    //     filteredLocations: locations,
    //   });
    // }
  }

  sortLocations(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    if (sortState === 1) {
      const locationsArray = [];
      locations.forEach((location) => {
        const miles = location.address.distance;
        if (location.type !== 1) {
          if (distanceFilterState === 0) {
            if (miles <= 50) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 1) {
            if (miles <= 10) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 2) {
            locationsArray.push(location);
          }
        }
      });

      return _.sortBy(locationsArray, (o) => o.address.distance);
    } else {
      return _.sortBy(locations, (o) => o.locationTitle);
    }
  }

  sortAndWeight(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    const sortedLocations = this.sortLocations(
      locations,
      distanceFilterState,
      currentLongitude,
      currentLatitude,
      sortState,
    );
    const weightedLocations = this.getWeightedLocations(
      sortedLocations,
      this.props.profile,
    );

    return weightedLocations;
  }

  addLocation(customEvent, location) {
    let customImages = false;
    if (location.settings.customImages !== undefined) {
      customImages = location.settings.customImages;
    }

    if (customEvent.event.locations !== null) {
      const newLocations = [];
      customEvent.event.locations.forEach((location) => {
        newLocations.push(location);
      });

      if (
        this.props.history.location !== undefined &&
        this.props.history.location.state !== undefined &&
        this.props.history.location.state.editItinerary !==
          undefined &&
        this.props.history.location.state.editItinerary === true
      ) {
        if (
          this.props.history.location !== undefined &&
          this.props.history.location.state !== undefined &&
          this.props.history.location.state.index !== undefined &&
          this.props.history.location.state.index != null
        ) {
          newLocations.splice(
            this.props.history.location.state.index,
            1,
            {
              locationId: location.locationId,
              type: 0,
              locationTitle: location.locationTitle,
              locationThumb: location.locationThumb,
              address: location.address,
            },
          );
        }
      } else {
        if (location.type === 1) {
          newLocations.push({ location: location, type: 1 });
        } else {
          newLocations.push({
            type: 0,
            locationId: location.locationId,
            locationTitle: location.locationTitle,
            locationThumb: location.locationThumb,
            address: location.address,
            hours: location.hours,
            settings: {
              customImages,
            },
          });
        }
      }

      customEvent.event.locations = newLocations;
      customEvent.step = 1;

      let newTagFilters = this.props.taRequest.tagFilters;
      if (
        this.props.taRequest.eventCats.eat === true &&
        this.props.taRequest.eventCats.watch === true
      ) {
        this.setState({
          guideMode: 2,
        });
        newTagFilters[8].tags[1].checked = true;
        newTagFilters[8].tags[2].checked = false;
      }
      let startingCat = 1;
      if (this.props.taRequest.eventCats.startingCat !== undefined) {
        if (this.props.taRequest.eventCats.startingCat === 1) {
          startingCat = 2;
        } else {
          startingCat = 1;
        }
      }
      // Update TaRequest
      let bundle = {
        tagFilters: newTagFilters,
        distance: this.props.taRequest.distance,
        startLocation: 'lastLocation',
        eventCats: {
          eat: this.props.taRequest.eventCats.eat,
          watch: this.props.taRequest.eventCats.watch,
          do: this.props.taRequest.eventCats.do,
          startingCat,
          endCat: this.props.taRequest.eventCats.endCat,
        },
        currentLatitude: this.props.taRequest.currentLatitude,
        currentLongitude: this.props.taRequest.currentLongitude,
        closestRegion: this.props.taRequest.closestRegion,
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
        reroll: this.props.taRequest.reroll,
      };
      this.props.getTaRequest(bundle);

      customEvent.activeEventCats = {
        eat: false,
        watch: this.props.taRequest.eventCats.watch,
        do: this.props.taRequest.eventCats.do,
        startingCat: this.props.taRequest.eventCats.startingCat,
        endCat: this.props.taRequest.eventCats.endCat,
      };
      // this.props.clearTaRequest(this.props.taRequest);
      this.props.getCe(customEvent);
      this.reFilter(2);
      if (
        this.props.taRequest.eventCats.startingCat ===
        this.props.taRequest.eventCats.endCat
      ) {
        this.props.clearTaRequest(this.props.taRequest);
        if (this.props.customEvent.editMode === true) {
          this.props.history.push(
            '/event/edit/' +
              this.props.customEvent.event.eventId +
              '/1',
          );
        } else {
          this.props.history.push('/event/create/home/1');
        }
      }
    } else {
      const newLocations = [];
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          locationId: location.locationId,
          type: 0,
        });
      }
      customEvent.step = 1;
      customEvent.event.locations = newLocations;
      this.props.getCe(customEvent);

      this.props.clearTaRequest(this.props.taRequest);
      if (this.props.customEvent.editMode === true) {
        this.props.history.push(
          '/event/edit/' +
            this.props.customEvent.event.eventId +
            '/1',
        );
      } else {
        this.props.history.push('/event/create/home/1');
      }
    }
  }

  addEntertainment(customEvent, event) {
    let location = {};
    let customImages = false;

    if (event.address.tdId !== null) {
      const locationDetails = query('locationId')
        .is(event.address.tdId)
        .on(this.props.alllocations);
      location = locationDetails[0];
      location.locationThumb = event.eventThumb;
      location.locationHero = event.eventHero;
      if (location.settings.customImages !== undefined) {
        customImages = location.settings.customImages;
      }
    } else {
      customImages = true;
      location = {
        locationId: null,
        locationTitle: event.address.locationName,
        locationThumb: event.eventThumb,
        zoneId: event.zoneId,
        locationHero: event.eventHero,
        address: {
          street: event.address.street,
          state: event.address.state,
          city: event.address.city,
          zip: event.address.zip,
          latitude: event.address.latitude,
          longitude: event.address.longitude,
          region: event.address.region,
        },
      };
    }
    if (customEvent.event.locations !== null) {
      const newLocations = [];
      customEvent.event.locations.forEach((location) => {
        newLocations.push(location);
      });
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          type: 0,
          locationId: location.locationId,
          locationTitle: location.locationTitle,
          locationThumb: location.locationThumb,
          locationHero: location.locationHero,
          zoneId: location.zoneId,
          hours: location.hours,
          address: location.address,
          settings: {
            customImages,
          },
          locationType: 3,
          event: true,
          eventId: event.eventId,
        });
      }
      customEvent.event.locations = newLocations;
      customEvent.step = 1;

      let newTagFilters = this.props.taRequest.tagFilters;

      if (
        this.props.taRequest.eventCats.eat === true &&
        this.props.taRequest.eventCats.watch === true
      ) {
        this.setState({
          guideMode: 1,
        });
        newTagFilters[8].tags[1].checked = false;
        newTagFilters[8].tags[0].checked = false;
        newTagFilters[8].tags[2].checked = true;
      }

      let startingCat = 1;
      if (this.props.taRequest.eventCats.startingCat !== undefined) {
        if (this.props.taRequest.eventCats.startingCat === 1) {
          startingCat = 2;
        } else {
          startingCat = 1;
        }
      }
      // Update TaRequest
      let bundle = {
        tagFilters: this.props.taRequest.tagFilters,
        distance: this.props.taRequest.distance,
        startLocation: 'lastLocation',
        eventCats: {
          eat: this.props.taRequest.eventCats.eat,
          watch: this.props.taRequest.eventCats.watch,
          do: this.props.taRequest.eventCats.do,
          startingCat,
          endCat: this.props.taRequest.eventCats.endCat,
        },
        currentLatitude: this.props.taRequest.currentLatitude,
        currentLongitude: this.props.taRequest.currentLongitude,
        closestRegion: this.props.taRequest.closestRegion,
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
        reroll: this.props.taRequest.reroll,
      };
      this.props.getTaRequest(bundle);
      if (customEvent.event.title === '') {
        customEvent.event.title = event.eventTitle;
      }

      customEvent.activeEventCats = {
        eat: this.props.taRequest.eventCats.eat,
        watch: false,
        do: this.props.taRequest.eventCats.do,
        startingCat: this.props.taRequest.eventCatsstartingCat,
        endCat: this.props.taRequest.eventCats.endCat,
      };

      // this.props.clearTaRequest(this.props.taRequest);

      // If Event Incluced with Date Set That Date and Lock Calendar
      let currentEvent = null;
      if (customEvent.event.locations.length > 0) {
        customEvent.event.locations.forEach((location, i) => {
          if (location.event === true) {
            const eventDetails = query('id')
              .is(location.eventId)
              .on(this.props.timedEvents);
            currentEvent = eventDetails[0];
          }
        });

        if (
          currentEvent !== null &&
          currentEvent.scheduledTime.dates.oneDayEvent === true
        ) {
          if (customEvent.event.scheduledTime.date === null) {
            // Update Schedule
            customEvent.event.scheduledTime = this.setDate(
              currentEvent.scheduledTime.dates.startDate,

              currentEvent.scheduledTime.dates.startTimestamp,
            );
          }
        }
        this.props.getCe(customEvent);

        if (currentEvent !== null) {
          if (
            (currentEvent.scheduledTime.dates.repeat !== undefined &&
              currentEvent.scheduledTime.dates.repeat === true) ||
            (currentEvent.scheduledTime.dates.multiDates !==
              undefined &&
              currentEvent.scheduledTime.dates.multiDates.length > 1)
          ) {
            this.reFilter(4);
          } else {
            this.reFilter(2);
          }
        }
      }

      if (
        this.props.taRequest.eventCats.startingCat ===
        this.props.taRequest.eventCats.endCat
      ) {
        this.props.clearTaRequest(this.props.taRequest);
        if (this.props.customEvent.editMode === true) {
          this.props.history.push(
            '/event/edit/' +
              this.props.customEvent.event.eventId +
              '/1',
          );
        } else {
          // If Event Incluced with Date Set That Date and Lock Calendar
          let event = null;
          if (customEvent.event.locations.length > 0) {
            customEvent.event.locations.forEach((location, i) => {
              if (location.event === true) {
                const eventDetails = query('id')
                  .is(location.eventId)
                  .on(this.props.timedEvents);
                event = eventDetails[0];
              }
            });

            if (event !== null) {
              if (
                (event.scheduledTime.dates.repeat !== undefined &&
                  event.scheduledTime.dates.repeat === true) ||
                (event.scheduledTime.dates.multiDates !== undefined &&
                  event.scheduledTime.dates.multiDates.length > 1)
              ) {
                if (customEvent.event.scheduledTime.date === null) {
                  this.props.history.push('/event/create/home/2');
                }
              } else {
                this.props.history.push('/event/create/home/1');
              }
            }
          }
        }
      } else {
      }
    } else {
      const newLocations = [];
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          locationId: location.locationId,
          type: 0,
        });
      }
      customEvent.step = 1;
      customEvent.event.locations = newLocations;
      if (customEvent.event.title === '') {
        customEvent.event.title = event.eventTitle;
      }

      this.props.getCe(customEvent);
      this.props.clearTaRequest(this.props.taRequest);
      if (this.props.customEvent.editMode === true) {
        this.props.history.push(
          '/event/edit/' +
            this.props.customEvent.event.eventId +
            '/1',
        );
      } else {
        this.props.history.push('/event/create/home/1');
      }
    }
  }

  // changeLocation(newLocationId, newLocationTitle, index, type, custom) {
  //   if (type === 0) {
  //     const updatedLocations = [...this.state.locations];
  //     if (index != null) {
  //       updatedLocations.splice(index, 1, {
  //         locationName: newLocationTitle,
  //         locationId: newLocationId,
  //         type
  //       });
  //       return updatedLocations;
  //     }
  //   } else {
  //     const updatedLocations = [...this.state.locations];
  //     if (index != null) {
  //       updatedLocations.splice(index, 1, {
  //         location: custom,
  //         type: 1
  //       });
  //       return updatedLocations;
  //     }
  //   }
  // }

  getFooter(mode) {
    return (
      <Container>
        <Row className="no-margin">
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <a
              onClick={() => {
                this.setMode(1);
              }}
            >
              <span className="footer-link left">Recommended</span>
            </a>
          </Col>
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <a
              onClick={() => {
                this.setMode(2);
              }}
            >
              <span className="right footer-link">Any Location</span>
            </a>
          </Col>
        </Row>
      </Container>
    );
  }

  getTitleBar() {
    let url = '';
    if (this.props.customEvent.editMode === true) {
      url =
        '/event/edit/' + this.props.customEvent.event.eventId + '/1';
    } else {
      url = '/event/create/home/1';
    }
    if (this.state.displayAd === true) {
      return (
        <div id="headersTop" style={{ width: '100%' }}>
          <div className="backbar">
            <Row className="no-margin">
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={2}
                className="no-padding"
              ></Col>

              <Col lg={8} md={8} sm={8} xs={8}>
                <center>
                  <span className="page-header">Sponsored Ad</span>
                </center>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
                {/* <div>
                    <a
                      onClick={() => {
                        this.displayAd(false);
                      }}
                      style={{ float: 'right' }}
                    >
                      <MaterialIcon
                        icon="arrow_forward"
                        size={30}
                        color="white"
                      />
                    </a>
                  </div> */}
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return (
        <div id="headersTop" style={{ width: '100%' }}>
          <div className="backbar">
            <Row className="no-margin">
              <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
                <div>
                  {this.state.recMode === 1 ? (
                    <div>
                      {this.state.reFilterMode === false && (
                        <Link
                          to={{
                            pathname: url,
                          }}
                        >
                          <MaterialIcon
                            icon="keyboard_backspace"
                            size={30}
                            color="white"
                          />
                        </Link>
                      )}
                    </div>
                  ) : (
                    <a
                      onClick={() => {
                        this.setRecMode(1);
                      }}
                      className="pointer"
                    >
                      {this.state.reFilterMode === false && (
                        <MaterialIcon
                          icon="keyboard_backspace"
                          size={30}
                          color="white"
                        />
                      )}
                    </a>
                  )}
                </div>
              </Col>

              <Col lg={8} md={8} sm={8} xs={8}>
                <center>
                  <span className="page-header">Trevel Agent</span>
                </center>
              </Col>
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={2}
                className="no-padding"
              ></Col>
            </Row>
          </div>
        </div>
      );
    }
  }

  getLocationTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <a
                onClick={() => {
                  this.hideLocationDetails();
                }}
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </a>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Add This Location</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  getCurrentDistance(filter) {
    let text = '';
    if (filter === 0) {
      text = '50';
    } else if (filter === 1) {
      text = '10';
    } else {
      text = '100+';
    }
    return text;
  }

  getMatches(location) {
    let userLoveTags = [];
    let otherUserLoveTags = [];
    let matchLoveTags = [];
    let userHateTags = [];
    let otherUserHateTags = [];
    let matchHateTags = [];
    let matchesUserHate = [];
    if (location.matchesUserHate !== undefined) {
      matchesUserHate = location.matchesUserHate;
    }
    let matchesUserLove = [];
    if (location.matchesUserLove !== undefined) {
      matchesUserLove = location.matchesUserLove;
    }
    let matchesOtherUserHate = [];
    if (location.matchesOtherUserHate !== undefined) {
      matchesOtherUserHate = location.matchesOtherUserHate;
    }
    let matchesOtherUserLove = [];
    if (location.matchesOtherUserLove !== undefined) {
      matchesOtherUserLove = location.matchesOtherUserLove;
    }
    matchesUserHate.forEach((userMatch) => {
      if (matchesOtherUserHate.length !== 0) {
        matchesOtherUserHate.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            matchHateTags.push(userMatch);
          } else {
            userHateTags.push(userMatch);
          }
        });
      }
    });

    matchesUserLove.forEach((userMatch) => {
      let matched = [];
      if (matchesOtherUserLove.length !== 0) {
        matchesOtherUserLove.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            matched.push(userMatch);
          }
        });
      }
      if (matched.length !== 0) {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          matchLoveTags.push(userMatch);
        }
      } else {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          userLoveTags.push(userMatch);
        }
      }
    });

    matchesOtherUserLove.forEach((userMatch) => {
      let matched = [];
      if (matchesUserLove.length !== 0) {
        matchesUserLove.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            if (matchLoveTags.indexOf(userMatch) === 0) {
              matched.push(userMatch);
            }
          }
        });
      }
      if (matched.length !== 0) {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          matchLoveTags.push(userMatch);
        }
      } else {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          otherUserLoveTags.push(userMatch);
        }
      }
    });

    matchesOtherUserHate.forEach((userMatch) => {
      if (matchesUserHate.length !== 0) {
        matchesUserHate.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            if (matchHateTags.indexOf(userMatch) === 0) {
              matchHateTags.push(userMatch);
            }
          } else {
            if (matchHateTags.indexOf(userMatch) === 0) {
              otherUserHateTags.push(userMatch);
            }
          }
        });
      }
    });

    return (
      <div>
        {userLoveTags.map((tag, index) => (
          <span className="tag-user">{tag}</span>
        ))}
        {otherUserLoveTags.map((tag, index) => (
          <span className="tag-otherUser">{tag}</span>
        ))}
        {matchLoveTags.map((tag, index) => (
          <span className="tag-match">{tag}</span>
        ))}
      </div>
    );
  }

  getKey() {
    if (this.props.newEvent.connections.length !== 0) {
      return (
        <p>
          <b>Tag Colors: </b>
          <span className="tag-user">
            {/* {this.props.profile.firstName} */}
            You
          </span>
          <span className="tag-otherUser">
            {this.props.connectionsTagsAndFavs.length < 2 &&
            this.props.connectionsTagsAndFavs.length !== 0
              ? this.props.connectionsTagsAndFavs[0].firstName
              : 'Group'}
          </span>
          <span className="tag-match">Match!</span>
        </p>
      );
    } else {
      return (
        <p>
          <b>Tag Colors: </b>
          <span className="tag-user">You</span>
        </p>
      );
    }
  }

  selectedTags(tagFilters) {
    let selectedTags = [];
    let allTags = [];
    let relatedTags = [];
    tagFilters.forEach((tagCat) => {
      let skip = false;
      if (tagCat.sid === 'trevelTags') {
        skip = true;
      }
      if (skip === false) {
        tagCat.tags.forEach((tag) => {
          if (tag.checked === true) {
            // selectedTags.push(tag.tag);
            selectedTags.push(tag);
            allTags.push(tag);
          }
        });
      }
    });

    // selectedTags.forEach((tag) => {
    //   if (tag.plus !== undefined) {
    //     tag.plus.forEach((tagplus) => {
    //       let status = allTags.includes(tagplus);
    //       if (status === false) {
    //         allTags.push(tagplus.tag);
    //         relatedTags.push(tagplus);
    //       }
    //     });
    //   }
    // });

    let bundle = {
      allTags,
      relatedTags,
      selectedTags,
    };

    return bundle;
  }

  getCleanTags(selectedTags) {
    let cleanTags = [];
    let hasMusic = [];
    selectedTags.forEach((tag) => {
      if (
        this.props.tags[tag.parentindex].sid !== 'trevelTags' &&
        this.props.tags[tag.parentindex].sid !== 'dateType'
      ) {
        if (tag.tag === 'Music') {
          let status = hasMusic.includes('Music');
          if (status === false) {
            hasMusic.push('Music');
            cleanTags.push(tag);
          }
        } else {
          cleanTags.push(tag);
        }
      }
    });
    return cleanTags;
  }

  getDateTypeTags(selectedTags) {
    let cleanTags = [];
    selectedTags.forEach((tag) => {
      if (this.props.tags[tag.parentindex].sid === 'dateType') {
        cleanTags.push(tag);
      }
    });
    return cleanTags;
  }

  getFilteredTag(tag) {
    return (
      <div className="tag-match">
        <span
          style={{
            position: 'absolute',
            color: '#f21b59',
            left: '-4px',
            top: '-7px',
            borderRadius: '20px',
            background: 'rgb(255 255 255)',
            width: '12px',
            textAlign: 'center',
            height: '15px',
            lineHeight: '14px',
            fontSize: '9px',
            transform: 'scale(1, .8)',
            border: '1px solid #f21b59',
          }}
          onClick={() => {
            this.toggleTag(tag, false);
          }}
        >
          x
        </span>

        <span
          onClick={() => {
            this.toggleTag(tag, false);
          }}
        >
          {tag.tag}
        </span>
      </div>
    );
  }

  getFilters(userMoodNo, userMoodYes, guideMode) {
    if (this.state.tagFilters.length !== 0) {
      let selectedTagsBundle = this.selectedTags(
        this.state.tagFilters,
      );

      let selectedTags = selectedTagsBundle.allTags;
      let cleanTags = this.getCleanTags(selectedTags);
      let dateTypeTags = this.getDateTypeTags(selectedTags);
      // if (cleanTags.length > 0) {
      return (
        <div>
          <div
            style={{
              clear: 'both',
              display: 'block',
              paddingBottom: '3px',
            }}
          >
            <span
              style={{
                display: 'block',
                marginBottom: '10px',
                fontWeight: '500',
                top: '12px',
                // float: 'left',
                marginRight: '10px',
                position: 'relative',
              }}
              className="mobileHide"
            >
              I am in the mood for:{'   '}
              {/* {cleanTags.map((tag) => {
              return this.getFilteredTag(tag);
            })} */}
              {/* <span
              onClick={() => {
                this.clearTagFilters(this.props.tags);
              }}
              className="redlink right"
            >
              clear
            </span> */}
              <span style={{ fontWeight: '100' }}>
                <a
                  onClick={() => {
                    this.reFilter(1);
                  }}
                  style={{
                    color: 'rgb(242, 29, 89)',
                    fontWeight: '100',
                    paddingLeft: '10px',
                  }}
                >
                  change
                </a>
              </span>
            </span>
            <span
              style={{
                marginBottom: '5px',
                fontWeight: '500',
                float: 'left',
                marginRight: '10px',
                position: 'relative',
              }}
              className="mobileShow"
            >
              I am in the mood for:{' '}
              {/* {cleanTags.map((tag) => {
              return this.getFilteredTag(tag);
            })} */}
              {/* <span
              onClick={() => {
                this.clearTagFilters(this.props.tags);
              }}
              className="redlink right"
            >
              clear
            </span> */}
              <a
                onClick={() => {
                  this.reFilter(1);
                }}
                style={{
                  paddingLeft: '10px',
                  color: 'rgb(242, 29, 89)',
                }}
              >
                change
              </a>
            </span>
          </div>
          <div
            style={{
              display: 'grid',

              paddingRight: '10px',
              marginBottom: '10px',
            }}
            className="mobileHide"
          >
            {guideMode === 1 &&
              this.getMainCuisineFields(
                this.props.tags,
                'userMoodYes',
                userMoodYes,
                userMoodNo,
              )}

            {guideMode === 2 && (
              <div style={{ marginBottom: '10px' }}>
                {this.props.tags.map((cattag, index) => (
                  <div>
                    {this.getEventTypeFields(cattag, 'Anything')}
                  </div>
                ))}
              </div>
            )}
            <div>
              {guideMode === 2 &&
                this.props.tags.map((cattag, index) => (
                  <div>{this.getMainGenreFields(cattag)}</div>
                ))}
            </div>

            {this.state.userTags[0].tags.length === 0 &&
            this.state.userTags[1].tags.length === 0 ? (
              <div></div>
            ) : (
              <div>
                <span
                  style={{
                    top: '11px',
                    position: 'relative',
                    float: 'left',
                    height: '40px',
                  }}
                >
                  {Object.keys(this.props.newEvent.connections)
                    .length > 1
                    ? 'Guest Favorites:'
                    : 'Your Favorites:'}
                </span>
                <div style={{ clear: 'both', display: 'block' }}>
                  {guideMode === 2 && (
                    <div
                      style={{
                        float: 'left',
                      }}
                    >
                      {this.getSection(
                        this.state.userTags[4].tags,
                        [],
                        'Music',
                      )}
                    </div>
                  )}
                  {guideMode === 1 && (
                    <div
                      style={{
                        float: 'left',
                      }}
                    >
                      {this.getSection(
                        this.state.userTags[0].tags,
                        this.state.userTags[1].tags,
                        'A Drink',
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              width: '100%',
              paddingRight: '10px',
              clear: 'both',
              marginBottom: '10px',
            }}
            className="mobileShow"
          >
            {this.props.taRequest.eventCats.eat === true &&
              this.getMainCuisineFields(
                this.props.tags,
                'userMoodYes',
                userMoodYes,
                userMoodNo,
              )}
            <div style={{ marginTop: '5px' }}>
              {this.props.taRequest.eventCats.watch === true &&
                this.props.tags.map((cattag, index) => (
                  <div>{this.getMainGenreFields(cattag)}</div>
                ))}
            </div>
          </div>
        </div>
      );
    }
    // }
  }

  getDateTypeFilters() {
    if (this.state.tagFilters.length !== 0) {
      let selectedTagsBundle = this.selectedTags(
        this.state.tagFilters,
      );
      let selectedTags = selectedTagsBundle.allTags;

      let dateTypeTags = this.getDateTypeTags(selectedTags);
      if (dateTypeTags.length > 0) {
        return (
          <span
            style={{
              display: 'block',
              marginBottom: '10px',
              fontWeight: '500',
            }}
          >
            Think:{' '}
            {dateTypeTags.map((tag) => {
              return this.getFilteredTag(tag);
            })}
            {/* <span
              onClick={() => {
                this.clearTagFilters(this.props.tags);
              }}
              className="redlink right"
            >
              clear
            </span> */}
          </span>
        );
      }
    }
  }

  showLocationDetails(location) {
    this.setState({
      showLocation: true,
      activeLocation: location,
    });
  }

  hideLocationDetails() {
    this.setState({
      showLocation: false,
      activeLocation: null,
    });
  }

  getLocationDetails(location) {
    return (
      <div>
        <LocationDetailsAdd
          customEvent={this.props.customEvent}
          location={location}
          addLocation={this.addLocation}
          type={1}
        />
      </div>
    );
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return <span className="mainTag">{location.tagsMain}</span>;
    }
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'activeEventLocation';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  setRecMode(mode) {
    this.searchUpdated('');
    window.scrollTo(0, 0);
    this.setState({
      recMode: mode,
    });
  }

  getGuest(guest) {
    return (
      <div className="avatar">
        <UserAvatar
          size="50"
          name={guest.fullName}
          src={guest.profilePhotoPath}
        />
      </div>
    );
  }

  createGuestList(event) {
    const guestListArray = [];
    if (event.clear === false) {
      if (event.connections !== undefined) {
        Object.keys(event.connections).forEach((key) => {
          let status = 0;
          if (event.connections[key].attending === true) {
            status = 1;
          }
          if (
            event.connections[key].rejected !== undefined &&
            event.connections[key].rejected === true
          ) {
            status = 2;
          }
          guestListArray.push({
            fullName: event.connections[key].profile.fullName,
            profilePhotoPath:
              event.connections[key].profile.profilePhotoPath,
            userId: key,
            userName: event.connections[key].profile.userName,
            user: event.connections[key],
            status,
          });
        });
      } else {
        Object.keys(event.users).forEach((key) => {
          let status = 0;
          if (event.users[key].attending === true) {
            status = 1;
          }
          if (
            event.users[key].rejected !== undefined &&
            event.users[key].rejected === true
          ) {
            status = 2;
          }
          guestListArray.push({
            fullName: event.users[key].profile.fullName,
            profilePhotoPath:
              event.users[key].profile.profilePhotoPath,
            userId: key,
            userName: event.users[key].profile.userName,
            user: event.users[key],
            status,
          });
        });
      }
    } else {
      if (this.props.customEvent.event.users !== undefined) {
        Object.keys(this.props.customEvent.event.users).forEach(
          (key) => {
            let status = 0;
            if (
              this.props.customEvent.event.users[key].attending ===
              true
            ) {
              status = 1;
            }
            if (
              this.props.customEvent.event.users[key].rejected !==
                undefined &&
              this.props.customEvent.event.users[key].rejected ===
                true
            ) {
              status = 2;
            }
            guestListArray.push({
              fullName:
                this.props.customEvent.event.users[key].profile
                  .fullName,
              profilePhotoPath:
                this.props.customEvent.event.users[key].profile
                  .profilePhotoPath,
              userId: key,
              userName:
                this.props.customEvent.event.users[key].profile
                  .userName,
              user: this.props.customEvent.event.users[key],
              status,
            });
          },
        );
      }
    }

    this.setState({
      guestListArray: guestListArray,
    });
  }

  getConnectionsPlaces(locations) {
    let comboTagsStageTwo = [];
    let favLocations = [];
    let favs = {};
    let comboTags = [];

    if (
      this.props.newEvent.invitedUsers !== undefined &&
      this.props.newEvent.invitedUsers.length !== 0
    ) {
      if (this.props.connectionsTagsAndFavs.status === undefined) {
        this.props.connectionsTagsAndFavs.forEach((user) => {
          if (user.favorites !== undefined) {
            user.favorites.forEach((favorite) => {
              let n = comboTags.includes(favorite);
              if (n === false) {
                comboTags.push(favorite);
                favs[favorite] = {
                  count: 2,
                  id: favorite,
                  users: [user.firstName],
                };
              } else {
                favs[favorite].count = favs[favorite].count + 1;
                favs[favorite].users.push(user.firstName);
              }
            });
          }
        });
      }
    }

    // this.props.profile.favorites.forEach((favorite) => {
    //   let n = comboTags.includes(favorite);
    //   if (n === false) {
    //     comboTags.push(favorite);
    //     favs[favorite] = {
    //       count: 1,
    //       id: favorite,
    //       users: [this.props.profile.firstName],
    //     };
    //   } else {
    //     favs[favorite].count = favs[favorite].count + 1;
    //     favs[favorite].users.push(this.props.profile.firstName);
    //   }
    // });

    Object.keys(favs).forEach((key) => {
      comboTagsStageTwo.push(favs[key]);
    });
    if (comboTagsStageTwo.length !== 0) {
      comboTagsStageTwo.forEach((fav) => {
        const locationDetails = query('locationId')
          .is(fav.id)
          .on(locations);
        let newLocation = {};
        if (locationDetails[0] !== undefined) {
          newLocation = locationDetails[0];
          newLocation.weight = fav.count;
          newLocation.users = fav.users;
        }
        favLocations.push(newLocation);
      });
    }

    let finalFavList = favLocations.sort((b, a) =>
      a.weight > b.weight ? 1 : -1,
    );

    // this.setState({
    //   // guestFavLocations: finalFavList,
    //   loading: false,
    // });

    return finalFavList;
  }

  // Only used if taRquest.tagFilters is empty
  setTags(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = this.getTagStatus(tag.tag);
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });

    this.setState({
      tagFilters: updatedTags,
    });
  }

  setUsersTags(userLove, userHate) {
    let userTags = [];
    let connectionsTags = [];

    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };

    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };

    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };

    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };

    let tagsGenre = {
      parent: 'Genre',
      sid: 'genre',
      tags: [],
    };

    if (
      (this.props.newEvent !== undefined &&
        Object.keys(this.props.newEvent.invitedUsers).length !== 0 &&
        this.props.connectionsTagsAndFavs !== undefined &&
        this.props.connectionsTagsAndFavs[0] !== undefined &&
        this.props.connectionsTagsAndFavs[0].tags !== undefined) ||
      (this.props.customEvent.event.invitations !== undefined &&
        Object.keys(this.props.customEvent.event.invitations)
          .length !== 0 &&
        this.props.connectionsTagsAndFavs !== undefined &&
        this.props.connectionsTagsAndFavs[0] !== undefined &&
        this.props.connectionsTagsAndFavs[0].tags !== undefined)
    ) {
      let allTags = {
        tagsBeverages: [],
        tagsDateType: [],
        tagsSetting: [],
        tagsCuisine: [],
        tagsGenre: [],
      };

      this.props.connectionsTagsAndFavs.forEach((guest, y) => {
        guest.tags.love.tagsBeverages.forEach((tagcat, x) => {
          let n = allTags.tagsBeverages.includes(tagcat);
          if (n === false) {
            allTags.tagsBeverages.push(tagcat);
          }
        });

        guest.tags.love.tagsDateType.forEach((tagcat, x) => {
          let n = allTags.tagsDateType.includes(tagcat);
          if (n === false) {
            allTags.tagsDateType.push(tagcat);
          }
        });

        guest.tags.love.tagsSetting.forEach((tagcat, x) => {
          let n = allTags.tagsSetting.includes(tagcat);
          if (n === false) {
            allTags.tagsSetting.push(tagcat);
          }
        });

        guest.tags.love.tagsCuisine.forEach((tagcat, x) => {
          let n = allTags.tagsCuisine.includes(tagcat);
          if (n === false) {
            allTags.tagsCuisine.push(tagcat);
          }
        });

        if (guest.tags.love.tagsGenre !== undefined) {
          guest.tags.love.tagsGenre.forEach((tagcat, x) => {
            let n = allTags.tagsGenre.includes(tagcat);
            if (n === false) {
              allTags.tagsGenre.push(tagcat);
            }
          });
        }
      });

      // Combine Tags
      userLove.tagsBeverages.forEach((tagcat, x) => {
        let n = allTags.tagsBeverages.includes(tagcat);
        if (n === false) {
          allTags.tagsBeverages.push(tagcat);
        }
      });
      userLove.tagsDateType.forEach((tagcat, x) => {
        let n = allTags.tagsDateType.includes(tagcat);
        if (n === false) {
          allTags.tagsDateType.push(tagcat);
        }
      });
      userLove.tagsSetting.forEach((tagcat, x) => {
        let n = allTags.tagsSetting.includes(tagcat);
        if (n === false) {
          allTags.tagsSetting.push(tagcat);
        }
      });
      userLove.tagsCuisine.forEach((tagcat, x) => {
        let n = allTags.tagsCuisine.includes(tagcat);
        if (n === false) {
          allTags.tagsCuisine.push(tagcat);
        }
      });
      userLove.tagsGenre.forEach((tagcat, x) => {
        let n = allTags.tagsGenre.includes(tagcat);
        if (n === false) {
          allTags.tagsGenre.push(tagcat);
        }
      });

      Object.keys(allTags).forEach((key) => {
        allTags[key].map((tag, y) => {
          let newTag = {};
          let status = this.getTagStatus(tag);
          newTag.checked = false;
          newTag.tagindex = y;
          newTag.status = status;
          newTag.tag = tag;
          if (key === 'tagsBeverages') {
            newTag.parentindex = 12;
            tagsBeverages.tags.push(newTag);
          } else if (key === 'tagsCuisine') {
            newTag.parentindex = 2;
            tagsCuisine.tags.push(newTag);
          } else if (key === 'tagsDateType') {
            newTag.parentindex = 5;
            tagsDateType.tags.push(newTag);
          } else if (key === 'tagsSetting') {
            newTag.parentindex = 3;
            tagsSetting.tags.push(newTag);
          } else if (key === 'tagsGenre') {
            newTag.parentindex = 9;
            tagsGenre.tags.push(newTag);
          }
        });
      });
    } else {
      Object.keys(userLove).forEach((key) => {
        userLove[key].map((tag, y) => {
          let newTag = {};
          newTag.checked = false;
          newTag.tagindex = y;
          newTag.status = 1;
          newTag.tag = tag;
          if (key === 'tagsBeverages') {
            newTag.parentindex = 12;
            tagsBeverages.tags.push(newTag);
          } else if (key === 'tagsCuisine') {
            newTag.parentindex = 2;
            tagsCuisine.tags.push(newTag);
          } else if (key === 'tagsDateType') {
            newTag.parentindex = 5;
            tagsDateType.tags.push(newTag);
          } else if (key === 'tagsSetting') {
            newTag.parentindex = 3;
            tagsSetting.tags.push(newTag);
          } else if (key === 'tagsGenre') {
            newTag.parentindex = 10;
            tagsGenre.tags.push(newTag);
          }
        });
      });
    }

    userTags.push(tagsBeverages);
    userTags.push(tagsCuisine);
    userTags.push(tagsSetting);
    userTags.push(tagsDateType);
    userTags.push(tagsGenre);
    this.getConnectionsPlaces(this.props.alllocations);
    // this.setTagFilters(userTags);
    this.setState({
      userTags: userTags,
    });
  }

  getTagStatus(tag) {
    let userTagsLove = this.props.profile.tags.love;
    let userTagsHate = this.props.profile.tags.hate;

    let otherUserTagsLove = null;
    // let otherUserTagsHate = null;

    let status = 0;

    Object.keys(userTagsLove).forEach((key) => {
      userTagsLove[key].forEach((userTag) => {
        if (tag === userTag) {
          status = 1;

          if (
            this.props.newEvent !== undefined &&
            Object.keys(this.props.newEvent.invitedUsers).length !==
              0 &&
            this.props.connectionsTagsAndFavs !== undefined &&
            this.props.connectionsTagsAndFavs[0] !== undefined &&
            this.props.connectionsTagsAndFavs[0].tags !== undefined
          ) {
            this.props.connectionsTagsAndFavs.forEach((guest, y) => {
              Object.keys(guest.tags.love).forEach((key) => {
                guest.tags.love[key].forEach((otherUserTag) => {
                  if (tag === otherUserTag) {
                    if (status === 1) {
                      status = 3;
                    }
                    if (status === 3) {
                      status = 4;
                    }
                  }
                });
              });
            });
          }
        }
      });
    });

    if (
      this.props.newEvent !== undefined &&
      Object.keys(this.props.newEvent.invitedUsers).length !== 0 &&
      this.props.connectionsTagsAndFavs !== undefined &&
      this.props.connectionsTagsAndFavs[0] !== undefined &&
      this.props.connectionsTagsAndFavs[0].tags !== undefined &&
      status === 0
    ) {
      this.props.connectionsTagsAndFavs.forEach((guest, y) => {
        Object.keys(guest.tags.love).forEach((key) => {
          guest.tags.love[key].forEach((otherUserTag) => {
            if (tag === otherUserTag) {
              if (status === 0) {
                status = 2;
              }
            }
          });
        });
      });
    }
    return status;
  }

  // toggleTag(selectedTag, status) {

  //   let userTags = this.state.userTags;

  //   userTags.forEach((tagcat, x) => {
  //     tagcat.tags.forEach((tag, y) => {
  //       if (selectedTag.tag === tag.tag) {
  //         userTags[x].tags[y].checked = status;
  //       }
  //     });
  //   });

  //   let currentSelections = this.state.selections;
  //   if (status === true) {
  //     currentSelections = this.state.selections + 1;
  //   } else currentSelections = this.state.selections - 1;

  //   this.setState({
  //     userTags: userTags,
  //     moodSelected: true,
  //     selections: currentSelections,
  //   });
  //   this.setTagFilters(userTags);
  // }

  toggleTag(tag, status) {
    let updatedTags = this.state.tagFilters;

    // let origionalTags = this.setInitialTags(
    //   this.props.tags,
    //   this.props.locations,
    // );
    // origionalTags.forEach((tagcat) => {
    //   updatedTags.push(tagcat);
    // });

    updatedTags[tag.parentindex].tags[tag.tagindex].checked = status;
    // console.log('updatedTags', origionalTags);
    let bundle = {
      tagFilters: updatedTags,
      distance: this.props.taRequest.distance,
      startLocation: this.props.taRequest.startLocation,
      eventCats: this.props.taRequest.eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
      reroll: true,
    };
    this.props.getTaRequest(bundle);
    this.setState({
      tagFilters: updatedTags,
    });

    this.state.register.forEach((id) => {
      this.setState({ [id + 'carouselPosition']: 0 });
    });

    // if (status === true) {
    //   this.fullScreenMode(true);
    // }

    this.setTagFilters(updatedTags);
  }

  toggleTagGuest(tag, status, title) {
    let updatedTags = this.state.tagFilters;

    let updatedMoodTags = this.state.userMoodYes;
    let updatedGenreType = this.state.genreType;

    if (title === 'Music') {
      let currentTags = this.state.genreType;
      if (status === true) {
        updatedGenreType.push({
          value: tag.tag,
          label: tag.tag,
        });
      } else {
        currentTags.forEach((tagSet) => {
          if (tagSet.label !== tag.tag) {
            updatedGenreType.push(tagSet);
          }
        });
      }
    } else {
      let currentTags = this.state.userMoodYes;
      if (status === true) {
        updatedMoodTags.push({
          value: tag.tag,
          label: tag.tag,
        });
      } else {
        currentTags.forEach((tagSet) => {
          if (tagSet.label !== tag.tag) {
            updatedMoodTags.push(tagSet);
          }
        });
      }
    }

    let masterTag = [];
    this.state.tagFilters.forEach((tagCat) => {
      const locationDetails = query('tag')
        .is(tag.tag)
        .on(tagCat.tags);
      if (locationDetails[0] !== undefined) {
        masterTag = locationDetails[0];
      } else {
      }
    });

    // let origionalTags = this.setInitialTags(
    //   this.props.tags,
    //   this.props.locations,
    // );
    // origionalTags.forEach((tagcat) => {
    //   updatedTags.push(tagcat);
    // });

    updatedTags[tag.parentindex].tags[masterTag.tagindex].checked =
      status;
    // console.log('updatedTags', origionalTags);
    let bundle = {
      tagFilters: updatedTags,
      distance: this.props.taRequest.distance,
      startLocation: this.props.taRequest.startLocation,
      eventCats: this.props.taRequest.eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
      reroll: true,
    };
    this.props.getTaRequest(bundle);
    this.setState({
      tagFilters: updatedTags,
      userMoodYes: updatedMoodTags,
      genreType: updatedGenreType,
    });
    // this.fullScreenMode(true);
    this.setTagFilters(updatedTags);
    this.state.register.forEach((id) => {
      this.setState({ [id + 'carouselPosition']: 0 });
    });
  }

  selectChange = (values) => {
    // let updatedTags = this.state.tagFilters;
    // updatedTags[values.value.parentindex].tags[
    //   values.value.tagindex
    // ].checked = true;
    // this.setState({
    //   tagFilters: updatedTags,
    // });
    // this.setTagFilters(updatedTags);
    // this.searchUpdated(this.state.searchTerm);
    // this.clearLanding(false);
    // this.updateUauth(updatedTags);

    // this.props.history.push('/guide/category/' + values.label);
    if (values.value.length > 15) {
      if (values.value.slice(-5) === '-xxxx') {
        const editedText = values.value.slice(0, -5); //'abcde'

        this.props.history.push({
          pathname: '/entertainment/' + editedText,
          state: {
            currentUrl: window.location.pathname,
            back: window.location.pathname,
            type: 500,
          },
        });
      } else {
        this.props.history.push({
          pathname: '/location/' + values.value,
          state: {
            currentUrl: window.location.pathname,
            back: window.location.pathname,
            type: 500,
          },
        });
      }
    } else {
      this.setState({
        selectInput: '',
      });
      this.toggleTag(values.value, true);
      // this.props.history.push('/guide/category/' + values.label);
    }
    this.fullScreenMode(true);
  };

  selectEntertainmentChange = (values) => {
    this.props.history.push({
      pathname: '/entertainment/' + values.value,
      state: {
        currentUrl: window.location.pathname,
        back: window.location.pathname,
        type: 500,
      },
    });
  };

  getTagButton(tag, title) {
    let style = 'tagFilterBtnNoneSmall';
    if (tag.status === 1) {
      style = 'tagFilterBtnUserSmall';
    } else if (tag.status === 2) {
      style = 'tagFilterBtnOtherSmall';
    } else if (tag.status === 3) {
      style = 'tagFilterBtnMatchedSmall';
    } else if (tag.status === 4) {
      style = 'tagFilterBtnMatchedBright';
    }
    let pathStart = '/guide/category/';
    if (title === 'Music') {
      pathStart = '/guide/category/music/';
    }

    let masterTag = [];
    this.state.tagFilters.forEach((tagCat) => {
      const locationDetails = query('tag')
        .is(tag.tag)
        .on(tagCat.tags);
      if (locationDetails[0] !== undefined) {
        masterTag = locationDetails[0];
      }
    });

    if (masterTag.checked !== true) {
      if (tag.checked === false) {
        return (
          // <Link
          //   to={{
          //     pathname: pathStart + tag.tag,
          //   }}
          // >

          <span
            className={style}
            onClick={() => {
              this.toggleTagGuest(tag, true, title);
            }}
          >
            {tag.tag}
          </span>
          // </Link>
        );
      } else {
        return (
          // <Link
          //   to={{
          //     pathname: pathStart + tag.tag,
          //   }}
          // >
          <span
            className="tagFilterBtnActiveSmall"
            onClick={() => {
              this.toggleTagGuest(tag, false, title);
            }}
          >
            {tag.tag}
          </span>
          // </Link>
        );
      }
    }
  }

  getSection(tags, secondTags, title) {
    let combined = [];
    tags.forEach((location) => {
      combined.push(location);
    });
    secondTags.forEach((location) => {
      combined.push(location);
    });
    let list = combined;
    list.sort((a, b) => (a.status > b.status ? 1 : -1)).reverse();

    // let sortedTags = _.sortBy(tags, (o) => o.status).reverse();
    if (list.length > 0) {
      return (
        <div className="clear">
          {/* <h6>{title}</h6> */}
          {list.map((tag, index) => this.getTagButton(tag, title))}
        </div>
      );
    }
    1;
  }

  getTopMusic(tags, events) {}

  registerCategory(id) {
    let currentRegister = this.state.register;
    currentRegister.push(id);
    // this.setState({
    //   register: currentRegister,
    // });
  }

  getCategory(
    category,
    activeZoneId,
    fullScreen,
    topPicksType,
    nearbyRegions,
  ) {
    this.registerCategory(category.categoryId);
    let fullScreenMode = false;
    if (fullScreen !== undefined) {
      fullScreenMode = fullScreen;
    }
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 't' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];
    let currentDate = new Date();
    let currentDay = dayArray[currentDate.getDay()].short;
    let checkit = [];

    category.filters.zones.forEach((zoneId) => {
      if (zoneId !== '') {
        checkit.push(parseInt(zoneId));
      } else {
        checkit.push(zoneId);
      }
    });
    // If time options set, check
    let show = true;
    if (
      category.time !== undefined &&
      category.time.activeDays !== undefined &&
      category.time.activeDays.length !== 0
    ) {
      let status = category.time.activeDays.includes(currentDay);
      if (status === false) {
        show = false;
      }
    }
    if (
      category.time !== undefined &&
      category.time.toTime !== undefined
    ) {
      let currentTime = moment().format('HH:mm');
      var format = 'hh:mm:ss';
      var time = moment(currentTime, format),
        beforeTime = moment(category.time.fromTime, format),
        afterTime = moment(category.time.toTime, format);
      if (time.isBetween(beforeTime, afterTime) === false) {
        show = false;
      }
    }

    // get events ready to pass to GuideCategory

    let events = [];
    this.props.timedEvents.forEach((event) => {
      events.push(event);
    });

    if (
      this.state.weightedMusic !== undefined &&
      this.state.weightedMusic.length !== 0
    ) {
      events = this.state.weightedMusic;
    } else {
      // console.log('weightedMusicxxx', this.state.weightedMusic);
    }

    let editMode = false;
    if (category.title === 'Top Picks') {
      editMode = false;
    }

    // Check if guest will like NEEDS WORK
    let updatedLocations = this.state.locationsWithDistance;
    if (updatedLocations.length === 0) {
      updatedLocations = this.props.newEvent.weightedLocations;
    }

    if (this.props.tagsAndFavs.status === undefined) {
      this.state.locationsWithDistance.forEach((location, index) => {
        updatedLocations[index].guestMatches = {};
        this.props.tagsAndFavs.forEach((user) => {
          updatedLocations[index].guestMatches[user.userId] = {
            weight: 0,
            match: false,
          };
          location.tagsBeverages.forEach((tag) => {
            let status = false;
            status = user.tags.love.tagsBeverages.includes(tag);
            if (status === true) {
              updatedLocations[index].guestMatches[
                user.userId
              ].match = true;
              updatedLocations[index].guestMatches[
                user.userId
              ].weight =
                updatedLocations[index].guestMatches[user.userId]
                  .weight + 5;
            }
          });
        });
      });
    }

    let type = 'Locations';

    let includeLocations = false;
    if (category.types !== undefined) {
      includeLocations = category.types.includes('Locations');
    } else if (category.type === 'Locations') {
      includeLocations = true;
    }

    let includeEvents = false;
    if (category.types !== undefined) {
      includeEvents = category.types.includes('Events');
      if (includeEvents === true) {
        type = 'Events';
      }
    } else if (category.type === 'Events') {
      includeEvents = true;
    }

    let includeEntertainers = false;
    if (category.types !== undefined) {
      includeEntertainers = category.types.includes('Entertainers');
    } else if (category.type === 'Entertainers') {
      includeEntertainers = true;
    }

    let includeBoth = false;
    if (includeEvents === true && includeLocations === true) {
      includeBoth = true;
    }

    if (category.title === 'Dinner and a Show') {
      type = 'Events';
    }

    if (
      (checkit.includes(activeZoneId) === true && show === true) ||
      (checkit[0] === '' && show === true)
    ) {
      return (
        <GuideCategory
          category={category}
          weightMusic={this.weightMusic}
          type={type}
          includeBoth={includeBoth}
          locations={updatedLocations}
          timedEvents={events}
          count={this.state.count}
          catTitle={category.title}
          addLocation={this.addLocation}
          showLocationDetails={this.showLocationDetails}
          customEvent={this.props.customEvent}
          setRecMode={this.setRecMode}
          addEntertainment={this.addEntertainment}
          editMode={editMode}
          otherUserTagsLove={this.state.otherUserTagsLove}
          otherUserTagsHate={this.state.otherUserTagsHate}
          currentLongitude={this.state.currentLongitude}
          currentLatitude={this.state.currentLatitude}
          venues={this.state.venues}
          nearbyRegions={nearbyRegions}
          fullScreen={fullScreenMode}
          key={category.categoryId}
          carouselPosition={
            this.state[category.categoryId + 'carouselPosition'] !==
            undefined
              ? this.state[category.categoryId + 'carouselPosition']
              : 0
          }
          changeCarousel={this.changeCarousel}
          adCounter={this.adCounter}
          setRandomizer={this.setRandomizer}
          randomizer={
            this.state[category.categoryId + 'randomizer'] !==
            undefined
              ? this.state[category.categoryId + 'randomizer']
              : null
          }
        />
      );
    }
  }

  changeCarousel = (index, id) => {
    if (
      index === 1 &&
      this.state.fullScreen === true &&
      this.state.adShown === false
    ) {
      this.displayAd(true);
    }
    this.setState({ [id + 'carouselPosition']: index });
  };

  displayAd = (value) => {
    this.setState({
      displayAd: value,
    });
    if (value === false) {
      this.setState({ adShown: true });
    }
  };

  sortGuideCategories(section, reverse) {
    let weightedSections = [];
    let topPicks = [];
    let ids = [];
    let allTagFilters = [];
    this.props.taRequest.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          let status = allTagFilters.includes(tag.tag);
          if (status === false) {
            allTagFilters.push(tag.tag);
          }
        }
      });
    });
    section.forEach((category) => {
      if (category.title === 'New In Town') {
      }
      let cat = category;

      if (cat.title === 'Top Picks') {
        topPicks.push(category);
      }

      cat.weight = 0;
      let catExclude = true;
      if (
        category.filters.required !== undefined &&
        category.filters.required.tags.length !== 0
      ) {
        category.filters.required.tags.forEach((tagCat) => {
          tagCat.tags.forEach((tag) => {
            let status = allTagFilters.includes(tag);
            if (status === true) {
              catExclude = false;
            }
          });
        });
      } else if (
        category.filters.required !== undefined &&
        category.filters.required.tags.length === 0
      ) {
        catExclude = false;
      }
      if (catExclude !== true) {
        if (
          category.filters.boosters !== undefined &&
          category.filters.boosters.tags.length !== 0
        ) {
          category.filters.boosters.tags.forEach((tagCat) => {
            tagCat.tags.forEach((tag) => {
              let status = allTagFilters.includes(tag);
              let idcheck = ids.includes(category.categoryId);
              if (status === true) {
                cat.weight = cat.weight + 5;
              }
              if (idcheck === false) {
                ids.push(category.categoryId);
                weightedSections.push(cat);
              }
            });
          });
        } else if (
          category.filters.reducers !== undefined &&
          category.filters.reducers.tags.length !== 0
        ) {
          category.filters.reducers.tags.forEach((tagCat) => {
            tagCat.tags.forEach((tag) => {
              let status = allTagFilters.includes(tag);
              let idcheck = ids.includes(category.categoryId);
              if (status === true) {
                cat.weight = cat.weight - 10;
              }
              if (idcheck === false) {
                ids.push(category.categoryId);
                weightedSections.push(cat);
              }
            });
          });
        } else {
          weightedSections.push(cat);
        }
      }
    });

    if (reverse === true) {
      return _.sortBy(weightedSections, (o) => o.weight).reverse();
    } else {
      return _.sortBy(weightedSections, (o) => o.weight);
    }
  }

  getTopPicks(section, title) {
    let topPicks = [];
    section.forEach((category) => {
      let cat = category;
      if (cat.title === title) {
        topPicks.push(category);
      }
    });
    return topPicks;
  }

  getDates(scheduledTime, guideMode) {
    if (
      scheduledTime.checkWeek === false &&
      scheduledTime.checkMonth === false &&
      scheduledTime.date !== null &&
      scheduledTime.date !== ''
    ) {
      return (
        <div>
          Look for {guideMode === 2 ? 'events' : 'places open'} on{' '}
          <span style={{ fontWeight: '600' }}>
            {moment(scheduledTime.date).format('MMMM DD')}

            <a
              onClick={() => {
                this.changeDate();
              }}
              style={{
                paddingLeft: '10px',
                color: 'rgb(242, 29, 89)',
                fontWeight: '100',
              }}
            >
              change
            </a>
          </span>
        </div>
      );
    } else if (
      scheduledTime.checkWeek === undefined &&
      scheduledTime.checkMonth === undefined &&
      scheduledTime.date !== null &&
      scheduledTime.date !== ''
    ) {
      return (
        <div>
          Look for {guideMode === 2 ? 'events' : 'places open'} on{' '}
          <span style={{ fontWeight: '600' }}>
            {moment(scheduledTime.date).format('MMMM DD')}

            <a
              onClick={() => {
                this.changeDate();
              }}
              style={{
                paddingLeft: '10px',
                color: 'rgb(242, 29, 89)',
                fontWeight: '100',
              }}
            >
              change
            </a>
          </span>
        </div>
      );
    } else if (scheduledTime.checkMonth === true) {
      return (
        <div>
          Look for events in{' '}
          <span style={{ fontWeight: '600' }}>
            {moment(scheduledTime.date).format('MMMM')}
            <a
              onClick={() => {
                this.changeDate();
              }}
              style={{
                paddingLeft: '10px',
                color: 'rgb(242, 29, 89)',
                fontWeight: '100',
              }}
            >
              change
            </a>
          </span>
        </div>
      );
    } else if (scheduledTime.checkWeek === true) {
      return (
        <div>
          Look during the week of{' '}
          <span style={{ fontWeight: '600' }}>
            {moment(scheduledTime.date).format('MMMM DD')}
            <a
              onClick={() => {
                this.changeDate();
              }}
              style={{
                paddingLeft: '10px',
                color: 'rgb(242, 29, 89)',
                fontWeight: '100',
              }}
            >
              change
            </a>
          </span>
        </div>
      );
    } else {
      return (
        <div>
          Look <span style={{ fontWeight: '600' }}>any time</span>.
          <span style={{ fontWeight: '600' }}>
            <a
              onClick={() => {
                this.changeDate();
              }}
              style={{
                paddingLeft: '10px',
                color: 'rgb(242, 29, 89)',
                fontWeight: '100',
              }}
            >
              change
            </a>
          </span>
        </div>
      );
    }
  }

  getSelectedTagCategory(tag, nearbyRegions, guideMode) {
    let events = this.state.allEvents;

    let type = 'Locations';
    if (tag.sid === 'genre') {
      type = 'Events';
    }
    let excludeList = ['Dinner', 'Brunch', 'Breakfast', 'Lunch'];
    if (excludeList.includes(tag.tag) === false) {
      if (
        (type === 'Events' && guideMode === 2) ||
        (type === 'Locations' && guideMode === 1)
      ) {
        return (
          <div>
            <div>
              <GuideCategory
                key={tag.tag}
                category={{
                  type,
                  filters: {
                    tags: [
                      {
                        sid: tag.sid,
                        tagType: tag.tagType,
                        tags: [tag.tag],
                        zones: [],
                        zips: [],
                        cities: [],
                      },
                    ],
                    ignoreRegionFilters: false,
                  },
                  visibility: true,
                  title: tag.tag,
                  categoryId: tag.tag,
                }}
                type={type}
                nearbyRegions={nearbyRegions}
                locations={this.props.newEvent.weightedLocations}
                weightMusic={this.weightMusic}
                timedEvents={events}
                count={this.state.count}
                catTitle={tag.tag}
                showLocationDetails={this.showLocationDetails}
                addLocation={this.addLocation}
                addEntertainment={this.addEntertainment}
                customEvent={this.props.customEvent}
                setRecMode={this.setRecMode}
                editMode={false}
                carouselPosition={
                  this.state[tag.tag + 'carouselPosition'] !==
                  undefined
                    ? this.state[tag.tag + 'carouselPosition']
                    : 0
                }
                changeCarousel={this.changeCarousel}
              />
            </div>
          </div>
        );
      }
    }
  }

  getCategories(category) {
    const categories = [];

    if (category === 'music') {
      this.props.tags.forEach((cattag) => {
        if (
          // cattag.sid === 'entertainmentType' ||
          cattag.sid === 'genre'
        ) {
          const tags = cattag.tags;
          tags.forEach((tag) => {
            let tagBundle = {};
            if (tag.plural !== undefined) {
              tagBundle.category = tag.plural;
            } else {
              tagBundle.category = tag.tag;
            }
            tagBundle.tag = tag.tag;
            categories.push(tagBundle);
          });
        }
      });
    } else if (category === 'entertainment') {
      this.props.tags.forEach((cattag) => {
        if (
          // cattag.sid === 'entertainmentType' ||
          cattag.sid === 'eventType'
        ) {
          const tags = cattag.tags;
          tags.forEach((tag) => {
            let tagBundle = {
              type: 'Events',
              filters: {
                tags: [
                  {
                    sid: 'eventType',
                    tagType: 'tagsEventType',
                    tags: [tag.tag],
                    zones: [],
                    zips: [],
                    cities: [],
                  },
                ],
              },
              visibility: true,
              title: tag.tag,
              categoryId: tag.tag,
            };
            // if (tag.plural !== undefined) {
            //   tagBundle.category = tag.plural;
            // } else {
            //   tagBundle.category = tag.tag;
            // }
            // tagBundle.tag = tag.tag;
            categories.push(tagBundle);
          });
        }
      });
    }
    return categories;
  }

  getRecommendedTab(
    recMode,
    newEvent,
    filteredArray,
    connections,
    fullScreen,
    hateBundle,
    nearbyRegions,
    userMoodYes,
    userMoodNo,
    selectedTags,
    guideMode,
  ) {
    let weightedCategories = this.sortGuideCategories(
      this.props.guideTemplates[2].categories.sectionThree,
      true,
    );
    let weightedCategoriesOne = this.sortGuideCategories(
      this.props.guideTemplates[2].categories.sectionOne,
      false,
    );
    let weightedCategoriesTwo = this.sortGuideCategories(
      this.props.guideTemplates[2].categories.sectionTwo,
      false,
    );
    let categories = [];
    if (guideMode === 2) {
      categories = this.getCategories('entertainment');
    }

    // Setup Top Pics
    let topPicks = [];
    let topPicksType = 'eat';
    if (
      this.props.taRequest.eventCats.eat === true &&
      this.props.taRequest.eventCats.watch === false
    ) {
      topPicks = this.getTopPicks(
        this.props.guideTemplates[2].categories.sectionOne,
        'Top Picks',
      );
    } else if (
      this.props.taRequest.eventCats.eat === false &&
      this.props.taRequest.eventCats.watch === true
    ) {
      topPicks = this.getTopPicks(
        this.props.guideTemplates[2].categories.sectionOne,
        'Top Entertainment Picks',
      );
      topPicksType = 'watch';
    } else if (
      this.props.taRequest.eventCats.eat === true &&
      this.props.taRequest.eventCats.watch === true
    ) {
      if (this.props.taRequest.eventCats.startingCat === 1) {
        topPicks = this.getTopPicks(
          this.props.guideTemplates[2].categories.sectionOne,
          'Top Picks',
        );
      } else {
        topPicks = this.getTopPicks(
          this.props.guideTemplates[2].categories.sectionOne,
          'Top Entertainment Picks',
        );
      }
    } else {
      topPicks = this.getTopPicks(
        this.props.guideTemplates[2].categories.sectionOne,
        'Top Picks',
      );
    }

    // if (this.props.customEvent.event.locations.length === 0) {
    //   if (
    //     this.props.newEvent.weightedLocations !== undefined &&
    //     this.props.newEvent.weightedLocations.length !== 0
    //   ) {
    //     filteredArray = this.props.newEvent.weightedLocations;
    //   }
    // } else {
    //   if (
    //     this.props.newEvent.reRoll !== undefined &&
    //     this.props.newEvent.reRoll === true
    //   ) {
    //     filteredArray = this.props.newEvent.weightedLocations;
    //   } else {
    //     this.addDistance(
    //       this.props.alllocations,
    //       this.props.customEvent.event.locations[0].address.latitude,
    //       this.props.customEvent.event.locations[0].address.longitude,
    //     );
    //   }
    // }

    let guestFavLocations = this.getConnectionsPlaces(
      this.props.alllocations,
    );

    let timeSorted = [];

    //old
    // this.props.customEvent.event.scheduledTime.date !== null &&
    //   this.props.customEvent.event.scheduledTime.time !== undefined &&
    //   this.props.customEvent.event.scheduledTime.time !== ''

    // if the event is scheduled
    if (
      this.props.customEvent.event.scheduledTime.date !== null &&
      this.props.customEvent.event.scheduledTime.time !== undefined &&
      this.props.customEvent.event.scheduledTime.time !== ''
    ) {
      let eventDate = this.props.customEvent.event.scheduledTime.date;
      let eventDay = moment(eventDate).format('dddd');
      let lowercase = eventDay.toLowerCase();
      if (lowercase === 'saturday') {
        lowercase = 'saterday';
      }
      let openString = lowercase + 'Open';
      let fromAMPMString = lowercase + 'FromAMPM';
      let from = lowercase + 'FromHour';
      let toAMPMString = lowercase + 'ToAMPM';
      let to = lowercase + 'ToHour';
      let eventTime = this.props.customEvent.event.scheduledTime.time;
      let hour = eventTime.split(':')[0];

      // Check if Location is open on dates and time
      filteredArray.forEach((location, index) => {
        let openTime = true;
        let closeTime = undefined;
        if (location.hours !== undefined) {
          let openStatus = location.hours[lowercase][openString];
          openTime = location.hours[lowercase][from];
          let openTimeInt = parseInt(openTime);
          let fromAMPM = location.hours[lowercase][fromAMPMString];
          closeTime = location.hours[lowercase][to];
          let closeTimeInt = parseInt(closeTime);
          let toAMPM = location.hours[lowercase][toAMPMString];
          if (fromAMPM === 'pm') {
            openTimeInt = openTimeInt + 12;
          }
          if (toAMPM === 'pm') {
            closeTimeInt = closeTimeInt + 12;
          }
          let eventAMPM =
            this.props.customEvent.event.scheduledTime.time.includes(
              'P.M',
            );
          let hourInt = parseInt(hour);
          if (eventAMPM === true) {
            hourInt = hourInt + 12;
          }
          if (openStatus === false) {
            if (hourInt >= openTimeInt && hourInt < closeTimeInt) {
              timeSorted.push(location);
            }
          }
        }
      });
    } else if (
      this.props.customEvent.event.scheduledTime.date !== null &&
      this.props.customEvent.event.scheduledTime.time !== undefined &&
      this.props.customEvent.event.scheduledTime.time === ''
    ) {
      let eventDate = this.props.customEvent.event.scheduledTime.date;
      let eventDay = moment(eventDate).format('dddd');
      let lowercase = eventDay.toLowerCase();
      if (lowercase === 'saturday') {
        lowercase = 'saterday';
      }
      let openString = lowercase + 'Open';

      filteredArray.forEach((location, index) => {
        if (
          location.hours !== undefined &&
          openString !== undefined &&
          openString !== 'invalid dateOpen'
        ) {
          let openStatus = location.hours[lowercase][openString];
          if (openStatus === false) {
            timeSorted.push(location);
          }
        } else if (
          location.hours !== undefined &&
          openString !== undefined &&
          openString === 'invalid dateOpen'
        ) {
          timeSorted.push(location);
        } else if (openString !== 'invalid dateOpen') {
          // console.log('invalid but cool', location.locationTitle);
          timeSorted.push(location);
        }
      });
    } else {
      filteredArray.forEach((location, index) => {
        timeSorted.push(location);
      });
    }

    // Get Top 3 Picks
    let topThree = [];
    timeSorted.forEach((location, index) => {
      if (index < 4) {
        topThree.push(location);
      }
    });

    // let dining = [];
    //       locations.forEach((location) => {
    //         if (location.tagsLocationType === undefined)
    //           dining.push(location);
    //       });

    // Get Top 3 Closest Picks
    let closeThree = [];
    let sorted = _.orderBy(
      timeSorted,
      ['address.distance'],
      ['asc', 'desc'],
    );

    sorted.forEach((location, index) => {
      if (closeThree.length < 6) {
        if (location.tagsLocationType === undefined) {
          closeThree.push(location);
        } else {
          location.tagsLocationType.forEach((tag, index) => {
            if (tag === 'Restaurant' || tag === 'Dinner') {
              closeThree.push(location);
            }
          });
        }
      }
    });

    let weightedMusic = [];
    if (this.state.weightedMusic.length !== 0) {
      weightedMusic = this.state.weightedMusic;
    } else {
      weightedMusic = this.getWeightedMusic(
        this.state.allEvents,
        this.props.profile,
      );
    }

    // Get Top Music
    let topMusic = [];

    weightedMusic.forEach((event, index) => {
      if (index < 4) {
        if (event.tagsEventType[0] !== 'Happy Hour') {
          topMusic.push(event);
        }
      }
    });

    let selectOptions = this.getSelectOptions(
      this.props.alllocations,
    );

    let selectEntertainerOptions = this.getEnterainerOptions(
      this.props.entertainment,
    );

    let num = 0;
    let closestTitle = '';
    if (this.props.customEvent.event.locations.length !== 0) {
      let xnum = this.props.customEvent.event.locations.length;
      if (xnum !== 0) {
        num = Number(xnum) - 1;
      }
      closestTitle =
        this.props.customEvent.event.locations[num].locationTitle;
    }

    let groupBarStatus = false;

    this.state.userTags.forEach((tags) => {
      tags.tags.forEach((tag) => {
        if (tag.status !== 1) {
          groupBarStatus = true;
        }
      });
    });

    let activeZoneId = this.props.profile.zoneId;
    if (
      this.props.alllocations[0] &&
      undefined &&
      this.props.alllocations[0].zoneId !== undefined
    ) {
      activeZoneId = this.props.alllocations[0].zoneId;
    }

    // if(){
    //   groupBarStatus = false;
    // } else if (thi)
    let names = [];
    let id = this.props.profile.userId;
    let connectionsx = this.props.newEvent.connections;
    Object.keys(connectionsx).forEach(function (key) {
      if (key !== id) {
        let name = connectionsx[key].profile.fullName.replace(
          / .*/,
          '',
        );
        names.push(name);
      }
    });

    // Show Picks
    if (recMode === 1) {
      return (
        // <div id="events" className="section">

        <div>
          {fullScreen === true && (
            <div
              style={{
                height: '100vh',
              }}
            >
              <div>
                {topPicks.map((category, index) => (
                  <div
                    id={fullScreen === true ? 'topPick' : ''}
                    style={{
                      height: '100% !important',
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        padding: '0px 0px 10px 0px ',
                      }}
                    >
                      <span
                        style={{
                          color: 'white',
                          fontSize: '25px',
                          maxWidth: '80%',
                        }}
                      >
                        Here are my Top Picks<br></br>
                        {names.length === 0 ? (
                          'for you!'
                        ) : (
                          <div>
                            for you and{' '}
                            {names.map((name, index) => (
                              <span
                                style={{
                                  color: 'white',
                                  fontSize: '25px',
                                  maxWidth: '80%',
                                }}
                              >
                                {name}
                              </span>
                            ))}
                            !
                          </div>
                        )}
                      </span>
                    </div>

                    {category.visibility === true && (
                      <div>
                        {this.getCategory(
                          category,
                          activeZoneId,
                          true,
                          topPicksType,
                          nearbyRegions,
                        )}
                      </div>
                    )}
                    <div style={{ textAlign: 'center' }}>
                      <span
                        onClick={() => {
                          this.fullScreenMode(false);
                        }}
                        style={{
                          border: '1px solid #ec0359',
                          background: 'white',
                          marginTop: '5%',
                          display: 'inline-block',
                          position: 'relative',
                          padding: '20px',
                          color: '#ec0359',
                          borderRadius: '25px',
                        }}
                      >
                        Show Me More Options
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="header-container">
            {/* <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                {groupBarStatus === true && (
                  <div id="trevelAgentBar">
                    <div
                      style={{
                        margin: '0px auto',
                        width: 'fit-content',
                        display: 'flex',
                        marginBottom: '20px',
                      }}
                    >
                      {this.state.guestListArray.map(
                        (guest, index) => (
                          <div>{this.getGuest(guest)}</div>
                        ),
                      )}
                    </div>
                    <div
                      style={{
                        margin: '0px auto',
                        display: 'block',
                        position: 'relative',
                        width: 'fit-content',
                      }}
                    >
                      {this.getKey()}
                    </div>

                    <div
                      style={{ display: 'block', margin: '0px auto' }}
                    >
                      <center>
                        {this.getSection(
                          this.state.userTags[0].tags,
                          'A Drink',
                        )}
                        {this.getSection(
                          this.state.userTags[1].tags,
                          'A Favorite Cuisine',
                        )}
                        {this.getSection(
                        this.state.userTags[2].tags,
                        'A Certain Atmosphere',
                      )}
                        {this.getSection(
                          this.state.userTags[4].tags,
                          'Music',
                        )}
                        {this.getSection(this.state.userTags[3].tags)}
                      </center>
                    </div>
                  </div>
                )}
              </Col>
            </Row> */}
            <div className="homeMoodBar">
              <Row style={{ margin: '0px 0px 10px 0px' }}>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  // className="no-padding"
                  style={{ padding: '5px 5px 5px 5px' }}
                >
                  {guideMode === 1 && (
                    <div
                      style={{
                        width: '350px',
                        margin: '0px auto',
                      }}
                    >
                      <span
                        className="left"
                        style={{
                          width: '350px',
                        }}
                      >
                        <Select
                          value={this.state.selectInput}
                          closeMenuOnSelect={true}
                          blurInputOnSelect={false}
                          onChange={(e) =>
                            this.selectChange(e, 0, 'one')
                          }
                          options={selectOptions}
                          isMulti={false}
                          placeholder={
                            'Are you looking for a specific location?'
                          }
                        />
                      </span>

                      {/* <a
                      onClick={() => {
                        this.setRecMode(3);
                      }}
                      className="pointer"
                    >
                      <span
                        className="right squareSearch"
                        style={{
                          padding:
                            this.state.count > 2
                              ? '4px 4px 0px'
                              : '4px 4px 0px',
                        }}
                      >
                        <MaterialIcon
                          icon="search"
                          size={35}
                          color="#f21d59"
                        />
                      </span>
                    </a> */}
                    </div>
                  )}
                  {guideMode === 2 && (
                    <div
                      style={{
                        width: '350px',
                        margin: '0px auto',
                      }}
                    >
                      <span
                        className="left"
                        style={{
                          width: '350px',
                        }}
                      >
                        <Select
                          value={this.state.selectInput}
                          closeMenuOnSelect={true}
                          blurInputOnSelect={false}
                          onChange={(e) =>
                            this.selectEntertainmentChange(
                              e,
                              0,
                              'one',
                            )
                          }
                          options={selectEntertainerOptions}
                          isMulti={false}
                          placeholder={
                            'Are you looking for a specific group?'
                          }
                        />
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  {this.props.profile !== undefined &&
                    this.props.profile.tags !== undefined &&
                    this.props.profile.tags.love.tagsBeverages
                      .length === 0 &&
                    this.props.profile.tags.love.tagsCuisine
                      .length === 0 &&
                    this.props.profile.tags.love.tagsExperienceType
                      .length === 0 && (
                      <div
                        style={{
                          textAlign: 'center',
                          padding: '10px',
                        }}
                      >
                        <Link
                          to={{
                            pathname: '/profile/tag',
                            state: {},
                          }}
                        >
                          <span style={{ color: 'white' }}>
                            Don{"'"}t forget to let me know your Food
                            and Beverage preferences.{' '}
                            <span
                              style={{
                                textDecoration: 'underline',
                                fontWeight: '600',
                              }}
                            >
                              Set Preferences
                            </span>
                          </span>
                        </Link>
                      </div>
                    )}
                </Col>
              </Row>
              <div
                style={{
                  background: 'white',
                  padding: '15px 0px 15px 10px',
                }}
              >
                <div>{this.getDateTypeFilters()}</div>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    {this.getFilters(
                      userMoodNo,
                      userMoodYes,
                      guideMode,
                    )}
                    <div style={{ clear: 'both' }}>
                      <span
                        // onClick={() => {
                        //   this.openFilterModal();
                        // }}

                        onClick={() => {
                          this.reFilter(2);
                        }}
                      >
                        Show places{' '}
                        {this.props.taRequest.startLocation ===
                        'current' ? (
                          <span>
                            near my{' '}
                            <span style={{ fontWeight: '600' }}>
                              current location
                            </span>
                            <span style={{ fontWeight: '100' }}>
                              <a
                                onClick={() => {
                                  this.reFilter(2);
                                }}
                                style={{
                                  paddingLeft: '10px',
                                  color: 'rgb(242, 29, 89)',
                                  fontWeight: 100,
                                  textDecoration: 'none !important',
                                }}
                              >
                                change
                              </a>
                            </span>
                          </span>
                        ) : (
                          <span>
                            around{' '}
                            <span style={{ fontWeight: '600' }}>
                              {this.props.taRequest.startLocation ===
                                'lastLocation' &&
                              this.props.customEvent.event
                                .locations[0] !== undefined
                                ? this.props.customEvent.event
                                    .locations[0].locationTitle
                                : this.props.taRequest.startLocation}
                              <span style={{ fontWeight: '100' }}>
                                <a
                                  onClick={() => {
                                    this.reFilter(2);
                                  }}
                                  style={{
                                    paddingLeft: '10px',
                                    color: 'rgb(242, 29, 89)',
                                    fontWeight: 100,
                                    textDecoration: 'none !important',
                                  }}
                                >
                                  change
                                </a>
                              </span>
                            </span>
                          </span>
                        )}
                      </span>
                    </div>
                  </Col>
                  {/* <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="no-padding"
                  > */}
                  {/* <span
                      // onClick={() => {
                      //   this.openFilterModal();
                      // }}
                      onClick={() => {
                        this.reFilter(1);
                      }}
                    >
                      <span className="changeButton">
                        <MaterialIcon
                          icon="edit"
                          size={25}
                          color="white"
                        />
                      </span>
                    </span> */}
                  {/* </Col> */}
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div style={{ marginTop: '10px' }}>
                      {this.getDates(
                        this.props.customEvent.event.scheduledTime,
                        guideMode,
                      )}
                    </div>
                  </Col>
                  {/* <Col
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    className="no-padding"
                  ></Col> */}
                </Row>

                {/* <div style={{ display: 'inline-block' }}>
                  <div style={{ maxWidth: '400px', float: 'left' }}>
                    <Row className="no-padding">
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        style={{ padding: '0px 5px 0px 0px' }}
                      >
                        {this.getButton(
                          1,
                          '10',
                          this.state.distanceFilterState,
                        )}
                      </Col>
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        style={{ padding: '0px 5px 0px 0px' }}
                      >
                        {this.getButton(
                          0,
                          '50',
                          this.state.distanceFilterState,
                        )}
                      </Col>
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        style={{ padding: '0px 5px 0px 0px' }}
                      >
                        {this.getButton(
                          2,
                          '100+',
                          this.state.distanceFilterState,
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div style={{ float: 'left' }}>
                    <span>miles around my current locat </span>
                  </div>
                </div> */}
              </div>
              {hateBundle.match === true && (
                <div
                  style={{
                    display: 'block',
                    clear: 'both',
                    padding: '10px',
                    background: '#f21b59',
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: '600',
                    borderTop: '2px solid #a50033',
                  }}
                >
                  Just a heads up that {hateBundle.hater} hates{' '}
                  {hateBundle.hateWord}!
                </div>
              )}
            </div>

            <Row className="no-margin" style={{ clear: 'both' }}>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <div
                  id="homeSections"
                  style={{
                    paddingTop: '20px',
                    paddingBottom: '10px',
                  }}
                >
                  {this.props.guideTemplates !== undefined &&
                    this.props.guideTemplates.length !== 0 && (
                      <div style={{ paddingTop: '20px' }}>
                        {weightedCategoriesOne.map(
                          (category, index) => (
                            <div>
                              {category.visibility === true && (
                                <div>
                                  {this.getCategory(
                                    category,
                                    activeZoneId,
                                    false,
                                    null,
                                    nearbyRegions,
                                  )}
                                </div>
                              )}
                            </div>
                          ),
                        )}
                      </div>
                    )}

                  <div style={{ paddingTop: '10px' }}>
                    {selectedTags.map((tag, index) =>
                      this.getSelectedTagCategory(
                        tag,
                        nearbyRegions,
                        guideMode,
                      ),
                    )}
                  </div>

                  {guideMode === 1 &&
                    this.props.customEvent.event.locations !==
                      undefined &&
                    this.props.customEvent.event.locations.length !==
                      0 && (
                      <GuideCategoryAdd
                        category={''}
                        tagCat={'all'}
                        locations={closeThree}
                        count={this.state.count}
                        catTitle={'Closest To ' + closestTitle}
                        showLocationDetails={this.showLocationDetails}
                        addLocation={this.addLocation}
                        customEvent={this.props.customEvent}
                        setRecMode={this.setRecMode}
                      />
                    )}

                  {/* {newEvent.weightedLocations.length !== 0 &&
                    this.props.customEvent.event.locations !==
                      undefined &&
                    this.props.customEvent.event.locations.length ===
                      0 && (
                      <div>
                        {this.props.taRequest.eventCats.eat ===
                          true && (
                          <GuideCategoryAdd
                            category={''}
                            tagCat={'all'}
                            locations={closeThree}
                            count={this.state.count}
                            catTitle={
                              'Closest Dining Recommendations'
                            }
                            showLocationDetails={
                              this.showLocationDetails
                            }
                            addLocation={this.addLocation}
                            customEvent={this.props.customEvent}
                            setRecMode={this.setRecMode}
                          />
                        )}
                      </div>
                    )} */}
                  {/* {topMusic.length !== 0 &&
                    newEvent.weightedLocations.length !== 0 &&
                    this.props.customEvent.event.locations !==
                      undefined && (
                      <div>
                        {this.props.taRequest.eventCats.music ===
                          true && (
                          <GuideCategory
                            key={1}
                            id="topMusic"
                            category={{
                              type: 'Events',
                              filters: {
                                tags: [
                                  {
                                    sid: '',
                                    tagType: 'topMusic',
                                    tags: [],
                                    zones: [],
                                    zips: [],
                                    cities: [],
                                  },
                                ],
                              },
                              visibility: true,
                              title: 'Top Music',
                            }}
                            type="Events"
                            locations={topMusic}
                            timedEvents={topMusic}
                            count={this.state.count}
                            weightMusic={this.weightMusic}
                            catTitle={'Top Music Recommendations'}
                            addLocation={this.props.addLocation}
                            editMode={false}
                            customEvent={this.props.customEvent}
                            addEntertainment={this.addEntertainment}
                            carouselPosition={
                              this.state.TopMusiccarouselPosition !==
                              undefined
                                ? this.state.TopMusiccarouselPosition
                                : 0
                            }
                            changeCarousel={this.changeCarousel}
                          />
                        )}
                      </div>
                    )} */}

                  {guideMode === 1 &&
                    this.props.customEvent.event.locations !==
                      undefined &&
                    this.props.customEvent.event.locations.length !==
                      0 && (
                      <GuideCategory
                        key={'cocktails'}
                        category={{
                          type: 'Locations',
                          filters: {
                            tags: [
                              {
                                sid: 'beverages',
                                tagType: 'tagsBeverages',
                                tags: ['Cocktails'],
                                zones: [],
                                zips: [],
                                cities: [],
                              },
                            ],
                          },
                          visibility: true,
                          title: 'Feel like a nightcap?',
                          categoryId: 'Cocktails',
                        }}
                        type="Locations"
                        locations={
                          this.props.newEvent.weightedLocations
                        }
                        timedEvents={this.state.allEvents}
                        count={this.state.count}
                        catTitle={'Feel like a nightcap?'}
                        showLocationDetails={this.showLocationDetails}
                        addLocation={this.addLocation}
                        customEvent={this.props.customEvent}
                        setRecMode={this.setRecMode}
                        editMode={false}
                        carouselPosition={
                          this.state.CocktailscarouselPosition !==
                          undefined
                            ? this.state.CocktailscarouselPosition
                            : 0
                        }
                        changeCarousel={this.changeCarousel}
                        weightMusic={this.weightMusic}
                      />
                    )}
                  {guideMode === 1 &&
                    this.props.customEvent.event.locations !==
                      undefined &&
                    this.props.customEvent.event.locations.length !==
                      0 && (
                      <GuideCategory
                        key={'coffee'}
                        category={{
                          type: 'Locations',
                          filters: {
                            tags: [
                              {
                                sid: 'beverages',
                                tagType: 'tagsBeverages',
                                tags: ['Coffee'],
                                zones: [],
                                zips: [],
                                cities: [],
                                categoryId: 'Coffee',
                              },
                            ],
                          },
                          visibility: true,
                          title: 'Need a little pick me up?',
                        }}
                        type="Locations"
                        locations={
                          this.props.newEvent.weightedLocations
                        }
                        timedEvents={this.state.allEvents}
                        count={this.state.count}
                        catTitle={'Need a little pick me up?'}
                        showLocationDetails={this.showLocationDetails}
                        addLocation={this.addLocation}
                        customEvent={this.props.customEvent}
                        setRecMode={this.setRecMode}
                        editMode={false}
                        carouselPosition={
                          this.state.CoffeecarouselPosition !==
                          undefined
                            ? this.state.CoffeecarouselPosition
                            : 0
                        }
                        changeCarousel={this.changeCarousel}
                        weightMusic={this.weightMusic}
                      />
                    )}
                  {/* {this.props.customEvent.event.locations !==
                    undefined &&
                    this.props.customEvent.event.locations.length ===
                      0 && (
                      <GuideCategoryAdd
                        category={''}
                        tagCat={'all'}
                        locations={topThree}
                        count={this.state.count}
                        catTitle={'Top Dining Recommendations'}
                        showLocationDetails={this.showLocationDetails}
                        addLocation={this.addLocation}
                        customEvent={this.props.customEvent}
                        setRecMode={this.setRecMode}
                        // fullScreen={true}
                      />
                    )} */}

                  {/* <GuideCategory
                    key={1}
                    category={{
                      type: 'Events',
                      filters: {
                        tags: [
                          {
                            sid: 'eventType',
                            tagType: 'tagsEventType',
                            tags: ['Happy Hour'],
                            zones: [],
                            zips: [],
                            cities: [],
                          },
                        ],
                      },
                      visibility: true,
                      title: 'Happy Hours',
                    }}
                    type="Events"
                    timedEvents={
                      this.state.weightedMusic.length !== 0
                        ? this.state.weightedMusic
                        : this.state.timedEvents
                    }
                    count={this.state.count}
                    catTitle={'Happy Hours'}
                    addLocation={this.addLocation}
                    editMode={false}
                    customEvent={this.props.customEvent}
                    addEntertainment={this.addEntertainment}
                  /> */}

                  <GuideCategoryAdd
                    category={''}
                    tagCat={'all'}
                    locations={guestFavLocations}
                    count={this.state.count}
                    catTitle={"Guest's Favorite Locations"}
                    showLocationDetails={this.showLocationDetails}
                    addLocation={this.addLocation}
                    customEvent={this.props.customEvent}
                    setRecMode={this.setRecMode}
                  />

                  {this.props.guideTemplates !== undefined &&
                    this.props.guideTemplates.length !== 0 && (
                      <div style={{ paddingTop: '20px' }}>
                        {/* {this.props.guideTemplates[2].categories.sectionTwo.map( */}
                        {weightedCategoriesTwo.map(
                          (category, index) => (
                            <div>
                              {category.visibility === true && (
                                <div>
                                  {this.getCategory(
                                    category,
                                    activeZoneId,
                                    false,
                                    null,
                                    nearbyRegions,
                                  )}
                                </div>
                              )}
                            </div>
                          ),
                        )}
                      </div>
                    )}

                  {this.props.guideTemplates !== undefined &&
                    this.props.guideTemplates.length !== 0 && (
                      <div style={{ paddingTop: '20px' }}>
                        {weightedCategories.map((category, index) => (
                          <div>
                            {category.visibility === true && (
                              <div>
                                {this.getCategory(
                                  category,
                                  activeZoneId,
                                  false,
                                  null,
                                  nearbyRegions,
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}

                  {/* Favorite Entertainers */}
                  {this.props.profile.favoriteEntertainment !==
                    undefined &&
                    this.props.profile.favoriteEntertainment
                      .length !== 0 && (
                      <div>
                        {this.props.profile.favoriteEntertainment.map(
                          (entertainerId, index) => (
                            <div>
                              <GuideEventsCategoryById
                                timedEvents={this.state.allEvents}
                                count={this.state.count}
                                id={entertainerId}
                                editMode={false}
                                addEntertainment={
                                  this.addEntertainment
                                }
                                addLocation={this.addLocation}
                                customEvent={this.props.customEvent}
                              />
                            </div>
                          ),
                        )}
                      </div>
                    )}

                  {guideMode === 2 && (
                    <div className="homeSectionAll">
                      <div>
                        {categories.map((category, index) => (
                          <GuideCategory
                            key={index + 1}
                            weightMusic={this.weightMusic}
                            editMode={false}
                            addEntertainment={this.addEntertainment}
                            category={category}
                            type="Events"
                            locations={this.props.getAllLocations}
                            timedEvents={this.state.allEvents}
                            count={this.state.count}
                            nearbyRegions={nearbyRegions}
                            catTitle={category.title}
                            setRandomizer={this.setRandomizer}
                            customEvent={this.props.customEvent}
                            randomizer={
                              this.state[
                                category.categoryId + 'randomizer'
                              ] !== undefined
                                ? this.state[
                                    category.categoryId + 'randomizer'
                                  ]
                                : null
                            }
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {/* <Container className="no-padding">
                    {topThree.map((location, index) => (
                      <div
                        className="oddeven"
                        key={location.locationId}
                      >
                        <Row key={location.locationId}>
                          <Col
                            xs={10}
                            md={10}
                            sm={10}
                            lg={10}
                            className="no-padding"
                          >
                            <div
                              style={{
                                float: 'left',
                                width: '100px',
                                marginRight: '10px',
                              }}
                            >
                              <a
                                onClick={() => {
                                  this.showLocationDetails(location);
                                }}
                              >
                                {this.getImage('thumb', location)}
                              </a>
                            </div>

                           
                            <div>
                              <a
                                onClick={() => {
                                  this.showLocationDetails(location);
                                }}
                              >
                                <span className="location-title">
                                  {location.locationTitle}
                                </span>
                                <span className="address">
                                  {location.address.street}
                                </span>
                                <span className="address">
                                  {location.address.city}{' '}
                                  {location.address.state},{' '}
                                  {location.address.zip}
                                </span>
                              </a>
                            </div>
                          </Col>
                          <Col
                            xs={2}
                            md={2}
                            sm={2}
                            lg={2}
                            className="no-padding"
                          >
                            <div className="right text-right">
                              <a
                                onClick={() => {
                                  this.addLocation(
                                    this.props.customEvent,
                                    location,
                                  );
                                }}
                              >
                                <MaterialIcon
                                  icon="add_circle"
                                  size={25}
                                  color="#f21d59"
                                />
                              </a>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs={12}
                            md={12}
                            sm={12}
                            lg={12}
                            className="no-padding"
                          >
                            <div
                              style={{
                                float: 'left',
                                width: '100px',
                                marginRight: '10px',
                              }}
                            >
                              <span className="distance">
                                {location.address.distance} miles
                              </span>
                            </div>
                          
                            {this.getMainTag(location)}
                            {this.getMatches(location)}
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Container> */}
                </div>
                {/* <a
                  onClick={() => {
                    this.setRecMode(2);
                  }}
                  className="solidRedbutton"
                  style={{ marginTop: '15px', width: '150px' }}
                >
                  <span>See More</span>
                </a> */}
              </Col>
            </Row>
            {/* <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <div style={{ marginTop: '20px' }}>
                  <h5>Closest to You</h5>
                  <Container className="no-padding">
                    {closeThree.map((location, index) => (
                      <div
                        className="oddeven"
                        key={location.locationId}
                      >
                        <Row key={location.locationId}>
                          <Col
                            xs={10}
                            md={10}
                            sm={10}
                            lg={10}
                            className="no-padding"
                          >
                            <div
                              style={{
                                float: 'left',
                                width: '100px',
                                marginRight: '10px',
                              }}
                            >
                              <a
                                onClick={() => {
                                  this.showLocationDetails(location);
                                }}
                              >
                                {this.getImage('thumb', location)}
                              </a>
                            </div>

                            <div>
                              <a
                                onClick={() => {
                                  this.showLocationDetails(location);
                                }}
                              >
                                <span className="location-title">
                                  {location.locationTitle}
                                </span>
                                <span className="address">
                                  {location.address.street}
                                </span>
                                <span className="address">
                                  {location.address.city}{' '}
                                  {location.address.state},{' '}
                                  {location.address.zip}
                                </span>
                              </a>
                            </div>
                          </Col>
                          <Col
                            xs={2}
                            md={2}
                            sm={2}
                            lg={2}
                            className="no-padding"
                          >
                            <div className="right text-right">
                              <a
                                onClick={() => {
                                  this.addLocation(
                                    this.props.customEvent,
                                    location,
                                  );
                                }}
                              >
                                <MaterialIcon
                                  icon="add_circle"
                                  size={25}
                                  color="#f21d59"
                                />
                              </a>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs={12}
                            md={12}
                            sm={12}
                            lg={12}
                            className="no-padding"
                          >
                            <div
                              style={{
                                float: 'left',
                                width: '100px',
                                marginRight: '10px',
                              }}
                            >
                              <span className="distance">
                                {location.address.distance} miles
                              </span>
                            </div>

                            {this.getMainTag(location)}
                            {this.getMatches(location)}
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Container>
                </div>
                <a
                  onClick={() => {
                    this.setRecMode(3);
                  }}
                  className="solidRedbutton"
                  style={{ marginTop: '15px', width: '150px' }}
                >
                  <span>See More</span>
                </a>
              </Col>
            </Row> */}
          </div>
        </div>
      );
    }
    if (recMode === 2) {
      return (
        <div id="events" className="section">
          <div className="header-container">
            <Row className="no-margin">
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="no-padding"
              >
                <SearchInput
                  className="search-input"
                  onChange={this.searchUpdated}
                />
              </Col>
              <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
                <a
                  onClick={() => {
                    this.openFilterModal();
                  }}
                  className="right"
                >
                  <MaterialIcon
                    icon="filter_list"
                    size={25}
                    color="#f21d59"
                  />
                </a>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <p>
                  Showing locations{' '}
                  <b>
                    {this.getCurrentDistance(
                      this.state.distanceFilterState,
                    )}
                  </b>{' '}
                  miles from you.
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                {this.getKey()}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                {this.getFilters(userMoodNo, userMoodYes, guideMode)}
              </Col>
              <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
                <span
                  // onClick={() => {
                  //   this.openFilterModal();
                  // }}
                  onClick={() => {
                    this.reFilter(1);
                  }}
                >
                  <span className="changeButton">Update</span>
                </span>
              </Col>
            </Row>
          </div>
          <div>
            <Container className="no-padding">
              {this.state.filteredArray.map((location, index) => (
                <div className="oddeven" key={location.locationId}>
                  <Row key={location.locationId}>
                    <Col
                      xs={10}
                      md={10}
                      sm={10}
                      lg={10}
                      className="no-padding"
                    >
                      <div
                        style={{
                          float: 'left',
                          width: '100px',
                          marginRight: '10px',
                        }}
                      >
                        {this.getFavStatus(location.locationId)}

                        <a
                          onClick={() => {
                            this.showLocationDetails(location);
                          }}
                        >
                          {this.getImage('thumb', location)}
                        </a>
                      </div>

                      {/* </Col>
                    <Col
                      xs={6}
                      md={6}
                      sm={6}
                      lg={6}
                      className="no-padding"
                    > */}
                      <div>
                        <a
                          onClick={() => {
                            this.showLocationDetails(location);
                          }}
                        >
                          <span className="location-title">
                            {location.locationTitle}
                          </span>
                          <span className="address">
                            {location.address.street}
                          </span>
                          <span className="address">
                            {location.address.city}{' '}
                            {location.address.state},{' '}
                            {location.address.zip}
                          </span>
                        </a>
                      </div>
                    </Col>
                    <Col
                      xs={2}
                      md={2}
                      sm={2}
                      lg={2}
                      className="no-padding"
                    >
                      <div className="right text-right">
                        <a
                          onClick={() => {
                            this.addLocation(
                              this.props.customEvent,
                              location,
                            );
                          }}
                        >
                          <MaterialIcon
                            icon="add_circle"
                            size={25}
                            color="#f21d59"
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                    >
                      <div
                        style={{
                          float: 'left',
                          width: '100px',
                          marginRight: '10px',
                        }}
                      >
                        <span className="distance">
                          {location.address.distance} miles
                        </span>
                      </div>
                      {/* </Col>
  
                    <Col
                      xs={8}
                      md={8}
                      sm={8}
                      lg={8}
                      className="no-padding"
                    > */}
                      {this.getMainTag(location)}
                      {this.getMatches(location)}
                    </Col>
                  </Row>
                </div>
              ))}
            </Container>
          </div>
        </div>
      );
    }
    if (recMode === 3) {
      return (
        <div id="events" className="section">
          <div className="header-container">
            <Row className="no-margin">
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="no-padding"
              >
                <SearchInput
                  className="search-input"
                  onChange={this.searchUpdated}
                />
              </Col>
              <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
                <a
                  onClick={() => {
                    this.openFilterModal();
                  }}
                  className="right"
                >
                  <MaterialIcon
                    icon="filter_list"
                    size={25}
                    color="#f21d59"
                  />
                </a>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <p>
                  Showing locations{' '}
                  <b>
                    {this.getCurrentDistance(
                      this.state.distanceFilterState,
                    )}
                  </b>{' '}
                  miles from you.
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                {this.getKey()}
              </Col>
            </Row>
            <Row>
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="no-padding"
              >
                {this.getFilters(userMoodNo, userMoodYes, guideMode)}
              </Col>
              <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
                <span
                  // onClick={() => {
                  //   this.openFilterModal();
                  // }}
                  onClick={() => {
                    this.reFilter(1);
                  }}
                >
                  <span className="changeButton">Update</span>
                </span>
              </Col>
            </Row>
          </div>
          <div>
            <Container className="no-padding">
              {sorted.map((location, index) => (
                <div className="oddeven" key={location.locationId}>
                  <Row key={location.locationId}>
                    <Col
                      xs={10}
                      md={10}
                      sm={10}
                      lg={10}
                      className="no-padding"
                    >
                      <div
                        style={{
                          float: 'left',
                          width: '100px',
                          marginRight: '10px',
                        }}
                      >
                        <a
                          onClick={() => {
                            this.showLocationDetails(location);
                          }}
                        >
                          {this.getImage('thumb', location)}
                        </a>
                      </div>

                      {/* </Col>
                    <Col
                      xs={6}
                      md={6}
                      sm={6}
                      lg={6}
                      className="no-padding"
                    > */}
                      <div>
                        <a
                          onClick={() => {
                            this.showLocationDetails(location);
                          }}
                        >
                          <span className="location-title">
                            {location.locationTitle}
                          </span>
                          <span className="address">
                            {location.address.street}
                          </span>
                          <span className="address">
                            {location.address.city}{' '}
                            {location.address.state},{' '}
                            {location.address.zip}
                          </span>
                        </a>
                      </div>
                    </Col>
                    <Col
                      xs={2}
                      md={2}
                      sm={2}
                      lg={2}
                      className="no-padding"
                    >
                      <div className="right text-right">
                        <a
                          onClick={() => {
                            this.addLocation(
                              this.props.customEvent,
                              location,
                            );
                          }}
                        >
                          <MaterialIcon
                            icon="add_circle"
                            size={25}
                            color="#f21d59"
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                    >
                      <div
                        style={{
                          float: 'left',
                          width: '100px',
                          marginRight: '10px',
                        }}
                      >
                        <span className="distance">
                          {location.address.distance} miles
                        </span>
                      </div>
                      {/* </Col>
  
                    <Col
                      xs={8}
                      md={8}
                      sm={8}
                      lg={8}
                      className="no-padding"
                    > */}
                      {this.getMainTag(location)}
                      {this.getMatches(location)}
                    </Col>
                  </Row>
                </div>
              ))}
            </Container>
          </div>
        </div>
      );
    }
  }

  getFavStatus(locationId) {
    let status = 0;
    let favorites = this.props.profile.favorites;
    let allGuestFavs = [];

    if (this.props.tagsAndFavs.status === undefined) {
      let guestFavorites = this.props.tagsAndFavs;
      guestFavorites.forEach((guest) => {
        guest.favorites.forEach((fav) => {
          allGuestFavs.push(fav);
        });
      });
    }

    let isFavorite = _.includes(favorites, locationId);
    let isGuestFavorite = _.includes(allGuestFavs, locationId);

    if (isFavorite === true && isGuestFavorite === true) {
      status = 3;
    } else if (isFavorite === true && isGuestFavorite === false) {
      status = 2;
    } else if (isFavorite === false && isGuestFavorite === true) {
      status = 1;
    }

    if (status === 1) {
      return (
        <div>
          <div
            style={{
              position: 'absolute',
              left: '-15px',
              top: '-15px',
            }}
          >
            <MaterialIcon
              icon="star_border"
              size={30}
              color="#ee0158"
            />
          </div>
          <div
            style={{
              position: 'absolute',
              left: '-12.5px',
              top: '-13px',
            }}
          >
            <MaterialIcon icon="star_rate" size={25} color="white" />
          </div>
        </div>
      );
    } else if (status === 2) {
      return (
        <div>
          <div
            style={{
              position: 'absolute',
              left: '-15px',
              top: '-15px',
            }}
          >
            <MaterialIcon
              icon="star_border"
              size={30}
              color="#363636"
            />
          </div>
          <div
            style={{
              position: 'absolute',
              left: '-12.5px',
              top: '-13px',
            }}
          >
            <MaterialIcon icon="star_rate" size={25} color="white" />
          </div>
        </div>
      );
    } else if (status === 3) {
      return (
        <div>
          <div
            style={{
              position: 'absolute',
              left: '-15px',
              top: '-15px',
            }}
          >
            <MaterialIcon
              icon="star_border"
              size={30}
              color="#ee0158"
            />
          </div>
          <div
            style={{
              position: 'absolute',
              left: '-12.5px',
              top: '-13px',
            }}
          >
            <MaterialIcon
              icon="star_rate"
              size={25}
              color="#f885a3"
            />
          </div>
        </div>
      );
    }
  }

  getFSTab() {
    return (
      <FFLocations
        locations={this.props.alllocations}
        addLocation={this.addLocation}
        customEvent={this.props.customEvent}
      />
    );
  }

  getContent(
    mode,
    newEvent,
    filteredArray,
    connections,
    fullScreen,
    hateBundle,
    nearbyRegions,
    userMoodYes,
    userMoodNo,
    selectedTags,
    guideMode,
  ) {
    if (mode === 1) {
      return this.getRecommendedTab(
        this.state.recMode,
        newEvent,
        filteredArray,
        connections,
        fullScreen,
        hateBundle,
        nearbyRegions,
        userMoodYes,
        userMoodNo,
        selectedTags,
        guideMode,
      );
    } else if (mode === 2) {
      return this.getFSTab();
    }
  }

  getTab(tabMode, tabLable, mode) {
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tabLable}
          </div>
        </a>
      );
    }
  }

  getModalHeader = (value) => {
    return (
      <Row className="no-margin">
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
        <Col lg={8} md={8} sm={8} xs={8}>
          <center>
            <span className="page-header white">Update Filters</span>
          </center>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
          <a onClick={() => this.closeFilterModal()}>
            <MaterialIcon icon="check" size={25} color="white" />
          </a>
        </Col>
      </Row>
    );
  };

  setDistanceFilter = (value) => {
    this.setState({
      distanceFilterState: value,
    });
  };

  getEnterainerOptions(entertainment) {
    let selectOptions = [];
    entertainment.forEach((entertainer) => {
      selectOptions.push({
        value: entertainer.slug,
        label: entertainer.entertainmentTitle,
      });
    });
    return selectOptions;
  }

  getSelectOptions() {
    let locations = [];
    if (
      this.props.alllocations !== undefined &&
      Array.isArray(this.props.alllocations) === true
    ) {
      locations = this.props.alllocations;
    }

    // if (
    //   this.state.locations !== undefined &&
    //   this.state.locations.length !== 0
    // ) {
    //   locations = this.state.locations;
    // } else if (
    //   this.props.uauth.guide !== undefined &&
    //   this.props.uauth.guide.locationsWithDistance !== undefined &&
    //   this.props.uauth.guide.locationsWithDistance.length !== 0
    // ) {
    //   locations = this.props.uauth.guide.locationsWithDistance;
    // }

    let allTags = [];
    let selectOptions = [];

    locations.forEach((location) => {
      selectOptions.push({
        value: location.locationId,
        label: location.locationTitle,
      });

      // if (location.tagsBeverages !== undefined) {
      //   location.tagsBeverages.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsSettings !== undefined) {
      //   location.tagsSettings.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsCuisine !== undefined) {
      //   location.tagsCuisine.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsHours !== undefined) {
      //   location.tagsHours.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsDateType !== undefined) {
      //   location.tagsDateType.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsLocationType !== undefined) {
      //   location.tagsLocationType.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsEventType !== undefined) {
      //   location.tagsEventType.forEach((tag) => {
      //     let status = allTags.includes(tag);
      //     if (status === false) {
      //       allTags.push(tag);
      //     }
      //   });
      // }

      // if (location.tagsMain !== undefined) {
      //   let status = allTags.includes(location.tagsMain);
      //   if (status === false) {
      //     allTags.push(location.tagsMain);
      //   }
      // }
    });

    // this.state.tagFilters.forEach((tagx, indexA) => {
    //   tagx.tags.forEach((tag, indexB) => {
    //     let status = allTags.includes(tag.tag);
    //     if (status === true) {
    //       selectOptions.push({
    //         value: tag,
    //         label: tag.tag,
    //       });
    //     }
    //   });
    // });

    // Include Entertainers in Mood

    // if (this.props.entertainment !== undefined) {
    //   this.props.entertainment.forEach((entertainer) => {
    //     selectOptions.push({
    //       value: entertainer.slug + '-xxxx',
    //       label: entertainer.entertainmentTitle,
    //     });
    //   });
    // }

    let reversedOptions = selectOptions.reverse();
    // this.setTagFilters(updatedTagFilters);
    return reversedOptions;
  }

  getButton(value, distance, state) {
    if (value !== state) {
      return (
        <button
          className="redbutton full-width"
          onClick={() => {
            this.setDistanceFilter(value);
          }}
        >
          {distance}
        </button>
      );
    } else {
      return (
        <button className="greybutton full-width">{distance}</button>
      );
    }
  }

  getRegions(regions) {
    let selectOptions = [];
    if (regions !== undefined) {
      regions.forEach((region) => {
        selectOptions.push({
          value: region.name,
          label: region.name,
        });
      });
    }
    let sorted = selectOptions.sort((a, b) =>
      a.value.localeCompare(b.value),
    );
    return sorted;
  }

  setMoodModalState(status) {
    this.setState({
      moodSet: status,
    });
    this.searchUpdated('');
  }

  // criteria Handlers
  selectRegionChange = (values) => {
    let bundle = {
      tagFilters: this.props.taRequest.tagFilters,
      distance: this.props.taRequest.distance,
      startLocation: values.value,
      eventCats: this.props.taRequest.eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
      reroll: this.props.taRequest.reroll,
    };
    this.props.getTaRequest(bundle);
    this.setState({
      selectedRegion: values,
      participantsType: values.value,
      regionInputOn: true,
    });

    let regionLat = '';
    let regionLong = '';

    if (
      values.values !== 'current' &&
      values.values !== 'lastLocation'
    ) {
      const docDetails = query('name')
        .is(values.value)
        .on(this.props.regions);

      regionLong = docDetails[0].long;
      regionLat = docDetails[0].lat;
      this.addDistance(
        this.props.alllocations,
        regionLat,
        regionLong,
        false,
        true,
      );
    }
    this.state.register.forEach((id) => {
      this.setState({ [id + 'carouselPosition']: 0 });
    });

    let nearbyRegions = [];
    this.props.regions.forEach((region) => {
      let regionDistance = geolib.getDistance(
        { latitude: region.long, longitude: region.lat },
        {
          latitude: regionLong,
          longitude: regionLat,
        },
      );
      let regionDistanceMiles = geolib.convertUnit(
        'mi',
        regionDistance,
        2,
      );
      if (regionDistanceMiles < 2) {
        nearbyRegions.push(region);
      }
    });

    this.setState({
      nearbyRegions,
    });
  };

  // this.searchUpdated('');

  participantsTypeChange(event) {
    let value = event.target.value;

    if (value === 'current') {
      let bundle = {
        tagFilters: this.props.taRequest.tagFilters,
        distance: this.props.taRequest.distance,
        startLocation: event.target.value,
        eventCats: this.props.taRequest.eventCats,
        currentLatitude: this.props.taRequest.currentLatitude,
        currentLongitude: this.props.taRequest.currentLongitude,
        closestRegion: this.props.taRequest.closestRegion,
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
        reroll: this.props.taRequest.reroll,
      };

      this.addDistance(
        this.props.alllocations,
        this.props.taRequest.currentLongitude,
        this.props.taRequest.currentLatitude,
        false,
        true,
      );

      this.props.getTaRequest(bundle);
      this.setState({
        participantsType: event.target.value,
      });
    } else if (value === 'lastLocation') {
      let lastLocation =
        this.props.customEvent.event.locations[
          this.props.customEvent.event.locations.length - 1
        ];

      let lastLocationRegion = 'lastLocation';
      // if (lastLocation.address.region !== undefined) {
      //   lastLocationRegion = lastLocation.address.region;
      // }

      let bundle = {
        tagFilters: this.props.taRequest.tagFilters,
        distance: this.props.taRequest.distance,
        startLocation: lastLocationRegion,
        eventCats: this.props.taRequest.eventCats,
        currentLatitude: this.props.taRequest.currentLatitude,
        currentLongitude: this.props.taRequest.currentLongitude,
        closestRegion: this.props.taRequest.closestRegion,
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
        reroll: this.props.taRequest.reroll,
      };

      this.addDistance(
        this.props.alllocations,
        lastLocation.address.longitude,
        lastLocation.address.latitude,
        false,
        true,
      );

      this.props.getTaRequest(bundle);
      this.setState({
        participantsType: event.target.value,
      });
    } else {
      let bundle = {
        tagFilters: this.props.taRequest.tagFilters,
        distance: this.props.taRequest.distance,
        startLocation: this.state.selectedRegion.value,
        eventCats: this.props.taRequest.eventCats,
        currentLatitude: this.props.taRequest.currentLatitude,
        currentLongitude: this.props.taRequest.currentLongitude,
        closestRegion: this.props.taRequest.closestRegion,
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
        reroll: this.props.taRequest.reroll,
      };

      const docDetails = query('name')
        .is(this.state.selectedRegion.value)
        .on(this.props.regions);

      this.addDistance(
        this.props.alllocations,
        docDetails[0].lat,
        docDetails[0].long,
        false,
        true,
      );
      this.props.getTaRequest(bundle);
      this.setState({
        participantsType: this.state.selectedRegion.value,
      });
    }
    this.state.register.forEach((id) => {
      this.setState({ [id + 'carouselPosition']: 0 });
    });
  }

  hateCheck(tagFilters) {
    // Get the current Selected Filters
    let selectedTags = [];
    let plusTagNames = [];
    let plusTags = [];
    tagFilters !== undefined &&
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          tag.plus.forEach((tagx) => {
            if (tag.checked === true) {
              let status = plusTagNames.includes(tagx.tag);
              if (status === false) {
                plusTags.push(tagx);
                plusTagNames.push(tagx.tag);
              }
              selectedTags.push(tag.tag);
            }
          });
        });
      });

    let match = false;
    let hateWord = '';
    let hater = '';
    if (
      this.props.newEvent !== undefined &&
      Object.keys(this.props.newEvent.invitedUsers).length !== 0 &&
      this.props.connectionsTagsAndFavs !== undefined &&
      this.props.connectionsTagsAndFavs[0] !== undefined &&
      this.props.connectionsTagsAndFavs[0].tags !== undefined
    ) {
      selectedTags.forEach((tagx, y) => {
        this.props.connectionsTagsAndFavs.forEach((guest, y) => {
          Object.keys(guest.tags.hate).forEach((key) => {
            guest.tags.hate[key].forEach((otherUserTag) => {
              if (tagx === otherUserTag) {
                match = true;
                hateWord = otherUserTag;
                hater = guest.firstName;
              }
            });
          });
        });
      });
    }

    let hateBundle = {
      match,
      hateWord,
      hater,
    };
    return hateBundle;
  }

  setEventCats() {
    let updatedTags = [];
    let tagFilters = [];
    if (this.props.taRequest.tagFilters.length > 0) {
      this.props.taRequest.tagFilters.forEach((tagcat, x) => {
        tagFilters.push(tagcat);
      });
    } else {
      this.props.tags.forEach((tagcat, x) => {
        tagFilters.push(tagcat);
      });
      tagFilters.forEach((tagcat, x) => {
        tagcat.tags.map((tag, y) => {
          tagFilters[x].tags[y].status = 1;
          tagFilters[x].tags[y].checked = false;
          tagFilters[x].tags[y].parentindex = x;
          tagFilters[x].tags[y].tagindex = y;
        });
      });
    }

    let eventCats = {
      eat: false,
      watch: false,
      do: false,
      startingCat: this.props.taRequest.eventCats.startingCat,
      endCat: this.props.taRequest.eventCats.endCat,
    };
    if (this.state.Do === true) {
      eventCats.do = true;
    }
    if (this.state.Watch === true) {
      eventCats.watch = true;
    }

    if (this.state.Dine === true) {
      eventCats.eat = true;
    }
    // if (this.props.taRequest.eventCats.eat === true) {
    //   tagFilters.forEach((tagCat) => {
    //     tagCat.tags.forEach((tag) => {
    //       if (tag.tag === 'Eat') {
    //         tagFilters[tag.parentindex].tags[
    //           tag.tagindex
    //         ].checked = true;
    //       }
    //     });
    //   });
    //   eventCats.eat = true;
    // }

    if (this.state.Music === true) {
      eventCats.music = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Music') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Theater === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Theater') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.theater = true;
    }

    if (this.state.HappyHour === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Happy Hour') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.happyHour = true;
    }

    if (this.state.Brunch === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Brunch') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.brunch = true;
    }

    if (this.state.Dinner === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Dinner') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.dinner = true;
    }

    if (this.state.Dessert === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Dessert') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.dessert = true;
    }

    if (this.state.Breakfast === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Breakfast') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.breakfast = true;
    }

    if (this.state.Lunch === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Lunch') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.lunch = true;
    }

    if (this.state.Outdoors === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Outdoors') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.outdoors = true;
    }

    if (this.state.Museum === true) {
      eventCats.museum = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Museum') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Golf === true) {
      eventCats.golf = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Golf') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Drinks === true) {
      eventCats.drinks = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Drinks') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Comedy === true) {
      eventCats.comedy = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Comedy Show') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Zoo === true) {
      eventCats.zoo = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Zoo') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    // if (this.state.Do === true) {
    //   tagFilters.forEach((tagCat) => {
    //     tagCat.tags.forEach((tag) => {
    //       if (tag.tag === 'Do') {
    //         tagFilters[tag.parentindex].tags[
    //           tag.tagindex
    //         ].checked = true;
    //       }
    //     });
    //   });
    // }

    // if (this.state.Watch === true) {
    //   tagFilters.forEach((tagCat) => {
    //     tagCat.tags.forEach((tag) => {
    //       if (tag.tag === 'Watch') {
    //         tagFilters[tag.parentindex].tags[
    //           tag.tagindex
    //         ].checked = true;
    //       }
    //     });
    //   });
    // }

    // if (this.state.Dine === true) {
    //   tagFilters.forEach((tagCat) => {
    //     tagCat.tags.forEach((tag) => {
    //       if (tag.tag === 'Eat') {
    //         tagFilters[tag.parentindex].tags[
    //           tag.tagindex
    //         ].checked = true;
    //       }
    //     });
    //   });
    // }

    // if (this.props.taRequest.eventCats.eat === true) {
    //   tagFilters.forEach((tagCat) => {
    //     tagCat.tags.forEach((tag) => {
    //       if (tag.tag === 'Eat') {
    //         tagFilters[tag.parentindex].tags[
    //           tag.tagindex
    //         ].checked = true;
    //       }
    //     });
    //   });
    //   eventCats.eat = true;
    // }

    this.props.tags.forEach((tagCat) => {
      if (tagCat.sid == 'dateType') {
        tagCat.tags.forEach((tag) => {
          if (
            this.state['type' + tag.tag] !== undefined &&
            this.state['type' + tag.tag] === true
          ) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      }
    });
    const tagsBeverageType = [];
    if (this.state.beverageType !== undefined) {
      this.state.beverageType.forEach((doc) => {
        tagsBeverageType.push(doc.value);
      });
    }
    tagsBeverageType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    const tagsCuisineType = [];
    if (this.state.userMoodYes !== undefined) {
      this.state.userMoodYes.forEach((doc) => {
        tagsCuisineType.push(doc.value);
      });
    }
    tagsCuisineType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    const tagsGenreType = [];
    if (this.state.genreType !== undefined) {
      this.state.genreType.forEach((doc) => {
        tagsGenreType.push(doc.value);
      });
    }
    tagsGenreType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    const tagsEventType = [];
    if (this.state.eventTypes !== undefined) {
      this.state.eventTypes.forEach((doc) => {
        tagsEventType.push(doc.value);
      });
    }
    tagsEventType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    if (eventCats.eat === false && eventCats.watch === true) {
      eventCats.startingCat = 2;
      eventCats.endCat = 2;
    }

    let bundle = {
      tagFilters: tagFilters,
      distance: this.props.taRequest.distance,
      startLocation: this.props.taRequest.startLocation,
      eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
      reroll: true,
    };
    this.props.getTaRequest(bundle);
  }

  reFilter(step) {
    this.setState({
      multiLocationQuestions: true,
      step: step,
      reFilterMode: true,
      fullScreen: true,
    });
  }

  updateEventCats(newTagFilters) {
    let tagFilters = [];
    if (newTagFilters.length > 0) {
      newTagFilters.forEach((tagcat, x) => {
        tagFilters.push(tagcat);
      });
    } else {
      this.props.tags.forEach((tagcat, x) => {
        tagFilters.push(tagcat);
      });
      tagFilters.forEach((tagcat, x) => {
        tagcat.tags.map((tag, y) => {
          tagFilters[x].tags[y].status = 1;
          tagFilters[x].tags[y].checked = false;
          tagFilters[x].tags[y].parentindex = x;
          tagFilters[x].tags[y].tagindex = y;
        });
      });
    }

    let eventCats = this.props.taRequest.eventCats;

    if (this.state.Music === true) {
      eventCats.music = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Music') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Theater === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Theater') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.theater = true;
    }

    if (this.state.HappyHour === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Happy Hour') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.happyHour = true;
    }

    if (this.state.Brunch === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Brunch') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.brunch = true;
    }

    if (this.state.Dine === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Eat') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Watch === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Watch') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Dinner === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Dinner') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.dinner = true;
    }

    if (this.state.Dessert === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Dessert') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.dessert = true;
    }

    if (this.state.Breakfast === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Breakfast') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.breakfast = true;
    }

    if (this.state.Lunch === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Lunch') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.lunch = true;
    }

    if (this.state.Outdoors === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Outdoors') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.outdoors = true;
    }

    if (this.state.Museum === true) {
      eventCats.museum = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Museum') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Golf === true) {
      eventCats.golf = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Golf') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Drinks === true) {
      eventCats.drinks = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Drinks') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    this.props.tags[9].tags.forEach((tagx) => {
      if (this.state[tagx.tag] === true) {
        eventCats[tagx.tag] = true;
        tagFilters.forEach((tagCat) => {
          tagCat.tags.forEach((tag) => {
            if (tag.tag === tagx.tag) {
              tagFilters[tag.parentindex].tags[
                tag.tagindex
              ].checked = true;
            }
          });
        });
      }
    });

    if (this.state.Comedy === true) {
      eventCats.comedy = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Comedy Show') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Zoo === true) {
      eventCats.zoo = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Zoo') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    this.props.tags.forEach((tagCat) => {
      if (tagCat.sid == 'dateType') {
        tagCat.tags.forEach((tag) => {
          if (
            this.state['type' + tag.tag] !== undefined &&
            this.state['type' + tag.tag] === true
          ) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      }
    });
    const tagsBeverageType = [];
    if (this.state.beverageType !== undefined) {
      this.state.beverageType.forEach((doc) => {
        tagsBeverageType.push(doc.value);
      });
    }
    tagsBeverageType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    const tagsCuisineType = [];
    if (this.state.userMoodYes !== undefined) {
      this.state.userMoodYes.forEach((doc) => {
        tagsCuisineType.push(doc.value);
      });
    }
    tagsCuisineType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    const tagsGenreType = [];
    if (this.state.genreType !== undefined) {
      this.state.genreType.forEach((doc) => {
        tagsGenreType.push(doc.value);
      });
    }
    tagsGenreType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    let bundle = {
      tagFilters: tagFilters,
      distance: this.props.taRequest.distance,
      startLocation: this.props.taRequest.startLocation,
      eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
      reroll: true,
    };

    this.props.getTaRequest(bundle);
  }

  changeDate() {
    let customEvent = this.props.customEvent;
    customEvent.initialMoodSet = false;

    this.props.getCe(customEvent);

    let bundle = {
      tagFilters: this.props.taRequest.tagFilters,
      distance: this.props.taRequest.distance,
      startLocation: this.props.taRequest.startLocation,
      eventCats: this.props.taRequest.eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
      reroll: true,
    };
    this.props.getTaRequest(bundle);

    this.props.history.push('/event/create/home/1');
  }

  setStep(step) {
    setTimeout(function () {
      window.scrollTo(0, 300);
    }, 2);
    // 4 means cancel
    if (step !== 4) {
      this.setEventCats();

      if (step === 3) {
        this.setState({
          multiLocationQuestions: false,
          reFilterMode: false,
        });
      }
      if (step === 2) {
        let newTagFilters = _.cloneDeep(
          this.props.taRequest.tagFilters,
        );

        if (newTagFilters.length === 0) {
          newTagFilters = _.cloneDeep(this.props.tags);
        }

        // if (this.state.Dine === true) {
        //   newTagFilters[8].tags[2].checked = true;
        // }

        // if (this.state.Watch === true) {
        //   newTagFilters[8].tags[1].status = 11;
        //   newTagFilters[8].tags[1].checked = true;
        // }

        // if (this.state.moreDining === true) {
        //   this.setState({
        //     guideMode: 1,
        //   });
        //   newTagFilters[8].tags[1].checked = false;
        // }

        // if (this.state.moreEntertainment === true) {
        //   this.setState({
        //     guideMode: 2,
        //   });
        //   newTagFilters[8].tags[2].checked = false;
        // }

        let bundle = {
          tagFilters: newTagFilters,
          distance: this.props.taRequest.distance,
          startLocation: this.props.taRequest.startLocation,
          eventCats: this.props.taRequest.eventCats,
          currentLatitude: this.props.taRequest.currentLatitude,
          currentLongitude: this.props.taRequest.currentLongitude,
          closestRegion: this.props.taRequest.closestRegion,
          userMoodYes: this.props.taRequest.userMoodYes,
          userMoodNo: this.props.taRequest.userMoodNo,
          reroll: false,
        };
        this.props.getTaRequest(bundle);
        this.updateEventCats(newTagFilters);
      }
    } else {
      this.setState({
        fullScreen: false,
        multiLocationQuestions: false,
        reFilterMode: false,
      });
    }
    this.setState({
      step,
    });
  }

  getStepOneFooter(step) {
    return (
      <div className="guestListNav">
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <a
                onClick={() => {
                  this.setStep(4);
                }}
                className="left"
              >
                <span className="center solidredbutton">Cancel</span>
              </a>

              {/* {step !== 2 && (
                <a
                  onClick={() => {
                    this.setStep(1);
                  }}
                  className="left"
                >
                  <span className="center solidredbutton">Prev</span>
                </a>
              )} */}

              <div>
                {this.state.Dine === true ||
                this.state.Watch === true ||
                this.state.Do === true ? (
                  <a
                    onClick={() => {
                      this.setStep(step);
                    }}
                    className="right"
                  >
                    <span className="center solidredbutton">
                      Next
                    </span>
                  </a>
                ) : (
                  <a className="right">
                    <span className="center solidgreybutton">
                      Next
                    </span>
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  getStepFourFooter(step) {
    return (
      <div className="guestListNav">
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              {/*  Maybe add back with a Remove this event Message */}

              {/* <a
                onClick={() => {
                  this.setStep(4);
                }}
                className="left"
              >
                <span className="center solidredbutton">Cancel</span>
              </a> */}
              {/* {step !== 2 && (
                <a
                  onClick={() => {
                    this.setStep(1);
                  }}
                  className="left"
                >
                  <span className="center solidredbutton">Prev</span>
                </a>
              )} */}
              <div>
                {this.state.selectedDay === null &&
                this.props.customEvent.event.scheduledTime.date !==
                  null ? (
                  <a className="right">
                    <span className="center solidgreybutton">
                      Next
                    </span>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      this.setMultiDateSchedule();
                    }}
                    className="right"
                  >
                    <span className="center solidredbutton">
                      Next
                    </span>
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  setMultiDateSchedule() {
    let thisEvent = this.props.customEvent;
    // Update Schedule
    thisEvent.event.scheduledTime = this.setSchedule(
      this.state.selectedDay,
      this.state.selectedTime,
    );
    this.props.getCe(thisEvent);

    this.setState({
      step: 2,
    });
  }

  selectCat = (value) => {
    // let targetValue = value.target.alt;
    // if (targetValue === 'moreDining') {
    //   console.log('go1a');
    //   targetValue = 'Dine';
    // } else if (targetValue === 'moreEntertainment') {
    //   console.log('go1b');
    //   targetValue = 'Watch';
    // }

    let events = [];
    this.props.timedEvents.forEach((event) => {
      events.push(event);
    });

    if (value.target.alt === 'moreEntertainment') {
      // Weight Music
      if (this.state.weightedMusic.length === 0) {
        let weightedMusic = this.getWeightedMusic(
          this.state.allEvents,
          this.props.profile,
        );
        this.setState({
          weightedMusic,
          moreDining: false,
          moreEntertainment: value.target.checked,
          Watch: true,
          Dine: false,
        });
      } else {
        this.setState({
          moreDining: false,
          moreEntertainment: value.target.checked,
          Watch: true,
          Dine: false,
        });
      }
    } else if (value.target.alt === 'moreDining') {
      this.setState({
        moreDining: value.target.checked,
        moreEntertainment: false,
        Watch: false,
        Dine: true,
      });
    } else if (
      value.target.alt === 'Watch' ||
      value.target.alt === 'Do'
    ) {
      // Weight Music
      if (this.state.weightedMusic.length === 0) {
        let weightedMusic = this.getWeightedMusic(
          events,
          this.props.profile,
        );
        this.setState({
          weightedMusic,
          [value.target.alt]: value.target.checked,
        });
      } else {
        this.setState({
          weightedMusic: this.state.weightedMusic,
          [value.target.alt]: value.target.checked,
        });
        this.setState({
          [value.target.alt]: value.target.checked,
        });
      }
    } else {
      this.setState({
        [value.target.alt]: value.target.checked,
      });
    }
  };

  // getCategoryFieldsDateTypeTags(cattag) {
  //   const options = [];
  //   if (cattag.sid === 'dateType') {
  //     const tags = cattag.tags;
  //     tags.forEach((tag) => {
  //       options.push({
  //         value: tag.tag,
  //         label: tag.tag,
  //       });
  //     });
  //     return (
  //       <div>
  //         {/* <span className="inputLabel">{cattag.parent}</span> */}
  //         <Select
  //           value={this.state[cattag.sid]}
  //           onChange={(value) => {
  //             this.handleOnChange(cattag.sid, value);
  //           }}
  //           options={options}
  //           isMulti={true}
  //         />
  //       </div>
  //     );
  //   }
  // }

  handleCuisineChange = (
    selectName,
    value,
    userMoodYes,
    userMoodNo,
  ) => {
    let updatedTags = this.props.taRequest.tagFilters;
    if (updatedTags.length === 0) {
      updatedTags = this.props.tags;
    }

    let tagReference = [];

    this.props.tags.forEach((tagCat, x) => {
      if (
        tagCat.sid === 'cuisineType' ||
        tagCat.sid === 'beverages'
      ) {
        let newCat = tagCat;

        tagCat.tags.map((tag, y) => {
          newCat.tags[y].checked = false;
          newCat.tags[y].parentindex = x;
          newCat.tags[y].tagindex = y;
        });

        tagReference.push(newCat);
      }
    });

    if (value.length > userMoodYes.length) {
      let tag = value[value.length - 1];
      tagReference.forEach((tagCat) => {
        tagCat.tags.forEach((tagx) => {
          if (tagx.tag === tag.label) {
            updatedTags[tagx.parentindex].tags[
              tagx.tagindex
            ].checked = true;
          }
        });
      });
    } else {
      let tag = '';
      if (value.length !== 0) {
        userMoodYes.forEach((tagSet, y) => {
          let status = false;
          value.forEach((tagSetX, x) => {
            if (tagSet.label === tagSetX.label) {
              status = true;
            }
          });
          if (status === false) {
            tag = tagSet.label;
          }
        });
      } else {
        tag = userMoodYes[0].label;
      }

      tagReference.forEach((tagCat) => {
        tagCat.tags.forEach((tagx) => {
          if (tagx.tag === tag) {
            updatedTags[tagx.parentindex].tags[
              tagx.tagindex
            ].checked = false;
          }
        });
      });
    }

    this.setState({
      [selectName]: value,
      tagFilters: updatedTags,
    });
    let bundle = {
      tagFilters: updatedTags,
      distance: this.props.taRequest.distance,
      startLocation: this.props.taRequest.startLocation,
      eventCats: this.props.taRequest.eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes:
        selectName === 'userMoodYes'
          ? value
          : this.props.taRequest.userMoodYes,
      userMoodNo:
        selectName === 'userMoodNo'
          ? value
          : this.props.taRequest.userMoodNo,
      reroll: true,
    };
    this.props.getTaRequest(bundle);
  };

  getMainCuisineFields(tags, selectName, userMoodYes, userMoodNo) {
    const options = [];
    tags.forEach((cattag) => {
      if (
        cattag.sid === 'cuisineType' ||
        cattag.sid === 'beverages'
      ) {
        cattag.tags.forEach((tag) => {
          options.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      }
    });
    let sorted = options.sort((a, b) =>
      a.value.localeCompare(b.value),
    );
    return (
      <div>
        {/* <span className="inputLabel">{cattag.parent}</span> */}
        <Select
          placeholder={'Select...'}
          value={
            selectName === 'userMoodYes' ? userMoodYes : userMoodNo
          }
          onChange={(value) => {
            this.handleCuisineChange(
              selectName,
              value,
              userMoodYes,
              userMoodNo,
            );
          }}
          options={sorted}
          isMulti={true}
        />
      </div>
    );
  }

  // handleGenreChange = (fieldName, value) => {
  //   this.setState({ genreType: value });
  // };

  handleGenreChange = (selectName, value) => {
    let updatedTags = this.state.tagFilters;
    let tagReference = [];

    updatedTags.forEach((tagCat) => {
      if (tagCat.sid === 'genre') {
        tagReference.push(tagCat);
      }
    });
    if (value.length > this.state.genreType.length) {
      let tag = value[value.length - 1];
      tagReference.forEach((tagCat) => {
        tagCat.tags.forEach((tagx) => {
          if (tagx.tag === tag.label) {
            updatedTags[tagx.parentindex].tags[
              tagx.tagindex
            ].checked = true;
          }
        });
      });
    } else {
      let tag = '';
      if (value.length !== 0) {
        this.state.genreType.forEach((tagSet, y) => {
          let status = false;
          value.forEach((tagSetX, x) => {
            if (tagSet.label === tagSetX.label) {
              status = true;
            }
          });
          if (status === false) {
            tag = tagSet.label;
          }
        });
      } else {
        tag = this.state.genreType[0].label;
      }

      tagReference.forEach((tagCat) => {
        tagCat.tags.forEach((tagx) => {
          if (tagx.tag === tag) {
            updatedTags[tagx.parentindex].tags[
              tagx.tagindex
            ].checked = false;
          }
        });
      });
    }
    this.setState({
      genreType: value,
      tagFilters: updatedTags,
    });
    let bundle = {
      tagFilters: updatedTags,
      distance: this.props.taRequest.distance,
      startLocation: this.props.taRequest.startLocation,
      eventCats: this.props.taRequest.eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
      reroll: true,
    };

    this.props.getTaRequest(bundle);
  };

  handleEventTypeChange = (selectName, value) => {
    let updatedTags = this.state.tagFilters;
    let tagReference = [];
    updatedTags.forEach((tagCat) => {
      if (tagCat.sid === 'eventType') {
        tagReference.push(tagCat);
      }
    });
    if (value.length > this.state.eventTypes.length) {
      let tag = value[value.length - 1];
      tagReference.forEach((tagCat) => {
        tagCat.tags.forEach((tagx) => {
          if (tagx.tag === tag.label) {
            updatedTags[tagx.parentindex].tags[
              tagx.tagindex
            ].checked = true;
          }
        });
      });
    } else {
      let tag = '';
      if (value.length !== 0) {
        this.state.eventTypes.forEach((tagSet, y) => {
          let status = false;
          value.forEach((tagSetX, x) => {
            if (tagSet.label === tagSetX.label) {
              status = true;
            }
          });
          if (status === false) {
            tag = tagSet.label;
          }
        });
      } else {
        tag = this.state.eventTypes[0].label;
      }

      tagReference.forEach((tagCat) => {
        tagCat.tags.forEach((tagx) => {
          if (tagx.tag === tag) {
            updatedTags[tagx.parentindex].tags[
              tagx.tagindex
            ].checked = false;
          }
        });
      });
    }
    this.setState({
      eventTypes: value,
      tagFilters: updatedTags,
    });
    let bundle = {
      tagFilters: updatedTags,
      distance: this.props.taRequest.distance,
      startLocation: this.props.taRequest.startLocation,
      eventCats: this.props.taRequest.eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
      reroll: true,
    };
    this.props.getTaRequest(bundle);
  };

  getMainGenreFields(cattag) {
    const options = [];
    if (cattag.sid === 'genre') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });

      let sorted = options.sort((a, b) =>
        a.value.localeCompare(b.value),
      );
      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            placeholder={'Certain Genre?'}
            value={this.state.genreType}
            onChange={(value) => {
              this.handleGenreChange(cattag.sid, value);
            }}
            options={sorted}
            isMulti={true}
          />
        </div>
      );
    }
  }

  getEventTypeFields(cattag, placeholder) {
    const options = [];
    if (cattag.sid === 'eventType') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });

      let sorted = options.sort((a, b) =>
        a.value.localeCompare(b.value),
      );
      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            placeholder={placeholder}
            value={this.state.eventTypes}
            onChange={(value) => {
              this.handleEventTypeChange(cattag.sid, value);
            }}
            options={sorted}
            isMulti={true}
          />
        </div>
      );
    }
  }

  getBox = (tag) => {
    return (
      <div>
        <input
          type="checkbox"
          checked={this.state['type' + tag]}
          onChange={this.selectCat}
          className="closedCheckBox"
          style={{
            width: '20px',
            height: '20px',
            marginRight: '5px',
            top: '2px',
          }}
          alt={'type' + tag}
        />
        <span>{tag}</span>
      </div>
    );
  };

  getCategoryFieldsDateTypeTags(tagsx) {
    const optionsEven = [];
    const optionsOdd = [];
    tagsx.forEach((cattag, i) => {
      if (cattag.sid === 'dateType') {
        const tags = cattag.tags;
        let count = tags.length;
        let half = count / 2;

        let alphaTags = [];
        tags.forEach((tag, i) => {
          alphaTags.push(tag.tag);
        });
        let sorted = alphaTags.sort((a, b) => a.localeCompare(b));
        sorted.forEach((tag, i) => {
          if (tag !== 'Weekend Getaway')
            if (i < half) {
              optionsEven.push({
                value: tag,
                label: tag,
              });
            } else {
              optionsOdd.push({
                value: tag,
                label: tag,
              });
            }
        });
      }
    });

    return (
      <Row className="no-margin">
        <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
          <div>
            {optionsEven.map((tag, index) => (
              <div>{this.getBox(tag.value)}</div>
            ))}
          </div>
        </Col>
        <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
          <div>
            {optionsOdd.map((tag, index) => (
              <div>{this.getBox(tag.value)}</div>
            ))}
          </div>
        </Col>
      </Row>
    );
  }

  getEventTypeCheckBox(eventType) {
    return (
      <div>
        <input
          type="checkbox"
          checked={this.state[eventType]}
          onChange={this.selectCat}
          className="closedCheckBox"
          style={{
            width: '20px',
            height: '20px',
            marginRight: '5px',
            top: '2px',
          }}
          alt={eventType}
        />
        <span>{eventType}</span>
      </div>
    );
  }

  // Schedule Functions

  // Schedule Functions

  handleDayClick = (day, { selected }) => {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  };

  onTimeChange = (time) => {
    this.setState({ selectedTime: time });
  };

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    let t = to.substr(0, to.lastIndexOf('/'));
    return t;
  }

  getWhen(event) {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 'tu' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];

    let days = [];

    if (event.scheduledTime.dates.repeatDays !== undefined) {
      event.scheduledTime.dates.repeatDays.forEach((day) => {
        let eventDetailsx = query('short').is(day).on(dayArray);
        let eventx = eventDetailsx[0];
        days.push(eventx.full);
      });
      let dayText = '';
      days.forEach((day, index) => {
        if (days.length === 1) {
          dayText = day;
        } else if (days.length === 7) {
          dayText = 'Day';
        } else if (days.length === 2) {
          if (index === 0) {
            dayText = day;
          } else {
            dayText = dayText + ' & ' + day;
          }
        } else if (
          days.length > 2 &&
          event.scheduledTime.dates.timesSelected > 1
        ) {
          if (index === 0) {
            dayText = day + 's';
          } else if (days.length - 1 === index) {
            dayText = dayText + ', & ' + day + 's';
          } else {
            dayText = dayText + ',  ' + day + 's';
          }
        } else if (
          days.length > 2 &&
          event.scheduledTime.dates.timesSelected === 1
        ) {
          if (index === 0) {
            dayText = day;
          } else if (days.length - 1 === index) {
            dayText = dayText + ', & ' + day;
          } else {
            dayText = dayText + ',  ' + day;
          }
        }
      });

      let weekly = '';
      if (event.scheduledTime.dates.timesSelected === 1) {
        weekly = 'week';
      } else {
        weekly = event.scheduledTime.dates.timesSelected + ' weeks ';
      }
      let statement = '';
      if (event.scheduledTime.dates.timesSelected === 1) {
        if (event.scheduledTime.dates.repeatType === 'Weekly') {
          statement = 'every ' + dayText;
        }
      } else {
        if (event.scheduledTime.dates.repeatType === 'Weekly') {
          statement = 'every ' + weekly + ' on ' + dayText;
        }
      }

      return statement;
    } else {
      let statement = '';
      event.scheduledTime.dates.multiDates.forEach((date, index) => {
        if (index === 0) {
          let currentTime = moment(date.date).format('MMMM D');
          statement = statement + currentTime + ' ';
        } else if (
          (index = event.scheduledTime.dates.multiDates.length - 1)
        ) {
          let currentTime = moment(date.date).format('D');
          statement = statement + '& ' + currentTime;
        } else {
          let currentTime = moment(date.date).format('D');
          statement = statement + ',' + currentTime;
        }
      });
      return statement;
    }
  }

  setDate(selectedDay) {
    let bundle = {
      date: '',
      time: '',
      timeString: '',
      timestamp: '',
    };
    if (
      selectedDay !== '' &&
      selectedDay !== undefined &&
      selectedDay !== null
    ) {
      const timestamp = moment(selectedDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.date = dateString;
      bundle.timestamp = timestampReady;
    }
    return bundle;
  }

  setSchedule(selectedDay, selectedTime) {
    let bundle = {
      date: '',
      time: '',
      timeString: '',
      timestamp: '',
    };
    // Check if Time Set
    if (
      selectedTime !== '' &&
      selectedTime !== undefined &&
      selectedTime !== null
    ) {
      const datestring = String(selectedTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.time = timeValue;
      bundle.timeString = selectedTime._d;
    }
    // Check for Date
    if (
      selectedDay !== '' &&
      selectedDay !== undefined &&
      selectedDay !== null
    ) {
      const timestamp = moment(selectedDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.date = dateString;
      bundle.timestamp = timestampReady;
    }
    return bundle;
  }

  isDayDisabled = (day) => {
    // Remember, in this scenario - TRUE means the day is disabled. FALSE means it is not disabled.
    let locationClosed = false;
    let eventNotAvailable = false;
    let noItineraryAvailable = null;
    let locations = this.props.customEvent.event.locations;
    const today = moment().format('YYYY-MM-DD');
    const timestamp = moment(day).unix();
    const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
    let dayName = moment(day).format('dddd');
    let lowercase = dayName.toLowerCase();
    if (lowercase === 'saturday') {
      lowercase = 'saterday';
    }
    let openString = lowercase + 'Open';
    // Is this day in the past?
    let pastDate = moment(dateString).isBefore(today);
    // First we check if date is already in the past
    if (pastDate === false) {
      // check for events with multiple days and build out the multiDates Array
      if (locations.length !== 0) {
        locations.forEach((location) => {
          // If this is an event lets get to it.
          if (
            location.eventId !== undefined &&
            location.eventId !== ''
          ) {
            //This is an Event
            let multiDates = [];
            let match = false;
            let eventDetails = query('eventId')
              .is(location.eventId)
              .on(this.props.timedEvents);

            if (
              eventDetails[0].scheduledTime.dates !== undefined &&
              eventDetails[0].scheduledTime.dates.multiDates !==
                undefined
            ) {
              eventDetails[0].scheduledTime.dates.multiDates.forEach(
                (date) => {
                  multiDates.push(
                    moment(date.date).format('YYYY-MM-DD'),
                  );
                },
              );
            }
            if (multiDates.length !== 0) {
              multiDates.forEach((date) => {
                let check = moment(dateString).isSame(date);
                if (check === true) {
                  match = true;
                }
              });
            }
            if (match === false) {
              eventNotAvailable = true;
            }
          } else {
            if (
              location !== undefined &&
              location.hours !== undefined &&
              location.hours[lowercase][openString] !== undefined &&
              location.hours[lowercase][openString] === true
            ) {
              locationClosed = true;
            } else if (location.hours === undefined) {
              let locationDetails = query('locationId')
                .is(location.locationId)
                .on(this.props.locations);

              if (
                locationDetails[0] !== undefined &&
                locationDetails[0].hours !== undefined &&
                locationDetails[0].hours[lowercase][openString] !==
                  undefined &&
                locationDetails[0].hours[lowercase][openString] ===
                  true
              ) {
                locationClosed = true;
              }
            }
          }
        });
      } else {
        let check = moment(dateString).isSame(today);
        let past = moment(dateString).isBefore(today);
        if (past === false || check === true) {
          noItineraryAvailable = true;
        } else {
          noItineraryAvailable = false;
        }
      }
    }
    if (
      locationClosed === true ||
      eventNotAvailable === true ||
      pastDate === true ||
      noItineraryAvailable === false
    ) {
      return true;
    } else {
      return false;
    }
  };

  // MAKE CALENDAT A COMPONENT!!!!!

  getScheduleTab() {
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
      },
    };

    let eventInfo = { eventTitle: '' };
    let locations = this.props.customEvent.event.locations;
    let calendarLock = false;
    let multiDates = [];

    locations.forEach((location) => {
      // If this is an event lets get to it.
      if (location.eventId !== undefined && location.eventId !== '') {
        let eventDetails = query('eventId')
          .is(location.eventId)
          .on(this.props.timedEvents);
        if (eventDetails[0] !== undefined) {
          eventInfo = eventDetails[0];
        }
        if (eventInfo !== null) {
          if (
            (eventInfo.scheduledTime !== undefined &&
              eventInfo.scheduledTime.dates !== undefined &&
              eventInfo.scheduledTime.dates.repeat !== undefined &&
              eventInfo.scheduledTime.dates.repeat === true) ||
            (eventInfo.scheduledTime.dates.multiDates !== undefined &&
              eventInfo.scheduledTime.dates.multiDates.length > 1)
          ) {
            calendarLock = false;
          } else {
            calendarLock = true;
          }
        }
      }
    });

    // function isDayDisabled(day) {
    //   console.log('dayx 1');
    //   // Remember, in this scenario - TRUE means the day is disabled. FALSE means it is not disabled.
    //   let locationClosed = false;
    //   let eventNotAvailable = false;
    //   let noItineraryAvailable = null;
    //   const today = moment().format('YYYY-MM-DD');
    //   const timestamp = moment(day).unix();
    //   const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
    //   let dayName = moment(day).format('dddd');
    //   let lowercase = dayName.toLowerCase();
    //   if (lowercase === 'saturday') {
    //     lowercase = 'saterday';
    //   }
    //   let openString = lowercase + 'Open';
    //   // Is this day in the past?
    //   let pastDate = moment(dateString).isBefore(today);
    //   // First we check if date is already in the past
    //   if (pastDate === false) {
    //     // check for events with multiple days and build out the multiDates Array
    //     if (locations.length !== 0) {
    //       locations.forEach((location) => {
    //         // If this is an event lets get to it.
    //         if (
    //           location.eventId !== undefined &&
    //           location.eventId !== ''
    //         ) {
    //           //This is an Event
    //           let multiDates = [];
    //           let match = false;
    //           let eventDetails = query('eventId')
    //             .is(location.eventId)
    //             .on(this.props.timedEvents);

    //           if (
    //             eventDetails[0].scheduledTime.dates !== undefined &&
    //             eventDetails[0].scheduledTime.dates.multiDates !==
    //               undefined
    //           ) {
    //             eventDetails[0].scheduledTime.dates.multiDates.forEach(
    //               (date) => {
    //                 multiDates.push(
    //                   moment(date.date).format('YYYY-MM-DD'),
    //                 );
    //               },
    //             );
    //           }
    //           if (multiDates.length !== 0) {
    //             multiDates.forEach((date) => {
    //               let check = moment(dateString).isSame(date);
    //               if (check === true) {
    //                 match = true;
    //               }
    //             });
    //           }
    //           if (match === false) {
    //             eventNotAvailable = true;
    //           }
    //         } else {
    //           if (
    //             location !== undefined &&
    //             location.hours !== undefined &&
    //             location.hours[lowercase][openString] !== undefined &&
    //             location.hours[lowercase][openString] === true
    //           ) {
    //             locationClosed = true;
    //           } else if (location.hours === undefined) {
    //             let locationDetails = query('locationId')
    //               .is(location.locationId)
    //               .on(this.props.locations);

    //             if (
    //               locationDetails[0] !== undefined &&
    //               locationDetails[0].hours !== undefined &&
    //               locationDetails[0].hours[lowercase][openString] !==
    //                 undefined &&
    //               locationDetails[0].hours[lowercase][openString] ===
    //                 true
    //             ) {
    //               locationClosed = true;
    //             }
    //           }
    //         }
    //       });
    //     } else {
    //       let check = moment(dateString).isSame(today);
    //       let past = moment(dateString).isBefore(today);
    //       if (past === false || check === true) {
    //         noItineraryAvailable = true;
    //       } else {
    //         noItineraryAvailable = false;
    //       }
    //     }
    //   }
    //   if (
    //     locationClosed === true ||
    //     eventNotAvailable === true ||
    //     pastDate === true ||
    //     noItineraryAvailable === false
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }

    return (
      <Container>
        {multiDates.length > 1 && (
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div style={{ padding: '15px', marginTop: '10px' }}>
                <center>
                  <span>
                    <span style={{ fontWeight: '600' }}>
                      {eventInfo.eventTitle} is{' '}
                      {this.getWhen(eventInfo)}
                    </span>
                  </span>
                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginTop: '15px',
                      marginBottom: '10px',
                      fontWeight: '500',
                      fontSize: '18px',
                      color: '#ed0259',
                    }}
                  >
                    When would you like to go?
                  </span>
                </center>
              </div>
            </Col>
          </Row>
        )}

        <Row className="no-margin">
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            {calendarLock === false ? (
              <div>
                <div className="text-center">
                  {multiDates.length > 1 ? (
                    <DayPicker
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiersStyles={modifiersStyles}
                      disabledDays={this.isDayDisabled}
                    />
                  ) : (
                    <DayPicker
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiersStyles={modifiersStyles}
                      disabledDays={this.isDayDisabled}
                    />
                  )}

                  {/* <center>
                    <p style={{ marginTop: '20px' }}>
                      {this.state.selectedDay
                        ? this.state.selectedDay.toLocaleDateString()
                        : 'Please select a day'}
                    </p>
                  </center> */}
                </div>
                <div className="text-center">
                  <span className="inputLabel">Time</span>
                  <TimePicker
                    showSecond={false}
                    defaultValue={this.state.selectedTime}
                    className="xxx"
                    onChange={this.onTimeChange}
                    format={format}
                    use12Hours
                    inputReadOnly
                  />
                </div>
              </div>
            ) : (
              <div>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div
                      style={{ padding: '15px', marginTop: '10px' }}
                    >
                      <center>
                        <span>
                          <span style={{ fontWeight: '600' }}>
                            {eventInfo.eventTitle} is{' '}
                            {this.getWhen(eventInfo)}.
                          </span>
                        </span>
                      </center>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <center>
                    {/* <h4 style={{ marginTop: '80px' }}>
                      {this.state.event.eventTitle}
                    </h4> */}

                    <h2 style={{ marginTop: '80px' }}>
                      {this.getDate(eventInfo)}
                    </h2>
                  </center>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    let guideMode = 1;
    if (this.props.taRequest !== undefined) {
      guideMode = this.getGuideMode(this.props.taRequest);
    }

    // == Get Regions for Drop Down
    let regions = this.getRegions(this.props.regions);

    // == Check for hated tags for us in "guest hates this" alert
    let hateBundle = this.hateCheck(this.props.taRequest.tagFilters);

    // if (this.state.moodSet === false) {
    //   return (
    //     <MoodModal
    //       setTagFilters={this.setTagFilters}
    //       setMoodModalState={this.setMoodModalState}
    //       otherUserTagsLove={this.state.otherUserTagsLove}
    //       otherUser={this.state.otherUser}
    //     />
    //   );
    // }

    let selectedTags = [];
    this.props.taRequest.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          let tagType = '';
          if (tagCat.sid === 'beverages') {
            tagType = 'tagsBeverages';
          } else if (tagCat.sid === 'cuisineType') {
            tagType = 'tagsCuisine';
          }
          let tagObject = {
            tag: tag.tag,
            sid: tagCat.sid,
            tagType,
          };
          selectedTags.push(tagObject);
        }
      });
    });

    // Load Screen
    if (this.state.loading) {
      return (
        <div
          // style={{
          //   backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fstllanding.png?alt=media&token=30292636-ca2a-4bfc-b7dc-4da988d518ee')`,
          //   height: '100%',
          //   backgroundSize: 'cover',
          // }}
          style={{ height: '100%' }}
        >
          {/* {this.getTitleBar()} */}
          <div
            style={{
              height: '100%',
              display: 'table',
              width: '100%',
              background:
                'linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%)',
            }}
          >
            <div
              style={{
                position: 'relative',
                textAlign: 'center',
                display: 'table-cell',
                verticalAlign: 'middle',
                top: '-100px',
              }}
            >
              <h2
                style={{
                  margin: '0px 0px 10px 0px',
                  color: 'white',
                  fontSize: '40px',
                  textShadow: '#bb4a65 1px 4px',
                }}
              >
                <img
                  style={{
                    width: '90%',
                    maxWidth: '500px',
                    margin: '0px auto',
                  }}
                  src={whitelogo}
                  className="mobileHide"
                />
                <img
                  style={{
                    width: '80%',
                    maxWidth: '500px',
                    margin: '0px auto',
                  }}
                  src={whitelogo}
                  className="mobileShow"
                />
              </h2>
              <span
                style={{
                  fontSize: '35px',
                  lineHeight: '40px',
                  color: 'white',
                  textShadow: '#bb4a65 1px 4px',
                  marginLeft: '30px',
                  paddingTop: '25px',
                }}
                className="mobileHide"
              >
                Hmmm, let me think a moment. <br />
                {/* 2 Guests */}
                {/* {this.props.customEvent.editMode === false &&
                  this.props.connectionsTagsAndFavs.length < 2 &&
                  this.props.connectionsTagsAndFavs.length !== 0 &&
                  'for you & ' +
                    this.props.connectionsTagsAndFavs[0].firstName}
                {this.props.customEvent.editMode === true &&
                  this.props.connectionsTagsAndFavs.length < 2 &&
                  'for you & ' +
                    this.props.connectionsTagsAndFavs[0].firstName} */}
                {/* 1 Guest */}
                {/* {this.props.customEvent.editMode === false &&
                  this.props.connectionsTagsAndFavs.status !==
                    undefined &&
                  this.props.connectionsTagsAndFavs.status === 0 &&
                  'just for you'} */}
                {/* More then 2 Guest */}
                {/* {this.props.customEvent.editMode === false &&
                  this.props.connectionsTagsAndFavs.length > 1 &&
                  'for your group'} */}
              </span>
              <span
                style={{
                  fontSize: '30px',
                  lineHeight: '40px',
                  color: 'white',
                  textShadow: '#bb4a65 1px 4px',
                  paddingTop: '15px',
                }}
                className="mobileShow"
              >
                Hmmm, let me think a moment. <br />
                {/* {this.props.customEvent.editMode === false &&
                  this.props.connectionsTagsAndFavs.length < 2 &&
                  this.props.connectionsTagsAndFavs.length !== 0 &&
                  'for you & ' +
                    this.props.connectionsTagsAndFavs[0].firstName}
                {this.props.customEvent.editMode === true &&
                  this.props.connectionsTagsAndFavs.length < 2 &&
                  'for you & ' +
                    this.props.connectionsTagsAndFavs[0].firstName} */}
                {/* 1 Guest */}
                {/* {this.props.customEvent.editMode === false &&
                  this.props.connectionsTagsAndFavs.status !==
                    undefined &&
                  this.props.connectionsTagsAndFavs.status === 0 &&
                  'just for you'} */}
                {/* More then 2 Guest */}
                {/* {this.props.customEvent.editMode === false &&
                  this.props.connectionsTagsAndFavs.length > 1 &&
                  'for your group'} */}
              </span>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'body {height: 100%} .toolbar{display: none}.section{max-width: 1000px} .container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }

    // Location Details Mode
    if (this.state.showLocation === true) {
      window.scrollTo(0, 0);
      return (
        <div id="home">
          {this.getLocationTitleBar()}
          {this.getLocationDetails(this.state.activeLocation)}
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 40px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }

    // ??
    if (this.state.multiLocationQuestions === true) {
      let lastLocation = {
        locationTitle: '',
      };
      if (this.props.customEvent.event.locations.length !== 0) {
        lastLocation =
          this.props.customEvent.event.locations[
            this.props.customEvent.event.locations.length - 1
          ];
      }

      let regions = this.getRegions(this.props.regions);

      if (this.state.step === 4) {
        return (
          <div id="newplans">
            {this.getTitleBar()}

            <div className="secondary-title-bar">
              <div>
                <Container>
                  <Row className="no-margin">
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding text-center"
                    >
                      <span style={{ fontStyle: 'normal' }}>
                        When should I look?
                      </span>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>

            <div>{this.getScheduleTab()}</div>
            {this.getStepFourFooter(2)}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              }}
            />
          </div>
        );
      } else if (this.state.step === 2) {
        return (
          <div id="newplans">
            {this.getTitleBar()}

            <div className="secondary-title-bar">
              <div>
                <Container>
                  <Row className="no-margin">
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding text-center"
                    >
                      <span style={{ fontStyle: 'normal' }}>
                        Where should I look?
                      </span>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>

            <div
              style={{
                padding: '15px',
                maxWidth: '600px',
                margin: '0px auto',
              }}
            >
              <div
                style={{
                  clear: 'both',
                  borderBottom: '1px solid grey',
                }}
              >
                <label
                  style={{
                    marginRight: '10px',
                    fontWeight: '500',
                    fontSize: '18px',
                    color: '#ed0259',
                  }}
                >
                  <input
                    style={{
                      marginRight: '5px',
                      float: 'left',
                      minWidth: 'unset',
                      position: 'relative',
                      width: '25px',
                      height: '25px',
                    }}
                    type="radio"
                    value="current"
                    checked={
                      this.state.participantsType === 'current'
                    }
                    onChange={this.participantsTypeChange.bind(this)}
                  />
                  Near My Current Location
                </label>
              </div>
              {this.props.customEvent.event.locations.length !==
                0 && (
                <div
                  style={{
                    clear: 'both',
                    borderBottom: '1px solid grey',
                    padding: '10px 0px 0px 0px',
                  }}
                >
                  <label
                    style={{
                      marginRight: '10px',
                      fontWeight: '500',
                      fontSize: '18px',
                      color: '#ed0259',
                    }}
                  >
                    <input
                      style={{
                        marginRight: '5px',
                        float: 'left',
                        minWidth: 'unset',
                        position: 'relative',
                        width: '25px',
                        height: '25px',
                      }}
                      type="radio"
                      value="lastLocation"
                      alt={lastLocation.locationTitle}
                      checked={
                        this.state.participantsType === 'lastLocation'
                      }
                      onChange={this.participantsTypeChange.bind(
                        this,
                      )}
                    />
                    Near {lastLocation.locationTitle}
                  </label>
                </div>
              )}

              {/* <div
              style={{
                clear: 'both',
                borderBottom: '1px solid grey',
                padding: '10px 0px 0px 0px',
              }}
            >
              <label
                style={{
                  marginRight: '10px',
                  fontWeight: '500',
                  fontSize: '18px',
                  color: '#ed0259',
                }}
              >
                <input
                  style={{
                    marginRight: '5px',
                    float: 'left',
                    minWidth: 'unset',
                    position: 'relative',
                    width: '25px',
                    height: '25px',
                  }}
                  type="radio"
                  value="home"
                  checked={this.state.participantsType === 'home'}
                  onChange={this.participantsTypeChange.bind(this)}
                />
                Near My Home
              </label>
            </div> */}

              <div
                style={{
                  clear: 'both',

                  padding: '10px 0px 0px 0px',
                }}
              >
                <input
                  style={{
                    marginRight: '5px',
                    float: 'left',
                    minWidth: 'unset',
                    position: 'relative',
                    width: '25px',
                    height: '25px',
                  }}
                  type="radio"
                  value={'region'}
                  checked={
                    this.state.participantsType !== 'current' &&
                    this.state.participantsType !== 'lastLocation'
                  }
                  onChange={this.participantsTypeChange.bind(this)}
                />

                <div
                  style={{
                    height: '50px',
                    margin: '0px auto',
                    float: 'left',
                    minWidth: '300px',
                  }}
                >
                  <Select
                    value={this.state.selectedRegion}
                    // closeMenuOnSelect={true}
                    blurInputOnSelect={false}
                    onChange={(e) =>
                      this.selectRegionChange(e, 0, 'one')
                    }
                    options={regions}
                    // onInputChange={(e) =>
                    //   this.moodTextChange(e, 0, 'one')
                    // }
                    isMulti={false}
                    placeholder={'Select area of City'}
                  />
                </div>
              </div>
              {/* <div style={{ marginTop: '30px' }}>
              <div style={{ display: 'inline-block' }}>
                <span
                  style={{
                    fontWeight: '500',
                    fontSize: '18px',
                    clear: 'both',
                    display: 'block',
                  }}
                >
                  Distance
                </span>
                <div style={{ maxWidth: '400px', float: 'left' }}>
                  <Row className="no-padding">
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      style={{ padding: '0px 5px 0px 0px' }}
                    >
                      {this.getButton(
                        1,
                        '10',
                        this.state.distanceFilterState,
                      )}
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      style={{ padding: '0px 5px 0px 0px' }}
                    >
                      {this.getButton(
                        0,
                        '50',
                        this.state.distanceFilterState,
                      )}
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      style={{ padding: '0px 5px 0px 0px' }}
                    >
                      {this.getButton(
                        2,
                        '100+',
                        this.state.distanceFilterState,
                      )}
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    clear: 'both',
                    display: 'block',
                    marginTop: '20px',
                    float: 'left',
                  }}
                >
                  {this.state.participantsType === 'current' && (
                    <span style={{}}>
                      <span style={{ fontWeight: '600' }}>
                        {miles}
                      </span>{' '}
                      Miles around my current location.
                    </span>
                  )} */}
              {/* {this.state.participantsType === 'home' && (
                    <span><span style={{fontWeight: '600'}}>{miles}</span> Miles around my home. </span>
                  )} */}

              {/* {this.state.participantsType !== 'current' && (
                    <span
                      style={{
                        clear: 'both',
                        display: 'block',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: '600',
                        }}
                      >
                        {miles}
                      </span>{' '}
                      Miles around {this.state.selectedRegion.value}
                    </span>
                  )}
                </div> */}
              {/* </div> */}
              {/* </div> */}
            </div>

            {this.getStepOneFooter(3)}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              }}
            />
          </div>
        );
      } else if (this.state.step === 1) {
        return (
          <div id="newplans" style={{ paddingBottom: '70px' }}>
            {this.getTitleBar()}

            <div className="secondary-title-bar">
              {/* <div
              style={{
                backgroundColor: '#ee0059',
                paddingBottom: '10px',
              }}
            > */}
              <div>
                <Container>
                  <Row className="no-margin">
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding text-center"
                    >
                      <span
                        style={{
                          fontStyle: 'normal',
                        }}
                      >
                        {this.props.customEvent.event.locations
                          .length !== 0
                          ? 'What would you like to add?'
                          : 'In the mood for something else?'}
                      </span>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            {this.props.customEvent.event.locations.length !== 0 ? (
              <div>
                <div
                  style={{
                    padding: '10px',
                    maxWidth: '500px',
                    margin: '0px auto',
                  }}
                >
                  <div
                    style={{
                      borderBottom: '1px solid grey',
                      padding: '10px',
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={this.state.moreDining}
                      onChange={this.selectCat}
                      className="closedCheckBox"
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '10px',
                        top: '2px',
                      }}
                      alt="moreDining"
                    />
                    <span
                      style={{
                        fontWeight: '500',
                        fontSize: '18px',
                        color: '#ed0259',
                      }}
                    >
                      Another Dining / Drinks Location
                    </span>

                    {this.state.moreDining === true && (
                      <div>
                        <div style={{ padding: '10px 0px 0px 30px' }}>
                          <span
                            style={{
                              fontWeight: '100',
                              fontSize: '16px',
                              fontStyle: 'italic',
                            }}
                          >
                            Anything Specific?
                          </span>
                        </div>
                        <div style={{ padding: '10px 0px 0px 30px' }}>
                          <Row className="no-padding">
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className="no-padding"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Breakfast}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Breakfast"
                                />
                                <span>Breakfast</span>
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Brunch}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  alt="Brunch"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                />
                                <span>Brunch</span>
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Lunch}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                />
                                <span>Lunch</span>
                              </div>
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className="no-padding"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Dinner}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Dinner"
                                />
                                <span>Dinner</span>
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Dessert}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Dessert"
                                />
                                <span>Dessert</span>
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Drinks}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Drinks"
                                />
                                <span>Drinks</span>
                              </div>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: '10px' }}>
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="no-padding"
                            >
                              <div style={{ marginTop: '5px' }}>
                                <span
                                  style={{
                                    fontWeight: '100',
                                    fontSize: '16px',
                                    fontStyle: 'italic',
                                    marginBottom: '5px',
                                    display: 'block',
                                  }}
                                >
                                  Are you in the mood for anything?
                                </span>
                                <div>
                                  {this.getMainCuisineFields(
                                    this.props.tags,
                                    'userMoodYes',
                                    this.state.userMoodYes,
                                    this.state.userMoodNo,
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row style={{ marginTop: '5px' }}>
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="no-padding"
                            >
                              <div style={{ marginTop: '5px' }}>
                                <span
                                  style={{
                                    fontWeight: '100',
                                    fontSize: '16px',
                                    fontStyle: 'italic',
                                    marginBottom: '5px',
                                    display: 'block',
                                  }}
                                >
                                  Anything you are NOT in the mood
                                  for?
                                </span>

                                <div>
                                  {this.getMainCuisineFields(
                                    this.props.tags,
                                    'userMoodNo',
                                    this.state.userMoodYes,
                                    this.state.userMoodNo,
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      borderBottom: '1px solid grey',
                      padding: '10px',
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={this.state.moreEntertainment}
                      className="closedCheckBox"
                      onChange={this.selectCat}
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '10px',
                        top: '2px',
                      }}
                      alt="moreEntertainment"
                    />
                    <span
                      style={{
                        fontWeight: '500',
                        fontSize: '18px',
                        color: '#ed0259',
                      }}
                    >
                      Some Entertainment
                    </span>
                    {this.state.moreEntertainment === true && (
                      <div>
                        <div style={{ padding: '10px 0px 0px 30px' }}>
                          <span
                            style={{
                              fontWeight: '100',
                              fontSize: '16px',
                              fontStyle: 'italic',
                            }}
                          >
                            Would you like to take in a show?
                          </span>
                        </div>
                        <div style={{ padding: '10px 0px 0px 30px' }}>
                          <Row className="no-padding">
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className="no-padding"
                            >
                              {/* {this.props.tags[9].tags.map(
                                (eventType, index) => (
                                  <div>
                                    {this.getEventTypeCheckBox(
                                      eventType.tag,
                                    )}
                                  </div>
                                ),
                              )} */}
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Music}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Music"
                                />
                                <span>Live Music</span>
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Comedy}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Comedy"
                                />
                                <span>Comedy</span>
                              </div>
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className="no-padding"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Theater}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Theater"
                                />
                                <span>Theater</span>
                              </div>
                            </Col>
                          </Row>

                          <Row style={{ marginTop: '10px' }}>
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="no-padding"
                            >
                              <div>
                                {this.props.tags.map(
                                  (cattag, index) => (
                                    <div>
                                      {this.getEventTypeFields(
                                        cattag,
                                        'Other type of event?',
                                      )}
                                    </div>
                                  ),
                                )}
                              </div>
                            </Col>
                          </Row>

                          {this.state.Music === true && (
                            <Row style={{ marginTop: '10px' }}>
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="no-padding"
                              >
                                <div>
                                  {this.props.tags.map(
                                    (cattag, index) => (
                                      <div>
                                        {this.getMainGenreFields(
                                          cattag,
                                        )}
                                      </div>
                                    ),
                                  )}
                                </div>
                              </Col>
                            </Row>
                          )}

                          <Row>
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="no-padding"
                            >
                              <div
                                style={{
                                  padding: '20px 0px 0px 0px',
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: '100',
                                    fontSize: '16px',
                                    fontStyle: 'italic',
                                  }}
                                >
                                  Something a little more active?
                                </span>
                              </div>
                              <div
                                style={{
                                  padding: '10px 0px 0px 0px',
                                }}
                              >
                                <Row className="no-padding">
                                  <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={6}
                                    className="no-padding"
                                  >
                                    <div>
                                      <input
                                        type="checkbox"
                                        checked={this.state.Outdoors}
                                        onChange={this.selectCat}
                                        className="closedCheckBox"
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          marginRight: '5px',
                                          top: '2px',
                                        }}
                                        alt="Outdoors"
                                      />
                                      <span>Get Outdoors</span>
                                    </div>
                                  </Col>
                                  <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={6}
                                    className="no-padding"
                                  >
                                    <div>
                                      <input
                                        type="checkbox"
                                        checked={this.state.Golf}
                                        onChange={this.selectCat}
                                        className="closedCheckBox"
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          marginRight: '5px',
                                          top: '2px',
                                        }}
                                        alt="Golf"
                                      />
                                      <span>Golf</span>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  padding: '10px',
                  maxWidth: '500px',
                  margin: '0px auto',
                }}
              >
                <div
                  style={{
                    borderBottom: '1px solid grey',
                    padding: '10px',
                  }}
                >
                  <input
                    type="checkbox"
                    checked={this.state.Dine}
                    onChange={this.selectCat}
                    className="closedCheckBox"
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px',
                      top: '2px',
                    }}
                    alt="Dine"
                  />
                  <span
                    style={{
                      fontWeight: '500',
                      fontSize: '18px',
                      color: '#ed0259',
                    }}
                  >
                    Get Food or Drinks
                  </span>

                  {this.state.Dine === true && (
                    <div>
                      <div style={{ padding: '10px 0px 0px 30px' }}>
                        <span
                          style={{
                            fontWeight: '100',
                            fontSize: '16px',
                            fontStyle: 'italic',
                          }}
                        >
                          Anything Specific?
                        </span>
                      </div>
                      <div style={{ padding: '10px 0px 0px 30px' }}>
                        <Row className="no-padding">
                          <Col
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            className="no-padding"
                          >
                            <div>
                              <input
                                type="checkbox"
                                checked={this.state.Breakfast}
                                onChange={this.selectCat}
                                className="closedCheckBox"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '5px',
                                  top: '2px',
                                }}
                                alt="Breakfast"
                              />
                              <span>Breakfast</span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                checked={this.state.Brunch}
                                onChange={this.selectCat}
                                className="closedCheckBox"
                                alt="Brunch"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '5px',
                                  top: '2px',
                                }}
                              />
                              <span>Brunch</span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                checked={this.state.Lunch}
                                onChange={this.selectCat}
                                className="closedCheckBox"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '5px',
                                  top: '2px',
                                }}
                              />
                              <span>Lunch</span>
                            </div>
                          </Col>
                          <Col
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            className="no-padding"
                          >
                            <div>
                              <input
                                type="checkbox"
                                checked={this.state.Dinner}
                                onChange={this.selectCat}
                                className="closedCheckBox"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '5px',
                                  top: '2px',
                                }}
                                alt="Dinner"
                              />
                              <span>Dinner</span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                checked={this.state.Dessert}
                                onChange={this.selectCat}
                                className="closedCheckBox"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '5px',
                                  top: '2px',
                                }}
                                alt="Dessert"
                              />
                              <span>Dessert</span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                checked={this.state.Drinks}
                                onChange={this.selectCat}
                                className="closedCheckBox"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '5px',
                                  top: '2px',
                                }}
                                alt="Drinks"
                              />
                              <span>Drinks</span>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            <div style={{ marginTop: '5px' }}>
                              <span
                                style={{
                                  fontWeight: '100',
                                  fontSize: '16px',
                                  fontStyle: 'italic',
                                  marginBottom: '5px',
                                  display: 'block',
                                }}
                              >
                                Are you in the mood for anything?
                              </span>
                              <div>
                                {this.getMainCuisineFields(
                                  this.props.tags,
                                  'userMoodYes',
                                  this.state.userMoodYes,
                                  this.state.userMoodNo,
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: '5px' }}>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            <div style={{ marginTop: '5px' }}>
                              <span
                                style={{
                                  fontWeight: '100',
                                  fontSize: '16px',
                                  fontStyle: 'italic',
                                  marginBottom: '5px',
                                  display: 'block',
                                }}
                              >
                                Anything you are NOT in the mood for?
                              </span>

                              <div>
                                {this.getMainCuisineFields(
                                  this.props.tags,
                                  'userMoodNo',
                                  this.state.userMoodYes,
                                  this.state.userMoodNo,
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    borderBottom: '1px solid grey',
                    padding: '10px',
                  }}
                >
                  <input
                    type="checkbox"
                    checked={this.state.Watch}
                    className="closedCheckBox"
                    onChange={this.selectCat}
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px',
                      top: '2px',
                    }}
                    alt="Watch"
                  />
                  <span
                    style={{
                      fontWeight: '500',
                      fontSize: '18px',
                      color: '#ed0259',
                    }}
                  >
                    Do Something Fun
                  </span>
                  {this.state.Watch === true && (
                    <div>
                      <div style={{ padding: '10px 0px 0px 30px' }}>
                        <span
                          style={{
                            fontWeight: '100',
                            fontSize: '16px',
                            fontStyle: 'italic',
                          }}
                        >
                          Would you like to take in a show?
                        </span>
                      </div>
                      <div style={{ padding: '10px 0px 0px 30px' }}>
                        <Row className="no-padding">
                          <Col
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            className="no-padding"
                          >
                            {/* {this.props.tags[9].tags.map(
                              (eventType, index) => (
                                <div>
                                  {this.getEventTypeCheckBox(
                                    eventType.tag,
                                  )}
                                </div>
                              ),
                            )} */}
                            <div>
                              <input
                                type="checkbox"
                                checked={this.state.Music}
                                onChange={this.selectCat}
                                className="closedCheckBox"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '5px',
                                  top: '2px',
                                }}
                                alt="Music"
                              />
                              <span>Live Music</span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                checked={this.state.Comedy}
                                onChange={this.selectCat}
                                className="closedCheckBox"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '5px',
                                  top: '2px',
                                }}
                                alt="Comedy"
                              />
                              <span>Comedy</span>
                            </div>
                          </Col>
                          <Col
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            className="no-padding"
                          >
                            <div>
                              <input
                                type="checkbox"
                                checked={this.state.Theater}
                                onChange={this.selectCat}
                                className="closedCheckBox"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '5px',
                                  top: '2px',
                                }}
                                alt="Theater"
                              />
                              <span>Theater</span>
                            </div>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: '10px' }}>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            <div>
                              {this.props.tags.map(
                                (cattag, index) => (
                                  <div>
                                    {this.getEventTypeFields(
                                      cattag,
                                      'Other type of event?',
                                    )}
                                  </div>
                                ),
                              )}
                            </div>
                          </Col>
                        </Row>

                        {this.state.Music === true && (
                          <Row style={{ marginTop: '10px' }}>
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="no-padding"
                            >
                              <div>
                                {this.props.tags.map(
                                  (cattag, index) => (
                                    <div>
                                      {this.getMainGenreFields(
                                        cattag,
                                      )}
                                    </div>
                                  ),
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}

                        {/* <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div style={{ padding: '20px 0px 0px 0px' }}>
                          <span
                            style={{
                              fontWeight: '100',
                              fontSize: '16px',
                              fontStyle: 'italic',
                            }}
                          >
                            Looking to Explore The City?
                          </span>
                        </div>
                        <div style={{ padding: '10px 0px 0px 0px' }}>
                          <Row className="no-padding">
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className="no-padding"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Museum}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Museum"
                                />
                                <span>Visit a Museum</span>
                              </div>
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className="no-padding"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Zoo}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Zoo"
                                />
                                <span>Visit a Zoo</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row> */}
                        <Row>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            <div
                              style={{ padding: '20px 0px 0px 0px' }}
                            >
                              <span
                                style={{
                                  fontWeight: '100',
                                  fontSize: '16px',
                                  fontStyle: 'italic',
                                }}
                              >
                                Something a little more active?
                              </span>
                            </div>
                            <div
                              style={{ padding: '10px 0px 0px 0px' }}
                            >
                              <Row className="no-padding">
                                <Col
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className="no-padding"
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={this.state.Outdoors}
                                      onChange={this.selectCat}
                                      className="closedCheckBox"
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '5px',
                                        top: '2px',
                                      }}
                                      alt="Outdoors"
                                    />
                                    <span>Get Outdoors</span>
                                  </div>
                                </Col>
                                <Col
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className="no-padding"
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={this.state.Golf}
                                      onChange={this.selectCat}
                                      className="closedCheckBox"
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '5px',
                                        top: '2px',
                                      }}
                                      alt="Golf"
                                    />
                                    <span>Golf</span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div
                style={{
                  padding: '10px',
                }}
              >
                <input
                  type="checkbox"
                  checked={this.state.Do}
                  onChange={this.selectCat}
                  className="closedCheckBox"
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '10px',
                    top: '2px',
                  }}
                  alt="Do"
                />
                <span
                  style={{
                    fontWeight: '500',
                    fontSize: '18px',
                    color: '#ed0259',
                  }}
                >
                  Do Something
                </span> */}
                {/* <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div>
                      {this.props.tags.map((cattag, index) => (
                        <div>
                          {this.getCategoryFieldsDateTypeTags(cattag)}
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row> */}
                {/* {this.state.Do === true && (
                  <div>
                    <div style={{ padding: '10px 0px 0px 30px' }}>
                      <span
                        style={{
                          fontWeight: '100',
                          fontSize: '16px',
                          fontStyle: 'italic',
                        }}
                      >
                        Anything Specific?
                      </span>
                    </div>
                    <div style={{ padding: '10px 0px 0px 30px' }}>
                      <Row className="no-padding">
                        <Col
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className="no-padding"
                        >
                          <div>
                            <input
                              type="checkbox"
                              checked={this.state.Outdoors}
                              onChange={this.selectCat}
                              className="closedCheckBox"
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                                top: '2px',
                              }}
                              alt="Outdoors"
                            />
                            <span>Get Outdoors</span>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              checked={this.state.Museum}
                              onChange={this.selectCat}
                              className="closedCheckBox"
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                                top: '2px',
                              }}
                              alt="Museum"
                            />
                            <span>Visit a Museum</span>
                          </div>
                        </Col>
                        <Col
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className="no-padding"
                        >
                          <div>
                            <input
                              type="checkbox"
                              checked={this.state.Zoo}
                              onChange={this.selectCat}
                              className="closedCheckBox"
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                                top: '2px',
                              }}
                              alt="Zoo"
                            />
                            <span>Visit a Zoo</span>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              checked={this.state.Golf}
                              onChange={this.selectCat}
                              className="closedCheckBox"
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                                top: '2px',
                              }}
                              alt="Golf"
                            />
                            <span>Golf</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div
                className="secondary-title-bar"
                style={{
                  borderTop: '1px solid #ee0059',
                  marginTop: '10px',
                }}
              >
                <div>
                  <Container>
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding text-center"
                      >
                        <span style={{ fontStyle: 'normal' }}>
                          Looking for a certain type of experience?
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              <div
                style={{
                  maxWidth: '500px',
                  margin: '0px auto',
                  marginTop: '10px',
                }}
              >
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div>
                      {this.getCategoryFieldsDateTypeTags(
                        this.props.tags,
                      )}
                    </div>
                  </Col>
                </Row>
              </div> */}
              </div>
            )}

            {this.getStepOneFooter(2)}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              }}
            />
          </div>
        );
      }
    }

    if (this.state.displayAd === true) {
      return (
        <div id="home">
          {this.getTitleBar()}
          <div
            style={{
              height: '100vh',
              marginTop: '40px',
            }}
          >
            <GoogleAd
              slot={'9789677108'}
              client={'ca-pub-4916458608267365'}
            />
            <div
              style={{
                position: 'fixed',
                bottom: '40px',
                width: '100%',
              }}
            >
              <center>
                <a
                  onClick={() => {
                    this.displayAd(false);
                  }}
                  style={{
                    padding: '10px',
                    background: '#ee0059',
                    margin: '0px auto',
                    color: 'white',
                  }}
                >
                  Show Next Pick!
                </a>
              </center>
            </div>
          </div>
          <div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  'body{background: #d1d1d1 !important} .section{max-width: 1000px} .container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 40px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div id="home">
          {this.getTitleBar()}
          {/* <div id="headersTopSecondary">
            <Row>
              <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
                {this.getTab(1, 'Trevel Destinations', this.state.mode)}
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
                {this.getTab(2, 'Other Locations', this.state.mode)}
              </Col>
            </Row>
          </div> */}
          {this.getContent(
            this.state.mode,
            this.props.newEvent,
            this.props.newEvent.weightedLocations,
            this.props.newEvent.connections,
            this.state.fullScreen,
            hateBundle,
            this.state.nearbyRegions,
            this.state.userMoodYes,
            this.state.userMoodNo,
            selectedTags,
            guideMode,
          )}
          <div className="footer">{this.getFooter()}</div>
          <Modal
            isOpen={this.state.filterModalVisible}
            // onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeFilterModal}
            style={modalStyles}
            // contentLabel="Wait for Connection?"
          >
            <div className="full-height">
              <div className="modalHeader">
                {this.getModalHeader()}
              </div>
              <div className="secondary-title-bar">
                <div>
                  <Container>
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding text-center"
                      >
                        <span>Where should I look?</span>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>

              <div
                style={{
                  padding: '15px',
                  maxWidth: '600px',
                  margin: '0px auto',
                }}
              >
                <div
                  style={{
                    clear: 'both',
                    borderBottom: '1px solid grey',
                  }}
                >
                  <label
                    style={{
                      marginRight: '10px',
                      fontWeight: '500',
                      fontSize: '18px',
                      color: '#ed0259',
                    }}
                  >
                    <input
                      style={{
                        marginRight: '5px',
                        float: 'left',
                        minWidth: 'unset',
                        position: 'relative',
                        width: '25px',
                        height: '25px',
                      }}
                      type="radio"
                      value="current"
                      checked={
                        this.state.participantsType === 'current'
                      }
                      onChange={this.participantsTypeChange.bind(
                        this,
                      )}
                    />
                    Near My Current Location
                  </label>
                </div>

                <div
                  style={{
                    clear: 'both',
                    display: 'inline-block',
                    padding: '10px 0px 0px 0px',
                  }}
                >
                  <input
                    style={{
                      marginRight: '5px',
                      float: 'left',
                      minWidth: 'unset',
                      position: 'relative',
                      width: '25px',
                      height: '25px',
                    }}
                    type="radio"
                    value={'region'}
                    checked={
                      this.state.participantsType !== 'current'
                    }
                    onChange={this.participantsTypeChange.bind(this)}
                  />

                  <div
                    style={{
                      height: '50px',
                      margin: '0px auto',
                      float: 'left',
                      minWidth: '300px',
                    }}
                  >
                    <Select
                      value={this.state.selectedRegion}
                      // closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.selectRegionChange(e, 0, 'one')
                      }
                      options={regions}
                      // onInputChange={(e) =>
                      //   this.moodTextChange(e, 0, 'one')
                      // }
                      isMulti={false}
                      placeholder={'Select area of City'}
                    />
                  </div>
                </div>
              </div>

              {/* <div style={{ clear: 'both', display: 'inline-block' }}>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <h5 className="red">Distance</h5>
                    <span>Radius (Miles):</span>
                  </Col>
                </Row>
  
                <Row>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="padding-ten"
                  >
                    {this.getButton(
                      1,
                      '10',
                      this.state.distanceFilterState,
                    )}
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="padding-ten"
                  >
                    {this.getButton(
                      0,
                      '50',
                      this.state.distanceFilterState,
                    )}
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="padding-ten"
                  >
                    {this.getButton(
                      2,
                      '100+',
                      this.state.distanceFilterState,
                    )}
                  </Col>
                </Row>
              </div> */}
              <div
                className="secondary-title-bar"
                style={{
                  borderTop: '1px solid #b90b3b',
                  marginTop: '10px',
                }}
              >
                <Container>
                  <Row className="no-margin">
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding text-center"
                    >
                      <span>What are you in the mood for?</span>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="wizard-section-border">
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <FilterModal
                      tagFilters={this.state.tagFilters}
                      setTagFilters={this.setTagFilters}
                      otherUserTagsLove={this.state.otherUserTagsLove}
                      otherUser={this.state.otherUser}
                      customEvent={this.props.customEvent}
                      locations={this.state.locations}
                    />
                  </Col>
                </Row>
              </div>
              {/* <div className="section">{this.getModalContent()}</div> */}
            </div>
          </Modal>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.section{max-width: 1000px} .container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 40px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    customEvent: state.customEvent.customEvent,
    profile: state.profile.profile,
    newEvent: state.newEvent,
    users: state.users.users,
    tags: state.tags.tags,
    user: state.user,
    tagsAndFavs: state.tagsAndFavs.connectionsTagsAndFavs,
    connectionsTagsAndFavs: state.tagsAndFavs.connectionsTagsAndFavs,
    timedEvents: state.timedEvents.events,
    guideTemplates: state.guideTemplates.templates,
    entertainment: state.entertainment.entertainment,
    taRequest: state.taRequest,
    regions: state.regions.regions,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(init());
    },
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
    getTaRequest: (request) => {
      dispatch(getTaRequest(request));
    },
    clearTaRequest: (user) => {
      dispatch(clearTaRequest(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomPackageAddCustomLocation);
