import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
// Components
import DrawerToggleButton from '../DrawerToggleButton';
import InviteHeader from '../InviteHeader';
// Images
import logo from '../../images/TrevelLogoText.svg';
import beta from '../../images/beta.png';

import './Toolbar.css';

export class AuthTopBar extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.profile !== prevState.profile) {
      return {
        profile: nextProps.profile,
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.props.profile.profilePhotoPath !== undefined) {
      this.setState({
        avatarURL: this.props.profile.profilePhotoPath,
        loading: false,
      });
    }
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div
        className={
          this.props.profile.userType !== 1 &&
          this.props.profile.userType !== 3
            ? 'toolbar authtoolbar'
            : 'toolbar authtoolbar'
        }
        id="authtoolbar"
      >
        {(this.props.profile.userType === 1 ||
          this.props.profile.userType === 3 ||
          this.props.profile.userType === 4) && (
          <div id="vendorTopLink">
            <a href="/vendor/home">
              {/* <a href="/vendor/home" target="_blank"> */}
              Open Trevel Experiences
            </a>
          </div>
        )}
        <nav className="toolbar__navigation" id="mobile-nav">
          <Container>
            <Row>
              <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
                <div className="toolbar__toggle-button">
                  <DrawerToggleButton
                    click={this.props.drawerClickHandler}
                  />
                </div>
              </Col>
              <Col xs={8} md={8} sm={8} lg={8}>
                <center>
                  <div className="toolbar__logo">
                    <Link
                      to={{
                        pathname: '/home',
                      }}
                    >
                      {/* <img src={beta} /> */}
                      <img src={logo} />
                    </Link>
                  </div>
                </center>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
                <Link
                  to={{
                    pathname: '/profile/edit',
                  }}
                >
                  <UserAvatar
                    size="44"
                    name={
                      this.props.profile.firstName +
                      this.props.profile.lastName
                    }
                    src={this.state.avatarURL}
                    style={{
                      float: 'right',
                      color: 'white',
                    }}
                  />
                </Link>

                <InviteHeader />
              </Col>
            </Row>
          </Container>
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: state.profile.profile,
  };
}

export default connect(mapStateToProps)(AuthTopBar);

// Drop Down
{
  /* <div className="eventDropDown"> */
}

{
  /* <div className="dropdown-content">
          <li>
            <a href="/packages">Romantic</a>
          </li>
          <li>
            <a href="/packages ">Adventurous</a>
          </li>
          <li>
            <a href="/packages ">Category</a>
          </li>
        </div> */
}
{
  /* </div> */
}
