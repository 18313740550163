import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

const query = require('array-query');

class PackageDrawer extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      imageurl: null,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    const drawerLocations = [];
    this.props.locations.forEach(location => {
      const thislocation = query('locationId')
        .is(location.locationId)
        .on(this.props.alllocations);
      drawerLocations.push(thislocation[0]);
    });
    this.setState({
      drawerLocations: drawerLocations,
      loading: false,
    });
  }

  getImage(type, locationStart) {
    let location = '';
    let locations = this.props.alllocations;
    const locationDetails = query('locationId')
      .is(locationStart.locationId)
      .on(locations);
    location = locationDetails[0];
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getLocation(location) {
    if (location !== undefined) {
      return (
        <div className="drawer-location">
          <Row>{this.getImage('thumb', location)}</Row>
          <Row>
            <center>
              <span className="title">{location.locationTitle}</span>
            </center>
          </Row>
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <Col xs={12} md={12} sm={12} lg={12}>
        <h2>Itinerary</h2>
        {this.state.drawerLocations.map((location, index) => (
          <div key={'key' + index}>{this.getLocation(location)}</div>
        ))}
      </Col>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
  };
}

export default connect(mapStateToProps)(PackageDrawer);
