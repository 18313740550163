import React, { Component } from 'react';
import MaterialIcon from 'material-icons-react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import './LocationDetails.css';
import firebase from '../../config/firebase';
import whiteLogo from '../../images/trevel_logo_white.png';
import moment from 'moment';

// Components

const query = require('array-query');

class AwardDetails extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const awardDetails = query('awardId')
      .is(this.props.awardId)
      .on(this.props.awards);
    this.setState({
      award: awardDetails[0],
      loading: false,
    });
  }

  getStatus(active) {
    if (active === true) {
      return 'Active';
    } else {
      return 'Redeemed';
    }
  }

  validate = (value, activeCriteriaIndex) => {
    this.setState({
      validate: value,
      activeCriteriaIndex,
    });
  };

  getValidated(index, userReward) {
    let newAward = userReward;
    newAward.criteria[index].activeCriteria = true;

    this.setState({
      activeCriteriaIndex: index,
    });

    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(newAward.awardId);
    const setDoc = update
      .update({
        criteria: newAward.criteria,
        validatorMode: true,
        validationType: 1, // 2 means validate criteria
      })
      .then((ref) => {
        this.validate(true, index);
        // this.setState({
        //   showConfirm: false,
        //   validate: false,
        //   redeem: false,
        // });
      });
  }

  setCriteriaDetailView(mode, criteria, index) {
    this.setState({
      criteriaDetailView: mode,
      activeCriteriaPreview: criteria,
      index,
    });
  }

  getCriteria(criteria, index, userReward, reward, promo) {
    let indexPlusOne = index + 1;
    if (userReward !== undefined) {
      if (criteria.type === 1) {
        if (criteria.numTimes < 2) {
          if (userReward.earned === false) {
            if (userReward.criteria[index].satisfied === false) {
              return (
                <div className="criteriaBorder">
                  <span className="rewardIcon">
                    <MaterialIcon
                      icon="check_box_outline_blank"
                      size={25}
                      color="#9a0039"
                    />
                  </span>
                  <span className="criteriaHeaderComplete">
                    Criteria {indexPlusOne}
                  </span>
                  <br></br>
                  {/* <a
                    onClick={() => {
                      this.getValidated(index, userReward);
                    }}
                  > */}
                  <a
                    onClick={() => {
                      this.setCriteriaDetailView(
                        true,
                        criteria,
                        index,
                      );
                    }}
                  >
                    <span className="criteriaText">
                      {criteria.text}
                    </span>
                  </a>
                </div>
              );
            } else {
              return (
                <div className="criteriaBorderGrey">
                  <span className="rewardIcon">
                    <MaterialIcon
                      icon="check_box"
                      size={25}
                      color="#9a0039"
                    />
                  </span>
                  <span className="criteriaHeaderComplete">
                    Criteria {indexPlusOne}
                  </span>
                  <br></br>
                  <span className="criteriaText">
                    {criteria.text}
                  </span>
                </div>
              );
            }
          } else {
            // if (userReward === undefined || userReward.redemption.redeemed === false) {
            //   return (
            //   <a
            //   onClick={() => {
            //     this.requestRedemption(userReward);
            //   }}
            //   className="redbutton text-center"
            // ><span>Redeem!</span>
            // </a>
            // )
            // } else {
            //   let myDate = userReward.redemption.date.seconds * 1000; // Unix ms timestamp
            //   let dayText = moment(myDate, "x")
            //     .subtract(10, "days")
            //     .calendar();
            //   return  <span>Redeemed: {dayText}</span>
            // }
          }
        } else {
          let currentNum = 0;
          if (criteria.type === 1) {
            if (
              userReward.criteria[index].validationHistory !==
              undefined
            ) {
              userReward.criteria[index].validationHistory.forEach(
                (validation) => {
                  if (validation.validated === true) {
                    currentNum = currentNum + 1;
                  }
                },
              );
            }
          }
          let width =
            ((currentNum / criteria.numTimes) * 100).toFixed(2) + '%';

          if (userReward.criteria[index].satisfied === true) {
            return (
              <div className="criteriaBorderGrey">
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="check_box"
                    size={25}
                    color="#9a0039"
                  />
                </span>
                <span className="criteriaHeaderComplete">
                  Criteria {indexPlusOne}
                </span>
                <br></br>
                <span className="criteriaText">{criteria.text}</span>
              </div>
            );
          } else {
            return (
              <div className="criteriaBorder">
                {/* <a
                  onClick={() => {
                    this.getValidated(index, userReward);
                  }}
                > */}
                <a
                  onClick={() => {
                    this.setCriteriaDetailView(true, criteria, index);
                  }}
                >
                  <span className="rewardIcon">
                    <MaterialIcon
                      icon="check_box_outline_blank"
                      size={25}
                      color="#9a0039"
                    />
                  </span>
                  <span className="criteriaHeader">
                    {' '}
                    Criteria {indexPlusOne}
                  </span>
                  <br></br>
                  <span className="criteriaText">
                    {criteria.text}
                  </span>
                  <div id="progressBarBack">
                    <span className="right red">
                      <span className="progressGoal">
                        {criteria.numTimes}
                      </span>
                    </span>
                    <div
                      className="progressBar"
                      style={{ width: width }}
                    >
                      <span className="progressTotal">
                        {currentNum}
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            );
          }
        }
      } else {
        // return(
        //   <div className="criteriaBorder">
        //     <span className="rewardIcon"><MaterialIcon icon="check_box_outline_blank" size={25} color="#9a0039" /></span>
        //     <span className="criteriaHeaderComplete">Criteria {indexPlusOne}</span><br></br>
        //     <span className="criteriaText">{criteria.text}</span>
        // </div>
        // );
      }
    } else {
      let currentNum = 0;
      let width = '5%';
      return (
        <div className="criteriaBorder">
          <a
            onClick={() => {
              this.getValidatedNew(
                index,
                reward,
                promo,
                this.props.profile,
              );
            }}
          >
            <span className="rewardIcon">
              <MaterialIcon
                icon="check_box_outline_blank"
                size={25}
                color="#9a0039"
              />
            </span>
            <span className="criteriaHeader">
              {' '}
              Criteria {indexPlusOne}
            </span>
            <br></br>
            <span className="criteriaText">{criteria.text}</span>
            <div id="progressBarBack">
              <span className="right red">
                <span className="progressGoal">
                  {criteria.numTimes}
                </span>
              </span>
              <div className="progressBar" style={{ width: width }}>
                <span className="progressTotal">{currentNum}</span>
              </div>
            </div>
          </a>
        </div>
      );
    }
  }

  getDate(timestamp) {
    let myDate = new Date(timestamp * 1000);
    let dayTextFinal = moment(myDate).format('MM/DD/YYYY');
    return dayTextFinal;
  }

  render() {
    let award = this.state.award;

    if (this.state.loading) {
      return null; // or render a loading icon
    }

    let startedDate = this.getDate(
      award.creationDetails.startDate.seconds,
    );
    let earnedDate = null;
    if (
      award.creationDetails.earnedDate !== undefined &&
      award.creationDetails.earnedDate.seconds !== undefined
    ) {
      earnedDate = this.getDate(
        award.creationDetails.earnedDate.seconds,
      );
    }
    let redeemedDate = null;
    if (award.redemption.redeemed === true) {
      redeemedDate = this.getDate(award.redemption.date.seconds);
    }

    if (this.state.criteriaDetailView === true) {
      // Is it Once Per Day?
      let activeStatus = true;

      let date = '';
      let validationHistory =
        this.state.activeCriteriaPreview.validationHistory;

      let confirmedValidations = [];
      validationHistory.forEach((history) => {
        if (history.validated === true) {
          confirmedValidations.push(history);
        }
      });
      let historyCount = confirmedValidations.length;
      if (historyCount > 0) {
        let index = historyCount - 1;
        date = confirmedValidations[index].date.seconds * 1000;
        let hours = moment().diff(moment(date), 'hours');
        if (hours < 24) {
          activeStatus = false;
        }
      }

      return (
        <div style={{ height: '100%' }}>
          {/* <div id="validator">
            {this.getValidateScreen(this.props.awards)}
          </div> */}

          <div id="package" style={{ height: '100%' }}>
            <div
              className="criteriaDetails"
              style={{ height: '100%' }}
            >
              <div className="right">
                <MaterialIcon
                  icon="close"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.setCriteriaDetailView(false);
                  }}
                />
              </div>
              <div
                style={{
                  clear: 'both',
                  display: 'block',
                  padding: '10px',
                }}
              >
                <span style={{ color: 'white', fontSize: '18px' }}>
                  {this.state.activeCriteriaPreview.text}
                </span>
                <hr></hr>
                <div style={{ minHeight: '350px' }}>
                  {this.state.activeCriteriaPreview.guidelines !==
                    '' && (
                    <span style={{ color: 'white' }}>
                      {this.state.activeCriteriaPreview.guidelines}
                    </span>
                  )}
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                {activeStatus === true ? (
                  <span
                    style={{
                      background: 'white',
                      padding: '10px',
                      borderRadius: '5px',
                      color: '#f21d59',
                    }}
                    onClick={() => {
                      activeStatus === true &&
                        this.getValidated(
                          this.state.index,
                          this.props.activeReward,
                        );
                    }}
                  >
                    Get Validated
                  </span>
                ) : (
                  <div>
                    <span
                      style={{
                        color: 'white',
                        clear: 'both',
                        display: 'block',
                        padding: '0px 0px 20px 0px',
                      }}
                    >
                      Available Once Per Day
                    </span>
                    <span
                      style={{
                        background: 'white',
                        padding: '10px',
                        borderRadius: '5px',
                        color: '#f21d59',
                      }}
                      onClick={() => {
                        this.setCriteriaDetailView(false);
                      }}
                    >
                      Close
                    </span>
                  </div>
                )}
              </div>
            </div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="section">
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div
                style={{
                  margin: '10px 0px 20px 0px',
                  borderBottom: '1px solid grey',
                  padding: '0px 0px 10px 0px',
                }}
              >
                {award.rewardSettings !== undefined &&
                  award.rewardSettings.chosenStatus === false &&
                  award.rewardSettings.colabMode === 'choose' && (
                    <h4>
                      You can select{' '}
                      {award.rewardSettings.colabQuantity} of these
                      rewards:
                    </h4>
                  )}
                {award.rewards !== undefined &&
                  award.rewards.map((award, index) => (
                    <div>
                      <span
                        style={{
                          margin: '0px 0px 10px 0px',
                          color: '#f31e59',
                          fontSize: '20px',
                          display: 'block',
                        }}
                      >
                        {award.title}
                      </span>
                      <span
                        style={{ display: 'block', clear: 'both' }}
                      >
                        {award.description}
                      </span>
                    </div>
                  ))}
              </div>
              <div
                style={{
                  margin: '10px 0px 20px 0px',
                  borderBottom: '1px solid grey',
                  padding: '0px 0px 20px 0px',
                }}
              >
                <span>
                  <span style={{ fontWeight: '600' }}>
                    Start Date:{' '}
                  </span>
                  {startedDate}
                </span>
                {earnedDate !== null && (
                  <span
                    style={{
                      clear: 'both',
                      display: 'block',
                      margin: '10px 0px 0px',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>
                      Earned:{' '}
                    </span>
                    {earnedDate}
                  </span>
                )}
                {redeemedDate !== null && (
                  <span
                    style={{
                      clear: 'both',
                      display: 'block',
                      margin: '10px 0px 0px',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>
                      Reemed:{' '}
                    </span>
                    {redeemedDate}
                  </span>
                )}
              </div>
              {award.redemption.redeemed === false &&
              award.earned === true ? (
                <div
                  onClick={() => {
                    this.props.redeemAward(award);
                  }}
                >
                  <span>Redeem!</span>
                </div>
              ) : (
                <div></div>
              )}
              <div>
                {award.criteria.map((criteria, index) => (
                  <div>
                    {this.getCriteria(criteria, index, award)}
                  </div>
                ))}
              </div>
              {/* <div className="wizard-section-ten text-center">
                <h6>{this.getStatus(award.active)}</h6>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    awards: state.userAwards.awards,
  };
}

export default connect(mapStateToProps)(AwardDetails);
