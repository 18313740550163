import firebase from '../config/firebase';

export function deleteDocument(table, id, returnPath) {
  const db = firebase.firestore();

  db.collection(table)
    .doc(id)
    .delete()
    .then(function () {
      window.location.reload();
    })
    .catch(function (error) {
      // console.error("Error removing document: ", error);
    });
}

export function getTags() {
  const docArray = [];
  const db = firebase.firestore();
  const docs = db.collection('tags');

  const getV = function () {
    return new Promise((resolve, reject) => {
      const query = docs
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const { tagsTest, parent, sid } = doc.data();
              docArray.push({
                id: doc.id,
                tags: tagsTest,
                parent,
                sid,
              });
            }
          });
          return docArray;
        })
        .then((result) => {
          resolve(docArray);
        });
    });
  };

  return getV();
}
