import React from 'react';
import { connect } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
import { Col, Container, Row } from 'reactstrap';
// Components
import RelatedArticles from '../components/RelatedArticles';
import PromotedArticleHero from '../components/PromotedArticleHero';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
// Reducers
import { getArticles } from '../sagas/articleSaga/reducer';

const KEYS_TO_FILTERS = ['title'];

export class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: false,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    // if (this.props.articles === undefined) {
    this.props.getArticles();
    // } else {
    this.setState({
      loading: false,
    });
    // }
  }

  componentDidMount() {
    document.title = 'Trevel Guide Articles';
    if (this.props.articles === undefined) {
      this.props.getArticles();
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  setTitleBar() {
    if (this.props.profile.profilePhotoPath !== undefined) {
      return <div />;
    }
  }

  sortArticles(articles) {
    articles.sort(function compare(a, b) {
      var dateA = new Date(a.postdate);
      var dateB = new Date(b.postdate);
      return dateB - dateA;
    });
    return articles;
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          {/* <Row className="center" style={{ maxWidth: '1200px' }}> */}
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Things to Read</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <a
                onClick={value => {
                  this.saveTags(this.props.user.id);
                }}
                className="right"
              >
                <MaterialIcon icon="check" size={25} color="white" />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.articles === undefined) {
      return null; // or render a loading icon
    } else {
      const filteredArticles = this.props.articles.filter(
        createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
      );
      const sortedArticles = this.sortArticles(filteredArticles);

      return (
        <div id="articles">
          {this.getTitleBar()}
          <div className="contentbox">
            <PromotedArticleHero />
            <div>
              <Row style={{ padding: '10px 0px 10px 0px' }}>
                <Col xs={12} md={12} sm={12} lg={12}>
                  <SearchInput
                    className="search-input"
                    onChange={this.searchUpdated}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  <Container>
                    <RelatedArticles articles={sortedArticles} />
                  </Container>
                </Col>
                {/* <Col xs={3} md={3} sm={3} lg={3} className="twocolRight">
                <div id="right-filter">
                  <h2>Sort and Filter</h2>
                </div>
              </Col> */}
              </Row>
            </div>
          </div>
          {this.props.user.authenticated === true && (
            <style
              dangerouslySetInnerHTML={{
                __html: '#mobile-nav { display: none }',
              }}
            />
          )}
          {this.props.user.authenticated === false && (
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '@media (max-width: 800px) {.maincontent{top:80px !important}}.backbar{display: none}',
              }}
            />
          )}
          <style
            dangerouslySetInnerHTML={{
              __html:
                'html{display:grid} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;}  #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    articles: state.articles.articles,
    profile: state.profile.profile,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getArticles: (article) => {
      dispatch(getArticles(article));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
