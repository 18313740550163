import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import { getSpotlight } from '../../sagas/setDailySpotlightSaga/reducer';
import { Link } from 'react-router-dom';

const query = require('array-query');

class DailySpotlight extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      dailySpotlight: {},
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.dailySpotlight.dailySpotlight == false) {
      let trevelPackages = [];
      this.props.packages.forEach((packageX) => {
        if (packageX.category === 1) {
          trevelPackages.push(packageX);
        }
      });
      let item =
        this.props.locations[
          Math.floor(Math.random() * this.props.locations.length)
        ];

      this.props.getSpotlight(item);
      this.setState({
        loading: false,
        dailySpotlight: item,
      });
    } else {
      let trevelPackages = [];
      this.props.packages.forEach((packageX) => {
        if (packageX.category === 1) {
          trevelPackages.push(packageX);
        }
      });
      let item =
        this.props.locations[
          Math.floor(Math.random() * this.props.locations.length)
        ];

      this.props.getSpotlight(item);
      this.setState({
        loading: false,
        dailySpotlight: item,
      });
      // this.setState({
      //   loading: false,
      //   dailySpotlight: this.props.dailySpotlight,
      // });
    }
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  getSpotlight(dailySpotlight) {
    let backgroundUrl = dailySpotlight.locationHero;

    let backgroundStyle = {
      backgroundImage:
        'linear-gradient(45deg,rgba(242, 29, 89, 1),rgba(245, 246, 252, 0)), url(' +
        backgroundUrl +
        ')',
      backgroundSize: 'cover',

      backgroundPosition: 'center',
      position: 'relative',
    };

    let description = this.truncate(
      dailySpotlight.locationDescription,
    );

    return (
      <Link
        to={{
          // pathname: '/package/' + dailySpotlight.packageId,
          pathname: '/location/' + dailySpotlight.locationId,
        }}
      >
        <div style={backgroundStyle} className="guideBanner">
          {/* <img
            src={
              'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
              dailySpotlight.heroImage +
              '?alt=media'
            }
            style={{ width: '100%' }}
          /> */}
          <div className="spotlightText">
            <span className="spotlightTitle white">
              {dailySpotlight.locationTitle}
            </span>
            <span className="white">{description}</span>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    if (this.props.loading) {
      return null; // or render a loading icon
    }

    return <div>{this.getSpotlight(this.state.dailySpotlight)}</div>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSpotlight: (spotlight) => {
      dispatch(getSpotlight(spotlight));
    },
  };
};

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    dailySpotlight: state.dailySpotlight,
    connectionsTagsAndFavs: state.tagsAndFavs.connectionsTagsAndFavs,
    locations: state.locations.locations,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailySpotlight);
