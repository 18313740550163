import * as action from './actions';

export const getEntertainment = (zoneId) => {
  return {
    type: action.GET_ENTERTAINMENT,
    payload: {},
    zoneId: zoneId,
  };
};

const ACTION_HANDLERS = {
  [action.SET_ENTERTAINMENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_ENTERTAINMENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = { entertainment: [] };

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
