import React, { Component } from 'react';
import { connect } from 'react-redux';
import ArticleListItem from '../../components/ArticleListItem';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Row, Col } from 'reactstrap';

import { Link } from 'react-router-dom';

class PromotedArticles extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  UNSAFE_componentWillMount() {
    const promotedArticles = this.findPromotedArticles(
      this.props.articles,
    );
    this.setState({ articles: promotedArticles, loading: false });
  }

  findPromotedArticles(articles) {
    const promotedArticles = [];
    if (articles !== undefined) {
      articles.forEach((article) => {
        if (article.promoted === true) {
          promotedArticles.push(article);
        }
      });
    }
    return promotedArticles;
  }

  getSlideSections(promotedLocations, count) {
    let sections = [];
    let sectionOne = [];
    let sectionTwo = [];
    let sectionThree = [];
    let sectionFour = [];
    let sectionFive = [];
    let sectionSix = [];
    let sectionSeven = [];
    let sectionEight = [];
    let sectionNine = [];
    let sectionTen = [];

    if (count === 4) {
      promotedLocations.forEach((location, index) => {
        if (index < 3 || index === 3) {
          sectionOne.push(location);
        }
        if (index > 3 && index < 8) {
          sectionTwo.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
    }

    if (count === 3) {
      promotedLocations.forEach((location, index) => {
        if (index < 2 || index === 2) {
          sectionOne.push(location);
        }
        if (index > 2 && index < 6) {
          sectionTwo.push(location);
        }
        if (index > 6 && index < 9) {
          sectionThree.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
    }

    if (count === 2) {
      promotedLocations.forEach((location, index) => {
        if (index === 0) {
          sectionOne.push(location);
        }
        if (index === 1) {
          sectionTwo.push(location);
        }
        if (index === 2) {
          sectionThree.push(location);
        }
        if (index === 3) {
          sectionFour.push(location);
        }
        if (index === 4) {
          sectionFive.push(location);
        }
        if (index === 5) {
          sectionSix.push(location);
        }
        if (index === 6) {
          sectionSeven.push(location);
        }
        if (index === 7) {
          sectionEight.push(location);
        }
        if (index === 8) {
          sectionNine.push(location);
        }
        if (index === 9) {
          sectionTen.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
      if (sectionFour.length !== 0) {
        sections.push(sectionFour);
      }
      if (sectionFive.length !== 0) {
        sections.push(sectionFive);
      }
      if (sectionSix.length !== 0) {
        sections.push(sectionSix);
      }
      if (sectionEight.length !== 0) {
        sections.push(sectionEight);
      }
      if (sectionSeven.length !== 0) {
        sections.push(sectionSeven);
      }
      if (sectionNine.length !== 0) {
        sections.push(sectionNine);
      }
      if (sectionTen.length !== 0) {
        sections.push(sectionTen);
      }
    }

    return sections.map((section, index) => {
      return (
        <div style={{ height: '100%' }}>
          {section.map((article, index) => (
            <div
              style={{
                border: '0px solid rgba(0, 0, 0, 0.125)',
                background: 'white !important',
              }}
              className={count === 2 ? 'singleSlide' : 'multiSlide'}
            >
              <ArticleListItem article={article} />
            </div>
          ))}
        </div>
      );
    });
  }

  render() {
    let count = this.props.count;
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div className="homeSection">
        <div
          style={{
            height: count > 2 ? '40px' : '80px',
            marginBottom: count > 2 ? '0px' : '0px',
          }}
        >
          <Row
            className="no-margin"
            style={{
              padding: count > 2 ? '0x' : '0px',
            }}
          >
            <Col
              xs={12}
              sm={12}
              md={10}
              lg={10}
              className="no-padding"
            >
              <div className="sectiontitle text-center">
                <span
                  className="homeCat "
                  style={{
                    float: count > 2 ? 'left' : 'none',
                    marginBottom: count > 2 ? '10px' : '',
                    textAlign: count > 2 ? 'left' : 'center',
                  }}
                >
                  Suggested Reading
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="no-padding">
              <div
                style={{
                  zindex: '99999',
                  textAlign: count > 2 ? 'left' : 'center',
                }}
              >
                <Link
                  to={{
                    pathname: '/articles/',
                  }}
                  className={count > 2 ? 'right' : 'seeMoreLeft'}
                >
                  Read More
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <Carousel
          showThumbs={false}
          // centerMode={true}
          // centerSlidePercentage={80}
          // selectedItem={this.getCount(promotedLocations)}
          showIndicators={false}
          showStatus={false}
          // showArrows={true}
          infiniteLoop={false}
          centerMode={count === 2 ? true : false}
          swipeScrollTolerance={50}
          preventMovementUntilSwipeScrollTolerance={true}
        >
          {this.getSlideSections(this.state.articles, count)}
        </Carousel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(PromotedArticles);
