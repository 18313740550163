import * as action from './actions';

export const getAllEvents = (packageId) => {
  return {
    type: action.GETALL_EVENTS,
    payload: {},
    packageId: packageId,
  };
};

export const setEvents = (events) => {
  return {
    type: action.SET_ALLEVENTS,
    payload: events,
  };
};

const ACTION_HANDLERS = {
  [action.GETALL_EVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SET_ALLEVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  events: [],
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
