import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import firebase from '../../config/firebase';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';
// Styles
import './LocationListItem.css';

const query = require('array-query');

class LocationListItem extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    // if (this.props.alllocations.length === 0) {
    //   console.log("EMPTY");
    //   this.props.getAllLocations(1);
    // }
  }

  componentDidMount() {
    // const packageLocations = [];
    // this.props.locations.forEach(location => {
    //   console.log("packageLocations 1", location);
    //   const thislocation = query("locationId")
    //     .is(location.locationId)
    //     .on(this.props.alllocations);
    //   console.log("packageLocations 2", thislocation[0]);
    //   packageLocations.push(thislocation[0]);
    // });
    // console.log("packageLocations 3", packageLocations);
    // this.setState({
    //   packageLocations: packageLocations,
    //   loading: false
    // });
    this.setState({
      loading: false,
    });
  }

  getImage(type, locationStart, locations) {
    let location = '';
    const locationDetails = query('locationId')
      .is(locationStart.locationId)
      .on(locations);
    location = locationDetails[0];
    if (location === undefined) {
      const db = firebase.firestore();
      db.collection('locations')
        .where('locationId', '==', locationStart.locationId)
        .get()
        .then((querySnapshot) => {
          //Notice the arrow funtion which bind `this` automatically.
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            location.push(doc.data());
          });
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
        });
    }

    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      let status = location.locationThumb.startsWith('http');
      if (
        // location !== undefined &&
        // location.settings !== undefined &&
        // location.settings.customImages !== undefined &&
        // location.settings.customImages === true
        status === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getContent(eventId, token, type) {
    if (this.props.links !== undefined) {
      return (
        <div className="fullwidth">
          <ul className="no-padding no-margin">
            {this.props.locations.map((location, index) => (
              <li className="adminlistitem" key={'key' + index}>
                <div className="fullwidth">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    key={'key' + index}
                    className="no-padding"
                  >
                    <Row>
                      <Col
                        xs={4}
                        md={4}
                        sm={4}
                        lg={4}
                        className="no-padding thumbnailCol"
                      >
                        {this.getImage(
                          'thumb',
                          location,
                          this.props.locations,
                        )}
                      </Col>
                      <Col xs={8} md={8} sm={8} lg={8}>
                        <span className="location-title">
                          {location.locationTitle}
                        </span>
                        <span className="address">
                          {location.address.street}
                        </span>
                        <span className="address">
                          {location.address.city}{' '}
                          {location.address.state},{' '}
                          {location.address.zip}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="fullwidth">
          <ul className="no-padding no-margin">
            {this.props.locations.map((location, index) => (
              <li className="adminlistitem" key={'key' + index}>
                <div className="fullwidth">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    key={'key' + index}
                    className="no-padding"
                  >
                    <Row>
                      <Col
                        xs={4}
                        md={4}
                        sm={4}
                        lg={4}
                        className="no-padding thumbnailCol"
                      >
                        <Link
                          to={{
                            pathname:
                              '/location/' + location.locationId,
                            state: {
                              location: { location },
                              eventId: { eventId },
                              token: { token },
                              type: { type },
                            },
                          }}
                        >
                          {this.getImage(
                            'thumb',
                            location,
                            this.props.locations,
                          )}
                        </Link>
                      </Col>
                      <Col xs={8} md={8} sm={8} lg={8}>
                        <Link
                          to={{
                            pathname:
                              '/location/' + location.locationId,
                            state: {
                              location: { location },
                              eventId: { eventId },
                              token: { token },
                              type: { type },
                            },
                          }}
                        >
                          <span className="location-title">
                            {location.locationTitle}
                          </span>
                          <span className="address">
                            {location.address.street}
                          </span>
                          <span className="address">
                            {location.address.city}{' '}
                            {location.address.state},{' '}
                            {location.address.zip}
                          </span>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    const eventId = this.props.eventId;
    const token = this.props.token;
    const type = this.props.type;
    // let subset = this.props.packages.slice(0, 10);
    return (
      <Row className=" no-margin no-padding">
        {this.getContent(eventId, token, type)}
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    // alllocations: state.locations.locations,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationListItem);

// Location Details Available
// **************************
// location.locationTitle
// location.categories
// location.locationDescription
// location.locationHero
// location.ocationThumb
// location.locationId
// location.locationType
// ** Vouchers and Packages as well
