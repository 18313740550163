import firebase from '../config/firebase';

export function getVendors() {
  const vendorArray = [];
  const db = firebase.firestore();

  const getV = function () {
    return new Promise((resolve, reject) => {
      db.collection('vendors')
        .where('subscription.community', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                businessName,
                created,
                primaryContact,
                primaryAddress,
                vendorTypes,
                locations,
                entertainment,
                // users,
                // subscription,
              } = doc.data();
              vendorArray.push({
                id: doc.id,
                businessName,
                created,
                primaryContact,
                primaryAddress,
                vendorTypes,
                locations,
                entertainment,
                // users,
                // subscription,
              });
            }
          });
          return vendorArray;
        })
        .then((result) => {
          resolve(vendorArray);
        });
    });
  };

  return getV();
}

export function getVendorAwards(vendorId) {
  const awardsArray = [];
  const db = firebase.firestore();

  const getV = function () {
    return new Promise((resolve, reject) => {
      db.collection('user_awards')
        .where('promo.vendorId', '==', vendorId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                active,
                creationDetails,
                criteria,
                reward,
                owner,
                redemption,
                restrictions,
                promo,
                earned,
                validatorMode,
                validationType,
              } = doc.data();
              // const expireDateString = expireDate.toDateString();
              // const purchaseDateString = purchaseDate.toDateString();
              awardsArray.push({
                awardId: doc.id,
                active,
                creationDetails,
                criteria,
                reward,
                owner,
                redemption,
                restrictions,
                promo,
                earned,
                validatorMode,
                validationType,
              });
            }
          });
          return awardsArray;
        })
        .then((result) => {
          // const final_vouchers = result[0]
          resolve(awardsArray);
        });
    });
  };
  return getV();
}

export function getVendorUserEvents(vendorId) {
  const eventsArray = [];
  const db = firebase.firestore();

  const getV = function () {
    return new Promise((resolve, reject) => {
      db.collection('user_events')
        .where('vEventDetails.vendorId', '==', vendorId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const { created, creationDetails, users } = doc.data();
              let attendingCount = Object.keys(users).length;
              eventsArray.push({
                eventId: doc.id,
                created,
                creationDetails,
                attendingCount,
              });
            }
          });
          return eventsArray;
        })
        .then((result) => {
          // const final_vouchers = result[0]
          resolve(eventsArray);
        });
    });
  };
  return getV();
}

export function getVendorObject(id) {
  console.log('idf', id);
  const db = firebase.firestore();
  const vendors = db.collection('vendors').doc(id);

  const getV = function () {
    return new Promise((resolve, reject) => {
      vendors
        .get()
        .then(function (doc) {
          if (doc.exists) {
            const docit = doc.data();
            return docit;
          } else {
            console.log('No such document!');
          }
        })
        .catch(function (error) {
          console.log('Error getting document:', error);
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  return getV();
}

export function getVendorPromos(id) {
  const db = firebase.firestore();
  const vendors = db.collection('user_awards').doc(id);

  const getV = function () {
    return new Promise((resolve, reject) => {
      vendors
        .get()
        .then(function (doc) {
          if (doc.exists) {
            const docit = doc.data();
            return docit;
          } else {
            console.log('No such document!');
          }
        })
        .catch(function (error) {
          console.log('Error getting document:', error);
        })
        .then((result) => {
          resolve(result);
        });
    });
  };

  return getV();
}
