import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../../config/firebase';
// Components
// Reducers
import { getCe, setDate } from '../../sagas/customEventSaga/reducer';
import { getConnectionsTagsAndFavs } from '../../sagas/connectionsTagsAndFavsSaga/reducer';
import { updateLocations } from '../../sagas/locationsSaga/reducer';
import {
  getNewEvent,
  resetNewEvent,
} from '../../sagas/createEventSaga/reducer';
import { Entity } from 'draft-js';
import { array } from 'yup';
import PromotionFeed from '../PromotionFeed';
import { withRouter } from 'react-router';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const query = require('array-query');
const _ = require('lodash');
const format = 'h:mm a';

class TrevelAgent extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      screen: 1,
      fullName: '',
      moodSelected: false,
      selections: 0,
      guestFavLocations: null,
      selectedDay: '',
      selectedTime: '',
      checkMonth: false,
      checkWeek: false,
      calendarLock: false,
    };
  }

  componentDidMount() {
    if (
      this.props.newEvent !== undefined &&
      this.props.newEvent.invitedUsers !== undefined &&
      Object.keys(this.props.newEvent.invitedUsers).length !== 0 &&
      this.props.connectionsTagsAndFavs[0] !== undefined
    ) {
      // this.setTags(this.props.tags);
      this.setUsersTags(
        this.props.profile.tags.love,
        this.props.profile.tags.hate,
      );
    } else {
      // this.setTags(this.props.tags);
      this.setUsersTags(
        this.props.profile.tags.love,
        this.props.profile.tags.hate,
      );
    }

    // If Event Incluced with Date Set That Date and Lock Calendar
    let event = null;
    if (this.props.customEvent.event.locations.length > 0) {
      this.props.customEvent.event.locations.forEach(
        (location, i) => {
          if (location.event === true) {
            const eventDetails = query('id')
              .is(location.eventId)
              .on(this.props.timedEvents);
            event = eventDetails[0];
          }
        },
      );
      if (event !== null) {
        if (
          (event.scheduledTime.dates.repeat !== undefined &&
            event.scheduledTime.dates.repeat === true) ||
          (event.scheduledTime.dates.multiDates !== undefined &&
            event.scheduledTime.dates.multiDates.length > 1)
        ) {
          this.setState({
            // selectedDay: selectedDay._d,
            calendarLock: false,
            event,
          });
        } else {
          let selectedDay = moment(
            event.scheduledTime.dates.startTimestamp,
          );
          this.setState({
            selectedDay: selectedDay._d,
            calendarLock: true,
            event,
          });
        }
      }
    }
  }

  getConnectionsPlaces(locations) {
    let comboTagsStageTwo = [];
    let favLocations = [];
    let favs = {};
    let comboTags = [];

    if (this.props.connectionsTagsAndFavs.status === undefined) {
      this.props.connectionsTagsAndFavs.forEach((user) => {
        if (user.favorites !== undefined) {
          user.favorites.forEach((favorite) => {
            let n = comboTags.includes(favorite);
            if (n === false) {
              comboTags.push(favorite);
              favs[favorite] = {
                count: 2,
                id: favorite,
                users: [user.firstName],
              };
            } else {
              favs[favorite].count = favs[favorite].count + 1;

              favs[favorite].users.push(user.firstName);
            }
          });
        }
      });
    }

    this.props.profile.favorites.forEach((favorite) => {
      let n = comboTags.includes(favorite);
      if (n === false) {
        comboTags.push(favorite);
        favs[favorite] = {
          count: 1,
          id: favorite,
          users: [this.props.profile.firstName],
        };
      } else {
        favs[favorite].count = favs[favorite].count + 1;

        favs[favorite].users.push(this.props.profile.firstName);
      }
    });

    Object.keys(favs).forEach((key) => {
      comboTagsStageTwo.push(favs[key]);
    });
    if (comboTagsStageTwo.length !== 0) {
      comboTagsStageTwo.forEach((fav) => {
        const locationDetails = query('locationId')
          .is(fav.id)
          .on(locations);
        let newLocation = {};
        if (locationDetails[0] !== undefined) {
          newLocation = {
            locationTitle: locationDetails[0].locationTitle,
            locationId: locationDetails[0].locationId,
            locationThumb: locationDetails[0].locationThumb,
            address: locationDetails[0].address,
            weight: fav.count,
            users: fav.users,
          };
        }
        favLocations.push(newLocation);
      });
    }

    let finalFavList = favLocations.sort((b, a) =>
      a.weight > b.weight ? 1 : -1,
    );

    // this.setState({
    //   // guestFavLocations: finalFavList,
    //   loading: false,
    // });

    return finalFavList;
  }

  getConnectionTagsandFavs(invitedUser) {
    if (Object.keys(invitedUser).length !== 0) {
      let inviteId = this.props.newEvent.invitedUser.userId;
      this.props.getConnectionsTagsAndFavs(inviteId);
    }
  }

  getTagStatus(tag) {
    let userTagsLove = this.props.profile.tags.love;
    let userTagsHate = this.props.profile.tags.hate;

    let otherUserTagsLove = null;
    // let otherUserTagsHate = null;
    if (
      this.props.newEvent !== undefined &&
      Object.keys(this.props.newEvent.invitedUsers).length !== 0 &&
      this.props.connectionsTagsAndFavs !== undefined &&
      this.props.connectionsTagsAndFavs[0] !== undefined &&
      this.props.connectionsTagsAndFavs[0].tags !== undefined
    ) {
      otherUserTagsLove =
        this.props.connectionsTagsAndFavs[0].tags.love;
      // otherUserTagsHate = this.props.connectionsTagsAndFavs[0].tags.hate;
    }

    let status = 0;

    Object.keys(userTagsLove).forEach((key) => {
      userTagsLove[key].forEach((userTag) => {
        if (tag === userTag) {
          status = 1;
          if (
            otherUserTagsLove !== undefined &&
            otherUserTagsLove !== null
          ) {
            Object.keys(otherUserTagsLove).forEach((key) => {
              otherUserTagsLove[key].forEach((otherUserTag) => {
                if (tag === otherUserTag) {
                  status = 3;
                }
              });
            });
          }
        }
      });
    });

    if (
      otherUserTagsLove !== undefined &&
      otherUserTagsLove !== null &&
      status === 0
    ) {
      Object.keys(otherUserTagsLove).forEach((key) => {
        otherUserTagsLove[key].forEach((otherUserTag) => {
          if (tag === otherUserTag) {
            status = 2;
          }
        });
      });
    }

    return status;
  }

  setTags(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        let status = this.getTagStatus(tag.tag);
        updatedTags[x].tags[y].status = status;
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    this.setState({
      tagFilters: updatedTags,
    });
  }

  setUsersTags(userLove, userHate) {
    let userTags = [];
    let connectionsTags = [];

    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };

    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };

    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };

    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };

    if (
      Object.keys(this.props.newEvent.invitedUsers).length !== 0 &&
      this.props.connectionsTagsAndFavs[0] !== undefined &&
      this.props.connectionsTagsAndFavs[0].tags !== undefined
    ) {
      let allTags = {
        tagsBeverages: [],
        tagsDateType: [],
        tagsSetting: [],
        tagsCuisine: [],
      };
      // Combine Tags
      this.props.connectionsTagsAndFavs[0].tags.love.tagsBeverages.forEach(
        (tagcat, x) => {
          let n = allTags.tagsBeverages.includes(tagcat);
          if (n === false) {
            allTags.tagsBeverages.push(tagcat);
          }
        },
      );
      userLove.tagsBeverages.forEach((tagcat, x) => {
        let n = allTags.tagsBeverages.includes(tagcat);
        if (n === false) {
          allTags.tagsBeverages.push(tagcat);
        }
      });
      this.props.connectionsTagsAndFavs[0].tags.love.tagsDateType.forEach(
        (tagcat, x) => {
          let n = allTags.tagsDateType.includes(tagcat);
          if (n === false) {
            allTags.tagsDateType.push(tagcat);
          }
        },
      );
      userLove.tagsDateType.forEach((tagcat, x) => {
        let n = allTags.tagsDateType.includes(tagcat);
        if (n === false) {
          allTags.tagsDateType.push(tagcat);
        }
      });
      this.props.connectionsTagsAndFavs[0].tags.love.tagsSetting.forEach(
        (tagcat, x) => {
          let n = allTags.tagsSetting.includes(tagcat);
          if (n === false) {
            allTags.tagsSetting.push(tagcat);
          }
        },
      );
      userLove.tagsSetting.forEach((tagcat, x) => {
        let n = allTags.tagsSetting.includes(tagcat);
        if (n === false) {
          allTags.tagsSetting.push(tagcat);
        }
      });
      this.props.connectionsTagsAndFavs[0].tags.love.tagsCuisine.forEach(
        (tagcat, x) => {
          let n = allTags.tagsCuisine.includes(tagcat);
          if (n === false) {
            allTags.tagsCuisine.push(tagcat);
          }
        },
      );
      userLove.tagsCuisine.forEach((tagcat, x) => {
        let n = allTags.tagsCuisine.includes(tagcat);
        if (n === false) {
          allTags.tagsCuisine.push(tagcat);
        }
      });

      Object.keys(allTags).forEach((key) => {
        allTags[key].map((tag, y) => {
          let newTag = {};
          let status = this.getTagStatus(tag);
          newTag.checked = false;
          newTag.tagindex = y;
          newTag.status = status;
          newTag.tag = tag;
          if (key === 'tagsBeverages') {
            newTag.parentindex = 0;
            tagsBeverages.tags.push(newTag);
          } else if (key === 'tagsCuisine') {
            newTag.parentindex = 1;
            tagsCuisine.tags.push(newTag);
          } else if (key === 'tagsDateType') {
            newTag.parentindex = 3;
            tagsDateType.tags.push(newTag);
          } else if (key === 'tagsSetting') {
            newTag.parentindex = 2;
            tagsSetting.tags.push(newTag);
          }
        });
      });
    } else {
      Object.keys(userLove).forEach((key) => {
        userLove[key].map((tag, y) => {
          let newTag = {};
          newTag.checked = false;
          newTag.tagindex = y;
          newTag.status = 0;
          newTag.tag = tag;
          if (key === 'tagsBeverages') {
            newTag.parentindex = 0;
            tagsBeverages.tags.push(newTag);
          } else if (key === 'tagsCuisine') {
            newTag.parentindex = 1;
            tagsCuisine.tags.push(newTag);
          } else if (key === 'tagsDateType') {
            newTag.parentindex = 3;
            tagsDateType.tags.push(newTag);
          } else if (key === 'tagsSetting') {
            newTag.parentindex = 2;
            tagsSetting.tags.push(newTag);
          }
        });
      });
    }

    userTags.push(tagsBeverages);
    userTags.push(tagsCuisine);
    userTags.push(tagsSetting);
    userTags.push(tagsDateType);

    this.getConnectionsPlaces(this.props.alllocations);

    this.setState({
      userTags: userTags,
      loading: false,
    });
  }

  toggleTag(selectedTag, status) {
    let userTags = this.state.userTags;

    userTags.forEach((tagcat, x) => {
      tagcat.tags.forEach((tag, y) => {
        if (selectedTag.tag === tag.tag) {
          userTags[x].tags[y].checked = status;
        }
      });
    });

    let currentSelections = this.state.selections;
    if (status === true) {
      currentSelections = this.state.selections + 1;
    } else currentSelections = this.state.selections - 1;

    this.setState({
      userTags: userTags,
      moodSelected: true,
      selections: currentSelections,
    });
    // this.props.setTagFilters(userTags);
  }

  getButton(tag) {
    let style = 'tagFilterBtnNone';
    if (tag.status === 1) {
      style = 'tagFilterBtnUser';
    } else if (tag.status === 2) {
      style = 'tagFilterBtnOther';
    } else if (tag.status === 3) {
      style = 'tagFilterBtnMatched';
    }
    if (tag.checked === false) {
      return (
        <span
          className={style}
          onClick={() => {
            this.toggleTag(tag, true);
          }}
        >
          {tag.tag}
        </span>
      );
    } else {
      return (
        <span
          className="tagFilterBtnActive"
          onClick={() => {
            this.toggleTag(tag, false);
          }}
        >
          {tag.tag}
        </span>
      );
    }
  }

  getSection(tags, title) {
    let sortedTags = _.sortBy(tags, (o) => o.status).reverse();
    if (tags.length > 0) {
      return (
        <div className="section clear">
          <h6>{title}</h6>
          {sortedTags.map((tag, index) => (
            <Col xs={4} md={4} sm={4} lg={4} className="no-padding">
              {this.getButton(tag)}
            </Col>
          ))}
        </div>
      );
    }
  }

  getHelpMessage(userTags, userFavorites) {
    let status = true;
    userTags.forEach((tags) => {
      if (tags.tags.length > 0) {
        status = false;
      }
    });
    if (status === true) {
      return (
        <div>
          <div
            style={{
              padding: '15px 10px 10px 10px',
              border: '1px solid lightgrey',
              margin: '0px auto',
              width: '100%',
              marginTop: '100px',
              background: 'white',
              display: 'flex',
              textAlign: 'left',
            }}
          >
            <Col xs={2} md={1} sm={2} lg={1}>
              <div style={{ float: 'left' }}>
                <MaterialIcon
                  icon="help_outline"
                  size={35}
                  color="rgb(238 1 88)"
                />
              </div>
            </Col>
            <Col xs={10} md={11} sm={10} lg={11}>
              <h4 style={{ fontSize: '18px', marginBottom: '10px' }}>
                Trevel Agent
              </h4>
              <p
                style={{
                  margin: '0px',
                  padding: '0px 0px 10px 0px',
                }}
              >
                Your Trevel Agent is here to assist you in planning
                your experience.
              </p>
              <p>
                Be sure to set up your{' '}
                <span style={{ fontWeight: '600', fontSize: '14px' }}>
                  Favorite Places
                </span>{' '}
                and your{' '}
                <span style={{ fontWeight: '600', fontSize: '14px' }}>
                  Preferences
                </span>{' '}
                to help your Tevel Agent find you the perfect place.
              </p>
              <p>
                For now you can just click on{' '}
                <span style={{ fontWeight: '600', fontSize: '14px' }}>
                  Skip
                </span>{' '}
                below
              </p>
            </Col>
          </div>
        </div>
      );
    }
  }

  // Standardize these two

  getPlaces(events, profile) {
    let favorites = [];
    if (profile.favorites !== undefined) {
      favorites = profile.favorites;
    }
    let updatedLocations = [];
    let favoriteLocations = [];
    let locationIds = {};
    if (events !== undefined) {
      events.forEach((event) => {
        event.packageDetails.locations.forEach((location) => {
          let newLocation = {};
          if (locationIds[location.locationId] === undefined) {
            let isFavorite = _.includes(
              favorites,
              location.locationId,
            );
            newLocation = {
              locationTitle: location.locationTitle,
              locationId: location.locationId,
              locationThumb: location.locationThumb,
              address: location.address,
              weight: 1,
            };
            if (isFavorite === true) {
              favoriteLocations.push(newLocation);
            }
            locationIds[location.locationId] = location.locationId;
            updatedLocations.push(newLocation);
          } else {
            let index = _.findIndex(updatedLocations, {
              locationId: location.locationId,
            });
            let weight = updatedLocations[index].weight + 1;
            updatedLocations[index].weight = weight;
          }
        });
      });
    }

    return favoriteLocations;
  }

  getImage(type, locationStart) {
    let location = '';
    let locations = this.props.alllocations;
    const locationDetails = query('locationId')
      .is(locationStart.locationId)
      .on(locations);
    location = locationDetails[0];
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getLocation(location) {
    if (location.weight !== undefined) {
      return (
        <div>
          <Row className="no-padding">
            <Col xs={2} md={1} sm={2} lg={1} className="no-padding">
              {this.getImage('thumb', location)}
            </Col>
            <Col xs={8} md={9} sm={8} lg={9}>
              <span className="location-title">
                {location.locationTitle}
              </span>
              <span className="address">
                {location.address.street}
              </span>
              <span className="address">
                {location.address.city} {location.address.state},{' '}
                {location.address.zip}
              </span>
            </Col>
            <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
              <div className="right text-right">
                <MaterialIcon
                  icon="add"
                  size={30}
                  color="#f21d59"
                  onClick={() => {
                    this.addLocation(
                      this.props.customEvent,
                      location,
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              {location.users.map((user, index) => (
                <span className="experienceTag">{user}</span>
              ))}
            </Col>
          </Row>
        </div>
      );
    }
  }

  // getUsersFavorites(favoriteLocations) {
  //   if (
  //     favoriteLocations !== undefined &&
  //     favoriteLocations.length !== 0
  //   ) {
  //     return (
  //       <div className="wizard-section">
  //         <h6>Your Favorite Places</h6>
  //         <Row>{this.listLocations(favoriteLocations)}</Row>
  //       </div>
  //     );
  //   }
  // }

  getConnectionsFavorites(favoriteLocations) {
    let name = this.props.newEvent.invitedUser.firstName + "'s";
    if (
      favoriteLocations !== undefined &&
      favoriteLocations.length !== 0
    ) {
      return (
        <div className="wizard-section">
          {/* <h6>{name} Favorite Places</h6> */}
          <Row>{this.listLocations(favoriteLocations)}</Row>
        </div>
      );
    }
  }

  getPromotionalFeed() {
    if (this.props.customEvent.event.locations.length === 0) {
      return <PromotionFeed />;
    }
  }

  getFavoriteSection(
    selections,
    connectionsFavoriteLocations,
    favoriteLocations,
  ) {
    let num = 0;
    num =
      connectionsFavoriteLocations.length + favoriteLocations.length;
    if (selections === 0) {
      if (num > 0) {
        return (
          <div className="favoriteSection">
            <div className="secondary-title-bar-agent">
              <div>
                <Container>
                  <Row className="no-margin">
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding text-center"
                    >
                      <span>"How about a favorite location?"</span>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className=" section ">
              {this.getConnectionsFavorites(
                connectionsFavoriteLocations,
              )}
              {/* {this.getUsersFavorites(favoriteLocations)} */}
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className=" section favoriteSection">
          {/* <a
            onClick={() => {
              this.changeScreen(2);
            }}
            className="solidRedbutton"
          >
            <span>All Set!</span>
          </a> */}
        </div>
      );
    }
  }

  giveSuggestions() {
    let customEvent = this.props.customEvent;
    let tags = this.props.tags;
    let tagsUpdated = this.props.tags;

    this.state.userTags.forEach((tagCatt) => {
      tagCatt.tags.forEach((tagg) => {
        tags.forEach((tagCat, x) => {
          tagCat.tags.forEach((tag, y) => {
            if (tag.tag === tagg.tag && tagg.checked === true) {
              tagsUpdated[x].tags[y].checked = true;
            }
          });
        });
      });
    });
    customEvent.selectedTags = tagsUpdated;
    customEvent.initialMoodSet = true;
    this.props.getCe(customEvent);
    this.props.setTrevelAgentState(false);
    this.props.history.push('/packages');
  }

  checkConnectFirst() {
    if (this.props.newEvent.invitedUsers.length === 0) {
      if (
        this.props.newEvent.fromLocation === true ||
        this.props.newEvent.fromEntertainment === true
      ) {
        this.saveTags(6);
      } else {
        this.saveTags(4);
      }
    } else {
      this.changeScreen(0);
    }
  }

  setConnectFirst(value) {
    if (value === false) {
      this.saveTags(4);
    } else {
      const newEvent = {
        connections: this.props.newEvent.connections,
        planFirst: this.props.newEvent.planFirst,
        connectFirst: value,
        clear: this.props.newEvent.clear,
        step: 0,
        invitedUsers: this.props.newEvent.invitedUsers,
        favoriteLocations: this.props.newEvent.favoriteLocations,
        fromFavorite: this.props.newEvent.fromFavorite,
        fromVEvent: this.props.newEvent.fromVEvent,
        fromLocation: this.props.newEvent.fromLocation,
        fromPackage: this.props.newEvent.fromPackage,
        vEvent: this.props.newEvent.vEvent,
        location: this.props.newEvent.locations,
        weightedLocations: this.props.newEvent.weightedLocations,
        customEvent: this.props.customEvent,
      };
      this.props.getNewEvent(newEvent);
      this.saveTags(2);
    }
  }

  saveTags(step) {
    let customEvent = this.props.customEvent;
    let tags = this.props.tags;
    let tagsUpdated = this.props.tags;
    this.state.userTags.forEach((tagCatt) => {
      tagCatt.tags.forEach((tagg) => {
        tags.forEach((tagCat, x) => {
          tagCat.tags.forEach((tag, y) => {
            if (tag.tag === tagg.tag && tagg.checked === true) {
              tagsUpdated[x].tags[y].checked = true;
            }
          });
        });
      });
    });

    customEvent.selectedTags = tagsUpdated;
    customEvent.initialMoodSet = true;
    customEvent.step = step;
    this.props.getCe(customEvent);
    this.props.setTrevelAgentState(false);
  }

  setDate() {
    let customEvent = this.props.customEvent;
    customEvent.event.scheduledTime = this.setSchedule(
      this.state.selectedDay,
      this.state.selectedTime,
    );
    this.props.setSelectedDate(this.state.selectedDay);
    // this.changeScreen(2);
    customEvent.step = 4;
    this.props.setDate(customEvent);
    if (
      this.props.newEvent.fromLocation === true ||
      this.props.newEvent.fromEntertainment === true ||
      (this.props.customEvent.event.locations.length > 0 &&
        this.props.taRequest.reroll === false)
    ) {
      this.saveTags(6);
    } else {
      this.saveTags(4);
    }
  }

  changeScreen(screen) {
    this.setState({
      screen,
    });
  }

  getScreen(locations, guestFavLocations) {
    let favoriteLocations = this.getPlaces(
      this.props.events,
      this.props.profile,
    );

    let connectionsFavoriteLocations = [];
    if (Object.keys(this.props.newEvent.invitedUsers).length !== 0) {
      connectionsFavoriteLocations = guestFavLocations;
    } else {
      connectionsFavoriteLocations = guestFavLocations;
    }

    if (this.state.screen === 0) {
      return (
        <div style={{ height: '100%', backgroundColor: '#ee0059' }}>
          <Container>
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className=" text-center"
              >
                <div style={{ marginTop: '100px' }}>
                  <span
                    style={{
                      color: 'white',
                      fontSize: '18px',
                    }}
                  >
                    Would you like your guest to pick first?
                  </span>

                  <a onClick={() => this.setConnectFirst(true)}>
                    <span className="stackedWhiteButtonBlock fullwidth">
                      Yes
                    </span>
                  </a>

                  <a onClick={() => this.setConnectFirst(false)}>
                    <span className="stackedWhiteButtonBlock fullwidth">
                      No
                    </span>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <style
            dangerouslySetInnerHTML={{
              __html: 'html {background: #ee0059} ',
            }}
          />
        </div>
      );
    }
    // else if (this.state.screen === 1) {
    //   return (
    //     <div>
    //       <div className="secondary-title-bar">
    //         <div>
    //           <Container>
    //             <Row className="no-margin">
    //               <Col
    //                 xs={12}
    //                 md={12}
    //                 sm={12}
    //                 lg={12}
    //                 className="no-padding text-center"
    //               >
    //                 <span>
    //                   "In the mood for one of your favorites?"
    //                 </span>
    //               </Col>
    //             </Row>
    //           </Container>
    //         </div>
    //       </div>
    //       <div className="flow-root padding-top-twenty">
    //         <div className="section">{this.getKey()}</div>
    //         {/* get rid oof key class and standardize */}
    //         {/* <div className="key">{this.getKey()}</div> */}

    //         {this.getHelpMessage(
    //           this.state.userTags,
    //           this.props.profile.favorites,
    //         )}

    //         {this.getSection(this.state.userTags[0].tags, 'A Drink')}
    //         {this.getSection(
    //           this.state.userTags[1].tags,
    //           'A Favorite Cuisine',
    //         )}
    //         {this.getSection(
    //           this.state.userTags[2].tags,
    //           'A Certain Atmosphere',
    //         )}
    //         {this.getSection(
    //           this.state.userTags[3].tags,
    //           'Something...',
    //         )}
    //       </div>
    //       {this.getFavoriteSection(
    //         this.state.selections,
    //         connectionsFavoriteLocations,
    //         favoriteLocations,
    //       )}
    //     </div>
    //   );
    // } else if (this.state.screen === 2) {
    //   return (
    //     <div>
    //       <div className="secondary-title-bar">
    //         <div>
    //           <Container>
    //             <Row className="no-margin">
    //               <Col
    //                 xs={12}
    //                 md={12}
    //                 sm={12}
    //                 lg={12}
    //                 className="no-padding text-center"
    //               >
    //                 <span>"Would you like some suggestions?"</span>
    //               </Col>
    //             </Row>
    //           </Container>
    //         </div>
    //       </div>
    //       <div className="flow-root padding-top-twenty">
    //         <center>
    //           <a
    //             onClick={() => {
    //               this.giveSuggestions();
    //             }}
    //           >
    //             <span className="stackedRedButtonBlock">Yes</span>
    //           </a>
    //           <a
    //             onClick={() => {
    //               this.saveTags();
    //             }}
    //           >
    //             <span className="stackedRedButtonBlock">No</span>
    //           </a>
    //         </center>
    //       </div>
    //       <div className="section">
    //         <div className="pushtwenty wizard-border-top">
    //           {this.getPromotionalFeed()}
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    // else if (this.state.screen === 3) {
    else if (this.state.screen === 1) {
      return (
        <div>
          <div className="secondary-title-bar">
            <div>
              <Container className="no-margin">
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    <span>When would you like to go out?</span>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="flow-root padding-top-twenty">
            <center>{this.getScheduleTab()}</center>
          </div>
        </div>
      );
    }
  }

  listLocations(locations) {
    if (locations !== undefined) {
      return (
        <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
          {locations.map((location, index) => (
            <div className="oddeven" key={'key' + index}>
              {this.getLocation(location)}
            </div>
          ))}
        </Col>
      );
    }
  }

  getFooter(moodSelected) {
    // if (moodSelected === true && this.state.screen === 1) {
    //   return (
    //     <div id="startBar">
    //       <Container>
    //         <Row className="no-margin">
    //           <Col
    //             xs={12}
    //             md={12}
    //             sm={12}
    //             lg={12}
    //             className="no-padding"
    //           >
    //             {/* <a
    //               // onClick={() => {
    //               //   this.props.setTrevelAgentState(false);
    //               // }}
    //               onClick={() => {
    //                 this.changeScreen(2);
    //               }}
    //               className="left"
    //             >
    //               <span className="center solidredbutton">Back</span>
    //             </a> */}
    //             <a
    //               // onClick={() => {
    //               //   this.props.setTrevelAgentState(false);
    //               // }}
    //               onClick={() => {
    //                 this.changeScreen(3);
    //               }}
    //               className="right"
    //             >
    //               <span className="center solidredbutton">
    //                 All Set!
    //               </span>
    //             </a>
    //           </Col>
    //         </Row>
    //       </Container>
    //     </div>
    //   );
    // } else if (this.state.screen === 3) {
    if (this.state.screen === 1) {
      if (
        this.state.selectedDay === '' &&
        this.state.selectedTime === '' &&
        this.props.customEvent.event.locations.length === 0
      ) {
        return (
          <div id="startBar">
            <Container>
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  <center>
                    {/* <a
                      onClick={() => {
                        this.changeScreen(2);
                      }}
                    > */}
                    <a
                      // onClick={() => {
                      //   this.saveTags();
                      // }}
                      onClick={() => {
                        this.checkConnectFirst();
                      }}
                      className="right"
                    >
                      <span className="center solidredbutton">
                        Skip
                      </span>
                    </a>
                  </center>
                </Col>
              </Row>
            </Container>
          </div>
        );
      } else {
        if (this.state.selectedDay === '') {
          return (
            <div id="startBar">
              <Container>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <center>
                      <a className="right">
                        <span className="center solidgreybutton">
                          All Set!
                        </span>
                      </a>
                    </center>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        } else {
          return (
            <div id="startBar">
              <Container>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <center>
                      <a
                        onClick={() => {
                          // this.props.setTrevelAgentState(false);

                          this.setDate();
                        }}
                        className="right"
                      >
                        <span className="center solidredbutton">
                          All Set!
                        </span>
                      </a>
                    </center>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        }
      }
    } else {
      return (
        <div id="startBar">
          <Container>
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <center>
                  <a
                    onClick={() => {
                      // this.props.setTrevelAgentState(false);
                      this.changeScreen(3);
                    }}
                    className="right"
                  >
                    <span className="center solidredbutton">
                      Skip
                    </span>
                  </a>
                </center>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <Link
                to={{
                  pathname: " "
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link> */}
              <Link
                to={{
                  pathname: '/',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">
                  Create New Experience
                </span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <a
                onClick={() => {
                  this.props.setTrevelAgentState(false);
                }}
                className="right"
              >
                <MaterialIcon
                  icon="keyboard_arrow_down"
                  size={25}
                  color="white"
                />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  addLocation(customEvent, location) {
    let customImages = false;
    if (location.settings.customImages !== undefined) {
      customImages = location.settings.customImages;
    }

    if (customEvent.event.locations !== null) {
      const newLocations = [];
      customEvent.event.locations.forEach((location) => {
        newLocations.push(location);
      });
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          type: 0,
          locationId: location.locationId,
          locationTitle: location.locationTitle,
          locationThumb: location.locationThumb,
          address: location.address,
          hours: location.hours,
          settings: {
            customImages,
          },
        });
      }
      customEvent.event.locations = newLocations;
      customEvent.step = 1;
      this.props.getCe(customEvent);
    } else {
      const newLocations = [];
      if (location.type === 1) {
        newLocations.push({ location: location, type: 1 });
      } else {
        newLocations.push({
          locationId: location.locationId,
          type: 0,
        });
      }
      customEvent.step = 1;
      customEvent.event.locations = newLocations;
      this.props.getCe(customEvent);
      this.props.history.push('/event/create/home/1');
    }
  }

  getKey() {
    if (this.props.newEvent.invitedUsers.length !== 0) {
      return (
        <div>
          <b>Tag Colors: </b>
          <span className="tag-user">
            {/* {this.props.profile.firstName} */}
            You
          </span>
          <span className="tag-otherUser">
            {/* {this.state.otherUser.firstName} */}
            Guests
          </span>
          <span className="tag-match">Match!</span>
        </div>
      );
    } else {
      return (
        <div>
          <b>Tag Colors: </b>
          <span className="tag-user">You</span>
        </div>
      );
    }
  }

  selectCat = (value) => {
    this.setState({
      [value.target.alt]: value.target.checked,
    });
  };

  setSchedule(selectedDay, selectedTime) {
    let bundle = {
      date: '',
      time: '',
      timeString: '',
      timestamp: '',
      checkWeek: this.state.checkWeek,
      checkMonth: this.state.checkMonth,
    };
    // Check if Time Set
    if (
      selectedTime !== '' &&
      selectedTime !== undefined &&
      selectedTime !== null
    ) {
      const datestring = String(selectedTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.time = timeValue;
      bundle.timeString = selectedTime._d;
    }
    // Check for Date
    if (
      selectedDay !== '' &&
      selectedDay !== undefined &&
      selectedDay !== null
    ) {
      const timestamp = moment(selectedDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.date = dateString;
      bundle.timestamp = timestampReady;
    }
    return bundle;
  }

  // Schedule Functions
  handleDayClick = (day, { selected }) => {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
    this.props.showTopMenu(true);
  };

  onTimeChange = (time) => {
    this.setState({ selectedTime: time });
  };

  getWhen(event) {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 'tu' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];

    let days = [];

    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.repeatDays !== undefined
    ) {
      event.scheduledTime.dates.repeatDays.forEach((day) => {
        let eventDetailsx = query('short').is(day).on(dayArray);
        let eventx = eventDetailsx[0];
        days.push(eventx.full);
      });
      let dayText = '';
      days.forEach((day, index) => {
        if (days.length === 1) {
          dayText = day;
        } else if (days.length === 7) {
          dayText = 'Day';
        } else if (days.length === 2) {
          if (index === 0) {
            dayText = day;
          } else {
            dayText = dayText + ' & ' + day;
          }
        } else if (
          days.length > 2 &&
          event.scheduledTime.dates.timesSelected > 1
        ) {
          if (index === 0) {
            dayText = day + 's';
          } else if (days.length - 1 === index) {
            dayText = dayText + ', & ' + day + 's';
          } else {
            dayText = dayText + ',  ' + day + 's';
          }
        } else if (
          days.length > 2 &&
          event.scheduledTime.dates.timesSelected === 1
        ) {
          if (index === 0) {
            dayText = day;
          } else if (days.length - 1 === index) {
            dayText = dayText + ', & ' + day;
          } else {
            dayText = dayText + ',  ' + day;
          }
        }
      });

      let weekly = '';
      if (event.scheduledTime.dates.timesSelected === 1) {
        weekly = 'week';
      } else {
        weekly = event.scheduledTime.dates.timesSelected + ' weeks ';
      }
      let statement = '';
      if (event.scheduledTime.dates.timesSelected === 1) {
        if (event.scheduledTime.dates.repeatType === 'Weekly') {
          statement = 'every ' + dayText;
        }
      } else {
        if (event.scheduledTime.dates.repeatType === 'Weekly') {
          statement = 'every ' + weekly + ' on ' + dayText;
        }
      }

      return statement;
    } else {
      let statement = '';
      if (event.scheduledTime !== undefined) {
        event.scheduledTime.dates.multiDates.forEach(
          (date, index) => {
            if (index === 0) {
              let currentTime = moment(date.date).format('MMMM D');
              statement = statement + currentTime + ' ';
            } else if (
              (index =
                event.scheduledTime.dates.multiDates.length - 1)
            ) {
              let currentTime = moment(date.date).format('D');
              statement = statement + '& ' + currentTime;
            } else {
              let currentTime = moment(date.date).format('D');
              statement = statement + ',' + currentTime;
            }
          },
        );
      }
      return statement;
    }
  }

  getDate(event) {
    let selectedDay = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedDay = moment(event.scheduledTime.dates.startTimestamp);
    }
    let to = selectedDay._d.toLocaleDateString();
    let t = to.substr(0, to.lastIndexOf('/'));
    return t;
  }

  getLocationMessage(locations) {
    let message = 'hello';

    // locations.forEach((location) => {
    //   location.hours.forEach((hour) => {
    //   if (
    //     location.hours[lowercase][openString] !== undefined &&
    //     location.hours[lowercase][openString] === false
    //   ) {
    //     console.log('hound', lowercase, location);
    //     match = false;
    //   }
    // });

    return <span style={{ fontWeight: '600' }}>{message}</span>;
  }

  isDayDisabled = (day) => {
    // Remember, in this scenario - TRUE means the day is disabled. FALSE means it is not disabled.
    let locationClosed = false;
    let eventNotAvailable = false;
    let noItineraryAvailable = null;
    let locations = this.props.customEvent.event.locations;
    const today = moment().format('YYYY-MM-DD');
    const timestamp = moment(day).unix();
    const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
    let dayName = moment(day).format('dddd');
    let lowercase = dayName.toLowerCase();
    if (lowercase === 'saturday') {
      lowercase = 'saterday';
    }
    let openString = lowercase + 'Open';
    // Is this day in the past?
    let pastDate = moment(dateString).isBefore(today);
    // First we check if date is already in the past
    if (pastDate === false) {
      // check for events with multiple days and build out the multiDates Array
      if (locations.length !== 0) {
        locations.forEach((location) => {
          // If this is an event lets get to it.
          if (
            location.eventId !== undefined &&
            location.eventId !== ''
          ) {
            //This is an Event
            let multiDates = [];
            let match = false;
            let eventDetails = query('eventId')
              .is(location.eventId)
              .on(this.props.timedEvents);

            if (
              eventDetails[0].scheduledTime.dates !== undefined &&
              eventDetails[0].scheduledTime.dates.multiDates !==
                undefined
            ) {
              eventDetails[0].scheduledTime.dates.multiDates.forEach(
                (date) => {
                  multiDates.push(
                    moment(date.date).format('YYYY-MM-DD'),
                  );
                },
              );
            }
            if (multiDates.length !== 0) {
              multiDates.forEach((date) => {
                let check = moment(dateString).isSame(date);
                if (check === true) {
                  match = true;
                }
              });
            }
            if (match === false) {
              eventNotAvailable = true;
            }
          } else {
            if (
              location !== undefined &&
              location.hours !== undefined &&
              location.hours[lowercase][openString] !== undefined &&
              location.hours[lowercase][openString] === true
            ) {
              locationClosed = true;
            } else if (location.hours === undefined) {
              let locationDetails = query('locationId')
                .is(location.locationId)
                .on(this.props.locations);

              if (
                locationDetails[0] !== undefined &&
                locationDetails[0].hours !== undefined &&
                locationDetails[0].hours[lowercase][openString] !==
                  undefined &&
                locationDetails[0].hours[lowercase][openString] ===
                  true
              ) {
                locationClosed = true;
              }
            }
          }
        });
      } else {
        let check = moment(dateString).isSame(today);
        let past = moment(dateString).isBefore(today);
        if (past === false || check === true) {
          noItineraryAvailable = true;
        } else {
          noItineraryAvailable = false;
        }
      }
    }
    if (
      locationClosed === true ||
      eventNotAvailable === true ||
      pastDate === true ||
      noItineraryAvailable === false
    ) {
      return true;
    } else {
      return false;
    }
  };

  getScheduleTab() {
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
      },
    };

    let eventInfo = { eventTitle: '' };
    let locations = this.props.customEvent.event.locations;
    let calendarLock = false;
    let multiDates = [];

    locations.forEach((location) => {
      // If this is an event lets get to it.

      if (location.eventId !== undefined && location.eventId !== '') {
        let eventDetails = query('eventId')
          .is(location.eventId)
          .on(this.props.timedEvents);

        if (eventDetails[0] !== undefined) {
          eventInfo = eventDetails[0];
          if (
            (eventInfo.scheduledTime !== undefined &&
              eventInfo.scheduledTime.dates.repeat !== undefined &&
              eventInfo.scheduledTime.dates.repeat === true) ||
            (eventInfo.scheduledTime.dates.multiDates !== undefined &&
              eventInfo.scheduledTime.dates.multiDates.length > 1)
          ) {
            calendarLock = false;
          } else {
            calendarLock = true;
          }
        }
      }
    });

    // function isDayDisabled(day, timedEvents) {
    //   console.log('dayx 2');
    //   // Remember, in this scenario - TRUE means the day is disabled. FALSE means it is not disabled.
    //   let locationClosed = false;
    //   let eventNotAvailable = false;
    //   let noItineraryAvailable = null;
    //   const today = moment().format('YYYY-MM-DD');
    //   const timestamp = moment(day).unix();
    //   const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
    //   let dayName = moment(day).format('dddd');
    //   let lowercase = dayName.toLowerCase();
    //   if (lowercase === 'saturday') {
    //     lowercase = 'saterday';
    //   }
    //   let openString = lowercase + 'Open';
    //   // Is this day in the past?
    //   let pastDate = moment(dateString).isBefore(today);
    //   // First we check if date is already in the past
    //   if (pastDate === false) {
    //     // check for events with multiple days and build out the multiDates Array
    //     if (locations.length !== 0) {
    //       locations.forEach((location) => {
    //         // If this is an event lets get to it.
    //         if (
    //           location.eventId !== undefined &&
    //           location.eventId !== ''
    //         ) {
    //           //This is an Event
    //           let multiDates = [];
    //           let match = false;
    //           let eventDetails = query('eventId')
    //             .is(location.eventId)
    //             .on(this.props.timedEvents);

    //           if (
    //             eventDetails[0].scheduledTime.dates !== undefined &&
    //             eventDetails[0].scheduledTime.dates.multiDates !==
    //               undefined
    //           ) {
    //             eventDetails[0].scheduledTime.dates.multiDates.forEach(
    //               (date) => {
    //                 multiDates.push(
    //                   moment(date.date).format('YYYY-MM-DD'),
    //                 );
    //               },
    //             );
    //           }
    //           if (multiDates.length !== 0) {
    //             multiDates.forEach((date) => {
    //               let check = moment(dateString).isSame(date);
    //               if (check === true) {
    //                 match = true;
    //               }
    //             });
    //           }
    //           if (match === false) {
    //             eventNotAvailable = true;
    //           }
    //         } else {
    //           if (
    //             location !== undefined &&
    //             location.hours !== undefined &&
    //             location.hours[lowercase][openString] !== undefined &&
    //             location.hours[lowercase][openString] === true
    //           ) {
    //             locationClosed = true;
    //           } else if (location.hours === undefined) {
    //             let locationDetails = query('locationId')
    //               .is(location.locationId)
    //               .on(this.props.locations);

    //             if (
    //               locationDetails[0] !== undefined &&
    //               locationDetails[0].hours !== undefined &&
    //               locationDetails[0].hours[lowercase][openString] !==
    //                 undefined &&
    //               locationDetails[0].hours[lowercase][openString] ===
    //                 true
    //             ) {
    //               locationClosed = true;
    //             }
    //           }
    //         }
    //       });
    //     } else {
    //       let check = moment(dateString).isSame(today);
    //       let past = moment(dateString).isBefore(today);
    //       if (past === false || check === true) {
    //         noItineraryAvailable = true;
    //       } else {
    //         noItineraryAvailable = false;
    //       }
    //     }
    //   }
    //   if (
    //     locationClosed === true ||
    //     eventNotAvailable === true ||
    //     pastDate === true ||
    //     noItineraryAvailable === false
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }

    return (
      <Container>
        {multiDates.length > 1 && (
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div style={{ padding: '15px', marginTop: '10px' }}>
                <center>
                  <span>
                    <span style={{ fontWeight: '600' }}>
                      {eventInfo.eventTitle} is{' '}
                      {this.getWhen(eventInfo)}
                    </span>
                  </span>
                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginTop: '15px',
                      marginBottom: '10px',
                      fontWeight: '500',
                      fontSize: '18px',
                      color: '#ed0259',
                    }}
                  >
                    When would you like to go?
                  </span>
                </center>
              </div>
            </Col>
          </Row>
        )}

        {/* {locations.length > 0 && (
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div style={{ padding: '15px', marginTop: '10px' }}>
                <center>
                  <span>{this.getLocationMessage(locations)}</span>
                  <span
                    style={{
                      display: 'block',
                      clear: 'both',
                      marginTop: '15px',
                      marginBottom: '10px',
                      fontWeight: '500',
                      fontSize: '18px',
                      color: '#ed0259',
                    }}
                  >
                    When would you like to go?
                  </span>
                </center>
              </div>
            </Col>
          </Row>
        )} */}

        <Row className="no-margin">
          <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
            {this.state.calendarLock === false ? (
              <div>
                <div className="text-center">
                  {multiDates.length > 1 ? (
                    <DayPicker
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiersStyles={modifiersStyles}
                      disabledDays={this.isDayDisabled}
                    />
                  ) : (
                    <DayPicker
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiersStyles={modifiersStyles}
                      disabledDays={this.isDayDisabled}
                    />
                  )}

                  {/* <center>
                    <p style={{ marginTop: '20px' }}>
                      {this.state.selectedDay
                        ? this.state.selectedDay.toLocaleDateString()
                        : 'Please select a day'}
                    </p>
                  </center> */}
                </div>
                <div className="text-center">
                  <span className="inputLabel">Time</span>
                  <TimePicker
                    showSecond={false}
                    defaultValue={this.state.selectedTime}
                    className="xxx"
                    onChange={this.onTimeChange}
                    format={format}
                    use12Hours
                    inputReadOnly
                  />
                </div>
                {this.state.selectedDay &&
                multiDates.length === 0 &&
                this.props.taRequest.reroll !== false ? (
                  <div
                    style={{
                      display: 'block',
                      width: '330px',
                      margin: '0px auto',
                      borderTop: '1px solid #d7d7d7',
                      padding: '15px 0px 0px 0px',
                    }}
                  >
                    <div>
                      <input
                        type="checkbox"
                        checked={this.state.checkWeek}
                        onChange={this.selectCat}
                        className="closedCheckBox"
                        alt="checkWeek"
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '5px',
                          top: '2px',
                        }}
                      />
                      <span
                        style={{
                          float: 'left',
                        }}
                      >
                        Check the{' '}
                        <span style={{ fontWeight: '600' }}>
                          weeks of{' '}
                          {moment(this.state.selectedDay).format(
                            'MMMM DD',
                          )}
                        </span>
                      </span>
                    </div>

                    <div
                      style={{
                        clear: 'both',
                        display: 'block',
                        paddingTop: '10px',
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={this.state.checkMonth}
                        onChange={this.selectCat}
                        className="closedCheckBox"
                        alt="checkMonth"
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '5px',
                          top: '2px',
                        }}
                      />
                      <span
                        style={{
                          float: 'left',
                        }}
                      >
                        Check the{' '}
                        <span style={{ fontWeight: '600' }}>
                          month of{' '}
                          {moment(this.state.selectedDay).format(
                            'MMMM',
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            ) : (
              <div>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div
                      style={{ padding: '15px', marginTop: '10px' }}
                    >
                      <center>
                        <span>
                          <span style={{ fontWeight: '600' }}>
                            {eventInfo.eventTitle} is{' '}
                            {this.getWhen(eventInfo)}.
                          </span>
                        </span>
                      </center>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <center>
                    {/* <h4 style={{ marginTop: '80px' }}>
                      {this.state.event.eventTitle}
                    </h4> */}

                    {/* <h2 style={{ marginTop: '80px' }}>
                      {this.getDate(this.props.customEvent.event)}
                    </h2> */}
                  </center>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  // getScheduleTab(description) {
  //   const modifiersStyles = {
  //     selected: {
  //       color: 'white',
  //       backgroundColor: '#f21d59',
  //     },
  //   };
  //   const today = new Date();
  //   return (
  //     <Container>
  //       <Row className="no-margin">
  //         <Col xs={12} md={12} sm={12} lg={12} className="no-padding">
  //           <div>
  //             <div className="text-center">
  //               <DayPicker
  //                 onDayClick={this.handleDayClick}
  //                 selectedDays={this.state.selectedDay}
  //                 modifiersStyles={modifiersStyles}
  //                 disabledDays={{ before: today }}
  //               />
  //               {/* <center>
  //                 <p style={{ marginTop: '20px' }}>
  //                   {this.state.selectedDay
  //                     ? this.state.selectedDay.toLocaleDateString()
  //                     : 'Please select a day'}
  //                 </p>
  //               </center> */}
  //             </div>
  //             <div
  //               className="text-center"
  //               style={{ width: '230px', marginBottom: '10px' }}
  //             >
  //               {this.state.selectedDay && (
  //                 <Row>
  //                   <Col
  //                     xs={4}
  //                     md={4}
  //                     sm={4}
  //                     lg={4}
  //                     className="no-padding text-center"
  //                   ></Col>
  //                   <Col
  //                     xs={4}
  //                     md={4}
  //                     sm={4}
  //                     lg={4}
  //                     className="no-padding text-center"
  //                   >
  //                     <center>
  //                       <span className="inputLabel">Time </span>
  //                     </center>
  //                   </Col>
  //                   <Col
  //                     xs={4}
  //                     md={4}
  //                     sm={4}
  //                     lg={4}
  //                     className="no-padding text-center"
  //                   >
  //                     <span
  //                       style={{
  //                         fontSize: '10px',
  //                         float: 'right',
  //                         display: 'block',
  //                         position: 'relative',
  //                         top: '15px',
  //                       }}
  //                     >
  //                       (Optional)
  //                     </span>
  //                   </Col>
  //                 </Row>
  //               )}
  //               {this.state.selectedDay ? (
  //                 <TimePicker
  //                   showSecond={false}
  //                   defaultValue={this.state.selectedTime}
  //                   className="xxx"
  //                   onChange={this.onTimeChange}
  //                   format={format}
  //                   use12Hours
  //                   inputReadOnly
  //                 />
  //               ) : (
  //                 <div></div>
  //               )}
  //             </div>
  // {this.state.selectedDay ? (
  //   <div
  //     style={{
  //       display: 'block',
  //       width: '330px',
  //       margin: '0px auto',
  //       borderTop: '1px solid #d7d7d7',
  //       padding: '15px 0px 0px 0px',
  //     }}
  //   >
  //     <div>
  //       <input
  //         type="checkbox"
  //         checked={this.state.checkWeek}
  //         onChange={this.selectCat}
  //         className="closedCheckBox"
  //         alt="checkWeek"
  //         style={{
  //           width: '20px',
  //           height: '20px',
  //           marginRight: '5px',
  //           top: '2px',
  //                     }}
  //                   />
  //                   <span
  //                     style={{
  //                       float: 'left',
  //                     }}
  //                   >
  //                     Check the{' '}
  //                     <span style={{ fontWeight: '600' }}>
  //                       week of{' '}
  //                       {moment(this.state.selectedDay).format(
  //                         'MMMM DD',
  //                       )}
  //                     </span>
  //                   </span>
  //                 </div>

  //                 <div
  //                   style={{
  //                     clear: 'both',
  //                     display: 'block',
  //                     paddingTop: '10px',
  //                   }}
  //                 >
  //                   <input
  //                     type="checkbox"
  //                     checked={this.state.checkMonth}
  //                     onChange={this.selectCat}
  //                     className="closedCheckBox"
  //                     alt="checkMonth"
  //                     style={{
  //                       width: '20px',
  //                       height: '20px',
  //                       marginRight: '5px',
  //                       top: '2px',
  //                     }}
  //                   />
  //                   <span
  //                     style={{
  //                       float: 'left',
  //                     }}
  //                   >
  //                     Check the{' '}
  //                     <span style={{ fontWeight: '600' }}>
  //                       month of{' '}
  //                       {moment(this.state.selectedDay).format(
  //                         'MMMM',
  //                       )}
  //                     </span>
  //                   </span>
  //                 </div>
  //               </div>
  //             ) : (
  //               <div></div>
  //             )}
  //           </div>
  //         </Col>
  //       </Row>
  //     </Container>
  //   );
  // }

  selectCat = (value) => {
    this.setState({
      [value.target.alt]: value.target.checked,
    });
  };

  render() {
    if (this.state.loading) {
      return null;
    }
    let guestFavLocations = this.getConnectionsPlaces(
      this.props.alllocations,
    );
    return (
      <div style={{ height: '100%' }}>
        {this.getTitleBar()}
        {/* <div className="secondary-title-bar">
          <div>
            <Container>
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding text-center"
                >
                  <span>What are you in the mood for?</span>
                </Col>
              </Row>
            </Container>
          </div>
        </div> */}
        {/* <Container className="no-margin"> */}
        <Row className="no-margin" style={{ height: '100%' }}>
          <Col
            xs={12}
            md={12}
            sm={12}
            lg={12}
            className="no-padding"
            style={{ height: '100%' }}
          >
            {this.getScreen(
              this.props.alllocations,
              guestFavLocations,
            )}
          </Col>
        </Row>
        {/* </Container> */}
        {this.state.screen !== 2 &&
          this.state.screen !== 0 &&
          this.getFooter(this.state.moodSelected)}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.container{padding-bottom:50px} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    customEvent: state.customEvent.customEvent,
    newEvent: state.newEvent,
    tags: state.tags.tags,
    events: state.events.events,
    user: state.user,
    connectionsTagsAndFavs: state.tagsAndFavs.connectionsTagsAndFavs,
    alllocations: state.locations.locations,
    timedEvents: state.timedEvents.events,
    taRequest: state.taRequest,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
    setDate: (customEvent) => {
      dispatch(setDate(customEvent));
    },
    getConnectionsTagsAndFavs: (userId) => {
      dispatch(getConnectionsTagsAndFavs(userId));
    },
    updateLocations: (bundle) => {
      dispatch(updateLocations(bundle));
    },
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrevelAgent),
);

// Location Details Available
// **************************
// this.props.location.state.location.location.locationTitle
// this.props.location.state.location.location.categories
// this.props.location.state.location.location.locationDescription
// this.props.location.state.location.location.locationHero
// this.props.location.state.location.location.locationThumb
// this.props.location.state.location.location.locationId
// this.props.location.state.location.location.locationType
// ** Vouchers and Packages as well
