import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class RedCallToAction extends Component {
  render() {
    return (
      <div className="feature red-back padding-bottom calltoaction">
        <div className="wrapper">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="callSection"
            >
              <center>
                <h2 className="reallywhite">
                  Find Your Perfect Experience
                </h2>
                <a
                  href="/signup?source=redcalltoaction"
                  className="button whiteButton"
                >
                  Create Your Free Account!
                </a>
              </center>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default RedCallToAction;
