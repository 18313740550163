import { Formik } from 'formik';
import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
import UserAvatar from 'react-user-avatar';
import { Col, Container, Row } from 'reactstrap';
import UserListItem from '../UserListItem';

const KEYS_TO_FILTERS = ['firstName', 'lastName', 'userName'];

const query = require('array-query');

class InviteUser extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      searchTerm: '',
      mode: 1,
      copied: false,
      connections: {},
      invitedUsers: [],
      screen: 1,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setConnectionsArray(this.props.connections);
  }

  setConnectionsArray(connections) {
    let readyConnections = {};
    if (connections.connections !== undefined) {
      readyConnections = connections.connections;
    }
    let connectionsArray = [];
    let connectionsCount = Object.keys(readyConnections).length;
    if (connectionsCount > 0) {
      Object.keys(readyConnections).forEach((key) => {
        Object.keys(readyConnections[key]).forEach((keyinner) => {
          if (readyConnections[key][keyinner].connected === true) {
            connectionsArray.push(readyConnections[key][keyinner]);
          }
        });
      });
    }
    this.setState({
      connectionsArray,
    });
  }

  sendInvite(user) {
    this.props.prepareInvite(user);
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  addUser(user, connections, invitedUsers) {
    let newUserFullName = '';
    if (user.newUser !== undefined && user.newUser === true) {
      newUserFullName = user.fullName;
    } else {
      newUserFullName = user.firstName + ' ' + user.lastName;
    }

    let newConnections = connections;

    if (newConnections[user.userId] === undefined) {
      // Add Guest
      if (user.newUser !== undefined && user.newUser === true) {
        newConnections[user.userId] = {
          access: true,
          approved: false,
          editor: false,
          attending: false,
          rejected: false,
          inviteAction: false,
          newUser: true,
          profile: {
            fullName: newUserFullName,
            profilePhotoPath: user.profilePhotoPath,
            userName: user.userName,
          },
        };
      } else {
        newConnections[user.userId] = {
          access: true,
          approved: false,
          editor: false,
          attending: false,
          rejected: false,
          inviteAction: false,
          profile: {
            fullName: newUserFullName,
            profilePhotoPath: user.profilePhotoPath,
            userName: user.userName,
          },
        };
        // }
      }
    } else {
      let xxx = {};
      Object.keys(connections).forEach((key) => {
        if (key !== user.userId) {
          xxx[key] = connections[key];
        }
      });
      newConnections = xxx;
    }

    let invitedUsersUpdate = invitedUsers;
    invitedUsersUpdate.push(user.userId);
    this.setState({
      connections: newConnections,
      invitedUsers: invitedUsersUpdate,
    });
  }

  getUser(user) {
    const userId = user.userId;
    const docDetails = query('userId')
      .is(userId)
      .on(this.props.users);
    const userInfo = docDetails[0];
    if (userInfo !== undefined) {
      return (
        <div
          onClick={() => {
            this.addUser(
              userInfo,
              this.state.connections,
              this.state.invitedUsers,
            );
          }}
        >
          {/* <a
            onClick={() => {
              this.setConnection(
                userInfo,
                this.props.user,
                false,
                this.props.alllocations,
              );
            }}
          > */}

          <div className="left">
            <UserAvatar
              size="50"
              name={userInfo.firstName + userInfo.lastName}
              src={userInfo.profilePhotoPath}
              // onClick={() => {
              //   this.setConnection(
              //     userInfo,
              //     this.props.user,
              //     false,
              //     this.props.alllocations,
              //   );
              // }}
            />
          </div>
          <div className="left" style={{ marginLeft: '10px' }}>
            {/* <span
                className="invite-label"
                onClick={() => {
                  this.setConnection(
                    userInfo,
                    this.props.user,
                    false,
                    this.props.alllocations,
                  );
                }}
              > */}
            <span className="invite-label">
              {userInfo.firstName} {userInfo.lastName}
            </span>
            <br />
            <span className="address">@{userInfo.userName}</span>
          </div>
          <div className="right">
            {/* <MaterialIcon
                icon="email"
                size={25}
                color="#f21d59"
                onClick={() => {
                  this.setConnection(userInfo, this.props.user, false);
                }}
              /> */}
            {/* <span
                onClick={() => {
                  this.setConnection(
                    userInfo,
                    this.props.user,
                    false,
                    this.props.alllocations
                  );
                }}
              >
                <img src={redLogo} className="bottomNavLogo" alt="logo" />
              </span> */}
          </div>
        </div>
      );
    }
  }

  getUserListing(user, index) {
    const userx = user;
    return (
      <div
        onClick={() => {
          this.sendInvite(userx, index);
        }}
      >
        <div className="left">
          <UserAvatar
            size="50"
            name={userx.firstName + userx.lastName}
            src={userx.profilePhotoPath}
          />
        </div>
        <div className="left" style={{ marginLeft: '10px' }}>
          {/* <span
        className="invite-label"
        onClick={() => {
          this.setConnection(
            userInfo,
            this.props.user,
            false,
            this.props.alllocations,
          );
        }}
      > */}
          <span className="invite-label">
            {userx.firstName} {userx.lastName}
          </span>
          <br />
          <span className="address">@{userx.userName}</span>
        </div>
        <div className="right">
          {/* <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
            <div className="right">
              <MaterialIcon
                icon="email"
                size={25}
                color="#f21d59"
                onClick={() => {
                  this.newConnection(user);
                }}
              />
            </div>
          </Col> */}
        </div>
      </div>
    );
  }

  // getUserListing(user, index) {
  //   const userx = user;
  //   return (
  //     <Row className="no-margin oddeven">
  //       <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
  //         <UserAvatar
  //           size="50"
  //           name={userx.firstName + userx.lastName}
  //           src={userx.profilePhotoPath}
  //         />
  //       </Col>
  //       <Col xs={8} md={8} sm={8} lg={8}>
  //         <span
  //           className="name"
  //           onClick={() => {
  //             this.sendInvite(userx, index);
  //           }}
  //         >
  //           {userx.firstName} {userx.lastName}
  //         </span>
  //         <br />
  //         <span className="userName">@{userx.userName} </span>
  //       </Col>
  //       <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
  //         <div className="right">
  //           <span
  //             onClick={() => {
  //               this.sendInvite(userx, index);
  //             }}
  //           >
  //             <MaterialIcon icon="email" size={25} color="#f21d59" />
  //           </span>
  //         </div>
  //       </Col>
  //     </Row>
  //   );
  // }

  getUserStatus(user) {
    let connections = this.state.connections;
    if (connections[user.userId] !== undefined) {
      return 'adminlistitem selectedLocationBack';
    } else {
      return 'adminlistitem';
    }
  }

  getConnections(connections) {
    let noneText = "You haven't connected with any one yet.";
    if (this.props.connections.length !== 0) {
      noneText = 'All of your contacts have been invited.';
    }
    if (connections.length > 0) {
      return (
        <ul className="no-padding">
          {connections.map((user, index) => (
            <li className={this.getUserStatus(user)}>
              {this.getUser(user)}
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <div style={{ paddingTop: '20px', textAlign: 'center' }}>
          <span className="tipText bold">{noneText}</span>
          <br></br>
          <span className="tipText">
            You can search for Trevel Users, or send an invite link to
            anyone you want!
          </span>
        </div>
      );
    }
  }

  getContent(mode, copied) {
    if (mode === 1) {
      let filteredUsers = [];
      if (this.state.searchTerm === '') {
        filteredUsers = [];
      } else {
        filteredUsers = this.props.users.filter(
          createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
        );
      }

      return (
        <div>
          <Row>
            <Col xs={12} md={12} sm={12} lg={12}>
              <SearchInput
                className="search-input centered-input"
                onChange={this.searchUpdated}
                placeholder="Search Trevel Users"
              />
            </Col>
          </Row>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              {filteredUsers.map((user, index) => (
                <div key={'key' + index}>
                  {this.getUserListing(user, index)}
                </div>
              ))}
            </Col>
          </Row>
          <Row className="no-padding">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div className="tipBox">
                {/* <span className="tipTitle">
                  <b>Quick Tip</b>
                </span> */}
                <span className="tipText">
                  <h2 className="guestListTitle">
                    can{"'"}t find your guest?
                  </h2>
                  <span>
                    You can invite anyone by sending an Invite Link!
                  </span>
                  <br />
                </span>

                <div>
                  <center>
                    <a
                      onClick={() => {
                        this.changeMode(2);
                      }}
                      className="redbutton fullwidth white display-block"
                    >
                      Get Invite Link
                    </a>
                  </center>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <Container>
          <Row>
            <Col xs={12} md={12} sm={12} lg={12}>
              <div style={{ maxWidth: '500px', margin: '0px auto' }}>
                <span>
                  You can send a Quick Invite Link to anyone, no
                  matter if they are signed up to Trevel or not.
                </span>
                <ul
                  style={{ marginTop: '20px', marginBottom: '40px' }}
                >
                  <li>
                    Fill in their name, any name will do, and click
                    "Next".
                  </li>
                  <li>Create and save your event.</li>
                  <li>
                    Copy your new Quick Invite Link and send it to
                    your guest however you want!
                  </li>
                </ul>
                <center>
                  <span
                    style={{
                      fontWeight: '500',
                      fontSize: '20px',
                      display: 'block',
                      clear: 'both',
                    }}
                  >
                    Who do you want to invite?
                  </span>
                </center>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} sm={12} lg={12}>
              <Formik
                initialValues={{ fullName: '' }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                  }, 500);
                }}
                // validationSchema={Yup.object().shape({
                //   email: Yup.string()
                //     .email()
                //     .required('Required'),
                // })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit} id="fullName">
                      {/* <label htmlFor="fullName" style={{ display: "block" }}>
                    Email
                  </label> */}
                      <div
                        style={{
                          maxWidth: '300px',
                          margin: '0px auto',
                        }}
                      >
                        <input
                          id="fullName"
                          placeholder="Any name will do."
                          type="text"
                          value={values.fullName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? 'text-input error'
                              : 'text-input'
                          }
                        />
                      </div>
                      {/* {errors.email && touched.email && (
              <div className="input-feedback">{errors.email}</div>
            )} */}
                      {/* 
                  <button
                    type="button"
                    className="outline"
                    onClick={handleReset}
                    disabled={!dirty || isSubmitting}
                  >
                    Reset
                  </button>
                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button> */}
                      {this.getNextButton(props)}
                    </form>
                  );
                }}
              </Formik>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} sm={12} lg={12} />
          </Row>
        </Container>
      );
    }
  }

  getScreen() {
    let filteredList = [];
    let allIds = [];
    let filteredConnections = [];

    let invites = [];
    if (this.props.event.invitations.constructor === Array) {
      invites = this.props.event.invitations;
    } else {
      Object.keys(this.props.event.invitations).forEach((key) => {
        invites.push(key);
      });
    }
    this.state.connectionsArray.forEach((user, index) => {
      let inviteStatus = invites.includes(user.userId);

      if (inviteStatus === false) {
        filteredConnections.push(user);
      }
    });
    this.props.users.forEach((user, index) => {
      let inviteStatus = invites.includes(user.userId);
      if (inviteStatus === false) {
        let status = allIds.includes(user.userId);
        if (status === false) {
          allIds.push(user.userId);
          filteredList.push(user);
        }
      }
    });
    let filteredUsers = [];
    if (this.state.searchTerm === '') {
      filteredUsers = filteredList;
    } else {
      filteredUsers = filteredList.filter(
        createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
      );
    }
    if (this.state.screen === 2) {
      return (
        <div className="twentyPush">
          {this.getContent(this.state.mode)}
        </div>
      );
    } else if (this.state.screen === 1) {
      return (
        <div style={{ marginTop: '10px' }}>
          {/* <div className="header-container">
            <div>
              <Container>
                <Row>
                  <Col xs={12} md={12} sm={12} lg={12}>
                    <a
                      onClick={() => {
                        this.changeScreen(2);
                      }}
                    >
                      <span className="header-button white right">
                        + Add New Contact
                      </span>
                    </a>
                  </Col>
                </Row>
              </Container>
            </div>
          </div> */}
          <Container>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12}>
                <SearchInput
                  className="search-input centered-input"
                  onChange={this.searchUpdated}
                  placeholder="Search For Additional Trevel Users"
                />
              </Col>
            </Row>
            {this.state.searchTerm === '' && (
              <Row
                className="no-margin"
                style={{ paddingTop: '20px' }}
              >
                <Col xs={12} md={12} sm={12} lg={12}>
                  <div style={{ padding: '0px 0px 0px 0px' }}>
                    {this.getConnections(filteredConnections)}
                  </div>
                </Col>
              </Row>
            )}
            {this.state.searchTerm !== '' && (
              <Row
                className="no-margin"
                style={{ paddingTop: '20px' }}
              >
                <Col xs={12} md={12} sm={12} lg={12}>
                  <ul className="no-padding">
                    {filteredUsers.map((user, index) => (
                      <li
                        className={this.getUserStatus(user)}
                        key={'key' + index}
                      >
                        {this.getUserListing(user, index)}
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      );
    } else if (this.state.screen === 3) {
      return (
        <div>
          <div className="section">{this.getModalContent()}</div>
        </div>
      );
    }
  }

  getNextButton(value) {
    if (value.values.fullName !== '') {
      return (
        <div>
          <center>
            <a
              onClick={() => {
                this.props.gotoMessage(value.values.fullName);
              }}
              className="redbutton white display-block"
            >
              <span>Next</span>
            </a>
          </center>
        </div>
      );
    }
  }

  // getCopyButton(value, copied) {
  //   console.log("value", value);
  //   if (value.values.fullName !== "") {
  //     if (copied === false) {
  //       return (
  //         <div>
  //           <Row className="no-margin">
  //             <Col xs={12} md={12} sm={12} lg={12}>
  //               <center>
  //                 <span className="copyLinkMessage">
  //                   You can send this Invite Link to anyone!
  //                 </span>
  //               </center>
  //             </Col>
  //           </Row>
  //           <center>
  //             <a
  //               onClick={() => {
  //                 this.copyLink(value.values.fullName);
  //               }}
  //               className="redbutton white display-block"
  //             >
  //               <span>Copy Invite Link</span>
  //             </a>
  //           </center>
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <center>
  //           <a
  //             onClick={() => {
  //               // this.copyLink();
  //             }}
  //             className="greybutton white display-block"
  //           >
  //             <span>Copy Invite Link</span>
  //           </a>
  //         </center>
  //       );
  //     }
  //   }
  // }

  // copyLink(fullName) {
  //   const fullNamex = fullName;
  //   const token = (Math.floor(Math.random() * 10000) + 10000)
  //     .toString()
  //     .substring(1);
  //   const user = {
  //     fullName: fullNamex,
  //     userId: token,
  //     type: 0
  //   };
  //   let textToCopy = "http://trevel.com/invite/" + token;
  //   // 1) Add the text to the DOM (usually achieved with a hidden input field)
  //   const input = document.createElement("input");
  //   document.body.appendChild(input);
  //   input.value = textToCopy;
  //   // 2) Select the text
  //   input.focus();
  //   input.select();
  //   // 3) Copy text to clipboard
  //   const isSuccessful = document.execCommand("copy");
  //   // 4) Catch errors
  //   if (!isSuccessful) {
  //     console.error("Failed to copy text.");
  //   }
  //   this.setState({
  //     copied: true
  //   });
  //   this.sendInvite(user);
  // }

  changeMode(mode) {
    this.setState({
      mode,
    });
  }

  getTab(tab, tabMode, mode) {
    if (tabMode !== mode) {
      return (
        <a
          onClick={() => {
            this.changeMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tab}
          </div>
        </a>
      );
    } else {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tab}
        </div>
      );
    }
  }

  changeScreen(screen) {
    this.setState({
      screen,
    });
  }

  getTitleBar() {
    return (
      <div id="headersTop" style={{ left: '0px' }}>
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="left">
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.props.changeScreen(1);
                  }}
                />
              </span>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Add Guests</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  getGoButton(connections) {
    let count = Object.keys(connections).length;
    if (count === 0) {
      return (
        <span className="center solidgreybutton right">
          Add Guests
        </span>
      );
    } else {
      return (
        <a
          onClick={() => {
            // this.setConnection(
            //   this.props.user,
            //   false,
            //   this.props.alllocations,
            // );
            this.props.prepareMultipleInvites(
              this.state.connections,
              this.state.invitedUsers,
            );
          }}
          className="right"
        >
          <span className="center solidredbutton">Add Guests</span>
        </a>
      );
    }
  }

  bothGo() {
    this.changeScreen(2);
    this.changeMode(2);
  }

  getFooter(mode) {
    return (
      <div className="guestListNav">
        <Container>
          <Row className="no-padding">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div style={{ paddingBottom: '10px' }}>
                {/* <span className="tipText">
                  <h2 className="guestListTitle">
                    can{"'"}t find your guest?
                  </h2>
                  <span>
                    You can invite anyone by sending an Invite Link!
                  </span>
                  <br />
                </span> */}
                {this.state.mode !== 2 && (
                  <div style={{ paddingBottom: '10px' }}>
                    <center>
                      <a
                        onClick={() => {
                          this.bothGo();
                        }}
                        className="redbuttonStrong fullwidth white display-block"
                        style={{ top: '0px' }}
                      >
                        Invite a New User
                      </a>
                    </center>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              {this.getGoButton(this.state.connections)}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.getTitleBar()}
        {/* <div className="modalActionBar">
          <Row>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab('Trevel Users', 1, this.state.mode)}
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab('Invite Link', 2, this.state.mode)}
            </Col>
          </Row>
        </div> */}
        <div>
          {/* {this.getContent(this.state.mode, this.state.copied)} */}
          <div>{this.getScreen(this.state.screen)}</div>
        </div>
        {this.state.mode !== 2 && this.getFooter()}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#headersTopSecondary{ display:none;} .maincontent{top: 45px !important} #buttonbar{display:none}#events{position: relative; top: 0px !important}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    connections: state.connections,
  };
}

export default connect(mapStateToProps)(InviteUser);
