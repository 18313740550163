import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../../config/firebase';

export class EmailVerificationForm extends React.Component {
  constructor(props) {
    super(props);
  }

  sendVerificationEmai(){
    var user = firebase.auth().currentUser;

    user.sendEmailVerification().then(function() {
        console.log("Verification link sent to your email. Kinldy check to verify your account")
    }).catch(function(error) {
        // An error happened.
    });
  }

  render() {
    return (
      <div id="signup">
        <div>
          <div className="title-area">
            <p className="signup-box-info"></p>
          </div>
          <Formik
            onSubmit={(values) => {
              this.sendVerificationEmai();
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting
              } = props;
              return (
                <Form>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="left"
                      >
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} lg={12}>
                        <div className="submitarea">
                          <center>
                            <p>Send Verification Email Link to { this.props.user.email }</p>
                            <button
                              type="submit"
                              className="center"
                            >
                              Send
                            </button>
                          </center>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              );
            }}
          </Formik>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'header { display: none } .maincontent { top: 0px; margin-top: 0px;} .footer-container{display:none} .full-height{height:100%}',
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(EmailVerificationForm);
