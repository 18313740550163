export const RESET_NEWEVENT = 'RESET_NEWEVENT';
export const GET_NEWEVENT = 'GET_NEWEVENT';
export const SET_NEWEVENT = 'SET_NEWEVENT';
export const UPDATE_NEWEVENT = 'UPDATE_NEWEVENT';

// export const SAVE_CE = "SAVE_CE";
// export const SAVE_CEP = "SAVE_CEP";
// export const EDIT_CE = "EDIT_CE";
// export const EDIT_CEP = "EDIT_CEP";
// export const UPDATE_CE = "UPDATE_CE";
