import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import { Col, Row } from 'reactstrap';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';

const query = require('array-query');

class VendorUserListItem extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  confirmDelete(id) {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            this.props.deleteDocument('users', id, '/admin/users'),
        },
        {
          label: 'No',
          onClick: () => console.log('closed'),
        },
      ],
    });
  }

  getPostDate(timestamp) {
    // const dayText = moment.utc(timestamp).format("dddd");
    const dateText = moment.utc(timestamp).format('D');
    const monthText = moment.utc(timestamp).format('MMM');
    const yearText = moment.utc(timestamp).format('YYYY');
    const date = monthText + ' ' + dateText + ', ' + yearText;
    return date;
  }

  getAdminStatus(user) {
    if (user.role === 0) {
      return <span>Admin</span>;
    } else {
      return <span>User</span>;
    }
  }

  // onClick={() => {
  //   this.props.deleteDocument("users", Id);
  // }}

  getEdit(id) {
    if (this.props.edit === true) {
      const url = '/vendor/user/edit/' + id;
      return (
        <div>
          <a href={url}>
            <MaterialIcon icon="edit" size={25} color="#f21d59" />
          </a>
          {/* <MaterialIcon
            icon="delete"
            size={25}
            onClick={() => {
              this.confirmDelete(id);
            }}
          /> */}
        </div>
      );
    }
  }

  getLocation(id) {
    let locationTitle = 'No Location Assigned';
    Object.keys(this.props.vendor.users).map((key, index) => {
      if (this.props.vendor.users[key].locations) {
        if (key === id) {
          this.props.vendor.users[key].locations.forEach(
            (location) => {
              const docDetails = query('locationId')
                .is(location.id)
                .on(this.props.alllocations);
              locationTitle = docDetails[0].locationTitle;
            },
          );
        }
      }
    });
    return locationTitle;
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={11} md={11} sm={11} lg={11} className="left">
                  <span className="label">User</span>
                </Col>
                {/* <Col xs={1} md={1} sm={1} lg={1} className="left">
                  <span className="label">Type</span>
                </Col>
                <Col xs={5} md={5} sm={5} lg={5} className="left">
                  <span className="label">Location</span>
                </Col> */}

                <Col xs={1} md={1} sm={1} lg={1} className="left">
                  {/* <span className="label">Actions</span> */}
                </Col>
              </div>
            </li>
            {this.props.users.map((user, index) => (
              <li className="adminlistitem">
                <div>
                  <Col
                    xs={11}
                    md={11}
                    sm={11}
                    lg={11}
                    className="left"
                  >
                    <Link
                      to={{
                        pathname: '/vendor/user/edit/' + user.userId,
                      }}
                    >
                      <div className="left">
                        <UserAvatar
                          size="50"
                          name={user.firstName + user.lastName}
                          src={user.profilePhotoPath}
                        />
                      </div>
                      <div className="left">
                        <span className="invite-label">
                          {user.firstName} {user.lastName}
                        </span>
                        <br />
                        <span className="address">
                          @{user.userName}
                        </span>
                      </div>
                    </Link>
                  </Col>
                  {/* <Col xs={1} md={1} sm={1} lg={1} className="left">
                    {this.getAdminStatus(user)}
                  </Col>
                  <Col xs={5} md={5} sm={5} lg={5} className="left">
                    {this.getLocation(user.userId)}
                  </Col> */}

                  <Col xs={1} md={1} sm={1} lg={1} className="left">
                    {/* {this.getEdit(user.userId)} */}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    vendor: state.vendor.vendor,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorUserListItem);
