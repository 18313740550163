import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
// Reducers
// import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';
// Services
import { deleteDocument } from '../../services/AdminServices';
// Styles
import './LocationListItem.css';

const query = require('array-query');

class LocationListItemEdit extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  getEdit(location) {
    const copyLink =
      'https://trevel.com/vendor/claim/' + location.locationId;
    if (this.props.edit === true) {
      const url = this.props.editPath + location.locationId;
      const quickSearchUrl =
        'https://www.google.com/search?as_q=' +
        location.locationTitle +
        ' ' +
        location.address.street;
      return (
        <div className="right">
          {/* <span className="right">
            <MaterialIcon
              icon="delete"
              size={25}
              onClick={() => {
                this.confirmDelete(location.locationId);
              }}
            />
          </span> */}
          <a
            href={quickSearchUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="right"
            style={{ clear: 'both', display: 'block' }}
          >
            <MaterialIcon icon="search" size={25} color="#f21d59" />
          </a>{' '}
          <Link
            to={{
              pathname: url,
            }}
            className="right"
            style={{ clear: 'both', display: 'block' }}
          >
            <MaterialIcon icon="edit" size={25} color="#f21d59" />
          </Link>
          <CopyToClipboard
            text={copyLink}
            onCopy={() => this.setState({ copied: true })}
            className="right"
            style={{ clear: 'both', display: 'block' }}
          >
            <button>VLink</button>
          </CopyToClipboard>
        </div>
      );
    }
  }

  getTaggedStatus(location) {
    let tagsSetting = 0;
    let tagsBeverages = 0;
    let tagsCuisine = 0;
    let tagsHours = 0;
    let tagsDateType = 0;

    if (location.tagsSetting) {
      if (location.tagsSetting.length > 0) {
        tagsSetting = 20;
      }
    }
    if (location.tagsBeverages) {
      if (location.tagsBeverages.length > 0) {
        tagsBeverages = 20;
      }
    }
    if (location.tagsCuisine !== undefined && location.tagsCuisine) {
      if (location.tagsCuisine.length > 0) {
        tagsCuisine = 20;
      }
    }
    if (location.tagsHours) {
      if (location.tagsHours.length > 0) {
        tagsHours = 20;
      }
    }
    if (location.tagsDateType) {
      if (location.tagsDateType.length > 0) {
        tagsDateType = 20;
      }
    }

    const count =
      tagsSetting +
      tagsBeverages +
      tagsCuisine +
      tagsHours +
      tagsDateType;

    if (count === 100) {
      return <span className="table-bold green">{count}%</span>;
    } else {
      return (
        <span className="table-bold error-message">{count}%</span>
      );
    }
  }

  getDescriptionStatus(location) {
    if (location.locationDescription !== undefined) {
      if (location.locationDescription !== '<p></p>') {
        return <span className="table-bold green">Yes</span>;
      }
    } else {
      return <span className="table-bold error-message">No</span>;
    }
  }

  getPhoneStatus(location) {
    let phone = 'tel:' + location.phone;
    if (location.phone !== undefined) {
      if (
        location.phone === '(555) 555-5555' ||
        location.phone === '(555)555-555' ||
        location.phone === '555-555-5555' ||
        location.phone === '555-555-5602' ||
        location.phone === '' ||
        location.phone === null
      ) {
        return (
          <span className="table-bold error-message">Needed</span>
        );
      } else {
        return (
          <span
            className=""
            style={{
              margin: '5px 0px 5px 0px',
              display: 'block',
              position: 'relative',
            }}
          >
            <a href={phone}>{location.phone}</a>
          </span>
        );
      }
    } else {
      return <span className="table-bold error-message">Needed</span>;
    }
  }

  confirmDelete(id) {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            deleteDocument('location', id, '/admin/locations'),
        },
        {
          label: 'No',
          onClick: () => console.log('closed'),
        },
      ],
    });
  }

  getLocationType(location) {
    if (
      location.tagsLocationType !== undefined &&
      location.tagsLocationType.length !== 0
    ) {
      return (
        <div>
          {location.tagsLocationType.map((location, index) => (
            <span
              style={{
                clear: 'both',
                display: 'block',
                position: 'relative',
              }}
            >
              {location}
            </span>
          ))}
        </div>
      );
    } else {
      return <span style={{ color: '#afafaf' }}>none</span>;
    }
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span style={{ fontWeight: '500' }}>{location.tagsMain}</span>
      );
    } else {
      return <span />;
    }
  }

  getClaimStatus(location) {
    if (location.tagsMain !== undefined) {
      return <span>{location.tagsMain}</span>;
    } else {
      return <span />;
    }
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    const eventId = this.props.eventId;
    const token = this.props.token;
    const type = this.props.type;
    // let subset = this.props.packages.slice(0, 10);
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={4} md={4} sm={4} lg={4} className="left">
                  <span className="label">Location</span>
                </Col>
                <Col xs={1} md={1} sm={1} lg={1} className="left">
                  <span className="label">ZoneId</span>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Type</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Tags</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Claim Status</span>
                </Col>
                <Col xs={1} md={1} sm={1} lg={1} className="left">
                  {/* <span className="label">Actions</span> */}
                </Col>
              </div>
            </li>
            {this.props.locations.map((location, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    key={'key' + index}
                  >
                    <Link
                      to={{
                        pathname: '/location',
                        state: {
                          location: { location },
                          eventId: { eventId },
                          token: { token },
                          type: 13,
                        },
                      }}
                    >
                      <Row>
                        <Col
                          xs={6}
                          md={6}
                          sm={6}
                          lg={6}
                          className="thumbnailCol left no-padding"
                        >
                          {this.getImage('thumb', location)}
                        </Col>
                        <Col
                          xs={6}
                          md={6}
                          sm={6}
                          lg={6}
                          className="left"
                        >
                          <span
                            className="location-title  red"
                            style={{
                              fontWeight: '500',
                              fontSize: '15px',
                            }}
                          >
                            {location.locationTitle}
                          </span>
                          <span className="address">
                            {location.address.street}
                          </span>
                          <span className="address">
                            {location.address.city}{' '}
                            {location.address.state},{' '}
                            {location.address.zip}
                          </span>
                          {this.getPhoneStatus(location)}
                        </Col>
                      </Row>
                    </Link>
                  </Col>
                  <Col xs={1} md={1} sm={1} lg={1}>
                    {/* {this.getTaggedStatus(location)} */}
                    <span className="address">{location.zoneId}</span>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {/* {this.getDescriptionStatus(location)} */}
                    <div>{this.getLocationType(location)}</div>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {this.getMainTag(location)}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    <span className="label">Claim Status</span>
                  </Col>
                  <Col xs={1} md={1} sm={1} lg={1}>
                    {this.getEdit(location)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    vendors: state.vendors.vendors,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getAllLocations: (zone) => {
    //   dispatch(getAllLocations(zone));
    // },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationListItemEdit);

// Location Details Available
// **************************
// location.locationTitle
// location.categories
// location.locationDescription
// location.locationHero
// location.ocationThumb
// location.locationId
// location.locationType
// ** Vouchers and Packages as well
