import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

let defaultState = null;

function* setModeAction(action) {
  const destination = action.payload;
  yield put({
    type: actions.SET_DESTINATION,
    payload: {
      ...defaultState,
      destination,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_DESTINATION, setModeAction);
}
