import * as action from './actions';

export const getRegions = (id) => {
  return {
    type: action.GET_REGIONS,
    payload: id,
  };
};

export const getAllRegions = (id) => {
  return {
    type: action.GET_ALLREGIONS,
    payload: id,
  };
};

export const setRegions = (regions) => {
  return {
    type: action.SET_REGIONS,
    payload: regions,
  };
};

const ACTION_HANDLERS = {
  [action.GET_REGIONS]: (state, action) => {
    return { ...state };
  },
  [action.GET_ALLREGIONS]: (state, action) => {
    return { ...state };
  },
  [action.SET_REGIONS]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  regions: [],
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
