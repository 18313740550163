import React from 'react';
import { connect } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
// Components
import { Container, Row, Col } from 'reactstrap';
import UserListItem from '../../components/UserListItem';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
// Reducers
import { getUsers } from '../../sagas/usersSaga/reducer';
import { getAdminUsers } from '../../sagas/adminUsersSaga/reducer';
// Services
import { deleteDocument } from '../../services/AdminServices';

const KEYS_TO_FILTERS = ['firstName', 'lastName', 'userName'];

export class AdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      unverifiedOnly: false,
      verifiedOnly: false,
      loading: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (
      this.props.users !== undefined ||
      this.props.users.length !== 0
    ) {
      this.setState({
        loading: false,
      });
    }
  }

  componentDidMount() {
    this.props.getAdminUsers();
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/home',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Manage Users</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}></Col>
          </Row>
        </div>
      </div>
    );
  }

  setVerifiedOnly = (value) => {
    this.setState({
      verifiedOnly: value.target.checked,
    });
    if (value.target.checked === true) {
      this.setState({
        unverifiedOnly: false,
      });
    }
  };

  setUnverifiedOnly = (value) => {
    this.setState({
      unverifiedOnly: value.target.checked,
    });
    if (value.target.checked === true) {
      this.setState({
        verifiedOnly: false,
      });
    }
  };

  render() {
    if (this.state.loading) {
    }
    const filteredUsers = this.props.users.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );

    let userArray = [];
    filteredUsers.forEach((user, i) => {
      if (this.state.unverifiedOnly === true) {
        if (user.verified !== true) {
          userArray.push(user);
        }
      } else if (this.state.verifiedOnly === true) {
        if (user.verified === true) {
          userArray.push(user);
        }
      } else {
        userArray.push(user);
      }
    });

    return (
      <div id="admin">
        {this.getTitleBar()}
        <div className="section">
          <div className="header-container">
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>

            <div style={{ display: 'block', clear: 'both' }}>
              <h4 className="hfour left">Verified Only</h4>
              <div style={{ width: '20px', float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state.verifiedOnly}
                  onChange={this.setVerifiedOnly}
                  className="left"
                />
              </div>
            </div>
            <div
              style={{
                display: 'block',
                clear: 'both',
                float: 'left',
              }}
            >
              <h4 className="hfour left">Not Verified Only</h4>
              <div style={{ width: '20px', float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state.unverifiedOnly}
                  onChange={this.setUnverifiedOnly}
                  className="left"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'block', clear: 'both' }}>
          <UserListItem
            users={userArray}
            edit={true}
            deleteDocument={deleteDocument}
          />
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.adminUsers.users,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => {
      dispatch(getUsers());
    },
    getAdminUsers: () => {
      dispatch(getAdminUsers());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUsers);
