import firebase from '../config/firebase';

export function getVendorEntertainment(vendorId) {
  const eventArray = [];
  const db = firebase.firestore();
  const events = db.collection('entertainment');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = events
        .where('account.vendorId', '==', vendorId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                active,
                entertainmentDescription,
                lastUpdated,
                entertainmentTitle,
                entertainmentId,
                entertainmentThumb,
                entertainmentHero,
                website,
                social,
                // twitter,
                // twitch,
                // tiktok,
                // facebook,
                // patreon,
                // bandcamp,
                // instagram,
                // youtube,
                settings,
                creationDetails,
                zoneId,
                account,
                tagsEntertainmentType,
                tagsGenre,
                slug,
              } = doc.data();
              eventArray.push({
                id: doc.id,
                active,
                entertainmentDescription,
                lastUpdated,
                entertainmentTitle,
                entertainmentId,
                entertainmentThumb,
                entertainmentHero,
                website,
                social,
                // twitter,
                // twitch,
                // tiktok,
                // facebook,
                // patreon,
                // bandcamp,
                // instagram,
                // youtube,
                settings,
                creationDetails,
                zoneId,
                account,
                tagsEntertainmentType,
                tagsGenre,
                slug,
              });
            }
          });
          return eventArray;
        })
        .then((result) => {
          resolve(eventArray);
        });
    });
  };
  return getP();
}
