import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
// Components

import ContactUsForm from '../components/ContactUsForm';
import CompanyInfo from '../components/CompanyInfo';
import RedCallToAction from '../components/RedCallToAction';

export default class ContacttUs extends React.Component {
  render() {
    return (
      <div>
        <div id="contactForm">
          <div>
            <Container>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="no-padding"
                >
                  <h1 style={{ fontSize: '30px', marginTop: '10px' }}>
                    Contact Us
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="no-padding"
                >
                  <span>
                    Is one of your favorite places missing?
                    <span
                      style={{
                        fontWeight: '600',
                        display: 'block',
                        clear: 'both',
                      }}
                    >
                      Send it our way.
                    </span>
                  </span>
                  <span>
                    Have a comment about how we are doing?{' '}
                    <span
                      style={{
                        fontWeight: '600',
                        display: 'block',
                        clear: 'both',
                      }}
                    >
                      Let us know.
                    </span>
                  </span>
                  <span>
                    Have an idea on how we can improve?{' '}
                    <span
                      style={{
                        fontWeight: '600',
                        display: 'block',
                        clear: 'both',
                      }}
                    >
                      Please reach out.
                    </span>
                  </span>
                  <br />
                  <CompanyInfo />
                </Col>
                {/* <Col lg={7} md={7} sm={12} xs={12}>
                  <center>
                    <ContactUsForm />
                  </center>
                </Col> */}
              </Row>
            </Container>
          </div>

          {/* <Link to={`/packages`}>Packages</Link> */}
        </div>
        <div className="padding-top">
          <RedCallToAction />
        </div>
      </div>
    );
  }
}
