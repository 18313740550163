import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import ReactCrop from 'react-image-crop';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../../config/firebase';
// Reducers
import {
  addPackage,
  resetPackage,
  savePackage,
  updatePackage,
} from '../../sagas/createPackageSaga/reducer';
import { saveEvent } from '../../sagas/createVendorEventSaga/reducer';
import { getCe, resetCe } from '../../sagas/customEventSaga/reducer';
import { getMode } from '../../sagas/setModeSaga/reducer';
// Reducers
import { getVendorEvents } from '../../sagas/vendorEventsSaga/reducer';

const query = require('array-query');
const _ = require('lodash');

const format = 'h:mm a';
const now = moment().hour(0).minute(0);

export class VendorEditEvent extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      title: '',
      description: '',
      selectedTime: '',
      mode: 1,
      crop: {
        unit: '%',
        width: 30,
        aspect: 800 / 259,
      },
      cropThumb: {
        unit: '%',
        width: 30,
        aspect: 146 / 122,
      },
      cropping: false,
      croppingThumb: false,
      differentEnd: 'no',
      differentTime: 'no',
      from: undefined,
      to: undefined,
      customHero: true,
      customThumb: true,
    };
    this.handleStartDayClick = this.handleStartDayClick.bind(this);
    this.handleEndDayClick = this.handleEndDayClick.bind(this);
  }

  componentDidMount() {
    // const modeInt = parseInt(this.props.match.params.mode, 10);
    if (
      this.props.customEvent.event.eventId ===
      this.props.match.params.id
    ) {
      if (this.props.customEvent.hydrated === true) {
        // Set Hero
        let heroURL =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          this.props.customEvent.event.heroImage +
          '?alt=media';

        // Set Thumbnail
        let thumbURL =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          this.props.customEvent.event.thumbnail +
          '?alt=media';

        if (
          this.props.customEvent.event.scheduledTime.dates
            .startTimestamp !== ''
        ) {
          let selectedDay = moment(
            this.props.customEvent.event.scheduledTime.dates
              .startTimestamp,
          );
          this.setState({
            from: selectedDay._d,
          });
        }

        if (
          this.props.customEvent.event.scheduledTime.dates
            .endTimestamp !== ''
        ) {
          let selectedDay = moment(
            this.props.customEvent.event.scheduledTime.dates
              .endTimestamp,
          );
          this.setState({
            to: selectedDay._d,
          });
        }

        if (
          this.props.customEvent.event.scheduledTime.times
            .regHours === false
        ) {
          let startTime =
            this.props.customEvent.event.scheduledTime.times
              .startTimeString.seconds * 1000; // Unix ms timestamp

          let endTime =
            this.props.customEvent.event.scheduledTime.times
              .endTimeString.seconds * 1000; // Unix ms timestamp

          let selectedStartTime = moment(startTime);
          let selectedEndTime = moment(endTime);
          this.setState({
            selectedStartTime: selectedStartTime,
            selectedEndTime: selectedEndTime,
            differentTime: 'yes',
          });
        }

        this.setState({
          loading: false,
          customEvent: this.props.customEvent,
          title: this.props.customEvent.event.title,
          description: this.props.customEvent.event.description,
          teaser: this.props.customEvent.event.teaser,
          heroURL,
          thumbURL,
          heroPath: heroURL,
          thumbPath: thumbURL,
          locations: this.props.customEvent.event.locations,
          checked: this.props.customEvent.event.active,

          // mode: modeInt
        });
      } else {
        let eventReady = this.props.customEvent;
        eventReady.hydrated = true;
        this.props.getCe(eventReady);
      }
    } else {
      const eventDetails = query('id')
        .is(this.props.match.params.id)
        .on(this.props.vendorEvents);
      const activeEvent = eventDetails[0];
      const customEvent = {
        // urlMode: this.props.match.params.mode,
        urlMode: 1,
        clear: false,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: true,
        hydrated: true,
        vendorMode: true,
        event: {
          eventId: activeEvent.id,
          description: activeEvent.description,
          teaser: activeEvent.teaser,
          title: activeEvent.title,
          status: activeEvent.status,
          locations: activeEvent.locations,
          creatorInfo: activeEvent.creatorInfo,
          scheduledTime: activeEvent.scheduledTime,
          heroImage: activeEvent.heroImage,
          thumbnail: activeEvent.thumbnail,
          created: activeEvent.created,
          zoneId: activeEvent.zoneId,
          active: activeEvent.active,
          // editHistory: eventDetails[0].editHistory
        },
      };
      this.props.getCe(customEvent);

      // Set Hero
      let heroURL =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
        activeEvent.heroImage +
        '?alt=media';

      // Set Thumbnail
      let thumbURL =
        'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
        activeEvent.thumbnail +
        '?alt=media';

      if (activeEvent.scheduledTime.dates.startTimestamp !== '') {
        let selectedDay = moment(
          activeEvent.scheduledTime.dates.startTimestamp,
        );
        this.setState({
          from: selectedDay._d,
        });
      }

      if (activeEvent.scheduledTime.dates.endTimestamp !== '') {
        let selectedDay = moment(
          activeEvent.scheduledTime.dates.endTimestamp,
        );
        this.setState({
          to: selectedDay._d,
        });
      }

      if (activeEvent.scheduledTime.times.regHours === false) {
        let startTime =
          activeEvent.scheduledTime.times.startTimeString.seconds *
          1000; // Unix ms timestamp

        let endTime =
          activeEvent.scheduledTime.times.endTimeString.seconds *
          1000; // Unix ms timestamp

        let selectedStartTime = moment(startTime);
        let selectedEndTime = moment(endTime);
        this.setState({
          selectedStartTime: selectedStartTime,
          selectedEndTime: selectedEndTime,
          differentTime: 'yes',
        });
      }

      this.setState({
        loading: false,
        customEvent: this.props.customEvent,
        title: activeEvent.title,
        description: activeEvent.description,
        teaser: activeEvent.teaser,
        heroURL,
        thumbURL,
        heroPath: heroURL,
        thumbPath: thumbURL,
        locations: activeEvent.locations,
        checked: activeEvent.active,
      });
    }
  }

  updateEvent() {
    const db = firebase.firestore();
    const timestamp = new Date();
    let activeStatus = this.state.checked;
    const thisEvent = this.props.customEvent.event;
    let fullName =
      this.props.profile.firstName +
      ' ' +
      this.props.profile.lastName;
    const eventDetails = query('id')
      .is(this.props.match.params.id)
      .on(this.props.vendorEvents);
    const activeEvent = eventDetails[0];
    let eventUpdate = {
      eventId: activeEvent.id,
      description: this.state.description,
      teaser: this.state.teaser,
      title: this.state.title,
      status: activeEvent.status,
      locations: this.state.locations,
      creatorInfo: activeEvent.creatorInfo,
      created: activeEvent.created,
      zoneId: activeEvent.zoneId,
      active: this.state.checked,
      id: activeEvent.id,
    };
    // Set Schedule
    eventUpdate.scheduledTime = {};
    eventUpdate.scheduledTime.times = this.setScheduleTimes(
      this.state.selectedStartTime,
      this.state.selectedEndTime,
      this.state.differentTime,
    );
    let to = null;
    if (this.state.to !== undefined) {
      to = this.state.to;
    } else {
      to = this.state.from;
    }
    eventUpdate.scheduledTime.dates = this.setSchedule(
      this.state.from,
      to,
    );
    // Set Images
    // Update Hero and Thumbnail
    let heroPath = '';
    let thumbnailPath = '';
    if (this.state.customHero === true) {
      heroPath =
        'vendor_images%2F' +
        this.props.vendor.vendorId +
        '%2F' +
        this.state.dbref +
        '%2FheroImage.jpeg';
    } else {
      heroPath = thisEvent.heroImage;
    }
    if (this.state.customThumb === true) {
      thumbnailPath =
        'vendor_images%2F' +
        this.props.vendor.vendorId +
        '%2F' +
        this.state.dbref +
        '%2Fthumbnail.jpeg';
    } else {
      thumbnailPath = thisEvent.thumbnail;
    }
    if (heroPath !== undefined) {
      eventUpdate.heroImage = heroPath;
    } else {
      eventUpdate.heroImage = null;
    }
    if (thumbnailPath !== undefined) {
      eventUpdate.thumbnail = thumbnailPath;
    } else {
      eventUpdate.thumbnail = null;
    }
    let updatedHistory = [];
    // Update History
    if (activeEvent.editHistory !== undefined) {
      updatedHistory = activeEvent.editHistory;
      const localDate = new Date();
      const newHistory = {
        editDate: localDate,
        eventType: 1,
        fullName: fullName,
        userId: this.profile.userId,
      };
      updatedHistory.push(newHistory);
    } else {
      const localDate = new Date();
      const newHistory = {
        editDate: localDate,
        eventType: 1,
        fullName: fullName,
        userId: this.props.profile.userId,
      };
      updatedHistory.push(newHistory);
    }

    eventUpdate.editHistory = updatedHistory;

    const update = db.collection('events').doc(activeEvent.id);
    const setDoc = update.update(eventUpdate).then((ref) => {
      this.props.getVendorEvents(this.props.vendor.vendorId);
      this.props.history.push('/vendor/events');
    });
  }

  setSchedule(selectedStartDay, selectedEndDay) {
    let bundle = {
      startDate: null,
      startTimestamp: null,
      endDate: null,
      endTimestamp: null,
      oneDayEvent: null,
    };

    if (selectedStartDay === selectedEndDay) {
      bundle.oneDayEvent = true;
    } else {
      bundle.oneDayEvent = false;
    }

    // Check for Date
    if (
      selectedStartDay !== '' &&
      selectedStartDay !== undefined &&
      selectedStartDay !== null
    ) {
      const timestamp = moment(selectedStartDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.startDate = dateString;
      bundle.startTimestamp = timestampReady;
    }

    if (
      selectedEndDay !== '' &&
      selectedEndDay !== undefined &&
      selectedEndDay !== null
    ) {
      const timestamp = moment(selectedEndDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.endDate = dateString;
      bundle.endTimestamp = timestampReady;
    }
    return bundle;
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleStartDayClick(day, { selected }) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);

    // this.setState({
    //   selectedStartDay: selected ? undefined : day
    // });
  }

  handleEndDayClick(day, { selected }) {
    this.setState({
      selectedEndDay: selected ? undefined : day,
    });
  }

  onStartTimeChange = (time) => {
    console.log('Start TIME!', time);
    this.setState({ selectedStartTime: time });
  };

  onEndTimeChange = (time) => {
    console.log('End TIME!', time);
    this.setState({ selectedEndTime: time });
  };

  activeOnChange() {
    if (this.state.checked === true) {
      this.setState({ checked: false });
    } else {
      this.setState({ checked: true });
    }
  }

  displayForm() {
    return (
      // <div id="eventDetailsForm">
      <div>
        <div>
          <div>
            <Container>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12} className="left">
                  <span className="inputLabel">Title</span>
                  <input
                    type="text"
                    onChange={this.titleOnChange.bind(this)}
                    value={this.state.title}
                    className="full-width"
                  />
                  <span className="inputLabel">Description</span>
                  <textarea
                    type="text"
                    onChange={this.descriptionOnChange.bind(this)}
                    value={this.state.description}
                    className="full-width"
                  />
                  <span className="inputLabel">Teaser</span>
                  <textarea
                    type="text"
                    onChange={this.teaserOnChange.bind(this)}
                    value={this.state.teaser}
                    className="full-width"
                  />
                  <input
                    type="checkbox"
                    name="active"
                    checked={this.state.checked}
                    onChange={this.activeOnChange.bind(this)}
                  />
                  <label className="activeBtn">Active</label>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  getLocationDetails(location, locations, index, editMode) {
    const currentIndex = index;

    let locationReady = '';
    if (location.type === 1) {
      locationReady = location.location;
    } else {
      const locationDetails = query('locationId')
        .is(location.locationId)
        .on(locations);
      locationReady = locationDetails[0];
    }

    return (
      <div>
        <Row className="oddeven">
          <Col
            xs={10}
            md={10}
            sm={10}
            lg={10}
            key={'key' + index}
            className="no-padding"
          >
            <Link
              to={{
                pathname: '/location',
                state: {
                  location: { locationReady },
                  // eventId: { eventId },
                  // token: { token },
                  // type: { type }
                },
              }}
            >
              <div className="full-height left">
                <img
                  src={
                    'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
                    locationReady.locationThumb +
                    '?alt=media'
                  }
                  alt={locationReady.locationTitle}
                  className="thumbnail"
                />
              </div>
              <span className="invite-label">
                {locationReady.locationTitle}
              </span>
              <br />
              <span className="address">
                {locationReady.address.street}
              </span>
              <br />
              <span className="address">
                {locationReady.address.city}{' '}
                {locationReady.address.state},{' '}
                {locationReady.address.zip}
              </span>
            </Link>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2} key={'key' + index}>
            <div className="right text-right">
              {this.getRemoveButton(index, locationReady.locationId)}
            </div>
            {/* {this.getEditButton(editMode, currentIndex)} */}
          </Col>
        </Row>
      </div>
    );
  }

  getDeleteButton(index, customEvent) {
    console.log('deleteCount', customEvent);
    const locations = customEvent.event.locations;
    if (locations.length === 1) {
      console.log('deleteCount1', locations.length);
      return (
        <div>
          <MaterialIcon icon="delete" size={20} color="#d0d0d0" />
        </div>
      );
    } else {
      console.log('deleteCount2', locations.length);
      return (
        <MaterialIcon
          icon="delete"
          size={20}
          color="#f21d59"
          onClick={() => {
            this.removeLocation(index, customEvent);
          }}
        />
      );
    }
  }

  getEditButton(editMode, index) {
    const currentIndex = index;
    console.log('customidex1', index);
    if (editMode === true) {
      return (
        <div>
          <Link
            to={{
              pathname: '/event/create/edit/',
              state: {
                index: currentIndex,
              },
            }}
            className="right"
          >
            <MaterialIcon icon="edit" size={20} color="#f21d59" />
          </Link>
        </div>
      );
    }
  }

  getRemoveButton(index, locationId) {
    if (locationId === this.props.vendor.locations[0].locationId) {
      return;
    } else {
      return (
        <MaterialIcon
          icon="delete"
          size={20}
          color="#f21d59"
          onClick={() => {
            this.removeLocation(index, this.props.packageBundle);
          }}
        />
      );
    }
  }

  removeLocation(i, packageBundle) {
    const locations = packageBundle.package.locations;
    if (locations.length === 1) {
      // this.scaleAnimationDialog.show();
      // this.setState({
      //   loading: false
      // });
      console.log('last');
    } else {
      locations.splice(i, 1);
      packageBundle.package.locations = locations;
      this.props.updatePackage(packageBundle);
    }
  }

  // getFooter(mode) {
  // <MaterialIcon icon="check" size={25} color="#b90b3a" />
  //   return (
  //     <Container>
  //       <Row className="no-margin">
  //         <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
  //           <div>
  //             <span
  //               className="footer-link left"
  //               onClick={() => {
  //                 this.resetPackage();
  //               }}
  //             >
  //               Cancel
  //             </span>
  //           </div>
  //         </Col>
  //         <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
  //           <span
  //             className="right footer-link"
  //             onClick={() => {
  //               this.createPackage(
  //                 this.props.profile,
  //                 this.props.packageBundle,
  //                 this.props.locations,
  //                 this.props.vendor
  //               );
  //             }}
  //           >
  //             Save
  //           </span>
  //         </Col>
  //       </Row>
  //     </Container>
  //   );
  // }

  updateAndAdd(packageBundle, placement) {
    let updatedPackageBundle = packageBundle;
    if (updatedPackageBundle.started === 0) {
      updatedPackageBundle.package.locations = this.state.locations;
    }
    updatedPackageBundle.package.title = this.state.title;
    updatedPackageBundle.package.description = this.state.description;
    updatedPackageBundle.started = 1;
    updatedPackageBundle.placement = placement;
    this.props.addPackage(updatedPackageBundle);
  }

  titleOnChange(e) {
    console.log('e.target.value', e.target.value);
    this.setState({ title: e.target.value });
  }

  teaserOnChange(e) {
    this.setState({ teaser: e.target.value });
  }

  descriptionOnChange(e) {
    console.log('e.target.value', e.target.value);
    this.setState({ description: e.target.value });
  }

  differentEndChange(event) {
    console.log('event.target.value', event.target.value);
    this.setState({
      differentEnd: event.target.value,
    });
  }

  differentTimeChange(event) {
    console.log('event.target.value', event.target.value);
    this.setState({
      differentTime: event.target.value,
    });
  }

  resetPackage() {
    this.props.resetPackage();
  }

  createEvent(profile, packageBundle, customEvent) {
    console.log('customEvent', customEvent);
    const thisEvent = customEvent.event;
    const db = firebase.firestore();
    const newEventRef = db.collection('events').doc();
    const dbref = newEventRef.id;
    const fullname = profile.firstName + ' ' + profile.lastName;
    const timestamp = new Date();
    // Prepare Locations
    const locationArray = [];
    // Check if there are any new locations in the event and if so add to DB

    const eventLocations = packageBundle.package.locations;
    if (eventLocations.length > 0) {
      eventLocations.forEach((location, i) => {
        if (location.type === 1) {
          const db = firebase.firestore();
          const newLocationRef = db.collection('locations').doc();
          const dbref = newLocationRef.id;
          locationArray[i] = {
            locationId: dbref,
            locationTitle: location.location.locationTitle,
            locationType: 1,
          };
          this.uploadLocation(
            location.location,
            dbref,
            profile.vendorAccount.id,
            this.props.vendor.businessName,
          );
        } else {
          const locationDetails = query('locationId')
            .is(location.locationId)
            .on(this.props.locations);
          locationArray[i] = {
            locationId: locationDetails[0].locationId,
            locationTitle: locationDetails[0].locationTitle,
            locationType: 0,
          };
        }
      });
    } else {
      let locationId = this.props.vendor.locations[0];
      let locationDetails = query('locationId')
        .is(locationId)
        .on(this.props.locations);
      locationArray[0] = {
        locationId,
        locationTitle: locationDetails[0].locationTitle,
        locationType: 0,
      };
    }

    // Create Event
    const newEvent = {
      active: true,
      created: timestamp,
      title: this.state.title,
      id: dbref,
      locations: locationArray,
      description: this.state.description,
      zoneId: profile.zoneId,
      creatorInfo: {
        creatorImage: profile.profilePhotoPath,
        creatorName: fullname,
        creatorId: profile.userId,
        // type: 3,
        type: 1,
        vendorId: profile.vendorAccount.id,
        vendorBusinessName: this.props.vendor.businessName,
      },
      status: 1,
    };

    // Update Hero and Thumbnail
    let heroPath = '';
    let thumbnailPath = '';
    if (this.state.customHero === true) {
      heroPath =
        'vendor_images%2F' +
        this.props.vendor.vendorId +
        '%2F' +
        this.state.dbref +
        '%2FheroImage.jpeg';
      thisEvent.eventSettings.customHero = true;
    } else {
      heroPath = thisEvent.heroImage;
      thisEvent.eventSettings.customHero = false;
    }
    if (this.state.customThumb === true) {
      thumbnailPath =
        'vendor_images%2F' +
        this.props.vendor.vendorId +
        '%2F' +
        this.state.dbref +
        '%2Fthumbnail.jpeg';
      thisEvent.eventSettings.customThumb = true;
    } else {
      thumbnailPath = thisEvent.thumbnail;
      thisEvent.eventSettings.customThumb = false;
    }
    if (heroPath !== undefined) {
      newEvent.heroImage = heroPath;
    } else {
      newEvent.heroImage = null;
    }
    if (thumbnailPath !== undefined) {
      newEvent.thumbnail = thumbnailPath;
    } else {
      newEvent.thumbnail = null;
    }

    // Update Schedule
    console.log('datesxxx - from', this.state.from);
    console.log('datesxxx - to', this.state.to);

    // let scheduledTime ={
    //   dates: {
    //     oneDay: true,
    //     startDate: null,
    //     endDate: null
    //   },
    //   times: {
    //     regHours: true,
    //     startTime: null,
    //     endTime: null
    //   }

    // }

    // this.state.selectedStartTime
    // this.state.selectedEndTime
    // this.state.to
    // this.state.from
    newEvent.scheduledTime = {};
    newEvent.scheduledTime.times = this.setScheduleTimes(
      this.state.selectedStartTime,
      this.state.selectedEndTime,
      this.state.differentTime,
    );

    let to = null;
    if (this.state.to !== undefined) {
      to = this.state.to;
    } else {
      to = this.state.from;
    }

    newEvent.scheduledTime.dates = this.setSchedule(
      this.state.from,
      to,
    );

    // newEvent.scheduledTime = this.setSchedule(
    //   this.state.selectedDay,
    //   this.state.selectedTime
    // );

    console.log('SavePackage', newEvent);
    this.props.saveEvent(newEvent);
  }

  uploadLocation(location, id, vendorId, vendorBusinessName) {
    const newLocation = Object.assign({}, location, {
      locationId: id,
      custom: true,
      vendorReferral: true,
      vendorReferralInfo: {
        vendorId: vendorId,
        vendorBusinessName: vendorBusinessName,
      },
    });
    console.log('SavePackage - uploadLocation', newLocation);
    const db = firebase.firestore();
    const setDoc = db
      .collection('locationTest')
      .doc(id)
      .set(newLocation)
      .then((ref) => {
        console.log(ref);
      });
  }

  // Crop Functions
  // Banner Crop
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      cropping: true,
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'heroImage.jpeg',
      );

      this.setState({ heroURL: croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getBannerCropper(bannerCropping) {
    const { crop, croppedImageUrl, src } = this.state;
    if (bannerCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropper();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropper();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </div>
        </div>
      );
    }
  }
  // End Banner Crop
  // Thumb Crop
  onSelectFileThumb = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      croppingThumb: true,
    });
  };

  onImageLoadedThumb = (image) => {
    this.imageRef = image;
  };

  onCropCompleteThumb = (crop) => {
    this.makeClientCropThumb(crop);
  };

  onCropChangeThumb = (cropThumb, percentCrop) => {
    this.setState({ cropThumb });
  };

  async makeClientCropThumb(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImgThumb(
        this.imageRef,
        crop,
        'thumbnail.jpeg',
      );
      console.log('croppedImageUrl', croppedImageUrl);
      this.setState({
        thumbURL: croppedImageUrl,
      });
    }
  }

  getCroppedImgThumb(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getThumbnailCropper(thumbnailCropping) {
    const { cropThumb, croppedImageUrl, src } = this.state;
    if (thumbnailCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropperThumb();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropperThumb();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={cropThumb}
                onImageLoaded={this.onImageLoadedThumb}
                onComplete={this.onCropCompleteThumb}
                onChange={this.onCropChangeThumb}
              />
            )}
          </div>
        </div>
      );
    }
  }
  //End Thumb Crop
  cancelCropper() {
    this.setState({
      cropping: false,
    });
  }

  saveCropper() {
    this.uploadImage(this.state.blob, 'hero');
    this.setState({
      cropping: false,
    });
  }

  cancelCropperThumb() {
    this.setState({
      croppingThumb: false,
    });
  }

  saveCropperThumb() {
    this.uploadImage(this.state.blob, 'thumb');
    this.setState({
      croppingThumb: false,
    });
  }

  uploadImage = (image, type) => {
    const uploadUrl =
      'vendor_images/' +
      this.props.vendor.vendorId +
      '/' +
      this.state.dbref +
      '/' +
      image.name;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadUrl);
    imageRef.put(image).then((snapshot) => {
      // const path = snapshot.ref.location.path_;
      if (type === 'hero') {
        let heroImagePath =
          'vendor_images%2F' +
          this.props.vendor.vendorId +
          '%2F' +
          this.state.dbref +
          '%2FheroImage.jpeg';
        let heroPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          heroImagePath +
          '?alt=media';
        this.setState({
          heroPath,
          customHero: true,
        });
      } else {
        let thumbnailImagePath =
          'vendor_images%2F' +
          this.props.vendor.vendorId +
          '%2F' +
          this.state.dbref +
          '%2Fthumbnail.jpeg';
        let thumbPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          thumbnailImagePath +
          '?alt=media';
        this.setState({
          thumbPath,
          customThumb: true,
        });
      }
    });
  };

  getHeroImage() {
    return (
      <img
        alt="Crop"
        style={{ maxWidth: '100%' }}
        src={this.state.heroPath}
      />
    );
  }

  getThumbnail() {
    return (
      <img
        alt="Crop"
        style={{ maxWidth: '100%' }}
        src={this.state.thumbPath}
      />
    );
  }

  getImagesTab() {
    return (
      <Container id="imageUpload">
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <span className="inputLabel">Banner Image</span>
            {this.getHeroImage()}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <div>
              <input type="file" onChange={this.onSelectFile} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <span className="inputLabel">Thumbnail Image</span>
            {this.getThumbnail()}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={6} sm={12} xs={12} className="left">
            <div>
              <input type="file" onChange={this.onSelectFileThumb} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  // // Schedule Functions
  // handleDayClick(day, { selected }) {
  //   console.log("handleDayClick - day", day);
  //   console.log("handleDayClick - selected", selected);
  //   this.setState({
  //     selectedDay: selected ? undefined : day
  //   });
  // }

  onTimeChange = (time) => {
    console.log('TIME!', time);
    this.setState({ selectedTime: time });
  };

  setScheduleTimes(
    selectedStartTime,
    selectedEndTime,
    differentTime,
  ) {
    // Check if Time Set
    let bundle = {
      startTime: null,
      startTimeString: null,
      endTime: null,
      endTimeString: null,
      regHours: null,
    };

    if (differentTime === 'no') {
      bundle.regHours = true;
    } else {
      bundle.regHours = false;
    }

    if (
      selectedStartTime !== '' &&
      selectedStartTime !== undefined &&
      selectedStartTime !== null
    ) {
      const datestring = String(selectedStartTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      // const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.startTime = timeValue;
      bundle.startTimeString = selectedStartTime._d;
    }

    if (
      selectedEndTime !== '' &&
      selectedEndTime !== undefined &&
      selectedEndTime !== null
    ) {
      const datestring = String(selectedEndTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      // const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.endTime = timeValue;
      bundle.endTimeString = selectedEndTime._d;
    }

    return bundle;
  }

  // getEndDatePicker(differentEnd, modifiersStyles, today) {
  //   if (differentEnd === "yes") {
  //     return (
  //       <div>
  //         <span className="inputLabel">What is the End Date of the Event?</span>
  //         <div className="text-center">
  //           <DayPicker
  //             onDayClick={this.handleEndDayClick}
  //             selectedDays={this.state.selectedEndDay}
  //             modifiersStyles={modifiersStyles}
  //             disabledDays={{ before: today }}
  //           />
  //           <p>
  //             {this.state.selectedEndDay
  //               ? this.state.selectedEndDay.toLocaleDateString()
  //               : "Please select a day"}
  //           </p>
  //         </div>
  //       </div>
  //     );
  //   }
  // }

  getDifferentTimePicker(differentTime, modifiersStyles, today) {
    if (differentTime === 'yes') {
      return (
        <div>
          <div className="clear">
            <span className="inputLabel">Start Time</span>
            <TimePicker
              showSecond={false}
              defaultValue={this.state.selectedStartTime}
              className="xxx"
              onChange={this.onStartTimeChange}
              format={format}
              use12Hours
              inputReadOnly
            />
          </div>

          <div className="clear">
            <span className="inputLabel">End Time</span>
            <TimePicker
              showSecond={false}
              defaultValue={this.state.selectedEndTime}
              className="xxx"
              onChange={this.onEndTimeChange}
              format={format}
              use12Hours
              inputReadOnly
            />
          </div>
        </div>
      );
    }
  }

  handleResetClick() {
    this.setState({ from: undefined, to: undefined });
  }

  getScheduleTab() {
    const { from, to } = this.state;
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
      },
    };
    const modifiers = { start: from, end: to };
    const today = new Date();

    return (
      <Container>
        <Row className="no-margin">
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <Row className="no-margin">
              <h5>Event Dates</h5>
              <div className="full-width">
                <p>
                  {!from && !to && 'Please select the first day.'}
                  {from && !to && 'Please select the last day.'}
                  {from &&
                    to &&
                    `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
                  {from && to && (
                    <button
                      className="link"
                      onClick={this.handleResetClick.bind(this)}
                    >
                      Reset
                    </button>
                  )}
                </p>
              </div>
              <div id="vendorSchedule">
                <DayPicker
                  onDayClick={this.handleStartDayClick}
                  selectedDays={[from, { from, to }]}
                  modifiers={modifiers}
                  modifiersStyles={modifiersStyles}
                  disabledDays={{ before: today }}
                />
              </div>
              {/* <p>
                    {this.state.selectedStartDay
                      ? this.state.selectedStartDay.toLocaleDateString()
                      : "Please select a day"}
                  </p> */}
            </Row>
            <Row>
              <div></div>
            </Row>
          </Col>
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <Row>
              <h5>Event Time</h5>
              <div className="full-width">
                <label>
                  Is the time of the event different than your regular
                  hours?
                </label>
              </div>
              <div className="clear">
                <label>
                  <input
                    type="radio"
                    value="no"
                    checked={this.state.differentTime === 'no'}
                    onChange={this.differentTimeChange.bind(this)}
                  />
                  No
                </label>

                <label>
                  <input
                    type="radio"
                    value="yes"
                    checked={this.state.differentTime === 'yes'}
                    onChange={this.differentTimeChange.bind(this)}
                  />
                  Yes
                </label>
              </div>
            </Row>
            <Row>
              {this.getDifferentTimePicker(
                this.state.differentTime,
                modifiersStyles,
                today,
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  //Tabs
  getDetailsTab() {
    return (
      <div className="section">
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            {this.displayForm()}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <span className="inputLabel">Event Itinerary</span>
            <div className="clear">
              <a
                onClick={() => {
                  this.updateAndAdd(this.props.packageBundle, 0);
                }}
              >
                <span className="left addLocationButton">
                  <MaterialIcon icon="add" size={35} color="white" />
                </span>
              </a>
            </div>
            <div className="clear">
              {this.state.locations.map((location, index) => (
                <div>
                  {this.getLocationDetails(
                    location,
                    this.props.locations,
                    index,
                    true,
                  )}
                </div>
              ))}
            </div>
            <div className="clear">
              <a
                onClick={() => {
                  this.updateAndAdd(this.props.packageBundle, 1);
                }}
              >
                <span className="left addLocationButton">
                  <MaterialIcon icon="add" size={35} color="white" />
                </span>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getContent(mode, users, userId) {
    if (mode === 1) {
      return this.getDetailsTab(users, userId);
    } else if (mode === 2) {
      return this.getScheduleTab();
    } else {
      return this.getImagesTab();
    }
  }

  getTab(tabMode, tabLable, mode, last) {
    if (tabMode === mode) {
      if (last === true) {
        return (
          <div className="text-center noBorderRight actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      } else {
        return (
          <div className="text-center actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      }
    } else {
      if (last === true) {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="text-center actionButton noBorderRight  actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="text-center actionButton actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      }
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <MaterialIcon
                // icon="keyboard_backspace"
                icon="close"
                size={25}
                color="white"
                onClick={() => {
                  this.resetPackage();
                }}
              />
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Edit Event</span>
              </center>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="right">
                <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.updateEvent(
                      this.props.profile,
                      this.props.packageBundle,
                      this.props.customEvent,
                    );
                  }}
                />
              </span>
            </Col>
          </Row>
        </div>
        <div id="headersTopSecondaryEightyWidthless">
          <Row>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getTab(1, 'Details', this.state.mode, false)}
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getTab(2, 'Schedule', this.state.mode, false)}
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {this.getTab(3, 'Images', this.state.mode, true)}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div>
        <div>{this.getBannerCropper(this.state.cropping)}</div>
        <div>
          {this.getThumbnailCropper(this.state.croppingThumb)}
        </div>
        {this.getTitleBar()}
        <div
          id="events"
          className="section bottom-push grey-back pushfifty"
        >
          {this.getContent(
            this.state.mode,
            this.props.customEvent.event.users,
            this.props.profile.userId,
          )}
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customEvent: state.customEvent.customEvent,
    locations: state.locations.locations,
    events: state.events.events,
    packages: state.packages.packages,
    profile: state.profile.profile,
    packageBundle: state.newPackage,
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    vendorEvents: state.vendorEvents.events,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePackage: (newPackage) => {
      dispatch(updatePackage(newPackage));
    },
    saveEvent: (newEvent) => {
      dispatch(saveEvent(newEvent));
    },
    savePackage: (newPackage) => {
      dispatch(savePackage(newPackage));
    },
    addPackage: (newPackage) => {
      dispatch(addPackage(newPackage));
    },
    resetPackage: (newPackage) => {
      dispatch(resetPackage(newPackage));
    },
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    getVendorEvents: (id) => {
      dispatch(getVendorEvents(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorEditEvent);
