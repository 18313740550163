import MaterialIcon from 'material-icons-react';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../images/TrevelUnderlined.svg';
import * as auth from '../../config/auth';
import { clearProfile } from '../../sagas/getProfileSaga/reducer';
import { withRouter } from 'react-router';

class VendorMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.logOut = this.logOut.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.history) {
      this.setState({
        currentUrl: this.props.history.location.pathname,
        loading: false,
      });
    }
  }

  logOut() {
    const user = {
      id: null,
      userName: null,
      email: null,
      authenticated: false,
    };
    auth.doSignOut();
    this.props.clearProfile();
  }

  render() {
    let showLocation = this.props.vendor.vendorTypes.includes(1);
    let showEvents = this.props.vendor.vendorTypes.includes(3);
    let entertainer = this.props.vendor.vendorTypes.includes(2);

    return (
      <div
        className={
          this.props.popupMenu === true
            ? 'appPickerDrawer'
            : 'appMenu'
        }
      >
        {this.props.popupMenu === true && (
          <div
            className="sidebar-logo"
            style={{ height: '80px', margin: '0px 0px 25px 0px' }}
          >
            <div className=" left">
              <center>
                <img
                  src={logo}
                  style={{
                    maxWidth: '110px',
                    margin: '0px 0px 6px 0px',
                  }}
                />
                <h5>Experiences</h5>
              </center>
            </div>
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="rgb(238 1 88)"
                onClick={() => {
                  this.props.showPicker(false);
                }}
              />
            </span>
          </div>
        )}

        <div
          style={{
            padding:
              this.props.popupMenu === true
                ? '0px'
                : '0px 0px 0px 0px',
          }}
        >
          <NavLink
            to={{
              pathname: '/vendor/home',
            }}
            activeClassName="activeMemuItem"
          >
            <span className="appTitle">Home</span>
          </NavLink>

          {/* <div className="appList"> */}
        </div>
        {this.props.profile.userType !== undefined &&
          this.props.profile.userType === 1 && (
            <div style={{ marginTop: '20px' }}>
              <div>
                <NavLink
                  to={{
                    pathname: '/admin/templates',
                  }}
                  activeClassName="activeMemuItem"
                >
                  <span className="appTitle">Guide</span>
                </NavLink>
                <NavLink
                  to={{
                    pathname: '/admin/articles',
                  }}
                  activeClassName="activeMemuItem"
                >
                  <span className="appTitle">Articles</span>
                </NavLink>

                <NavLink
                  to={{
                    pathname: '/admin/locations',
                  }}
                  activeClassName="activeMemuItem"
                >
                  <span className="appTitle">Locations</span>
                </NavLink>
                <NavLink
                  to={{
                    pathname: '/admin/vendors',
                  }}
                  activeClassName="activeMemuItem"
                >
                  <span className="appTitle">Vendors</span>
                </NavLink>
                <NavLink
                  to={{
                    pathname: '/admin/users',
                  }}
                  activeClassName="activeMemuItem"
                >
                  <span className="appTitle">Users</span>
                </NavLink>
                <NavLink
                  to={{
                    pathname: '/admin/regions',
                  }}
                  activeClassName="activeMemuItem"
                >
                  <span className="appTitle">Regions</span>
                </NavLink>
                <NavLink
                  to={{
                    pathname: '/admin/tags',
                  }}
                  activeClassName="activeMemuItem"
                >
                  <span className="appTitle">Tags</span>
                </NavLink>
              </div>
              <div
                style={{
                  margin:
                    this.props.popupMenu === true
                      ? '20px 0px 0px 0px'
                      : '20px 0px 0px 0px',
                }}
              >
                <span className="appListCategory">Experiences</span>
                <NavLink
                  to={{
                    pathname: '/vendor/experiences',
                  }}
                  activeClassName="activeMemuItem"
                >
                  <span
                    className="appTitle"
                    style={{ fontWeight: '600' }}
                  >
                    Create
                  </span>
                </NavLink>
                <NavLink
                  to={{
                    pathname: '/vendor/validator',
                  }}
                  activeClassName="activeMemuItem"
                >
                  <span
                    className="appTitle"
                    style={{ fontWeight: '600' }}
                  >
                    Manage
                  </span>
                </NavLink>
                <NavLink
                  to={{
                    pathname: '/vendor/insights',
                  }}
                  activeClassName="activeMemuItem"
                >
                  <span
                    className="appTitle"
                    style={{ fontWeight: '600' }}
                  >
                    Analyze
                  </span>
                </NavLink>
              </div>
            </div>
          )}
        <div style={{ margin: '20px 0px 0px 0px' }}>
          <NavLink
            to={{
              pathname: '/vendor/timedevents',
            }}
            activeClassName="activeMemuItem"
          >
            <span className="appTitle">Promote</span>
          </NavLink>
        </div>
        <div style={{ margin: '20px 0px 0px 0px' }}>
          <span className="appListCategory">MANAGE</span>
          {showLocation === true && (
            <NavLink
              to={{
                pathname: '/vendor/locations',
              }}
              activeClassName="activeMemuItem"
            >
              <span className="appTitle">Locations</span>
            </NavLink>
          )}

          {/* {showLocation === true && (
            <NavLink
              to={{
                pathname: '/vendor/timedpromos',
              }}
              activeClassName="activeMemuItem"
            >
              <span className="appTitle">Promotions</span>
            </NavLink>
          )} */}

          {this.props.profile.userType === 1 && (
            <NavLink
              to={{
                pathname: '/vendor/admin/entertainment',
              }}
              activeClassName="activeMemuItem"
            >
              <span className="appTitle">Entertainment</span>
            </NavLink>
          )}
          {entertainer === true &&
            this.props.profile.userType !== 1 && (
              <NavLink
                to={{
                  pathname:
                    '/vendor/entertainment/edit/' +
                    this.props.vendor.entertainment[0],
                }}
                activeClassName="activeMemuItem"
              >
                <span className="appTitle">Page</span>
              </NavLink>
            )}
          {/* {showEvents === true && (
            <NavLink
              to={{
                pathname: '/vendor/timedevents',
              }}
              activeClassName="activeMemuItem"
            >
              <span className="appTitle">Events</span>
            </NavLink>
          )} */}

          {/* <NavLink
            to={{
              pathname: '/vendor/users',
            }}
            activeClassName="activeMemuItem"
          >
            <span className="appTitle">Users</span>
          </NavLink> */}
          <NavLink
            to={{
              pathname: '/vendor/account',
            }}
            activeClassName="activeMemuItem"
          >
            <span className="appTitle">Account</span>
          </NavLink>

          <div style={{ marginTop: '40px' }}>
            {/* <NavLink
              to={{
                pathname: '/vendor/community',
              }}
              activeClassName="activeMemuItem"
            >
              <span className="appTitle">Community</span>
            </NavLink> */}
            <NavLink
              to={{
                pathname: '/vendor/support',
              }}
              activeClassName="activeMemuItem"
            >
              <span className="appTitle">Help</span>
            </NavLink>
            <a
              href="https://trevel.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="appTitle">Open Trevel</span>
            </a>
          </div>
          {/* <div
            style={{
              margin:
                this.props.popupMenu === true
                  ? '20px 0px 0px 0px'
                  : '20px 0px 0px 0px',
            }}
          >
            <span className="appListCategory">Experiences</span>
            <NavLink
              to={{
                pathname: '/vendor/experiences',
              }}
              activeClassName="activeMemuItem"
            >
              <span
                className="appTitle"
                style={{ fontWeight: '600' }}
              >
                Create
              </span>
            </NavLink>
            <NavLink
              to={{
                pathname: '/vendor/validator',
              }}
              activeClassName="activeMemuItem"
            >
              <span
                className="appTitle"
                style={{ fontWeight: '600' }}
              >
                Manage
              </span>
            </NavLink>
            <NavLink
              to={{
                pathname: '/vendor/insights',
              }}
              activeClassName="activeMemuItem"
            >
              <span
                className="appTitle"
                style={{ fontWeight: '600' }}
              >
                Analyze
              </span>
            </NavLink>
          </div> */}

          <div
            style={{
              display: 'block',
              marginTop: '40px',
              width: '100%',
            }}
          >
            <a
              onClick={() => {
                this.logOut();
              }}
            >
              <span className="appTitle">Sign out</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearProfile: (user) => {
      dispatch(clearProfile(user));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: state.profile.profile,
    vendor: state.vendor.vendor,
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VendorMenu),
);
