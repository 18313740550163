import * as action from './actions';

export const getArticles = () => {
  return {
    type: action.GET_ARTICLES,
    payload: {}
  };
};

const ACTION_HANDLERS = {
  [action.SET_ARTICLES]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_ARTICLES]: (state, action) => {
    return { ...state, ...action.payload };
  }
};

let defaultState = {};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
