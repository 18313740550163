import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

class DailyLocations extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {}

  findPromotedLocations(locations, userLove) {
    let userTags = [];
    let connectionsTags = [];
    let setTag = undefined;

    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };

    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };

    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };

    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };

    let allTags = {
      tagsBeverages: [],
      tagsDateType: [],
      tagsSetting: [],
      tagsCuisine: [],
    };
    // Combine Tags

    function getPromoted(locations) {
      const promotedLocations = [];
      userLove.tagsBeverages.forEach((tagcat, x) => {
        let n = allTags.tagsBeverages.includes(tagcat);
        if (n === false) {
          allTags.tagsBeverages.push(tagcat);
        }
      });

      userLove.tagsDateType.forEach((tagcat, x) => {
        let n = allTags.tagsDateType.includes(tagcat);
        if (n === false) {
          allTags.tagsDateType.push(tagcat);
        }
      });

      userLove.tagsSetting.forEach((tagcat, x) => {
        let n = allTags.tagsSetting.includes(tagcat);
        if (n === false) {
          allTags.tagsSetting.push(tagcat);
        }
      });

      userLove.tagsCuisine.forEach((tagcat, x) => {
        let n = allTags.tagsCuisine.includes(tagcat);
        if (n === false) {
          allTags.tagsCuisine.push(tagcat);
        }
      });

      let randomNum = Math.floor(Math.random() * 2);

      if (randomNum === 1) {
        if (allTags.tagsBeverages.length === 0) {
          randomNum = 0;
        }
      }

      if (randomNum === 0) {
        if (allTags.tagsCuisine.length === 0) {
          randomNum = 1;
        }
      }

      if (randomNum === 1) {
        if (allTags.tagsBeverages.length !== 0) {
          let randomTagNum = Math.floor(
            Math.random() * allTags.tagsBeverages.length,
          );
          setTag = allTags.tagsBeverages[randomTagNum];

          allTags.tagsBeverages.forEach((tag) => {
            locations.forEach((location) => {
              if (location.tagsBeverages.length !== 0) {
                location.tagsBeverages.forEach((tagx) => {
                  if (tagx === allTags.tagsBeverages[randomTagNum]) {
                    promotedLocations.push(location);
                  }
                });
              }
            });
          });
        }
      } else if (randomNum === 0) {
        if (allTags.tagsCuisine.length !== 0) {
          let randomTagNum = Math.floor(
            Math.random() * allTags.tagsCuisine.length,
          );

          setTag = allTags.tagsCuisine[randomTagNum];
          let promotedLocationIds = [];
          allTags.tagsCuisine.forEach((tag) => {
            locations.forEach((location) => {
              if (
                location.tagsCuisine !== undefined &&
                location.tagsCuisine.length !== 0
              ) {
                location.tagsCuisine.forEach((tagx) => {
                  if (tagx === allTags.tagsCuisine[randomTagNum]) {
                    const status = promotedLocationIds.includes(
                      location.locationId,
                    );
                    if (status === false) {
                      promotedLocationIds.push(location.locationId);
                      promotedLocations.push(location);
                    }
                  }
                });
              }
            });
          });
        }
      }
      return promotedLocations;
    }

    let promotedLocations = [];
    let i = 0;

    while (promotedLocations.length === 0 && i !== 10) {
      promotedLocations = getPromoted(locations);
      i++;
    }

    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
    let shuffledLocations = shuffleArray(promotedLocations);
    let topThree = [];
    let topThreeIds = [];
    shuffledLocations.forEach((location) => {
      if (topThree.length !== 10) {
        const status = topThreeIds.includes(location.locationId);
        if (status === false) {
          topThreeIds.push(location.locationId);
          topThree.push(location);
        }
      }
    });

    let bundle = {
      promotedLocations: topThree,
      setTag,
    };

    return bundle;
  }

  getTagCount(userLove) {
    let userTags = [];
    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };
    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };
    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateype',
      tags: [],
    };
    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisinetype',
      tags: [],
    };
    let tagsGenre = {
      parent: 'Genre',
      sid: 'genre',
      tags: [],
    };
    let allTags = {
      tagsBeverages: [],
      tagsDateType: [],
      tagsSetting: [],
      tagsCuisine: [],
      tagsGenre: [],
    };
    // Combine Tags

    userLove.tagsBeverages.forEach((tagcat, x) => {
      let n = allTags.tagsBeverages.includes(tagcat);
      if (n === false) {
        allTags.tagsBeverages.push(tagcat);
      }
    });

    userLove.tagsDateType.forEach((tagcat, x) => {
      let n = allTags.tagsDateType.includes(tagcat);
      if (n === false) {
        allTags.tagsDateType.push(tagcat);
      }
    });

    userLove.tagsSetting.forEach((tagcat, x) => {
      let n = allTags.tagsSetting.includes(tagcat);
      if (n === false) {
        allTags.tagsSetting.push(tagcat);
      }
    });

    userLove.tagsCuisine.forEach((tagcat, x) => {
      let n = allTags.tagsCuisine.includes(tagcat);
      if (n === false) {
        allTags.tagsCuisine.push(tagcat);
      }
    });
    if (userLove.tagsGenre !== undefined) {
      userLove.tagsGenre.forEach((tagcat, x) => {
        let n = allTags.tagsGenre.includes(tagcat);
        if (n === false) {
          allTags.tagsGenre.push(tagcat);
        }
      });
    }

    let tagCount =
      allTags.tagsCuisine.length +
      allTags.tagsSetting.length +
      allTags.tagsDateType.length +
      allTags.tagsGenre.length +
      allTags.tagsBeverages.length;
    allTags.tagsSetting;

    return tagCount;
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  getSingle(promotedLocations) {
    let location = promotedLocations[0];
    if (promotedLocations.length === 1) {
      return (
        <div>
          <Link
            to={{
              pathname: '/location/' + location.locationId,
              state: {
                location: { location },
                // eventId: { eventId },
                // token: { token },
                type: 500,
              },
            }}
          >
            <div>
              <img
                src={this.getImage('hero', location)}
                style={{ width: '100%' }}
              />
              <div className="section">
                <span className="title">
                  {location.locationTitle}
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: location.locationDescription,
                  }}
                  className="editoOverRide"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getSlideSections(promotedLocations, count) {
    let sections = [];
    let sectionOne = [];
    let sectionTwo = [];
    let sectionThree = [];
    let sectionFour = [];
    let sectionFive = [];
    let sectionSix = [];
    let sectionSeven = [];
    let sectionEight = [];
    let sectionNine = [];
    let sectionTen = [];

    if (count === 4) {
      promotedLocations.forEach((location, index) => {
        if (index < 3 || index === 3) {
          sectionOne.push(location);
        }
        if (index > 3 && index < 8) {
          sectionTwo.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
    }

    if (count === 3) {
      promotedLocations.forEach((location, index) => {
        if (index < 2 || index === 2) {
          sectionOne.push(location);
        }
        if (index > 2 && index < 6) {
          sectionTwo.push(location);
        }
        if (index > 6 && index < 9) {
          sectionThree.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
    }

    if (count === 2) {
      promotedLocations.forEach((location, index) => {
        if (index === 0) {
          sectionOne.push(location);
        }
        if (index === 1) {
          sectionTwo.push(location);
        }
        if (index === 2) {
          sectionThree.push(location);
        }
        if (index === 3) {
          sectionFour.push(location);
        }
        if (index === 4) {
          sectionFive.push(location);
        }
        if (index === 5) {
          sectionSix.push(location);
        }
        if (index === 6) {
          sectionSeven.push(location);
        }
        if (index === 7) {
          sectionEight.push(location);
        }
        if (index === 8) {
          sectionNine.push(location);
        }
        if (index === 9) {
          sectionTen.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
      if (sectionFour.length !== 0) {
        sections.push(sectionFour);
      }
      if (sectionFive.length !== 0) {
        sections.push(sectionFive);
      }
      if (sectionSix.length !== 0) {
        sections.push(sectionSix);
      }
      if (sectionEight.length !== 0) {
        sections.push(sectionEight);
      }
      if (sectionSeven.length !== 0) {
        sections.push(sectionSeven);
      }
      if (sectionNine.length !== 0) {
        sections.push(sectionNine);
      }
      if (sectionTen.length !== 0) {
        sections.push(sectionTen);
      }
    }

    return sections.map((section, index) => {
      return (
        <div style={{ height: '100%' }}>
          {section.map((location, index) => (
            <div
              style={{
                border: '0px solid rgba(0, 0, 0, 0.125)',
                background: 'white !important',
              }}
              className={count === 2 ? 'singleSlide' : 'multiSlide'}
            >
              <Link
                to={{
                  pathname: '/location/' + location.locationId,
                  state: {
                    location: { location },
                    // eventId: { eventId },
                    // token: { token },
                    type: 500,
                  },
                }}
              >
                <CardImg
                  top
                  width="100%"
                  src={this.getImage('hero', location)}
                  alt="articleHero"
                />
                <CardBody style={{ textAlign: 'left' }}>
                  <div style={{ paddingTop: '10px', border: '0px' }}>
                    <span className="title">
                      {location.locationTitle}
                    </span>
                    {this.getMainTag(location)}
                    {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}

                    <CardText>
                      <span className="editoOverRide">
                        {this.truncate(location.locationDescription)}
                      </span>
                    </CardText>
                    <CardText />
                  </div>
                </CardBody>
                <div
                  style={{
                    display: 'block',
                    paddingLeft: '5px',
                    bottom: '10px',
                  }}
                >
                  <span
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                    className="left "
                  >
                    {location.address.region}
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      );
    });
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span className="mainTagClear">{location.tagsMain}</span>
      );
    }
  }

  truncate(str) {
    let regex = /(<([^>]+)>)/gi;
    let result = str.replace(regex, '');
    let final = result.replace(/&amp;/g, '&');
    return final.length > 100
      ? final.substring(0, 100) + '...'
      : final;
  }

  render() {
    let loveTags = {};
    if (
      this.props.profile.tags !== undefined &&
      this.props.profile.tags.love !== undefined
    ) {
      loveTags = this.props.profile.tags.love;
    }
    const bundle = this.findPromotedLocations(
      this.props.locations,
      loveTags,
    );
    let promotedLocations = bundle.promotedLocations;
    let setTag = bundle.setTag;
    let count = this.props.count;
    let tagCount = this.getTagCount(loveTags);
    // if (this.state.loading) {
    //   return null; // or render a loading icon
    // }
    if (tagCount === 0) {
      return (
        // <div className="homeSection" id="homeSectionGrey">
        <div className="homeSection">
          <div
            className="sectiontitle text-center"
            // style={{
            //   borderTop: '1px solid #313131',
            // }}
          >
            <span className="homeCatCenter">
              Customize Your Guide
            </span>
            <span>You haven{"'"}t set any preferences yet.</span>
            <Link
              to={{
                pathname: '/profile/tag',
                state: {},
              }}
            >
              <span
                className="solidredbutton"
                style={{ maxWidth: '150px', marginTop: '10px' }}
              >
                Set Preferences
              </span>
            </Link>
          </div>
        </div>
      );
    }
    if (promotedLocations.length > 0) {
      return (
        // <div className="homeSection" id="homeSectionGrey">
        <div className="homeSection">
          <div
          // style={{
          //   height: count > 2 ? '40px' : '40px',
          //   marginBottom: count > 2 ? '10px' : '10px',
          // }}
          >
            <Row
              className="no-margin"
              style={{
                padding: count > 2 ? '0x' : '0px',
              }}
            >
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="no-padding"
              >
                <div className="sectiontitle">
                  <span
                    className="homeCat "
                    style={{
                      float: count > 2 ? 'left' : 'left',
                      marginBottom: count > 2 ? '10px' : '',
                      textAlign: count > 2 ? 'left' : 'left',
                    }}
                  >
                    Great Places for {setTag}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          {promotedLocations.length > 1 && (
            <Carousel
              showThumbs={false}
              // centerMode={true}
              // centerSlidePercentage={80}
              // selectedItem={this.getCount(promotedLocations)}
              showIndicators={false}
              showStatus={false}
              infiniteLoop={false}
              centerMode={count === 2 ? true : false}
              swipeScrollTolerance={50}
              preventMovementUntilSwipeScrollTolerance={true}
            >
              {this.getSlideSections(promotedLocations, count)}
            </Carousel>
          )}
          {this.getSingle(promotedLocations)}
        </div>
      );
    }
    return null; // or render a loading icon
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
  };
}

export default connect(mapStateToProps)(DailyLocations);
