import React from 'react';
import { connect } from 'react-redux';
import SignUpBanner from '../../components/SignUpBanner';
import { Col, Row, Container } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../images/TrevelUnderlined.svg';
import DrawerToggleButton from '../../components/DrawerToggleButton';
// Components
import VendorFeatures from '../../components/VendorFeatures';
// Images

import { getUauth } from '../../sagas/unauthenticatedSaga/reducer';

export class VendorAbout extends React.Component {
  UNSAFE_componentWillMount() {
    document.title = 'Trevel Experiences  ';
    let persona = '0';
    if (this.props.match.params.persona !== undefined) {
      if (this.props.match.params.persona === 'destination') {
        persona = '1';
      } else if (
        this.props.match.params.persona === 'entertainment'
      ) {
        persona = '2';
      }
    }

    this.setState({
      persona,
    });
  }

  setVendorType(persona) {
    let personaNum = null;
    if (persona === '1') {
      personaNum = 1;
    } else {
      personaNum = 2;
    }
    let bundle = {
      claimId: null,
      vendorId: null,
      claimLink: false,
      guide: this.props.uauth.guide,
      vendorType: personaNum,
    };
    this.props.getUauth(bundle);

    if (persona === '1') {
      this.props.history.push('/experiences/destination');
    } else if (persona === '2') {
      this.props.history.push('/experiences/entertainment');
    }

    this.setState({
      persona,
    });
  }

  getTitleBar(title) {
    const authLink = this.authLink();
    return (
      <div id="headersTop">
        <div className="toolbarSignup">
          <div className="toolbar__navigation" id="full-navSignup">
            <Row className="no-margin">
              <Col lg={8} md={8} sm={8} xs={8} className="no-padding">
                <div className="toolbar__logo">
                  <Link
                    to={{
                      pathname: '/experiences',
                    }}
                  >
                    <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880" />
                  </Link>
                </div>
              </Col>

              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                <div className="toolbar_navigation_authlinks">
                  {this.authLink()}
                </div>
              </Col>
            </Row>
          </div>

          <div className="toolbar__navigation" id="mobile-nav">
            <Container>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="no-padding"
                >
                  <center>
                    <div className="toolbar__logo">
                      <Link
                        to={{
                          pathname: '/experiences',
                        }}
                      >
                        <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880" />
                      </Link>
                    </div>
                  </center>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="no-padding"
                >
                  <div>{this.authLinkMobile()}</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  authLinkMobile() {
    if (this.props.user.authenticated === true) {
      return (
        <div className="right">
          <a onClick={this.logOut} className="nav-logout">
            Log Out
          </a>
        </div>
      );
    } else {
      return (
        <div>
          {/* <a onClick={this.logIn} className='nav-login'> */}
          <Link
            to={{
              pathname: '/vendor/signup',
              state: {},
            }}
            className="nav-signup"
          >
            Create Free Account
          </Link>
        </div>
      );
    }
  }

  authLink() {
    if (this.props.user.authenticated === true) {
      return (
        <div className="right">
          <a onClick={this.logOut} className="nav-logout">
            Log Out
          </a>
        </div>
      );
    } else {
      return (
        <div className="right">
          {/* <a onClick={this.logIn} className='nav-login'> */}
          <Link
            to={{
              pathname: '/experiences',
            }}
            className="hideThis right"
            style={{ marginRight: '10px', color: '#f21d59' }}
          >
            <span className="nav-business">Businesses</span>
          </Link>
          <Link
            to={{
              pathname: '/vendor/signup',
              state: {},
            }}
            className="nav-signup right"
          >
            Create Free Account
          </Link>
        </div>
      );
    }
  }

  render() {
    if (this.state.persona === '0') {
      return (
        <div id="home">
          {this.getTitleBar('Trevel Experiences')}
          <div className="section">
            <center>
              <div
                style={{ height: '50px' }}
                className="mobileHide"
              />
              <div className="mobileHide">
                <img
                  style={{ maxWidth: '90%', marginTop: '20px' }}
                  src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880"
                />
              </div>

              <div style={{ padding: '40px 0px 0px 0px' }}>
                <h4
                  style={{
                    margin: '0px 0px 30px 0px',
                    fontWeight: '100',
                    color: '#4e4e4e',
                  }}
                  className="mobileHide"
                >
                  Our goal is help local businesses and entertainers
                  rise up together.
                </h4>
                <h4
                  style={{
                    margin: '0px 0px 50px 0px',
                  }}
                >
                  Which of these describes you best?
                </h4>
              </div>
            </center>

            <div
              style={{
                minHeight: '600px',
                maxWidth: '800px',
                margin: '0px auto',
              }}
            >
              <Row>
                <Col
                  xs={12}
                  md={6}
                  sm={12}
                  lg={6}
                  style={{ height: '100%' }}
                >
                  <a
                    onClick={() => {
                      this.setVendorType('1');
                    }}
                  >
                    <div className="homebaseApp">
                      <center>
                        <span
                          style={{
                            fontSize: '30px',
                            fontWeight: '100',
                            color: '#f21d59',
                          }}
                        >
                          Destination
                        </span>

                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '100',
                            clear: 'both',
                            display: 'block',
                            margin: '10px 0px 0px 0px',
                            color: 'dimgrey',
                            maxWidth: '350px',
                          }}
                        >
                          You represent a location, like a resteraunt,
                          bar or venue.
                        </span>
                      </center>
                    </div>
                  </a>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  sm={12}
                  lg={6}
                  style={{ height: '100%' }}
                >
                  <a
                    onClick={() => {
                      this.setVendorType('2');
                    }}
                  >
                    <div className="homebaseApp">
                      <center>
                        <span
                          style={{
                            fontSize: '30px',
                            fontWeight: '100',
                            color: '#f21d59',
                          }}
                        >
                          Entertainment
                        </span>

                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '100',
                            clear: 'both',
                            display: 'block',
                            margin: '10px 0px 0px 0px',
                            color: 'dimgrey',
                            maxWidth: '350px',
                          }}
                        >
                          You provide entertainment for people, like a
                          musician.
                        </span>
                      </center>
                    </div>
                  </a>
                </Col>
              </Row>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                ' body{height: 100%}  .hideThis { display: none } .feature{padding: 10px 0px 20px 0px} .toolbar{display:none}',
            }}
          />
        </div>
      );
    } else {
      return (
        <div id="home">
          {this.getTitleBar('Trevel Experiences')}

          <VendorFeatures persona={this.state.persona} />

          <style
            dangerouslySetInnerHTML={{
              __html:
                ' body{height: 100%}  .hideThis { display: none } .feature{padding: 10px 0px 20px 0px} .toolbar{display:none}',
            }}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    user: state.user,
    uauth: state.unauth,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUauth: (bundle) => {
      dispatch(getUauth(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorAbout);
