import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import MaterialIcon from 'material-icons-react';
import { random } from 'lodash';
import GoogleAd from '../GoogleAd';
import { getTaRequest } from '../../sagas/taRequestSaga/reducer';
import { ContinuousSizeLegend } from 'react-vis';

const query = require('array-query');

class GuideCategory extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      width: 0,
      height: 0,
      count: 4,
      carouselPosition: 0,
      taMessage: '',
      venues: [],
    };
  }

  componentDidMount() {
    this.setState({
      count: this.props.count,
    });
  }

  findPromotedLocations(locations, category) {
    const sortedLocations = this.sortAndWeight(
      locations,
      this.props.taRequest.distance,
      this.props.currentLongitude,
      this.props.currentLatitude,
      1,
    );
    let locationIds = [];
    if (this.props.customEvent !== undefined) {
      this.props.customEvent.event.locations.forEach((location) => {
        locationIds.push(location.locationId);
      });
    }

    let setTag = undefined;

    // Combine Tags
    let tagsLocationType = {
      parent: 'Location Type',
      sid: 'locationType',
      tags: [],
    };

    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };

    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };

    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateType',
      tags: [],
    };

    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisineType',
      tags: [],
    };

    let tagsEventType = {
      parent: 'Event Type',
      sid: 'eventType',
      tags: [],
    };

    let tagsGenre = {
      parent: 'Genre',
      sid: 'genre',
      tags: [],
    };

    // Set Entertainment
    let entertainment = [];
    if (this.props.entertainment !== undefined) {
      entertainment = this.props.entertainment;
    }

    // Sat Max Limit
    let max = 10;
    if (category.filters !== undefined) {
      category.filters.tags.forEach((tagGroup) => {
        if (tagGroup.tagType === 'localEntertainer') {
          max = 100;
        }
      });
    }

    function getPromoted(
      category,
      locations,
      max,
      type,
      tagFilters,
      taRequest,
      nearbyRegions,
      customEvent,
    ) {
      let promotedLocations = [];
      let promotedLocationIds = [];

      let selectedTags = [];

      let required = [];
      let requiredDiningType = [];

      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.checked === true) {
            if (
              tag.tag === 'Dinner' ||
              tag.tag === 'Lunch' ||
              tag.tag === 'Breakfast' ||
              tag.tag === 'Brunch' ||
              tag.tag === 'Happy Hour'
            ) {
              requiredDiningType.push(tag.tag);
            }
            selectedTags.push(tag.tag);
          }
        });
      });

      // If Strict Mode is on for tags, add the tags to required
      if (
        category.filters.tagSettings !== undefined &&
        category.filters.tagSettings.location.strict === true
      ) {
        category.filters.tags.forEach((tagGroup) => {
          if (tagGroup.sid !== 'locationType') {
            tagGroup.tags.forEach((tag) => {
              required.push(tag);
            });
          }
        });
      }

      if (locations !== undefined) {
        if (category.filters.tags.length !== 0) {
          locations.forEach((location) => {
            let dateCheck = false;

            if (
              location.eventId === undefined &&
              customEvent !== undefined &&
              customEvent.event.scheduledTime !== undefined &&
              customEvent.event.scheduledTime.date !== '' &&
              customEvent.event.scheduledTime.date !== null &&
              customEvent.event.scheduledTime.checkWeek === false &&
              customEvent.event.scheduledTime.checkMonth === false
            ) {
              let day = moment(customEvent.event.scheduledTime.date)
                .format('dddd')
                .toLowerCase();
              if (day === 'saturday') {
                day = 'saterday';
              }
              let dayString = day + 'Open';

              if (
                dayString !== 'invalid dateOpen' &&
                location.hours[day][dayString] !== undefined &&
                location.hours[day][dayString] === false
              ) {
                dateCheck = true;
              }
              // else if (dayString !== 'invalid dateOpen') {
              //   dateCheck = true;
              // }
            } else if (
              location.eventId === undefined &&
              customEvent !== undefined &&
              customEvent.event.scheduledTime !== undefined &&
              customEvent.event.scheduledTime.date !== '' &&
              customEvent.event.scheduledTime.date !== null &&
              customEvent.event.scheduledTime.checkWeek ===
                undefined &&
              customEvent.event.scheduledTime.checkMonth === undefined
            ) {
              let day = moment(customEvent.event.scheduledTime.date)
                .format('dddd')
                .toLowerCase();
              if (day === 'saturday') {
                day = 'saterday';
              }
              let dayString = day + 'Open';

              if (
                dayString !== 'invalid dateOpen' &&
                location.hours[day][dayString] !== undefined &&
                location.hours[day][dayString] === false
              ) {
                dateCheck = true;
              }
              // else if (dayString !== 'invalid dateOpen') {
              //
              //   dateCheck = true;
              // }
            } else {
              dateCheck = true;
            }

            let status = locationIds.includes(location.locationId);

            if (status !== true && dateCheck === true) {
              if (location.guestMatches !== undefined) {
              }

              // Check if any required fields
              let exclude = true;

              if (required.length === 0) {
                exclude = false;
              }

              if (
                location.tagsCuisine !== undefined &&
                location.tagsCuisine.length !== 0
              ) {
                location.tagsCuisine.forEach((tagx) => {
                  let status = required.includes(tagx);
                  if (status === true) {
                    exclude = false;
                  }
                });
              }
              if (
                location.tagsBeverages !== undefined &&
                location.tagsBeverages.length !== 0
              ) {
                location.tagsBeverages.forEach((tagx) => {
                  let status = required.includes(tagx);
                  if (status === true) {
                    exclude = false;
                  }
                });
              }
              if (
                location.tagsDateType !== undefined &&
                location.tagsDateType.length !== 0
              ) {
                location.tagsDateType.forEach((tagx) => {
                  let status = required.includes(tagx);
                  if (status === true) {
                    exclude = false;
                  }
                });
              }
              if (
                location.tagsTrevelTags !== undefined &&
                location.tagsTrevelTags.length !== 0
              ) {
                location.tagsTrevelTags.forEach((tagx) => {
                  let status = required.includes(tagx);
                  if (status === true) {
                    exclude = false;
                  }
                });
              }

              if (exclude === false) {
                if (taRequest.startLocation !== 'current') {
                  // gone for now
                  // if (category.filters.ignoreRegionFilters !== true) {
                  if (
                    category.filters.ignoreRegionFilters !== undefined
                  ) {
                    if (location.address.region !== undefined) {
                      let num = 0;
                      let region = taRequest.startLocation;

                      // If Last Location
                      if (region === 'lastLocation') {
                        let xnum = customEvent.event.locations.length;
                        if (xnum !== 0) {
                          num = Number(xnum) - 1;
                        }
                        region =
                          customEvent.event.locations[num].address
                            .region;
                      }

                      if (location.address.region !== region) {
                        const status = promotedLocationIds.includes(
                          location.eventId,
                        );
                        if (status === false) {
                          promotedLocationIds.push(
                            location.locationId,
                          );
                          let temp = location;
                          temp.bucket = 7;
                          promotedLocations.push(temp);
                        }
                        exclude = true;
                        let matchedOne = false;
                        if (nearbyRegions !== undefined) {
                          nearbyRegions.forEach((region) => {
                            if (region.name !== undefined) {
                              if (
                                location.address.region ===
                                region.name
                              ) {
                                matchedOne = true;
                              }
                            } else {
                              if (
                                location.address.region === region
                              ) {
                                matchedOne = true;
                              }
                            }
                          });
                          if (matchedOne === true) {
                            exclude = false;
                          }
                        }
                      }
                    }
                  }
                }
              }

              if (exclude === false) {
                if (requiredDiningType.length !== 0) {
                  if (location.tagsCuisine !== undefined) {
                    let matched = false;
                    location.tagsCuisine.forEach((tagx) => {
                      let status = requiredDiningType.includes(tagx);
                      if (status === true) {
                        matched = true;
                        exclude = false;
                      } else {
                        if (matched === false) {
                          exclude = true;
                        }
                      }
                    });
                  }
                  let status = requiredDiningType.includes(
                    location.tagsMain,
                  );
                  if (status === true) {
                    exclude = false;
                  }
                }
              }

              if (exclude === true) {
                if (
                  location.tagsTrevelTags !== undefined &&
                  location.tagsTrevelTags.length !== 0
                ) {
                  location.tagsTrevelTags.forEach((tag) => {
                    if (tag === 'No Results') {
                      const status = promotedLocationIds.includes(
                        location.eventId,
                      );
                      if (status === false) {
                        promotedLocationIds.push(location.locationId);
                        let temp = location;
                        temp.bucket = 7;
                        promotedLocations.push(temp);
                      }
                    }
                  });
                }
              } else {
                // console.log('hellov 2');
              }

              if (exclude === false) {
                category.filters.tags.forEach((tagGroup) => {
                  if (tagGroup.sid === 'trevelTags') {
                    if (
                      location.tagsTrevelTags !== undefined &&
                      location.tagsTrevelTags.length !== 0
                    ) {
                      location.tagsTrevelTags.forEach((tagx) => {
                        category.filters.tags.forEach((tagy) => {
                          tagy.tags.forEach((tagp) => {
                            if (tagx === tagp) {
                              const status =
                                promotedLocationIds.includes(
                                  location.eventId,
                                );
                              if (status === false) {
                                promotedLocationIds.push(
                                  location.locationId,
                                );
                                promotedLocations.push(location);
                              }
                            }
                          });
                        });
                      });
                    }
                  } else if (tagGroup.tagType === 'tagsBeverages') {
                    if (
                      location.tagsBeverages !== undefined &&
                      location.tagsBeverages.length !== 0
                    ) {
                      location.tagsBeverages.forEach((tagx) => {
                        category.filters.tags.forEach((tagy) => {
                          tagy.tags.forEach((tagp) => {
                            if (tagx === tagp) {
                              const status =
                                promotedLocationIds.includes(
                                  location.eventId,
                                );
                              if (status === false) {
                                promotedLocationIds.push(
                                  location.locationId,
                                );

                                promotedLocations.push(location);
                              }
                            }
                          });
                        });
                      });
                    }
                  } else if (tagGroup.tagType === 'tagsDateType') {
                    if (
                      location.tagsDateType !== undefined &&
                      location.tagsDateType.length !== 0
                    ) {
                      location.tagsDateType.forEach((tagx) => {
                        category.filters.tags.forEach((tagy) => {
                          tagy.tags.forEach((tagp) => {
                            if (tagx === tagp) {
                              const status =
                                promotedLocationIds.includes(
                                  location.locationId,
                                );
                              if (status === false) {
                                promotedLocationIds.push(
                                  location.locationId,
                                );
                                promotedLocations.push(location);
                              }
                            }
                          });
                        });
                      });
                    }
                  } else if (tagGroup.tagType === 'tagsCuisine') {
                    if (
                      location.tagsCuisine !== undefined &&
                      location.tagsCuisine.length !== 0
                    ) {
                      location.tagsCuisine.forEach((tagx) => {
                        category.filters.tags.forEach((tagy) => {
                          tagy.tags.forEach((tagp) => {
                            if (tagx === tagp) {
                              const status =
                                promotedLocationIds.includes(
                                  location.locationId,
                                );
                              if (status === false) {
                                promotedLocationIds.push(
                                  location.locationId,
                                );
                                promotedLocations.push(location);
                              }
                            }
                          });
                        });
                      });
                    }
                  } else if (tagGroup.sid === 'genre') {
                    if (
                      location.tagsGenre !== undefined &&
                      location.tagsGenre.length !== 0
                    ) {
                      location.tagsGenre.forEach((tagx) => {
                        category.filters.tags.forEach((tagy) => {
                          tagy.tags.forEach((tagp) => {
                            if (tagx === tagp) {
                              const status =
                                promotedLocationIds.includes(
                                  location.eventId !== undefined
                                    ? location.eventId
                                    : location.entertainmentId,
                                );
                              if (status === false) {
                                promotedLocationIds.push(
                                  location.eventId !== undefined
                                    ? location.eventId
                                    : location.entertainmentId,
                                );

                                promotedLocations.push(location);
                              }
                            }
                          });
                        });
                      });
                    }
                  } else if (
                    tagGroup.tagType === 'localEntertainer'
                  ) {
                    location.entertainment.entertainmentIds.forEach(
                      (id) => {
                        let status = false;
                        const entertainmentDetails = query('id')
                          .is(id)
                          .on(entertainment);
                        let entertainer = entertainmentDetails[0];

                        if (
                          entertainer !== undefined &&
                          entertainer.settings !== undefined &&
                          entertainer.settings.local !== undefined &&
                          entertainer.settings.local === true
                        ) {
                          status = promotedLocationIds.includes(
                            location.eventId,
                          );
                          if (status === false) {
                            promotedLocationIds.push(
                              location.eventId,
                            );
                            promotedLocations.push(location);
                          }
                        }
                      },
                    );
                  } else if (tagGroup.tagType === 'tagsEventType') {
                    if (
                      location.tagsEventType !== undefined &&
                      location.tagsEventType.length !== 0
                    ) {
                      location.tagsEventType.forEach((tagx) => {
                        category.filters.tags.forEach((tagy) => {
                          tagy.tags.forEach((tagp) => {
                            if (tagx === tagp) {
                              const status =
                                promotedLocationIds.includes(
                                  location.eventId,
                                );
                              if (status === false) {
                                promotedLocationIds.push(
                                  location.eventId,
                                );

                                var todayDate = moment(
                                  new Date(),
                                ).format('MM/DD/YYYY');

                                var pastDate = moment(
                                  location.scheduledTime.dates
                                    .endDate,
                                );

                                var dDiff = pastDate.diff(todayDate);

                                if (dDiff > 0 && dDiff !== 0) {
                                  promotedLocations.push(location);
                                } else if (dDiff === 0) {
                                  promotedLocations.push(location);
                                }
                              }
                            }
                          });
                        });
                      });
                    }
                  } else if (
                    tagGroup.tagType === 'tagsSettings' ||
                    tagGroup.tagType === 'tagsSetting'
                  ) {
                    if (
                      location.tagsSettings !== undefined &&
                      location.tagsSettings.length !== 0
                    ) {
                      location.tagsSettings.forEach((tagx) => {
                        category.filters.tags.forEach((tagy) => {
                          tagy.tags.forEach((tagp) => {
                            if (tagx === tagp) {
                              const status =
                                promotedLocationIds.includes(
                                  location.locationId,
                                );
                              if (status === false) {
                                promotedLocationIds.push(
                                  location.locationId,
                                );
                                promotedLocations.push(location);
                              }
                            }
                          });
                        });
                      });
                    }
                    if (
                      location.tagsSetting !== undefined &&
                      location.tagsSetting.length !== 0
                    ) {
                      location.tagsSetting.forEach((tagx) => {
                        category.filters.tags.forEach((tagy) => {
                          tagy.tags.forEach((tagp) => {
                            if (tagx === tagp) {
                              const status =
                                promotedLocationIds.includes(
                                  location.locationId,
                                );
                              if (status === false) {
                                promotedLocationIds.push(
                                  location.locationId,
                                );
                                promotedLocations.push(location);
                              }
                            }
                          });
                        });
                      });
                    }
                  } else if (
                    tagGroup.tagType === 'tagsLocationType'
                  ) {
                    if (
                      location.tagsLocationType !== undefined &&
                      location.tagsLocationType.length !== 0
                    ) {
                      location.tagsLocationType.forEach((tagx) => {
                        category.filters.tags.forEach((tagy) => {
                          tagy.tags.forEach((tagp) => {
                            if (tagx === tagp) {
                              const status =
                                promotedLocationIds.includes(
                                  location.locationId,
                                );
                              if (status === false) {
                                promotedLocationIds.push(
                                  location.locationId,
                                );

                                promotedLocations.push(location);
                              }
                            }
                          });
                        });
                      });
                    }
                  } else if (tagGroup.tagType === 'topMusic') {
                    promotedLocations.push(location);
                  }
                });
              }
            }
          });
        } else {
          locations.forEach((location) => {
            let dateCheck = false;
            if (
              location.eventId === undefined &&
              customEvent !== undefined &&
              customEvent.event.scheduledTime !== undefined &&
              customEvent.event.scheduledTime.date !== '' &&
              customEvent.event.scheduledTime.date !== null &&
              customEvent.event.scheduledTime.checkWeek === false &&
              customEvent.event.scheduledTime.checkMonth === false
            ) {
              let day = moment(customEvent.event.scheduledTime.date)
                .format('dddd')
                .toLowerCase();

              if (day === 'saturday') {
                day = 'saterday';
              }
              let dayString = day + 'Open';
              if (
                location.hours[day][dayString] !== undefined &&
                location.hours[day][dayString] === false
              ) {
                dateCheck = true;
              }
            } else if (
              location.eventId === undefined &&
              customEvent !== undefined &&
              customEvent.event.scheduledTime !== undefined &&
              customEvent.event.scheduledTime.date !== '' &&
              customEvent.event.scheduledTime.date !== null &&
              customEvent.event.scheduledTime.checkWeek ===
                undefined &&
              customEvent.event.scheduledTime.checkMonth === undefined
            ) {
              let day = moment(customEvent.event.scheduledTime.date)
                .format('dddd')
                .toLowerCase();
              if (day === 'saturday') {
                day = 'saterday';
              }
              let dayString = day + 'Open';

              if (
                dayString !== 'invalid dateOpen' &&
                location.hours[day][dayString] !== undefined &&
                location.hours[day][dayString] === true
              ) {
                dateCheck = true;
              }

              // else if (dayString !== 'invalid dateOpen') {
              //   if (
              //     location.locationTitle === "Adriana's on the Hill"
              //   ) {
              //
              //   }
              //   dateCheck = true;
              // }
            } else {
              dateCheck = true;
            }

            let status = locationIds.includes(location.locationId);

            if (status !== true && dateCheck === true) {
              // Check if any required fields
              let exclude = true;

              if (required.length === 0) {
                exclude = false;
              }
              if (
                location.tagsCuisine !== undefined &&
                location.tagsCuisine.length !== 0
              ) {
                location.tagsCuisine.forEach((tagx) => {
                  let status = required.includes(tagx);
                  if (status === true) {
                    exclude = false;
                  }
                });
              }
              if (
                location.tagsTrevelTags !== undefined &&
                location.tagsTrevelTags.length !== 0
              ) {
                location.tagsTrevelTags.forEach((tagx) => {
                  let status = required.includes(tagx);
                  if (status === true) {
                    exclude = false;
                  }
                });
              }
              if (
                location.tagsBeverages !== undefined &&
                location.tagsBeverages.length !== 0
              ) {
                location.tagsBeverages.forEach((tagx) => {
                  let status = required.includes(tagx);
                  if (status === true) {
                    exclude = false;
                  }
                });
              }
              if (
                location.tagsDateType !== undefined &&
                location.tagsDateType.length !== 0
              ) {
                location.tagsDateType.forEach((tagx) => {
                  let status = required.includes(tagx);
                  if (status === true) {
                    exclude = false;
                  }
                });
              }

              if (exclude === false) {
                if (taRequest.startLocation !== 'current') {
                  if (category.filters.ignoreRegionFilters !== true) {
                    if (location.address.region !== undefined) {
                      if (
                        location.address.region !==
                        taRequest.startLocation
                      ) {
                        exclude = true;
                        let matchedOne = false;
                        if (nearbyRegions !== undefined) {
                          nearbyRegions.forEach((region) => {
                            if (region.name !== undefined) {
                              if (
                                location.address.region ===
                                region.name
                              ) {
                                matchedOne = true;
                              }
                            } else {
                              if (
                                location.address.region === region
                              ) {
                                matchedOne = true;
                              }
                            }
                          });
                          if (matchedOne === true) {
                            exclude = false;
                          }
                        }
                      }
                    }
                  }
                }
              }

              if (exclude === false) {
                promotedLocations.push(location);
              }
            }
          });
        }
      }

      // If specific location is required, check
      let regionalLocations = [];
      if (
        category.filters.cities !== undefined &&
        category.filters.cities.length !== 0 &&
        category.filters.cities[0] !== ''
      ) {
        promotedLocations.forEach((location) => {
          category.filters.cities.forEach((city) => {
            let status = location.address.city === city;
            if (status === true) {
              regionalLocations.push(location);
            }
          });
        });
        return regionalLocations;
      } else if (
        category.filters.zips !== undefined &&
        category.filters.zips.length !== 0 &&
        category.filters.zips[0] !== ''
      ) {
        promotedLocations.forEach((location) => {
          category.filters.zips.forEach((zip) => {
            let status = location.address.zip === zip;
            if (status === true) {
              regionalLocations.push(location);
            }
          });
        });
        return regionalLocations;
      } else {
        return promotedLocations;
      }
    }

    let promotedLocations = [];
    let i = 0;

    if (category.type === 'Locations') {
      while (promotedLocations.length === 0 && i !== max) {
        promotedLocations = getPromoted(
          this.props.category,
          sortedLocations,
          max,
          this.props.type,
          this.props.taRequest.tagFilters,
          this.props.taRequest,
          this.props.nearbyRegions,
          this.props.customEvent,
        );
        i++;
      }
    } else if (
      category.type === 'Entertainers' ||
      category.type === 'Events'
    ) {
      promotedLocations = getPromoted(
        this.props.category,
        sortedLocations,
        max,
        this.props.type,
        this.props.taRequest.tagFilters,
        this.props.taRequest,
        this.props.nearbyRegions,
        this.props.customEvent,
      );
    } else {
      promotedLocations = locations;
    }

    // if (category.title === 'Top Entertainment Picks') {
    //   console.log(
    //     'top entertainment promotedLocations',
    //     promotedLocations,
    //   );
    // }

    function shuffleArray(array, category) {
      let topFive = [];
      let topFiveRegion = [];
      let rest = [];

      array.forEach((location, x) => {
        if (x < 6) {
          if (location.bucket === 1 || location.bucket === 3) {
            topFiveRegion.push(location);
          } else {
            topFive.push(location);
          }
        } else {
          rest.push(location);
        }
      });

      if (
        category !== undefined &&
        category.title !== 'Top Picks' &&
        category.title !== 'Top Entertainment Picks'
      ) {
        let i = topFive.length - 1;
        for (; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const temp = topFive[i];
          topFive[i] = topFive[j];
          topFive[j] = temp;
        }
      }

      topFive.forEach((location, x) => {
        topFiveRegion.push(location);
      });

      rest.forEach((location, x) => {
        topFiveRegion.push(location);
      });

      return topFiveRegion;
    }

    // Randdomizer Setup
    let shuffledLocations = promotedLocations;

    if (
      this.props.randomizer !== undefined &&
      this.props.randomizer === null
    ) {
      shuffledLocations = shuffleArray(promotedLocations, category);
      if (this.props.setRandomizer !== undefined) {
        this.props.setRandomizer(
          shuffledLocations,
          this.props.category.categoryId,
        );
      }
    } else if (this.props.taRequest.reroll === true) {
      shuffledLocations = shuffleArray(promotedLocations, category);
      if (this.props.setRandomizer !== undefined) {
        this.props.setRandomizer(
          shuffledLocations,
          this.props.category.categoryId,
        );
      }
      let bundle = {
        tagFilters: this.props.taRequest.tagFilters,
        distance: this.props.taRequest.distance,
        startLocation: this.props.taRequest.startLocation,
        eventCats: this.props.taRequest.eventCats,
        currentLatitude: this.props.taRequest.currentLatitude,
        currentLongitude: this.props.taRequest.currentLongitude,
        closestRegion: this.props.taRequest.closestRegion,
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
        reroll: false,
      };
      this.props.getTaRequest(bundle);
    } else if (this.props.randomizer === undefined) {
      shuffledLocations = shuffleArray(promotedLocations);
    } else {
      // shuffledLocations = this.props.randomizer;
    }

    let topThree = [];
    let topThreeIds = [];

    let type = 'Locations';

    let includeLocations = false;
    if (category.types !== undefined) {
      includeLocations = category.types.includes('Location');
    } else if (category.type === 'Locations') {
      includeLocations = true;
    }

    let includeEvents = false;
    if (category.types !== undefined) {
      includeEvents = category.types.includes('Events');
      if (includeEvents === true) {
        type = 'Events';
      }
    } else if (category.type === 'Events') {
      includeEvents = true;
    }

    let includeEntertainers = false;
    if (category.types !== undefined) {
      includeEntertainers = category.types.includes('Entertainers');
    } else if (category.type === 'Entertainers') {
      includeEntertainers = true;
    }

    shuffledLocations.forEach((location) => {
      if (this.props.catTitle === 'Jazz') {
      }
      // ATTENTION!!!!!!!!  do we need this max limit?
      if (topThree.length !== 500) {
        if (includeEvents === true || includeEntertainers === true) {
          const status = topThreeIds.includes(
            location.eventId !== undefined
              ? location.eventId
              : location.entertainmentId,
          );
          if (status === false) {
            topThreeIds.push(
              location.eventId !== undefined
                ? location.eventId
                : location.entertainmentId,
            );
            topThree.push(location);
          }
        } else {
          const status = topThreeIds.includes(location.locationId);
          if (status === false) {
            topThreeIds.push(location.locationId);
            topThree.push(location);
          }
        }
      }
    });

    if (category.filters !== undefined) {
      this.props.category.filters.tags.forEach((tagGroup) => {
        if (tagGroup.tagType === 'topMusic') {
          topThree = promotedLocations;
        }
      });
    }

    let seeMore = false;
    // if (shuffledLocations.length > max) {
    //   seeMore = true;
    // }
    let bundle = {
      promotedLocations: topThree,
      setTag,
      seeMore,
    };

    // if (category.title === 'Top Entertainment Picks') {
    //   console.log('bundlex', bundle, category);
    // }

    return bundle;
  }

  getTagCount(userLove) {
    let userTags = [];
    let tagsBeverages = {
      parent: 'Beverages',
      sid: 'beverages',
      tags: [],
    };
    let tagsSetting = {
      parent: 'Setting',
      sid: 'setting',
      tags: [],
    };
    let tagsDateType = {
      parent: 'Date Type',
      sid: 'dateType',
      tags: [],
    };
    let tagsCuisine = {
      parent: 'Cuisine Type',
      sid: 'cuisineType',
      tags: [],
    };
    let allTags = {
      tagsBeverages: [],
      tagsDateType: [],
      tagsSetting: [],
      tagsCuisine: [],
    };
    // Combine Tags

    userLove.tagsBeverages.forEach((tagcat, x) => {
      let n = allTags.tagsBeverages.includes(tagcat);
      if (n === false) {
        allTags.tagsBeverages.push(tagcat);
      }
    });

    userLove.tagsDateType.forEach((tagcat, x) => {
      let n = allTags.tagsDateType.includes(tagcat);
      if (n === false) {
        allTags.tagsDateType.push(tagcat);
      }
    });

    userLove.tagsSetting.forEach((tagcat, x) => {
      let n = allTags.tagsSetting.includes(tagcat);
      if (n === false) {
        allTags.tagsSetting.push(tagcat);
      }
    });

    userLove.tagsCuisine.forEach((tagcat, x) => {
      let n = allTags.tagsCuisine.includes(tagcat);
      if (n === false) {
        allTags.tagsCuisine.push(tagcat);
      }
    });

    let tagCount =
      allTags.tagsCuisine.length +
      allTags.tagsSetting.length +
      allTags.tagsDateType.length +
      allTags.tagsBeverages.length;
    allTags.tagsSetting;

    return tagCount;
  }

  getImage(type, location) {
    let hero = '';
    let thumb = '';
    if (this.props.type === 'Events') {
      thumb = location.eventThumb;
      hero = location.eventHero;
    } else if (this.props.type === 'Entertainers') {
      hero = location.entertainmentHero;
      thumb = location.entertainmentThumb;
    } else {
      hero = location.locationHero;
      thumb = location.locationThumb;
    }
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = hero;
      } else {
        if (location !== undefined && hero !== undefined) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            hero +
            '?alt=media';
        }
      }
      if (this.props.type === 'Events' && hero === '') {
        path =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2Feat_hero.jpg?alt=media';
      }
    } else if (type === 'thumb') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = thumb;
      } else {
        if (location !== undefined && thumb !== undefined) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            thumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  getSingle(promotedLocations) {
    let location = promotedLocations[0];
    if (promotedLocations.length === 1) {
      return (
        <div>
          <Link
            to={{
              pathname: '/location/' + location.locationId,
              state: {
                location: { location },
                // eventId: { eventId },
                // token: { token },
                currentUrl: window.location.pathname,
                type: 500,
              },
            }}
          >
            <div>
              <img
                src={this.getImage('hero', location)}
                style={{ width: '100%' }}
              />
              <div className="section">
                <span className="title">
                  {location.locationTitle}
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: location.locationDescription,
                  }}
                  className="editoOverRide"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  getCount(locations) {
    let start = 0;
    if (locations.length > 2) {
      start = 1;
    }
    return start;
  }

  getDate(event) {
    let selectedStart = {};
    let selectedEnd = {};
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.startTimestamp !== ''
    ) {
      selectedStart = moment(
        event.scheduledTime.dates.startTimestamp,
      );
    }
    let start = selectedStart._d.toLocaleDateString();
    if (
      event.scheduledTime !== undefined &&
      event.scheduledTime.dates.endTimestamp !== ''
    ) {
      selectedEnd = moment(event.scheduledTime.dates.endTimestamp);
    }
    let end = selectedEnd._d.toLocaleDateString();
    if (start === end) {
      return start;
    } else {
      return start + ' - ' + end;
    }
  }

  getWhen(event) {
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 'tu' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];

    let days = [];

    if (event.scheduledTime.dates.repeatDays !== undefined) {
      event.scheduledTime.dates.repeatDays.forEach((day) => {
        let eventDetailsx = query('short').is(day).on(dayArray);
        let eventx = eventDetailsx[0];
        days.push(eventx.full);
      });
    }

    let dayText = '';
    days.forEach((day, index) => {
      if (days.length === 1) {
        dayText = day;
      } else if (days.length === 7) {
        dayText = 'Day';
      } else if (days.length === 2) {
        if (index === 0) {
          dayText = day;
        } else {
          dayText = dayText + ' & ' + day;
        }
      } else if (
        days.length > 2 &&
        event.scheduledTime.dates.timesSelected > 1
      ) {
        if (index === 0) {
          dayText = day + 's';
        } else if (days.length - 1 === index) {
          dayText = dayText + ', & ' + day + 's';
        } else {
          dayText = dayText + ',  ' + day + 's';
        }
      } else if (
        days.length > 2 &&
        event.scheduledTime.dates.timesSelected === 1
      ) {
        if (index === 0) {
          dayText = day;
        } else if (days.length - 1 === index) {
          dayText = dayText + ', & ' + day;
        } else {
          dayText = dayText + ',  ' + day;
        }
      }
    });

    let weekly = '';
    if (event.scheduledTime.dates.timesSelected === 1) {
      weekly = 'week';
    } else {
      weekly = event.scheduledTime.dates.timesSelected + ' weeks ';
    }
    let statement = '';
    if (event.scheduledTime.dates.timesSelected === 1) {
      if (event.scheduledTime.dates.repeatType === 'Weekly') {
        statement = 'Every ' + dayText;
      }
    } else {
      if (event.scheduledTime.dates.repeatType === 'Weekly') {
        statement = 'Every ' + weekly + ' on ' + dayText;
      }
    }

    return statement;
  }

  getLocal(event) {
    let local = false;
    event.entertainment.entertainmentIds.forEach(
      (entertainmentId, index) => {
        const entertainmentDetails = query('id')
          .is(entertainmentId)
          .on(this.props.entertainment);
        let entertainer = entertainmentDetails[0];
        if (
          entertainer !== undefined &&
          entertainer.settings.local !== undefined &&
          entertainer.settings.local === true
        ) {
          local = true;
        }
      },
    );
    if (local === true) {
      return <span className="mainTagLeft local">Local</span>;
    }
  }

  getHeadliners(event) {
    let headliners = '';
    event.entertainment.headliners.forEach((entertainment, index) => {
      headliners = headliners + entertainment.entertainmentTitle;
      if (index + 1 !== event.entertainment.headliners.length) {
        headliners = headliners + ' & ';
      }
    });
    return headliners;
  }

  getSlideSections(promotedLocations, count, fullScreen, promo) {
    let sections = [];
    let sectionOne = [];
    let sectionTwo = [];
    let sectionThree = [];
    let sectionFour = [];
    let sectionFive = [];
    let sectionSix = [];
    let sectionSeven = [];
    let sectionEight = [];
    let sectionNine = [];
    let sectionTen = [];

    let shuffledSection = [];
    if (count === 4) {
      promotedLocations.forEach((location, index) => {
        if (index < 3 || index === 3) {
          sectionOne.push(location);
        }
        if (index > 3 && index < 8) {
          sectionTwo.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        shuffledSection = sectionOne;
        if (
          this.props.category.title !== 'Top Picks' &&
          this.props.category.title !== 'Top Entertainment Picks'
        ) {
          if (
            this.props.randomizer !== undefined &&
            this.props.randomizer === null
          ) {
            if (
              this.props.category.title !== 'Top Picks' &&
              this.props.category.title !== 'Dinner and a Show' &&
              this.props.category.title !==
                'Top Music Recommendations' &&
              this.props.category.title !== 'Top Entertainment Picks'
            ) {
              shuffledSection = sectionOne.sort(
                () => Math.random() - 0.5,
              );
            }
          }
        }
        sections.push(shuffledSection);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
    }

    if (count === 3) {
      promotedLocations.forEach((location, index) => {
        if (index < 2 || index === 2) {
          sectionOne.push(location);
        }
        if (index > 2 && index < 6) {
          sectionTwo.push(location);
        }
        if (index > 6 && index < 9) {
          sectionThree.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        shuffledSection = sectionOne;
        if (
          this.props.randomizer !== undefined &&
          this.props.randomizer === null
        ) {
          if (this.props.category.title !== 'Top Picks') {
            shuffledSection = sectionOne.sort(
              () => Math.random() - 0.5,
            );
          }
        }
        sections.push(shuffledSection);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
    }

    if (count === 2) {
      promotedLocations.forEach((location, index) => {
        if (this.props.category.title === 'Top Picks') {
          if (index === 0) {
            sectionOne.push(location);
          }
          if (index === 1) {
            sectionTwo.push(location);
          }
          if (index === 2) {
            sectionThree.push(location);
          }
          if (index === 3) {
            sectionFour.push(location);
          }
          if (index === 4) {
            sectionFive.push(location);
          }
        } else {
          if (index === 2) {
            sectionOne.push(location);
          }
          if (index === 0) {
            sectionTwo.push(location);
          }
          if (index === 1) {
            sectionThree.push(location);
          }
          if (index === 4) {
            sectionFour.push(location);
          }
          if (index === 3) {
            sectionFive.push(location);
          }
        }

        if (index === 5) {
          sectionSix.push(location);
        }
        if (index === 6) {
          sectionSeven.push(location);
        }
        if (index === 7) {
          sectionEight.push(location);
        }
        if (index === 8) {
          sectionNine.push(location);
        }
        if (index === 9) {
          sectionTen.push(location);
        }
      });
      if (sectionOne.length !== 0) {
        sections.push(sectionOne);
      }
      if (sectionTwo.length !== 0) {
        sections.push(sectionTwo);
      }
      if (sectionThree.length !== 0) {
        sections.push(sectionThree);
      }
      if (sectionFour.length !== 0) {
        sections.push(sectionFour);
      }
      if (sectionFive.length !== 0) {
        sections.push(sectionFive);
      }
      if (sectionSix.length !== 0) {
        sections.push(sectionSix);
      }
      if (sectionEight.length !== 0) {
        sections.push(sectionEight);
      }
      if (sectionSeven.length !== 0) {
        sections.push(sectionSeven);
      }
      if (sectionNine.length !== 0) {
        sections.push(sectionNine);
      }
      if (sectionTen.length !== 0) {
        sections.push(sectionTen);
      }
    }
    // if (this.props.tagCat === 'topMusic') {
    // }

    if (fullScreen === true) {
      if (sections[0].length === 1) {
        let newSections = [];
        let newObject = {
          slot: '9789677108',
          client: 'ca-pub-4916458608267365',
        };
        sections.forEach((section, index) => {
          if (index === 0) {
            newSections.push(section);
            newSections.push(newObject);
          } else {
            newSections.push(section);
          }
        });
        // sections = newSections;
      }
    }

    // Get the current Selected Filters
    let plusTagNames = [];
    let plusTags = [];
    let selectedTags = [];
    this.props.taRequest.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        tag.plus.forEach((tagx) => {
          if (tag.checked === true) {
            let status = plusTagNames.includes(tagx.tag);
            if (status === false) {
              plusTags.push(tagx);
              plusTagNames.push(tagx.tag);
            }
            selectedTags.push(tag.tag);
          }
        });
      });
    });

    let back = window.location.pathname;
    if (
      this.props.includeBoth !== undefined &&
      this.props.includeBoth === true
    ) {
      return sections.map((section, index) => {
        return (
          <div key={index + 1}>
            {section.map((location, index) => (
              <div
                style={{
                  border: '0px solid rgba(0, 0, 0, 0.125)',
                  background: 'white !important',
                }}
                className={count === 2 ? 'singleSlide' : 'multiSlide'}
              >
                {this.props.editMode === false &&
                  this.props.category.type !== 'Entertainers' && (
                    <div
                      className={
                        count === 2
                          ? 'guideAddButtonMobile'
                          : 'guideAddButton'
                      }
                    >
                      <a
                        className="guideAddButtonLink"
                        onClick={() => {
                          this.props.addEntertainment(
                            this.props.customEvent,
                            location,
                          );
                        }}
                      >
                        <span>
                          <MaterialIcon
                            icon="add"
                            size={30}
                            color="white"
                          />
                        </span>
                      </a>
                    </div>
                  )}
                <Link
                  to={{
                    pathname: '/timedevent/' + location.eventId,
                    state: {
                      location: { location },
                      // eventId: { eventId },
                      // token: { token },
                      back: { back },
                      type: 500,
                      currentUrl: window.location.pathname,
                    },
                  }}
                >
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <CardImg
                        top
                        width="100%"
                        src={this.getImage('thumb', location)}
                        alt="articleHero"
                      />
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <CardImg
                        top
                        width="100%"
                        src={this.getImage('thumb', location)}
                        alt="articleHero"
                      />
                    </Col>
                  </Row>
                  {/* <CardImg
                    top
                    width="100%"
                    src={this.getImage('hero', location)}
                    alt="articleHero"
                  /> */}
                  <CardBody style={{ textAlign: 'left' }}>
                    <div
                      style={{ paddingTop: '10px', border: '0px' }}
                    >
                      <span className="title">
                        {location.eventTitle}
                      </span>
                      {location.entertainment.headliners.length !==
                      0 ? (
                        <span
                          className="address"
                          style={{ fontWeight: '500' }}
                        >
                          {this.getHeadliners(location)} - @{' '}
                          {location.address.locationName}
                        </span>
                      ) : (
                        <span
                          className="address"
                          style={{ fontWeight: '500' }}
                        >
                          {location.address.locationName}
                        </span>
                      )}

                      {location.scheduledTime.dates.repeatType !==
                        undefined &&
                      location.scheduledTime.dates.repeatType ===
                        'Weekly' ? (
                        <span
                          className="address"
                          style={{ marginTop: '5px' }}
                        >
                          {this.getWhen(location)}
                        </span>
                      ) : (
                        <span
                          className="address"
                          style={{ marginTop: '5px' }}
                        >
                          {this.getDate(location)}
                        </span>
                      )}

                      <div
                        style={{
                          display: 'inline-block',
                          clear: 'both',
                        }}
                      >
                        {this.getLocal(location)}
                        {location.tagsGenre.map((location, index) =>
                          this.getGenreTag(location),
                        )}
                      </div>

                      {/* <small className="text-muted right">
                        {this.getPostDate(article.postdate)}
                      </small> */}

                      <CardText>
                        <span className="editoOverRide">
                          {this.truncate(location.eventDescription)}
                        </span>
                      </CardText>
                      <CardText />
                    </div>
                  </CardBody>
                </Link>
              </div>
            ))}
          </div>
        );
      });
    } else if (this.props.type === 'Locations') {
      let sectionCount = 0;

      sections.forEach((section, index) => {
        section.forEach((sectionx, index) => {
          sectionCount = sectionCount + 1;
        });
      });

      if (sectionCount !== 1) {
        return sections.map((section, index) => {
          if (section.slot !== undefined) {
            // return (
            //   <div
            //     style={{ height: '100%' }}
            //     key={this.props.category.categoryId + index + 'add'}
            //   >
            //     <div
            //       style={{
            //         border: '0px solid rgba(0, 0, 0, 0.125)',
            //         background: 'white !important',
            //       }}
            //       key={index + 'add'}
            //       className={count === 2 ? 'singleSlide' : 'multiSlide'}
            //     >
            //       <div>
            //         <CardBody style={{ textAlign: 'left' }}>
            //           <div
            //             style={{
            //               paddingTop: '10px',
            //               border: '0px',
            //             }}
            //           >
            //             hello
            //             <GoogleAd
            //               slot={section.slot}
            //               client={'ca-pub-4916458608267365'}
            //             />
            //           </div>
            //         </CardBody>
            //       </div>
            //     </div>
            //   </div>
            // );

            return (
              <div
                style={{ height: '100%' }}
                key={this.props.category.categoryId + index + 'b'}
              >
                <div
                  style={{
                    border: '0px solid rgba(0, 0, 0, 0.125)',
                    background: 'white !important',
                  }}
                  key={index + 'a'}
                  className={
                    count === 2 ? 'singleSlide' : 'multiSlide'
                  }
                >
                  <div>
                    <div
                      style={{
                        zindex: 9999,
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        display: 'inline-block',
                      }}
                    ></div>
                    <div
                      style={{
                        paddingTop: '10px',
                        border: '0px',
                      }}
                    >
                      <GoogleAd
                        slot={section.slot}
                        client={'ca-pub-4916458608267365'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div
                style={{ height: '100%' }}
                key={this.props.category.categoryId + index + 'b'}
              >
                {section.map((location, indexx) => (
                  <div
                    style={{
                      border: '0px solid rgba(0, 0, 0, 0.125)',
                      background: 'white !important',
                    }}
                    key={indexx + 'a'}
                    className={
                      count === 2 ? 'singleSlide' : 'multiSlide'
                    }
                  >
                    <div>
                      {this.props.editMode === false &&
                        this.props.category.type !==
                          'Entertainers' && (
                          <div
                            className={
                              count === 2
                                ? 'guideAddButtonMobile'
                                : 'guideAddButton'
                            }
                          >
                            <a
                              className="guideAddButtonLink"
                              onClick={() => {
                                this.props.addLocation(
                                  this.props.customEvent,
                                  location,
                                );
                              }}
                            >
                              <span>
                                <MaterialIcon
                                  icon="add"
                                  size={30}
                                  color="white"
                                />
                              </span>
                            </a>
                          </div>
                        )}

                      <Link
                        to={{
                          pathname:
                            '/location/' + location.locationId,
                          state: {
                            location: { location },
                            // eventId: { eventId },
                            // token: { token },
                            type: 500,
                            currentUrl: window.location.pathname,
                          },
                        }}
                      >
                        {sectionCount !== 1 ? (
                          <CardImg
                            top
                            width="100%"
                            src={this.getImage('hero', location)}
                            alt="articleHero"
                          />
                        ) : (
                          <CardImg
                            top
                            width="100%"
                            src={this.getImage('hero', location)}
                            alt="articleHero"
                          />
                        )}

                        <CardBody style={{ textAlign: 'left' }}>
                          <div
                            style={{
                              paddingTop: '10px',
                              border: '0px',
                            }}
                          >
                            <Col
                              xs={9}
                              sm={9}
                              md={9}
                              lg={9}
                              className="no-padding"
                            >
                              <span className="title">
                                {/* {location.locationTitle} -{' '}
                                {location.weight} */}
                                {location.locationTitle}
                              </span>
                            </Col>
                            <Col
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              className="no-padding"
                            >
                              {location.address.distance !==
                                undefined &&
                                this.props.taRequest.startlocation ===
                                  'current' && (
                                  <span className="distanceRight">
                                    {location.address.distance} mi.
                                  </span>
                                )}
                            </Col>
                            <div
                              style={{
                                display: 'flex',
                                clear: 'both',
                                padding: '5px 0px 5px 0px',
                              }}
                            >
                              {this.getMainTag(location)}
                            </div>
                            <CardText>
                              <span className="editoOverRide">
                                {this.truncate(
                                  location.locationDescription,
                                )}
                              </span>
                            </CardText>
                          </div>
                        </CardBody>
                        <div
                          style={{
                            paddingLeft: '5px',
                            // position: 'absolute',
                            // bottom: '10px',
                            minHeight: '40px',
                            paddingTop: '10px',
                          }}
                        >
                          {location.taNote !== undefined &&
                          this.props.editMode === false ? (
                            <div
                              style={{
                                display: 'block',

                                bottom: '10px',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  display: 'inline-block',
                                }}
                                className="left "
                              >
                                {location.taNote}
                              </span>
                              <div
                                style={{
                                  display: 'block',
                                  clear: 'both',
                                }}
                              >
                                {location.tagMatchSettings.matchedTags.map(
                                  (tag, index) => (
                                    <span className="matchedTag">
                                      {tag}
                                    </span>
                                  ),
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'block',
                                  clear: 'both',
                                }}
                              >
                                {location.tagMatchSettings.matchedTags
                                  .length === 0 &&
                                  location.tagMatchSettings.matchedPlusTags.map(
                                    (tag, index) => (
                                      <span className="matchedTag">
                                        {tag}
                                      </span>
                                    ),
                                  )}
                              </div>
                              {location.filteredGuestMatches
                                .length !== 0 && (
                                <div
                                  style={{
                                    display: 'block',
                                    clear: 'both',
                                    margin: '5px 0px 0px 0px',
                                    padding: '5px 0px 0px 0px',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      display: 'flex',
                                      clear: 'both',
                                      position: 'relative',
                                      left: '0px',
                                    }}
                                  >
                                    Your guests also likes:
                                  </span>
                                  {location.filteredGuestMatches.map(
                                    (tag, index) => (
                                      <span className="matchedTag">
                                        {tag}
                                      </span>
                                    ),
                                  )}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{
                                display: 'block',

                                bottom: '10px',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  display: 'inline-block',
                                }}
                                className="left "
                              >
                                {location.taNote}
                              </span>
                            </div>
                          )}
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            );
          }
        });
      } else {
        return sections.map((section, index) => {
          if (section.slot !== undefined) {
            // return (
            //   <div
            //     style={{ height: '100%' }}
            //     key={this.props.category.categoryId + index + 'add'}
            //   >
            //     <div
            //       style={{
            //         border: '0px solid rgba(0, 0, 0, 0.125)',
            //         background: 'white !important',
            //       }}
            //       key={index + 'add'}
            //       className={count === 2 ? 'singleSlide' : 'multiSlide'}
            //     >
            //       <div>
            //         <CardBody style={{ textAlign: 'left' }}>
            //           <div
            //             style={{
            //               paddingTop: '10px',
            //               border: '0px',
            //             }}
            //           >
            //             hello
            //             <GoogleAd
            //               slot={section.slot}
            //               client={'ca-pub-4916458608267365'}
            //             />
            //           </div>
            //         </CardBody>
            //       </div>
            //     </div>
            //   </div>
            // );

            return (
              <div
                style={{ height: '100%' }}
                key={this.props.category.categoryId + index + 'b'}
              >
                <div
                  style={{
                    border: '0px solid rgba(0, 0, 0, 0.125)',
                    background: 'white !important',
                  }}
                  key={index + 'a'}
                  className={
                    promo === true ? 'singleSlide' : 'multiSlide'
                  }
                >
                  <div>
                    <div
                      style={{
                        zindex: 9999,
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        display: 'inline-block',
                      }}
                    ></div>
                    <div
                      style={{
                        paddingTop: '10px',
                        border: '0px',
                      }}
                    >
                      <GoogleAd
                        slot={section.slot}
                        client={'ca-pub-4916458608267365'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div
                style={{
                  height: '100%',
                  marginBottom: promo === true ? '20px' : '0px ',
                }}
                key={this.props.category.categoryId + index + 'b'}
              >
                {section.map((location, indexx) => (
                  <div
                    style={{
                      border:
                        promo === true
                          ? '2px solid black'
                          : '0px solid rgba(0, 0, 0, 0.125)',
                      background: 'white !important',
                      display: 'flex',
                      paddingBottom: '10px',
                    }}
                    key={indexx + 'a'}
                    className={
                      promo === true || count === 2
                        ? 'singleSlide'
                        : 'multiSlide'
                    }
                  >
                    <div style={{ width: '100%' }}>
                      {this.props.editMode === false &&
                        this.props.category.type !==
                          'Entertainers' && (
                          <div
                            className={
                              count === 2
                                ? 'guideAddButtonMobile'
                                : 'guideAddButton'
                            }
                          >
                            <a
                              className="guideAddButtonLink"
                              onClick={() => {
                                this.props.addLocation(
                                  this.props.customEvent,
                                  location,
                                );
                              }}
                            >
                              <span>
                                <MaterialIcon
                                  icon="add"
                                  size={30}
                                  color="white"
                                />
                              </span>
                            </a>
                          </div>
                        )}

                      <Link
                        to={{
                          pathname:
                            '/location/' + location.locationId,
                          state: {
                            location: { location },
                            // eventId: { eventId },
                            // token: { token },
                            type: 500,
                            currentUrl: window.location.pathname,
                          },
                        }}
                      >
                        {sectionCount !== 1 ? (
                          <CardImg
                            top
                            width="100%"
                            src={this.getImage('hero', location)}
                            alt="articleHero"
                          />
                        ) : (
                          <CardImg
                            top
                            width="100%"
                            src={this.getImage('hero', location)}
                            alt="articleHero"
                          />
                        )}

                        <CardBody style={{ textAlign: 'left' }}>
                          <div
                            style={{
                              paddingTop: '10px',
                              border: '0px',
                            }}
                          >
                            <Col
                              xs={9}
                              sm={9}
                              md={9}
                              lg={9}
                              className="no-padding"
                            >
                              <span
                                className="title"
                                style={{
                                  fontWeight:
                                    promo === true ? '600' : '400',
                                  fontSize:
                                    promo === true
                                      ? '20px'
                                      : '0  16px ',
                                }}
                              >
                                {/* {location.locationTitle} -{' '}
                                {location.weight} */}
                                {location.locationTitle}
                              </span>
                            </Col>
                            <Col
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              className="no-padding"
                            >
                              {location.address.distance !==
                                undefined &&
                                this.props.taRequest.startlocation ===
                                  'current' && (
                                  <span className="distanceRight">
                                    {location.address.distance} mi.
                                  </span>
                                )}
                            </Col>
                            <div
                              style={{
                                display: 'flex',
                                clear: 'both',
                                padding: '5px 0px 5px 0px',
                              }}
                            >
                              {this.getMainTag(location)}
                            </div>
                            <CardText>
                              <span className="editoOverRide">
                                {this.truncate(
                                  location.locationDescription,
                                )}
                              </span>
                            </CardText>
                          </div>
                          <div
                            style={{
                              // paddingLeft: '10px',
                              // position: 'absolute',
                              // bottom: '10px',
                              minHeight: '40px',
                              paddingTop: '10px',
                            }}
                          >
                            {location.taNote !== undefined &&
                              this.props.editMode === false && (
                                <div
                                  style={{
                                    display: 'block',

                                    bottom: '10px',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      display: 'inline-block',
                                    }}
                                    className="left "
                                  >
                                    {location.taNote}
                                  </span>
                                  <div
                                    style={{
                                      display: 'block',
                                      clear: 'both',
                                    }}
                                  >
                                    {location.tagMatchSettings.matchedTags.map(
                                      (tag, index) => (
                                        <span className="matchedTag">
                                          {tag}
                                        </span>
                                      ),
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: 'block',
                                      clear: 'both',
                                    }}
                                  >
                                    {location.tagMatchSettings
                                      .matchedTags.length === 0 &&
                                      location.tagMatchSettings.matchedPlusTags.map(
                                        (tag, index) => (
                                          <span className="matchedTag">
                                            {tag}
                                          </span>
                                        ),
                                      )}
                                  </div>
                                  {location.filteredGuestMatches
                                    .length !== 0 && (
                                    <div
                                      style={{
                                        display: 'block',
                                        clear: 'both',
                                        margin: '5px 0px 0px 0px',
                                        padding: '5px 0px 0px 0px',
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          display: 'flex',
                                          clear: 'both',
                                          position: 'relative',
                                          left: '0px',
                                        }}
                                      >
                                        Your guests also likes:
                                      </span>
                                      {location.filteredGuestMatches.map(
                                        (tag, index) => (
                                          <span className="matchedTag">
                                            {tag}
                                          </span>
                                        ),
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        </CardBody>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            );
          }
        });
      }
    } else if (this.props.type === 'Events') {
      return sections.map((section, index) => {
        return (
          <div key={index + 1}>
            {section.map((location, index) => (
              <div
                style={{
                  border: '0px solid rgba(0, 0, 0, 0.125)',
                  background: 'white !important',
                }}
                className={count === 2 ? 'singleSlide' : 'multiSlide'}
              >
                {this.props.editMode === false &&
                  this.props.category.type !== 'Entertainers' && (
                    <div
                      className={
                        count === 2
                          ? 'guideAddButtonMobile'
                          : 'guideAddButton'
                      }
                    >
                      <a
                        className="guideAddButtonLink"
                        onClick={() => {
                          this.props.addEntertainment(
                            this.props.customEvent,
                            location,
                          );
                        }}
                      >
                        <span>
                          <MaterialIcon
                            icon="add"
                            size={30}
                            color="white"
                          />
                        </span>
                      </a>
                    </div>
                  )}
                <Link
                  to={{
                    pathname: '/timedevent/' + location.eventId,
                    state: {
                      location: { location },
                      // eventId: { eventId },
                      // token: { token },
                      back: { back },
                      type: 500,
                      currentUrl: window.location.pathname,
                    },
                  }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={this.getImage('hero', location)}
                    alt="articleHero"
                  />
                  <CardBody style={{ textAlign: 'left' }}>
                    <div
                      style={{ paddingTop: '10px', border: '0px' }}
                    >
                      <span className="title">
                        {location.eventTitle}
                      </span>
                      {location.entertainment.headliners.length !==
                      0 ? (
                        <span
                          className="address"
                          style={{ fontWeight: '500' }}
                        >
                          {this.getHeadliners(location)} - @{' '}
                          {location.address.locationName}
                        </span>
                      ) : (
                        <span
                          className="address"
                          style={{ fontWeight: '500' }}
                        >
                          {location.address.locationName}
                        </span>
                      )}

                      {location.scheduledTime.dates.repeatType !==
                        undefined &&
                      location.scheduledTime.dates.repeatType ===
                        'Weekly' ? (
                        <span
                          className="address"
                          style={{ marginTop: '5px' }}
                        >
                          {this.getWhen(location)}
                        </span>
                      ) : (
                        <span
                          className="address"
                          style={{ marginTop: '5px' }}
                        >
                          {this.getDate(location)}
                        </span>
                      )}

                      <div
                        style={{
                          display: 'inline-block',
                          clear: 'both',
                        }}
                      >
                        {this.getLocal(location)}
                        {location.tagsEventType.map(
                          (location, index) =>
                            this.getEventTypeTag(location),
                        )}
                        {location.tagsGenre.map((location, index) =>
                          this.getGenreTag(location),
                        )}
                      </div>

                      {/* <small className="text-muted right">
                        {this.getPostDate(article.postdate)}
                      </small> */}

                      <CardText>
                        <span className="editoOverRide">
                          {this.truncate(location.eventDescription)}
                        </span>
                      </CardText>
                      <CardText />
                    </div>
                    {location.taNote !== undefined && (
                      <div
                        style={{
                          display: 'block',
                          position: 'relative',
                          clear: 'both',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          {this.props.newEvent.clear === false
                            ? location.taNote
                            : location.address.region}
                        </span>

                        {(this.props.profile.userId ===
                          'tBF716cwX9hCZi1NWFoCD6e4RhU2' ||
                          this.props.profile.userId ===
                            '4iPSmNoRXhNErKCzyDX5oq3ZxRm2' ||
                          this.props.profile.userId ===
                            '9VIHZPl5q9XXEZmASpeUoEvPnLc2') && (
                          <span
                            style={{
                              fontSize: '12px',
                              display: 'block',
                              color: 'black',
                              clear: 'both',
                            }}
                          >
                            Bucket: {location.bucket} Weight:{' '}
                            {location.weight}{' '}
                          </span>
                        )}
                      </div>
                    )}
                  </CardBody>
                  {/* {location.address.region !== undefined && (
                    <div
                      style={{
                        display: 'block',

                        bottom: '10px',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12px',
                          display: 'inline-block',
                        }}
                        className="left "
                      >
                        {location.address.region}
                      </span>
                    </div>
                  )} */}
                </Link>
              </div>
            ))}
          </div>
        );
      });
    } else if (this.props.type === 'Entertainers') {
      return sections.map((section, index) => {
        return (
          <div key={index + 1}>
            {section.map((location, index) => (
              <div
                style={{
                  border: '0px solid rgba(0, 0, 0, 0.125)',
                  background: 'white !important',
                }}
                className={count === 2 ? 'singleSlide' : 'multiSlide'}
              >
                {this.props.editMode === false &&
                  this.props.category.type !== 'Entertainers' && (
                    <div
                      className={
                        count === 2
                          ? 'guideAddButtonMobile'
                          : 'guideAddButton'
                      }
                    >
                      <a
                        className="guideAddButtonLink"
                        onClick={() => {
                          this.props.addEntertainment(
                            this.props.customEvent,
                            location,
                          );
                        }}
                      >
                        <span>
                          <MaterialIcon
                            icon="add"
                            size={30}
                            color="white"
                          />
                        </span>
                      </a>
                    </div>
                  )}
                <Link
                  to={{
                    pathname: '/entertainment/' + location.slug,
                    state: {
                      location: { location },
                      // eventId: { eventId },
                      // token: { token },
                      type: 500,
                      currentUrl: window.location.pathname,
                    },
                  }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={this.getImage('hero', location)}
                    alt="articleHero"
                  />
                  <CardBody style={{ textAlign: 'left' }}>
                    <div
                      style={{ paddingTop: '10px', border: '0px' }}
                    >
                      <span className="title">
                        {location.entertainmentTitle}
                      </span>
                      {/* <span className="address">
                        {this.getHeadliners(location)} - @{' '}
                        {location.address.locationName}
                      </span> */}

                      {/* <span className="address">
                        {this.getDate(location)}
                      </span> */}

                      <div
                        style={{
                          display: 'block',
                          clear: 'both',
                        }}
                      >
                        {/* {this.getLocal(location)} */}
                        {location.tagsGenre.map((location, index) =>
                          this.getGenreTag(location),
                        )}
                      </div>

                      {/* <small className="text-muted right">
      {this.getPostDate(article.postdate)}
    </small> */}
                      <div
                        style={{
                          display: 'block',
                          clear: 'both',
                        }}
                      >
                        <CardText>
                          <span className="editoOverRide">
                            {this.truncate(
                              location.entertainmentDescription,
                            )}
                          </span>
                        </CardText>
                      </div>
                      <CardText />
                    </div>
                  </CardBody>
                </Link>
              </div>
            ))}
          </div>
        );
      });
    }
  }

  getGenreTag(tag) {
    return (
      <span className="mainTagLeft" key={tag}>
        {tag}
      </span>
    );
  }

  getEventTypeTag(tag) {
    return (
      <span className="eventTypeTagLeft " key={tag}>
        {tag}
      </span>
    );
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return (
        <span className="mainTagLeft" key={location.tagsMain}>
          {location.tagsMain}
        </span>
      );
    }
  }

  truncate(str) {
    if (str !== undefined) {
      let regex = /(<([^>]+)>)/gi;
      let result = str.replace(regex, '');
      let final = result.replace(/&amp;/g, '&');
      return final.length > 100
        ? final.substring(0, 100) + '...'
        : final;
    } else {
      return '';
    }
  }

  sortAndWeight(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    let weightedItems = [];

    if (
      this.props.type === 'Events' &&
      this.props.weightMusic !== undefined
    ) {
      weightedItems = this.getWeightedMusic(
        this.props.timedEvents,
        this.props.profile,
      );

      // weightedItems = this.props.timedEvents;
    } else {
      // if (this.props.type === 'Events') {
      //   console.log(
      //     'pippin',
      //     this.props.catTitle,
      //     this.props.type,
      //     this.props.weightMusic,
      //   );
      // }
      let sortedLocations = this.sortLocations(
        locations,
        100,
        currentLongitude,
        currentLatitude,
        sortState,
      );

      weightedItems = this.getWeightedLocations(
        sortedLocations,
        this.props.profile,
      );
    }

    return weightedItems;
  }

  sortLocations(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    if (sortState === 1) {
      const locationsArray = [];
      locations.forEach((location) => {
        const miles = location.address.distance;

        if (location.type !== 1) {
          if (distanceFilterState === 50) {
            if (miles <= 50) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 10) {
            if (miles <= 10) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 100) {
            locationsArray.push(location);
          }
        }
      });

      return _.sortBy(locationsArray, (o) => o.weight);
    } else {
      return _.sortBy(locations, (o) => o.locationTitle);
    }
  }

  getWeightedMusic(timedEvents, user) {
    let otherUserTagsLove = this.state.otherUserTagsLove;
    let otherUserTagsHate = this.state.otherUserTagsHate;
    if (user.tags !== undefined) {
      let userTagsLove = user.tags.love;
      let userTagsHate = user.tags.hate;

      const weightedMusic = this.props.weightMusic(
        timedEvents,
        userTagsLove,
        userTagsHate,
        otherUserTagsLove,
        otherUserTagsHate,
        this.props.catTitle,
        'guidecategory',
      );

      return weightedMusic;
    }
  }

  getWeightedLocations(locations, user) {
    let weightedLocations = locations;
    if (this.props.user.authenticated === true) {
      let otherUserTagsLove = this.props.otherUserTagsLove;
      let otherUserTagsHate = this.props.otherUserTagsHate;

      if (user.tags !== undefined) {
        let userTagsLove = user.tags.love;

        let userTagsHate = user.tags.hate;
        weightedLocations = this.weightLocations(
          locations,
          userTagsLove,
          userTagsHate,
          otherUserTagsLove,
          otherUserTagsHate,
        );
      }
    }

    return weightedLocations;
  }

  weightLocations(
    locations,
    userTagsLove,
    userTagsHate,
    otherUserTagsLove,
    otherUserTagsHate,
  ) {
    let plusTags = [];
    let plusTagNames = [];
    let weightedLocations = [];
    let oneMile = [];
    let fiveMiles = [];
    let tenMiles = [];
    let moreMiles = [];

    // Get the current Selected Filters
    let selectedTags = [];
    this.props.taRequest.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        tag.plus.forEach((tagx) => {
          if (tag.checked === true) {
            let status = plusTagNames.includes(tagx.tag);
            if (status === false) {
              plusTags.push(tagx);
              plusTagNames.push(tagx.tag);
            }
            selectedTags.push(tag.tag);
          }
        });
      });
    });

    locations.forEach((location) => {
      let weightedLocation = location;
      let weight = 0;
      weightedLocation.matchesOtherUserLove = [];
      weightedLocation.matchesOtherUserHate = [];
      weightedLocation.matchesUserLove = [];
      weightedLocation.matchesUserHate = [];
      weightedLocation.weight = weight;

      location.tagMatchSettings = {
        requestedTagMatch: false,
        trevelTagMatch: false,
        matchedTags: [],
        matchedPlusTags: [],
        guestTags: [],
      };

      // Add Weight for having Main Tag they searched for
      const status = selectedTags.includes(location.tagsMain);
      if (status === true) {
        weight = weight + 10;
        location.tagMatchSettings.requestedTagMatch = true;
      }

      // Check Region
      if (location.address.region !== undefined) {
        if (
          this.props.taRequest.startLocation !== 'current' &&
          this.props.taRequest.startLocation !== 'lastLocation'
        ) {
          if (
            location.address.region ===
            this.props.taRequest.startLocation
          ) {
            location.weight = location.weight + 5;
          }
        }
      }

      // Check for Trevel Tag Match
      if (location.tagsTrevelTags !== undefined) {
        location.tagsTrevelTags.forEach((userTag) => {
          plusTags.forEach((tag) => {
            if (tag.tag === userTag) {
              location.tagMatchSettings.requestedTagMatch = true;
              weight = weight + tag.value;
            }
          });
          // const status = selectedTags.includes(userTag);
          // if (status === true) {
          //   weight = weight + 40;
          // }
        });
      }

      // Add Weight for tags the user and guest love

      // Other User Love
      if (otherUserTagsLove !== undefined) {
        if (location.tagsBeverages !== undefined) {
          location.tagsBeverages.forEach((userTag) => {
            otherUserTagsLove.tagsBeverages.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 2;
              }
            });
          });
        }
        if (location.tagsCuisine !== undefined) {
          location.tagsCuisine.forEach((userTag) => {
            otherUserTagsLove.tagsCuisine.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 2;
              }
            });
          });
        }
        if (location.tagsDateType !== undefined) {
          location.tagsDateType.forEach((userTag) => {
            otherUserTagsLove.tagsDateType.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 2;
              }
            });
          });
        }
        if (location.tagsSetting !== undefined) {
          location.tagsSetting.forEach((userTag) => {
            otherUserTagsLove.tagsSetting.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 2;
              }
            });
          });
        }
      }
      // Other User Hate
      if (otherUserTagsHate !== undefined) {
        if (location.tagsBeverages !== undefined) {
          location.tagsBeverages.forEach((userTag) => {
            otherUserTagsHate.tagsBeverages.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 1;
              }
            });
          });
        }
        if (location.tagsCuisine !== undefined) {
          location.tagsCuisine.forEach((userTag) => {
            otherUserTagsHate.tagsCuisine.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 1;
              }
            });
          });
        }
        // if (location.tagsDateType !== undefined) {
        //   location.tagsDateType.forEach((userTag) => {
        //     otherUserTagsHate.tagsDateType.forEach((tag) => {
        //       if (userTag === tag) {
        //         weightedLocation.matchesOtherUserHate.push(tag);
        //         weight = weight - 3;
        //       }
        //     });
        //   });
        // }
        if (location.tagsSetting !== undefined) {
          location.tagsSetting.forEach((userTag) => {
            otherUserTagsHate.tagsSetting.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 1;
              }
            });
          });
        }
      }

      // User Love
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((userTag) => {
          plusTags.forEach((tag) => {
            if (tag.tag === userTag) {
              location.tagMatchSettings.matchedPlusTags.push(userTag);
              weight = weight + tag.value;
            }
          });

          const status = selectedTags.includes(userTag);
          if (status === true) {
            location.tagMatchSettings.requestedTagMatch = true;
            location.tagMatchSettings.matchedTags.push(userTag);
            weight = weight + 5;
          }

          userTagsLove.tagsBeverages.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 1;
            }
          });
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((userTag) => {
          plusTags.forEach((tag) => {
            if (tag.tag === userTag) {
              location.tagMatchSettings.matchedPlusTags.push(userTag);
              weight = weight + tag.value;
            }
          });
          const status = selectedTags.includes(userTag);
          if (status === true) {
            location.tagMatchSettings.requestedTagMatch = true;
            location.tagMatchSettings.matchedTags.push(userTag);
            weight = weight + 5;
          }
          userTagsLove.tagsCuisine.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 1;
            }
          });
        });
      }
      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((userTag) => {
          plusTags.forEach((tag) => {
            if (tag.tag === userTag) {
              location.tagMatchSettings.matchedPlusTags.push(userTag);
              weight = weight + tag.value;
            }
          });
          const status = selectedTags.includes(userTag);
          if (status === true) {
            location.tagMatchSettings.requestedTagMatch = true;
            weight = weight + 5;
          }
          userTagsLove.tagsDateType.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              location.tagMatchSettings.matchedTags.push(userTag);
              weight = weight + 1;
            }
          });
        });
      }

      if (location.tagsSetting !== undefined) {
        location.tagsSetting.forEach((userTag) => {
          plusTags.forEach((tag) => {
            if (tag.tag === userTag) {
              weight = weight + tag.value;
            }
          });
          const status = selectedTags.includes(userTag);
          if (status === true) {
            location.tagMatchSettings.requestedTagMatch = true;
            weight = weight + 5;
          }
          userTagsLove.tagsSetting.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 1;
            }
          });
        });
      }
      // User Hate
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((userTag) => {
          userTagsHate.tagsBeverages.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 1;
            }
          });
        });
      }
      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((userTag) => {
          userTagsHate.tagsCuisine.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 1;
            }
          });
        });
      }
      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((userTag) => {
          userTagsHate.tagsDateType.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 1;
            }
          });
        });
      }
      if (location.tagsSetting !== undefined) {
        location.tagsSetting.forEach((userTag) => {
          userTagsHate.tagsSetting.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 1;
            }
          });
        });
      }

      weightedLocation.weight = weight;

      let filteredGuestMatches = [];
      weightedLocation.matchesOtherUserLove.forEach((userTag) => {
        if (selectedTags.includes(userTag) === false) {
          filteredGuestMatches.push(userTag);
        }
      });

      weightedLocation.filteredGuestMatches = filteredGuestMatches;
      // Account on Distance
      if (location.address.distance < 1.1) {
        if (weightedLocation.weight === 0) {
          moreMiles.push(weightedLocation);
        } else {
          oneMile.push(weightedLocation);
        }
      } else if (
        location.address.distance > 1.0 &&
        location.address.distance < 6
      ) {
        if (weightedLocation.weight === 0) {
          moreMiles.push(weightedLocation);
        }
        // } else if (weightedLocation.weight > 30) {
        //   oneMile.push(weightedLocation);
        // }
        else {
          fiveMiles.push(weightedLocation);
        }
      } else if (
        location.address.distance > 5.9 &&
        location.address.distance < 11
      ) {
        if (weightedLocation.weight === 0) {
          moreMiles.push(weightedLocation);
        } else if (weightedLocation.weight > 5) {
          fiveMiles.push(weightedLocation);
        } else {
          tenMiles.push(weightedLocation);
        }
      } else {
        if (weightedLocation.weight > 3) {
          tenMiles.push(weightedLocation);
        } else {
          moreMiles.push(weightedLocation);
        }
      }
    });

    // let sortedOneMiles = _.orderBy(
    //   oneMile,
    //   ['weight'],
    //   ['desc', 'asc'],
    // );

    // let sortedFiveMiles = _.orderBy(
    //   fiveMiles,
    //   ['weight'],
    //   ['desc', 'asc'],
    // );
    // let sortedTenMiles = _.orderBy(
    //   tenMiles,
    //   ['weight'],
    //   ['desc', 'asc'],
    // );

    // let sortedMoreMiles = _.orderBy(
    //   moreMiles,
    //   ['weight'],
    //   ['desc', 'asc'],
    // );

    // sortedOneMiles.forEach((location) => {
    //   weightedLocations.push(location);
    // });
    // sortedFiveMiles.forEach((location) => {
    //   weightedLocations.push(location);
    // });
    // sortedTenMiles.forEach((location) => {
    //   weightedLocations.push(location);
    // });
    // sortedMoreMiles.forEach((location) => {
    //   weightedLocations.push(location);
    // });

    // let sortedOneMiles = _.orderBy(
    //   oneMile,
    //   ['weight'],
    //   ['desc', 'asc'],
    // );

    // let sortedFiveMiles = _.orderBy(
    //   fiveMiles,
    //   ['weight'],
    //   ['desc', 'asc'],
    // );
    // let sortedTenMiles = _.orderBy(
    //   tenMiles,
    //   ['weight'],
    //   ['desc', 'asc'],
    // );

    // let sortedMoreMiles = _.orderBy(
    //   moreMiles,
    //   ['weight'],
    //   ['desc', 'asc'],
    // );

    // Add Weight for Distance
    oneMile.forEach((location) => {
      location.weight = location.weight + 5;
      weightedLocations.push(location);
    });
    fiveMiles.forEach((location) => {
      location.weight = location.weight + 2;
      weightedLocations.push(location);
    });
    tenMiles.forEach((location) => {
      location.weight = location.weight + 1;
      weightedLocations.push(location);
    });
    moreMiles.forEach((location) => {
      weightedLocations.push(location);
    });
    let sortedMoreMiles = _.orderBy(
      weightedLocations,
      ['weight'],
      ['desc', 'asc'],
    );

    if (this.props.catTitle === 'Top Picks') {
      let topPicks = this.topPickBuilder(
        sortedMoreMiles,
        this.props.taRequest.startLocation,
      );
      return topPicks;
    } else {
      let topPicks = this.topPickBuilder(
        sortedMoreMiles,
        this.props.taRequest.startLocation,
      );

      return topPicks;
    }
  }

  topPickBuilder(sortedMoreMiles, startLocation) {
    let region = startLocation;
    let num = 0;

    if (startLocation === 'lastLocation') {
      let xnum = this.props.customEvent.event.locations.length;
      if (xnum !== 0) {
        num = Number(xnum) - 1;
      }
      if (this.props.customEvent.event.locations[num] !== undefined) {
        region =
          this.props.customEvent.event.locations[num].address.region;
      }
    }

    if (startLocation === 'current') {
      region = this.props.taRequest.closestRegion;
    }

    let topPicks = [];

    let bucketOne = [];
    let bucketTwo = [];
    let bucketThree = [];
    let bucketFour = [];
    let bucketFive = [];
    let noRegion = [];

    let inRegionVenues = [];

    // this.props.timedEvents.forEach((event) => {
    //   if (event.address.tdId === 'fRTUdNz0yMQBz6jBj6Bh') {
    //     var todayDate = moment(new Date()).format('MM/DD/YYYY');
    //     var pastDate = moment(event.scheduledTime.dates.endDate);
    //     var dDiff = pastDate.diff(todayDate);
    //     if (dDiff > 0 && dDiff !== 0) {
    //       inRegionVenues.push(event);
    //     } else if (dDiff === 0) {
    //       inRegionVenues.push(event);
    //     }
    //   }
    // });

    // console.log('inRegionVenues', inRegionVenues);

    let nearbyRegions = [];
    this.props.regions.forEach((regionx) => {
      if (regionx.name === region) {
        nearbyRegions = regionx.nearbyRegions;
      }
    });

    let selectedTags = [];
    let plusTags = [];
    let plusTagNames = [];
    this.props.taRequest.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }

        tag.plus.forEach((tagx) => {
          if (tag.checked === true) {
            let status = plusTagNames.includes(tagx.tag);
            if (status === false) {
              plusTags.push(tagx);
              plusTagNames.push(tagx.tag);
            }
          }
        });
      });
    });

    sortedMoreMiles.forEach((location) => {
      if (location.eventId !== undefined) {
      }
      let matchedOne = false;

      // Check if location matches a nearby location
      if (nearbyRegions !== undefined) {
        nearbyRegions.forEach((regiony) => {
          if (location.address.region === regiony) {
            matchedOne = true;
          }
        });
      }

      // Temp fix for locations with no region
      if (location.address.region === '') {
        if (noRegion.includes(location.locationTitle) === false) {
          noRegion.push(location.locationTitle);
        }
        if (noRegion.includes(location.locationName) === false) {
          noRegion.push(location.locationName);
        }
      }

      if (location.tagMatchSettings.requestedTagMatch === true) {
        // Bucket 1: What you asked for, where you asked for it

        if (location.address.region === region) {
          location.taNote = 'Good Match In ' + region;
          location.bucket = 1;
          bucketOne.push(location);
        } else if (matchedOne === true) {
          // Bucket 2: What you asked for, in nearby regions
          bucketTwo.push(location);
        } else {
          // Bucket 3: What you asked for, outside region and nearby regions
          location.taNote = location.address.region;
          location.bucket = 3;
          // Limit BucketThree to only 3 places

          if (bucketThree.length !== 2) {
            bucketThree.push(location);
          }
          bucketThree.push(location);
        }
      } else {
        if (location.eventId !== undefined) {
          if (location.tagsEventType !== undefined) {
            location.tagsEventType.forEach((tag) => {
              if (selectedTags.includes(tag) === true) {
                // if (this.props.catTitle === 'Top Entertainment Picks') {
                //   console.log('bucketxx 1', tag);
                // }
                location.bucket = 1;
                matchedOne = true;
                bucketOne.push(location);
              } else {
                // if (this.props.catTitle === 'Top Entertainment Picks') {
                //   console.log('bucketxx 5a', tag);
                // }
                matchedOne = true;
                location.bucket = 5;
                bucketFive.push(location);
              }
            });
          }
        } else if (matchedOne === false) {
          // Bucket 4: What you asked for, in nearby regions
          if (location.address.region === region) {
            location.taNote = 'In ' + location.address.region;
            location.bucket = 4;
            bucketFour.push(location);
            // if (this.props.catTitle === 'Top Entertainment Picks') {
            //   console.log('bucketxx 4', tag);
            // }
          } else {
            if (location.address.region !== undefined) {
              location.taNote = location.address.region;
            }
            // if (this.props.catTitle === 'Top Entertainment Picks') {
            //   console.log('bucket 5xx', location);
            // }
            // if (this.props.catTitle === 'Top Entertainment Picks') {
            //   console.log('bucketxx 5b', tag);
            // }
            location.bucket = 5;
            bucketFive.push(location);
          }
        }
      }
    });

    // if (this.props.catTitle === 'Top Entertainment Picks') {
    //   console.log(
    //     'top entertainmen bucket 1',
    //     bucketOne,
    //     'bucket 2',
    //     bucketTwo,
    //     'bucket 3',
    //     bucketThree,
    //     'bucket 4',
    //     bucketFour,
    //     'bucket 5',
    //     bucketFive,
    //   );
    // }

    bucketOne.forEach((location) => {
      topPicks.push(location);
    });
    bucketTwo.forEach((location) => {
      topPicks.push(location);
    });
    bucketThree.forEach((location) => {
      topPicks.push(location);
    });

    bucketFour.forEach((location) => {
      topPicks.push(location);
    });
    bucketFive.forEach((location) => {
      topPicks.push(location);
    });
    // if (this.props.catTitle === 'Top Entertainment Picks') {
    //   console.log('top entertainmen topPicks', topPicks);
    // }

    return topPicks;
  }

  render() {
    // if (this.props.category.title === 'Top Entertainment Picks') {
    //   console.log('customEventxx', this.props.timedEvents);
    // }
    let bundle = [];
    if (
      this.props.includeBoth !== undefined &&
      this.props.includeBoth === true
    ) {
      // if (this.props.catTitle === 'Top Entertainment Picks') {
      //   console.log('eventsxxx both');
      // }
      bundle = this.findPromotedLocations(
        this.props.timedEvents,
        this.props.category,
      );

      // if (this.props.weightMusic !== undefined) {
      //   let weightedMusic = this.getWeightedMusic(
      //     this.props.timedEvents,
      //     this.props.profile,
      //   );

      //   bundle = {
      //     promotedLocations: weightedMusic,
      //     setTag: undefined,
      //     seeMore: false,
      //   };
      // }
    } else if (
      // this.props.tagCat === 'tagsGenre' ||
      // this.props.tagCat === 'tagsEventType' ||
      // this.props.tagCat === 'localEntertainer' ||
      // this.props.tagCat === 'topMusic'

      this.props.type !== undefined &&
      this.props.type === 'Events'
    ) {
      // if (this.props.catTitle === 'Top Entertainment Picks') {
      //   console.log('eventsxxx events');
      // }

      bundle = this.findPromotedLocations(
        this.props.timedEvents,
        this.props.category,
      );
      // if (this.props.catTitle === 'Top Entertainment Picks') {
      //   console.log('bundlemaker 1', bundle);
      // }

      // if (this.props.weightMusic !== undefined) {
      //   let weightedMusic = this.getWeightedMusic(
      //     bundle.promotedLocations,
      //     this.props.profile,
      //   );

      //   bundle = {
      //     promotedLocations: weightedMusic,
      //     setTag: undefined,
      //     seeMore: false,
      //   };
      // if (this.props.catTitle === 'Top Entertainment Picks') {
      //   console.log('bundlemaker 2', bundle);
      // }
      // }
    } else if (
      this.props.type !== undefined &&
      this.props.type === 'Entertainers'
    ) {
      bundle = this.findPromotedLocations(
        this.props.entertainment,
        this.props.category,
      );
    } else if (
      this.props.type !== undefined &&
      this.props.type === 'Locations'
    ) {
      bundle = this.findPromotedLocations(
        this.props.locations,
        this.props.category,
      );
      // if (this.props.catTitle === 'Top Entertainment Picks') {
      //   console.log('top entertainment topic', bundle);
      // }
    }

    let promotedLocations = bundle.promotedLocations;

    // if (this.props.catTitle === 'Top Entertainment Picks') {
    //   console.log('top entertainment bundle', bundle);
    // }

    let showMessage = false;
    let taMesssage = '';
    if (
      promotedLocations[0] !== undefined &&
      promotedLocations[0].bucket !== 1 &&
      this.props.taRequest.startLocation !== 'current' &&
      this.props.taRequest.startLocation !== 'lastLocation'
    ) {
      showMessage = true;
      taMesssage =
        'Here are some great places near ' +
        this.props.taRequest.startLocation;
    }

    let slug = '';
    // if (this.props.category !== undefined) {
    //   slug = this.props.category.replace(/\s/g, '-');
    // }
    let plusTagNames = [];
    let plusTags = [];
    let selectedTags = [];
    this.props.taRequest.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        tag.plus.forEach((tagx) => {
          if (tag.checked === true) {
            let status = plusTagNames.includes(tagx.tag);
            if (status === false) {
              plusTags.push(tagx);
              plusTagNames.push(tagx.tag);
            }
            selectedTags.push(tag.tag);
          }
        });
      });
    });

    let count = this.props.count;
    if (
      promotedLocations !== undefined &&
      promotedLocations.length > 0
    ) {
      return (
        // <div className="homeSection" id="homeSectionGrey">
        <div className="homeSection">
          <div
          // style={{
          //   height:
          //     count > 2 || bundle.seeMore === false
          //       ? '40px'
          //       : '40px',
          //   marginBottom:
          //     count > 2 || bundle.seeMore === false
          //       ? '10px'
          //       : '10px',
          // }}
          >
            <Row
              className="no-margin"
              style={{
                padding: count > 2 ? '0x' : '0px',
              }}
            >
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="no-padding"
                style={{ width: '100%' }}
              >
                <div
                  className="sectiontitle"
                  style={{ width: '100%' }}
                >
                  <span
                    className="homeCat "
                    style={{
                      float: count > 2 ? 'left' : 'left',
                      marginBottom: count > 2 ? '10px' : '',
                      textAlign: count > 2 ? 'left' : 'left',
                    }}
                  >
                    {this.props.catTitle !== 'Top Picks'
                      ? this.props.catTitle
                      : 'Top Dining Picks'}
                  </span>
                  {showMessage === true &&
                    selectedTags.length !== 0 &&
                    this.props.catTitle === 'Top Picks' && (
                      <div className="taMessage">
                        <span>{taMesssage}</span>
                      </div>
                    )}
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                className="no-padding"
              >
                {bundle.seeMore === true && (
                  <div
                    style={{
                      zindex: '99999',
                      textAlign: count > 2 ? 'left' : 'left',
                    }}
                  >
                    <Link
                      to={{
                        pathname: '/guide/category/' + slug,
                      }}
                      className={count > 2 ? 'right' : 'seeMoreLeft'}
                    >
                      See More
                    </Link>
                  </div>
                )}
              </Col>
            </Row>
          </div>
          {this.props.catTitle !== 'Partner Spotlight' ? (
            <div>
              {this.props.fullScreen === true ? (
                <Carousel
                  key={this.props.category.categoryId}
                  showThumbs={false}
                  // centerSlidePercentage={80}
                  // selectedItem={this.getCount(promotedLocations)}
                  showIndicators={false}
                  showStatus={false}
                  // showArrows={true}
                  infiniteLoop={false}
                  swipeScrollTolerance={30}
                  preventMovementUntilSwipeScrollTolerance={true}
                  onChange={(value) => {
                    this.props.changeCarousel !== undefined &&
                      this.props.changeCarousel(
                        value,
                        this.props.category.categoryId,
                      );
                  }}
                  selectedItem={this.props.carouselPosition}
                >
                  {this.getSlideSections(
                    promotedLocations,
                    count,
                    true,
                    false,
                  )}
                </Carousel>
              ) : (
                <Carousel
                  key={this.props.category.categoryId}
                  showThumbs={false}
                  // centerMode={true}
                  // centerSlidePercentage={80}
                  // selectedItem={this.getCount(promotedLocations)}
                  showIndicators={false}
                  showStatus={false}
                  // showArrows={true}
                  infiniteLoop={false}
                  centerMode={count === 2 ? true : false}
                  swipeScrollTolerance={30}
                  preventMovementUntilSwipeScrollTolerance={true}
                  onChange={(value) => {
                    this.props.changeCarousel !== undefined &&
                      this.props.changeCarousel(
                        value,
                        this.props.category.categoryId,
                      );
                  }}
                  selectedItem={this.props.carouselPosition}
                >
                  {this.getSlideSections(
                    promotedLocations,
                    count,
                    false,
                    false,
                  )}
                </Carousel>
              )}
            </div>
          ) : (
            <div style={{ position: 'relative' }}>
              {this.getSlideSections(
                promotedLocations,
                count,
                false,
                true,
              )}
            </div>
          )}
        </div>
      );
    }
    return null; // or render a loading icon
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    entertainment: state.entertainment.entertainment,
    taRequest: state.taRequest,
    user: state.user,
    regions: state.regions.regions,
    newEvent: state.newEvent,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTaRequest: (request) => {
      dispatch(getTaRequest(request));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuideCategory);
