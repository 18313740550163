import React from "react";
import { connect } from "react-redux";
// Components
import AdminVendorForm from "../../components/AdminVendorForm";
import TitleBar from "../../components/TitleBar";

const query = require("array-query");

export class AdminVendorEdit extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getContent(id, array) {
    if (id !== undefined) {
      const contentDetails = query("id")
        .is(id)
        .on(array);
      return contentDetails[0];
    } else {
      return false;
    }
  }

  getForm() {
    if (this.props.match.params.id !== undefined) {
      const vendor = this.getContent(
        this.props.match.params.id,
        this.props.vendors
      );
      return <AdminVendorForm editableDoc={vendor} new={false} />;
    } else {
      return <AdminVendorForm editableDoc={""} new={true} />;
    }
  }

  render() {
    return (
      <div id="admin">
        <TitleBar title="Edit/Add Vendor" backlink="/admin/vendors/" />
        <div className="section">{this.getForm()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vendors: state.vendors.vendors
  };
}

export default connect(mapStateToProps)(AdminVendorEdit);
