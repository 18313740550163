import { call, put, takeLatest } from 'redux-saga/effects';

import { authhydrateApp } from '../authhydrateSaga/reducer';

import { init as getPackages } from '../packagesSaga/reducer';
import { getAllLocations } from '../allLocationsSaga/reducer';
import { getUsers } from '../usersSaga/reducer';
import { clearUauth } from '../unauthenticatedSaga/reducer';

import { push } from 'connected-react-router';

import * as actions from './actions';

let defaultState = {
  user: {
    authenticated: false,
  },
};

function* setUserAction(user) {
  // Hydrate
  yield put(authhydrateApp(user));
  // Redirect
}

function* setNewUserAction(user) {
  yield put(getUsers());
  // yield put(getPackages());
  // yield put(getAllLocations(1));
  yield put(push('/signup/profile/'));
}

function* setVendorAction(user) {
  // Hydrate
  yield put(authhydrateApp(user));
}

function* setNewVendorAction(user) {
  let url = '/vendor/signupprofile';
  if (
    user.payload.locationId !== undefined &&
    user.payload.locationId !== null
  ) {
    url = '/vendor/signupprofile/' + user.payload.locationId;
  }

  // yield put(getPackages());
  // yield put(getAllLocations(1));
  yield put(push(url));
}

function* clearUserAction(user) {
  yield put(clearUauth());
  yield put(push('/'));
}

export default function* sagas() {
  yield takeLatest(actions.SET_USER, setUserAction);
  yield takeLatest(actions.CLEAR_USER, clearUserAction);
  yield takeLatest(actions.SET_NEWUSER, setNewUserAction);
  yield takeLatest(actions.SET_VENDORLOGIN, setVendorAction);
  yield takeLatest(actions.SET_NEWVENDOR, setNewVendorAction);
}
