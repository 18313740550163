import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
// Components

const query = require('array-query');

class NewEventMessage extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      message: '',
    };
  }

  UNSAFE_componentWillMount() {}

  render() {
    return (
      <div id="message-form">
        <Container>
          {/* <Row>
            <Col className="text-center">
              <h4>Include Message?</h4>
            </Col>
          </Row> */}
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <Formik
                enableReinitialize
                onSubmit={values => {
                  this.props.saveMessage(values.message);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          md={12}
                          sm={12}
                          lg={12}
                          className="left"
                        >
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="error-message"
                          />
                          <Field
                            type="text"
                            component="textarea"
                            name="message"
                            placeholder="Message"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <button
                            type="submit"
                            className="redbutton fullwidth white display-block"
                            disabled={isSubmitting}
                          >
                            Send Invite!
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
  };
}

export default connect(mapStateToProps)(NewEventMessage);
