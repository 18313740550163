import React from 'react';
import moment from 'moment';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import TimePicker from 'rc-time-picker';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import firebase from '../../config/firebase';
import FileUploader from 'react-firebase-file-uploader';
import Select from 'react-select';
import MaterialIcon from 'material-icons-react';
import ReactCrop from 'react-image-crop';
import UserAvatar from 'react-user-avatar';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../images/TrevelUnderlined.svg';
import {
  DayPicker,
  DateUtils,
  DayClickEventHandler,
} from 'react-day-picker';
// Reducers
// import { setLocations } from '../../sagas/locationsSaga/reducer';
import { getVendorLocations } from '../../sagas/vendorLocationsSaga/reducer';

const INITIAL_STATE = {
  mode: 0,
  showHours: false,
  title: '',
  categories: '',
  city: '',
  latitude: null,
  longitude: null,
  state: '',
  street: '',
  zip: '',
  locationHero: '',
  locationId: '',
  locationThumb: '',
  locationDescriptionLong: '',
  phone: '',
  website: '',
  menuLink: '',
  voucher1Info: '',
  voucher1Cost: '',
  voucher1Id: '',
  voucher1Promo: '',
  voucher1Type: '',
  voucher2Info: '',
  voucher2Cost: '',
  voucher2Id: '',
  voucher2Promo: '',
  voucher2Type: '',
  voucher3Info: '',
  voucher3Cost: '',
  voucher3Id: '',
  voucher3Promo: '',
  voucher3Type: '',
  tagsMain: {
    label: '',
    value: '',
  },
  packages: '',
  zoneId: '',
  error: null,
  loading: true,
  isUploading: false,
  progress: 0,
  progressThumb: 0,
  heroURL: '',
  hero: '',
  selectedOption: null,
  contactEmail: null,
  contactName: null,
  contactPhone: null,
  step: 1,
  feedbackSubmitstatus: 0,
  feedback: '',
  restaurant: '',
  hasFood: false,
  hasDrinks: false,
  cuisineOther: [],
  beveragesOther: [],
  beverages: [],
  cuisine: [],

  sundayTwo: false,
  mondayTwo: false,
  tuesdayTwo: false,
  wednesdayTwo: false,
  thursdayTwo: false,
  fridayTwo: false,
  saterdayTwo: false,

  sundayToMin: 0,
  mondayToMin: 0,
  tuesdayToMin: 0,
  wednesdayToMin: 0,
  thursdayToMin: 0,
  fridayToMin: 0,
  saterdayToMin: 0,
  sundayFromMin: 0,
  mondayFromMin: 0,
  tuesdayFromMin: 0,
  wednesdayFromMin: 0,
  thursdayFromMin: 0,
  fridayFromMin: 0,
  saterdayFromMin: 0,

  sundayToHour: 8,
  mondayToHour: 8,
  tuesdayToHour: 8,
  wednesdayToHour: 8,
  thursdayToHour: 8,
  fridayToHour: 8,
  saterdayToHour: 8,
  sundayFromHour: 8,
  mondayFromHour: 8,
  tuesdayFromHour: 8,
  wednesdayFromHour: 8,
  thursdayFromHour: 8,
  fridayFromHour: 8,
  saterdayFromHour: 8,

  sundayToMinTwo: 0,
  mondayToMinTwo: 0,
  tuesdayToMinTwo: 0,
  wednesdayToMinTwo: 0,
  thursdayToMinTwo: 0,
  fridayToMinTwo: 0,
  saterdayToMinTwo: 0,
  sundayFromMinTwo: 0,
  mondayFromMinTwo: 0,
  tuesdayFromMinTwo: 0,
  wednesdayFromMinTwo: 0,
  thursdayFromMinTwo: 0,
  fridayFromMinTwo: 0,
  saterdayFromMinTwo: 0,

  sundayToHourTwo: 8,
  mondayToHourTwo: 8,
  tuesdayToHourTwo: 8,
  wednesdayToHourTwo: 8,
  thursdayToHourTwo: 8,
  fridayToHourTwo: 8,
  saterdayToHourTwo: 8,
  sundayFromHourTwo: 8,
  mondayFromHourTwo: 8,
  tuesdayFromHourTwo: 8,
  wednesdayFromHourTwo: 8,
  thursdayFromHourTwo: 8,
  fridayFromHourTwo: 8,
  saterdayFromHourTwo: 8,

  sundayToAMPM: 'pm',
  mondayToAMPM: 'pm',
  tuesdayToAMPM: 'pm',
  wednesdayToAMPM: 'pm',
  thursdayToAMPM: 'pm',
  fridayToAMPM: 'pm',
  saterdayToAMPM: 'pm',

  sundayFromAMPM: 'am',
  mondayFromAMPM: 'am',
  tuesdayFromAMPM: 'am',
  wednesdayFromAMPM: 'am',
  thursdayFromAMPM: 'am',
  fridayFromAMPM: 'am',
  saterdayFromAMPM: 'am',

  sundayToAMPMTwo: 'pm',
  mondayToAMPMTwo: 'pm',
  tuesdayToAMPMTwo: 'pm',
  wednesdayToAMPMTwo: 'pm',
  thursdayToAMPMTwo: 'pm',
  fridayToAMPMTwo: 'pm',
  saterdayToAMPMTwo: 'pm',

  sundayFromAMPMTwo: 'am',
  mondayFromAMPMTwo: 'am',
  tuesdayFromAMPMTwo: 'am',
  wednesdayFromAMPMTwo: 'am',
  thursdayFromAMPMTwo: 'am',
  fridayFromAMPMTwo: 'am',
  saterdayFromAMPMTwo: 'am',

  sundayOpen: false,
  mondayOpen: false,
  tuesdayOpen: false,
  wednesdayOpen: false,
  thursdayOpen: false,
  fridayOpen: false,
  saterdayOpen: false,

  sundayOpenTwo: false,
  mondayOpenTwo: false,
  tuesdayOpenTwo: false,
  wednesdayOpenTwo: false,
  thursdayOpenTwo: false,
  fridayOpenTwo: false,
  saterdayOpenTwo: false,

  SundayHappyHourToTime: '',
  MondayHappyHourToTime: '',
  TuesdayHappyHourToTime: '',
  WednesdayHappyHourToTime: '',
  ThursdayHappyHourToTime: '',
  FridayHappyHourToTime: '',
  SaturdayHappyHourToTime: '',
  SundayHappyHourFromTime: '',
  MondayHappyHourFromTime: '',
  TuesdayHappyHourFromTime: '',
  WednesdayHappyHourFromTime: '',
  ThursdayHappyHourFromTime: '',
  FridayHappyHourFromTime: '',
  SaturdayHappyHourFromTime: '',
  SameHappyHourFromTime: '',
  SameHappyHourToTime: '',
  happyHourDetails: '',
  happyHourLink: 'http://',

  eventType: [],
  genre: [],

  hostEvents: false,

  crop: {
    unit: '%',
    width: 30,
    aspect: 800 / 259,
  },
  cropThumb: {
    unit: '%',
    width: 30,
    aspect: 146 / 122,
  },
  cropping: false,
  croppingThumb: false,
  thumbPath: '',
  heroPath: '',
  local: false,

  selected: 'Weekly',
  timesSelected: 1,
  dates: [],
  repeatStartDate: [],
  differentTimes: false,

  restaurant: false,
};

export class VendorLocationsAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getCategoryFields = this.getCategoryFields.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
  }

  UNSAFE_componentWillMount() {
    const editorState = EditorState.createEmpty();
    const editorStateLong = EditorState.createEmpty();
    this.setState({
      editorStateLong,
      editorState,
    });
  }

  componentDidMount() {
    // async function getToken() {
    //   const preview = await client.og(
    //     'http://www.imdb.com/title/tt0117500/',
    //   );
    //   console.log('preview', preview);
    // }
    // getToken();

    this.setCurrentValues();
    // Get New Location ID to use later
    const db = firebase.firestore();
    const newLocationRef = db.collection('locations').doc();
    const dbref = newLocationRef.id;

    this.setState({
      dbref: dbref,
    });
    // this.updateFields();
  }

  getCategoryFieldsLocationType(cattag) {
    const options = [];
    if (cattag.sid === 'locationType') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        // if (tag.tag !== 'Restaurant')
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={this.state[cattag.sid]}
            onChange={(value) => {
              this.handleOnChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  getMainTag(cattag) {
    const options = [];

    this.props.tags.forEach((cattag) => {
      if (cattag.sid === 'dateType') {
        const tags = cattag.tags;
        tags.forEach((tag) => {
          options.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (cattag.sid === 'locationType') {
        const tags = cattag.tags;
        tags.forEach((tag) => {
          options.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (cattag.sid === 'beverages') {
        const tags = cattag.tags;
        tags.forEach((tag) => {
          options.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (cattag.sid === 'cuisineType') {
        const tags = cattag.tags;
        tags.forEach((tag) => {
          options.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      }
    });

    return (
      <div>
        {/* <span className="inputLabel">Main Tag</span> */}
        <Select
          value={this.state.tagsMain}
          onChange={(value) => {
            this.handleOnChangeMainTag(value);
          }}
          options={options}
          isMulti={false}
        />
      </div>
    );
  }

  getCategoryFieldsRestaurant() {
    let locationType = [];
    if (this.state.locationType !== undefined) {
      this.state.locationType.forEach((doc) => {
        locationType.push(doc.value);
      });
    }

    let status = false;
    // if (
    //   this.state.restaurant === 'yes' ||
    //   locationType.includes('Bar') ||
    //   locationType.includes('Cocktail Bar')
    // ) {
    //   status = true;
    // }

    // if (status === true) {
    return (
      <div>
        {this.state.hasFood === true && (
          <div>
            <div
              style={{
                padding: '10px 0px 0px 0px',
                margin: '30px 0px 10px 0px',
              }}
            >
              <h4 style={{ fontWeight: '600', fontSize: '18px' }}>
                Cuisine
              </h4>
              <span className="title">
                What type of cuisine are you most known for? Skip if
                this does not apply to you.
              </span>
              {this.props.tags.map((cattag, index) => (
                <div>{this.getMainCuisineFields(cattag)}</div>
              ))}
            </div>
            <div
              style={{
                padding: '10px 0px 0px 0px',
                margin: '30px 0px 10px 0px',
              }}
            >
              <span className="title">
                What other types of cuisine would you highlight? (Pick
                2)
              </span>
              {this.props.tags.map((cattag, index) => (
                <div>{this.getOtherCuisineFields(cattag)}</div>
              ))}
            </div>
          </div>
        )}
        {this.state.hasDrinks === true && (
          <div>
            <div
              style={{
                padding: '10px 0px 0px 0px',
                margin: '30px 0px 10px 0px',
              }}
            >
              <h4 style={{ fontWeight: '600', fontSize: '18px' }}>
                Drinks
              </h4>
              <span className="title">
                What beverages are you most known for?
              </span>
              {this.props.tags.map((cattag, index) => (
                <div>{this.getMainBeverageFields(cattag)}</div>
              ))}
            </div>
            <div
              style={{
                padding: '10px 0px 0px 0px',
                margin: '30px 0px 10px 0px',
              }}
            >
              <span className="title">
                Any other drinks do you want to call out? (Pick 2)
              </span>
              {this.props.tags.map((cattag, index) => (
                <div>{this.getOtherBeverageFields(cattag)}</div>
              ))}
            </div>
          </div>
        )}

        <div
          style={{
            padding: '10px 0px 0px 0px',
            margin: '30px 0px 10px 0px',
          }}
        >
          <h4 style={{ fontWeight: '600', fontSize: '18px' }}>
            Descriptive
          </h4>
          <span className="title">
            How would you desribe your setting and atmosphere?
          </span>
          {this.props.tags.map((cattag, index) => (
            <div>{this.getSettingsFields(cattag)}</div>
          ))}
        </div>
        {/* {this.state.restaurant === 'yes' && (
          <div>
            <div
              style={{
                padding: '10px 0px 0px 0px',
                margin: '30px 0px 10px 0px',
              }}
            >
              <span className="title">
                What type of cuisine are you most known for?
              </span>
              {this.props.tags.map((cattag, index) => (
                <div>{this.getMainCuisineFields(cattag)}</div>
              ))}
            </div>
            <div
              style={{
                padding: '10px 0px 0px 0px',
                margin: '30px 0px 10px 0px',
              }}
            >
              <span className="title">
                What other types of cuisine would you highlight? (Pick
                2)
              </span>
              {this.props.tags.map((cattag, index) => (
                <div>{this.getOtherCuisineFields(cattag)}</div>
              ))}
            </div>
            <div
              style={{
                padding: '10px 0px 0px 0px',
                margin: '30px 0px 10px 0px',
              }}
            >
              <span className="title">
                What beverages are you most known for?
              </span>
              {this.props.tags.map((cattag, index) => (
                <div>{this.getMainBeverageFields(cattag)}</div>
              ))}
            </div>
            <div
              style={{
                padding: '10px 0px 0px 0px',
                margin: '30px 0px 10px 0px',
              }}
            >
              <span className="title">
                Any other drinks you want to call out? (Pick 2)
              </span>
              {this.props.tags.map((cattag, index) => (
                <div>{this.getOtherBeverageFields(cattag)}</div>
              ))}
            </div>
            <div
              style={{
                padding: '10px 0px 0px 0px',
                margin: '30px 0px 10px 0px',
              }}
            >
              <span className="title">
                How would you desribe your setting and atmosphere?
              </span>
              {this.props.tags.map((cattag, index) => (
                <div>{this.getSettingsFields(cattag)}</div>
              ))}
            </div>
          </div>
        )} */}
      </div>
    );
    // }
  }

  getCategoryFields(cattag) {
    const options = [];

    if (cattag.sid === 'dateType') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });

      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={this.state[cattag.sid]}
            onChange={(value) => {
              this.handleOnChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  checkForMusic(eventType) {
    let status = false;
    eventType.forEach((type) => {
      if (type.value === 'Music' || type.value === 'Theater') {
        status = true;
      }
    });
    return status;
  }

  getEntertainmentTags(cattag, eventType) {
    const options = [];
    if (cattag.sid === 'eventType') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={eventType}
            onChange={(value) => {
              this.handleEventTypeChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  getGenreTags(cattag) {
    const options = [];

    if (cattag.sid === 'genre') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });

      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={this.state[cattag.sid]}
            onChange={(value) => {
              this.handleGenreChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  getMainCuisineFields(cattag) {
    const options = [];

    if (cattag.sid === 'cuisineType') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });

      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={this.state.cuisineType}
            onChange={(value) => {
              this.handleCuisineChange(cattag.sid, value);
            }}
            options={options}
            isMulti={false}
          />
        </div>
      );
    }
  }

  getMainBeverageFields(cattag) {
    const options = [];
    if (cattag.sid === 'beverages') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={this.state.beverages}
            onChange={(value) => {
              this.handleOtherBeveragesChange(cattag.sid, value);
            }}
            options={options}
            isMulti={false}
          />
        </div>
      );
    }
  }

  getOtherBeverageFields(cattag) {
    const options = [];
    if (cattag.sid === 'beverages') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={this.state.beveragesOther}
            onChange={(value) => {
              this.handleBeveragesChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  getSettingsFields(cattag) {
    const options = [];
    if (cattag.sid === 'setting') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={this.state[cattag.sid]}
            onChange={(value) => {
              this.handleOnChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  getOtherCuisineFields(cattag) {
    const options = [];

    if (cattag.sid === 'cuisineType') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });

      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={this.state.cuisineOther}
            onChange={(value) => {
              this.handleOtherCuisineChange('cuisineOther', value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  getCategoryFields(cattag) {
    const options = [];

    if (cattag.sid === 'dateType') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });

      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={this.state[cattag.sid]}
            onChange={(value) => {
              this.handleOnChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  handleOnChange = (fieldName, value) => {
    if (fieldName === 'locationType') {
      value.forEach((tag) => {
        if (tag.value === 'Restaurant') {
          this.setState({ restaurant: 'yes' });
          this.setState({ hasFood: true });
          this.setState({ hasDrinks: true });
        }
        if (tag.value === 'Brewery') {
          this.setState({ hasDrinks: true });
        }
      });
    }
    this.setState({ [fieldName]: value });
  };

  handleOtherBeveragesChange = (fieldName, value) => {
    let newArray = [];
    newArray.push(value);
    this.setState({ beverages: newArray });
  };

  handleBeveragesChange = (fieldName, value) => {
    this.setState({ beveragesOther: value });
  };

  handleCuisineChange = (fieldName, value) => {
    let newArray = [];
    newArray.push(value);
    this.setState({ cuisineType: newArray });
  };

  handleEventTypeChange = (fieldName, value) => {
    this.setState({ eventType: value });
  };

  handleGenreChange = (fieldName, value) => {
    this.setState({ genre: value });
  };

  handleOtherCuisineChange = (fieldName, value) => {
    this.setState({ cuisineOther: value });
  };

  handleOnChangeMainTag = (value) => {
    this.setState({ tagsMain: value });
  };

  setCurrentValues() {
    const beverageTags = [];
    const locationTypeTags = [];
    const settingTags = [];
    const cuisineTags = [];
    const dateTypeTags = [];
    const hoursTags = [];
    this.setState({
      beverages: beverageTags,
      cuisineType: cuisineTags,
      hours: hoursTags,
      dateType: dateTypeTags,
      setting: settingTags,
      locationType: locationTypeTags,
    });
  }

  selectRegionChange = (values) => {
    this.setState({
      selectedRegion: values,
    });
  };

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onEditorStateChangeLong: Function = (editorStateLong) => {
    this.setState({
      editorStateLong,
    });
  };

  saveLocation(editorState, values) {
    const db = firebase.firestore();
    // const articleToUpdate = db.collection('locations').doc(id);
    const timestamp = new Date();
    const tagsBeverages = [];
    this.state.beverages.forEach((doc) => {
      tagsBeverages.push(doc.value);
    });
    this.state.beveragesOther.forEach((doc) => {
      tagsBeverages.push(doc.value);
    });

    const tagsCuisine = [];
    this.state.cuisineType.forEach((doc) => {
      tagsCuisine.push(doc.value);
    });
    this.state.cuisineOther.forEach((doc) => {
      tagsCuisine.push(doc.value);
    });

    const tagsDateType = [];
    this.state.dateType.forEach((doc) => {
      tagsDateType.push(doc.value);
    });

    const tagsHours = [];
    this.state.hours.forEach((doc) => {
      tagsHours.push(doc.value);
    });

    const tagsSetting = [];
    this.state.setting.forEach((doc) => {
      tagsSetting.push(doc.value);
    });

    const tagsLocationType = [];
    this.state.locationType.forEach((doc) => {
      tagsLocationType.push(doc.value);
    });

    const tagsGenre = [];
    this.state.genre.forEach((doc) => {
      tagsGenre.push(doc.value);
    });

    const tagsEventType = [];
    this.state.eventType.forEach((doc) => {
      tagsEventType.push(doc.value);
    });

    let tagsMain = '';
    if (this.state.restaurant === 'yes') {
      if (tagsCuisine[0] !== undefined) {
        tagsMain = tagsCuisine[0];
      } else {
        tagsMain = tagsLocationType[0];
      }
    } else {
      tagsMain = tagsLocationType[0];
    }

    var newCuisineArray = [];
    var newCuisineArray = tagsCuisine.filter(function (elem, pos) {
      return tagsCuisine.indexOf(elem) == pos;
    });

    var newBeveragesArray = [];
    var newBeveragesArray = tagsBeverages.filter(function (
      elem,
      pos,
    ) {
      return tagsBeverages.indexOf(elem) == pos;
    });

    // Convert Body from Draft.js to HTML
    const rawContentState = convertToRaw(
      editorState.getCurrentContent(),
    );

    const markup = draftToHtml(rawContentState);

    const rawContentStateLong = convertToRaw(
      this.state.editorStateLong.getCurrentContent(),
    );
    const markupLong = draftToHtml(rawContentStateLong);

    let {
      sundayToMin,
      sundayFromMin,
      sundayToHour,
      sundayFromHour,
      sundayFromAMPM,
      sundayToAMPM,
      sundayOpen,

      mondayToMin,
      mondayFromMin,
      mondayToHour,
      mondayFromHour,
      mondayFromAMPM,
      mondayToAMPM,
      mondayOpen,

      tuesdayToMin,
      tuesdayFromMin,
      tuesdayToHour,
      tuesdayFromHour,
      tuesdayFromAMPM,
      tuesdayToAMPM,
      tuesdayOpen,

      wednesdayToMin,
      wednesdayFromMin,
      wednesdayToHour,
      wednesdayFromHour,
      wednesdayFromAMPM,
      wednesdayToAMPM,
      wednesdayOpen,

      thursdayToMin,
      thursdayFromMin,
      thursdayToHour,
      thursdayFromHour,
      thursdayFromAMPM,
      thursdayToAMPM,
      thursdayOpen,

      fridayToMin,
      fridayFromMin,
      fridayToHour,
      fridayFromHour,
      fridayFromAMPM,
      fridayToAMPM,
      fridayOpen,

      saterdayToMin,
      saterdayFromMin,
      saterdayToHour,
      saterdayFromHour,
      saterdayFromAMPM,
      saterdayToAMPM,
      saterdayOpen,
    } = this.state;

    let {
      sundayToMinTwo,
      sundayFromMinTwo,
      sundayToHourTwo,
      sundayFromHourTwo,
      sundayFromAMPMTwo,
      sundayToAMPMTwo,
      sundayOpenTwo,

      mondayToMinTwo,
      mondayFromMinTwo,
      mondayToHourTwo,
      mondayFromHourTwo,
      mondayFromAMPMTwo,
      mondayToAMPMTwo,
      mondayOpenTwo,

      tuesdayToMinTwo,
      tuesdayFromMinTwo,
      tuesdayToHourTwo,
      tuesdayFromHourTwo,
      tuesdayFromAMPMTwo,
      tuesdayToAMPMTwo,
      tuesdayOpenTwo,

      wednesdayToMinTwo,
      wednesdayFromMinTwo,
      wednesdayToHourTwo,
      wednesdayFromHourTwo,
      wednesdayFromAMPMTwo,
      wednesdayToAMPMTwo,
      wednesdayOpenTwo,

      thursdayToMinTwo,
      thursdayFromMinTwo,
      thursdayToHourTwo,
      thursdayFromHourTwo,
      thursdayFromAMPMTwo,
      thursdayToAMPMTwo,
      thursdayOpenTwo,

      fridayToMinTwo,
      fridayFromMinTwo,
      fridayToHourTwo,
      fridayFromHourTwo,
      fridayFromAMPMTwo,
      fridayToAMPMTwo,
      fridayOpenTwo,

      saterdayToMinTwo,
      saterdayFromMinTwo,
      saterdayToHourTwo,
      saterdayFromHourTwo,
      saterdayFromAMPMTwo,
      saterdayToAMPMTwo,
      saterdayOpenTwo,

      sundayTwo,
      mondayTwo,
      tuesdayTwo,
      wednesdayTwo,
      thursdayTwo,
      fridayTwo,
      saterdayTwo,
    } = this.state;

    let {
      SundayHappyHourToTime,
      MondayHappyHourToTime,
      TuesdayHappyHourToTime,
      WednesdayHappyHourToTime,
      ThursdayHappyHourToTime,
      FridayHappyHourToTime,
      SaturdayHappyHourToTime,
      SundayHappyHourFromTime,
      MondayHappyHourFromTime,
      TuesdayHappyHourFromTime,
      WednesdayHappyHourFromTime,
      ThursdayHappyHourFromTime,
      FridayHappyHourFromTime,
      SaturdayHappyHourFromTime,
      SameHappyHourFromTime,
      SameHappyHourToTime,
    } = this.state;
    let happyHours = {
      days: {},
    };

    if (this.state.differentTimes === false) {
      if (this.state.Sunday === true) {
        happyHours.days.sunday = {
          toTime: SameHappyHourToTime,
          fromTime: SameHappyHourFromTime,
        };
      }
      if (this.state.Tuesday === true) {
        happyHours.days.tuesday = {
          toTime: SameHappyHourToTime,
          fromTime: SameHappyHourFromTime,
        };
      }

      if (this.state.Monday === true) {
        happyHours.days.monday = {
          toTime: SameHappyHourToTime,
          fromTime: SameHappyHourFromTime,
        };
      }

      if (this.state.Wednesday === true) {
        happyHours.days.wednesday = {
          toTime: SameHappyHourToTime,
          fromTime: SameHappyHourFromTime,
        };
      }

      if (this.state.Thursday === true) {
        happyHours.days.thursday = {
          toTime: SameHappyHourToTime,
          fromTime: SameHappyHourFromTime,
        };
      }

      if (this.state.Friday === true) {
        happyHours.days.friday = {
          toTime: SameHappyHourToTime,
          fromTime: SameHappyHourFromTime,
        };
      }

      if (this.state.Saturday === true) {
        happyHours.days.saturday = {
          toTime: SameHappyHourToTime,
          fromTime: SameHappyHourFromTime,
        };
      }
    } else {
      if (SundayHappyHourToTime !== '') {
        happyHours.days.sunday = {
          toTime: SundayHappyHourToTime,
          fromTime: SundayHappyHourFromTime,
        };
      }
      if (TuesdayHappyHourToTime !== '') {
        happyHours.days.tuesday = {
          toTime: TuesdayHappyHourToTime,
          fromTime: TuesdayHappyHourFromTime,
        };
      }

      if (MondayHappyHourToTime !== '') {
        happyHours.days.monday = {
          toTime: MondayHappyHourToTime,
          fromTime: MondayHappyHourFromTime,
        };
      }

      if (WednesdayHappyHourToTime !== '') {
        happyHours.days.wednesday = {
          toTime: WednesdayHappyHourToTime,
          fromTime: WednesdayHappyHourFromTime,
        };
      }

      if (ThursdayHappyHourToTime !== '') {
        happyHours.days.thursday = {
          toTime: ThursdayHappyHourToTime,
          fromTime: ThursdayHappyHourFromTime,
        };
      }

      if (FridayHappyHourToTime !== '') {
        happyHours.days.friday = {
          toTime: FridayHappyHourToTime,
          fromTime: FridayHappyHourFromTime,
        };
      }

      if (SaturdayHappyHourToTime !== '') {
        happyHours.days.saturday = {
          toTime: SaturdayHappyHourToTime,
          fromTime: SaturdayHappyHourFromTime,
        };
      }
    }

    // if (happyHours.days.length !== 0) {
    //   if (tagsDateType.includes('Happy Hour') !== true) {
    //     tagsDateType.push('Happy Hour');
    //   }
    // }

    let happyHour = {
      days: happyHours.days,
      details: this.state.happyHourDetails,
      link: this.state.happyHourLink,
      differentTimes: this.state.differentTimes,
    };

    let hours = {
      showHours: this.state.showHours,
      sunday: {
        sundayToMin,
        sundayFromMin,
        sundayToHour,
        sundayFromHour,
        sundayFromAMPM,
        sundayToAMPM,
        sundayOpen,
        sundayTwo,
      },
      monday: {
        mondayToMin,
        mondayFromMin,
        mondayToHour,
        mondayFromHour,
        mondayFromAMPM,
        mondayToAMPM,
        mondayOpen,
        mondayTwo,
      },
      tuesday: {
        tuesdayToMin,
        tuesdayFromMin,
        tuesdayToHour,
        tuesdayFromHour,
        tuesdayFromAMPM,
        tuesdayToAMPM,
        tuesdayOpen,
        tuesdayTwo,
      },
      wednesday: {
        wednesdayToMin,
        wednesdayFromMin,
        wednesdayToHour,
        wednesdayFromHour,
        wednesdayFromAMPM,
        wednesdayToAMPM,
        wednesdayOpen,
        wednesdayTwo,
      },
      thursday: {
        thursdayToMin,
        thursdayFromMin,
        thursdayToHour,
        thursdayFromHour,
        thursdayFromAMPM,
        thursdayToAMPM,
        thursdayOpen,
        thursdayTwo,
      },
      friday: {
        fridayToMin,
        fridayFromMin,
        fridayToHour,
        fridayFromHour,
        fridayFromAMPM,
        fridayToAMPM,
        fridayOpen,
        fridayTwo,
      },
      saterday: {
        saterdayToMin,
        saterdayFromMin,
        saterdayToHour,
        saterdayFromHour,
        saterdayFromAMPM,
        saterdayToAMPM,
        saterdayOpen,
        saterdayTwo,
      },
    };

    if (sundayTwo === true) {
      hours.sunday.sundayToMinTwo = sundayToMinTwo;
      hours.sunday.sundayFromMinTwo = sundayFromMinTwo;
      hours.sunday.sundayToHourTwo = sundayToHourTwo;
      hours.sunday.sundayFromHourTwo = sundayFromHourTwo;
      hours.sunday.sundayFromAMPMTwo = sundayFromAMPMTwo;
      hours.sunday.sundayToAMPMTwo = sundayToAMPMTwo;
    }
    if (mondayTwo === true) {
      hours.monday.mondayToMinTwo = mondayToMinTwo;
      hours.monday.mondayFromMinTwo = mondayFromMinTwo;
      hours.monday.mondayToHourTwo = mondayToHourTwo;
      hours.monday.mondayFromHourTwo = mondayFromHourTwo;
      hours.monday.mondayFromAMPMTwo = mondayFromAMPMTwo;
      hours.monday.mondayToAMPMTwo = mondayToAMPMTwo;
    }
    if (tuesdayTwo === true) {
      hours.tuesday.tuesdayToMinTwo = tuesdayToMinTwo;
      hours.tuesday.tuesdayFromMinTwo = tuesdayFromMinTwo;
      hours.tuesday.tuesdayToHourTwo = tuesdayToHourTwo;
      hours.tuesday.tuesdayFromHourTwo = tuesdayFromHourTwo;
      hours.tuesday.tuesdayFromAMPMTwo = tuesdayFromAMPMTwo;
      hours.tuesday.tuesdayToAMPMTwo = tuesdayToAMPMTwo;
    }
    if (wednesdayTwo === true) {
      hours.wednesday.wednesdayToMinTwo = wednesdayToMinTwo;
      hours.wednesday.wednesdayFromMinTwo = wednesdayFromMinTwo;
      hours.wednesday.wednesdayToHourTwo = wednesdayToHourTwo;
      hours.wednesday.wednesdayFromHourTwo = wednesdayFromHourTwo;
      hours.wednesday.wednesdayFromAMPMTwo = wednesdayFromAMPMTwo;
      hours.wednesday.wednesdayToAMPMTwo = wednesdayToAMPMTwo;
    }
    if (thursdayTwo === true) {
      hours.thursday.thursdayToMinTwo = thursdayToMinTwo;
      hours.thursday.thursdayFromMinTwo = thursdayFromMinTwo;
      hours.thursday.thursdayToHourTwo = thursdayToHourTwo;
      hours.thursday.thursdayFromHourTwo = thursdayFromHourTwo;
      hours.thursday.thursdayFromAMPMTwo = thursdayFromAMPMTwo;
      hours.thursday.thursdayToAMPMTwo = thursdayToAMPMTwo;
    }
    if (fridayTwo === true) {
      hours.friday.fridayToMinTwo = fridayToMinTwo;
      hours.friday.fridayFromMinTwo = fridayFromMinTwo;
      hours.friday.fridayToHourTwo = fridayToHourTwo;
      hours.friday.fridayFromHourTwo = fridayFromHourTwo;
      hours.friday.fridayFromAMPMTwo = fridayFromAMPMTwo;
      hours.friday.fridayToAMPMTwo = fridayToAMPMTwo;
    }
    if (saterdayTwo === true) {
      hours.saterday.saterdayToMinTwo = saterdayToMinTwo;
      hours.saterday.saterdayFromMinTwo = saterdayFromMinTwo;
      hours.saterday.saterdayToHourTwo = saterdayToHourTwo;
      hours.saterday.saterdayFromHourTwo = saterdayFromHourTwo;
      hours.saterday.saterdayFromAMPMTwo = saterdayFromAMPMTwo;
      hours.saterday.saterdayToAMPMTwo = saterdayToAMPMTwo;
    }

    let selectedRegion = null;
    if (this.state.selectedRegion !== undefined) {
      selectedRegion = this.state.selectedRegion.value;
    }

    let finalObject = {
      locationDescription: markup,
      locationDescriptionLong: markupLong,
      created: timestamp,
      lastUpdated: timestamp,
      locationTitle: values.title,
      locationId: this.state.dbref,
      locationThumb: this.state.thumbPath,
      locationHero: this.state.heroPath,
      hours: hours,
      happyHour: happyHour,
      address: {
        city: values.city,
        street: values.street,
        zip: values.zip,
        longitude: values.longitude,
        latitude: values.latitude,
        state: values.state,
        local: this.state.local,
        region: selectedRegion,
      },
      contact: {
        contactName: values.contactName,
        contactPhone: values.contactPhone,
        contactEmail: values.contactEmail,
      },
      tagsDateType,
      tagsCuisine: newCuisineArray,
      tagsHours,
      tagsBeverages: newBeveragesArray,
      tagsSetting,
      tagsLocationType,
      tagsMain,
      zoneId: values.zoneId,
      phone: values.phone,
      website: values.website,
      menuLink: values.menuLink,
      settings: {
        public: true,
        published: true,
        tdStatus: 1,
        customImages: true,
      },
      creationDetails: {
        creatorId: this.props.profile.userId,
        createdDate: timestamp,
      },
      zoneId: this.props.profile.zoneId,
    };

    let eventSettings = {
      status: false,
      tags: {
        tagsEventType: [],
        tagsGenre: [],
      },
    };
    if (this.state.hostEvents === true) {
      eventSettings.status = true;
      eventSettings.tags = {
        tagsEventType,
        tagsGenre,
      };
    }
    finalObject.eventSettings = eventSettings;

    let vendorUpdate = this.props.vendor;
    let vendorLocations = this.props.vendor.locations;
    vendorLocations.push(this.state.dbref);
    vendorUpdate.locations = vendorLocations;

    // console.log('finalObjectx', finalObject);

    const setDoc = db
      .collection('locations')
      .doc(this.state.dbref)
      .set(finalObject)
      .then((ref) => {
        const update = db
          .collection('vendors')
          .doc(this.props.profile.vendorAccount.id);
        const setDoc = update.update(vendorUpdate).then((ref) => {
          let bundle = {
            zoneId: this.props.profile.zoneId,
            vendorId: this.props.profile.vendorAccount.id,
          };
          this.props.getVendorLocations(bundle);
        });
      });
  }

  // Banner Crop
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      cropping: true,
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'heroImage.jpeg',
      );
      this.setState({ heroPath: croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getBannerCropper(bannerCropping) {
    const { crop, croppedImageUrl, src } = this.state;
    if (bannerCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="Bar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropper();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropper();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </div>
        </div>
      );
    }
  }
  // End Banner Crop
  // Thumb Crop
  onSelectFileThumb = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      croppingThumb: true,
    });
  };

  onImageLoadedThumb = (image) => {
    this.imageRef = image;
  };

  onCropCompleteThumb = (crop) => {
    this.makeClientCropThumb(crop);
  };

  onCropChangeThumb = (cropThumb, percentCrop) => {
    this.setState({ cropThumb });
  };

  // Hours
  onChangeActionSunday = (value) => {
    this.setState({
      sundayOpen: value.target.checked,
    });
  };
  onChangeActionMonday = (value) => {
    this.setState({
      mondayOpen: value.target.checked,
    });
  };
  onChangeActionTuesday = (value) => {
    this.setState({
      tuesdayOpen: value.target.checked,
    });
  };
  onChangeActionWednesday = (value) => {
    this.setState({
      wednesdayOpen: value.target.checked,
    });
  };
  onChangeActionThursday = (value) => {
    this.setState({
      thursdayOpen: value.target.checked,
    });
  };
  onChangeActionFriday = (value) => {
    this.setState({
      fridayOpen: value.target.checked,
    });
  };
  onChangeActionSaterday = (value) => {
    this.setState({
      saterdayOpen: value.target.checked,
    });
  };
  sundayFromAMPMChange = (value) => {
    this.setState({
      sundayFromAMPM: value.target.value,
    });
  };
  sundayToAMPMChange = (value) => {
    this.setState({
      sundayToAMPM: value.target.value,
    });
  };
  mondayFromAMPMChange = (value) => {
    this.setState({
      mondayFromAMPM: value.target.value,
    });
  };
  mondayToAMPMChange = (value) => {
    this.setState({
      mondayToAMPM: value.target.value,
    });
  };
  tuesdayFromAMPMChange = (value) => {
    this.setState({
      tuesdayFromAMPM: value.target.value,
    });
  };
  tuesdayToAMPMChange = (value) => {
    this.setState({
      tuesdayToAMPM: value.target.value,
    });
  };
  wednesdayFromAMPMChange = (value) => {
    this.setState({
      wednesdayFromAMPM: value.target.value,
    });
  };
  wednesdayToAMPMChange = (value) => {
    this.setState({
      wednesdayToAMPM: value.target.value,
    });
  };
  thursdayToAMPMChange = (value) => {
    this.setState({
      thursdayToAMPM: value.target.value,
    });
  };
  thursdayFromAMPMChange = (value) => {
    this.setState({
      thursdayFromAMPM: value.target.value,
    });
  };
  fridayFromAMPMChange = (value) => {
    this.setState({
      fridayFromAMPM: value.target.value,
    });
  };
  fridayToAMPMChange = (value) => {
    this.setState({
      fridayToAMPM: value.target.value,
    });
  };
  saterdayToAMPMChange = (value) => {
    this.setState({
      saterdayToAMPM: value.target.value,
    });
  };
  saterdayFromAMPMChange = (value) => {
    this.setState({
      saterdayFromAMPM: value.target.value,
    });
  };

  sundayFromMinChange = (value) => {
    this.setState({
      sundayFromMin: value.target.value,
    });
  };
  sundayFromHourChange = (value) => {
    this.setState({
      sundayFromHour: value.target.value,
    });
  };
  sundayToMinChange = (value) => {
    this.setState({
      sundayToMin: value.target.value,
    });
  };
  sundayToHourChange = (value) => {
    this.setState({
      sundayToHour: value.target.value,
    });
  };

  mondayFromMinChange = (value) => {
    this.setState({
      mondayFromMin: value.target.value,
    });
  };
  mondayFromHourChange = (value) => {
    this.setState({
      mondayFromHour: value.target.value,
    });
  };
  mondayToMinChange = (value) => {
    this.setState({
      mondayToMin: value.target.value,
    });
  };
  mondayToHourChange = (value) => {
    this.setState({
      mondayToHour: value.target.value,
    });
  };

  tuesdayFromMinChange = (value) => {
    this.setState({
      tuesdayFromMin: value.target.value,
    });
  };
  tuesdayFromHourChange = (value) => {
    this.setState({
      tuesdayFromHour: value.target.value,
    });
  };
  tuesdayToMinChange = (value) => {
    this.setState({
      tuesdayToMin: value.target.value,
    });
  };
  tuesdayToHourChange = (value) => {
    this.setState({
      tuesdayToHour: value.target.value,
    });
  };

  wednesdayFromMinChange = (value) => {
    this.setState({
      wednesdayFromMin: value.target.value,
    });
  };
  wednesdayFromHourChange = (value) => {
    this.setState({
      wednesdayFromHour: value.target.value,
    });
  };
  wednesdayToMinChange = (value) => {
    this.setState({
      wednesdayToMin: value.target.value,
    });
  };
  wednesdayToHourChange = (value) => {
    this.setState({
      wednesdayToHour: value.target.value,
    });
  };

  thursdayFromMinChange = (value) => {
    this.setState({
      thursdayFromMin: value.target.value,
    });
  };
  thursdayFromHourChange = (value) => {
    this.setState({
      thursdayFromHour: value.target.value,
    });
  };
  thursdayToMinChange = (value) => {
    this.setState({
      thursdayToMin: value.target.value,
    });
  };
  thursdayToHourChange = (value) => {
    this.setState({
      thursdayToHour: value.target.value,
    });
  };
  fridayFromMinChange = (value) => {
    this.setState({
      fridayFromMin: value.target.value,
    });
  };
  fridayFromHourChange = (value) => {
    this.setState({
      fridayFromHour: value.target.value,
    });
  };
  fridayToMinChange = (value) => {
    this.setState({
      fridayToMin: value.target.value,
    });
  };
  fridayToHourChange = (value) => {
    this.setState({
      fridayToHour: value.target.value,
    });
  };

  saterdayFromMinChange = (value) => {
    this.setState({
      saterdayFromMin: value.target.value,
    });
  };
  saterdayFromHourChange = (value) => {
    this.setState({
      saterdayFromHour: value.target.value,
    });
  };
  saterdayToMinChange = (value) => {
    this.setState({
      saterdayToMin: value.target.value,
    });
  };
  saterdayToHourChange = (value) => {
    this.setState({
      saterdayToHour: value.target.value,
    });
  };

  // Hours
  onChangeActionSundayTwo = (value) => {
    this.setState({
      sundayTwo: value.target.checked,
    });
  };
  onChangeActionMondayTwo = (value) => {
    this.setState({
      mondayTwo: value.target.checked,
    });
  };
  onChangeActionTuesdayTwo = (value) => {
    this.setState({
      tuesdayTwo: value.target.checked,
    });
  };
  onChangeActionWednesdayTwo = (value) => {
    this.setState({
      wednesdayTwo: value.target.checked,
    });
  };
  onChangeActionThursdayTwo = (value) => {
    this.setState({
      thursdayTwo: value.target.checked,
    });
  };
  onChangeActionFridayTwo = (value) => {
    this.setState({
      fridayTwo: value.target.checked,
    });
  };
  onChangeActionSaterdayTwo = (value) => {
    this.setState({
      saterdayTwo: value.target.checked,
    });
  };
  sundayFromAMPMChangeTwo = (value) => {
    this.setState({
      sundayFromAMPMTwo: value.target.value,
    });
  };
  sundayToAMPMChangeTwo = (value) => {
    this.setState({
      sundayToAMPMTwo: value.target.value,
    });
  };
  mondayFromAMPMChangeTwo = (value) => {
    this.setState({
      mondayFromAMPMTwo: value.target.value,
    });
  };
  mondayToAMPMChangeTwo = (value) => {
    this.setState({
      mondayToAMPMTwo: value.target.value,
    });
  };
  tuesdayFromAMPMChangeTwo = (value) => {
    this.setState({
      tuesdayFromAMPMTwo: value.target.value,
    });
  };
  tuesdayToAMPMChangeTwo = (value) => {
    this.setState({
      tuesdayToAMPMTwo: value.target.value,
    });
  };
  wednesdayFromAMPMChangeTwo = (value) => {
    this.setState({
      wednesdayFromAMPMTwo: value.target.value,
    });
  };
  wednesdayToAMPMChangeTwo = (value) => {
    this.setState({
      wednesdayToAMPMTwo: value.target.value,
    });
  };
  thursdayToAMPMChangeTwo = (value) => {
    this.setState({
      thursdayToAMPMTwo: value.target.value,
    });
  };
  thursdayFromAMPMChangeTwo = (value) => {
    this.setState({
      thursdayFromAMPMTwo: value.target.value,
    });
  };
  fridayFromAMPMChangeTwo = (value) => {
    this.setState({
      fridayFromAMPMTwo: value.target.value,
    });
  };
  fridayToAMPMChangeTwo = (value) => {
    this.setState({
      fridayToAMPMTwo: value.target.value,
    });
  };
  saterdayToAMPMChangeTwo = (value) => {
    this.setState({
      saterdayToAMPMTwo: value.target.value,
    });
  };
  saterdayFromAMPMChangeTwo = (value) => {
    this.setState({
      saterdayFromAMPMTwo: value.target.value,
    });
  };

  sundayFromMinChangeTwo = (value) => {
    this.setState({
      sundayFromMinTwo: value.target.value,
    });
  };
  sundayFromHourChangeTwo = (value) => {
    this.setState({
      sundayFromHourTwo: value.target.value,
    });
  };
  sundayToMinChangeTwo = (value) => {
    this.setState({
      sundayToMinTwo: value.target.value,
    });
  };
  sundayToHourChangeTwo = (value) => {
    this.setState({
      sundayToHourTwo: value.target.value,
    });
  };

  mondayFromMinChangeTwo = (value) => {
    this.setState({
      mondayFromMinTwo: value.target.value,
    });
  };
  mondayFromHourChangeTwo = (value) => {
    this.setState({
      mondayFromHourTwo: value.target.value,
    });
  };
  mondayToMinChangeTwo = (value) => {
    this.setState({
      mondayToMinTwo: value.target.value,
    });
  };
  mondayToHourChangeTwo = (value) => {
    this.setState({
      mondayToHourTwo: value.target.value,
    });
  };

  tuesdayFromMinChangeTwo = (value) => {
    this.setState({
      tuesdayFromMinTwo: value.target.value,
    });
  };
  tuesdayFromHourChangeTwo = (value) => {
    this.setState({
      tuesdayFromHourTwo: value.target.value,
    });
  };
  tuesdayToMinChangeTwo = (value) => {
    this.setState({
      tuesdayToMinTwo: value.target.value,
    });
  };
  tuesdayToHourChangeTwo = (value) => {
    this.setState({
      tuesdayToHourTwo: value.target.value,
    });
  };

  wednesdayFromMinChangeTwo = (value) => {
    this.setState({
      wednesdayFromMinTwo: value.target.value,
    });
  };
  wednesdayFromHourChangeTwo = (value) => {
    this.setState({
      wednesdayFromHourTwo: value.target.value,
    });
  };
  wednesdayToMinChangeTwo = (value) => {
    this.setState({
      wednesdayToMinTwo: value.target.value,
    });
  };
  wednesdayToHourChangeTwo = (value) => {
    this.setState({
      wednesdayToHourTwo: value.target.value,
    });
  };

  thursdayFromMinChangeTwo = (value) => {
    this.setState({
      thursdayFromMinTwo: value.target.value,
    });
  };
  thursdayFromHourChangeTwo = (value) => {
    this.setState({
      thursdayFromHourTwo: value.target.value,
    });
  };
  thursdayToMinChangeTwo = (value) => {
    this.setState({
      thursdayToMinTwo: value.target.value,
    });
  };
  thursdayToHourChangeTwo = (value) => {
    this.setState({
      thursdayToHourTwo: value.target.value,
    });
  };
  fridayFromMinChangeTwo = (value) => {
    this.setState({
      fridayFromMinTwo: value.target.value,
    });
  };
  fridayFromHourChangeTwo = (value) => {
    this.setState({
      fridayFromHourTwo: value.target.value,
    });
  };
  fridayToMinChangeTwo = (value) => {
    this.setState({
      fridayToMinTwo: value.target.value,
    });
  };
  fridayToHourChangeTwo = (value) => {
    this.setState({
      fridayToHourTwo: value.target.value,
    });
  };

  saterdayFromMinChangeTwo = (value) => {
    this.setState({
      saterdayFromMinTwo: value.target.value,
    });
  };
  saterdayFromHourChangeTwo = (value) => {
    this.setState({
      saterdayFromHourTwo: value.target.value,
    });
  };
  saterdayToMinChangeTwo = (value) => {
    this.setState({
      saterdayToMinTwo: value.target.value,
    });
  };
  saterdayToHourChangeTwo = (value) => {
    this.setState({
      saterdayToHourTwo: value.target.value,
    });
  };

  onChangeActionLocal = (value) => {
    this.setState({
      local: value.target.checked,
    });
  };

  onChangeActionDifferentTimes = (value) => {
    this.setState({
      differentTimes: value.target.checked,
    });
  };

  onChangeActionShowHours = (value) => {
    this.setState({
      showHours: value.target.checked,
    });
  };
  onChangeActionHostEvents = (value) => {
    this.setState({
      hostEvents: value.target.checked,
    });
  };

  onChangeActionHasDrinks = (value) => {
    this.setState({
      hasDrinks: value.target.checked,
    });
  };

  onChangeActionHasFood = (value) => {
    this.setState({
      hasFood: value.target.checked,
    });
  };

  getRegions(regions) {
    let selectOptions = [];
    selectOptions.push({
      value: 'Current Location',
      label: 'Current Location',
    });
    regions.forEach((region) => {
      if (region.zoneId === this.props.profile.zoneId)
        selectOptions.push({
          value: region.name,
          label: region.name,
        });
    });
    let sorted = selectOptions.sort((a, b) =>
      a.value.localeCompare(b.value),
    );
    return sorted;
  }

  async makeClientCropThumb(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImgThumb(
        this.imageRef,
        crop,
        'thumbnail.jpeg',
      );
      this.setState({
        thumbPath: croppedImageUrl,
      });
    }
  }

  getCroppedImgThumb(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  onTimeChange = (time) => {
    this.setState({ selectedTime: time });
  };

  setScheduleTimes(
    selectedStartTime,
    selectedEndTime,
    differentTime,
  ) {
    // Check if Time Set
    let bundle = {
      startTime: null,
      startTimeString: null,
      endTime: null,
      endTimeString: null,
      regHours: null,
    };

    if (differentTime === 'no') {
      bundle.regHours = true;
    } else {
      bundle.regHours = false;
    }

    if (
      selectedStartTime !== '' &&
      selectedStartTime !== undefined &&
      selectedStartTime !== null
    ) {
      const datestring = String(selectedStartTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      // const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.startTime = timeValue;
      bundle.startTimeString = selectedStartTime._d;
    }

    if (
      selectedEndTime !== '' &&
      selectedEndTime !== undefined &&
      selectedEndTime !== null
    ) {
      const datestring = String(selectedEndTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      // const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.endTime = timeValue;
      bundle.endTimeString = selectedEndTime._d;
    }

    return bundle;
  }

  getDateEnd(numx) {
    let num = parseInt(numx, 10);

    if (num === 1 || num === 21 || num === 31) {
      return 'st';
    }
    if (num === 2 || num === 22) {
      return 'nd';
    }
    if (num === 3 || num === 23) {
      return 'rd';
    }
    if (num === 4 || num === 24) {
      return 'rth';
    }
    if (num > 5 && num < 20) {
      return <span>th</span>;
    }
    if (num === 25) {
      return <span>th</span>;
    }
    if (
      num === 20 ||
      num === 26 ||
      num === 27 ||
      num === 28 ||
      num === 29 ||
      num === 30
    ) {
      return <span>th</span>;
    }
  }

  getDifferentTimePicker() {
    return (
      <div style={{ margin: '0px auto' }}>
        <div className="clear">
          <span className="inputLabel">Start Time</span>
          <TimePicker
            showSecond={false}
            defaultValue={this.state.selectedStartTime}
            className="xxx"
            onChange={this.onStartTimeChange}
            // format={format}
            use12Hours
            inputReadOnly
          />
        </div>

        <div className="clear">
          <span className="inputLabel">End Time</span>
          <TimePicker
            showSecond={false}
            defaultValue={this.state.selectedEndTime}
            className="xxx"
            onChange={this.onEndTimeChange}
            // format={format}
            use12Hours
            inputReadOnly
          />
        </div>
      </div>
    );
  }

  onChangeDate = (day) => {
    let status = false;
    if (this.state[day] === false || this.state[day] === undefined) {
      status = true;
    }
    let dayArray = {};
    dayArray.Sunday = { num: 0 };
    dayArray.Monday = { num: 1 };
    dayArray.Tuesday = { num: 2 };
    dayArray.Wednesday = { num: 3 };
    dayArray.Thursday = { num: 4 };
    dayArray.Friday = { num: 5 };
    dayArray.Saturday = { num: 6 };

    let currentNum = null;

    if (this.state.activeDay !== undefined) {
      Object.keys(dayArray).forEach((key) => {
        if (
          this.state.activeDay.includes(dayArray[key].num) === false
        ) {
          currentNum = dayArray[key].num;
        }
      });
    }
    let activeDay = dayArray[day].num;

    let newDays = [];

    if (this.state.activeDay === undefined) {
      let days = [0, 1, 2, 3, 4, 5, 6];
      days.forEach((day, index) => {
        if (day !== activeDay) {
          newDays.push(day);
        }
      });
    }

    if (
      this.state.activeDay !== undefined &&
      currentNum !== null &&
      currentNum < activeDay
    ) {
      newDays = this.state.activeDay;
    } else if (
      this.state.activeDay !== undefined &&
      currentNum !== null &&
      currentNum > activeDay
    ) {
      let days = [0, 1, 2, 3, 4, 5, 6];
      days.forEach((day, index) => {
        if (day !== activeDay) {
          newDays.push(day);
        }
      });
    }

    this.setState({
      [day]: status,
      activeDay: newDays,
    });
  };
  getScheduleTab = (selected, timesSelected, dates) => {
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
        margin: '0px',
      },
    };
    const today = new Date();

    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 't' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];

    let selectedDayArray = [];
    dayArray.forEach((day, index) => {
      if (this.state[day.full] === true) {
        selectedDayArray.push(day.short);
      }
    });

    return (
      <Container>
        <Row>
          {this.state.selected === 'Weekly' && (
            <div
              style={{
                clear: 'both',
                position: 'relative',
                width: '100%',
                marginTop: '10px',
                maxWidth: '700px',
              }}
              className="mobileShow"
            >
              <div>
                <Container>
                  <Row>
                    <div style={{ padding: '20px 0px 10px 0px' }}>
                      <span style={{ fontWeight: '600' }}>
                        What days do you have a Happy Hour?
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: '16px',
                        }}
                      >
                        <a
                          onClick={() => {
                            this.onChangeDate('Sunday');
                          }}
                          className={
                            this.state.Sunday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>Su</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Monday');
                          }}
                          className={
                            this.state.Monday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>M</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Tuesday');
                          }}
                          className={
                            this.state.Tuesday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>T</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Wednesday');
                          }}
                          className={
                            this.state.Wednesday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>W</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Thursday');
                          }}
                          className={
                            this.state.Thursday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>Th</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Friday');
                          }}
                          className={
                            this.state.Friday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>F</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Saturday');
                          }}
                          className={
                            this.state.Saturday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>S</span>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              {this.state.activeDay !== undefined && (
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div
                      id="vendorTimes"
                      style={{
                        marginTop: '0px',
                        float: 'left',
                        marginLeft: '10px',
                      }}
                      className="mobileShow"
                    >
                      <Row>
                        {this.getDifferentTimePicker(
                          this.state.differentTime,
                          modifiersStyles,
                          today,
                        )}
                      </Row>
                    </div>
                    <div
                      id="vendorTimes"
                      style={{ marginTop: '60px' }}
                      className="mobileHide"
                    >
                      <Row>
                        {this.getDifferentTimePicker(
                          this.state.differentTime,
                          modifiersStyles,
                          today,
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          )}
          {this.state.selected === 'Weekly' && (
            <div
              style={{
                clear: 'both',
                position: 'relative',
                width: '100%',
              }}
              className="mobileHide"
            >
              <div>
                <Container>
                  <Row>
                    <div style={{ padding: '20px 0px 10px 0px' }}>
                      <span style={{ fontWeight: '600' }}>
                        What days do you have a Happy Hour?
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <div>
                        <a
                          onClick={() => {
                            this.onChangeDate('Sunday');
                          }}
                          className={
                            this.state.Sunday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>Su</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Monday');
                          }}
                          className={
                            this.state.Monday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>M</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Tuesday');
                          }}
                          className={
                            this.state.Tuesday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>T</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Wednesday');
                          }}
                          className={
                            this.state.Wednesday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>W</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Thursday');
                          }}
                          className={
                            this.state.Thursday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>Th</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Friday');
                          }}
                          className={
                            this.state.Friday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>F</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Saturday');
                          }}
                          className={
                            this.state.Saturday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>S</span>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              {this.state.activeDay !== undefined && (
                <div>
                  <Row>
                    <div style={{ padding: '20px 0px 10px 0px' }}>
                      <span style={{ fontWeight: '600' }}>
                        What time is Happy Hour?
                      </span>
                    </div>
                  </Row>

                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                    >
                      <div
                        style={{
                          paddingBottom: '10px',
                          display: 'inline-block',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={this.state.differentTimes}
                          onChange={this.onChangeActionDifferentTimes}
                          className="closedCheckBox"
                          name="local"
                          style={{
                            transform: 'scale(2)',
                            float: 'left',
                          }}
                        />
                        <span
                          style={{
                            float: 'left',
                            position: 'relative',
                            top: '9px',
                          }}
                        >
                          Set Different Times Per Day
                        </span>
                        <ErrorMessage name="local" component="div" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                    >
                      <div id="vendorTimes" className="mobileHide">
                        {this.getTimePickers(
                          this.state.differentTimes,
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          )}
        </Row>
        {this.state.activeDay !== undefined && (
          <div>
            <Row>
              <span style={{ fontWeight: '600' }}>Description</span>
            </Row>
            <Row>
              <textarea
                type="text"
                onChange={this.detailsOnChange.bind(this)}
                value={this.state.happyHourDetails}
                style={{
                  minHeight: '150px',
                  maxWidth: '700px',
                  clear: 'both',
                  display: 'block',
                }}
              />
            </Row>
            <Row>
              <span style={{ fontWeight: '600' }}>
                Link to More Details
              </span>
            </Row>
            <Row>
              <input
                id="happyHourWebsite"
                placeholder=""
                type="text"
                value={this.state.happyHourLink}
                onChange={this.happyHourLinkOnChange.bind(this)}
                style={{
                  maxWidth: '700px',
                }}
              />
            </Row>
          </div>
        )}
      </Container>
    );
  };

  detailsOnChange(e) {
    this.setState({ happyHourDetails: e.target.value });
  }

  happyHourLinkOnChange(e) {
    this.setState({ happyHourLink: e.target.value });
  }

  getTimePickers(differentTimes) {
    let dateArray = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let activeDays = [];
    dateArray.forEach((day) => {
      if (this.state[day] === true) {
        activeDays.push(day);
      }
    });

    if (differentTimes === false) {
      return (
        <div
          style={{
            marginBottom: '15px',
            float: 'left',
            marginRight: '15px',
            background: 'white',
            padding: '10px',
            border: '1px solid grey',
          }}
        >
          <Row>
            <div>
              <span
                className="inputLabel"
                style={{
                  clear: 'both',
                  display: 'block',
                }}
              >
                From
              </span>
              <input
                name={'Same' + 'HappyHourFromTime'}
                value={this.state['Same' + 'HappyHourFromTime']}
                onChange={(value) => {
                  this.fromTimeChange(
                    'Same',
                    'HappyHourFromTime',
                    value,
                  );
                }}
                type="time"
                placeholder=""
                // min="09:00"
                // max="18:00"
                style={{
                  float: 'left',
                  minWidth: '40px',
                }}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <span
                className="inputLabel"
                style={{ clear: 'both', display: 'block' }}
              >
                To
              </span>
              <input
                name={'Same' + 'HappyHourToTime'}
                value={this.state['Same' + 'HappyHourToTime']}
                onChange={(value) => {
                  this.fromTimeChange(
                    'Same',
                    'HappyHourToTime',
                    value,
                  );
                }}
                type="time"
                placeholder=""
                // min="09:00"
                // max="18:00"
                style={{ float: 'left', minWidth: '40px' }}
              />
            </div>
          </Row>
        </div>
      );
    } else {
      return (
        <div>
          {activeDays.map((day, index) => (
            <div
              style={{
                marginBottom: '15px',
                float: 'left',
                marginRight: '15px',
                background: 'white',
                padding: '10px',
                border: '1px solid grey',
              }}
            >
              <Row>
                <span
                  className="inputLabel"
                  style={{ float: 'left', fontWeight: '600' }}
                >
                  {day}
                </span>
              </Row>
              <Row>
                <div>
                  <span
                    className="inputLabel"
                    style={{
                      clear: 'both',
                      display: 'block',
                    }}
                  >
                    From
                  </span>
                  <input
                    name={day + 'HappyHourFromTime'}
                    value={this.state[day + 'HappyHourFromTime']}
                    onChange={(value) => {
                      this.fromTimeChange(
                        day,
                        'HappyHourFromTime',
                        value,
                      );
                    }}
                    type="time"
                    placeholder=""
                    // min="09:00"
                    // max="18:00"
                    style={{
                      float: 'left',
                      minWidth: '50px',
                    }}
                  />
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <span
                    className="inputLabel"
                    style={{ clear: 'both', display: 'block' }}
                  >
                    To
                  </span>
                  <input
                    name={day + 'HappyHourToTime'}
                    value={this.state[day + 'HappyHourToTime']}
                    onChange={(value) => {
                      this.fromTimeChange(
                        day,
                        'HappyHourToTime',
                        value,
                      );
                    }}
                    type="time"
                    placeholder=""
                    // min="09:00"
                    // max="18:00"
                    style={{ float: 'left', minWidth: '50px' }}
                  />
                </div>
              </Row>
            </div>
          ))}
        </div>
      );
    }
  }

  fromTimeChange = (catId, day, value) => {
    let combo = catId + day;
    this.setState({
      [combo]: value.target.value,
    });
  };

  getThumbnailCropper(thumbnailCropping) {
    const { cropThumb, croppedImageUrl, src } = this.state;
    if (thumbnailCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropperThumb();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropperThumb();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={cropThumb}
                onImageLoaded={this.onImageLoadedThumb}
                onComplete={this.onCropCompleteThumb}
                onChange={this.onCropChangeThumb}
              />
            )}
          </div>
        </div>
      );
    }
  }
  //End Thumb Crop
  cancelCropper() {
    this.setState({
      cropping: false,
    });
  }

  saveCropper() {
    this.uploadImage(this.state.blob, 'hero');
    this.setState({
      cropping: false,
    });
  }

  cancelCropperThumb() {
    this.setState({
      croppingThumb: false,
    });
  }

  saveCropperThumb() {
    this.uploadImage(this.state.blob, 'thumb');
    this.setState({
      croppingThumb: false,
    });
  }

  uploadImage = (image, type) => {
    const uploadUrl =
      'vendor_images/' +
      this.props.vendor.vendorId +
      '/' +
      this.state.dbref +
      '/' +
      image.name;
    // const uploadUrl =
    //   'event_images/' + this.state.dbref + '/' + image.name;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadUrl);
    imageRef.put(image).then((snapshot) => {
      // const path = snapshot.ref.location.path_;
      if (type === 'hero') {
        let heroImagePath =
          'vendor_images%2F' +
          this.props.vendor.vendorId +
          '%2F' +
          this.state.dbref +
          '%2FheroImage.jpeg';
        let heroPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          heroImagePath +
          '?alt=media';
        this.setState({
          heroPath,
          customHero: true,
        });
      } else {
        let thumbnailImagePath =
          'vendor_images%2F' +
          this.props.vendor.vendorId +
          '%2F' +
          this.state.dbref +
          '%2Fthumbnail.jpeg';
        let thumbPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          thumbnailImagePath +
          '?alt=media';
        this.setState({
          thumbPath,
          customThumb: true,
        });
      }
    });
  };

  // Crop Functions
  getHeroImage() {
    if (this.state.heroPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '100%' }}
          src={this.state.heroPath}
        />
      );
    }
  }

  chooseRestaurante(event) {
    if (event.target.value === 'yes') {
      let newArray = [];
      let object = {
        value: 'Restaurant',
        label: 'Restaurant',
      };
      newArray.push(object);
      this.setState({
        locationType: newArray,
      });
    }
    this.setState({
      restaurant: event.target.value,
    });
  }

  getThumbnail() {
    if (this.state.thumbPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '100%' }}
          src={this.state.thumbPath}
        />
      );
    }
  }

  getStep(step, eventType, selected, timesSelected, dates) {
    let regions = this.getRegions(this.props.regions);
    const { editorState, editorStateLong } = this.state;
    if (step === 0) {
      return (
        <div id="locationForm">
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                {this.getId(this.props.mode)}
                <div className="section no-padding">
                  <span className="inputLabel">Business Name</span>
                  <Field
                    type="title"
                    name="title"
                    placeholder={this.state.title}
                  />
                  <ErrorMessage name="title" component="div" />
                  <span className="inputLabel">Description</span>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      // inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      options: [
                        'inline',
                        'blockType',
                        'list',
                        'colorPicker',
                        'link',
                        'remove',
                        'history',
                      ],
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">Street</span>
                  <Field
                    type="street"
                    name="street"
                    placeholder={this.state.street}
                  />
                  <ErrorMessage name="street" component="div" />
                  <span className="inputLabel">City</span>
                  <Field
                    type="city"
                    name="city"
                    placeholder={this.state.city}
                  />
                  <ErrorMessage name="city" component="div" />
                  <span className="inputLabel">State</span>
                  <Field
                    type="state"
                    name="state"
                    placeholder={this.state.state}
                  />
                  <ErrorMessage name="state" component="div" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">Zip Code</span>
                  <Field
                    type="zip"
                    name="zip"
                    placeholder={this.state.zip}
                  />
                  <ErrorMessage name="zip" component="div" />
                  {this.props.profile.userType === 1 && (
                    <div>
                      <span className="inputLabel">Longitude</span>
                      <Field
                        type="longitude"
                        name="longitude"
                        placeholder={this.state.longitude}
                      />
                      <ErrorMessage
                        name="longitude"
                        component="div"
                      />
                      <span className="inputLabel">Latitude</span>
                      <Field
                        type="latitude"
                        name="latitude"
                        placeholder={this.state.latitude}
                      />
                      <ErrorMessage name="latitude" component="div" />
                    </div>
                  )}
                  <div>
                    <span className="inputLabel">
                      Region / Area of City: (Optional)
                    </span>

                    <span
                      className="left"
                      style={{
                        width: '300px',
                        height: '50px',
                      }}
                    >
                      <Select
                        value={this.state.selectedRegion}
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectRegionChange(e, 0, 'one')
                        }
                        options={regions}
                        // onInputChange={(e) =>
                        //   this.moodTextChange(e, 0, 'one')
                        // }
                        isMulti={false}
                        placeholder={'Select area of City'}
                      />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="no-padding"
              >
                <hr />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div
                  style={{
                    paddingBottom: '10px',
                    display: 'inline-block',
                  }}
                >
                  <span className="inputLabel">
                    Are you local to the {this.getCity()} area?
                  </span>

                  <input
                    type="checkbox"
                    checked={this.state.local}
                    onChange={this.onChangeActionLocal}
                    className="closedCheckBox"
                    name="local"
                    style={{ transform: 'scale(2)', float: 'left' }}
                  />
                  <span
                    style={{
                      float: 'left',
                      position: 'relative',
                      top: '9px',
                    }}
                  >
                    Yes
                  </span>
                  <ErrorMessage name="local" component="div" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">Phone</span>
                  <Field
                    type="phone"
                    name="phone"
                    placeholder="Phone Number"
                  />
                  <ErrorMessage name="phone" component="div" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">Website</span>
                  <Field
                    type="phone"
                    name="website"
                    placeholder={this.state.website}
                  />
                  <ErrorMessage name="website" component="div" />
                  {/* <span className="inputLabel">Main Contact Name</span>
                      <Field
                        type="text"
                        name="contactName"
                        placeholder="Name"
                      />
                      <ErrorMessage name="contactName" component="div" />
                      <span className="inputLabel">Main Contact Phone</span>
                      <Field
                        type="text"
                        name="contactPhone"
                        placeholder="Phone Number"
                      />
                      <ErrorMessage name="contactPhone" component="div" />
                      <span className="inputLabel">Main Contact Email</span>
                      <Field
                        type="text"
                        name="contactEmail"
                        placeholder="Email Address"
                      />
                      <ErrorMessage name="contactEmail" component="div" /> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">Link to Menu</span>
                  <Field
                    type="text"
                    name="menuLink"
                    placeholder={this.state.menuLink}
                  />
                  <ErrorMessage name="website" component="div" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (step === 2) {
      return (
        <Container id="imageUpload">
          <Row>
            <Col
              lg={12}
              md={6}
              sm={12}
              xs={12}
              className="left"
              style={{ marginTop: '10px' }}
            >
              <span className="title">Images</span>
              <p>
                You can skip uploading images for now, but you will
                need to add them before your page can be published.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <span className="inputLabel">Banner Image</span>
              {this.getHeroImage()}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <div>
                <input type="file" onChange={this.onSelectFile} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <span className="inputLabel">Thumbnail Image</span>
              {this.getThumbnail()}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <div>
                <input
                  type="file"
                  onChange={this.onSelectFileThumb}
                />
              </div>
            </Col>
          </Row>
        </Container>
      );
    } else if (step === 3) {
      return (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {/* <div className="section no-padding">
              <span className="section-header">Categories</span>
            </div> */}
            <div
              className="section no-padding"
              style={{
                padding: '10px 0px 0px 0px',
                margin: '10px 0px 10px 0px',
              }}
            >
              <div>
                <div
                  style={{
                    padding: '10px 0px 0px 0px',
                    margin: '10px 0px 10px 0px',
                    clear: 'both',
                  }}
                >
                  <span className="title">
                    What type of location is this?
                  </span>
                </div>
                {this.props.tags.map((cattag, index) => (
                  <div>
                    {this.getCategoryFieldsLocationType(cattag)}
                  </div>
                ))}
              </div>

              <div
                style={{
                  paddingBottom: '10px',
                  display: 'inline-block',
                  marginRight: '20px',
                }}
              >
                <span className="inputLabel">Do you serve food?</span>

                <input
                  type="checkbox"
                  checked={this.state.hasFood}
                  onChange={this.onChangeActionHasFood}
                  className="closedCheckBox"
                  name="hasDrinks"
                  style={{ transform: 'scale(2)', float: 'left' }}
                />
                <span
                  style={{
                    float: 'left',
                    position: 'relative',
                    top: '9px',
                  }}
                >
                  Yes
                </span>
                <ErrorMessage name="local" component="div" />
              </div>
              <div
                style={{
                  paddingBottom: '10px',
                  display: 'inline-block',
                }}
              >
                <span className="inputLabel">
                  Do you serve drinks?
                </span>

                <input
                  type="checkbox"
                  checked={this.state.hasDrinks}
                  onChange={this.onChangeActionHasDrinks}
                  className="closedCheckBox"
                  name="hasDrinks"
                  style={{ transform: 'scale(2)', float: 'left' }}
                />
                <span
                  style={{
                    float: 'left',
                    position: 'relative',
                    top: '9px',
                  }}
                >
                  Yes
                </span>
                <ErrorMessage name="local" component="div" />
              </div>

              {/* <span className="title">Is this a restaurant?</span>
              <div
                style={{
                  padding: '10px 0px 0px 0px',
                  margin: '10px 0px 10px 0px',
                }}
              >
                <div
                  style={{
                    margin: '0px 10px 0px 0px',
                    float: 'left',
                  }}
                >
                  <label>
                    <input
                      type="radio"
                      value="no"
                      checked={this.state.restaurant === 'no'}
                      onChange={this.chooseRestaurante.bind(this)}
                      style={{
                        transform: 'scale(2)',
                        marginRight: '10px',
                      }}
                    />
                    No
                  </label>
                </div>
                <div
                  style={{
                    margin: '0px 20px 0px 0px',
                    float: 'left',
                  }}
                >
                  <label>
                    <input
                      type="radio"
                      value="yes"
                      checked={this.state.restaurant === 'yes'}
                      onChange={this.chooseRestaurante.bind(this)}
                      style={{
                        transform: 'scale(2)',
                        marginRight: '10px',
                        marginLeft: '20px',
                      }}
                    />
                    Yes
                  </label>
                </div>
              </div> */}

              {this.state.restaurant === 'no' && (
                <div>
                  <div
                    style={{
                      padding: '10px 0px 0px 0px',
                      margin: '10px 0px 10px 0px',
                      clear: 'both',
                    }}
                  >
                    <span className="title">
                      What type of location is this?
                    </span>
                  </div>
                  {this.props.tags.map((cattag, index) => (
                    <div>
                      {this.getCategoryFieldsLocationType(cattag)}
                    </div>
                  ))}
                </div>
              )}
              <div>{this.getCategoryFieldsRestaurant()}</div>

              <div
                style={{
                  padding: '10px 0px 0px 0px',
                  margin: '30px 0px 10px 0px',
                }}
              >
                <span className="title">
                  What best describes experiences at this location?
                  (Choose all that apply)
                </span>
                {this.props.tags.map((cattag, index) => (
                  <div>{this.getCategoryFields(cattag)}</div>
                ))}
              </div>

              <ErrorMessage name="categories" component="div" />
            </div>
            {/* <div
              style={{
                padding: '10px 0px 0px 0px',
                margin: '30px 0px 60px 0px',
              }}
            >
              <span className="title">
                What should your Main Tag be?
              </span>

              <div>{this.getMainTag(this.props.tags)}</div>
            </div> */}

            <ErrorMessage name="categories" component="div" />

            {/* <Container>
              <Row className="noticeboxBottom">
                <Col xs={12} md={12} sm={12} lg={12}>
                  <span className="bold">
                    Recommend Additional Tags
                  </span>
                  <p>
                    Our goal is to connect our users (your customers)
                    to the experiences we know they'll love. The way
                    we tag our locations and our users plays an
                    important part in making that recommendation.{' '}
                  </p>
                  <p>
                    If you have any suggestions on additional tags to
                    add, or for entirely new categories of options,
                    please let us know. Our mission is to provide
                    continual improvement to our product based on the
                    needs of our users and location partners and we
                    value all feedback you might have.
                  </p>

                  <Form>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          md={12}
                          sm={12}
                          lg={12}
                          className="left"
                        >
                          <textarea
                            type="text"
                            name="feedback"
                            placeholder="Suggestion...Feedback...Comment"
                            onChange={this.handleChange}
                            value={this.state.feedback}
                          />
                          <ErrorMessage
                            name="feedback"
                            component="div"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12} sm={12} lg={12}>
                          <div className="submitarea">
                            <center>
                              <span
                                type="submit"
                                onClick={() => {
                                  this.submitFeedback(
                                    this.state.feedback,
                                  );
                                }}
                                className="left redbutton"
                              >
                                Send
                              </span>
                            </center>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Form>

                  <Row />
                </Col>
              </Row>
            </Container> */}
          </Col>
        </Row>
      );
    } else if (step === 4) {
      // let musicCheck = this.checkForMusic(this.state.eventType);
      // console.log('checkForMusic', musicCheck);

      return (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                paddingBottom: '10px',
                display: 'inline-block',
              }}
            >
              <input
                type="checkbox"
                checked={this.state.hostEvents}
                onChange={this.onChangeActionHostEvents}
                className="closedCheckBox"
                name="hostEvents"
                style={{ transform: 'scale(2)', float: 'left' }}
              />
              <span
                style={{
                  float: 'left',
                  position: 'relative',
                  top: '9px',
                }}
              >
                Do you host events with entertainment?
              </span>
              <ErrorMessage name="local" component="div" />
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                paddingBottom: '10px',
                display: 'inline-block',
              }}
            >
              {this.state.hostEvents === true && (
                <div>
                  <div
                    style={{
                      padding: '10px 0px 0px 0px',
                      margin: '30px 0px 10px 0px',
                    }}
                  >
                    <h4
                      style={{ fontWeight: '600', fontSize: '18px' }}
                    >
                      Entertainment Types
                    </h4>
                    <span className="title">
                      What type of entertainment do you host?
                    </span>
                    {this.props.tags.map((cattag, index) => (
                      <div>
                        {this.getEntertainmentTags(cattag, eventType)}
                      </div>
                    ))}
                  </div>

                  <div>
                    <div
                      style={{
                        padding: '10px 0px 0px 0px',
                        margin: '30px 0px 10px 0px',
                      }}
                    >
                      <h4
                        style={{
                          fontWeight: '600',
                          fontSize: '18px',
                        }}
                      >
                        Genres
                      </h4>
                      <span className="title">
                        Which genres of entertainment do you often
                        host? (Optional if applies to you.)
                      </span>
                      {this.props.tags.map((cattag, index) => (
                        <div>{this.getGenreTags(cattag)}</div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      );
    } else if (step === 6) {
      return (
        <div>
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                {this.getScheduleTab(selected, timesSelected, dates)}
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (step === 5) {
      return (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <span className="inputLabel">
              Our Story{' '}
              <span style={{ color: 'grey', fontSize: '12px' }}>
                (Optional)
              </span>
            </span>
            <Editor
              editorState={editorStateLong}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorLong"
              onEditorStateChange={this.onEditorStateChangeLong}
              toolbar={{
                // inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                options: [
                  'inline',
                  'blockType',
                  'list',
                  'colorPicker',
                  'link',
                  'remove',
                  'history',
                ],
              }}
            />
          </Col>
        </Row>
      );
    } else if (step === 1) {
      return (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                paddingBottom: '10px',
                display: 'inline-block',
              }}
            >
              <input
                type="checkbox"
                checked={this.state.showHours}
                onChange={this.onChangeActionShowHours}
                className="closedCheckBox"
                name="local"
                style={{ transform: 'scale(2)', float: 'left' }}
              />
              <span
                style={{
                  float: 'left',
                  position: 'relative',
                  top: '9px',
                }}
              >
                Show Hours
              </span>
              <ErrorMessage name="local" component="div" />
            </div>
          </Col>
          {this.state.showHours === true && (
            <div>
              <Col lg={3} md={3} sm={6} xs={12}>
                <div
                  style={{
                    margin: '20px 0px 20px 0px',
                    background: 'white',
                    border: '1px solid #a1a1a1',
                    padding: '10px',
                  }}
                >
                  <span className="title">Sunday</span>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Closed
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state.sundayOpen}
                        onChange={this.onChangeActionSunday}
                        className="closedCheckBox"
                      />
                    </Col>
                  </Row>
                  {this.state.sundayOpen === false && (
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          From
                        </span>
                        <input
                          name="sundayFromHour"
                          value={this.state.sundayFromHour}
                          onChange={this.sundayFromHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="sundayFromMin"
                          value={this.state.sundayFromMin}
                          onChange={this.sundayFromMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="00"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="sundayFromAMPM"
                          id="ampm1"
                          onChange={this.sundayFromAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.sundayFromAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          To
                        </span>
                        <input
                          name="sundayFromHour"
                          value={this.state.sundayToHour}
                          onChange={this.sundayToHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="sundayFromMin"
                          value={this.state.sundayToMin}
                          onChange={this.sundayToMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="sundayToAMPM"
                          id="ampm1"
                          onChange={this.sundayToAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.sundayToAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>

                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <hr />
                        <span
                          className="inputLabel"
                          style={{ float: 'left' }}
                        >
                          Add Second Time Set
                        </span>
                        <input
                          type="checkbox"
                          checked={this.state.sundayTwo}
                          onChange={this.onChangeActionSundayTwo}
                          className="closedCheckBox"
                        />
                      </Col>

                      {this.state.sundayTwo === true && (
                        <div>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              From
                            </span>
                            <input
                              name="sundayFromHour"
                              value={this.state.sundayFromHourTwo}
                              onChange={this.sundayFromHourChangeTwo}
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="sundayFromMin"
                              value={this.state.sundayFromMinTwo}
                              onChange={this.sundayFromMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="00"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="sundayFromAMPM"
                              id="ampm1"
                              onChange={this.sundayFromAMPMChangeTwo}
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.sundayFromAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              To
                            </span>
                            <input
                              name="sundayFromHourTwo"
                              value={this.state.sundayToHourTwo}
                              onChange={this.sundayToHourChangeTwo}
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="sundayFromMin"
                              value={this.state.sundayToMinTwo}
                              onChange={this.sundayToMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="0"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="sundayToAMPM"
                              id="ampm1"
                              onChange={this.sundayToAMPMChangeTwo}
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.sundayToAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                        </div>
                      )}
                    </Row>
                  )}
                </div>
              </Col>

              <Col lg={3} md={3} sm={6} xs={12}>
                <div
                  style={{
                    margin: '20px 0px 20px 0px',
                    background: 'white',
                    border: '1px solid #a1a1a1',
                    padding: '10px',
                  }}
                >
                  <span className="title">Monday</span>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Closed
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state.mondayOpen}
                        onChange={this.onChangeActionMonday}
                        className="closedCheckBox"
                      />
                    </Col>
                  </Row>
                  {this.state.mondayOpen === false && (
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          From
                        </span>
                        <input
                          name="mondayFromHour"
                          value={this.state.mondayFromHour}
                          onChange={this.mondayFromHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="mondayFromMin"
                          value={this.state.mondayFromMin}
                          onChange={this.mondayFromMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="mondayFromAMPM"
                          id="ampm1"
                          onChange={this.mondayFromAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.mondayFromAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          To
                        </span>
                        <input
                          name="sundayFromHour"
                          value={this.state.mondayToHour}
                          onChange={this.mondayToHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="sundayFromMin"
                          value={this.state.mondayToMin}
                          onChange={this.mondayToMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="mondayToAMPM"
                          id="ampm1"
                          onChange={this.mondayToAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.mondayToAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <div>
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="no-padding"
                        >
                          <hr />
                          <span
                            className="inputLabel"
                            style={{ float: 'left' }}
                          >
                            Add Second Time Set
                          </span>
                          <input
                            type="checkbox"
                            checked={this.state.mondayTwo}
                            onChange={this.onChangeActionMondayTwo}
                            className="closedCheckBox"
                          />
                        </Col>

                        {this.state.mondayTwo === true && (
                          <div>
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="no-padding"
                            >
                              {' '}
                              <span
                                className="inputLabel"
                                style={{
                                  clear: 'both',
                                  display: 'block',
                                }}
                              >
                                From
                              </span>
                              <input
                                name="mondayFromHour"
                                value={this.state.mondayFromHourTwo}
                                onChange={
                                  this.mondayFromHourChangeTwo
                                }
                                type="number"
                                placeholder=""
                                max="12"
                                min="1"
                                style={{
                                  float: 'left',
                                  minWidth: '40px',
                                }}
                              />
                              <span style={{ float: 'left' }}>
                                {''}:{''}
                              </span>
                              <input
                                name="mondayFromMin"
                                value={this.state.mondayFromMinTwo}
                                onChange={this.mondayFromMinChangeTwo}
                                type="number"
                                placeholder=""
                                max="59"
                                min="00"
                                style={{
                                  float: 'left',
                                  minWidth: '40px',
                                }}
                              />
                              <select
                                name="mondayFromAMPM"
                                id="ampm1"
                                onChange={
                                  this.mondayFromAMPMChangeTwo
                                }
                                style={{ margin: '0px 0px 0px 10px' }}
                                value={this.state.mondayFromAMPMTwo}
                              >
                                <option value="am">A.M</option>
                                <option value="pm">P.M</option>
                              </select>
                            </Col>

                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="no-padding"
                            >
                              {' '}
                              <span
                                className="inputLabel"
                                style={{
                                  clear: 'both',
                                  display: 'block',
                                }}
                              >
                                To
                              </span>
                              <input
                                name="mondayFromHourTwo"
                                value={this.state.mondayToHourTwo}
                                onChange={this.mondayToHourChangeTwo}
                                type="number"
                                placeholder=""
                                max="12"
                                min="1"
                                style={{
                                  float: 'left',
                                  minWidth: '40px',
                                }}
                              />
                              <span style={{ float: 'left' }}>
                                {''}:{''}
                              </span>
                              <input
                                name="mondayFromMin"
                                value={this.state.mondayToMinTwo}
                                onChange={this.mondayToMinChangeTwo}
                                type="number"
                                placeholder=""
                                max="59"
                                min="0"
                                style={{
                                  float: 'left',
                                  minWidth: '40px',
                                }}
                              />
                              <select
                                name="mondayToAMPM"
                                id="ampm1"
                                onChange={this.mondayToAMPMChangeTwo}
                                style={{ margin: '0px 0px 0px 10px' }}
                                value={this.state.mondayToAMPMTwo}
                              >
                                <option value="am">A.M</option>
                                <option value="pm">P.M</option>
                              </select>
                            </Col>
                          </div>
                        )}
                      </div>
                    </Row>
                  )}
                </div>
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <div
                  style={{
                    margin: '20px 0px 20px 0px',
                    background: 'white',
                    border: '1px solid #a1a1a1',
                    padding: '10px',
                  }}
                >
                  <span className="title">Tuesday</span>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Closed
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state.tuesdayOpen}
                        onChange={this.onChangeActionTuesday}
                        className="closedCheckBox"
                      />
                    </Col>
                  </Row>
                  {this.state.tuesdayOpen === false && (
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          From
                        </span>
                        <input
                          name="tuesdayFromHour"
                          value={this.state.tuesdayFromHour}
                          onChange={this.tuesdayFromHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="tuesdayFromMin"
                          value={this.state.tuesdayFromMin}
                          onChange={this.tuesdayFromMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="tuesdayFromAMPM"
                          id="ampm1"
                          onChange={this.tuesdayFromAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.tuesdayFromAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          To
                        </span>
                        <input
                          name="tuesdayFromHour"
                          value={this.state.tuesdayToHour}
                          onChange={this.tuesdayToHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="tuesdayFromMin"
                          value={this.state.tuesdayToMin}
                          onChange={this.tuesdayToMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="tuesdayToAMPM"
                          id="ampm1"
                          onChange={this.tuesdayToAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.tuesdayToAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <hr />
                        <span
                          className="inputLabel"
                          style={{ float: 'left' }}
                        >
                          Add Second Time Set
                        </span>
                        <input
                          type="checkbox"
                          checked={this.state.tuesdayTwo}
                          onChange={this.onChangeActionTuesdayTwo}
                          className="closedCheckBox"
                        />
                      </Col>

                      {this.state.tuesdayTwo === true && (
                        <div>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              From
                            </span>
                            <input
                              name="tuesdayFromHour"
                              value={this.state.tuesdayFromHourTwo}
                              onChange={this.tuesdayFromHourChangeTwo}
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="tuesdayFromMin"
                              value={this.state.tuesdayFromMinTwo}
                              onChange={this.tuesdayFromMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="00"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="tuesdayFromAMPM"
                              id="ampm1"
                              onChange={this.tuesdayFromAMPMChangeTwo}
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.tuesdayFromAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              To
                            </span>
                            <input
                              name="tuesdayFromHourTwo"
                              value={this.state.tuesdayToHourTwo}
                              onChange={this.tuesdayToHourChangeTwo}
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="tuesdayFromMin"
                              value={this.state.tuesdayToMinTwo}
                              onChange={this.tuesdayToMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="0"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="tuesdayToAMPM"
                              id="ampm1"
                              onChange={this.tuesdayToAMPMChangeTwo}
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.tuesdayToAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                        </div>
                      )}
                    </Row>
                  )}
                </div>
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <div
                  style={{
                    margin: '20px 0px 20px 0px',
                    background: 'white',
                    border: '1px solid #a1a1a1',
                    padding: '10px',
                  }}
                >
                  <span className="title">Wednesday</span>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Closed
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state.wednesdayOpen}
                        onChange={this.onChangeActionWednesday}
                        className="closedCheckBox"
                      />
                    </Col>
                  </Row>
                  {this.state.wednesdayOpen === false && (
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          From
                        </span>
                        <input
                          name="wednesdayFromHour"
                          value={this.state.wednesdayFromHour}
                          onChange={this.wednesdayFromHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="wednesdayFromMin"
                          value={this.state.wednesdayFromMin}
                          onChange={this.wednesdayFromMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="wednesdayFromAMPM"
                          id="ampm1"
                          onChange={this.wednesdayFromAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.wednesdayFromAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          To
                        </span>
                        <input
                          name="wednesdayFromHour"
                          value={this.state.wednesdayToHour}
                          onChange={this.wednesdayToHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="wednesdayFromMin"
                          value={this.state.wednesdayToMin}
                          onChange={this.wednesdayToMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="wednesdayToAMPM"
                          id="ampm1"
                          onChange={this.wednesdayToAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.wednesdayToAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <hr />
                        <span
                          className="inputLabel"
                          style={{ float: 'left' }}
                        >
                          Add Second Time Set
                        </span>
                        <input
                          type="checkbox"
                          checked={this.state.wednesdayTwo}
                          onChange={this.onChangeActionWednesdayTwo}
                          className="closedCheckBox"
                        />
                      </Col>

                      {this.state.wednesdayTwo === true && (
                        <div>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              From
                            </span>
                            <input
                              name="wednesdayFromHour"
                              value={this.state.wednesdayFromHourTwo}
                              onChange={
                                this.wednesdayFromHourChangeTwo
                              }
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="wednesdayFromMin"
                              value={this.state.wednesdayFromMinTwo}
                              onChange={
                                this.wednesdayFromMinChangeTwo
                              }
                              type="number"
                              placeholder=""
                              max="59"
                              min="00"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="wednesdayFromAMPM"
                              id="ampm1"
                              onChange={this.wednesdayFromAMPMChange}
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.wednesdayFromAMPM}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              To
                            </span>
                            <input
                              name="wednesdayFromHourTwo"
                              value={this.state.wednesdayToHourTwo}
                              onChange={this.wednesdayToHourChangeTwo}
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="wednesdayFromMin"
                              value={this.state.wednesdayToMinTwo}
                              onChange={this.wednesdayToMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="0"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="wednesdayToAMPM"
                              id="ampm1"
                              onChange={this.wednesdayToAMPMChangeTwo}
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.wednesdayToAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                        </div>
                      )}
                    </Row>
                  )}
                </div>
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <div
                  style={{
                    margin: '20px 0px 20px 0px',
                    background: 'white',
                    border: '1px solid #a1a1a1',
                    padding: '10px',
                  }}
                >
                  <span className="title">Thursday</span>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Closed
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state.thursdayOpen}
                        onChange={this.onChangeActionThursday}
                        className="closedCheckBox"
                      />
                    </Col>
                  </Row>
                  {this.state.thursdayOpen === false && (
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          From
                        </span>
                        <input
                          name="thursdayFromHour"
                          value={this.state.thursdayFromHour}
                          onChange={this.thursdayFromHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="thursdayFromMin"
                          value={this.state.thursdayFromMin}
                          onChange={this.thursdayFromMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="thursdayFromAMPM"
                          id="ampm1"
                          onChange={this.thursdayFromAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.thursdayFromAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          To
                        </span>
                        <input
                          name="thursdayFromHour"
                          value={this.state.thursdayToHour}
                          onChange={this.thursdayToHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="thursdayFromMin"
                          value={this.state.thursdayToMin}
                          onChange={this.thursdayToMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="thursdayToAMPM"
                          id="ampm1"
                          onChange={this.thursdayToAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.thursdayToAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <hr />
                        <span
                          className="inputLabel"
                          style={{ float: 'left' }}
                        >
                          Add Second Time Set
                        </span>
                        <input
                          type="checkbox"
                          checked={this.state.thursdayTwo}
                          onChange={this.onChangeActionThursdayTwo}
                          className="closedCheckBox"
                        />
                      </Col>

                      {this.state.thursdayTwo === true && (
                        <div>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              From
                            </span>
                            <input
                              name="thursdayFromHour"
                              value={this.state.thursdayFromHourTwo}
                              onChange={
                                this.thursdayFromHourChangeTwo
                              }
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="thursdayFromMin"
                              value={this.state.thursdayFromMinTwo}
                              onChange={this.thursdayFromMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="00"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="thursdayFromAMPM"
                              id="ampm1"
                              onChange={
                                this.thursdayFromAMPMChangeTwo
                              }
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.thursdayFromAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              To
                            </span>
                            <input
                              name="thursdayFromHourTwo"
                              value={this.state.thursdayToHourTwo}
                              onChange={this.thursdayToHourChangeTwo}
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="thursdayFromMin"
                              value={this.state.thursdayToMinTwo}
                              onChange={this.thursdayToMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="0"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="thursdayToAMPM"
                              id="ampm1"
                              onChange={this.thursdayToAMPMChangeTwo}
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.thursdayToAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                        </div>
                      )}
                    </Row>
                  )}
                </div>
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <div
                  style={{
                    margin: '20px 0px 20px 0px',
                    background: 'white',
                    border: '1px solid #a1a1a1',
                    padding: '10px',
                  }}
                >
                  <span className="title">Friday</span>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Closed
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state.fridaydayOpen}
                        onChange={this.onChangeActionFriday}
                        className="closedCheckBox"
                      />
                    </Col>
                  </Row>
                  {this.state.fridayOpen === false && (
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          From
                        </span>
                        <input
                          name="fridayFromHour"
                          value={this.state.fridayFromHour}
                          onChange={this.fridayFromHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="fridayFromMin"
                          value={this.state.fridayFromMin}
                          onChange={this.fridayFromMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="fridayFromAMPM"
                          id="ampm1"
                          onChange={this.fridayFromAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.fridayFromAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          To
                        </span>
                        <input
                          name="fridayFromHour"
                          value={this.state.fridayToHour}
                          onChange={this.fridayToHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="fridayFromMin"
                          value={this.state.fridayToMin}
                          onChange={this.fridayToMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="fridayToAMPM"
                          id="ampm1"
                          onChange={this.fridayToAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.fridayToAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <hr />
                        <span
                          className="inputLabel"
                          style={{ float: 'left' }}
                        >
                          Add Second Time Set
                        </span>
                        <input
                          type="checkbox"
                          checked={this.state.fridayTwo}
                          onChange={this.onChangeActionFridayTwo}
                          className="closedCheckBox"
                        />
                      </Col>

                      {this.state.fridayTwo === true && (
                        <div>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              From
                            </span>
                            <input
                              name="fridayFromHour"
                              value={this.state.fridayFromHourTwo}
                              onChange={this.fridayFromHourChangeTwo}
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="fridayFromMin"
                              value={this.state.fridayFromMinTwo}
                              onChange={this.fridayFromMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="00"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="fridayFromAMPM"
                              id="ampm1"
                              onChange={this.fridayFromAMPMChangeTwo}
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.fridayFromAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              To
                            </span>
                            <input
                              name="fridayFromHourTwo"
                              value={this.state.fridayToHourTwo}
                              onChange={this.fridayToHourChangeTwo}
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="fridayFromMin"
                              value={this.state.fridayToMinTwo}
                              onChange={this.fridayToMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="0"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="fridayToAMPM"
                              id="ampm1"
                              onChange={this.fridayToAMPMChangeTwo}
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.fridayToAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                        </div>
                      )}
                    </Row>
                  )}
                </div>
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <div
                  style={{
                    margin: '20px 0px 20px 0px',
                    background: 'white',
                    border: '1px solid #a1a1a1',
                    padding: '10px',
                  }}
                >
                  <span className="title">Saturday</span>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                    >
                      <span
                        className="inputLabel"
                        style={{ float: 'left' }}
                      >
                        Closed
                      </span>
                      <input
                        type="checkbox"
                        checked={this.state.saterdayOpen}
                        onChange={this.onChangeActionSaterday}
                        className="closedCheckBox"
                      />
                    </Col>
                  </Row>
                  {this.state.saterdayOpen === false && (
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          From
                        </span>
                        <input
                          name="saterdayFromHour"
                          value={this.state.saterdayFromHour}
                          onChange={this.saterdayFromHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="saterdayFromMin"
                          value={this.state.saterdayFromMin}
                          onChange={this.saterdayFromMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="saterdayFromAMPM"
                          id="ampm1"
                          onChange={this.saterdayFromAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.saterdayFromAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        {' '}
                        <span
                          className="inputLabel"
                          style={{ clear: 'both', display: 'block' }}
                        >
                          To
                        </span>
                        <input
                          name="saterdayFromHour"
                          value={this.state.saterdayToHour}
                          onChange={this.saterdayToHourChange}
                          type="number"
                          placeholder=""
                          max="12"
                          min="1"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <span style={{ float: 'left' }}>
                          {''}:{''}
                        </span>
                        <input
                          name="saterdayFromMin"
                          value={this.state.saterdayToMin}
                          onChange={this.saterdayToMinChange}
                          type="number"
                          placeholder=""
                          max="59"
                          min="0"
                          style={{ float: 'left', minWidth: '40px' }}
                        />
                        <select
                          name="saterdayToAMPM"
                          id="ampm1"
                          onChange={this.saterdayToAMPMChange}
                          style={{ margin: '0px 0px 0px 10px' }}
                          value={this.state.saterdayToAMPM}
                        >
                          <option value="am">A.M</option>
                          <option value="pm">P.M</option>
                        </select>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <hr />
                        <span
                          className="inputLabel"
                          style={{ float: 'left' }}
                        >
                          Add Second Time Set
                        </span>
                        <input
                          type="checkbox"
                          checked={this.state.saterdayTwo}
                          onChange={this.onChangeActionSaterdayTwo}
                          className="closedCheckBox"
                        />
                      </Col>

                      {this.state.saterdayTwo === true && (
                        <div>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              From
                            </span>
                            <input
                              name="saterdayFromHour"
                              value={this.state.saterdayFromHourTwo}
                              onChange={
                                this.saterdayFromHourChangeTwo
                              }
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="saterdayFromMin"
                              value={this.state.saterdayFromMinTwo}
                              onChange={this.saterdayFromMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="00"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="saterdayFromAMPM"
                              id="ampm1"
                              onChange={
                                this.saterdayFromAMPMChangeTwo
                              }
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.saterdayFromAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="no-padding"
                          >
                            {' '}
                            <span
                              className="inputLabel"
                              style={{
                                clear: 'both',
                                display: 'block',
                              }}
                            >
                              To
                            </span>
                            <input
                              name="saterdayFromHourTwo"
                              value={this.state.saterdayToHourTwo}
                              onChange={this.saterdayToHourChangeTwo}
                              type="number"
                              placeholder=""
                              max="12"
                              min="1"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <span style={{ float: 'left' }}>
                              {''}:{''}
                            </span>
                            <input
                              name="saterdayFromMin"
                              value={this.state.saterdayToMinTwo}
                              onChange={this.saterdayToMinChangeTwo}
                              type="number"
                              placeholder=""
                              max="59"
                              min="0"
                              style={{
                                float: 'left',
                                minWidth: '40px',
                              }}
                            />
                            <select
                              name="saterdayToAMPM"
                              id="ampm1"
                              onChange={this.saterdayToAMPMChangeTwo}
                              style={{ margin: '0px 0px 0px 10px' }}
                              value={this.state.saterdayToAMPMTwo}
                            >
                              <option value="am">A.M</option>
                              <option value="pm">P.M</option>
                            </select>
                          </Col>
                        </div>
                      )}
                    </Row>
                  )}
                </div>
              </Col>
            </div>
          )}
        </Row>
      );
    }
  }

  handleChange = (event) => {
    this.setState({ feedback: event.target.value });
  };

  submitFeedback(feedback) {
    const userName =
      this.props.profile.firstName + this.props.profile.lastName;
    const userId = this.props.profile.userId;
    const db = firebase.firestore();
    const timestamp = new Date();
    db.collection('feedback').add({
      created: timestamp,
      userName,
      userId,
      feedback: feedback,
      topic: 'tags',
    });
    this.setState({ feedbackSubmitstatus: 1, feedback: '' });
  }

  getActionButton(step, title) {
    if (this.state.step === step) {
      return (
        <div
          className="text-center borderRight actionButtonActive"
          onClick={() => {
            this.setStep(step);
          }}
        >
          {title}
        </div>
      );
    } else {
      return (
        <div
          className="text-center borderRight actionButton"
          onClick={() => {
            this.setStep(step);
          }}
        >
          {title}
        </div>
      );
    }
  }

  getId(mode) {
    if (mode === 1) {
      return (
        <div className="border-box greyback">
          <span>
            <span className="inputLabel">
              ID: {this.state.locationId}
            </span>
          </span>
        </div>
      );
    }
  }

  setStep(step) {
    this.setState({
      step,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  getTitleBar(title, restaurant) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              {/* <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span> */}
              <span className="vendorAppTitle">
                {title}
                <span
                  className="vendorAppTitle"
                  style={{ color: 'grey' }}
                >
                  {/* {name} */}
                </span>
              </span>
              {/* </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding"> */}
              {this.props.vendor.locations.length !== 0 ? (
                <span className="helpButton right mobileHide">
                  <Link
                    to={{
                      pathname: '/vendor/support',
                    }}
                  >
                    <MaterialIcon
                      icon="help_outline"
                      size={35}
                      color="#353535"
                    />
                  </Link>
                  {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
                </span>
              ) : (
                <span />
              )}
              <span className="right mobileHide">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>

              <div className="saveButtons mobileHide">
                <button className="saveButton" type="submit">
                  Save
                </button>
                {this.props.vendor.locations.length !== 0 ? (
                  <Link
                    to={{
                      pathname: '/vendor/locations',
                    }}
                  >
                    <span className="cancelButton">Cancel</span>
                  </Link>
                ) : (
                  <span className="cancelButtonDeactive">Cancel</span>
                )}
              </div>

              <div className="right mobileShow">
                <button className="saveButton" type="submit">
                  Save
                </button>
                {this.props.vendor.locations.length !== 0 ? (
                  <Link
                    to={{
                      pathname: '/vendor/locations',
                    }}
                  >
                    <span className="cancelButton">Cancel</span>
                  </Link>
                ) : (
                  <span className="cancelButtonDeactive">Cancel</span>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <Row className="no-margin">
          <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
            {/* <div className="mobileShow">{this.getMobileMenu()}</div> */}
            <div>{this.getMobileMenu(restaurant)}</div>
          </Col>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'body{height: 100%} @media screen and (max-width: 768px) { .maincontent{margin-top: 120px !important; top:0px !important}}',
            }}
          />
        </Row>
      </div>
    );
  }

  getCity() {
    let city = '';
    if (this.props.profile.zoneId === 11) {
      city = 'St. Louis';
    } else if (this.props.profile.zoneId === 10) {
      city = 'Kansas City';
    }
    return city;
  }

  getTab(tabMode, tabLable, mode, last) {
    if (tabMode === mode) {
      if (last === true) {
        return (
          <div className="noBorderRight actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      } else {
        return (
          <div className="actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      }
    } else {
      if (last === true) {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton noBorderRight  actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      }
    }
  }

  onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
    window.scrollTo(0, 0);
  }

  getLeftMenu() {
    return (
      <div className="vendorLeftNavButtons">
        {this.getTab(0, 'Details', this.state.mode, false)}
        {this.getTab(1, 'Hours', this.state.mode, false)}
        {this.getTab(2, 'Images', this.state.mode, false)}
        {this.getTab(3, 'Tags', this.state.mode, false)}
        {this.getTab(4, 'Our Story', this.state.mode, false)}
        {this.state.restaurant === 'yes' && (
          <div>
            {this.getTab(6, 'Happy Hour', this.state.mode, false)}
          </div>
        )}
      </div>
    );
  }

  getMobileNext(mode, mobileMenu) {
    let totalCount = mobileMenu.length;
    let currentMenu = mobileMenu[mode];
    if (currentMenu.last !== true) {
      let arrayNum = mode + 1;
      let nextMenu = mobileMenu[arrayNum];
      return (
        <div
          style={{
            float: 'right',
            position: 'relative',
            top: '8px',
          }}
        >
          <a
            onClick={() => {
              this.setMode(nextMenu.mode);
            }}
          >
            <span style={{ fontWeight: '600', color: 'white' }}>
              {/* {nextMenu.label} */}
              Next
            </span>
            <span
              className="right"
              style={{ position: 'relative', right: '-5px' }}
            >
              <MaterialIcon
                icon="arrow_forward_ios"
                size={25}
                color="white"
              />
            </span>
          </a>
        </div>
      );
    }
  }

  getMobilePrev(mode, mobileMenu) {
    let currentMenu = mobileMenu[mode];
    if (currentMenu.first !== true) {
      let arrayNum = mode - 1;
      let nextMenu = mobileMenu[arrayNum];
      return (
        <div
          style={{ float: 'left', position: 'relative', top: '8px' }}
        >
          <a
            onClick={() => {
              this.setMode(nextMenu.mode);
            }}
          >
            <span style={{ fontWeight: '600', color: 'white' }}>
              Back
            </span>
            <span className="left">
              <MaterialIcon
                icon="arrow_back_ios"
                size={25}
                color="white"
              />
            </span>
          </a>
        </div>
      );
    }
  }

  getMobileCurrent(mode, mobileMenu) {
    let total = mobileMenu.length;
    let currentMenu = mobileMenu[mode];
    return (
      <div>
        <span style={{ fontWeight: '600', color: 'white' }}>
          {currentMenu.label}
        </span>
      </div>
    );
  }

  getMobileMenu(restaurant) {
    let mobileMenu = [
      {
        label: 'Details',
        mode: 0,
        last: false,
        first: true,
      },
      {
        label: 'Hours',
        mode: 1,
        last: false,
        first: false,
      },
      {
        label: 'Images',
        mode: 2,
        last: false,
        first: false,
      },
      {
        label: 'Tags',
        mode: 3,
        last: false,
        first: false,
      },
      {
        label: 'Events',
        mode: 4,
        last: false,
        first: false,
      },

      {
        label: 'Our Story',
        mode: 5,
        last: false,
        first: false,
      },
    ];

    if (restaurant === 'yes') {
      mobileMenu.push({
        label: 'Happy Hour',
        mode: 6,
        last: true,
        first: false,
      });
    }

    return (
      <div
        style={{
          background: '#353535',
          paddingTop: '5px',
          paddingBottom: '5px',
          // position: 'fixed',
          // bottom: '0px',
          width: '100%',
        }}
      >
        <Row>
          <Col xs={3} md={3} sm={3} lg={3}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobilePrev(this.state.mode, mobileMenu)}
            </div>
          </Col>
          <Col
            xs={6}
            md={6}
            sm={6}
            lg={6}
            style={{ padding: '0px 5px 0px 5px' }}
          >
            <div style={{ textAlign: 'center' }}>
              {this.getMobileCurrent(this.state.mode, mobileMenu)}
              <div className="paginationInner">
                {mobileMenu.map((menu, index) => (
                  <a
                    onClick={() => {
                      this.setMode(menu.mode);
                    }}
                  >
                    <div
                      className={
                        menu.mode === this.state.mode
                          ? 'dot active'
                          : 'dot'
                      }
                    />
                  </a>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={3} md={3} sm={3} lg={3}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobileNext(this.state.mode, mobileMenu)}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const {
      title,
      categories,
      error,
      city,
      latitude,
      longitude,
      state,
      street,
      zip,
      website,
      locationHero,
      locationId,
      locationThumb,
      phone,
      voucher1Info,
      voucher1Cost,
      voucher1Id,
      voucher1Promo,
      voucher1Type,
      voucher2Info,
      voucher2Cost,
      voucher2Id,
      voucher2Promo,
      voucher2Type,
      voucher3Info,
      voucher3Cost,
      voucher3Id,
      voucher3Promo,
      voucher3Type,
      packages,
      zoneId,
    } = this.state;
    const isInvalid =
      categories === '' ||
      title === '' ||
      city === '' ||
      latitude === '' ||
      longitude === '' ||
      state === '' ||
      street === '' ||
      zip === '' ||
      locationHero === '' ||
      locationId === '' ||
      locationThumb === '' ||
      phone === '' ||
      website === '' ||
      voucher1Info === '' ||
      voucher1Cost === '' ||
      voucher1Id === '' ||
      voucher1Promo === '' ||
      voucher1Type === '' ||
      voucher2Info === '' ||
      voucher2Cost === '' ||
      voucher2Id === '' ||
      voucher2Promo === '' ||
      voucher2Type === '' ||
      voucher3Info === '' ||
      voucher3Cost === '' ||
      voucher3Id === '' ||
      voucher3Promo === '' ||
      voucher3Type === '' ||
      packages === '' ||
      zoneId === '';

    return (
      <div style={{ height: '100%', display: 'block' }} id="vendor">
        <div>{this.getBannerCropper(this.state.cropping)}</div>
        <div>
          {this.getThumbnailCropper(this.state.croppingThumb)}
        </div>

        <Formik
          enableReinitialize
          initialValues={{
            title: this.state.title,
            categories: '',
            city: this.state.city,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            state: this.state.state,
            street: this.state.street,
            zip: this.state.zip,
            zoneId: this.state.zoneId,
            phone: this.state.phone,
            contactEmail: this.state.contactEmail,
            contactName: this.state.contactName,
            contactPhone: this.state.contactPhone,
            locationHero: '',
            locationId: '',
            locationThumb: '',
            voucher1Info: '',
            voucher1Cost: '',
            voucher1Id: '',
            voucher1Promo: '',
            voucher1Type: '',
            voucher2Info: '',
            voucher2Cost: '',
            voucher2Id: '',
            voucher2Promo: '',
            voucher2Type: '',
            voucher3Info: '',
            voucher3Cost: '',
            voucher3Id: '',
            voucher3Promo: '',
            voucher3Type: '',
            packages: '',
            description: this.state.description,
            longDescription: this.state.longDescription,
            website: this.state.website,
            menuLink: this.state.menuLink,
          }}
          // validate={values => {
          //   let errors = {};
          //   if (!values.title) {
          //     errors.title = "Required";
          //   } else if (
          //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          //   ) {
          //     errors.title = "Required";
          //   }
          //   return errors;
          // }}
          onSubmit={(values) => {
            this.saveLocation(this.state.editorState, values);
          }}
        >
          {({ isSubmitting }) => (
            <Form
              style={{ height: '100%', display: 'block' }}
              onKeyDown={this.onKeyDown}
            >
              {this.getTitleBar(
                'Add New Location',
                this.state.restaurant,
              )}
              {this.props.vendor.locations.length === 0 && (
                <div className="topTextBox">
                  Welcome {this.props.profile.firstName}! {'  '}Lets
                  setup your first location.
                </div>
              )}
              {/* <div className="vendorLeftNav mobileHide">
                <div style={{ padding: '10px' }}>
                  <span style={{ fontWeight: '600', color: 'white' }}>
                    SETUP
                  </span>
                </div>

                {this.getLeftMenu()}
              </div> */}
              <Container>
                {this.getStep(
                  this.state.mode,
                  this.state.eventType,
                  this.state.selected,
                  this.state.timesSelected,
                  this.state.dates,
                  this.state.restaurant,
                )}
              </Container>
            </Form>
          )}
        </Formik>
        {/* <div id="previewer">
          <div id="previewTabs">
            <span style={{ float: 'left' }}>PREVIEW</span>
            <a
              className={
                this.state.previewMode === 2
                  ? 'activePreviewTab'
                  : 'previewTab'
              }
              onClick={() => {
                this.setPreviewMode(2);
              }}
            >
              <span className="right">List</span>
            </a>
            <a
              className={
                this.state.previewMode === 1
                  ? 'activePreviewTab'
                  : 'previewTab'
              }
              onClick={() => {
                this.setPreviewMode(1);
              }}
            >
              <span className="right">Details</span>
            </a>
          </div>
          {this.getPreview(this.state.previewMode)}
        </div> */}

        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .col-12:nth-child(even){padding-left: 15px} .col-12:nth-child(even){padding-right: 15px}  .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 120px  !important; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    tags: state.tags.tags,
    mode: state.mode.mode,
    profile: state.profile.profile,
    vendor: state.vendor.vendor,
    regions: state.regions.regions,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setLocations: (location) => {
    //   dispatch(setLocations(location));
    // },
    getVendorLocations: (bundle) => {
      dispatch(getVendorLocations(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorLocationsAdd);
