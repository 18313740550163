import firebase from '../config/firebase';

export function getEntertainment(zoneId) {
  const promoArray = [];
  const db = firebase.firestore();
  const promos = db.collection('entertainment');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = promos
        .where('zoneId', '==', zoneId)
        .where('active', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                // entertainmentDescription,
                // lastUpdated,
                entertainmentTitle,
                entertainmentId,
                entertainmentThumb,
                // entertainmentHero,
                // website,
                // social,
                // twitter,
                // twitch,
                // tiktok,
                // facebook,
                // patreon,
                // bandcamp,
                // instagram,
                // youtube,
                // settings,
                // creationDetails,
                // zoneId,
                // account,
                // tagsEntertainmentType,
                // tagsGenre,
                slug,
                // claimed,
              } = doc.data();
              promoArray.push({
                // id: doc.id,
                // entertainmentDescription,
                // lastUpdated,
                entertainmentTitle,
                entertainmentId,
                entertainmentThumb,
                // entertainmentHero,
                // website,
                // social,
                // twitter,
                // twitch,
                // tiktok,
                // facebook,
                // patreon,
                // bandcamp,
                // instagram,
                // youtube,
                // settings,
                // creationDetails,
                // zoneId,
                // account,
                // tagsEntertainmentType,
                // tagsGenre,
                slug,
                // claimed,
              });
            }
          });
          return promoArray;
        })
        .then((result) => {
          resolve(promoArray);
        });
    });
  };
  return getP();
}
