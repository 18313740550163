import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import firebase from '../../config/firebase';
import { Col, Row } from 'reactstrap';
import TitleBar from '../../components/TitleBar';

const query = require('array-query');

const locations = []; // dont delete this

let updateArray = [];

const packages = []; // dont delete this

let vendorCodes = [
  {
    locationId: '-LKT3c5Y-L5WqBOlc9VY',
    vendorCode: '914415',
  },
  {
    locationId: '-LKT3c_MZoEryQj4W40G',
    vendorCode: '678394',
  },
  {
    locationId: '-LKT3c5UhDFSlmSkpVuE',
    vendorCode: '147436',
  },
  {
    locationId: '-LKT3c8OW551y_b3m0Nz',
    vendorCode: '751871',
  },
  {
    locationId: '-LKT3c5LAV2EBX9Sctoi',
    vendorCode: '320862',
  },
  {
    locationId: '-LKT3c5SY5Qig7kGd4T5',
    vendorCode: '540136',
  },
  {
    locationId: '-LKT3c5LAV2EBX9Sctoj',
    vendorCode: '743805',
  },
  {
    locationId: '-LKT3c6qez5LbZ5wWtr0',
    vendorCode: '022526',
  },
  {
    locationId: '-LKT3c_DnerKTJhcbkAk',
    vendorCode: '652792',
  },
  {
    locationId: '-LKT3c6hOTh5dMMuuVA-',
    vendorCode: '073915',
  },
  {
    locationId: '-LKT3c6v_jfjqkweEEnR',
    vendorCode: '611446',
  },
  {
    locationId: '-LKT3c6v_jfjqkweEEnQ',
    vendorCode: '400377',
  },
  {
    locationId: '-LKT3cA7UJ2cwFtJSzPH',
    vendorCode: '146333',
  },
  {
    locationId: '-LKT3cA7UJ2cwFtJSzPF',
    vendorCode: '914100',
  },
  {
    locationId: '-LKT3c6hOTh5dMMuuV9z',
    vendorCode: '616477',
  },
  {
    locationId: '-LKT3c_MZoEryQj4W40J',
    vendorCode: '586183',
  },
  {
    locationId: '-LKT3c_O_43DZqRTkoIr',
    vendorCode: '633834',
  },
  {
    locationId: '-LKT3cLBlJYYtB82Jczs',
    vendorCode: '459497',
  },
  {
    locationId: '-LKT3cB95LELccxv0ksM',
    vendorCode: '528870',
  },
  {
    locationId: '-LKT3c5Q7daul-8Rp2-q',
    vendorCode: '363910',
  },
  {
    locationId: '-LKT3cAHV-8nYr5-tFgT',
    vendorCode: '336910',
  },
  {
    locationId: '-LKT3c9vkYsjB5nmjM6t',
    vendorCode: '576644',
  },
  {
    locationId: '-LKT3c6-XYwFxFbx8a26',
    vendorCode: '583710',
  },
  {
    locationId: '-LKT3c_Ag4Dh2tMhFVwI',
    vendorCode: '223533',
  },
  {
    locationId: '-LKT3c_NT3YQlGGxnFWf',
    vendorCode: '237979',
  },
  {
    locationId: '-LKT3c5RpQghCrUX1zr6',
    vendorCode: '474912',
  },
  {
    locationId: '-LKT3c5SY5Qig7kGd4T4',
    vendorCode: '989828',
  },
  {
    locationId: '-LKT3c9uDTGfNcearzct',
    vendorCode: '685836',
  },
  {
    locationId: '-LKT3c_MZoEryQj4W40I',
    vendorCode: '446582',
  },
  {
    locationId: '-LKT3c9vkYsjB5nmjM6u',
    vendorCode: '213181',
  },
  {
    locationId: '-LKT3c_BfHuQWmb9w5lL',
    vendorCode: '966970',
  },
  {
    locationId: '-LKT3cBRryd5-KYzLd3R',
    vendorCode: '392536',
  },
  {
    locationId: '-LKT3c6x6spcNTuQ2d85',
    vendorCode: '462811',
  },
  {
    locationId: '-LKT3c6s5krS4uOYHXSt',
    vendorCode: '714781',
  },
  {
    locationId: '-LKT3c_FeLskyvrzgIco',
    vendorCode: '701232',
  },
  {
    locationId: '-LKT3cA9VMPltPd9VTdM',
    vendorCode: '188411',
  },
  {
    locationId: '-LKT3cAAj2vPXyKLvi17',
    vendorCode: '950001',
  },
  {
    locationId: '-LKT3c6tNXBRM2OEAuhs',
    vendorCode: '443250',
  },
  {
    locationId: '-LKT3c_EgdqneydWorz8',
    vendorCode: '095435',
  },
  {
    locationId: '-LKT3c_GLbhdwodYCg47',
    vendorCode: '574606',
  },
  {
    locationId: '-LKT3cA9VMPltPd9VTdQ',
    vendorCode: '082461',
  },
  {
    locationId: '-LKT3c8l6qJkp0n3L8pH',
    vendorCode: '554660',
  },
  {
    locationId: '-LKT3c8w65OfQGHUupyp',
    vendorCode: '053041',
  },
  {
    locationId: '-LKT3c8ybQBYEyjQsNpR',
    vendorCode: '308400',
  },
  {
    locationId: '-LKT3c5bP6XZ5aJ9UaE5',
    vendorCode: '226222',
  },
  {
    locationId: 'JhheblGvBxXm1LpM1FKs',
    vendorCode: '524484',
  },
  {
    locationId: 'i80Av5Wla2V2m00xrFJG',
    vendorCode: '174215',
  },
  {
    locationId: '-LKT3c_Vmtv4S0nrv-U0',
    vendorCode: '255001',
  },
  {
    locationId: '-LKT3c_UYLRugPBJVCKY',
    vendorCode: '313105',
  },
  {
    locationId: '-LKT3c_Vmtv4S0nrv-U1',
    vendorCode: '322661',
  },
  {
    locationId: 'oMOqE0bpimIHggLkbErO',
    vendorCode: '327915',
  },
  {
    locationId: 'hhenWJsHMNRMJjf7Ozhi',
    vendorCode: '563649',
  },
  {
    locationId: '-LKT3c_RswlLYHZbdIBW',
    vendorCode: '362761',
  },
  {
    locationId: '-LKT3c_GLbhdwodYCg48',
    vendorCode: '385037',
  },
  {
    locationId: '-LKT3c_QaGOE4-tGGg90',
    vendorCode: '846180',
  },
  {
    locationId: 'bTskQysHwc94lzU19ahs',
    vendorCode: '967892',
  },
  {
    locationId: '-LKT3c_QaGOE4-tGGg92',
    vendorCode: '940885',
  },
  {
    locationId: '-LKT3c_RswlLYHZbdIBX',
    vendorCode: '832823',
  },
  {
    locationId: '-LKT3c_SnNuhyUdom6mZ',
    vendorCode: '370967',
  },
  {
    locationId: '-LKT3c_Vmtv4S0nrv-U2',
    vendorCode: '695854',
  },
  {
    locationId: '-LKT3c_XYPQhOeOmNycF',
    vendorCode: '639304',
  },
  {
    locationId: '-LKT3c_YfUveuOoXLTMn',
    vendorCode: '786184',
  },
  {
    locationId: 'bXml0bRUzrghDyhamFOU',
    vendorCode: '256428',
  },
  {
    locationId: 'oeze7f08z5Mz2ivr1fsV',
    vendorCode: '869077',
  },
  {
    locationId: '-LKT3c_FeLskyvrzgIcp',
    vendorCode: '347547',
  },
  {
    locationId: '-LKT3c6x6spcNTuQ2d84',
    vendorCode: '992482',
  },
  {
    locationId: '-LKT3c994bKF_l0Vq8Hb',
    vendorCode: '080649',
  },
  {
    locationId: 'mzd2gPCRTnxh1xe64TTJ',
    vendorCode: '839023',
  },
  {
    locationId: 'erR8bpdkrBSWQ3npQeeV',
    vendorCode: '338848',
  },
  {
    locationId: 'HMyYSqijrjeBsGS9kyEb',
    vendorCode: '831531',
  },
  {
    locationId: 'Nawr0GePqRQBswSLn1Uu',
    vendorCode: '062802',
  },
  {
    locationId: 'Nj3vGt6OQG5UZdrc16zo',
    vendorCode: '792856',
  },
  {
    locationId: '52BuwWfqMaRqxYCBGH0k',
    vendorCode: '590041',
  },
  {
    locationId: 'b6hJkFlJD2WZcajH66x6',
    vendorCode: '809861',
  },
  {
    locationId: 'gOvFz0saHXli8QlyT4tK',
    vendorCode: '112174',
  },
  {
    locationId: 'gQ28QN4KUC958MWBKCI1',
    vendorCode: '718279',
  },
  {
    locationId: 'yi4Oxbv63URICe5BB8lc',
    vendorCode: '269112',
  },
  {
    locationId: 'cPViGa1ItmLkIpKO29se',
    vendorCode: '465422',
  },
  {
    locationId: 'GdDnwF7oWC2iPKMDZhZg',
    vendorCode: '280309',
  },
  {
    locationId: 'RBy16IujII5AgW9fR2Ng',
    vendorCode: '273107',
  },
  {
    locationId: 'AXclcgMNhahwjKJkxTOC',
    vendorCode: '152352',
  },
  {
    locationId: 'sOPuJDX8fPpumplFGHh8',
    vendorCode: '290619',
  },
  {
    locationId: 'vOPo9BY1N5K9KFrd2KE6',
    vendorCode: '130571',
  },
  {
    locationId: '2h09g6plVvJRnUuV0Niw',
    vendorCode: '460583',
  },
  {
    locationId: '9LZMGyP6B2ZKQYE86bwl',
    vendorCode: '761222',
  },
  {
    locationId: 'CUY83qRWdjNZXj5vEINy',
    vendorCode: '744148',
  },
  {
    locationId: 'fDB37j7wSZiYdPcKfiCw',
    vendorCode: '677633',
  },
  {
    locationId: '3BNJ2KptXd9E310TuGYL',
    vendorCode: '183665',
  },
  {
    locationId: 'jg8P0U2Xo1XLjeI7IWjR',
    vendorCode: '099020',
  },
  {
    locationId: 'Z80kHD7lvlTy5V6DmyCr',
    vendorCode: '858734',
  },
  {
    locationId: 'bNdQtMaEASVsO78vdnNm',
    vendorCode: '761643',
  },
  {
    locationId: 'gi52Sxi5sAkSCiswY4kB',
    vendorCode: '228965',
  },
  {
    locationId: '5pQ6SbOyUYoQOYGDEc98',
    vendorCode: '985583',
  },
  {
    locationId: '63Llh4s9aQ87zFfjbILW',
    vendorCode: '555196',
  },
  {
    locationId: 'Ebz1FmojPch0LQsnNHuV',
    vendorCode: '155367',
  },
  {
    locationId: 'rR89tEq01dmGwogBsuCD',
    vendorCode: '629315',
  },
  {
    locationId: 'LJJYKJt7ofB4sSlVpvAj',
    vendorCode: '317847',
  },
  {
    locationId: 'tuH1h9Nt3ra7TG1T9I3z',
    vendorCode: '419668',
  },
  {
    locationId: 'wWQr0JuZkPafFeDOTrOg',
    vendorCode: '734393',
  },
  {
    locationId: 'xaYWuyA7Z4zUkO8D7aoH',
    vendorCode: '376049',
  },
  {
    locationId: 'agY5oJLX9ZNbjzBu31Yg',
    vendorCode: '230870',
  },
  {
    locationId: 'ps4kZrzylZ9pMLWgnZsS',
    vendorCode: '786576',
  },
  {
    locationId: 'jcDNFiHjrxlOi3U6GVFX',
    vendorCode: '825509',
  },
  {
    locationId: 'lsuWPtKDR9koe2qBWdpm',
    vendorCode: '709776',
  },
  {
    locationId: 'uguNoJa7l4R6GYB99cvM',
    vendorCode: '185205',
  },
  {
    locationId: '1ZzMhfBlbwJhxFF7XLHv',
    vendorCode: '126618',
  },
  {
    locationId: 'OZG9mjrLZiqrBYYiFtqn',
    vendorCode: '532984',
  },
  {
    locationId: 'PKkYXsNGHvdsFqe10pHD',
    vendorCode: '603240',
  },
  {
    locationId: 'v4Mo6NS0RS8yxs3omrH6',
    vendorCode: '170026',
  },
  {
    locationId: '9MgCHvHa3r4vM25zZb7I',
    vendorCode: '489024',
  },
  {
    locationId: '06xRqS2b25H2GsEi3B3H',
    vendorCode: '338818',
  },
  {
    locationId: 'XwHMUhzdYxXj0Hg2uIrl',
    vendorCode: '304206',
  },
  {
    locationId: 'p2iV8G2fM31qkaA8nyxh',
    vendorCode: '358505',
  },
  {
    locationId: '7KzOqmAef4rvbCC59yb8',
    vendorCode: '963291',
  },
  {
    locationId: 'PmV0CSNgniYFb6pTj5a1',
    vendorCode: '863876',
  },
  {
    locationId: 'zp6beGxbOs62iVGcAo2B',
    vendorCode: '779414',
  },
  {
    locationId: 'ogfMXK8eQEGo6g4FYMFt',
    vendorCode: '286601',
  },
  {
    locationId: 'svnBCs8SBHaQwZiB9krJ',
    vendorCode: '452609',
  },
  {
    locationId: 'sdlH9zLycKXwTDsM9OvL',
    vendorCode: '749748',
  },
  {
    locationId: 'evyLDXGBHobD83lFw0YB',
    vendorCode: '316971',
  },
  {
    locationId: 'mdaLCyYs12RuyVtZJTpD',
    vendorCode: '277043',
  },
  {
    locationId: 'LEdap4RrHZQgR9ARNxOQ',
    vendorCode: '093917',
  },
  {
    locationId: 'GUqmacV0hjHcebSZ2JYJ',
    vendorCode: '917320',
  },
  {
    locationId: 'As5fCAB31rUmrXN7nE4D',
    vendorCode: '536734',
  },
  {
    locationId: 'TdbKASEGpUV0eC4OY2Sw',
    vendorCode: '856066',
  },
  {
    locationId: 'n3RsSVjeaeNyV09nKyp8',
    vendorCode: '821223',
  },
  {
    locationId: 'YZIQY7QUdVB0GW2fDhJP',
    vendorCode: '899559',
  },
  {
    locationId: '5YCGmjD1iEYCZWPDF71p',
    vendorCode: '422938',
  },
  {
    locationId: 'qnBXyIrkDncM0Gb352ix',
    vendorCode: '977337',
  },
  {
    locationId: '0urW1013iKQjpKT7haKT',
    vendorCode: '823951',
  },
  {
    locationId: '4DkNhX9LbWXJogq2Zh6G',
    vendorCode: '267487',
  },
  {
    locationId: 'UlmqIEEmKyOTnr0YX3DN',
    vendorCode: '110151',
  },
  {
    locationId: 'jitzpSBM5E0BMqBog28D',
    vendorCode: '933246',
  },
  {
    locationId: '5GisKao7wzc8WrFo3ONn',
    vendorCode: '508170',
  },
  {
    locationId: 'uHUAIcmMVft16dS3jaI3',
    vendorCode: '590988',
  },
  {
    locationId: 'IyLgaPpr9xBOTRAyqEVF',
    vendorCode: '930327',
  },
  {
    locationId: 'Gs0i5vJx1NfpvoUAmEMc',
    vendorCode: '660340',
  },
  {
    locationId: '3rW1fAXVtN73iUV3Xzeq',
    vendorCode: '607161',
  },
  {
    locationId: 'kL7ppFFOHEdtUccPxM3Z',
    vendorCode: '456515',
  },
  {
    locationId: 'OkK9SBh2k2fJN3tbmBA7',
    vendorCode: '709187',
  },
  {
    locationId: 'noEayhcYT84x9dK5tTks',
    vendorCode: '968494',
  },
  {
    locationId: 'C6pU5sd93pPZ8Ecg7m8c',
    vendorCode: '466192',
  },
  {
    locationId: 'D2nDm635sSaqKu8PCScZ',
    vendorCode: '459708',
  },
  {
    locationId: 'jkcuJsEWIVVjhD0v5LZh',
    vendorCode: '938783',
  },
  {
    locationId: 'viM7xxJRDR8AdfEPRsyC',
    vendorCode: '382393',
  },
  {
    locationId: 'pLHQR6F3sDaDcd0TrQim',
    vendorCode: '180284',
  },
];

let Cocktails = [
  'Cocktails',
  'Cocktail Bar',
  'Cocktail Lounge',
  'Speakeasy',
  'Mezcalaria',
  'Open Late',
  'Rum Bar',
  'Tequila Bar',
  'Vodka Bar',
  'Whiskey Bar',
  'Tiki Bar',
];

export class AdminImports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  addLocations() {
    const db = firebase.firestore();
    locations.forEach((location) => {
      const setDoc = db
        .collection('locations')
        .doc(location.locationId)
        .set(location)
        .then((ref) => {});
    });
  }

  updateLocations() {
    const db = firebase.firestore();
    locations.forEach((location) => {
      const locationToUpdate = db
        .collection('locations')
        .doc(location.locationId);
      const updateDoc = locationToUpdate
        .update({
          zoneId: location.zoneId,
          locationType: 1,
        })
        .then(() => {
          console.log('success');
        });
    });
  }

  updatePackages() {
    const db = firebase.firestore();
    packages.forEach((packagex) => {
      const packageToUpdate = db
        .collection('packages')
        .doc(packagex.id);
      const updateDoc = packageToUpdate
        .update({
          zoneId: packagex.zoneId,
        })
        .then(() => {
          console.log('success');
        });
    });
  }

  addCodes() {
    const db = firebase.firestore();
    vendorCodes.forEach((code) => {
      const setDoc = db
        .collection('vendorCodes')
        .add(code)
        .then((ref) => {});
    });
  }

  updateLocationsPublishedStatus() {
    const db = firebase.firestore();
    let settings = {
      public: true,
      published: true,
      tdStatus: 1,
      demo: false,
    };
    updateArray.forEach((location) => {
      const locationToUpdate = db
        .collection('locations')
        .doc(location);
      const updateDoc = locationToUpdate
        .update({
          settings,
        })
        .then(() => {
          console.log('success');
        });
    });
  }

  addPackages() {
    const db = firebase.firestore();
    packages.forEach((packagex) => {
      const setDoc = db
        .collection('packages')
        .doc(packagex.id)
        .set(packagex)
        .then((ref) => {});
    });
  }

  tagImport() {
    const db = firebase.firestore();
    let tagArray = [];

    let tags = this.props.tags;
    tagArray.forEach((location) => {
      let tagsCuisine = [];
      let tagsDateType = [];
      let tagsSettings = [];
      let tagsBeverages = [];
      let tagsHours = [];
      let tagsLocationType = [];
      let tagBundle = {};

      location.tags.forEach((locationTag) => {
        tags.forEach((tagcat, x) => {
          tagcat.tags.map((tag, y) => {
            if (tag.tag === locationTag) {
              if (tagcat.sid === 'hours') {
                tagsHours.push(locationTag);
              } else if (tagcat.sid === 'setting') {
                tagsSettings.push(locationTag);
              } else if (tagcat.sid === 'cuisineType') {
                tagsCuisine.push(locationTag);
              } else if (tagcat.sid === 'dateType') {
                tagsDateType.push(locationTag);
              } else if (tagcat.sid === 'beverages') {
                tagsBeverages.push(locationTag);
              } else if (tagcat.sid === 'locationType') {
                tagsLocationType.push(locationTag);
              }
            }
          });
        });
      });
      tagBundle.tagsCuisine = tagsCuisine;
      tagBundle.tagsDateType = tagsDateType;
      tagBundle.tagsSettings = tagsSettings;
      tagBundle.tagsHours = tagsHours;
      tagBundle.tagsBeverages = tagsBeverages;
      tagBundle.tagsLocationType = tagsLocationType;

      const update = db
        .collection('locations')
        .doc(location.locationId);
      const setDoc = update
        .update({
          tagsCuisine,
          tagsDateType,
          tagsSettings,
          tagsHours,
          tagsBeverages,
          tagsLocationType,
        })
        .then(() => {
          console.log('Write succeeded!');
        });
    });
  }

  importTags() {
    this.tagImport();
  }

  render() {
    if (this.state.loading) {
    }
    return (
      <div id="admin">
        <TitleBar title="Importing" backlink="" />
        <div className="section">
          <div className="header-container"></div>
        </div>
        {/* <div className="section text-center">
          <a
            onClick={() => {
              this.addLocations();
            }}
          >
            <span className="claimButton"> Import Locations</span>
          </a>
        </div>
        <div className="text-center">
          <a
            onClick={() => {
              this.addPackages();
            }}
          >
            <span className="claimButton"> Import Packages</span>
          </a>
        </div>
        <div className="text-center">
          <a
            onClick={() => {
              this.updateLocations();
            }}
          >
            <span className="claimButton">Update Locations</span>
          </a>
        </div>
        <div className="text-center">
          <a
            onClick={() => {
              this.importTags();
            }}
          >
            <span className="claimButton">Tag Locations</span>
          </a>
        </div>
        <div className="text-center">
          <a
            onClick={() => {
              this.updatePackages();
            }}
          >
            <span className="claimButton">Update Packages</span>
          </a>
        </div>
        <div className="text-center">
          <a
            onClick={() => {
              this.updateLocationsPublishedStatus();
            }}
          >
            <span className="claimButton">
              Update Location Settings
            </span>
          </a>
        </div>
        <div className="text-center">
          <a
            onClick={() => {
              this.addCodes();
            }}
          >
            <span className="claimButton">Add Vendor Codes</span>
          </a>
        </div> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { tags: state.tags.tags };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminImports);
