import * as action from './actions';

export const getUauth = (id) => {
  return {
    type: action.GET_UAUTH,
    payload: id,
  };
};

export const setUauth = (ap) => {
  return {
    type: action.SET_UAUTH,
    payload: ap,
  };
};

export const clearUauth = (user) => {
  let defaultState = {
    claimLink: false,
    claimId: null,
    vendorId: null,
    vendorSignup: false,
    referalId: null,
    guide: {
      landing: true,
      loading: true,
      tagFilters: [],
      locationsWithDistance: [],
      filteredArray: [],
      promos: [],
    },
    modalShown: 1,
  };
  return {
    type: action.CLEAR_UAUTH,
    payload: defaultState,
  };
};

const ACTION_HANDLERS = {
  [action.GET_UAUTH]: (state, action) => {
    return { ...state };
  },
  [action.SET_UAUTH]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.CLEAR_UAUTH]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  claimLink: false,
  claimId: null,
  vendorId: null,
  vendorSignup: false,
  referalId: null,
  guide: {
    landing: true,
    loading: true,
    tagFilters: [],
    locationsWithDistance: [],
    filteredArray: [],
    promos: [],
  },
  modalShown: 1,
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
