import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
// Components
import TitleBar from '../components/TitleBar';

const query = require('array-query');

export class Stats extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      mode: 1,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    document.title = 'Rankings';
  }

  getTotalEvents(events) {
    let count = events.length;
    return (
      <div>
        <span className="statcat">Total Events</span>
        <span className="right stat">{count}</span>
      </div>
    );
  }

  getTotalAwards(awards) {
    let count = awards.length;
    return (
      <div>
        <span className="statcat">Rewards Earned</span>
        <span className="right stat">{count}</span>
      </div>
    );
  }

  getEventsCreated(events, userId) {
    let eventsArray = [];
    events.forEach(event => {
      if (event.creationDetails.plannerId === userId) {
        eventsArray.push(event);
      }
    });

    let count = eventsArray.length;
    return (
      <div>
        <span className="statcat">Events Created</span>
        <span className="right stat">{count}</span>
      </div>
    );
  }

  getTab(tabMode, tabLable, mode) {
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {' '}
            {tabLable}
          </div>
        </a>
      );
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getContent(mode) {
    if (mode === 2) {
      return (
        <div className="pushfifty text-center">
          <b>Coming Soon!</b>
        </div>
      );
    } else if (mode === 1) {
      return (
        <div className="pushtwenty">
          <div className="statbox">
            {this.getTotalAwards(this.props.awards)}
          </div>
          <div className="statbox">
            {this.getEventsCreated(
              this.props.events,
              this.props.profile.userId,
            )}
          </div>
          {/* <div className="statbox">
            {this.getTotalEvents(this.props.events)}
          </div> */}
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div id="profile">
        <TitleBar title="Rankings" backlink="" />
        <div className="adminActionBar">
          <Row>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(1, 'Your Points', this.state.mode)}
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(2, 'Your Ranking', this.state.mode)}
            </Col>
          </Row>
        </div>
        <div className="section actionBarPush">
          {this.getContent(this.state.mode)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    events: state.events.events,
    awards: state.userAwards.awards,
  };
}

export default connect(mapStateToProps)(Stats);
