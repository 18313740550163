import { call, put, takeLatest } from "redux-saga/effects";
import { getArticles } from "../../services/ArticleServices";

import * as actions from "./actions";

let defaultState = {
  articles: [],
};

function* getArticlesAction() {
  let articles = yield call(getArticles);
  yield put({
    type: actions.SET_ARTICLES,
    payload: {
      ...defaultState,
      articles,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.GET_ARTICLES, getArticlesAction);
}
