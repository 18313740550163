import { call, put, takeLatest } from 'redux-saga/effects';
import { getVendorTimedEvents } from '../../services/VendorTimedEventsServices';

import * as actions from './actions';

let defaultState = {
  events: [],
};

function* getVendorTimedEventsAction(vendorId) {
  console.log('vendorTimedEventsxx', vendorId);
  if (vendorId !== undefined) {
    let events = yield call(getVendorTimedEvents, vendorId.vendorId);
    yield put({
      type: actions.SET_VENDORTIMEDEVENTS,
      payload: {
        ...defaultState,
        events,
      },
    });
  }
}

export default function* sagas() {
  yield takeLatest(
    actions.GET_VENDORTIMEDEVENTS,
    getVendorTimedEventsAction,
  );
}
