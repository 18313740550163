import React from 'react';
import MaterialIcon from 'material-icons-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
// Components
import EventListItem from '../../components/EventListItem';
import TitleBar from '../../components/TitleBar';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';
import { resetCe } from '../../sagas/customEventSaga/reducer';
import { resetEvent } from '../../sagas/activeEventSaga/reducer';
import {
  getEvents,
  refreshEvents,
} from '../../sagas/userEventsSaga/reducer';

const query = require('array-query');

export class EventsCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    if (this.props.customEvent.clear === false) {
      const create = false;
      this.props.resetCe(create);
    }
    this.props.resetEvent();
  }

  componentDidMount() {
    document.title = 'Upcoming Experiences';
  }

  setEvents(events, userId) {
    const planned = [];
    const unplanned = [];
    const finished = [];
    let setEvents = {};

    if (events !== undefined) {
      events.forEach((event) => {
        if (
          (event.users[userId].finished === true &&
            event.users[userId].delete !== true) ||
          (event.users[userId].canceled === true &&
            event.users[userId].delete !== true)
        ) {
          if (event.status !== 11) {
            event.mode = '6';
            finished.push(event);
          } else if (
            event.status === 11 &&
            event.users[this.props.user.id].editor === true
          ) {
            event.mode = '6';
            finished.push(event);
          }
        } else if (
          event.users[userId].finished !== true &&
          event.status !== 9
        ) {
          if (event.status === 0) {
            planned.push(event);
          } else if (
            event.status === 5 &&
            event.creationDetails.plannerId === this.props.user.id
          ) {
            event.mode = '1';
            planned.push(event);
          } else if (
            (event.users[this.props.user.id].editor === true &&
              event.status === 2) ||
            event.status === 3 ||
            event.status === 4 ||
            event.status === 11
          ) {
            unplanned.push(event);
          }
        }
      });
      let plannedCount = planned.length;
      let unplannedCount = unplanned.length;

      const activeCountArray = [];
      const activeInvitationsArray = [];
      events.forEach((event) => {
        if (event.users[this.props.user.id].inviteAction === false) {
          activeInvitationsArray.push(
            event.invitations[this.props.user.id],
          );
        }
      });
      activeInvitationsArray.forEach((event) => {
        if (event.status === 0 || event.status === 9) {
          activeCountArray.push(event);
        }
      });
      const activeCount = activeCountArray.length;

      setEvents = {
        planned: planned,
        unplanned: unplanned,
        plannedCount,
        unplannedCount,
        inviteCount: activeCount,
        finished,
      };
      return setEvents;
    }
  }

  changeMode(mode) {
    this.setState({
      mode,
    });
  }

  getFooter() {
    return (
      <Container>
        <Row className="no-margin">
          <Col
            xs={12}
            md={12}
            sm={12}
            lg={12}
            className="no-padding text-center"
          >
            <Link
              to={{
                pathname: '/events/home',
              }}
            >
              <span className="footer-link-withborder">
                Upcoming Experience
              </span>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  getContent(setEvents) {
    const finished = setEvents.finished;
    if (finished.length === 0) {
      return (
        <Container className="pushfifty">
          <Row className="text-center">
            <span>You do not have any completed Experiences.</span>
            <div
              style={{
                padding: '15px 10px 10px 10px',
                border: '1px solid lightgrey',
                margin: '0px auto',
                width: '100%',
                marginTop: '100px',
                background: 'white',
                display: 'flex',
                textAlign: 'left',
              }}
            >
              <Col xs={2} md={1} sm={2} lg={1}>
                <div style={{ float: 'left' }}>
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="rgb(238 1 88)"
                  />
                </div>
              </Col>
              <Col xs={10} md={11} sm={10} lg={11}>
                <h4
                  style={{ fontSize: '18px', marginBottom: '10px' }}
                >
                  Your Experiences - Completed
                </h4>
                <p
                  style={{
                    margin: '0px',
                    padding: '0px 0px 10px 0px',
                  }}
                >
                  Here you will find all of your completed
                  Experiences.
                </p>
              </Col>
            </div>
          </Row>
        </Container>
      );
    } else {
      return (
        <div id="eventList" style={{ height: '100%' }}>
          {finished.length !== 0 && (
            <div style={{ height: '100%', display: 'contents' }}>
              <div
                style={{
                  padding: '10px',
                  textAlign: 'center',
                  borderTop: '1px solid grey',
                  position: 'relative',
                  top: '-2px',
                  background: 'white',
                }}
              >
                {/* <Link
                  to={{
                    pathname: '/',
                  }}
                  className="right red"
                >
                  <span>See Past Events</span>
                </Link> */}
              </div>

              <EventListItem events={finished} mode="6" />
            </div>
          )}
        </div>
      );
    }
  }

  getActionButton(mode, title, count) {
    if (this.state.mode === mode) {
      return (
        <div
          className="text-center actionButton actionButtonActive"
          onClick={() => {
            this.changeMode(mode);
          }}
        >
          {title}
          <div className="counterGrey">
            <span>{count}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="text-center  actionButton actionButtonInactive"
          onClick={() => {
            this.changeMode(mode);
          }}
        >
          {title}
          <div className="counterGrey">
            <span>{count}</span>
          </div>
        </div>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/home',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Your Experiences</span>
              </center>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              {/* <Link
                to={{
                  pathname: '/past-events',
                }}
                className="right"
              >
                <span>
                  Completed{' '}
                  <MaterialIcon
                    icon="close"
                    size={25}
                    color="white"
                  />
                </span>
              </Link> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    let setEvents = this.setEvents(
      this.props.events,
      this.props.user.id,
    );

    return (
      <div id="events">
        {/* <TitleBar title="Social Calendar" backlink="" /> */}
        {this.getTitleBar()}
        <div
          className="adminActionBar"
          style={{ top: '40px !important', width: '100%' }}
        >
          <Row>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <Link
                to={{
                  pathname: '/events/upcoming',
                }}
              >
                <div className="text-center actionButton actionButtonInactive">
                  Upcoming
                  <div className="counterGrey">
                    <span>
                      {setEvents.plannedCount +
                        setEvents.unplannedCount}
                    </span>
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <div className="text-center actionButtonActive actionButton">
                Completed
                <div className="counterGrey">
                  <span>{setEvents.finished.length}</span>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <Link
                to={{
                  pathname: '/events/invitations',
                }}
              >
                <div className="text-center actionButton actionButtonInactive noBorderRight ">
                  Invitations
                  <div className="counterGrey">
                    <span>{setEvents.inviteCount}</span>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
        <div
          className="actionBarPush"
          style={{ paddingBottom: '80px', height: '100%' }}
        >
          {/* <div> */}
          {this.getContent(setEvents)}
        </div>
        {/* <div className="footer">{this.getFooter()}</div> */}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#startBar{background-color: #ef0059;} .adminActionBar {top: 40px !important;} #events{position: relative;}  #headersTop {width: 100% !important;} #headersTopSecondary{width: 100%} #authright {padding-left: 0px !important;}  #mobile-nav { display: none } #authleft {display:none;} #events{position: relative; top: 40px}  #bottomNav{left:0px; width:100%} .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    events: state.events.events,
    alllocations: state.locations.locations,
    user: state.user,
    customEvent: state.customEvent.customEvent,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
    getEvents: (userId) => {
      dispatch(getEvents(userId));
    },
    refreshEvents: (events) => {
      dispatch(refreshEvents(events));
    },
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    resetEvent: (event) => {
      dispatch(resetEvent(event));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventsCompleted);
