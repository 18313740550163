import firebase from '../config/firebase';

// const _ = require("lodash");

export function getEvent(token) {
  const db = firebase.firestore();
  const eventsObject = {};
  const eventsArray = [];
  const getS = function (token) {
    const user_events = db.collection('user_events');
    return new Promise((resolve, reject) => {
      const table = `invitations.${token}.status`;
      const query = user_events
        .where(table, '==', 0)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                creationDetails,
                eventSettings,
                packageDetails,
                status,
                title,
                description,
                scheduledTime,
                heroImage,
                thumbnail,
                editHistory,
                users,
                invitations,
              } = doc.data();
              // const expireDateString = expireDate.toDateString();
              // const purchaseDateString = purchaseDate.toDateString();
              eventsArray.push({
                eventId: doc.id,
                creationDetails,
                eventSettings,
                packageDetails,
                status,
                title,
                description,
                scheduledTime,
                heroImage,
                thumbnail,
                editHistory,
                users,
                invitations,
                token: token,
              });
            }
          });
          return eventsArray;
        })
        .then((result) => {
          resolve(eventsArray);
        });
    });
  };

  return getS(token);
}

// export function getEvent(token) {
//   console.log("Getting Notifications - Service", token);
//   const notificationArray = [];
//   const db = firebase.firestore();
//   const eventsObject = {};

//   const getS = function(token) {
//     const notifications = db.collection("notifications");
//     return new Promise((resolve, reject) => {
//       const query = notifications
//         .where("nonuser.token", "==", token)
//         .get()
//         .then(snapshot => {
//           snapshot.forEach(doc => {
//             if (doc && doc.exists) {
//               const {
//                 status,
//                 dateSent,
//                 locations,
//                 message,
//                 senderId,
//                 type,
//                 senderName,
//                 senderLastName,
//                 profilePhotoPath,
//                 userId,
//                 receiverName,
//                 userPurchaseId,
//                 eventId,
//                 usersAttending,
//                 replyMessage,
//                 receiverPhotoPath,
//                 editable
//               } = doc.data();
//               // const expireDateString = expireDate.toDateString();
//               // const purchaseDateString = purchaseDate.toDateString();
//               notificationArray.push({
//                 notificationId: doc.id,
//                 status,
//                 dateSent,
//                 locations,
//                 message,
//                 senderId,
//                 type,
//                 senderName,
//                 senderLastName,
//                 profilePhotoPath,
//                 userId,
//                 receiverName,
//                 userPurchaseId,
//                 eventId,
//                 usersAttending,
//                 replyMessage,
//                 receiverPhotoPath,
//                 editable
//               });
//             }
//           });
//           return notificationArray;
//         })
//         .then(result => {
//           resolve(notificationArray);
//         });
//     });
//   };

//   const getf = function(eventId) {
//     const user_events = db.collection("user_events").doc(eventId);
//     return new Promise((resolve, reject) => {
//       const query = user_events
//         .get()
//         .then(function(doc) {
//           if (doc.exists) {
//             const {
//               creationDetails,
//               eventSettings,
//               packageDetails,
//               usersAttending,
//               status,
//               title,
//               description,
//               scheduledTime
//             } = doc.data();
//             eventsObject.eventId = doc.id;
//             eventsObject.creationDetails = creationDetails;
//             eventsObject.eventSettings = eventSettings;
//             eventsObject.packageDetails = packageDetails;
//             eventsObject.usersAttending = usersAttending;
//             eventsObject.status = status;
//             eventsObject.title = title;
//             eventsObject.description = description;
//             eventsObject.scheduledTime = scheduledTime;
//             eventsObject.invite = notificationArray;
//           } else {
//             // doc.data() will be undefined in this case
//             console.log("No such document!");
//           }
//           return eventsObject;
//         })
//         .then(result => {
//           resolve(eventsObject);
//         });
//     });
//   };

//   return getS(token).then(result => getf(notificationArray[0].eventId));
// }

// // Send Invitation
// export function sendInvitation(invitation) {
//   console.log("Sending Invitation - Service", invitation);
//   const db = firebase.firestore();
//   const setDoc = db
//     .collection("notifications")
//     .add(invitation)
//     .then(ref => {
//       console.log("Added invitation with ID: ", ref);
//     });
// }

// Send Invitation
export function sendInvitation(event) {
  const db = firebase.firestore();
  const userEvent = db.collection('user_events').doc(event.eventId);
  const users = event.users;
  const invitations = event.invitations;
  const setDoc = userEvent.update({
    users,
    invitations,
  });
}

// Accept Invitation
export function acceptInvitation(invitation, userPackage) {
  const updatedPackage = updatePackage(userPackage);
}

// Accept Invitaion - Update Notification
export function acceptInvite(action) {
  const db = firebase.firestore();
  const notification = db
    .collection('notifications')
    .doc(action.notificationId);
  const setDoc = notification.update({
    status: 1,
    responceDate: 'today',
    replyMessage: action.replyMessage,
  });
}

// Accept Invitation - Update User Package
export function updatePackage(action) {
  const db = firebase.firestore();
  const userEvent = db.collection('user_events').doc(action.eventId);
  const usersObject = action.event.users;
  const fullName = action.firstName + ' ' + action.lastName;
  const senderFullName =
    action.senderName + ' ' + action.senderLastName;
  usersObject[action.userId].access = true;
  usersObject[action.userId].inviteAction = true;
  let editorStatus = action.event.invitations[action.userId].editable;
  let approvedStatus = false;
  if (action.event.status === 0) {
    approvedStatus = true;
  }
  usersObject[action.userId].approved = approvedStatus;
  usersObject[action.userId].editor = editorStatus;
  usersObject[action.userId].attending = true;
  const getV = function () {
    const userId = action.userId;
    return new Promise((resolve, reject) => {
      const setDoc = userEvent
        .update({
          users: usersObject,
          status: 0,
          ['invitations.' + userId + '.status']: 1,
        })
        .then(() => {
          // console.log("Write succeeded!");
          resolve();
        });
    });
  };
  return getV();
}

// Create Connection Record
export function createConnectionRecord(userId) {
  const timestamp = new Date();
  const db = firebase.firestore();
  const getV = function () {
    return new Promise((resolve, reject) => {
      const setDoc = db
        .collection('connections')
        .doc(userId)
        .set({ connections: {} })
        .then((ref) => {
          console.log('Added document with ID: ', ref);
          return ref;
        })
        .then((result) => {
          resolve(result);
        });
    });
  };
  return getV();
}

// Accept Invitation - Create Connection Part 1
export function createConnectionOne(invite, connections) {
  const userId = invite.userId;
  const newUserId = invite.event.invitations[userId].senderId;
  const timestamp = new Date();
  const db = firebase.firestore();
  const newConnectionUpdate = db
    .collection('connections')
    .doc(userId);
  const connectionObject = {
    connected: true,
    created: timestamp,
    userId: newUserId,
  };
  const getV = function () {
    return new Promise((resolve, reject) => {
      const setDoc = newConnectionUpdate
        .update({
          ['connections.' + newUserId]: connectionObject,
        })
        .then(() => {
          console.log('Write succeeded!');
          resolve();
        });
    });
  };
  return getV();
}

// Accept Invitation - Create Connection Part 2
export function createConnectionTwo(invite, connections) {
  const userId = invite.userId;
  const newUserId = invite.event.invitations[userId].senderId;
  const timestamp = new Date();
  const db = firebase.firestore();
  const newConnection = db.collection('connections').doc(newUserId);
  const connectionObject = {
    connected: true,
    created: timestamp,
    userId: userId,
  };
  const getV = function () {
    return new Promise((resolve, reject) => {
      const setDoc = newConnection
        .update({
          ['connections.' + userId]: connectionObject,
        })
        .then(() => {
          // console.log("Write succeeded!");
          resolve();
        });
    });
  };
  return getV();
}

// Reject Invitaion - Update Notification

// export function rejectInvite(action) {
//   console.log("rejecting", action);
//   const db = firebase.firestore();
//   const notification = db
//     .collection("notifications")
//     .doc(action.notificationId);
//   const setDoc = notification.update({
//     status: 2,
//     responceDate: "today",
//     replyMessage: action.replyMessage
//   });
// }

// Edit Invitation - Update User Package
export function rejectInvite(action) {
  const db = firebase.firestore();
  const userEvent = db.collection('user_events').doc(action.eventId);

  const usersObject = action.event.users;
  usersObject[action.userId].access = false;
  usersObject[action.userId].inviteAction = true;
  usersObject[action.userId].rejected = true;
  usersObject[action.userId].editor = false;

  let status = action.event.status;
  let rejectedStatus = true;
  Object.keys(action.event.users).forEach((key) => {
    if (
      action.event.users[key].rejected === undefined ||
      (action.event.users[key].rejected !== undefined &&
        action.event.users[key].rejected === false)
    ) {
      if (key !== action.senderId) {
        rejectedStatus = false;
      }
    }
  });

  if (rejectedStatus === true) {
    status = 3;
  }

  const getV = function () {
    return new Promise((resolve, reject) => {
      const userId = action.userId;
      const setDoc = userEvent
        .update({
          status,
          users: usersObject,
          ['invitations.' + userId + '.status']: 2,
        })
        .then(() => {
          // console.log("Write succeeded!");
          resolve();
        });
    });
  };
  return getV();
}

export function maybeInvite(action) {
  const db = firebase.firestore();
  const userEvent = db.collection('user_events').doc(action.eventId);
  const usersObject = action.event.users;
  // usersObject[action.userId].inviteAction = true;
  const getV = function () {
    return new Promise((resolve, reject) => {
      const userId = action.userId;
      const setDoc = userEvent
        .update({
          users: usersObject,
          ['invitations.' + userId + '.status']: 9,
        })
        .then(() => {
          // console.log("Write succeeded!");
          resolve();
        });
    });
  };
  return getV();
}

// Edit Invitation - Update User Package
export function updatePackageEdit(action) {
  const db = firebase.firestore();
  const userEvent = db.collection('user_events').doc(action.eventId);
  const usersObject = action.event.users;
  usersObject[action.userId].editor = true;
  usersObject[action.userId].inviteAction = true;
  const getV = function () {
    return new Promise((resolve, reject) => {
      const userId = action.userId;
      const setDoc = userEvent
        .update({
          status: 2,
          users: usersObject,
          ['invitations.' + userId + '.status']: 1,
        })
        .then(() => {
          // console.log("Write succeeded!");
          resolve();
        });
    });
  };
  return getV();
}

// // Edit Invitation - Update Notification
// export function editInvite(action) {
//   console.log("EDIT INVITE FUNCTION", action.notificationId);
//   const db = firebase.firestore();
//   const notification = db
//     .collection("notifications")
//     .doc(action.notificationId);
//   const setDoc = notification.update({
//     status: 3,
//     responceDate: "today",
//     replyMessage: action.replyMessage
//   });
// }
