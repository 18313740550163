import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Col, Container, Row } from 'reactstrap';
import { TopicCreateEvent } from '../components/HelpTopics/TopicCreateEvent';
import firebase from '../config/firebase';
// Reducers
import {
  getProfile,
  saveState,
} from '../sagas/getProfileSaga/reducer';

const query = require('array-query');

export class Wizard extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      step: 2,
      mode: 1,
      event: null,
      eventId: '',
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      this.props.history.push('/login');
    } else if (
      this.props.profile.zoneId !== undefined &&
      this.props.profile.zoneId !== 1
    ) {
      this.props.history.push('/events/home');
    } else {
      this.setCurrentValues();
    }
  }

  componentDidMount() {
    if (this.props.match.params.eventId !== undefined) {
      let id = this.props.match.params.eventId;
      const event = query('eventId')
        .is(id)
        .on(this.props.events);
      this.setState({ event: event[0], eventId: id });
    }
  }

  getTags() {
    if (this.props.profile.tags) {
      const beverages = this.props.profile.tags.tagsBeverages;
      const cuisine = this.props.profile.tags.tagsCuisine;
      return (
        <Row>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="left no-padding"
          >
            <h5>Favorite Drinks</h5>
            <ul>
              {beverages.map(tag => (
                <div>{this.getTag(tag)}</div>
              ))}
            </ul>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="left no-padding"
          >
            <h5>Favorite Cuisine</h5>
            <ul>
              {cuisine.map(tag => (
                <div>{this.getTag(tag)}</div>
              ))}
            </ul>
          </Col>
        </Row>
      );
    }
  }

  getTag(tag) {
    return <li>{tag}</li>;
  }

  getTitle() {
    if (this.state.event === null) {
      return (
        <center>
          <span className="page-header">Your Preferences</span>
        </center>
      );
    } else {
      return (
        <center>
          <span className="page-header">Welcome to Trevel!</span>
        </center>
      );
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="left">
                {/* <MaterialIcon
                  icon="close"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.editMode(this.state.customEvent.event);
                  }}
                /> */}
              </span>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              {this.getTitle()}
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="right">
                {/* <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      this.props.customEvent,
                      this.props.locations,
                      this.props.packages
                    );
                  }}
                /> */}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getPreferences() {
    return (
      <div className="section">
        <Formik
          enableReinitialize
          onSubmit={values => {
            this.saveTags(values, this.props.user.id);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Container>
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="left no-padding"
                  >
                    <div className="section no-padding">
                      <div>
                        {this.getCategoryFields(this.props.tags)}
                      </div>
                      <ErrorMessage
                        name="categories"
                        component="div"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
              {/* <button
              type="submit"
              // className="redbutton"
              disabled={isSubmitting}
            >
              Get Started!
            </button> */}
            </Form>
          )}
        </Formik>
        {this.getTagNextButton(this.state.mode)}
      </div>
    );
  }

  getTagNextButton(mode, userId) {
    if (mode === 1) {
      return (
        <div>
          <center>
            <a
              onClick={() => {
                this.setMode(2);
              }}
              className="redbutton white display-block"
            >
              <span>Next</span>
            </a>
          </center>
        </div>
      );
    } else if (mode === 2) {
      return (
        <div>
          <center>
            <a
              onClick={() => {
                this.saveTags(this.props.user.id);
                // this.setStep(3);
              }}
              className="redbutton white display-block"
            >
              <span>Next</span>
            </a>
          </center>
        </div>
      );
    }
  }

  setMode(mode) {
    window.scrollTo(0, 0);
    this.setState({
      mode,
    });
  }

  setCurrentValues() {
    const beverageTagsHate = [];
    const settingTagsHate = [];
    const cuisineTagsHate = [];
    const dateTypeTagsHate = [];
    const beverageTagsLove = [];
    const cuisineTagsLove = [];
    const dateTypeTagsLove = [];
    const settingTagsLove = [];

    if (this.props.profile.tags !== undefined) {
      const userTags = this.props.profile.tags;

      if (userTags.hate.tagsBeverages !== 0) {
        userTags.hate.tagsBeverages.forEach(tag => {
          beverageTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.hate.tagsSetting.length !== 0) {
        userTags.hate.tagsSetting.forEach(tag => {
          settingTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.hate.tagsCuisine.length !== 0) {
        userTags.hate.tagsCuisine.forEach(tag => {
          cuisineTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.hate.tagsDateType.length !== 0) {
        userTags.hate.tagsDateType.forEach(tag => {
          dateTypeTagsHate.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.love.tagsBeverages.length !== 0) {
        userTags.love.tagsBeverages.forEach(tag => {
          beverageTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.love.tagsSetting.length !== 0) {
        userTags.love.tagsSetting.forEach(tag => {
          settingTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.love.tagsCuisine.length !== 0) {
        userTags.love.tagsCuisine.forEach(tag => {
          cuisineTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }

      if (userTags.love.tagsDateType.length !== 0) {
        userTags.love.tagsDateType.forEach(tag => {
          dateTypeTagsLove.push({
            value: tag,
            label: tag,
          });
        });
      }
    }
    this.setState({
      love: {
        beverages: beverageTagsLove,
        cuisine: cuisineTagsLove,
        dateType: dateTypeTagsLove,
        setting: settingTagsLove,
      },
      hate: {
        beverages: beverageTagsHate,
        cuisine: cuisineTagsHate,
        dateType: dateTypeTagsHate,
        setting: settingTagsHate,
      },
      loading: false,
    });
  }

  saveTags(userId) {
    const db = firebase.firestore();
    const docToUpdate = db.collection('users').doc(userId);

    const tagsBeveragesLove = [];
    this.state.love.beverages.forEach(doc => {
      tagsBeveragesLove.push(doc.value);
    });

    const tagsCuisineLove = [];
    this.state.love.cuisine.forEach(doc => {
      tagsCuisineLove.push(doc.value);
    });

    const tagsDateTypeLove = [];
    this.state.love.dateType.forEach(doc => {
      tagsDateTypeLove.push(doc.value);
    });

    const tagsSettingLove = [];
    this.state.love.setting.forEach(doc => {
      tagsSettingLove.push(doc.value);
    });

    const tagsBeveragesHate = [];
    this.state.hate.beverages.forEach(doc => {
      tagsBeveragesHate.push(doc.value);
    });

    const tagsCuisineHate = [];
    this.state.hate.cuisine.forEach(doc => {
      tagsCuisineHate.push(doc.value);
    });

    const tagsDateTypeHate = [];
    this.state.hate.dateType.forEach(doc => {
      tagsDateTypeHate.push(doc.value);
    });

    const tagsSettingHate = [];
    this.state.hate.setting.forEach(doc => {
      tagsSettingHate.push(doc.value);
    });

    const tagsArray = {
      love: {
        tagsDateType: tagsDateTypeLove,
        tagsCuisine: tagsCuisineLove,
        tagsBeverages: tagsBeveragesLove,
        tagsSetting: tagsSettingLove,
      },
      hate: {
        tagsDateType: tagsDateTypeHate,
        tagsCuisine: tagsCuisineHate,
        tagsBeverages: tagsBeveragesHate,
        tagsSetting: tagsSettingHate,
      },
    };

    const updateDoc = docToUpdate
      .update({
        tags: tagsArray,
      })
      .then(() => {
        this.props.getProfile(this.props.profile.userId);
        // this.props.history.push("/");
        this.setStep(3);
      });
  }

  getCategoryFields(tags) {
    const cuisineOptions = [];
    const beverageOptions = [];
    const settingOptions = [];
    tags.forEach(tag => {
      if (tag.sid === 'cuisineType') {
        tag.tags.forEach(tag => {
          cuisineOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (tag.sid === 'beverages') {
        tag.tags.forEach(tag => {
          beverageOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      } else if (tag.sid === 'setting') {
        tag.tags.forEach(tag => {
          settingOptions.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      }
    });
    if (this.state.mode === 1) {
      return (
        <div className="signup-questions">
          <center>
            <h5>What do you love?</h5>
          </center>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">Cuisine</span>
            <Select
              value={this.state.love.cuisine}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={value => {
                this.handleOnChange('cuisine', value, 'love');
              }}
              options={cuisineOptions}
              isMulti={true}
            />
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">Drinks</span>
            <Select
              value={this.state.love.beverages}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={value => {
                this.handleOnChange('beverages', value, 'love');
              }}
              options={beverageOptions}
              isMulti={true}
            />
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">Setting</span>
            <Select
              value={this.state.love.setting}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={value => {
                this.handleOnChange('setting', value, 'love');
              }}
              options={settingOptions}
              isMulti={true}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="signup-questions">
          <center>
            <h5>What do you hate?</h5>
          </center>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">Cuisine</span>
            <Select
              value={this.state.hate.cuisine}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={value => {
                this.handleOnChange('cuisine', value, 'hate');
              }}
              options={cuisineOptions}
              isMulti={true}
            />
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">Drinks</span>
            <Select
              value={this.state.hate.beverages}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={value => {
                this.handleOnChange('beverages', value, 'hate');
              }}
              options={beverageOptions}
              isMulti={true}
            />
          </div>
          <div className="signup-question">
            <span className="signup-tag-lable textleft">Setting</span>
            <Select
              value={this.state.hate.setting}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={value => {
                this.handleOnChange('setting', value, 'hate');
              }}
              options={settingOptions}
              isMulti={true}
            />
          </div>
        </div>
      );
    }
  }

  handleOnChange = (fieldName, value, cat) => {
    let loveTags = this.state.love;
    let hateTags = this.state.hate;
    if (cat === 'love') {
      loveTags[fieldName] = value;
      this.setState({ love: loveTags });
    }
    if (cat === 'hate') {
      hateTags[fieldName] = value;
      this.setState({ hate: hateTags });
    }
  };

  getLocationPicker() {
    return (
      <div className="section">
        <center>
          <h5>What state are you in?</h5>
        </center>
        <center>
          <Formik
            initialValues={{ state: 'none' }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 500);
            }}
            // validationSchema={Yup.object().shape({
            //   email: Yup.string()
            //     .email()
            //     .required('Required'),
            // })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit} id="fullName">
                  {/* <label htmlFor="fullName" style={{ display: "block" }}>
                    Email
                  </label> */}

                  <fieldset>
                    <select
                      id="state"
                      type="text"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.email && touched.email
                          ? 'text-input error'
                          : 'text-input'
                      }
                    >
                      <option value="none">Select a State</option>
                      <option value="AL">Alabama</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </fieldset>

                  {/* {errors.email && touched.email && (
              <div className="input-feedback">{errors.email}</div>
            )} */}
                  {/*
                  <button
                    type="button"
                    className="outline"
                    onClick={handleReset}
                    disabled={!dirty || isSubmitting}
                  >
                    Reset
                  </button>
                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button> */}
                  {this.getNextButton(props, this.props.user.id)}
                </form>
              );
            }}
          </Formik>
        </center>
      </div>
    );
  }

  getEventHelp() {
    let url = '';
    if (this.state.event !== null) {
      url = '/event/' + this.state.eventId;
    } else if (this.props.destination !== undefined) {
      url = this.props.destination;
    } else {
      url = '/home';
    }

    return (
      <div>
        <div className="section">
          <TopicCreateEvent />
        </div>
        <div id="startBar">
          <Container>
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <center>
                  <a
                    onClick={() => {
                      this.props.history.push(url);
                    }}
                    className="center solidredbutton"
                  >
                    <span>I'm Ready To Plan!</span>
                  </a>
                </center>
              </Col>
            </Row>
          </Container>
        </div>
        <div />
      </div>
    );
  }

  getNextButton(value, userId) {
    if (value.values.state !== 'none') {
      return (
        <div>
          <center>
            <a
              onClick={() => {
                this.saveState(value.values.state, userId);
              }}
              className="redbutton white display-block"
            >
              <span>Next</span>
            </a>
          </center>
        </div>
      );
    }
  }

  saveState(state, userId) {
    const bundle = {
      state: state,
      userId: userId,
    };
    this.props.saveState(bundle);
    this.setState({
      step: 2,
    });
  }

  getStep(step) {
    if (step === 1) {
      return this.getLocationPicker();
    } else if (step === 2) {
      return this.getPreferences();
    } else if (step === 3) {
      return this.getEventHelp();
    }
  }

  setStep(step) {
    window.scrollTo(0, 0);
    this.setState({
      step,
    });
  }

  getInstructions(step) {
    if (step === 1) {
      if (this.state.event === null) {
        return (
          <center>
            <p>
              Lets get you started buiding the perfect <b>Social</b>{' '}
              Event!
            </p>
          </center>
        );
      } else {
        return (
          <div>
            <p>
              You have been invited to make plans with{' '}
              {this.state.event.creationDetails.plannerFirstName} and
              Trevel is here to help you find the perfect places to
              go.
            </p>
            <p>
              To get started, lets find out a little bit about you to
              make sure we find places you will both love!
            </p>
          </div>
        );
      }
    } else if (step === 2) {
      return (
        <div>
          <center>
            {/* <p>
            Your Preferences help us find places you will both love to go.{" "}
            <b>
              {this.state.event.creationDetails.plannerFirstName} +{" "}
              {this.props.profile.firstName} = MATCH!
            </b>
          </p> */}
            <span className="helpTipTitle">Quick Tip</span>
            <p>
              You can always change these from
              <br />
              <b>Your Preferences</b>
            </p>
          </center>
        </div>
      );
    } else if (step === 3) {
      return (
        <div>
          <center>
            {/* <p>
            Your Preferences help us find places you will both love to go.{" "}
            <b>
              {this.state.event.creationDetails.plannerFirstName} +{" "}
              {this.props.profile.firstName} = MATCH!
            </b>
          </p> */}
            <span className="helpTipTitle">Tutorial</span>
            <p>
              You can always find this information, and much more in{' '}
              <b>Help and Support</b>. Just look for it in the left
              menu.
            </p>
          </center>
        </div>
      );
    }
  }

  render() {
    if (this.state.loading || this.props.tags === undefined) {
      return (
        <div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }

    return (
      <div id="wizard">
        {this.getTitleBar()}
        {/* <div className="section"> */}
        <Container>
          <div className="section">
            <Row className="wizard-section-border-ten">
              <Col className="no-padding">
                {this.getInstructions(this.state.step)}
              </Col>
            </Row>
          </div>
          <Row>
            {/* <Row className="wizard-section"> */}
            <Col className="no-padding">
              <div>{this.getStep(this.state.step)}</div>
            </Col>
          </Row>
        </Container>
        {/* </div> */}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveState: bundle => {
      dispatch(saveState(bundle));
    },
    getProfile: userId => {
      dispatch(getProfile(userId));
    },
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: state.profile.profile,
    tags: state.tags.tags,
    events: state.events.events,
    destination: state.destination.destination,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Wizard);
