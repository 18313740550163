import * as action from './actions';

export const getEvents = (id) => {
  return {
    type: action.GET_EVENTS,
    payload: id,
  };
};

export const setEvents = (events) => {
  return {
    type: action.SET_EVENTS,
    payload: events,
  };
};

export const refreshEvents = (events) => {
  return {
    type: action.REFRESH_EVENTS,
    payload: events,
  };
};

export const updateEvents = (id) => {
  return {
    type: action.UPDATE_EVENTS,
    payload: id,
  };
};

export const scheduleEvent = (event) => {
  return {
    type: action.SCHEDULE_EVENT,
    payload: event,
  };
};

export const finishEvent = (event) => {
  return {
    type: action.FINISH_EVENT,
    payload: event,
  };
};

export const removeGuest = (event) => {
  return {
    type: action.REMOVE_GUEST,
    payload: event,
  };
};

const ACTION_HANDLERS = {
  [action.GET_EVENTS]: (state, action) => {
    return { ...state };
  },
  [action.REFRESH_EVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SET_EVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.UPDATE_EVENTS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SCHEDULE_EVENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.FINISH_EVENT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.REMOVE_GUEST]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
