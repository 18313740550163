import MaterialIcon from 'material-icons-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import { Col, Row } from 'reactstrap';
// Reducers
import { resetCe } from '../../sagas/customEventSaga/reducer';

const KEYS_TO_FILTERS = ['title'];
const query = require('array-query');

export class VendorEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.resetCe();
  }

  componentDidMount() {
    // const locationsArray = [];
    // const packageIdArray = [];
    // const packageArray = [];
    // this.props.vendor.locations.forEach(location => {
    //   const docDetails = query("locationId")
    //     .is(location.locationId)
    //     .on(this.props.alllocations);
    //   locationsArray.push(docDetails[0]);
    // });
    // console.log("locationsArray", locationsArray);

    // locationsArray.forEach(location => {
    //   const packageIds = Object.keys(location.packages);
    //   console.log("packageIds", packageIds);
    //   packageIds.forEach(packageId => {
    //     packageIdArray.push(packageId);
    //   });
    // });
    // console.log("packageIdArray", packageIdArray);

    // this.props.packages.forEach(thisPackage => {
    //   const locations = thisPackage.locations;
    //   console.log("plocations", locations);
    //   locations.forEach(packageLocation => {
    //     locationsArray.forEach(location => {
    //       if (packageLocation.locationId === location.locationId) {
    //         console.log("MATH!!!");
    //         packageArray.push(thisPackage);
    //       }
    //     });
    //   });
    // });

    // packageIdArray.forEach(packageId => {
    //   const docDetails = query("packageId")
    //     .is(packageId)
    //     .on(this.props.packages);
    //   packageArray.push(docDetails[0]);
    // });

    this.setState({
      // vendorPackages: packageArray,
      loading: false,
    });
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getLocationLink(allLocations, id) {
    const locationDetails = query('locationId')
      .is(id)
      .on(allLocations);
    const location = locationDetails[0];
    return (
      <Link
        to={{
          pathname: '/location',
          state: {
            location: { location },
            type: 11,
          },
        }}
      >
        <span>{locationDetails[0].locationTitle}</span>
      </Link>
    );
  }

  getLocations(packageLocations) {
    const locations = packageLocations;
    const url = '/location';
    return (
      <div>
        {locations.map((location, index) => (
          <div>
            {this.getLocationLink(
              this.props.alllocations,
              location.locationId,
            )}
          </div>
        ))}
      </div>
    );
  }

  getPackageTitle(title, id) {
    const packageUrl = '/package/' + id;
    return (
      <a href={packageUrl} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    );
  }

  getEditButton(id) {
    return (
      <Link
        to={{
          pathname: '/vendor/event/edit/' + id,
        }}
        // className="redbutton"
      >
        <MaterialIcon icon="edit" size={25} color="red" />
      </Link>
    );
  }

  getStatus(status) {
    if (status === true) {
      return 'Active';
    } else {
      return 'Inactive';
    }
  }

  getEvents(events) {
    return (
      <Row className=" no-margin no-padding">
        <div className="fullwidth">
          <ul className="no-padding">
            <li className="adminlistitem adminlistheader ">
              <div>
                <Col xs={4} md={4} sm={4} lg={4} className="left">
                  <span className="label">Event Name</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Locations</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Creator</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  <span className="label">Status</span>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2} className="left">
                  {/* <span className="label">Actions</span> */}
                </Col>
              </div>
            </li>
            {events.map((event, index) => (
              <li className="adminlistitem">
                <div className="fullwidth">
                  <Col
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    key={'key' + index}
                  >
                    <Link
                      to={{
                        pathname: '/vendor/event/' + event.id,
                      }}
                    >
                      {event.title}
                    </Link>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {this.getLocations(event.locations)}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {event.creatorInfo.creatorName}
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2} className="left">
                    {this.getStatus(event.active)}
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    sm={2}
                    lg={2}
                    className="text-right"
                  >
                    {this.getEditButton(event.id)}
                  </Col>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    );
  }

  // getActiveEventCount(thisPackage) {
  //   const userEventCountArray = [];
  //   this.props.allEvents.forEach(event => {
  //     if (event.packageDetails.packageId === thisPackage.packageId) {
  //       userEventCountArray.push(event);
  //     }
  //   });
  //   const count = userEventCountArray.length;
  //   return count;
  // }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <span className="left">
                {/* <MaterialIcon
                  icon="close"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.editMode(this.state.customEvent.event);
                  }}
                /> */}
              </span>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Events</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/vendor/event/create/scheduled',
                }}
              >
                <span className="right">
                  <MaterialIcon
                    icon="add"
                    size={25}
                    color="white"
                    onClick={() => {}}
                  />
                </span>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    const filteredEvents = this.props.vendorEvents.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );
    return (
      <div id="admin">
        {/* <TitleBar title="Events" backlink="" /> */}
        {this.getTitleBar()}
        <div className="section">
          <div className="header-container">
            <div className="search-area">
              <SearchInput onChange={this.searchUpdated} />
            </div>
          </div>
        </div>
        <div>{this.getEvents(filteredEvents)}</div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} #bottomNav { display: none } ',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    alllocations: state.locations.locations,
    vendor: state.vendor.vendor,
    allEvents: state.allEvents.events,
    vendorEvents: state.vendorEvents.events,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorEvents);
