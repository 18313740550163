import geolib from 'geolib';
import MaterialIcon from 'material-icons-react';
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import { Col, Container, Row } from 'reactstrap';
import FFLocations from '../components/FFLocations';
import FilterModal from '../components/FilterModal';
import LocationDetailsAdd from '../components/LocationDetailsAdd';
// Styles
import '../components/PackageListItem/PackageListItem.css';
import firebase from '../config/firebase';
import { getAllLocations } from '../sagas/allLocationsSaga/reducer';
import { getCe } from '../sagas/customEventSaga/reducer';
// Reducers
import { init } from '../sagas/packagesSaga/reducer';

const KEYS_TO_FILTERS = ['locationTitle'];

const query = require('array-query');
const _ = require('lodash');

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    width: '300px',
  },
};

export class CustomPackageEditHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      filteredArray: null,
      filteredList: '',
      mode: 1,
      distanceFilterState: 0,
      sortState: 1,
      modalState: 1,
      filterModalVisible: false,
      tagFilters: [],
      otherUserTagsLove: [],
      locationsWithDistance: [],
      locationServices: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
    this.setTagFilters = this.setTagFilters.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    if (this.props.custom === true) {
      this.filterCustom(this.props.alllocations);
    } else {
      // this.setState({
      //   filteredArray: this.props.alllocations,
      //   loading: false
      // });
    }
    this.getGeoLocation();
  }

  componentDidMount() {
    this.getUserTags(
      this.props.newEvent,
      this.props.users,
      this.props.profile.userId,
    );

    // Update Other User Tags
    let connections = this.props.newEvent.connections;
    let userId = this.props.profile.userId;
    let otherUserId = '';
    let userMatch = [];
    Object.keys(connections).forEach((key) => {
      if (key !== userId) {
        if (connections[key].newUser !== true) {
          const user = query('userId').is(key).on(this.props.users);
          otherUserId = key;
          userMatch = user[0];
        }
      }
    });
    this.setState({
      otherUser: userMatch,
    });
    if (
      this.props.newEvent.planFirst !== true &&
      this.props.newEvent.invitedUser !== undefined &&
      this.props.newEvent.invitedUser.newUser !== true &&
      otherUserId !== ''
    ) {
      const db = firebase.firestore();
      const userEvents = db.collection('users').doc(otherUserId);
      userEvents.onSnapshot(this.updateOtherUser);
    }
  }

  updateOtherUser = (documentSnapshot) => {
    const eventsObject = {};
    eventsObject.events = [];
    const user = documentSnapshot.data();
    const tags = user.tags;

    if (tags !== null) {
      this.setState({
        otherUserTagsLove: tags.love,
        otherUserTagsHate: tags.hate,
      });
    } else {
      this.setState({
        otherUserTagsLove: undefined,
        otherUserTagsHate: undefined,
      });
    }
    this.searchUpdated('');
  };

  getUserTags(newEvent, users, userId) {
    let connections = newEvent.connections;
    let userTags = null;
    let userMatch = [];
    Object.keys(connections).forEach((key) => {
      if (key !== userId) {
        if (connections[key].newUser !== true) {
          const user = query('userId').is(key).on(users);
          userTags = user[0].tags;
          userMatch = user[0];
        }
      }
    });

    if (userTags !== null && userTags !== undefined) {
      this.setState({
        otherUserTagsLove: userTags.love,
        otherUserTagsHate: userTags.hate,
        otherUser: userMatch,
      });
    } else {
      this.setState({
        otherUserTagsLove: undefined,
        otherUserTagsHate: undefined,
        otherUser: userMatch,
      });
    }
  }

  openFilterModal(user) {
    this.setState({ filterModalVisible: true });
  }

  closeFilterModal = () => {
    this.searchUpdated('');
    this.setState({ filterModalVisible: false });
  };

  setTagFilters(tagFilters) {
    this.setState({ tagFilters });
  }

  getGeoLocation = () => {
    let self = this;
    let currentLongitude = '';
    let currentLatitude = '';
    this.getPosition()
      .then((position) => {
        self.setState({
          currentLongitude: position.coords.longitude,
          currentLatitude: position.coords.latitude,
          error: null,
        });
        currentLatitude = position.coords.latitude;
        currentLongitude = position.coords.longitude;
        this.addDistance(
          this.props.alllocations,
          currentLongitude,
          currentLatitude,
        );
        this.searchUpdated('');
      })
      .catch((err) => {
        console.error('something wrongg', err.message);
        self.setState({
          locationServices: false,
        });
      });
  };

  getPosition() {
    let options = {
      timeout: 5000,
      maximumAge: 10000,
      enableHighAccuracy: true,
    };
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          options,
        ),
      );
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }

  filterCustom(locations) {
    const filteredArray = [];
    const theselocations = locations;
    theselocations.forEach((location) => {
      if (location.custom === true) {
        filteredArray.push(location);
      }
    });
    this.setState({
      filteredArray,
    });
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  searchUpdated(term) {
    const filteredList = this.state.locationsWithDistance.filter(
      createFilter(term, KEYS_TO_FILTERS),
    );
    let filteredLocations = [];
    const sortedLocations = this.sortAndWeight(
      filteredList,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );

    let selectedTags = [];
    this.state.tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });

    if (selectedTags.length > 0) {
      filteredLocations = this.updateLocations(
        this.props.tags,
        sortedLocations,
      );
    } else {
      filteredLocations = sortedLocations;
    }
    this.setState({
      searchTerm: term,
      filteredArray: filteredLocations,
    });
  }

  clearTagFilters(tags) {
    let updatedTags = tags;
    updatedTags.forEach((tagcat, x) => {
      tagcat.tags.map((tag, y) => {
        updatedTags[x].tags[y].checked = false;
        updatedTags[x].tags[y].parentindex = x;
        updatedTags[x].tags[y].tagindex = y;
      });
    });
    this.setState({
      tagFilters: updatedTags,
    });
    window.scrollTo(0, 0);
    this.searchUpdated('');
  }

  addDistance(locations, currentLongitude, currentLatitude) {
    const locationsArray = [];
    locations.forEach((location) => {
      const locLat = location.address.latitude;
      const locLong = location.address.longitude;
      const distance = geolib.getDistance(
        { latitude: locLong, longitude: locLat },
        { latitude: currentLatitude, longitude: currentLongitude },
      );
      // console.log("geolibtard", distance);
      const miles = geolib.convertUnit('mi', distance, 2); // -> 14,21
      location.address.distance = miles;

      locationsArray.push(location);
    });
    const sortedLocations = this.sortAndWeight(
      locations,
      this.state.distanceFilterState,
      this.state.currentLongitude,
      this.state.currentLatitude,
      this.state.sortState,
    );
    this.setState({
      locationsWithDistance: locationsArray,
      loading: false,
      filteredArray: sortedLocations,
    });
  }

  weightLocations(
    locations,
    userTagsLove,
    userTagsHate,
    otherUserTagsLove,
    otherUserTagsHate,
  ) {
    const weightedLocations = [];

    locations.forEach((location) => {
      let weightedLocation = location;
      let weight = 0;
      weightedLocation.matchesOtherUserLove = [];
      weightedLocation.matchesOtherUserHate = [];
      weightedLocation.matchesUserLove = [];
      weightedLocation.matchesUserHate = [];
      weightedLocation.weight = weight;

      // Other User Love

      if (otherUserTagsLove !== undefined) {
        if (location.tagsBeverages !== undefined) {
          location.tagsBeverages.forEach((userTag) => {
            otherUserTagsLove.tagsBeverages.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 5;
              }
            });
          });
        }

        if (location.tagsCuisine !== undefined) {
          location.tagsCuisine.forEach((userTag) => {
            otherUserTagsLove.tagsCuisine.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 5;
              }
            });
          });
        }

        if (location.tagsDateType !== undefined) {
          location.tagsDateType.forEach((userTag) => {
            otherUserTagsLove.tagsDateType.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 5;
              }
            });
          });
        }

        if (location.tagsSetting !== undefined) {
          location.tagsSetting.forEach((userTag) => {
            otherUserTagsLove.tagsSetting.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserLove.push(tag);
                weight = weight + 1;
              }
            });
          });
        }
      }

      // Other User Hate
      if (otherUserTagsHate !== undefined) {
        if (location.tagsBeverages !== undefined) {
          location.tagsBeverages.forEach((userTag) => {
            otherUserTagsHate.tagsBeverages.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 5;
              }
            });
          });
        }

        if (location.tagsCuisine !== undefined) {
          location.tagsCuisine.forEach((userTag) => {
            otherUserTagsHate.tagsCuisine.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 5;
              }
            });
          });
        }

        if (location.tagsDateType !== undefined) {
          location.tagsDateType.forEach((userTag) => {
            otherUserTagsHate.tagsDateType.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 5;
              }
            });
          });
        }

        if (location.tagsSetting !== undefined) {
          location.tagsSetting.forEach((userTag) => {
            otherUserTagsHate.tagsSetting.forEach((tag) => {
              if (userTag === tag) {
                weightedLocation.matchesOtherUserHate.push(tag);
                weight = weight - 5;
              }
            });
          });
        }
      }

      // User Love

      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((userTag) => {
          userTagsLove.tagsBeverages.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 5;
            }
          });
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((userTag) => {
          userTagsLove.tagsCuisine.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 5;
            }
          });
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((userTag) => {
          userTagsLove.tagsDateType.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 5;
            }
          });
        });
      }

      if (location.tagsSetting !== undefined) {
        location.tagsSetting.forEach((userTag) => {
          userTagsLove.tagsSetting.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserLove.push(tag);
              weight = weight + 1;
            }
          });
        });
      }

      // User Hate

      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((userTag) => {
          userTagsHate.tagsBeverages.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 5;
            }
          });
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((userTag) => {
          userTagsHate.tagsCuisine.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 5;
            }
          });
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((userTag) => {
          userTagsHate.tagsDateType.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 5;
            }
          });
        });
      }

      if (location.tagsSetting !== undefined) {
        location.tagsSetting.forEach((userTag) => {
          userTagsHate.tagsSetting.forEach((tag) => {
            if (userTag === tag) {
              weightedLocation.matchesUserHate.push(tag);
              weight = weight - 5;
            }
          });
        });
      }

      weightedLocation.weight = weight;
      weightedLocations.push(weightedLocation);
    });

    return _.orderBy(weightedLocations, ['weight'], ['desc', 'asc']);
  }

  getWeightedLocations(locations, user) {
    let otherUserTagsLove = this.state.otherUserTagsLove;
    let otherUserTagsHate = this.state.otherUserTagsHate;

    if (user.tags !== undefined) {
      let userTagsLove = user.tags.love;
      let userTagsHate = user.tags.hate;
      const weightedLocations = this.weightLocations(
        locations,
        userTagsLove,
        userTagsHate,
        otherUserTagsLove,
        otherUserTagsHate,
      );
      return weightedLocations;
    }
  }

  updateLocations(tags, locations) {
    // This will have every location but with its tags put into a single list
    const locationsReady = [];
    // For each location we push all of its tags into the allTags array
    locations.forEach((location) => {
      const allTags = [];
      if (location.tagsBeverages !== undefined) {
        location.tagsBeverages.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsSetting !== undefined) {
        location.tagsSetting.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsCuisine !== undefined) {
        location.tagsCuisine.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsHours !== undefined) {
        location.tagsHours.forEach((tag) => {
          allTags.push(tag);
        });
      }

      if (location.tagsDateType !== undefined) {
        location.tagsDateType.forEach((tag) => {
          allTags.push(tag);
        });
      }

      const newLocation = location;
      newLocation.tags = allTags;
      locationsReady.push(newLocation);
    });

    // const dif = _.differenceWith(cuisine, beverages, _.isEqual);
    // const filtered = _.filter(coll, item => _.some(filter, key => _.includes(item.tags, key)));

    // Filter Locations Based On Selected Categories
    function filterByMultipleFields(coll, filter) {
      // Organize Tags into their categories
      const tagsBeverages = [];
      const tagsSetting = [];
      const tagsCuisine = [];
      const tagsHours = [];
      const tagsDateType = [];

      let beverages = [];
      let setting = [];
      let type = [];
      let cuisine = [];
      let hours = [];

      tags.forEach((tagcat) => {
        tagcat.tags.forEach((tag) => {
          if (tagcat.parent === 'Hours') {
            tagsHours.push(tag.tag);
          } else if (tagcat.parent === 'Beverages') {
            tagsBeverages.push(tag.tag);
          } else if (tagcat.parent === 'Date Type') {
            tagsDateType.push(tag.tag);
          } else if (tagcat.parent === 'Cuisine Type') {
            tagsCuisine.push(tag.tag);
          } else if (tagcat.parent === 'Setting Type') {
            tagsSetting.push(tag.tag);
          }
        });
      });

      filter.forEach((tag) => {
        if (tagsBeverages.includes(tag) === true) {
          beverages = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsBeverages, key),
            ),
          );
        } else if (tagsDateType.includes(tag) === true) {
          type = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsDateType, key),
            ),
          );
        } else if (tagsSetting.includes(tag) === true) {
          setting = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsSetting, key),
            ),
          );
        } else if (tagsHours.includes(tag) === true) {
          hours = _.filter(locationsReady, (item) =>
            _.some(filter, (key) => _.includes(item.tagsHours, key)),
          );
        } else if (tagsCuisine.includes(tag) === true) {
          cuisine = _.filter(locationsReady, (item) =>
            _.some(filter, (key) =>
              _.includes(item.tagsCuisine, key),
            ),
          );
        }
      });
      const filtered = _.filter(coll, (item) =>
        _.some(filter, (key) => _.includes(item.tags, key)),
      );
      const arrays = [cuisine, beverages, type, setting, hours];
      const result = _.spread(_.union)(arrays);
      return result;
    }
    const filter = this.selectedTags(this.state.tagFilters);
    const result = filterByMultipleFields(locationsReady, filter);
    return result;
    // if (this.props.selectedCategories.length > 0) {
    //   this.setState({
    //     filteredLocations: result,
    //   });
    // } else {
    //   this.setState({
    //     filteredLocations: locations,
    //   });
    // }
  }

  sortLocations(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    if (sortState === 1) {
      const locationsArray = [];
      locations.forEach((location) => {
        const miles = location.address.distance;
        if (location.type !== 1) {
          if (distanceFilterState === 0) {
            if (miles <= 50) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 1) {
            if (miles <= 10) {
              locationsArray.push(location);
            }
          } else if (distanceFilterState === 2) {
            locationsArray.push(location);
          }
        }
      });
      return _.sortBy(locationsArray, (o) => o.address.distance);
    } else {
      return _.sortBy(locations, (o) => o.locationTitle);
    }
  }

  sortAndWeight(
    locations,
    distanceFilterState,
    currentLongitude,
    currentLatitude,
    sortState,
  ) {
    const sortedLocations = this.sortLocations(
      locations,
      distanceFilterState,
      currentLongitude,
      currentLatitude,
      sortState,
    );
    const weightedLocations = this.getWeightedLocations(
      sortedLocations,
      this.props.profile,
    );

    return weightedLocations;
  }

  changeLocation(customEvent, location, index, type, custom) {
    if (type === 0) {
      const updatedLocations = [...customEvent.event.locations];
      if (index != null) {
        const update = customEvent;
        updatedLocations.splice(index, 1, {
          locationId: location.locationId,
          type,
          locationTitle: location.locationTitle,
          locationThumb: location.locationThumb,
          address: location.address,
        });
        update.event.locations = updatedLocations;
        this.props.getCe(update);
      }
    } else {
      const updatedLocations = [...customEvent.event.locations];
      if (index != null) {
        const update = customEvent;
        updatedLocations.splice(index, 1, {
          location: location,
          type: 1,
        });
        update.event.locations = updatedLocations;
        this.props.getCe(update);
      }
    }
  }

  getFooter(mode) {
    return (
      <Container>
        <Row className="no-margin">
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <a
              onClick={() => {
                this.setMode(1);
              }}
            >
              <span className="footer-link left">Recommended</span>
            </a>
          </Col>
          <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
            <a
              onClick={() => {
                this.setMode(2);
              }}
            >
              <span className="right footer-link">Any Location</span>
            </a>
          </Col>
        </Row>
      </Container>
    );
  }

  getLocationTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <a
                onClick={() => {
                  this.hideLocationDetails();
                }}
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </a>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Add Location</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  getContent(mode, users, userId) {
    if (mode === 1) {
      return this.getRecommendedTab();
    } else if (mode === 2) {
      return this.getFSTab();
    }
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname:
                    '/event/edit/' +
                    this.props.customEvent.event.eventId +
                    '/1',
                }}
                className="left"
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Change Location</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
          </Row>
        </div>
      </div>
    );
  }

  getTab(tabMode, tabLable, mode) {
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tabLable}
          </div>
        </a>
      );
    }
  }

  getFSTab() {
    return (
      <FFLocations
        locations={this.props.alllocations}
        addLocation={this.changeLocation}
        customEvent={this.props.customEvent}
        index={this.props.history.location.state.index}
      />
    );
  }

  getCurrentDistance(filter) {
    let text = '';
    if (filter === 0) {
      text = '50';
    } else if (filter === 1) {
      text = '10';
    } else {
      text = '100+';
    }
    return text;
  }

  getMatches(location) {
    let userLoveTags = [];
    let otherUserLoveTags = [];
    let matchLoveTags = [];
    let userHateTags = [];
    let otherUserHateTags = [];
    let matchHateTags = [];
    let matchesUserHate = [];
    if (location.matchesUserHate !== undefined) {
      matchesUserHate = location.matchesUserHate;
    }
    let matchesUserLove = [];
    if (location.matchesUserLove !== undefined) {
      matchesUserLove = location.matchesUserLove;
    }
    let matchesOtherUserHate = [];
    if (location.matchesOtherUserHate !== undefined) {
      matchesOtherUserHate = location.matchesOtherUserHate;
    }
    let matchesOtherUserLove = [];
    if (location.matchesOtherUserLove !== undefined) {
      matchesOtherUserLove = location.matchesOtherUserLove;
    }
    matchesUserHate.forEach((userMatch) => {
      if (matchesOtherUserHate.length !== 0) {
        matchesOtherUserHate.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            matchHateTags.push(userMatch);
          } else {
            userHateTags.push(userMatch);
          }
        });
      }
    });

    matchesUserLove.forEach((userMatch) => {
      let matched = [];
      if (matchesOtherUserLove.length !== 0) {
        matchesOtherUserLove.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            matched.push(userMatch);
          }
        });
      }
      if (matched.length !== 0) {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          matchLoveTags.push(userMatch);
        }
      } else {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          userLoveTags.push(userMatch);
        }
      }
    });

    matchesOtherUserLove.forEach((userMatch) => {
      let matched = [];
      if (matchesUserLove.length !== 0) {
        matchesUserLove.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            if (matchLoveTags.indexOf(userMatch) === 0) {
              matched.push(userMatch);
            }
          }
        });
      }
      if (matched.length !== 0) {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          matchLoveTags.push(userMatch);
        }
      } else {
        if (matchLoveTags.indexOf(userMatch) === -1) {
          otherUserLoveTags.push(userMatch);
        }
      }
    });

    matchesOtherUserHate.forEach((userMatch) => {
      if (matchesUserHate.length !== 0) {
        matchesUserHate.forEach((otherUserMatch) => {
          if (userMatch === otherUserMatch) {
            if (matchHateTags.indexOf(userMatch) === 0) {
              matchHateTags.push(userMatch);
            }
          } else {
            if (matchHateTags.indexOf(userMatch) === 0) {
              otherUserHateTags.push(userMatch);
            }
          }
        });
      }
    });
    return (
      <div>
        {userLoveTags.map((tag, index) => (
          <span className="tag-user">{tag}</span>
        ))}
        {otherUserLoveTags.map((tag, index) => (
          <span className="tag-otherUser">{tag}</span>
        ))}
        {matchLoveTags.map((tag, index) => (
          <span className="tag-match">{tag}</span>
        ))}
      </div>
    );
  }

  getKey() {
    if (this.state.otherUser.length !== 0) {
      return (
        <p>
          <b>Tag Colors: </b>
          <span className="tag-user">
            {/* {this.props.profile.firstName} */}
            You
          </span>
          <span className="tag-otherUser">
            {this.state.otherUser.firstName}
          </span>
          <span className="tag-match">Match!</span>
        </p>
      );
    } else {
      return (
        <p>
          <b>Tag Colors: </b>
          <span className="tag-user">You</span>
        </p>
      );
    }
  }

  selectedTags(tagFilters) {
    let selectedTags = [];
    tagFilters.forEach((tagCat) => {
      tagCat.tags.forEach((tag) => {
        if (tag.checked === true) {
          selectedTags.push(tag.tag);
        }
      });
    });

    return selectedTags;
  }

  getFilteredTag(tag) {
    return <span className="tag-match">{tag}</span>;
  }

  getFilters() {
    if (this.state.tagFilters.length !== 0) {
      let selectedTags = this.selectedTags(this.state.tagFilters);
      if (selectedTags.length > 0) {
        return (
          <p>
            <b>Applied Filters: </b>
            {selectedTags.map((tag) => {
              return this.getFilteredTag(tag);
            })}
            <span
              onClick={() => {
                this.clearTagFilters(this.props.tags);
              }}
              className="redlink"
            >
              clear all
            </span>
          </p>
        );
      }
    }
  }

  showLocationDetails(location) {
    this.setState({
      showLocation: true,
      activeLocation: location,
    });
  }

  hideLocationDetails() {
    this.setState({
      showLocation: false,
      activeLocation: null,
    });
  }

  getLocationDetails(location) {
    return (
      <div>
        <LocationDetailsAdd
          customEvent={this.props.customEvent}
          location={location}
          changeLocation={this.changeLocation}
          index={this.props.history.location.state.index}
          type={2}
        />
      </div>
    );
  }

  getImage(type, locationStart) {
    let location = '';
    let locations = this.props.alllocations;
    const locationDetails = query('locationId')
      .is(locationStart.locationId)
      .on(locations);
    location = locationDetails[0];
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getMainTag(location) {
    if (location.tagsMain !== undefined) {
      return <span className="mainTag">{location.tagsMain}</span>;
    }
  }

  getRecommendedTab() {
    return (
      <div id="events" className="section">
        <div className="header-container">
          <Row className="no-margin">
            <Col
              xs={11}
              md={11}
              sm={11}
              lg={11}
              className="no-padding"
            >
              <SearchInput
                className="search-input"
                onChange={this.searchUpdated}
              />
            </Col>
            <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
              <a
                onClick={() => {
                  this.openFilterModal();
                }}
                className="right"
              >
                <MaterialIcon
                  icon="filter_list"
                  size={25}
                  color="#f21d59"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <p>
                Showing locations{' '}
                <b>
                  {this.getCurrentDistance(
                    this.state.distanceFilterState,
                  )}
                </b>{' '}
                miles from you.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              {this.getKey()}
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              {this.getFilters()}
            </Col>
          </Row>
        </div>
        <div>
          <Container className="no-padding">
            {this.state.filteredArray.map((location, index) => (
              <div className="oddeven" key={location.locationId}>
                <Row key={location.locationId}>
                  <Col
                    xs={10}
                    md={10}
                    sm={10}
                    lg={10}
                    className="no-padding"
                  >
                    <div
                      style={{
                        float: 'left',
                        width: '100px',
                        marginRight: '10px',
                      }}
                    >
                      <a
                        onClick={() => {
                          this.showLocationDetails(location);
                        }}
                      >
                        {this.getImage('thumb', location)}
                      </a>
                    </div>

                    {/* </Col>
                  <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={6}
                    className="no-padding"
                  > */}
                    <div>
                      <a
                        onClick={() => {
                          this.showLocationDetails(location);
                        }}
                      >
                        <span className="location-title">
                          {location.locationTitle}
                        </span>
                        <span className="address">
                          {location.address.street}
                        </span>
                        <span className="address">
                          {location.address.city}{' '}
                          {location.address.state},{' '}
                          {location.address.zip}
                        </span>
                      </a>
                    </div>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    sm={2}
                    lg={2}
                    className="no-padding"
                  >
                    <div className="right text-right">
                      <MaterialIcon
                        icon="add_circle"
                        size={25}
                        color="#f21d59"
                        onClick={() => {
                          this.changeLocation(
                            this.props.customEvent,
                            location,
                            this.props.history.location.state.index,
                            0,
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div
                      style={{
                        float: 'left',
                        width: '100px',
                        marginRight: '10px',
                      }}
                    >
                      <span className="distance">
                        {location.address.distance} miles
                      </span>
                    </div>
                    {/* </Col>

                  <Col
                    xs={8}
                    md={8}
                    sm={8}
                    lg={8}
                    className="no-padding"
                  > */}
                    {this.getMainTag(location)}
                    {this.getMatches(location)}
                  </Col>
                </Row>
              </div>
            ))}
          </Container>
        </div>
      </div>
    );
  }

  getModalHeader = (value) => {
    return (
      <Row className="no-margin">
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding" />
        <Col lg={8} md={8} sm={8} xs={8}>
          <center>
            <span className="page-header white">
              Filter Locations
            </span>
          </center>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
          <a onClick={() => this.closeFilterModal()}>
            <MaterialIcon icon="check" size={25} color="white" />
          </a>
        </Col>
      </Row>
    );
  };

  setDistanceFilter = (value) => {
    this.setState({
      distanceFilterState: value,
    });
  };

  getButton(value, distance, state) {
    if (value !== state) {
      return (
        <button
          className="redbutton full-width"
          onClick={() => {
            this.setDistanceFilter(value);
          }}
        >
          {distance}
        </button>
      );
    } else {
      return (
        <button className="greybutton full-width">{distance}</button>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div id="home">
          {this.getTitleBar()}
          {/* <div id="headersTopSecondary">
            <Row>
              <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
                {this.getTab(1, 'Recommended', this.state.mode)}
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
                {this.getTab(2, 'Any Location', this.state.mode)}
              </Col>
            </Row>
          </div> */}
          <div className="section text-center">
            <h3>
              {this.state.locationServices === true
                ? 'Gathering some great options...'
                : 'Please Check Your Location Settings and Refresh'}
            </h3>
          </div>
          <div className="footer">{this.getFooter()}</div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 40px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    if (this.state.showLocation === true) {
      window.scrollTo(0, 0);
      return (
        <div id="home">
          {this.getLocationTitleBar()}
          {this.getLocationDetails(this.state.activeLocation)}
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 40px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    return (
      <div id="home">
        {this.getTitleBar()}
        {/* <div id="headersTopSecondary">
          <Row>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(1, 'Trevel Destinations', this.state.mode)}
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {this.getTab(2, 'Other Locations', this.state.mode)}
            </Col>
          </Row>
        </div> */}
        {this.getContent(this.state.mode)}
        <div className="footer">{this.getFooter()}</div>
        <Modal
          isOpen={this.state.filterModalVisible}
          // onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeFilterModal}
          style={modalStyles}
          // contentLabel="Wait for Connection?"
        >
          <div className="full-height">
            <div className="modalHeader">{this.getModalHeader()}</div>
            <div className="secondary-title-bar">
              <div>
                <Container>
                  <Row className="no-margin">
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding text-center"
                    >
                      <span>What are you in the mood for?</span>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="wizard-section-border">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <h5 className="red">Distance</h5>
                  <span>Radius (Miles):</span>
                </Col>
              </Row>

              <Row>
                <Col
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="padding-ten"
                >
                  {this.getButton(
                    1,
                    '10',
                    this.state.distanceFilterState,
                  )}
                </Col>
                <Col
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="padding-ten"
                >
                  {this.getButton(
                    0,
                    '50',
                    this.state.distanceFilterState,
                  )}
                </Col>
                <Col
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="padding-ten"
                >
                  {this.getButton(
                    2,
                    '100+',
                    this.state.distanceFilterState,
                  )}
                </Col>
              </Row>
            </div>
            <div className="wizard-section-border">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <FilterModal
                    tagFilters={this.state.tagFilters}
                    setTagFilters={this.setTagFilters}
                    otherUserTagsLove={this.state.otherUserTagsLove}
                    otherUser={this.state.otherUser}
                    customEvent={this.props.customEvent}
                  />
                </Col>
              </Row>
            </div>
            {/* <div className="section">{this.getModalContent()}</div> */}
          </div>
        </Modal>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.section{max-width: 1000px} .container{max-width:100%} #headersTopSecondary{width:100%} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 40px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alllocations: state.locations.locations,
    customEvent: state.customEvent.customEvent,
    profile: state.profile.profile,
    newEvent: state.newEvent,
    users: state.users.users,
    tags: state.tags.tags,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(init());
    },
    getAllLocations: (zone) => {
      dispatch(getAllLocations(zone));
    },
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomPackageEditHome);
