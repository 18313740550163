import * as action from './actions';

export const init = (zoneId) => {
  return {
    type: action.INIT,
    payload: {},
    zoneId: zoneId,
  };
};

export const setPackages = (packages) => {
  return {
    type: action.SET_PACKAGES,
    payload: packages,
  };
};

const ACTION_HANDLERS = {
  [action.SET_PACKAGES]: (state, action) => {
    return { ...state, ...action.payload };
  },

  [action.INIT]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = { packages: [] };

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
