import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import VendorMenu from '../../components/VendorMenu';
import VendorFeatures from '../../components/VendorFeatures';
import ex1 from '../../images/ex1.png';
// Components
import TitleBar from '../../components/TitleBar';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';

export class VendorPilot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
    };
    this.showPicker = this.showPicker.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  getAppContent(filteredLocations) {
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuNew">
          <VendorMenu popupMenu={false} />
        </div>
        <div
          style={{
            float: 'left',
            width: 'calc(100% - 120px)',
          }}
        >
          <div>
            <div>
              {/* <div>
                <Container>
                  <Row className="greybox">
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <h5>News and Announcements</h5>
                      <span className="bold">
                        Trevel Is Currently In Beta
                      </span>
                      <p>
                        The iPhone version and the Web version of
                        Trevel are currently in Beta. So what does
                        that mean? It means you might find a bug on
                        occasion as users test the tools, and that
                        design is being polished up as we get ready
                        for mass consumption.
                      </p>
                      <hr />
                      <span className="bold">
                        {this.props.vendor.businessName} Joined The
                        Beta
                      </span>
                      <p>
                        Thank you for claiming your location and
                        helping us make Trevel an essential tool for
                        both our users and our location partners. Mike
                        from Trevel will reach out to you with more
                        information soon.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div> */}
              <div className="section">
                <div
                  style={{
                    borderBottom: '1px solid grey',
                    padding: '10px',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Container>
                    <Row>
                      <Col xs={12} md={12} sm={12} lg={12}>
                        <center>
                          <h2>Join The Pilot!</h2>
                          <p>
                            We are looking for local businesses to
                            join our pilot launch of Trevel
                            Experiences, a new platform that lets you
                            gamify your business and drive customer
                            engagement.
                          </p>
                          <p>
                            If you are interetsed in finding new ways
                            to incentivize your customers we would
                            love to talk.{' '}
                          </p>{' '}
                          <p>
                            Just call Mike at{' '}
                            <span style={{ fontWeight: '600' }}>
                              (913) 384-7377.
                            </span>
                          </p>
                        </center>
                      </Col>
                    </Row>
                  </Container>
                </div>

                <div id="home">
                  <div
                    className="cityBanner"
                    style={{ padding: '20px 0px 0px 0px' }}
                  >
                    <h2
                      style={{
                        margin: '10px 0px 0px 0px',
                        color: 'black',
                      }}
                    >
                      <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2FTrevelExperiences.svg?alt=media&token=266be1b5-da5b-494a-bdda-ecc8eaa1a880" />
                    </h2>
                    <h2
                      style={{
                        fontSize: '30px',
                        lineHeight: '35px',
                        color: '#f21f5a',
                        marginBottom: '30px',
                      }}
                    >
                      Gamify Your Business
                    </h2>
                  </div>
                  <img
                    src={ex1}
                    style={{
                      maxWidth: '75%',
                      margin: '0px auto',
                      display: 'block',
                    }}
                  />
                  <VendorFeatures />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">{title}</span>

              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.vendor === undefined) {
      return null; // or render a loading icon
    }
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Trevel Experiences')}
        <div className="vendorContent">{this.getAppContent()}</div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} #analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorPilot);
