import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
// Components
import EventListItem from '../../components/EventListItem';
import TitleBar from '../../components/TitleBar';
// Reducers
import { getAllLocations } from '../../sagas/allLocationsSaga/reducer';
import { resetCe } from '../../sagas/customEventSaga/reducer';
import {
  getEvents,
  refreshEvents,
} from '../../sagas/userEventsSaga/reducer';

const query = require('array-query');

export class ScrapBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    // this.props.getEvents(this.props.user.id);
    // if (this.props.events.length !== 0) {
    //   this.setEvents(this.props.events);
    // } else {
    //   this.setState({
    //     loading: false
    //   });
    // }
    if (this.props.customEvent.clear === false) {
      const create = false;
      this.props.resetCe(create);
    }
    // this.setEvents(this.props.events);
  }

  componentDidMount() {
    // Get Events In RealTime
    // const table = `users.${this.props.user.id}.access`;
    // const userEvents = firebase
    //   .firestore()
    //   .collection("user_events")
    //   .where(table, "==", true);
    // userEvents.onSnapshot(this.eventUpdate);
  }

  // eventUpdate = querySnapshot => {
  //   const eventsObject = {};
  //   eventsObject.events = [];

  //   querySnapshot.forEach(doc => {
  //     const {
  //       creationDetails,
  //       eventSettings,
  //       packageDetails,
  //       usersAttending,
  //       status,
  //       title,
  //       description,
  //       scheduledTime,
  //       heroImage,
  //       thumbnail,
  //       planners,
  //       editHistory,
  //       users,
  //       invitations
  //     } = doc.data();
  //     eventsObject.events.push({
  //       eventId: doc.id,
  //       creationDetails,
  //       eventSettings,
  //       packageDetails,
  //       usersAttending,
  //       status,
  //       title,
  //       description,
  //       scheduledTime,
  //       heroImage,
  //       thumbnail,
  //       planners,
  //       editHistory,
  //       users,
  //       invitations
  //     });
  //   });

  //   console.log("UPDATING EVENT!!!", eventsObject);

  //   this.props.refreshEvents(eventsObject);

  //   this.setEvents(eventsObject.events);
  // };

  setEvents(events, userId) {
    const finishedArray = [];
    let setEvents = {};
    if (events !== undefined) {
      events.forEach(event => {
        if (event.users[userId].finished === true) {
          finishedArray.push(event);
        }
      });
      setEvents = {
        finished: finishedArray,
      };
      return setEvents;
    }
  }

  changeMode(mode) {
    this.setState({
      mode,
    });
  }

  getFooter() {
    return (
      <Container>
        <Row className="no-margin">
          <Col
            xs={12}
            md={12}
            sm={12}
            lg={12}
            className="no-padding text-center"
          >
            <Link
              to={{
                pathname: '/events/home',
              }}
            >
              <span className="footer-link-withborder">
                Create New Experience
              </span>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  getContent(setEvents) {
    const finished = setEvents.finished;
    // if (setEvents.plannedCount === 0) {
    if (finished.length === 0) {
      return (
        <Container className="pushfifty">
          <Row className="text-center">
            <p>
              Your Scrap Book is where you can find details and photos
              from past events.
            </p>

            <p>
              <b>You do not have any memories yet.</b>
            </p>
          </Row>
          <Row className="text-center">
            <Link
              to={{
                pathname: '/events/home',
              }}
              className="solidredbutton"
            >
              Create New Experience
            </Link>
          </Row>
        </Container>
      );
    } else {
      return (
        <div>
          <div>
            <EventListItem events={finished} mode="3" />
          </div>
        </div>
      );
    }
  }

  getActionButton(mode, title, count) {
    if (this.state.mode === mode) {
      return (
        <div
          className="text-center actionButton actionButtonActive"
          onClick={() => {
            this.changeMode(mode);
          }}
        >
          {title}
          <div className="counterGrey">
            <span>{count}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="text-center  actionButton actionButtonInactive"
          onClick={() => {
            this.changeMode(mode);
          }}
        >
          {title}
          <div className="counterGrey">
            <span>{count}</span>
          </div>
        </div>
      );
    }
  }

  render() {
    let setEvents = this.setEvents(
      this.props.events,
      this.props.user.id,
    );

    return (
      <div id="events">
        <TitleBar title="Scrap Book" backlink="" />
        <div className="adminActionBar">
          <Row>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              <div className="text-center actionButtonActive actionButton">
                Events
              </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {/* <Link
                to={{
                  pathname: '/events/planning'
                }}
              > */}
              <div className="text-center actionButton actionButtonInactive inactive">
                Friends
              </div>
              {/* </Link> */}
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
              {/* <Link
                to={{
                  pathname: '/events/invitations'
                }}
              > */}
              <div className="text-center actionButton actionButtonInactive noBorderRight inactive">
                Photo Wall
              </div>
              {/* </Link> */}
            </Col>
          </Row>
        </div>
        <div id="events" className="actionBarPush">
          <div className="section">{this.getContent(setEvents)}</div>
        </div>
        <div className="footer">{this.getFooter()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    events: state.events.events,
    alllocations: state.locations.locations,
    user: state.user,
    customEvent: state.customEvent.customEvent,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getAllLocations: zone => {
      dispatch(getAllLocations(zone));
    },
    getEvents: userId => {
      dispatch(getEvents(userId));
    },
    refreshEvents: events => {
      dispatch(refreshEvents(events));
    },
    resetCe: zone => {
      dispatch(resetCe(zone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScrapBook);
