import { call, put, takeLatest } from "redux-saga/effects";
import {
  savePurchase,
  saveVouchers
  // saveVoucher,
  // saveCustomEvent
} from "../../services/SavePurchase";
import { push } from "connected-react-router";
import * as actions from "./actions";
import { getUserVouchers } from "../userVoucherSaga/reducer";
import { getEvents } from "../userEventsSaga/reducer";
import { sendInvitation } from "../../services/InvitationServices";
import { saveColabEvent } from "../../services/UserEventServices";

let defaultState = {
  purchaseStatus: {
    status: 0
  }
};

function* savePurchaseAction(action) {
  let users = action.payload.event.users;
  let url = "";
  let newUserId = null;
  Object.keys(users).forEach(key => {
    if (users[key].newUser !== undefined && users[key].newUser === true) {
      newUserId = key;
    }
  });
  if (newUserId === null) {
    url = "/event/edit/" + action.payload.eventId + "/1";
  } else {
    url = "/invitelink/" + newUserId + "/" + action.payload.eventId;
  }
  yield call(saveColabEvent, action.payload.event);
  yield call(savePurchase, action.payload);
  yield call(saveVouchers, action.payload.userVouchers);
  yield put(getUserVouchers(action.payload.profile.userId));
  yield put(getEvents(action.payload.profile.userId));
  yield put({
    type: actions.SAVE_SUCCESS,
    payload: {
      ...defaultState,
      action
    }
  });
  yield put(push(url));
}

export default function* sagas() {
  yield takeLatest(actions.SAVE_PURCHASE, savePurchaseAction);
}
