import * as action from './actions';

export const getProfile = (id) => {
  return {
    type: action.GET_PROFILE,
    payload: id,
  };
};

export const saveState = (bundle) => {
  return {
    type: action.SAVE_STATE,
    payload: bundle,
  };
};

export const getUpdatedProfile = (id) => {
  return {
    type: action.GET_UPDATEDPROFILE,
    payload: id,
  };
};

export const getUpdatedProfileFavorites = (id) => {
  return {
    type: action.GET_UPDATEDPROFILEFAVS,
    payload: id,
  };
};

export const setProfile = (profile) => {
  return {
    type: action.SET_PROFILE,
    payload: profile,
  };
};

export const clearProfile = (profile) => {
  const clearedProfile = {
    profile: {
      userType: 0,
    },
  };
  return {
    type: action.CLEAR_PROFILE,
    payload: clearedProfile,
  };
};

const ACTION_HANDLERS = {
  [action.GET_PROFILE]: (state, action) => {
    return { ...state };
  },
  [action.SET_PROFILE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.CLEAR_PROFILE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_UPDATEDPROFILE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.GET_UPDATEDPROFILEFAVS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [action.SAVE_STATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {
  profile: {
    userType: 0,
  },
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
