import * as action from './actions';

export const getGuideTemplates = (id) => {
  return {
    type: action.GET_GUIDETEMPLATES,
    payload: id,
  };
};

export const setGuideTemplates = (templates) => {
  return {
    type: action.SET_GUIDETEMPLATES,
    payload: templates,
  };
};

const ACTION_HANDLERS = {
  [action.GET_GUIDETEMPLATES]: (state, action) => {
    return { ...state };
  },
  [action.SET_GUIDETEMPLATES]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

let defaultState = {};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
