import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import {
  DayPicker,
  DateUtils,
  DayClickEventHandler,
} from 'react-day-picker';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import firebase from '../../config/firebase';
import FileUploader from 'react-firebase-file-uploader';
import Select from 'react-select';
import MaterialIcon from 'material-icons-react';
import ReactCrop from 'react-image-crop';
import UserAvatar from 'react-user-avatar';
import CreatableSelect from 'react-select/creatable';
import { Link, NavLink } from 'react-router-dom';
import Modal from 'react-modal';
import logo from '../../images/TrevelUnderlined.svg';
// Reducers
// import { setLocations } from '../../sagas/locationsSaga/reducer';
import { getVendorTimedEvents } from '../../sagas/vendorTimedEventsSaga/reducer';
import { getVendorEntertainment } from '../../sagas/vendorEntertainmentSaga/reducer';
import { getTimedEvents } from '../../sagas/timedEventsSaga/reducer';
import { getEntertainment } from '../../sagas/entertainmentSaga/reducer';
import { select } from 'redux-saga/effects';

const format = 'h:mm a';
const now = moment().hour(0).minute(0);

const conversationModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    // minWidth: '75%',
    maxHeight: '75%',
    minWidth: '700px',
    background: '#fafafa',
  },
};

const INITIAL_STATE = {
  mode: 0,
  genreEntertainer: [],
  entertainmentTypeEntertainer: [],
  newEntertainerName: '',
  newEntertainerOne: null,
  newEntertainerTwo: null,
  newEntertainerThree: null,
  newEntertainerFour: null,
  newEntertainerFive: null,
  newEntertainerCount: 0,
  selectedEntertainmentOne: null,
  selectedEntertainmentTwo: null,
  selectedEntertainmentThree: null,
  selectedEntertainmentFour: null,
  selectedEntertainmentFive: null,
  newAdditionalEntertainerOne: null,
  newAdditionalEntertainerTwo: null,
  newAdditionalEntertainerThree: null,
  newAdditionalEntertainerFour: null,
  newAdditionalEntertainerFive: null,
  newAdditionalEntertainerCount: 0,
  selectedAdditionalEntertainmentOne: null,
  selectedAdditionalEntertainmentTwo: null,
  selectedAdditionalEntertainmentThree: null,
  selectedAdditionalEntertainmentFour: null,
  selectedAdditionalEntertainmentFive: null,
  active: false,
  title: '',
  categories: '',
  city: '',
  latitude: null,
  longitude: null,
  state: '',
  street: '',
  zip: '',
  tdId: null,
  locationHero: '',
  locationId: '',
  locationThumb: '',
  eventDescriptionLong: '',
  locationName: '',
  website: '',
  menuLink: '',
  zoneId: '',
  error: null,
  loading: true,
  isUploading: false,
  showNewEntertainerModal: false,
  progress: 0,
  progressThumb: 0,
  heroURL: '',
  hero: '',
  bandName: '',
  eventType: [],
  genre: [],
  selectedOption: null,
  contactEmail: null,
  contactName: null,
  contactPhone: null,
  step: 1,
  feedbackSubmitstatus: 0,
  feedback: '',
  selectedTime: '',
  from: undefined,
  to: undefined,
  ticketsRequired: false,
  atDoor: false,
  mustPurchase: false,
  shouldPurchase: false,
  ticketUrl: '',

  crop: {
    unit: '%',
    width: 30,
    aspect: 800 / 259,
  },
  cropThumb: {
    unit: '%',
    width: 30,
    aspect: 146 / 122,
  },
  cropping: false,
  croppingThumb: false,
  thumbPath: '',
  heroPath: '',
  selected: 'Never',
  timesSelected: 1,
  dates: [],
  repeatStartDate: [],
  differentTimes: false,
  SameFromTime: '',
  SameToTime: '',
  typeCap: 'Event',
  promoTypes: [
    'Food & Beverage Event',
    'Happy Hour',
    'Happy Hour Event',
  ],
  pastDates: [],
  trevelTags: [],
};

const query = require('array-query');

export class VendorTimedEventEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getCategoryFields = this.getCategoryFields.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
    this.ticketChange = this.ticketChange.bind(this);
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.setCurrentValues();
    // Get New Location ID to use later
  }

  getCategoryFields(cattag, category) {
    const options = [];
    if (category === 'all') {
      if (cattag.sid === 'eventType' || cattag.sid === 'genre') {
        const tags = cattag.tags;
        tags.forEach((tag) => {
          let tagx = tag.tag;
          if (tag.tag === 'Music') {
            tagx = 'Concert';
          }
          options.push({
            value: tag.tag,
            label: tagx,
          });
        });
        return (
          <div>
            <span className="title">{cattag.parent}</span>
            <Select
              value={this.state[cattag.sid]}
              onChange={(value) => {
                this.handleOnChange(cattag.sid, value);
              }}
              options={options}
              isMulti={true}
            />
          </div>
        );
      }
    } else {
      if (cattag.sid === category) {
        const tags = cattag.tags;
        tags.forEach((tag) => {
          let tagx = tag.tag;
          if (tag.tag === 'Music') {
            tagx = 'Concert';
          }
          options.push({
            value: tag.tag,
            label: tagx,
          });
        });
        return (
          <div>
            <span className="title">{cattag.parent}</span>
            <Select
              value={this.state[cattag.sid]}
              onChange={(value) => {
                this.handleOnChange(cattag.sid, value);
              }}
              options={options}
              isMulti={true}
            />
          </div>
        );
      }
    }
  }

  handleOnChange = (fieldName, value) => {
    if (value[0].value === 'Happy Hour') {
      this.setState({
        title: 'Happy Hour',
      });
    }
    this.setState({ [fieldName]: value });
  };

  handleOnChangeEntertainment = (fieldName, value) => {
    if (this.state.activeFieldIndex === 0) {
      let edit = {};
      if (this.state.newEntertainerOne !== null) {
        edit = this.state.newEntertainerOne;
        edit[fieldName] = value;
      } else {
        edit = {
          name: '',
          description: '',
          [fieldName]: value,
          website: '',
        };
      }
      this.setState({
        newEntertainerOne: edit,
        [fieldName + 'Entertainer']: value,
      });
    }
    if (this.state.activeFieldIndex === 1) {
      let edit = {};
      if (this.state.newEntertainerTwo !== null) {
        edit = this.state.newEntertainerTwo;
        edit[fieldName] = value;
      } else {
        edit = {
          name: '',
          description: '',
          [fieldName]: value,
          website: '',
        };
      }
      this.setState({
        newEntertainerTwo: edit,
        [fieldName + 'Entertainer']: value,
      });
    }
    if (this.state.activeFieldIndex === 2) {
      let edit = {};
      if (this.state.newEntertainerThree !== null) {
        edit = this.state.newEntertainerThree;
        edit[fieldName] = value;
      } else {
        edit = {
          name: '',
          description: '',
          [fieldName]: value,
          website: '',
        };
      }
      this.setState({
        newEntertainerThree: edit,
        [fieldName + 'Entertainer']: value,
      });
    }
    if (this.state.activeFieldIndex === 3) {
      let edit = {};
      if (this.state.newEntertainerFour !== null) {
        edit = this.state.newEntertainerFour;
        edit[fieldName] = value;
      } else {
        edit = {
          name: '',
          description: '',
          [fieldName]: value,
          website: '',
        };
      }
      this.setState({
        newEntertainerFour: edit,
        [fieldName + 'Entertainer']: value,
      });
    }
    if (this.state.activeFieldIndex === 4) {
      let edit = {};
      if (this.state.newEntertainerFive !== null) {
        edit = this.state.newEntertainerFive;
        edit[fieldName] = value;
      } else {
        edit = {
          name: '',
          description: '',
          [fieldName]: value,
          website: '',
        };
      }
      this.setState({
        newEntertainerFive: edit,
        [fieldName + 'Entertainer']: value,
      });
    }
  };

  getRegions(regions) {
    let selectOptions = [];
    selectOptions.push({
      value: 'Current Location',
      label: 'Current Location',
    });
    regions.forEach((region) => {
      if (region.zoneId === this.props.profile.zoneId)
        selectOptions.push({
          value: region.name,
          label: region.name,
        });
    });
    let sorted = selectOptions.sort((a, b) =>
      a.value.localeCompare(b.value),
    );
    return sorted;
  }

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.eventHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.eventHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.eventThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.eventThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  setCurrentValues() {
    // const eventDetails = query('eventId')
    //   .is(this.props.match.params.id)
    //   .on(this.props.vendorTimedEvents);

    // let event = eventDetails[0];

    let event = this.props.location.state.timedEvent;
    console.log('eventxx', event);
    this.setState({
      currentDates: event.scheduledTime.dates.multiDates,
    });

    if (event.scheduledTime.dates.pastDates !== undefined) {
      this.setState({
        pastDates: event.scheduledTime.dates.pastDates,
      });
    }

    event.tagsEventType.forEach((tag) => {
      let status = this.state.promoTypes.includes(tag);
      if (status === true) {
        this.setState({
          typeCap: 'Promo',
        });
      }
    });

    this.setDates(event);

    const tagsEventType = [];
    if (event.tagsEventType) {
      event.tagsEventType.forEach((tag) => {
        tagsEventType.push({
          value: tag,
          label: tag,
        });
      });
    }

    const tagsTrevelTags = [];
    if (event.tagsTrevelTags) {
      event.tagsTrevelTags.forEach((tag) => {
        tagsTrevelTags.push({
          value: tag,
          label: tag,
        });
      });
    }

    const tagsGenre = [];
    if (event.tagsGenre) {
      event.tagsGenre.forEach((tag) => {
        tagsGenre.push({
          value: tag,
          label: tag,
        });
      });
    }

    if (event.eventDescription !== undefined) {
      const blocksFromHtml = htmlToDraft(event.eventDescription);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });
    } else {
      const editorState = EditorState.createEmpty();
      this.setState({
        editorState,
      });
    }
    const timestamp = new Date();

    const heroPath = this.getImage('hero', event);
    const thumbPath = this.getImage('thumb', event);

    if (event.scheduledTime.dates.startTimestamp !== '') {
      let selectedDay = moment(
        event.scheduledTime.dates.startTimestamp,
      );
      this.setState({
        from: selectedDay._d,
      });
    }

    if (event.scheduledTime.dates.endTimestamp !== '') {
      let selectedDay = moment(
        event.scheduledTime.dates.endTimestamp,
      );

      this.setState({
        to: selectedDay._d,
      });
    }

    // Set times
    let startTime = 1420093800;
    if (
      event.scheduledTime.times.startTimeString !== undefined &&
      event.scheduledTime.times.startTimeString !== null
    ) {
      startTime =
        event.scheduledTime.times.startTimeString.seconds * 1000; // Unix ms timestamp
    }
    let endTime = 1420093800;
    if (
      event.scheduledTime.times.endTimeString !== undefined &&
      event.scheduledTime.times.endTimeString !== null
    ) {
      endTime =
        event.scheduledTime.times.endTimeString.seconds * 1000; // Unix ms timestamp
    }

    if (event.scheduledTime.times.eventTimes !== undefined) {
      this.setState({
        differentTimes: true,
      });
      Object.keys(event.scheduledTime.times.eventTimes).forEach(
        (key) => {
          let selectedStartTime = moment(
            event.scheduledTime.times.eventTimes[key].startTime,
            'hh:mm a',
          );
          let startTimeReady = selectedStartTime.format('HH:mm');
          let selectedEndTime = moment(
            event.scheduledTime.times.eventTimes[key].endTime,
            'hh:mm a',
          );
          let endTimeReady = selectedEndTime.format('HH:mm');
          this.setState({
            [key + 'FromTime']: startTimeReady,
            [key + 'ToTime']: endTimeReady,
          });
        },
      );
    }

    let selectedStartTime = moment(startTime);
    let selectedEndTime = moment(endTime);
    let startTimeReady = selectedStartTime.format('HH:mm');
    let endTimeReady = selectedEndTime.format('HH:mm');

    this.setState({
      selectedStartTime: selectedStartTime,
      selectedEndTime: selectedEndTime,
      SameFromTime: startTimeReady,
      SameToTime: endTimeReady,
      differentTime: 'yes',
    });

    if (event.scheduledTime.dates.repeatType === 'Monthly') {
      this.setState({
        selectedOption: event.scheduledTime.dates.selectedOption,
      });
    }

    let ticketBox = 'value 1';
    let atDoor = false;
    let mustPurchase = false;
    let shouldPurchase = false;
    let ticketUrl = '';
    let ticketsRequired = false;

    if (event.ticketInfo !== undefined) {
      if (event.ticketInfo.ticketsRequired === true) {
        ticketsRequired = true;
        ticketBox = 'value 2';
      }

      atDoor = event.ticketInfo.atDoor;
      mustPurchase = event.ticketInfo.mustPurchase;
      shouldPurchase = event.ticketInfo.shouldPurchase;
      ticketUrl = event.ticketInfo.ticketUrl;
    }

    let selectedAdditionalEntertainmentOne = null;
    let selectedAdditionalEntertainmentTwo = null;
    let selectedAdditionalEntertainmentThree = null;
    let selectedAdditionalEntertainmentFour = null;
    let selectedAdditionalEntertainmentFive = null;
    let selectedEntertainmentOne = null;
    let selectedEntertainmentTwo = null;
    let selectedEntertainmentThree = null;
    let selectedEntertainmentFour = null;
    let selectedEntertainmentFive = null;

    if (event.entertainment !== undefined) {
      event.entertainment.headliners.forEach((entertainment, i) => {
        let pair = {};
        pair.value =
          entertainment.entertainmentTitle +
          '/' +
          entertainment.entertainmentId;
        pair.label = entertainment.entertainmentTitle;
        if (i === 0) {
          selectedEntertainmentOne = pair;
        }
        if (i === 1) {
          selectedEntertainmentTwo = pair;
        }
        if (i === 2) {
          selectedEntertainmentThree = pair;
        }
        if (i === 3) {
          selectedEntertainmentFour = pair;
        }
        if (i === 4) {
          selectedEntertainmentFive = pair;
        }
      });
      event.entertainment.additional.forEach((entertainment, i) => {
        let pair = {};
        pair.value =
          entertainment.entertainmentTitle +
          '/' +
          entertainment.entertainmentId;
        pair.label = entertainment.entertainmentTitle;
        if (i === 0) {
          selectedAdditionalEntertainmentOne = pair;
        }
        if (i === 1) {
          selectedAdditionalEntertainmentTwo = pair;
        }
        if (i === 2) {
          selectedAdditionalEntertainmentThree = pair;
        }
        if (i === 3) {
          selectedAdditionalEntertainmentFour = pair;
        }
        if (i === 4) {
          selectedAdditionalEntertainmentFive = pair;
        }
      });
    }

    let currentLocation = {
      value: event.address.locationName + '/' + event.address.tdId,
      label: event.address.locationName,
    };

    let selected = 'Never';
    let timesSelected = 1;
    if (event.scheduledTime.dates.repeat === true) {
      selected = event.scheduledTime.dates.repeatType;
      timesSelected = event.scheduledTime.dates.timesSelected;
    }

    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 'tu' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];
    if (event.scheduledTime.dates.repeatDays !== undefined) {
      let days = [0, 1, 2, 3, 4, 5, 6];
      this.setState({
        activeDay: days,
        repeatStartDate: [event.scheduledTime.dates.startDate],
      });

      event.scheduledTime.dates.repeatDays.forEach((day, i) => {
        const eventDetailsx = query('short').is(day).on(dayArray);
        let eventx = eventDetailsx[0];
        this.setState({
          [eventx.full]: true,
        });
      });
    }

    let selectedRegion = {
      value: '',
      label: '',
    };

    if (event.address.region !== undefined) {
      selectedRegion = {
        value: event.address.region,
        label: event.address.region,
      };
    }

    this.setState({
      timesSelected,
      selected,
      selectedAdditionalEntertainmentOne,
      selectedAdditionalEntertainmentTwo,
      selectedAdditionalEntertainmentThree,
      selectedAdditionalEntertainmentFour,
      selectedAdditionalEntertainmentFive,
      selectedEntertainmentOne,
      selectedEntertainmentTwo,
      selectedEntertainmentThree,
      selectedEntertainmentFour,
      selectedEntertainmentFive,
      // dateType: dateTypeTags,
      // bandName: event.bandName,
      active: event.active,
      eventDescription: event.eventDescription,
      title: event.eventTitle,
      eventId: event.eventId,
      eventThumb: event.eventThumb,
      eventHero: event.eventHero,
      website: event.website,
      street: event.address.street,
      zip: event.address.zip,
      state: event.address.state,
      latitude: event.address.latitude,
      selectedRegion,
      longitude: event.address.longitude,
      city: event.address.city,
      locationName: event.address.locationName,
      eventType: tagsEventType,
      trevelTags: tagsTrevelTags,
      genre: tagsGenre,
      settings: event.settings,
      creationDetails: event.creationDetails,
      scheduledTime: event.scheduledTime,
      zoneId: event.zoneId,
      account: event.account,
      tdId: event.address.tdId,
      ticketBox,
      ticketsRequired,
      atDoor,
      mustPurchase,
      shouldPurchase,
      ticketUrl,
      thumbPath,
      heroPath,
      levelOne: currentLocation,
    });
  }

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onEditorStateChangeLong: Function = (editorStateLong) => {
    this.setState({
      editorStateLong,
    });
  };

  setMultiSchedule(date) {
    let bundle = {
      timestamp: null,
      date: null,
    };

    // Check for Date
    if (date !== '' && date !== undefined && date !== null) {
      const timestamp = moment(date).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.date = dateString;
      bundle.timestamp = timestampReady;
    }

    return bundle;
  }

  saveLocation(editorState, values) {
    const db = firebase.firestore();
    // const articleToUpdate = db.collection('locations').doc(id);
    const timestamp = new Date();

    const tagsEventType = [];
    if (this.state.eventType !== undefined) {
      this.state.eventType.forEach((doc) => {
        tagsEventType.push(doc.value);
      });
    }
    const tagsGenre = [];
    if (this.state.genre !== undefined) {
      this.state.genre.forEach((doc) => {
        tagsGenre.push(doc.value);
      });
    }

    let tagsTrevelTags = [];
    this.state.trevelTags.forEach((doc) => {
      tagsTrevelTags.push(doc.value);
    });

    // Convert Body from Draft.js to HTML
    const rawContentState = convertToRaw(
      editorState.getCurrentContent(),
    );

    const markup = draftToHtml(rawContentState);

    // let scheduledTime = {};
    // scheduledTime.times = this.setScheduleTimes(
    //   this.state.selectedStartTime,
    //   this.state.selectedEndTime,
    //   this.state.differentTime,
    // );

    // let to = null;
    // if (this.state.to !== undefined) {
    //   to = this.state.to;
    // } else {
    //   to = this.state.from;
    // }

    // scheduledTime.dates = this.setSchedule(this.state.from, to);

    // NEW STUFF

    // Weekly Repeat
    let arrayDates = [
      {
        dayNum: 0,
        day: 'Sunday',
        status: this.state.Sunday,
        short: 'su',
      },
      {
        dayNum: 1,
        day: 'Monday',
        status: this.state.Monday,
        short: 'm',
      },
      {
        dayNum: 2,
        day: 'Tuesday',
        status: this.state.Tuesday,
        short: 'tu',
      },
      {
        dayNum: 3,
        day: 'Wednesday',
        status: this.state.Wednesday,
        short: 'w',
      },
      {
        dayNum: 4,
        day: 'Thursday',
        status: this.state.Thursday,
        short: 'th',
      },
      {
        dayNum: 5,
        day: 'Friday',
        status: this.state.Friday,
        short: 'f',
      },
      {
        dayNum: 6,
        day: 'Saturday',
        status: this.state.Saturday,
        short: 'sa',
      },
    ];
    const days = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 7,
    };
    let repeatDayArray = [];
    let repeatDays = [];
    arrayDates.forEach((date) => {
      if (date.status === true) {
        repeatDays.push(date);
        repeatDayArray.push(date.short);
      }
    });

    let allDates = [];
    let addAmount = 7 * this.state.timesSelected;
    // Get All Dates based on selected Weekly Days
    let counter = {
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
    };

    let eventTimes = {};
    if (this.state.selected === 'Weekly') {
      repeatDays.forEach((day, index) => {
        var firstDate = moment().startOf('month').day(day.day);
        if (firstDate.date() > 7) firstDate.add(7, 'd');
        // while (allDates.length !== 20) {
        while (counter[day.day] !== 20) {
          let after = moment(firstDate).isAfter(
            this.state.repeatStartDate[0],
          );
          counter[day.day] = counter[day.day] + 1;
          if (
            moment(firstDate).format('DD/MM/YYYY') ===
            moment(this.state.repeatStartDate[0]).format('DD/MM/YYYY')
          ) {
            allDates.push(moment(firstDate));
          }
          if (after === true) {
            allDates.push(moment(firstDate));
          }
          if (this.state.differentTimes === true) {
            let startT = moment(
              this.state[day.day + 'FromTime'],
              'hh:mm A',
            );
            let endT = moment(
              this.state[day.day + 'ToTime'],
              'hh:mm A',
            );
            let startTimeReady = startT.format('hh:mm A');
            let endTimeReady = endT.format('hh:mm A');

            eventTimes[day.day] = {
              startTime: startTimeReady,
              endTime: endTimeReady,
            };
          }
          firstDate.add(addAmount, 'd');
        }
      });
    }
    // Monthly Option 1 'On the 22nd of each month.'
    if (
      this.state.selected === 'Monthly' &&
      this.state.selectedOption === 'option1'
    ) {
      while (allDates.length !== 6) {
        let futureMonth = moment(this.state.repeatStartDate[0]).add(
          allDates.length * this.state.timesSelected,
          'M',
        );
        allDates.push(futureMonth);
      }
    }
    // Monthly Option 2 'On the third Friday of each month.'
    if (
      this.state.selected === 'Monthly' &&
      this.state.selectedOption === 'option2'
    ) {
      var checkRepeatStart = moment(this.state.repeatStartDate[0]);
      var selectedRepeatStartDay = checkRepeatStart.format('D');
      var selectedRepeatStartDayWord =
        checkRepeatStart.format('dddd');
      const nthDayOfMonth = (monthMoment, day, weekNumber) => {
        let m = monthMoment
          .clone()
          .startOf('month') // go to the beginning of the month
          .day(day);
        if (m.month() !== monthMoment.month()) m.add(7, 'd');
        return m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DD');
      };
      let dates = [];
      let selectedDateX = moment(
        this.state.repeatStartDate[0],
      ).format('YYYY-MM-DD');
      dates.push(selectedDateX);
      let weekx = '';
      if (this.state.repeatStartDate[0] !== undefined) {
        let timex = moment(this.state.repeatStartDate[0]).format(
          'YYYY-MM-DD',
        );
        let sn = moment(timex);
        weekx = Math.ceil(sn.date() / 7);
      }
      let count = 1;
      while (count !== 6) {
        let datep = nthDayOfMonth(
          moment(this.state.repeatStartDate[0]).add(
            count * this.state.timesSelected,
            'M',
          ),
          days[selectedRepeatStartDayWord],
          weekx,
        );
        dates.push(datep);
        count++;
      }
      allDates = dates;
    }
    // Monthly Option 4 'On the first Thursday of each month.'
    if (
      this.state.selected === 'Monthly' &&
      this.state.selectedOption === 'option4'
    ) {
      var checkRepeatStart = moment(this.state.repeatStartDate[0]);
      var selectedRepeatStartDayWord =
        checkRepeatStart.format('dddd');

      function getFirstDate(date) {
        let result = moment(date).startOf('month');
        let dayNum = days[selectedRepeatStartDayWord];
        while (result.day() !== dayNum) {
          result.add(1, 'day');
        }
        return result;
      }

      let testdates = [];
      let countIt = 1;
      let currentDate = this.state.repeatStartDate[0];
      while (countIt !== 6) {
        let datep = getFirstDate(currentDate).format('YYYY-MM-DD ');
        currentDate = moment(currentDate).add(
          this.state.timesSelected,
          'months',
        );
        testdates.push(datep);
        countIt++;
      }
      allDates = testdates;
    }

    // Monthly Option 3 'On the last Thursday of each month.'
    if (
      this.state.selected === 'Monthly' &&
      this.state.selectedOption === 'option3'
    ) {
      var checkRepeatStart = moment(this.state.repeatStartDate[0]);
      var selectedRepeatStartDayWord =
        checkRepeatStart.format('dddd');

      function getLastDate(date) {
        let result = moment(date).endOf('month');
        let dayNum = days[selectedRepeatStartDayWord];
        while (result.day() !== dayNum) {
          result.subtract(1, 'day');
        }
        return result;
      }

      let testdates = [];
      let countIt = 1;
      let currentDate = this.state.repeatStartDate[0];
      while (countIt !== 6) {
        let datep = getLastDate(currentDate).format('YYYY-MM-DD ');
        currentDate = moment(currentDate).add(
          this.state.timesSelected,
          'months',
        );
        testdates.push(datep);
        countIt++;
      }
      allDates = testdates;
    }

    // Sort Dates
    let sortedArray = allDates.sort(
      (a, b) => moment(a).valueOf() - moment(b).valueOf(),
    );

    // Set to and from dates
    let from = null;
    let to = null;

    if (
      this.state.selected === 'Never' &&
      this.state.dates.length !== 0
    ) {
      let sortedDates = this.state.dates.sort(
        (a, b) => moment(a).valueOf() - moment(b).valueOf(),
      );
      from = sortedDates[0];
      to = sortedDates[sortedDates.length - 1];
    } else {
      from = sortedArray[0];
      to = sortedArray[sortedArray.length - 1];
    }

    // Set Schedule
    let scheduledTime = {};
    if (
      this.state.selected === 'Weekly' &&
      this.state.differentTimes === true
    ) {
      scheduledTime.times = {};
      scheduledTime.times.eventTimes = eventTimes;
    } else {
      // scheduledTime.times = this.setScheduleTimes(
      //   this.state.selectedStartTime,
      //   this.state.selectedEndTime,
      //   false,
      // );
      let startT = moment(this.state.SameFromTime, 'hh:mm A');
      let endT = moment(this.state.SameToTime, 'hh:mm A');
      let startTimeReady = startT.format('hh:mm A');
      let endTimeReady = endT.format('hh:mm A');
      scheduledTime.times = {
        startTime: startTimeReady,
        endTime: endTimeReady,
      };
    }

    scheduledTime.dates = this.setSchedule(from, to);

    let datesReady = [];
    this.state.dates.forEach((date) => {
      datesReady.push(this.setMultiSchedule(date));
    });

    // Set Multiple Dates
    if (this.state.selected === 'Never') {
      scheduledTime.dates.multiDates = datesReady;
      scheduledTime.dates.repeat = false;
    } else if (
      this.state.selected === 'Weekly' ||
      this.state.selected === 'Monthly'
    ) {
      let weeklyDates = [];
      sortedArray.forEach((date) => {
        weeklyDates.push(this.setMultiSchedule(date));
      });
      scheduledTime.dates.multiDates = weeklyDates;
      scheduledTime.dates.repeat = true;
      scheduledTime.dates.repeatType = this.state.selected;
      scheduledTime.dates.timesSelected = this.state.timesSelected;
      scheduledTime.dates.repeatDays = repeatDayArray;
    }

    if (this.state.selected === 'Monthly') {
      scheduledTime.dates.selectedOption = this.state.selectedOption;
    }

    let pastDates = this.state.pastDates;

    if (this.state.currentDates !== undefined) {
      this.state.currentDates.forEach((date) => {
        let status = pastDates.includes(date);
        if (status === false) {
          pastDates.push(date);
        }
      });
    }

    let ticketInfo = {
      ticketsRequired: this.state.ticketsRequired,
      atDoor: values.atDoor,
      mustPurchase: values.mustPurchase,
      shouldPurchase: values.shouldPurchase,
      ticketUrl: values.ticketUrl,
    };
    if (values.tickets === 'value 2') {
      ticketInfo.ticketsRequired = true;
    }

    // Add Entertainment & Create New Ones
    let entertainment = {};
    let entertainers = [];
    let entertainersAdditional = [];
    let entertainmentIds = [];
    if (this.state.selectedEntertainmentOne !== null) {
      let entertainer = {};
      let pair = this.state.selectedEntertainmentOne;
      if (pair.value !== 'tempid') {
        const text = pair.value.split('/', 5);
        entertainer = {
          entertainmentId: text[1],
          entertainmentTitle: text[0],
        };
        entertainmentIds.push(text[1]);
      } else {
        const db = firebase.firestore();
        const newEntertainmentRef = db
          .collection('entertainment')
          .doc();
        const dbref = newEntertainmentRef.id;
        this.createNewEntertainer(
          this.state.newEntertainerOne,
          dbref,
        );
        entertainer = {
          entertainmentId: dbref,
          entertainmentTitle: pair.label,
        };
        entertainmentIds.push(dbref);
      }
      entertainers.push(entertainer);
    }
    if (this.state.selectedEntertainmentTwo !== null) {
      let entertainer = {};
      let pair = this.state.selectedEntertainmentTwo;
      if (pair.value !== 'tempid') {
        const text = pair.value.split('/', 5);
        entertainer = {
          entertainmentId: text[1],
          entertainmentTitle: text[0],
        };
        entertainmentIds.push(text[1]);
      } else {
        const db = firebase.firestore();
        const newEntertainmentRef = db
          .collection('entertainment')
          .doc();
        const dbref = newEntertainmentRef.id;
        this.createNewEntertainer(
          this.state.newEntertainerTwo,
          dbref,
        );
        entertainer = {
          entertainmentId: dbref,
          entertainmentTitle: pair.label,
        };
        entertainmentIds.push(dbref);
      }
      entertainers.push(entertainer);
    }
    if (this.state.selectedEntertainmentThree !== null) {
      let entertainer = {};
      let pair = this.state.selectedEntertainmentThree;
      if (pair.value !== 'tempid') {
        const text = pair.value.split('/', 5);
        entertainer = {
          entertainmentId: text[1],
          entertainmentTitle: text[0],
        };
        entertainmentIds.push(text[1]);
      } else {
        const db = firebase.firestore();
        const newEntertainmentRef = db
          .collection('entertainment')
          .doc();
        const dbref = newEntertainmentRef.id;
        this.createNewEntertainer(
          this.state.newEntertainerThree,
          dbref,
        );
        entertainer = {
          entertainmentId: dbref,
          entertainmentTitle: pair.label,
        };
        entertainmentIds.push(dbref);
      }
      entertainers.push(entertainer);
    }
    if (this.state.selectedEntertainmentFour !== null) {
      let entertainer = {};
      let pair = this.state.selectedEntertainmentFour;
      if (pair.value !== 'tempid') {
        const text = pair.value.split('/', 5);
        entertainer = {
          entertainmentId: text[1],
          entertainmentTitle: text[0],
        };
        entertainmentIds.push(text[1]);
      } else {
        const db = firebase.firestore();
        const newEntertainmentRef = db
          .collection('entertainment')
          .doc();
        const dbref = newEntertainmentRef.id;
        this.createNewEntertainer(
          this.state.newEntertainerFour,
          dbref,
        );
        entertainer = {
          entertainmentId: dbref,
          entertainmentTitle: pair.label,
        };
        entertainmentIds.push(dbref);
      }
      entertainers.push(entertainer);
    }
    if (this.state.selectedEntertainmentFive !== null) {
      let entertainer = {};
      let pair = this.state.selectedEntertainmentFive;
      if (pair.value !== 'tempid') {
        const text = pair.value.split('/', 5);
        entertainer = {
          entertainmentId: text[1],
          entertainmentTitle: text[0],
        };
        entertainmentIds.push(text[1]);
      } else {
        const db = firebase.firestore();
        const newEntertainmentRef = db
          .collection('entertainment')
          .doc();
        const dbref = newEntertainmentRef.id;
        this.createNewEntertainer(
          this.state.newEntertainerFive,
          dbref,
        );
        entertainer = {
          entertainmentId: dbref,
          entertainmentTitle: pair.label,
        };
        entertainmentIds.push(dbref);
      }
      entertainers.push(entertainer);
    }
    if (this.state.selectedAdditionalEntertainmentOne !== null) {
      let entertainer = {};
      let pair = this.state.selectedAdditionalEntertainmentOne;
      if (pair.value !== 'tempid') {
        const text = pair.value.split('/', 5);
        entertainer = {
          entertainmentId: text[1],
          entertainmentTitle: text[0],
        };
        entertainmentIds.push(text[1]);
      } else {
        const db = firebase.firestore();
        const newEntertainmentRef = db
          .collection('entertainment')
          .doc();
        const dbref = newEntertainmentRef.id;
        this.createNewEntertainer(
          this.state.newAdditionalEntertainerOne,
          dbref,
        );
        entertainer = {
          entertainmentId: dbref,
          entertainmentTitle: pair.label,
        };
        entertainmentIds.push(dbref);
      }
      entertainersAdditional.push(entertainer);
    }
    if (this.state.selectedAdditionalEntertainmentTwo !== null) {
      let entertainer = {};
      let pair = this.state.selectedAdditionalEntertainmentTwo;
      if (pair.value !== 'tempid') {
        const text = pair.value.split('/', 5);
        entertainer = {
          entertainmentId: text[1],
          entertainmentTitle: text[0],
        };
        entertainmentIds.push(text[1]);
      } else {
        const db = firebase.firestore();
        const newEntertainmentRef = db
          .collection('entertainment')
          .doc();
        const dbref = newEntertainmentRef.id;
        this.createNewEntertainer(
          this.state.newAdditionalEntertainerTwo,
          dbref,
        );
        entertainer = {
          entertainmentId: dbref,
          entertainmentTitle: pair.label,
        };
        entertainmentIds.push(dbref);
      }
      entertainersAdditional.push(entertainer);
    }
    if (this.state.selectedAdditionalEntertainmentThree !== null) {
      let entertainer = {};
      let pair = this.state.selectedAdditionalEntertainmentThree;
      if (pair.value !== 'tempid') {
        const text = pair.value.split('/', 5);
        entertainer = {
          entertainmentId: text[1],
          entertainmentTitle: text[0],
        };
        entertainmentIds.push(text[1]);
      } else {
        const db = firebase.firestore();
        const newEntertainmentRef = db
          .collection('entertainment')
          .doc();
        const dbref = newEntertainmentRef.id;
        this.createNewEntertainer(
          this.state.newAdditionalEntertainerThree,
          dbref,
        );
        entertainer = {
          entertainmentId: dbref,
          entertainmentTitle: pair.label,
        };
        entertainmentIds.push(dbref);
      }
      entertainersAdditional.push(entertainer);
    }
    if (this.state.selectedAdditionalEntertainmentFour !== null) {
      let entertainer = {};
      let pair = this.state.selectedAdditionalEntertainmentFour;
      if (pair.value !== 'tempid') {
        const text = pair.value.split('/', 5);
        entertainer = {
          entertainmentId: text[1],
          entertainmentTitle: text[0],
        };
        entertainmentIds.push(text[1]);
      } else {
        const db = firebase.firestore();
        const newEntertainmentRef = db
          .collection('entertainment')
          .doc();
        const dbref = newEntertainmentRef.id;
        this.createNewEntertainer(
          this.state.newAdditionalEntertainerFour,
          dbref,
        );
        entertainer = {
          entertainmentId: dbref,
          entertainmentTitle: pair.label,
        };
        entertainmentIds.push(dbref);
      }
      entertainersAdditional.push(entertainer);
    }
    if (this.state.selectedAdditionalEntertainmentFive !== null) {
      let entertainer = {};
      let pair = this.state.selectedAdditionalEntertainmentFive;
      if (pair.value !== 'tempid') {
        const text = pair.value.split('/', 5);
        entertainer = {
          entertainmentId: text[1],
          entertainmentTitle: text[0],
        };
        entertainmentIds.push(text[1]);
      } else {
        const db = firebase.firestore();
        const newEntertainmentRef = db
          .collection('entertainment')
          .doc();
        const dbref = newEntertainmentRef.id;
        this.createNewEntertainer(
          this.state.newAdditionalEntertainerFive,
          dbref,
        );
        entertainer = {
          entertainmentId: dbref,
          entertainmentTitle: pair.label,
        };
        entertainmentIds.push(dbref);
      }
      entertainersAdditional.push(entertainer);
    }
    entertainment.headliners = entertainers;
    entertainment.additional = entertainersAdditional;
    entertainment.entertainmentIds = entertainmentIds;

    let selectedRegion = null;
    if (this.state.selectedRegion !== undefined) {
      selectedRegion = this.state.selectedRegion.value;
    }

    // if no lat/long use Regions
    let longitude = values.longitude;
    let latitude = values.latitude;
    if (latitude === null || latitude === undefined) {
      this.props.regions.forEach((region) => {
        if (region.name === selectedRegion) {
          latitude = region.lat;
          longitude = region.long;
        }
      });
    }

    // const docToUpdate = {
    //   active: this.state.active,
    //   eventDescription: markup,
    //   lastUpdated: timestamp,
    //   eventTitle: values.title,
    //   entertainment,
    //   eventThumb: this.state.thumbPath,
    //   eventHero: this.state.heroPath,
    //   website: values.website,
    //   address: {
    //     city: values.city,
    //     street: values.street,
    //     zip: values.zip,
    //     longitude: values.longitude,
    //     latitude: values.latitude,
    //     state: values.state,
    //     locationName: values.locationName,
    //     region: selectedRegion,
    //     tdId: this.state.tdId,
    //   },
    //   ticketInfo,
    //   // tagsDateType: tagsDateType,
    //   settings: this.state.settings,
    //   creationDetails: {
    //     creatorId: this.props.profile.userId,
    //     createdDate: timestamp,
    //   },
    //   scheduledTime,
    //   zoneId: this.props.profile.zoneId,
    //   account: {
    //     vendorId: this.props.vendor.vendorId,
    //   },
    //   pastDates,
    //   tagsEventType,
    //   tagsGenre,
    // };
    // console.log('docToUpdate', docToUpdate);

    const docToUpdate = db
      .collection('vendor_events')
      .doc(this.state.eventId);
    const updateDoc = docToUpdate
      .update({
        active: this.state.active,
        eventDescription: markup,
        lastUpdated: timestamp,
        eventTitle: values.title,
        entertainment,
        eventThumb: this.state.thumbPath,
        eventHero: this.state.heroPath,
        website: values.website,
        address: {
          city: values.city,
          street: values.street,
          zip: values.zip,
          longitude: values.longitude,
          latitude: values.latitude,
          state: values.state,
          locationName: values.locationName,
          region: selectedRegion,
          tdId: this.state.tdId,
        },
        ticketInfo,
        // tagsDateType: tagsDateType,
        settings: this.state.settings,
        creationDetails: {
          creatorId: this.props.profile.userId,
          createdDate: timestamp,
        },
        scheduledTime,
        zoneId: this.props.profile.zoneId,
        account: {
          vendorId: this.props.vendor.vendorId,
        },
        pastDates,
        tagsEventType,
        tagsGenre,
        tagsTrevelTags,
      })
      .then(() => {
        // this.props.getVendorEntertainment(this.props.vendor.vendorId);
        // this.props.getVendorTimedEvents(this.props.vendor.vendorId);
        // this.props.getEntertainment(this.props.profile.zoneId);
        // this.props.getTimedEvents(this.props.profile.zoneId);
        this.props.history.push('/vendor/timedevents');
      });
  }

  createNewEntertainer = (entertainer, dbref) => {
    const timestamp = new Date();
    const tagsEntertainmentType = [];

    entertainer.entertainmentType.forEach((doc) => {
      tagsEntertainmentType.push(doc.value);
    });
    const tagsGenre = [];

    entertainer.genre.forEach((doc) => {
      tagsGenre.push(doc.value);
    });

    let preSlug = entertainer.name;
    var slugSpaces = preSlug.replace(/ +/g, '-');
    var slugNoA = slugSpaces.replace("'", '');
    var slug = slugNoA.toLowerCase();

    let finalObject = {
      active: true,
      entertainmentDescription: entertainer.description,
      lastUpdated: timestamp,
      entertainmentTitle: entertainer.name,
      slug,
      entertainmentId: dbref,
      entertainmentThumb: '',
      entertainmentHero: '',
      website: entertainer.website,
      social: {
        twitter: '',
        twitch: '',
        tiktok: '',
        facebook: '',
        patreon: '',
        bandcamp: '',
        instagram: '',
        youtube: '',
      },
      settings: {
        public: true,
        published: true,
        tdStatus: 0,
        customImages: false,
        claimed: false,
        local: false,
        tempPage: true,
      },
      creationDetails: {
        creatorId: this.props.profile.userId,
        createdDate: timestamp,
        vendorId: this.props.vendor.vendorId,
      },
      zoneId: this.props.profile.zoneId,
      account: {
        vendorId: this.props.vendor.vendorId,
      },
      tagsEntertainmentType,
      tagsGenre,
    };
    console.log('entertainer', finalObject);
    const db = firebase.firestore();
    const setDoc = db
      .collection('entertainment')
      .doc(dbref)
      .set(finalObject)
      .then((ref) => {
        console.log('added:', dbref);
      });
  };

  selectEntertainerChange = (values, index, term) => {
    console.log('entertainervalues', values);
    const text = values.value.split('/', 5);

    this.setState({
      addMode: 1,
    });

    if (index === 0) {
      this.setState({
        selectedEntertainmentOne: values,
        entertainmentOneTitle: text[0],
        entertainmentOneId: text[1],
      });
    } else if (index === 1) {
      this.setState({
        selectedEntertainmentTwo: values,
        entertainmentTwoTitle: text[0],
        entertainmentTwoId: text[1],
      });
    } else if (index === 2) {
      this.setState({
        selectedEntertainmentThree: values,
        entertainmentThreeTitle: text[0],
        entertainmentThreeId: text[1],
      });
    } else if (index === 3) {
      this.setState({
        selectedEntertainmentFour: values,
        entertainmentFourTitle: text[0],
        entertainmentFourId: text[1],
      });
    } else if (index === 4) {
      this.setState({
        selectedEntertainmentFive: values,
        entertainmentFiveTitle: text[0],
        entertainmentFiveId: text[1],
      });
    }
  };

  selectAdditionalEntertainerChange = (values, index, term) => {
    const text = values.value.split('/', 5);

    this.setState({
      addMode: 2,
    });

    if (index === 0) {
      this.setState({
        selectedAdditionalEntertainmentOne: values,
        entertainmentOneTitleAdditional: text[0],
        entertainmentOneIdAdditional: text[1],
      });
    } else if (index === 1) {
      this.setState({
        selectedAdditionalEntertainmentTwo: values,
        entertainmentTwoTitleAdditional: text[0],
        entertainmentTwoIdAdditional: text[1],
      });
    } else if (index === 2) {
      this.setState({
        selectedAdditionalEntertainmentThree: values,
        entertainmentThreeTitleAdditional: text[0],
        entertainmentThreeIdAdditional: text[1],
      });
    } else if (index === 3) {
      this.setState({
        selectedAdditionalEntertainmentFour: values,
        entertainmentFourTitleAdditional: text[0],
        entertainmentFourIdAdditional: text[1],
      });
    } else if (index === 4) {
      this.setState({
        selectedAdditionalEntertainmentFive: values,
        entertainmentFiveTitleAdditional: text[0],
        entertainmentFiveIdAdditional: text[1],
      });
    }
  };

  // Banner Crop
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      cropping: true,
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'heroImage.jpeg',
      );
      this.setState({ heroPath: croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getBannerCropper(bannerCropping) {
    const { crop, croppedImageUrl, src } = this.state;
    if (bannerCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="Bar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropper();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropper();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </div>
        </div>
      );
    }
  }
  // End Banner Crop
  // Thumb Crop
  onSelectFileThumb = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      croppingThumb: true,
    });
  };

  onImageLoadedThumb = (image) => {
    this.imageRef = image;
  };

  onCropCompleteThumb = (crop) => {
    this.makeClientCropThumb(crop);
  };

  onCropChangeThumb = (cropThumb, percentCrop) => {
    this.setState({ cropThumb });
  };

  async makeClientCropThumb(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImgThumb(
        this.imageRef,
        crop,
        'thumbnail.jpeg',
      );
      this.setState({
        thumbPath: croppedImageUrl,
      });
    }
  }

  getCroppedImgThumb(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getThumbnailCropper(thumbnailCropping) {
    const { cropThumb, croppedImageUrl, src } = this.state;
    if (thumbnailCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropperThumb();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropperThumb();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={cropThumb}
                onImageLoaded={this.onImageLoadedThumb}
                onComplete={this.onCropCompleteThumb}
                onChange={this.onCropChangeThumb}
              />
            )}
          </div>
        </div>
      );
    }
  }
  //End Thumb Crop
  cancelCropper() {
    this.setState({
      cropping: false,
    });
  }

  saveCropper() {
    this.uploadImage(this.state.blob, 'hero');
    this.setState({
      cropping: false,
    });
  }

  cancelCropperThumb() {
    this.setState({
      croppingThumb: false,
    });
  }

  saveCropperThumb() {
    this.uploadImage(this.state.blob, 'thumb');
    this.setState({
      croppingThumb: false,
    });
  }

  selectChangeLocation = (values) => {
    const text = values.value.split('/', 5);

    let locationTitle = text[0];
    let locationId = text[1];

    const locationDetails = query('locationId')
      .is(locationId)
      .on(this.props.locations);
    let location = locationDetails[0];

    let selectedRegion = {
      value: location.address.region,
      label: location.address.region,
    };

    let currentLocation = {
      value:
        location.locationTitle + '/' + location.address.locationId,
      label: location.locationTitle,
    };

    this.setState({
      street: location.address.street,
      zip: location.address.zip,
      state: location.address.state,
      city: location.address.city,
      street: location.address.street,
      selectedRegion,
      longitude: location.address.longitude,
      latitude: location.address.latitude,
      locationName: location.locationTitle,
      locationTitle: text[0],
      locationId: text[1],
      tdId: location.locationId,
      title: this.state.title,
      levelOne: currentLocation,
    });
  };

  uploadImage = (image, type) => {
    const uploadUrl =
      'vendor_images/' +
      this.props.vendor.vendorId +
      '/' +
      this.state.eventId +
      '/' +
      image.name;
    // const uploadUrl =
    //   'event_images/' + this.state.dbref + '/' + image.name;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadUrl);
    imageRef.put(image).then((snapshot) => {
      // const path = snapshot.ref.location.path_;
      if (type === 'hero') {
        let heroImagePath =
          'vendor_images%2F' +
          this.props.vendor.vendorId +
          '%2F' +
          this.state.eventId +
          '%2FheroImage.jpeg';
        let heroPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          heroImagePath +
          '?alt=media';
        this.setState({
          heroPath,
          customHero: true,
        });
      } else {
        let thumbnailImagePath =
          'vendor_images%2F' +
          this.props.vendor.vendorId +
          '%2F' +
          this.state.eventId +
          '%2Fthumbnail.jpeg';
        let thumbPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          thumbnailImagePath +
          '?alt=media';
        this.setState({
          thumbPath,
          customThumb: true,
        });
      }
    });
  };

  // Crop Functions
  getHeroImage() {
    if (this.state.heroPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '100%' }}
          src={this.state.heroPath}
        />
      );
    }
  }

  titleChange = (event) => {
    this.setState({
      title: event.target.value,
    });
  };

  nEtitleChange = (event) => {
    this.setState({
      newEntertainerName: event.target.value,
    });
  };

  nEWebsiteChange = (event) => {
    this.setState({
      newEntertainerWebsite: event.target.value,
    });
  };

  getThumbnail() {
    if (this.state.thumbPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '100%' }}
          src={this.state.thumbPath}
        />
      );
    }
  }

  activeOnChange() {
    if (this.state.active === true) {
      this.setState({ active: false });
    } else {
      this.setState({ active: true });
    }
  }

  getCategoryFieldsTrevelTags(cattag) {
    const options = [];
    if (cattag.sid === 'trevelTags') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div style={{ paddingBottom: '20px' }}>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            value={this.state[cattag.sid]}
            onChange={(value) => {
              this.handleTrevelTagOnChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  handleTrevelTagOnChange = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  getStep(step, selected, timesSelected, dates, selectedRegion) {
    let selectOptions = this.getSelectOptions();
    let selectOptionsLocations = this.getSelectOptionsLocations();
    const { editorState, editorStateLong } = this.state;
    let regions = this.getRegions(this.props.regions);
    const radioOptions = [
      { key: 'Tickets Are Not Required', value: 'value 1' },
      { key: 'Tickets Are Required', value: 'value 2' },
    ];

    let showGenre = false;
    this.state.eventType.forEach((type) => {
      if (type.value === 'Music') {
        showGenre = true;
      }
    });
    let showTicketInfo = true;

    let isHappyHour = false;
    this.state.eventType.forEach((type) => {
      if (type.value === 'Happy Hour') {
        isHappyHour = true;
      }
    });

    if (step === 0) {
      return (
        <div id="vendorAdmin">
          <Container>
            <Row style={{ padding: '5px 0px 0px 0px' }}>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="no-padding"
              >
                <input
                  type="checkbox"
                  // name="active"
                  checked={this.state.active}
                  onChange={this.activeOnChange.bind(this)}
                  style={{
                    width: '20px',
                    height: '20px',
                    float: 'right',
                  }}
                />
                <label
                  style={{
                    float: 'right',
                    margin: '0px 5px 0px 0px',
                    top: '-2px',
                    position: 'relative',
                  }}
                >
                  Active
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                {this.getId(this.props.mode)}
                <div
                  className="section no-padding"
                  style={{ marginTop: '20px' }}
                >
                  <div className="section no-padding">
                    {this.props.tags.map((cattag, index) => (
                      <div style={{ marginTop: '20px' }}>
                        {this.getCategoryFields(cattag, 'eventType')}
                      </div>
                    ))}
                    <ErrorMessage name="categories" component="div" />
                  </div>
                  {showGenre === true && (
                    <div className="section no-padding">
                      {this.props.tags.map((cattag, index) => (
                        <div style={{ marginTop: '20px' }}>
                          {this.getCategoryFields(cattag, 'genre')}
                        </div>
                      ))}
                      <ErrorMessage
                        name="categories"
                        component="div"
                      />
                    </div>
                  )}

                  {this.props.profile.userType === 1 && (
                    <div className="section no-padding">
                      <span className="title">Trevel Tags</span>
                      {this.props.tags.map((cattag, index) => (
                        <div>
                          {this.getCategoryFieldsTrevelTags(cattag)}
                        </div>
                      ))}
                    </div>
                  )}

                  <span
                    className="title"
                    style={{ display: 'block' }}
                  >
                    {this.state.typeCap + ' ' + 'Name '}{' '}
                    {isHappyHour === true ? (
                      <span style={{ color: '#7a7a7a' }}></span>
                    ) : (
                      <span style={{ color: '#7a7a7a' }}>
                        (Leave blank if you would like to use the
                        Entertainment's name as your Event Name)
                      </span>
                    )}
                  </span>
                  <ErrorMessage
                    name="title"
                    component="div"
                    style={{ float: 'right', color: 'red' }}
                  />
                  <input
                    type="title"
                    name="title"
                    value={this.state.title}
                    onChange={(e) => {
                      this.cursor = e.target.selectionStart;
                      this.setState({ title: e.target.value });
                    }}
                    onFocus={(e) => {
                      e.target.selectionStart = this.cursor;
                    }}
                  />
                  {isHappyHour === false || showGenre === true ? (
                    <Row
                      className="no-padding"
                      style={{ marginTop: '10px' }}
                    >
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <span className="title">Ticket Info</span>
                        <div style={{ marginTop: '10px' }}>
                          <Field name="tickets">
                            {({ field }) => {
                              return radioOptions.map((option) => {
                                return (
                                  <React.Fragment key={option.key}>
                                    <div
                                      style={{
                                        display: 'block',
                                        clear: 'both',
                                      }}
                                    >
                                      <input
                                        name="tickets"
                                        type="radio"
                                        id={option.id}
                                        {...field}
                                        value={option.value}
                                        checked={
                                          this.state.ticketBox ===
                                          option.value
                                        }
                                        style={{
                                          float: 'left',
                                          position: 'relative',
                                          top: '5px',
                                          marginRight: '10px',
                                          minWidth: 'initial',
                                          display: 'block',
                                        }}
                                        onClick={() =>
                                          this.ticketChange(
                                            option.value,
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={option.id}
                                        style={{
                                          float: 'left',
                                          display: 'block',
                                        }}
                                      >
                                        {option.key}
                                      </label>
                                    </div>
                                  </React.Fragment>
                                );
                              });
                            }}
                          </Field>
                        </div>
                        {this.state.ticketsRequired === true && (
                          <div
                            style={{
                              marginBottom: '10px',
                              display: 'block',
                              position: 'relative',
                              clear: 'both',
                              marginTop: '10px',
                            }}
                          >
                            <span className="title">
                              How Required?
                            </span>
                            <div
                              style={{
                                display: 'block',
                                clear: 'both',
                                marginTop: '10px',
                              }}
                            >
                              <label
                                style={{
                                  float: 'left',
                                  display: 'block',
                                }}
                              >
                                <Field
                                  type="checkbox"
                                  name="atDoor"
                                  checked={this.state.atDoor}
                                  style={{
                                    float: 'left',
                                    position: 'relative',
                                    top: '5px',
                                    marginRight: '10px',
                                    minWidth: 'initial',
                                    display: 'block',
                                  }}
                                  onClick={(value) =>
                                    this.atDoorChange(value)
                                  }
                                />
                                Available at the door.
                              </label>
                            </div>
                            <div
                              style={{
                                display: 'block',
                                clear: 'both',
                              }}
                            >
                              <label
                                style={{
                                  float: 'left',
                                  display: 'block',
                                }}
                              >
                                <Field
                                  type="checkbox"
                                  checked={this.state.shouldPurchase}
                                  name="shouldPurchase"
                                  style={{
                                    float: 'left',
                                    position: 'relative',
                                    top: '5px',
                                    marginRight: '10px',
                                    minWidth: 'initial',
                                    display: 'block',
                                  }}
                                  onClick={(value) =>
                                    this.shouldChange(value)
                                  }
                                />
                                Should be purchased in advance.
                              </label>
                            </div>
                            <div
                              style={{
                                display: 'block',
                                clear: 'both',
                              }}
                            >
                              <label
                                style={{
                                  float: 'left',
                                  display: 'block',
                                }}
                              >
                                <Field
                                  type="checkbox"
                                  name="mustPurchase"
                                  checked={this.state.mustPurchase}
                                  style={{
                                    float: 'left',
                                    position: 'relative',
                                    top: '5px',
                                    marginRight: '10px',
                                    minWidth: 'initial',
                                    display: 'block',
                                  }}
                                  onClick={(value) =>
                                    this.mustChange(value)
                                  }
                                />
                                Must be purchased in advance.
                              </label>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <span className="title">Websites</span>
                        <div>
                          <span className="inputLabel">
                            Event URL (Optional)
                          </span>
                          <Field
                            type="phone"
                            name="website"
                            placeholder="Website URL"
                          />
                          <ErrorMessage
                            name="website"
                            component="div"
                          />
                        </div>
                        {this.state.ticketsRequired === true && (
                          <div>
                            <span className="inputLabel">
                              Ticket Purchase URL (Optional)
                            </span>
                            <Field
                              type="url"
                              name="ticketUrl"
                              placeholder="Ticket Purchase URL"
                            />
                            <ErrorMessage
                              name="ticketUrl"
                              component="div"
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <span className="title">Websites</span>
                        <div>
                          <span className="inputLabel">
                            Event URL (Optional)
                          </span>
                          <Field
                            type="phone"
                            name="website"
                            placeholder="Website URL"
                          />
                          <ErrorMessage
                            name="website"
                            component="div"
                          />
                        </div>
                        {this.state.ticketsRequired === true && (
                          <div>
                            <span className="inputLabel">
                              Ticket Purchase URL (Optional)
                            </span>
                            <Field
                              type="url"
                              name="ticketUrl"
                              placeholder="Ticket Purchase URL"
                            />
                            <ErrorMessage
                              name="ticketUrl"
                              component="div"
                            />
                          </div>
                        )}
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      ></Col>
                    </Row>
                  )}
                  <span
                    className="title"
                    style={{ marginTop: '20px', display: 'block' }}
                  >
                    Description
                  </span>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      // inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      options: [
                        'inline',
                        'blockType',
                        'list',
                        'colorPicker',
                        'link',
                        'remove',
                        'history',
                      ],
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="no-padding"
              >
                <hr />
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (step === 2) {
      return (
        <div id="vendorAdmin">
          <Container>
            {this.props.vendor.vendorTypes.includes(1) &&
            this.props.vendor.locations.length !== 1 ? (
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <div
                    style={{
                      maxWidth: '500px',
                      margin: '0px auto',
                    }}
                  >
                    {this.props.vendor.vendorTypes.includes(1) &&
                    this.props.profile.userType !== 1 ? (
                      <div
                        style={{
                          marginTop: '10px',
                        }}
                      ></div>
                    ) : (
                      <div>
                        <div
                          className="mobileHide"
                          style={{ height: '60px' }}
                        />
                        <span
                          style={{
                            fontWeight: '600',
                            fontSize: '20px',
                            marginTop: '40px',
                          }}
                        >
                          Start Here
                        </span>
                      </div>
                    )}

                    {(this.props.vendor.vendorTypes.includes(2) ===
                      true && (
                      <div>
                        <p className="mobileHide">
                          First, lets check if your location is
                          already in the Trevel Guide.
                        </p>
                        <p className="mobileShow">
                          Check if your location is already in the
                          Trevel Guide.
                        </p>
                      </div>
                    )) ||
                      (this.props.profile.userType === 1 && (
                        <div>
                          <p className="mobileHide">
                            First, lets check if your location is
                            already in the Trevel Guide.
                          </p>
                          <p className="mobileShow">
                            Check if your location is already in the
                            Trevel Guide.
                          </p>
                        </div>
                      ))}

                    <Select
                      value={this.state.levelOne}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.selectChangeLocation(e, 0, 'one')
                      }
                      options={selectOptionsLocations}
                      isMulti={false}
                      placeholder={'Select a Location'}
                      // className="activeLevel"
                    />
                    {(this.props.vendor.vendorTypes.includes(2) ===
                      true && (
                      <center>
                        <p
                          style={{
                            marginTop: '20px',
                            display: 'block',
                          }}
                        >
                          If you don{"'"}t find your location just add
                          the info yourself.
                        </p>
                      </center>
                    )) ||
                      (this.props.profile.userType === 1 && (
                        <center>
                          <p
                            style={{
                              marginTop: '20px',
                              display: 'block',
                            }}
                          >
                            If you don{"'"}t find your location just
                            add the info yourself.
                          </p>
                        </center>
                      ))}
                  </div>
                </Col>

                {this.props.vendor.vendorTypes.includes(2) ===
                  true && (
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <div
                      style={{
                        marginTop: '30px',
                      }}
                    >
                      <span className="inputLabel">
                        Location Name
                      </span>
                      <ErrorMessage
                        name="locationName"
                        component="div"
                        style={{ float: 'right', color: 'red' }}
                      />
                      <Field
                        type="locationName"
                        name="locationName"
                        placeholder={this.state.locationName}
                      />

                      <span className="inputLabel">Street</span>
                      <Field
                        type="street"
                        name="street"
                        placeholder={this.state.street}
                      />
                      <ErrorMessage name="street" component="div" />
                      <span className="inputLabel">City</span>
                      <Field
                        type="city"
                        name="city"
                        placeholder={this.state.city}
                      />
                      <ErrorMessage name="city" component="div" />
                      <span className="inputLabel">State</span>
                      <Field
                        type="state"
                        name="state"
                        placeholder={this.state.state}
                      />
                      <ErrorMessage name="state" component="div" />
                    </div>
                    <span className="inputLabel">Zip Code</span>
                    <Field
                      type="zip"
                      name="zip"
                      placeholder={this.state.zip}
                    />
                    <ErrorMessage name="zip" component="div" />

                    {/* <span className="inputLabel">Longitude</span>
                <Field
                  type="longitude"
                  name="longitude"
                  placeholder={this.state.longitude}
                />
                <ErrorMessage name="longitude" component="div" />
                <span className="inputLabel">Latitude</span>
                <Field
                  type="latitude"
                  name="latitude"
                  placeholder={this.state.latitude}
                />
                <ErrorMessage name="latitude" component="div" /> */}
                    <div>
                      <span className="inputLabel">
                        Region / Area of City:
                      </span>

                      <span
                        className="left"
                        style={{
                          width: '300px',
                          height: '50px',
                        }}
                      >
                        <Select
                          value={selectedRegion}
                          closeMenuOnSelect={true}
                          blurInputOnSelect={false}
                          onChange={(e) =>
                            this.selectRegionChange(e, 0, 'one')
                          }
                          options={regions}
                          // onInputChange={(e) =>
                          //   this.moodTextChange(e, 0, 'one')
                          // }
                          isMulti={false}
                          placeholder={'Select area of City'}
                        />
                      </span>
                    </div>
                  </Col>
                )}
              </Row>
            ) : (
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div
                    style={{
                      marginTop: '30px',
                    }}
                  >
                    <Select
                      value={this.state.levelOne}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.selectChangeLocation(e, 0, 'one')
                      }
                      options={selectOptionsLocations}
                      isMulti={false}
                      // className="activeLevel"
                    />
                  </div>

                  {/* <div
                    style={{
                      marginTop: '30px',
                    }}
                  >
                    <span className="inputLabel">Location Name</span>
                    <ErrorMessage
                      name="locationName"
                      component="div"
                      style={{ float: 'right', color: 'red' }}
                    />
                    <Field
                      type="locationName"
                      name="locationName"
                      placeholder={this.state.locationName}
                    />

                    <span className="inputLabel">Street</span>
                    <Field
                      type="street"
                      name="street"
                      placeholder={this.state.street}
                    />
                    <ErrorMessage name="street" component="div" />
                    <span className="inputLabel">City</span>
                    <Field
                      type="city"
                      name="city"
                      placeholder={this.state.city}
                    />
                    <ErrorMessage name="city" component="div" />
                    <span className="inputLabel">State</span>
                    <Field
                      type="state"
                      name="state"
                      placeholder={this.state.state}
                    />
                    <ErrorMessage name="state" component="div" />
                  </div>
                  <span className="inputLabel">Zip Code</span>
                  <Field
                    type="zip"
                    name="zip"
                    placeholder={this.state.zip}
                  />
                  <ErrorMessage name="zip" component="div" /> */}

                  {/* <span className="inputLabel">Longitude</span>
                <Field
                  type="longitude"
                  name="longitude"
                  placeholder={this.state.longitude}
                />
                <ErrorMessage name="longitude" component="div" />
                <span className="inputLabel">Latitude</span>
                <Field
                  type="latitude"
                  name="latitude"
                  placeholder={this.state.latitude}
                />
                <ErrorMessage name="latitude" component="div" /> */}
                </Col>
              </Row>
            )}
          </Container>
        </div>
      );
    } else if (step === 4) {
      return (
        <Container id="imageUpload">
          <Row>
            <Col
              lg={12}
              md={6}
              sm={12}
              xs={12}
              className="left"
              style={{ marginTop: '10px' }}
            >
              <span className="title">Images</span>
              <p>
                You can skip uploading images for now, but you will
                need to add them before your page can be published.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <span className="inputLabel">Banner Image</span>
              {this.getHeroImage()}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <div>
                <input type="file" onChange={this.onSelectFile} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <span className="inputLabel">Thumbnail Image</span>
              {this.getThumbnail()}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <div>
                <input
                  type="file"
                  onChange={this.onSelectFileThumb}
                />
              </div>
            </Col>
          </Row>
        </Container>
      );
    } else if (step === 5) {
      return (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {/* <div className="section no-padding">
              <span className="section-header">Categories</span>
            </div> */}

            <Container>
              {/* <Row className="noticeboxBottom">
                <Col xs={12} md={12} sm={12} lg={12}>
                  <span className="bold">
                    Recommend Additional Tags
                  </span>
                  <p>
                    Our goal is to connect our users (your customers)
                    to the experiences we know they'll love. The way
                    we tag our locations and our users plays an
                    important part in making that recommendation.{' '}
                  </p>
                  <p>
                    If you have any suggestions on additional tags to
                    add, or for entirely new categories of options,
                    please let us know. Our mission is to provide
                    continual improvement to our product based on the
                    needs of our users and location partners and we
                    value all feedback you might have.
                  </p>

                  <Form>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          md={12}
                          sm={12}
                          lg={12}
                          className="left"
                        >
                          <textarea
                            type="text"
                            name="feedback"
                            placeholder="Suggestion...Feedback...Comment"
                            onChange={this.handleChange}
                            value={this.state.feedback}
                          />
                          <ErrorMessage
                            name="feedback"
                            component="div"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12} sm={12} lg={12}>
                          <div className="submitarea">
                            <center>
                              <span
                                type="submit"
                                onClick={() => {
                                  this.submitFeedback(
                                    this.state.feedback,
                                  );
                                }}
                                className="left redbutton"
                              >
                                Send
                              </span>
                            </center>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Form>

                  <Row />
                </Col>
              </Row> */}
            </Container>
          </Col>
        </Row>
      );
    } else if (step === 3) {
      return (
        <div>
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                {this.getScheduleTab(selected, timesSelected, dates)}
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (step === 1) {
      return (
        <div style={{ paddingTop: '15px' }}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <p>Will this event have entertainers performing?</p>
            </Col>
          </Row>
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <span className="title">Headliners</span>
            </Col>
          </Row>
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              {this.props.vendor.vendorTypes[0] === undefined ||
                (this.props.vendor.vendorTypes[0] !== 2 && (
                  <div>
                    <CreatableSelect
                      value={this.state.selectedEntertainmentOne}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.selectEntertainerChange(e, 0, 'One')
                      }
                      options={selectOptions}
                      isMulti={false}
                      placeholder={'Select Entertainer'}
                      // isValidNewOption={true}
                      onCreateOption={(e) =>
                        this.handleCreate(e, 0, 1)
                      }
                    />
                  </div>
                ))}
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              {this.state.newEntertainerOne !== null && (
                <a
                  onClick={() => {
                    this.editNewEntertainer(
                      this.state.newEntertainerOne,
                      1,
                    );
                  }}
                >
                  <span>Edit</span>
                </a>
              )}
            </Col>
          </Row>
          {this.state.selectedEntertainmentOne !== null && (
            <Row style={{ paddingTop: '15px' }}>
              <Col lg={10} md={10} sm={10} xs={10}>
                {this.props.vendor.vendorTypes[0] === undefined ||
                  (this.props.vendor.vendorTypes[0] !== 2 && (
                    <div>
                      <CreatableSelect
                        value={this.state.selectedEntertainmentTwo}
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectEntertainerChange(e, 1, 'Two')
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'Select Entertainer'}
                        // isValidNewOption={true}
                        onCreateOption={(e) =>
                          this.handleCreate(e, 1, 1)
                        }
                      />
                    </div>
                  ))}
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                {this.state.newEntertainerTwo !== null && (
                  <a
                    onClick={() => {
                      this.editNewEntertainer(
                        this.state.newEntertainerTwo,
                        1,
                      );
                    }}
                  >
                    <span>Edit</span>
                  </a>
                )}
              </Col>
            </Row>
          )}
          {this.state.selectedEntertainmentTwo !== null && (
            <Row style={{ paddingTop: '15px' }}>
              <Col lg={10} md={10} sm={10} xs={10}>
                {this.props.vendor.vendorTypes[0] === undefined ||
                  (this.props.vendor.vendorTypes[0] !== 2 && (
                    <div>
                      <CreatableSelect
                        value={this.state.selectedEntertainmentThree}
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectEntertainerChange(e, 2, 'Three')
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'Select Entertainer'}
                        // isValidNewOption={true}
                        onCreateOption={(e) =>
                          this.handleCreate(e, 2, 1)
                        }
                      />
                    </div>
                  ))}
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                {this.state.newEntertainerThree !== null && (
                  <a
                    onClick={() => {
                      this.editNewEntertainer(
                        this.state.newEntertainerThree,
                        1,
                      );
                    }}
                  >
                    <span>Edit</span>
                  </a>
                )}
              </Col>
            </Row>
          )}
          {this.state.selectedEntertainmentThree !== null && (
            <Row style={{ paddingTop: '15px' }}>
              <Col lg={10} md={10} sm={10} xs={10}>
                {this.props.vendor.vendorTypes[0] === undefined ||
                  (this.props.vendor.vendorTypes[0] !== 2 && (
                    <div>
                      <CreatableSelect
                        value={this.state.selectedEntertainmentFour}
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectEntertainerChange(e, 3, 'Four')
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'Select Entertainer'}
                        // isValidNewOption={true}
                        onCreateOption={(e) =>
                          this.handleCreate(e, 3, 1)
                        }
                      />
                    </div>
                  ))}
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                {this.state.newEntertainerFour !== null && (
                  <a
                    onClick={() => {
                      this.editNewEntertainer(
                        this.state.newEntertainerFour,
                        1,
                      );
                    }}
                  >
                    <span>Edit</span>
                  </a>
                )}
              </Col>
            </Row>
          )}
          {this.state.selectedEntertainmentFour !== null && (
            <Row style={{ paddingTop: '15px' }}>
              <Col lg={10} md={10} sm={10} xs={10}>
                {this.props.vendor.vendorTypes[0] === undefined ||
                  (this.props.vendor.vendorTypes[0] !== 2 && (
                    <div>
                      <CreatableSelect
                        value={this.state.selectedEntertainmentFive}
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectEntertainerChange(e, 4, 'Five')
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'Select Entertainer'}
                        // isValidNewOption={true}
                        onCreateOption={(e) =>
                          this.handleCreate(e, 4, 1)
                        }
                      />
                    </div>
                  ))}
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                {this.state.newEntertainerFive !== null && (
                  <a
                    onClick={() => {
                      this.editNewEntertainer(
                        this.state.newEntertainerFive,
                        1,
                      );
                    }}
                  >
                    <span>Edit</span>
                  </a>
                )}
              </Col>
            </Row>
          )}

          <Row style={{ marginTop: '25px' }}>
            <Col lg={10} md={10} sm={10} xs={10}>
              <span className="title">Additional</span>
            </Col>
          </Row>
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              {this.props.vendor.vendorTypes[0] === undefined ||
                (this.props.vendor.vendorTypes[0] !== 2 && (
                  <div>
                    <CreatableSelect
                      value={
                        this.state.selectedAdditionalEntertainmentOne
                      }
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) =>
                        this.selectAdditionalEntertainerChange(
                          e,
                          0,
                          'One',
                        )
                      }
                      options={selectOptions}
                      isMulti={false}
                      placeholder={'Select Entertainer'}
                      // isValidNewOption={true}
                      onCreateOption={(e) =>
                        this.handleCreate(e, 0, 2)
                      }
                    />
                  </div>
                ))}
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              {this.state.newAdditionalEntertainerOne !== null && (
                <a
                  onClick={() => {
                    this.editNewEntertainer(
                      this.state.newAdditionalEntertainerOne,
                      2,
                    );
                  }}
                >
                  <span>Edit</span>
                </a>
              )}
            </Col>
          </Row>
          {this.state.selectedAdditionalEntertainmentOne !== null && (
            <Row style={{ paddingTop: '15px' }}>
              <Col lg={10} md={10} sm={10} xs={10}>
                {this.props.vendor.vendorTypes[0] === undefined ||
                  (this.props.vendor.vendorTypes[0] !== 2 && (
                    <div>
                      <CreatableSelect
                        value={
                          this.state
                            .selectedAdditionalEntertainmentTwo
                        }
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectAdditionalEntertainerChange(
                            e,
                            1,
                            'Two',
                          )
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'Select Entertainer'}
                        // isValidNewOption={true}
                        onCreateOption={(e) =>
                          this.handleCreate(e, 1, 2)
                        }
                      />
                    </div>
                  ))}
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                {this.state.newAdditionalEntertainerTwo !== null && (
                  <a
                    onClick={() => {
                      this.editNewEntertainer(
                        this.state.newAdditionalEntertainerTwo,
                        2,
                      );
                    }}
                  >
                    <span>Edit</span>
                  </a>
                )}
              </Col>
            </Row>
          )}
          {this.state.selectedAdditionalEntertainmentTwo !== null && (
            <Row style={{ paddingTop: '15px' }}>
              <Col lg={10} md={10} sm={10} xs={10}>
                {this.props.vendor.vendorTypes[0] === undefined ||
                  (this.props.vendor.vendorTypes[0] !== 2 && (
                    <div>
                      <CreatableSelect
                        value={
                          this.state
                            .selectedAdditionalEntertainmentThree
                        }
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectAdditionalEntertainerChange(
                            e,
                            2,
                            'Three',
                          )
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'Select Entertainer'}
                        // isValidNewOption={true}
                        onCreateOption={(e) =>
                          this.handleCreate(e, 2, 2)
                        }
                      />
                    </div>
                  ))}
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                {this.state.newAdditionalEntertainerThree !==
                  null && (
                  <a
                    onClick={() => {
                      this.editNewEntertainer(
                        this.state.newAdditionalEntertainerThree,
                        2,
                      );
                    }}
                  >
                    <span>Edit</span>
                  </a>
                )}
              </Col>
            </Row>
          )}
          {this.state.selectedAdditionalEntertainmentThree !==
            null && (
            <Row style={{ paddingTop: '15px' }}>
              <Col lg={10} md={10} sm={10} xs={10}>
                {this.props.vendor.vendorTypes[0] === undefined ||
                  (this.props.vendor.vendorTypes[0] !== 2 && (
                    <div>
                      <CreatableSelect
                        value={
                          this.state
                            .selectedAdditionalEntertainmentFour
                        }
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectAdditionalEntertainerChange(
                            e,
                            3,
                            'Four',
                          )
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'Select Entertainer'}
                        // isValidNewOption={true}
                        onCreateOption={(e) =>
                          this.handleCreate(e, 3, 2)
                        }
                      />
                    </div>
                  ))}
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                {this.state.newAdditionalEntertainerFour !== null && (
                  <a
                    onClick={() => {
                      this.editNewEntertainer(
                        this.state.newAdditionalEntertainerFour,
                        2,
                      );
                    }}
                  >
                    <span>Edit</span>
                  </a>
                )}
              </Col>
            </Row>
          )}
          {this.state.selectedAdditionalEntertainmentFour !==
            null && (
            <Row style={{ paddingTop: '15px' }}>
              <Col lg={10} md={10} sm={10} xs={10}>
                {this.props.vendor.vendorTypes[0] === undefined ||
                  (this.props.vendor.vendorTypes[0] !== 2 && (
                    <div>
                      <CreatableSelect
                        value={
                          this.state
                            .selectedAdditionalEntertainmentFive
                        }
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        onChange={(e) =>
                          this.selectAdditionalEntertainerChange(
                            e,
                            4,
                            'Five',
                          )
                        }
                        options={selectOptions}
                        isMulti={false}
                        placeholder={'Select Entertainer'}
                        // isValidNewOption={true}
                        onCreateOption={(e) =>
                          this.handleCreate(e, 4, 2)
                        }
                      />
                    </div>
                  ))}
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                {this.state.newAdditionalEntertainerFive !== null && (
                  <a
                    onClick={() => {
                      this.editNewEntertainer(
                        this.state.newAdditionalEntertainerFive,
                        2,
                      );
                    }}
                  >
                    <span>Edit</span>
                  </a>
                )}
              </Col>
            </Row>
          )}
        </div>
      );
    }
  }

  editNewEntertainer = (editEntertainer, addMode) => {
    const blocksFromHtml = htmlToDraft(editEntertainer.description);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    const editorState = EditorState.createWithContent(contentState);
    this.setState({
      newEntertainerName: editEntertainer.name,
      newEntertainerEditorState: editorState,
      newEntertainerWebsite: editEntertainer.website,
      genreEntertainer: editEntertainer.genre,
      entertainmentTypeEntertainer: editEntertainer.entertainmentType,
      showNewEntertainerModal: true,
      addMode,
    });
  };

  handleChange = (event) => {
    this.setState({ feedback: event.target.value });
  };

  submitFeedback(feedback) {
    const userName =
      this.props.profile.firstName + this.props.profile.lastName;
    const userId = this.props.profile.userId;
    const db = firebase.firestore();
    const timestamp = new Date();
    db.collection('feedback').add({
      created: timestamp,
      userName,
      userId,
      feedback: feedback,
      topic: 'tags',
    });
    this.setState({ feedbackSubmitstatus: 1, feedback: '' });
  }

  ticketChange(e) {
    if (e === 'value 2') {
      this.setState({
        ticketsRequired: true,
        ticketBox: e,
      });
    } else if (e === 'value 1') {
      this.setState({
        ticketsRequired: false,
        ticketBox: e,
      });
    }
  }

  mustChange(w) {
    this.setState({
      mustPurchase: w.target.checked,
    });
  }

  shouldChange(w) {
    this.setState({
      shouldPurchase: w.target.checked,
    });
  }

  atDoorChange(w) {
    this.setState({
      atDoor: w.target.checked,
    });
  }

  getActionButton(step, title) {
    if (this.state.step === step) {
      return (
        <div
          className="text-center borderRight actionButtonActive"
          onClick={() => {
            this.setStep(step);
          }}
        >
          {title}
        </div>
      );
    } else {
      return (
        <div
          className="text-center borderRight actionButton"
          onClick={() => {
            this.setStep(step);
          }}
        >
          {title}
        </div>
      );
    }
  }

  getId(mode) {
    if (mode === 1) {
      return (
        <div className="border-box greyback">
          <span>
            <span className="inputLabel">
              ID: {this.state.locationId}
            </span>
          </span>
        </div>
      );
    }
  }

  setStep(step) {
    this.setState({
      step,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  getTitleBar(title) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              {/* <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span> */}
              <span className="vendorAppTitle">
                {title}
                <span
                  className="vendorAppTitle"
                  style={{ color: 'grey' }}
                >
                  {/* {name} */}
                </span>
              </span>
              {/* </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding"> */}
              <span className="helpButton mobileHide right">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right mobileHide">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>

              <div className="saveButtons mobileHide">
                <button className="saveButton" type="submit">
                  Save
                </button>

                <Link
                  to={{
                    pathname: '/vendor/timedevents',
                  }}
                >
                  <span className="cancelButton">Cancel</span>
                </Link>
              </div>

              <div className="right mobileShow">
                <button className="saveButton" type="submit">
                  Save
                </button>

                <Link
                  to={{
                    pathname: '/vendor/timedevents',
                  }}
                >
                  <span className="cancelButton">Cancel</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="no-margin">
          <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
            <div className="mobileShow">{this.getMobileMenu()}</div>
          </Col>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'body{height: 100%} @media screen and (max-width: 768px) { .maincontent{margin-top: 120px !important; top:0px !important}}',
            }}
          />
        </Row>
      </div>
    );
  }

  getTab(tabMode, tabLable, mode, last) {
    if (tabMode === mode) {
      if (last === true) {
        return (
          <div className="noBorderRight actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      } else {
        return (
          <div className="actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      }
    } else {
      if (last === true) {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton noBorderRight  actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      }
    }
  }

  onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }
  handleInputChange = (e) => {
    this.setState({
      selected: e.target.value,
      differentTimes: false,
      timesSelected: 1,
    });
  };

  handleTimesChange = (e) => {
    var numberAsInt = parseInt(e.target.value, 10);
    this.setState({
      timesSelected: numberAsInt,
    });
  };

  getSelectOptions() {
    let selectOptions = [];
    this.props.entertainment.forEach((entertainment) => {
      selectOptions.push({
        value:
          entertainment.entertainmentTitle +
          '/' +
          entertainment.entertainmentId,
        label: entertainment.entertainmentTitle,
      });
    });
    return selectOptions;
  }

  getSelectOptionsLocations() {
    let selectOptions = [];

    if (
      this.props.vendor.vendorTypes.includes(1) === true &&
      this.props.profile.userType !== 1
    ) {
      this.props.vendor.locations.forEach((locationId) => {
        const locationDetails = query('locationId')
          .is(locationId)
          .on(this.props.locations);
        if (locationDetails.length !== 0) {
          selectOptions.push({
            value:
              locationDetails[0].locationTitle + '/' + locationId,
            label: locationDetails[0].locationTitle,
          });
        }
      });
    } else {
      this.props.locations.forEach((location) => {
        selectOptions.push({
          value: location.locationTitle + '/' + location.key,
          label: location.locationTitle,
        });
      });
    }
    return selectOptions;
  }

  differentEndChange(event) {
    this.setState({
      differentEnd: event.target.value,
    });
  }

  differentTimeChange(event) {
    this.setState({
      differentTime: event.target.value,
    });
  }

  onChangeDate = (day) => {
    let status = false;
    if (this.state[day] === false || this.state[day] === undefined) {
      status = true;
    }
    let dayArray = {};
    dayArray.Sunday = { num: 0 };
    dayArray.Monday = { num: 1 };
    dayArray.Tuesday = { num: 2 };
    dayArray.Wednesday = { num: 3 };
    dayArray.Thursday = { num: 4 };
    dayArray.Friday = { num: 5 };
    dayArray.Saturday = { num: 6 };

    let currentNum = null;

    if (this.state.activeDay !== undefined) {
      Object.keys(dayArray).forEach((key) => {
        if (
          this.state.activeDay.includes(dayArray[key].num) === false
        ) {
          currentNum = dayArray[key].num;
        }
      });
    }
    let activeDay = dayArray[day].num;
    let newDays = [];

    if (this.state.activeDay === undefined) {
      let days = [0, 1, 2, 3, 4, 5, 6];
      days.forEach((day, index) => {
        if (day !== activeDay) {
          newDays.push(day);
        }
      });
    }

    if (
      this.state.activeDay !== undefined &&
      currentNum !== null &&
      currentNum < activeDay
    ) {
      newDays = this.state.activeDay;
    } else if (
      this.state.activeDay !== undefined &&
      currentNum !== null &&
      currentNum > activeDay
    ) {
      let days = [0, 1, 2, 3, 4, 5, 6];
      days.forEach((day, index) => {
        if (day !== activeDay) {
          newDays.push(day);
        }
      });
    }

    this.setState({
      [day]: status,
      activeDay: newDays,
    });
  };

  getLeftMenu() {
    return (
      <div className="vendorLeftNavButtons">
        {this.getTab(0, 'Details', this.state.mode, false)}
        {this.state.typeCap !== 'Promo' &&
          this.getTab(1, 'Entertainers', this.state.mode, false)}
        {this.getTab(2, 'Location', this.state.mode, false)}
        {this.getTab(3, 'Dates', this.state.mode, false)}
        {this.getTab(4, 'Images', this.state.mode, false)}
        {/* {this.getTab(5, 'Tags', this.state.mode, false)} */}
      </div>
    );
  }

  onChangeActionDifferentTimes = (value) => {
    this.setState({
      differentTimes: value.target.checked,
    });
  };

  onTimeChange = (time) => {
    console.log('TIME!', time);
    this.setState({ selectedTime: time });
  };

  setScheduleTimes(
    selectedStartTime,
    selectedEndTime,
    differentTime,
  ) {
    // Check if Time Set
    let bundle = {
      startTime: null,
      startTimeString: null,
      endTime: null,
      endTimeString: null,
      regHours: null,
    };

    if (differentTime === 'no') {
      bundle.regHours = true;
    } else {
      bundle.regHours = false;
    }

    if (
      selectedStartTime !== '' &&
      selectedStartTime !== undefined &&
      selectedStartTime !== null
    ) {
      const datestring = String(selectedStartTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      // const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.startTime = timeValue;
      bundle.startTimeString = selectedStartTime._d;
    }

    if (
      selectedEndTime !== '' &&
      selectedEndTime !== undefined &&
      selectedEndTime !== null
    ) {
      const datestring = String(selectedEndTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      // const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.endTime = timeValue;
      bundle.endTimeString = selectedEndTime._d;
    }

    return bundle;
  }

  getDifferentTimePicker() {
    return (
      <div style={{ margin: '0px auto' }}>
        <div className="clear">
          <span className="inputLabel">Start Time</span>
          <TimePicker
            showSecond={false}
            defaultValue={this.state.selectedStartTime}
            className="xxx"
            onChange={this.onStartTimeChange}
            format={format}
            use12Hours
            inputReadOnly
          />
        </div>

        <div className="clear text-center">
          <span className="inputLabel">End Time</span>
          <TimePicker
            showSecond={false}
            defaultValue={this.state.selectedEndTime}
            className="xxx"
            onChange={this.onEndTimeChange}
            format={format}
            use12Hours
            inputReadOnly
          />
        </div>
      </div>
    );
  }

  differentEndChange(event) {
    this.setState({
      differentEnd: event.target.value,
    });
  }

  differentTimeChange(event) {
    this.setState({
      differentTime: event.target.value,
    });
  }

  getDateEnd(numx) {
    let num = parseInt(numx, 10);

    if (num === 1 || num === 21 || num === 31) {
      return 'st';
    }
    if (num === 2 || num === 22) {
      return 'nd';
    }
    if (num === 3 || num === 23) {
      return 'rd';
    }
    if (num === 4 || num === 24) {
      return 'rth';
    }
    if (num > 5 && num < 20) {
      return <span>th</span>;
    }
    if (num === 25) {
      return <span>th</span>;
    }
    if (
      num === 20 ||
      num === 26 ||
      num === 27 ||
      num === 28 ||
      num === 29 ||
      num === 30
    ) {
      return <span>th</span>;
    }
  }

  handleDatePickStartDate = (DayClickEventHandler = (
    day,
    modifiers,
  ) => {
    if (modifiers.disabled !== true) {
      const days = [];
      days.push(day);
      this.setState({ repeatStartDate: days });
    }
  });

  getTimePickers(differentTimes) {
    console.log('SameToTime', this.state.SameToTime);
    let dateArray = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let activeDays = [];
    dateArray.forEach((day) => {
      if (this.state[day] === true) {
        activeDays.push(day);
      }
    });

    if (differentTimes === false) {
      return (
        <div
          style={{
            marginBottom: '15px',
            float: 'left',
            marginRight: '15px',
            background: 'white',
            padding: '10px',
            border: '1px solid grey',
          }}
        >
          <Row>
            <div>
              <span
                className="inputLabel"
                style={{
                  clear: 'both',
                  display: 'block',
                  fontWeight: '600',
                }}
              >
                From
              </span>
              <input
                name={'Same' + 'FromTime'}
                value={this.state['Same' + 'FromTime']}
                onChange={(value) => {
                  this.fromTimeChange('Same', 'FromTime', value);
                }}
                type="time"
                placeholder=""
                // min="09:00"
                // max="18:00"
                style={{
                  float: 'left',
                  minWidth: '40px',
                }}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <span
                className="inputLabel"
                style={{
                  clear: 'both',
                  display: 'block',
                  fontWeight: '600',
                }}
              >
                To
              </span>
              <input
                name={'Same' + 'ToTime'}
                value={this.state['Same' + 'ToTime']}
                onChange={(value) => {
                  this.fromTimeChange('Same', 'ToTime', value);
                }}
                type="time"
                placeholder=""
                // min="09:00"
                // max="18:00"
                style={{ float: 'left', minWidth: '40px' }}
              />
            </div>
          </Row>
        </div>
      );
    } else {
      return (
        <div>
          {activeDays.map((day, index) => (
            <div
              style={{
                marginBottom: '15px',
                float: 'left',
                marginRight: '15px',
                background: 'white',
                padding: '10px',
                border: '1px solid grey',
              }}
            >
              <Row>
                <span
                  className="inputLabel"
                  style={{ float: 'left', fontWeight: '600' }}
                >
                  {day}
                </span>
              </Row>
              <Row>
                <div>
                  <span
                    className="inputLabel"
                    style={{
                      clear: 'both',
                      display: 'block',
                      fontWeight: '600',
                    }}
                  >
                    From
                  </span>
                  <input
                    name={day + 'FromTime'}
                    value={this.state[day + 'FromTime']}
                    onChange={(value) => {
                      this.fromTimeChange(day, 'FromTime', value);
                    }}
                    type="time"
                    placeholder=""
                    // min="09:00"
                    // max="18:00"
                    style={{
                      float: 'left',
                      minWidth: '50px',
                    }}
                  />
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <span
                    className="inputLabel"
                    style={{
                      clear: 'both',
                      display: 'block',
                      fontWeight: '600',
                    }}
                  >
                    To
                  </span>
                  <input
                    name={day + 'ToTime'}
                    value={this.state[day + 'ToTime']}
                    onChange={(value) => {
                      this.fromTimeChange(day, 'ToTime', value);
                    }}
                    type="time"
                    placeholder=""
                    // min="09:00"
                    // max="18:00"
                    style={{ float: 'left', minWidth: '50px' }}
                  />
                </div>
              </Row>
            </div>
          ))}
        </div>
      );
    }
  }

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  };

  fromTimeChange = (catId, day, value) => {
    let combo = catId + day;
    this.setState({
      [combo]: value.target.value,
    });
  };

  // goat
  setDates(event) {
    let dates = [];
    if (event.scheduledTime.dates.multiDates === undefined) {
      dates.push(event.scheduledTime.dates.startDate);
    } else {
      event.scheduledTime.dates.multiDates.forEach((day, index) => {
        let date = moment(day.timestamp);
        dates.push(date);
      });
    }
    this.setState({ dates });
  }

  handleDatePick = (DayClickEventHandler = (day, modifiers) => {
    const days = this.state.dates;
    let newDates = [];
    if (modifiers.disabled !== true) {
      if (modifiers.selected) {
        days.forEach((dayx) => {
          let check = moment(day).isSame(dayx);
          if (check != true) {
            newDates.push(dayx);
          }
        });
        this.setState({ dates: newDates });
      } else {
        days.push(day);

        this.setState({ dates: days });
      }
    }
  });

  getScheduleTab = (selected, timesSelected, dates) => {
    const { from, to } = this.state;
    var check = moment(from);
    var selectedFromDay = check.format('D');
    var selectedFromDayWord = check.format('dddd');
    var weeknumber = check.week();

    var checkRepeatStart = moment(this.state.repeatStartDate[0]);
    var selectedRepeatStartDay = checkRepeatStart.format('D');
    var selectedRepeatStartDayWord = checkRepeatStart.format('dddd');

    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
        margin: '0px',
      },
    };
    const modifiers = { start: from, end: to };
    const today = new Date();
    const num = ['Never', 'Weekly', 'Monthly'];
    const nums = [1, 2, 3, 4, 5, 6];
    let dayArray = [
      { full: 'Sunday', short: 'su' },
      { full: 'Monday', short: 'm' },
      { full: 'Tuesday', short: 't' },
      { full: 'Wednesday', short: 'w' },
      { full: 'Thursday', short: 'th' },
      { full: 'Friday', short: 'f' },
      { full: 'Saturday', short: 'sa' },
    ];

    let selectedDayArray = [];
    dayArray.forEach((day, index) => {
      if (this.state[day.full] === true) {
        selectedDayArray.push(day.short);
      }
    });

    // const startOfMonth = moment(check)
    //   .startOf('month')
    //   .format('YYYY-MM-DD hh:mm');
    // const endOfMonth = moment(check)
    //   .endOf('month')
    //   .format('YYYY-MM-DD hh:mm');
    // const weekday = moment(check).weekday();
    // var results = [];
    // var weekx = 0;
    // var monday = moment(check)
    //   .startOf('month')
    //   .day(selectedFromDayWord);
    // if (monday.date() > 7) monday.add(7, 'd');
    // var month = monday.month();
    // while (month === monday.month()) {
    //   results.push(monday.format('LLLL'));
    //   if (monday.format('D') === check.format('D')) {
    //     console.log('weekx', results);
    //     weekx = results.length;
    //   }
    //   monday.add(7, 'd');
    // }
    let dateish = '';
    let weekx = '';
    if (this.state.repeatStartDate[0] !== undefined) {
      let timex = moment(this.state.repeatStartDate[0]).format(
        'YYYY-MM-DD',
      );
      let sn = moment(timex);
      weekx = Math.ceil(sn.date() / 7);
      if (weekx === 1) {
        dateish = 'first';
      } else if (weekx === 2) {
        dateish = 'second';
      } else if (weekx === 3) {
        dateish = 'third';
      } else if (weekx === 4) {
        dateish = 'fourth';
      } else if (weekx === 5) {
        dateish = 'fifth';
      }
    }
    let dateEnd = this.getDateEnd(selectedRepeatStartDay);

    let odd = [];
    let even = [];
    this.state.dates.forEach((date, index) => {
      if (index < this.state.dates.length / 2) {
        even.push(date);
      } else {
        odd.push(date);
      }
    });

    return (
      <Container>
        <Row style={{ paddingTop: '15px' }}>
          <label>Repeat Event:</label>
          <div
            style={{
              margin: '0px 10px 0px 10px',
              display: 'inline-block',
            }}
          >
            <select name="select" onChange={this.handleInputChange}>
              {num.map(function (n) {
                return (
                  <option value={n} selected={selected === n}>
                    {n}
                  </option>
                );
              })}
            </select>
          </div>
        </Row>
        <Row>
          {this.state.selected === 'Never' && (
            <div
              style={{
                clear: 'both',
                display: 'block',
                position: 'relative',
                padding: '20px',
                // border: '1px solid #a3a3a3',
                width: '100%',
                // background: 'white',
                maxWidth: '700px',
              }}
            >
              <Row>
                <div style={{ padding: '10px 0px 10px 0px' }}>
                  <span style={{ fontWeight: '600' }}>
                    {this.state.typeCap === 'Event'
                      ? '1. When is this event?'
                      : '1. When does this promotion run?'}
                  </span>
                </div>
              </Row>
              <Row>
                <Col
                  xs={12}
                  md={6}
                  sm={6}
                  lg={6}
                  className="no-padding"
                >
                  <div style={{ float: 'left' }}>
                    <DayPicker
                      selectedDays={dates.map(
                        (date) => new Date(date),
                      )}
                      onDayClick={this.handleDatePick}
                      modifiersStyles={modifiersStyles}
                      disabledDays={[{ before: today }]}
                    />
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  sm={6}
                  lg={6}
                  className="no-padding"
                >
                  <div
                    id="vendorTimes"
                    style={{ marginTop: '10px' }}
                    className="mobileShow"
                  >
                    {/* <Row>
                      <span
                        className="inputLabel"
                        style={{
                          margin: '0px auto',
                          marginBottom: '5px',
                        }}
                      >
                        Event Time
                      </span>
                    </Row> */}
                    {/* <Row>
                      {this.getDifferentTimePicker(
                        this.state.differentTime,
                        modifiersStyles,
                        today,
                      )}
                    </Row> */}
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding"
                      >
                        <div>
                          {this.getTimePickers(
                            this.state.differentTimes,
                          )}
                        </div>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div
                          style={{
                            paddingBottom: '10px',
                            display: 'inline-block',
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={this.state.differentTimes}
                            onChange={
                              this.onChangeActionDifferentTimes
                            }
                            className="closedCheckBox"
                            name="local"
                            style={{
                              transform: 'scale(2)',
                              float: 'left',
                            }}
                          />
                          <span
                            style={{
                              float: 'left',
                              position: 'relative',
                              top: '9px',
                            }}
                          >
                            Set Different Times Per Day
                          </span>
                          <ErrorMessage
                            name="local"
                            component="div"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding"
                      >
                        <div id="vendorTimes" className="mobileHide">
                          {this.getTimePickers(
                            this.state.differentTimes,
                          )}
                        </div>
                      </Col>
                    </Row> */}
                  </div>
                  <div
                    id="vendorTimes"
                    style={{ marginTop: '0px' }}
                    className="mobileHide"
                  >
                    {/* <Row>
                      <span
                        className="inputLabel"
                        style={{
                          margin: '0px auto',
                          marginBottom: '5px',
                        }}
                      >
                        Event Time
                      </span>
                    </Row> */}
                    {/* <Row>
                      {this.getDifferentTimePicker(
                        this.state.differentTime,
                        modifiersStyles,
                        today,
                      )}
                    </Row> */}
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding"
                      >
                        <div id="vendorTimes">
                          {this.getTimePickers(
                            this.state.differentTimes,
                          )}
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div
                          style={{
                            paddingBottom: '10px',
                            display: 'inline-block',
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={this.state.differentTimes}
                            onChange={
                              this.onChangeActionDifferentTimes
                            }
                            className="closedCheckBox"
                            name="local"
                            style={{
                              transform: 'scale(2)',
                              float: 'left',
                            }}
                          />
                          <span
                            style={{
                              float: 'left',
                              position: 'relative',
                              top: '9px',
                            }}
                          >
                            Set Different Times Per Day
                          </span>
                          <ErrorMessage
                            name="local"
                            component="div"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding"
                      >
                        <div id="vendorTimes" className="mobileHide">
                          {this.getTimePickers(
                            this.state.differentTimes,
                          )}
                        </div>
                      </Col>
                    </Row> */}
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {/* Weekly Mobile  */}
          {this.state.selected === 'Weekly' && (
            <div
              style={{
                clear: 'both',
                position: 'relative',
                width: '100%',
                maxWidth: '700px',
              }}
              className="mobileShow"
            >
              <div style={{ padding: '10px 0px 12px 0px' }}>
                <span style={{ fontWeight: '600' }}>
                  1. How often should we repeat this{' '}
                  {this.state.typeCap === 'Event'
                    ? 'event'
                    : 'promotion  '}
                  ?
                </span>
              </div>
              <div>
                <div style={{ paddingLeft: '20px' }}>
                  <label>Every:</label>
                  <div
                    style={{
                      margin: '0px 10px 0px 10px',
                      display: 'inline-block',
                    }}
                  >
                    <select
                      name="select"
                      onChange={this.handleTimesChange}
                    >
                      {nums.map(function (n) {
                        return (
                          <option
                            value={n}
                            selected={timesSelected === n}
                          >
                            {n}
                          </option>
                        );
                      })}
                    </select>{' '}
                    week{this.state.timesSelected !== 1 && 's'}
                  </div>
                </div>
                <Container>
                  <Row>
                    <div style={{ padding: '20px 0px 10px 0px' }}>
                      <span style={{ fontWeight: '600' }}>
                        2. When is this{' '}
                        {this.state.typeCap === 'Event'
                          ? 'event'
                          : 'promotion'}{' '}
                        each week?
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: '16px',
                        }}
                      >
                        <a
                          onClick={() => {
                            this.onChangeDate('Sunday');
                          }}
                          className={
                            this.state.Sunday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>Su</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Monday');
                          }}
                          className={
                            this.state.Monday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>M</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Tuesday');
                          }}
                          className={
                            this.state.Tuesday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>T</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Wednesday');
                          }}
                          className={
                            this.state.Wednesday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>W</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Thursday');
                          }}
                          className={
                            this.state.Thursday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>Th</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Friday');
                          }}
                          className={
                            this.state.Friday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>F</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Saturday');
                          }}
                          className={
                            this.state.Saturday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>S</span>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              {this.state.activeDay !== undefined && (
                <Row>
                  <Col
                    xs={12}
                    md={6}
                    sm={6}
                    lg={6}
                    className="no-padding"
                  >
                    <div style={{ float: 'left', marginTop: '20px' }}>
                      <div style={{ padding: '10px 0px 10px 0px' }}>
                        <span style={{ fontWeight: '600' }}>
                          3. When does this{' '}
                          {this.state.typeCap === 'Event'
                            ? 'event'
                            : 'promotion'}{' '}
                          start?
                        </span>
                      </div>
                      <DayPicker
                        selectedDays={this.state.repeatStartDate.map(
                          (date) => new Date(date),
                        )}
                        onDayClick={this.handleDatePickStartDate}
                        modifiersStyles={modifiersStyles}
                        disabledDays={[
                          { daysOfWeek: this.state.activeDay },
                          { before: today },
                        ]}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    sm={6}
                    lg={6}
                    className="no-padding"
                  >
                    <div
                      id="vendorTimes"
                      style={{
                        marginTop: '0px',
                        float: 'left',
                      }}
                    >
                      {/* <Row>
                      <span
                        className="inputLabel"
                        style={{
                          margin: '0px auto',
                          marginBottom: '5px',
                        }}
                      >
                        Event Time
                      </span>
                    </Row> */}
                      {/* <Row>
                        {this.getDifferentTimePicker(
                          this.state.differentTime,
                          modifiersStyles,
                          today,
                        )}
                      </Row> */}

                      <Row>
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="no-padding"
                        >
                          <div
                            style={{
                              paddingBottom: '10px',
                              display: 'inline-block',
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={this.state.differentTimes}
                              onChange={
                                this.onChangeActionDifferentTimes
                              }
                              className="closedCheckBox"
                              name="local"
                              style={{
                                transform: 'scale(2)',
                                float: 'left',
                              }}
                            />
                            <span
                              style={{
                                float: 'left',
                                position: 'relative',
                                top: '9px',
                              }}
                            >
                              Set Different Times Per Day
                            </span>
                            <ErrorMessage
                              name="local"
                              component="div"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={12}
                          md={12}
                          sm={12}
                          lg={12}
                          className="no-padding"
                        >
                          <div id="vendorTimes">
                            {this.getTimePickers(
                              this.state.differentTimes,
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          )}
          {/* Weekly Desktop */}
          {this.state.selected === 'Weekly' && (
            <div
              style={{
                clear: 'both',
                position: 'relative',
                padding: '20px',
                // border: '1px solid #a3a3a3',
                width: '100%',
                // background: 'white',
                maxWidth: '700px',
              }}
              className="mobileHide"
            >
              <div style={{ padding: '10px 0px 12px 0px' }}>
                <span style={{ fontWeight: '600' }}>
                  1. How often should we repeat this{' '}
                  {this.state.typeCap === 'Event'
                    ? 'event'
                    : 'promotion  '}
                  ?
                </span>
              </div>
              <div>
                <div style={{ paddingLeft: '20px' }}>
                  <label>Every: </label>
                  <div
                    style={{
                      margin: '0px 10px 0px 10px',
                      display: 'inline-block',
                    }}
                  >
                    <select
                      name="select"
                      onChange={this.handleTimesChange}
                    >
                      {nums.map(function (n) {
                        return (
                          <option
                            value={n}
                            selected={timesSelected === n}
                          >
                            {n}
                          </option>
                        );
                      })}
                    </select>{' '}
                    week{this.state.timesSelected !== 1 && 's'}
                  </div>
                </div>
                <Container>
                  <Row>
                    <div style={{ padding: '20px 0px 10px 0px' }}>
                      <span style={{ fontWeight: '600' }}>
                        2. When is this{' '}
                        {this.state.typeCap === 'Event'
                          ? 'event'
                          : 'promotion'}{' '}
                        each week?
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="no-padding"
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: '16px',
                        }}
                      >
                        <a
                          onClick={() => {
                            this.onChangeDate('Sunday');
                          }}
                          className={
                            this.state.Sunday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>Su</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Monday');
                          }}
                          className={
                            this.state.Monday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>M</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Tuesday');
                          }}
                          className={
                            this.state.Tuesday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>T</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Wednesday');
                          }}
                          className={
                            this.state.Wednesday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>W</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Thursday');
                          }}
                          className={
                            this.state.Thursday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>Th</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Friday');
                          }}
                          className={
                            this.state.Friday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>F</span>
                        </a>

                        <a
                          onClick={() => {
                            this.onChangeDate('Saturday');
                          }}
                          className={
                            this.state.Saturday === true
                              ? 'dayDotActive'
                              : 'dayDotInActive'
                          }
                        >
                          <span>S</span>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              {this.state.activeDay !== undefined && (
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div style={{ float: 'left', marginTop: '20px' }}>
                      <div style={{ padding: '10px 0px 10px 0px' }}>
                        <span style={{ fontWeight: '600' }}>
                          3. When does this{' '}
                          {this.state.typeCap === 'Event'
                            ? 'event'
                            : 'promotion'}{' '}
                          start?
                        </span>
                      </div>

                      <DayPicker
                        selectedDays={this.state.repeatStartDate.map(
                          (date) => new Date(date),
                        )}
                        onDayClick={this.handleDatePickStartDate}
                        modifiersStyles={modifiersStyles}
                        disabledDays={[
                          { daysOfWeek: this.state.activeDay },
                          { before: today },
                        ]}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding"
                  >
                    <div
                      id="vendorTimes"
                      style={{
                        marginTop: '0px',
                        float: 'left',
                      }}
                    >
                      <div style={{ padding: '10px 0px 10px 0px' }}>
                        <span style={{ fontWeight: '600' }}>
                          4. What time is this{' '}
                          {this.state.typeCap === 'Event'
                            ? 'event'
                            : 'promotion'}
                          ?
                        </span>
                      </div>
                      {/* <Row>
                      <span
                        className="inputLabel"
                        style={{
                          margin: '0px auto',
                          marginBottom: '5px',
                        }}
                      >
                        Event Time
                      </span>
                    </Row> */}
                      {/* <Row>
                        {this.getDifferentTimePicker(
                          this.state.differentTime,
                          modifiersStyles,
                          today,
                        )}
                      </Row> */}
                      <Row>
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="no-padding"
                        >
                          <div
                            style={{
                              paddingBottom: '10px',
                              display: 'inline-block',
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={this.state.differentTimes}
                              onChange={
                                this.onChangeActionDifferentTimes
                              }
                              className="closedCheckBox"
                              name="local"
                              style={{
                                transform: 'scale(2)',
                                float: 'left',
                              }}
                            />
                            <span
                              style={{
                                float: 'left',
                                position: 'relative',
                                top: '9px',
                              }}
                            >
                              Set Different Times Per Day
                            </span>
                            <ErrorMessage
                              name="local"
                              component="div"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={12}
                          md={12}
                          sm={12}
                          lg={12}
                          className="no-padding"
                        >
                          <div id="vendorTimes">
                            {this.getTimePickers(
                              this.state.differentTimes,
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          )}
          {/* Monthly Desktop */}
          {this.state.selected === 'Monthly' && (
            <div
              style={{
                clear: 'both',
                position: 'relative',
                padding: '20px',
                // border: '1px solid #a3a3a3',
                width: '100%',
                // background: 'white',
                maxWidth: '700px',
              }}
              className="mobileHide"
            >
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <span style={{ fontWeight: '600' }}>
                  1. How often should we repeat this{' '}
                  {this.state.typeCap === 'Event'
                    ? 'event'
                    : 'promotion  '}
                  ?
                </span>
              </div>
              <div>
                <div style={{ paddingLeft: '20px' }}>
                  <label>Every: </label>
                  <div
                    style={{
                      margin: '0px 10px 0px 10px',
                      display: 'inline-block',
                    }}
                  >
                    <select
                      name="select"
                      onChange={this.handleTimesChange}
                    >
                      {nums.map(function (n) {
                        return (
                          <option
                            value={n}
                            selected={timesSelected === n}
                          >
                            {n}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  month{this.state.timesSelected !== 1 && 's'}
                </div>

                <Container>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                    >
                      <div style={{ padding: '20px 0px 10px 0px' }}>
                        <span style={{ fontWeight: '600' }}>
                          2. What day does this{' '}
                          {this.state.typeCap === 'Event'
                            ? 'event'
                            : 'promotion'}{' '}
                          start?
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={6}
                      sm={6}
                      lg={6}
                      className="no-padding"
                    >
                      <div
                        style={{ float: 'left', marginTop: '0px' }}
                      >
                        <DayPicker
                          selectedDays={this.state.repeatStartDate.map(
                            (date) => new Date(date),
                          )}
                          onDayClick={this.handleDatePickStartDate}
                          modifiersStyles={modifiersStyles}
                        />
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      sm={6}
                      lg={6}
                      className="no-padding"
                    >
                      <div
                        id="vendorTimes"
                        style={{
                          marginTop: '0px',
                          float: 'left',
                        }}
                      >
                        {/* <Row>
                      <span
                        className="inputLabel"
                        style={{
                          margin: '0px auto',
                          marginBottom: '5px',
                        }}
                      >
                        Event Time
                      </span>
                    </Row> */}
                        <Row>
                          <Col
                            xs={12}
                            md={12}
                            sm={12}
                            lg={12}
                            className="no-padding"
                          >
                            <div id="vendorTimes">
                              {this.getTimePickers(
                                this.state.differentTimes,
                              )}
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                          {this.getDifferentTimePicker(
                            this.state.differentTime,
                            modifiersStyles,
                            today,
                          )}
                        </Row> */}
                      </div>
                    </Col>
                  </Row>
                  {this.state.repeatStartDate.length !== 0 && (
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div style={{ padding: '10px 0px 10px 0px' }}>
                          <span style={{ fontWeight: '600' }}>
                            3. How should this{' '}
                            {this.state.typeCap === 'Event'
                              ? 'event'
                              : 'promotion'}{' '}
                            repeat?
                          </span>
                        </div>
                        <div
                          className="radio"
                          style={{ marginLeft: '20px' }}
                        >
                          <label>
                            <input
                              type="radio"
                              value="option1"
                              checked={
                                this.state.selectedOption ===
                                'option1'
                              }
                              onChange={this.handleOptionChange}
                            />
                            <span style={{ marginLeft: '10px' }}>
                              On the {selectedRepeatStartDay}
                              {dateEnd} of each month.
                            </span>
                          </label>
                        </div>
                        <div
                          className="radio"
                          style={{ marginLeft: '20px' }}
                        >
                          <label>
                            <input
                              type="radio"
                              value="option2"
                              checked={
                                this.state.selectedOption ===
                                'option2'
                              }
                              onChange={this.handleOptionChange}
                            />
                            <span style={{ marginLeft: '10px' }}>
                              On the {dateish}{' '}
                              {selectedRepeatStartDayWord} of each
                              month.
                            </span>
                          </label>
                        </div>
                        <div
                          className="radio"
                          style={{ marginLeft: '20px' }}
                        >
                          <label>
                            <input
                              type="radio"
                              value="option4"
                              checked={
                                this.state.selectedOption ===
                                'option4'
                              }
                              onChange={this.handleOptionChange}
                            />
                            <span style={{ marginLeft: '10px' }}>
                              On the first{' '}
                              {selectedRepeatStartDayWord} of each
                              month.
                            </span>
                          </label>
                        </div>
                        <div
                          className="radio"
                          style={{ marginLeft: '20px' }}
                        >
                          <label>
                            <input
                              type="radio"
                              value="option3"
                              checked={
                                this.state.selectedOption ===
                                'option3'
                              }
                              onChange={this.handleOptionChange}
                            />
                            <span style={{ marginLeft: '10px' }}>
                              On the last {selectedRepeatStartDayWord}{' '}
                              of each month.
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Container>
              </div>
            </div>
          )}
          {/* Monthly Mobile */}
          {this.state.selected === 'Monthly' && (
            <div
              style={{
                clear: 'both',
                position: 'relative',
                width: '100%',
                maxWidth: '700px',
              }}
              className="mobileShow"
            >
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <span style={{ fontWeight: '600' }}>
                  1. How often should we repeat this{' '}
                  {this.state.typeCap === 'Event'
                    ? 'event'
                    : 'promotion  '}
                  ?
                </span>
              </div>
              <div>
                <div style={{ paddingLeft: '20px' }}>
                  <label>Every: </label>
                  <div
                    style={{
                      margin: '0px 10px 0px 10px',
                      display: 'inline-block',
                    }}
                  >
                    <select
                      name="select"
                      onChange={this.handleTimesChange}
                    >
                      {nums.map(function (n) {
                        return (
                          <option
                            value={n}
                            selected={timesSelected === n}
                          >
                            {n}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  month{this.state.timesSelected !== 1 && 's'}
                </div>
                <Container>
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      className="no-padding"
                    >
                      <div style={{ padding: '20px 0px 10px 0px' }}>
                        <span style={{ fontWeight: '600' }}>
                          2. What day does this{' '}
                          {this.state.typeCap === 'Event'
                            ? 'event'
                            : 'promotion'}{' '}
                          start?
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={6}
                      sm={6}
                      lg={6}
                      className="no-padding"
                    >
                      <div
                        style={{ float: 'left', marginTop: '0px' }}
                      >
                        <DayPicker
                          selectedDays={this.state.repeatStartDate.map(
                            (date) => new Date(date),
                          )}
                          onDayClick={this.handleDatePickStartDate}
                          modifiersStyles={modifiersStyles}
                        />
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      sm={6}
                      lg={6}
                      className="no-padding"
                    >
                      <div
                        style={{
                          marginTop: '10px',
                          float: 'left',
                        }}
                        className="mobileShow"
                      >
                        {/* <Row>
                      <span
                        className="inputLabel"
                        style={{
                          margin: '0px auto',
                          marginBottom: '5px',
                        }}
                      >
                        Event Time
                      </span>
                    </Row> */}
                        {/* <Row>
                          {this.getDifferentTimePicker(
                            this.state.differentTime,
                            modifiersStyles,
                            today,
                          )}
                        </Row> */}
                        <Row>
                          <Col
                            xs={12}
                            md={12}
                            sm={12}
                            lg={12}
                            className="no-padding"
                          >
                            <div id="vendorTimes">
                              {this.getTimePickers(
                                this.state.differentTimes,
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div
                        id="vendorTimes"
                        style={{ marginTop: '60px' }}
                        className="mobileHide"
                      >
                        {/* <Row>
                      <span
                        className="inputLabel"
                        style={{
                          margin: '0px auto',
                          marginBottom: '5px',
                        }}
                      >
                        Event Time
                      </span>
                    </Row> */}
                        {/* <Row>
                          {this.getDifferentTimePicker(
                            this.state.differentTime,
                            modifiersStyles,
                            today,
                          )}
                        </Row> */}
                        <Row>
                          <Col
                            xs={12}
                            md={12}
                            sm={12}
                            lg={12}
                            className="no-padding"
                          >
                            <div id="vendorTimes">
                              {this.getTimePickers(
                                this.state.differentTimes,
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  {this.state.repeatStartDate.length !== 0 && (
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div style={{ padding: '10px 0px 10px 0px' }}>
                          <span style={{ fontWeight: '600' }}>
                            3. How should this{' '}
                            {this.state.typeCap === 'Event'
                              ? 'event'
                              : 'promotion'}{' '}
                            repeat?
                          </span>
                        </div>
                        <div
                          className="radio"
                          style={{ marginLeft: '20px' }}
                        >
                          <label>
                            <input
                              type="radio"
                              value="option1"
                              checked={
                                this.state.selectedOption ===
                                'option1'
                              }
                              onChange={this.handleOptionChange}
                            />
                            <span style={{ marginLeft: '10px' }}>
                              On the {selectedRepeatStartDay}
                              {dateEnd} of each month.
                            </span>
                          </label>
                        </div>
                        <div
                          className="radio"
                          style={{ marginLeft: '20px' }}
                        >
                          <label>
                            <input
                              type="radio"
                              value="option2"
                              checked={
                                this.state.selectedOption ===
                                'option2'
                              }
                              onChange={this.handleOptionChange}
                            />
                            <span style={{ marginLeft: '10px' }}>
                              On the {dateish}{' '}
                              {selectedRepeatStartDayWord} of each
                              month.
                            </span>
                          </label>
                        </div>
                        <div
                          className="radio"
                          style={{ marginLeft: '20px' }}
                        >
                          <label>
                            <input
                              type="radio"
                              value="option4"
                              checked={
                                this.state.selectedOption ===
                                'option4'
                              }
                              onChange={this.handleOptionChange}
                            />
                            <span style={{ marginLeft: '10px' }}>
                              On the first{' '}
                              {selectedRepeatStartDayWord} of each
                              month.
                            </span>
                          </label>
                        </div>
                        <div
                          className="radio"
                          style={{ marginLeft: '20px' }}
                        >
                          <label>
                            <input
                              type="radio"
                              value="option3"
                              checked={
                                this.state.selectedOption ===
                                'option3'
                              }
                              onChange={this.handleOptionChange}
                            />
                            <span style={{ marginLeft: '10px' }}>
                              On the last {selectedRepeatStartDayWord}{' '}
                              of each month.
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Container>
              </div>
            </div>
          )}
        </Row>
        {/* <Row className="no-margin">
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <Row className="no-margin text-center">
              <span className="inputLabel">Event Dates</span>
              <div className="full-width">
                <p>
                  {!from && !to && 'Please select the first day.'}
                  {from && !to && 'Please select the last day.'}
                  {from &&
                    to &&
                    `${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
                  {from && to && (
                    <button
                      className="link"
                      onClick={this.handleResetClick.bind(this)}
                    >
                      Reset
                    </button>
                  )}
                </p>
              </div>
              <div id="vendorSchedule">
                <DayPicker
                  onDayClick={this.handleStartDayClick}
                  selectedDays={[from, { from, to }]}
                  modifiers={modifiers}
                  modifiersStyles={modifiersStyles}
                  disabledDays={{ before: today }}
                />
              </div>

              <p>
                    {this.state.selectedStartDay
                      ? this.state.selectedStartDay.toLocaleDateString()
                      : "Please select a day"}
                  </p>
            </Row>
          </Col>
        </Row> */}
        <div style={{ maxWidth: '350px', padding: '10px' }}>
          <span
            style={{
              fontWeight: '600',
              marginBottom: '10px',
              display: 'block',
            }}
          >
            Currently Scheduled Dates
          </span>
          <Row>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {even.map((datex, index) => (
                <span
                  style={{
                    display: 'block',
                    clear: 'both',
                    color: 'red',
                  }}
                >
                  {this.getCurrentDates(datex)}
                </span>
              ))}
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
              {odd.map((datex, index) => (
                <div>{this.getCurrentDates(datex)}</div>
              ))}
            </Col>
          </Row>
        </div>
      </Container>
    );
  };

  getCurrentDates(date) {
    let today = moment();
    let status = moment(date).isBefore(today);

    if (status === true) {
      return (
        <span
          style={{
            display: 'block',
            clear: 'both',
            color: 'red',
          }}
        >
          {moment(date).format('MMMM, DD, YYYY')}
        </span>
      );
    } else {
      return (
        <span
          style={{
            display: 'block',
            clear: 'both',
            color: 'green',
          }}
        >
          {moment(date).format('MMMM, DD, YYYY')}
        </span>
      );
    }
  }

  handleResetClick() {
    this.setState({ from: undefined, to: undefined });
  }

  setSchedule(selectedStartDay, selectedEndDay) {
    let bundle = {
      startDate: null,
      startTimestamp: null,
      endDate: null,
      endTimestamp: null,
      oneDayEvent: null,
    };

    if (selectedStartDay === selectedEndDay) {
      bundle.oneDayEvent = true;
    } else {
      bundle.oneDayEvent = false;
    }

    // Check for Date
    if (
      selectedStartDay !== '' &&
      selectedStartDay !== undefined &&
      selectedStartDay !== null
    ) {
      const timestamp = moment(selectedStartDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.startDate = dateString;
      bundle.startTimestamp = timestampReady;
    }

    if (
      selectedEndDay !== '' &&
      selectedEndDay !== undefined &&
      selectedEndDay !== null
    ) {
      const timestamp = moment(selectedEndDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.endDate = dateString;
      bundle.endTimestamp = timestampReady;
    }
    return bundle;
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleStartDayClick = (day, { selected }) => {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);

    // this.setState({
    //   selectedStartDay: selected ? undefined : day
    // });
  };

  handleEndDayClick(day, { selected }) {
    this.setState({
      selectedEndDay: selected ? undefined : day,
    });
  }

  onStartTimeChange = (time) => {
    console.log('Start TIME!', time);
    this.setState({ selectedStartTime: time });
  };

  onEndTimeChange = (time) => {
    console.log('End TIME!', time);
    this.setState({ selectedEndTime: time });
  };

  getMobileNext(mode, mobileMenu) {
    let totalCount = mobileMenu.length;
    let currentMenu = mobileMenu[mode];
    if (currentMenu.last !== true) {
      let arrayNum = mode + 1;
      let nextMenu = mobileMenu[arrayNum];
      return (
        <div
          style={{
            float: 'right',
            position: 'relative',
            top: '8px',
          }}
        >
          <a
            onClick={() => {
              this.setMode(nextMenu.mode);
            }}
          >
            <span style={{ fontWeight: '600', color: 'white' }}>
              {/* {nextMenu.label} */}
              Next
            </span>
            <span
              className="right"
              style={{ position: 'relative', right: '-5px' }}
            >
              <MaterialIcon
                icon="arrow_forward_ios"
                size={25}
                color="white"
              />
            </span>
          </a>
        </div>
      );
    }
  }

  getMobilePrev(mode, mobileMenu) {
    let currentMenu = mobileMenu[mode];
    if (currentMenu.first !== true) {
      let arrayNum = mode - 1;
      let nextMenu = mobileMenu[arrayNum];
      return (
        <div
          style={{ float: 'left', position: 'relative', top: '8px' }}
        >
          <a
            onClick={() => {
              this.setMode(nextMenu.mode);
            }}
          >
            <span style={{ fontWeight: '600', color: 'white' }}>
              Back
            </span>
            <span className="left">
              <MaterialIcon
                icon="arrow_back_ios"
                size={25}
                color="white"
              />
            </span>
          </a>
        </div>
      );
    }
  }

  getMobileCurrent(mode, mobileMenu) {
    let total = mobileMenu.length;
    let currentMenu = mobileMenu[mode];
    return (
      <div>
        <span style={{ fontWeight: '600', color: 'white' }}>
          {currentMenu.label}
        </span>
      </div>
    );
  }

  getMobileMenu() {
    let mobileMenu = [
      {
        label: 'Details',
        mode: 0,
        last: false,
        first: true,
      },
      {
        label: 'Entertainers',
        mode: 1,
        last: false,
        first: false,
      },
      {
        label: 'Location',
        mode: 2,
        last: false,
        first: false,
      },
      {
        label: 'Dates',
        mode: 3,
        last: false,
        first: false,
      },
      {
        label: 'Images',
        mode: 4,
        last: false,
        first: false,
      },
      // {
      //   label: 'Tags',
      //   mode: 5,
      //   last: true,
      //   first: false,
      // },
    ];

    return (
      <div
        style={{
          background: '#353535',
          paddingTop: '5px',
          paddingBottom: '5px',
          // position: 'fixed',
          // bottom: '0px',
          width: '100%',
        }}
      >
        <Row>
          <Col xs={4} md={4} sm={4} lg={4}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobilePrev(this.state.mode, mobileMenu)}
            </div>
          </Col>
          <Col xs={4} md={4} sm={4} lg={4}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobileCurrent(this.state.mode, mobileMenu)}
              <div className="paginationInner">
                {mobileMenu.map((menu, index) => (
                  <div
                    className={
                      menu.mode === this.state.mode
                        ? 'dot active'
                        : 'dot'
                    }
                  />
                ))}
              </div>
            </div>
          </Col>
          <Col xs={4} md={4} sm={4} lg={4}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobileNext(this.state.mode, mobileMenu)}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  createEntertainer = () => {
    let values = {
      value: 'tempid',
      label: this.state.newEntertainerName,
    };
    if (this.state.activeFieldIndex === 0) {
      this.setState({
        selectedEntertainmentOne: values,
      });
    } else if (this.state.activeFieldIndex === 1) {
      this.setState({
        selectedEntertainmentTwo: values,
      });
    } else if (this.state.activeFieldIndex === 2) {
      this.setState({
        selectedEntertainmentThree: values,
      });
    } else if (this.state.activeFieldIndex === 3) {
      this.setState({
        selectedEntertainmentFour: values,
      });
    } else if (this.state.activeFieldIndex === 4) {
      this.setState({
        selectedEntertainmentFive: values,
      });
    }
    console.log(
      'this.state.newEntertainerCount',
      this.state.newEntertainerCount,
    );
    let currentCount = this.state.newEntertainerCount;
    const rawContentState = convertToRaw(
      this.state.newEntertainerEditorState.getCurrentContent(),
    );
    const markup = draftToHtml(rawContentState);

    let newEntertainer = {
      name: this.state.newEntertainerName,
      description: markup,
      website: this.state.newEntertainerWebsite,
      genre: this.state.genreEntertainer,
      entertainmentType: this.state.entertainmentTypeEntertainer,
    };

    if (this.state.activeFieldIndex === 0) {
      this.setState({
        newEntertainerOne: newEntertainer,
      });
    } else if (this.state.activeFieldIndex === 1) {
      this.setState({
        newEntertainerTwo: newEntertainer,
      });
    } else if (this.state.activeFieldIndex === 2) {
      this.setState({
        newEntertainerThree: newEntertainer,
      });
    } else if (this.state.activeFieldIndex === 3) {
      this.setState({
        newEntertainerFour: newEntertainer,
      });
    } else if (this.state.activeFieldIndex === 4) {
      this.setState({
        newEntertainerFive: newEntertainer,
      });
    }
    //Set Counter and Reset
    let newCount = currentCount + 1;
    const newEntertainerEditorState = EditorState.createEmpty();
    this.setState({
      showNewEntertainerModal: false,
      newEntertainerEditorState,
      newEntertainerWebsite: '',
      newEntertainerCount: newCount,
      entertainmentTypeEntertainer: [],
      genreEntertainer: [],
    });
  };

  createAdditionalEntertainer = () => {
    let values = {
      value: 'tempid',
      label: this.state.newEntertainerName,
    };
    if (this.state.activeFieldIndex === 0) {
      this.setState({
        selectedAdditionalEntertainmentOne: values,
      });
    } else if (this.state.activeFieldIndex === 1) {
      this.setState({
        selectedAdditionalEntertainmentTwo: values,
      });
    } else if (this.state.activeFieldIndex === 2) {
      this.setState({
        selectedAdditionalEntertainmentThree: values,
      });
    } else if (this.state.activeFieldIndex === 3) {
      this.setState({
        selectedAdditionalEntertainmentFour: values,
      });
    } else if (this.state.activeFieldIndex === 4) {
      this.setState({
        selectedAdditionalEntertainmentFive: values,
      });
    }
    console.log(
      'this.state.newEntertainerCount',
      this.state.newAdditionalEntertainerCount,
    );
    let currentCount = this.state.newAdditionalEntertainerCount;
    const rawContentState = convertToRaw(
      this.state.newEntertainerEditorState.getCurrentContent(),
    );
    const markup = draftToHtml(rawContentState);

    let newEntertainer = {
      name: this.state.newEntertainerName,
      description: markup,
      website: this.state.newEntertainerWebsite,
      genre: this.state.genreEntertainer,
      entertainmentType: this.state.entertainmentTypeEntertainer,
    };

    if (this.state.activeFieldIndex === 0) {
      this.setState({
        newAdditionalEntertainerOne: newEntertainer,
      });
    } else if (this.state.activeFieldIndex === 1) {
      this.setState({
        newAdditionalEntertainerTwo: newEntertainer,
      });
    } else if (this.state.activeFieldIndex === 2) {
      this.setState({
        newAdditionalEntertainerThree: newEntertainer,
      });
    } else if (this.state.activeFieldIndex === 3) {
      this.setState({
        newAdditionalEntertainerFour: newEntertainer,
      });
    } else if (this.state.activeFieldIndex === 4) {
      this.setState({
        newAdditionalEntertainerFive: newEntertainer,
      });
    }
    //Set Counter and Reset
    let newCount = currentCount + 1;
    const newEntertainerEditorState = EditorState.createEmpty();
    this.setState({
      showNewEntertainerModal: false,
      newEntertainerEditorState,
      newEntertainerWebsite: '',
      newEntertainerCount: newCount,
      entertainmentTypeEntertainer: [],
      genreEntertainer: [],
    });
  };

  closeModal = () => {
    const newEntertainerEditorState = EditorState.createEmpty();
    this.setState({
      showNewEntertainerModal: false,
      newEntertainerEditorState,
      newEntertainerWebsite: '',
      genreEntertainer: [],
      entertainmentTypeEntertainer: [],
    });
  };

  onNEEditorStateChange = (editorState) => {
    this.setState({
      nEeditorChange: true,
      newEntertainerEditorState: editorState,
    });
  };
  selectRegionChange = (values) => {
    this.setState({
      selectedRegion: values,
    });
  };
  getEntertainmentETCategoryFields(cattag) {
    const options = [];
    if (cattag.sid === 'entertainmentType') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div>
          <span className="inputLabel">{cattag.parent}</span>
          <Select
            value={this.state[cattag.sid + 'Entertainer']}
            onChange={(value) => {
              this.handleOnChangeEntertainment(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  handleCreate = (input, index, addMode) => {
    this.setState({
      newEntertainerName: input,
      showNewEntertainerModal: true,
      activeFieldIndex: index,
      addMode,
    });
  };

  getEntertainmentGenreCategoryFields(cattag) {
    const options = [];
    if (cattag.sid === 'genre') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div>
          <span className="inputLabel">{cattag.parent}</span>
          <Select
            value={this.state[cattag.sid + 'Entertainer']}
            onChange={(value) => {
              this.handleOnChangeEntertainment(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  render() {
    const {
      title,
      categories,
      error,
      city,
      latitude,
      longitude,
      state,
      street,
      locationName,
      zip,
      website,
      locationHero,
      locationId,
      locationThumb,
      phone,
      tickets,
      mustPurchase,
      shouldPurchase,
      atDoor,
    } = this.state;
    const isInvalid =
      categories === '' ||
      title === '' ||
      locationName === '' ||
      city === '' ||
      latitude === '' ||
      longitude === '' ||
      state === '' ||
      street === '' ||
      zip === '' ||
      locationHero === '' ||
      locationId === '' ||
      locationThumb === '' ||
      phone === '' ||
      website === '' ||
      tickets === '' ||
      mustPurchase === '' ||
      shouldPurchase === '' ||
      atDoor === '';
    const { editorState, newEntertainerEditorState } = this.state;
    return (
      <div style={{ height: '100%', display: 'block' }} id="vendor">
        <div>{this.getBannerCropper(this.state.cropping)}</div>
        <div>
          {this.getThumbnailCropper(this.state.croppingThumb)}
        </div>

        <Formik
          enableReinitialize
          initialValues={{
            title: this.state.title,
            categories: '',
            city: this.state.city,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            state: this.state.state,
            street: this.state.street,
            zip: this.state.zip,
            zoneId: this.state.zoneId,
            locationHero: '',
            bandName: this.state.bandName,
            locationId: '',
            locationThumb: '',
            packages: '',
            description: this.state.description,
            longDescription: this.state.longDescription,
            website: this.state.website,
            menuLink: this.state.menuLink,
            locationName: this.state.locationName,
            atDoor: this.state.atDoor,
            mustPurchase: this.state.mustPurchase,
            shouldPurchase: this.state.shouldPurchase,
            ticketUrl: this.state.ticketUrl,
          }}
          validate={(values) => {
            let errors = {};
            if (!values.title) {
              errors.title = 'Required';
            }
            if (this.state.locationName === '') {
              errors.locationName = 'Required';
            }
            return errors;
          }}
          onSubmit={(values) => {
            this.saveLocation(this.state.editorState, values);
          }}
        >
          {({ isSubmitting }) => (
            <Form
              style={{ height: '100%', display: 'block' }}
              onKeyDown={this.onKeyDown}
            >
              {this.getTitleBar('Edit ' + this.state.typeCap)}
              <div className="vendorLeftNav mobileHide">
                <div style={{ padding: '10px' }}>
                  <span style={{ fontWeight: '600', color: 'white' }}>
                    SETUP
                  </span>
                </div>

                {this.getLeftMenu()}
              </div>

              <Container style={{ paddingBottom: '20px' }}>
                {this.getStep(
                  this.state.mode,
                  this.state.selected,
                  this.state.timesSelected,
                  this.state.dates,
                  this.state.selectedRegion,
                )}
              </Container>
            </Form>
          )}
        </Formik>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
        <Modal
          isOpen={this.state.showNewEntertainerModal}
          closeModal
          // onRequestClose={this.closeRewardModal}
          style={conversationModalStyles}
          contentLabel="Reward Modal"
        >
          <div style={{ padding: '20px' }}>
            <Row>
              <Col lg={8} md={8} sm={8} xs={8} className="no-padding">
                <h4>Add New Entertainer</h4>
              </Col>

              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.state.addMode === 1 ? (
                  <a
                    style={{
                      textAlign: 'center',
                      float: 'right',
                      marginLeft: '10px',
                    }}
                    onClick={() => {
                      this.createEntertainer();
                    }}
                  >
                    <MaterialIcon
                      icon="check"
                      size={30}
                      color="#f21d59 "
                    />
                  </a>
                ) : (
                  <a
                    style={{
                      textAlign: 'center',
                      float: 'right',
                      marginLeft: '10px',
                    }}
                    onClick={() => {
                      this.createAdditionalEntertainer();
                    }}
                  >
                    <MaterialIcon
                      icon="check"
                      size={30}
                      color="#f21d59 "
                    />
                  </a>
                )}

                <a
                  style={{ textAlign: 'center', float: 'right' }}
                  onClick={() => {
                    this.closeModal();
                  }}
                >
                  <MaterialIcon
                    icon="close"
                    size={30}
                    color="#353535"
                  />
                </a>
              </Col>
            </Row>

            <div style={{ marginBottom: '20px' }}>
              <span>
                Only the name is required, but you can fill in as much
                info as needed.
              </span>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                newEntertainerName: this.state.newEntertainerName,
                description: this.state.newEntertainerDescription,
                website: this.state.newEntertainerWebsite,
              }}
              validate={(values) => {
                let errors = {};
                if (!values.title) {
                  errors.title = 'Required';
                }
                if (this.state.editorChange === false) {
                  errors.description = 'Required';
                }
                return errors;
              }}
              onSubmit={(values) => {
                this.saveLocation(this.state.editorState, values);
              }}
            >
              {({ isSubmitting }) => (
                <Form
                  style={{ height: '100%', display: 'block' }}
                  onKeyDown={this.onKeyDown}
                >
                  <span
                    className="inputLabel"
                    style={{ display: 'inline' }}
                  >
                    Name
                  </span>
                  <ErrorMessage
                    name="title"
                    component="div"
                    style={{ float: 'right', color: 'red' }}
                  />
                  <Field
                    type="text"
                    name="newEntertainerName"
                    placeholder={this.state.newEntertainerName}
                    onChange={this.nEtitleChange}
                    style={{ marginBottom: '20px' }}
                    value={this.state.newEntertainerName}
                  />

                  <span
                    className="inputLabel"
                    style={{ display: 'inline' }}
                  >
                    Description
                  </span>
                  <ErrorMessage
                    name="description"
                    component="div"
                    style={{
                      float: 'right',
                      color: 'red',
                      fontSize: '15px',
                    }}
                  />
                  <Editor
                    editorState={newEntertainerEditorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onNEEditorStateChange}
                    toolbar={{
                      // inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      options: [
                        'inline',
                        'blockType',
                        'list',
                        'colorPicker',
                        'link',
                        'remove',
                        'history',
                      ],
                    }}
                  />
                  <span className="inputLabel">Website Link</span>
                  <Field
                    type="url"
                    name="newEntertainerWebsite"
                    placeholder="Website URL"
                    onChange={this.nEWebsiteChange}
                    value={this.state.newEntertainerWebsite}
                  />
                  <ErrorMessage
                    name="newEntertainerWebsite"
                    component="div"
                  />
                </Form>
              )}
            </Formik>
            <div style={{ marginTop: '15px' }}>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="no-padding"
                >
                  <div className="section no-padding">
                    {this.props.tags.map((cattag, index) => (
                      <div>
                        {this.getEntertainmentETCategoryFields(
                          cattag,
                        )}
                      </div>
                    ))}
                    <ErrorMessage name="categories" component="div" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="no-padding"
                >
                  <div className="section no-padding">
                    {this.props.tags.map((cattag, index) => (
                      <div>
                        {this.getEntertainmentGenreCategoryFields(
                          cattag,
                        )}
                      </div>
                    ))}
                    <ErrorMessage name="categories" component="div" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .editorClassName{min-height: 150px} #vendor input[name="title"], input[type="phone"]{min-width: 100% !important}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locations: state.locations.locations,
    tags: state.tags.tags,
    mode: state.mode.mode,
    profile: state.profile.profile,
    vendor: state.vendor.vendor,
    vendorTimedEvents: state.vendorTimedEvents.events,
    entertainment: state.vendorEntertainment.entertainment,
    regions: state.regions.regions,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setLocations: (location) => {
    //   dispatch(setLocations(location));
    // },
    getVendorTimedEvents: (vendorId) => {
      dispatch(getVendorTimedEvents(vendorId));
    },
    getVendorEntertainment: (vendorId) => {
      dispatch(getVendorEntertainment(vendorId));
    },
    getEntertainment: (zoneId) => {
      dispatch(getEntertainment(zoneId));
    },
    getTimedEvents: (zoneId) => {
      dispatch(getTimedEvents(zoneId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorTimedEventEdit);
