import { call, put, takeLatest } from 'redux-saga/effects';
import {
  rejectInvite,
  maybeInvite,
  updatePackage,
  updatePackageEdit,
  createConnectionOne,
  createConnectionTwo,
} from '../../services/InvitationServices';
import { push } from 'connected-react-router';

import * as actions from './actions';

let defaultState = {
  event: {
    title: 'hello',
  },
};

function* acceptInvitationSaga(action) {
  // yield call(acceptInvite, action.invite);
  yield call(updatePackage, action.invite);
  yield call(
    createConnectionOne,
    action.invite,
    action.invite.connections,
  );
  yield call(
    createConnectionTwo,
    action.invite,
    action.invite.connections,
  );

  yield put({
    type: actions.INVITE_SUCCESS,
    payload: {
      ...defaultState,
      action,
    },
  });
  const url = '/event/' + action.invite.eventId;

  yield put(push(url));
}

function* editInvitationSaga(action) {
  // yield call(editInvite, action.invite);
  yield call(updatePackageEdit, action.invite);
  yield call(
    createConnectionOne,
    action.invite,
    action.invite.connections,
  );
  yield call(
    createConnectionTwo,
    action.invite,
    action.invite.connections,
  );
  yield put({
    type: actions.INVITE_SUCCESS,
    payload: {
      ...defaultState,
      action,
    },
  });
  // const url = "/event/edit/" + action.invite.eventId + "/1";
  const url = '/event/' + action.invite.eventId;
  yield put(push(url));
}

function* rejectInvitationSaga(action) {
  const rejectStatus = yield call(rejectInvite, action.invite);
  // yield put({
  //   type: actions.INVITE_SUCCESS,
  //   payload: {
  //     ...defaultState,
  //     rejectStatus,
  //   },
  // });
}

function* maybeInvitationSaga(action) {
  const maybeStatus = yield call(maybeInvite, action.invite);
  yield put({
    type: actions.INVITE_SUCCESS,
    payload: {
      ...defaultState,
      maybeStatus,
    },
  });
}

export default function* sagas() {
  yield takeLatest(actions.INVITE_ACCEPT, acceptInvitationSaga);
  yield takeLatest(actions.INVITE_REJECT, rejectInvitationSaga);
  yield takeLatest(actions.INVITE_EDIT, editInvitationSaga);
  yield takeLatest(actions.INVITE_MAYBE, maybeInvitationSaga);
}
