import React from 'react';
import { connect } from 'react-redux';

export class TopicUpcomingEvents extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }

    return (
      <div id="helpTopic">
        <div className="wizard-section-border noTopPadding">
          <h6>Upcoming Events</h6>
          <p>
            Trevel is not only a planner, but it is also a social
            calendar.
          </p>
          <p>
            Socials start out in the planning section as you and your
            guests work through some of the details. Once a social is
            accepted by everyone, it will move from planning to
            Upcoming. The social calendar is then where you can see
            Socials that have been accepted by everyone. The only
            thing left to do is "Start" your Social!
          </p>
          {/* <Card>
            <a id='inviteNow' className='red helpTopic'>
              Invite Someone Now
            </a>
            <UncontrolledCollapse toggler='#inviteNow'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card>
          <Card>
            <a id='inviteLater' className='red helpTopic'>
              Invite Someone Later
            </a>
            <UncontrolledCollapse toggler='#inviteLater'>
              <div className='section full-width noTopPadding'>
                <div className='card-section'>coming soon</div>
              </div>
            </UncontrolledCollapse>
          </Card> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {};
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
    user: state.user,
    events: state.events.events,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicUpcomingEvents);
