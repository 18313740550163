import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import firebase from '../../config/firebase';
// Reducers
import { setUser } from '../../sagas/loginSaga/reducer';
import { getEvents } from '../../sagas/userEventsSaga/reducer';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  UNSAFE_componentWillMount() {
    this.setUsers(this.props.users);
  }

  setUsers(users) {
    let usersArray = [];
    if (users !== undefined) {
      users.forEach((user) => {
        usersArray.push(user.userName);
      });
    }
    this.setState({
      usersArray,
    });
  }

  saveProfile(firstName, lastName, userName, state) {
    // this.props.setSignupStatus();
    this.setState({
      loaded: false,
    });
    let zone = 11;
    if (state === 'MO') {
      zone = 11;
    } else if (state === 'KS') {
      zone = 10;
    }

    // Add the new Favorite if there is a referalId
    let favoriteEntertainment = [];
    if (
      this.props.unauth.referalId !== undefined &&
      this.props.unauth.referalId !== null
    ) {
      favoriteEntertainment.push(this.props.unauth.referalId);
    }

    const timestamp = new Date();

    const userRef = firebase
      .firestore()
      .collection('users')
      .doc(this.props.user.id);
    userRef
      .set({
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        email: this.props.user.email,
        sex: 0,
        profilePhotoPath: '',
        verified: false,
        created: timestamp,
        state: state,
        zoneId: zone,
        favorites: [],
        favoriteEntertainment,
        tags: {
          love: {
            tagsBeverages: [],
            tagsCuisine: [],
            tagsSetting: [],
            tagsDateType: [],
            tagsExperienceType: [],
            tagsGenre: [],
          },
          hate: {
            tagsBeverages: [],
            tagsCuisine: [],
            tagsSetting: [],
            tagsDateType: [],
            tagsExperienceType: [],
            tagsGenre: [],
          },
        },
        vendorAccount: null,
      })
      .then(() => {
        const user = firebase.auth().currentUser;
        user
          .updateProfile({
            displayName: userName,
          })
          .then(() => {})
          .catch((error) => {
            // An error happened.
          });
      })
      .then(() => {
        let user = {
          id: this.props.user.id,
          userName: userName,
          email: this.props.user.email,
          authenticated: true,
          newuser: true,
          invited: null,
          eventId: null,
          createEvent: false,
        };
        // Check if user has click an "Invite Someone Link"
        if (this.props.newEvent.clear === false) {
          user.createEvent = true;
        }
        // Check if this user was sent an invite to join
        if (this.props.invite.event.title !== null) {
          user.invited = true;
          user.eventId = this.props.invite.event[0].eventId;
          user.inviteType =
            this.props.invite.event[0].invitations[
              this.props.invite.event[0].token
            ].type;
        }
        // We will want to seperate this from setUser
        if (this.props.invite.event.title !== null) {
          this.updateEvent(
            this.props.event,
            firstName,
            lastName,
            userName,
            this.props.event.creationDetails.plannerId,
            this.props.user.id,
            this.props.invite,
            user,
          );
        } else {
          this.props.setUser(user);
        }
      });
  }

  // updateNotification(notificationId, userName, firstName, lastName, userId) {
  //   console.log("ACCEPT FUNCTION", notificationId);
  //   const fullName = firstName + " " + lastName;
  //   const db = firebase.firestore();
  //   const notification = db.collection("notifications").doc(notificationId);
  //   const setDoc = notification.update({
  //     status: 1,
  //     responceDate: "today",
  //     replyMessage: "",
  //     receiverName: fullName,
  //     userId: userId,
  //     userName: userName
  //   });
  // }

  updateEvent(
    event,
    firstName,
    lastName,
    userName,
    senderId,
    userId,
    invite,
    user,
  ) {
    const db = firebase.firestore();
    const eventId = event.eventId;
    const userEvent = db.collection('user_events').doc(eventId);
    const timestamp = new Date();

    const userConnectionObject = {
      connected: true,
      created: timestamp,
      userId: senderId,
    };

    const senderConnectionObject = {
      connected: true,
      created: timestamp,
      userId: userId,
    };

    const usersObject = event.users;
    const invitationObject = event.invitations;
    const fullname = firstName + ' ' + lastName;
    let updatedUser = {};

    // Add User With New UID
    if (invite.event[0].eventSettings.eventApproved === true) {
      updatedUser = {
        access: true,
        approved: true,
        editor: false,
        attending: true,
        rejected: false,
        inviteAction: true,
        profile: {
          fullName: fullname,
          profilePhotoPath: '',
          userName: userName,
        },
      };
    } else {
      // is planning invite
      updatedUser = {
        access: true,
        approved: false,
        editor: true,
        attending: true,
        rejected: false,
        inviteAction: true,
        profile: {
          fullName: fullname,
          profilePhotoPath: '',
          userName: userName,
        },
      };
    }

    usersObject[userId] = updatedUser;
    // Delete temp id
    delete usersObject[event.token];
    // Update Invitations
    invitationObject[userId] = event.invitations[event.token];
    invitationObject[userId].userId = userId;
    // Delete Old Invite
    delete invitationObject[event.token];
    // Make New Event
    // let updatedEvent = event;
    // updatedEvent.users = usersObject;
    // updatedEvent.invitations = invitationObject;
    // Delete Token Property
    // delete updatedEvent.token;
    // console.log("updatedEvent", updatedEvent);
    const setDoc = userEvent
      .update({
        users: usersObject,
        invitations: invitationObject,
        // status: 2
      })
      .then(() => {
        const setDoc = db
          .collection('connections')
          .doc(userId)
          .set({
            connections: {
              [senderId]: {
                connected: true,
                created: timestamp,
                userId: senderId,
              },
            },
          })
          .then(() => {
            const senderConnections = db
              .collection('connections')
              .doc(senderId);
            const setDoc3 = senderConnections
              .update({
                ['connections.' + userId]: senderConnectionObject,
              })
              .then(() => {
                this.props.getEvents(userId);
                this.props.setUser(user);
              });
          });
      });
  }

  checkUserName(userName) {
    if (this.state.usersArray.indexOf(userName) + 1) {
      return true;
    }
  }

  render() {
    return (
      <div id="signup">
        <div>
          <div className="title-area">
            {/* <h3>Create a New Account</h3> */}
            <p className="signup-box-info">
              {/* <span>Or </span>
              <a href="/login">login to your account</a>
              <span>.</span> */}
            </p>
          </div>
          <Formik
            initialValues={{
              firstname: '',
              lastname: '',
              username: '',
              state: '',
            }}
            validate={(values) => {
              let errors = {};
              if (!values.firstname) {
                errors.firstname = 'Required';
              }
              if (!values.lastname) {
                errors.lastname = 'Required';
              }
              if (!values.username) {
                errors.username = 'Required';
              }
              if (values.username) {
                let check = this.checkUserName(values.username);
                if (check === true) {
                  errors.username = 'This User Name is Taken. Sorry!';
                }
              }
              if (values.state === '') {
                errors.state = 'Required';
              }
              return errors;
            }}
            onSubmit={(values) => {
              this.saveProfile(
                values.firstname,
                values.lastname,
                values.username,
                values.state,
              );
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <Form>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="left"
                      >
                        {/* <h4 className="hfour">Required</h4> */}
                        <Field
                          type="text"
                          name="firstname"
                          placeholder="First Name"
                        />
                        <ErrorMessage
                          name="firstname"
                          component="div"
                          className="error-message"
                        />
                        <Field
                          type="text"
                          name="lastname"
                          placeholder="Last Name"
                        />
                        <ErrorMessage
                          name="lastname"
                          component="div"
                          className="error-message"
                        />
                        <Field
                          type="text"
                          name="username"
                          placeholder="Display Name"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="error-message"
                        />
                        <fieldset>
                          <select
                            id="state"
                            type="text"
                            value={values.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.email && touched.email
                                ? 'text-input error'
                                : 'text-input'
                            }
                          >
                            <option value="none">
                              Choose Your City
                            </option>
                            <option value="MO">St. Louis</option>
                            <option value="KS">Kansas City</option>]
                          </select>
                        </fieldset>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error-message"
                        />
                      </Col>
                      {/* <Col xs={12} md={6} sm={12} lg={6}>
                      <h4 className="hfour">Optional</h4>
                      <Field component="select" name="gender">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other?</option>
                      </Field>
                      <ErrorMessage name="firstname" component="div" />
                    </Col> */}
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} lg={12}>
                        <div className="submitarea">
                          <center>
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="center"
                            >
                              Next
                            </button>
                          </center>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              );
            }}
          </Formik>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'header { display: none } .maincontent { top: 0px; margin-top: 0px;} .footer-container{display:none} .full-height{height:100%}',
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.invite.event[0],
    invite: state.invite,
    users: state.users.users,
    unauth: state.unauth,
    newEvent: state.newEvent,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
    getEvents: (user) => {
      dispatch(getEvents(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileForm);
