import firebase from '../config/firebase';

export function getEvents(zoneId) {
  const promoArray = [];
  const db = firebase.firestore();
  const promos = db.collection('events');
  const getP = function () {
    return new Promise((resolve, reject) => {
      const query = promos
        .where('zoneId', '==', zoneId)
        .where('active', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc && doc.exists) {
              const {
                description,
                heroImage,
                locations,
                scheduledTime,
                status,
                thumbnail,
                teaser,
                title,
                creatorInfo,
              } = doc.data();
              promoArray.push({
                id: doc.id,
                description,
                heroImage,
                locations,
                scheduledTime,
                status,
                thumbnail,
                teaser,
                title,
                vendorId: creatorInfo.vendorId,
              });
            }
          });
          return promoArray;
        })
        .then((result) => {
          resolve(promoArray);
        });
    });
  };
  return getP();
}
