import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import VendorMenu from '../../components/VendorMenu';
import VendorUserListItem from '../../components/VendorUserListItem';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';
import { deleteDocument } from '../../services/AdminServices';
const KEYS_TO_FILTERS = ['firstName', 'lastName', 'userName'];
const query = require('array-query');

export class VendorAddUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,

      searchTerm: '',
      loading: true,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
  }

  componentDidMount() {
    document.title = 'Manage Users';
    const docArray = [];
    this.props.vendor.users.forEach((user) => {
      const docDetails = query('userId')
        .is(user.userId)
        .on(this.props.users);
      docDetails[0].role = user.role;
      docArray.push(docDetails[0]);
    });
    this.setState({
      vendorUsers: docArray,
      loading: false,
    });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getLocations() {
    const docArray = [];
    this.props.vendor.locations.forEach((location) => {
      const docDetails = query('locationId')
        .is(location)
        .on(this.props.alllocations);
      if (docDetails[0] !== undefined) {
        docArray.push(docDetails[0]);
      }
    });
    return docArray;
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">{title}</span>

              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
              <div className="saveButtons">
                <Link
                  to={{
                    pathname: '/vendor/users',
                  }}
                  className="right"
                >
                  <span className="cancelButton">Cancel</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  getUserListing(user, index) {
    const userx = user;
    return (
      <Row className="no-margin oddeven">
        <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
          <UserAvatar
            size="50"
            name={userx.firstName + userx.lastName}
            src={userx.profilePhotoPath}
          />
        </Col>
        <Col xs={10} md={10} sm={10} lg={10}>
          <Link
            to={{
              pathname: '/vendor/new/user',
              state: {
                user: { userx },
              },
            }}
          >
            {userx.firstName} {userx.lastName}
          </Link>
          <br />
          <span className="userName">@{userx.userName} </span>
        </Col>
      </Row>
    );
  }

  getAppContent(filteredUsers) {
    return (
      <div id="openBox">
        {/* <div className="leftNav">
          <span>hello</span>
        </div> */}

        {/* <div style={{ padding: '10px' }}>
          <center>
            <h5 style={{}}>Choose a User</h5>
          </center>
        </div> */}

        <div style={{ padding: '10px', clear: 'both' }}>
          <div>
            <Container>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
                  <SearchInput
                    className="search-input"
                    onChange={this.searchUpdated}
                  />
                </Col>
              </Row>
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  {filteredUsers.map((user, index) => (
                    <div key={'key' + index}>
                      {this.getUserListing(user, index)}
                    </div>
                  ))}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    let filteredUsers = [];
    if (this.state.searchTerm === '') {
      filteredUsers = [];
    } else {
      filteredUsers = this.props.users.filter(
        createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
      );
    }
    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Add User')}
        <div className="vendorContent">
          {this.getAppContent(filteredUsers)}
        </div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%}  #analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
  };
};

function mapStateToProps(state) {
  return {
    users: state.users.users,
    vendor: state.vendor.vendor,
    profile: state.profile.profile,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorAddUsers);
