// import React, { useEffect, Fragment } from 'react';
// import { withRouter } from 'react-router-dom';

// function ScrollToTop({ history, children }) {
//   useEffect(() => {
//     const unlisten = history.listen(() => {
//       window.scrollTo(0, 0);
//     });
//     console.log('hellox');
//     return () => {
//       unlisten();
//     };
//   }, []);

//   return <Fragment>{children}</Fragment>;
// }

// export default withRouter(ScrollToTop);

// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// export default function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

// import { useEffect } from 'react';
// import { withRouter } from 'react-router-dom';

// function ScrollToTop({ history }) {
//   useEffect(() => {
//     const unlisten = history.listen(() => {
//       const divElement = document.getElementById('root');
//       if (divElement) {
//         alert('Hello! I am an alert box!');
//         divElement.scrollIntoView({ behavior: 'instant' });
//       }
//     });
//     return () => {
//       unlisten();
//     };
//   }, []);

//   return null;
// }

// export default withRouter(ScrollToTop);

import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // const divElement = document.getElementById('root');
      // if (divElement) {
      //   console.log('dd', divElement);
      //   divElement.scrollTo(0, 0);
      // }

      const element = document.getElementById('root');
      const y = element.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y,
        behavior: 'instant',
      });

      // window.scrollTo(0, 0);
    }
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
